import React from "react";
import { useLocation } from "react-router-dom";
import { PC, Mobile } from "../MediaQuery";
import styled from "styled-components";

import Header from "../components/common/header/Header";
import ContentDetails from "../components/content/ContentDetails";
import Footer from "../components/common/footer/Footer";
import FooterMenu from "../components/common/footer/FooterMenu";

const ContentDetailPage = ({ setIsContentModal, contentId, setContentId }) => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Container>
      <PC>
        <Header />
      </PC>
      <ContentDetails
        setIsContentModal={setIsContentModal}
        setContentId={setContentId}
        contentId={contentId}
      />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
    </Container>
  );
};
const Container = styled.div`
  @media screen and (max-width: 1024px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    z-index: 99999;
    transition: 0.5s;
  }
`;

export default ContentDetailPage;
