//react
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { Mobile, PC } from "../../../MediaQuery";
import serverController from "../../../server/serverController";

//css
import styled from "styled-components";

// img
// import ProductImgage1 from "../../../img/brand_logo3.png"
// import ProductImgage2 from "../../../img/brand_logo2.png"
// import ProductImgage3 from "../../../img/brand_logo1.png"
import ProductImgage1 from "../../../../images/category/category-prod-1.png";
import ProductImgage2 from "../../../../images/category/category-prod-2.png";
import ProductImgage3 from "../../../../images/category/category-prod-3.png";

// page
import Mypagefilter from "../Mypagefilter";
import OrderProduct from "../../common/product/OrderProduct";
import OrderProduct2 from "../../common/product/OrderProduct2";
import Pagination from "../../common/pagination/Pagination";

export default function MypageCancle() {
  const [list, setList] = useState([]);
  const [pageData, setPageData] = useState();

  const getCancle = async () => {
    await serverController.connectFetchController(
      `payment/history?orderType=noOrder?dateType=year`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setList(res?.payment_list ?? []);
          setPageData(res?.pageDto);
        }
      }
    );
  };

  useEffect(() => {
    getCancle();
  }, []);

  return (
    <Container>
      <PC>
        <MyTitleWrap>
          <Title>취소/교환/반품 조회</Title>
        </MyTitleWrap>

        {/* <Mypagefilter/> */}

        <MypageCancleWrap>
          <CancleTitleWrap>
            <ProductTit>상품정보</ProductTit>
            {/* <NumberTit>주문정보</NumberTit> */}
            <DayTit>주문일</DayTit>
            {/* <AmountTit>수량</AmountTit> */}
            <PriceTit>상품금액</PriceTit>
            <StepTit>진행상황</StepTit>
          </CancleTitleWrap>
          <ListWrap>
            {list.length > 0 ? (
              list.map((value, index) => {
                return (
                  <OrderProduct2 key={"orderList" + index} value={value} />
                );
              })
            ) : (
              <NoText>조회할 목록이 없습니다.</NoText>
            )}
          </ListWrap>
          {/* {
                    list.length > 0 &&
                    <Pagination pageData={pageData}/>
                } */}
        </MypageCancleWrap>
      </PC>

      <Mobile>
        <MyTitleWrap>
          <Title>취소/교환/반품 조회</Title>
        </MyTitleWrap>

        {/* <Mypagefilter/> */}

        <MypageCancleWrap>
          <ListWrap>
            {list.length > 0 ? (
              list.map((value, index) => {
                return <OrderProduct key={"orderList" + index} value={value} />;
              })
            ) : (
              <NoText>조회할 목록이 없습니다.</NoText>
            )}
          </ListWrap>
          {/* {
                    list.length > 0 &&
                    <Pagination pageData={pageData}/>
                } */}
        </MypageCancleWrap>
      </Mobile>
    </Container>
  );
}

const Container = styled.div`
  width: 1300px;
  margin: 0 auto;
  padding: 180px 0 200px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    padding: calc(100vw * (40 / 428)) 0 calc(100vw * (80 / 428));
  }
`;
const MyTitleWrap = styled.div`
  padding: 25px 0 0;
  margin-bottom: 50px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (25 / 1320)) 0;
    margin-bottom: calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0;
    margin-bottom: calc(100vw * (30 / 428));
  }
`;
const Title = styled.h2`
  font-size: 24px;
  color: #000;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    text-align: center;
    font-size: calc(100vw * (20 / 428));
  }
`;
const MypageCancleWrap = styled.div`
  width: 100%;
  border-top: 1px solid #000;

  @media screen and (max-width: 1320px) {
    border-width: calc(100vw * (1 / 1320));
  }

  @media screen and (max-width: 930px) {
    border-top: none;
  }
`;
const CancleTitleWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 0 18px 5px;
  border-bottom: 1px solid #d9d9d9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (18 / 1320)) 0 calc(100vw * (18 / 1320))
      calc(100vw * (5 / 1320));
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }
`;
const ProductTit = styled.div`
  width: calc(100% - 580px);
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #000;

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (580 / 1320)));
    font-size: calc(100vw * (18 / 1320));
  }
`;
const DayTit = styled.div`
  width: 140px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
`;
const NumberTit = styled.div`
  width: 100px;
  text-align: center;
  font-size: 18px;
  color: #000;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
`;
const AmountTit = styled.div`
  width: 80px;
  text-align: center;
  font-size: 18px;
  color: #000;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (80 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
`;
const PriceTit = styled.div`
  width: 200px;
  text-align: center;
  font-size: 18px;
  color: #000;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (200 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
`;
const StepTit = styled.div`
  width: 100px;
  text-align: center;
  font-size: 18px;
  color: #000;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
`;
const ListWrap = styled.div`
  width: 100%;
  border-bottom: 1px solid #000;

  @media screen and (max-width: 1320px) {
    border-bottom: calc(100vw * (1 / 1320)) solid #000;
  }
  @media screen and (max-width: 930px) {
    border-bottom: none;
  }
`;
const NoText = styled.p`
  width: 100%;
  padding: 100px 0;
  font-size: 16px;
  text-align: center;
  color: #969696;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (100 / 1320)) 0;
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (50 / 428)) 0;
    font-size: calc(100vw * (15 / 428));
  }
`;
