import React, { useState, useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";
import serverController from "../../../server/serverController";
import { imgURL } from "../../../server/appInfoContoller";
import { Swiper, SwiperSlide } from "swiper/react";
import IconDown from "../../img/brand/icon_down.svg";
import IconUp from "../../img/brand/icon_up.svg";
import brandDefaultImg from "../../img/icon/icon_brandDefault.svg";
import { useHistory } from "react-router-dom";

export default function BrandRankSection() {
  const history = useHistory();
  const [rankList, setRankList] = useState([]);

  const getBrandRanking = () => {
    serverController.connectFetchController(
      `brand/ranking`,
      "GET",
      null,
      (res) => {
        if (res.result === 1) setRankList(res.data);
      },
      (err) => console.error(err)
    );
  };

  useEffect(() => {
    getBrandRanking();
  }, []);

  return (
    <>
      {rankList?.length > 0 && (
        <BackGround>
          <Container>
            <>
              {/* <PC>
              <BrandWrap>
                <TitleWrap>
                  <TitleText>BEST BRAND</TitleText>
                </TitleWrap>
                <Swiper>
                  <RankWrap slidesPerView={1} slidesPerGroup={1}>
                    {rankList.map((v, i) => {
                      return (
                        <SwiperSlide key={i + "rank"}>
                          <RankList>
                            {v.map((value, index) => {
                              var randType = "+";
                              return (
                                <RankListBox key={value.brrVariance + index}>
                                  <RankImgBox>
                                    <RankImg>
                                      <img src={imgURL + value.brLogo} alt="" />
                                    </RankImg>
                                    <Brand>
                                      {index + 1}. {value.brName}
                                    </Brand>
                                  </RankImgBox>
                                  <RankCount
                                    on={value.brrVariance.includes(randType)}
                                  >
                                    <img
                                      src={
                                        value.brrVariance.includes(randType)
                                          ? IconUp
                                          : IconDown
                                      }
                                      alt=""
                                    />
                                    {value.brrVariance}
                                  </RankCount>
                                </RankListBox>
                              );
                            })}
                          </RankList>
                        </SwiperSlide>
                      );
                    })}
                  </RankWrap>
                </Swiper>
              </BrandWrap>
            </PC> */}
            </>

            <BrandWrap>
              <TitleWrap>
                <TitleText>BEST BRAND</TitleText>
              </TitleWrap>

              <PC>
                <RankWrap>
                  <RankArea>
                    <RankList>
                      {rankList.map((rankItem, index) => {
                        var randType = "+";
                        return (
                          <RankListBox
                            key={`${rankItem.brrVariance}${index}`}
                            onClick={() => {
                              history.push(`/store/brand/detail/${rankItem.bcId}?page=1`);
                            }}
                          >
                            <RankImgBox>
                              <RankImg>
                                <img
                                  src={
                                    rankItem.bcLogo
                                      ? `${imgURL}${rankItem.bcLogo}`
                                      : `${imgURL}${brandDefaultImg}`
                                  }
                                  alt="brand-lmg"
                                />
                              </RankImg>
                              <Brand>
                                {index + 1}. {rankItem.bcTitle}
                              </Brand>
                            </RankImgBox>
                            <RankCount>
                              <RankCountImg
                                src={rankItem.brrVariance.includes(randType) ? IconUp : IconDown}
                                alt="rank-lmg"
                              />
                              <RankCountText on={rankItem.brrVariance.includes(randType)}>
                                {rankItem.brrVariance}
                              </RankCountText>
                            </RankCount>
                          </RankListBox>
                        );
                      })}
                    </RankList>
                  </RankArea>
                </RankWrap>
              </PC>

              <Mobile>
                <Swiper slidesPerView={1} slidesPerGroup={1} pagination={true}>
                  <RankWrap>
                    <SwiperSlide>
                      <RankList>
                        {rankList.map((rankItem, index) => {
                          var randType = "+";
                          return (
                            <RankListBox
                              key={`${rankItem.brrVariance}${index}`}
                              onClick={() => {
                                history.push(`/store/brand/detail/${rankItem.bcId}?page=1`);
                              }}
                            >
                              <RankImgBox>
                                <RankImg>
                                  <img
                                    src={
                                      rankItem.bcLogo
                                        ? `${imgURL}${rankItem.bcLogo}`
                                        : `${imgURL}${brandDefaultImg}`
                                    }
                                    alt="brand-lmg"
                                  />
                                </RankImg>
                                <Brand>
                                  {index + 1}. {rankItem.bcTitle}
                                </Brand>
                              </RankImgBox>
                              <RankCount>
                                <RankCountImg
                                  src={rankItem.brrVariance.includes(randType) ? IconUp : IconDown}
                                  alt="rank-lmg"
                                />
                                <RankCountText on={rankItem.brrVariance.includes(randType)}>
                                  {rankItem.brrVariance}
                                </RankCountText>
                              </RankCount>
                            </RankListBox>
                          );
                        })}
                      </RankList>
                    </SwiperSlide>
                  </RankWrap>
                </Swiper>
              </Mobile>
            </BrandWrap>
          </Container>
        </BackGround>
      )}
    </>
  );
}
const BackGround = styled.div`
  background-color: #f9f9f9;
`;
const Container = styled.div`
  width: 1240px;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    padding-top: calc(100vw * (60 / 1320));
    padding-bottom: calc(100vw * (60 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding-top: calc(100vw * (30 / 428));
    padding-bottom: calc(100vw * (10 / 428));
  }
`;
const BrandWrap = styled.div`
  width: 100%;
`;
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (20 / 428));
  }
`;
const TitleText = styled.h2`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
  color: #222222;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const RankWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 13px;
  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (13 / 1320));
  }

  @media screen and (max-width: 930px) {
    display: block;
    margin-top: calc(100vw * (20 / 428));
  }
`;

const RankArea = styled.div``;

const RankList = styled.div`
  width: 350px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (350 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (358 / 428));
  }
`;
const RankListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px 0;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (7 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (10 / 428)) 0;
    &:last-child {
      padding-bottom: calc(100vw * (30 / 428));
    }
    &:first-child {
      padding-top: 0;
    }
  }
`;
const RankImgBox = styled.div`
  display: flex;
  align-items: center;
`;
const RankImg = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  overflow: hidden;
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (50 / 1320));
    height: calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (50 / 428));
    height: calc(100vw * (50 / 428));
  }
`;
const Brand = styled.p`
  font-size: 15px;
  padding-left: 15px;
  color: #222222;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding-left: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
    font-size: calc(100vw * (15 / 428));
    padding-left: calc(100vw * (15 / 428));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const RankCount = styled.p`
  display: flex;
  align-items: center;
`;
const RankCountImg = styled.img`
  display: block;
  width: 8px;
  margin-right: 10px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (8 / 1320));
    margin-right: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (8 / 428));
    margin-right: calc(100vw * (5 / 428));
  }
`;
const RankCountText = styled.span`
  font-size: 16px;
  ${({ on }) => {
    return on ? `color: #FF4B4B;` : `color: #4B60FF;`;
  }}
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
