import { enableAllPlugins } from 'immer';
import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
enableAllPlugins();

const SEND_NORMAL_INFO = 'signupData/SEND_NORMAL_INFO'; 
const SEND_NORMAL_PERSONAL_INFO = 'signupData/SEND_NORMAL_PERSONAL_INFO'; 
const SEND_BRAND_INFO = 'signupData/SEND_BRAND_INFO'; 
// const SIGN_UP_NORMAL_FINISH = 'signupData/SIGN_UP_NORMAL_FINISH'; 
// const LOG_OUT_REQUEST = 'signupData/LOG_OUT_REQUEST'; 


export const sendNormalInfo = createAction(SEND_NORMAL_INFO);
export const sendPersonalNormalInfo = createAction(SEND_NORMAL_PERSONAL_INFO);
export const sendBrandInfo = createAction(SEND_BRAND_INFO);
// export const logInRequest = createAction(SIGN_UP_NORMAL_FINISH);
// export const logOutRequest = createAction(LOG_OUT_REQUEST);

const initialState = {
    mem_password : "",
    mem_name : "",
    mem_email     : "",
    mem_phone: "",
    mem_style : [],
    mem_fit : [],
    mem_gender : 0,  // 0 남자 1 여자 
    mem_color : [],

    mem_business_registration : "",

    mem_rank : "",
    mem_brand_name : "",
    mem_belong : "",

    mem_celebrity_list : "",
    mem_business_registration : "",    
};


export default handleActions({
    [SEND_NORMAL_INFO]: (state, action) => {
        return produce(state, draft => {            
            draft.mem_password = action.payload.mem_password ?? draft.mem_password;
            draft.mem_name = action.payload.mem_name ?? draft.mem_name;
            draft.mem_email = action.payload.mem_email ?? draft.mem_email;           
            draft.mem_phone = action.payload.mem_phone ?? draft.mem_phone;           
        });
    },   
    [SEND_NORMAL_PERSONAL_INFO]: (state, action) => {
        return produce(state, draft => {            
            draft.mem_phone = action.payload.mem_phone ?? draft.mem_phone;   
            draft.mem_style = action.payload.mem_style ?? draft.mem_style;
            draft.mem_fit = action.payload.mem_fit ?? draft.mem_fit;
            draft.mem_gender = action.payload.mem_gender ?? draft.mem_gender;
            draft.mem_color = action.payload.mem_color ?? draft.mem_color;            
        });
    },   
    [SEND_BRAND_INFO]: (state, action) => {
        return produce(state, draft => {     
            draft.mem_phone = action.payload.mem_phone ?? "";   
            draft.mem_brand_name = action.payload.mem_brand_name ?? draft.mem_brand_name;
            draft.mem_business_registration = action.payload.mem_business_registration ?? draft.mem_business_registration;
            draft.mem_rank = action.payload.mem_rank ?? draft.mem_rank;
            draft.mem_brand_name = action.payload.mem_brand_name ?? draft.mem_brand_name;           
            draft.mem_belong = action.payload.mem_belong ?? draft.mem_belong;           
            
        });
    },   
}, initialState);