import React, { useState } from 'react';
import styled from 'styled-components';

import Header from '../common/header/Header';
import Footer from '../common/footer/Footer';

import SearchProduct from './SearchProduct';
import {ManagementTap} from '../../components/management/ManagementTap';
import {ProductListTab} from './ListTab';
import ContentsListEl from './contents/ContentsListEl';
import ContenstSimpleListEl from './contents/ContenstSimpleListEl';

export default function ContentsList({activeTab}) {
    const [viewSet, setViewSet] = useState('list');
    
    const ListView = (viewSet)=>{
        switch(viewSet){
            case 'list':
                return(
                    <ContentsListWrap>
                        {
                            Array(8).fill().map(()=> {
                                return(
                                    <ContentsListEl />
                                )
                            })
                        }
                    </ContentsListWrap>
                )
                break;
            case 'simple':
                return(
                    <ContentsSimpleWrap>
                        {
                            Array(8).fill().map(()=> {
                                return(
                                    <ContenstSimpleListEl/>
                                )
                            })
                        }
                    </ContentsSimpleWrap>
                )
                break;
            
        }
    }

    return(
        <>
            <ManagementTap activeTab={activeTab}/>
            <Wrapper>
                <ProductListTab setViewSet={setViewSet}/>
                {ListView(viewSet)}
            </Wrapper>
        </>
    )
}
const Wrapper = styled.div`
    position:relative;
    margin:0 auto;
    width: calc(100vw * (1300 / 1920));

    @media screen and (max-width:768px){
        width:100%;
        padding-left:calc(100vw * (25 / 428));padding-right:calc(100vw * (25 / 428));
        padding-top:36px;
    }
`;

const ContentsListWrap = styled.div`
    padding-bottom:calc(100vw * (147 / 1920));
`;

const ContentsSimpleWrap = styled.div`
    width:100%; display:flex; flex-wrap:wrap;
    margin-left:-9px; margin-right:-9px; padding:0 calc(100vw * (64 / 1920));
    &:after { content:''; display:block; clear:both; }
    @media screen and (max-width:1300px){
        padding:0;
    }
    @media screen and (max-width:768px){
        margin-left:-6px; margin-right:-6px;
    }
`;