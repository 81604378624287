import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from "styled-components";

import Modal from '../../../server/modal';
import serverController from '../../../server/serverController';

export default function ExitModal({setIsExitModal,exitClick}) {

    return (
        <Container>
            <ModalBox>
                <Title>WALA 회원을 탈퇴하시겠습니까?</Title>
                <Text>회원탈퇴를 신청하시면 즉시 탈퇴가 완료되며,<br/>해당 아이디의 모든 정보가 삭제되어 복구가 불가합니다.</Text>
                <BtnBox>
                    <Btn onClick={()=>{setIsExitModal(false); Modal.modalClosed()}}>취소</Btn>
                    <Btn onClick={()=>{exitClick(); Modal.modalClosed()}}>확인</Btn>
                </BtnBox>
            </ModalBox>
        </Container>
    );
}

const Container = styled.div`
    position: fixed; left: 0; top: 0; bottom: 0; right: 0;
    width: 100%; height: 100%;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.3);
`
const Title = styled.p`
    font-size: calc(100vw*(30/1920));
    text-align: center;
    font-weight: 900;
    padding-bottom: calc(100vw*(20/1920));
    
    @media screen and (max-width: 1024px) {
        font-size: calc(100vw*(22/428));
        padding-bottom: calc(100vw*(20/428));
    }
`
const Text = styled.p`
    font-size: calc(100vw*(16/1920));
    text-align: center;
    word-break: keep-all;
    padding-bottom: calc(100vw*(35/1920));
    
    @media screen and (max-width: 1024px) {
        font-size: calc(100vw*(16/428));
        padding-bottom: calc(100vw*(20/428));
    }
`
const ModalBox = styled.div`
    width: calc(100vw*(900/1920)); max-height: 85vh;
    padding: calc(100vw*(40/1920)) calc(100vw*(100/1920));
    position: relative;
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.4);
    overflow-y: auto;
    
    @media screen and (max-width: 1024px) {
        width: calc(100vw*(380/428));
        padding: calc(100vw*(25/428)) calc(100vw*(30/428));
    }
`
const Row = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: calc(100vw*(12/1920)) calc(100vw*(20/1920));
    border-bottom: 1px solid #000;

    @media screen and (max-width: 1024px) {
        padding: calc(100vw*(10/428));
    }
`
const SubTitle = styled.p`
    width: calc(100vw*(140/1920)); height: calc(100vw*(40/1920));
    font-size: calc(100vw*(18/1920));
    line-height: calc(100vw*(43/1920));
    
    @media screen and (max-width: 1024px) {
        font-size: calc(100vw*(16/428));
        width: calc(100vw*(90/428)); height: calc(100vw*(35/428));
        line-height: calc(100vw*(38/428));
    }
`
const Select = styled.select`
    width: calc(100% - calc(100vw*(140/1920))); height: calc(100vw*(40/1920));
    font-size: calc(100vw*(16/1920));
    
    @media screen and (max-width: 1024px) {
        width: calc(100% - calc(100vw*(140/428))); height: calc(100vw*(35/428));
        font-size: calc(100vw*(15/428));
    }
`
const Input = styled.input`
    width: calc(100% - calc(100vw*(140/1920))); height: calc(100vw*(40/1920));
    font-size: calc(100vw*(16/1920));
    &::placeholder{color: #bbbbbb;}
    
    @media screen and (max-width: 1024px) {
        width: calc(100% - calc(100vw*(140/428))); height: calc(100vw*(35/428));
        font-size: calc(100vw*(15/428));
    }
`
const Textarea = styled.textarea`
    width: calc(100% - calc(100vw*(140/1920))); height: calc(100vw*(150/1920));
    font-size: calc(100vw*(16/1920));
    border: none;
    &::placeholder{color: #bbbbbb;}
    
    @media screen and (max-width: 1024px) {
        width: calc(100% - calc(100vw*(140/428))); height: calc(100vw*(100/428));
        font-size: calc(100vw*(15/428));
    }
`
const BtnBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    @media screen and (max-width: 1024px) {
    }
`
const Btn = styled.p`
    width: calc(100vw*(140/1920));
    font-size: calc(100vw*(16/1920));
    text-align: center;
    padding: calc(100vw*(8/1920)) 0;
    margin: calc(100vw*(20/1920)) calc(100vw*(8/1920));
    background: #222;
    color: #fff;
    box-sizing: border-box;
    border: 1px solid #222;
    cursor: pointer;
    &:first-child {background: #fff; color: #222;}
    
    @media screen and (max-width: 1024px) {
        width: calc(100vw*(120/428));
        font-size: calc(100vw*(15/428));
        padding: calc(100vw*(8/428)) 0;
        margin: calc(100vw*(20/428)) calc(100vw*(6/428));
    }
`