import { useEffect, useState } from "react";
import { Mobile, PC } from "../../MediaQuery";

// component
import Header from "../../component/common/Header";
import MyPageHeader from "./MyPageHeader";
import MyProductInquiry from "../../component/mypage/inquiry/MyProductInquiry";
import FullLoading from "../../component/common/modal/FullLoading";
import FixMenu from "../../component/common/FixMenu";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function MyProductInquiryPage() {
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const validLogin = () => {
    if (isLogged) return;
    alert(`잘못된 접근입니다.`);
    history.push(`/store`);
  };

  const clickPrevButton = () => {
    history.push(`/store/mypage`);
  };

  useEffect(() => {
    validLogin();
  }, []);

  return (
    <>
      {loading && <FullLoading />}
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader title={"문의내역"} prevBtn prevFunc={clickPrevButton} />
      </Mobile>
      <MyProductInquiry setLoading={setLoading} />
      <FixMenu />
    </>
  );
}
