import { enableAllPlugins } from "immer";
import { createAction, handleActions } from "redux-actions";
import produce from "immer";
enableAllPlugins();

const LOG_IN_REQUEST = "userData/LOG_IN_REQUEST";
const LOG_OUT_REQUEST = "userData/LOG_OUT_REQUEST";

export const logInRequest = createAction(LOG_IN_REQUEST);
export const logOutRequest = createAction(LOG_OUT_REQUEST);

const initialState = {
  isLogged: false,
  userInfo: {
    mem_point: 0,
  },
  isNormal: false,
  isBrand: false,
  isStyle: false,
};

export default handleActions(
  {
    [LOG_IN_REQUEST]: (state, action) => {
      return produce(state, (draft) => {
        draft.isLogged = action.payload.isLogged ?? draft.isLogged;
        draft.userInfo = action.payload.userInfo
          ? Object.assign({}, draft.userInfo, action.payload.userInfo)
          : draft.userInfo;

        // if(action.payload === 1) {
        //     draft.isNormal = true;
        // } else if (action.payload === 2) {
        //     draft.isBrand = true;
        // } else if (action.payload === 3) {
        //     draft.isStyle = true;
        // }
      });
    },
    [LOG_OUT_REQUEST]: (state, action) => {
      return produce(state, (draft) => {
        const cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
          const cookie = cookies[i];
          const eqPos = cookie.indexOf("=");
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
        const delCookie = function delCookie_by_name(name) {
          let date = new Date();
          date.setDate(date.getDate() - 100);
          let Cookie = `${name}=;Expires=${date.toUTCString()}`;
          document.cookie = Cookie;
        };
        delCookie("JSESSIONID");
        sessionStorage.clear();

        draft.isLogged = false;
        draft.userInfo = {
          mem_id: "",
          mem_email: "",
          mem_password: "",
          mem_name: "",
          mem_phone: "",
          mem_alarm: "",
          mem_role: "",
          mem_point: 0,
          mem_gender: "",
          mem_style: "",
          mem_fit: "",
          mem_color: "",
          mem_brand_name: "",
          mem_business_registration: "",
          mem_rank: "",
          mem_belong: "",
          mem_celebrity_list: "",
          mem_activated: "",
          mem_register_date: "",
        };
      });
    },
  },
  initialState
);
