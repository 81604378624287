import { useState, useEffect, useRef } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import FixedTop from "../../component/common/FixedTop";
import MypageMenuList from "../../component/mypage/MypageMenuList";
import MypageWishList from "../../component/mypage/my/MypageWishList";
import WishList from "../../component/wish/WishList";
import Footer from "../../component/common/StoreFooter";
import FixMenu from "../../component/common/FixMenu";
import FullLoading from "../../component/common/modal/FullLoading";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function WishPage() {
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const validLogin = () => {
    if (isLogged) return;
    alert(`잘못된 접근입니다.`);
    history.push(`/store`);
  };

  useEffect(() => {
    validLogin();
  }, []);

  return (
    <>
      <PC>
        <Header />
        <MypageMenuList />
        <MypageWishList />
        <Footer />
        <FixedTop />
      </PC>

      <Mobile>
        {loading && <FullLoading />}
        <Header />
        <WishList setLoading={setLoading} />
        {/* <Footer pb={true} /> */}
        <FixMenu />
      </Mobile>
    </>
  );
}
