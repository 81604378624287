import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PaymentAction } from "../../../../store/actionCreators";
import serverController from "../../../server/serverController";

//img
import IconClose from "../../../img/icon/icon_popup_close.svg";

// component
import PayAdressEditModal from "./PayAdressEditModal";

import AdressEditModal from "./AdressEditModal";
import AdressPatchModal from "./AdressPatchModal";

const PayAdressModal = ({ setIsModal, isModal }) => {
  const body = document.querySelector("body");
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [current, setCurrent] = useState({});

  const [list, setList] = useState([]);

  const getData = () => {
    serverController.connectFetchController(`receivers`, "GET", null, (res) => {
      if (res.result == 1) {
        setList(res?.list ?? []);
        const item = res?.list[0];
        PaymentAction.saveLocationInfo({
          ...item,
        });
      }
    });
  };

  useEffect(() => {
    body.style.overflow = "hidden";
    body.style.height = "100vh";
    return () => {
      body.style.overflow = "auto";
      body.style.height = "auto";
    };
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const selectReceiver = (value) => {
    if (!value) return;
    PaymentAction.saveLocationInfo(value ?? {});
    setIsModal(false);
  };

  return (
    <React.Fragment>
      {/* {isAddModal && (
        <PayAdressEditModal
          setIsAddModal={setIsAddModal}
          setIsModal={setIsModal}
          title={"배송지 추가"}
          getData={getData}
        />
      )} */}

      {isAddModal && (
        <AdressEditModal
          setIsAddModal={setIsAddModal}
          setIsModal={setIsModal}
          title={"배송지 추가"}
        />
      )}
      {isEditModal && (
        <AdressPatchModal
          setIsEditModal={setIsEditModal}
          setIsModal={setIsModal}
          title={"배송지 수정"}
          current={current}
          getData={getData}
        />
      )}

      <Container>
        <Background />

        <Content>
          <TitleCont>
            <Title>배송지 변경</Title>
          </TitleCont>
          <ListWrap>
            <ListArea>
              {list.map((value, index) => {
                return (
                  <AdressList key={value?.receiver_id}>
                    <BtnBox>
                      <AdrTitleWrap>
                        <AdrTitle>{value?.receiver_title} </AdrTitle>
                        {value?.main_receiver == 1 ? (
                          <Default>기본배송지</Default>
                        ) : null}
                      </AdrTitleWrap>
                      <Adradress>
                        {value.postal_code} {value?.receiver_address}
                      </Adradress>
                      <AdrName>
                        {value?.receiver_username} - {value?.receiver_phone}
                      </AdrName>
                    </BtnBox>
                    <AdressBtnWrap>
                      <AdressBtnBox>
                        {/* <AdressBtn
                          type="button"
                          onClick={() => deleteReceiver(value, index)}
                        >
                          삭제
                        </AdressBtn> */}
                        <AdressBtn
                          type="button"
                          onClick={() => {
                            setIsEditModal(true);
                            setCurrent(value);
                          }}
                        >
                          수정
                        </AdressBtn>
                      </AdressBtnBox>
                      <AdressBtnOn
                        type="button"
                        onClick={() => selectReceiver(value)}
                      >
                        선택
                      </AdressBtnOn>
                    </AdressBtnWrap>
                  </AdressList>
                );
              })}
            </ListArea>
          </ListWrap>
          <BtnWrap>
            <Btn
              type="button"
              onClick={() => {
                setIsAddModal(true);
              }}
            >
              + 신규 배송지 추가
            </Btn>
          </BtnWrap>

          <CloseButton
            type="button"
            onClick={() => {
              setIsModal(false);
            }}
          >
            닫기
          </CloseButton>
        </Content>
      </Container>
    </React.Fragment>
  );
};

export default PayAdressModal;

const Container = styled.div`
  width: 100%;
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;
// 컨텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 520px;
  background-color: #fff;
  z-index: 100;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (520 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (398 / 428));
  }
`;

const TitleCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 83px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (83 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (71 / 428));
  }
`;
const Title = styled.h2`
  color: #333;
  font-weight: 500;
  font-size: 20px;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;

//Adress list
const ListWrap = styled.div`
  width: 100%;
  max-height: 457px;
  overflow-y: auto;

  @media screen and (max-width: 1320px) {
    max-height: calc(100vw * (457 / 1320));
  }
  @media screen and (max-width: 930px) {
    max-height: calc(100vh * (508 / 925));
  }
`;
const ListArea = styled.div`
  width: 460px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (460 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;

const AdressList = styled.div`
  padding: 20px 10px;
  &:not(:last-child) {
    border-bottom: 8px solid #f9f9f9;
  }
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) calc(100vw * (25 / 428));
  }
`;
const AdrTitleWrap = styled.p`
  display: flex;
  align-items: center;
`;
const AdrTitle = styled.span`
  max-width: calc(100% - 92px);
  color: #333;
  font-weight: 600;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    max-width: calc(100% - calc(100vw * (92 / 1320)));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    max-width: calc(100% - calc(100vw * (92 / 428)));
    font-size: calc(100vw * (16 / 428));
  }
`;
const Default = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82px;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  padding: 5px 0;
  margin-left: 10px;
  background-color: #fdf251;
  border-radius: 14px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (82 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (5 / 1320)) 0;
    margin-left: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (82 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (5 / 428)) 0;
    margin-left: calc(100vw * (10 / 428));
  }
`;
const Adradress = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 15px;
  margin-top: 8px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (12 / 428));
  }
`;
const AdrName = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 15px;
  margin-top: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (6 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (6 / 428));
  }
`;
const AdressBtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  & button:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
    & button:not(:last-child) {
      margin-right: calc(100vw * (10 / 428));
    }
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
    & button:not(:last-child) {
      margin-right: calc(100vw * (10 / 428));
    }
  }
`;

const AdressBtnBox = styled.div`
  display: inline-flex;
  align-items: center;
`;
const BtnBox = styled.div`
  width: 100%;
`;
const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (110 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (110 / 428));
  }
`;
const Btn = styled.button`
  display: block;
  width: 202px;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (202 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (348 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
const AdressBtn = styled.button`
  width: 90px;
  height: 35px;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #ddd;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (90 / 1320));
    height: calc(100vw * (35 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (90 / 428));
    height: calc(100vw * (35 / 428));
    font-size: calc(100vw * (16 / 428));
  }
`;

const AdressBtnOn = styled(AdressBtn)`
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
`;
// 닫기
const CloseButton = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  width: 32px;
  height: 32px;
  font-size: 0;
  background: url(${IconClose}) no-repeat center / cover;

  @media screen and (max-width: 1320px) {
    top: calc(100vw * (25 / 1320));
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: calc(100vw * (15 / 428));
    right: calc(100vw * (18 / 428));
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
  }
`;
