import styled, { css } from 'styled-components';
import {
    mobil,
    mquery,
    Media,
    Px,
    pc,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
} from '../../common/mediaStlye';
import {
    PrimaryButton,
    CheckboxInput
} from '../auth.elements';

export const LoginIntroBtns = styled.div`
    ${[
        MT(111, pc),
        MB(433, pc)
    ].join("")}
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: ${mquery}px) {
        ${[
            MT(91, mobil),
            MB(150, mobil)
        ].join("")}
    }
`

export const LoginButton = styled(PrimaryButton)`
    ${props => props.primary && css`
        background-color: #FFF200;
        color: #101010;
    `}    

    @media screen and (max-width: ${mquery}px) {
        ${[
            Height(54.97, mobil),            
            MB(15, mobil),
            FontSize(20, mobil)
        ].join("")} 
        width: 100%;
    }
`

// Login
export const LoginBox = styled.div`

`



export const LoginBottom = styled.div`
    ${[
        Padding2(57, 0, pc),
        MB(45, pc)
    ].join("")}
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom:1px solid #A5A5A5;

    @media screen and (max-width: ${mquery}px) {
        ${PB(40, mobil)}
        ${MB(30.9, mobil)}
    }
`

export const LoginBtn = styled(LoginButton).attrs(props => ({
    type: 'submit',    
}))`
    margin-bottom: 0;
`

export const LoginSub = styled.div`
    ${FontSize(15, pc)}
    display: flex; 
    justify-content: flex-end;
    align-items: center;    

    & > ul {
        display: flex; 
        justify-content: center;
        align-items: center;               
    }
    & li {
        font-size: calc(100vw*(16/1920));
        color: #A5A5A5;
        cursor: pointer;
    }
    & li:first-child {
        ${MR(12, pc)}
        position: relative;
    }
    & li:first-child::after {
        ${Height(11, pc)}
        content: "";
        position: absolute;
        right: calc(100vw*(-10/1920));
        top:50%;
        transform: translateY(-50%);
        width: 1px;
        background-color: #C1C1C1;
    }
    & li:last-child {
        ${ML(12, pc)}
    }

    @media screen and (max-width: ${mquery}px) {
        ${FontSize(13, mobil)}
        ${MB(80, mobil)}

        & li {
            font-size: calc(100vw*(12/428));
        }
        & li:first-child {
            ${PR(12, mobil)}
            position: relative;
        }
        & li:first-child::after {
            ${Height(11, mobil)}            
        }
        & li:last-child {
            ${PL(12, mobil)}
        }
    }
    
`    

export const IdSaveLabel = styled.label`
    ${PL(12, pc)}
    & > input {
        ${MR(10, pc)}
    }

    & > span {
        color: #A5A5A5;
    }

    @media screen and (max-width: ${mquery}px) {
        & > input {
            ${MR(6, mobil)}
        }
    }    
`
export const IdSaveCheckbox = styled(CheckboxInput)`
    vertical-align: middle;
` 

// sign up
export const SignUpLinkBox = styled.div`
    ${MB(175, pc)}
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > p {
        ${MB(16, pc)}
    }

    @media screen and (max-width: ${mquery}px) {
        ${MB(145, mobil)}

        & > p {
            ${MB(16, mobil)}
        }   
    }
`
export const SignUpLinkButton = styled(LoginButton)`
    background-color: #fff;
    border: 1px solid #222;
    color: #222;
`
