//react
import React from "react";
import { useHistory } from "react-router-dom";

//css
import styled from "styled-components";

// controller
import Commas from "../../../server/Commas";

//img
import CompleteImg01 from "../../../img/icon/icon_pay_complete_01.svg";
import CompleteImg02 from "../../../img/icon/icon_pay_complete_02.svg";
import { imgURL } from "../../../server/appInfoController";

export default function Complete({ title, list, query }) {
  const history = useHistory();

  return (
    <Container>
      <CompleteWrap>
        {/* order info */}
        <OrderInfoWrap>
          <OrderImgArea>
            {/* TODO PUB :: 교환/반품 요청 시, 이미지 */}
            {query?.code && query?.code === "PAY_PROCESS_CANCELED" ? (
              <img src={CompleteImg01} alt="product-img" />
            ) : (
              <img src={CompleteImg02} alt="product-img" />
            )}
          </OrderImgArea>
          <OrderTextArea>
            <OrderTitleBox>
              <OrderTitle>{title}</OrderTitle>
              {query?.orderId && (
                <OrderTextBox>
                  <OrderText>주문번호 : {query?.orderId || "undefinded"}</OrderText>
                  <OrderSubText>
                    주문내역 확인은 마이페이지
                    <br />
                    ‘주문/배송조회’에서 하실 수 있습니다.
                  </OrderSubText>
                </OrderTextBox>
              )}
            </OrderTitleBox>
            {/* 무통장 입금 시 */}
            {query?.vbankNum && (
              <BankTextListBox>
                <BankTextList>
                  <BankTitle>입금은행</BankTitle>
                  <BankText>{query.vbankName}</BankText>
                </BankTextList>
                <BankTextList>
                  <BankTitle>계좌번호</BankTitle>
                  <BankText>{query.vbankNum}</BankText>
                </BankTextList>
                <BankTextList>
                  <BankTitle>예금주</BankTitle>
                  <BankText>왈라</BankText>
                </BankTextList>
                <BankTextList>
                  <BankTitle>입급금액</BankTitle>
                  <BankText>{Commas.numberWithCommas(query.amount)}원</BankText>
                </BankTextList>
                <BankTextList>
                  <BankTitle>입금만료일</BankTitle>
                  <BankText>{query?.vbankDate?.split("T")[0]}까지</BankText>
                </BankTextList>
              </BankTextListBox>
            )}

            {/* TODO PUB :: 교환/반품 요청 시, 타이틀 및 텍스트 변경 부탁드립니다. */}
            {/* <OrderTitleBox>
              <OrderTitle>교환 요청이 접수되었습니다.</OrderTitle>
              <OrderTextBox>
                <OrderSubText>
                  판매자 확인 후 
                  <br />
                  교환요청이 거절 될 수 있습니다.
                </OrderSubText>
              </OrderTextBox>
            </OrderTitleBox>
            <OrderTitleBox>
              <OrderTitle>반품 요청이 접수되었습니다.</OrderTitle>
              <OrderTextBox>
                <OrderSubText>
                  판매자 확인 후 
                  <br />
                  반품요청이 거절 될 수 있습니다.
                </OrderSubText>
              </OrderTextBox>
            </OrderTitleBox> */}
          </OrderTextArea>

          <OrderButtonArea>
            {/* <OrderDetailViewButton
              type="button"
              onClick={() => {
                history.push(
                  `/store/mypage/order/detail/${prdId}?orderId=${query?.orderId}`
                );
              }}
            >
              주문상세 보기
            </OrderDetailViewButton> */}
            <ShoppingViewButtonMy
              type="button"
              onClick={() => {
                history.push("/store/mypage");
              }}
            >
              마이페이지
            </ShoppingViewButtonMy>
            <ShoppingViewButton
              type="button"
              onClick={() => {
                history.push("/store");
              }}
            >
              계속 쇼핑하기
            </ShoppingViewButton>
          </OrderButtonArea>
          {/* TODO PUB :: 교환/반품 요청 시, 버튼 적용 부탁드립니다. */}
          {/* <OrderButtonArea>
            <OrderConfirmButton
              type="button"
              onClick={() => {
                history.push("/store");
              }}
            >
              확인
            </OrderConfirmButton>
          </OrderButtonArea> */}
        </OrderInfoWrap>
        {/* product info */}
        <ProductListWrap>
          {list?.length > 0 &&
            query?.code !== "PAY_PROCESS_CANCELED" &&
            list?.map((productItem, idx) => {
              const cartItem = JSON.parse(productItem.cart_items);
              console.log(cartItem, "cartItem");
              return (
                <ProductListArea key={`${cartItem.prd_id}-completeItem-${idx}`}>
                  <ProductListInner>
                    <ProductBoxWrap>
                      <ProductBoxList>
                        <ProductInfo>
                          <ProductImgBox>
                            <img src={`${imgURL}${cartItem.prd_img}`} alt="product-img" />
                          </ProductImgBox>
                          <ProductTextBox>
                            <ProductIngText>결제완료</ProductIngText>
                            <ProductName>{cartItem.prd_title}</ProductName>
                            {cartItem.data.length > 0 &&
                              cartItem.data.map((optionListV) => {
                                let optionArr = [];
                                for (let i in optionListV.options.optionList) {
                                  optionArr.push(optionListV.options.optionList[i]);
                                }
                                return (
                                  <>
                                    {optionArr[0].length > 0 &&
                                      optionArr[0].map((optionItem) => {
                                        return (
                                          <ProductColor key={optionItem.opt_id}>
                                            {`${optionItem.opt_title} : ${optionItem.opt_name}`} |{" "}
                                            {`${optionListV.count} 개`}
                                          </ProductColor>
                                        );
                                      })}
                                  </>
                                );
                              })}
                            <ProductPriceWrap>
                              <ProductPriceInner>
                                <ProductPrice>
                                  {query?.amount &&
                                    Commas.numberWithCommas(cartItem?.prd_list_price)}
                                  원
                                </ProductPrice>
                              </ProductPriceInner>
                            </ProductPriceWrap>
                          </ProductTextBox>
                        </ProductInfo>
                      </ProductBoxList>
                    </ProductBoxWrap>
                  </ProductListInner>
                </ProductListArea>
              );
            })}
        </ProductListWrap>
      </CompleteWrap>
    </Container>
  );
}
const Container = styled.div``;
const CompleteWrap = styled.div`
  width: 100%;
  padding: 180px 0 260px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (260 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (40 / 428)) 0 calc(100vw * (193 / 428));
  }
`;

// 이후 코드
// order info
const OrderInfoWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 80px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (80 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: calc(100vw * (40 / 428)) 0;
    margin: 0 auto;
  }
`;
const OrderImgArea = styled.div`
  width: 200px;
  height: 180px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (200 / 1320));
    height: calc(100vw * (180 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (200 / 428));
    height: calc(100vw * (180 / 428));
  }
`;
const OrderTextArea = styled.div``;
const OrderTitleBox = styled.div`
  margin-top: 50px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (50 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (50 / 428));
  }
`;
const OrderTitle = styled.p`
  color: #222;
  font-weight: 600;
  font-size: 20px;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
  }
`;
const OrderTextBox = styled.div`
  margin-top: 30px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (30 / 428));
  }
`;
const OrderText = styled.p`
  line-height: 1.5;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  margin-bottom: 10px;
  /* 드래그 허용 */
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-user-drag: text;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-bottom: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-bottom: calc(100vw * (12 / 428));
  }
`;
const OrderSubText = styled(OrderText)`
  font-weight: 400;

  @media screen and (max-width: 930px) {
    margin-bottom: 0;
  }
`;

// 무통장 입금 시
const BankTextListBox = styled.ul`
  margin-top: 50px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (50 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (50 / 428));
  }
`;
const BankTextList = styled.li`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 428));
    }
  }
`;
const BankTitle = styled.span`
  width: 96px;
  color: #777;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (96 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (96 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;
const BankText = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 15px;
  /* 드래그 허용 */
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-user-drag: text;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const OrderButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (50 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (30 / 428));
    flex-direction: column;
  }
`;
const OrderDetailViewButton = styled.button`
  width: 184px;
  height: 50px;
  color: #333;
  font-weight: 500;
  font-size: 18px;
  background-color: #fff;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (184 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (184 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
const ShoppingViewButtonMy = styled.button`
  width: 184px;
  height: 50px;
  color: #333;
  font-weight: 500;
  font-size: 18px;
  background-color: #fff;
  border: 1px solid #333;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (184 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (184 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
    margin-bottom: calc(100vw * (14 / 428));
  }
`;
const ShoppingViewButton = styled(OrderDetailViewButton)`
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
  margin-left: 10px;

  @media screen and (max-width: 1320px) {
    margin-left: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-left: 0;
  }
`;
const OrderConfirmButton = styled.button`
  width: 378px;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;

// product info
const ProductListWrap = styled.ul`
  width: 1240px;
  margin: 0 auto;
  border-top: 10px solid #f9f9f9;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ProductListArea = styled.li`
  padding: 30px 0;

  &:not(:last-child) {
    border-bottom: 10px solid #f9f9f9;
  }

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const ProductListInner = styled.div`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  }
`;
const Brand = styled.p`
  color: #111;
  font-weight: 600;
  font-size: 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #000;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding-bottom: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    padding-bottom: calc(100vw * (10 / 428));
  }
`;
const ProductBoxWrap = styled.ul``;
const ProductBoxList = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 15px 0 30px;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (15 / 1320)) 0 calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0 calc(100vw * (30 / 428));
  }
`;
const ProductInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const ProductImgBox = styled.div`
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 1px solid #eee;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    height: calc(100vw * (100 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    height: calc(100vw * (100 / 428));
  }
`;
const ProductTextBox = styled.div`
  width: calc(100% - 112px);

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (112 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (266 / 428));
  }
`;
const ProductIngText = styled.p`
  color: #111;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-bottom: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProductColor = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 15px;
  margin-top: 4px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (4 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (4 / 428));
  }
`;
const ProductPriceWrap = styled.div``;
const ProductPriceInner = styled.p`
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (6 / 428));
  }
`;
const ProductPrice = styled.span`
  color: #333;
  font-weight: 600;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;

// 이전 코드
const CompleteTitleWrap = styled.div`
  width: 100%;
  margin-bottom: 80px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (80 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
    margin-bottom: calc(100vw * (50 / 428));
  }
`;
const CompleteTitle = styled.p`
  font-size: 26px;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (26 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (22 / 428));
  }
`;
const VbankBox = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  & p:last-child {
    margin-left: 3px;
  }

  @media screen and (max-width: 1320px) {
    & p:last-child {
      margin-left: calc(100vw * (3 / 428));
    }
  }
  @media screen and (max-width: 930px) {
    & p:last-child {
      margin-left: calc(100vw * (3 / 428));
    }
  }
`;
const VbankNum = styled.p`
  font-size: 18px;
  margin-top: 12px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    margin-top: calc(100vw * (12 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;
const VbankDate = styled(VbankNum)`
  width: 100%;
  text-align: center;
  margin-top: 0;
  margin-bottom: 5px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (5 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (4 / 428));
  }
`;
const Sub = styled.p`
  width: 100%;
  font-size: 15px;
  text-align: center;
  color: #999999;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (13 / 428));
  }
`;
const CompleteBtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CompleteBtn = styled.p`
  width: 450px;
  font-size: 18px;
  text-align: center;
  color: #fff;
  background: #000;
  padding: 20px 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (450 / 1320));
    font-size: calc(100vw * (18 / 1320));
    padding: calc(100vw * (20 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
