import React, { useState } from "react";
import axios from "axios";
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";

export default function Paginate({ page, setPage, items, pageList, loading, PAGE_LIMIT }) {
  const history = useHistory();

  const JumpPrevPage = () => {
    setPage(() => {
      const newPage = pageList[0] - PAGE_LIMIT;
      history.replace(`test?page=${newPage}`);
      return newPage;
    });
  };
  const JumpNextPage = () => {
    setPage(() => {
      const newPage = pageList[pageList.length - 1] + 1;
      history.replace(`test?page=${newPage}`);
      return newPage;
    });
  };
  const ChangePage = (next) => {
    setPage((prev) => {
      const newPage = prev + next;
      history.replace(`test?page=${newPage}`);
      return newPage;
    });
  };
  const NumberPage = (el) => {
    setPage(() => {
      const newPage = el;
      history.replace(`test?page=${newPage}`);
      return newPage;
    });
  };


  return (
    <>
      <PaginationCont>
        {items?.pageInfo && <JumpPageArrow
          onClick={JumpPrevPage}
          disabled={pageList[0] < PAGE_LIMIT}
        >&lt;&lt;</JumpPageArrow>}

        {items?.pageInfo && <PageArrow
          onClick={() => { ChangePage(-1) }}
          disabled={page === 1}
        >&lt;</PageArrow>}

        <PageBtnWrap>
          {pageList.map((el) => {
            return <PageBtn
              onClick={() => NumberPage(el)}
              active={el == page} key={el}>{el}</PageBtn>
          })}
        </PageBtnWrap>

        {items?.pageInfo &&
          <PageArrow
            onClick={() => { ChangePage(1) }}
            disabled={page === items.pageInfo.maxPage}
          >&gt;</PageArrow>}

        {items?.pageInfo &&
          <JumpPageArrow
            onClick={JumpNextPage}
            disabled={pageList.includes(items.pageInfo.maxPage)}
          >&gt;&gt;</JumpPageArrow>}
      </PaginationCont>
    </>
  )
}
const PageBtnWrap = styled.div`
width: 120px;
`

const PaginationCont = styled.div`
display: flex;
`

const PageBtn = styled.button`
  ${props => props.active && css`
    font-weight: bold;
   `}
`

const PageArrow = styled.button`
${props => props.disabled && css`
    color: #ccc;
   `}
`

const JumpPageArrow = styled.button`
${props => props.disabled && css`
    color: #ccc;
   `}
`