//react
import React, { useState, useEffect, useRef } from "react";

//css
import styled from "styled-components";

//img
import IconSelect from "../../img/brand/select.png";
import ArrowImg from "../../store_src/img/icon/icon_selectBox_arrow.svg";

export default function DetailSetOptionSelectBox({ title, list, clickSelect }) {
  //const [title,setTitle ] = useState("");
  const optionBoxRef = useRef();
  const [show, setShow] = useState(false);
  const [selectTitle, setSelectTitle] = useState(title);
  const [listHistory, setListHistory] = useState(list);
  // if(list == undefined || list.length == 0 || reset == true){
  //   return <View></View>
  // }

  useEffect(() => {
    if (!list || list[0].opt_relate_id == 0) {
      return;
    }

    if (listHistory != null) {
      if (JSON.stringify(listHistory) != JSON.stringify(list)) {
        setSelectTitle(title);
      }
    }

    setListHistory(list);
  }, [list]);

  const openDropDownModal = () => {
    const selectBox = document.getElementsByClassName('box_wrap');
    if (document.getElementById(title) && document.getElementById(title).classList.contains("active")) {
      for (let i = 0; i < selectBox.length; i++) {
        selectBox[i].classList.remove("active");
      };
      return;
    } else {
      for (let i = 0; i < selectBox.length; i++) {
        selectBox[i].classList.remove("active");
      };
      setTimeout(() => {
        document.getElementById(title) &&
          document.getElementById(title).classList.add('active');
      }, 0);
    }

  };

  const soldOutDropDownModal = (e) => {
    const selectBox = document.getElementsByClassName('box_wrap');
    for (let i = 0; i < selectBox.length; i++) {
      selectBox[i].classList.remove("active");
    };
  };

  const modalSelect = (value) => {
    document.getElementById(title).classList.remove('active');
    if (clickSelect(value) == false) return;
    setSelectTitle(value.label);
  };

  return (
    <OptionWrap>
      <Optionbox ref={optionBoxRef} onClick={(e) => { openDropDownModal() }}>
        <OptionBoxIcon src={ArrowImg} alt="arrow-img" />
        <OptionText>{selectTitle}</OptionText>
        {
          // show ?
          // <img  src={require('../../../assets/img/label_point/dropUpPoint.png')} resizeMode={"stretch"}/>
          // :
          // <img  src={require('../../../assets/img/label_point/dropDownPoint.png')} resizeMode={"stretch"}/>
        }
      </Optionbox>
      {list ? (
        <SelectBoxWrap id={title} className="box_wrap">
          {list.map((value, index) => {
            const isSoldOut =
              (value.option.opt_inventory === 0 && value.option.opt_last === "Y") || value.soldout;
            const isLastInventory =
              value.option.opt_inventory < 10 && value.option.opt_last === "Y";
            return (
              <SelectBox
                key={`${index}-option`}
                onClick={() => {
                  if (isSoldOut) {
                    soldOutDropDownModal();
                    alert("품절 상품은 선택하실 수 없습니다.");
                    return;
                  };
                  modalSelect(value);
                }}
              >
                <SelectText gray={isSoldOut} disabled={isSoldOut}>
                  {isSoldOut ? "[품절] " : ""}
                  {value.label}
                  {isLastInventory && ` (${value.option.opt_inventory}개 남음)`}
                </SelectText>
              </SelectBox>
            );
          })}
        </SelectBoxWrap>
      ) : null}
    </OptionWrap>
  );
}

const OptionWrap = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 428));
    }
  }
`;
const Optionbox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 0 20px;
  border: 1px solid #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* background: url(${IconSelect}) no-repeat 96% 50% / calc(100vw * (10 / 1920)); */

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    padding: 0 calc(100vw * (20 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
    /* background: url(${IconSelect}) no-repeat 96% 50% / calc(100vw * (10 / 428)); */
  }
`;
const OptionBoxIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (15 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    right: calc(100vw * (15 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const SelectBoxWrap = styled.div`
  position: absolute;
  display: none;
  z-index: 50;
  width: 100%;
  max-height: 130px;
  background: #fff;
  border: 1px solid #000;
  overflow: scroll;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    max-height: calc(100vw * (130 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    max-height: calc(100vw * (130 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
  &.active{
    display: block;
  }
  &.box_wrap{

  }
`;
const SelectBox = styled.div`
  width: 100%;
  padding: 10px 20px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (10 / 1320)) calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (10 / 428)) calc(100vw * (20 / 428));
  }
`;
const SelectText = styled.p`
  width: 100%;
  color: #333333;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${({ gray }) => gray && `color : #999;`}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const OptionText = styled(SelectText)`
  padding-right: 54px;

  @media screen and (max-width: 1320px) {
    padding-right: calc(100vw * (54 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-right: calc(100vw * (54 / 428));
  }
`;
