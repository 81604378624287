import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { numberWithCommas } from '../commonUse';

import {
    RequstPeriod,
    RequstLabel,
} from './SponRequestModal.elements';
import Calendar from '../calendar';

import IconCheckbox from "../../../store_src/img/icon/icon_checkbox.png"

const RequestCalendar = ({ current, setStartDate, setEndDate, totalPrice, setTotalPrice, totalDays, setTotalDays }) => {
    const [option, setOption] = useState("3일");
    const [isLong, setIsLong] = useState(false);
    const [value, setValue] = useState("")

    useEffect(() => {
        setIsLong(option === "장기")
    }, [option])

    function leftPad(value) {
        if (value >= 10) {
            return value;
        }
        return `0${value}`;
    }

    function toStringByFormatting(source, delimiter = '-') {
        const year = source.getFullYear();
        const month = leftPad(source.getMonth() + 1);
        const day = leftPad(source.getDate());
        return [year, month, day].join(delimiter);
    }

    useEffect(() => {
        const [start, end] = value;
        console.log(start, end)
        if (!start || !end) return;
        const startDate = new Date(start);
        const endDate = new Date(end);
        const differ = endDate.getTime() - startDate.getTime();
        const days = (differ / (1000 * 60 * 60 * 24) ?? 1);
        const rentPrice = (current?.product?.prd_days_price ?? 1) * (days + 1);
        setTotalDays(days + 1)
        setTotalPrice(rentPrice)

        setStartDate(toStringByFormatting(startDate))
        setEndDate(toStringByFormatting(endDate))
    }, [current, value])


    const checkboxData = [{
        value: "3일",
    }, {
        value: "5일",
    }, {
        value: "장기",
    }]

    const [checks, setChecks] = useState([true, false, false])
    // const handleCheck = (e, index) => {
    //     e.preventDefault();
    //     setChecks(prev => {
    //         const newArr = [...prev];
    //         newArr[index] = !newArr[index];
    //         return newArr
    //     })
    // }
    const handleChecks = (index) => {
        // e.preventDefault();
        setChecks(prev => {
            // const newArr = [...prev];            
            if (!prev[index]) {
                const newArr = Array(checkboxData.length).fill(false);
                newArr[index] = true;
                return newArr;
            } else {
                const newArr = [...prev];
                newArr[index] = !prev[index];
                return newArr;
            }
        })
    }

    useEffect(() => {
        setChecks(prev => prev.map((v, i) => {
            if (i === 0) v = true;
            return v
        }))
    }, [])

    useEffect(() => {
        const optIndex = checks.indexOf(true);
        if (optIndex !== -1) {
            setOption(checkboxData[optIndex].value)
        }
    }, [checks])


    return (
        <RequstPeriod>
            <em>협찬 기간</em>
            <Container>
                {!isLong ? <Calendar option={option} setValue={setValue} /> : <Calendar isLong setValue={setValue} />}
                <CheckWrapper>
                    {
                        checkboxData.map((v, i) => {
                            return (
                                <CheckRow key={i}>
                                    <Checks>
                                        <ChkBox id={`option${i}`} type="checkbox" name="checkbox" checked={checks[i]} onClick={() => handleChecks(i)} />
                                        <ChkBoxLabel htmlFor={`option${i}`}></ChkBoxLabel>
                                    </Checks>
                                    <span>{v.value}</span>
                                </CheckRow>
                            )
                        })
                    }
                </CheckWrapper>
                {/* <select onChange={e => setOption(e.target.value)}>
                        <option value="3일">3일</option>
                        <option value="5일">5일</option>
                        <option value="장기">장기</option>
                    </select> */}
            </Container>

            <RequstLabel>
                <Em>협찬 예상 가격</Em>
                <TotalPrice>{numberWithCommas(totalPrice)}원</TotalPrice>
            </RequstLabel>
            <RequstLabel>
                <Em>협찬 기간</Em>
                <TotalPrice>{totalDays ? `${totalDays}일` : ""}</TotalPrice>
            </RequstLabel>
        </RequstPeriod>

    )
}

export default RequestCalendar

const Container = styled.div`
    display: flex;
    margin-top: calc(100vw*(10/1920));
    gap: calc(100vw*(40/1920));
`

const CheckWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: calc(100vw*(10/1920));
`

const CheckRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* padding-left: calc(100vw*(40 /1920)); */

    & span {
        font-size: calc(100vw*(18/1920));
        font-weight: 600;
        line-height: 1.2;
    }
`

const TotalPrice = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    top: 2px;
    line-height: 1;
    font-weight: bold;
`

const Em = styled.div`
    padding-left: calc(100vw*(10/1920));
    width: calc(100vw*(145/1920));
    font-size: calc(100vw*(18/1920));
    font-weight: 600;
    @media screen and (max-width: 930px) {
        padding-left: calc(100vw*(10/428));
        width: calc(100vw*(115/428));
        font-size: calc(100vw*(15/428));
    }
`

const Checks = styled.div`
    width: 24px;
    height: 24px;
    margin-right: calc(100vw*(15 /1320));

    @media screen and (max-width: 1320px) {
        height: calc(100vw*(24/1320));
    }

    @media screen and (max-width: 930px) {
        height: calc(100vw*(24/428));
    }
`
const ChkBox = styled.input`
    position: absolute;
    width: 1px; height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;

    & + label::after {
        content: '';
        position: absolute; top: 50%; left: 0;
        transform: translateY(-50%);
        width: calc(100vw*(20/1920)); height: calc(100vw*(20/1920));
        background: #fff;
        border: calc(100vw*(2/1920)) solid #000;
        border-radius: 100%;
    }

    :checked + label::after {
        display: none;
    }

    @media screen and (max-width: 1320px) {

        & + label::after {
            width: calc(100vw*(20/1320)); height: calc(100vw*(20/1320));
            /* border: calc(100vw*(20/1320)) solid #000; */
        }

        :checked + label::after {
            width: calc(100vw*(20/1320)); height: calc(100vw*(20/1320));
            border: calc(100vw*(2/1320)) solid #000;
        }
    }

    @media screen and (max-width: 930px) {

        & + label::after {
            width: calc(100vw*(18/428)); height: calc(100vw*(18/428));
            border: calc(100vw*(2/428)) solid #000;
        }

        :checked + label::after {
            width: calc(100vw*(18/428)); height: calc(100vw*(18/428));
            border: calc(100vw*(2/428)) solid #000;
        }
    }
`
const ChkBoxAllLabel = styled.label`
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 42px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    &::before {
        content: '';
        position: absolute; top: 50%; left: 0;
    transform: translateY(-50%);
        width: 20px; height: 20px;
        background: #000;
        border: 2px solid #000;
        border-radius: 100%;
        background: #000 url(${IconCheckbox}) no-repeat center/14px;
    }

    @media screen and (max-width: 1320px) {
        padding-left: calc(100vw*(42/1320));
        &&::before {
            width: calc(100vw*(20/1320)); height: calc(100vw*(20/1320));
            border: calc(100vw*(2/1320)) solid #000;
            background: #000 url(${IconCheckbox}) no-repeat center/calc(100vw*(14/1320));
        }
    }

    @media screen and (max-width: 930px) {
        padding-left: calc(100vw*(30/428));
        &&::before {
            width: calc(100vw*(18/428)); height: calc(100vw*(18/428));
            border: calc(100vw*(2/428)) solid #000;
            background: #000 url(${IconCheckbox}) no-repeat center/calc(100vw*(14/428));
        }
    }
`
const ChkBoxLabel = styled.label`
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    &&::before {
        content: '';
        position: absolute; top: 50%; left: 0;
        transform: translateY(-50%);
        width: calc(100vw*(20/1920)); height: calc(100vw*(20/1920));
        background: #000;
        border: calc(100vw*(2/1920)) solid #000;
        border-radius: 100%;
        background: #000 url(${IconCheckbox}) no-repeat center/calc(100vw*(14/1920));
    }

    @media screen and (max-width: 1320px) {
        &&::before {
            width: calc(100vw*(20/1320)); height: calc(100vw*(20/1320));
            border: calc(100vw*(2/1320)) solid #000;
            background: #000 url(${IconCheckbox}) no-repeat center/calc(100vw*(14/1320));
        }
    }

    @media screen and (max-width: 930px) {
        &&::before {
            width: calc(100vw*(18/428)); height: calc(100vw*(18/428));
            border: calc(100vw*(2/428)) solid #000;
            background: #000 url(${IconCheckbox}) no-repeat center/calc(100vw*(14/428));
        }
    }
`