import styled, { css, keyframes } from "styled-components";
import {            
    pc,
    mobil,
    mquery,
    Media,
    Px,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
} from '../common/mediaStlye';

export const IssueSlideWrapper = styled.div`
    position: relative;

    & .swiper-slide {
        ${[
            Width(330, mobil),
            Height(329, mobil),
        ].join("")}
    }
`

export const IssueItemContainer = styled.div`
    margin: 0 auto;     
    height: calc(100vw * (355 / 1920));
    & .swiper-container {
        width: calc(100vw * (1090 / 1920));
    }


    & .swiper-slide {   
        display: flex;
        justify-content: space-between;     
    }

    @media ${props => props.theme.container} {
        margin: 0;

        & .swiper-container {
           /* height: 100%; */
           ${[
            Width(380, mobil),
            Height(329, mobil)
        ].join("")}
        }
    }
`
export const Item = styled.div`
    width: calc(100vw * (357 / 1920));    
    height: calc(100vw * (355 / 1920));
    overflow: hidden;    
    position: relative;
    cursor: pointer;
    &:hover .item_head {
        opacity: 1;
    }

    @media ${props => props.theme.container} {
        ${[
            Width(330, mobil),
            Height(329, mobil),
        ].join("")}
    }
`

export const ItemImg = styled.div`
    width: 100%;
    height: 100%;
    /* height: calc(100vw * (355 / 1920)); */
    line-height: 0;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media ${props => props.theme.container} {
        width: 100%;
        height: 100%;
    }
`

export const ItemDesc = styled.div`
    position: absolute;
    left: 0;
    bottom:0;
    width: 100%;
    padding-top: calc(100vw * (22/ 1920));
    padding-left: calc(100vw * (24/ 1920));
    /* padding: calc(100vw * (25/ 1920)) calc(100vw * (52 / 1920)) calc(100vw * (25/ 1920)) calc(100vw * (30 / 1920)); */
    height: calc(100vw * ( 114 / 1920));
    background-color: rgba(34,34,34,0.9);
    opacity: 0;
    /* transform: translateY(100%); */
    transition: all 0.3s ease;

    & > h4 {
        font-size: calc(100vw * (18 / 1920));
        padding-bottom: calc(100vw * (5 / 1920));
        color: #fff;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    & > p {
        font-size: calc(100vw * (13 / 1920));
        padding-right: calc(100vw * (30 / 1920));
        line-height: 1.2;
        color: #fff;
        white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
    }
    & p {
        color: #fff;
    }

    @media ${props => props.theme.container} {
        ${Height(105, mobil)}
        ${Padding(20, 70, 26, 21, mobil)}

        & > h4 {
            ${FontSize(16, mobil)}
            ${PB(8, mobil)}
        }

        & > p {
            ${FontSize(12, mobil)}
            -webkit-line-clamp: 1;
            line-height: unset;
        }
    }
`

export const PrevButton = styled.div`
position: absolute;
top: 50%;
left: 0;
transform: translateY(-50%);
cursor: pointer;
width: calc(100vw * (24 / 1920));
& > img {
    width: 100%;
}
`
export const NextButton = styled.div`
position: absolute;
top: 50%;
right: 0;
transform: translateY(-50%);
cursor: pointer;
width: calc(100vw * (24 / 1920));
& > img {
    width: 100%;
}
`