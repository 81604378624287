import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile } from "../../MediaQuery";
import styled from "styled-components";

// img
import IconBack from "../../img/icon/icon_header_back.svg";
import IconCancel from "../../img/icon/icon_myhaeder_cancle.svg";
import IconMoHome from "../../img/icon/icon_mo_top_home.svg";

export default function MyPageHeader({ title, prevBtn, deleteBtn, prevFunc, deleteFunc = null }) {
  const history = useHistory();
  const goBackFunc = () => {
    history.goBack();
  };

  return (
    <Container>
      <Mobile>
        <Wrap>
          {/* 이전버튼 */}
          {prevBtn && (
            <PageButton
              onClick={() => {
                prevFunc ? prevFunc() : goBackFunc();
              }}
            >
              <img src={IconBack} alt="back-img" />
            </PageButton>
          )}
          <p>{title}</p>
          <HomeLink to={`/store`}>
            <img src={IconMoHome} alt="home-icon" />
          </HomeLink>
          {/* 삭제 버튼 */}
          {deleteBtn && (
            <DeleteButton
              onClick={deleteFunc !== null ? deleteFunc : console.log("delete func error")}
            >
              <img src={IconCancel} alt="deleteButton" />
            </DeleteButton>
          )}
        </Wrap>
      </Mobile>
    </Container>
  );
}
const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 100;
  background-color: #fff;

  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
  }
`;
const Wrap = styled.div`
  background-color: #fff;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;

  & p {
    color: #333;
    font-weight: 500;
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
    padding: 0 calc(100vw * (10 / 428));
    & p {
      font-size: calc(100vw * (16 / 428));
    }
  }
`;

const PageButton = styled.div`
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;
const HomeLink = styled(Link)`
  box-sizing: border-box;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;
const DeleteButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 930px) {
    right: calc(100vw * (13.5 / 428));
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
    background-color: #fff;
  }
`;
