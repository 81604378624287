//react
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Mobile } from "../../../MediaQuery";

// controller
import serverController from "../../server/serverController";

//css
import styled from "styled-components";

// img
import IconDelete from "../../img/icon/icon_keyword_delete.svg";

// component
import RecentProductList from "../../component/product/RecentProductList";

export default function StoreSearch(props) {
  const { recentList, setRecentList, setLoginPop } = props;
  const [recentProductList, setRecentProductList] = useState([]);

  const getProductsRecently = () => {
    serverController.connectFetchController("products/recently", "GET", null, (res) => {
      if (res?.result === 1) {
        setRecentProductList(res?.list.reverse());
      }
    });
  };

  // delete one recent item
  const deleteRecentItem = (idx) => {
    let recentListArr = recentList;
    recentListArr.splice(idx, 1);
    setRecentList([...recentList]);
    localStorage.setItem("recentList", JSON.stringify([...recentList]));
  };

  // delete all recent items
  const deleteAllRecentItem = () => {
    setRecentList([]);
    localStorage.removeItem("recentList");
  };

  useEffect(() => {
    getProductsRecently();
    setRecentList(JSON.parse(localStorage.getItem("recentList")) || []);
  }, []);

  return (
    <Container>
      <Mobile>
        <StoreSearchWrap>
          <>
            {recentList.length > 0 && (
              <RecentKeywordWrap>
                <BestRankTitleBox>
                  <BestRankTitle>최근 검색어</BestRankTitle>
                  <BestRankAllDeleteButton type="button" onClick={deleteAllRecentItem}>
                    전체삭제
                  </BestRankAllDeleteButton>
                </BestRankTitleBox>
                <RecentKeywordListArea>
                  {recentList.map((recentItem, idx) => {
                    const recentKeywordCompItems = {
                      recentItem,
                      deleteRecentItem,
                      idx,
                    };
                    return (
                      <RecentKeywordComp
                        key={`${recentItem}-recentItem-${idx}`}
                        {...recentKeywordCompItems}
                      />
                    );
                  })}
                </RecentKeywordListArea>
              </RecentKeywordWrap>
            )}

            {recentProductList?.length > 0 && (
              <RecentProductList recentProductList={recentProductList} setLoginPop={setLoginPop} />
            )}
          </>
        </StoreSearchWrap>
      </Mobile>
    </Container>
  );
}
const RecentKeywordComp = (props) => {
  const { recentItem, deleteRecentItem, idx } = props;
  const history = useHistory();
  const [keywordTouch, setKeywordTouch] = useState(false);
  return (
    <RecentKeywordList>
      <RecentKeywordBox
        onTouchStart={() => {
          setKeywordTouch(true);
        }}
        onTouchEnd={() => {
          setKeywordTouch(false);
        }}
        keywordTouch={keywordTouch}
      >
        <RecentKeywordText
          onClick={() => {
            history.push(`/store/search?keyword=${recentItem}`);
          }}
        >
          {recentItem}
        </RecentKeywordText>
        <RecentKeywordDeleteButton
          type="button"
          onClick={() => {
            deleteRecentItem(idx);
          }}
        />
      </RecentKeywordBox>
    </RecentKeywordList>
  );
};
const Container = styled.div`
  width: 100%;
  color: #333333;
  font-size: 16px;
  @media screen and (max-width: 930px) {
    /* padding-top: calc(100vw * (67 / 428)); */
  }
`;
const StoreSearchWrap = styled.div`
  padding: 80px 0 180px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (80 / 1320)) 0 calc(100vw * (180 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (15 / 428)) 0 0;
  }
`;
const BestRankTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (30 / 428));
    padding-bottom: calc(100vw * (11 / 428));
  }
`;
const BestRankTitle = styled.span`
  color: #333;
  font-weight: 500;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;

// 최근 검색어
const RecentKeywordWrap = styled.section`
  margin: 0 auto;
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding-bottom: calc(100vw * (20 / 428));
  }
`;
const RecentKeywordListArea = styled.ul`
  display: inline-flex;
  flex-wrap: wrap;
  @media screen and (max-width: 930px) {
    row-gap: calc(100vw * (10 / 428));
  }
`;
const RecentKeywordList = styled.li`
  @media screen and (max-width: 930px) {
    margin-right: calc(100vw * (10 / 428));
  }
`;
const RecentKeywordBox = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  transition: ease 0.3s all;
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (8 / 428)) calc(100vw * (5 / 428)) calc(100vw * (8 / 428))
      calc(100vw * (15 / 428));
    ${({ keywordTouch }) =>
      keywordTouch &&
      `
      background-color : #cdcdcd
      `}
  }
`;
const RecentKeywordText = styled.span`
  color: #333;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (17 / 428));
  }
`;
const RecentKeywordDeleteButton = styled.button`
  background: url(${IconDelete}) no-repeat center/contain;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
    margin-left: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
    margin-left: calc(100vw * (10 / 428));
  }
`;
const BestRankAllDeleteButton = styled.button`
  color: #777777;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    line-height: calc(100vw * (19 / 428));
  }
`;
