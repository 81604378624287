import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import InquiryWrite from "../../component/service/InquiryWrite";
import Footer from "../../component/common/StoreFooter";
import FooterMenu from "../../component/common/FooterMenu";
import FixMenu from "../../component/common/FixMenu";

export default function StoreInquiryWritePage() {
  return (
    <>
      <Header search={true} />
      <InquiryWrite title="1:1문의" rank={false} isOne />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
      <FixMenu />
    </>
  );
}
