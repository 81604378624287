//react
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";

//css
import styled from "styled-components";

export default function Modal({
  title,
  setIsModal,
  action,
  btn,
  btnText = "확인",
}) {
  return (
    <Container>
      <Background
        onClick={() => {
          setIsModal(false);
        }}
      />
      <Content>
        <TitleCont>
          <Title>{title}</Title>
        </TitleCont>
        <BtnWrap>
          <Btn
            onClick={() => {
              setIsModal(false);
            }}
          >
            닫기
          </Btn>
          {!btn && (
            <BtnBlue
              onClick={() => {
                setIsModal(false);
                action();
              }}
            >
              {btnText}
            </BtnBlue>
          )}
        </BtnWrap>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;
// 컨텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  border-radius: 11px;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 100;
  padding: 50px;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (500 / 1320));
    border-radius: calc(100vw * (11 / 1320));
    padding: calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (100 / 428)));
    border-radius: calc(100vw * (11 / 428));
    padding: calc(100vw * (50 / 428)) calc(100vw * (25 / 428));
  }
`;
const TitleCont = styled.div`
  padding: 25px 25px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (25 / 1320)) calc(100vw * (25 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #000;
  text-align: center;
  padding-bottom: 60px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    padding-bottom: calc(100vw * (60 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    padding-bottom: calc(100vw * (30 / 428));
    word-break: keep-all;
  }
`;
const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Btn = styled.p`
  /* width: 100px; */
  cursor: pointer;
  padding: 12px 30px;
  background: #f1f1f1;
  border-radius: 5px;
  margin: 0 10px;
  text-align: center;

  @media screen and (max-width: 1320px) {
    /* width: calc(100vw * (100 / 1320)); */
    padding: calc(100vw * (12 / 1320)) calc(100vw * (30 / 1320));
    border-radius: calc(100vw * (5 / 1320));
    margin: 0 calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    /* width: calc(100vw * (100 / 428)); */
    padding: calc(100vw * (10 / 428)) calc(100vw * (30 / 428));
    border-radius: calc(100vw * (5 / 428));
    margin: 0 calc(100vw * (10 / 428));
  }
`;
const BtnBlue = styled(Btn)`
  background: #000;
  color: #fff;
`;
