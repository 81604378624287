import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";
import GrayZone from "../../common/GrayZone";

export default function MyDeliveryTracking() {

  return (
    <Container>
      <Mobile>
        <Wrap>
          <ListArea>
            <ListBox>
              <List>
                <Title>받는사람</Title>
                <Text>김왈라</Text>
              </List>
              <List>
                <Title>휴대폰번호</Title>
                <Text>01012345678</Text>
              </List>
              <List>
                <Title>주소</Title>
                <Text>[06159] 서울 강남구 테헤란로 425(삼성동, 신일빌딩), 신일빌딩 11층</Text>
              </List>
              <List>
                <Title>배송메모</Title>
                <Text>부재시 문앞에 놓아주세요.</Text>
              </List>
            </ListBox>
            <ListBox>
              <List>
                <Title>택배사</Title>
                <Text>CJ대한통운</Text>
              </List>
              <List>
                <Title>운송장번호</Title>
                <Text>123456789012</Text>
              </List>
            </ListBox>
          </ListArea>
          <GrayZone/>
          <TableArea>
            <Table>
              <TableThead>
                  <tr>
                      <th>처리일시</th>
                      <th>현재위치</th>
                      <th>상태</th>
                  </tr>
              </TableThead>
              <TableTbody>
                  <tr>
                      <td>
                        <p>2022-04-05</p>
                        <p>15:45:30</p>
                      </td>
                      <td>경기화성중앙</td>
                      <td>배달완료</td>
                  </tr>
                  <tr>
                      <td>
                        <p>2022-04-05</p>
                        <p>15:45:30</p>
                      </td>
                      <td>경기화성중앙</td>
                      <td>배달중</td>
                  </tr>
                  <tr>
                      <td>
                        <p>2022-04-05</p>
                        <p>15:45:30</p>
                      </td>
                      <td>화성ASub</td>
                      <td>배송중(출고)</td>
                  </tr>
                  <tr>
                      <td>
                        <p>2022-04-05</p>
                        <p>15:45:30</p>
                      </td>
                      <td>화성ASub</td>
                      <td>배송중(입고)</td>
                  </tr>
                  <tr>
                      <td>
                        <p>2022-04-05</p>
                        <p>15:45:30</p>
                      </td>
                      <td>전북진주신인후</td>
                      <td>집하</td>
                  </tr>
              </TableTbody>
            </Table>
            {/* 없을 때 */}
            {/* <NoneText>
              조회기간이 만료되어<br/>배송현황이 확인되지 않습니다.
            </NoneText> */}
          </TableArea>
        </Wrap>
      </Mobile>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
`;

const Wrap = styled.div`

  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (140 / 428));
  }
`;
const ListArea = styled.div`

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (10 / 428)) 0 calc(100vw * (5 / 428));
  }
`;
const ListBox = styled.ul`
  margin: 0 auto;
  &:not(:last-child){
    border-bottom: 1px solid #eee;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const List = styled.li`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media screen and (max-width: 930px) {
    &:not(:last-child){
      margin-bottom: calc(100vw * (10 / 428));
    }
  }
`;
const Title = styled.p`
  line-height: 1.6;
  color: #777;
  font-weight: 400;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const Text = styled(Title)`
  color: #333;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (282 / 428));
  }
`;

const TableArea = styled.div`

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const Table = styled.table`
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const TableThead = styled.thead`
  & th {
    width: calc(100% / 3);
    line-height: 1.6;
    color: #333;
    font-weight: 400;
    text-align: center;
    background-color: #F5F5F5;
  }

  @media screen and (max-width: 930px) {
    & th {
      font-size: calc(100vw * (15 / 428));
      padding: calc(100vw * (13 / 428)) 0;
    }
  }
`;
const TableTbody = styled.tbody`
  & tr{
    border-bottom: 1px solid #ddd;
  }
  & td {
    width: calc(100% / 3);
    line-height: 1.6;
    color: #333;
    font-weight: 400;
    text-align: center;
  }

  @media screen and (max-width: 930px) {
    & td {
      font-size: calc(100vw * (15 / 428));
      padding: calc(100vw * (12 / 428)) 0;
    }
  }
`;

// 없을 때
const NoneText = styled.p`
  line-height: 1.6;
  color: #999;
  font-weight: 400;
  text-align: center;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (190 / 428)) 0;
  }
`;