//react
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import { useState, useEffect } from "react";
import serverController from "../../../server/serverController";
import styled from "styled-components";
import { imgURL } from "../../../server/appInfoController";

import Pagination from "../../common/pagination/Pagination";
import MypageReviewModal from "../../common/modal/MypageReviewModal";
import Modal from "../../common/modal/Modal";
import DeleteModal from "../../common/modal/DeleteModal";

export default function MypageReview() {
  const history = useHistory();
  const [reviewList, setReviewList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [current, setCurrent] = useState({});

  const getReviewList = async () => {
    await serverController.connectFetchController(
      `review/user?page=${page}&limit=20`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setReviewList([...res.reviewList]);
          setPageData(res?.pagination);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getReviewList();
  }, [editModal, page]);

  const showEdit = (value, index) => {
    setEditModal(true);
    setCurrent((prev) => ({
      index,
      src: imgURL + value.prd_thumbnail,
      ...value,
    }));
  };

  const showDelete = (value, index) => {
    setDeleteModal(true);
    setCurrent((prev) => ({
      index,
      src: imgURL + value.prd_thumbnail,
      ...value,
    }));
  };

  const deleteAction = async () => {
    console.log("delete review fetch");
    const formData = new FormData();
    formData.append("rev_id", current?.rev_id);

    const res = await serverController.connectFetchController(
      `review/product`,
      "DELETE",
      formData,
      (res) => {
        if (res?.result === 1) {
          getReviewList();
        }
      }
    );
    return res;
  };

  return (
    <Container>
      <TitleWrap>
        <Title>내가 작성한 리뷰</Title>
      </TitleWrap>
      <ContentBox>
        {reviewList.map((value, index) => {
          return (
            <Content>
              <Mobile>
                <Day>
                  {value.rev_date.substring(0, 4)}-{value.rev_date.substring(5, 7)}-
                  {value.rev_date.substring(8, 10)}
                </Day>
              </Mobile>
              <Cont>
                <ContFlex>
                  <ProductImg src={imgURL + value.prd_thumbnail} />
                  <ProductTextWrap
                    onClick={() => {
                      history.push(`/store/detail/${value.prd_id}`);
                    }}
                  >
                    <Brand>{value.bc_title || "(BRAND)"}</Brand>
                    <ProductName>{value.prd_name}</ProductName>
                    {/* <Option>옵션 (+{value.option}원)</Option> */}
                  </ProductTextWrap>
                </ContFlex>

                {value.rev_image && (
                  <UploadImageBox key={index + "imageBox"}>
                    <UploadImage alt="previewImg" src={`${imgURL}${value.rev_image}`} />
                  </UploadImageBox>
                )}
                <ReviewSatisfaction>
                  {value.satisfaction === 0 ? "만족해요" : "별로예요"}
                </ReviewSatisfaction>
                <Review>{value.rev_content}</Review>
              </Cont>
              <PC>
                <Day>
                  {value.rev_date.substring(0, 4)}-{value.rev_date.substring(5, 7)}-
                  {value.rev_date.substring(8, 10)}
                </Day>
              </PC>
              <BtnBox>
                <Btn onClick={() => showEdit(value, index)}>수정</Btn>
                <Btn onClick={() => showDelete(value, index)}>삭제</Btn>
              </BtnBox>
            </Content>
          );
        })}
      </ContentBox>
      {reviewList.length > 0 && <Pagination pageData={pageData} page={page} setPage={setPage} />}
      {editModal && <MypageReviewModal setIsModal={setEditModal} current={current} />}
      {deleteModal && (
        <Modal
          setIsModal={setDeleteModal}
          action={deleteAction}
          current={current}
          title="리뷰를 삭제하시겠습니까?"
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 1300px;
  margin: 0 auto;
  padding: 180px 0 200px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    padding: calc(100vw * (35 / 428)) 0 calc(100vw * (150 / 428));
  }
`;
const TitleWrap = styled.div`
  padding: 25px 0;
  border-bottom: 1px solid #000;
  margin-bottom: 15px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (25 / 1320));
    padding: calc(100vw * (25 / 1320)) 0 0;
    margin-bottom: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    display: block;
    padding: calc(100vw * (25 / 428)) 0 0;
    margin-bottom: calc(100vw * (15 / 428));
    padding: calc(100vw * (25 / 428)) 0;
  }
`;
const Title = styled.h2`
  font-size: 24px;
  color: #000;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    text-align: center;
  }
`;
// main
const ContentBox = styled.div`
  width: 100%;
  border-bottom: 1px solid #000;
  padding-bottom: 15px;

  @media screen and (max-width: 1320px) {
    border-bottom: calc(100vw * (1 / 1320)) solid #000;
    padding-bottom: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    border-bottom: calc(100vw * (1 / 428)) solid #000;
    padding-bottom: calc(100vw * (15 / 428));
  }
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px 20px;
  border-bottom: 1px solid #d9d9d9;
  &:last-child {
    border-bottom: none;
  }

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) calc(100vw * (30 / 1320)) calc(100vw * (20 / 1320));
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 930px) {
    display: block;
    padding: calc(100vw * (20 / 428)) 0;
    border-bottom: calc(100vw * (1 / 428)) solid #d9d9d9;
  }
`;

const Cont = styled.div`
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;

const ContFlex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (10 / 428));
  }
`;

const ProductImg = styled.img`
  width: 86px;
  height: 86px;
  object-fit: cover;
  margin-right: 10px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (86 / 1320));
    height: calc(100vw * (86 / 1320));
    margin-right: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (86 / 428));
    height: calc(100vw * (86 / 428));
    margin-right: calc(100vw * (10 / 428));
  }
`;
const Brand = styled.div`
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin-bottom: 9px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    margin-bottom: calc(100vw * (9 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (9 / 428));
  }
`;
const ProductName = styled.div`
  display: block;
  cursor: pointer;
  width: 700px;
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin-bottom: 7px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (700 / 1320));
    font-size: calc(100vw * (18 / 1320));
    margin-bottom: calc(100vw * (7 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (7 / 428));
  }
`;
const Option = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #969696;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const Day = styled.p`
  width: 190px;
  text-align: center;
  font-size: 18px;
  color: #000;
  font-weight: 500;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (190 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    text-align: left;
    width: calc(100vw * (190 / 428));
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (20 / 428));
  }
`;
const Review = styled.p`
  font-size: 14px;
  color: #6b6b6b;
  font-weight: 500;
  width: 800px;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    width: calc(100vw * (800 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    width: 100%;
    padding: calc(100vw * (15 / 428)) 0;
  }
`;
const BtnBox = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (250 / 1320));
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    justify-content: flex-start;
    width: 100%;
    font-size: calc(100vw * (14 / 428));
  }
`;
const Btn = styled.div`
  cursor: pointer;
  width: 104px;
  height: 36px;
  border-radius: 10px;
  border: 1px solid #dddddd;
  line-height: 36px;
  color: #000;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (104 / 1320));
    height: calc(100vw * (36 / 1320));
    border-radius: calc(100vw * (10 / 1320));
    border: calc(100vw * (1 / 1320)) solid #dddddd;
    line-height: calc(100vw * (36 / 1320));
    margin-right: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    height: calc(100vw * (35 / 428));
    border-radius: calc(100vw * (10 / 428));
    border: calc(100vw * (1 / 428)) solid #dddddd;
    line-height: calc(100vw * (35 / 428));
    margin-right: calc(100vw * (20 / 428));
    margin-top: calc(100vw * (15 / 428));
  }
`;
// mobile

const ProductTextWrap = styled.div`
  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (86 / 428)));
  }
`;
const ReviewSatisfaction = styled.div`
  font-size: 14px;
  color: #6b6b6b;
  font-weight: 500;
  width: 120px;
  white-space: normal;
  display: inline-block;

  overflow: hidden;
  line-height: 1.2;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    padding: calc(100vw * (15 / 428)) 0;
  }
`;
// upload image
const UploadImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const UploadImageBox = styled.div`
  cursor: pointer;
  width: 115px;
  height: 115px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  margin-right: 4px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (115 / 1320));
    height: calc(100vw * (115 / 1320));
    border-radius: calc(100vw * (5 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (115 / 428));
    height: calc(100vw * (115 / 428));
    border-radius: calc(100vw * (5 / 428));
  }
`;
