//modal
const body = document.querySelector("body");

function modalOpen() {
  body.style.overflow = "hidden";
  body.style.height = "100vh";
}
function modalClosed() {
  body.style.overflow = "auto";
  body.style.height = "auto";
}

export default { modalOpen, modalClosed };
