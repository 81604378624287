import styled, { css } from "styled-components";
import {            
    pc,
    MQ,
    mquery,    
    mobil,
    Media,    
    Px,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,    
} from '../common/mediaStlye';

export const CategoryWrapper = styled.div`
    padding-bottom: calc(100vw * (300 / 1920));
`

export const Wrapper = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const LayOut = styled.div`
    width: calc(100vw * (1316 / 1920));
    @media ${props => props.theme.container} {
        width: 100%;
        ${Padding2(0, 25, mobil)}
    }
`

export const ContentHeading = styled.h2`
    font-size: calc(100vw * (35 / 1920));
    color: #222;
    text-align: center;

    @media ${props => props.theme.container} {
        ${[
            MB(58, mobil),
            FontSize(22, mobil)
        ].join("")}
    }
`

export const FashionsContainer = styled.div`
    padding: calc(100vw * (70 / 1920)) 0 calc(100vw * (50 / 1920));
    display: flex;
    justify-content: flex-start;
    gap: ${Px(10, pc)};
    align-items: center;
    flex-wrap: wrap;

    & .swiper-container {
        overflow: visible;
        
    }

    & .swiper-slide {
        width: 100%;
    }
    @media ${props => props.theme.container} {
        ${[
            MB(66, mobil),            
        ].join("")}
    }
`

export const FashionItem = styled.div`

`
export const Item = styled.div`
    width: calc(100vw * (428 / 1920));
    height: calc(100vw * (413 / 1920));
    /* margin-bottom: calc(100vw * (20 / 1920)); */
    overflow: hidden;    
    position: relative;
    cursor: pointer;
    
    &:hover .item_head {
        opacity: 1;
    }

    @media ${props => props.theme.container} {
        ${[
            MB(0, mobil),
            Width(350, mobil),
            Height(358, mobil),
        ].join("")}
    }
`

export const ItemImg = styled.div`
    width: 100%;
    height: 100%;
    line-height: 0;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

export const ItemDesc = styled.div`
    position: absolute;
    left: 0;
    bottom:0;
    width: 100%;
    padding-top: calc(100vw * (22/ 1920));
    padding-left: calc(100vw * (24/ 1920));
    padding: calc(100vw * (25/ 1920)) calc(100vw * (52 / 1920)) calc(100vw * (25/ 1920)) calc(100vw * (30 / 1920));
    height: calc(100vw * (201 / 1920));
    background-color: rgba(34,34,34,0.9);
    opacity: 0;    
    transition: all 0.3s ease;

    & > div {
        overflow: hidden;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    & p {
        color: #fff;
    }

    & > em {
        display: block;
        font-size: calc(100vw * (13 / 1920));
        color: #FFF200;
        padding-bottom: calc(100vw * (11 / 1920));
    }

    & > h4 {
        font-size: calc(100vw * (25 / 1920));
        font-weight: 900;
        padding-bottom: calc(100vw * (19 / 1920));
        color: #fff;
    }

    & > p {
        font-size: calc(100vw * (14 / 1920));
        padding-right: calc(100vw * (30 / 1920));
        line-height: 1.5;
        color: #fff;
    }

    @media ${props => props.theme.container} {
        ${[
            MB(0, mobil),            
            Height(152, mobil),
            Padding(23, 44 ,31, 31, mobil)
        ].join("")}

        & > em {
            ${[
                FontSize(13, mobil),            
                PB(4.5, mobil),
            ].join("")}
        }
        & > h4 {
            ${[
                FontSize(20, mobil),            
                PB(9.9, mobil),
            ].join("")}
        }
        & > p {
            ${[
                FontSize(15, mobil),            
                PR(0, mobil),
            ].join("")}
        }
    }
`
export const MoreButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: calc(100vw * (188 / 1920));
    height: calc(100vw * (51 / 1920));
    font-size: calc(100vw * (18 / 1920));
    font-weight: 900;
    border: 2px solid #222;
    border-radius: calc(100vw * (37 / 1920)); 
    
    @media ${props => props.theme.container} {        
        ${[
            Width(163, mobil),
            Height(44, mobil),
            FontSize(16, mobil),            
        ].join("")}
        line-height: 0;
        border-radius: ${Px(37, mobil)};
    }
`

// 카테고리 메인, CategoryMain
export const BannerSlide = styled.div`
    display: flex;    
    background-color: #FCFCFC;
    cursor: pointer;
    ${({ reverse }) => reverse && css`
    flex-direction: row-reverse;
    `}
    @media ${props => props.theme.container} {
        flex-direction: column;
    }
`

export const BannerText = styled.div`
    flex: 1;    
    padding-top: calc(100vw * (223 / 1920));
    padding-left: calc(100vw * (${({reverse}) => reverse ? 60 : 350} / 1920));
    padding-right: calc(100vw * (${({reverse}) => reverse ? 420 : 111} / 1920));

    & > h3 {
        padding-bottom: calc(100vw * (20 / 1920));
        font-size: calc(100vw * (40 / 1920));
        font-weight: 900;
        color: #222;
    }

    & > p {
        font-size: calc(100vw * (20 / 1920));  
        color: #222;      
    }

    
    @media ${props => props.theme.container} {
        ${Padding(16, 48, 34, 25, mobil)}

        & h3 {
            ${[
                FontSize(23, mobil),
                PB(14, mobil)
            ].join("")}
        }

        & p {
            ${[
                FontSize(14, mobil),                
            ].join("")}
            line-height: 1.5;
        }
    }
`

export const BannerImg = styled.div`
    width: calc(100vw * (1065 / 1920));
    height: calc(100vw * (738 / 1920));
    line-height: 0;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
    }

    @media ${props => props.theme.container} {
        width: 100%;
        ${Height(293, mobil)}
    }
`
// CategoryMultipleContents
export const MultipleWrapper = styled(Wrapper)`
    padding-bottom: calc(100vw * (357 / 1920));
    margin-bottom: calc(100vw * (113 / 1920));
    border-bottom: 7px solid #F8F8F8;
`

export const ContentHeading2 = styled(ContentHeading)`
    padding-left: calc(100vw * (40 / 1920));
    margin-bottom: calc(100vw * (43 / 1920));
    text-align: left;

    @media ${props => props.theme.container} {
        ${[
            MB(58, mobil),
            FontSize(22, mobil)
        ].join("")}
    }
`
export const MultipleContentSlider = styled.div`
    width: 100%;
    /* height: calc(100vw * (1189 / 1920)); */
    @media ${props => props.theme.container} {
        & .swiper-slide {

        }
    }

`

export const MultipleSlide = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    @media ${props => props.theme.container} {
        flex-direction: column;
    }
`

export const MultipleLeft = styled.div`
    width: calc(100vw * (761 / 1920));
    height: 100%;
`

export const MultipleRight = styled.div`
    flex: 1;
    padding-left: calc(100vw * (16/ 1920));
    height: 100%;
`

export const MultipleContentImg = styled.div`
    display: flex;
    ${({col}) => col && css`
    flex-direction: column;    
    `};

    @media ${props => props.theme.container} {
        width: 100%;
        gap: ${Px(8, mobil)};
        ${Height(323, mobil)}
        ${MB(35, mobil)}
    }
`

export const MultipleProducts = styled.div`
    flex: 1;
    display: flex; 
    justify-content: flex-start;    
    align-items: center;
    gap: ${Px(10, pc)};
    padding-right: calc(100vw * (16 / 1920));
    ${({col}) => col && css`
    flex-direction: column;
    `};
    ${({right}) => right && css`
        padding: 0;       
    `}

    & > div {
        flex: ${({right}) => right ? `unset` : `1`};
        max-height: ${Px(206, pc)};
        width: ${({right}) => right ? `33.33%` : `100%`};
        
    }

    & > div:nth-child(2) {
        margin: ${({right}) => right ?`
        calc(100vw * (16 / 1920)) calc(100vw * (16 / 1920));
        `
        :`
        calc(100vw * (16 / 1920)) 0
        `}
    }

    & > div > img {
        width:100%;
        height:100%;
        object-fit:cover;
    }
    @media ${props => props.theme.container} {
        flex: none;
        row-gap: ${Px(7, mobil)};
        ${[
            Width(102, mobil),
        ].join("")}

        & > div {
            flex: 1;
            max-height: ${Px(103, mobil)};
            width: 100%;
        }
    }
`

export const MultipleThumbs = styled.div`
    width: calc(100vw * (539 / 1920));
    /* height:calc(100vw * (650 / 1920)); */

    & > img {
        width: 100%;
        height: 100%;
        object-fit:cover;
    }

    @media ${props => props.theme.container} {
        width: 100%;
        height: 100%;
        
        & img {

        }
    }
`

export const MultipleContentText = styled.div`
    flex: 1;
    padding: calc(100vw * (65 / 1920)) calc(100vw * (187 / 1920))  0 calc(100vw * (37 / 1920));
    height: calc(100vw * (541 / 1920));
    ${({right}) => right && css`
    padding: calc(100vw * (67 / 1920)) calc(100vw * (43 / 1920)) 0;
    height: calc(100vw * (360 / 1920));
    `}
    & h3 {
        font-size: calc(100vw * (22 / 1920));
        padding-bottom: calc(100vw * (20 / 1920));
        font-weight: 700;
    }

    & > p {
        padding-bottom: calc(100vw * (53 / 1920));
        font-size: calc(100vw * (16 / 1920));
        line-height:calc(100vw * (21 / 1920));
    }

    & > div {
        text-align: right;
    }

    & > .multiple_contents {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1;
    }

    & > .multiple_contents p {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.25;
    }

    @media ${props => props.theme.container} {
        padding: 0;

        & h3 {
            ${[
                FontSize(20, mobil),
                PB(14, mobil)
            ]}
        }

        & > p {
            ${FontSize(15, mobil)}
            line-height: 1.3;
        }

        & > div {
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            ${MB(50, mobil)}
        }
    }
`

export const MoreButton2 = styled(MoreButton)`
    display: inline-block;
    width: calc(100vw * (162 / 1920));
    height: calc(100vw * (44 / 1920));

    @media ${props => props.theme.container} {
        ${[
            Width(163, mobil),
            Height(44, mobil),
            FontSize(16, mobil)
        ].join("")}
        line-height: 0;
        border-radius: 30px;
    }
`

/* 드라마 패션 메인 */
export const DramaSlider = styled.div`
    flex: 1;
    width: 100%;
    @media ${props => props.theme.container} {
        ${MB(80, mobil)}
        height: calc(100vh * (493 / 1024));
        ${MB(350, mobil)}
    }
`

export const DramaSlide = styled.div`
    position: relative;
    display: flex;
    justify-content: center;    
    width: 100%;
    height: calc(100vw * (879 / 1920));
    @media ${props => props.theme.container} {
        height: auto;
        height: calc(100vh * (493 / 1024));
    }
`

export const DramaBackground = styled.div`
    position: absolute;
    left:0;
    top:0;
    z-index: -1;
    width:100%;
    height: calc(100vw * (660 / 1920));
    line-height: 0;
    filter: brightness(0.7);
    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media ${props => props.theme.container} {
        height: calc(100vh * (493 / 1024));
    }
`

export const DramaSlideContent = styled.div`
    width: calc(100vw * (1133 / 1920));
    height: 100%;
    padding-top: calc(100vw * (146 / 1920));
    @media ${props => props.theme.container} {
        width: 100%;
        ${Height(300, mobil)}
        ${PT(60, mobil)}
        ${MB(50, mobil)}
    }
`

export const DramaSlideText = styled.div`
    & > h3 {
        margin-bottom: calc(100vw * (24 / 1920));
        font-size: calc(100vw * (40 / 1920));
        color:#fff;
        font-weight: 900; 
    }

    & > div {
        ${PR(300, pc)}
        max-height: 100px;
        white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: calc(100vw * (65 / 1920));
    color: #fff;
    }

    & p {
        
        padding-right: 30%;
        color:#fff;
        font-size: calc(100vw * (20 / 1920));
    }

    @media ${props => props.theme.container} {
        ${Padding2(0, 25, mobil)}
        & h3 {
            ${[
                FontSize(25, mobil),
                MB(20, mobil),
                MT(125, mobil)
            ].join("")}
        }

        & p {
            ${[
                FontSize(15, mobil),
                MB(27, mobil)
            ].join("")}
        }
    }

`

export const DramaSlideProducts = styled.div`
    display: flex;
    justify-content:flex-start;
    gap: ${Px(10, pc)};
    align-items: center;
    padding-left: calc(100vw * (263 / 1920));
    ${MT(30, pc)}
    
    & > div {
        /* flex: 1; */
        width: 33%;
        height: calc(100vw * (394 / 1920));
        box-shadow: 1px 2px 10px rgba(0,0,0,0.4);
        cursor: pointer;;
    }

    & > div:nth-child(2) {
        /* margin: 0 calc(100vw * (27 / 1920)); */
    }

    & > div img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media ${props => props.theme.container} {
        ${PL(25, mobil)}         

        & > div {
            flex: 1;
            ${Width(184, mobil)}
            ${Height(266, mobil)}
            box-shadow: 1px 2px 10px rgba(0,0,0,0.4);
        }
        & .drama-thumb {
            ${Height(266, mobil)}
            
            box-shadow: 1px 2px 10px rgba(0,0,0,0.4);
        }

        & p {
            ${[
                FontSize(15, mobil),
                MB(27, mobil)
            ].join("")}
        }
    }

`

export const WhiteMoreBtn = styled(MoreButton)`
    display: flex;
    justify-content: center;
    margin: 0 auto 0 0;
    align-items: center;
    width: calc(100vw * (169 / 1920));
    height: calc(100vw * (46 / 1920));
    color:#fff;
    border:1px solid #fff;
    background: none;
    /* ${[
            Width(163, mobil),
            Height(44, mobil),
            FontSize(16, mobil),            
        ].join("")}
        line-height: 0;
        border-radius: ${Px(37, mobil)}; */
`

/* CategoryFaces */
export const FaceWrap = styled(Wrapper)`
    /* ${Margin2(100,0, pc)} */
    ${MB(227, pc)}
    ${MQ(mquery)} {
        
        ${MB(227, mobil)}
    }
`

export const FaceHeadings = styled.h2`
    font-size: calc(100vw * (30 / 1920));
    color: #222;
    font-weight: 900;
    text-align: left;
    text-transform: uppercase;

    @media screen and (max-width:${mquery}px) {
        ${FontSize(22, mobil)}
    }
`
export const FaceSlider = styled.div`
    padding: 0 calc(100vw * (98 / 1920));

    @media screen and (max-width:${mquery}px) {
        ${MB(100, mobil)}
        ${Padding2(0,0,mobil)}
    }
`

export const FaceSlide = styled.div`
    width: calc(100vw * (317 / 1920));

    @media screen and (max-width:${mquery}px) {
        ${Width(165, mobil)}    
        /* width: calc(100vw * (113 / 428)) !important; */

    }
`
export const Face = styled.div`
    overflow:hidden;
    border-radius:50%;
    margin-bottom: calc(100vw * (28 / 1920));
    width: calc(100vw * (317 / 1920));
    height: calc(100vw * (317 / 1920));
    line-height: 0;

    & > img {
        width: 100%;
        height:100%;
        object-fit: cover;
    }

    ${MQ(mquery)} {
        ${Width(160, mobil)}
        ${Height(160, mobil)}
    }
`

export const FaceText = styled.div`
   text-align: center;

   & > h4 {
       margin-bottom: calc(100vw * (4 / 1920));
       font-size: calc(100vw * (22 / 1920));
       color:#222;
       font-weight: 700;
   }

   & > p {
        font-size: calc(100vw * (14 / 1920));
        color:#A5A5A5;
   }

   ${MQ(mquery)} {
        & > p {
            font-size: calc(100vw * (14 / 428));
            color:#A5A5A5;
        }

        & > h4 {
            margin-bottom: calc(100vw * (4 / 428));
            font-size: calc(100vw * (22 / 428));
            color:#222;
            font-weight: 700;
        }

        & > p {
            font-size: calc(100vw * (14 / 428));
            color:#A5A5A5;
        }

    }
`

// CategoryMultipleMain
export const MultipleMainSlider = styled.div`
    width: 100%;
    height: calc(100vw * (738 / 1920));
    ${Media(mquery,[       
        Height(511, mobil),
        MB(152, mobil)
    ])}
`

export const MultipleMainSlide = styled.div`
    display: flex;
    justify-content:space-between;
    ${Media(mquery,[       
        Height(511, mobil)
    ])}
`

export const MultipleMainLeft = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-right: calc(100vw * (16 / 1920));
`

export const MultipleMainProducts = styled.div`

   & h4 {
    padding-left: calc(100vw * (22 / 1920));
    padding-top: calc(100vw * (75 / 1920));
    padding-bottom: calc(100vw * (13 / 1920));
    font-size: calc(100vw * (22 / 1920));
    font-weight: 900;
   }

   @media screen and (max-width: ${mquery}px) {
        & h4 {
            ${[
                FontSize(18, mobil),
                Padding(24,78,16,0, mobil)
            ]}            
            font-weight: 900;
        }
   }
    
`

export const MultipleMainProduct = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw * (362 / 1920));
    height: calc(100vw * (122 / 1920));
    padding: calc(100vw * (10 / 1920));
    margin-bottom: calc(100vw * (16 / 1920));
    border: 1px solid #d0d0d0;

    @media screen and (max-width: ${mquery}px) {
        ${[
            Width(117, mobil),
            Height(109, mobil),
            MB(6, mobil),
        ].join("")}
        flex-direction: column;
      
    }
 
`

export const MultipleMainProductImg = styled.div`
    width: calc(100vw * (146 / 1920));
    height: calc(100vw * (101 / 1920));
    line-height: 0;
    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media screen and (max-width: ${mquery}px) {
        ${[
            Width(104, mobil),
            Height(64, mobil),
            MT(7,mobil),
            MB(5, mobil),
        ].join("")}
        flex-direction: column;      
    }
`

export const MultipleMainProductDesc = styled.div`
    flex: 1;
    padding-left:calc(100vw * (35 / 1920)); 
    & > strong {
        display: block;
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
        max-width: calc(100vw * (180 / 1920));
        color: #101010;        
        padding-bottom: calc(100vw * (10 / 1920));
        font-size: calc(100vw * (22 / 1920));
    }

    & > p {
        overflow:hidden;
        text-overflow: ellipsis;
        /* white-space: nowrap;  */
        white-space: normal; 
        line-height: 1.2;
         height: 3.6em;
        color: #272727;
        font-size: calc(100vw * (14 / 1920));
    }
    @media screen and (max-width: ${mquery}px) {
        ${[           
            PL(0, mobil),
        ].join("")}
        
        & > strong {
            ${FontSize(13, mobil)}
            max-width: ${Px(80, mobil)};
            font-weight: 900;
        }
    }
`

export const MultipleMainText = styled.div`
    position: absolute;
    right: calc(100vw * (-275 / 1920));
    bottom:0;
    width: calc(100vw * (655 / 1920));
    height: calc(100vw * (224 / 1920));
    background-color: #fff;
    padding: calc(100vw * (32 / 1920)) calc(100vw * (60 / 1920)) 0 calc(100vw * (23 / 1920));

    & > h3 {
        margin-bottom: calc(100vw * (24 / 1920));
        font-size: calc(100vw * (40 / 1920)); 
        font-weight: 900;
    }

    & > p {
        font-size: calc(100vw * (20 / 1920));
        font-weight: 400;
        overflow:hidden;
        text-overflow: ellipsis;        
        white-space: normal; 
        line-height: 1.2;
         height: 4.8em;
    }
    @media screen and (max-width: ${mquery}px) {
        ${[           
            PL(0, mobil),      
            Width(275,mobil),      
            Height(108, mobil),
            Right(-155, mobil),
            Padding(16,10,20,13,mobil)
        ].join("")}        
        
        & > h3 {
            ${[           
                FontSize(18, mobil),
                MB(9, mobil)
            ].join("")}            
            font-weight: 900;
        }

        & > p {
            ${FontSize(13, mobil)}
            font-weight: 400;
            overflow:hidden;
            text-overflow: ellipsis;        
            white-space: normal; 
            line-height: 1.2;
            height: 2.2em;
        }
    }
`

export const MultipleMainRight = styled.div`
    width: calc(100vw * (1176 / 1920));
    max-height: calc(100vw * (738 / 1920));
    /* height: 100%; */
    line-height: 0;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
    }

    ${Media(mquery,[
        Width(280,mobil),
        Height(511, mobil),
        
    ])}
`

