import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Mobile, PC } from "../../../MediaQuery";
import serverController from "../../../server/serverController";

//css
import styled from "styled-components";

//img
import IconFileUpload from "../../../img/icon/icon_upload.svg";
import IconFileUploadCancel from "../../../img/icon/icon_upload_cancel.svg";
import IconCancel from "../../../img/icon/icon_myhaeder_cancle.svg";

//controller
import { imgURL } from "../../../server/appInfoController";

//component, page
import { MyPageButtonComp } from "../MyPageButtonComp";
import MyPageBottomModal from "../../../page/mypage/MyPageBottomModal";
import { InfoText } from "../../login/auth.elements";

const BtnList = [
  {
    text: "만족해요",
    value: 0,
  },
  {
    text: "별로예요",
    value: 1,
  },
];

// mypage bottom data
const myPageBottomTextList = [
  "포인트는 최초 작성한 리뷰를 기준으로 지급됩니다.",
  "사진 첨부 시 캡처, 도용, 유사 상품 촬영, 동일 상품 여부 식별이 불가능한 경우에는 등록이 거절되며 사유는 별도 안내되지 않습니다.",
  "상품과 무관한 내용이나 사진, 동일 문자 반복 등의 부적합한 리뷰는 사전 경고 없이 사진 및 포인트 회수될 수 있습니다.",
];

export default function ReviewFormModal(props) {
  const { title, setIsEditModal, pc = false, clickItem = {} } = props;
  const params = useParams();
  const mog_id = params.mog_id;
  const history = useHistory();

  const [product, setProduct] = useState(null);
  const [satisfaction, setSatisfaction] = useState(0); // 상품은 어떠셨나요 섹션
  const [reviewText, setReviewText] = useState("");
  const [imageArr, setImageArr] = useState([]);
  const [previewImageArr, setPreviewImageArr] = useState([]);

  const [photoSection, setPhotoSection] = useState(true);

  const permission = useSelector((state) => state.permissionData.permission.state);

  useEffect(() => {
    if (!mog_id) return setProduct(clickItem);
    serverController.connectFetchController(
      `payment/history/${mog_id}?orderType=order&dateType=year&page=1`,
      "GET",
      null,
      (res) => {
        setProduct(res.payment_list[0]);
      }
    );
  }, [mog_id]);

  const postReview = () => {
    const formData = new FormData();
    formData.append("mog_id", product.mog_id);
    formData.append("prd_id", product.prd_id);
    formData.append("mog_id", product.mog_id);
    formData.append("rev_content", reviewText);
    formData.append("satisfaction", satisfaction);
    if (imageArr.length > 0) {
      formData.append("rev_image_file", imageArr[0]);
    }

    serverController.connectFetchController(`review/product`, "POST", formData, (res) => {
      if (res.result !== 1) return alert("등록에 실패하였습니다. 다시 시도해주세요.");
      postMogId();
      setIsEditModal(false);
      history.push(`/store/mypage/review?tab=1&page=1`);
    });
  };

  // put Review
  const putReview = () => {
    const formData = new FormData();
    formData.append("rev_id", clickItem.rev_id);
    formData.append("rev_content", reviewText);
    formData.append("satisfaction", satisfaction);
    formData.append("mog_id", clickItem.mog_id);
    if (imageArr[0] !== "" && imageArr.length !== 0) {
      formData.append("rev_image_file", imageArr[0]);
    } else if (imageArr.length === 0) {
      formData.append("rev_image_file", "");
    }
    serverController.connectFetchController(`review/product`, "PUT", formData, (res) => {
      if (res.result === 1) {
        setIsEditModal(false);
        history.push(`/store/mypage/review?tab=1&page=1`);
      }
    });
  };

  const uploadImage = (e) => {
    let arr = [];
    for (var i = 0; e.target.files.length > i; i++) {
      arr.push(e.target.files[i]);
    }
    setImageArr(arr);
  };

  const handleImageUpload = (e) => {
    const fileArr = e.target.files;
    let fileArrUrl = [];
    let file = null;

    for (let i = 0; i < fileArr.length; i++) {
      file = fileArr[i];

      let reader = new FileReader();
      reader.onload = () => {
        fileArrUrl[i] = reader.result;
        setPreviewImageArr([...fileArrUrl]);
      };
      reader.readAsDataURL(file);
    }
  };
  const deleteButtonClick = () => {
    setImageArr([]);
    setPreviewImageArr([]);
  };

  const postMogId = () => {
    serverController.connectFetchController(
      `review/status/${product.mog_id}`,
      "PUT",
      null,
      (res) => {
        if (res.result === 1) {
          history.push(`/store/mypage/review?tab=1&page=1`);
        }
      }
    );
  };

  // test zone
  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "permission",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!permission) {
      setPhotoSection(false);
    }
  }, [permission]);

  useEffect(() => {
    if (clickItem === {}) return;
    setSatisfaction(clickItem.satisfaction);
    setReviewText(clickItem.rev_content);
    setPreviewImageArr([`${imgURL}${clickItem.rev_image}`]);
    setImageArr([""]);
  }, []);

  return (
    <Container>
      <Background
        onClick={() => {
          setIsEditModal(false);
        }}
      />

      <Content>
        {/* header */}
        <HeaderSection>
          <HeaderInner>
            <p>{title}</p>
            {/* 닫기 버튼 */}
            <CloseButton
              onClick={() => {
                setIsEditModal(false);
              }}
            >
              <img src={IconCancel} alt="cancel-icon" />
            </CloseButton>
          </HeaderInner>
        </HeaderSection>

        <ReviewFromWrap>
          <ReviewListWrap>
            <ListWrapLeft>
              <ListInner>
                <ProductInfoWrap>
                  <ImgBox
                    onClick={() => {
                      history.push(`/store/detail/${product?.prd_id}`);
                    }}
                  >
                    <img src={`${imgURL}${product?.prd_thumbnail}`} alt="product-img" />
                  </ImgBox>
                  <TextBox>
                    <Brand>{product?.bc_title || product?.br_name || "BRAND"}</Brand>
                    <ProductName
                      onClick={() => {
                        history.push(`/store/detail/${product?.prd_id}`);
                      }}
                    >
                      {product?.prd_name}
                    </ProductName>
                    {product?.optionInfo?.map((optionItem) => {
                      return (
                        <ProductColor>
                          {optionItem?.optName && <span>{optionItem?.optName}</span>}
                          {/* <span>1개</span> */}
                        </ProductColor>
                      );
                    })}
                  </TextBox>
                </ProductInfoWrap>
              </ListInner>

              <BackgroundGray />
              <MyPageBottomModal textList={myPageBottomTextList} />

              <SubmitBtnWrap>
                <Btn
                  as="button"
                  onClick={() => {
                    if (reviewText.length < 5) return alert("리뷰를 5자 이상 작성해주세요.");
                    if (clickItem === {}) {
                      postReview();
                    } else {
                      putReview();
                    }
                  }}
                >
                  등록하기
                </Btn>
              </SubmitBtnWrap>
            </ListWrapLeft>
            <ListWrapRight>
              <FromWrap>
                <FromTitleWrap>
                  <ListTitle>상품은 어떠셨나요?</ListTitle>
                  <FromBox>
                    <ReviewTextWrap>
                      <BtnWrap>
                        {BtnList.map((value, index) => {
                          return (
                            <MyPageButtonComp
                              active={index === satisfaction}
                              key={index + "btnList"}
                              state={index}
                              value={value}
                              setSatisfaction={setSatisfaction}
                            ></MyPageButtonComp>
                          );
                        })}
                      </BtnWrap>
                    </ReviewTextWrap>
                  </FromBox>
                </FromTitleWrap>
              </FromWrap>
              <FromWrap>
                <FromTitleWrap>
                  <ListTitle>후기를 작성해주세요.</ListTitle>
                  <ReviewTextLength>({reviewText.length}/500)</ReviewTextLength>
                </FromTitleWrap>
                <FromBox>
                  <ReviewTextWrap>
                    <ReviewText
                      placeholder="구매하신 제품의 후기를 작성해주세요."
                      name="review_text"
                      value={reviewText}
                      onChange={(e) => {
                        if (e.target.value.length > 500) return;
                        setReviewText(e.target.value);
                      }}
                    />
                  </ReviewTextWrap>
                </FromBox>
              </FromWrap>
              {photoSection ? (
                <ImageWrap>
                  <ImageBox>
                    <FromTitleWrap>
                      <ListTitle>사진올리기(선택)</ListTitle>
                      <ListSubTitle>*사진 첨부하기(최대1장)</ListSubTitle>
                    </FromTitleWrap>

                    {/* 사진이미지 파일 올리기 */}
                    {imageArr.length === 0 && (
                      <FileReviseBtnWrap>
                        <FileReviseBtn
                          type="file"
                          id="filePic"
                          accept="image/*"
                          onChange={(e) => {
                            handleImageUpload(e);
                            uploadImage(e);
                          }}
                        />

                        <FileBtnLabel for="filePic" />
                      </FileReviseBtnWrap>
                    )}
                  </ImageBox>
                  {imageArr.map((el, index) => {
                    return (
                      <UploadImageBox key={index + "imageBox"}>
                        <DeleteButton onClick={deleteButtonClick} />
                        <UploadImage alt="previewImg" src={previewImageArr[index]} />
                      </UploadImageBox>
                    );
                  })}
                </ImageWrap>
              ) : (
                <InfoText>* 이미지 업로드는 '사진 접근 허용' 후 이용 가능합니다.</InfoText>
              )}
            </ListWrapRight>
          </ReviewListWrap>
        </ReviewFromWrap>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  position: relative;

  @media screen and (max-width: 930px) {
    min-height: calc(100vw * (500 / 428));
  }
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 101;
`;

// header
const HeaderSection = styled.div`
  position: relative;
  width: 100%;
  height: 84px;
  overflow: hidden;
  z-index: 99;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
  }
`;
const HeaderInner = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
  background-color: #fff;
  padding: 0 25px;

  & p {
    color: #333;
    font-weight: 500;
    font-size: 20px;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
    padding: 0 calc(100vw * (25 / 1320));
    & p {
      font-size: calc(100vw * (20 / 1320));
    }
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
`;

// 콘텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  width: 1240px;
  height: 650px;
  background: #fff;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    height: calc(100vw * (650 / 1320));
  }
`;

const ReviewFromWrap = styled.div``;

const BackgroundGray = styled.div`
  width: 100%;
  height: 8px;
  background-color: #f9f9f9;
  flex-shrink: 0;
`;
//info
const ListInner = styled.div`
  width: 100%;
  padding: 0 10px 20px;
  flex-shrink: 0;

  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (10 / 1320)) calc(100vw * (20 / 1320));
  }
`;
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;

const ImgBox = styled.div`
  width: 70px;
  height: 70px;
  background-color: #fff;
  border: 1px solid #eee;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (70 / 1320));
    height: calc(100vw * (70 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (70 / 428));
    height: calc(100vw * (70 / 428));
  }
`;
const TextBox = styled.div`
  width: calc(100% - 80px);
  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (80 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (298 / 428));
  }
`;
const Brand = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (6 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (6 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProductColor = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;

  & span {
    color: #777;
    font-weight: 400;
  }
  & span:not(:last-child)::after {
    content: " / ";
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (4 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (4 / 428));
  }
`;

//list
const ReviewListWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 1190px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1190 / 1320));
  }
`;
const ListWrapLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 428px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (428 / 1320));
  }
`;
const ListWrapRight = styled.div`
  width: 742px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (742 / 1320));
  }
`;

const FromTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (10 / 1320));
  }
`;
const ListSubTitle = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }
`;
const ListTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
`;

// from
const FromWrap = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (30 / 1320));
    }
  }
`;
const FromBox = styled.div``;

const ReviewTextWrap = styled.div``;
const ReviewText = styled.textarea`
  width: 100%;
  height: 232px;
  color: #333;
  font-weight: 400;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  padding: 15px 20px;
  &:focus {
    border: 1px solid #000;
  }

  &::placeholder {
    color: #acacac;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (232 / 1320));
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (15 / 1320)) calc(100vw * (20 / 1320));
  }
`;
const ReviewTextLength = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubmitBtnWrap = styled.div`
  width: 100%;
  height: 50px;
  flex-shrink: 0;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
  }
`;
const Btn = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
`;

// image upload
const ImageWrap = styled.div``;

const UploadImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const UploadImageBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  background-color: #fff;
  border: 1px solid #eee;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    height: calc(100vw * (140 / 1320));
  }
`;
const DeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 28px;
  height: 28px;
  background: url(${IconFileUploadCancel}) no-repeat center / contain;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (28 / 1320));
    height: calc(100vw * (28 / 1320));
  }
`;

const ImageBox = styled.div``;

// file upload
const FileReviseBtnWrap = styled.div``;
const FileReviseBtn = styled.input`
  display: none;
`;
const FileBtnLabel = styled.label`
  display: block;
  width: 140px;
  height: 140px;
  background: url(${IconFileUpload}) no-repeat center / contain;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    height: calc(100vw * (140 / 1320));
  }
`;
