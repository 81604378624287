import styled, { css } from "styled-components";
import {            
    pc,
    mobil,
    Media,    
    Px,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
    mquery,
} from '../common/mediaStlye';

export const MoreButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: calc(100vw * (188 / 1920));
    height: calc(100vw * (51 / 1920));
    font-size: calc(100vw * (18 / 1920));
    font-weight: 900;
    border: 2px solid #222;
    border-radius: calc(100vw * (37 / 1920));    

    @media screen and (max-width:${mquery}px) {
        ${Width(162, mobil)}
        ${Height(44, mobil)}
        ${FontSize(16, mobil)}
        border-radius: ${Px(37, mobil)};
        line-height: 0;
        margin: 0;       

    }
`

export const Container = styled.div`
    padding-top: calc((100vw * (172 / 1920)));    
    width: 100%;
    display: flex;
    justify-content: center;
`

export const Layout = styled.div`
    width: calc(100vw * (1316 / 1920));

    @media screen and (max-width: ${mquery}px) {
        width: 100%;
        ${Padding2(0, 25, mobil)}
    }
`

export const FashionNewsHeadings = styled.div`
    margin-bottom: calc((100vw * (43 / 1920)));
    & > strong {
        font-size: calc((100vw * (22 / 1920)));
        color: #FFF200;   
        font-weight: 900;
        text-transform: uppercase;
    }

    & > h2 {
        font-size: calc((100vw * (50 / 1920)));
        font-weight: 900;
        color: #222;
        text-transform: uppercase;
    }
    @media screen and (max-width: ${mquery}px) {
        & > strong {
            ${FontSize(16, mobil)}
        }

        & > h2 {
            ${FontSize(25, mobil)}
        }
    }
`

export const FashionNewsBackground = styled.div`
    position: absolute;
    left: calc((100vw * (-240 / 1920)));
    top: calc((100vw * (16 / 1920)));    
    width: calc((100vw * (928 / 1920)));
    
    @media screen and (max-width: ${mquery}px) {
        ${[
            Width(464, mobil),
            Top(-130, mobil),
            Left(-90, mobil)
        ].join("")}
        z-index: -1;

        & > img {
            width: 100%;
        }
    }
`

export const FashionNewsSlider = styled.div`
    position: relative;
    margin-bottom: calc((100vw * (117 / 1920)));

    @media screen and (max-width: ${mquery}px) {
        & .swiper-container {
            ${PB(68, mobil)}
        }
    }
`

export const FashionNewsSlide = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding-bottom: calc((100vw * (117 / 1920)));
    
    @media screen and (max-width: ${mquery}px) {
        flex-direction: column;
        height: auto;
    }
`

export const FashionNewsLeft = styled.div`
    width: calc((100vw * (761 / 1920)));
    border-top: 3px solid #404040;

    @media screen and (max-width: ${mquery}px) {
        width: 100%;
        ${MB(20, mobil)}
    }
`

export const MainNewsItem = styled.div`
    width: 100%;
    height: calc(100vw * (632 / 1920));
    display: flex;
    justify-content: space-between;    
    flex-direction: column;
    @media screen and (max-width: ${mquery}px) {
        height: auto;
    }
`

export const MainNewsItemText = styled.div`
 padding-top: calc(100vw * (20 / 1920));
    padding-left: calc(100vw * (12 / 1920));
    padding-right: calc(100vw * (52 / 1920));
    
    & > span {
        font-size: calc(100vw * (14 / 1920));
        padding-bottom: calc(100vw * (11 / 1920));
        color: #A5A5A5;
    }

    & > h3 {
        font-size: calc(100vw * (22 / 1920));
        padding-bottom: calc(100vw * (24 / 1920));
        font-weight: 900;
    }

    & > p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        /* line-height: 1.3; */
/*        height: 4.8em;*/
        text-align: left;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 5 ;
        -webkit-box-orient: vertical;
        font-size: calc(100vw * (16 / 1920));
        color: #222;
    }

    @media screen and (max-width: ${mquery}px) {
        ${Padding(20,13,0,13,mobil)}
        ${MB(20, mobil)}

        & > span {
            ${[
                FontSize(13, mobil),
                PB(18, mobil)
            ].join("")}
        }

        & > h3 {
            ${[
                FontSize(16, mobil),
                PB(20, mobil)
            ].join("")}
        }

        & > p {
            ${FontSize(15, mobil)}
        }
    }

    
`
export const MainNewsItemImg = styled.div`
    height: calc((100vw * (355 / 1920)));
    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media screen and (max-width: ${mquery}px) {
        ${Height(176,mobil)}
    }
`

export const FashionNewsRight = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items:center;
    flex-wrap: wrap;
    padding-left: calc((100vw * (16 / 1920)));

    @media screen and (max-width: ${mquery}px) {
        width: 100%;   
        gap: ${Px(11, mobil)};
    }
`

export const MainNewsRightItem = styled.div`
    display: flex;
    justify-content: space-between;    
    flex-direction: column;    
    border-top: 3px solid #222;
    width: calc((100vw * (261 / 1920)));
    height: calc((100vw * (310 / 1920)));
    padding-top: calc(100vw * (19 / 1920));
    margin-bottom: calc(100vw * (11 / 1920));

    @media screen and (max-width: ${mquery}px) {
        flex: 1;
        ${Height(217, mobil)}
    }
`
export const MainNewsRightText = styled.div`
    position: relative;
   

    & > span {
        position: absolute;
        right:0;
        top:0;
        font-size: calc(100vw * (14 / 1920));        
        color: #A5A5A5;
    }

    & > h3 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: calc(100vw * (18 / 1920));
        font-weight: 900;
        padding-right: 30%;
        padding-bottom: calc(100vw * (12 / 1920));
    }

    & > p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 1.3;
/*        height: 4.8em;*/
        text-align: left;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 4 ;
        -webkit-box-orient: vertical;
        font-size: calc(100vw * (14 / 1920));
        color: #222;
    }

    @media screen and (max-width: ${mquery}px) {
        & > span { 
            ${FontSize(13, mobil)}
        }
        & > h3 {
            ${FontSize(15, mobil)}
            ${MB(8, mobil)}
        }
        & > p { 
            -webkit-line-clamp: 3;
            ${FontSize(13, mobil)}
        }
    }
`

export const MainNewsRightImg = styled.div`
    width: 100%;
    height: calc((100vw * (157 / 1920)));
    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media screen and (max-width: ${mquery}px) {
        ${Height(109, mobil)}
        
    }
`

// FashionNewsIntro
export const IntroContainer = styled(Container)`
    padding-bottom: calc((100vw * (300 / 1920)));
`
export const IntroBox = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;    
    align-items:flex-end;
    flex-direction: ${props => !props.reverse ? 'row' : 'row-reverse'};
    margin-bottom: calc((100vw * (152 / 1920)));

    @media screen and (max-width: ${mquery}px) {
        flex-direction: column;        
        width: 100%;
    }
`

export const IntroThumb = styled.div`
    width: calc((100vw * (650 / 1920)));
    height: calc((100vw * (393 / 1920)));

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media screen and (max-width: ${mquery}px) {
        ${Height(228, mobil)}
        width: 100%;
    }
`

export const IntroText = styled.div`
    flex: 1;

    ${({reverse}) => !reverse ?`
    padding-left: calc((100vw * (24 / 1920)));
    
    & > button {
        margin-left: calc((100vw * (116 / 1920)));
    }
    `:`
    text-align: right;
    padding-right: calc((100vw * (24 / 1920)));
    
    & > button {
        margin-right: calc((100vw * (116 / 1920)));
    }
    `}
    padding-left: calc((100vw * (24 / 1920)));

    & > em {
        display: block;
        margin-bottom: calc((100vw * (5 / 1920)));
        font-size:  calc((100vw * (16 / 1920)));
        font-weight: 900;
        color: #A5A5A5;
    }

    & > h2 {
        margin-bottom: calc((100vw * (43 / 1920)));
        font-size:  calc((100vw * (28 / 1920)));
        font-weight: 900;
        color: #222;
    }

    @media screen and (max-width: ${mquery}px) {
        ${Height(228, mobil)}
        ${MT(27, mobil)}

        width: 100%;
        padding-left: 0;
        padding-right:0;

        & > em {
            ${FontSize(15, mobil)}
            ${MB(7, mobil)}
        }

        & > h2 {
            ${FontSize(22, mobil)}
            ${MB(5, mobil)}
        }

        ${({reverse}) => !reverse ?`        
        & > button {
            flaot: right;
        }
        `:`
        text-align: right;
        
        
        & > button {
            margin-right: 0;
        }
        `}
    }

`

export const BlogBackground = styled(FashionNewsBackground)`
    top: calc((100vw * (-179 / 1920)));
    left: calc((100vw * (179 / 1920)));
    width: calc((100vw * (1461 / 1920)));
    z-index: -1;
`
export const MediaBackground = styled(FashionNewsBackground)`
    top: calc((100vw * (-179 / 1920)));
    left: calc((100vw * (-301 / 1920)));
    width: calc((100vw * (980 / 1920)));
    z-index: -1;
`