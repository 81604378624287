import { useQuery } from "react-query";

//prettier-ignore
export default function useRequestQuery({ queryKey, requestAPI, options, requestData }) {
  console.log('requestData: ', requestData);
  const OPTION = {
    ...options,
  };

  const fetcher = async () => {
    const data = await requestAPI(requestData);
    return data;
  };

  const { data, isLoading, isSuccess, isError, isFetching, refetch } = useQuery(queryKey, fetcher, OPTION);

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    isFetching,
    refetch,
  };
}
