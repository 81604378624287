import React, { useState, useEffect } from "react";

import Header from "../../component/common/Header";
import CartProductList from "../../component/shopping/cart/CartProductList";
import FixMenu from "../../component/common/FixMenu";
import FullLoading from "../../component/common/modal/FullLoading";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function CartPage() {
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const validLogin = () => {
    if (isLogged) return;
    alert(`잘못된 접근입니다.`);
    history.push(`/store`);
  };

  useEffect(() => {
    validLogin();
  }, []);

  return (
    <>
      {isLoading && <FullLoading />}
      <Header back onlyLeft />
      <CartProductList setLoading={setIsLoading} />
      <FixMenu />
    </>
  );
}
