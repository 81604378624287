import React from 'react';
import { Mobile, PC } from "../../MediaQuery";
import { useHistory } from 'react-router-dom';

//css
import styled from "styled-components"
import "../../App.css";

//img
import icon from "../../img/brand/benner_icon.svg";
import plus from "../../img/brand/plus.png";
import arrow from "../../img/brand/main_arrow.png";

export default function BrandMain() {
    const history = useHistory();

    return (
        <>
            <PC>
                <BrandMainWrap>
                    <BrandMainHead>
                        <BrandText>Node be Made Incorporation</BrandText>
                        <BrandTextImg>
                            <img src={icon} alt="icon" />
                        </BrandTextImg>
                    </BrandMainHead>

                    <BrandMainContentWrap>
                        <BrandMainContent>

                            <BrandHeadWrap>
                                <BrandTextHead onClick={() => history.push('/management')}>제품 관리</BrandTextHead>

                                <BrandBtnWrap>
                                    <BrandProductBtn onClick={() => history.push('/brandregist')}>제품 등록</BrandProductBtn>
                                </BrandBtnWrap>
                            </BrandHeadWrap>

                            <BrandMainProductBox>

                                <BrandMainProduct>
                                    <BrandMainProductText>등록:</BrandMainProductText>
                                    <BrandMainProductNum>30</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </BrandMainProduct>
                                <BrandMainProduct>
                                    <BrandMainProductText>임시저장:</BrandMainProductText>
                                    <BrandMainProductNum>10</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </BrandMainProduct>
                                <BrandMainArrowProduct>
                                    <BrandMainProductText>ARCHIVE:</BrandMainProductText>
                                    <BrandMainProductNum>5</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </BrandMainArrowProduct>
                                <BrandMainProduct>
                                    <BrandMainProductText>협찬신청:</BrandMainProductText>
                                    <BrandMainProductNum>30</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </BrandMainProduct>
                                <BrandMainProduct>
                                    <BrandMainProductText>배송준비:</BrandMainProductText>
                                    <BrandMainProductNum>10</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </BrandMainProduct>
                                <BrandMainArrowProduct>
                                    <BrandMainProductText>반납임박:</BrandMainProductText>
                                    <BrandMainProductNum>5</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </BrandMainArrowProduct>
                            </BrandMainProductBox>

                        </BrandMainContent>


                        <BrandMainContent>

                            <BrandHeadWrap>
                                <BrandTextHead>컨텐츠 관리</BrandTextHead>

                                <BrandBtnWrap>
                                    <BrandProductBtn onClick={() => history.push('/brandregist')}>컨텐츠 등록</BrandProductBtn>
                                </BrandBtnWrap>
                            </BrandHeadWrap>

                            <BrandMainProductBox>

                                <BrandMainProduct>
                                    <BrandMainProductText>등록:</BrandMainProductText>
                                    <BrandMainProductNum>30</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </BrandMainProduct>
                                <BrandMainProduct>
                                    <BrandMainProductText>임시저장:</BrandMainProductText>
                                    <BrandMainProductNum>10</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </BrandMainProduct>
                                <BrandMainArrowProduct>
                                    <BrandMainProductText>ARCHIVE:</BrandMainProductText>
                                    <BrandMainProductNum>5</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </BrandMainArrowProduct>
                            </BrandMainProductBox>

                        </BrandMainContent>

                        <BrandBoxWrap>
                            <BrandBox>
                                <BrandBoxText onClick={() => history.push('/management')}>MEMBER 관리</BrandBoxText>
                            </BrandBox>
                            <BrandBox>
                                <BrandBoxText>DATA CENTER</BrandBoxText>
                            </BrandBox>
                            <BrandBox>
                                <BrandBoxText onClick={() => history.push('/brandarchive')}>ARCHIVE</BrandBoxText>
                            </BrandBox>
                        </BrandBoxWrap>
                    </BrandMainContentWrap>
                </BrandMainWrap>
            </PC>

            <Mobile>
                <BrandMainWrap>
                    <BrandMainHead>
                        <BrandText>Node be Made Incorporation</BrandText>

                        {/* 헤더에 들어가야함. */}
                        {/* <BrandTextImg>
                            <img src={icon} alt="icon"/>
                        </BrandTextImg> */}
                    </BrandMainHead>

                    <BrandMainContentWrap>
                        <BrandMainContent>

                            <BrandHeadWrap>
                                <BrandTextHead>제품 관리</BrandTextHead>

                                <BrandBtnWrap>
                                    <BrandProductBtn>제품 등록</BrandProductBtn>
                                </BrandBtnWrap>
                            </BrandHeadWrap>

                            <BrandMainProductBox>

                                <MbBrandMainArrowProduct>
                                    <BrandMainProductText>등록:</BrandMainProductText>
                                    <BrandMainProductNum>30</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </MbBrandMainArrowProduct>
                                <MbBrandMainProduct>
                                    <BrandMainProductText>임시저장:</BrandMainProductText>
                                    <BrandMainProductNum>10</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </MbBrandMainProduct>
                                <MbBrandMainArrowProduct>
                                    <BrandMainProductText>ARCHIVE:</BrandMainProductText>
                                    <BrandMainProductNum>5</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </MbBrandMainArrowProduct>
                                <MbBrandMainProduct>
                                    <BrandMainProductText>협찬신청:</BrandMainProductText>
                                    <BrandMainProductNum>30</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </MbBrandMainProduct>
                                <MbBrandMainArrowProduct>
                                    <BrandMainProductText>배송준비:</BrandMainProductText>
                                    <BrandMainProductNum>10</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </MbBrandMainArrowProduct>
                                <MbBrandMainProduct>
                                    <BrandMainProductText>반납임박:</BrandMainProductText>
                                    <BrandMainProductNum>5</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </MbBrandMainProduct>
                            </BrandMainProductBox>

                        </BrandMainContent>


                        <BrandMainContent>

                            <BrandHeadWrap>
                                <BrandTextHead>컨텐츠 관리</BrandTextHead>

                                <BrandBtnWrap>
                                    <BrandProductBtn>컨텐츠 등록</BrandProductBtn>
                                </BrandBtnWrap>
                            </BrandHeadWrap>

                            <BrandMainContentBox>

                                <MbBrandMainArrowProduct>
                                    <BrandMainProductText>등록:</BrandMainProductText>
                                    <BrandMainProductNum>30</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </MbBrandMainArrowProduct>
                                <MbBrandMainProduct>
                                    <BrandMainProductText>임시저장:</BrandMainProductText>
                                    <BrandMainProductNum>10</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </MbBrandMainProduct>
                                <MbBrandMainArrowProduct>
                                    <BrandMainProductText>ARCHIVE:</BrandMainProductText>
                                    <BrandMainProductNum>5</BrandMainProductNum>
                                    <BrandMainProductIcon>
                                        <img src={plus} alt="plus" />
                                    </BrandMainProductIcon>
                                </MbBrandMainArrowProduct>
                            </BrandMainContentBox>

                        </BrandMainContent>

                        <BrandBoxWrap>
                            <BrandBox>
                                <BrandBoxText>MEMBER 관리</BrandBoxText>
                            </BrandBox>
                            <BrandBox>
                                <BrandBoxText>DATA CENTER</BrandBoxText>
                            </BrandBox>
                            <BrandBox>
                                <BrandBoxText>ARCHIVE</BrandBoxText>
                            </BrandBox>
                        </BrandBoxWrap>
                    </BrandMainContentWrap>
                </BrandMainWrap>

            </Mobile>
        </>
    )
}

const BrandMainWrap = styled.div`
    width: 100%;
`
const BrandMainHead = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 141px;
    background-color: #f7f7f8;
    padding: 40px 0;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    height: calc(100vw*(141/1300));
    padding: calc(100vw*(40/1300)) 0;
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    height: calc(100vw*(108/428));
    padding: calc(100vw*(40/428)) 0;
}
`
const BrandMainContentWrap = styled.div`
    width: calc(100vw*(1300/1920));
    margin: 0 auto;
    padding-bottom: 153px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    padding-bottom: calc(100vw*(153/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    padding-bottom: calc(100vw*(153/428));
    width: calc(100vw*(428/428));
}
`
const BrandText = styled.p`
    font-family: "Avenir";
    font-weight: 900;
    font-size: 39px;
    text-align: center;
    color: #1f1f1f;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(39/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(20/428));
}
`
const BrandTextImg = styled.div`
    width: 38px;
    height: 38px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(38/1300));
    height: calc(100vw*(38/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(38/428));
    height: calc(100vw*(38/428));
}
`
const BrandMainContent = styled.div`
    margin-top: 55px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    margin-top: calc(100vw*(55/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    margin-top: calc(100vw*(55/428));
}
`
const BrandHeadWrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 45px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    padding: 0 calc(100vw*(45/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    padding: 0 calc(100vw*(45/428));
}
`
const BrandTextHead = styled.p`
    font-size: 25px;
    color: #1f1f1f;
    font-weight: 900;
    cursor: pointer;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(25/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(20/428));
}
`
const BrandBtnWrap = styled.div`
    width: 159px;
    height: 42px;
    border-radius: 37px;
    border: 2px solid #222222;
    text-align: center;
    padding: 6px 0;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(159/1300));
    height: calc(100vw*(42/1300));
    border-radius: calc(100vw*(37/1300));
    padding: calc(100vw*(6/1300)) 0;
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(159/428));
    height: calc(100vw*(42/428));
    border-radius: calc(100vw*(37/428));
    padding: calc(100vw*(6/428)) 0;
}
`
const BrandProductBtn = styled.button`
    font-size: 15px;
    font-weight: 900;
    color: #222222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(15/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(15/428));
}
`
const BrandMainProductBox = styled.div`
    display: grid;
    grid-template: 'item1 item2 item3';
    justify-content: center;
    justify-items: center;
    height: 154px;
    border-radius: 21px;
    border: 1px solid #d9d9d9;
    margin-top: 15px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    height: calc(100vw*(154/1300));
    border-radius: calc(100vw*(21/1300));
    margin-top: calc(100vw*(15/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(378/428));
    height: calc(100vw*(237/428));
    margin: 0 auto;
    grid-template: 'item1 item2';
    border-radius: calc(100vw*(21/428));
    margin-top: calc(100vw*(15/428));
    /* padding: 0 calc(100vw*(20/428)); */
}
`
const BrandMainContentBox = styled.div`
    display: grid;
    grid-template: 'item1 item2 item3';
    justify-content: center;
    justify-items: center;
    width: 1233px;
    height: 154px;
    border-radius: 21px;
    border: 1px solid #d9d9d9;
    margin-top: 15px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(900/1300));
    height: calc(100vw*(154/1300));
    border-radius: calc(100vw*(21/1300));
    margin-top: calc(100vw*(15/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(378/428));
    height: calc(100vw*(164/428));
    margin: 0 auto;
    grid-template: 'item1 item2';
    border-radius: calc(100vw*(21/428));
    margin-top: calc(100vw*(15/428));
}
`
const BrandMainProduct = styled.div`
    display: flex;
    align-items: center;


    &::after {
        content: url(${arrow});
        height: 12px;
        padding: 0 30px;
    }

        /* 테블릿 */
  @media screen and (max-width: 1300px) {
    /* margin-left: calc(100vw*(65/1300)); */
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    /* margin-left: calc(100vw*(15/428)); */
}
`
const BrandMainArrowProduct = styled.div`
    display: flex;
    align-items: center;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    /* margin-left: calc(100vw*(65/1300)); */
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    /* margin-left: calc(100vw*(15/428)); */
}
`
const MbBrandMainProduct = styled.div`
    display: flex;
    align-items: center;
`
const MbBrandMainArrowProduct = styled.div`
    display: flex;
    align-items: center;

    &::after {
        content: url(${arrow});
        height: 12px;
        padding: 0 30px;
    }
`
const BrandMainProductText = styled.span`
    font-family: "Avenir";
    font-size: 15px;
    font-weight: 900;
    color: #1f1f1f;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(15/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(15/428));
}
`
const BrandMainProductNum = styled.span`
    font-family: "Avenir";
    font-size: 15px;
    font-weight: 900;
    color: #1f1f1f;
    margin-left: 67px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(15/1300));
    margin-left: calc(100vw*(67/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(15/428));
    margin-left: calc(100vw*(30/428));
}
`
const BrandMainProductIcon = styled.span`
    width: 22px;
    height: 22px;
    margin-left: 22px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(22/1300));
    height: calc(100vw*(22/1300));
    margin-left: calc(100vw*(22/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(22/428));
    height: calc(100vw*(22/428));
    margin-left: calc(100vw*(10/428));
}
`
const BrandBoxWrap = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 55px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    gap: calc(100vw*(10/1300));
    margin-top: calc(100vw*(55/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    flex-direction: column;
    align-items: center;
    gap: calc(100vw*(10/428));
    margin-top: calc(100vw*(55/428));
}
`
const BrandBox = styled.div`
    width: 402px;
    height: 119px;
    border-radius: 21px;
    border: 1px solid #d9d9d9;
    text-align: center;
    padding: 41px 0;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(402/1300));
    height: calc(100vw*(119/1300));
    border-radius: calc(100vw*(21/1300));
    padding: calc(100vw*(41/1300)) 0;
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(378/428));
    height: calc(100vw*(112/428));
    border-radius: calc(100vw*(21/428));
    padding: calc(100vw*(41/428)) 0;
}
`
const BrandBoxText = styled.p`
    font-family: Avenir;
    font-size: 25px;
    font-weight: 900;
    color: #1f1f1f;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(25/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(22/428));
}
`