import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PC, Mobile } from '../common/mediaStlye';
import serverController from '../../server/serverController';

import {
    FieldBox,
    Label,
    LabelName,
    EmailInput,
    PasswordInput,
    TelephoneInput,
    Input
} from '../auth/auth.elements';
import {
    SubCheckButton
} from '../auth/signup/signup.element';
import {
    TabContentHeading,
    TabContent,
    TabUserForm,
    ModifyButton
} from '../auth/mypage/mypage.elements';

const StylistInfoHistory = () => {
    const history = useHistory();
    const [counts, setCounts] = useState({
        apply: 0,
        approval: 0,
        refusal: 0
    })

    const getData = async () => {
        await serverController.connectFetchController(
            `purchases/count`,
            "GET",
            null,
            (res) => {
                console.log(res, "협찬 신청 내역 res");
                setCounts(prev => ({
                    ...prev,
                    ...res?.totalCount
                }))
            }
        )
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <PC>
                <TabContentHeading>협찬 신청 내역</TabContentHeading>
            </PC>
            <TabContent>
                <TabUserForm>
                    <div>
                        {/* <FieldBox>
                            <Label>
                                <LabelName>신청 내역</LabelName>
                                <Input value={counts.apply + "건"} />
                            </Label>                        
                        </FieldBox> */}

                        <FieldBox>
                            <Label>
                                <LabelName>신청 중</LabelName>
                                <Input value={counts.apply + "건"} />
                            </Label>
                            <SubCheckButton onClick={() => history.push('/stylist/mypage/1')}>전체보기</SubCheckButton>
                        </FieldBox>

                        <FieldBox>
                            <Label>
                                <LabelName>승인 내역</LabelName>
                                <Input value={counts.approval + "건"} />
                            </Label>
                            <SubCheckButton onClick={() => history.push('/stylist/mypage/2')}>전체보기</SubCheckButton>
                        </FieldBox>

                        <FieldBox>
                            <Label>
                                <LabelName readOnly>반려 내역</LabelName>
                                <Input value={counts.refusal + "건"} readOnly />
                            </Label>
                            <SubCheckButton onClick={() => history.push('/stylist/mypage/3')}>전체보기</SubCheckButton>
                        </FieldBox>
                    </div>

                    <PC>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 'calc(100vw *(150 / 1920))' }}>
                            <ModifyButton onClick={() => history.push('/stylist/sponserd')} >협찬 제품 보러가기</ModifyButton>
                        </div>
                    </PC>
                    <Mobile>
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 'calc(100vw *(150 / 428))' }}>
                            <ModifyButton onClick={() => history.push('/stylist/sponserd')} >협찬 제품 보러가기</ModifyButton>
                        </div>
                    </Mobile>


                </TabUserForm>
            </TabContent>
        </div>
    )
}

export default StylistInfoHistory
