import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Mobile, PC } from "../../../MediaQuery";
import Header from "../../component/common/Header";
import MyPageHeader from "./MyPageHeader";
import MyAdress from "../../component/mypage/adress/MyAdress";
import FullLoading from "../../component/common/modal/FullLoading";
import MyPageLayOutMenu from "../../component/mypage/MyPageLayOutMenu";
import MyPageLayOutName from "../../component/mypage/MyPageLayOutName";
import MyPageLayOutInfo from "../../component/mypage/MyPageLayOutInfo";

export default function AdressPage() {
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const validLogin = () => {
    if (isLogged) return;
    alert(`잘못된 접근입니다.`);
    history.push(`/store`);
  };

  useEffect(() => {
    validLogin();
  }, []);

  return (
    <>
      {/* <PC>
        <Header />
        <MypageMenuList />
        <MypageAdress />
        <Footer />
        <FixedTop />
      </PC> */}
      {/* <Mobile>
        <Header />
        <MypageMenuList menu={true} />
        <MypageAdress />
        <Footer />
        <FooterMenu />
        <FixMenu />
      </Mobile> */}

      {loading && <FullLoading />}
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader title={"배송지 관리"} prevBtn />
      </Mobile>

      <MyAdressArea>
        {/* page */}
        <PC>
          <MyPageLayOutName />
        </PC>

        <FlexLayOut>
          <PC>
            <MyPageLayOutMenu />
          </PC>

          {/* content */}
          <ContentSection>
            <PC>
              <MyPageLayOutInfo />
            </PC>

            <MyAdressSection>
              <MyAdress setLoading={setLoading} />
            </MyAdressSection>
          </ContentSection>
        </FlexLayOut>
      </MyAdressArea>
    </>
  );
}

const MyAdressArea = styled.div`
  width: 100%;
  padding: 180px 0 200px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const MyAdressSection = styled.div`
  padding-top: 60px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (60 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: 0;
  }
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
