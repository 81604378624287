import React from "react";
import { useParams } from "react-router-dom";
import { PC, Mobile } from "../../MediaQuery";
import styled from "styled-components";

import Header from "../../components/common/header/Header";
import MyPageHeader from "../../components/common/header/MyPageHeader";
import StylistMyRequest from "../../components/stylist/StylistMyRequest";
import Footer from "../../components/common/footer/Footer";
import FooterMenu from "../../components/common/footer/FooterMenu";

const StylistMyRequestPage = () => {
  const { statusId } = useParams();
  const sponStatuslist = [
    "협찬 신청중인 내역",
    "협찬 승인 내역",
    "협찬 반려 내역",
  ];

  return (
    <Container>
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader title={sponStatuslist[Number(statusId) - 1]} />
      </Mobile>

      <StylistMyRequest />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
    </Container>
  );
};
const Container = styled.div`
  @media screen and (max-width: 1024px) {
    padding-bottom: calc(100vw * (68 / 428));
  }
`;

export default StylistMyRequestPage;
