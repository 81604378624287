import styled from 'styled-components';
import {    
    mquery,mobil,
    Media,
    css,
    pc,
    Px,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    MQ,
    PT,
    PB,
    PL,
    PR,
    LineH,
} from '../../common/mediaStlye';
import {
    Container, 
    PrimaryButton, 
    Form 
} from '../auth.elements';

export const SignupFormWrapper = styled(Form)`
    padding-bottom: 0;
`

export const SubCheckButton = styled(PrimaryButton).attrs(props => ({
    as: 'label'
}))`
    ${[
        Width(143, pc),
        Height(49, pc),
        FontSize(16, pc),
        Right(20, pc),
        Bottom(16.5, pc),
    ].join("")}
    position: absolute;
    margin-bottom:0;
    background-color: #fff;
    color: #222;
    border: 1px solid #222;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    ${MQ(mquery)} {
        ${[
            Width(121, mobil),
            Height(43, mobil),
            FontSize(16, mobil),                     
            MB(5, mobil)
        ].join("")}
        position: static;
        margin: 0 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
`

export const SignupBox = styled.div`
    ${MB(163, pc)}
    ${MT(65, pc)}
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > p {
        ${[
            PB(15, pc),
            FontSize(14, pc)
        ].join("")}
        color: #A5A5A5;
    }

    & > p::before {
        content: "*";
        position: relative;
        ${[
            Top(-2, pc),
            Right(2, pc)
        ].join("")}        
    }

    & em {
        cursor: pointer;
    }

    @media ${props => props.theme.container} {
        ${MT(30, mobil)}
        ${MB(52, mobil)}

        & > p {
            ${[
                Padding(0,50,0,50,mobil),
                MB(20, mobil),
                FontSize(14, mobil)
            ].join("")}
            color: #A5A5A5;
            text-align: center;
        }
    }   
`

export const SignupButton = styled(PrimaryButton).attrs()`
    ${[
        Width(278,pc),
        Height(59, pc)
    ].join("")}
    color: #fff;
    background-color: #222;
    margin-bottom:0 ;
    ${({on})=>{
        return on ?
        `background: #222;`
        :
        `background: #999;`
    }}

    ${props => props.prev && css`
        color: #222;
        background-color: #fff;
        border: 1px solid #222;
    `}    

    @media ${props => props.theme.container} {
        ${[
            Width(220,mobil),
            Height(59, mobil),
            FontSize(16, mobil)
        ].join("")}
    }

    
`

//  Success 회원가입 성공페이지
export const SuccessContainer = styled.div`
    ${[
        MB(65, pc),
        Padding(50,0,120,0,pc)
    ].join("")}
    text-align: center;
`

export const SuccessImg = styled.div`
    ${[
        Width(124, pc),
        MB(34, pc)
    ].join("")}
    margin-right: auto;
    margin-left: auto;
    & > img {
        width: 100%;        
        object-fit: cover;
    }

    ${MQ(mquery)} {
        ${Width(100, mobil)}
        ${MB(32, mobil)}
    }
`

export const SuccessText = styled.div`
    ${MB(65, pc)}
    & > h2 {
        ${[
            FontSize(30, pc)
        ].join("")}
        color: #0A0A0A
    }

    & > p {
        ${FontSize(16, pc)}
        color: #A5A5A5;
    }

    ${MQ(mquery)} {
        ${MB(43, mobil)}

        & > h2 {
            ${[
                FontSize(20, mobil),
                MB(5, mobil)
            ].join("")}
            color: #0A0A0A
        }

        & > p {
            ${FontSize(16, mobil)}
            color: #A5A5A5;
        }
    }
`

// SignUpSteps
export const StepContainer = styled(Container)`
    ${Padding(78,0,161,0,pc)}

    ${MQ(mquery)} {
        /* ${Padding2(0,25, mobil)} */
        padding: 0;
    }
`

export const StepContents = styled.div`
    display: flex;    
    align-items: center;
    flex-direction: column;
    ${MQ(mquery)} {
        width: 100%;
    }
`

export const StepHeadings = styled.div`
    ${MB(52, pc)}
    text-align: center;
    & > h2 {
        ${[
            FontSize(36, pc),
            MB(61, pc)
        ].join("")}
        font-weight: 900;
        text-transform: uppercase;
    }

    & > p {
        ${FontSize(15, pc)}
    }

    & em {
        color: #222;
        letter-spacing: 1px;
    }

    & span {
        color: #D9D9D9;
        letter-spacing: 1px;
    }

    ${MQ(mquery)} {
        ${MB(100, mobil)}
        & > h2 {
            ${[
                FontSize(25, mobil),
                MB(20, mobil)
            ].join("")}           
        }

        & > p {
            ${FontSize(15, mobil)}
            font-weight: 700;
        }
    }
`

export const GenderForm = styled.div`
    ${[
        Width(580, pc),
        Height(371, pc),
        MB(38, pc)
    ].join("")}
    display: flex;
    flex-grow: 1;
    gap: ${Px(18, pc)};

    ${MQ(mquery)} { 
        ${Height(240, mobil)}
        ${MB(100, mobil)}
        width: 100%;
        gap: ${Px(10, mobil)};
    }
`

export const Gender = styled.div`    
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid #D9D9D9;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s ease;

    ${props => props.isSelect && css`
        transform: ${`translate(0, ${Px(-16, pc)})`};
        box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 20%);           

        
        & > div {
            background-color: #FFF200;
            
        }
        & > span {
            color: #222;
        }
    `}

    & > span {
        ${FontSize(20, pc)}
        font-weight: 900;
        color: #A5A5A5;
        text-transform: uppercase;
    }

    ${MQ(mquery)} { 
        & > span {
            ${FontSize(13, mobil)}
        }
    }
    
`

export const GenderMark = styled.div`
    ${[
        Width(197,pc),
        Height(197, pc),        
        MB(35, pc)
    ].join("")}
    display: flex;
    justify-content: center;
    align-items: center;   
    background-color: #D9D9D9;    
    border-radius: 50%;    
    transition: all 0.3s ease;
    
    & > strong {
        transform: ${`translateY(${Px(10, pc)})`};
        color: #fff;
        font-weight: 900;        
        text-transform: uppercase;
        ${FontSize(84, pc)}
    }

    ${MQ(mquery)} { 
        ${[
            Width(128,mobil),
            Height(128, mobil),        
            MB(22, mobil)
        ].join("")}

        & > strong {
            transform: ${`translateY(${Px(10, mobil)})`};
            ${FontSize(54, mobil)}
        }
    }
`

export const StepNextBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > p {
        position: relative;
        border-bottom: 1px solid #222;
        cursor: pointer;
    }

    & > button {
        ${MB(28, pc)}
    }

    ${MQ(mquery)} {
        ${MB(102, mobil)}        
        width: 100%;

        & > button {
            ${MB(20, mobil)}
        }
    }
`

export const StepForm = styled.div`
    ${MB(60, pc)}

    ${MQ(mquery)} {
        ${MB(60, mobil)}
    }
`

export const StepMessage = styled.h3`
    ${[
        FontSize(20, pc),
        MB(60, pc)
    ].join("")}
    text-align: center;

    & > em {
        color: #D9D9D9
    }
    font-weight: 900;

    ${MQ(mquery)} {
        ${FontSize(16, mobil)}
        ${MB(40, mobil)}
    }
`
export const StepStyles = styled.div`
    ${[
        Width(665, pc),
        MB(45, pc)
    ].join("")}
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    ${MQ(mquery)} {
        width: 100%;
        ${MB(60, mobil)}
        row-gap: ${Px(8, mobil)};
    }
`

export const StepStyle = styled.div`
    ${[
        Width(211, pc),
        Height(246, pc),
        MB(14, pc)
    ].join("")}
    position: relative;
    cursor: pointer;

    :hover div {
        opacity: 1;
    }

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    & > div {
        ${FontSize(16, pc)}
        opacity: ${({isClick}) => isClick ? "1" : "0"};
        position: absolute;
        left: 0;
        top:0;
        width: 100%;
        height:100%;
        background-color: rgba(0,0,0,0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        transition: opacity 0.3s ease;        
    }

    

    ${MQ(mquery)} {
        width: 49%;
        ${Height(217, mobil)}

        & > div {
            ${FontSize(16, mobil)}
        }
    }
`

export const StepButtonBox = styled.div`
    display: flex;    
    ${MB(28, pc)}
    & > button {
        ${[
            Width(206, pc),
            Margin2(0, 8, pc)
        ].join("")}        
    }

    ${MQ(mquery)} {
        ${MB(20, mobil)}
        width: 100%;
        
        & > button {
            flex: 1;
        }
    }
`
export const StepBodys = styled.div`
    ${[
        Width(770, pc),
    ].join("")}
    display: flex;
    justify-content: space-between;
    align-items: center;    
`

export const StepBody = styled.div`
    position: relative;
    cursor: pointer;
`
export const StepBodyInner = styled.div`
    width: calc(100vw*(428/428));
    overflow-x: scroll;
`

export const StepColors = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;   
    row-gap: ${Px(20, pc)};       
    ${Width(450, pc)}

    ${MQ(mquery)} {
        width: 100%;     
        row-gap: ${Px(30, mobil)};      
        ${MB(40, mobil)}       
        ${PT(40, mobil)}       
    }
`

export const StepColor = styled.li`
    ${[
        Width(117, pc),
        MB(44, pc)
    ].join("")}
    cursor: pointer;
    text-align: center;    

    ${MQ(mquery)} {
        width: 48%;        
        /* ${MB(30, mobil)}           */
    }


`
export const Colors = styled.div`
    ${MB(27, pc)}
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    opacity: 1;
    ${props => !props.selected && css`
    opacity: 0.2;
    `}    
    
`

export const Color = styled.span`
    ${[
        Width(37, pc),
        Height(37, pc),
        Margin2(0,1.5,pc)
    ].join("")}
    display: block;
    border-radius: 50%;
    background-color: ${props => props.color};
    ${props => props.color === '#fff' && css`
    border: 1px solid #d9d9d9;
    `}

    ${MQ(mquery)} {        
        ${[
            Width(44, mobil),
            Height(44, mobil),
            Margin2(0,3,mobil)
        ].join("")}        
    }
`

export const MobileSubBox = styled.div`
    display: flex;
    justify-content: flex-end;

    & span {
        display: flex;
        justify-content: center;
        align-items: center;
        ${PL(0, mobil)}    
    }
`

export const MobileSubCheckButton = styled(SubCheckButton)`
    ${PL(0, mobil)}
    
`