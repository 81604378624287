//react
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";

//css
import styled from "styled-components";

//img
import IconPrevBtn from "../../img/icon/icon_pagination_prev_bk.png";
import IconNextBtn from "../../img/icon/icon_pagination_next.png";
// import BestItemImg1 from "../../img/best_item_img1.png"
// import BestItemImg2 from "../../img/best_item_img2.png"
// import BestItemImg3 from "../../img/best_item_img3.png"
// import BestItemImg4 from "../../img/best_item_img4.png"
// import BestItemImg5 from "../../img/best_item_img5.png"
// import BestItemImg6 from "../../img/best_item_img6.png"
import BestItemImg1 from "../../../images/content/content-detail-prod1.png";
import BestItemImg2 from "../../../images/content/content-detail-prod2.png";
import BestItemImg3 from "../../../images/content/content-detail-prod3.png";
import BestItemImg4 from "../../../images/category/category-prod-1.png";
import BestItemImg5 from "../../../images/category/category-prod-2.png";
import BestItemImg6 from "../../../images/category/category-prod-3.png";

//page
import Commas from "../../server/Commas";
import Pagination from "../common/pagination/Pagination";

export default function BestItems() {
  const [activeIndex, setActiveIndex] = useState(null);
  const hoverRef = useRef();

  const bestList = [
    {
      src: BestItemImg1,
      brand: "디디에두보",
      prd_title: "Pink Cashmere Jumper 세트",
      prd_sale_rate: 29,
      prd_list_price: 75000,
      prd_price: 105000,
    },
    {
      src: BestItemImg2,
      brand: "디디에두보",
      prd_title: "Pink Cashmere Jumper 세트",
      prd_sale_rate: 29,
      prd_list_price: 75000,
      prd_price: 105000,
    },
    {
      src: BestItemImg3,
      brand: "디디에두보",
      prd_title: "Pink Cashmere Jumper 세트",
      prd_sale_rate: 29,
      prd_list_price: 75000,
      prd_price: 105000,
    },
    {
      src: BestItemImg4,
      brand: "디디에두보",
      prd_title: "Pink Cashmere Jumper 세트",
      prd_sale_rate: 29,
      prd_list_price: 75000,
      prd_price: 105000,
    },
    {
      src: BestItemImg5,
      brand: "디디에두보",
      prd_title: "Pink Cashmere Jumper 세트",
      prd_sale_rate: 29,
      prd_list_price: 75000,
      prd_price: 105000,
    },
    {
      src: BestItemImg6,
      brand: "디디에두보",
      prd_title: "Pink Cashmere Jumper 세트",
      prd_sale_rate: 29,
      prd_list_price: 75000,
      prd_price: 105000,
    },
    {
      src: BestItemImg1,
      brand: "디디에두보",
      prd_title: "Pink Cashmere Jumper 세트",
      prd_sale_rate: 29,
      prd_list_price: 75000,
      prd_price: 105000,
    },
    {
      src: BestItemImg2,
      brand: "디디에두보",
      prd_title: "Pink Cashmere Jumper 세트",
      prd_sale_rate: 29,
      prd_list_price: 75000,
      prd_price: 105000,
    },
  ];

  useEffect(() => {
    for (let i = 0; i < hoverRef.current.children.length; i++) {
      const refList = hoverRef.current.children;
      console.log(refList.children);
      hoverRef.current.children[i].addEventListener("mouseover", () => {
        setActiveIndex(i);
      });
      hoverRef.current.children[i].addEventListener("mouseleave", () => {
        setActiveIndex(null);
      });
    }
  }, []);

  return (
    <Container>
      <PC>
        <BestItemsWrap>
          <SubTitleWrap>
            <SubTitle>BEST ITEMS</SubTitle>
          </SubTitleWrap>
          <BestItemsListWrap ref={hoverRef}>
            {bestList.map((value, index) => {
              return (
                <BestItemsList key={"best item list" + index}>
                  <LogoListInner>
                    <BestItemsImgWrap>
                      <BestItemsImg src={value.src} alt="best item image" />
                    </BestItemsImgWrap>
                    <BestItemsTextWrap active={activeIndex == index}>
                      <ItemsTextWrap>
                        <ItemsText>{value.brand}</ItemsText>
                        <ItemsText>{value.prd_title}</ItemsText>
                      </ItemsTextWrap>
                      <ItemsPriceWrap>
                        <ItemsSaleRate>{Commas.numberWithCommas(value.prd_sale_rate)}%</ItemsSaleRate>
                        <ItemsSalePrice>{Commas.numberWithCommas(value.prd_list_price)}</ItemsSalePrice>
                        <ItemsPrice>{Commas.numberWithCommas(value.prd_price)}</ItemsPrice>
                      </ItemsPriceWrap>
                    </BestItemsTextWrap>
                  </LogoListInner>
                </BestItemsList>
              );
            })}
          </BestItemsListWrap>

          {/* Pagination */}
          <Pagination />
        </BestItemsWrap>
      </PC>

      <Mobile>
        <BestItemsWrap>
          <SubTitleWrap>
            <SubTitle>BEST ITEMS</SubTitle>
          </SubTitleWrap>
          <BestItemsListWrap ref={hoverRef}>
            {bestList.map((value, index) => {
              return (
                <BestItemsList key={"best item list" + index}>
                  <LogoListInner>
                    <BestItemsImgWrap>
                      <BestItemsImg src={value.src} alt="best item image" />
                    </BestItemsImgWrap>
                    <BestItemsTextWrap>
                      <ItemsTextWrap>
                        <ItemsText>{value.brand}</ItemsText>
                        <ItemsText>{value.prd_title}</ItemsText>
                      </ItemsTextWrap>
                      <ItemsPriceWrap>
                        <div>
                          <ItemsSaleRate>{Commas.numberWithCommas(value.prd_sale_rate)}%</ItemsSaleRate>
                        </div>
                        <div>
                          <ItemsPrice>{Commas.numberWithCommas(value.prd_price)}</ItemsPrice>
                          <ItemsSalePrice>{Commas.numberWithCommas(value.prd_list_price)}</ItemsSalePrice>
                        </div>
                      </ItemsPriceWrap>
                    </BestItemsTextWrap>
                  </LogoListInner>
                </BestItemsList>
              );
            })}
          </BestItemsListWrap>

          {/* Pagination */}
          <Pagination />
        </BestItemsWrap>
      </Mobile>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  padding-top: 20px;
  margin-bottom: 180px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (20 / 1320));
    margin-bottom: calc(100vw * (180 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (40 / 428));
    margin-bottom: calc(100vw * (100 / 428));
  }
`;
const BestItemsWrap = styled.div`
  width: 1300px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
  }
`;
const SubTitleWrap = styled.div`
  padding-top: 100px;
  margin-bottom: 30px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (100 / 1320));
    margin-bottom: calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    margin: calc(100vw * (20 / 428)) auto calc(100vw * (30 / 428));
    padding-bottom: calc(100vw * (24 / 428));
    padding-top: 0;
    border-bottom: calc(100vw * (1 / 428)) solid #707070;
  }
`;
const SubTitle = styled.h2`
  font-size: 30px;
  font-weight: 900;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    text-align: center;
  }
`;
//list
const BestItemsListWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const BestItemsList = styled.div`
  width: calc(100% / 4 - 40px);
  margin: 25px 40px 25px 0;
  &:nth-child(4n) {
    margin-right: 0;
  }
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100% / 4 - calc(100vw * (40 / 1320)));
    margin: calc(100vw * (25 / 1320)) calc(100vw * (40 / 1320)) calc(100vw * (25 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    width: calc(100% / 2 - calc(100vw * (10 / 428)));
    margin: calc(100vw * (30 / 428)) calc(100vw * (20 / 428)) calc(100vw * (15 / 428)) 0;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
`;
const LogoListInner = styled.div`
  position: relative;
  width: 100%;
  height: 380px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (380 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (228 / 428));
  }
`;
const BestItemsImgWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
  }

  @media screen and (max-width: 930px) {
  }
`;
const BestItemsImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const BestItemsTextWrap = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 5;
  transition: 0.5s;
  cursor: pointer;
  &:after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 380px;
    background-color: rgba(1, 1, 1, 0.3);

    z-index: -1;
  }
  ${({ active }) => {
    return active ? `right: 0;` : `right: -150%;`;
  }}

  @media screen and (max-width: 1320px) {
    &:after {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: calc(100vw * (380 / 1320));
    }
    ${({ active }) => {
      return active ? `right: 0;` : `right: -150%;`;
    }}
  }

  @media screen and (max-width: 930px) {
    &:after {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: calc(100vw * (228 / 428));
    }
    ${({ active }) => {
      return active ? `right: 0;` : `right: 0`;
    }}
  }
`;
const ItemsTextWrap = styled.div`
  padding: 0 20px;

  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0 calc(100vw * (15 / 428));
  }
`;
const ItemsText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  text-align: right;
  color: #fff;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-bottom: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const ItemsPriceWrap = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  padding: 24px 20px 30px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (24 / 1320)) calc(100vw * (20 / 1320)) calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    align-items: flex-end;
    padding: calc(100vw * (15 / 428)) calc(100vw * (15 / 428)) calc(100vw * (25 / 428));
  }
`;
const ItemsSaleRate = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin-right: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    margin-right: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    margin-right: calc(100vw * (10 / 428));
  }
`;
const ItemsSalePrice = styled.p`
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  margin-right: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
    margin-right: calc(100vw * (6 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    margin-right: 0;
    text-align: right;
    margin-top: calc(100vw * (5 / 428));
  }
`;
const ItemsPrice = styled.p`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: line-through;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-right: 0;
    text-align: right;
  }
`;
