import { useEffect, useState } from "react";
import qs from "qs";
import styled from "styled-components";
import serverController from "../../server/serverController";
import StoreSearchBrandSection from "./StoreSearchBrandSection";

import StoreSearchContentSection from "./StoreSearchContentSection";
import StoreSearchItemSection from "./StoreSearchItemSection";
import StoreSearchRecentListSection from "./StoreSearchRecentListSection";

export default function StoreSearchResultIndex(props) {
  const { setLoginPop } = props;
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const [recentProductList, setRecentProductList] = useState([]);
  const [resultPrdList, setResultPrdList] = useState([]);
  const [brandList, setBrandsList] = useState([]);

  const [contentsList, setContentsList] = useState([]);

  const getProductsRecentlyList = () => {
    serverController.connectFetchController("products/recently", "GET", null, (res) => {
      if (res?.result === 1) {
        setRecentProductList(res?.list.reverse() ?? []);
      }
    });
  };

  const getResultProductList = () => {
    serverController.connectFetchController(
      `products?prd_type=1&offset=${resultPrdList.length}&limit=4&keyword=${query.keyword}&filter=0&range=0`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setResultPrdList(res?.list ?? []);
        }
      }
    );
  };

  const getContentsList = () => {
    serverController.connectFetchController(
      `contentlist?keyword=${query.keyword}&page=0`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setContentsList(res.list ?? []);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getBrandsList = () => {
    serverController.connectFetchController(
      `brand/list?keyword=${query.keyword}&page=1&limit=5`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setBrandsList(res?.data);
        }
      }
    );
  };

  useEffect(() => {
    getProductsRecentlyList();
    getResultProductList();
    getContentsList();
    getBrandsList();
  }, [query.keyword]);

  const storeSearchContentSectionItems = {
    contentsList,
  };

  const storeSearchItemSectionItems = {
    list: resultPrdList,
    setLoginPop,
  };

  const storeSearchBrandSectionItems = {
    list: brandList,
    setLoginPop,
  };

  const storeSearchRecentListSectionItems = {
    recentProductList,
    setLoginPop,
  };

  return (
    <>
      {contentsList.length === 0 && resultPrdList.length === 0 && brandList.length === 0 ? (
        <InfoText>검색 결과가 없습니다.</InfoText>
      ) : (
        <>
          {brandList.length > 0 && <StoreSearchBrandSection {...storeSearchBrandSectionItems} />}
          {contentsList.length > 0 && (
            <StoreSearchContentSection {...storeSearchContentSectionItems} />
          )}
          {resultPrdList.length > 0 && <StoreSearchItemSection {...storeSearchItemSectionItems} />}
          {recentProductList.length > 0 && (
            <StoreSearchRecentListSection {...storeSearchRecentListSectionItems} />
          )}
        </>
      )}
    </>
  );
}
const InfoText = styled.p`
  font-weight: 500;
  color: #999;
  justify-content: center;
  display: flex;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: 0;
    margin-top: calc(100vh * (330 / 925));
  }
`;
