import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Mobile, PC } from '../../common/mediaStlye';
import { useSelector } from 'react-redux';
import { SignUpAction } from '../../../store/actionCreators';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
    Layout
} from '../auth.elements';
import {
    StepContainer,
    StepContents,
    StepHeadings,
    StepForm,
    StepMessage,
    StepButtonBox,
    StepNextBox,
    StepBodys,
    StepBody,
    StepBodyInner,
    SignupButton
} from './signup.element';

import womanBody1 from '../../../images/signup/woman-body-1.png';
import womanBody2 from '../../../images/signup/woman-body-2.png';
import womanBody3 from '../../../images/signup/woman-body-3.png';
import womanBody4 from '../../../images/signup/woman-body-4.png';
import womanBody5 from '../../../images/signup/woman-body-5.png';
import womanBodyHover1 from '../../../images/signup/woman-body-hover1.png';
import womanBodyHover2 from '../../../images/signup/woman-body-hover2.png';
import womanBodyHover3 from '../../../images/signup/woman-body-hover3.png';
import womanBodyHover4 from '../../../images/signup/woman-body-hover4.png';
import womanBodyHover5 from '../../../images/signup/woman-body-hover5.png';
import manBody1 from '../../../images/signup/man-body-1.png';
import manBody2 from '../../../images/signup/man-body-2.png';
import manBody3 from '../../../images/signup/man-body-3.png';
import manBody4 from '../../../images/signup/man-body-4.png';
import manBody5 from '../../../images/signup/man-body-5.png';
import manBodyHover1 from '../../../images/signup/man-body-hover1.png';
import manBodyHover2 from '../../../images/signup/man-body-hover2.png';
import manBodyHover3 from '../../../images/signup/man-body-hover3.png';
import manBodyHover4 from '../../../images/signup/man-body-hover4.png';
import manBodyHover5 from '../../../images/signup/man-body-hover5.png';

const typeBodys = ['모래시계형', '타원형', '역삼각형', '직사각형', '삼각형'];
const manBodys = [manBody1, manBody2, manBody3, manBody4, manBody5];
const manBodysHover = [manBodyHover1, manBodyHover2, manBodyHover3, manBodyHover4, manBodyHover5];
const womanBodys = [womanBody1, womanBody2, womanBody3, womanBody4, womanBody5];
const womanBodysHover = [womanBodyHover1, womanBodyHover2, womanBodyHover3, womanBodyHover4, womanBodyHover5];

const StepBodysBox = ({ value, setStyles, i }) => {
    const [isClick, setIsClick] = useState(false);
    const onClick = () => {
        setIsClick(prev => !prev);
    }

    useEffect(() => {
        setStyles(prev => {
            const newArr = [...prev];
            if (isClick) {
                return newArr.concat(value?.type)
            } else {
                return newArr.filter(v => v !== value?.type);
            }
        })
    }, [isClick])

    return (
        <StepBody onClick={onClick}>
            <img src={isClick ? value?.bodyHover : value?.body} alt={value?.type} />
        </StepBody>
    )
}

const SignUpStepThree = ({ step, url }) => {
    const history = useHistory();
    const { mem_gender } = useSelector(state => state.signupData);
    const [list, setList] = useState([]);
    const [styles, setStyles] = useState([]);

    const loadBodys = (mem_gender) => {
        if (mem_gender === 0) {
            const newArr = [];
            manBodys.forEach((body, index) => {
                const newObj = {};
                newObj.type = typeBodys[index];
                newObj.body = body;
                newObj.bodyHover = manBodysHover[index];
                newArr.push(newObj);
            })
            setList(newArr);
        } else if (mem_gender === 1) {
            const newArr = [];
            womanBodys.forEach((body, index) => {
                const newObj = {};
                newObj.type = typeBodys[index];
                newObj.body = body;
                newObj.bodyHover = womanBodysHover[index];
                newArr.push(newObj);
            })
            setList(newArr);
        }
    }

    useEffect(() => {
        loadBodys(mem_gender)
    }, [mem_gender])

    const prevStep = () => history.push(`/${url}/normal/2`);
    const nextStep = (skip) => {
        if (skip) {
            console.log(styles)
            SignUpAction.sendPersonalNormalInfo({ mem_fit: [] }) // 0 남자 1 여자
            history.push(`/${url}/normal/4`);
        } else if (styles.length > 0) {
            console.log(styles)
            SignUpAction.sendPersonalNormalInfo({ mem_fit: styles }) // 0 남자 1 여자
            history.push(`/${url}/normal/4`)
        } else {
            alert("선호하시는 스타일을 골라주세요.")
        }
    };

    return (
        <StepContainer>
            <Layout>
                <StepContents>
                    <StepHeadings>
                        <h2>Personal infomation</h2>
                        <p><em>0{step} / </em><span>04</span></p>
                    </StepHeadings>

                    <StepForm>
                        <StepMessage>체형을 선택해 주세요. <em>(중복 선택 가능)</em></StepMessage>
                        <PC>
                            <StepBodys>
                                {
                                    list.map((value, i) => {
                                        return <StepBodysBox
                                            setStyles={setStyles}
                                            value={value}
                                            index={i}
                                            key={i}
                                        />
                                    })
                                }
                            </StepBodys>
                        </PC>
                        <Mobile>
                            <StepBodyInner>
                                <StepBodys>
                                    {
                                        list.map((value, i) => {
                                            return (
                                                <StepBodysBox setStyles={setStyles} value={value} index={i} key={i} />
                                            )
                                        })
                                    }
                                </StepBodys>
                            </StepBodyInner>
                        </Mobile>
                    </StepForm>

                    <StepNextBox>
                        <StepButtonBox>
                            <SignupButton prev onClick={prevStep}>이전으로</SignupButton>
                            <SignupButton onClick={() => nextStep()}>다음으로</SignupButton>
                        </StepButtonBox>

                        {/* <p onClick={() => nextStep(true)}>스킵하기</p> */}
                    </StepNextBox>
                </StepContents>
            </Layout>
        </StepContainer>
    )
}

export default SignUpStepThree
