import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { InfoText } from "../../page/main/MainPage";
import Loading from "../common/modal/Loading";
import CelebsPickList from "./CelebsPickList";
import { Swiper, SwiperSlide } from "swiper/react";
import { height } from "dom7";
import { PC, Mobile } from "../../MediaQuery";

export default function BestItemSection(props) {
  const { list, mainCateLink, getProductBest, setBestLoading, bestLoading, setLoginPop } = props;
  const [cateList, setCateList] = useState([]);
  const clickCate = useRef(null);

  const getMainCateLink = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(mainCateLink, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCateList(result.list);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (mainCateLink === "") return;
    getMainCateLink();
  }, [mainCateLink]);

  return (
    <Container>
      <BestNewWrap>
        <TitleWrap>
          <TitleText>Celeb’s Pick</TitleText>
        </TitleWrap>
        {/* <CateWrap>
          {cateList.map((value, index) => {
            return (
              <CateTitle
                active={
                  clickCate.current === null && index === 0 ? true : index === clickCate.current
                }
                onClick={() => {
                  setBestLoading(true);
                  getProductBest(value.prd_maincat_id);
                  clickCate.current = index;
                }}
                key={`${value.prd_maincat_name}-${value.c_cat_id}`}
              >
                {value.prd_maincat_name}
              </CateTitle>
            );
          })}
        </CateWrap> */}
        <BestNewListWrap>
          {bestLoading ? (
            <Loading />
          ) : (
            <BestNewInner>
              {list.length > 0 ? (
                <>
                  <PC>
                    <Swiper
                      slidesPerView={6.5}
                      slidesPerGroup={6}
                      spaceBetween={10}
                    >
                      {list?.map((value, index) => {
                        return (
                          <SwiperSlide key={value.prd_id}>
                            <CelebsPickList
                              key={`${value.prd_name}-mainBest-${index}`}
                              value={value}
                              height={"bestSlide"}
                              setLoginPop={setLoginPop}
                              rank
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </PC>
                  <Mobile>
                    {list?.map((value, index) => {
                      return (
                        <CelebsPickList
                          key={`${value.prd_name}-mainBest-${index}`}
                          value={value}
                          height={"bestSlide"}
                          setLoginPop={setLoginPop}
                          rank
                        />
                      );
                    })}
                  </Mobile>
                </>
              ) : (
                <InfoText>해당 상품이 없습니다.</InfoText>
              )}
              <SlideGradient />
            </BestNewInner>
          )}
        </BestNewListWrap>
      </BestNewWrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  /* padding-top: 80px; */
  padding-bottom: 60px;

  @media screen and (max-width: 1320px) {
    /* padding-top: calc(100vw * (80 / 1320)); */
    padding-bottom: calc(100vw * (60 / 1320));
  }

  @media screen and (max-width: 930px) {
    /* padding-top: calc(100vw * (40 / 428)); */
    border-top: calc(100vw * (1 / 428)) #eee solid;
    padding: calc(100vw * (40 / 428)) 0 calc(100vw * (40 / 428));
  }
`;
const BestNewWrap = styled.div`
  width: 1240px;
  margin: 0 auto;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: auto;
    margin: auto;
  }
`;
const CateWrap = styled.div`
  position: relative;
  display: flex;
  @media screen and (max-width: 930px) {
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
    padding: 0 calc(100vw * (25 / 428)) calc(100vw * (20 / 428));
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const CateTitle = styled.div`
  position: relative;
  font-size: 16px;
  color: #999999;
  font-weight: 400;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 22px;
  }

  &::after {
    display: none;
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2px;
    background-color: #000;
  }

  ${({ active }) =>
    active &&
    `
      color: #333;
      font-weight: 700;

      &::after {
        display: block;
      }
    `}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (22 / 1320));
    }

    &::after {
      bottom: calc(100vw * (-5 / 1320));
      height: calc(100vw * (2 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));

    &:not(:last-child) {
      margin-right: calc(100vw * (20 / 428));
    }

    &::after {
      bottom: calc(100vw * (-5 / 428));
      height: calc(100vw * (2 / 428));
    }
  }
`;
const TitleWrap = styled.div`
  font-weight: bold;
  padding-bottom: 20px;
  text-align: center;

  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (16 / 428));
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  }
`;
const TitleText = styled.h2`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
  color: #222222;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const BestNewListWrap = styled.div`
  width: 100%;
  padding-top: 20px;
  position: relative;
  min-height: 300px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (20 / 1320));
    min-height: calc(100vw * (300 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: 0;
    background: none;
    /* width: calc(100vw * (378 / 428));
    margin: 0 auto; */
    width: 100%;
    margin: 0;
    min-height: calc(100vw * (256 / 428));
  }
`;
const BestNewInner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;

  & .product_box {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1320px) {
    & .product_box {
      &:not(:last-child) {
        margin-right: calc(100vw * (8 / 1320));
      }
    }
  }

  @media screen and (max-width: 930px) {
    display: -webkit-box;
    -webkit-flex-wrap: inherit;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
    padding: 0 calc(100vw * (25 / 428));
    ::-webkit-scrollbar {
      display: none;
    }
    & .product_box {
      &:not(:last-child) {
        margin-right: calc(100vw * (8 / 428));
      }
    }
  }

  /* @media screen and (max-width: 930px) {
    justify-content: space-between;

    & .product_box {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  } */
  `;

const SlideGradient = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: -4px;
  width: 50px;
  height: 100%;
  background: linear-gradient(to right, transparent, #fff 80%);
  @media screen and (max-width: 930px) {
    display: none;
    /* right: 0;
    width: calc(100vw * (30 / 428)); */
    }
  `