import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import serverController from "store_src/server/serverController";
import styled from "styled-components";
import PlanListWrap from "store_src/component/plan/PlanListWrap";
import { Mobile, PC } from "../../MediaQuery";
import HtmlParser from "react-html-parser";
import axios from "axios";
import { ip } from "store_src/server/serverController";
import Header from "store_src/component/common/Header";
import FixMenu from "store_src/component/common/FixMenu";
import { Get } from "api/index";
import { queryKeys } from "constants/queryKeys";
import useRequestQuery from "hooks/useRequestQuery";
import Moment from "react-moment";
import StorePlanContainer from "containers/storePlanContainer/StorePlanContainer";
import StorePlanProductList from "containers/storePlanContainer/StorePlanProductList";
import StoreAllPlanProductList from "containers/storePlanContainer/StoreAllPlanProductList";
import PlanCategory from "newComponents/storePlan/PlanCategory";
// import PlanAllPrdList from "newComponents/storePlan/PlanAllPrdList";
// import PlanPrdList from "newComponents/storePlan/PlanPrdList";

export default function StorePlanShopContainer() {
  const history = useHistory();
  const location = useLocation();
  const { event_id } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const limit = Number(searchParams.get("limit"));
  const containerRef = useRef(null);

  const chkAbleDate = React.useCallback((startDate, endDate) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);
    const now = new Date();
    if (newStartDate <= now && newEndDate >= now) {
      console.log("기획전 진행 기간입니다.");
    } else {
      alert(`기획전 진행 기간이 아닙니다.`);
      history.push(`/store`);
    }
  }, []);

  const requestData = {
    requestAPI: Get.getPlanData,
    requestData: { event_id: event_id },
    queryKey: [queryKeys.STORE_PLAN_DATA],
    options: {
      retry: 0,
      staleTime: 10 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
      keepPreviousData: true,
      onSuccess: (data) => {
        if (data && data.message === "실패") {
          alert("잠시 후 다시 시도해주세요.");
          history.push("/store");
        }
        if (data && data.result === 0) {
          alert(data.message);
          history.push("/store");
        }
        // if (data && data.event) {
        //   chkAbleDate(data.event.eventStartDatetime, data.event.eventEndDatetime);
        // }
      },
    },
  };

  useEffect(() => {
    location.search && containerRef?.current?.scrollIntoView();
  }, [location]);

  const { data: planData, isSuccess, isError, isLoading } = useRequestQuery(requestData);
  console.log("planData: ", planData);

  useEffect(() => {
    if (planData && planData.event) {
      chkAbleDate(planData.event.eventStartDatetime, planData.event.eventEndDatetime);
    }
  }, [chkAbleDate, planData]);

  return (
    <>
      <Header back />
      <FixMenu />
      <Container>
        {isSuccess && <StorePlanContainer planData={planData} />}
        {planData?.eventCategoryList.length > 0 && (
          <ScrollWrap ref={containerRef}>
            {isSuccess && limit === 10 && (
              <StorePlanProductList planData={planData}>
                {planData && <PlanCategory id={planData.event.eventId} item={planData.eventCategoryList} />}
              </StorePlanProductList>
            )}
            {isSuccess && limit !== 10 && (
              <StoreAllPlanProductList planData={planData}>
                {planData && <PlanCategory id={planData.event.eventId} item={planData.eventCategoryList} />}
              </StoreAllPlanProductList>
            )}
          </ScrollWrap>
        )}
      </Container>
    </>
  );
}

const Container = styled.section`
  position: relative;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    margin: auto;
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    padding-top: calc(100vw * (20 / 428));
    font-size: calc(100vw * (16 / 428));
  }
`;

const ScrollWrap = styled.div`
  scroll-margin: 200px;
  min-height: 1000px;
`;
