import React from "react";
import faker from "faker";
import {
  TabItemsContent,
  TabItemsHeading,
  TabItemConts,
  TabItemImg,
  TabItemDesc,
  TabItemText,
  TabItemLiked,
  TabItemsList,
  TabPaging,
  TabPages,
  PageNum,
  PrevButton,
  NextButton,
  MobileTabItemsHeading,
  MobileTabItemConts,
} from "./mypage.elements";
import { imgURL } from "../../../server/appInfoContoller";
import openLink from "../../../server/openLink";
import LikedIcon from "../../../images/stylist/liked_icon.svg";
import TabPrevIcon from "../../../images/tab-arrow-prev.png";
import { useHistory } from "react-router-dom";
import { Mobile, PC } from "../../common/mediaStlye";

const TabContentItemList2 = ({ itemData, setActiveIndex }) => {
  const history = useHistory();
  //
  const pageLinkClick = (id, type, link) => {
    if (type == 3) {
      openLink.openLinkEvent(link);
      // window.open(`${link}`);
    } else {
      alert("런칭 준비중입니다 :D");
      // history.push(`/store/detail/${id}`);
    }
  };

  return (
    <>
      <PC>
        <TabItemsContent
          onClick={() => setActiveIndex(0)}
          style={{ cursor: "pointer" }}
        >
          <TabItemsHeading>
            <h3>
              <img src={TabPrevIcon} />
              Item
            </h3>
            <div>
              <span>총 {itemData ? itemData.length : 0}개</span>
              <span>더보기</span>
            </div>
          </TabItemsHeading>

          <TabItemsList list>
            {itemData.map((v, i) => {
              const src = v?.prd_thumbnail?.split(",")[0];
              return (
                <TabItemConts
                  key={v?.prd_id}
                  onClick={() => {
                    pageLinkClick(v?.prd_id, v?.prd_type, v?.prd_link);
                  }}
                >
                  <TabItemImg>
                    <img src={imgURL + src} />
                  </TabItemImg>

                  <TabItemDesc className="items-desc" second>
                    <TabItemText second>
                      <em>{v?.br_name}</em>
                      <h2>{v?.prd_name}</h2>
                      <p>{v?.prd_detail1}</p>
                    </TabItemText>
                  </TabItemDesc>

                  <TabItemLiked className="item-liked">
                    <img src={LikedIcon} />
                  </TabItemLiked>
                </TabItemConts>
              );
            })}
          </TabItemsList>
          {/* <TabPaging>
                    <PrevButton />

                    <TabPages>
                    {
                        Array(3).fill().map((page, i) => {
                            return <PageNum>{i + 1}</PageNum>
                        })
                    }
                    </TabPages>

                    <NextButton />
                </TabPaging> */}
        </TabItemsContent>
      </PC>

      <Mobile>
        <TabItemsContent
          onClick={() => setActiveIndex(0)}
          style={{ cursor: "pointer" }}
        >
          <MobileTabItemsHeading>
            <h3>
              <img src={TabPrevIcon} />
              Item
            </h3>
            <div>
              <span>총 {itemData ? itemData.length : 0}개</span>
              <span>더보기</span>
            </div>
          </MobileTabItemsHeading>
          <div
            style={{
              padding: "0 20px",
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            <TabItemsList list>
              {itemData.map((v, i) => {
                const src = v?.prd_thumbnail?.split(",")[0];
                return (
                  <MobileTabItemConts
                    key={v?.prd_id}
                    style={{ width: "48%" }}
                    onClick={() =>
                      pageLinkClick(v?.prd_id, v?.prd_type, v?.prd_link)
                    }
                  >
                    <TabItemImg>
                      <img src={imgURL + src} />
                    </TabItemImg>
                    <TabItemLiked className="item-liked">
                      <img src={LikedIcon} />
                    </TabItemLiked>
                  </MobileTabItemConts>
                );
              })}
            </TabItemsList>
          </div>
          {/* <TabPaging>
                    <PrevButton />

                    <TabPages>
                    {
                        Array(3).fill().map((page, i) => {
                            return <PageNum>{i + 1}</PageNum>
                        })
                    }
                    </TabPages>

                    <NextButton />
                </TabPaging> */}
        </TabItemsContent>
      </Mobile>
    </>
  );
};

export default TabContentItemList2;
