import styled, { css } from "styled-components";
import {            
    pc,
    mobil,
    Media,
    Px,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
    mquery,
} from '../common/mediaStlye';

export const Section = styled.section`
    width: 100%; height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @media ${props => props.theme.container} {
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        ::-webkit-scrollbar {
            display:none;
        }
    }

    ${Media(mquery,[
        Padding2(0,25,mobil),
        "box-sizing: border-box;",
    ])}

    &:last-of-type {
        ${Media(mquery,[
            // MT(350, mobil),
        ])}
    }
`

export const SectionHeadings = styled.h2`
    text-align: center;
    padding-bottom: calc(100vw * (54 / 1920));
    font-size: calc(100vw * (35 / 1920));
    font-weight: 900;
    color: #222;

    ${Media(mquery,[
        FontSize(23, mobil),
        "width: 100%;",  
        MT(80, mobil),
        PB(50, mobil)     
    ])}
`

export const Wrapper = styled.div`
    position:relative;
    margin: 0 auto;
    width: 100%;
    padding-top: calc(100vw*(50/1920));

    @media ${props => props.theme.container} {
        width: 100%;
        padding-top: 0;
    }
`

export const MoreButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: calc(100vw * (188 / 1920));
    height: calc(100vw * (51 / 1920));
    font-size: calc(100vw * (18 / 1920));
    font-weight: 900;
    border: 2px solid #222;
    border-radius: 37px;
    
    @media ${props => props.theme.container} {
        ${[
            Width(163, mobil),
            Height(44, mobil),
            FontSize(16, mobil),            
        ].join("")}
        line-height: 0;
    }
`