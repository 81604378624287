import React from 'react';

import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import IconSearch from '../../img/common/searchIconImg_gray.png';

export default function SearchProduct({backBtn}){
    let history = useHistory();
    return(
        <Wrapper>
            <SearchWrap>
                {backBtn && <BackBtn onClick={()=>{history.goBack();}}/>}
                <InputWrap icon={IconSearch}>
                    <SearchInput type="text"/>
                </InputWrap>
            </SearchWrap>
        </Wrapper>
    )
}
const SearchWrap = styled.div`
    position:relative; margin-top:calc(100vw * (108 / 1920));
    @media screen and (max-width:768px){
        display:none;
    }
`;
const BackBtn = styled.button`
    border-top:3px solid #D3D3D3;
    border-left:3px solid #D3D3D3;
    width:16px; height:16px; transform:translateY(-50%) rotate(-45deg);
    position:absolute; left:0; top:50%;
`;
const InputWrap = styled.div`
    max-width:407px; width:100%; height:44px; margin:0 auto; position:relative;
    &:after { content:""; display:inline-block; background:url(${props => props.icon}) no-repeat center /contain; width:25px; height:25px; position:absolute; top:50%; right:24px; transform:translateY(-50%); }
`
const SearchInput = styled.input`
    border:3px solid #A5A5A5; height:100%; width:100%;
    border-radius:30px; display:block; font-size:16px; color:#222222;
    line-height:44px; padding:2px 52px 0 16px;
`
const Wrapper = styled.div`
    position:relative;
    margin:0 auto;
    width: calc(100vw * (1300 / 1920));

    @media screen and (max-width:768px){
        width:100%;
    }
`;