import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Layout,
} from '../auth.elements';
import {
    SignupBox,
    SignupButton,
    SuccessContainer,
    SuccessImg,
    SuccessText
} from './signup.element';
import signupsuccessImg from '../../../images/signup-success.png';

const SignUpSuccess = () => {
    const history = useHistory();
    return (
        <Container>
            <Layout>
                <SuccessContainer>
                    <SuccessImg><img src={signupsuccessImg} alt="회원가입 완료 이미지" /></SuccessImg>
                    <SuccessText>
                        <h2>
                            환영합니다! <br />
                            WALA 회원가입이 완료 되었습니다
                        </h2>
                        <p>로그인 후 서비스를 이용하실수 있습니다</p>
                    </SuccessText>
                    <SignupBox>
                        <SignupButton on={true} onClick={() => history.push('/login')}>로그인하러가기</SignupButton>
                    </SignupBox>
                </SuccessContainer>

            </Layout>
        </Container>
    )
}

export default SignUpSuccess
