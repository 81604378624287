//react
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import serverController from "../../server/serverController";

import { DateToText } from "../../../components/common/commonUse";

//css
import styled from "styled-components";

//page
import Pagination from "../common/pagination/Pagination";
import Search from "../common/pagination/Search";

export default function NoticeList() {
  const [noticeList, setNoticeList] = useState([]);

  const getNoticeList = () => {
    serverController.connectFetchController(`noticeList?page=0`, "POST", null, function (res) {
      if (res.result == 1) {
        console.log("c:", res);
        let listGet = res.list;
        setNoticeList(listGet);
      }
    });
  };

  useEffect(() => {
    getNoticeList();
  }, []);

  return (
    <Container>
      <PC>
        <NoticeListWrap>
          <TitleWrap>
            <Title>공지사항</Title>
          </TitleWrap>
          <Content>
            <Thead>
              <DivisionTit>NO</DivisionTit>
              <SubjectTit>제목</SubjectTit>
              {/* <AnswerTit>작성자</AnswerTit> */}
              <ViewTit>조회</ViewTit>
              <DateTit>작성일</DateTit>
            </Thead>
            {noticeList?.map((value, index) => {
              const { notice_id, notice_title, notice_view_count, notice_create_date } = value;
              return (
                <Cont>
                  <Division>{index + 1}</Division>
                  <Link to={`/store/notice/detail/${notice_id}`}>
                    <Subject>
                      <SubjectText>{notice_title}</SubjectText>
                    </Subject>
                  </Link>
                  {/* <User>{value.user_name}</User> */}
                  <View>{notice_view_count}</View>
                  <Date>{DateToText(notice_create_date, "-", true)}</Date>
                </Cont>
              );
            })}
          </Content>
          {/* <Pagination/> */}
          {/* <Search/> */}
        </NoticeListWrap>
      </PC>

      <Mobile>
        <NoticeListWrap>
          <TitleWrap>
            <Title>공지사항</Title>
          </TitleWrap>
          <Content>
            {noticeList.length > 0 ? (
              noticeList?.map((value, index) => {
                const { notice_id, notice_title, notice_view_count, notice_create_date } = value;
                return (
                  <Cont>
                    <Division>{index}</Division>
                    <MbTitleWrap>
                      <Link to={`/store/notice/detail/${notice_id}`}>
                        <Subject>
                          <SubjectText>{notice_title}</SubjectText>
                        </Subject>
                      </Link>
                      <MbTextWrap>
                        {/* <User>{value.user_name}</User> */}
                        <View>조회 {notice_view_count}</View>
                        <Date>{DateToText(notice_create_date, "-", true)}</Date>
                      </MbTextWrap>
                    </MbTitleWrap>
                  </Cont>
                );
              })
            ) : (
              <NoText>공지사항이 없습니다.</NoText>
            )}
          </Content>
          {/* <Pagination/> */}
          {/* <Search/> */}
        </NoticeListWrap>
      </Mobile>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
`;
const NoticeListWrap = styled.div`
  width: 1300px;
  margin: 0 auto;
  padding-bottom: 200px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
    padding-bottom: calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    padding-bottom: calc(100vw * (100 / 428));
  }
`;
const TitleWrap = styled.div`
  width: 100%;
  margin-bottom: 25px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (25 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (20 / 428));
  }
`;
const Title = styled.h2`
  font-size: 24px;
  color: #000;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
  }
`;
// form
const Content = styled.div`
  width: 100%;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;

  @media screen and (max-width: 1320px) {
    border-top: calc(100vw * (1 / 1320)) solid #000;
    border-bottom: calc(100vw * (1 / 1320)) solid #000;
  }

  @media screen and (max-width: 930px) {
    border-top: calc(100vw * (1 / 428)) solid #000;
    border-bottom: calc(100vw * (1 / 428)) solid #000;
  }
`;
const Thead = styled.div`
  display: flex;
  align-items: center;
  padding: 21px 0;
  text-align: center;
  color: #525252;
  font-weight: bold;
  font-size: 15px;
  border-bottom: 1px solid #d9d9d9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (21 / 1320)) 0;
    font-size: calc(100vw * (15 / 1320));
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }
`;
const DivisionTit = styled.div`
  width: 163px;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (163 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }
`;
const SubjectTit = styled(DivisionTit)`
  width: 652px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (652 / 1320));
  }
`;
const AnswerTit = styled(DivisionTit)`
  width: 169px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (169 / 1320));
  }
`;
const ViewTit = styled(DivisionTit)`
  width: 141px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (141 / 1320));
  }
`;
const DateTit = styled(DivisionTit)`
  width: 175px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (175 / 1320));
  }
`;
const Cont = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding: 22px 0;
  border-bottom: 1px solid #d9d9d9;
  &:last-child {
    border-bottom: none;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (22 / 1320)) 0;
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    align-items: flex-start;
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (22 / 428)) 0;
    border-bottom: calc(100vw * (1 / 428)) solid #d9d9d9;
  }
`;
const Division = styled.div`
  width: 163px;
  color: #969696;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (163 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (60 / 428));
  }
`;
const Subject = styled.div`
  display: flex;
  align-items: center;
  width: 652px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (652 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const SubjectText = styled.div`
  width: 600px;
  text-align: left;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (600 / 1320));
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (15 / 428));
  }
`;
const User = styled.div`
  width: 169px;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (169 / 1320));
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: auto;
    font-size: calc(100vw * (13 / 428));
    text-align: center;
    margin-right: calc(100vw * (6 / 428));
    padding-right: calc(100vw * (6 / 428));
    border-right: calc(100vw * (1 / 428)) solid #969696;
    &:last-child {
      border-right: 0;
    }
  }
`;
const View = styled(User)`
  width: 141px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (141 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: auto;
    text-align: center;
  }
`;
const Date = styled(User)`
  width: 175px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (175 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: auto;
    text-align: center;
  }
`;
// mobile
const MbTitleWrap = styled.div`
  width: calc(100% - calc(100vw * (60 / 428)));
`;
const MbTextWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: calc(100vw * (12 / 428));
`;
const NoText = styled.p`
  width: 100%;
  text-align: center;
  padding: 100px 0;
  font-size: 15px;
  color: #999;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (100 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (13 / 428));
    padding: calc(100vw * (50 / 428)) 0;
  }
`;
