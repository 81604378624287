import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function SocialLoginPage() {
  const history = useHistory();
  useEffect(() => {
    history.push(`/store/mypage/profile`);
  }, []);

  return <h1>Login</h1>;
}
