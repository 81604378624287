import React, { useState, useEffect } from 'react';
import faker from 'faker';
import { Link, useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import {    
    Layout,
    TabItemsContent,
    TabItemsHeading,
    TabItemConts,
    TabItemImg,
    TabItemDesc,
    TabItemText,
    TabItemLiked,
    TabItemsList
} from './mypage.elements';
import LikedIcon from '../../../images/stylist/liked_icon.svg';
import { imgURL } from '../../../server/appInfoContoller';

const MyPageContentsIntro = ({data, itemData, setActiveIndex}) => {
    const history = useHistory()

    return (
        <Layout style={{marginBottom: 'calc(100vw * (150 / 428))'}}>
          <TabItemsContent>
            <TabItemsHeading>   
                <h3>Contents</h3>
                <div onClick={() => setActiveIndex(1)} style={{cursor: 'pointer'}}>
                    <span>총 {data ? data.length : 0}개</span>
                    <span>더보기</span>
                </div>
            </TabItemsHeading>

            <TabItemsList>
                <Swiper>
                {
                    data.map((v, i) => {
                        const src = v?.contents_thumbnail?.split(",")[0]
                        return (
                            <SwiperSlide key={i}>
                            <TabItemConts onClick={() => history.push(`/content/${v?.contents_id}`)}>
                                <TabItemImg style={{height: "calc(100vw * (258/428))"}}><img src={imgURL + src} /></TabItemImg>                                
                                <TabItemLiked className="item-liked"><img src={LikedIcon}/></TabItemLiked>
                            </TabItemConts>
                            </SwiperSlide>
                        )
                    })
                }
                </Swiper>
            </TabItemsList>
        </TabItemsContent>

        <TabItemsContent>
            <TabItemsHeading>   
                <h3>Item</h3>
                <div onClick={() => setActiveIndex(2)} style={{cursor: 'pointer'}}>
                    <span>총 {itemData ? itemData.length : 0}개</span>
                    <span>더보기</span>
                </div>
            </TabItemsHeading>

            <TabItemsList>
            <Swiper>
                {
                    itemData.map((v, i) => {
                        const src = v?.prd_thumbnail?.split(",")[0]
                        return (
                            <SwiperSlide key={i}>
                            <TabItemConts onClick={() => history.push(`/content/${v?.prd_id}`)}>
                                <TabItemImg style={{height: "calc(100vw * (258/428))"}}><img src={imgURL + src} /></TabItemImg>
                                <TabItemLiked className="item-liked"><img src={LikedIcon}/></TabItemLiked>
                            </TabItemConts>
                            </SwiperSlide>
                        )
                    })
                }
                </Swiper>
            </TabItemsList>
        </TabItemsContent>     
        </Layout>
    )
}

export default MyPageContentsIntro
