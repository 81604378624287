import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Mobile } from "../../MediaQuery";
import styled from "styled-components";

import serverController from "../../server/serverController";
import { UserDataAction } from "../../../store/actionCreators";
import MyPageDefaultModal from "../common/modal/MyPageDefaultModal";
import { useSelector } from "react-redux";

export default function MySetting() {
  const history = useHistory();
  const settingVersionData = useSelector((state) => state.settingVersionData.setting.version);

  const [exitClick, setExitClick] = useState(false);

  const logoutFunc = () => {
    serverController.connectFetchController(
      `logout`,
      "POST",
      null,
      (res) => {},
      (err) => console.error(err)
    );
    if (localStorage.getItem("login-info")) {
      localStorage.removeItem("login-info");
    }
    UserDataAction.logOutRequest();
    history.push("/store");
  };

  const exitClickFunc = () => {
    serverController.connectFetchController(
      `user/exit`,
      "PUT",
      null,
      (res) => {
        if (res.result !== 1)
          return alert("다시 시도해주세요. 문제가 지속될 경우 고객센터로 문의바랍니다.");
        UserDataAction.logOutRequest();
        history.push(`/store`);
      },
      (err) => console.error(err)
    );
  };

  return (
    <Container>
      {exitClick && (
        <MyPageDefaultModal
          text={
            <>
              회원 탈퇴를 하면 모든 활동 내역이 삭제됩니다.
              <br />
              정말 탈퇴하시겠습니까?
            </>
          }
          trueFunc={exitClickFunc}
          falseFunc={() => {
            setExitClick(false);
          }}
        />
      )}

      <Mobile>
        <MyMenuWrap>
          <MenuListArea>
            <MenuListInner>
              <MenuListBox>
                {window.ReactNativeWebView && (
                  <MenuList>
                    <MenuListText>
                      현재버전
                      <Version>{settingVersionData}</Version>
                    </MenuListText>
                  </MenuList>
                )}
                <MenuList>
                  <MenuListText onClick={logoutFunc}>로그아웃</MenuListText>
                </MenuList>
                <MenuList>
                  <MenuListTextExit
                    onClick={() => {
                      setExitClick(true);
                    }}
                  >
                    회원탈퇴
                  </MenuListTextExit>
                </MenuList>
              </MenuListBox>
            </MenuListInner>
          </MenuListArea>
        </MyMenuWrap>
      </Mobile>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
`;

// MyMenuWrap
const MyMenuWrap = styled.div`
  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (217 / 428));
  }
`;

const MenuListArea = styled.ul``;
const MenuListInner = styled.li`
  &:not(:last-child) {
    border-bottom: 8px solid #f9f9f9;
  }
`;

const MenuListBox = styled.ul`
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding-top: calc(100vw * (20 / 428));
  }
`;
const MenuList = styled.li`
  width: 100%;
  overflow: hidden;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (60 / 428));
  }
`;

const MenuListText = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  color: #333;
  font-weight: 500;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding-left: calc(100vw * (10 / 428));
  }
`;
const Version = styled.span`
  color: #777;
  font-weight: 500;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const MenuListTextExit = styled(MenuListText)`
  color: #999999;
`;
