import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Mobile, PC } from '../common/mediaStlye';
import styled from 'styled-components';
import serverController from '../../server/serverController';
import { imgURL } from '../../server/appInfoContoller';
import { useSelector } from 'react-redux';

import {
    Wrapper,
    MultipleMainSlider,
    MultipleMainSlide,
    MultipleMainLeft,
    MultipleMainProducts,
    MultipleMainProduct,
    MultipleMainProductImg,
    MultipleMainProductDesc,
    MultipleMainText,
    MultipleMainRight,
} from './CategoryContents.element';

import Banner from '../../images/category-idol-banner.png';
import BannerProd1 from '../../images/category/category-idol-prod1.png';
import BannerProd2 from '../../images/category/category-idol-prod2.png';
import BannerProd3 from '../../images/category/category-idol-prod3.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import "swiper/swiper-bundle.css";
import "./CategoryMultipleMain.css";
SwiperCore.use([Navigation]);

const CategoryMultipleMain = () => {
    const prodDummys = [BannerProd1, BannerProd2, BannerProd3];

    const { isLogged } = useSelector(state => state.userData);
    const { category_id } = useParams();
    const categoryUrl = () => {
        switch (category_id) {
            case "1":
                return `bannerlist?c_cat_id=${category_id}`;
            case "2":
                return `bannerlist?c_cat_id=${category_id}`;
            case "3":
                return `bannerlist?c_cat_id=${category_id}`;
            case "4":
                return `bannerlist?c_cat_id=${category_id}`;
            default:
                return;
        }
    }

    const [list, setList] = useState([]);
    const getCategoryBanners = async () => {
        await serverController.connectFetchController(
            categoryUrl(),
            "GET",
            null,
            (res) => {
                console.log(res, "아이돌 카테고리 목록 res");
                if (res?.result === 1) {
                    setList(res.list ?? []);
                }
            },
            (err) => console.error(err)
        )
    };

    useEffect(() => {
        getCategoryBanners()
    }, [category_id, isLogged])

    return (
        <Wrapper>
            <MultipleMainSlider className="multiple-main-slider">
                <Swiper
                    navigation
                    loop
                >
                    {
                        list.map((v, i) => {
                            const src = v?.contents_thumbnail?.split(",")[0];

                            return (
                                <SwiperSlide key={v?.contents_id}>
                                    <MultipleMainSlide>
                                        <MultipleMainLeft>
                                            <MultipleMainProducts>
                                                <h4>ITEM</h4>
                                                <ul>
                                                    {v?.products?.map((value, i) => {
                                                        return (
                                                            <MultipleMainProduct key={i}>
                                                                <MultipleMainProductImg>
                                                                    <img src={imgURL + value?.prd_img} alt="" />
                                                                </MultipleMainProductImg>
                                                                <MultipleMainProductDesc>
                                                                    <strong>{value?.prd_name}</strong>
                                                                    <PC>
                                                                        <p>{value?.prd_content}</p>
                                                                    </PC>
                                                                </MultipleMainProductDesc>
                                                            </MultipleMainProduct>
                                                        )
                                                    })}

                                                </ul>
                                            </MultipleMainProducts>
                                            <MultipleMainText>
                                                <h3>{v?.contents_title}</h3>
                                                <p>{v?.contents_teg}</p>
                                            </MultipleMainText>
                                        </MultipleMainLeft>

                                        <MultipleMainRight>
                                            <img src={imgURL + src} alt={v?.contents_intro} />
                                        </MultipleMainRight>
                                    </MultipleMainSlide>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>

            </MultipleMainSlider>
        </Wrapper>
    )
}

export default CategoryMultipleMain
