//react
import { useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import { useState, useEffect } from "react";
import serverController from "../../../server/serverController";
import qs from "qs";

import BestItemProductList from "../../main/BestItemProductList";

//css
import styled from "styled-components";

// img
import IconCheckbox from "../../../img/icon/icon_checkbox.png";
import Zigzag from "../../../img/borderBottom_zigzag.svg";
import ZigzagPcSmall from "../../../img/borderBottom_zigzag_small.svg";

import BrandListContents from "../../brand/BrandListContents";

import MyPageLayOutMenu from "../MyPageLayOutMenu";
import MyPageLayOutName from "../MyPageLayOutName";
import MyPageLayOutInfo from "../MyPageLayOutInfo";
import NewPagination from "../../common/pagination/NewPagination";

export default function MypageWishList(props) {
  const { setLoading } = props;
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const history = useHistory();

  const [hide, setHide] = useState(false);
  const [list, setList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [page, setPage] = useState(query.page ?? 1);
  const [pageData, setPageData] = useState([]);

  // get like items
  const getLikeItems = () => {
    serverController.connectFetchController(
      `products/like?type=1&page=${query.page || 1}&limit=20`,
      "GET",
      null,
      (res) => {
        if (res.result === 1) {
          setPageData(res.pagination);
          setList(res.list);
          setLoading(false);
        }
      }
    );
  };

  // get like brands
  const getLikeBrands = () => {
    serverController.connectFetchController(`brand/like`, "GET", null, (res) => {
      if (res.result === 1) {
        setBrandList(res.data);
      } else {
        return () => {
          alert("잘못된 접근입니다.");
          history.push(`/store/store`);
        };
      }
    });
  };

  useEffect(() => {
    history.push(`/store/mypage/wish?tab=0&page=${page}`);
  }, [page]);

  // header scroll control
  useEffect(() => {
    // s :: scroll down fix
    let lastScrollY = 0;

    window?.addEventListener("scroll", (e) => {
      const scrollY = window?.scrollY;
      const direction = scrollY > lastScrollY;

      lastScrollY = scrollY;

      if (lastScrollY > 130) setHide(direction);
    });
    // e :: scroll down fix
  }, []);

  useEffect(() => {
    getLikeBrands();
  }, []);

  useEffect(() => {
    getLikeItems();
  }, [query.page]);

  return (
    <Container>
      <PC>
        <MyPageLayOutName />
      </PC>
      <FlexLayOut>
        <PC>
          <MyPageLayOutMenu />
        </PC>
        <ContentSection>
          <PC>
            <MyPageLayOutInfo />
          </PC>
          <MypageWishListSection>
            <WishListTabArea hide={hide}>
              <WishListTabBox>
                <WishListTab
                  onClick={() => {
                    history.push(`/store/mypage/wish?tab=0&page=1`);
                    setPage(1);
                  }}
                >
                  상품
                </WishListTab>
                <WishListTab
                  onClick={() => {
                    history.push(`/store/mypage/wish?tab=1`);
                  }}
                >
                  브랜드
                </WishListTab>
                <WishListTabBottom tabIndex={query.tab}>
                  <PC>
                    <WishListTabBottomImg src={ZigzagPcSmall} alt="bottom" />
                  </PC>
                  <Mobile>
                    <WishListTabBottomImg src={Zigzag} alt="zigzag-bottom" />
                  </Mobile>
                </WishListTabBottom>
              </WishListTabBox>
            </WishListTabArea>
            {query.tab === "0" ? (
              <WishList>
                {list?.length > 0 ? (
                  list?.map((listValue, index) => {
                    return (
                      <BestItemProductList
                        key={`${listValue.prd_id}-wishList-${index}`}
                        value={listValue}
                        rank
                        getLikeItems={getLikeItems}
                      />
                    );
                  })
                ) : (
                  <NoText>찜한 상품이 없습니다.</NoText>
                )}
              </WishList>
            ) : (
              <WishBrand>
                {brandList?.length > 0 ? (
                  brandList?.map((brandValue, index) => {
                    return (
                      <BrandListContents
                        value={brandValue}
                        key={`${brandValue?.bcId}-brandList-${index}`}
                        widthOne
                      />
                    );
                  })
                ) : (
                  <NoText>찜한 브랜드가 없습니다.</NoText>
                )}
              </WishBrand>
            )}
          </MypageWishListSection>
          {query.tab === "0" && list?.length > 0 && (
            <NewPagination page={page} setPage={setPage} pageData={pageData} />
          )}
        </ContentSection>
      </FlexLayOut>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 180px 0 200px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const MypageWishListSection = styled.div`
  width: 100%;
  padding-top: 20px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (55 / 428));
  }
`;
const WishLength = styled.p`
  width: 100%;
  margin: 0 auto 10px;
  color: #333333;
  font-weight: 700;
  font-size: 18px;

  @media screen and (max-width: 1320px) {
    margin: 0 auto calc(100vw * (10 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto calc(100vw * (10 / 428));
    font-size: calc(100vw * (16 / 428));
  }
`;

const TitleWrap = styled.div`
  width: 1240px;
  margin-bottom: 25px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (25 / 1320));
    width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding-top: calc(100vw * (30 / 428));
    margin-bottom: calc(100vw * (15 / 428));
  }
`;

// tab
const WishListTabArea = styled.div`
  width: 100%;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  transition: ease 0.3s all;

  @media screen and (max-width: 930px) {
    position: fixed;
    top: calc(100vw * (55 / 428));
    left: 0;
    z-index: 9;
    width: 100%;
    ${({ hide }) =>
      hide &&
      `
      top : 0;
  `}
  }
`;
const WishListTabBox = styled.div`
  position: relative;
  display: flex;
  width: 200px;
  height: 50px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (200 / 1320));
    height: calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    height: calc(100vw * (50 / 428));
    margin: 0 auto;
  }
`;
const WishListTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 2);
  height: 100%;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const WishListTabBottom = styled.div`
  position: absolute;
  left: ${(props) => (props.tabIndex === "0" ? `0` : `100px`)};
  bottom: -7px;
  transition: ease 0.3s all;
  width: 100px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    left: ${(props) => (props.tabIndex === "0" ? `0` : `calc(100vw * (100 / 1320))`)};
    bottom: calc(100vw * (-7 / 1320));
    width: calc(100vw * (100 / 1320));
  }

  @media screen and (max-width: 930px) {
    left: ${(props) => (props.tabIndex === "0" ? `0` : `calc(100vw * (189 / 428))`)};
    bottom: calc(100vw * (-7 / 428));
    width: calc(100vw * (189 / 428));
  }
`;
const WishListTabBottomImg = styled.img`
  width: 100%;
  height: 100%;
`;
const Title = styled.h2`
  font-size: 24px;
  color: #333333;
  font-weight: 700;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
// list
const WishListWrap = styled.div``;
const WishListTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (25 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (15 / 428)) 0;
  }
`;
const WishList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin: 20px auto;

  & .product_box {
    &:not(:nth-child(5n)) {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1320px) {
    margin: calc(100vw * (20 / 1320)) auto;

    & .product_box {
      &:not(:nth-child(5n)) {
        margin-right: calc(100vw * (8 / 1320));
      }
    }
  }

  @media screen and (max-width: 930px) {
    justify-content: space-between;
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (20 / 428)) auto;

    & .product_box {
      &:not(:nth-child(5n)) {
        margin-right: 0;
      }
    }
  }
`;
const WishBrand = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 20px auto;

  @media screen and (max-width: 1320px) {
    margin: calc(100vw * (20 / 1320)) auto;
  }

  @media screen and (max-width: 930px) {
    display: block;
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (20 / 428)) auto;
  }
`;
const ChecksWrap = styled.div`
  width: 260px;
  padding-left: 10px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (260 / 1320));
    padding-left: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    justify-content: space-between;
    width: 100%;
    padding-left: 0;
  }
`;
const InfoTitleWrap = styled.div`
  width: calc(100% - 660px);

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (660 / 1320)));
  }
`;
const PriceTitleWrap = styled.div`
  width: 150px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (150 / 1320));
  }
`;
const DateTitleWrap = styled.div`
  width: 250px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (250 / 1320));
  }
`;
const ProductTit = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #646464;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;
const PriceTit = styled(ProductTit)``;
const Keeptit = styled(ProductTit)``;

// trash
const Trash = styled.div`
  width: 19px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (19 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (19 / 428));
  }
`;
const TrashImg = styled.img`
  width: 100%;
`;
const Bar = styled.div`
  width: 1px;
  height: 19px;
  background: #707070;
  margin: 0 44px 0 35px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1 / 1320));
    height: calc(100vw * (19 / 1320));
    margin: 0 calc(100vw * (44 / 1320)) 0 calc(100vw * (35 / 1320));
  }
`;

//checkbox
const Checks = styled.div`
  height: 24px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (24 / 428));
  }
`;
const ChkBox = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  & + label::after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid #000;
    border-radius: 100%;
  }

  :checked + label::after {
    display: none;
  }

  @media screen and (max-width: 1320px) {
    & + label::after {
      width: calc(100vw * (20 / 1320));
      height: calc(100vw * (20 / 1320));
    }
    &:checked + label::after {
      width: calc(100vw * (20 / 1320));
      height: calc(100vw * (20 / 1320));
      border: calc(100vw * (2 / 1320)) solid #000;
    }
  }

  @media screen and (max-width: 930px) {
    & + label::after {
      width: calc(100vw * (18 / 428));
      height: calc(100vw * (18 / 428));
    }
    &:checked + label::after {
      width: calc(100vw * (18 / 428));
      height: calc(100vw * (18 / 428));
      border: calc(100vw * (2 / 428)) solid #000;
    }
  }
`;
const ChkBoxAllLabel = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 42px;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  &&::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: #000;
    border: 2px solid #000;
    border-radius: 100%;
    background: #000 url(${IconCheckbox}) no-repeat center/14px;
  }

  @media screen and (max-width: 1320px) {
    padding-left: calc(100vw * (42 / 1320));
    font-size: calc(100vw * (16 / 1320));
    &&::before {
      width: calc(100vw * (20 / 1320));
      height: calc(100vw * (20 / 1320));
      border: calc(100vw * (2 / 1320)) solid #000;
      background: #000 url(${IconCheckbox}) no-repeat center/calc(100vw * (14/1320));
    }
  }
  @media screen and (max-width: 930px) {
    padding-left: calc(100vw * (30 / 428));
    font-size: calc(100vw * (14 / 428));
    &&::before {
      width: calc(100vw * (18 / 428));
      height: calc(100vw * (18 / 428));
      border: calc(100vw * (2 / 428)) solid #000;
      background: #000 url(${IconCheckbox}) no-repeat center/calc(100vw * (14/428));
    }
  }
`;
const NoText = styled.p`
  width: 100%;
  padding: 280px 0;
  font-size: 16px;
  text-align: center;
  color: #969696;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (280 / 1320)) 0;
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vh * (190 / 925)) 0 0;
    font-size: calc(100vw * (16 / 428));
  }
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
