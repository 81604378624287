import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { imgURL } from "../../server/appInfoContoller";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { StyleSearchListItem } from "./stylist.element";

const StylistSearchProductItem = ({ v, myList, mydata }) => {
  const history = useHistory();
  const { isLogged } = useSelector((state) => state.userData);
  const moveDetail = (id) => history.push(`/stylist/sponserd/${id}`);

  const src = v.purchase_prd ? imgURL + v?.purchase_prd?.prd_thumbnail : imgURL + v?.prd_thumbnail;

  const name = v?.purchase_prd ? v?.purchase_prd?.prd_name : v?.prd_name;

  const prdId = v?.purchase_prd ? v?.purchase_prd?.prd_id : v?.prd_id;


  const movePage = () => {
    if (!isLogged) {
      alert('협찬/구매상품은 로그인 후 확인 가능합니다.');
      history.push(`/login`);
      return;
    };
    if (mydata) {
      history.push(`/stylist/detail/${v?.purchase_id}`);
    } else {
      history.push(`/stylist/sponserd/${prdId}`);
    }
  };
  return (
    <StyleSearchListItem className="btn_wrap">
      <div className="stylist-spon-thumb">
        <img
          src={src}
          alt={v?.purchase_prd?.prd_name}
          onClick={movePage}
          style={{ cursor: "pointer" }}
        />
      </div>
      <strong>{name}</strong>
    </StyleSearchListItem>
  );
};

export default StylistSearchProductItem;
