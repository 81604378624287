import React, { useState } from 'react';
import { PC, Mobile } from '../../MediaQuery';
import { useSelector } from 'react-redux';
import { DateToText } from '../common/commonUse';

import {
    FieldBox,
    Label,
    LabelName,
    EmailInput,
    PasswordInput,
    TelephoneInput,
    Input
} from '../auth/auth.elements';
import {
    SubCheckButton
} from '../auth/signup/signup.element';
import {
    TabContentHeading,
    TabContent,
    TabUserForm,
    ModifyButton
} from '../auth/mypage/mypage.elements';
import InviteModal from '../common/pop/InviteModal';

const StylistInfoUser = () => {
    const [isPhone, setIsPhone] = useState(false);
    const [isPassword, setIsPassword] = useState(false);
    const [isModifyPop, setIsModifyPop] = useState(false);

    const {
        mem_name, mem_email, mem_phone, mem_register_date, mem_belong
    } = useSelector(state => state.userData.userInfo);

    return (
        <>
            <div>
                <PC>
                    <TabContentHeading>회원정보 수정</TabContentHeading>
                </PC>
                <TabContent>
                    <TabUserForm>
                        <div>
                            <FieldBox>
                                <Label>
                                    <LabelName>아이디</LabelName>
                                    <EmailInput value={mem_email} />
                                </Label>
                            </FieldBox>

                            <FieldBox>
                                <Label>
                                    <LabelName>비밀번호</LabelName>
                                    <PasswordInput placeholder="" value="aaaa" readOnly />
                                </Label>
                                <SubCheckButton onClick={() => setIsPassword(true)}>비밀번호 변경</SubCheckButton>
                            </FieldBox>

                            {
                                isPassword && (
                                    <>
                                        <FieldBox>
                                            <Label>
                                                <LabelName>새 비밀번호</LabelName>
                                                <PasswordInput />
                                            </Label>

                                        </FieldBox>
                                        <FieldBox>
                                            <Label>
                                                <LabelName>새 비밀번호 확인</LabelName>
                                                <PasswordInput placeholder="입력하신 비밀번호를 한번 더 입력해주세요" />
                                            </Label>
                                            <SubCheckButton onClick={() => setIsPassword(false)}>확인</SubCheckButton>
                                        </FieldBox>
                                    </>
                                )
                            }

                            <FieldBox>
                                <Label>
                                    <LabelName>소속</LabelName>
                                    <Input value={mem_belong} readOnly />
                                </Label>
                            </FieldBox>

                            {
                                isPhone && (
                                    <>
                                        <FieldBox>
                                            <Label>
                                                <LabelName>새 연락처</LabelName>
                                                <TelephoneInput placeholder="새 연락처를 입력해주세요." />
                                            </Label>
                                            <SubCheckButton onClick={() => setIsPhone(false)}>인증번호 받기</SubCheckButton>
                                        </FieldBox>

                                        <FieldBox>
                                            <Label>
                                                <LabelName>인증번호</LabelName>
                                                <Input placeholder="인증번호를 입력해주세요." />
                                            </Label>
                                            <SubCheckButton onClick={() => setIsPhone(false)}>확인</SubCheckButton>
                                        </FieldBox>
                                    </>
                                )
                            }





                            <FieldBox>
                                <Label>
                                    <LabelName style={{ color: '#ccc' }} readOnly>계정 생성일</LabelName>
                                    <Input value={DateToText(mem_register_date, ".")} readOnly style={{ color: '#ccc' }} />
                                </Label>
                            </FieldBox>

                            <FieldBox>
                                <Label>
                                    <LabelName style={{ color: '#ccc' }} readOnly>사업자 등록증</LabelName>
                                    <Input value="사업자등록증 증빙 완료" readOnly style={{ color: '#ccc' }} />
                                </Label>
                            </FieldBox>
                        </div>

                        {/* <PC>
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 'calc(100vw *(150 / 1920))'}}>
                        <ModifyButton onClick={() => setIsModifyPop(true)}>초대하기</ModifyButton>
                    </div>
                    </PC>
                    <Mobile>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: 'calc(100vw *(150 / 428))'}}>
                        <ModifyButton onClick={() => setIsModifyPop(true)}>초대하기</ModifyButton>
                    </div>
                    </Mobile> */}

                    </TabUserForm>
                </TabContent>

            </div>
            {isModifyPop && <InviteModal setIsModifyPop={setIsModifyPop} />}
        </>
    )
}

export default StylistInfoUser
