import { useEffect, useState } from "react";
import { Mobile } from "../../MediaQuery";
import serverController from "../../../server/serverController";
import styled from "styled-components";
import { imgURL } from "../../server/appInfoController";
import DefaultImg from "../../img/icon/loading_default_img.svg";
import GrayZone from "../common/GrayZone";
import ContentsDetailProductBox from "./ContentsDetailProductBox";
import FullLoading from "../common/modal/FullLoading";
import NewPagination from "../../component/common/pagination/NewPagination";
import ContentsReviewWrapM from "./ContentsReviewWrapM";
import ContentsYouTubeWrap from "./ContentsYoutubeWrap";
import IconWishOn from "../../img/icon/icon_wish_on.svg";
import IconWishOff from "../../img/icon/icon_wish_off.svg";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ContentsLoginModal from "./ContentsLoginModal";
import MyPageDefaultModal from "../common/modal/MyPageDefaultModal";
import shareIcon from "../../../images/stylist/share-icon.png";
import ContentsShare from "./ContentsShare";
import HtmlParser from 'react-html-parser';

export default function ContentsDetail({ contentsId, setContentsDetail, wishiconRefMobile }) {

  const history = useHistory();
  const location = useLocation();
  const { isLogged } = useSelector((state) => state.userData);

  const [isWish, setIsWish] = useState(false);
  const [loginPop, setLoginPop] = useState(false);

  const [contentsDetailData, setContentsDetailData] = useState([]);
  const [contentsDetailStylingList, setContentsDetailStylingList] = useState([]);
  const [contentsSubImgArr, setContentsSubImgArr] = useState([]);
  const [isContentsLoading, setIsContentsLoading] = useState(false);
  const [sharePop, setSharePop] = useState(false);


  const [video, setVideo] = useState();

  const [inquiryText, setInquiryText] = useState("");
  const [page, setPage] = useState(1);
  const [reviewData, setReviewData] = useState();

  const getDetails = () => {
    serverController.connectFetchController(
      `contents/${contentsId ? contentsId : 0}`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setContentsDetailData(res?.content);
          setContentsDetailStylingList(res?.content?.products);
          setContentsSubImgArr(res?.content.contents_sub?.split(","));
          setIsContentsLoading(false);
          setVideo(res?.content?.contents_video);
          res?.content?.like_count !== 0 ? setIsWish(true) : setIsWish(false);
        }
      }
    );
  };

  // 댓글정보 기존코드 방식으로 불러오는 코드
  const getContentsReview = () => {
    serverController.connectFetchController(`api/content/comment/${contentsId}?page=${page}`, "GET", null, (res) => {
      if (res?.result === 1) {
        // const reverseData = res.data.reverse();
        setReviewData(res?.data);
      }
    });
  };

  const onClickWindowOpenUrl = (url) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "linking",
          value: url,
        })
      );
    } else window.open(url, "_blank");
  };

  useEffect(() => {
    setIsContentsLoading(true);
    getDetails();
  }, [contentsId]);

  const postLikeCount = (contentsId) => {
    serverController.connectFetchController(
      `contentslike/${contentsId}`,
      "POST",
      null,
      (res) => {
        setIsWish(!isWish);
      },
      (err) => { console.log(err) }
    );
  };

  const onHandleLike = (contentsId) => {
    if (!isLogged) return setLoginPop(true);
    postLikeCount(contentsId);
    wishiconRefMobile.current[contentsDetailData.contents_id].classList.toggle('active');
  }

  const loginPopupPush = () => {
    setContentsDetail(false);
    setTimeout(() => {
      if (location) return history.push(`/store/login?returnTo=/store`);
      history.push(`/store/login`);
    }, 0)

  };

  useEffect(() => {
    getContentsReview();
  }, [page]);


  useEffect(() => {
    if (!contentsId) return alert("잘못된 접근입니다.");
    getContentsReview();
  }, [contentsId]);

  return (
    <Mobile>
      {loginPop && (
        <MyPageDefaultModal
          text="로그인 후 이용 가능합니다."
          trueText="로그인"
          trueFunc={loginPopupPush}
          falseFunc={() => setLoginPop(false)}
        />
      )}
      {/* {loginPop && (
        <ContentsLoginModal
          text="로그인 후 이용 가능합니다."
          // trueText="로그인"
          // trueFunc={() => {
          //   console.log(isLogged);
          //   if (location) return history.push(`/store/login?returnTo=/store`);
          //   history.push(`/store/login`);
          // }}
          falseFunc={() => setLoginPop(false)}
        />
      )} */}
      <Container>
        {isContentsLoading && <FullLoading />}
        <ContentsDetailWrap contentsLoading={isContentsLoading}>
          <ContentsDetailBannerBox>
            <ContentsDetailBannerImg
              src={
                contentsDetailData.contents_main
                  ? `${imgURL}${contentsDetailData.contents_main}`
                  : DefaultImg
              }
              alt={contentsDetailData.contents_main ? "contents-main-img" : "default-img"}
            />
            <ContentLike>
              <WishIcon
                src={isWish ? IconWishOn : IconWishOff}
                alt="wish"
                onClick={() => {
                  onHandleLike(contentsDetailData.contents_id);
                }}
              />
            </ContentLike>
            <ContentsDetailBannerImgBackground />
            <ContentsDetailTextWrap>
              <ContentsDetailTextBox>
                <ContentsDetailDate>
                  {contentsDetailData?.contents_date?.replaceAll("/", ". ")}
                </ContentsDetailDate>
                <ContentsDetailTitle>{contentsDetailData.contents_title}</ContentsDetailTitle>
              </ContentsDetailTextBox>
            </ContentsDetailTextWrap>
          </ContentsDetailBannerBox>
          {/* 공유하기 */}
          <ShareSection>
            <ShareIconWrap>
              <ShareIcon
                src={shareIcon}
                alt="shareIcon"
                onClick={() => {
                  setSharePop(!sharePop);
                }}
              />
            </ShareIconWrap>
            {sharePop && <ContentsShare contentsDetailData={contentsDetailData} setSharePop={setSharePop} />}
          </ShareSection>
          {/* <ContentsHashTagBox>
            <ContentsHashTag># 원피스</ContentsHashTag>
            <ContentsHashTag># 원피스</ContentsHashTag>
            <ContentsHashTag># 원피스</ContentsHashTag>
          </ContentsHashTagBox> */}
          <ContentsProductBox>
            {contentsDetailStylingList?.map((contentsStylingEl, idx) => {
              return (
                <ContentsDetailStylingTopBox
                  key={`${contentsStylingEl.contents_id}-${idx}-contentsStyling`}
                >
                  <ContentsDetailStylingTopImgBox
                    onClick={() => onClickWindowOpenUrl(contentsStylingEl.prd_link)}
                  >
                    <ContentsDetailStylingTopImg
                      src={
                        contentsStylingEl.prd_main_img
                          ? `${imgURL}${contentsStylingEl.prd_main_img}`
                          : DefaultImg
                      }
                      alt={contentsStylingEl.prd_main_img ? "contents-styling-list" : "default-img"}
                    />
                  </ContentsDetailStylingTopImgBox>
                  <ContentsDetailStylingTopText>
                    {contentsStylingEl.prd_name}
                  </ContentsDetailStylingTopText>
                </ContentsDetailStylingTopBox>
              );
            })}
          </ContentsProductBox>
          <GrayZone />
          <ContentsDetailBox>
            {contentsSubImgArr?.length > 0 && (
              <ContentsDetailImgBox>
                {/* 유튜브 동영상 추가 */}
                {/* {video && <ContentsYouTubeWrap video={video} />} */}
                {contentsSubImgArr?.map((img, index) => {
                  return (
                    <ContentsDetailImg
                      key={index + "contentImg"}
                      src={img ? `${imgURL}${img}` : DefaultImg}
                      alt={img ? "contents-sub-img" : "default-img"}
                    />
                  );
                })}
                {contentsDetailData?.contents_content && <div>{HtmlParser(contentsDetailData.contents_content)}</div>}
              </ContentsDetailImgBox>
            )}
            <ContentsDetailStylingBox>
              <ContentsDetailStylingTitle>STYLING PRODUCTS</ContentsDetailStylingTitle>
              <ContentsDetailProductArea>
                {contentsDetailStylingList?.map((contentsStylingEl, index) => {
                  return (
                    <ContentsDetailProductBox
                      key={index + "prdBox"}
                      contentsStylingPrd={contentsStylingEl}
                    />
                  );
                })}
              </ContentsDetailProductArea>
            </ContentsDetailStylingBox>
            {/* 댓글자리 */}
            <ContentsReviewWrapM clickContentsId={contentsId} reviewData={reviewData} setContentsDetail={setContentsDetail} setReviewData={setReviewData} getContentsReview={getContentsReview} page={page} setPage={setPage} />
          </ContentsDetailBox>
        </ContentsDetailWrap>
      </Container>
    </Mobile>
  );
}

const Container = styled.div`
  display: none;
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  z-index: 101;
  @media screen and (max-width: 930px) {
    display: block;
    padding-top: calc(100vw * (57 / 428));
    min-height: calc(100vw * (500 / 428));
    /* width: calc(100vw * (378 / 428)); */
  }
`;
const ContentsDetailWrap = styled.div`
  transition: ease 0.3s all;
  ${({ contentsLoading }) => (contentsLoading ? `display : none;` : `display : block`)}
`;
const ContentsDetailBannerBox = styled.div`
  position: relative;
  @media screen and (max-width: 930px) {
    height: calc(100vw * (340 / 428));
    width: 100%;
  }
`;
const ContentsDetailBannerImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
const ContentsDetailBannerImgBackground = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(transparent, #000);
  opacity: 0.7;
  @media screen and (max-width: 930px) {
    height: calc(100vw * (120 / 428));
  }
`;
const ContentsDetailTextWrap = styled.div`
  position: relative;
  margin: 0 auto;
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const ContentsDetailTextBox = styled.div`
  position: absolute;
  left: 0;
  @media screen and (max-width: 930px) {
    bottom: calc(100vw * (20 / 428));
  }
`;
const ContentsDetailDate = styled.div`
  color: #dddddd;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    line-height: calc(100vw * (21 / 428));
  }
`;

// 좋아요
const ContentLike = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`
const WishIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain !important;
`


// 공유
const ShareSection = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 10px;
  border-bottom: 1px solid #eee;

  @media screen and (max-width: 1320px){
    height: calc(100vw * (50 / 1320));
    padding: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px){
    height: calc(100vw * (50 / 428));
    padding: calc(100vw * (10 / 428)) calc(100vw * (20 / 428));
  }
`
const ShareIconWrap = styled.div`
  width: 22px;
  height: 22px;
  margin-right: 10px;
  cursor: pointer;

  @media screen and (max-width: 1320px){
    width: calc(100vw * (22 / 1320));
    height: calc(100vw * (22 / 1320));
    margin-right: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px){
    width: calc(100vw * (22 / 428));
    height: calc(100vw * (22 / 428));
  }
`
const ShareIcon = styled.img`
  width: 100%;
  height: 100%;
`


// const ContentLike = styled.div`
//   margin: 10px;
//   @media screen and (max-width: 1320px) {
//     margin: calc(100vw * (10 / 1320)) 0 0;
//   }
//   @media screen and (max-width: 930px) {
//     margin: 0;
//     padding: calc(100vw * (12 / 428)) calc(100vw * (15 / 428)) calc(100vw * (12 / 428)) calc(100vw * (20 / 428));
//     border-bottom : 1px solid #eee;
//   }
// `
// const WishIcon = styled.img`
//   width: 24px;
//   height: 24px;
//   @media screen and (max-width: 1320px) {
//     width: calc(100vw * (24 / 1320));
//     height: calc(100vw * (24 / 1320));
//   }
//   @media screen and (max-width: 930px) {
//     width: calc(100vw * (24 / 428));
//     height: calc(100vw * (24 / 428));
//     padding-top: 6px;
//   }
// `


const ContentsHashTagBox = styled.div`
  display: flex;
  margin: 0 auto;
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding-top: calc(100vw * (15 / 428));
  }
`;

const ContentsDetailTitle = styled.div`
  color: #ffffff;
  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (5 / 428));
    font-size: calc(100vw * (22 / 428));
    line-height: calc(100vw * (25 / 428));
  }
`;
const ContentsHashTag = styled.div`
  color: #333;
  border: 1px solid #dddddd;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    line-height: calc(100vw * (21 / 428));
    padding: calc(100vw * (7 / 428)) calc(100vw * (12 / 428));
    margin-right: calc(100vw * (6 / 428));
  }
`;
const ContentsProductBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (24 / 428)) calc(100vw * (15 / 428)) calc(100vw * (24 / 428))
      calc(100vw * (20 / 428));
    gap: calc(100vw * (10 / 428));
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
const ContentsDetailStylingTopBox = styled.div`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
  }
`;
const ContentsDetailStylingTopImgBox = styled.div`
  border: 1px solid #f8f8f8f8;
  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
    height: calc(100vw * (120 / 428));
    border-width: calc(100vw * (1 / 428));
  }
`;
const ContentsDetailStylingTopImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ContentsDetailStylingTopText = styled.div`
  color: #333333;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    padding-top: calc(100vw * (5 / 428));
  }
`;

const ContentsDetailImgBox = styled.div``;
const ContentsDetailImg = styled.img`
  width: 100%;
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 428));
    }
  }
`;
// 유튜브
const ContentsYoutubeBox = styled.div`
  width: 100%;
  height: 680px;
  margin-bottom: 10px;
  
  @media screen and (max-width: 930px) {
    height: calc(100vw * (680 / 930));
    margin-bottom: calc(100vw * (10 / 930));
  }
`;
const ContentsYoutube = styled.embed`
  width: 100%;
  height: 100%;
`;
const ContentsDetailBox = styled.div`
  margin: 0 auto;
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (100 / 428));
  }
`;

const ContentsDetailStylingBox = styled.div`
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (50 / 428));
  }
`;
const ContentsDetailStylingTitle = styled.span`
  position: relative;
  color: #222222;
  font-weight: 700;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #fdf251;
    z-index: -1;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    padding: 0 calc(100vw * (7 / 428));

    &::before {
      content: "";
      bottom: calc(100vw * (-2 / 428));
      height: calc(100vw * (12 / 428));
    }
  }
`;

const ContentsDetailProductArea = styled.div`
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (16 / 428));
  }
`;

// review
const ContentsDetailReviewWrap = styled.div`
  margin-top: calc(100vw * (20 / 428));
`
const ContentsDetailReviewTitle = styled.div`
  width: 100%;
  margin-bottom: 22px;

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (22 / 428));
  }
`
const ReviewTitleText = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #333333;


  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
  }
`
// textarea
const ContentsTextAreaWrap = styled.div`
  position: relative;
`;
const ContentsTextArea = styled.textarea`
  width: 100%;
  height: 100px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  padding: 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 930px) {
    height: calc(100vw * (200 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (15 / 428)) calc(100vw * (20 / 428));
  }
`;
const SubmitBtnWrap = styled.div`
  width: 100px;
  height: 50px;
  margin: 10px auto 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 428));
    height: calc(100vw * (50 / 428));
    margin: calc(10vw * (100 / 428)) auto 0;
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (80 / 428));
    margin: 0;
  }
`;
const Btn = styled.button`
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 428));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;

// review list
const CommentWrap = styled.div`
  width: 100%;
  &::after {
    content: "";
    display: block;
    height: 1px;
    margin: 0 auto;
    background-color: #eee;
  }
`;
const CommentInner = styled.div`
  padding: 18px 0 20px;

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (10 / 428)) 0 calc(100vw * (10 / 428));
  }
`
const CommentTop = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0 auto 8px;
`;
const CommentLeftUserName = styled.span`
  font-size: 15px;
  color: #333;
  margin-right: 12px;
  padding-right: 12px;
  border-right: 1px solid #ddd;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-right: calc(100vw * (12 / 428));
    padding-right: calc(100vw * (12 / 428));
  }
`;
const CommentLeftDate = styled.div`
  font-size: 15px;
  color: #999;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const CommentText = styled.p`
  font-size: 15px;
  
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;


