import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Container, MypageHead, PageButton } from "./Header.elements";

import mypageIcon from "../../../images/page-button.png";

const MyPageHeader = ({ title, activeIndex }) => {
  const history = useHistory();
  const aa = [0, "CONTENT", "ITEM"];
  return (
    <HeaderWrap>
      <Container style={{ borderBottom: "1px solid #BEBEBE" }}>
        <MypageHead>
          <PageButton onClick={() => history.goBack()}>
            <img src={mypageIcon} />
          </PageButton>
          <p>{activeIndex > 0 ? aa[activeIndex] : title}</p>
        </MypageHead>
      </Container>
    </HeaderWrap>
  );
};

export default MyPageHeader;

const HeaderWrap = styled.div`
  @media screen and (max-width: 1024px) {
    margin-bottom: calc(100vw * (50 / 428));
  }
`;
