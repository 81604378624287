import React, { useState } from 'react';
import { DateToText } from '../../common/commonUse';

import {    
    TabContentFaqRow,
    TabContentRow,
    TabFaqQuestion,
    TabFaqText,
    TabFaqAnswerText,
    TabFaqAnswerd
} from './mypage.elements';

import answerArrowIcon from '../../../images/signup/answer-arrow.png';

const TabFaqItem = ({ i, data }) => {
    const [isOpen, setIsOpen] = useState(false)

    const State = (data) => {
        switch (data) {
            case 1: 
                return (
                    <span style={{color: "red"}}>NO</span>
                )
            case 2:
                return (
                    <span style={{color: "green"}}>YES</span>
                )
            default:
                break;
        }
    }
    console.log(data)

    return (        
        <TabContentFaqRow>
            <TabContentRow onClick={() => setIsOpen(!isOpen)}>
                <div className="faq-date">{DateToText(data?.inq_date, ".")}</div>                                       
                <div className="faq-question">{data?.inq_title}</div>                                       
                <div className="faq-answerd">{State(data?.inq_answer)}</div>                  
            </TabContentRow>            
            
            {/* 문의 답변이 없으면 !isOpen */}
            {
                 isOpen ?
                    <>
                        <TabFaqQuestion>
                            <TabFaqText>{data?.inq_content}</TabFaqText>                           
                        </TabFaqQuestion>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            {
                                data.inq_answer !== 1 && (
                                    <TabFaqAnswerd>
                                        <TabFaqAnswerText className="faq-answer">
                                            <div className="faq-answer-head">왈라 관리자</div>
                                            <div className="faq-answer-content">
                                                <div className="faq-answer-icon"><img src={answerArrowIcon} /></div>
                                                <p>{data.inq_answer_content}</p>
                                            </div>
                                        </TabFaqAnswerText>
                                    </TabFaqAnswerd>
                                )
                            }                        
                        </div>
                    </>
                :
                null
            }
        </TabContentFaqRow>               
    )
}

export default TabFaqItem
