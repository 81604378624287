import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import serverController from "../../store_src/MediaQuery";
import { Mobile, PC } from "MediaQuery";
import { Get } from "api/index";
import { queryKeys } from "constants/queryKeys";
import useAllPlanInfiniteScroll from "hooks/useAllPlanInfiniteScroll";
import PlanPrdList from "newComponents/storePlan/PlanPrdList";
import InfiniteScroll from "react-infinite-scroller";

export default function StoreAllPlanProductList({ planData, children, scrollContainerRef }) {
  const location = useLocation();
  const body = document.querySelector("body");

  const eventCategoryId = planData.eventCategoryList[0].eventCategoryId;
  const eventCategoryIdArr = planData.eventCategoryList.map((obj) => obj.eventCategoryId);

  useEffect(() => {
    body.style.overflow = "visible";
    console.log(eventCategoryIdArr);
  }, []);

  const requestData = {
    requestAPI: Get.getPlanAllList,
    requestQueryString: {
      // eventCategoryId: eventCategoryIdArr[0],
      // lastCategoryId: eventCategoryIdArr[eventCategoryIdArr.length - 1],
      eventCategoryIdArr: eventCategoryIdArr,
    },
    queryKey: [queryKeys.STORE_PLAN_ALL_LIST, eventCategoryIdArr[0]],
    options: {
      retry: 0,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      keepPreviousData: true,
    },
  };

  const { data, hasNextPage, fetchNextPage, isError, isFetching, isLoading, isSuccess, refetch } =
    useAllPlanInfiniteScroll(requestData);
  console.log("data: ", data);

  return (
    <Container>
      <PlanNavWrap>{children}</PlanNavWrap>
      {isSuccess && (
        <PlanInner
          ref={scrollContainerRef}
          className="infinite-scroll-wrapper"
          loadMore={() => {
            console.log("나와야됨");
            fetchNextPage();
          }}
          hasMore={hasNextPage}
          threshold={450}
        >
          {data.pages.map((arr, i) => {
            return (
              <div key={i}>
                {/* {arr.eventCategory !== null && ( */}
                <PlanListTitle>{arr.eventCategory.eventCategoryTitle}</PlanListTitle>
                {/* )} */}
                <PlanListWrap>
                  {arr.eventProductList.map((product, index) => (
                    <PlanPrdList key={index} value={product} />
                  ))}
                </PlanListWrap>
                <GrayZone />
              </div>
            );
          })}
        </PlanInner>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: auto;
  scroll-margin-top: 300px;
  @media screen and (max-width: 1320px) {
    scroll-margin-top: calc(100vw * (300 / 1320));
  }

  @media screen and (max-width: 930px) {
    scroll-margin-top: calc(100vw * (120 / 428));
  }
`;

const PlanNavWrap = styled.div`
  position: sticky;
  max-width: 1280px;
  margin: auto;
  width: 100%;
  top: 109px;
  z-index: 4;

  @media screen and (max-width: 1320px) {
    top: calc(100vw * (109 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: calc(100vw * (55 / 428));
  }
`;
const PlanInner = styled(InfiniteScroll)`
  margin: 0 auto;
  padding-bottom: 20px;

  & .product_box {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (20 / 1320));

    & .product_box {
      &:not(:last-child) {
        margin-right: calc(100vw * (8 / 1320));
      }
    }
  }

  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (20 / 428));

    & .product_box {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
`;

const PlanListWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 20px;
  min-height: 500px;

  & .product_box {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (20 / 1320));

    & .product_box {
      &:not(:last-child) {
        margin-right: calc(100vw * (8 / 1320));
      }
    }
  }

  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (20 / 428));
    justify-content: space-between;
    width: calc(100vw * (378 / 428));
    & .product_box {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
`;

const PlanListTitle = styled.h1`
  font-size: 20px;
  font-weight: 700;
  padding: 50px 0 30px;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
    padding: calc(100vw * (50 / 1320)) 0 calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    padding: calc(100vw * (50 / 428)) 0 calc(100vw * (30 / 428));
    text-align: center;
  }
`;

const GrayZone = styled.div`
  width: 100%;
  height: 10px;
  background-color: #f9f9f9;
`;
