import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import serverController from "../../../server/serverController";
import {
  Container,
  Layout,
  AuthIntroMessage,
  LabelName,
  FindTelInput,
  FindEmailInput,
  FindTelNumInput,
  InputWrap,
  BlackButton,
  WhiteButton,
  InfoText,
  FindTelValidationWrap,
  FindTelValidationInputBox,
  Time,
  AlertBox,
} from "./auth.elements";

export default function FindPW({ returnTo }) {
  const history = useHistory();

  const [isBtn, setIsBtn] = useState(true);
  const [isShowPW, setIsShowPW] = useState(false);
  const [isCorrect, setIsCorrect] = useState(true);
  const [isTimer, setIsTimer] = useState(false);
  const [isNan, setIsNan] = useState(false);
  const [isFindPw, setIsFindPw] = useState(true);

  const [telValue, setTelValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [num, setNum] = useState("");
  const [numValue, setNumValue] = useState("");

  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);
  const [isButtonClick, setIsButtonClick] = useState(false);

  const postNewPWNum = () => {
    serverController.connectFetchController(
      `send/pw?mem_email=${emailValue}&mem_phone=${telValue}&code=${numValue}`,
      "POST",
      null,
      (res) => {
        if (res?.result === 1) {
          setIsTimer(false);
          setIsBtn(false);
          setIsCorrect(true);
          setIsShowPW(true);
        } else {
          return alert("비밀번호 찾기 오류입니다. 다시 시도해주세요.");
        }
      },
      (err) => console.error(err)
    );
  };

  const postFindPWNum = () => {
    if (emailValue.length === 0) return;
    if (telValue.length === 0) return;
    serverController.connectFetchController(
      `find/pw?mem_email=${emailValue}&mem_phone=${telValue}`,
      "POST",
      null,
      (res) => {
        if (res.result === 1) {
          setNum(res.code);
          setIsBtn(false);
          setIsTimer(true);
          setMinutes(3);
          setSeconds(0);
          setIsCorrect(true);
        } else {
          setIsFindPw(false);
        }
      },
      (err) => console.error(err)
    );
  };

  const handleTimerRefetch = () => {
    let timeOut = setTimeout(() => {
      setIsShowPW(false);
      clearTimeout(timeOut);
    }, 3000);
  };

  const handleTimerSetting = () => {
    if (minutes === undefined || minutes === NaN) {
      setMinutes("00");
    }
    if (seconds === undefined || seconds === NaN) {
      setSeconds("00");
    }
    if (seconds * 1 === 0 && minutes * 1 === 0 && isNan === false) {
      setIsNan(true);
    }
    if (seconds * 1 === 0 && minutes * 1 === 0 && isNan === true) {
      setIsNan(false);
      setIsBtn(true);
      setNumValue("");
      setIsTimer(false);
      alert("인증번호 유효 기간이 끝났습니다. 다시 시도해주세요.");
    }
  };

  const onClickFindPW = () => {
    if (isButtonClick) return;
    if (num !== numValue) {
      setIsCorrect(false);
    } else {
      postNewPWNum();
      setIsButtonClick(true);
    }
  };

  useEffect(() => {
    if (!isTimer) return;

    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1);
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(parseInt(minutes) - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [isTimer, minutes, seconds]);

  useEffect(() => {
    handleTimerRefetch();
  }, [isShowPW]);

  useEffect(() => {
    handleTimerSetting();
  }, [minutes, seconds]);

  return (
    <React.Fragment>
      <Container>
        <AlertBox show={isShowPW}>임시 비밀번호가 문자로 발송되었습니다.</AlertBox>
        <Layout>
          <LoginBox>
            <AuthIntroMessage>
              <AuthTitle>비밀번호 찾기</AuthTitle>
            </AuthIntroMessage>
            <InputWrap>
              <InputEmailBox>
                <LabelName>이메일</LabelName>
                <FindEmailInput
                  findPwIs={isFindPw}
                  value={emailValue}
                  onChange={(e) => {
                    if (40 < e.target.value.length) return;
                    setEmailValue(e.target.value);
                    setIsFindPw(true);
                  }}
                />
              </InputEmailBox>
            </InputWrap>
            <InputWrap>
              <LabelName>휴대폰번호</LabelName>
              <FindTelInput
                type="number"
                findPwIs={isFindPw}
                value={telValue}
                onChange={(e) => {
                  if (11 < e.target.value.length) return;
                  setTelValue(e.target.value);
                  setIsFindPw(true);
                }}
              />
              {isFindPw === false && (
                <InfoText>가입되어 있지 않은 이메일 혹은 휴대폰번호입니다.</InfoText>
              )}
            </InputWrap>
            {!isBtn ? (
              <InputWrap>
                <FindTelValidationWrap>
                  <FindTelValidationInputBox>
                    <FindTelNumInput
                      type="number"
                      value={numValue}
                      onChange={(e) => {
                        if (e.target.value.length > 10) return;
                        setNumValue(e.target.value);
                      }}
                      typeof="number"
                    />
                    <Time>
                      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </Time>
                  </FindTelValidationInputBox>
                  <WhiteButton onClick={postFindPWNum}>재전송</WhiteButton>
                </FindTelValidationWrap>
                {!isCorrect && <InfoText>인증번호가 일치하지 않습니다.</InfoText>}
                <BlackButtonSend
                  activity={numValue.length === 0 || isButtonClick}
                  onClick={onClickFindPW}
                >
                  {isButtonClick ? "전송완료" : "확인"}
                </BlackButtonSend>
              </InputWrap>
            ) : (
              <React.Fragment>
                <BlackButton onClick={postFindPWNum} activity={telValue.length === 0}>
                  인증번호 전송
                </BlackButton>
              </React.Fragment>
            )}

            <LoginBottom>
              <WhiteButton onClick={() => history.push(`/store/login?returnTo=${returnTo}`)}>
                로그인
              </WhiteButton>
            </LoginBottom>
          </LoginBox>
        </Layout>
      </Container>
    </React.Fragment>
  );
}

const LoginBox = styled.div``;
const AuthTitle = styled.span`
  font-size: 26px;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (26 / 428));
  }
`;
const InputEmailBox = styled.div`
  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (5 / 428));
  }
`;
const BlackButtonSend = styled(BlackButton)`
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (10 / 428));
  }
`;

const LoginBottom = styled.div`
  margin-top: 60px;

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (60 / 428));
  }
`;
