//react
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";
import qs from "qs";
import { Mobile, PC } from "../../../MediaQuery";

// controller
import serverController, { ip } from "../../../server/serverController";

//img
import IconSelectArrow from "../../../img/icon/icon_bottom_arrow.svg";
import IconClose from "../../../img/icon/icon_popup_close.svg";
import SelectBoxArrow from "../../../img/icon/icon_selectBox_arrow.svg";

// modal
import MyProductInquiryDetailModal from "../../common/modal/MyProductInquiryDetailModal";
import MyPageDefaultModal from "../../common/modal/MyPageDefaultModal";
import { imgURL } from "../../../server/appInfoController";

// component
import MyPageLayOutMenu from "../MyPageLayOutMenu";
import MyPageLayOutName from "../MyPageLayOutName";
import MyPageLayOutInfo from "../MyPageLayOutInfo";
import NewPagination from "../../common/pagination/NewPagination";

const filterPItems = [
  { title: "1개월", value: "month" },
  { title: "3개월", value: "3month" },
  { title: "6개월", value: "6month" },
  { title: "1년", value: "year" },
];
const filterTItems = [
  { title: "전체문의", value: "11" },
  { title: "상품문의", value: "1" },
  { title: "재입고 문의", value: "2" },
  { title: "사이즈문의", value: "3" },
  { title: "배송문의", value: "4" },
];

export default function MyProductInquiry({ setLoading }) {
  //페이지 이동
  const history = useHistory();
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const [filterShowF, setFilterShowF] = useState(false);
  const [filterShowS, setFilterShowS] = useState(false);
  const [inquiryRemove, setInquiryRemove] = useState(false);
  const [inquiryDetail, setInquiryDetail] = useState([]);
  const [clickInqId, setClickInqId] = useState(0);
  const [inquiryList, setInquiryList] = useState([]);

  const [clickP, setClickP] = useState(0);
  const [clickT, setClickT] = useState(0);

  // page
  const [pageData, setPageData] = useState([]);
  const [page, setPage] = useState(query.page ?? 1);

  const [detailShow, setDetailShow] = useState(false);

  // select
  const [option, setOption] = useState(false);

  // get inquiry list
  const getInquiryList = (type = "11", period = "month", page = false) => {
    serverController.connectFetchController(
      `inquiry/store?type=${type}&period=${period}&page=${page ? 1 : query.page}&limit=5`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setPageData(res.pagination);
          setInquiryList(res.inquiryList);
        }
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  // get inquiry detail info
  const getInquiryDetail = (inq_id) => {
    serverController.connectFetchController(`inquiry/${inq_id}`, "GET", null, (res) => {
      if (res.result === 1) {
        const detailGet = res.inquiry;
        setInquiryDetail(detailGet);
        setDetailShow(true);
      }
    });
  };

  // delete review
  const deleteInquiryItem = () => {
    var header = new Headers();
    header.append("Content-Type", "application/json");
    var rawBody = JSON.stringify({
      inq_id: clickInqId.toString(),
    });

    var options = {
      method: "DELETE",
      body: rawBody,
      headers: header,
      redirect: "follow",
    };

    fetch(`${ip}/inquiry/store`, options)
      .then((response) => response.json())
      .then((result) => {
        if (result.result === 1) {
          setInquiryList([]);
          getInquiryList(filterTItems[clickT].value, filterPItems[clickP].value);
          setInquiryRemove(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    history.push(`/store/mypage/productInquiry?page=${page}`);
  }, [page]);

  useEffect(() => {
    getInquiryList(filterTItems[clickT].value, filterPItems[clickP].value);
  }, [query.page]);

  useEffect(() => {
    if (detailShow) {
      setClickP(0);
      setClickT(0);
    }
  }, [detailShow]);

  const detailModalItems = {
    setDetailShow,
    inquiryDetail,
    setInquiryList,
    getInquiryList,
  };

  return (
    <Container>
      {detailShow && <MyProductInquiryDetailModal {...detailModalItems} />}

      {inquiryRemove && (
        <MyPageDefaultModal
          text={"해당 문의를 삭제하시겠습니까?"}
          trueFunc={deleteInquiryItem}
          falseFunc={() => setInquiryRemove(false)}
        />
      )}

      {/* page */}
      <PC>
        <MyPageLayOutName />
      </PC>

      {/* content */}
      <FlexLayOut>
        <PC>
          <MyPageLayOutMenu />
        </PC>

        <ContentSection>
          <PC>
            <MyPageLayOutInfo />
          </PC>

          {/* 문의 내역 */}
          <Wrap>
            <AllBrandFilterWrap>
              <PC>
                <AllBrandFilter>
                  <AllBrandFilterDateListWrap>
                    {filterPItems.map((itemP, idx) => {
                      return (
                        <AllBrandFilterDateButton
                          type="button"
                          key={itemP.value}
                          active={clickP === idx}
                          onClick={() => {
                            setClickP(idx);
                            setInquiryList([]);
                            getInquiryList(filterTItems[clickT].value, itemP.value);
                          }}
                        >
                          {itemP.title}
                        </AllBrandFilterDateButton>
                      );
                    })}
                  </AllBrandFilterDateListWrap>
                  <SelectBoxWrap
                    tabIndex="0"
                    onBlur={() => {
                      setOption(false);
                    }}
                  >
                    <SelectedButton
                      onClick={() => {
                        setOption(!option);
                      }}
                    >
                      <SelectedButtonText active={option && true}>
                        {filterTItems[clickT].title}
                      </SelectedButtonText>
                    </SelectedButton>
                    <SelectedArrowImg
                      onClick={() => {
                        setOption(!option);
                      }}
                      active={option && true}
                      src={SelectBoxArrow}
                      alt="selectArrow"
                    />
                    {option ? (
                      <SelectListBox>
                        {filterTItems.map((itemT, idx) => {
                          return (
                            <SelectBtn
                              key={itemT.value}
                              onClick={() => {
                                setClickT(idx);
                                setOption(false);
                                setInquiryList([]);
                                getInquiryList(itemT.value, filterPItems[clickP].value);
                              }}
                            >
                              {itemT.title}
                            </SelectBtn>
                          );
                        })}
                      </SelectListBox>
                    ) : null}
                  </SelectBoxWrap>
                </AllBrandFilter>
              </PC>
              <Mobile>
                <AllBrandFilter>
                  <AllBrandButton
                    onClick={() => {
                      setFilterShowF(!filterShowF);
                    }}
                  >
                    <AllBrandText className="text">{filterPItems[clickP].title}</AllBrandText>
                    <AllBrandImgBox className="img-box">
                      <img src={IconSelectArrow} alt="" />
                    </AllBrandImgBox>
                  </AllBrandButton>
                  <AllBrandButton
                    onClick={() => {
                      setFilterShowS(!filterShowS);
                    }}
                  >
                    <AllBrandText className="text">{filterTItems[clickT].title}</AllBrandText>
                    <AllBrandImgBox className="img-box">
                      <img src={IconSelectArrow} alt="" />
                    </AllBrandImgBox>
                  </AllBrandButton>
                </AllBrandFilter>
              </Mobile>
            </AllBrandFilterWrap>

            <ContentsWrap>
              {inquiryList.length > 0 ? (
                <Contents>
                  <ListBox>
                    {inquiryList.map((inquiryV) => {
                      return (
                        <List key={inquiryV.inq_id}>
                          <ListInner>
                            <ProductInfoWrap>
                              <ImgBox>
                                <img src={`${imgURL}${inquiryV.prd_thumbnail}`} alt="product-img" />
                              </ImgBox>
                              <TextBox>
                                <InquirySatisfactionBox>
                                  <InquirySatisfactionList>
                                    <InquiryDate>
                                      {inquiryV.inq_date.split("T")[0].replaceAll("-", ".")}
                                    </InquiryDate>
                                  </InquirySatisfactionList>
                                  <InquirySatisfactionList
                                    onClick={() => {
                                      getInquiryDetail(inquiryV.inq_id);
                                    }}
                                  >
                                    <InquirySatisfaction complete={inquiryV.inq_answer !== 1}>
                                      {inquiryV.inq_answer === 1 ? "답변대기중 " : "답변완료 "}
                                      &gt;
                                    </InquirySatisfaction>
                                  </InquirySatisfactionList>
                                </InquirySatisfactionBox>

                                <Brand>{inquiryV.bc_title || "(BRAND)"}</Brand>
                                <ProductName>{inquiryV.prd_name || "(상품 이름 없음)"}</ProductName>
                              </TextBox>
                            </ProductInfoWrap>
                            <InquiryTextWrap>
                              <PC>
                                <InquiryTextInner
                                  onClick={() => {
                                    history.push(
                                      `/store/mypage/productInquiry/detail?inqId=${inquiryV.inq_id}`
                                    );
                                  }}
                                >
                                  <InquiryText>
                                    [
                                    {inquiryV.inq_type === 1
                                      ? "상품문의"
                                      : inquiryV.inq_type === 2
                                      ? "재입고 문의"
                                      : inquiryV.inq_type === 3
                                      ? "사이즈 문의"
                                      : inquiryV.inq_type === 4
                                      ? "배송문의"
                                      : null}
                                    ] {inquiryV.inq_title}
                                  </InquiryText>
                                  {inquiryV.inq_images && (
                                    <InquiryImgBoxList>
                                      {inquiryV.inq_images?.split(",") &&
                                        inquiryV.inq_images?.split(",")?.length > 0 &&
                                        inquiryV.inq_images.split(",").map((el, idx) => {
                                          return (
                                            <InquiryImgBox>
                                              <img
                                                key={`${idx}-imgB-${idx}`}
                                                src={`${imgURL}${el}`}
                                                alt="imgBox"
                                              />
                                            </InquiryImgBox>
                                          );
                                        })}
                                    </InquiryImgBoxList>
                                  )}
                                </InquiryTextInner>
                              </PC>
                              <Mobile>
                                <InquiryTextInner
                                  onClick={() => {
                                    getInquiryDetail(inquiryV.inq_id);
                                  }}
                                >
                                  <InquiryText>
                                    [
                                    {inquiryV.inq_type === 1
                                      ? "상품문의"
                                      : inquiryV.inq_type === 2
                                      ? "재입고 문의"
                                      : inquiryV.inq_type === 3
                                      ? "사이즈 문의"
                                      : inquiryV.inq_type === 4
                                      ? "배송문의"
                                      : null}
                                    ] {inquiryV.inq_title}
                                  </InquiryText>
                                  {inquiryV.inq_images && (
                                    <InquiryImgBoxList>
                                      {inquiryV.inq_images?.split(",") &&
                                        inquiryV.inq_images?.split(",")?.length > 0 &&
                                        inquiryV.inq_images.split(",").map((el, idx) => {
                                          return (
                                            <InquiryImgBox>
                                              <img
                                                key={`${idx}-imgB-${el}`}
                                                src={`${imgURL}${el}`}
                                                alt="inquiry-img-box"
                                              />
                                            </InquiryImgBox>
                                          );
                                        })}
                                    </InquiryImgBoxList>
                                  )}
                                </InquiryTextInner>
                              </Mobile>
                            </InquiryTextWrap>
                            <InquiryDeleteButton
                              type="button"
                              onClick={() => {
                                setInquiryRemove(true);
                                setClickInqId(inquiryV.inq_id);
                              }}
                            >
                              삭제
                            </InquiryDeleteButton>
                          </ListInner>
                        </List>
                      );
                    })}
                  </ListBox>
                </Contents>
              ) : (
                <Contents>
                  <NoneText>작성한 문의내역이 없습니다.</NoneText>
                </Contents>
              )}
              {inquiryList.length > 0 && (
                <NewPagination pageData={pageData} page={page} setPage={setPage} />
              )}
            </ContentsWrap>
          </Wrap>
        </ContentSection>
      </FlexLayOut>
      {filterShowF && (
        <Mobile>
          <ModalBg
            onClick={() => {
              setFilterShowF(false);
            }}
          />
          <FilterListModal>
            <ModalWrap>
              <ModalUl>
                {filterPItems.map((itemP, idx) => {
                  return (
                    <ModalLi
                      key={itemP.value}
                      active={clickP === idx}
                      onClick={() => {
                        setClickP(idx);
                        setInquiryList([]);
                        setPage(1);
                        getInquiryList(filterTItems[clickT].value, itemP.value, true);
                      }}
                    >
                      {itemP.title}
                    </ModalLi>
                  );
                })}
              </ModalUl>
              <ModalCloseButton
                onClick={() => {
                  setFilterShowF(false);
                }}
              >
                닫기
              </ModalCloseButton>
            </ModalWrap>
          </FilterListModal>
        </Mobile>
      )}
      {filterShowS && (
        <Mobile>
          <ModalBg
            onClick={() => {
              setFilterShowS(false);
            }}
          />
          <FilterListModal>
            <ModalWrap className="modal-wrap">
              <ModalUl>
                {filterTItems.map((itemT, idx) => {
                  return (
                    <ModalLi
                      key={itemT.value}
                      active={clickT === idx}
                      onClick={() => {
                        setClickT(idx);
                        setInquiryList([]);
                        setPage(1);
                        getInquiryList(itemT.value, filterPItems[clickP].value, true);
                      }}
                    >
                      <span>{itemT.title}</span>
                    </ModalLi>
                  );
                })}
              </ModalUl>
              <ModalCloseButton
                onClick={() => {
                  setFilterShowS(false);
                }}
              >
                닫기
              </ModalCloseButton>
            </ModalWrap>
          </FilterListModal>
        </Mobile>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  padding: 180px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (180 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;

const Wrap = styled.div`
  width: 100%;
`;

// 필터 버튼
const AllBrandFilterWrap = styled.div`
  background-color: #fff;
  transition: ease 0.3s all;
  @media screen and (max-width: 930px) {
    position: fixed;
    top: calc(100vw * (55 / 428));
    left: 0;
    z-index: 9;
    width: 100%;
  }
`;
const AllBrandFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (10 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: 0;
    border-bottom: 0;
  }
`;
const AllBrandButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;

  :not(:last-child) {
    border-right: 1px solid #dddddd;
  }
  @media screen and (max-width: 930px) {
    width: 50%;
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;
const AllBrandText = styled.span`
  color: #333333;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const AllBrandImgBox = styled.span`
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;

// 콘텐츠
const ContentsWrap = styled.div`
  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (55 / 428));
  }
`;
const Contents = styled.div``;

const ListBox = styled.ul``;
const List = styled.li`
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 8px;
    background-color: #f9f9f9;
  }
`;

const ListInner = styled.div`
  position: relative;
  width: 990px;
  margin: 0 auto;
  padding: 20px 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (990 / 1320));
    padding: calc(100vw * (20 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;

const ImgBox = styled.div`
  width: 70px;
  height: 70px;
  background-color: #fff;
  border: 1px solid #eee;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (70 / 1320));
    height: calc(100vw * (70 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (70 / 428));
    height: calc(100vw * (70 / 428));
  }
`;
const TextBox = styled.div`
  width: calc(100% - 80px);
  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (80 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (298 / 428));
  }
`;
const Brand = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 3px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (3 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (6 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const InquirySatisfactionBox = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const InquirySatisfactionList = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 1px;
    height: 14px;
    margin: 0 12px;
    background-color: #ddd;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      height: calc(100vw * (14 / 1320));
      margin: 0 calc(100vw * (12 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      height: calc(100vw * (14 / 428));
      margin: 0 calc(100vw * (12 / 428));
    }
  }
`;
const InquirySatisfaction = styled.span`
  color: ${(props) => (props.complete ? "#333" : "#ACACAC")};
  font-weight: ${(props) => (props.complete ? "600" : "400")};
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const InquiryDate = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

const InquiryTextWrap = styled.div`
  margin: 20px 0 0;

  @media screen and (max-width: 1320px) {
    margin: calc(100vw * (20 / 1320)) 0 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (20 / 428)) auto 0;
  }
`;
const InquiryTextInner = styled.div`
  cursor: pointer;
`;
const InquiryText = styled.p`
  color: #333;
  font-weight: 400;
  word-break: keep-all;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

const InquiryImgBoxList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (15 / 428));
  }
`;
const InquiryImgBox = styled.div`
  width: 100px;
  height: 100px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    height: calc(100vw * (100 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
    height: calc(100vw * (120 / 428));

    &:not(:last-child) {
      margin-right: calc(100vw * (9 / 428));
    }
  }
`;

const InquiryDeleteButton = styled.button`
  position: absolute;
  top: 20px;
  right: 25px;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  text-decoration: underline;

  @media screen and (max-width: 1320px) {
    top: calc(100vw * (20 / 1320));
    right: calc(100vw * (25 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: calc(100vw * (30 / 428));
    right: calc(100vw * (25 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;

const NoneText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #999;
  font-weight: 400;
  font-size: 16px;
  padding: 200px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (200 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: 0;
    margin-top: calc(100vh * (330 / 925));
  }
`;

// 필터 팝업
const FilterListModal = styled.div`
  & .close-button {
  }

  & .modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

const ModalWrap = styled.div`
  position: fixed;
  z-index: 106;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
`;
const ModalUl = styled.ul`
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (54 / 428)) calc(100vw * (25 / 428));
  }
`;
const ModalLi = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ active }) =>
    active &&
    ` & span {
      position: relative;
    }
    &::after {
      content: "";
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-6deg);
      z-index: -1;
      display: block;
      background-color: #fdf251;
    }

`}

  span {
    color: #333333;
    font-weight: 500;
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));

    ${({ active }) =>
      active &&
      `
  & span {
        position: relative;
      }
      &::after {
        width: calc(100vw * (56 / 428));
        height: calc(100vw * (12 / 428));
      }
  `}
  }
`;
const ModalBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 105;
  background-color: rgba(0, 0, 0, 0.4);
`;
const ModalCloseButton = styled.button`
  position: absolute;
  font-size: 0;
  background: url(${IconClose}) no-repeat center / cover;
  @media screen and (max-width: 930px) {
    top: calc(100vw * (20 / 428));
    right: calc(100vw * (25 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;

// date filter button list
const AllBrandFilterDateListWrap = styled.div`
  display: flex;
  align-items: center;
`;
const AllBrandFilterDateButton = styled.button`
  width: 80px;
  height: 35px;
  font-size: 15px;
  font-weight: 400;
  color: #111;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  &:not(:last-child) {
    margin-right: 10px;
  }

  ${({ active }) => {
    return active && `border: 1px solid #000;`;
  }}

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (80 / 1320));
    height: calc(100vw * (35 / 1320));
    font-size: calc(100vw * (15 / 1320));
    &:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }
`;

// select
const SelectBoxWrap = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 145px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (145 / 1320));
  }
`;
const SelectedButton = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background-color: #fff;
  border: 1px solid #dddddd;
  cursor: pointer;
  transition: ease 0.3s all;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (40 / 1320));
    padding: 0 calc(100vw * (10 / 1320));
  }
`;
const SelectedButtonText = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333333;
  transition: ease 0.3s all;

  ${({ active }) => {
    return (
      active &&
      `
        color: #000;
    `
    );
  }}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;
const SelectListBox = styled.ul`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dddddd;

  @media screen and (max-width: 1320px) {
    top: calc(100% + 100vw * (8 / 1320));
    max-height: calc(100vw * (304 / 1320));
  }
`;
const SelectBtn = styled.li`
  width: 100%;
  color: #888;
  font-size: 16px;
  text-align: center;
  padding: 8px 0;
  cursor: pointer;

  &:hover {
    background: #f7f7f7;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (8 / 1320)) 0;
  }
`;
const SelectedArrowImg = styled.img`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  transition: ease 0.3s all;
  ${({ active }) => {
    return (
      active &&
      `
        transform : translateY(-50%) rotate(180deg);
    `
    );
  }}

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (10 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
`;
