//react
import { Link, useHistory } from "react-router-dom";
import qs from "qs";
import { Mobile, PC } from "../../../MediaQuery";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

//img
import IconMarkQ from "../../../img/icon/icon_q.svg";
import IconSelectArrow from "../../../img/icon/icon_bottom_arrow.svg";
import IconClose from "../../../img/icon/icon_popup_close.svg";
import SelectBoxArrow from "../../../img/icon/icon_selectBox_arrow.svg";

// modal
import MyInquiryEditModal from "../../common/modal/MyInquiryEditModal";
import MyPageDefaultModal from "../../common/modal/MyPageDefaultModal";
import { imgURL } from "../../../server/appInfoController";
import NewPagination from "../../common/pagination/NewPagination";
import Pagination from "../../common/pagination/Pagination";

const filterPItems = [
  { title: "1개월", value: "month" },
  { title: "3개월", value: "3month" },
  { title: "6개월", value: "6month" },
  { title: "1년", value: "year" },
];
const filterTItems = [
  { title: "전체문의", value: "12" },
  { title: "상품문의", value: "5" },
  { title: "주문/결제문의", value: "6" },
  { title: "배송문의", value: "7" },
  { title: "교환/취소문의", value: "8" },
  { title: "계정문의", value: "9" },
  { title: "기타", value: "10" },
];

export default function MyQuestion({
  setDetail,
  setDetailItem,
  detail,
  pageData,
  questionList,
  getQuestionList,
  setQuestionList,
  deleteQuestionItem,
  page,
  setPage,
}) {
  const history = useHistory();
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const [hide, setHide] = useState(false);
  const [filterShowF, setFilterShowF] = useState(false);
  const [filterShowS, setFilterShowS] = useState(false);
  const [inquiryRemove, setInquiryRemove] = useState(false);
  const [detailShow, setDetailShow] = useState(false);

  const [clickP, setClickP] = useState(0);
  const [clickT, setClickT] = useState(0);

  const [clickQusId, setClickQusId] = useState(0);

  // select
  const [option, setOption] = useState(false);

  useEffect(() => {
    history.push(`/store/mypage/inquiry?page=${page}`);
  }, [page]);

  useEffect(() => {
    getQuestionList(filterTItems[clickT].value, filterPItems[clickP].value);
  }, [query.page]);

  return (
    <Container detail={detail}>
      {detailShow && <MyInquiryEditModal setDetailShow={setDetailShow} btnText={"등록하기"} />}
      {inquiryRemove && (
        <MyPageDefaultModal
          text={"해당 문의를 삭제하시겠습니까?"}
          trueFunc={() => {
            deleteQuestionItem(clickQusId, setInquiryRemove);
          }}
          falseFunc={() => setInquiryRemove(false)}
        />
      )}

      <Wrap>
        <Mobile>
          <WriteWrap hide={hide}>
            <WriteBtn
              type="button"
              onClick={() => {
                setDetailShow(true);
              }}
            >
              1:1 문의 작성
            </WriteBtn>
          </WriteWrap>
        </Mobile>

        <AllBrandFilterWrap hide={hide}>
          <PC>
            <AllBrandFilter>
              <AllBrandFilterDateListWrap>
                {filterPItems.map((itemP, idx) => {
                  return (
                    <AllBrandFilterDateButton
                      type="button"
                      key={itemP.value}
                      active={clickP === idx}
                      onClick={() => {
                        setClickP(idx);
                        setQuestionList([]);
                        setPage(1);
                        getQuestionList(filterTItems[clickT].value, itemP.value, true);
                      }}
                    >
                      {itemP.title}
                    </AllBrandFilterDateButton>
                  );
                })}
              </AllBrandFilterDateListWrap>
              <SelectBoxWrap
                tabIndex="0"
                onBlur={() => {
                  setOption(false);
                }}
              >
                <SelectedButton
                  onClick={() => {
                    setOption(!option);
                  }}
                >
                  <SelectedButtonText active={option && true}>
                    {filterTItems[clickT].title}
                  </SelectedButtonText>
                </SelectedButton>
                <SelectedArrowImg
                  onClick={() => {
                    setOption(!option);
                  }}
                  active={option && true}
                  src={SelectBoxArrow}
                  alt="selectArrow"
                />
                {option ? (
                  <SelectListBox>
                    {filterTItems.map((itemT, idx) => {
                      return (
                        <SelectBtn
                          key={`${itemT.value}-filterT-pc-${idx}`}
                          onClick={() => {
                            setClickT(idx);
                            setOption(false);
                            setQuestionList([]);
                            setPage(1);
                            getQuestionList(itemT.value, filterPItems[clickP].value, true);
                          }}
                        >
                          {itemT.title}
                        </SelectBtn>
                      );
                    })}
                  </SelectListBox>
                ) : null}
              </SelectBoxWrap>
            </AllBrandFilter>
          </PC>
          <Mobile>
            <AllBrandFilter>
              <AllBrandButton
                onClick={() => {
                  setFilterShowF(!filterShowF);
                }}
              >
                <AllBrandText className="text">{filterPItems[clickP].title}</AllBrandText>
                <AllBrandImgBox className="img-box">
                  <img src={IconSelectArrow} alt="" />
                </AllBrandImgBox>
              </AllBrandButton>
              <AllBrandButton
                onClick={() => {
                  setFilterShowS(!filterShowS);
                }}
              >
                <AllBrandText className="text">{filterTItems[clickT].title}</AllBrandText>
                <AllBrandImgBox className="img-box">
                  <img src={IconSelectArrow} alt="" />
                </AllBrandImgBox>
              </AllBrandButton>
            </AllBrandFilter>
          </Mobile>
        </AllBrandFilterWrap>

        <ContentsWrap>
          <Contents>
            <ListBox>
              {questionList.length > 0 ? (
                questionList.map((listItem, index) => {
                  return (
                    <List key={`${listItem.inq_id}-question-${index}`}>
                      <ListInner
                        onClick={() => {
                          setDetail(true);
                          setDetailItem(listItem);
                        }}
                      >
                        <ListTopWrap>
                          <SatisfactionBox>
                            <Date>{listItem.inq_date.split("T")[0].replaceAll("-", ".")}</Date>
                            {listItem.inq_answer === 1 ? (
                              <Satisfaction>답변대기</Satisfaction>
                            ) : (
                              <Satisfaction complete>답변완료 &gt;</Satisfaction>
                            )}
                          </SatisfactionBox>
                        </ListTopWrap>
                        <ListTextWrap>
                          <MarkImg>
                            <img src={IconMarkQ} alt="" />
                          </MarkImg>
                          <TextBox>
                            <Title>{listItem.inq_title}</Title>
                            <Text>{listItem.inq_content}</Text>
                          </TextBox>
                        </ListTextWrap>
                        {listItem.inq_images && (
                          <ListImgBoxWrap>
                            {listItem.inq_images?.split(",") &&
                              listItem.inq_images?.split(",")?.length > 0 &&
                              listItem.inq_images.split(",").map((el, idx) => {
                                return (
                                  <ListImg>
                                    <img
                                      key={`${idx}-imgB`}
                                      src={`${imgURL}${el}`}
                                      alt="question-img"
                                    />
                                  </ListImg>
                                );
                              })}
                          </ListImgBoxWrap>
                        )}

                        {/* {listItem.prd_thumbnail > 0 && (
                          <ListImgBoxWrap>
                            <ListImg>
                              <img
                                src={imgURL + listItem.prd_thumbnail}
                                alt="prd-thumbnail"
                              />
                            </ListImg>
                          </ListImgBoxWrap>
                        )} */}
                      </ListInner>
                      <ListDeleteButton
                        onClick={() => {
                          setInquiryRemove(true);
                          setClickQusId(listItem.inq_id);
                        }}
                        type="button"
                      >
                        삭제
                      </ListDeleteButton>
                    </List>
                  );
                })
              ) : (
                <Contents>
                  <NoneText>1:1 문의 내역이 없습니다.</NoneText>
                </Contents>
              )}
            </ListBox>
          </Contents>
        </ContentsWrap>

        <PC>
          <WriteWrap>
            <WriteBtn
              type="button"
              onClick={() => {
                history.push("/store/mypage/inquiry/edit");
              }}
            >
              1:1 문의 작성
            </WriteBtn>
          </WriteWrap>
        </PC>
        {questionList.length > 0 && (
          <NewPagination pageData={pageData} page={page} setPage={setPage} />
        )}
      </Wrap>

      <Mobile>
        {filterShowF && (
          <>
            <ModalBg
              onClick={() => {
                setFilterShowF(false);
              }}
            />
            <FilterListModal>
              <ModalWrap>
                <ModalUl>
                  {filterPItems.map((itemP, idx) => {
                    return (
                      <ModalLi
                        key={`${itemP.value}-filterKey-question-${idx}`}
                        active={clickP === idx}
                        onClick={() => {
                          setClickP(idx);
                          setPage(1);
                          setQuestionList([]);
                          getQuestionList(filterTItems[clickT].value, itemP.value, true);
                        }}
                      >
                        <span>{itemP.title}</span>
                      </ModalLi>
                    );
                  })}
                </ModalUl>
                <ModalCloseButton
                  onClick={() => {
                    setFilterShowF(false);
                  }}
                >
                  닫기
                </ModalCloseButton>
              </ModalWrap>
            </FilterListModal>
          </>
        )}
        {filterShowS && (
          <>
            <ModalBg
              onClick={() => {
                setFilterShowS(false);
              }}
            />
            <FilterListModal>
              <ModalWrap className="modal-wrap">
                <ModalUl>
                  {filterTItems.map((itemT, idx) => {
                    return (
                      <ModalLi
                        key={`${itemT.value}-filterT-${idx}`}
                        active={clickT === idx}
                        onClick={() => {
                          setClickT(idx);
                          setPage(1);
                          setQuestionList([]);
                          getQuestionList(itemT.value, filterPItems[clickP].value, true);
                        }}
                      >
                        <span>{itemT.title}</span>
                      </ModalLi>
                    );
                  })}
                </ModalUl>
                <ModalCloseButton
                  onClick={() => {
                    setFilterShowS(false);
                  }}
                >
                  닫기
                </ModalCloseButton>
              </ModalWrap>
            </FilterListModal>
          </>
        )}
      </Mobile>
    </Container>
  );
}

const Container = styled.div`
  ${({ detail }) => detail && `display : none;`}
  @media screen and (max-width: 930px) {
  }
`;

const Wrap = styled.div`
  width: 100%;
`;

// 필터 버튼
const AllBrandFilterWrap = styled.div`
  background-color: #fff;

  @media screen and (max-width: 930px) {
    position: fixed;
    top: calc(100vw * (145 / 428));
    left: 0;
    z-index: 9;
    width: 100%;
    transition: ease 0.3s all;
    ${({ hide }) =>
      hide &&
      `
      top : 0;
  `}
  }
`;
const AllBrandFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (10 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: 0;
    border-bottom: 0;
  }
`;
const AllBrandButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;

  :not(:last-child) {
    border-right: 1px solid #dddddd;
  }
  @media screen and (max-width: 930px) {
    width: 50%;
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;
const AllBrandText = styled.span`
  color: #333333;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const AllBrandImgBox = styled.span`
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;

// 콘텐츠
const ContentsWrap = styled.div`
  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (140 / 428));
  }
`;
const Contents = styled.div``;

const ListBox = styled.ul`
  margin-top: 15px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: 0;
  }
`;
const List = styled.li`
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 8px;
    background-color: #f9f9f9;
  }
`;

const ListInner = styled.div`
  width: 992px;
  margin: 0 auto;
  padding: 25px 0;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (992 / 1320));
    padding: calc(100vw * (25 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: calc(100vw * (30 / 428)) 0;
  }
`;

const ListTopWrap = styled.div``;
const SatisfactionBox = styled.div`
  display: flex;
  align-items: center;

  & span {
    display: flex;
    align-items: center;
  }

  & span:not(:last-child)::after {
    content: "";
    display: block;
    width: 1px;
    height: 14px;
    margin: 0 12px;
    background-color: #ddd;
  }

  @media screen and (max-width: 1320px) {
    & span:not(:last-child)::after {
      height: calc(100vw * (14 / 1320));
      margin: 0 calc(100vw * (12 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    & span:not(:last-child)::after {
      height: calc(100vw * (14 / 428));
      margin: 0 calc(100vw * (12 / 428));
    }
  }
`;
const Date = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const Satisfaction = styled.span`
  color: ${(props) => (props.complete ? "#333" : "#ACACAC")};
  font-weight: ${(props) => (props.complete ? "600" : "400")};
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

const ListTextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (10 / 428));
  }
`;
const MarkImg = styled.div`
  width: 24px;
  height: 24px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;

const TextBox = styled.div`
  width: calc(100% - 32px);
  padding-top: 3px;

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (32 / 1320)));
    padding-top: calc(100vw * (3 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (346 / 428));
    padding-top: calc(100vw * (3 / 428));
  }
`;
const Title = styled.p`
  color: #333;
  font-weight: 500;
  line-height: 1.5;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const Text = styled(Title)`
  font-weight: 400;
  -webkit-line-clamp: 2;
  margin-top: 8px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (8 / 428));
  }
`;

// 이미지 영역
const ListImgBoxWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const ListImg = styled.div`
  width: 100px;
  height: 100px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    height: calc(100vw * (100 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
    height: calc(100vw * (120 / 428));

    &:not(:last-child) {
      margin-right: calc(100vw * (9 / 428));
    }
  }
`;

// 삭제 버튼
const ListDeleteButton = styled.button`
  position: absolute;
  top: 25px;
  right: 20px;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  z-index: 1;

  @media screen and (max-width: 1320px) {
    top: calc(100vw * (25 / 1320));
    right: calc(100vw * (20 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: calc(100vw * (30 / 428));
    right: calc(100vw * (25 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;

const NoneText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #999;
  font-weight: 400;
  font-size: 16px;
  padding: 200px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (200 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: 0;
    margin-top: calc(100vh * (220 / 925));
  }
`;

const WriteWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  background-color: #fff;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    position: fixed;
    top: calc(100vw * (55 / 428));
    left: 0;
    z-index: 9;
    display: block;
    width: 100%;
    padding: calc(100vw * (20 / 428)) 0;
    margin-top: 0;
    transition: ease 0.3s all;
    ${({ hide }) =>
      hide &&
      `
      top : calc(100vw * (-40 / 428));
  `}
  }
`;
const WriteBtn = styled.button`
  display: block;
  width: 180px;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  border: 1px solid #000;
  background: #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (180 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
    margin: 0 auto;
  }
`;

// 필터 팝업
const FilterListModal = styled.div`
  & .close-button {
  }

  & .modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

const ModalWrap = styled.div`
  position: fixed;
  z-index: 106;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
`;
const ModalUl = styled.ul`
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (54 / 428)) calc(100vw * (25 / 428));
  }
`;
const ModalLi = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ active }) =>
    active &&
    ` & span {
      position: relative;
    }
    &::after {
      content: "";
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-6deg);
      z-index: -1;
      display: block;
      background-color: #fdf251;
    }

`}

  span {
    color: #333333;
    font-weight: 500;
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));

    ${({ active }) =>
      active &&
      `
  & span {
        position: relative;
      }
      &::after {
        width: calc(100vw * (56 / 428));
        height: calc(100vw * (12 / 428));
      }
  `}
  }
`;
const ModalBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 105;
  background-color: rgba(0, 0, 0, 0.4);
`;
const ModalCloseButton = styled.button`
  position: absolute;
  font-size: 0;
  background: url(${IconClose}) no-repeat center / cover;
  @media screen and (max-width: 930px) {
    top: calc(100vw * (20 / 428));
    right: calc(100vw * (25 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;

// date filter button list
const AllBrandFilterDateListWrap = styled.div`
  display: flex;
  align-items: center;
`;
const AllBrandFilterDateButton = styled.button`
  width: 80px;
  height: 35px;
  font-size: 15px;
  font-weight: 400;
  color: #111;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  &:not(:last-child) {
    margin-right: 10px;
  }

  ${({ active }) => {
    return active && `border: 1px solid #000;`;
  }}

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (80 / 1320));
    height: calc(100vw * (35 / 1320));
    font-size: calc(100vw * (15 / 1320));
    &:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }
`;

// select
const SelectBoxWrap = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 145px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (145 / 1320));
  }
`;
const SelectedButton = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background-color: #fff;
  border: 1px solid #dddddd;
  cursor: pointer;
  transition: ease 0.3s all;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (40 / 1320));
    padding: 0 calc(100vw * (10 / 1320));
  }
`;
const SelectedButtonText = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333333;
  transition: ease 0.3s all;

  ${({ active }) => {
    return (
      active &&
      `
        color: #000;
    `
    );
  }}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;
const SelectListBox = styled.ul`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dddddd;

  @media screen and (max-width: 1320px) {
    top: calc(100% + 100vw * (8 / 1320));
    max-height: calc(100vw * (304 / 1320));
  }
`;
const SelectBtn = styled.li`
  width: 100%;
  color: #888;
  font-size: 16px;
  text-align: center;
  padding: 8px 0;
  cursor: pointer;

  &:hover {
    background: #f7f7f7;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (8 / 1320)) 0;
  }
`;
const SelectedArrowImg = styled.img`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  transition: ease 0.3s all;
  ${({ active }) => {
    return (
      active &&
      `
        transform : translateY(-50%) rotate(180deg);
    `
    );
  }}

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (10 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
`;
