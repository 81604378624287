import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PC, Mobile } from '../common/mediaStlye';
import serverController from '../../server/serverController';

import {
    Container,
    Layout,
    StyleSponHead,
    StyleSubHead,
    StyleBackButton,
    StyleSponWrap,
    StyleSponCategorys,
    StyleCatgoryIcon
} from './stylist.element';
import StylistSponserdList from './StylistSponserdList';
import StylistRequestHead from './StylistRequestHead';

import arrow from "../../img/brand/arrow.png";

import styleHeadIcon from '../../images/stylist/iconmonstr-arrow-55.png';

const StylistMyRequest = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [morePage, setMorePage] = useState(0);
    const [isLoad, setIsLoad] = useState(false);
    const [list, setList] = useState([]);
    const [moreOff, setMoreOff] = useState(true);
    const history = useHistory();
    const { statusId } = useParams()

    const renderTitle = () => {
        // console.log(statusId)
        switch (statusId) {
            case "1":
                return "협찬 신청중인 내역"
            case "2":
                return "협찬 승인 내역"
            case "3":
                return "협찬 반려 내역"
            default:
                return
        }
    }

    const renderPurchaseState = () => {
        //  purchase_state 1. 신청대기 2. 승인 3. 반려 4. 반납대기 5. 반납완료 6. 주문취소
        switch (statusId) {
            case "1":
                return 1
            case "2":
                return 2
            case "3":
                return 3
            default:
                return
        }
    }

    const onHandleGoback = () => history.goBack();

    const fetchPrdList = () => {
        setMorePage(0);
        setMoreOff(true);
        setIsLoad(false);
        setList([]);
        serverController.connectFetchController(`purchases?type=0&offset=0&limit=10&purchase_state=${renderPurchaseState()}${tabIndex ? "&prd_main_cat=" + tabIndex : ""}`, "GET", null, function (res) {
            if (res.result == 1) {
                console.log(res)
                setList(res.list)
                if (res.list.length < 10) setMoreOff(false);
            }
            setIsLoad(true);
        });
    }
    useEffect(() => {
        fetchPrdList()
    }, [tabIndex]);

    const MoreDataClick = () => {
        const tabCheck = tabIndex ?? false;
        serverController.connectFetchController(`purchases?type=0&offset=${morePage + 10}&limit=10&purchase_state=${renderPurchaseState()}${tabCheck ? "&prd_main_cat=" + tabIndex : ""}`, "GET", null, function (res) {
            if (res.result == 1) {
                console.log(res)
                setList([...list, ...res.list])
                if (res.list.length < 10) setMoreOff(false)
                setMorePage(morePage + 10)
            }
        });
    }

    return (
        <>
            <Container>
                <Layout>
                    <StyleSponWrap>
                        <div>
                            <PC>
                                <StyleSubHead sub>
                                    <StyleBackButton onClick={onHandleGoback}>
                                        <img src={arrow} alt="arrow" />
                                    </StyleBackButton>
                                    <h2>{renderTitle()}</h2>
                                </StyleSubHead>
                            </PC>

                            <StylistRequestHead setTabIndex={setTabIndex} />
                        </div>
                    </StyleSponWrap>
                    <StylistSponserdList list={list} MoreDataClick={MoreDataClick} moreOff={moreOff} isLoad={isLoad} mydata={true} />
                </Layout>
            </Container>
        </>
    )
}

export default StylistMyRequest
