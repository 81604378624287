import React from "react";
import { Mobile, PC } from "../../components/common/mediaStlye";
import Header from "../../components/common/header/Header";
import StepSuccess from "../../components/auth/signup/StepSuccess";
import Footer from "../../components/common/footer/Footer";

const StepSuccessPage = () => {
  return (
    <>
      <Header />
      <StepSuccess />
      <Footer />
    </>
  );
};

export default StepSuccessPage;
