import styled from "styled-components";

// controller
import { imgURL } from "../../server/appInfoController";
import DefaultImg from "../../img/icon/loading_default_img.svg";

export default function ContentsDetailProductBox({ contentsStylingPrd }) {
  const windowOpenUrl = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "linking",
          value: contentsStylingPrd.prd_link,
        })
      );
    } else window.open(`${contentsStylingPrd.prd_link}`, "_blank");
  };
  return (
    <ContentsDetailProductWrap
      onClick={() => {
        if (!contentsStylingPrd.prd_link) return;
        windowOpenUrl();
      }}
    >
      <ContentsDetailProductLeft>
        <ContentsDetailProductImg
          src={
            contentsStylingPrd.prd_thumbnail
              ? `${imgURL}${contentsStylingPrd.prd_thumbnail}`
              : DefaultImg
          }
          alt={contentsStylingPrd.prd_thumbnail ? "Contents-product-img" : "default-img"}
        />
      </ContentsDetailProductLeft>
      <ContentsDetailProductRight>
        {contentsStylingPrd.br_name ? (
          <ContentsDetailProductRightBrand>
            {contentsStylingPrd.br_name}
          </ContentsDetailProductRightBrand>
        ) : null}
        <ContentsDetailProductRightTitle>
          {contentsStylingPrd.prd_name}
        </ContentsDetailProductRightTitle>
        <ContentsDetailProductRightPrice>
          {contentsStylingPrd.prd_price === 0 ? "see more" : `₩ ${contentsStylingPrd.prd_price}`}
        </ContentsDetailProductRightPrice>
      </ContentsDetailProductRight>
    </ContentsDetailProductWrap>
  );
}
const ContentsDetailProductWrap = styled.div`
  display: flex;
  border: 1px solid #eeeeee;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 428));
    }
  }
`;
const ContentsDetailProductLeft = styled.div`
  width: 120px;
  height: 120px;
  border-right: 1px solid #eeeeee;
  flex-shrink: 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (120 / 1320));
    height: calc(100vw * (120 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
    height: calc(100vw * (120 / 428));
  }
`;
const ContentsDetailProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ContentsDetailProductRight = styled.div`
  width: 570px;
  padding: 10px 12px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (570 / 1320));
    padding: calc(100vw * (10 / 1320)) calc(100vw * (12 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (258 / 428));
    padding: calc(100vw * (10 / 428)) calc(100vw * (12 / 428));
  }
`;
const ContentsDetailProductRightBrand = styled.div`
  color: #777777;
  font-weight: 600;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const ContentsDetailProductRightTitle = styled.p`
  height: 40px;
  line-height: 1.5;
  color: #333333;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 5px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (40 / 1320));
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (40 / 428));
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (5 / 428));
  }
`;
const ContentsDetailProductRightPrice = styled.span`
  color: #777;
  font-size: 14px;
  text-decoration: underline;
  margin-top: 12px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (12 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (12 / 428));
  }
`;
