import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";
import serverController from "../../server/serverController";
import { imgURL } from "../../../server/appInfoContoller";
import BannerArrow from "../../img/icon/icon_bannerArrow.svg";
import "swiper/swiper-bundle.css";

import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";

export default function MainBenner() {
  SwiperCore.use([Pagination, Navigation, Autoplay]);

  const onClickBanner = (ban_url) => {
    window.location.href = ban_url;
  };

  const [banners, setBanners] = useState([]);
  console.log("banners: ", banners);

  const getBannerList = () => {
    serverController.connectFetchController(`bannerlist?type=store`, "GET", null, (res) => {
      if (res?.result === 1) {
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@: ", res);
        setBanners(res?.list ?? []);
      }
    });
  };

  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    getBannerList();
  }, []);

  useEffect(() => {
    if (swiper !== null) {
      swiper.slideTo(1, 0);
    }
  }, [banners]);

  return (
    <Container style={{ background: "#000" }}>
      <PC>
        <BannerWrap className="MainBannerWrap">
          {
            <Swiper
              initialSlide={1}
              navigation={{
                prevEl: ".prevNav",
                nextEl: ".nextNav",
              }}
              slidesPerView={1}
              centeredSlides={true}
              loop={true}
              onSwiper={setSwiper}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
            >
              {banners.map((bannerImg) => {
                return (
                  <SwiperSlide key={bannerImg?.ban_id}>
                    <Slide>
                      <MainSlideImg
                        src={`${imgURL}${bannerImg?.ban_image}`}
                        alt={bannerImg?.ban_title}
                        onClick={() => onClickBanner(bannerImg?.ban_url)}
                      />
                    </Slide>
                  </SwiperSlide>
                );
              })}
              <SwiperNavWrap>
                <SwiperNavBox>
                  <SwiperNavL className="prevNav">
                    <img src={BannerArrow} alt="prev-arrow" />
                  </SwiperNavL>
                  <SwiperNavR className="nextNav">
                    <img src={BannerArrow} alt="next-arrow" />
                  </SwiperNavR>
                </SwiperNavBox>
              </SwiperNavWrap>
            </Swiper>
          }
        </BannerWrap>
      </PC>

      <Mobile>
        <BannerWrap className="MainBannerWrap">
          {
            <Swiper
              initialSlide={1}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              slidesPerView={1}
              centeredSlides={true}
              pagination={{
                type: "fraction",
              }}
              loop={true}
            >
              {banners.map((bannerImg) => {
                return (
                  <SwiperSlide key={bannerImg?.ban_id}>
                    <Slide>
                      <MainSlideImg
                        src={`${imgURL}${bannerImg?.ban_image_mobile}`}
                        alt={bannerImg?.ban_title}
                        onClick={() => onClickBanner(bannerImg?.ban_url)}
                      />
                    </Slide>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          }
        </BannerWrap>
      </Mobile>
    </Container>
  );
}
const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 130px;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 440px;
  }
  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (130 / 1320));
    &::after {
      height: calc(100vw * (440 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    padding-top: 0;
    background: none;
    &::after {
      display: none;
    }
  }
`;
const BannerWrap = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 930px) {
    min-height: calc(100vw * (565 / 428));
  }
`;
/* main slide */
const Slide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 650px;
  margin: 0 auto;
  overflow: hidden;
  transition: 0.8s;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (645 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (565 / 428));
    background: none;
  }
`;
const MainSlideImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const SwiperNavWrap = styled.div`
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;
const SwiperNavBox = styled.div`
  width: 1240px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
`;
const SwiperNavL = styled.div`
  height: 70px;
  width: 70px;
  z-index: 3;
  transform: rotate(180deg);
  cursor: pointer;
  & > img {
    width: 100%;
    height: 100%;
    pointer-events: auto;
  }
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (70 / 1320));
    width: calc(100vw * (70 / 1320));
  }
`;
const SwiperNavR = styled.div`
  height: 70px;
  width: 70px;
  z-index: 3;
  cursor: pointer;
  & > img {
    width: 100%;
    height: 100%;
    pointer-events: auto;
  }
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (70 / 1320));
    width: calc(100vw * (70 / 1320));
  }
`;
