import styled from 'styled-components';
import {    
    css,
    Mobile,PC,
    mobil,mquery,MQ,
    pc,
    Media,
    Px,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
} from '../mediaStlye';
import CloseIcon from '../../../images/close-icon.png';
import CloseIcon2 from '../../../images/modal-close-icon.png';

export const ModalContainer = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.3);
    & table {border-collapse: collapse; margin: calc(100vw*(20/1920)) 0;}
    & th {font-weight: bold; text-align: center;}
    & th, td {border: 1px solid #dfdfdf; padding: calc(100vw*(10/1920)); font-size: calc(100vw*(14/1920));}
    @media screen and (max-width: 1024px) {
        & table {border-collapse: collapse; margin: calc(100vw*(20/428)) 0;}
        & th {font-weight: bold; text-align: center;}
        & th, td {border: 1px solid #dfdfdf; padding: calc(100vw*(10/428)); font-size: calc(100vw*(14/428));}
    }
`

export const ModalContents = styled.div`
    ${Width(611, pc)}
    position: relative;
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.4);

    

    @media screen and (max-width: 1024px) {
        ${Width(378, mobil)}
    }
`

export const ModalText = styled.div`
    ${[
        Height(597, pc),
        Padding(38 ,42,70,42,pc)
    ].join("")}
    width: 100%;
    & div div {padding-left: calc(100vw*(25/1920)); color: #666666; padding-bottom: calc(100vw*(10/1920));}

    & > h1 {
        ${[
            FontSize(24, pc),
            MB(26, pc)
        ].join("")}
        font-weight: 700;
        font-family: "Noto Sans CJK KR";
        color: #252525;        
    }
    
    & > div {
        max-height: ${Px(459, pc)};
        overflow-y: scroll;
        -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */    

    ::-webkit-scrollbar {
        display: none;
    }
    }

    & > div p{
        ${[
            MB(30, pc),
            FontSize(14, pc),
            LineH(23, pc)
        ].join("")}
        
        font-family: "Noto Sans CJK KR";
        color: #666666;
    }
    

    @media screen and (max-width: 1024px) {
        ${Height(378, mobil)}
        ${Padding(34 ,28,0,28,mobil)}

        & div div {padding-left: calc(100vw*(15/428)); padding-bottom: calc(100vw*(8/428));}
        & > h1 {
            ${[
                FontSize(20, mobil),
                MB(25, mobil)
            ].join("")}               
        }

        & > div {
            max-height: ${Px(263, mobil)};            
        }

        & > div p{
            ${[
                MB(36.3, mobil),
                FontSize(14, mobil),
                LineH(23, mobil)
            ].join("")}           
        }
    }

`
            

export const ModalButton = styled.span`
    ${[
        Height(61, pc),
        FontSize(20, pc)
    ].join("")}
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;
    color: #fff;
    font-family: "Noto Sans CJK KR";
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        ${Height(60, mobil)}
        ${FontSize(20, mobil)}
    }
`

export const CloseButton = styled.button`
    ${[
        Width(17,pc),
        // Height(12,pc),
        Top(26,pc),
        Right(40,pc)
    ].join("")}
    position: absolute;    
    /* background: url(${CloseIcon}) no-repeat center; */
    /* background-size: 100%; */
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        ${Width(17, mobil)}
        /* ${Height(17, mobil)} */
        ${Top(26, mobil)}
        ${Right(26, mobil)}
    }
`

// myPage
export const MyPageModalContents = styled(ModalContents)`
    ${[
        Width(665, pc),
        Height(324, pc),
        Padding2(25, 21, pc)
    ].join("")}

    @media screen and (max-width:1024px){
        width: calc(100vw * (380 / 428));
        height: auto;
        padding: calc(100vw * (15 / 428)) calc(100vw * (20 / 428));
    }

`

export const MyPageModalText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    border: 1px solid #707070;

    & > p {
        ${[
            FontSize(30, pc),
            MB(20, pc)
        ].join("")}
        text-align: center;
    }

    & p small {
        ${FontSize(18, pc)}
    }
    @media screen and (max-width:1024px){
        padding: calc(100vw * (50 / 428)) 0 calc(100vw * (60 / 428));
        & > p {
            ${[
                FontSize(20, mobil),
                MB(20, mobil)
            ].join("")}
            text-align: center;
        }

        & p small {
            ${FontSize(16, mobil)}
        }
    }

`

export const MyPageModalButton = styled.div`
    font-size: calc(100vw*(18/1920));
    ${[
        Width(194, pc),
        Height(43, pc),        
    ].join("")}
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;
    color: #fff;
    cursor: pointer;
    @media screen and (max-width: 1024px) {
      font-size: calc(100vw*(18/428));
        ${[
            Width(194, mobil),
            Height(43, mobil),        
        ].join("")}
    }
`

// QuestionModal

export const QuestModalContents = styled(ModalContents)`
    max-height: 85vh;
    ${[
        Width(874, pc),
        Height(784, pc)        
    ].join("")}
    padding: 0;
    overflow-y: scroll;

    @media screen and (max-width: 1024px) {
        ${[
            Width(300, mobil),
            Height(200, mobil)        
        ].join("")}
        width: 100%;
        height: auto;
        ${Margin2(0, 25, mobil)}
        
    }
`

export const QuestModalHeadings = styled.div`
    ${[
        Height(123, pc),
        FontSize(22, pc),
        PL(45, pc)
    ].join("")}
    display: flex;
    align-items: center;
    width: 100%;
    color: #fff;
    background-color: #222;

    @media screen and (max-width: 1024px) {
        ${[
            Height(80, mobil),
            FontSize(20, mobil),
            PL(28, mobil)
        ].join("")}
    }
`

export const QuestCloseButton = styled(CloseButton)`
    ${[
        Width(19, pc),
        Height(19, pc)
    ].join("")}
    background: url(${CloseIcon2}) no-repeat center /100%;

    @media screen and (max-width: 1024px) {
        ${[
            Width(19, mobil),
            Height(19, mobil)
        ].join("")}
    }
`

export const QuestFormWrapper = styled.div`
    ${[
        Padding2(20, 43, pc)
    ].join("")}

    @media screen and (max-width: 1024px) {
        ${Padding2(25,23, mobil)}
    }
`

export const QuestCategorys = styled.div`
    ${MB(28, pc)}
    & select {width: 100%; height: calc(100vw*(45/1920)); font-size: calc(100vw*(15/1920)); padding: 0 calc(100vw*(20/1920));}
    & select option {font-size: calc(100vw*(15/1920)); padding: calc(100vw*(10/1920));}

    @media screen and (max-width: 1024px) {
        & select { 
            width: 100%;
            ${Height(38, mobil)}
            ${MB(20, mobil)}
            font-size: calc(100vw*(15/428));
            padding: 0 calc(100vw*(16/428))
        }
        & select option {font-size: calc(100vw*(15/428)); padding: calc(100vw*(10/428))}
    }
`

export const QuestForm = styled.form`

`

export const QuestInput = styled.input`
    ${[
        Padding2(15, 18, pc),
        FontSize(16, pc),
        MB(22, pc)
    ].join("")}
    display: block;
    width: 100%;
    border-bottom: 1px solid #222;    

    @media screen and (max-width: 1024px) {
        ${[
            Padding2(10, 20, mobil),
            FontSize(16, mobil),
            MB(22, mobil)
        ].join("")}
    }
`

export const QuestTextarea = styled.textarea`
    ${[
        Height(323, pc),
        Padding2(18, 18, pc),
        FontSize(16, pc),
        MB(12, pc)
    ].join("")}
    width: 100%;
    color: #000;
    resize: none;
    background-color: #F4F4F4;

    &::placeholder {
        ${FontSize(16, pc)}
    }

    @media screen and (max-width: 1024px) {
        ${[
            Height(200, mobil),
            Padding2(18, 18, mobil),
            FontSize(16, mobil),
            MB(12, mobil)
        ].join("")}
        &::placeholder {
            ${FontSize(16, mobil)}
        }
    }
    
`

export const QuestFile = styled.div`
    ${[
        Height(59, pc),        
        FontSize(16, pc),
        MB(22, pc)
    ].join("")}
    position: relative;
    width: 100%;    
    resize: none;
    background-color: #F4F4F4;

    & img {
        ${[
            Right(15, pc)           
        ].join("")}
        
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    @media screen and (max-width: 1024px) {
        ${[
            Height(59, mobil),        
            FontSize(16, mobil),
            MB(22, mobil)
        ].join("")}

        & img {
            ${[
                Right(15, pc)           
            ].join("")}
            
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
`

export const QuestFileInput = styled.input`
    ${[
        Height(59, pc),
        Padding2(18, 18, pc),
        FontSize(16, pc),
        MB(12, pc)
    ].join("")}
    width: 100%;
    color: #000;    

    &::placeholder {
        ${FontSize(16, pc)}
        color: #A5A5A5;
    }

    @media screen and (max-width: 1024px) {
        ${[
            Height(59, mobil),
            Padding2(18, 18, mobil),
            FontSize(16, mobil),
            MB(12, mobil)
        ].join("")}
    }
`

export const QuestModalButton = styled(MyPageModalButton)`
    font-size: calc(100vw*(18/1920));
    @media screen and (max-width: 1024px) {
        font-size: calc(100vw*(18/428));
        ${[
            Width(162, mobil),                    
            Height(50, mobil)
        ].join("")}
    }
`

// QuestionSuccessModal
export const QuestionSuccessContents = styled(MyPageModalContents)`
    ${Width(801,pc)}
`

export const InviteContents = styled(ModalContents)`
    ${Width(874, pc)}
    @media screen and (max-width: 1024px) {
        ${Width(350, mobil)}
    }
`

export const InviteCloseButton = styled(CloseButton)`
    position: static;
`

export const InviteHeadings = styled(QuestModalHeadings)`
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 0 calc(100vw*(42/1920));

    & span {
        color: #fff;
    }

    @media screen and (max-width: 1024px) {
        padding: 0 calc(100vw*(25/428));
    }
`

export const InviteContent = styled.div`
    padding: ${Px(112, pc)} ${Px(112, pc)};

    & > div {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #404040;
        padding: ${Px(10, pc)} ${Px(11, pc)};
    }

    & > div span {
        ${Width(140, pc)}
    }

    & > div input {
        flex: 1;
    }

    & button {
        display: block;
        margin: ${Px(80, pc)} auto 0;
        padding: ${Px(13, pc)} ${Px(43, pc)};
        background: #222222;
        color: #fff;
    }

    & em {
        ${FontSize(23, pc)}
        width: 100%;
        text-align: center;
        margin-top: calc(100vw*(10/1920));
        margin-bottom: calc(100vw*(30/1920));
    }
    & .invite_success {align-items: center; justify-content: center; flex-wrap: wrap;}

    @media screen and (max-width: 1024px) {
        padding: calc(100vw*(30/428)) calc(100vw*(25/428)) calc(100vw*(40/428));
        & > div {
            padding: calc(100vw*(15/428)) calc(100vw*(10/428));
        }

        & > div span {
            width: calc(100vw*(70/428));
        }

        & button {
            margin: calc(100vw*(40/428)) auto 0;
            padding: calc(100vw*(10/428)) calc(100vw*(40/428));
        }

        & em {
            font-size: calc(100vw*(20/428));
            margin-top: calc(100vw*(10/428));
            margin-bottom: calc(100vw*(30/428));
        }
    }

`