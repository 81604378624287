import React, {useState} from 'react';

import styled, {css} from 'styled-components';

//img

import ProductImg01 from '../../../img/brand/product_img01.jpg';

export default function ProductDetail(){
    return(
        <DetailWrap>
            <ProdImgWrap>
                <Img src={ProductImg01}/>
            </ProdImgWrap>
            <InformContainer>
                <InformWrap>
                    <Row mOrder={2}>
                        <Col>
                            <Title>공개중</Title>
                            <Text>21.05.09</Text>
                        </Col>
                        <Col>
                            <Title>상품명</Title>
                            <Text>노드비메이드 타이백 트렌치코트</Text>
                        </Col>
                        <Col>
                            <Title>노출 순서</Title>
                            <Text>1</Text>
                        </Col>
                        <Col>
                            <Title>상품 코드</Title>
                            <Text>NBM12345FW</Text>
                        </Col>
                        <Col>
                            <Title>협찬</Title>
                            <Text>10개</Text>
                        </Col>
                        <Col>
                            <Title>등록</Title>
                            <Text>nodebemade_no1@gmail.com</Text>
                        </Col>
                        <TagWrap>
                            <Tag>카린</Tag>
                            <Tag>블랙</Tag>
                            <Tag>원피스</Tag>
                            <Tag>드레스</Tag>
                            <Tag>카울넥</Tag>
                            <Tag>카울넥</Tag>
                            <Tag>카린</Tag>
                        </TagWrap>
                        <ProdPriceWrap>
                            <ProdPrice>300,000</ProdPrice>
                        </ProdPriceWrap>
                    </Row>
                    <Row mOrder={1}>
                        <ProdPreviewImgWrap>
                            <ProdPreviewImg src={ProductImg01}/>
                            <ProdPreviewImg src={ProductImg01}/>
                            <ProdPreviewImg src={ProductImg01}/>
                            <ProdPreviewImg src={ProductImg01}/>
                        </ProdPreviewImgWrap>
                    </Row>
                </InformWrap>
            </InformContainer>
        </DetailWrap>
    )
}

function ProdPreviewImg({src}){
    return(
        <PreviewImg>
            <Img src={src}/>
            <PreviewImgCover/>
        </PreviewImg>
    )
}
const clear = `&:after { display:block; content:""; clear:both; }`;

const DetailWrap = styled.div`
    position:relative; width:100%;
    display:flex; padding-top:calc(100vw * (86 / 1920));

    @media screen and (max-width:768px){
        flex-direction:column;
    }
`;

const ProdImgWrap = styled.div`
    width:calc(100vw * (426 / 1920));
    margin-right:calc(100vw * (40 / 1920));
    @media screen and (max-width:1024px){
        width:100%; margin-right:0;
    }
`;
const Img = styled.img`
    width:100%; display:block;
`;

const InformContainer = styled.div`
    width: calc(100vw*(526/1920));

    @media screen and (max-width: 768px){
        width:100%; margin-right:0;
        padding: 0 calc(100vw*(15/428));
    }
`;

const InformWrap = styled.div`
    display:flex; width:100%; flex-direction:column;
`
const Title = styled.p`
    font-size:calc(100vw*(16/1920)); color:#A5A5A5; line-height:calc(100vw*(22/1920)); width:calc(100vw*(102/1920));

    @media screen and (max-width:768px){
        width: calc(100vw*(80/428));
        line-height: 1.5;
        font-size: calc(100vw*(15/428));
    }
`;
const Text = styled.p`
    font-size:calc(100vw*(16/1920)); color:#222222; line-height:calc(100vw*(22/1920));

    @media screen and (max-width:768px){
        line-height: 1.5;
        font-size: calc(100vw*(15/428));
    }
`;

const TagWrap = styled.div`
    margin-left:calc(100vw*(-4/1920)); margin-right:calc(100vw*(-4/1920)); margin-bottom:calc(100vw*(38/1920));
`;

const Tag = styled.p`
    font-size:calc(100vw*(14/1920)); display:inline-block;
     padding:calc(100vw*(5/1920)) calc(100vw*(26/1920)); margin:0 calc(100vw*(4/1920));  margin-bottom:calc(100vw*(7/1920));
     background:#F2F2F2; border-radius:calc(100vw*(41/1920)); font-weight:500;
    &:before { content:'#'; margin-right:calc(100vw*(3/1920)); }
`;

const ProdPriceWrap = styled.div`
    border-bottom:1px solid #222222; padding:0 calc(100vw*(9/1920)) calc(100vw*(9/1920)) calc(100vw*(21/1920)); float:right;
`;
const ProdPrice = styled.p`
    font-size:calc(100vw*(20/1920)); color:#222222;
    &:before { content:'협찬 가격 (개)'; font-size:16px; margin-right:calc(100vw*(32/1920)); }
    &:after { content:'원'; font-size:16px; color:#A5A5A5; margin-left:calc(100vw*(11/1920)); }
`;

const ProdPreviewImgWrap = styled.div`
    display:flex; justify-content: space-between; height:auto; margin-top:calc(100vw*(20/1920));
    @media screen and (max-width:768px){
        margin-bottom:calc(100vw * (27 / 428));
    }
`;
const PreviewImg = styled.div`
    position:relative; display:inline-block; width:calc(25% - calc(100vw*(10/1920))); height:auto;
`
const PreviewImgCover = styled.div`
    position:relative; display:inline-block; position:absolute; top:0; left:0; z-index:3; width:100%; height:100%; background:rgba(0,0,0,0.36);
`

const Row = styled.div`
    ${clear};
    position:relative; 
    ${props => props.fl && `float:${props.fl}`}
    @media screen and (max-width:768px){
        ${props => props.mOrder && `order:${props.mOrder}`}
    }
`;
const Col = styled.div`
    display:flex; width:100%; margin-bottom:calc(100vw*(26/1920));
    ${props => props.wrap && `flex-wrap:wrap`}
`;