import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";

import DetailProductImg from "../../../component/product/detail/DetailProductImg";
import DetailReview from "../../../component/product/detail/DetailReview";
import DetailInquiry from "../../../component/product/detail/DetailInquiry";
import DetailGuide from "../../../component/product/detail/DetailGuide";

import serverController from "../../../server/serverController";

import ZigzagPc from "../../../img/borderBottom_zigzag_detail.svg";
import Zigzag from "../../../img/borderBottom_zigzag_search.svg";

export default function DetailContent(props) {
  const { product, inquiryLength, inquiry, inquiryPageData, getInquiry } = props;

  const { prd_id } = useParams();

  const [tab, setTab] = useState(1);

  // review
  const [reviewItems, setReviewItems] = useState({});
  const [photoReviewItems, setPhotoReviewItems] = useState([]);
  const [reviewPage, setReviewPage] = useState(1);
  const [reviewSort, setReviewSort] = useState(0);
  const [photoCheck, setPhotoCheck] = useState(false);

  // get reviews data
  const getReviews = () => {
    serverController.connectFetchController(
      `review/product/${prd_id}?page=${reviewPage}&limit=5&sort=${reviewSort}&type=${photoCheck ? 3 : 1
      }`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setReviewItems(res || {});
        }
      }
    );
  };

  // get photo review data
  const getPhotoReviews = () => {
    serverController.connectFetchController(
      `review/product/${prd_id}?page=${reviewPage}&limit=30&sort=${reviewSort}&type=3`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setPhotoReviewItems(res || {});
        }
      }
    );
  };

  useEffect(() => {
    getReviews();
  }, [prd_id, reviewSort, reviewPage, photoCheck]);

  useEffect(() => {
    getPhotoReviews();
  }, [prd_id]);

  // s :: items
  const detailReviewItems = {
    reviewItems,
    reviewPage,
    setReviewPage,
    setReviewSort,
    photoCheck,
    setPhotoCheck,
    photoReviewItems,
    setPhotoReviewItems,
  };

  const detailInquiryItems = {
    product,
    inquiry,
    getInquiry,
    inquiryPageData,
  };
  // e :: items

  const getTabContent = (state) => {
    switch (state) {
      case 1:
        return <DetailProductImg product={product} prd_id={prd_id} />;
      case 2:
        return <DetailReview {...detailReviewItems} />;
      case 3:
        return <DetailInquiry {...detailInquiryItems} />;
      case 4:
        return <DetailGuide />;
      default:
        <></>;
    }
  };

  return (
    <Container>
      <DetailContentWrap>
        <DetailBtnWrap>
          <DetailBtnInner>
            <BottomZigZagImgBox tab={tab}>
              <PC>
                <BottomZigZagImg src={ZigzagPc} alt="bottom" />
              </PC>
              <Mobile>
                <BottomZigZagImg src={Zigzag} alt="bottom" />
              </Mobile>
            </BottomZigZagImgBox>
            <DetailBtnBox
              onClick={() => {
                setTab(1);
              }}
            >
              <DetailBtn BtnOn={tab === 1}>상품정보</DetailBtn>
            </DetailBtnBox>
            <DetailBtnBox onClick={() => setTab(2)}>
              <DetailBtn BtnOn={tab === 2}>
                리뷰
                {reviewItems?.totalReviewCount !== 0 && `(${reviewItems?.totalReviewCount})`}
              </DetailBtn>
            </DetailBtnBox>
            <DetailBtnBox
              onClick={() => {
                setTab(3);
              }}
            >
              <DetailBtn BtnOn={tab === 3}>
                상품문의 {inquiryLength !== 0 && `(${inquiryLength})`}
              </DetailBtn>
            </DetailBtnBox>
            <DetailBtnBox
              onClick={() => {
                setTab(4);
              }}
            >
              <DetailBtn BtnOn={tab === 4}>배송/반품</DetailBtn>
            </DetailBtnBox>
          </DetailBtnInner>
        </DetailBtnWrap>

        <DetailTabWrap>{getTabContent(tab)}</DetailTabWrap>
      </DetailContentWrap>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  padding-top: 100px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (100 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (20 / 428));
  }
`;
const DetailContentWrap = styled.div`
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const DetailBtnWrap = styled.div`
  position: relative;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  }
`;
const DetailBtnInner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
`;
const BottomZigZagImgBox = styled.div`
  position: absolute;
  bottom: -8px;
  width: 310px;
  overflow: hidden;
  transition: ease 0.3s all;
  cursor: pointer;

  ${({ tab }) =>
    tab === 1
      ? `
  left : 0;
  `
      : tab === 2
        ? `
  left : 310px;
  `
        : tab === 3
          ? `
  left : 620px;
  `
          : `
  left : 930px;
  `}

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (310 / 1320));
    bottom: calc(100vw * (-8 / 1320));
    ${({ tab }) =>
    tab === 1
      ? `
    left : 0;
    `
      : tab === 2
        ? `
    left : calc(100vw * (310 / 1320));;
    `
        : tab === 3
          ? `
    left : calc(100vw * (620 / 1320));
    `
          : `
    left : calc(100vw * (930 / 1320));
    `}
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (96 / 428));
    bottom: calc(100vw * (-6 / 428));
    ${({ tab }) =>
    tab === 1
      ? `
    left : 0;
    `
      : tab === 2
        ? `
    left : calc(100vw * (95 / 428));;
    `
        : tab === 3
          ? `
    left : calc(100vw * (190 / 428));
    `
          : `
    left : calc(100vw * (285 / 428));
    `}
  }
`;
const BottomZigZagImg = styled.img`
  width: 100%;
`;
const DetailBtnBox = styled.div`
  width: calc(100% / 4);
`;
const DetailBtn = styled.p`
  position: relative;
  color: #777777;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  padding: 18px 0;
  cursor: pointer;

  ${({ BtnOn }) => {
    return BtnOn
      ? `
      color: #333333;
          
        `
      : ``;
  }}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    padding: calc(100vw * (18 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (15 / 428)) 0;
  }
`;
const DetailTabWrap = styled.div`
  width: 100%;
  margin-bottom: 120px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (120 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: 0;
  }
`;
