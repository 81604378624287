//react
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import { DateToText } from "../../../components/common/commonUse";

//css
import styled from "styled-components";

// img
import IconInquiryType1 from "../../img/icon/icon_product_inquiry_type1.png";
import IconInquiryType2 from "../../img/icon/icon_product_inquiry_type2.png";
import IconInquiryType3 from "../../img/icon/icon_product_inquiry_type3.png";
import IconLock from "../../img/icon/icon_secret_blue.png";
import IconRe from "../../img/icon/icon_recomment.png";

//page
import PasswordModal from "../common/modal/PasswordModal";
import Pagination from "../common/pagination/Pagination";
import Search from "../common/pagination/Search";
import InquiryType from "./InquiryType";

export default function InquiryLayout({
  type,
  setInqIndex,
  list,
  listComment,
  setIsModal,
}) {
  const history = useHistory();
  const detailClick = (inq_id) => {
    if (type == 1) return history.push("/store/productInquiry/detail");

    if (type == 2) return history.push(`/store/inquiry/detail/${inq_id}`);
  };

  return (
    <Container>
      <PC>
        <Content>
          <Thead>
            <DivisionTit>NO</DivisionTit>
            <SubjectTit>제목</SubjectTit>
            {/* <AnswerTit>작성자</AnswerTit> */}
            {/* <ViewTit>조회</ViewTit> */}
            <DateTit>작성일</DateTit>
          </Thead>
          {list.length > 0 ? (
            list?.map((value, index) => {
              const {
                inq_id,
                inq_secret,
                inq_title,
                inq_date,
                prd_id,
                inq_answer_content,
                inq_answer_date,
              } = value;
              return (
                <React.Fragment>
                  {
                    <React.Fragment>
                      <Cont>
                        <Division>{index + 1}</Division>
                        <Subject>
                          {inq_secret == 1 ? (
                            <SubjectText
                              onClick={() => {
                                setInqIndex(inq_id);
                                setIsModal(true);
                              }}
                            >
                              <LockImg src={IconLock} alt="비밀글입니다" />{" "}
                              {inq_title}
                            </SubjectText>
                          ) : (
                            <SubjectText
                              onClick={() => {
                                detailClick(inq_id);
                              }}
                            >
                              {inq_title}
                            </SubjectText>
                          )}
                        </Subject>
                        <User>{value.user_name}</User>
                        <View>{value.view}</View>
                        <Date>{DateToText(inq_date, "-", false)}</Date>
                      </Cont>
                      {inq_secret !== 1 && inq_answer_content !== null ? (
                        <Cont>
                          <Division></Division>
                          <Subject>
                            <SubjectText
                              onClick={() => {
                                detailClick(inq_id);
                              }}
                            >
                              <IconReComment src={IconRe} alt="답글" />
                              Re : {inq_answer_content}
                            </SubjectText>
                          </Subject>
                          <User>WALA스토어</User>
                          {/* <View>{value.view}</View> */}
                          <Date>{inq_answer_date}</Date>
                        </Cont>
                      ) : null}
                    </React.Fragment>
                  }
                </React.Fragment>
              );
            })
          ) : (
            <NoText>1:1문의 리스트가 없습니다.</NoText>
          )}
        </Content>
      </PC>

      <Mobile>
        <Content>
          {list?.map((value, index) => {
            const {
              inq_id,
              inq_secret,
              inq_title,
              inq_date,
              prd_id,
              inq_answer_content,
              inq_answer_date,
            } = value;
            return (
              <React.Fragment>
                {prd_id == -1 && (
                  <React.Fragment>
                    <Cont>
                      <Division>{index + 1}</Division>
                      <MbTitleWrap>
                        {inq_secret == 1 ? (
                          <SubjectText
                            onClick={() => {
                              setIsModal(true);
                            }}
                          >
                            <LockImg src={IconLock} alt="비밀글입니다" />{" "}
                            {inq_title}
                          </SubjectText>
                        ) : (
                          <SubjectText
                            onClick={() => {
                              detailClick(inq_id);
                            }}
                          >
                            {inq_title}
                          </SubjectText>
                        )}
                      </MbTitleWrap>
                      <User>{value.user_name}</User>
                      <View>{value.view}</View>
                      <Date>{DateToText(inq_date, "-", false)}</Date>
                    </Cont>
                    {inq_secret !== 1 && inq_answer_content !== null ? (
                      <Cont>
                        <Division></Division>
                        <Subject>
                          <SubjectText
                            onClick={() => {
                              detailClick(inq_id);
                            }}
                          >
                            <IconReComment src={IconRe} alt="답글" />
                            Re : {inq_answer_content}
                          </SubjectText>
                        </Subject>
                        <User>WALA스토어</User>
                        {/* <View>{value.view}</View> */}
                        <Date>{inq_answer_date}</Date>
                      </Cont>
                    ) : null}
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          })}
        </Content>
      </Mobile>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
// form
const Content = styled.div`
  width: 100%;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;

  @media screen and (max-width: 1320px) {
    border-top: calc(100vw * (1 / 1320)) solid #000;
    border-bottom: calc(100vw * (1 / 1320)) solid #000;
  }

  @media screen and (max-width: 1320px) {
    border-top: calc(100vw * (1 / 1320)) solid #000;
    border-bottom: calc(100vw * (1 / 1320)) solid #000;
  }
`;
const Thead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 0;
  text-align: center;
  color: #525252;
  font-weight: bold;
  font-size: 15px;
  border-bottom: 1px solid #d9d9d9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (21 / 1320)) 0;
    font-size: calc(100vw * (15 / 1320));
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }
`;
const DivisionTit = styled.div`
  width: 163px;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (163 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }
`;
const SubjectTit = styled(DivisionTit)`
  width: 652px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (652 / 1320));
  }
`;
const AnswerTit = styled(DivisionTit)`
  width: 169px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (169 / 1320));
  }
`;
const ViewTit = styled(DivisionTit)`
  width: 141px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (141 / 1320));
  }
`;
const DateTit = styled(DivisionTit)`
  width: 175px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (175 / 1320));
  }
`;
const Cont = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding: 22px 0;
  border-bottom: 1px solid #d9d9d9;
  &:last-child {
    border-bottom: none;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (22 / 1320)) 0;
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    align-items: center;
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (22 / 428)) 0;
    border-bottom: calc(100vw * (1 / 428)) solid #d9d9d9;
  }
`;
const Division = styled.div`
  width: 163px;
  font-size: 14px;
  color: #969696;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (163 / 1320));
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (40 / 428));
    font-size: calc(100vw * (14 / 428));
  }
`;
const Subject = styled.div`
  display: flex;
  align-items: center;
  width: 652px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (652 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const SubjectText = styled.div`
  display: flex;
  align-items: center;
  width: 600px;
  text-align: left;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (600 / 1320));
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (15 / 428));
  }
`;
const LockImg = styled.img`
  display: block;
  width: 14px;
  margin-right: 4px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (14 / 1320));
    margin-right: calc(100vw * (4 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (14 / 428));
    margin-right: calc(100vw * (4 / 428));
  }
`;
const IconReComment = styled.img`
  display: block;
  width: 13px;
  margin-right: 6px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (13 / 1320));
    margin-right: calc(100vw * (6 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (13 / 428));
    margin-right: calc(100vw * (6 / 428));
  }
`;
const User = styled.div`
  width: 169px;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (169 / 1320));
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: auto;
    font-size: calc(100vw * (13 / 428));
    text-align: center;
    margin-right: calc(100vw * (6 / 428));
    padding-right: calc(100vw * (6 / 428));
    border-right: calc(100vw * (1 / 428)) solid #969696;
    &:last-child {
      border-right: 0;
    }
  }
`;
const View = styled(User)`
  width: 141px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (141 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: auto;
    text-align: center;
  }
`;
const Date = styled(User)`
  width: 175px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (175 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    text-align: center;
  }
`;
// mobile
const MbTitleWrap = styled.div`
  width: calc(100% - calc(100vw * (140 / 428)));
`;
const MbTextWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: calc(100vw * (12 / 428));
`;
const NoText = styled.p`
  width: 100%;
  text-align: center;
  padding: 100px 0;
  font-size: 15px;
  color: #999;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (100 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (13 / 428));
    padding: calc(100vw * (50 / 428)) 0;
  }
`;
