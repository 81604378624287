import React, { useState, useEffect, useCallback } from 'react';
import serverController from '../../../server/serverController';
import { PC, Mobile } from '../../../MediaQuery';
import styled from 'styled-components';

import {  
    FieldBox,
    Label,    
    LabelName,
    Input,    
} from '../auth.elements';
import {    
    SubCheckButton, 
    MobileSubBox
} from './signup.element';

const SignupBusinessRegistrationInput = ({ setBusinessFile, setIsCheck }) => {
    const [value, setValue] = useState(null);    
    const [name, setName] = useState("");
    
    const onHandleChange = (e) => { 
        const {
            target: { files }
        } = e;   
        const file = files[0];  
        if (file) {
            setValue(file)
            setBusinessFile(file);            
            setName(file?.name)
        } else {
            setValue(null)
            setBusinessFile(null);            
            setName("")
        }       
    }; 

    useEffect(() => {
        setIsCheck(prev => ({...prev, isBusinessFile: Boolean(value)}))
    }, [value])

    return (
        <>
        <FieldBox>
            <Label htmlFor="file">
                <LabelName>사업자 등록증</LabelName>
                <FileInput 
                    type="file"
                    id="file1"
                    accept="image/*"
                    required
                    // value={value}
                    onChange={onHandleChange}
                />
                <FileName>{name}</FileName> 
                <PC>
                    <SubCheckButton htmlFor="file1">업로드</SubCheckButton>
                </PC>
            </Label>                             
            <Mobile>
                <MobileSubBox>
                    <SubCheckButton htmlFor="file1">업로드</SubCheckButton>
                </MobileSubBox>
            </Mobile>
        </FieldBox>
        </>
    )
}

export default SignupBusinessRegistrationInput;

const FileInput = styled(Input)`
    display: none;
`

const FileName = styled.span`
    position: absolute;
    left: calc(100vw * (162 / 1920));
    top: 50%;
    transform: translateY(-50%);
    color: #A5A5A5;
    font-size: calc(100vw * (16/ 1920));
    font-weight: 700;

    @media screen and (max-width: 1024px) {
        left: calc(100vw * (140 / 428)); top: calc(100vw * (5 / 428));
        transform: translateY(0);
        font-size: calc(100vw * (14/ 428));
    }
`