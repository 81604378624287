import React from 'react';
import FashionNewsMain from './FashionNewsMain';
import FashionNewsIntro from './FashionNewsIntro';

const FashionNews = () => {
    return (
        <div>
            <FashionNewsMain />
            <FashionNewsIntro />
        </div>
    )
}

export default FashionNews
