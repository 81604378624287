import React, { useState, useEffect } from 'react';
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery"
import styled from "styled-components"

//img
import IconCard from "../../../img/icon/icon_pay_card.png"
import IconBank from "../../../img/icon/icon_pay_bank.png"
import { PaymentAction } from "../../../../store/actionCreators";

export default function Payment() {
    const [method, setMethod] = useState("카드")

    useEffect(() => {
        PaymentAction.saveMethodInfo(method)
    }, [method])

    return (
        <Container>
                <PaymentWrap>
                    <TitleWrap>
                        <Title>결제수단</Title>
                    </TitleWrap>
                    <PaymentListWrap>
                        <PaymentList 
                        BtnOn={method === "카드"} 
                        onClick={() => setMethod("카드")}
                        >
                            <PaymentImg src={IconCard} alt="신용카드 결제" />
                            <PaymentText >신용카드</PaymentText>
                        </PaymentList>
                        
                        <PaymentList 
                        BtnOn={method === "가상계좌"}
                        onClick={() => setMethod("가상계좌")}
                        >
                            <PaymentImg src={IconBank} alt="무통장입금 결제" />
                            <PaymentText 
                            >무통장입금</PaymentText>
                        </PaymentList>
                    </PaymentListWrap>
                </PaymentWrap>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    margin-bottom: 20px;

    @media screen and (max-width: 1320px) {
        margin-bottom: calc(100vw*(20/1320));
    }

    @media screen and (max-width: 1024px) {
        margin-bottom: calc(100vw*(30/428));
        border-top: calc(100vw*(12/428)) solid #E9EBEE;
    }
`
const PaymentWrap = styled.div`
    width: 100%;

    @media screen and (max-width: 1024px) {
        width: calc(100vw*(388/428));
        margin: 0 auto;
    }
` 
const TitleWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #d9d9d9;

    @media screen and (max-width: 1320px) {
        padding: calc(100vw*(20/1320)) 0;
        border-bottom: calc(100vw*(1/1320)) solid #d9d9d9;
    }

    @media screen and (max-width: 1024px) {
        justify-content: space-between;
        padding: calc(100vw*(30/428)) 0 calc(100vw*(10/428));
        border-bottom: none;
    }
` 
const Title = styled.p`
    font-size: 22px;
    font-weight: bold;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(22/1320));
    }

    @media screen and (max-width: 1024px) {
        font-size: calc(100vw*(18/428));
    }
` 
// Payment
const PaymentListWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    @media screen and (max-width: 1320px) {
        margin-top: calc(100vw*(20/1320));
    }
    
    @media screen and (max-width: 1024px) {
        margin-top: calc(100vw*(20/428));
    }
` 
const PaymentList = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100px; height: 100px;
    padding: 10px;
    border-radius: 11px;
    margin-right: 20px;
    cursor: pointer;
    ${({BtnOn})=>{
        return BtnOn ?
        `border: 2px solid #000;`
        :
        ``
    }}

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(100/1320)); height: calc(100vw*(100/1320));
        padding: calc(100vw*(10/1320));
        border-radius: calc(100vw*(11/1320));
        margin-right: calc(100vw*(20/1320));
        ${({BtnOn})=>{
            return BtnOn ?
            `border: calc(100vw*(2/1320)) solid #000;`
            :
            ``
        }}
    }
    
    @media screen and (max-width: 1024px) {
        width: calc(100vw*(100/428)); height: calc(100vw*(100/428));
        padding: calc(100vw*(10/428));
        border-radius: calc(100vw*(11/428));
        margin-right: calc(100vw*(20/428));
        ${({BtnOn})=>{
            return BtnOn ?
            `border: calc(100vw*(2/428)) solid #000;`
            :
            ``
        }}
    }
` 
const PaymentImg = styled.img`
    width: 40px;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(40/1320));
    }

    @media screen and (max-width: 1024px) {
        width: calc(100vw*(40/428));
    }
` 
const PaymentText = styled.p`
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-top: 8px;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(16/1320));
        margin-top: calc(100vw*(8/1320));
    }

    @media screen and (max-width: 1024px) {
        font-size: calc(100vw*(15/428));
        margin-top: calc(100vw*(8/428));
    }
` 