//react
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import serverController from "../../server/serverController";

//css
import styled from "styled-components";

//page
import ProductList from "../../component/common/product/ProductList";

const cats = [
  "상의",
  "하의",
  "원피스,세트",
  "스커트",
  "아우터",
  "슈즈",
  "가방",
  "악세사리",
  "패션소",
  "키즈",
];

export default function CatProduct() {
  const { main_cat, sub_cat } = useParams();
  const [list, setList] = useState([]);

  async function getHitData() {
    await serverController.connectFetchController(
      `products?prd_main_cat=${cats.indexOf(main_cat) + 1}`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setList(res?.list);
        }
      },
      (err) => console.error(err)
    );
  }

  useEffect(() => {
    getHitData();
  }, [main_cat, sub_cat]);

  return (
    <Container>
      <AllBrandWrap>
        <SubTitleWrap>
          <SubTitle>PRODUCTS</SubTitle>
          <FilterWrap>
            {/* <FilterText>추천순 <FilterImg src={iconArrow} alt="추천순으로 보기"/></FilterText> */}
          </FilterWrap>
        </SubTitleWrap>
        <AllBrandLogoWrap>
          {list.length > 0 ? (
            list.map((value, index) => {
              return (
                <ProductBox key={`${value.prd_id}-${index}`}>
                  <ProductList
                    key={index}
                    index={index}
                    value={value}
                    height={"allProduct"}
                    rank={true}
                  />
                </ProductBox>
              );
            })
          ) : (
            <NoText>해당 카테고리의 상품이 없습니다.</NoText>
          )}
          {/* Pagination */}
          {/* <Pagination/> */}
        </AllBrandLogoWrap>
      </AllBrandWrap>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  padding-top: 20px;
  margin-bottom: 180px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (20 / 1320));
    margin-bottom: calc(100vw * (180 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: 0;
    margin-bottom: calc(100vw * (100 / 428));
  }
`;
const AllBrandWrap = styled.div`
  width: 100%;
`;
const SubTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1300px;
  margin: 30px auto;
  padding: 30px 0;
  border-bottom: 1px solid #646464;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
    margin: calc(100vw * (30 / 1320)) auto;
    padding: calc(100vw * (30 / 1320)) 0;
    border-bottom: 1px solid #646464;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    margin: calc(100vw * (20 / 428)) auto;
    padding: calc(100vw * (20 / 428)) 0;
    border-bottom: 1px solid #646464;
  }
`;
const SubTitle = styled.h2`
  font-size: 28px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (28 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const FilterWrap = styled.div`
  width: auto;
`;
const AllBrandLogoWrap = styled.div`
  width: 1300px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
  }
`;
const ProductBox = styled.div`
  width: calc(100% / 5 - 12px);
  margin-right: 15px;
  &:nth-child(5n) {
    margin-right: 0;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100% / 5 - calc(100vw * (15 / 1320)));
    margin-right: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100% / 2 - calc(100vw * (8 / 428)));
    margin-right: calc(100vw * (16 / 428));
    &:nth-child(5n) {
      margin-right: calc(100vw * (16 / 428));
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
`;
const NoText = styled.p`
  width: 100%;
  font-size: 15px;
  text-align: center;
  padding: 60px 0;
  color: #999999;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (60 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    padding: calc(100vw * (50 / 428)) 0;
  }
`;
