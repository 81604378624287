import React, { useState, useRef, useEffect } from 'react';
import { useParams, useHistory, } from 'react-router-dom';
import { PC, Mobile } from '../common/mediaStlye';
import serverController from '../../server/serverController';
import styled from 'styled-components';
import { numberWithCommas } from '../common/commonUse';
import { imgURL } from "../../server/appInfoContoller";


import {
    Container,
    Layout,
    StyleSubHead,
    StyleBackButton,
    StyleRegistWrap,
    StyleRegistForm,
    StyleRegistField,
    StyleFieldName,
    StyleFieldText,
    StyleRegistButtons,
    StyleRegistButton,
    Date,
    Nub,
    Title,
    ImgBox
} from './stylistDetail.element';

import icon from "../../img/brand/benner_icon.svg";
import arrow from "../../img/brand/arrow.png";
import Img from "../../images/category-idol-banner.png";


const StylistContentsModify = () => {
    const params = useParams();
    const { purchasePrd } = params;
    const [detailData, setDetailData] = useState([]);
    const [bankData, setBankData] = useState([]);

    const history = useHistory();
    const onHandleGoback = () => history.goBack();

    const getDetailData = async () => {
        await serverController.connectFetchController(`purchase/detail/${purchasePrd}`, "GET", null, (res) => {
            if (res.result == 1) {
                console.log(res)
                setDetailData(res.sponsored)
            }
        });
    }
    const getVbankData = async () => {
        await serverController.connectFetchController(`vbank/${detailData.order_id}`, "GET", null, (res) => {
            if (res.result == 1) {
                console.log(res)
                setBankData(res)
            }
        });
    }
    useEffect(() => {
        getDetailData();
        if (detailData.purchase_state == '10') {
            getVbankData()
        }
    }, []);

    const receiveState = (i) => {
        switch (i) {
            case 0:
                return '방문'
            case 1:
                return '퀵'
            case 2:
                return '택배'
        }
    }

    const purchaseState = (i) => {
        switch (i) {
            case '1':
                return '결제전(승인대기)'
            case '2':
                return '결제전(승인완료)'
            case '3':
                return '결제전(반려)'
            case '4':
                return '결제완료(반납대기)'
            case '5':
                return '결제완료(반납완료)'
            case '6':
                return '협찬 주문취소'
            case '10':
                return '결제전(무통장 입금 대기)'
            case '20':
                return '결제완료'
            case '70':
                return '주문취소 완료'
            case '71':
                return '주문취소 요청중'
        }
    }

    return (
        <Container>
            <Layout sub>
                <StyleSubHead sub>
                    <StyleBackButton onClick={onHandleGoback}>
                        <img src={arrow} alt="arrow" />
                    </StyleBackButton>
                    <h2>뒤로가기</h2>
                </StyleSubHead>

                <StyleRegistWrap>
                    <StyleRegistForm >
                        <Nub>협찬번호: {detailData.order_id}</Nub>
                        <Date>{detailData.order_date}</Date>
                        <Title>협찬정보</Title>
                        <ImgBox>
                            <img src={imgURL + detailData.prd_thumbnail} alt="detail_image" />
                        </ImgBox>
                        <StyleRegistField>
                            <StyleFieldName>협찬제품</StyleFieldName>
                            <StyleFieldText>{detailData.prd_name}</StyleFieldText>
                        </StyleRegistField>
                        <StyleRegistField>
                            <StyleFieldName>옵션</StyleFieldName>
                            <StyleFieldText>{detailData.opt_detail ? detailData.opt_detail : '-'}</StyleFieldText>
                        </StyleRegistField>

                        <Title>업체정보</Title>
                        <StyleRegistField>
                            <StyleFieldName>업체명</StyleFieldName>
                            <StyleFieldText>{detailData.company_name}</StyleFieldText>
                        </StyleRegistField>
                        <StyleRegistField>
                            <StyleFieldName>담당자</StyleFieldName>
                            <StyleFieldText>{detailData.stylist_name}</StyleFieldText>
                        </StyleRegistField>
                        <StyleRegistField>
                            <StyleFieldName>셀럽(착장)</StyleFieldName>
                            <StyleFieldText>{detailData.celevrity_name}</StyleFieldText>
                        </StyleRegistField>
                        <StyleRegistField>
                            <StyleFieldName>작품</StyleFieldName>
                            <StyleFieldText>{detailData.work_name}</StyleFieldText>
                        </StyleRegistField>

                        <Title>결제정보</Title>
                        <StyleRegistField>
                            <StyleFieldName>결제 금액</StyleFieldName>
                            <StyleFieldText>{numberWithCommas(detailData.price ? detailData.price : 0)}원</StyleFieldText>
                        </StyleRegistField>
                        <StyleRegistField>
                            <StyleFieldName>수령방법</StyleFieldName>
                            <StyleFieldText>{receiveState(detailData.receive_type)}</StyleFieldText>
                        </StyleRegistField>
                        <StyleRegistField>
                            <StyleFieldName>결제방식</StyleFieldName>
                            {
                                detailData.purchase_state !== '20' ?
                                    <StyleFieldText>{purchaseState(detailData.purchase_state)}</StyleFieldText>
                                    :
                                    <StyleFieldText>{detailData.vbank_num ? '무통장' : '카드'}</StyleFieldText>
                            }
                        </StyleRegistField>
                        {
                            detailData.purchase_state == '10' ?
                                <StyleRegistField>
                                    <StyleFieldName>무통장 계좌</StyleFieldName>
                                    <StyleFieldText>{receiveState(detailData.receive_type)}</StyleFieldText>
                                </StyleRegistField>
                                :
                                null
                        }

                    </StyleRegistForm>
                </StyleRegistWrap>
            </Layout>
        </Container>
    )
}

export default StylistContentsModify;

