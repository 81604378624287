//react
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery"

//css
import styled from "styled-components"

export default function SubTitleSection({content,title,subTitle,subText, children, off, isSearch}) {

  return (
    <Container content={content}>
        <SubTitleWrap>
            <SubTitleInner isSearch={isSearch}>
                {!off && <SubTitleText>{subText}</SubTitleText>}
                <SubTitle>{title}<SubText>{subTitle}</SubText></SubTitle>
            </SubTitleInner>
        </SubTitleWrap>
        {children}
    </Container>
  );
}
const Container = styled.div`
    width: 1300px;
    margin: 0 auto;
    ${({content})=>{
        return content ?
        `width: 1000px;`
        :
        ``
    }}
    @media screen and (max-width: 1320px) {
        width: calc(100vw*(1300/1320));
        ${({content})=>{
            return content ?
            `width: calc(100vw*(1000/1320));`
            :
            ``
        }}
    }
    @media screen and (max-width: 930px) {
        width: calc(100vw*(388/428));
    }
`
const SubTitleWrap = styled.div`
    width: 100%;
`
const SubTitleInner = styled.div`
    width: 100%; max-height: 251px;
    padding: 20px 0;
    ${({isSearch}) => isSearch ? `
    padding: 40px 0 40px;
    `:``}
    
    @media screen and (max-width: 1320px) {
        max-height: calc(100vw*(251/1320));
        padding: calc(100vw*(20/1320)) 0;
    }
    @media screen and (max-width: 930px) {
        max-height: calc(100vw*(180/428));
        padding: calc(100vw*(20/428)) 0;
    }
`
const SubTitle = styled.h2`
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
    text-align: left;
    color: #222222;
    text-transform: uppercase;
    font-weight: 700;
    
    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(28/1320));
    }
    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(24/428));
    }
`
const SubText = styled.p`
    font-size: 20px;
    text-align: left;
    color: #555555;
    margin-top: 5px;
    
    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(20/1320));
        margin-top: calc(100vw*(5/1320));
    }
    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(18/428));
        margin-top: calc(100vw*(5/428));
    }
`
const SubTitleText = styled.p`
    font-size: 16px;
    text-align: left;
    color: #999999;
    margin-top: 30px;
    
    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(16/1320));
        margin-top: calc(100vw*(30/1320));
    }
    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(15/428));
        margin-top: calc(100vw*(15/428));
        line-height: 1.6;
        padding: 0;
        word-break: keep-all;
    }
`