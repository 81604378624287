import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Mobile, PC } from '../common/mediaStlye';
import styled from 'styled-components';
import serverController from '../../server/serverController';
import { imgURL } from '../../server/appInfoContoller';
import { useSelector } from 'react-redux';

import {
    Wrapper,
    BannerSlide,
    BannerText,
    BannerImg
} from './CategoryContents.element';

import Banner from '../../images/main_intro_item.png';
import banner2 from '../../images/category/category-pro-1.png';

import { Swiper, SwiperSlide, } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import "swiper/swiper-bundle.css";
import './CategoryMain.css';
SwiperCore.use([Navigation]);

const CategoryMain = ({ reverse }) => {
    const history = useHistory()
    const { isLogged } = useSelector(state => state.userData);
    const { category_id } = useParams();

    const [list, setList] = useState([]);
    const getCategoryBanners = async () => {
        await serverController.connectFetchController(
            `bannerlist?c_cat_id=${category_id}`,
            "GET",
            null,
            (res) => {
                console.log(res, "카테고리 배너 목록 res");
                if (res?.result === 1) {
                    setList(res.list ?? []);
                }
            },
            (err) => console.error(err)
        )
    };

    useEffect(() => {
        getCategoryBanners()
    }, [category_id, isLogged])

    return (
        <>
            <PC>
                <MainWrapper className="category-swiper">
                    {
                        (list && Array.isArray(list))
                            ? <Swiper
                                navigation
                                loop
                            // initialSlide={0}
                            >
                                {
                                    list.map((v, i) => {

                                        return (
                                            <SwiperSlide key={v?.contents_id}>
                                                <BannerSlide reverse={reverse}>
                                                    <BannerText reverse={reverse}>
                                                        <h3>{v?.ban_title}</h3>
                                                        <p>
                                                            {v?.ban_intro}
                                                            {/* {ReactHtmlParser()} */}
                                                        </p>
                                                    </BannerText>

                                                    <BannerImg>
                                                        <img src={imgURL + v?.ban_image} alt={v?.contents_intro} />
                                                    </BannerImg>
                                                </BannerSlide>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                            : null
                    }

                </MainWrapper>
            </PC>

            <Mobile>
                <MainWrapper className="category-swiper">
                    <Swiper
                        navigation
                        loop
                    >
                        {
                            list.map((v, i) => {
                                const src = v?.contents_thumbnail?.split(",")[0]

                                return (
                                    <SwiperSlide key={v?.contents_id}>
                                        <BannerSlide reverse={reverse}>
                                            <BannerImg>
                                                <img src={imgURL + v?.ban_image} alt={v?.contents_intro} />
                                            </BannerImg>
                                            <BannerText reverse={reverse}>
                                                <h3>{v?.contents_title}</h3>
                                                <p>
                                                    {v?.contents_teg}
                                                </p>
                                            </BannerText>
                                        </BannerSlide>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </MainWrapper>
            </Mobile>
        </>
    )
}

export default CategoryMain;

const MainWrapper = styled(Wrapper)`
    width: 100%;
    height: calc(100vw * (738 / 1920));
    padding-bottom: calc(100vw * (153 / 1920));

    @media ${props => props.theme.container} {
        height: auto;
        padding: calc(100vw * (80 / 428)) 0 calc(100vw * (100 / 428));
    }

    
`