import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { PaymentAction } from "../../../../store/actionCreators";
import serverController from "../../../server/serverController";

import PayAdressModal from "../../common/modal/PayAdressModal";
import AdressEditModal from "../../common/modal/AdressEditModal";

import IconArrow from "../../../img/icon/icon_selectPay_arrow.png";

export default function PayAdress() {
  const [address, setAddress] = useState("");
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [requests, setRequests] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [delivery, setDelivery] = useState(false);

  const onHandleRequest = (e) => {
    const value = e.target.value;
    setRequests(value);
  };

  useEffect(() => {
    PaymentAction.saveLocationInfo({
      receiver_state: delivery,
      receiver_request: requests,
    });
  }, [requests, delivery]);

  const { location } = useSelector((state) => state.paymentData);

  useEffect(() => {
    if (location) {
      setTitle(location?.receiver_title);
      setName(location?.receiver_username);
      setPhone(location?.receiver_phone);
      setAddress(
        location.receiver_address + ` ` + location.receiver_address_detail
      );
    }
  }, [location]);

  useEffect(() => {
    serverController.connectFetchController(`receivers`, "GET", null, (res) => {
      console.log("res", res);
      if (res.result == 1) {
        // const item = res?.list[0];
        // PaymentAction.saveLocationInfo({
        //   ...item,
        // });
        setDelivery(true);
      } else {
        setDelivery(false);
      }
    });
  }, [isModal]);

  return (
    <Container>
      <PC>
        <PayAdressWrap>
          <TitleWrap>
            <Title>배송지</Title>
            <TitleBtn onClick={() => setIsModal(true)}>
              {delivery ? "변경" : "추가"}
            </TitleBtn>
          </TitleWrap>
          {isModal && (
            <PayAdressModal setIsModal={setIsModal} isModal={isModal} />
          )}
          {delivery ? (
            <AdressWrap>
              <AdressNameWrap>
                <AdressName>{title ? title : "배송지 이름"}</AdressName>
              </AdressNameWrap>
              <AdressTextWrap>
                <AdressText>{address ? address : "주소"}</AdressText>
              </AdressTextWrap>
              <AdressTelWrap>
                <AdressTelName>{name ? name : "이름"}</AdressTelName>
                <AdressTel>{phone ? phone : "010-0000-0000"}</AdressTel>
              </AdressTelWrap>
              <AdressMemoWrap>
                <AdressMemo name="" id="" onChange={onHandleRequest}>
                  <AdressMemoList value="">
                    배송시 요청사항을 선택해 주세요.
                  </AdressMemoList>
                  <AdressMemoList value="부재시 문앞에 놓아주세요.">
                    부재시 문앞에 놓아주세요.
                  </AdressMemoList>
                  <AdressMemoList value="부재시 경비실에 맡겨 주세요.">
                    부재시 경비실에 맡겨 주세요.
                  </AdressMemoList>
                  <AdressMemoList value="부재시 전화 또는 문자 주세요.">
                    부재시 전화 또는 문자 주세요.
                  </AdressMemoList>
                  <AdressMemoList value="택배함에 넣어 주세요.">
                    택배함에 넣어 주세요.
                  </AdressMemoList>
                  <AdressMemoList value="파손 위험 상품입니다. 배송시 주의해 주세요.">
                    파손 위험 상품입니다. 배송시 주의해 주세요.
                  </AdressMemoList>
                  <AdressMemoList value="배송 전에 연락주세요.">
                    배송 전에 연락주세요.
                  </AdressMemoList>
                </AdressMemo>
              </AdressMemoWrap>
            </AdressWrap>
          ) : (
            <NoData>등록된 배송지가 없습니다.</NoData>
          )}
        </PayAdressWrap>
      </PC>

      <Mobile>
        <PayAdressWrap>
          <TitleWrap>
            <Title>배송지</Title>
            <TitleBtn onClick={() => setIsModal(true)}>
              {delivery ? "변경" : "추가"}
            </TitleBtn>
          </TitleWrap>
          {isModal && (
            <PayAdressModal setIsModal={setIsModal} isModal={isModal} />
          )}
          {delivery ? (
            <AdressWrap>
              <AdressNameWrap>
                <AdressName>{title}</AdressName>
              </AdressNameWrap>
              <AdressTextWrap>
                <AdressText>{address}</AdressText>
              </AdressTextWrap>
              <AdressTelWrap>
                <AdressTelName>{name}</AdressTelName>
                <AdressTel>{phone}</AdressTel>
              </AdressTelWrap>
              <AdressMemoWrap>
                <AdressMemo name="" id="" onChange={onHandleRequest}>
                  <AdressMemoList value="">
                    배송시 요청사항을 선택해 주세요.
                  </AdressMemoList>
                  <AdressMemoList value="부재시 문앞에 놓아주세요.">
                    부재시 문앞에 놓아주세요.
                  </AdressMemoList>
                  <AdressMemoList value="부재시 경비실에 맡겨 주세요.">
                    부재시 경비실에 맡겨 주세요.
                  </AdressMemoList>
                  <AdressMemoList value="부재시 전화 또는 문자 주세요.">
                    부재시 전화 또는 문자 주세요.
                  </AdressMemoList>
                  <AdressMemoList value="택배함에 넣어 주세요.">
                    택배함에 넣어 주세요.
                  </AdressMemoList>
                  <AdressMemoList value="파손 위험 상품입니다. 배송시 주의해 주세요.">
                    파손 위험 상품입니다. 배송시 주의해 주세요.
                  </AdressMemoList>
                  <AdressMemoList value="배송 전에 연락주세요.">
                    배송 전에 연락주세요.
                  </AdressMemoList>
                </AdressMemo>
              </AdressMemoWrap>
            </AdressWrap>
          ) : (
            <NoData>등록된 배송지가 없습니다.</NoData>
          )}
          {/* <AdressNameWrap>
                            <AdressName>홍길동<AdressType>기본배송지</AdressType></AdressName>
                        </AdressNameWrap>
                        <AdressTextWrap>
                            <AdressText>서울 성동구 아차산로 66 (성수동2가), 지하1층</AdressText>
                        </AdressTextWrap>
                        <AdressTelWrap>
                            <AdressTelName>홍길동</AdressTelName>
                            <AdressTel>010-1111-2222</AdressTel>
                        </AdressTelWrap>
                        <AdressMemoWrap>
                            <AdressMemo name="" id="">
                                <AdressMemoList value="">배송시 요청사항을 선택해 주세요.</AdressMemoList>
                                <AdressMemoList value="">부재시 문앞에 놓아주세요.</AdressMemoList>
                                <AdressMemoList value="">부재시 경비실에 맡겨 주세요.</AdressMemoList>
                                <AdressMemoList value="">부재시 전화 또는 문자 주세요.</AdressMemoList>
                                <AdressMemoList value="">택배함에 넣어 주세요.</AdressMemoList>
                                <AdressMemoList value="">파손 위험 상품입니다. 배송시 주의해 주세요.</AdressMemoList>
                                <AdressMemoList value="">배송 전에 연락주세요.</AdressMemoList>
                                <AdressMemoList value="">직접입력</AdressMemoList>
                            </AdressMemo>
                        </AdressMemoWrap> */}
          {/* </AdressWrap> */}
        </PayAdressWrap>
      </Mobile>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: calc(100vw * (20 / 428));
  }
`;
const PayAdressWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 1024px) {
    width: calc(100vw * (388 / 428));
    margin: 0 auto;
    border-top: calc(100vw * (1 / 428)) solid #000;
  }
`;
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 15px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
    margin-bottom: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 1024px) {
    padding: calc(100vw * (20 / 428)) 0;
    border-bottom: none;
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const Title = styled.p`
  font-size: 22px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 1024px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const TitleBtn = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #000;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 1024px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
// Adress
const AdressWrap = styled.div`
  width: 100%;
  padding: 20px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }

  @media screen and (max-width: 1024px) {
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const AdressNameWrap = styled.div`
  width: 100%;
  margin-bottom: 10px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const AdressName = styled.p`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 1024px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const AdressType = styled.span`
  display: block;
  width: 90px;
  font-size: 13px;
  text-align: center;
  color: #000;
  margin-left: 10px;
  padding: 4px 0;
  border: 1px solid #000;
  border-radius: 13px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (90 / 1320));
    font-size: calc(100vw * (13 / 1320));
    margin-left: calc(100vw * (10 / 1320));
    padding: calc(100vw * (4 / 1320)) 0;
    border: calc(100vw * (1 / 1320)) solid #000;
    border-radius: calc(100vw * (13 / 1320));
  }

  @media screen and (max-width: 1024px) {
    width: calc(100vw * (85 / 428));
    font-size: calc(100vw * (12 / 428));
    margin-left: calc(100vw * (10 / 428));
    padding: calc(100vw * (4 / 428)) 0;
    border: calc(100vw * (1 / 428)) solid #000;
    border-radius: calc(100vw * (13 / 428));
  }
`;
const AdressTextWrap = styled.div`
  width: 100%;
  margin-bottom: 15px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: calc(100vw * (15 / 428));
  }
`;
const AdressText = styled.p`
  font-size: 15px;
  color: #525252;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 1024px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const AdressTelWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 22px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: calc(100vw * (20 / 428));
  }
`;
const AdressTelName = styled.p`
  font-size: 15px;
  color: #525252;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 1024px) {
    font-size: calc(100vw * (13 / 428));
    color: #969696;
  }
`;
const AdressTel = styled(AdressTelName)`
  margin-left: 8px;

  @media screen and (max-width: 1320px) {
    margin-left: calc(100vw * (8 / 1320));
  }

  @media screen and (max-width: 1024px) {
    margin-left: calc(100vw * (8 / 428));
  }
`;
const AdressMemoWrap = styled.div`
  width: 100%;
`;
const AdressMemo = styled.select`
  width: 430px;
  height: 45px;
  font-size: 15px;
  color: #525252;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  text-align: center;
  padding: 0 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f7f7f7 url(${IconArrow}) no-repeat center right 15px/10px;
  border-radius: 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (430 / 1320));
    height: calc(100vw * (45 / 1320));
    font-size: calc(100vw * (15 / 1320));
    border: calc(100vw * (1 / 1320)) solid #e4e4e4;
    border-radius: calc(100vw * (5 / 1320));
    padding: 0 calc(100vw * (20 / 1320));
    background: #f7f7f7 url(${IconArrow}) no-repeat center right
      calc(100vw * (15 / 1320)) / calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: calc(100vw * (45 / 428));
    font-size: calc(100vw * (15 / 428));
    border: calc(100vw * (1 / 428)) solid #e4e4e4;
    border-radius: calc(100vw * (5 / 428));
    padding: 0 calc(100vw * (20 / 428));
    background: #f7f7f7 url(${IconArrow}) no-repeat center right
      calc(100vw * (15 / 428)) / calc(100vw * (10 / 428));
  }
`;
const AdressMemoList = styled.option`
  font-size: 15px;
  color: #525252;
  padding: 5px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (5 / 1320)) 0;
  }
  @media screen and (max-width: 1024px) {
    font-size: calc(100vw * (14 / 428));
    padding: calc(100vw * (5 / 428)) 0;
  }
`;
const NoData = styled.p`
  font-size: 15px;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
