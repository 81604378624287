import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserDataAction } from '../../../store/actionCreators';
import serverController from '../../../server/serverController';
import IconBack from '../../../images/page-button.png';

import {
    Container,
    Layout,
    AuthIntroMessage,
    Form,
    Label,
    LabelName,
    EmailInput,
    FindTelInput,
    FindTelNumInput
} from '../auth.elements';
import {
    LoginBox,
    LoginIntroMessage,
    LoginSub,
    IdSaveLabel,
    IdSaveCheckbox,
    LoginBottom,
    LoginBtn,
    SignUpLinkBox,
    SignUpLinkButton,
    LoginPageBtn
} from './FindID.elements';

const FindID = () => {
    const history = useHistory();
    const { type_id } = useParams();
    const [isBtn, setIsBtn] = useState(true);
    const [showId, setShowId] = useState(false);
    const [telValue, setTelValue] = useState("");
    const [num, setnum] = useState("");
    const [numValue, setnumValue] = useState("");
    const [findId, setFindId] = useState("");

    // 아이디찾기 클릭 
    const onClickFindIdNum = async () => {
        if (!telValue) {
            alert("핸드폰 번호를 입력해주세요.");
            return;
        }
        if (isBtn) {
            await serverController.connectFetchController(
                `find/id?mem_phone=${telValue}`,
                "POST", null, (res) => {
                    if (res.result == 1) {
                        alert('인증번호가 발송되었습니다.')
                        setnum(res.code)
                        setFindId(res.email)
                        setIsBtn(false)
                    } else {
                        alert('해당 번호로 가입된 계정이 없습니다.')
                    }
                },
                (err) => console.error(err)
            );
        }
    }
    const onClickFindId = async () => {
        if (num !== numValue) {
            alert("인증번호가 일치하지 않습니다.");
            return;
        } else {
            setShowId(true)
        }

    }

    return (
        <Container>
            <Layout>
                <LoginBox>
                    <AuthIntroMessages>
                        <img src={IconBack} alt="" onClick={() => { history.push('/login'); }} />
                        <h2>아이디 찾기</h2>
                    </AuthIntroMessages>

                    <Form>
                        <Label>
                            <LabelName>핸드폰번호</LabelName>
                            <FindTelInput value={telValue} onChange={(e) => setTelValue(e.target.value)} />
                        </Label>
                        <Label>
                            <LabelName>인증번호</LabelName>
                            <FindTelNumInput value={numValue} onChange={(e) => setnumValue(e.target.value)} />
                        </Label>
                        {
                            showId && <Text>가입한 계정의 아이디는 <b>"{findId}"</b> 입니다.</Text>
                        }
                        <LoginBottom>
                            {
                                !showId ?
                                    isBtn ?
                                        <LoginBtn onClick={onClickFindIdNum}>인증번호 전송</LoginBtn>
                                        :
                                        <LoginBtn onClick={onClickFindId}>인증</LoginBtn>
                                    :
                                    null
                            }
                            <LoginPageBtn onClick={() => { history.push('/login'); }}>로그인하러 가기</LoginPageBtn>
                        </LoginBottom>
                    </Form>
                </LoginBox>

                <SignUpLinkBox>
                    <p>아직 WALA 회원이 아니신가요?</p>
                    <SignUpLinkButton onClick={() => history.push('/signup')}>회원가입</SignUpLinkButton>
                </SignUpLinkBox>
            </Layout>
        </Container>
    )
}

const AuthIntroMessages = styled(AuthIntroMessage)`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {position: absolute; bottom: calc(100vw*(28/1920)); left: 0; display: block; cursor: pointer; width: calc(100vw*(15/1920));}

    @media screen and (max-width: 1024px) {
        & img {position: absolute; bottom: calc(100vw*(17/428)); left: 0; display: block; cursor: pointer; width: calc(100vw*(12/428));}
    }
`
const Text = styled.p`
    font-size: calc(100vw*(17/1920));
    text-align: center;
    color: #aaaaaa;
    & b {font-weight: bold; color: #999;}

    @media screen and (max-width: 1024px) {
        font-size: calc(100vw*(16/428));
    }
`

export default FindID
