import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PC, Mobile } from "../../components/common/mediaStlye";
import styled from "styled-components";

import MyPageHeader from "../../components/common/header/MyPageHeader";
import MyPageAccount from "../../components/auth/mypage/MyPageAccount";
import Footer from "../../components/common/footer/Footer";
import FooterMenu from "../../components/common/footer/FooterMenu";

const MyInfoAccountPage = () => {
  const history = useHistory();

  useEffect(() => {
    window.addEventListener("resize", function (event) {
      if (window.innerWidth > 1024) {
        console.log("마이페이지로 이동!!");
        history.push("/mypage");
      }
    });
  }, []);

  return (
    <Container>
      <MyPageHeader title="회원정보 " />
      <MyPageAccount />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
    </Container>
  );
};
const Container = styled.div`
  @media screen and (max-width: 1024px) {
    padding-bottom: calc(100vw * (68 / 428));
  }
`;

export default MyInfoAccountPage;
