import styled from "styled-components";

// interface ICardTemplate {
//   children: React.ReactNode;
//   width: 'calc(50% - 10px)' | 'calc(33% - 10px)' | 'calc(25% - 10px)' | 'calc(100% - 10px)';
//   offHover?: boolean;
// }

// 커서는 children 에서
export default function CardTemplate({ width, offHover, children }) {
  return (
    <S.CardTemplate width={width} whileHover={{ scale: offHover ? 1 : 1.01 }}>
      {children}
    </S.CardTemplate>
  );
}

const S = {
  CardTemplate: styled.div`
    width: ${(props) => props.width};
    margin: 5px;
    box-shadow: rgb(0 0 0 / 4%) 0px 4px 16px 0px;
    background-color: #fff;

    @media screen and (max-width: 930px) {
      width: calc(50% - 10px);
    }
  `,
};
