import styled, { css } from "styled-components";
import {
  pc,
  mobil,
  Media,
  Px,
  Width,
  Height,
  Top,
  Bottom,
  Left,
  Right,
  FontSize,
  Margin,
  Margin2,
  Padding,
  Padding2,
  MT,
  MB,
  ML,
  MR,
  PT,
  PB,
  PL,
  PR,
  LineH,
  mquery,
} from "../mediaStlye";

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${MT(100, pc)}
  & .sns_footer li {
    cursor: pointer;
  }

  @media ${(props) => props.theme.container} {
    background-color: #000;
    border-top: 1px solid #222;
    ${Padding(15, 25, 25, 25, mobil)}
    ${MT(120, mobil)}

        & > ul.sns_footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      justify-content: space-between;
      gap: ${Px(10, mobil)};
    }
    & li:first-child img {
      width: calc(100vw * (12 / 428));
      margin: 0 calc(100vw * (5 / 428));
    }
    & li:last-child img {
      width: calc(100vw * (24 / 428));
      margin: 0 calc(100vw * (5 / 428));
    }

    & > ul li img {
      /* max-width: ${Px(14, mobil)}; */
    }
  }
`;

export const FooterTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;

  & > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw * (700 / 1920));
    height: calc(100vw * (47 / 1920));
  }

  & li span {
    font-size: calc(100vw * (14 / 1920));
    color: #222;
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
  }
`;

export const FooterBotWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vw * (218 / 1920));

  @media ${(props) => props.theme.container} {
    flex-direction: column;
    height: auto;
    margin-top: calc(100vw * (10 / 428));

    & p {
      width: 100%;
      ${FontSize(13, mobil)}
      text-align: left;
      line-height: 1.6;
    }
    & p span {
      display: block;
      ${FontSize(13, mobil)}
      color: #333;
    }
  }
`;
export const FooterBot = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100vw * (1240 / 1920));
`;

export const FooterLeft = styled.div`
  width: calc(100vw * (880 / 1920));
  & span {
    margin-right: calc(100vw * (5 / 1920));
    font-size: calc(100vw * (14 / 1920));
  }

  & em {
    display: block;
    padding-top: calc(100vw * (20 / 1920));
    font-size: calc(100vw * (14 / 1920));
  }
  @media ${(props) => props.theme.container} {
  }
`;
export const FooterRight = styled.div`
  & > ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100vw * (194 / 1920));
    padding-left: calc(100vw * (38 / 1920));
    gap: ${Px(20, 1920)};
  }
  & li:first-child img {
    width: calc(100vw * (12 / 1920));
  }
  & li:last-child img {
    width: calc(100vw * (24 / 1920));
  }
`;
export const FtText = styled.p`
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  & p {
    width: auto !important;
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: calc(100vw * (2 / 428)) 0;
    color: #fff;
  }
  & p span {
    color: #aaa;
  }
  & p:first-child {
    width: 100% !important;
    cursor: pointer;
  }
  & p:first-child span {
    color: #fff;
  }
  & img {
    display: block;
    width: calc(100vw * (13 / 428));
    margin-left: calc(100vw * (10 / 428));
    cursor: pointer;
  }
`;
export const FtMenuText = styled.p`
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: calc(100vw * (20 / 428));

  & p {
    cursor: pointer;
    width: auto !important;
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: calc(100vw * (4 / 428)) 0;
    color: #fff;
  }
  & p::after {
    content: "|";
    display: block;
    font-size: calc(100vw * (13 / 428));
    color: #999;
  }
  & p:last-child::after,
  p:nth-child(4)::after {
    content: "";
  }
  & p span {
    color: #fff;
    margin: 0 calc(100vw * (8 / 428));
  }
  & p span a {
    color: #fff;
  }
  & img {
    display: block;
    width: calc(100vw * (13 / 428));
    margin-left: calc(100vw * (10 / 428));
    cursor: pointer;
  }
`;
export const FooterStoreButton = styled.button`
  width: calc(100vw * (15 / 428));
  height: calc(100vw * (15 / 428));
`;
