import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import serverController from "../../../server/serverController";
//css
import styled from "styled-components";

//img
import OrderImg1 from "../../../img/brand_logo1.png";
import OrderImg2 from "../../../img/brand_logo3.png";
import OrderImg3 from "../../../img/brand_logo4.png";

//page
import Commas from "../../../server/Commas";

export default function CancleInfo() {
  const history = useHistory();
  const { state } = useLocation();
  const [data, setData] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [prodPrices, setProdPrices] = useState(0);
  const [usedPoint, setusedPoint] = useState(0);
  const [list, setList] = useState([]);

  const cancleClick = async () => {
    for (let i = 0; i < list.length; i++) {
      serverController.connectFetchController(
        `payment/request-cancel?mog_id=${list[i]?.mog_id}`,
        "POST",
        null,
        (res) => {
          console.log(res, "취소 res");
          if (res?.result === 1) {
            alert("주문 취소 완료되셨습니다.");
            history.push("/store/mypage/return/complete");
          }
        }
      );
    }
  };

  useEffect(() => {
    let totalPrice = 0;
    let productPrice = 0;
    let shipping = 0;
    let couponPrice = 0;
    let usePointPrice = 0;
    let cancelPrice = 0;
    setData(list[0]);

    list.forEach((value) => {
      totalPrice += value.product_sum_price ?? 0;
      // productPrice += (value?.)
    });
    setTotalPrice(totalPrice);
    setProdPrices(totalPrice);
  }, [list]);
  useEffect(() => {
    setList(state?.orders ?? []);
  }, [state]);

  return (
    <Container>
      <OrderInfoWrap>
        <OrderInfoInner>
          <InfoTitleWrap>
            <InfoTitle>취소 금액 확인</InfoTitle>
          </InfoTitleWrap>
          <InfoListWrap>
            <InfoList>
              <InfoListTitle>상품금액</InfoListTitle>
              <InfoListText>
                {Commas.numberWithCommas(data?.prd_list_price ?? 0)}원
              </InfoListText>
            </InfoList>
            <InfoList>
              <InfoListTitle>배송비</InfoListTitle>
              <InfoListText>
                {Commas.numberWithCommas(data?.producttxn_shipping ?? 0)}원
              </InfoListText>
            </InfoList>
            {/* <InfoList>
                            <InfoListTitle>추가배송비</InfoListTitle>
                            <InfoListText>{Commas.numberWithCommas(data?.product_sum_price ?? 0)}원</InfoListText>
                        </InfoList> */}
            <InfoList>
              <InfoListTitle>쿠폰 사용금액</InfoListTitle>
              <InfoListText>
                {Commas.numberWithCommas(data?.coupon_discount ?? 0)}원
              </InfoListText>
            </InfoList>
            <InfoList>
              <InfoListTitle>사용포인트</InfoListTitle>
              <InfoListText>
                {Commas.numberWithCommas(data?.txn_used_point ?? 0)}원
              </InfoListText>
            </InfoList>
            <InfoList>
              <InfoListTitle>취소 예상금액</InfoListTitle>
              <InfoListText>
                {Commas.numberWithCommas(data?.mog_dis_price ?? 0)}원
              </InfoListText>
            </InfoList>
            <InfoList>
              <InfoListTitle>결제수단</InfoListTitle>
              <InfoListText>
                {list && list[0] && list[0].mog_payment_method}
              </InfoListText>
            </InfoList>
          </InfoListWrap>
        </OrderInfoInner>
      </OrderInfoWrap>
      <BtnWrap>
        <Btn
          onClick={() => {
            cancleClick();
          }}
        >
          완료
        </Btn>
      </BtnWrap>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  margin-bottom: 200px;
  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (200 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (100 / 428));
  }
`;
const OrderInfoWrap = styled.div`
  display: flex;
  width: 1300px;
  margin: 0 auto;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
  }
`;
const OrderInfoInner = styled.div`
  width: 100%;
`;
const InfoTitleWrap = styled.div`
  width: 100%;
  padding-bottom: 25px;
  border-bottom: 1px solid #000;
  &:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (25 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (25 / 428));
  }
`;
const InfoTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
  }
`;
const InfoListWrap = styled.div`
  width: 100%;
  padding-top: 14px;
  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (14 / 428));
  }
`;
const InfoList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 0;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (12 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (12 / 428)) 0;
  }
`;
const InfoListTitle = styled.p`
  font-size: 15px;
  color: #969696;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const InfoListText = styled.p`
  font-size: 15px;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (50 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (40 / 428));
  }
`;
const Btn = styled.p`
  width: 450px;
  font-size: 18px;
  border-radius: 5px;
  padding: 15px 0;
  text-align: center;
  background: #000;
  color: #fff;
  cursor: pointer;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (450 / 1320));
    font-size: calc(100vw * (18 / 1320));
    border-radius: calc(100vw * (5 / 1320));
    padding: calc(100vw * (15 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    font-size: calc(100vw * (16 / 428));
    border-radius: calc(100vw * (5 / 428));
    padding: calc(100vw * (15 / 428)) 0;
    margin: 0 auto;
  }
`;
