import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import SubTitleSection from "../../component/common/SubTitleSection";
import NewProduct from "../../component/new/NewProduct";
import Footer from "../../component/common/StoreFooter";
import FixMenu from "../../component/common/FixMenu";

export default function NewPage() {
  return (
    <>
      <PC>
        <Header />
        <SubTitleSection
          title="NEW"
          subText="WALA 스토어에 입점한 새로운 제품들을 확인해 보세요."
        />
        <NewProduct />
        <Footer />
        <FixMenu />
      </PC>

      <Mobile>
        <Header />
        <SubTitleSection
          title="NEW"
          subText="WALA 스토어에 입점한 새로운 제품들을 확인해 보세요."
        />
        <NewProduct />
        <Footer />
      </Mobile>
    </>
  );
}
