import styled, { css } from "styled-components";
import {
  pc,
  mobil,
  mquery,
  MQ,
  Media,
  Px,
  Width,
  Height,
  Top,
  Bottom,
  Left,
  Right,
  FontSize,
  Margin,
  Margin2,
  Padding,
  Padding2,
  MT,
  MB,
  ML,
  MR,
  PT,
  PB,
  PL,
  PR,
  LineH,
} from "../mediaStlye";

export const Container = styled.div`
  width: 100%;
  padding: calc(100vw * (20 / 1920)) calc(100vw * (310 / 1920));
  position: fixed;
  background: #fff;
  left: 0;
  top: 0;
  z-index: 2;
  @media ${(props) => props.theme.container} {
    padding: 0;
    ${({ main, isScroll }) =>
    !main && isScroll
      ? css`
            ${Height(60, mobil)}
            position: fixed;
            background: #fff;
            left: 0;
            top: 0;
            z-index: 101;
            padding: 0;
          `
      : ``}
  }

  ${MQ(mquery)} {
    padding-bottom: 0;
  }
`;
export const HeaderWrapper = styled.header`
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${(props) => props.theme.container} {
    box-sizing: border-box;
  }
`;
/* 헤더 탑 */
export const HeaderContainer = styled.div`
  width: 100%;
  background-color: ${({ modal }) => (modal ? "none" : "#fff")};
`;
export const IconSearch = styled.img`
  display: block;
  width: calc(100vw * (25 / 1920));
  margin-left: calc(100vw * (15 / 1920));
  cursor: pointer;
  @media ${(props) => props.theme.container} {
    position: relative;
    width: calc(100vw * (20 / 375));
    padding: calc(100vw * (15 / 428)) calc(100vw * (25 / 428));
    margin-left: 0;
  }
`;
export const HeaderContents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: calc(100vw * (1167 / 1920));
  height: calc(100vw * (64 / 1920));

  @media ${(props) => props.theme.container} {
    width: 100%;
    justify-content: center;
  }
`;

export const HeaderTopLeft = styled.div`
  display: flex;
  align-items: center;

  & > div:nth-child(1) {
    margin-right: calc(100vw * (41 / 1920));
    cursor: pointer;
  }

  @media ${(props) => props.theme.container} {
    position: relative;

    & > div {
      position: absolute;
      left: calc(100vw * (25 / 428));
      top: calc(100vw * (25 / 428));
      width: calc(100vw * (15 / 428));
    }
    & img {
      width: 100%;
    }
  }
`;

export const Logo = styled.h1`
  width : 124px;
  position: relative;
  cursor: pointer;
  font-weight: 900;
  text-align: center;
  justify-content: center;
  ${FontSize(40, pc)}

  & > img {
    width: 100%;
  }

  @media ${(props) => props.theme.container} {
    font-size: ${({ isScroll }) =>
    isScroll ? `calc(100vw * (30 / 428))` : `calc(100vw * (30 / 428))`};
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (124 / 1320));
  }

  @media screen and (max-width: 1024px) {
    width: calc(100vw * (110 / 490));
  }

`;

export const HeaderTopRight = styled.div`
  display: flex;
  padding-right: 9px;

  & > div:nth-child(2n):after {
    /* content: ""; */
    position: absolute;
    right: -30px;
    top: 50%;
    width: 1px;
    height: 13px;
    background-color: #d8d8d8;
  }
`;

export const MenuImg = styled.div`
  & > img {
    width: calc(100vw * (30 / 1920));
  }
  @media ${(props) => props.theme.container} {
    padding: calc(100vw * (15 / 428)) calc(100vw * (25 / 428));
    & > img {
      width: calc(100vw * (25 / 428));
    }
  }
`;

export const PageBtn = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: calc(100vw * (20 / 1920));
  padding-left: 0;
  font-size: calc(100vw * (15 / 1920));
  color: #1f1f1f;
  cursor: pointer;

  & > img {
    margin-right: calc(100vw * (10 / 1920));
  }

  & > span {
    margin-top: 2px;
    font-weight: 500;
    text-transform: uppercase;
    ${({ modal }) =>
    modal &&
    css`
        color: #fff;
      `}
  }

  /* &:first-child{
        border-right : 1px #222 solid;
    } */

  &:first-child > span.is-logged-out {
    padding-right: calc(100vw * (22 / 1920));
  }
  &:last-child > span.is-logged-out {
    padding-left: calc(100vw * (22 / 1920));
  }
`;

/* 검색창 */
export const SearchContainer = styled.div`
  padding-top: calc(100vw * (20 / 1920));
  ${({ isScroll }) =>
    isScroll &&
    css`
      background-color: rgba(0, 0, 0, 0.5);
      padding-bottom: calc(100vw * (7 / 1920));
      padding-top: 0;
    `}

  ${({ modal }) =>
    modal &&
    css`
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `}
@media ${(props) => props.theme.container} {
    width: 100%;

    box-sizing: border-box;
  }
`;
export const SearchBox = styled.form`
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  width: calc(100vw * (800 / 1920));
  /* background-color: #ccc; */
  border-bottom: 3px solid #222;
  ${({ modal }) =>
    modal &&
    css`
      border-color: #fdf251;
    `}

  ${({ isScroll }) =>
    isScroll &&
    css`
      border-bottom: 1px solid #fff;
    `}

    @media ${(props) => props.theme.container} {
    padding: 0;
    width: 100%;
    border-bottom: none;
    ${Padding2(0, 25, mobil)}
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: calc(100vw * (16.5 / 1920)) calc(100vw * (36 / 1920));
  font-size: calc(100vw * (17 / 1920));
  text-align: center;
  color: #fff;
  background-color: transparent;

  ${({ isScroll }) =>
    !isScroll &&
    css`
      color: #222222;
      &::placeholder {
        color: #222222;
      }
    `}

  @media ${(props) => props.theme.container} {
    padding: 0;
    line-height: calc(100vw * (17 / 428));
    border-bottom: calc(100vw * (3 / 428)) solid #222;
    margin: 0 auto;
    width: calc(100vw * (378 / 428));
    height: calc(100vw * (50 / 428));
    ${[
    FontSize(13, mobil),
    // Padding(18,0,0,0, mobil)
    PB(13, mobil),
  ].join("")}
  }
`;

export const SearchInputTop = styled.div`
  width: 100%;
  padding: calc(100vw * (15 / 1920)) calc(100vw * (36 / 1920));
  font-size: calc(100vw * (17 / 1920));
  text-align: center;
  background-color: transparent;
  &::after {
    content: "[펜트하우스 시즌3 5화] 오윤희 착장 / 볼드 이어링 / 브이넥 자켓";
    position: "absolute";
    transform: translateY(2px);
    font-size: calc(100vw * (17 / 1920));
  }

  ${({ isScroll }) =>
    isScroll &&
    css`
      color: #fff;
      &::placeholder {
        color: #fff;
      }
    `}

  @media ${(props) => props.theme.container} {
    height: calc(100vw * (34 / 428));
    padding: 0;
    line-height: calc(100vw * (17 / 428));
    border-bottom: calc(100vw * (3 / 428)) solid #222;
    margin: 0 auto;
    width: calc(100vw * (378 / 428));
    ${[
    FontSize(13, mobil),
    // Padding(18,0,0,0, mobil)
  ].join("")}

    &::after {
      content: "[펜트하우스 시즌3 5화] 오윤희 착장";
      transform: translateY(2px);
      font-size: calc(100vw * (13 / 428));
      line-height: calc(100vw * (17 / 428));
      position: absolute;
      width: 100%;
      right: 0;
      top: calc(100vw * (4 / 428));
    }

    ${({ isScroll }) =>
    isScroll &&
    css`
        display: none;
      `}
    transition: all 0.3s ease;
  }
`;

export const SearchButton = styled.button`
  position: absolute;
  right: calc(100vw * (24 / 1920));
  top: 50%;
  transform: translateY(-40%);
  background-color: transparent;

  @media ${(props) => props.theme.container} {
    position: absolute;
    top: 0;
    transform: none;
    margin-right: calc(100vw * (26 / 428));

    & > img {
      ${Width(23, mobil)}
    }

    ${({ modal }) =>
    modal &&
    css`
        position: absolute;
        right: 0;
        top: 50%;
      `}

    ${({ isScroll }) =>
    isScroll &&
    css`
        bottom: auto;
        display: none;
        /* top: ${Px(-20, mobil)}; */
      `}
        transition: all 0.3s ease;
  }
`;

/* 헤더 모달 */
export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.87);
  ${(props) =>
    props.search &&
    css`
      background: none;
    `}

  & .background-search-modal {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.87);
    @media ${(props) => props.theme.container} {
      background-color: transparent;
    }
  }

  @media ${(props) => props.theme.container} {
    width: 100vw;
    height: 100vh;
  }
`;

export const RecommendContainer = styled.div`
  display: flex;
  color: #fff;

  @media ${(props) => props.theme.container} {
    ${Padding2(0, 52, mobil)}
  }
`;

export const KeywordsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > h3 {
    font-weight: 700;
    margin: calc(100vw * (17 / 1920)) 0 calc(100vw * (8 / 1920));
    font-size: calc(100vw * (17 / 1920));
    color: #fff;
  }

  & > span {
    display: block;

    color: #fff;
    font-size: calc(100vw * (15 / 1920));
    margin-bottom: calc(100vw * (7 / 1920));
  }

  @media ${(props) => props.theme.container} {
    & > h3 {
      ${FontSize(16, mobil)}
      ${MB(8, mobil)}
    }
    & > span {
      ${FontSize(15, mobil)}
      ${MB(7, mobil)}
        text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const CategoryModal = styled(ModalContainer)`
  right: calc(100vw * (733 / 1920));
  padding-left: calc(100vw * (372 / 1920));

  @media ${(props) => props.theme.container} {
    right: 0;
    padding: 0;
  }
`;

export const CategoryModalHead = styled.div`
  padding-top: calc(100vw * (21 / 1920));
  @media ${(props) => props.theme.container} {
    right: 0;
    padding: 0;
    ${[PL(25, mobil), PT(21, mobil), MB(73, mobil)].join("")}
  }
`;

export const OpenMenuBar = styled.div`
  width: calc(100vw * (21 / 1920));
  line-height: 0;
  cursor: pointer;
  & > img {
    width: inherit;
  }
  @media ${(props) => props.theme.container} {
    ${Width(15, mobil)}
  }
`;

export const CategoryModalList = styled.ul`
  padding-top: calc(100vh * (63 / 1080));
  @media ${(props) => props.theme.container} {
    ${[Padding2(0, 26, mobil)].join("")}
    border-bottom: 1px solid #fff;
  }
`;

export const CategoryModalListItem = styled.li`
  position: relative;
  padding-bottom: calc(100vh * (115 / 1080));
  text-transform: uppercase;
  color: #fff;
  font-size: calc(100vw * (50 / 1920));
  font-weight: 900;
  cursor: pointer;

  & > p {
    position: absolute;
    left: calc(100vw * (20 / 1920));
    top: calc(100vw * (50 / 1920));
    opacity: 0;
    font-size: calc(100vw * (25 / 1920));
    font-weight: 400;
    color: #fff;
    transition: opacity 0.5s ease-in;
  }

  &:hover p {
    opacity: 1;
  }
  @media ${(props) => props.theme.container} {
    ${[FontSize(25, mobil), PB(50, mobil)].join("")}

    & p {
      ${[FontSize(25, mobil), PB(50, mobil)].join("")}
    }
  }
`;

export const CategoryModalSub = styled.div``;

export const ModalSubItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  ${[Height(60, mobil), Padding2(0, 27, mobil)].join("")}
  border-bottom: 1px solid #fff;

  & div {
    color: #fff;
    cursor: pointer;
  }

  & div > img {
    ${[Width(19, mobil), MR(8, mobil)].join("")}
  }

  ${(props) =>
    props.store &&
    css`
      & div > img {
        width: auto;
      }
    `}
`;

export const MobileModalHeader = styled.div`
  display: flex;
  align-items: center;

  ${[Height(74, mobil)].join("")}

  & h1 {
    margin: 0;
    ${[Width(151, mobil)].join("")}
  }
  @media screen and (max-width: 1024px) {
    & h1 {
      margin: 0 auto;
    }
  }
`;

export const MobileSearchBox = styled.div`
  ${[MT(130, mobil), Padding(0, 25, 0, 25, mobil)].join("")}
  position: relative;

  & input {
    ${Padding(0, 50, 0, 50, mobil)}
    border-bottom: 3px solid #FDF251;
  }
  @media screen and (max-width: 1024px) {
    & input::placeholder {
      text-align: center;
    }
  }
`;

export const MobileSearchBtn = styled.div`
  position: absolute;
  ${Right(50, mobil)}
  ${Bottom(16, mobil)}  
    cursor: pointer;
  display: ${(props) => (props.scrollIcon ? "none" : "block")};

  ${({ isScroll }) =>
    isScroll &&
    css`
      display: ${(props) => (props.scrollIcon ? "block" : "none")};
      /* bottom :  ${(props) =>
        props.scrollIcon && `calc(100vw * (12 / 428))`}; */
      top: ${(props) => props.scrollIcon && `calc(100vw * (-13 / 428))`};
      right: ${(props) => props.scrollIcon && `calc(100vw * (30 / 428))`};
      & img {
        width: calc(100vw * (20 / 428));
      }
    `}
`;

export const MypageHead = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  & p {
    font-size: calc(100vw * (20 / 428));
    font-weight: 900;
  }
  @media screen and (max-width: 1024px) {
    padding: calc(100vw * (15 / 428));
  }
`;

export const PageButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  ${Left(25, mobil)}

  & > img {
    display: block;
    ${Width(10, mobil)}
  }
  @media screen and (max-width: 1024px) {
    top: calc(100vw * (4 / 428));
    transform: inherit;
    padding: calc(100vw * (15 / 428));
  }
`;
