import React from 'react';
import faker from 'faker';
import { Mobile, PC } from '../common/mediaStlye';
import { useHistory } from 'react-router-dom';

import {
    ExtraContainer,
    Layout,
    ExtraHeadings,
    ExtraList,
    ExtraItem,
    ExtraItemImg,
    ExtraItemText,
    MediaExtraMark,
    PagingBox,
    NextButton,
    PrevButton
} from './FashionNewsContents.element';
import NaverLogo from '../../images/naver-logo.png';

import dImg_1 from '../../img/main/gallery/dImg_1.png';
import dImg_2 from '../../img/main/gallery/dImg_2.png';
import dImg_3 from '../../img/main/gallery/dImg_3.png';


const MediaContentsExtra = () => {
    const history = useHistory()
    const arr = [
        {
            src: dImg_1,
            title: "검은태양 이보영 패션"
        },
        {
            src: dImg_1,
            title: "검은태양 이보영 패션"
        },
        {
            src: dImg_1,
            title: "검은태양 이보영 패션"
        },
    ]
    return (
        <ExtraContainer>
            <Layout>
                <ExtraHeadings>
                    <h3>기타 추천 블로그</h3>
                </ExtraHeadings>

                <ExtraList>
                    {
                        arr.map((item, i) => {
                            return (
                                <ExtraItem key={i} onClick={() => history.push(`/news/detail/1`)}>
                                    <ExtraItemImg><img src={item.src} /></ExtraItemImg>
                                    <ExtraItemText>
                                        <h4>{item.title}</h4>
                                        <PC>
                                            <p>{faker.commerce.productDescription()}</p>
                                        </PC>
                                        <MediaExtraMark>{faker.company.companyName()}</MediaExtraMark>
                                    </ExtraItemText>
                                </ExtraItem>
                            )
                        })
                    }
                </ExtraList>
                {/* <PagingBox>
                    <div>
                        <PrevButton />
                        <ul>
                            {
                                Array(5).fill().map((item, j) => item = j + 1).map((item, i) => {
                                    return <li>{item}</li>
                                })
                            }
                        </ul>
                        <NextButton />
                        
                    </div>
                </PagingBox> */}
            </Layout>
        </ExtraContainer>
    )
}

export default MediaContentsExtra
