import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { PC, Mobile } from "../../../MediaQuery";
import { Slide } from "react-full-page/lib";
import styled, { keyframes } from "styled-components";

import { Pagination } from "swiper";

import { imgURL } from "../../../server/appInfoController";

export default function MainPopup(props) {
  const { setIsMobilePopupClose, list } = props;

  const handleStorage = {
    setStorage: (name, exp) => {
      let date = new Date();
      date = date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);

      localStorage.setItem(name, date);
    },
    getStorage: (name) => {
      let now = new Date();
      now = now.setTime(now.getTime());
      return parseInt(localStorage.getItem(name)) > now;
    },
  };

  useEffect(() => {
    if (handleStorage.getStorage("main_pop_today")) return setIsMobilePopupClose(false);
  }, []);

  return (
    <>
      <Mobile>
        <Background
          onClick={() => {
            setIsMobilePopupClose(false);
            sessionStorage.setItem("main_pop_one", true);
          }}
        />
      </Mobile>
      <Container>
        <PopupTopSection>
          <Swiper
            modules={[Pagination]}
            pagination
            autoplay={{ delay: 4000 }}
            slidesPerView={1}
            centeredSlides={true}
            loop
          >
            {list.map((popUpImg, idx) => {
              return (
                <SwiperSlide key={`${popUpImg}-popUpImg-${idx}`}>
                  <Slide>
                    <Mobile>
                      <PopupSlideImg
                        onClick={() => {
                          if (!popUpImg.popupUrl) return;
                          window.location.href = popUpImg.popupUrl;
                        }}
                        src={`${imgURL}${popUpImg.popupImage}`}
                        alt={popUpImg?.popupTitle}
                      />
                    </Mobile>
                    <PC>
                      <PopupSlideImg
                        onClick={() => {
                          if (!popUpImg.popupUrl) return;
                          window.location.href = popUpImg.popupUrl;
                        }}
                        src={`${imgURL}${popUpImg.popupImagePc}`}
                        alt={popUpImg?.popupTitle}
                      />
                    </PC>
                  </Slide>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </PopupTopSection>
        <PopupBottomSection>
          <LeftButton
            onClick={() => {
              handleStorage.setStorage("main_pop_today", 1);
              setIsMobilePopupClose(false);
            }}
          >
            오늘 다시보지 않기
          </LeftButton>
          <RightButton
            onClick={() => {
              setIsMobilePopupClose(false);
              sessionStorage.setItem("main_pop_one", true);
            }}
          >
            닫기
          </RightButton>
        </PopupBottomSection>
      </Container>
    </>
  );
}
const transitionY = keyframes`
0%{
    transform : translateY(300px);
		opacity: 0.5;
}
	100% {
		opacity: 1;
	}
`;
const opacity = keyframes`
0%{
		opacity: 0;
}
	100% {
		opacity: 1;
	}
`;
const Container = styled.div`
  position: fixed;
  z-index: 999;
  bottom: 0;
  overflow: hidden;
  top: 85px;
  right: 130px;
  font-size: 13px;
  @media screen and (max-width: 1320px) {
    top: calc(100vw * (85 / 1320));
    right: calc(100vw * (130 / 1320));
    font-size: calc(100vw * (13 / 1320));
  }
  @media screen and (max-width: 930px) {
    animation: ${transitionY} 0.3s ease-out;
    font-size: calc(100vw * (16 / 428));
    bottom: 0;
    right: 0;
    top: auto;
    border-top-left-radius: calc(100vw * (20 / 428));
    border-top-right-radius: calc(100vw * (20 / 428));
  }
`;
const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 40%;
  position: fixed;
  z-index: 900;
  animation: ${opacity} 0.3s ease-out;
`;
const PopupTopSection = styled.div`
  width: 400px;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (400 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (428 / 428));
  }
`;
const PopupBottomSection = styled.div`
  background-color: #e0e0e0;
  display: flex;
  justify-content: space-between;
`;
const LeftButton = styled.button`
  padding: 10px 30px;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (10 / 1320)) calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (10 / 428)) calc(100vw * (30 / 428));
  }
`;
const RightButton = styled.button`
  font-weight: 700;
  padding: 10px 30px;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (10 / 1320)) calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (10 / 428)) calc(100vw * (30 / 428));
  }
`;
const PopupSlideImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
