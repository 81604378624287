//react
import React, { useState, useEffect } from "react";

//css
import styled from "styled-components";

//img
import IconPrevBtn from "../../../img/icon/icon_pagination_prev_bk.png";
import IconNextBtn from "../../../img/icon/icon_pagination_next.png";

export default function Pagination({ pageData, page, setPage }) {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    if (pageData?.startPage && pageData?.endPage) {
      if (pageData.endPage === 1) {
        setPages([1]);
      } else {
        setPages(
          Array(pageData?.endPage ?? 1)
            .fill()
            .map((v, i) => i + 1)
        );
      }
    }
  }, [pageData]);

  return (
    <Container>
      <PaginationWrap className="mg-top">
        <PaginationPrevWrap
          onClick={() => {
            setPage(page == pageData?.startPage ? null : page - 1);
          }}
        >
          <PaginationPrev src={IconPrevBtn} alt="이전페이지로 이동" active={pageData?.prev} />
        </PaginationPrevWrap>
        <PaginationNubWrap>
          {pages.map((num, i) => {
            return (
              <PaginationNub
                key={`${num}-pagination-${i}`}
                on={num === pageData?.page}
                onClick={() => {
                  console.log(num, "test num");
                  setPage(num);
                }}
              >
                {num}
              </PaginationNub>
            );
          })}
        </PaginationNubWrap>
        <PaginationNextWrap
          onClick={() => {
            setPage(page == pageData?.endPage ? null : page + 1);
          }}
        >
          <PaginationNext src={IconNextBtn} alt="다음페이지로 이동" active={pageData?.next} />
        </PaginationNextWrap>
      </PaginationWrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const PaginationWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (80 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (30 / 428));
  }
`;
const PaginationPrevWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border: 1px solid #000;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (35 / 1320));
    height: calc(100vw * (35 / 1320));
    border: calc(100vw * (1 / 1320)) solid #000;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (35 / 428));
    height: calc(100vw * (35 / 428));
    border: calc(100vw * (1 / 428)) solid #000;
  }
`;
const PaginationPrev = styled.img`
  width: 12px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (12 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (12 / 428));
  }
`;
const PaginationNubWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;

  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (40 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0 calc(100vw * (30 / 428));
  }
`;
const PaginationNub = styled.p`
  font-size: 16px;
  margin: 0 16px;
  cursor: pointer;
  font-weight: 100;
  ${({ on }) => {
    return on ? `color: #000; font-weight: bold;` : `color: #646464;`;
  }}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin: 0 calc(100vw * (16 / 1320));
    ${({ on }) => {
      return on ? `color: #000; font-weight: bold;` : `color: #646464;`;
    }}
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin: 0 calc(100vw * (14 / 428));
    ${({ on }) => {
      return on ? `color: #000; font-weight: bold;` : `color: #646464;`;
    }}
  }
`;
const PaginationNextWrap = styled(PaginationPrevWrap)`
  background: #000;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    border: calc(100vw * (1 / 1320)) solid #000;
  }

  @media screen and (max-width: 930px) {
    border: calc(100vw * (1 / 428)) solid #000;
  }
`;
const PaginationNext = styled(PaginationPrev)`
  width: 12px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (12 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (12 / 428));
  }
`;
