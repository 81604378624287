import styled, { css } from "styled-components";
import {
    pc,
    mobil,
    Media,
    Px,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
    mquery,
} from '../common/mediaStlye';

export const MoreButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: calc(100vw * (162 / 1920));
    height: calc(100vw * (44 / 1920));
    font-size: calc(100vw * (16 / 1920));
    font-weight: 900;
    border: 2px solid #222;
    border-radius: calc(100vw * (37 / 1920));  

    @media screen and (max-width: ${mquery}px){        
        ${[
        Width(163, mobil),
        Height(44, mobil),
        FontSize(16, mobil),
    ].join("")}
        line-height: 0;
        border-radius: ${Px(37, mobil)};
    }
`

export const ResultContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 0 calc(100vw * (120 / 1920));
    @media screen and (max-width: ${mquery}px) {
        padding-top : calc(100vw * (88 / 428));
    }
`

export const ResultLayout = styled.div`
    width: calc(100vw * (1176 / 1920));
    padding-bottom: calc(100vw * (180 / 1920));

    @media screen and (max-width: ${mquery}px) {
        width: 100%;
        padding: 0 ${Px(25, mobil)} ${Px(50, mobil)};
    }
`
export const ResultHeadings = styled.div`
    border-bottom: 2px solid #707070;

    & > h2 {          
        padding: calc(100vw * (16 / 1920)) 0 calc(100vw * (16 / 1920)) calc(100vw * (42 / 1920));    
        font-size: calc(100vw * (22 / 1920));    
        color: #222;
        font-weight: 700;
    }

    @media screen and (max-width: ${mquery}px) {
        & h2 {
            ${[
        FontSize(18, mobil),
        Padding(0, 0, 13, 0, mobil)
    ].join("")}
        }
    }
`
export const WalaItemList = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: ${Px(12, pc)};
    flex-wrap: wrap; 
    padding: calc(100vw * (40 / 1920)) calc(100vw * (26 / 1920));     
    margin-bottom: calc(100vw * (100 / 1920));

    @media screen and (max-width: ${mquery}px) {
        ${[
        Padding(19, 0, 0, 0, mobil),
        MB(68, mobil)
    ].join("")}    
        gap: ${Px(10, mobil)};
    }
`


export const Item = styled.div`    
    overflow: hidden;
    width: calc(100vw * (270 / 1920));
    height: calc(100vw * (377 / 1920));
    margin-bottom: calc(100vw * (20 / 1920));
    position: relative;
    cursor: pointer;

    :hover .item_head {
        opacity: 1;
    }

    @media ${props => props.theme.container} {
       ${[
        Width(180, mobil),
        Height(257, mobil),
        MB(11, mobil)
    ]}
       max-width: ${Px(180, mobil)};       
    }
`

export const ItemImg = styled.div`
    height: 100%;
    line-height: 0;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

export const ItemDesc = styled.div`
    position: absolute;
    left: 0;
    bottom:0;
    width: 100%;
    height: calc(100vw * (120 / 1920));
    padding: calc(100vw * (26/ 1920)) calc(100vw * (15 / 1920)) 0 calc(100vw * (14 / 1920));
    background-color: rgba(34,34,34,0.9);
    opacity: 0;
    transition: all 0.3s ease;

    & > em {
        display: block;
        font-size: calc(100vw * (13 / 1920));
        color: #FFF200;
        font-weight: 700;
        padding-bottom: calc(100vw * (5 / 1920));
    }

    & > h4 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: calc(100vw * (18 / 1920));
        padding-bottom: calc(100vw * (9 / 1920));
        color: #fff;
        font-weight: 900;
    }

    & > p {
        font-size: calc(100vw * (15 / 1920));
        padding-right: calc(100vw * (30 / 1920));
        color: #fff;
        overflow: hidden;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    @media ${props => props.theme.container} {
        ${[
        Height(103.92, mobil),
        Padding(12, 58, 0, 9.5, mobil)
    ].join("")}
       
        & > em {
            ${[
        FontSize(13, mobil),
        PB(5, mobil)
    ].join("")}
        }

        & > h4 {
            ${[
        FontSize(14, mobil),
        PB(5.7, mobil),

    ].join("")}
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal; 
            line-height: 1.2;
            height: 3.6em;
        }
    }
    
`

// 블로그 컨텐츠 
export const BlogItemList = styled(WalaItemList)`
    flex-direction: column;
    padding: calc(100vw * (40 / 1920)) calc(100vw * (26 / 1920)) 0; 

    @media screen and (max-width: ${mquery}px) {
        ${Padding(18, 0, 0, 0, mobil)}   
    }
`

export const BlogItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: calc(100vw * (16 / 1920)) 0;
    border-bottom: 1px solid #D9D9D9;
    @media screen and (max-width: ${mquery}px) {
        width: 100%;
        ${Padding2(10, 0, mobil)}
    }
`

export const BlogItemImg = styled.div`
    width: calc(100vw * (269 / 1920));
    height:calc(100vw * (151 / 1920));

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media screen and (max-width: ${mquery}px) {
        ${[
        Width(175, mobil),
        Height(98, mobil)
    ].join("")}
    }
`

export const BlogItemText = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    flex-direction: column;

    position: relative;
    padding-left: calc(100vw * (70 / 1920));
    padding-right: calc(100vw * (4 / 1920));

    & > h4 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: calc(100vw * (18 / 1920));
        padding-bottom: calc(100vw * (14 / 1920));
        color: #222;
        font-weight: 900;
    }

    & > p {
        font-size: calc(100vw * (16 / 1920));
        padding-right: calc(100vw * (30 / 1920));
        color: #222;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal; 
        line-height: 1.2;
         height: 3.6em;
    }

    @media screen and (max-width: ${mquery}px) {
       ${Padding(0, 0, 0, 13.3, mobil)}

       & > h4 {
            ${FontSize(16, mobil)}           
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;        
            text-align: left;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-width: ${Px(300, mobil)};  
            font-weight: normal;         
       }
    }
`

export const BlogMark = styled.div`
    position: absolute;
    right: 0;
    top:calc(100vw * (-5 / 1920));
    width: calc(100vw * (56 / 1920));

    & > img {
        width: 100%;        
    }

    @media ${props => props.theme.container} {
        ${Width(45, mobil)}
    }
`

// 패션 뉴스, ResultsFashionNews
export const NewsItemList = styled(WalaItemList)`
    padding: calc(100vw * (40 / 1920)) calc(100vw * (26 / 1920)) 0; 
    gap: ${Px(12, pc)};

    @media screen and (max-width: ${mquery}px) {
        padding: 0;
        justify-content: space-between;

        ${[
        MT(20, mobil)
    ].join("")}
    }
`
export const NewsItem = styled(Item)`
    border-top: 2px solid #222;
    padding-top: calc(100vw * (8 / 1920));
    margin-bottom: calc(100vw * (54 / 1920));

    @media screen and (max-width: ${mquery}px) {
        
    }
`
export const NewsItemImg = styled(ItemImg)`
    height: calc(100vw * (161 / 1920));
    @media screen and (max-width: ${mquery}px) {
        ${Height(109, mobil)}
    }

`


export const NewsItemText = styled.div`
    position: relative;
    margin-bottom: calc(100vw * (10 / 1920));
     & > h4 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: calc(100vw * (18 / 1920));
        padding-right: calc(100vw * (60 / 1920));
        padding-bottom: calc(100vw * (10 / 1920));
        color: #222;
        font-weight: 900;
    }
    
    & > span {
        position: absolute;
        right: 0;
        top:calc(100vw * (2/ 1920));
        font-size: calc(100vw * (14 / 1920));
        color: #A5A5A5;
        font-weight: 400;
    }

    & > p {
        font-size: calc(100vw * (14 / 1920));
        padding-left: calc(100vw * (14 / 1920));
        color: #222;
        
        height: calc(100vw * (104 / 1920));
    }

    @media screen and (max-width: ${mquery}px) {
        ${PT(5, mobil)}
        ${MB(6.3, mobil)}
        

        & h4 {
            ${[
        FontSize(15, mobil),
        PB(4.4, mobil),
        PR(38, mobil)
    ].join("")}
        }

        & span {
            ${[
        FontSize(12, mobil),
        Top(5, mobil),
    ].join("")}
        }
        & p {
            ${[
        FontSize(13, mobil),
        PL(13, mobil),
    ].join("")}
            height: auto;
            max-height: ${Px(75, mobil)};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: wrap; 
            
        }
    }
`