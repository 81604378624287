import React, { useEffect, useState } from "react";
import axios from "axios";
import usePaginate from "hooks/usePaginate";
import Paginate from "components/test/Paginate"
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";

const LIMIT = 10;
const PAGE_LIMIT = 5;

export default function TestPagePage() {
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const [page, setPage] = useState(searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1);

  const [items, setItems] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const {
    pageList
  } = usePaginate(page, items.pageInfo, PAGE_LIMIT);

  // React.useEffect(() => {
  //   setLoading(true);

  //   (async () => {
  //     try {
  //       const response = await axios.get(`http://localhost:3000/mock-list?page=${page}&limit=${LIMIT}`);
  //       console.log("response: ", response.data);
  //       setItems(response.data);

  //     } catch (error) {
  //       setError(true);
  //       console.log(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   })();
  // }, [page]);

  const getAPI = axios.get(`http://localhost:3000/mock-list?page=${page}&limit=${LIMIT}`);

  // const requestData = {
  //   requestAPI: getAPI,
  //   requestData: { page: page, LIMIT: LIMIT },
  //   queryKey: [queryKeys.STORE_CATEGORY_BEST, page],
  //   options: {
  //     keepPreviousData: true,
  //   },
  // };

  return (
    <>
      <ListWrap>
        {error && <div>다시 시도해주세요.</div>}
        {loading && <div>로딩중</div>}
        {!loading && items?.items && (
          <ul>
            {items.items.map((el, i) => {
              return <li onClick={() => { history.push(`test`) }} key={el.id}>{el.title}</li>
            })}
          </ul>
        )}
      </ListWrap>
      <Paginate page={page} setPage={setPage} items={items} pageList={pageList} loading={loading} PAGE_LIMIT={PAGE_LIMIT} />
    </>
  )
}

const ListWrap = styled.div`
height: 250px;
`