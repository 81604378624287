import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Commas from "../../../server/Commas";
//img
// import ReviewImg1 from "../../../img/product_detail_img1.png"
// import ReviewImg2 from "../../../img/product_detail_img2.png"
// import ReviewImg3 from "../../../img/product_detail_img3.png"
// import NoReviewImg from "../../../img/noimg/review_no_img.png"
import ReviewImg1 from "../../../../images/content/content-detail-prod1.png";
import ReviewImg2 from "../../../../images/content/content-detail-prod2.png";
import ReviewImg3 from "../../../../images/content/content-detail-prod3.png";
import NoReviewImg from "../../../../images/category/category-prod-1.png";

//page
import Pagination from "../../common/pagination/Pagination";

export default function DetailGuide() {
  const { brand } = useSelector((state) => state.prodDetailData);

  return (
    <Container>
      <DetailGuideWrap>
        <TitleWrap>
          <TitleText>배송정보, 반품/교환/환불안내</TitleText>
        </TitleWrap>
        {/* <GuideTableWrap>
          <GuideTable>
            <GuideTableTitle>반품배송비</GuideTableTitle>
            <GuideTableText>
              {Commas.numberWithCommas(brand?.br_refund_charge ?? 0)}원
              (단순변심으로 인한 반품 왕복 택배비가 차감)
            </GuideTableText>
          </GuideTable>
          <GuideTable>
            <GuideTableTitle>보내실 곳</GuideTableTitle>
            <GuideTableText>{brand?.br_address}</GuideTableText>
            <GuideTableText>
              서울특별시 강남구 언주로165길 7-3, 2층 (신사동 세경빌딩)
            </GuideTableText>
          </GuideTable>
        </GuideTableWrap> */}
        <GuideWrap>
          <GuideBox>
            <GuideTitleWrap>
              <GuideTitle>배송정보</GuideTitle>
              <GuideSubTitle>빠르게 보내드리고 싶은 왈라의 마음!</GuideSubTitle>
            </GuideTitleWrap>
            <GuideTextWrap>
              <GuideText>
                하지만 브랜드마다 출고일이 다 다르기 때문에 정확하게 답변을
                드리기가 어렵습니다🥴
                <br />
                평균적으로 주문 확인 후 평일 기준 3~7일 정도 이내 순차적으로
                출고를 도와드리고 있습니다!!
                <br />
                (주문량/재고상황에 따라서 브랜드사에서 준비하는 출고일이 지연 될
                수 있습니다.)
                <br />
                또한! 상품 중 주문제작 상품은 제작 기간일이 소요되어 최소
                7일-14일 소요될 수 있으므로,
                <br />
                주문시 꼭! 상세페이지에서 주문제작 상품인지 확인 부탁드립니다😊
                <br />
                배송이 지연되는 경우 왈라에서는 고객님들께 배송지연 알림을
                보내드리고,
                <br />
                브랜드사로 출고가 언제되는지 출고 예정일을 지속적으로 문의하고
                있습니다.
                <br />
                출고 예정일이 궁금하실 때에는 언제든지 070-8667-7016 고객센터로
                연락주세요.
              </GuideText>
            </GuideTextWrap>
          </GuideBox>
          <GuideBox>
            <GuideTitleWrap>
              <GuideTitle>반품/교환/환불안내</GuideTitle>
            </GuideTitleWrap>
            <GuideTextWrap>
              <GuideText>
                - 상품 수령 후 7일 이내 접수 된 교환/반품 유효합니다.
                <br />
                - 수령 후 세탁, 향수 사용 등 상품의 가치가 하락한 경우에는
                교환이나 반품이 불가합니다.
                <br />
                - 받으신 택배사가 아닌 다른 택배사를 이용하여 반품하실 경우 추가
                비용이 발생 할 수 있습니다.
                <br />
                - 구매 하실 때 할인 받으셨던 쿠폰 할인액은 반환 됩니다.
                <br />
                - 제품의 택(tag)이 분실/훼손 된 경우 반품이 불가능 합니다.
                <br />
                - 사은품을 사용하셨거나 분실한 경우 반품이 불가능 합니다.
                <br />
                - 제품 박스를 분실하거나 훼손한 경우 반품이 거절 되거나 비용이
                청구 될 수 있습니다.
                <br />
                - 교환의 경우, 브랜드사의 기준에 따라 선수거 후 교환 출고가
                진행될 수 있습니다.
                <br />
                - 교환 택배비는 고객센터로 문의 주시면 상담원이 확인하여 안내
                드리겠습니다.
                <br />- 교환 상품이 품절 될 경우 취소 안내를 받을 수 있습니다.
              </GuideText>
              <GuideText>
                ※ 무료 배송으로 받으신 상품을 변심 등의 사유로 반품하시게 되면
                왕복 택배비가 차감됩니다.
              </GuideText>
            </GuideTextWrap>
          </GuideBox>
        </GuideWrap>
      </DetailGuideWrap>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  padding: 40px 0 120px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (40 / 1320)) 0 calc(100vw * (120 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (200 / 428));
  }
`;
const DetailGuideWrap = styled.div`
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const TitleWrap = styled.div``;
const TitleText = styled.h2`
  line-height: 1.5;
  font-size: 18px;
  color: #333333;
  font-weight: 500;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
//table
const GuideTableWrap = styled.div`
  width: 100%;
`;
const GuideTable = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  border-bottom: 1px solid #d9d9d9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (12 / 1320)) 0;
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (12 / 428)) 0;
    border-bottom: calc(100vw * (1 / 428)) solid #d9d9d9;
  }
`;
const GuideTableTitle = styled.p`
  width: 140px;
  font-size: 14px;
  color: #969696;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (75 / 428));
    font-size: calc(100vw * (13 / 428));
  }
`;
const GuideTableText = styled.p`
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (75 / 428)));
    text-align: right;
    font-size: calc(100vw * (12 / 428));
  }
`;
//Guide text
const GuideWrap = styled.div`
  margin-top: 20px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (15 / 428));
  }
`;
const GuideBox = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (20 / 428));
  }
`;
const GuideTitleWrap = styled.div`
  width: 100%;
  margin-bottom: 10px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const GuideTitle = styled.p`
  font-size: 16px;
  color: #333333;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    line-height: calc(100vw * (21 / 428));
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const GuideSubTitle = styled.p`
  line-height: 1.4;
  color: #969696;
  font-size: 14px;
  margin-bottom: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (6 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (12 / 428));
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const GuideTextWrap = styled.div`
  width: 100%;
`;
const GuideText = styled.p`
  line-height: 1.8;
  font-size: 16px;
  color: #555;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
