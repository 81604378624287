import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import LogoImg from "../img/splash_logo.png";

export default function SplashPage() {
  const history = useHistory();

  useEffect(() => {
    const goHome = setTimeout(() => {
      history.push(`/store`);
      clearTimeout(goHome);
    }, 2000);
  }, []);

  return (
    <SplashWrap>
      <LogoImgBox>
        <img src={LogoImg} alt="logo-img" />
      </LogoImgBox>
    </SplashWrap>
  );
}
const SplashWrap = styled.div`
  position: relative;
  z-index: 1000000;
  background-color: #000000;
  width: 100vw;
  height: 100vh;
`;
const LogoImgBox = styled.div`
  width: calc(100vw * (211 / 428));
  margin: auto;
  height: fit-content;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  > img {
    width: 100%;
  }
`;
