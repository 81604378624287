import { enableAllPlugins } from 'immer';
import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
enableAllPlugins();

const UPDATE_RECOMMENDER = 'recommenderData/UPDATE_RECOMMENDER';


export const updateRecommender = createAction(UPDATE_RECOMMENDER);

const initialState = {
  recommender_cate: false,
};


export default handleActions({
  [UPDATE_RECOMMENDER]: (state, action) => {
    return produce(state, draft => {
      const payload = action.payload;
      draft.recommender_cate = payload.recommender_cate ?? draft.recommender_cate;
    });
  },
}, initialState);