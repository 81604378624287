import { useEffect, useRef } from "react";
import { Mobile, PC } from "../../MediaQuery";

import MyPageHeader from "./MyPageHeader";
import MySetting from "../../component/mypage/MySetting";
import FooterMenu from "../../component/common/FooterMenu";

export default function MySettingPage() {
  const nowTabRef = useRef("myPage");
  return (
    <>
      <PC>
      </PC>

      <Mobile>
        <MyPageHeader title={"설정"} prevBtn />
        <MySetting/>
        <FooterMenu nowTabRef={nowTabRef.current} />
      </Mobile>
    </>
  );
}
