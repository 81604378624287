import React, { useState, useEffect, useRef } from "react";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";
import serverController from "../../../server/serverController";
import IconWishOn from "../../img/icon/icon_wish_on.svg";
import IconWishOff from "../../img/icon/icon_wish_off.svg";
import { imgURL } from "../../../server/appInfoContoller";
import DefaultImg from "../../img/icon/loading_default_img.svg";
import ContentsDetail from "./ContentsDetail";
import Loading from "../common/modal/Loading";
import MyPageDefaultModal from "../common/modal/MyPageDefaultModal";
import ContentsDetailMain from "./ContentsDetailMain";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios"
import qs from "qs";
import SkeletonUI from "../../../newComponents/common/SkeletonUI"
import { Get } from "api";
import { queryKeys } from "constants/queryKeys";

export default function ContentsList(props) {
  const {
    contentsDetail,
    setContentsDetail = null,
    // setLoading = null,
    search = false,
    inputSearchValue = "",
  } = props;

  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const history = useHistory();
  console.log('history: ', history);
  const location = useLocation();



  const wishiconRef = useRef([]);
  const wishiconRefMobile = useRef([]);
  wishiconRef.current = [];
  wishiconRefMobile.current = [];

  const { isLogged } = useSelector((state) => state.userData);
  const [loginPop, setLoginPop] = useState(false);

  const [contentsList, setContentsList] = useState([]);
  const [clickContentsId, setClickContentsId] = useState(0);

  const [contentsLoading, setContentsLoading] = useState(false);
  const [pcContentsDetail, setPcContentsDetail] = useState(false);

  let isLoad = false;

  const [isWish, setIsWish] = useState(false);
  const [touch, setTouch] = useState(false);




  const getContentsList = () => {
    // if search
    if (search) {
      serverController.connectFetchController(
        `contentlist?keyword=${inputSearchValue}&page=0`,
        "GET",
        null,
        (res) => {
          setContentsLoading(false);
          if (res?.result === 1) {
            setContentsList(res?.list ?? []);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      // if contents tab
      try {
        serverController.connectFetchController(
          `main/contentslist?offset=${contentsList.length}&limit=20`,
          "GET",
          null,
          (res) => {
            console.log(contentsList.length, "contentsList.length");
            console.log(res, "res");

            setContentsLoading(false);
            if (res?.result === 1) {
              setContentsList((prevContents) => {
                return prevContents.concat(res?.list);
              });
            }
            // if (setLoading !== null) {
            //   setLoading(false);
            // }
            isLoad = false;
          }
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  // const getContentsLikeList = () => {
  //   axios.get(`/contentslike/${contentsList.contentsId}`)
  //   .then((res)=> {
  //     console.log(res);
  //   })
  // };

  // useEffect(()=>{
  //   // getContentsLikeList();
  // },[contentsList])

  const onScrollHeight = (e) => {
    if (isLoad) return;
    const { scrollHeight, offsetHeight, scrollTop } = e.target;
    const digitBottom = 1000;

    const isBottom = scrollHeight <= offsetHeight + scrollTop + digitBottom;
    if (isBottom) {
      isLoad = true;
      getContentsList();
    }
  };

  useEffect(() => {
    if (query?.id) {
      setClickContentsId(query?.id);
      setPcContentsDetail(true);
      // 모바일일때만 모바일 setState 실행
      if (window.innerWidth <= 960 && /Mobi/.test(navigator.userAgent)) {
        setContentsDetail(true);
      }
      history.push(`/store/contents/list`);
    };
    setContentsLoading(true);
    getContentsList();
  }, []);


  useEffect(() => {
    if (search) {
      serverController.connectFetchController(
        `contentlist?keyword=${inputSearchValue}&page=0`,
        "GET",
        null,
        (res) => {
          setContentsLoading(false);
          if (res?.result === 1) {
            setContentsList(res?.list ?? []);
          }
        },
        (err) => {
          console.log(err);
        }
      )
    };
  }, [inputSearchValue]);

  // like 기능 구현 중

  const postLikeCount = (contentsId) => {
    serverController.connectFetchController(
      `contentslike/${contentsId}`,
      "POST",
      null,
      (res) => {
        // setIsWish(!isWish);
      },
      (err) => { console.log(err) }
    );
  };


  const onHandleLike = (contentsId) => {
    if (!isLogged) return setLoginPop(true);
    postLikeCount(contentsId);
    wishiconRef.current[contentsId].classList.toggle('active');
  }
  const onHandleLikeM = (contentsId) => {
    if (!isLogged) return setLoginPop(true);
    postLikeCount(contentsId);
    wishiconRefMobile.current[contentsId].classList.toggle('active');
  }

  // 모달에서 리스트페이지 하트 제어
  const modalToListWish = (contentId) => {
    // wishiconRef.current[contentId].classList.toggle('active');
    console.log(contentId);
  };

  return (
    <>
      {loginPop && (
        <MyPageDefaultModal
          text="로그인 후 이용 가능합니다."
          trueText="로그인"
          trueFunc={() => {
            if (location) return history.push(`/store/login?returnTo=/store`);
            history.push(`/store/login`);
          }}
          falseFunc={() => setLoginPop(false)}
        />
      )}
      <PC>
        {pcContentsDetail && (
          <ContentsDetailMain
            clickContentsId={clickContentsId}
            setContentsDetail={setPcContentsDetail}
            wishiconRef={wishiconRef}
          />
        )}
        <Container
          id="infinite-scroll-wrap"
          onScroll={onScrollHeight}
        // contentsDetail={pcContentsDetail}
        >
          {contentsLoading && <SkeletonUI.ResponsiveContentsList count={16} />}
          <ContentsWrapWarp>
            <ContentsWrap>
              {contentsList.length > 0 ? (
                contentsList.map((contents, idx) => {
                  return (
                    <ContentsBox
                      key={`${contents.contents_id}-${idx}`}
                      onClick={() => {
                        setClickContentsId(contents.contents_id);
                        setPcContentsDetail(true);
                        // history.push(
                        //   `/store/contents/detail?id=${contents.contents_id}`
                        // );
                      }}
                    >
                      <ContentsImgBox>
                        <WishIcon
                          className={contents.like_id !== 0 && 'active'}
                          ref={(el) => { wishiconRef.current[contents.contents_id] = el }}
                          onClick={(e) => {
                            e.stopPropagation();
                            onHandleLike(contents.contents_id);
                            // e.target.classList.toggle('active');
                          }}
                        />
                        <ContentsImg
                          src={
                            contents.contents_main ? `${imgURL}${contents.contents_main}` : DefaultImg
                          }
                          alt={contents.contents_main ? "contents-img" : "default-img"}
                        // onClick={() => {
                        //   setContentsDetail(true);
                        //   setClickContentsId(contents.contents_id);
                        // }}
                        />
                        <ContentsProductBox>
                          <ContentsProduct contentsId={contents.contents_id} />
                        </ContentsProductBox>
                      </ContentsImgBox>
                      <ContentsTextBox>
                        <ContentsTitle>{contents.contents_title}</ContentsTitle>
                        <ContentsDate>
                          {contents.contents_date.split(" ")[0].replaceAll("-", ".")}
                        </ContentsDate>
                        {/* <ContentsHashTagBox>
                        <ContentsHashTag># 원피스</ContentsHashTag>
                        <ContentsHashTag># 원피스</ContentsHashTag>
                        <ContentsHashTag># 원피스</ContentsHashTag>
                      </ContentsHashTagBox> */}
                      </ContentsTextBox>
                    </ContentsBox>
                  );
                })
              ) : (
                <SearchInfoText>검색된 컨텐츠가 없습니다.</SearchInfoText>
              )}
            </ContentsWrap>
          </ContentsWrapWarp>
        </Container>
      </PC>
      <Mobile>
        {contentsDetail && (
          <ContentsDetail contentsId={clickContentsId} setContentsDetail={setContentsDetail} wishiconRefMobile={wishiconRefMobile} />
        )}
        <Container
          id="infinite-scroll-wrap"
          onScroll={onScrollHeight}
          contentsDetail={contentsDetail}
        >
          {contentsLoading && <SkeletonUI.ResponsiveContentsList count={3} />}
          <ContentsWrapWarp>
            <ContentsWrap>
              {contentsList.length > 0 ? (
                contentsList.map((contents, idx) => {
                  return (
                    <ContentsBox
                      key={`${contents.contents_id}-${idx}`}
                      onClick={() => {
                        setContentsDetail(true);
                        setClickContentsId(contents.contents_id);
                      }}
                    >
                      <ContentsImgBox>
                        <WishIcon
                          className={contents.like_id !== 0 && 'active'}
                          ref={(el) => { wishiconRefMobile.current[contents.contents_id] = el }}
                          onClick={(e) => {
                            e.stopPropagation();
                            onHandleLikeM(contents.contents_id);
                            // e.target.classList.toggle('active');
                          }}
                        />
                        <ContentsImg
                          src={
                            contents.contents_main ? `${imgURL}${contents.contents_main}` : DefaultImg
                          }
                          alt={contents.contents_main ? "contents-img" : "default-img"}
                        />
                        <ContentsProductBox>
                          <ContentsProduct contentsId={contents.contents_id} />
                        </ContentsProductBox>
                      </ContentsImgBox>
                      <ContentsTextBox>
                        <ContentsTitle>{contents.contents_title}</ContentsTitle>
                        <ContentsDate>
                          {contents.contents_date.split(" ")[0].replaceAll("-", ".")}
                        </ContentsDate>
                        {/* <ContentsHashTagBox>
                        <ContentsHashTag># 원피스</ContentsHashTag>
                        <ContentsHashTag># 원피스</ContentsHashTag>
                        <ContentsHashTag># 원피스</ContentsHashTag>
                      </ContentsHashTagBox> */}
                      </ContentsTextBox>
                    </ContentsBox>
                  );
                })
              ) : (
                <SearchInfoText>검색된 컨텐츠가 없습니다.</SearchInfoText>
              )}
            </ContentsWrap>
          </ContentsWrapWarp>
        </Container>
      </Mobile>
    </>
  );
}
export function ContentsProduct({ contentsId }) {
  const [contentsSubPrd, setContentsSubPrd] = useState([]);
  useEffect(() => {
    serverController.connectFetchController(
      `contents/${contentsId ? contentsId : 0}`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setContentsSubPrd(res?.content.products);
        }
      }
    );
  }, []);
  return (
    <>
      {contentsSubPrd?.map((prd) => {
        return (
          prd.prd_thumbnail && (
            <ContentsProductImgBox key={prd.prd_id}>
              <ContentsProductImg src={imgURL + prd.prd_thumbnail} alt="contentsProductImg" />
            </ContentsProductImgBox>
          )
        );
      })}
    </>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: scroll;
  position: relative;
  padding-top: 180px;
  &::-webkit-scrollbar {
    display: none;
  }
  ${({ contentsDetail }) =>
    contentsDetail &&
    `
    display : none;
    `}

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (180 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (70 / 428));
  }
`;

const ContentsWrapWarp = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1320px) {
    max-width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    max-width: calc(100vw * (378 / 428));
  }
`;
const ContentsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (max-width: 930px) {
    display: block;
  }
`;
const ContentsBox = styled.div`
  width: 304px;
  margin-bottom: 40px;
  cursor: pointer;

  &:not(:nth-child(4n)) {
    margin-right: 8px;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (304 / 1320));
    margin-bottom: calc(100vw * (40 / 1320));

    &:not(:nth-child(4n)) {
      margin-right: calc(100vw * (8 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    margin-bottom: calc(100vw * (25 / 428));

    &:not(:nth-child(4n)) {
      margin-right: 0;
    }
  }
`;
const WishIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 24px;
  height: 24px;
  background-image: url(${IconWishOff});
  background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  /* transition: ease 0.3s all; */

  &.active{
    background-image: url(${IconWishOn});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media screen and (max-width: 1320px) {
    top: calc(100vw * (10 / 1320));
    right: calc(100vw * (10 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    top: calc(100vw * (20 / 428));
    right: calc(100vw * (20 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
    /* ${({ touch }) =>
    touch &&
    `
      scale : 0.85;
        
    `} */
  }
`;
const ContentsImgBox = styled.div`
  position: relative;
  width: 100%;
  height: 280px;
  overflow: hidden;
  border-radius: 20px;

  @media screen and (max-width: 1320px) {
    border-radius: calc(100vw * (20 / 1320));
    height: calc(100vw * (280 / 1320));
  }
  @media screen and (max-width: 930px) {
    border-radius: calc(100vw * (20 / 428));
    height: calc(100vw * (378 / 428));
  }
`;
const ContentsImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ContentsProductBox = styled.div`
  position: absolute;
  display: flex;
  left: 10px;
  bottom: 10px;

  @media screen and (max-width: 1320px) {
    left: calc(100vw * (10 / 1320));
    bottom: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    left: calc(100vw * (20 / 428));
    bottom: calc(100vw * (10 / 428));
  }
`;
const ContentsProductImgBox = styled.div`
  width: 50px;
  height: 50px;
  &:not(:last-child) {
    margin-right: 5px;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (50 / 1320));
    height: calc(100vw * (50 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (5 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    width: calc(100vw * (50 / 428));

    &:not(:last-child) {
      margin-right: calc(100vw * (5 / 428));
    }
  }
`;
const ContentsProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ContentsTextBox = styled.div`
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (10 / 428));
  }
`;
const ContentsTitle = styled.div`
  color: #111;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
    margin-bottom: calc(100vw * (6 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    margin-bottom: calc(100vw * (6 / 428));
  }
`;
const ContentsDate = styled.div`
  color: #999999;
  font-size: 13px;
  margin-bottom: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (13 / 1320));
    margin-bottom: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (13 / 428));
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const ContentsHashTagBox = styled.div`
  display: flex;
`;
const ContentsHashTag = styled.div`
  color: #333;
  font-size: 14px;
  padding: 7px 12px;
  border: 1px solid #ddd;

  &:not(:last-child) {
    margin-right: 6px;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    padding: calc(100vw * (7 / 1320)) calc(100vw * (12 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (6 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    padding: calc(100vw * (7 / 428)) calc(100vw * (12 / 428));

    &:not(:last-child) {
      margin-right: calc(100vw * (6 / 428));
    }
  }
`;
const SearchInfoText = styled.p`
  width: 100%;
  color: #999;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  padding: 200px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (200 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (210 / 428)) 0 0;
  }
`;
