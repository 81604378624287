import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";

//css
import styled from "styled-components";

// img
import IconCheckbox from "../../../img/icon/icon_checkbox.png";

// page
import Commas from "../../../server/Commas";
import { imgURL } from "../../../server/appInfoController";
import { DateToText } from "../../../../components/common/commonUse";

export default function MypageProduct({ value, index, checks, setChecks }) {
  const history = useHistory();

  const handleChecks = (e) => {
    e.preventDefault();
    setChecks((item) => {
      const newArr = [...item];
      newArr[index] = !item[index];
      return newArr;
    });
  };

  return (
    <Container>
      <PC>
        <WishListBox>
          <Checks>
            <ChkBox checked={checks[index]} id={1} type="checkbox" name="checkbox" />
            <ChkBoxLabel
              checked={checks[index]}
              onClick={(e) => handleChecks(e)}
              htmlFor={1}
            ></ChkBoxLabel>
          </Checks>
          <WishImgWrap>
            <ProductImg
              src={imgURL + value.prd_main_img}
              onClick={() => history.push(`/store/detail/${value?.prd_id}`)}
            />
          </WishImgWrap>
          <PrdInfoTextWrap>
            <Brand>{value?.bc_title || "(BRAND)"}</Brand>
            <ProductName>{value?.prd_name}</ProductName>
          </PrdInfoTextWrap>
          <PriceWrap>
            <Price>{Commas.numberWithCommas(value.prd_list_price)}원</Price>
          </PriceWrap>
          <DateWrap>
            <Date>{value?.prd_date.split(" ")[0]}</Date>
          </DateWrap>
        </WishListBox>
      </PC>

      <Mobile>
        <WishListBox>
          <WishContentWrap>
            <WishImgWrap>
              <ProductImg
                src={imgURL + value.prd_main_img}
                onClick={() => history.push(`/store/detail/${value?.prd_id}`)}
              />
              <Checks>
                <ChkBox checked={checks[index]} id={1} type="checkbox" name="checkbox" />
                <ChkBoxLabel
                  checked={checks[index]}
                  onClick={(e) => handleChecks(e)}
                  htmlFor={1}
                ></ChkBoxLabel>
              </Checks>
            </WishImgWrap>
            <WishTextWrap>
              <PrdInfoTextWrap>
                <Brand>{value?.bc_title || "(BRAND)"}</Brand>
                <ProductName>{value?.prd_name}</ProductName>
              </PrdInfoTextWrap>
              <PriceWrap>
                <Price>{Commas.numberWithCommas(value?.prd_list_price ?? 0)}원</Price>
              </PriceWrap>
              {/* <DateWrap>
                            <DateTitle>보관일시</DateTitle>
                            <Date>{value?.prd_date.split(' ')[0]}</Date>
                        </DateWrap> */}
            </WishTextWrap>
          </WishContentWrap>
        </WishListBox>
      </Mobile>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  &:last-child {
    border-bottom: none;
  }

  @media screen and (max-width: 1320px) {
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 930px) {
    border-bottom: calc(100vw * (1 / 428)) solid #d9d9d9;
  }
`;
const WishListBox = styled.div`
  display: flex;
  align-items: center;
  padding: 35px 0 35px 5px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (35 / 1320)) 0 calc(100vw * (35 / 1320)) calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    display: block;
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const WishImgWrap = styled.div`
  width: 150px;
  height: 150px;
  margin: 0 82px 0 44px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (150 / 1320));
    height: calc(100vw * (150 / 1320));
    margin: 0 calc(100vw * (82 / 1320)) 0 calc(100vw * (44 / 1320));
  }

  @media screen and (max-width: 930px) {
    position: relative;
    width: calc(100vw * (90 / 428));
    height: calc(100vw * (90 / 428));
    margin: 0;
  }
`;
const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0 82px 0 44px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    margin: 0 calc(100vw * (82 / 1320)) 0 calc(100vw * (44 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin: 0;
  }
`;
const PrdInfoTextWrap = styled.div`
  width: calc(100% - 660px);

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (660 / 1320)));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const Brand = styled.div`
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin-bottom: 10px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    margin-bottom: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (2 / 428));
  }
`;
const ProductName = styled.p`
  cursor: pointer;
  width: 429px;
  word-break: break-all;
  font-size: 18px;
  line-height: 1.83;
  color: #646464;
  font-weight: 500;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (429 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (16 / 428));
  }
`;
const OptionWrap = styled.div`
  display: flex;
  width: 429px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (429 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    margin-top: calc(100vw * (5 / 428));
  }
`;
const OptionPrice = styled.p`
  font-size: 16px;
  color: #646464;
  font-weight: 500;
  margin-right: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-right: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-right: calc(100vw * (10 / 428));
  }
`;
const OptionText = styled.p`
  font-size: 16px;
  color: #646464;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const PriceWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (150 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    margin-top: calc(100vw * (6 / 428));
  }
`;
const Price = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #000;
  width: 263px;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
    width: calc(100vw * (263 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (18 / 428));
    text-align: left;
  }
`;
const DateWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (250 / 1320));
  }

  @media screen and (max-width: 930px) {
    display: block;
    width: 100%;
    margin-top: calc(100vw * (20 / 428));
  }
`;
const Date = styled.p`
  font-size: 15px;
  font-weight: 500;
  color: #000;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (13 / 428));
  }
`;

//checkbox
const Checks = styled.div`
  height: 24px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    position: absolute;
    top: calc(100vw * (5 / 428));
    left: calc(100vw * (5 / 428));
    height: calc(100vw * (18 / 428));
  }
`;
const ChkBox = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  & + label::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid #000;
    border-radius: 100%;
  }

  :checked + label::after {
    display: none;
  }
  @media screen and (max-width: 1320px) {
    & + label::after {
      width: calc(100vw * (20 / 1320));
      height: calc(100vw * (20 / 1320));
    }
    &:checked + label::after {
      width: calc(100vw * (20 / 1320));
      height: calc(100vw * (20 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    & + label::after {
      width: calc(100vw * (16 / 428));
      height: calc(100vw * (16 / 428));
    }
    &:checked + label::after {
      width: calc(100vw * (16 / 428));
      height: calc(100vw * (16 / 428));
    }
  }
`;
const ChkBoxLabel = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  &&::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: #000;
    border: 2px solid #000;
    border-radius: 100%;
    background: #000 url(${IconCheckbox}) no-repeat center/14px;
  }

  @media screen and (max-width: 1320px) {
    &&::before {
      width: calc(100vw * (20 / 1320));
      height: calc(100vw * (20 / 1320));
      border: calc(100vw * (2 / 1320)) solid #000;
      background: #000 url(${IconCheckbox}) no-repeat center/calc(100vw * (14/1320));
    }
  }

  @media screen and (max-width: 930px) {
    &&::before {
      width: calc(100vw * (16 / 428));
      height: calc(100vw * (16 / 428));
      border: calc(100vw * (2 / 428)) solid #000;
      background: #000 url(${IconCheckbox}) no-repeat center/calc(100vw * (14/428));
    }
  }
`;
// mobile
const WishContentWrap = styled.div`
  display: flex;
  width: 100%;
`;
const WishTextWrap = styled.div`
  width: calc(100% - calc(100vw * (90 / 428)));
  padding-left: calc(100vw * (15 / 428));
`;
const DateTitle = styled.p`
  width: 100%;
  font-size: calc(100vw * (14 / 428));
  font-weight: bold;
  margin-bottom: calc(100vw * (8 / 428));
`;
