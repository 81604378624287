import React, { useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components';
import DatePicker, { registerLocale } from "react-datepicker";  // 한국어적용
import ko from 'date-fns/locale/ko'; // 한국어적용
import "react-datepicker/dist/react-datepicker.css";
import './Calendar.css';

const Calendar = ({ isLong, option, setValue }) => {
    const [options, setOptions] = useState({})
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const handleSingleChange = useCallback((dates) => { 
        const [start, end] = dates;
        let opt = 1;
        if (option === "3일") {
            opt = 2 ;
        } else if (option === "5일") {
            opt = 4;
        }
        const startDay = new Date(start);
        const endDay = new Date(startDay.getTime() + (3600 * 1000 * 24 * opt))
        // setStartDate(e);
        console.table([startDay, endDay, opt])
        setStartDate(startDay);
        setEndDate(endDay)
    }, [option])

    const handleMutipleChange = useCallback((dates) => { 
        const [start, end] = dates;        
        setStartDate(start);
        setEndDate(end)
    }, [option])

    const onChangeMonth = () =>{
        // setStartDate("");
        // setEndDate("");
        // setTimeout(function(){
        //     if(document.getElementsByClassName("react-datepicker__day--keyboard-selected")[0]){
        //         document.getElementsByClassName("react-datepicker__day--keyboard-selected")[0].classList.remove("react-datepicker__day--keyboard-selected");
        //     }
        // },1);
        
        // setTimeout(function(){
        //     if(document.getElementsByClassName("react-datepicker__day--keyboard-selected")[0]){
        //         document.getElementsByClassName("react-datepicker__day--keyboard-selected")[0].classList.remove("react-datepicker__day--keyboard-selected");
        //     }
        // },15); 
    }

    useEffect(() => {
        setValue([startDate, endDate])
    }, [startDate, endDate])

    return (
        <div className="select_calendar">
            <DatePicker              
                // selected={startDate}
                onChange={isLong ? handleMutipleChange : handleSingleChange}
                // onMonthChange={onChangeMonth}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                minDate={new Date()}
                locale={ko}                
                />
        </div>
    )
}

export default Calendar
