import styled, { css } from "styled-components";
import {
    Px,
    Width,
    Height,
    Top,
    mquery,
    mobil,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
} from '../common/mediaStlye';
import arrowBtnNext from '../../images/next-button-arrow.png';
import arrowBtnPrev from '../../images/prev-button-arrow.png';
import arrowBtnNextOn from '../../images/next-button-arrow-on.png';
import arrowBtnPrevOn from '../../images/prev-button-arrow-on.png';

const pcVh = 1920;

export const Container = styled.div`
    padding-top: calc((100vw * (172 / 1920)));    
    width: 100%;
    display: flex;
    justify-content: center;
    ${props => props.grey && css`
    background-color: #FAFAFA;
    `}
`

export const Layout = styled.div`
    width: calc(100vw * (1173 / 1920));

    @media screen and (max-width: ${mquery}px) {
        width: 100%;
        ${Padding2(0, 25, mobil)}   
    }
`

export const ContentHeadings = styled.div`
    ${[
        Padding(0,0,0,23,pcVh),        
    ].join('')}
    border-bottom: 2px solid #222;

    & > em {
        ${[
            FontSize(22,pcVh), 
            MB(4, pcVh)       
        ].join('')}
        color: #FFF200;
        font-weight: 900;
    }

    & > h2 {
        ${[
            FontSize(28,pcVh), 
            PB(18.5, pcVh)       
        ].join('')}
        color: #222;
        font-weight: 900;
    }

    @media screen and (max-width: ${mquery}px) {
        & > em {
            ${[
                FontSize(14,mobil), 
                MB(8, mobil)       
            ].join('')}            
        }

        & > h2 {
            ${[
                FontSize(18,mobil), 
                PB(11.5, mobil)       
            ].join('')}            
        }
    }
`
export const Contents = styled.div`
    ${[
        Padding2(47.5, 24, pcVh),        
    ].join('')}
`

export const ContentLarge = styled.div`
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    row-gap: ${Px(15, pcVh)};
    ${MB(53, pcVh)}

    @media screen and (max-width: ${mquery}px) {

    }
`

export const Item = styled.div`
    ${[
        Width(556, pcVh),
        Height(317, 1920),
    ].join('')}
    overflow: hidden;    
    position: relative;
    cursor: pointer;
    
    &:hover .item_head {
        opacity: 1;
    }

    @media ${props => props.theme.container} {
        width: 100%;
        ${Height(288, mobil)}
    }
`
export const ItemImg = styled.div`
    width: 100%;
    height:100%;
    line-height: 0;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
`
export const ItemDesc = styled.div`
    position: absolute;
    left: 0;
    bottom:0;
    width: 100%;
    ${[
        Padding(24,42,0,26,pcVh),    
        Height(145, pcVh),            
    ].join('')}    
    height: calc(100vw * ( 114 / 1920));
    background-color: rgba(253,241,81,0.62); 
    opacity: 1;
    transition: all 0.3s ease;

    & > h4 {
        font-size: calc(100vw * (18 / 1920));
        padding-bottom: calc(100vw * (7 / 1920));
        color: #222;
    }

    & > p {
        font-size: calc(100vw * (15 / 1920));        
        line-height: 1.2;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;        
        text-align: left;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 3 ;
        -webkit-box-orient: vertical;
    }

    @media ${props => props.theme.container} {        
        ${Padding(25, 65, 10, 17, mobil)}
        ${Height(130, mobil)}

        & > h4 {
            ${FontSize(15, mobil)}
            ${PB(7, mobil)}    
            font-weight: 700;        
        }

        & > p {
            ${FontSize(13, mobil)}            
        }
    }
`
export const BlogIcon = styled.div`
    position: absolute;
    ${[
        Left(17, pcVh),    
        Top(17, pcVh),      
        Width(29, pcVh)      
    ].join('')}  

    @media ${props => props.theme.container} {        
        ${Width(29, mobil)}
        ${Left(17, mobil)}
        ${Top(17, mobil)}       
        
        & > img {
            width: 100%;
        }
    }
`


export const ContentOthers = styled.div`
    ${[
        Height(315, pcVh),    
        MB(179, pcVh)        
    ].join('')}  
    display: flex;
    width: 100%;
    flex-grow: 1;
    gap: ${Px(12, pcVh)};

    @media screen and (max-width: ${mquery}px) {
        height: auto;
        gap: ${Px(8, mobil)};
    }
`

export const OthersItem = styled.div`
    flex: 1;
    border-radius: ${Px(10, pcVh)};
    overflow: hidden;
    background-color: #fff;    
    box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
    cursor: pointer;    
`
export const OthersHead = styled.div`
    ${[
        Height(52, pcVh),
        PR(21, pcVh),
        PB(14, pcVh)
    ].join('')}
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    & > div {
        ${[
            Left(13, pcVh),    
            Top(14, pcVh),      
            Width(29, pcVh)      
        ].join('')} 
    }

    & > span {
        ${FontSize(13, pcVh)}
        color: #D9D9D9;
    }

    @media ${props => props.theme.container} {
        ${[
            Height(35, mobil),
            PR(15, mobil),
            PB(9, mobil)
        ].join('')}
        
        & > div {
            ${[
                Left(9, mobil),    
                Top(9, mobil),      
                Width(19, mobil)      
            ].join('')} 
        }

        & > span {
            ${FontSize(13, mobil)}            
        }
    }
`
export const OthersThumb = styled.div`
    ${[
        Width(273, pcVh),
        Height(173, pcVh)
    ].join('')}

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media ${props => props.theme.container} { 
        ${[            
            Height(177, mobil)
        ].join('')}
        width: 100%;
    }
`
export const OthersTitle = styled.div`
    ${Padding(20, 17, 0, 17, pcVh)}
    font-size: calc(100vw * (16 / 1920));        
    line-height: 1.4;
    color: #222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;        
    text-align: left;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2 ;
    -webkit-box-orient: vertical;

    @media ${props => props.theme.container} { 
        ${Padding(20, 17, 18, 17, mobil)}
        ${FontSize(15, mobil)}
        

        & > p {
            color: #222;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;        
            text-align: left;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2 ;
            -webkit-box-orient: vertical;
            font-weight: 700;
        }
    }
`
//  기타 컨텐츠 Extra
export const ExtraContainer = styled(Container)`
    ${PB(177, pcVh)}

    @media screen and (max-width: ${mquery}px) {
        width:100%;
    }
`

export const ExtraHeadings = styled(ContentHeadings)`
    & > h3 {
        ${[
                FontSize(18, pcVh),
                Padding2(24.5, 0, pcVh)
        ].join("")}       
        color: #606060;
    }

    @media screen and (max-width: ${mquery}px) {
        width:100%;
        & > h3 {
            ${[
                    FontSize(16, mobil),
                    Padding(13, 0, 13, 12, mobil)
            ].join("")}                   
        }
    }
`
export const ExtraList = styled.div`
    ${[
        PT(60, pcVh),
        PB(80, pcVh)
    ].join("")}

    @media screen and (max-width: ${mquery}px) {
        ${[
            PT(8, mobil),
            PB(40.5, mobil)
        ].join("")}
    }
`
export const ExtraItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: calc(100vw * (16 / 1920)) 0;
    border-bottom: 1px solid #D9D9D9;

    @media screen and (max-width: ${mquery}px) {
        ${[
            MT(10,mobil),
            PB(11, mobil),
        ].join("")}
    }
`

export const ExtraItemImg = styled.div`
    width: calc(100vw * (269 / 1920));
    height:calc(100vw * (151 / 1920));

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media screen and (max-width: ${mquery}px) {
        ${Width(175, mobil)}
        ${Height(98, mobil)}
    }
`

export const ExtraItemText = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    flex-direction: column;

    position: relative;
    padding-left: calc(100vw * (70 / 1920));
    padding-right: calc(100vw * (4 / 1920));

    & > h4 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: calc(100vw * (18 / 1920));
        padding-bottom: calc(100vw * (14 / 1920));
        color: #222;
        font-weight: 900;
    }

    & > p {
        font-size: calc(100vw * (16 / 1920));
        padding-right: calc(100vw * (30 / 1920));
        color: #222;
        line-height: 1.3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;        
        text-align: left;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    @media screen and (max-width: ${mquery}px) {
        ${PL(13.3, mobil)} 
        ${PR(63, mobil)} 

        & > h4 {          
            ${FontSize(16, mobil)}
            ${PB(0, mobil)}     
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;        
            text-align: left;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2;      
        }

    }
`

export const ExtraMark = styled.div`
    position: absolute;
    right: 0;
    top:calc(100vw * (-5 / 1920));
    width: calc(100vw * (56 / 1920));

    & > img {
        width: 100%;        
    }

    @media screen and (max-width: ${mquery}px) {
        ${Width(45, mobil)}
        top: 0;
    }
`
export const PagingBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        ${[
            Width(353, pcVh),
            Padding2(0, 72, pcVh)
        ].join("")}
        flex-grow: 1;        
    }
    & ul > li {
        flex: 1;
        ${Height(25, pcVh)}
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #A5A5A5;
    }
    
    @media screen and (max-width: ${mquery}px) {
        & ul {
            ${[
                Width(109, mobil),
                Margin2(0, 34, mobil),
            ].join("")}      
            padding: 0;
            transform: translateY(2px);
            
        }

        & ul > li {                        
            ${Height(18, mobil)}           
        }
    }
`

const ButtonCss = css`
    ${[
        Width(12, pcVh),
        Height(24, pcVh)
    ].join("")}
    background-repeat: no-repeact;
    background-size: 100%;
    background-position: center;
    cursor: pointer;

    @media screen and (max-width: ${mquery}px) {
        ${Width(6, mobil)}
        ${Height(12, mobil)}        
    }
`
export const NextButton = styled.button`
    ${ButtonCss}
    background-image: url(${arrowBtnNext});    

    :hover {
        background-image: url(${arrowBtnNextOn});
    }
`
export const PrevButton = styled.button`
    ${ButtonCss}
    background-image: url(${arrowBtnPrev});    

    :hover {
        background-image: url(${arrowBtnPrevOn});
    }
`

// media contents , Extra
export const MediaItem = styled(Item)`

`

export const MediaItemImg = styled(ItemImg)`
   
`

export const MediaOthersItem = styled.div`
    position: relative;    
`

export const MediaItemDesc = styled(ItemDesc)`
    background-color: rgba(34,34,34,0.87); 
    ${Padding(10.3,48, 0, 20,  pcVh)}

    & > em {
        ${[
            FontSize(10,pcVh),
            PB(4, pcVh)
        ].join("")}
        color: #FFF200;
    }

    & > h4 {
        ${[
            FontSize(18, pcVh),
            PB(10.3, pcVh)
        ].join("")}
        color: #fff;
    }

    & > p {
        ${[
            FontSize(15, pcVh)            
        ].join("")}
        -webkit-line-clamp: 3 ;
        color: #D9D9D9;
    }

    @media ${props => props.theme.container} {        
        ${Padding(12, 65, 10, 17, mobil)}
        ${Height(130, mobil)}

        & > h4 {
            ${FontSize(15, mobil)}
            ${PB(7, mobil)}    
            font-weight: 700;        
        }

        & > p {
            ${FontSize(13, mobil)}            
        }
    }
`

export const ExtraThumb = styled.div`
    width: 100%;
    height: 100%;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media ${props => props.theme.container} { 
        ${[            
            Height(177, mobil)
        ].join('')}
        width: 100%;
    }
`

export const ExtraDesc = styled(MediaItemDesc)`
    ${[
        Padding(18,17,0,18,pcVh),    
        Height(100, pcVh),            
    ].join('')}    
    background-color: #000;
    & > em {    
        ${PB(8,pcVh)}
    }

    & > h3 {    
        color: #fff;
        line-height: 1.3;
        -webkit-line-clamp: 2 ;
    }

    @media ${props => props.theme.container} {        
        ${Padding(12, 11, 10, 17, mobil)}
        ${Height(113, mobil)}

        & > em {    
            display: block;
            ${PB(12,pcVh)}
        }
        & > h3 {
            ${FontSize(15, mobil)}
              
            font-weight: 700;   
            -webkit-line-clamp:3;     
        }       
    }
`

export const MediaExtraMark = styled(ExtraMark)`
    color: #D9D9D9;
    min-width: ${Px(150, pcVh)};
    ${FontSize(16, pcVh)}
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;        
    text-align: left;
    font-weight: 900;
    
`