import { useEffect, useState } from "react";
import { Mobile, PC } from "../../MediaQuery";
import { useParams } from "react-router-dom";
import serverController from "../../server/serverController";

import SearchHeader from "../../component/common/SearchHeader";
import SubTitleSection from "../../component/common/SubTitleSection";
import CategoryMenuList from "../../component/product/CategoryMenuList";
import BestProduct from "../../component/product/BestProduct";
import SearchProduct from "../../component/product/SearchProduct";
import AllProduct from "../../component/product/AllProduct";
import Footer from "../../component/common/StoreFooter";
import FooterMenu from "../../component/common/FooterMenu";
import FixMenu from "../../component/common/FixMenu";

export default function SearchProductsPage() {
  const { keyword } = useParams();
  const [list, setList] = useState([]);

  useEffect(() => {
    serverController.connectFetchController(
      `products?search=${keyword}`,
      "GET",
      null,
      (res) => {
        console.log(res, "검색 결과 res");
        if (res?.result === 1) {
          setList(res?.list);
        }
      },
      (err) => console.error(err)
    );
  }, [keyword]);

  return (
    <>
      <PC>
        <SearchHeader />
        <SubTitleSection
          isSearch
          title={`'${keyword}'`}
          에
          subTitle={`에 대한 ${list.length > 0 ? list.length : 0}개의 상품`}
          subText="WALA 스토어의 다양한 제품들을 확인해 보세요."
          off={false}
        >
          {/* <CategoryMenuList/> */}
        </SubTitleSection>
        {/* <BestProduct/> */}
        <SearchProduct list={list} />
        <Footer />
        <FixMenu />
      </PC>

      <Mobile>
        <SearchHeader inputSearchValue={keyword} result={true} />
        <SearchProduct list={list} />
        <Footer />
        <FixMenu />
      </Mobile>
    </>
  );
}
