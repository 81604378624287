import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperArrow from "../../img/icon/icon_swiperArrow.svg";
import SwiperArrowL from "../../img/icon/icon_swiperArrowL.svg";
import SwiperArrowG from "../../img/icon/icon_swiperArrow_gray.svg";
import SwiperArrowGL from "../../img/icon/icon_swiperArrow_grayL.svg";
import ProductList from "../../component/common/product/ProductList";
import MyPageDefaultModal from "../common/modal/MyPageDefaultModal";
import { InfoText } from "../../page/main/MainPage";

export default function BestNewSection({ list, setLoginPop }) {
  const history = useHistory();
  const location = useLocation();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [isButtonClick, setIsButtonClick] = useState(false);

  // s :: button 아이콘 gray 바꿔주기
  const disablePrevButton = document.querySelector(
    "#swiper-prev-nav-newItem.swiper-button-disabled img"
  );
  const prevButton = document.querySelector("#swiper-prev-nav-newItem img");
  const disableNextButton = document.querySelector(
    "#swiper-next-nav-newItem.swiper-button-disabled img"
  );
  const nextButton = document.querySelector("#swiper-next-nav-newItem img");

  useEffect(() => {
    if (disablePrevButton) {
      disablePrevButton.setAttribute("src", SwiperArrowGL);
    } else if (prevButton !== null) {
      prevButton.setAttribute("src", SwiperArrowL);
    }
    if (disableNextButton) {
      disableNextButton.setAttribute("src", SwiperArrowG);
    } else if (nextButton !== null) {
      nextButton.setAttribute("src", SwiperArrow);
    }
  }, [isButtonClick]);
  // e :: button 아이콘 gray 바꿔주기

  return (
    <Container>
      <PC>
        <BestNewWrap>
          <TitleWrap>
            <TitleText>New Item</TitleText>
            <SwiperArrowWrap>
              <SwiperNav
                ref={prevRef}
                id="swiper-prev-nav-newItem"
                onClick={() => setIsButtonClick(!isButtonClick)}
              >
                <img src={SwiperArrowGL} alt="arrow" />
              </SwiperNav>
              <SwiperNav
                ref={nextRef}
                id="swiper-next-nav-newItem"
                onClick={() => setIsButtonClick(!isButtonClick)}
              >
                <img src={SwiperArrow} alt="arrow" />
              </SwiperNav>
            </SwiperArrowWrap>
          </TitleWrap>
          {list.length > 0 ? (
            <BestNewListWrap>
              <BestNewInner>
                <Swiper
                  slidesPerView={5}
                  slidesPerGroup={1}
                  spaceBetween={10}
                  navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                  }}
                >
                  {list?.map((bestItem, index) => {
                    return (
                      <SwiperSlide key={bestItem.prd_id}>
                        <ProductList
                          key={index}
                          value={bestItem}
                          height="bestSlide"
                          setLoginPop={setLoginPop}
                          newItem
                          rank
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </BestNewInner>
            </BestNewListWrap>
          ) : (
            <InfoText>해당 상품이 없습니다.</InfoText>
          )}
        </BestNewWrap>
      </PC>
      <Mobile>
        {list?.length > 0 ? (
          <BestNewWrap>
            <TitleWrap>
              <TitleText>New Item</TitleText>
            </TitleWrap>
            <BestNewListWrap>
              <BestNewInner>
                {list?.map((bestItem, index) => {
                  return (
                    <ProductList
                      key={index}
                      value={bestItem}
                      height="bestSlide"
                      setLoginPop={setLoginPop}
                      square
                      rank
                    />
                  );
                })}
              </BestNewInner>
              {/* <MbBtnWrap>
                <SeeMoreBtn onClick={() => history.push(`/store/best`)}>See More</SeeMoreBtn>
              </MbBtnWrap> */}
            </BestNewListWrap>
          </BestNewWrap>
        ) : null}
      </Mobile>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 100px;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (100 / 1320));
  }

  @media screen and (max-width: 930px) {
    border-top: calc(100vw * (1 / 428)) #eee solid;
    padding: calc(100vw * (40 / 428)) 0 calc(100vw * (60 / 428));
  }
`;
const BestNewWrap = styled.div`
  width: 1240px;
  margin: 0 auto;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const TitleWrap = styled.div`
  font-weight: bold;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (16 / 428));
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  }
`;
const TitleText = styled.h2`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
  color: #222222;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const BestNewListWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 1320px) {
  }

  @media screen and (max-width: 930px) {
    background: none;
  }
`;
const BestNewInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  & .swiper-container {
    cursor: default !important;
  }
  & .swiper-container-horizontal > .swiper-scrollbar {
    width: 200px;
    height: 3px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 1320px) {
    & .swiper-container-horizontal > .swiper-scrollbar {
      width: calc(100vw * (200 / 1320));
      height: calc(100vw * (3 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    display: -webkit-box;
    -webkit-flex-wrap: inherit;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
    padding: 0 calc(100vw * (25 / 428));
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const SwiperArrowWrap = styled.div`
  display: flex;
  align-items: center;
`;
const SwiperNav = styled.div`
  width: 36px;
  height: 36px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (36 / 1320));
    height: calc(100vw * (36 / 1320));
  }
`;
