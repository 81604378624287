import React, { useEffect } from "react";
import { Mobile, PC } from "../../../MediaQuery";
import { useState } from "react";
import styled from "styled-components";

// common
import { numberWithCommas } from "../../../../components/common/commonUse";

// controller
import serverController from "../../../server/serverController";
// img
import IconSelectArrow from "../../../img/icon/icon_bottom_arrow.svg";
import IconClose from "../../../img/icon/icon_popup_close.svg";
import IconCancle from "../../../img/icon/icon_myhaeder_cancle.svg";
import ZigzagPcSmall from "../../../img/borderBottom_zigzag_small.svg";

// component
import MyPageLayOutMenu from "../MyPageLayOutMenu";
import MyPageLayOutName from "../MyPageLayOutName";
import MyPageLayOutInfo from "../MyPageLayOutInfo";

const filterItems = ["전체", "적립", "사용"];

export default function MyPointList({}) {
  const [tabIndex, setTabIndex] = useState(0);
  const [hide, setHide] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [guideShow, setGuideShow] = useState(false);
  const [pointList, setPointList] = useState([]);
  const [filterType, setFilterType] = useState(0);
  const [totalPoint, setTotalPoint] = useState(0);

  // get point info
  const getPointInfoList = (index = 0) => {
    serverController.connectFetchController(`points?type=${index + 1}`, "GET", null, (res) => {
      if (res.result === 1) {
        setPointList(res.list);
        setTotalPoint(res.myTotalPoint);
      }
    });
  };

  // header scroll control
  useEffect(() => {
    // s :: scroll down fix
    let lastScrollY = 0;

    console.log("test");

    window?.addEventListener("scroll", (e) => {
      const scrollY = window?.scrollY;

      const direction = scrollY > lastScrollY ? true : false;

      lastScrollY = scrollY;

      if (lastScrollY > 130) setHide(direction);
    });

    // e :: scroll down fix
  }, []);

  useEffect(() => {
    getPointInfoList();
  }, []);

  return (
    <Container>
      {/* page */}
      <PC>
        <MyPageLayOutName />
      </PC>

      {/* content */}
      <FlexLayOut>
        <PC>
          <MyPageLayOutMenu />
        </PC>

        <ContentSection>
          <PC>
            <MyPageLayOutInfo />
          </PC>

          {/* 포인트 */}
          <PointSection>
            <PC>
              <PcTop>
                <WishListTabArea>
                  <WishListTabBox>
                    {filterItems.map((item, index) => {
                      return (
                        <WishListTab
                          onClick={() => {
                            setTabIndex(index);
                            getPointInfoList(index);
                          }}
                        >
                          {item}
                        </WishListTab>
                      );
                    })}

                    <WishListTabBottom tabIndex={tabIndex}>
                      <WishListTabBottomImg src={ZigzagPcSmall} alt="bottom" />
                    </WishListTabBottom>
                  </WishListTabBox>
                </WishListTabArea>
                <FilterButton
                  onClick={() => {
                    setGuideShow(!guideShow);
                  }}
                >
                  <FilterText>포인트 안내사항</FilterText>
                  <FilterImgBoxRight>
                    <img src={IconSelectArrow} alt="" />
                  </FilterImgBoxRight>
                </FilterButton>
              </PcTop>
            </PC>

            <TotalPointWrap hide={hide}>
              <p>
                {numberWithCommas(totalPoint)}
                <span>P</span>
              </p>
            </TotalPointWrap>

            <Mobile>
              <FilterWrap hide={hide}>
                <FilterButton
                  onClick={() => {
                    setFilterShow(!filterShow);
                  }}
                >
                  <FilterText>{filterItems[filterType]}</FilterText>
                  <FilterImgBox>
                    <img src={IconSelectArrow} alt="" />
                  </FilterImgBox>
                </FilterButton>
                <FilterButton
                  onClick={() => {
                    setGuideShow(!guideShow);
                  }}
                >
                  <FilterText>포인트 안내사항</FilterText>
                  <FilterImgBoxRight>
                    <img src={IconSelectArrow} alt="" />
                  </FilterImgBoxRight>
                </FilterButton>
              </FilterWrap>
            </Mobile>

            <ContentsWrap>
              {pointList.length > 0 ? (
                <Contents>
                  <ListBox>
                    {pointList.map((pointItem) => {
                      return (
                        <List>
                          <PointArea>
                            <PointDate>
                              {pointItem.point_create_date.split(" ")[0].replaceAll("-", ".")}
                            </PointDate>
                            <PointTitleBox>
                              <PointTitle>{pointItem.point_history}</PointTitle>
                              {pointItem.point_history === "상품구매 포인트 사용" ? (
                                <PointNum minus>
                                  - {numberWithCommas(pointItem.used_point)}
                                </PointNum>
                              ) : (
                                <PointNum>+ {numberWithCommas(pointItem.get_point)}</PointNum>
                              )}
                            </PointTitleBox>
                            {/* <PointText>
                              CELINE BANDEAU DRESS INSTRzD WOOL C REAM CELINE
                              BANDEAU
                            </PointText> */}
                          </PointArea>
                        </List>
                      );
                    })}
                  </ListBox>
                </Contents>
              ) : (
                <NoneText>보유 포인트가 없습니다.</NoneText>
              )}
            </ContentsWrap>

            {/* <Pagination /> */}
          </PointSection>
        </ContentSection>
      </FlexLayOut>

      {/* 필터 팝업 */}
      {filterShow && (
        <>
          <ModalBg
            onClick={() => {
              setFilterShow(false);
            }}
          />
          <FilterListModal>
            <ModalWrap>
              <ModalUl>
                {filterItems.map((item, index) => {
                  return (
                    <ModalLi
                      active={index === filterType}
                      key={item}
                      onClick={() => {
                        setFilterType(index);
                        getPointInfoList(index);
                      }}
                    >
                      <span>{item}</span>
                    </ModalLi>
                  );
                })}
              </ModalUl>
              <ModalCloseButton
                onClick={() => {
                  setFilterShow(false);
                }}
              >
                닫기
              </ModalCloseButton>
            </ModalWrap>
          </FilterListModal>
        </>
      )}

      {/* 포인트 안내사항 팝업 */}
      {guideShow && (
        <>
          <ModalBg
            onClick={() => {
              setGuideShow(false);
            }}
          />
          <GuideModal>
            {/* header */}
            <HeaderSection>
              <HeaderWrap>
                <p> 포인트 안내사항</p>
                {/* 삭제 버튼 */}
                <HeaderDeleteButton
                  onClick={() => {
                    setGuideShow(false);
                  }}
                >
                  <img src={IconCancle} alt="deleteButton" />
                </HeaderDeleteButton>
              </HeaderWrap>
            </HeaderSection>

            <GuideModalContent>포인트 안내사항 입니다.</GuideModalContent>
          </GuideModal>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 180px 0 200px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const PointSection = styled.div`
  padding: 20px 0 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0 0;
  }

  @media screen and (max-width: 930px) {
    padding: 0 0 calc(100vw * (100 / 428));
  }
`;
// header
const HeaderSection = styled.div`
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 930px) {
    position: relative;
    height: calc(100vw * (55 / 428));
    z-index: 99;
  }
`;
const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1190px;
  height: 77px;
  margin: 0 auto;
  border-bottom: 1px solid #eee;
  background-color: #fff;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1190 / 1320));
    height: calc(100vw * (77 / 1320));
  }

  @media screen and (max-width: 930px) {
    position: fixed;
    justify-content: center;
    width: 100%;
    height: calc(100vw * (55 / 428));
    margin: 0;
    border-bottom: 0;
  }
`;
const HeaderDeleteButton = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }

  @media screen and (max-width: 930px) {
    position: absolute;
    top: 50%;
    right: calc(100vw * (13.5 / 428));
    transform: translateY(-50%);
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;

// 필터 버튼
const FilterWrap = styled.div`
  background-color: #fff;

  @media screen and (max-width: 930px) {
    position: fixed;
    top: calc(100vw * (150 / 428));
    left: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    width: 100%;
    transition: ease 0.3s all;
    ${({ hide }) =>
      hide &&
      `
      opacity : 0;
      top : 0;
  `}
  }
`;
const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  height: 35px;
  padding: 0 5px 0 10px;
  border: solid #ddd;
  border-width: 1px;

  :not(:last-child) {
    border-right: 1px solid #dddddd;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (180 / 1320));
    height: calc(100vw * (35 / 1320));
    padding: 0 calc(100vw * (5 / 1320)) 0 calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 50%;
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (25 / 428));
    border: solid #ddd;
    border-width: 1px 0;
  }
`;
const FilterText = styled.span`
  color: #333333;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const FilterImgBox = styled.span`
  width: 24px;
  height: 24px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const FilterImgBoxRight = styled(FilterImgBox)`
  transform: rotate(270deg);
`;

// 콘텐츠
const ContentsWrap = styled.div`
  margin-top: 20px;
  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: 0;
    padding-top: calc(100vw * (150 / 428));
  }
`;
const Contents = styled.div``;

const TotalPointWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px 0;
  background-color: #f9f9f9;
  border-bottom: 1px solid #000;

  & p,
  span {
    color: #333;
    font-weight: 700;
  }

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (38 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    position: fixed;
    left: 0;
    z-index: 9;
    width: 100%;
    padding: calc(100vw * (34 / 428)) 0;
    background-color: #f8f8f8;
    border-bottom: 0;
    transition: ease 0.3s all;
    ${({ hide }) =>
      hide &&
      `
      opacity : 0;
      top : calc(100vw * (-80 / 428));
  `}

    & p,
    span {
      font-size: calc(100vw * (26 / 428));
    }

    & span {
      margin-left: calc(100vw * (6 / 428));
    }
  }
`;

const ListBox = styled.ul``;
const List = styled.li`
  border-bottom: 1px solid #eeeeee;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (398 / 428));
    margin: 0 auto;
  }
`;
const PointArea = styled.div`
  padding: 20px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) calc(100vw * (10 / 428));
  }
`;
const PointDate = styled.p`
  color: #555;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const PointTitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (8 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (8 / 428));
  }
`;
const PointTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const PointNum = styled(PointTitle)`
  color: ${(props) => props.minus && "#FF006C"};
  font-weight: 600;
`;
const PointText = styled.p`
  font-weight: 400;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (300 / 428));
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (5 / 428));
  }
`;

const NoneText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #999;
  font-weight: 400;
  font-size: 16px;
  padding: 200px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (200 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: 0;
    margin-top: calc(100vh * (220 / 925));
  }
`;

// 팝업
// 필터 팝업
const FilterListModal = styled.div``;

const ModalWrap = styled.div`
  position: fixed;
  z-index: 106;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
`;
const ModalUl = styled.ul`
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (54 / 428)) calc(100vw * (25 / 428));
  }
`;
const ModalLi = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ active }) =>
    active
      ? `
    &span {
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-6deg);
      z-index: 0;
      display: block;
      background-color: #fdf251;
    }
    `
      : `
  &>span {
    color: #333333;
    font-weight: 500;
  }
  `}
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));

    ${({ active }) =>
      active &&
      ` & span {
        position: relative;
        z-index: 1;
      }
      &::after {
        width: calc(100vw * (56 / 428));
        height: calc(100vw * (12 / 428));
      }`}
  }
`;

const ModalCloseButton = styled.button`
  position: absolute;
  font-size: 0;
  background: url(${IconClose}) no-repeat center / cover;

  @media screen and (max-width: 930px) {
    top: calc(100vw * (20 / 428));
    right: calc(100vw * (25 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;

// 포인트 안내사항 팝업
const GuideModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
  width: 1240px;
  height: 650px;
  background: #fff;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    height: calc(100vw * (650 / 1320));
  }

  @media screen and (max-width: 930px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    transform: none;
  }
`;
const GuideModalContent = styled.div`
  width: 1190px;
  height: calc(100% - 77px);
  line-height: 1.5;
  color: 333;
  font-weight: 400;
  font-size: 16px;
  padding: 20px 0;
  margin: 0 auto;
  overflow: auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1190 / 1320));
    height: calc(100% - calc(100vw * (77 / 1320)));
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (20 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    height: auto;
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (25 / 428)) calc(100vw * (30 / 428));
  }
`;

const ModalBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 105;
  background-color: rgba(0, 0, 0, 0.4);
`;
// pc- tab
const PcTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const WishListTabArea = styled.div`
  width: 100%;
  border-bottom: 1px solid #eee;
`;
const WishListTabBox = styled.div`
  position: relative;
  display: flex;
  width: 300px;
  height: 50px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (300 / 1320));
    height: calc(100vw * (50 / 1320));
  }
`;
const WishListTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 3);
  height: 100%;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;
const WishListTabBottom = styled.div`
  position: absolute;
  left: ${(props) =>
    props.tabIndex === 0
      ? `0`
      : props.tabIndex === 1
      ? `100px`
      : props.tabIndex === 2
      ? `200px`
      : null};
  bottom: -7px;
  transition: ease 0.3s all;
  width: 100px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    left: ${(props) =>
      props.tabIndex === 0
        ? `0`
        : props.tabIndex === 1
        ? `calc(100vw * (100 / 1320))`
        : props.tabIndex === 2
        ? `calc(100vw * (200 / 1320))`
        : null};
    bottom: calc(100vw * (-7 / 1320));
    width: calc(100vw * (100 / 1320));
  }
`;
const WishListTabBottomImg = styled.img`
  width: 100%;
  height: 100%;
`;
const Title = styled.h2`
  font-size: 24px;
  color: #333333;
  font-weight: 700;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
  }
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
