import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import openLink from "../../server/openLink";
import { imgURL } from "../../server/appInfoContoller";
import { postLikeProducts } from "../../hooks/likeHooks";

import {
  ProductItem,
  ItemImg,
  ProductItemDesc,
  ProductLikeButton,
} from "./ContentDetailsPage.element";

import likeBtnOff from "../../images/stylist/like_icon.svg";
import likedBtn from "../../images/stylist/liked_icon.svg";
import Commas from "../../store_src/server/Commas";
import DefaultImg from "../../store_src/img/icon/loading_default_img.svg";

const ContentProductItem = ({ data }) => {
  const history = useHistory();
  const [isLiked, setIsLiked] = useState(false);

  const handleLike = async (id) => {
    const res = await postLikeProducts(id);
    console.log(res, "제품 좋아요");
    setIsLiked(res?.result === 1);
  };

  const pageLinkClick = (id, type, link) => {
    if (type == 3) {
      openLink.openLinkEvent(link);
      // window.open(`${link}`);
    } else {
      alert("런칭 준비중입니다 :D");
      // history.push(`/store/detail/${id}`);
    }
  };

  return (
    <ProductItem>
      <ItemImg
        onClick={() => {
          pageLinkClick(data?.prd_id, data?.prd_type, data?.prd_link);
        }}
      >
        <img
          src={data?.prd_thumbnail ? imgURL + data?.prd_thumbnail : DefaultImg}
          alt={data?.prd_thumbnail ? "product-thumbnail" : "default-img"}
        />
      </ItemImg>
      <ProductItemDesc
        className="item_head"
        onClick={() => {
          pageLinkClick(data?.prd_id, data?.prd_type, data?.prd_link);
        }}
      >
        <em>{data?.br_name?.length}</em>
        <h4>{data?.prd_name}</h4>
        {/* <span>{"₩ " + Commas.numberWithCommas(data?.prd_price ?? 0)}</span> */}
      </ProductItemDesc>
      {/* <ProductLikeButton onClick={() => handleLike(data?.prd_id)}>
        <img src={isLiked ? likedBtn : likeBtnOff} alt="like-button" />
      </ProductLikeButton> */}
    </ProductItem>
  );
};

export default ContentProductItem;
