import { useEffect } from "react";
import { PC, Mobile } from "../../MediaQuery";
import styled from "styled-components";

import Header from "../../components/common/header/Header";
import Complete from "../../components/stylist/Complete";
import Footer from "../../components/common/footer/Footer";
import FooterMenu from "../../components/common/footer/FooterMenu";

export default function SponCompletePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header />
      <Complete
        title="협찬 주문이 완료 되었습니다."
        btn="주문현황 보기"
        url="/stylist/mypage"
        rank={true}
      />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
    </Container>
  );
}

const Container = styled.div`
  @media screen and (max-width: 1024px) {
    padding-bottom: calc(100vw * (68 / 428));
  }
`;
