import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import serverController from "../../../server/serverController";

//css
import styled from "styled-components";

//img
import IconArrow from "../../../img/icon/icon_selectPay_arrow.png";
import { numberWithCommas } from "../../../../components/common/commonUse";
import { PaymentAction } from "../../../../store/actionCreators";

export default function PayCoupon() {
  const [list, setList] = useState([]);

  useEffect(() => {
    serverController.connectFetchController(
      `coupon/list`,
      "GET",
      null,
      (res) => {
        console.log(res, "쿠폰 조회 res");
        if (res?.result === 1) {
          setList(res?.coupon_list ?? []);
        }
      }
    );
  }, []);

  const handleCoupon = (e) => {
    const value = e.target.value;
    PaymentAction.saveCouponInfo({
      ...list[value],
    });
  };

  return (
    <Container>
      <PayCouponWrap>
        <TitleWrap>
          <Title>쿠폰</Title>
        </TitleWrap>
        <CouponWrap>
          {list.length > 0 ? (
            <Coupon name="" id="" onChange={handleCoupon}>
              <CouponList value="" hidden>
                선택해주세요
              </CouponList>
              {list?.map((v, i) => {
                return (
                  <CouponList key={v?.coupon_id} value={i}>
                    {v?.coupon_title}{" "}
                    {numberWithCommas(v?.coupon_discount ?? 0)}
                    {v?.coupon_type == "won" ? "원 할인" : "% 할인"}
                  </CouponList>
                );
              })}
            </Coupon>
          ) : (
            <TitleSub>사용 가능한 쿠폰이 없습니다.</TitleSub>
          )}
        </CouponWrap>
      </PayCouponWrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 30px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (30 / 428));
    border-top: calc(100vw * (12 / 428)) solid #e9ebee;
  }
`;
const PayCouponWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    margin: 0 auto;
  }
`;
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 15px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
    margin-bottom: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    justify-content: space-between;
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (10 / 428));
    border-bottom: none;
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const Title = styled.p`
  font-size: 22px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const TitleSub = styled.p`
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
// Coupon
const CouponWrap = styled.div`
  width: 100%;
`;
const Coupon = styled.select`
  width: 430px;
  height: 45px;
  font-size: 15px;
  color: #525252;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  text-align: center;
  padding: 0 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f7f7f7 url(${IconArrow}) no-repeat center right 15px/10px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (430 / 1320));
    height: calc(100vw * (45 / 1320));
    font-size: calc(100vw * (15 / 1320));
    border: calc(100vw * (1 / 1320)) solid #e4e4e4;
    border-radius: calc(100vw * (5 / 1320));
    padding: 0 calc(100vw * (20 / 1320));
    background: #f7f7f7 url(${IconArrow}) no-repeat center right
      calc(100vw * (15 / 1320)) / calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (45 / 428));
    font-size: calc(100vw * (15 / 428));
    border: calc(100vw * (1 / 428)) solid #e4e4e4;
    border-radius: calc(100vw * (5 / 428));
    padding: 0 calc(100vw * (20 / 428));
    background: #f7f7f7 url(${IconArrow}) no-repeat center right
      calc(100vw * (15 / 428)) / calc(100vw * (10 / 428));
  }
`;
const CouponList = styled.option`
  font-size: 15px;
  color: #525252;
  padding: 5px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (5 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (5 / 428)) 0;
  }
`;
