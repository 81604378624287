//react
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery"

//css
import styled from "styled-components"

export default function PointList({value}) {

  return (
    <Container>
        <PC>
            <PointListBox>
                <RightContWrap>
                    <Icon src={value.src} />
                    <div>
                        <ContTextWrap>
                            <Day>{value.year}.{value.month}.{value.day}</Day>
                            <Detail>{value.detail}</Detail>
                            <Product>{value.product_name}</Product>
                        </ContTextWrap>
                    </div>
                </RightContWrap>
                {/* 사용시 color : #f24f32 / 적립시 color: #000 */}
                <Point style = {{color:"#f24f32"}}>{value.point} P</Point>
            </PointListBox>
        </PC>

        <Mobile>
            <PointListBox>
                <RightContWrap>
                    <PointImgWrap>
                        <Icon src={value.src} />
                        <Point style = {{color:"#f24f32"}}>{value.point} P</Point>
                    </PointImgWrap>
                    <PointTextWrap>
                        <ContTextWrap>
                            <Day>{value.year}.{value.month}.{value.day}</Day>
                            <Detail>{value.detail}</Detail>
                            <Product>{value.product_name}</Product>
                        </ContTextWrap>
                    </PointTextWrap>
                </RightContWrap>
            </PointListBox>
        </Mobile>
    </Container>
  );
}

const Container = styled.div`
    width: 100%;
    border-bottom: 1px solid #d9d9d9;

    @media screen and (max-width: 1320px) {
        border-bottom: calc(100vw*(1/1320)) solid #d9d9d9;
    }

    @media screen and (max-width: 930px) {
        width: calc(100vw*(388/428));
        border-bottom: calc(100vw*(1/428)) solid #d9d9d9;
        &:first-child {border-top: calc(100vw*(1/428)) solid #000;}
        &:last-child {border: none;}
        margin: 0 auto;
    }
`
const PointListBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px 100px 35px 70px;
    border-bottom: 1px solid #d9d9d9;
    &:last-child{border-bottom: transparent;}

    @media screen and (max-width: 1320px) {
        padding: calc(100vw*(35/1320)) calc(100vw*(100/1320)) calc(100vw*(35/1320)) calc(100vw*(70/1320));
        border-bottom: calc(100vw*(1/1320)) solid #d9d9d9;
    }

    @media screen and (max-width: 930px) {
        padding: calc(100vw*(30/428)) 0;
        border-bottom: calc(100vw*(1/428)) solid #d9d9d9;
    }
`
const RightContWrap = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 930px) {
        width: 100%;
    }
`
const ContTextWrap = styled.div`
    margin-left: 36px;

    @media screen and (max-width: 1320px) {
        margin-left: calc(100vw*(36/1320));
    }

    @media screen and (max-width: 930px) {
        width: 100%;
        margin-left: 0;
        padding-left: calc(100vw*(10/428));
    }
`
const Icon = styled.img`
    width: 96px; height: 96px;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(96/1320)); height: calc(100vw*(96/1320));
    }
    
    @media screen and (max-width: 930px) {
        display: block;
        width: calc(100vw*(54/428)); height: calc(100vw*(54/428));
        margin: 0 auto;
    }
`
const Day = styled.div`
    font-size: 18px;
    color: #000;
    font-weight: 500;
    margin-bottom: 7px;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(18/1320));
        margin-bottom: calc(100vw*(7/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(15/428));
        margin-bottom: calc(100vw*(7/428));
    }
`    
const Detail = styled.div`
    font-size: 18px;
    color: #000;
    font-weight: bold;
    margin-bottom: 11px;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(18/1320));
        margin-bottom: calc(100vw*(7/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(16/428));
        margin-bottom: calc(100vw*(7/428));
    }
`
const Product = styled.div`
    width: 800px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 18px;
    color: #969696;
    font-weight: 500;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(800/1320));
        font-size: calc(100vw*(18/1320));
    }
    @media screen and (max-width: 930px) {
        width: 100%;
        font-size: calc(100vw*(16/428));
    }
`    
const Point = styled.div`
    font-size: 20px;
    font-weight: 500;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(20/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(15/428));
        text-align: center;
        margin-top: calc(100vw*(8/428));
    }
`
// mobile
const PointImgWrap = styled.div`
    width: calc(100vw*(90/428));
`
const PointTextWrap = styled.div`
    width: calc(100% - calc(100vw*(90/428)));
`