import React from "react";
import { PC, Mobile } from "../../components/common/mediaStlye";
import styled from "styled-components";
import Header from "../../components/common/header/Header";
import MyPageHeader from "../../components/common/header/MyPageHeader";
import MyPage from "../../components/auth/mypage/MyPage";
import Footer from "../../components/common/footer/Footer";
import FooterMenu from "../../components/common/footer/FooterMenu";

const MyInfoPage = () => {
  return (
    <Container>
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader title="MY PAGE" />
      </Mobile>
      <MyPage />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
    </Container>
  );
};
const Container = styled.div`
  @media screen and (max-width: 1024px) {
    padding-bottom: calc(100vw * (68 / 428));
  }
`;

export default MyInfoPage;
