//react
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";

//css
import styled from "styled-components";

import serverController from "../../../server/serverController";
import { UserDataAction } from "../../../store/actionCreators";

//img
import DownArrow from "../../../images/arrow_down.svg";
import UpArrow from "../../../images/arrow_up.svg";
import IconMenu from "../../../images/menu-bar.svg";
import IconMenuClosed from "../../../images/icon_menu_closed.svg";

export default function MypageMenuList({ menu }) {
  const history = useHistory();
  const [menuState, setMenuState] = useState(false);
  const pathName = window.location.pathname;

  const LogoutFunc = () => {
    serverController.connectFetchController(
      `logout`,
      "POST",
      null,
      (res) => { },
      (err) => console.error(err)
    );
    if (localStorage.getItem("login-info")) {
      localStorage.removeItem("login-info");
    }
    UserDataAction.logOutRequest();
    history.push("/store/");
  };

  return (
    <Container>
      <PC>
        <MyMenuListWrap>
          <SubTitleWrap>
            <SubTitle
              onClick={() => {
                history.push("/store/mypage");
              }}
            >
              마이페이지
            </SubTitle>
          </SubTitleWrap>
          <MenuListWrap>
            <MenuListBox>
              <MenusList>
                <ListTitle>MY</ListTitle>
                <List MenuOn={pathName.split("/")[3] === "wish"}>
                  <Link to={`/store/mypage/wish?tab=0&page=1`}>찜 목록</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "lately"}>
                  <Link to={`/store/mypage/lately`}>최근 본 상품</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "review"}>
                  <Link to={`/store/mypage/review?tab=1&page=1`}>나의 리뷰</Link>
                </List>
              </MenusList>

              <MenusList>
                <ListTitle>주문관리</ListTitle>
                <List MenuOn={pathName.split("/")[3] === "order"}>
                  <Link to={`/store/mypage/order`}>주문/배송 조회</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "return"}>
                  <Link to={`/store/mypage/return`}>취소/교환/반품 조회</Link>
                </List>
              </MenusList>

              {/* <MenusList>
                            <ListTitle>쇼핑혜택</ListTitle>
                            <List MenuOn={pathName.split('/')[3] == "wala"}><Link to={`/store/mypage/wala/point`}>포인트(WALA)</Link></List>
                            <List MenuOn={pathName.split('/')[3] == "point"}><Link to={`/store/mypage/point`}>적립금(상품)</Link></List>
                            <List MenuOn={pathName.split('/')[3] == "coupon"}><Link to={`/store/mypage/coupon`}>보유 쿠폰</Link></List>
                        </MenusList> */}

              <MenusList>
                <ListTitle>정보관리</ListTitle>
                <List MenuOn={pathName.split("/")[3] === "profile"}>
                  <Link to={`/store/mypage/profile`}>회원정보 조회/수정</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "adress"}>
                  <Link to={`/store/mypage/adress`}>배송지 관리</Link>
                </List>
              </MenusList>

              <MenusList>
                <ListTitle>고객센터</ListTitle>
                <List MenuOn={pathName.split("/")[3] === "question"}>
                  <Link to={`/store/mypage/inquiry`}>1:1문의</Link>
                </List>
              </MenusList>
            </MenuListBox>
          </MenuListWrap>
        </MyMenuListWrap>
      </PC>

      <Mobile>
        <MyMenuListWrap on={menuState} fixed={menu === false}>
          <MenuListWrap>
            <MenuListBox>
              <MenusList>
                <ListTitle>MY</ListTitle>
                <List MenuOn={pathName.split("/")[3] === "wish"}>
                  <Link to={`/store/mypage/wish?tab=0&page=1`}>찜 목록</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "lately"}>
                  <Link to={`/store/mypage/lately`}>최근 본 상품</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "review"}>
                  <Link to={`/store/mypage/review?tab=1&page=1`}>나의 리뷰</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "review"}>
                  <Link to={`/store/mypage/productInquiry?page=1`}>문의 내역</Link>
                </List>
              </MenusList>

              <MenusList>
                <ListTitle>주문관리</ListTitle>
                <List MenuOn={pathName.split("/")[3] === "order"}>
                  <Link to={`/store/mypage/order/0?page=1`}>주문/배송 조회</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "return"}>
                  <Link to={`/store/mypage/order/3`}>취소/교환/반품 조회</Link>
                </List>
              </MenusList>

              <MenusList>
                <ListTitle>쇼핑혜택</ListTitle>
                <List MenuOn={pathName.split("/")[3] === "gong"}>
                  <Link to={`/store/mypage/wala/point`}>포인트(WALA)</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "point"}>
                  <Link to={`/store/mypage/point`}>적립금(상품)</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "coupon"}>
                  <Link to={`/store/mypage/coupon`}>보유 쿠폰</Link>
                </List>
              </MenusList>

              <MenusList>
                <ListTitle>정보관리</ListTitle>
                <List MenuOn={pathName.split("/")[3] === "profile"}>
                  <Link to={`/store/mypage/profile`}>회원정보 조회/수정</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "password"}>
                  <Link to={`/store/mypage/password`}>비밀번호 변경</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "adress"}>
                  <Link to={`/store/mypage/adress`}>배송지 관리</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "bank"}>
                  <Link to={`/store/mypage/bank`}>환불계좌 관리</Link>
                </List>
              </MenusList>

              <MenusList>
                <ListTitle>고객센터</ListTitle>
                <List MenuOn={pathName.split("/")[3] === "notice"}>
                  <Link to={`/store/mypage/notice?page=1`}>공지사항</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "faq"}>
                  <Link to={`/store/mypage/faq?page=1`}>자주묻는 질문</Link>
                </List>
                <List MenuOn={pathName.split("/")[3] === "question"}>
                  <Link to={`/store/mypage/inquiry`}>1:1문의</Link>
                </List>
                <List onClick={LogoutFunc}>로그아웃</List>
              </MenusList>
            </MenuListBox>
            {menu && (
              <MbBtnClosed>
                <MbBtnImg
                  src={IconMenuClosed}
                  alt=""
                  onClick={() => {
                    setMenuState(!menuState);
                  }}
                />
              </MbBtnClosed>
            )}
          </MenuListWrap>
        </MyMenuListWrap>
        {menu && (
          <MbBtn>
            <MbBtnImg
              src={IconMenu}
              alt=""
              onClick={() => {
                setMenuState(!menuState);
              }}
            />
          </MbBtn>
        )}
      </Mobile>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
`;
const MyMenuListWrap = styled.div`
  width: 100%;
  background: #ffffff;
  z-index: 100;
  @media screen and (max-width: 930px) {
    ${({ on }) => {
    return on
      ? `height: auto; position: absolute; top: calc(100vw*(85/428)); box-shadow: 0 0 calc(100vw*(7/428)) calc(100vw*(7/428)) rgb(1 1 1 / 10%);`
      : `height: 0; overflow: hidden;`;
  }}
    ${({ fixed }) => {
    return fixed ? `height: auto; overflow: visible;` : ``;
  }}
  }
`;
const SubTitleWrap = styled.div`
  padding-top: 80px;
  margin-bottom: 40px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (80 / 1320));
    margin-bottom: calc(100vw * (40 / 1320));
  }
`;
const SubTitle = styled.h2`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (30 / 1320));
  }
`;
//menu
const MenuListWrap = styled.div`
  width: 100%;
  padding: 35px 0;
  background: #000;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (35 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
    background: #fff;
  }
`;
const MenuListBox = styled.div`
  width: 1300px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
  }

  @media screen and (max-width: 930px) {
    flex-wrap: wrap;
    width: calc(100vw * (388 / 428));
  }
`;
const MenusList = styled.div`
  width: calc(100% / 5 - 92px);

  @media screen and (max-width: 1320px) {
    width: calc(100% / 5 - calc(100vw * (92 / 1320)));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ListTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  padding-bottom: 13px;
  border-bottom: 1px solid #707070;
  margin-bottom: 22px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
    padding-bottom: calc(100vw * (13 / 1320));
    border-bottom: calc(100vw * (1 / 1320)) solid #707070;
    margin-bottom: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    color: #000000;
    padding-bottom: calc(100vw * (13 / 428));
    border-bottom: calc(100vw * (1 / 428)) solid #707070;
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const List = styled.p`
  cursor: pointer;
  font-size: 16px;
  padding: 2px 0;
  margin: 8px 0;
  & a {
    color: #ffffff;
  }

  @media screen and (max-width: 930px) {
    & a {
      color: #000000;
      ${({ MenuOn }) => {
    return MenuOn ? `font-weight: bold;` : `font-weight: normal;`;
  }}
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (2 / 1320)) 0;
    margin: calc(100vw * (8 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    padding: calc(100vw * (2 / 428)) 0;
    margin: calc(100vw * (8 / 428)) 0;
    &:last-child {
      margin-bottom: calc(100vw * (26 / 428));
    }
  }
`;
const MbBtn = styled.div`
  cursor: pointer;

  @media screen and (max-width: 930px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: calc(100vw * (15 / 428));
  }
`;
const MbBtnClosed = styled(MbBtn)`
  @media screen and (max-width: 930px) {
    justify-content: center;
    padding: 0;
  }
`;
const MbBtnImg = styled.img`
  @media screen and (max-width: 930px) {
    display: block;
    width: calc(100vw * (24 / 428));
  }
`;
