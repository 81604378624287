import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserDataAction } from "../../../store/actionCreators";
import serverController from "../../../server/serverController";

import {
  Container,
  Layout,
  AuthIntroMessage,
  Form,
  Label,
  LabelName,
  EmailInput,
  PasswordInput,
} from "../auth.elements";
import {
  LoginBox,
  LoginIntroMessage,
  LoginSub,
  IdSaveLabel,
  IdSaveCheckbox,
  LoginBottom,
  LoginBtn,
  SignUpLinkBox,
  SignUpLinkButton,
} from "./Login.elements";

const Login = () => {
  const history = useHistory();
  const { type_id } = useParams();
  const location = useLocation();
  const [idValue, setIdValue] = useState("");
  const [pwValue, setPwValue] = useState("");

  // 로그인 클릭
  const onClickLogin = async () => {
    if (!idValue || !pwValue) {
      alert("아이디 혹은 비밀번호를 입력해주세요.");
      return;
    }
    await serverController.connectFetchController(
      `login?mem_email=${idValue}&mem_password=${pwValue}&type=${type_id}`,
      "POST",
      null,
      (res) => {
        if (res?.result === true) {
          getUserData();
          if (location.search.includes('?returnTo=')) {
            const queryString = location.search.substring(location.search.indexOf('?') + 1);
            const parameterValue = queryString.split('=')[1];
            history.push(`${parameterValue}`);
            return;
          }
          history.push("/");
        } else {
          alert("이메일이나 비밀번호가 일치하지 않습니다.");
        }
      },
      (err) => console.error(err)
    );
  };

  const getUserData = async () => {
    await serverController.connectFetchController(
      `mypage`,
      "POST",
      null,
      (res) => {
        console.log(res, "유저정보(mypage) response");
        if (res?.result === 1) {
          const list = res?.list;
          const userObj = {
            mem_id: list?.mem_id,
            mem_email: list?.mem_email,
            mem_password: list?.mem_password,
            mem_name: list?.mem_name,
            mem_phone: list?.mem_phone,
            mem_alarm: list?.mem_alarm,
            mem_role: list?.mem_role,
            mem_gender: list?.mem_gender,
            mem_style: list?.mem_style,
            mem_fit: list?.mem_fit,
            mem_color: list?.mem_color,
            mem_brand_name: list?.mem_brand_name,
            mem_business_registration: list?.mem_business_registration,
            mem_rank: list?.mem_rank,
            mem_belong: list?.mem_belong,
            mem_celebrity_list: list?.mem_celebrity_list,
            mem_activated: list?.mem_activated,
            mem_register_date: list?.mem_register_date,
          };
          UserDataAction.logInRequest({
            userInfo: userObj,
            isLogged: true,
          });
        }
      },
      (err) => console.error(err)
    );
  };

  const returnPosition = () => {
    if (type_id === "1") {
      return "";
    } else if (type_id === "2") {
      return "BRAND";
    } else if (type_id === "3") {
      return "STYLIST";
    }
  };

  const onHanldeSubmit = (e) => {
    e.preventDefault();
    onClickLogin();
  };

  const findPageClick = (i) => {
    if (i == 0) {
      history.push("/find/id");
    }
    if (i == 1) {
      history.push("/find/password");
    }
  };

  return (
    <Containers>
      <Layout>
        <LoginBox>
          <AuthIntroMessage>
            <h2>{returnPosition()} Login</h2>
            <p>WALA에 로그인 후 다양한 서비스를 이용하실 수 있습니다.</p>
          </AuthIntroMessage>

          <Form>
            <Label>
              <LabelName>이메일</LabelName>
              <EmailInput
                value={idValue}
                onChange={(e) => setIdValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onClickLogin();
                  }
                }}
              />
            </Label>
            <Label>
              <LabelName>비밀번호</LabelName>
              <PasswordInput
                value={pwValue}
                onChange={(e) => setPwValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onClickLogin();
                  }
                }}
              />
            </Label>

            <LoginSub>
              <ul>
                <li
                  onClick={() => {
                    findPageClick(0);
                  }}
                >
                  아이디 찾기
                </li>
                <li
                  onClick={() => {
                    findPageClick(1);
                  }}
                >
                  비밀번호 찾기
                </li>
              </ul>
            </LoginSub>

            <LoginBottom onClick={onClickLogin}>
              <LoginBtn>로그인</LoginBtn>
            </LoginBottom>
          </Form>
        </LoginBox>

        <SignUpLinkBox>
          <p>아직 WALA 회원이 아니신가요?</p>
          <SignUpLinkButton onClick={() => history.push("/signup")}>
            회원가입
          </SignUpLinkButton>
        </SignUpLinkBox>
      </Layout>
    </Containers>
  );
};
const Containers = styled(Container)`
  @media screen and (max-width: 1024px) {
    margin-bottom: calc(100vw * (30 / 428));
  }
`;

export default Login;
