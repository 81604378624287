import React from "react";
import styled from "styled-components";
import { PC, Mobile } from "../../components/common/mediaStlye";
import Header from "../../components/common/header/Header";
import Login from "../../components/auth/login/Login";
import Footer from "../../components/common/footer/Footer";
import FooterMenu from "../../components/common/footer/FooterMenu";

const LoginPage = () => {
  return (
    <Container>
      <Header />
      <Login />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
    </Container>
  );
};
const Container = styled.div`
  @media screen and (max-width: 1024px) {
    padding-bottom: calc(100vw * (68 / 428));
  }
`;

export default LoginPage;
