import React from "react";
import styled from "styled-components";
import { Mobile, PC } from "../../MediaQuery";
import Banner from "../../img/banner/banner_pc.svg";
import BannerMb from "../../img/banner/banner_mb.svg";

export default function SubBanner() {
  return (
    <Container>
      <SubBannerWrap>
        <PC>
          <BannerImg src={Banner} alt="Banner" />
        </PC>
        <Mobile>
          <BannerImg src={BannerMb} alt="Banner" />
        </Mobile>
      </SubBannerWrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 250px;
  overflow: hidden;
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (250 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (160 / 428));
  }
`;
const SubBannerWrap = styled.div`
  height: 100%;
`;
const BannerImg = styled.img`
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
