import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import qs from "qs";
import { useSelector } from "react-redux";
import styled from "styled-components";
import serverController from "../../server/serverController";
import { imgURL } from "../../server/appInfoController";
import BrandItemProductList from "../main/BrandItemProductList";
import SelectBoxArrow from "../../img/icon/icon_selectBox_arrow.svg";
import IconLikeButton from "../../img/brand/icon_brand_likeButton.svg";
import IconLikeButtonOn from "../../img/brand/icon_brand_likeButton_on.svg";

import NewPagination from "../common/pagination/NewPagination";

export default function BrandDetail({ setLoginPop }) {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const { isLogged } = useSelector((state) => state.userData);
  const { brandId } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(query.page ?? 1);
  const [pageData, setPageData] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [brandInfo, setBrandInfo] = useState([]);
  const [isOption, setIsOption] = useState(false);
  const [isLikeState, setIsLikeState] = useState(false);
  const [filterIndex, setFilterIndex] = useState(0);
  const [optionList, setOptionList] = useState("최신순");
  const selectData = ["최신순", "오래된순"];

  const getBrandProducts = () => {
    try {
      serverController.connectFetchController(
        `products?bc_id=${brandId}&filter=${filterIndex}&page=${query.page}&limit=20`,
        "GET",
        null,
        (res) => {
          if (res?.result !== 1) return;
          if (brandsList.length === 0) {
            setBrandInfo(res.brand);
          }
          setPageData(res.pagination);
          setBrandsList(res.list);
        },
        (err) => console.error(err)
      );
    } catch (e) {
      console.error(e);
    }
  };

  const postLikeBrand = () => {
    serverController.connectFetchController(
      `brand/like/${brandId}`,
      "POST",
      null,
      (res) => {
        if (res.result === 1) {
          setIsLikeState(!isLikeState);
        }
      },
      (err) => console.error(err)
    );
  };

  const onClickOptionBox = (optionData, index) => {
    if (filterIndex === index) return setIsOption(false);
    setBrandsList([]);
    setIsOption(false);
    setOptionList(optionData);
    setFilterIndex(index);
  };

  useEffect(() => {
    getBrandProducts();
  }, [filterIndex]);

  useEffect(() => {
    setIsLikeState(brandInfo?.like === 1);
  }, [brandInfo]);

  useEffect(() => {
    history.push(`/store/brand/detail/${brandId}?page=${page}`);
  }, [page]);

  useEffect(() => {
    getBrandProducts();
  }, [query.page]);

  return (
    <Container>
      <BrandDetailBannerBox>
        <BrandDetailBannerImgBox background></BrandDetailBannerImgBox>
        {/* {brandInfo?.bcBanner ? (
          <BrandDetailBannerImgBox>
            <img
              src={`${imgURL}${brandInfo?.bcBanner}`}
              alt={brandInfo?.bcBanner ? "banner-pc" : "default-img-pc"}
            />
          </BrandDetailBannerImgBox>
        ) : (
          <BrandDetailBannerImgBox background></BrandDetailBannerImgBox>
        )} */}

        <BannerContentBox>
          <BannerLeftSection>
            <BannerLogoBox>
              {brandInfo?.bcLogo && <img src={`${imgURL}${brandInfo?.bcLogo}`} alt="brand-logo" />}
              {!brandInfo?.bcLogo && <div></div>}
            </BannerLogoBox>
            <BannerTitleBox>
              <BannerTitle>{brandInfo?.bcTitle}</BannerTitle>
              <BannerText>{brandInfo?.bcTitleKor}</BannerText>
            </BannerTitleBox>
          </BannerLeftSection>

          <AllBrandLikeBox
            onClick={() => {
              if (!isLogged) return setLoginPop(true);
              postLikeBrand();
            }}
          >
            <AllBrandLikeImg
              src={isLikeState ? IconLikeButtonOn : IconLikeButton}
              alt={isLikeState ? "like-button-on" : "like-button-off"}
            />
          </AllBrandLikeBox>
        </BannerContentBox>
      </BrandDetailBannerBox>
      <BrandDetailWrap>
        <BrandDetailSelectBox>
          <SelectBoxWrap tabIndex="0" onBlur={() => setIsOption(false)}>
            <SelectedButton activeBorder={isOption && true} onClick={() => setIsOption(!isOption)}>
              <SelectedButtonText active={isOption && true}>{optionList}</SelectedButtonText>
              <SelectedArrowImg
                onClick={() => setIsOption(!isOption)}
                active={isOption && true}
                src={SelectBoxArrow}
                alt="selectArrow"
              />
            </SelectedButton>
            {isOption ? (
              <SelectListBox>
                {selectData.map((optionData, index) => {
                  return (
                    <SelectBtn
                      key={optionData}
                      onClick={() => onClickOptionBox(optionData, index === 1 ? 4 : index)}
                    >
                      {optionData}
                    </SelectBtn>
                  );
                })}
              </SelectListBox>
            ) : null}
          </SelectBoxWrap>
        </BrandDetailSelectBox>
        {brandsList.length > 0 ? (
          <BrandDetailListBox>
            {brandsList.map((brand, idx) => {
              return (
                <BrandItemProductList
                  key={`${brand.prd_id}-brandProduct-${idx}`}
                  value={brand}
                  setLoginPop={setLoginPop}
                />
              );
            })}
          </BrandDetailListBox>
        ) : (
          <InfoText>리스트가 없습니다.</InfoText>
        )}
      </BrandDetailWrap>
      {brandsList.length > 0 && <NewPagination page={page} setPage={setPage} pageData={pageData} />}
    </Container>
  );
}
const Container = styled.div`
  padding-bottom: 460px;
  position: relative;
  padding-top: 130px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (130 / 1320)) 0 calc(100vw * (460 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: 0;
    padding-bottom: calc(100vw * (146 / 428));
  }
`;
const BrandDetailWrap = styled.div`
  width: 1240px;
  margin: 0 auto;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const BrandDetailBannerBox = styled.div`
  position: relative;
`;
const BrandDetailBannerImgBox = styled.div`
  position: relative;
  width: 100%;
  height: 380px;
  overflow: hidden;
  ${({ background }) => background && `background-color : #000;`}

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (380 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (220 / 428));
  }
`;

const BannerContentBox = styled.div`
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 1240px;

  @media screen and (max-width: 1320px) {
    bottom: calc(100vw * (30 / 1320));
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    bottom: calc(100vw * (30 / 428));
    width: calc(100vw * (378 / 428));
  }
`;
const BannerLeftSection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: 1320px) {
    gap: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    gap: calc(100vw * (15 / 428));
  }
`;
const BannerLogoBox = styled.div`
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  height: 75px;
  width: 75px;
  > div {
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
  > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (75 / 1320));
    width: calc(100vw * (75 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (60 / 428));
    width: calc(100vw * (60 / 428));
  }
`;
const BannerTitleBox = styled.div`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (265 / 428));
  }
`;
const BannerTitle = styled.p`
  color: #fff;
  font-weight: 500;
  font-size: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const BannerText = styled(BannerTitle)`
  font-size: 42px;
  margin-top: 5px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (42 / 1320));
    margin-top: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (30 / 428));
    margin-top: calc(100vw * (5 / 428));
  }
`;

const AllBrandLikeBox = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (40 / 1320));
    height: calc(100vw * (40 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (36 / 428));
    height: calc(100vw * (36 / 428));
  }
`;
const AllBrandLikeImg = styled.img`
  width: 100%;
  height: 100%;
`;

const BrandDetailSelectBox = styled.div`
  width: 145px;
  margin: 60px 0 20px auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (145 / 1320));
    margin: calc(100vw * (60 / 1320)) 0 calc(100vw * (20 / 1320)) auto;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (145 / 428));
    margin: calc(100vw * (20 / 428)) 0 calc(100vw * (20 / 428)) auto;
  }
`;
const SelectBoxWrap = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 100%;
`;
const SelectedButton = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  transition: ease 0.3s all;

  ${({ activeBorder }) => {
    return (
      activeBorder &&
      `
           border-color:#000;
        `
    );
  }}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (40 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (40 / 428));
    padding: 0 calc(100vw * (15 / 428));
  }
`;

const SelectedButtonText = styled.p`
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #333333;
  transition: ease 0.3s all;

  ${({ active }) => {
    return (
      active &&
      `
        color: #000;
    `
    );
  }}
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 900px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

const SelectListBox = styled.ul`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  z-index: 5;
  width: 100%;
  max-height: 304px;
  background-color: #fff;
  border: 1px solid #dddddd;

  @media screen and (max-width: 1320px) {
    top: calc(100% + 100vw * (8 / 1320));
    max-height: calc(100vw * (304 / 1320));
  }

  @media screen and (max-width: 900px) {
    top: calc(100% + 100vw * (8 / 428));
    max-height: calc(100vw * (304 / 428));
  }
`;

const SelectBtn = styled.li`
  width: 100%;
  color: #888;
  font-size: 16px;
  text-align: center;
  padding: 8px 0;
  cursor: pointer;

  &:hover {
    background: #f7f7f7;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (8 / 1320)) 0;
  }

  @media screen and (max-width: 900px) {
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (8 / 428)) 0;
  }
`;
const BrandDetailListBox = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 900px) {
    justify-content: space-between;
  }
`;
const SelectedArrowImg = styled.img`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  transition: ease 0.3s all;
  ${({ active }) => {
    return (
      active &&
      `
        transform : translateY(-50%) rotate(180deg);
    `
    );
  }}

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (10 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 900px) {
    right: calc(100vw * (10 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const InfoText = styled.p`
  @media screen and (max-width: 900px) {
    text-align: center;
    color: #969696;
    padding: calc(100vh * (190 / 925)) 0 0;
    font-size: calc(100vw * (16 / 428));
  }
`;
