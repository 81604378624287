import React from "react";
import RankProduct from "../../store_src/component/product/RankProduct";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import useRequestQuery from "../../hooks/useRequestQuery";
import { Get } from "../../api";
import { useParams } from "react-router-dom";
import { queryKeys } from "../../constants/queryKeys";
import SkeletonUI from "newComponents/common/SkeletonUI";
import { crypto } from "utils";

export default function StoreCategoryBestContainer() {
  const { main_cat, sub_cat } = useParams();

  const requestData = {
    requestAPI: Get.getCategoryBestList,
    requestData: { prd_main_cat: crypto.decryptionBASE64(main_cat), prd_sub_cat: crypto.decryptionBASE64(sub_cat) },
    queryKey: [queryKeys.STORE_CATEGORY_BEST, main_cat, sub_cat],
    options: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      keepPreviousData: true,
    },
  };

  const { data, isLoading, isSuccess, isError } = useRequestQuery(requestData);
  console.log("베스트 아이템 리스트 API : ", data);

  if (isError) {
    return <div>잠시 후 다시시도해주세요</div>;
  }

  return (
    <>
      <RankProduct list={data && data.list} isLoading={isLoading} isSuccess={isSuccess} />
    </>
  );
}
