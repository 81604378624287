import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PC, Mobile } from '../mediaStlye';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import IconLogoW from "../../../store_src/img/icon/wala_logo_w.svg";

import IconArrow from '../../../images/icon_back_arrow_wh.svg';


export default function VideoHeader() {
    const history = useHistory();
    const goBack = () => {
        history.goBack();
    };

    return (
        <>
            <Container>
                <Back src={IconArrow} alt="뒤로가기" onClick={goBack} />
                <Logo to={`/`}>
                    <LogoImg src={IconLogoW} alt="logo" />
                </Logo>
            </Container>
        </>

    )
}

const Container = styled.div`
    position: fixed; top: 0; left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: calc(100vw * (25 / 428)) 0 calc(100vw * (20 / 428));
    z-index: 100;
    background: rgba(1,1,1,0.5);
`
const Logo = styled(Link)`
    width: calc(100vw * (124 / 428));
    font-size: calc(100vw * (24 / 428));
    color: #fff;
    font-weight: 900;
`
const Back = styled.img`
    position: absolute; top: calc(100vw * (22 / 428)); left: calc(100vw * (25 / 428));
    display: block;
    width: calc(100vw * (12 / 428));
    cursor: pointer;
`


const LogoImg = styled.img`
  display: block;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  
  @media screen and (max-width: 1320px) {
    width: 100%;
  }

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;