import { useEffect, useState } from "react";
import styled from "styled-components";
import qs from "qs";
import { useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";

import serverController from "../../server/serverController";

import Header from "../../component/common/Header";
import MyPageHeader from "./MyPageHeader";
import MyNotice from "../../component/mypage/notice/MyNotice";
import FullLoading from "../../component/common/modal/FullLoading";

import MyPageLayOutMenu from "../../component/mypage/MyPageLayOutMenu";
import MyPageLayOutName from "../../component/mypage/MyPageLayOutName";
import MyPageLayOutInfo from "../../component/mypage/MyPageLayOutInfo";
import NewPagination from "../../component/common/pagination/NewPagination";

export default function MyNoticePage() {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const history = useHistory();
  const [noticeList, setNoticeList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(query.page ?? 1);
  const [pageData, setPageData] = useState([]);

  // get notice list
  const getNoticeList = () => {
    serverController.connectFetchController(`noticeList?page=${page}`, "GET", null, (res) => {
      if (res.result === 1) {
        setPageData(res.pagination);
        setNoticeList(res.list);
        setLoading(false);
      } else {
        alert("알 수 없는 오류입니다. 관리자에게 문의 바랍니다.");
        history.push(`/store`);
      }
    });
  };

  useEffect(() => {
    history.push(`/store/mypage/notice?page=${page}`);
  }, [page]);

  useEffect(() => {
    getNoticeList();
  }, [query.page]);

  return (
    <>
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader
          title={"공지사항"}
          prevBtn
          prevFunc={() => {
            history.push(`/store/mypage`);
          }}
        />
      </Mobile>
      {loading && <FullLoading />}

      <MyNoticeArea>
        {/* page */}
        <PC>
          <MyPageLayOutName />
        </PC>

        <FlexLayOut>
          <PC>
            <MyPageLayOutMenu />
          </PC>

          {/* content */}
          <ContentSection>
            <PC>
              <MyPageLayOutInfo />
            </PC>

            <MyNoticeSection>
              <MyNotice noticeList={noticeList} />
              {noticeList.length > 0 && (
                <NewPagination page={page} setPage={setPage} pageData={pageData} />
              )}
            </MyNoticeSection>
          </ContentSection>
        </FlexLayOut>
      </MyNoticeArea>
    </>
  );
}

const MyNoticeArea = styled.div`
  width: 100%;
  padding: 180px 0 200px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const MyNoticeSection = styled.div`
  padding-top: 60px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (60 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0 0 calc(100vw * (40 / 428));
  }
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
