import React from 'react';
import Header from '../components/common/header/Header';
import FashionNewsContentsBlog from '../components/news/FashionNewsContentsBlog';
import Footer from '../components/common/footer/Footer';

const FashionNewsBlogPage = () => {
    return (
        <div>
            <Header />
            <FashionNewsContentsBlog />
            <Footer />
        </div>
    )
}

export default FashionNewsBlogPage;

