import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import serverController from "../../server/serverController";
import { Mobile, PC } from "../../MediaQuery";

import { Wrapper, Section, SectionHeadings, MoreButton } from "./Main.elements";
import {
  ItemWrapper,
  ItemLeft,
  ItemRight,
  ItemRtop,
  ItemRbot,
  Item,
  ItemImg,
  ItemDesc,
  ItemDoubleWrapper,
} from "./MainCeleb.elements";
import CelebItem from "./CelebItem";

const MainCeleb = () => {
  const { isLogged } = useSelector((state) => state.userData);
  const [list, setList] = useState([]);
  const getData = async () => {
    await serverController.connectFetchController(
      `main/contentslist/${1}`,
      "GET",
      null,
      (res) => {
        console.log(res, "메인 celeb  response");
        if (res?.result === 1) {
          setList(res?.list);
          console.log(res?.list.slice(0, 2));
        }
      },
      (err) => console.error(err)
    );
  };

  useEffect(() => {
    getData();
  }, [isLogged]);

  return (
    <>
      <PC>
        <Section>
          <Wrapper>
            <SectionHeadings>Celeb Fashion For You</SectionHeadings>

            <ItemWrapper>
              <ItemLeft>
                {list.slice(0, 1).map((v, i) => {
                  return (
                    <CelebItem key={v?.contents_id} value={v} rtop={false} />
                  );
                })}
              </ItemLeft>

              <ItemRight>
                <ItemRtop>
                  {list.slice(1, 4).map((v, i) => {
                    return (
                      <CelebItem key={v?.contents_id} value={v} rtop={true} />
                    );
                  })}
                  {/* <CelebItem rtop={true} src={mainCeleb2}/>
                            <CelebItem rtop={true} src={mainCeleb3}/>
                            <CelebItem rtop={true} src={mainCeleb4}/> */}
                </ItemRtop>
                <ItemRbot>
                  {list.slice(4, 6).map((v, i) => {
                    return (
                      <CelebItem key={v?.contents_id} value={v} rtop={false} />
                    );
                  })}
                  {/* <CelebItem/> */}
                  {/* <CelebItem/> */}
                </ItemRbot>
              </ItemRight>
            </ItemWrapper>

            <MoreButton>
              <Link to="/category/1">See More</Link>
            </MoreButton>
          </Wrapper>
        </Section>
      </PC>

      <Mobile>
        <Section>
          <Wrapper>
            <ItemWrapper>
              <Item mobileMain>
                {list.slice(0, 1).map((v, i) => {
                  return (
                    <CelebItem key={v?.contents_id} value={v} rtop={false} />
                  );
                })}
                {/* <ItemImg>
                            <img src={mainCeleb1} />
                            </ItemImg>
                            <ItemDesc className="item_head">
                            <em>Drama</em>
                            <h4>펜트하우스3 9화 이지아 패션 </h4>
                            <p>Iro Mini Dress와 제이에스티나귀걸이
                            지안비토로시 구두로 스타일링 해주었습니다.</p>            
                        </ItemDesc> */}
              </Item>

              <ItemDoubleWrapper>
                {list.slice(1, 3).map((v, i) => {
                  return (
                    <CelebItem
                      sub
                      key={v?.contents_id}
                      value={v}
                      rtop={false}
                    />
                  );
                })}
                {/* <Item>
                            <ItemImg className="item_img">
                                <img src={mainCeleb2} />
                            </ItemImg>
                            <ItemDesc className="item_head">
                                <em>Drama</em>
                                <h4>펜트하우스3 9화 이지아 패션 </h4>                                         
                            </ItemDesc>
                        </Item>
                        <Item>
                            <ItemImg className="item_img">
                                <img src={mainCeleb3} />
                            </ItemImg>
                            <ItemDesc className="item_head">
                                <em>Drama</em>
                                <h4>펜트하우스3 9화 이지아 패션 </h4>                                         
                            </ItemDesc>
                        </Item> */}
              </ItemDoubleWrapper>
            </ItemWrapper>

            <MoreButton>
              <Link to="/category/1">See More</Link>
            </MoreButton>
          </Wrapper>
        </Section>
      </Mobile>
    </>
  );
};

export default MainCeleb;
