import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import api from '../../server/serverController';
import { imgURL } from '../../server/appInfoContoller';
import { DateToText, timeText } from '../common/commonUse';
import styled from 'styled-components';

import {
    Container,
    Layout,
    DetailHeadings,
    DetailContent,
    DetailImg,
    DetailText,
} from './FashionNewsDetails.elements';

import newsImg from '../../images/category/news-img.png';

const FashionNewsDetails = () => {
    const { newsId } = useParams();

    const [data, setData] = useState(null)
    const getNews = async () => {
        await api.get(`news/${newsId}`, null,
            (res) => {
                console.log(res, "뉴스 상세 조회 res")
                if (res?.result === 1) {
                    setData(res?.news)
                }
            }
        );

    }

    useEffect(() => {
        getNews()
    }, [newsId])

    return (
        <Container>
            <NewsLayout>
                <DetailHeadings>
                    <h2>{data?.news_title}</h2>
                    {/* <span>2021.08.29 09:54</span> */}
                    <span>{DateToText(data?.news_create_date, ".")} {timeText(data?.news_modify_date, ":")}</span>
                </DetailHeadings>

                <DetailContent>
                    <DetailImg>
                        <img src={imgURL + data?.news_img} alt="제니사진" />
                    </DetailImg>
                    <DetailText>
                        {ReactHtmlParser(data?.news_content)}
                    </DetailText>
                </DetailContent>

            </NewsLayout>
        </Container>
    )
}

export default FashionNewsDetails

const NewsLayout = styled(Layout)`
    @media screen and (max-width: 1024px) {
        margin-top: 50px;
    }
`