import React from 'react';
import faker from 'faker';
import { Mobile, PC } from '../common/mediaStlye';
import {
    Container,
    Layout,
    Contents,
    ContentHeadings,
    ContentLarge,
    Item,
    BlogIcon,
    ContentOthers,
    MediaItem,
    MediaItemImg,
    MediaItemDesc,
    MediaOthersItem,
    ExtraThumb,
    ExtraDesc,
} from './FashionNewsContents.element';
import blogIcon from '../../images/news-blog-icon.png';

const MediaContentsBox = () => {
    return (
        <Container grey>
            <Layout>
                <ContentHeadings>
                    <em>WALA PICK!</em>
                    <h2>Media Contents </h2>
                </ContentHeadings>

                <Contents>
                    <ContentLarge>
                        <PC>
                        {
                            Array(2).fill().map((item, i) =>{
                                return (
                                    <MediaItem key={i}>
                                        <MediaItemImg>
                                            <img src={faker.image.image()} />
                                        </MediaItemImg>
                                        <MediaItemDesc className="item_head">       
                                            <em>한국일보</em>          
                                            <h4>펜트하우스3 9화 이지아 패션 </h4>
                                            <p>와인색 수트로 세련된 패션을 완성한 유진!
그리고 펜트하우스 17회 유진 패션을 더욱 고급스럽게 완성해 준 아이템은
바로 유진 귀걸이였어요 +_+</p>           
                                        </MediaItemDesc>                                        
                                    </MediaItem>
                                )
                            })
                        }
                        </PC>
                        <Mobile>
                            <MediaItem>
                                <MediaItemImg>
                                    <img src={faker.image.image()} />
                                </MediaItemImg>
                                <MediaItemDesc className="item_head">       
                                    <em>한국일보</em>          
                                    <h4>펜트하우스3 9화 이지아 패션 </h4>
                                    <p>
                                        와인색 수트로 세련된 패션을 완성한 유진!
                                        완성해 준 아이템은 바로 유진 귀걸이였어요 +_+
                                    </p>           
                                </MediaItemDesc>                                        
                            </MediaItem>
                        </Mobile>
                    </ContentLarge>

                    <ContentOthers>
                       <PC>
                       {
                            Array(4).fill().map((item, i) => {
                                return (
                                    <MediaOthersItem>
                                        <ExtraThumb>
                                            <img src={faker.image.image()} />
                                        </ExtraThumb>

                                        <ExtraDesc>
                                            <em>한국일보</em>
                                            <h3>펜트하우스2 2회 유진 귀걸이 & 반지 디디에두보, 우아한 패션 룩</h3>
                                        </ExtraDesc>
                                    </MediaOthersItem>
                                )
                            })
                        }
                       </PC>
                       <Mobile>
                       {
                            Array(2).fill().map((item, i) => {
                                return (
                                    <MediaOthersItem>
                                        <ExtraThumb>
                                            <img src={faker.image.image()} />
                                        </ExtraThumb>

                                        <ExtraDesc>
                                            <em>한국일보</em>
                                            <h3>펜트하우스2 2회 유진 귀걸이 & 반지 디디에두보, 우아한 패션 룩</h3>
                                        </ExtraDesc>
                                    </MediaOthersItem>
                                )
                            })
                        }
                       </Mobile>
                    </ContentOthers>
                </Contents>
            </Layout>
        </Container>
    )
}

export default MediaContentsBox
