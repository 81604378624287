//react
import { useState } from "react";
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery"

//css
import styled from "styled-components"

export default function Mypagefilter({ tab, setTab}) {

  return (
        <Container>
            <TabBox>
                <Tab><TabBtn BtnOn={tab === "month"} onClick={() => setTab(prev => prev === "month" ? null : "month")}>1개월전</TabBtn></Tab>
                <Tab><TabBtn BtnOn={tab === "3month"} onClick={() => setTab(prev => prev === "3month" ? null : "3month")}>3개월전</TabBtn></Tab>
                <Tab><TabBtn BtnOn={tab === "6month"} onClick={() => setTab(prev => prev === "6month" ? null : "6month")}>6개월전</TabBtn></Tab>
                <Tab><TabBtn BtnOn={tab === "year"} onClick={() => setTab(prev => prev === "year" ? null : "year")}>1년전</TabBtn></Tab>
            </TabBox>
        </Container>
  );
}
const Container = styled.div`
    width: 1300px;
    margin: 0 auto 30px;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(1250/1320));
        margin: 0 auto calc(100vw*(30/1320));
    }

    @media screen and (max-width: 930px) {
        width: calc(100vw*(388/428));
        margin: 0 auto calc(100vw*(20/428));
    }
`
const TabBox = styled.div`
    display: flex;
    align-items: center;
`
const Tab = styled.div`
    margin-right: 20px;

    &:last-child{
        margin-right:0;
    }

    @media screen and (max-width: 1320px) {
        margin-right: calc(100vw*(20/1320));
    }

    @media screen and (max-width: 930px) {
        margin-right: calc(100vw*(12/428));
    }
`
const TabBtn = styled.p`
    cursor: pointer;
    width: 100px;
    text-align: center;
    padding: 10px 0;
    border-radius: 11px;
    border: 1px solid #000000;
    font-size: 18px;
    color: #191919;
    font-weight: 500;
    ${({BtnOn})=>{
        return BtnOn ?
        `border-color: #222222;`
        :
        ``
    }}

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(100/1320));
        padding: calc(100vw*(10/1320)) 0;
        border-radius: calc(100vw*(11/1320));
        border: calc(100vw*(1/1320)) solid #e4e4e4;
        font-size: calc(100vw*(18/1320));
    }

    @media screen and (max-width: 930px) {
        width: auto;
        padding: calc(100vw*(10/428)) calc(100vw*(14/428));
        border-radius: calc(100vw*(11/428));
        border: calc(100vw*(1/428)) solid #e4e4e4;
        font-size: calc(100vw*(16/428));
    }
`