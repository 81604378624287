//react
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";

//css
import styled from "styled-components";

// img
import IconInquiryType1 from "../../img/icon/icon_product_inquiry_type1.png";
import IconInquiryType2 from "../../img/icon/icon_product_inquiry_type2.png";
import IconInquiryType3 from "../../img/icon/icon_product_inquiry_type3.png";
import IconLock from "../../img/icon/icon_secret_blue.png";
import IconRe from "../../img/icon/icon_recomment.png";

//page
import PasswordModal from "../common/modal/PasswordModal";
import Pagination from "../common/pagination/Pagination";
import Search from "../common/pagination/Search";
import InquiryType from "./InquiryType";
import InquiryLayout from "./InquiryLayout";

export default function ProductInquiryList() {
  const [isModal, setIsModal] = useState(false);

  const list = [
    {
      secret_type: 2,
      division: "상품문의",
      subject: "상품 문의합니다",
      user_name: "홍길동",
      comment_type: 1,
      view: "9999999999",
      date: "2021-03-26",
    },
    {
      secret_type: 1,
      division: "신상품 요청",
      subject: "포먼트 품평단 당첨자 발표",
      user_name: "홍길동",
      comment_type: 1,
      view: "9999999999",
      date: "2021-03-26",
    },
  ];
  const listComment = [
    {
      secret_type: 1,
      division: "신상품 요청",
      subject: "포먼트 품평단 당첨자 발표",
      comment_type: 1,
      view: "9999999999",
      date: "2021-03-26",
    },
  ];

  return (
    <Container>
      <ProductInquiryListWrap>
        <TitleWrap>
          <Title>상품문의</Title>
          <SubTitle>
            이용 중 불편 사항은 <SubText>1:1 문의</SubText> 게시판을 이용해
            주세요.
          </SubTitle>
          <SubTitle>
            이 곳은 상품에 대한 궁금한 점 또는 요청사항을 위한 커뮤니티입니다.
          </SubTitle>
        </TitleWrap>

        <InquiryType />

        <InquiryLayout
          type={1}
          list={list}
          listComment={listComment}
          setIsModal={setIsModal}
        />

        <WriteWrap>
          <Link to={`/store/productInquiry/write`}>
            <WriteBtn>글쓰기</WriteBtn>
          </Link>
        </WriteWrap>
        {/* <Pagination/> */}
        <Search />
      </ProductInquiryListWrap>

      {isModal && <PasswordModal setIsModal={setIsModal} />}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const ProductInquiryListWrap = styled.div`
  width: 1300px;
  margin: 0 auto;
  padding-bottom: 200px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
    padding-bottom: calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    padding-bottom: calc(100vw * (100 / 428));
  }
`;
const TitleWrap = styled.div`
  width: 100%;
  margin-bottom: 25px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (25 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (20 / 428));
    margin-bottom: calc(100vw * (25 / 428));
    border-bottom: calc(100vw * (1 / 428)) solid #000;
  }
`;
const Title = styled.h2`
  font-size: 24px;
  color: #000;
  font-weight: bold;
  margin-bottom: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
    margin-bottom: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const SubTitle = styled.p`
  font-size: 14px;
  color: #969696;
  line-height: 1.4;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const SubText = styled.span`
  font-size: 14px;
  color: #969696;
  line-height: 1.4;
  font-weight: bold;
  border-bottom: 1px solid #969696;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    border-bottom: calc(100vw * (1 / 1320)) solid #969696;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    border-bottom: calc(100vw * (1 / 428)) solid #969696;
  }
`;
const WriteWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const WriteBtn = styled.p`
  width: 100px;
  font-size: 15px;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 8px 0;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #000;
    color: #fff;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    font-size: calc(100vw * (15 / 1320));
    border: calc(100vw * (1 / 1320)) solid #000;
    border-radius: calc(100vw * (10 / 1320));
    padding: calc(100vw * (8 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    font-size: calc(100vw * (15 / 428));
    border: calc(100vw * (1 / 428)) solid #000;
    border-radius: calc(100vw * (10 / 428));
    padding: calc(100vw * (8 / 428)) 0;
  }
`;
