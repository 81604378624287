//react
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

// controller
import { imgURL } from "../../../server/appInfoController";

// img
import BannerArrow from "../../../img/icon/icon_bannerArrow.svg";
import IconCancel from "../../../img/icon/icon_myhaeder_cancle.svg";

export default function ReviewDetailModal({
  setPhotoModal,
  reviewData,
  index = 0,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const SlideChange = (e) => {
    setCurrentIndex(e.activeIndex);
  };

  useEffect(() => {
    if (index !== 0) {
      setCurrentIndex(index);
    }
  }, [index]);

  return (
    <Container>
      <PC>
        <Background onClick={()=>{setPhotoModal(false)}}/>
      </PC>

      <Content>
        {/* header */}
        <HeaderSection>
          <HeaderInner>
            <p>리뷰</p>
            {/* 삭제 버튼 */}
            <DeleteButton onClick={() => setPhotoModal(false)}>
              <img src={IconCancel} alt="deleteButton" />
            </DeleteButton>
          </HeaderInner>
        </HeaderSection>

        <ReviewWrap>
          {reviewData[0]?.rev_image && (
            <BestNewListWrap>
              <BestNewInner>
                <Swiper
                  slidesPerView={1}
                  initialSlide={index}
                  onSlideChange={(e) => SlideChange(e)}
                  navigation={{
                    prevEl: ".prev",
                    nextEl: ".next",
                  }}
                  pagination={{
                    type: "fraction",
                  }}
                >
                  {reviewData?.map((reviewV) => {
                    return (
                      <SwiperSlide key={reviewV.rev_id}>
                        <MainContentWrap>
                          <MainContentBox>
                            <img src={imgURL + reviewV.rev_image} alt="" />
                          </MainContentBox>
                        </MainContentWrap>
                      </SwiperSlide>
                    );
                  })}

                  {reviewData.length > 1 && (
                    <SwiperNavWrap>
                      <SwiperNavBox>
                        <SwiperNavL className="prev">
                          <img src={BannerArrow} alt="arrow" />
                        </SwiperNavL>
                        <SwiperNavR className="next">
                          <img src={BannerArrow} alt="arrow" />
                        </SwiperNavR>
                      </SwiperNavBox>
                    </SwiperNavWrap>
                  )}
                </Swiper>
              </BestNewInner>
            </BestNewListWrap>
          )}

          <ReviewTextSection>
            <ReviewTitleBox>
              <ReviewPrdTitleBox>
                <ReviewPrdTitle>
                  {reviewData[currentIndex]?.satisfaction === 0
                    ? "만족해요"
                    : "별로예요"}
                </ReviewPrdTitle>
              </ReviewPrdTitleBox>
              <ReviewDateWrap>
                <ReviewWriter>
                  {reviewData[currentIndex].mem_email.slice(0, 3) + "****"}
                </ReviewWriter>
                <ReviewDate>
                  {reviewData[currentIndex].rev_date
                    .split("T")[0]
                    .replaceAll("-", ".")}
                </ReviewDate>
              </ReviewDateWrap>
            </ReviewTitleBox>
            <ReviewContentWrap>
              <ReviewTextWrap>
                <ReviewText>
                  {reviewData[currentIndex].rev_content}
                </ReviewText>
              </ReviewTextWrap>
            </ReviewContentWrap>
          </ReviewTextSection>
        </ReviewWrap>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;
// header
const HeaderSection = styled.div`
position: relative;
width: 100%;
height: 84px;
overflow: hidden;
z-index: 99;

@media screen and (max-width: 1320px) {
  height: calc(100vw * (84 / 1320));
}
@media screen and (max-width: 930px) {
  height: calc(100vw * (55 / 428));
}
`;
const HeaderInner = styled.div`
position: fixed;
display: flex;
align-items: center;
width: 100%;
height: 84px;
background-color: #fff;
padding: 0 25px;

& p {
  color: #333;
  font-weight: 500;
  font-size: 20px;
}

@media screen and (max-width: 1320px) {
  height: calc(100vw * (84 / 1320));
  padding: 0 calc(100vw * (25 / 1320));

  & p {
    font-size: calc(100vw * (20 / 1320));
  }
}
@media screen and (max-width: 930px) {
  justify-content: center;
  height: calc(100vw * (55 / 428));
  padding: 0;

  & p {
    font-size: calc(100vw * (16 / 428));
  }
}
`;
const DeleteButton = styled.div`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    right: calc(100vw * (13.5 / 428));
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;

// 컨텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
  width: 1240px;
  height: 650px;
  background: #fff;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    height: calc(100vw * (650 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: 0;
    left: 0;
    transform: translate(0);
    width: 100%;
    height: 100vh;
    overflow: auto;
  }
`;

//Review
const ReviewWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1189px;
  height: 535px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1189 / 1320));
    height: calc(100vw * (535 / 1320));
  }
  @media screen and (max-width: 930px) {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
  }
`;
const ReviewTextSection = styled.div`
  width: 634px;
  height: 100%;
  overflow: auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (634 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    padding: calc(100vw * (20 / 428)) calc(100vw * (25 / 428));
  }
`;
const ReviewTitleBox = styled.div`
  width: 100%;
`;
const ReviewDateWrap = styled.div`
  color: #777777;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ReviewWriter = styled.span`
  color: #777777;
  border-right: 1px solid #dddddd;
  padding-right: 12px;
  margin-right: 12px;

  @media screen and (max-width: 1320px) {
    padding-right: calc(100vw * (12 / 1320));
    margin-right: calc(100vw * (12 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-right: calc(100vw * (12 / 428));
    margin-right: calc(100vw * (12 / 428));
  }
`;
const ReviewDate = styled.span`
  color: #777777;
`;
const ReviewPrdTitleBox = styled.div`
  width: 100%;
  padding-bottom: 12px;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (12 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (10 / 428));
  }
`;
const ReviewPrdTitle = styled.p`
  position: relative;
  color: #333333;
  font-weight: 600;
  font-size: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (17 / 428));
  }
`;
const ReviewContentWrap = styled.div``;
const ReviewTextWrap = styled.div`
  width: 100%;
  margin-top: 7px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (7 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (5 / 428));
  }
`;
const ReviewText = styled.p`
  width: 100%;
  color: #333;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

// slide
const BestNewListWrap = styled.div`
  width: 535px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (535 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const BestNewInner = styled.div`
  width: 100%;
  & .swiper-container {
    width: 100%;
  }
  & .swiper-pagination{
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    background: rgba(0, 0, 0, 0.4);
    padding: 4px 17px;
    border-radius: 14px;
  }
  & .swiper-pagination,.swiper-pagination-current,.swiper-pagination-total{
    color: #fff;
    font-size: 15px;
  }

  @media screen and (max-width: 1320px) {
    & .swiper-pagination{
      padding: calc(100vw * (4 / 1320)) calc(100vw * (17 / 1320));
    }
    & .swiper-pagination,.swiper-pagination-current,.swiper-pagination-total{
      font-size: calc(100vw * (15 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    & .swiper-pagination{
      padding: calc(100vw * (4 / 428)) calc(100vw * (17 / 428));
    }
    & .swiper-pagination,.swiper-pagination-current,.swiper-pagination-total{
      font-size: calc(100vw * (15 / 428));
    }
  }
`;
const MainContentWrap = styled.div`
  width: 100%;
  cursor: pointer;
`;
const MainContentBox = styled.div`
  width: 100%;
  height: 535px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (535 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (428 / 428));
  }
`;

// slide arrow
const SwiperNavWrap = styled.div``;
const SwiperNavBox = styled.div``;
const SwiperNavL = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(180deg);
  z-index: 1;
  width: 48px;
  height: 48px;
  cursor: pointer;

  & > img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (48 / 1320));
    width: calc(100vw * (48 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (48 / 428));
    width: calc(100vw * (48 / 428));
  }
`;
const SwiperNavR = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
  width: 48px;
  height: 48px;
  cursor: pointer;

  & > img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (48 / 1320));
    width: calc(100vw * (48 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (48 / 428));
    width: calc(100vw * (48 / 428));
  }
`;
