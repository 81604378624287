import React from "react";

import Header from "../../component/common/Header";
import ContentsDetailMain from "../../component/contents/ContentsDetailMain";

export default function ContentsDetailMainPage() {
  return (
    <>
      <Header back />
      <ContentsDetailMain />
    </>
  );
}
