//react
import React, { useState, useEffect } from "react";

// controller
import serverController from "../../../server/serverController";
import { UserDataAction } from "../../../../store/actionCreators";

//css
import styled from "styled-components";

//img
import IconSelectArrow from "../../../img/icon/icon_bottom_arrow.svg";

// elements
import { AlertBox } from "../../login/auth.elements";
import { useSelector } from "react-redux";

const bankList = [
  { value: "select", title: "선택" },
  { value: "002", title: "산업은행" },
  { value: "003", title: "기업은행" },
  { value: "004", title: "KB국민은행" },
  { value: "007", title: "수협은행" },
  { value: "011", title: "NH농협은행" },
  { value: "012", title: "농.축협은행" },
  { value: "023", title: "SC제일은행" },
  { value: "027", title: "한국씨티은행" },
  { value: "088", title: "신한은행" },
  { value: "089", title: "케이뱅크" },
  { value: "090", title: "카카오뱅크" },
  { value: "031", title: "대구은행" },
  { value: "032", title: "부산은행" },
  { value: "034", title: "광주은행" },
  { value: "035", title: "제주은행" },
  { value: "037", title: "전북은행" },
  { value: "039", title: "경남은행" },
  { value: "045", title: "새마을금고" },
  { value: "048", title: "신협" },
];

export default function MyProfile() {
  const { userInfo } = useSelector((state) => state.userData);

  const [changeButtonClick, setChangeButtonClick] = useState(false);
  const [profileName, setProfileName] = useState("");
  const [profileNum, setProfileNum] = useState("");
  const [profileEmail, setProfileEmail] = useState("");
  const [codeValue, setCodeValue] = useState("");
  const [userId, setUserId] = useState(0);
  const [isBtn, setIsBtn] = useState(false);
  const [correct, setCorrect] = useState(true);
  const [code, setCode] = useState("");

  const [clickPostUserInfo, setClickPostUserInfo] = useState(false);
  const [clickReFetch, setClickReFetch] = useState(false);

  // timer
  const [timer, setTimer] = useState(false);
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);
  const [nan, setNan] = useState(false);

  const [isButtonActive, setIsButtonActive] = useState(false);

  // my bank account
  const [userName, setUserName] = useState("");
  const [bankNum, setBankNum] = useState("");
  const [bankName, setBankName] = useState("");
  const [selectedValue, setSelectedValue] = useState("selectT선택");

  const getUserInfo = () => {
    serverController.connectFetchController(`mypage`, "POST", null, (res) => {
      if (res.result === 1) {
        setUserId(res.list.mem_id);
        setProfileName(res.list.mem_name);
        setProfileNum(res.list.mem_phone);
        setUserName(res.list.mem_bank_owner_name || "");
        setBankNum(res.list.mem_bank_account || "");
        setBankName(res.list.mem_bank_name);

        let memEmail = res.list.mem_email;
        let profile = "";
        if (memEmail.indexOf("google_") !== -1) {
          profile = memEmail.split("google_")[1];
        } else if (memEmail.indexOf("kakao_") !== -1) {
          profile = memEmail.split("kakao_")[1];
        } else if (memEmail.indexOf("apple_") !== -1) {
          profile = memEmail.split("apple_")[1];
        } else if (memEmail.indexOf("naver_") !== -1) {
          profile = memEmail.split("naver_")[1];
        } else {
          profile = memEmail;
        }

        setProfileEmail(profile);
      }
    });
  };

  const getCode = (reFetch = false) => {
    if (profileNum.length === 0) return;
    serverController.connectFetchController(
      `overlap/phone?mem_phone=${profileNum}`,
      "POST",
      null,
      (res) => {
        if (res.result === 1) {
          if (reFetch) {
            setClickReFetch(true);
          }
          setCode(res.identify_num);
          setIsBtn(true);
          setTimer(true);
          setMinutes(3);
          setSeconds(0);
        } else {
          if (res.message === "가입되어 있는 연락처") {
            alert("이미 가입되어있는 연락처입니다.");
          } else {
            alert("다시 시도해주세요.");
          }
        }
      },
      (err) => alert("다시 시도해주세요.")
    );
  };

  const postUpdateUserInfo = () => {
    if (profileName.length === 0) return alert("이름을 입력해주세요.");
    if (selectedValue.split("T")[0] === "select") return alert("은행을 선택해주세요.");
    if (userName.length === 0) return alert("예금주 명을 입력해주세요.");
    if (bankNum.length === 0) return alert("계좌번호를 입력해주세요.");
    if (profileNum.length === 0) return alert("휴대폰 번호를 입력해주세요.");

    const formData = new FormData();
    formData.append("mem_id", userId);
    formData.append("mem_username", profileName);
    formData.append("mem_email", profileEmail);
    formData.append("mem_phone", profileNum);
    formData.append("mem_bank_name", selectedValue.split("T")[1]);
    formData.append("mem_bank_code", selectedValue.split("T")[0]);
    formData.append("mem_bank_owner_name", userName);
    formData.append("mem_bank_account", bankNum);

    serverController.connectFetchController(`user/update`, "POST", formData, (res) => {
      if (res.result === 1) {
        setCode("");
        setIsBtn(false);
        setTimer(false);
        setMinutes(3);
        setSeconds(0);
        setClickPostUserInfo(true);
        setChangeButtonClick(false);
        setCodeValue("");

        UserDataAction.logInRequest({
          userInfo: { ...userInfo, mem_name: profileName, mem_phone: profileNum },
          isLogged: true,
        });
      }
    });
  };

  // timer
  useEffect(() => {
    if (minutes === undefined || minutes === NaN) {
      setMinutes("00");
    }
    if (seconds === undefined || seconds === NaN) {
      setSeconds("00");
    }
    if (seconds == "0" && minutes == "0" && nan === false) {
      // 첫번째 00:00은 예외처리

      setNan(true);
    }
    if (seconds == "0" && minutes == "0" && nan === true) {
      // 두번째 00:00은 막기
      setNan(false);
      setIsBtn(false);
      setProfileNum("");
      setTimer(false);
      setCorrect(true);
      alert("인증번호 유효 기간이 끝났습니다. 다시 시도해주세요.");
    }
  }, [minutes, seconds]);

  useEffect(() => {
    if (timer) {
      const countdown = setInterval(() => {
        if (parseInt(seconds) > 0) {
          setSeconds(parseInt(seconds) - 1);
        }
        if (parseInt(seconds) === 0) {
          if (parseInt(minutes) === 0) {
            clearInterval(countdown);
          } else {
            setMinutes(parseInt(minutes) - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [timer, minutes, seconds]);

  useEffect(() => {
    setTimeout(() => {
      setClickReFetch(false);
    }, 3000);
  }, [clickReFetch]);

  useEffect(() => {
    setTimeout(() => {
      setClickPostUserInfo(false);
    }, 3000);
  }, [clickPostUserInfo]);

  // get userInfo
  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    let isActive = false;
    if (
      profileName?.length === 0 ||
      profileEmail?.length === 0 ||
      profileNum?.length === 0 ||
      changeButtonClick
    ) {
      isActive = false;
    } else {
      isActive = true;
    }
    return setIsButtonActive(isActive);
  }, [profileName, profileEmail, profileNum, changeButtonClick]);

  return (
    <Container>
      <AlertBox show={clickReFetch}>인증번호가 전송되었습니다.</AlertBox>
      <AlertBox show={clickPostUserInfo}>회원 정보가 변경되었습니다.</AlertBox>
      <ProfileBox>
        <Content>
          <SectionTitle>기본 정보 수정 (* 필수)</SectionTitle>
          <ProfileListWrap>
            <ProfileInputWrap>
              <InputTitleWrap>
                <InputTitle>이메일아이디</InputTitle>
              </InputTitleWrap>
              <ProfileInputInner>
                <ProfileInput type="text" value={profileEmail} readOnly />
              </ProfileInputInner>
            </ProfileInputWrap>
            <ProfileInputWrap>
              <InputTitleWrap>
                <InputTitle>이름</InputTitle>
              </InputTitleWrap>
              <ProfileInputInner>
                <ProfileInput
                  type="text"
                  correct={true}
                  border={profileName.length > 0}
                  value={profileName}
                  onChange={(e) => {
                    setProfileName(e.target.value);
                  }}
                />
              </ProfileInputInner>
            </ProfileInputWrap>
            <ProfileInputWrap>
              <InputTitleWrap>
                <InputTitle>휴대폰번호</InputTitle>
              </InputTitleWrap>
              <ProfileInputInner>
                {/* default 기본 */}
                {!changeButtonClick ? (
                  <ProfileInputFlex>
                    <ProfileInput
                      correct={true}
                      type="number"
                      value={profileNum}
                      placeholder="등록된 번호가 없습니다."
                      readOnly
                    />
                    <ChangeButton
                      type="button"
                      onClick={() => {
                        setChangeButtonClick(true);
                        setProfileNum("");
                      }}
                    >
                      변경하기
                    </ChangeButton>
                  </ProfileInputFlex>
                ) : (
                  <>
                    <ProfileInputFlex>
                      <ProfileInput
                        correct
                        type="number"
                        value={profileNum}
                        onChange={(e) => {
                          if (e.nativeEvent.data === "-") return;
                          if (e.target.value.length > 11) return;
                          setProfileNum(e.target.value);
                        }}
                        placeholder="-를 제외한 숫자만 입력해주세요."
                      />
                    </ProfileInputFlex>
                    {!isBtn ? (
                      <ProfileInputFlex>
                        <NumberSendBtn
                          type="button"
                          disabled={profileNum.length < 11}
                          onClick={getCode}
                        >
                          인증번호 전송
                        </NumberSendBtn>
                      </ProfileInputFlex>
                    ) : (
                      <>
                        <ProfileInputFlex>
                          <ProfileInputBox>
                            <ProfileInput
                              type="text"
                              correct={correct}
                              value={codeValue}
                              onChange={(e) => {
                                setCorrect(true);
                                setCodeValue(e.target.value);
                              }}
                            />
                            <Time>
                              {" "}
                              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                            </Time>
                          </ProfileInputBox>
                          <ReSendButton
                            type="button"
                            onClick={() => {
                              getCode(true);
                            }}
                          >
                            재전송
                          </ReSendButton>
                        </ProfileInputFlex>
                        {!correct && (
                          <ProfileInputFlex>
                            <ValidatedText>인증번호가 일치하지 않습니다.</ValidatedText>
                          </ProfileInputFlex>
                        )}
                        <ProfileInputFlex>
                          <ConfirmBtn
                            type="button"
                            onClick={() => {
                              if (codeValue !== code) {
                                setCorrect(false);
                              } else {
                                postUpdateUserInfo();
                              }
                            }}
                          >
                            확인
                          </ConfirmBtn>
                        </ProfileInputFlex>
                      </>
                    )}
                  </>
                )}
              </ProfileInputInner>
            </ProfileInputWrap>
          </ProfileListWrap>
        </Content>
      </ProfileBox>
      <ListWrap>
        <ListArea>
          <SectionTitle>환불계좌 관리</SectionTitle>
          <AddressList>
            <InputText>예금주</InputText>
            <Input
              type="text"
              value={userName}
              active={userName.length > 0}
              placeholder="예금주명을 입력해주세요."
              onChange={(e) => {
                setUserName(e.target.value);
              }}
            />
          </AddressList>
          <AddressList>
            <InputText>은행</InputText>
            <SelectButton
              borderActive={selectedValue !== "selectT선택"}
              onChange={(e) => {
                setSelectedValue(e.target.value);
              }}
            >
              {bankList.map((item) => {
                return (
                  <option
                    key={item.value}
                    value={`${item.value}T${item.title}`}
                    selected={item.title === bankName}
                  >
                    {item.title}
                  </option>
                );
              })}
            </SelectButton>
          </AddressList>
          <AddressList>
            <InputText>계좌번호</InputText>
            <Input
              type="number"
              value={bankNum}
              active={bankNum.length > 0}
              placeholder="계좌번호를 입력해주세요."
              onChange={(e) => {
                if (e.target.value.length > 20) return;
                setBankNum(e.target.value);
              }}
            />
          </AddressList>
          <SaveBtn
            type="button"
            onClick={() => {
              if (codeValue.length > 0) return;
              if (!isButtonActive) return;
              postUpdateUserInfo();
            }}
            isButtonActive={isButtonActive}
          >
            저장
          </SaveBtn>
        </ListArea>
      </ListWrap>
      {/* <MyPageBottom textList={myPageBottomTextList} /> */}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 70px 0 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (70 / 1320)) 0 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0 0;
  }
`;
const ProfileBox = styled.div`
  @media screen and (max-width: 930px) {
    border-bottom: 1px #eee solid;
    padding-bottom: calc(100vw * (30 / 428));
  }
`;

const Content = styled.div`
  width: 378px;
  margin: 0 auto 70px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
    margin: 0 auto calc(100vw * (60 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  }
`;

//Profile input
const ProfileListWrap = styled.div`
  width: 100%;
`;
const ProfileInputWrap = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 428));
    }
  }
`;
const InputTitleWrap = styled.div`
  width: 100%;
`;
const InputTitle = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProfileInputInner = styled.div`
  width: 100%;
  margin-top: 8px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (8 / 428));
  }
`;
const ProfileInputFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (8 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (8 / 428));
    }
  }
`;
const ProfileInputBox = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
`;
const ProfileInput = styled.input`
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0 15px;
  flex: 1;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }
  &:focus {
    border: 1px solid #000;
  }

  ${({ border }) =>
    border &&
    `
  border-color: #000;
  `}

  ${({ correct }) =>
    !correct &&
    `border: 1px solid #FF006C;
    `}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: 0 calc(100vw * (15 / 428));
  }
`;

const Time = styled.span`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: #333;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (15 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    right: calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;
const ValidatedText = styled.p`
  color: #ff006c;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

const ChangeButton = styled.button`
  width: 120px;
  height: 50px;
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  background-color: #000;
  margin-left: 8px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (120 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (8 / 428));
  }
`;
const NumberSendBtn = styled.button`
  width: 100%;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;

  &:disabled {
    background-color: #bbb;
    border: 1px solid #bbb;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
const ReSendButton = styled(ChangeButton)`
  color: #111;
  background-color: #fff;
  border: 1px solid #000;
`;
const ConfirmBtn = styled(NumberSendBtn)``;

// 저장버튼
const SaveBtn = styled.button`
  width: 100%;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;
  margin-top: 60px;
  ${({ isButtonActive }) =>
    !isButtonActive &&
    `
  background-color : #bbbbbb;
  border : solid #bbbbbb 1px;
  `}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
    margin-top: calc(100vw * (60 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
    margin-top: calc(100vw * (60 / 428));
  }
`;

// my bank account
const ListWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (40 / 428)) 0 calc(100vw * (40 / 428));
  }
`;
const ListArea = styled.div`
  width: 378px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const AddressList = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 428));
    }
  }
`;
const InputText = styled.p`
  width: 100%;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 8px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-bottom: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-bottom: calc(100vw * (8 / 428));
  }
`;
const SectionTitle = styled(InputText)`
  font-weight: 600;
  margin-bottom: 20px;
  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (20 / 428));
  }
`;
const Input = styled.input`
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  transition: ease 0.3s all;
  padding: 0 15px;
  flex: 1;

  &::placeholder {
    color: #acacac;
  }
  &:focus {
    border: 1px solid #000;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: 0 calc(100vw * (15 / 428));
  }
`;
const SelectButton = styled.select`
  display: block;
  width: 100%;
  height: 50px;
  color: #acacac;
  font-weight: 400;
  font-size: 15px;
  background: #ffffff url(${IconSelectArrow}) no-repeat right 15px center / 24px;
  border: 1px solid #ddd;
  padding: 0 15px;
  transition: ease 0.3s all;
  border-radius: 0;

  -webkit-appearance: none; /* 네이티브 외형 감추기*/
  -moz-appearance: none;
  appearance: none;
  font-weight: normal;
  letter-spacing: normal;
  cursor: pointer;

  &.focus {
    color: #333;
    border: 1px solid #000;
  }

  ${({ borderActive }) =>
    borderActive &&
    `border: 1px solid #000;
  color: #333;
  `}
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
    font-size: calc(100vw * (15 / 1320));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 1320)) center /
      calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 428)) center /
      calc(100vw * (24 / 428));
  }
`;
