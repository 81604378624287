import React from 'react';
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import SignUpSuccess from '../../component/login/SignUpSuccess';
import Footer from '../../component/common/StoreFooter';
import FooterMenu from '../../component/common/FooterMenu';

const StoreSuccessPage = () => {
    return (
        <>
            <Header />
            <SignUpSuccess />
        </>
    )
}

export default StoreSuccessPage
