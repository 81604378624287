import React from 'react';
import faker from 'faker';
import { Mobile, PC } from '../common/mediaStlye';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, EffectCoverflow } from 'swiper';  
// import "swiper/swiper-bundle.css";
import './CategoryFaces.css';

import {
    FaceWrap,
    LayOut,
    FaceHeadings,
    FaceSlider,
    FaceSlide,
    Face,
    FaceText
} from './CategoryContents.element';

import categoryface1 from '../../images/category/category-face-1.png';
import categoryface2 from '../../images/category/category-face-2.png';
import categoryface3 from '../../images/category/category-face-3.png';
import categoryface4 from '../../images/category/category-face-4.png';
import categoryface5 from '../../images/category/category-face-5.png';

SwiperCore.use([Navigation, EffectCoverflow])

const CategoryFaces = () => {
    const dummys = [categoryface1,categoryface2,categoryface3,categoryface4,categoryface5,]

    const swiperOptions = {
        navigation: true,
        loop: true,
        effect: 'coverflow',        
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 220,
            modifier: 1.5,
        },
        slidesPerView: 3,
        grabCursor: true,
        centeredSlides: true,
    };

    const mobileSwiperOptions = {
        navigation: false,
        loop: true,
        effect: 'coverflow',        
        coverflowEffect: {
            rotate: 0,
            stretch: 5,
            depth: 270,
            modifier: 1,
        },
        slidesPerView: 3,
        grabCursor: true,
        centeredSlides: true,
    }

    return (
        <>
        <PC>
        <FaceWrap>
            <LayOut>
                <FaceHeadings>popular celebrity</FaceHeadings>
                <FaceSlider className="face-slider">
                    <Swiper {...swiperOptions}>
                        {
                            dummys.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                    <FaceSlide>
                                        <Face>
                                            <img src={item} />
                                        </Face>
                                        <FaceText className="face_text">
                                            <h4>이지아</h4>
                                            <p>Lee  Ji  Ah</p>
                                        </FaceText>                        
                                    </FaceSlide>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </FaceSlider>
            </LayOut>
        </FaceWrap>
        </PC>
        <Mobile>
        <FaceWrap>
            <LayOut>
                <FaceHeadings style={{marginBottom: '50px'}}>popular celebrity</FaceHeadings>
                <FaceSlider className="face-slider">
                    <Swiper {...mobileSwiperOptions}>
                        {
                            dummys.map((item, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <FaceSlide>
                                            <Face>
                                                <img src={item} />
                                            </Face>
                                            <FaceText className="face_text">
                                                <h4>이지아</h4>
                                                <p>Lee  Ji  Ah</p>
                                            </FaceText>                        
                                        </FaceSlide>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </FaceSlider>
            </LayOut>
        </FaceWrap>
        </Mobile>
        </>
    )
}

export default CategoryFaces
