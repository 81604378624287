import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PC, Mobile } from "../mediaStlye";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Container, HeaderWrapper, Logo } from "./Header.elements";
import HeaderTop from "./HeaderTop";
import MiniHeaderCategoryModal from "./MiniHeaderCategoryModal";
import HeaderSearchModal from "./HeaderSearchModal";
import menuBar from "../../../images/menu-bar.svg";
import SearchBlack from "../../../images/search_icon_black.svg";
import IconLogo from "../../../store_src/img/icon/wala_logo.svg";


import {
  HeaderTopRight,
  PageBtn,
  MenuImg,
  IconSearch,
} from "./Header.elements";

const Header = ({ main, isPay, infi }) => {
  const history = useHistory();
  const { isLogged } = useSelector((state) => state.userData);
  const [isScroll, setIsScroll] = useState(false);
  const mainUrl = window.location.pathname.split("/")[1];

  const [isMenu, setIsMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const onScroll = (e) => {
      return requestAnimationFrame(() => {
        setIsScroll((prev) => window.scrollY > 0);
      });
    };

    if (!main) {
      setIsScroll(true);
    } else {
      window.addEventListener("scroll", onScroll);
    }

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const handleLogoClick = () => {
    // if (mem_role === "STYLELIST") {
    //     window.scroll({
    //         top: 0,
    //         left: 0,
    //         behavior: 'smooth'
    //     });
    // } else
    if (window.location.pathname.split("/")[1].length == 0) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      history.push(`/`);
    }
  };

  const scrollTop = () => {
    if (mainUrl == "") {
      window.location.reload();
    } else {
      history.push("/");
    }
  };

  if (isPay) {
    return (
      <>
        <Container main={main}>
          <HeaderWrapper main={main} isScroll={isScroll}>
            <HeaderTop main={main} />

            <Logo isScroll={isScroll} onClick={() => history.push("/")}>
              <LogoImg src={IconLogo} alt="logo" />
              {/* <img src={walaLogoSvg} alt="WALA" /> */}
            </Logo>

            {/* <HeaderBottom isScroll={isScroll} /> */}
          </HeaderWrapper>
        </Container>
      </>
    );
  }

  return (
    <>
      {isMenu && <MiniHeaderCategoryModal setIsMenu={setIsMenu} />}
      {isOpen && <HeaderSearchModal setIsOpen={setIsOpen} />}
      <PC>
        <HeaderWrap>
          <Container main={main}>
            <HeaderWrapper main={main} isScroll={isScroll}>
              {/* <HeaderTop main={main} /> */}
              <MenuImg onClick={() => setIsMenu(true)}>
                <img src={menuBar} alt="메뉴바" />
              </MenuImg>

              <Logo isScroll={isScroll} onClick={handleLogoClick}>
                <LogoImg src={IconLogo} alt="logo" />
              </Logo>
              <HeaderTopRight>
                {!isLogged ? (
                  <NoLoggedAddRightSection>
                    <PageBtn onClick={() => history.push("/login")}>
                      <span className="is-logged-out">sign in</span>
                    </PageBtn>
                    <PageBtn onClick={() => history.push("/signup")}>
                      <span className="is-logged-out">sign up</span>
                    </PageBtn>
                  </NoLoggedAddRightSection>
                ) : null}
                <IconSearch
                  src={SearchBlack}
                  alt="검색"
                  onClick={() => setIsOpen(true)}
                />
                <>
                  {
                    // userInfo.mem_role === "USER" && (
                    //     <>
                    //     <PageBtn onClick={() => history.push('/mypage')} modal={modal}>
                    //         <img src={accountIcon} alt="" />
                    //         <span>MY PAGE</span>
                    //     </PageBtn>
                    //     </>
                    // )
                  }
                  {
                    // userInfo.mem_role === "BRAND" && (
                    //     <>
                    //     <PageBtn onClick={() => {
                    //         window.location.href = 'http://3.36.5.45:8073/login';
                    //     }} modal={modal}>
                    //         <img src={brandIcon} alt="" />
                    //         <span>BRAND 관리자페이지</span>
                    //     </PageBtn>
                    //     {/* <PageBtn onClick={onHandleLogout} modal={modal}>
                    //         <img src={logoutIcon} alt="" />
                    //         <span>로그아웃</span>
                    //     </PageBtn> */}
                    //     {/*
                    //     <PageBtn onClick={() => history.push('/management')} modal={modal}>
                    //         <img src={accountIcon} alt="" />
                    //         <span>MY PAGE</span>
                    //     </PageBtn> */}
                    //     </>
                    // )
                  }
                  {
                    // userInfo.mem_role === "STYLELIST" && (
                    //     <>
                    //     <PageBtn onClick={() => history.push('/stylist/sponserd')} modal={modal}>
                    //         <img src={stylistIcon} alt="" />
                    //         <span>STYLIST</span>
                    //     </PageBtn>
                    //     <PageBtn onClick={() => history.push('/stylist/liked')} modal={modal}>
                    //         <img src={heartIcon} alt="" />
                    //         <span>MY LIST</span>
                    //     </PageBtn>
                    //     <PageBtn onClick={() => history.push('/stylist/mypage')} modal={modal}>
                    //         <img src={accountIcon} alt="" />
                    //         <span>MY PAGE</span>
                    //     </PageBtn>
                    //     </>
                    // )
                  }
                </>
              </HeaderTopRight>
            </HeaderWrapper>
          </Container>
        </HeaderWrap>
      </PC>

      <Mobile>
        <Container isScroll={false}>
          <HeaderWrapper>
            <MenuImg onClick={() => setIsMenu(true)}>
              <img src={menuBar} alt="메뉴바" />
            </MenuImg>

            <Logo onClick={scrollTop} isScroll={isScroll}>
              <LogoImg src={IconLogo} alt="logo" />
            </Logo>
            <IconSearch
              src={SearchBlack}
              alt="검색"
              onClick={() => setIsOpen(true)}
            />
          </HeaderWrapper>
        </Container>
      </Mobile>
    </>
  );
};

export default Header;

const HeaderWrap = styled.div`
  margin-bottom: calc(100vw * (90 / 1920));
`;


const LogoImg = styled.img`
  display: block;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  
  @media screen and (max-width: 1320px) {
    width: 100%;
  }

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;

const NoLoggedAddRightSection = styled.div`
  position: absolute;
  display: flex;
  right: calc(100vw * (370 / 1920));
`;
