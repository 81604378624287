import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { UserDataAction } from "../../../store/actionCreators";
import IconLogo from "../../img/icon/wala_logo.svg";
import IconLogoW from "../../img/icon/wala_logo_w.svg";
import IconSearch from "../../img/icon/icon_header_search_black.svg";
import IconSearchW from "../../img/icon/icon_header_search_white.svg";
import IconCart from "../../img/icon/icon_header_cart_black.svg";
import IconCartW from "../../img/icon/icon_header_cart_white.svg";
import IconCartBack from "../../img/icon/icon_header_back.svg";
import IconMoHome from "../../img/icon/icon_mo_top_home.svg";
import IconMy from "../../img/icon/icon_header_my.svg";
import serverController from "../../../server/serverController";
import GnbList from "./GnbList";
import SearchModal from "./modal/SearchModal";
import { crypto } from "utils";
// import LogoGif from "../../img/icon/wala_logo.gif";

export default function Header(props) {
  const { scrollV = false, main, back = false, url = null, onlyLeft = false, setLoginPop } = props;
  const location = useLocation();
  const history = useHistory();
  const body = document.querySelector("body");
  const [menuOn, setMenuOn] = useState(false);
  const { isLogged } = useSelector((state) => state.userData);
  const [cateListHide, setCateListHide] = useState(false);
  const [searchModalShow, setSearchModalShow] = useState(false);

  const postLogout = () => {
    serverController.connectFetchController(
      `logout`,
      "POST",
      null,
      (res) => { },
      (err) => console.error(err)
    );
    if (localStorage.getItem("login-info")) {
      localStorage.removeItem("login-info");
    }
    UserDataAction.logOutRequest();
    window.location.href = "/store";
  };
  // function getAllCategoryList() {
  //   serverController.connectFetchController(
  //     `category/all`,
  //     "GET",
  //     null,
  //     (res) => {
  //       if (res.result === 1) {
  //       }
  //     },
  //     (err) => console.error(err)
  //   );
  // }

  return (
    <Container main={main || false}>
      <PC>
        {searchModalShow && <SearchModal setSearchModalShow={setSearchModalShow} />}

        <HeaderWrap>
          <MainHeader id="cate_wrap">
            <HeaderInner>
              <LogoWrap>
                <Logo to={`/store`}>
                  {/* <LogoImg src={LogoGif} alt="logo" /> */}
                  <LogoImg src={IconLogo} alt="logo" />
                </Logo>
              </LogoWrap>

              {isLogged ? (
                <>
                  <MenuWrap>
                    <Menu onClick={() => setSearchModalShow(true)}>
                      <SearchIconWrap>
                        <img src={IconSearch} alt="search" />
                      </SearchIconWrap>
                    </Menu>
                    <Menu>
                      <Link to={`/store/cart`}>
                        <SearchIconWrap>
                          <img src={IconCart} alt="cart-page" />
                        </SearchIconWrap>
                      </Link>
                    </Menu>
                    <Menu>
                      <Link to={`/store/mypage`}>
                        <SearchIconWrap>
                          <img src={IconMy} alt="mypage-page" />
                        </SearchIconWrap>
                      </Link>
                    </Menu>
                    <Menu>
                      <LogoutButton type="button" onClick={postLogout}>
                        LOGOUT
                      </LogoutButton>
                    </Menu>
                  </MenuWrap>
                </>
              ) : (
                <>
                  <MenuWrap>
                    <Menu>
                      <Link to={`/store/login?returnTo=${location.pathname}${location.search}`}>
                        <SignInText>SIGN IN</SignInText>
                      </Link>
                    </Menu>

                    <Menu onClick={() => setSearchModalShow(true)}>
                      <SearchIconWrap>
                        <img src={IconSearch} alt="search" />
                      </SearchIconWrap>
                    </Menu>
                  </MenuWrap>
                </>
              )}
            </HeaderInner>
          </MainHeader>
          <HeaderWrapBackground>
            <HeaderMenuList>
              <MenuLi
                // onMouseEnter={() => {
                //   setCateListHide(true);
                // }}
                // onMouseLeave={() => {
                //   setCateListHide(false);
                // }}
                onClick={() => {
                  //TODO - 고수
                  history.push(`/store/category/${crypto.encryptionBASE64("1")}/${crypto.encryptionBASE64("1")}`);
                }}
              >
                <HeaderCategoryMenu cateListHide={cateListHide}>
                  <span>CATEGORY</span>
                </HeaderCategoryMenu>
              </MenuLi>
              <MenuLi>
                <HeaderMenuWrap>
                  <HeaderMenu to={"/store/brand"}>BRAND</HeaderMenu>
                </HeaderMenuWrap>
              </MenuLi>
              <MenuLi>
                <HeaderMenuWrap>
                  <HeaderMenu to={"/store/contents/list"}>CONTENTS</HeaderMenu>
                </HeaderMenuWrap>
              </MenuLi>
            </HeaderMenuList>
          </HeaderWrapBackground>
        </HeaderWrap>

        <>
          {/* category menu */}
          {/* {cateListHide && (
          <CategoryWrap
            cateMenuFix={cateMenuFix}
            onMouseLeave={() => {
              setCateListHide(false);
            }}
          >
            <CategoryInner>
              {mainCateList.length > 0 &&
                mainCateList.map((mainCateItem) => {
                  return (
                    <CategoryUlBox key={mainCateItem.catId}>
                      <CategoryTitle>{mainCateItem.catName}</CategoryTitle>
                      <CategoryListBox>
                        {mainCateItem.subCategory.length > 0 &&
                          mainCateItem.subCategory.map((subCateItem) => {
                            return (
                              <CategoryList key={subCateItem.catId}>
                                <CategoryMenu>
                                  {subCateItem.catName}
                                </CategoryMenu>
                              </CategoryList>
                            );
                          })}
                      </CategoryListBox>
                    </CategoryUlBox>
                  );
                })}
            </CategoryInner>
          </CategoryWrap>
        )} */}
        </>
      </PC>

      <Mobile>
        <HeaderWrap scrollV={scrollV}>
          <HeaderInner>
            {!back || 2 >= history.length ? (
              <LogoWrap>
                <Logo to={`/store`}>
                  <LogoImg src={main && scrollV ? IconLogoW : IconLogo} alt={main && scrollV ? "white-logo" : "logo"} />
                </Logo>
              </LogoWrap>
            ) : (
              <BackWrap
                onClick={() => {
                  body.style.overflow = "auto";
                  body.style.height = "auto";
                  if (url !== null) {
                    const searchId = "/store/find/id?returnTo=/store";
                    const searchPw = "/store/find/pw?returnTo=/store";
                    const nowLocation = `${location.pathname}${location.search}`;
                    if (searchId === nowLocation || searchPw === nowLocation) {
                      return history.goBack();
                    }
                    return history.push({ pathname: url }, undefined, { scroll: false });
                  }
                  history.goBack();
                }}
              >
                <BackIcon>
                  <BackIconImg src={IconCartBack} alt="logo" />
                </BackIcon>
              </BackWrap>
            )}
          </HeaderInner>
          {!onlyLeft && (
            <HeaderRight>
              {!back || 2 >= history.length ? null : (
                <HomeLink to={`/store`}>
                  <SearchImg src={IconMoHome} alt={"home-icon"} />
                </HomeLink>
              )}
              <SearchIconWrap onClick={() => history.push(`/store/search?keyword=`)}>
                <SearchImg src={scrollV ? IconSearchW : IconSearch} alt={scrollV ? "search-icon-white" : "search-icon"} />
              </SearchIconWrap>
              <CartIconWrap
                // onClick={() => {
                //   if (isLogged) {
                //     history.push(`/store/cart`);
                //   } else {
                //     history.push(`/store/login?returnTo=${location.pathname}`);
                //   }
                // }}

                onClick={() => {
                  if (isLogged) {
                    history.push(`/store/cart`);
                  } else {
                    history.push(`/store/login?returnTo=${location.pathname}${location.search}`);
                  }
                }}
              >
                <CartImg src={scrollV ? IconCartW : IconCart} alt={scrollV ? "cart-icon-white" : "cart-icon"} />
              </CartIconWrap>
            </HeaderRight>
          )}
        </HeaderWrap>
      </Mobile>
      {menuOn && <GnbList menuOn={menuOn} setMenuOn={setMenuOn} />}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  transition: ease 0.3s all;
  position: fixed;
  top: 0;
  z-index: 99;
  -webkit-align-self: center;

  ${({ hide }) =>
    hide &&
    `
  top : -80px;
  `}

  @media screen and (max-width: 1320px) {
    ${({ hide }) =>
    hide &&
    `
      top : calc(100vw * (-80 / 1320));
  `}
  }

  @media screen and (max-width: 930px) {
    ${({ hide }) =>
    hide &&
    `
      top : 0;
  `}
    ${({ main }) =>
    main
      ? `
        height : calc(100vw * (55 / 428));
        top : 0;
      `
      : `
        position: relative;
        overflow: hidden;
        margin-bottom: calc(100vw * (55 / 428));
      `}
  }
`;
const HeaderWrap = styled.div`
  width: 100%;
  height: 130px;
  background: #fff;
  border-bottom: 1px solid #eeeeee;
  transition: ease 0.3s all;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (130 / 1320));
  }

  @media screen and (max-width: 930px) {
    position: fixed;
    z-index: 99;
    display: flex;
    top: 0;
    justify-content: space-between;
    height: calc(100vw * (55 / 428));
    padding: 0 calc(100vw * (25 / 428));
    box-shadow: none;

    ${({ scrollV }) =>
    scrollV &&
    `
        position : absolute;
        background: transparent;
        border-bottom : none;
    `}
  }
`;
const HeaderWrapBackground = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 50px;
  background-color: #fff;
  border-bottom: 1px solid #eee;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
  }
`;
const HeaderInner = styled.div`
  width: 1240px;
  height: 80px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    height: calc(100vw * (80 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: auto;
    height: auto;
  }
`;
const HeaderRight = styled.div`
  @media screen and (max-width: 930px) {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;
const SearchIconWrap = styled.div`
  width: 32px;
  height: 32px;
  & > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
    margin-right: calc(100vw * (15 / 428));
  }
`;
const HomeLink = styled(Link)`
  width: 32px;
  height: 32px;
  & > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
    margin-right: calc(100vw * (15 / 428));
  }
`;
const SearchImg = styled.img``;
const CartIconWrap = styled.div`
  width: 32px;
  height: 32px;
  & > img {
    height: 100%;
  }
  @media screen and (max-width: 1320px) {
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
  }
`;
const CartImg = styled.img``;
const MainHeader = styled.div`
  width: 1240px;
  margin: 0 auto;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    display: flex;
    justify-content: space-between;
    margin: 0;
    width: 100%;
  }
`;
const LogoWrap = styled.div`
  display: flex;
  align-items: center;
`;
const Logo = styled(Link)`
  width: 124px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (124 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (110 / 428));
  }
`;

const LogoImg = styled.img`
  display: block;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;

  @media screen and (max-width: 1320px) {
    width: 100%;
  }

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const BackWrap = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @media screen and (max-width: 930px) {
    left: calc(100vw * (13.5 / 428));
  }
`;
const BackIcon = styled.div`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;
const BackIconImg = styled.img`
  width: 100%;
  height: 100%;
`;
const MenuWrap = styled.div`
  display: flex;
  align-items: center;
`;
const Menu = styled.div`
  font-size: 18px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 25px;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (25 / 1320));
    }
  }
`;

const MenuLi = styled.li`
  display: block;
  position: relative;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 40px;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (40 / 1320));
    }
  }

  /* @media screen and (max-width: 930px) {
    padding: calc(100vw * (3 / 428)) calc(100vw * (5 / 428));
    margin: calc(100vw * (5 / 428)) calc(100vw * (12 / 428));
    margin-right: calc(100vw * (15 / 428));
    font-size: calc(100vw * (14 / 428));
    font-weight: bold;
  } */
`;

const SearchWrap = styled.div`
  position: absolute;
  right: 0;
  z-index: 5;
`;
const SearchBg = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
`;
const SearchInner = styled.div`
  position: relative;
  width: 300px;
  border-radius: 25px;
  padding: 0 20px;
  background: #000;
  margin-left: 20px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (300 / 1320));
    padding: 0 calc(100vw * (20 / 1320));
    margin-left: calc(100vw * (20 / 1320));
  }
`;
const SearchInput = styled.input`
  width: 100%;
  padding: 13px 30px 10px 0;
  font-size: 15px;
  color: #fff;
  &::placeholder {
    color: #999;
    text-align: center;
  }

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (13 / 1320)) calc(100vw * (30 / 1320)) calc(100vw * (10 / 1320)) 0;
    font-size: calc(100vw * (15 / 1320));
  }
`;
const SearchBtn = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  cursor: pointer;
  background: url(${IconSearch}) no-repeat center center / contain;

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (20 / 1320));
    width: calc(100vw * (20 / 1320));
    height: calc(100vw * (20 / 1320));
    margin-top: calc(100vw * (-10 / 1320));
  }
`;
//nav
const HeaderMenuList = styled.ul`
  width: 1240px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: #fff;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
`;

const HeaderMenuWrap = styled.span`
  &:hover::after {
    content: "";
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-4deg);
    width: 120%;
    height: 15px;
    z-index: 0;
    background-color: #fdf251;
  }

  @media screen and (max-width: 1320px) {
    &:hover::after {
      /* width: calc(100vw * (115 / 1320)); */
      height: calc(100vw * (15 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    &::after {
      display: none;
    }
  }
`;
const HeaderMenu = styled(Link)`
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-weight: 700;
  display: block;
  position: relative;
  text-transform: uppercase;
  /* &::after {
    content: "";
    display: none;
    width: 100%;
    height: 1px;
    background: #000;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &:hover::after {
    display: block;
  } */

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
  }
`;

const HeaderCategoryMenu = styled.span`
  cursor: pointer;
  & span {
    position: relative;
    z-index: 1;
    font-size: 18px;
    font-weight: 700;
  }

  &:hover::after {
    content: "";
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-4deg);
    width: 115px;
    height: 15px;
    z-index: 0;
    background-color: #fdf251;
  }

  @media screen and (max-width: 1320px) {
    & span {
      font-size: calc(100vw * (18 / 1320));
    }

    &:hover::after {
      width: calc(100vw * (115 / 1320));
      height: calc(100vw * (15 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    &::after {
      display: none;
    }
  }
`;

const SignInText = styled.span`
  color: #222;
  font-size: 18px;
  font-weight: 900;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
`;
const LogoutButton = styled.button`
  color: #222;
  font-size: 15px;
  text-decoration: underline;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
`;

// category menu
const CategoryWrap = styled.div`
  position: absolute;
  top: 130px;
  left: 0;
  z-index: 99;
  width: 100%;
  height: auto;
  padding: 30px 0 90px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);

  //cateMenuFix
  ${({ cateMenuFix }) =>
    cateMenuFix &&
    `
    position: fixed;
    top : 50px;
  `}

  @media screen and (max-width: 1320px) {
    top: calc(100vw * (130 / 1320));
    padding: calc(100vw * (30 / 1320)) 0 calc(100vw * (90 / 1320));

    //cateMenuFix
    ${({ cateMenuFix }) =>
    cateMenuFix &&
    `
      top : calc(100vw * (50 / 1320));
    `}
  }
`;
const CategoryInner = styled.div`
  width: 1240px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
`;
const CategoryUlBox = styled.div`
  width: 180px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (180 / 1320));
  }
`;
const CategoryListBox = styled.ul`
  margin-top: 15px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (15 / 1320));
  }
`;
const CategoryList = styled.li`
  display: flex;
  align-items: center;
  height: 35px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (35 / 1320));
  }
`;
const CategoryTitle = styled.span`
  color: #333;
  font-size: 15px;
  font-weight: 600;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
`;
const CategoryMenu = styled(Link)`
  color: #333;
  font-size: 16px;
  font-weight: 400;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;
