import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";
import { useSelector } from "react-redux";
import serverController from "../../../server/serverController";
import { imgURL } from "../../../server/appInfoController";

import { ButtonComp } from "../ButtonComp";

import ProductImgage1 from "../../../img/brand_logo3.png";
import CancleIcon from "../../../img/icon/icon_cancle.png";
import IconFile from "../../../img/icon/icon_file.png";

// 리뷰 수정 페이지
export default function MypageReviewModal({ setIsModal, current }) {
  const [text, setText] = useState("");
  const [satisfaction, setSatisfaction] = useState(0);
  const [imageArr, setImageArr] = useState([]);
  const [previewImageArr, setPreviewImageArr] = useState([]);
  const body = document.querySelector("body");
  const BtnList = [
    {
      text: "만족해요",
      value: 0,
    },
    {
      text: "별로예요",
      value: 1,
    },
  ];
  useEffect(() => {
    body.style.overflow = "hidden";
    body.style.height = "100vh";
  }, []);
  useEffect(() => {
    if (current) {
      if (current.rev_image !== null) {
        setPreviewImageArr([imgURL + current?.rev_image]);
        setImageArr([""]);
        // 이미지 업로드 안하면 imageArr 보내지 마!!
      }
      setText(current?.rev_content ?? "");
      setSatisfaction(current?.satisfaction || 0);
    }
  }, [current]);

  const patchReview = async () => {
    const formData = new FormData();
    formData.append("rev_content", text);
    formData.append("rev_id", current.rev_id);
    formData.append("satisfaction", satisfaction);

    if (imageArr[0] !== "" && imageArr.length !== 0) {
      formData.append("rev_image_file", imageArr[0]);
    } else if (imageArr.length === 0) {
      formData.append("rev_image_file", "");
    }

    await serverController.connectFetchController(`review/product`, "PUT", formData, (res) => {
      console.log(res, "res");
      if (res.result == 1) {
        setIsModal(false);
        body.style.overflow = "auto";
        body.style.height = "auto";
      }
    });
  };

  const uploadImage = (e) => {
    let arr = [];
    for (var i = 0; e.target.files.length > i; i++) {
      arr.push(e.target.files[i]);
    }
    setImageArr(arr);
  };

  const handleImageUpload = (fileArr) => {
    let fileArrUrl = [];
    let file = null;

    for (let i = 0; i < fileArr.length; i++) {
      file = fileArr[i];

      let reader = new FileReader();
      reader.onload = () => {
        fileArrUrl[i] = reader.result;
        setPreviewImageArr([...fileArrUrl]);
      };
      reader.readAsDataURL(file);
    }
  };

  const deleteButtonClick = () => {
    setImageArr([]);
    setPreviewImageArr([]);
  };

  return (
    <Container>
      <Background
        onClick={() => {
          setIsModal(false);
          body.style.overflow = "auto";
          body.style.height = "auto";
        }}
      />
      <Content>
        <TitleCont>
          <Cancle>
            <CancleImg
              src={CancleIcon}
              onClick={() => {
                setIsModal(false);
                body.style.overflow = "auto";
                body.style.height = "auto";
              }}
            />
          </Cancle>
          <Title>수정하기</Title>
        </TitleCont>
        <ScrollBox>
          <TopCont>
            <Product>
              <ProductImg src={current?.src} />
              <div>
                <BrandName>{current?.bc_title || "(BRAND)"}</BrandName>
                <ProductName>{current?.prd_name}</ProductName>
              </div>
            </Product>
            <FileBox>
              <FileTitle>사진첨부 (선택)</FileTitle>
              <FileText>사진을 첨부해 주세요. (최대 1장)</FileText>
              <FileReviseBtnWrap>
                <FileReviseBtn
                  type="file"
                  id="filePic"
                  accept="image/*"
                  onChange={(e) => {
                    handleImageUpload(e.target.files);
                    uploadImage(e);
                  }}
                />
                <FileBtnLabel for="filePic">
                  {"사진 " + (imageArr.length > 0 ? "수정하기" : "첨부하기")}
                </FileBtnLabel>
              </FileReviseBtnWrap>
            </FileBox>
            {imageArr.map((el, index) => {
              return (
                <React.Fragment>
                  <UploadImageBox key={index + "imageBox"}>
                    {/* <DeleteButton onClick={deleteButtonClick}>X</DeleteButton> */}
                    <UploadImage alt="previewImg" src={previewImageArr[index]} />
                  </UploadImageBox>
                  <ImageName>{imageArr[index]?.name}</ImageName>
                </React.Fragment>
              );
            })}
            <FromWrap>
              <FromTitleWrap>
                <ListTitle>상품은 어떠셨나요?</ListTitle>
              </FromTitleWrap>
              <FromBox>
                <ReviewTextWrap>
                  <BtnWrap>
                    {BtnList?.map((value, index) => {
                      return (
                        <ButtonComp
                          active={index === satisfaction}
                          key={index + "btnList"}
                          state={index}
                          value={value}
                          setSatisfaction={setSatisfaction}
                        ></ButtonComp>
                      );
                    })}
                  </BtnWrap>
                </ReviewTextWrap>
              </FromBox>
            </FromWrap>
            <ReviewBox>
              <ReviewTitle>리뷰 작성</ReviewTitle>
              <ReviewText
                placeholder="리뷰를 작성해주세요."
                cols="30"
                rows="10"
                value={text}
                onChange={(e) => setText(e.target.value)}
              ></ReviewText>
            </ReviewBox>
            <Revise>
              <ReviseBtn onClick={patchReview}>수정</ReviseBtn>
            </Revise>
          </TopCont>
          <BtmCont>
            <InforText>
              - 포인트는 최초 작성한 리뷰를 기준으로 지급됩니다.
              <br />- 사진 첨부 시{" "}
              <Span>캡쳐, 도용, 유사상품 촬영, 동일상품 여부 식별이 불가능한 경우</Span>
              에는 등록이 거절되며 사유는 별도 안내되지 않습니다.
              <br />- 상품과 무관한 내용이나 사진, 동일 문자 반복 등의 부적합한 리뷰는 사전 경고
              없이 삭제 및 포인트 회수될 수 있습니다.
            </InforText>
          </BtmCont>
        </ScrollBox>
      </Content>
    </Container>
  );
}

const Container = styled.div``;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;

// 컨텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 680px;
  height: calc(100vh - 140px);
  border-radius: 11px;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 100;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (680 / 1320));
    height: calc(100vh - calc(100vw * (140 / 1320)));
    border-radius: calc(100vw * (11 / 1320));
  }

  @media screen and (max-width: 930px) {
    top: auto;
    bottom: 0;
    left: 0;
    transform: translate(0);
    width: 100%;
    height: calc(100vh - calc(100vw * (150 / 428)));
    border-radius: calc(100vw * (11 / 428)) calc(100vw * (11 / 428)) 0 0;
  }
`;
const TitleCont = styled.div`
  padding: 25px 25px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (25 / 1320)) calc(100vw * (25 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (25 / 428)) calc(100vw * (25 / 428)) 0;
  }
`;
const TopCont = styled.div`
  width: 100%;
  padding: 0 25px 50px;

  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (25 / 1320)) calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0 calc(100vw * (25 / 428)) calc(100vw * (50 / 428));
  }
`;
const BtmCont = styled.div`
  padding: 20px 25px 35px;
  background-color: #f7f7f7;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) calc(100vw * (25 / 1320)) calc(100vw * (35 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) calc(100vw * (25 / 428)) calc(100vw * (35 / 428));
  }
`;
const ScrollBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: revert;
  height: calc(100% - 90px);
  overflow: auto;

  @media screen and (max-width: 1320px) {
    height: calc(100% - calc(100vw * (90 / 1320)));
  }

  @media screen and (max-width: 930px) {
    height: calc(100% - calc(100vw * (90 / 428)));
  }
`;

// 상품정보
const Product = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const ProductImg = styled.img`
  width: 52px;
  height: 52px;
  object-fit: cover;
  margin-right: 8px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (52 / 1320));
    height: calc(100vw * (52 / 1320));
    margin-right: calc(100vw * (8 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (52 / 428));
    height: calc(100vw * (52 / 428));
    margin-right: calc(100vw * (8 / 428));
  }
`;
const BrandName = styled.div`
  font-size: 14px;
  color: #969696;
  font-weight: 500;
  margin-bottom: 4px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (4 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (4 / 428));
  }
`;
const ProductName = styled.div`
  font-size: 15px;
  color: #191919;
  font-weight: bold;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

// 사진첨부
const FileBox = styled.div`
  margin-bottom: 30px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (30 / 428));
  }
`;
const FileTitle = styled.h2`
  font-size: 16px;
  color: #191919;
  font-weight: bold;
  margin-bottom: 5px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-bottom: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (5 / 428));
  }
`;
const FileText = styled.p`
  font-size: 14px;
  margin-bottom: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (15 / 428));
  }
`;
const FileReviseBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FileReviseBtn = styled.input`
  display: none;
`;
const FileBtnLabel = styled.label`
  width: 100%;
  color: #000;
  border: 1px solid #000;
  border-radius: 11px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  padding: 16px 0 16px 25px;
  background: url(${IconFile}) no-repeat center left 250px / 20px;

  @media screen and (max-width: 1320px) {
    border: calc(100vw * (1 / 1320)) solid #000;
    border-radius: calc(100vw * (11 / 1320));
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (16 / 1320)) 0 calc(100vw * (16 / 1320)) calc(100vw * (25 / 1320));
    background: url(${IconFile}) no-repeat center left calc(100vw * (250 / 1320)) /
      calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    border: calc(100vw * (1 / 428)) solid #000;
    border-radius: calc(100vw * (11 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (12 / 428)) 0;
    background: url(${IconFile}) no-repeat center left calc(100vw * (240 / 428)) /
      calc(100vw * (20 / 428));
  }
`;
// 리뷰작성
const ReviewBox = styled.div`
  margin-bottom: 15px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (15 / 428));
  }
`;
const ReviewTitle = styled.h2`
  font-size: 16px;
  color: #191919;
  font-weight: bold;
  margin-bottom: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-bottom: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-bottom: calc(100vw * (14 / 428));
  }
`;
const ReviewText = styled.textarea`
  width: 100%;
  height: 170px;
  padding: 20px;
  border-radius: 11px;
  border: 1px solid #d9d9d9;
  overflow: auto;
  font-size: 16px;
  font-weight: 500;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (170 / 1320));
    padding: calc(100vw * (20 / 1320));
    border-radius: calc(100vw * (11 / 1320));
    border: calc(100vw * (1 / 1320)) solid #d9d9d9;
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (100 / 428));
    padding: calc(100vw * (20 / 428));
    border-radius: calc(100vw * (11 / 428));
    border: calc(100vw * (1 / 428)) solid #d9d9d9;
    font-size: calc(100vw * (16 / 428));
  }
`;

// 상단닫기 버튼
const Cancle = styled.div`
  margin-bottom: 2px;
  text-align: right;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (2 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (2 / 428));
  }
`;
const CancleImg = styled.img`
  cursor: pointer;
  width: 14px;
  height: 14px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (14 / 1320));
    height: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (14 / 428));
    height: calc(100vw * (14 / 428));
  }
`;
const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #000;
  text-align: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #646464;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    padding-bottom: calc(100vw * (24 / 1320));
    border-bottom: calc(100vw * (1 / 1320)) solid #646464;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    padding-bottom: calc(100vw * (24 / 428));
    border-bottom: calc(100vw * (1 / 428)) solid #646464;
  }
`;
// 텍스트수정버튼
const Revise = styled.div`
  width: 100%;
`;
const ReviseBtn = styled.div`
  cursor: pointer;
  width: 100%;
  height: 48px;
  line-height: 48px;
  color: #fff;
  background: #000;
  border-radius: 11px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (48 / 1320));
    line-height: calc(100vw * (48 / 1320));
    border-radius: calc(100vw * (11 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (40 / 428));
    line-height: calc(100vw * (40 / 428));
    border-radius: calc(100vw * (11 / 428));
    font-size: calc(100vw * (16 / 428));
  }
`;
// 안내문구
const InforText = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #969696;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (13 / 428));
    line-height: 1.5;
  }
`;
const Span = styled.span`
  font-weight: bold;
  color: #f24f3c;
`;

// form
const FromWrap = styled.div`
  width: 100%;
  padding: 25px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (25 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (25 / 428)) 0;
  }
`;
const FromBox = styled.div`
  width: 100%;
  margin-bottom: 14px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const FromTitleWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const ListTitle = styled.p`
  font-size: 16px;
  color: #191919;
  font-weight: bold;
  margin-bottom: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-bottom: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-bottom: calc(100vw * (14 / 428));
  }
`;
const ReviewTextWrap = styled.div`
  width: 100%;
`;
const BtnWrap = styled.div`
  display: flex;
  @media screen and (max-width: 930px) {
    width: 100%;
    justify-content: center;
    margin-top: calc(100vw * (25 / 428));
  }
`;
const UploadImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const UploadImageBox = styled.div`
  position: relative;
  cursor: pointer;
  width: 115px;
  height: 115px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  margin-right: 4px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (115 / 1320));
    height: calc(100vw * (115 / 1320));
    border-radius: calc(100vw * (5 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (115 / 428));
    height: calc(100vw * (115 / 428));
    border-radius: calc(100vw * (5 / 428));
  }
`;
const ImageName = styled.p`
  font-size: 12px;
  padding-top: 10px;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (12 / 1320));
    padding-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (10 / 428));
    padding-top: calc(100vw * (8 / 428));
  }
`;
const DeleteButton = styled.button`
  position: absolute;
  font-size: 20px;
  z-index: 2;
  color: #fff;
  top: 0;
  right: 0;
`;
