import { useEffect } from "react";
import { Mobile, PC } from "../../store_src/MediaQuery";

import Header from '../../components/common/header/Header';
import InquiryWrite from '../../store_src/component/service/InquiryWrite';
import Footer from '../../components/common/footer/Footer';

export default function InquiryWritePage() {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <>
      <Header search={true}/>
      <InquiryWrite title="1:1문의" rank={false} isOne/>
      <Footer/>
    </>
  );
}
