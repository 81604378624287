import React from "react";
import { Mobile, PC } from "../common/mediaStlye";
import { imgURL } from "../../../server/appInfoContoller";

import {
  Container,
  StylingProductsLayout,
  StylingProductsHeadings,
  ProductsSlider,
  ProductsSlide,
  ProductItem,
  ItemImg,
  ProductItemDesc,
  ProductLikeButton,
} from "./ContentDetailsPage.element";
import ContentProductItem from "./ContentProductItem";

import detailThumbimg1 from "../../../images/content/content-detail-prod1.png";
import detailThumbimg2 from "../../../images/content/content-detail-prod2.png";
import detailThumbimg3 from "../../../images/content/content-detail-prod3.png";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import "./ContentStylingProducts.css";

SwiperCore.use([Navigation]);

const ContentProducts = ({ data }) => {
  return (
    <>
      <PC>
        <Container>
          <StylingProductsLayout>
            <StylingProductsHeadings>Styling Products</StylingProductsHeadings>
            {data?.products.length > 0 ? (
              <ProductsSlider className="product-slider">
                <Swiper
                  slidesPerView={3}
                  spaceBetween={10}
                  navigation={{
                    prevEl: ".product-swiper-button-prev",
                    nextEl: ".product-swiper-button-next",
                  }}
                >
                  {data?.products?.map((v, i) => {
                    return (
                      <SwiperSlide>
                        <ProductsSlide>
                          <ContentProductItem key={v?.prd_id} data={v} />
                        </ProductsSlide>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>

                <div className="product-swiper-button-prev"></div>
                <div className="product-swiper-button-next"></div>
              </ProductsSlider>
            ) : (
              <div>연관된 상품이 없습니다.</div>
            )}
          </StylingProductsLayout>
        </Container>
      </PC>

      <Mobile>
        <Container style={{ marginBottom: "calc(100vw * (133 / 428))" }}>
          {data ? (
            <StylingProductsLayout>
              <StylingProductsHeadings>Styling Products</StylingProductsHeadings>

              <ProductsSlider className="product-slider">
                {data?.products && Array.isArray(data.products) && data.products.length > 0 ? (
                  <Swiper spaceBetween={15} slidesPerView={1.5}>
                    {data?.products?.map((item, i) => {
                      return (
                        <SwiperSlide>
                          <ProductsSlide>
                            <ContentProductItem key={item?.prd_id} data={item} />
                          </ProductsSlide>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                ) : (
                  <div>연관된 상품이 없습니다.</div>
                )}
              </ProductsSlider>
            </StylingProductsLayout>
          ) : null}
        </Container>
      </Mobile>
    </>
  );
};

export default ContentProducts;
