import { createAction, handleActions } from "redux-actions"
import produce from "immer"

// 액션 타입을 정의해줍니다.
const UPDATE_ACTIVETAB = "management/activeTab";

export const updateActiveTab = createAction(UPDATE_ACTIVETAB);

const initialState = {
    TabIndex:0,
};

export default handleActions(
    {
        [UPDATE_ACTIVETAB] : (state, action) => {
            const payload = action.payload;
            const tabIndex = payload.TabIndex;
            const stateRef = {...state};
            stateRef.TabIndex = tabIndex;
            // console.log(stateRef.TabIndex);
            return stateRef;
        },
    },
    initialState
)



