import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Layout,
} from '../auth.elements';
import {
    SignupBox,
    SignupButton,
    SuccessContainer,
    SuccessImg,
    SuccessText
} from './signup.element';
import signupsuccessImg from '../../../images/signup-success.png';

const StepSuccess = () => {
    const history = useHistory();
    return (
        <Container>
            <Layout>
                <SuccessContainer>
                    <SuccessImg><img src={signupsuccessImg} alt="완료 이미지" /></SuccessImg>
                    <SuccessText>
                        <h2>맞춤분석이 완료되었습니다!</h2>
                        <p>마이페이지에서 다시 이용하실수 있습니다</p>
                    </SuccessText>
                    <SignupBox>
                        <SignupButton onClick={() => history.push('/mypage')}>마이페이지 이동</SignupButton>
                    </SignupBox>
                </SuccessContainer>

            </Layout>
        </Container>
    )
}

export default StepSuccess
