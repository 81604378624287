import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";
import { imgURL } from "../../server/appInfoContoller";
import serverController from "../../server/serverController";
import openLink from "../../server/openLink";
import { Mobile, PC } from "../../MediaQuery";
import DefaultImg from "../../store_src/img/icon/loading_default_img.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Pagination,
  Navigation,
  EffectFade,
  Autoplay,
  Scrollbar,
} from "swiper";
import "swiper/swiper-bundle.css";

import {
  DramaItemLeft,
  DramaImg,
  DramaItemRight,
  ItemBackground,
  ItemHead,
  ItemProducts,
  Product,
  Arrows,
  ControllBox,
} from "./DramaItem.elements";

import ArrowLeft from "../../images/drama_arrow_left.png";
import ArrowRight from "../../images/drama_arrow_right.png";

const MainDramaItem = ({
  value,
  isContentModal,
  setIsContentModal,
  contentId,
  setContentId,
}) => {
  SwiperCore.use([Pagination, Navigation, EffectFade, Autoplay, Scrollbar]);
  const history = useHistory();
  const src = value?.contents_thumbnail?.split(",")[0] ?? [];

  const prdArr = value?.contents_products?.split(",");
  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    await serverController.connectFetchController(
      `contents/products/${value?.contents_id}`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setProducts(res.list);
          // setProducts(prev => prev.concat(res?.list ?? []));
        }
      },
      (err) => console.error(err)
    );
  };

  const pageLinkClick = (id, type, link) => {
    if (type == 3) {
      openLink.openLinkEvent(link);
      // window.open(`${link}`);
    } else {
      alert("런칭 준비중입니다 :D");
      // history.push(`/store/detail/${id}`);
    }
  };

  useEffect(() => {
    getProducts();
  }, [value]);

  const handlePagination = {
    el: ".item-pagination",
    type: "fraction",
    formatFractionCurrent: function (number) {
      return "0" + number;
    },
    formatFractionTotal: function (number) {
      return ("0" + number).slice(-2);
    },
  };

  return (
    <>
      <PC>
        <Item>
          <DramaItemLeft
            onClick={() => history.push(`/content/${value?.contents_id}`)}
          >
            <DramaImg>
              <img src={imgURL + src} />
            </DramaImg>
          </DramaItemLeft>

          <DramaItemRight>
            <ItemProduct>
              {products.map((v, i) => {
                return (
                  <Product
                    onClick={() => {
                      pageLinkClick(v?.prd_id, v?.prd_type, v?.prd_link);
                    }}
                  >
                    <div className="main_drama_thumb">
                      <img
                        src={
                          v?.prd_main_img
                            ? imgURL + v?.prd_main_img
                            : DefaultImg
                        }
                        alt={v?.prd_main_img ? "contents-img" : "default-img"}
                      />
                    </div>
                    <strong>{v?.prd_name}</strong>
                  </Product>
                );
              })}
              {/* {
                       products.length > 3 ?
                       <>
                            <Swiper
                                autoplay={{delay:1000}}
                                slidesPerView={3.5}
                                // pagination={handlePagination}
                                navigation={{
                                    prevEl: '.drama-prev-button',
                                    nextEl: '.drama-next-button',
                                }}
                                pagination={{
                                    El: '.dm-pagination',
                                    clickable: true,
                                    type: 'fraction',
                                }}
                            >
                                {
                                    products.slice(0, 10).map((v, i) => {
                                        return (
                                            <SwiperSlide>
                                                <Product onClick={()=>{pageLinkClick(v?.prd_id, v?.prd_type, v?.prd_link)}}>
                                                    <div className="main_drama_thumb">
                                                        <img src={imgURL + v?.prd_main_img}/>
                                                    </div>
                                                    <strong>{v?.prd_name}</strong>
                                                </Product>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                            <ControllBox>
                                <div className="drama-prev-button arrow-button">
                                    <img src={ArrowLeft} alt="" />
                                </div>                                 
                                <div className="dm-pagination"></div>
                                <div className="drama-next-button arrow-button">
                                    <img src={ArrowRight} alt="" />
                                </div>                                 
                            </ControllBox>
                        </>
                        :
                        products.map((v, i) => {
                            return (
                                    <Product onClick={()=>{pageLinkClick(v?.prd_id, v?.prd_type, v?.prd_link)}}>
                                        <div className="main_drama_thumb">
                                            <img src={imgURL + v?.prd_main_img}/>
                                        </div>
                                        <strong>{v?.prd_name}</strong>
                                    </Product>
                            )
                        })

                    } */}
            </ItemProduct>
            <ItemHead>
              <h4>{value?.contents_title}</h4>
              <p>{value?.contents_basic_content}</p>
            </ItemHead>
          </DramaItemRight>
        </Item>
      </PC>

      <Mobile>
        {value ? (
          <Item>
            <DramaImg
              onClick={() => {
                setIsContentModal(true);
                setContentId(value?.contents_id);
              }}
            >
              <img
                src={src.length > 0 ? imgURL + src : DefaultImg}
                alt={src ? "contents-img" : "default-img"}
              />
              <ItemDramaHead>
                <h4>{value?.contents_title}</h4>
                <p>{value?.contents_basic_content}</p>
              </ItemDramaHead>
            </DramaImg>
            <ItemProduct>
              {products &&
                products?.map((v, i) => {
                  if (!v?.prd_main_img) return null;
                  return (
                    <Product
                      onClick={() => {
                        pageLinkClick(v?.prd_id, v?.prd_type, v?.prd_link);
                      }}
                    >
                      <div className="main_drama_thumb">
                        <img
                          src={
                            v?.prd_main_img
                              ? imgURL + v?.prd_main_img
                              : DefaultImg
                          }
                          alt={v?.prd_main_img ? "product-img" : "default-img"}
                        />
                      </div>
                      <strong>{v?.prd_name}</strong>
                    </Product>
                  );
                })}
              {/* <Swiper
                      autoplay={{delay:1000}}
                      slidesPerView={2.5}
                      spaceBetween={10}
                  >
                      {
                          products && 
                          products?.map((v, i) => {
                              if (!v?.prd_main_img) return null
                              return (
                                  <SwiperSlide>
                                      <Product onClick={()=>{pageLinkClick(v?.prd_id, v?.prd_type, v?.prd_link)}}>
                                          <div className="main_drama_thumb">
                                              <img src={imgURL + v?.prd_main_img}/>
                                          </div>
                                          <strong>{v?.prd_name}</strong>
                                      </Product>
                                  </SwiperSlide>
                              )
                          })
                      }
                  </Swiper>  */}
              {/* <ControllBox>
                      <div className="drama-prev-button arrow-button">
                          <img src={ArrowLeft} alt="" />
                      </div>                                 
                      <div className="item-pagination"></div>                       
                      <div className="drama-next-button arrow-button">
                          <img src={ArrowRight} alt="" />
                      </div>                                 
                  </ControllBox> */}
            </ItemProduct>
          </Item>
        ) : null}
      </Mobile>
    </>
  );
};
export default MainDramaItem;

const Item = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  margin: 0 auto calc(100vw * (80 / 1920));

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    margin: 0 auto calc(100vw * (40 / 428));
    /* &::after {
            top: calc(100vw*(50/428)); left: calc(100vw*(-25/428));
            height: calc(100vw * (320 / 375));
        } */
    &:last-child {
      padding-bottom: calc(100vw * (100 / 428));
    }
  }
`;

const ItemDramaHead = styled(ItemHead)`
  @media screen and (max-width: 1024px) {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: calc(100vw * (20 / 428)) calc(100vw * (20 / 428))
      calc(100vw * (20 / 428));
    min-height: calc(100vw * (74 / 428));
    margin-bottom: 0;
    overflow: visible;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(1, 1, 1, 0.5);
      filter: blur(calc(100vw * (20 / 428)));
      width: 100%;
      height: 100%;
    }
    & h4 {
      position: relative;
      color: #fff;
      line-height: 1.8;
      z-index: 1;
      font-size: calc(100vw * (16 / 428));
    }
  }
`;

const ItemProduct = styled(ItemProducts)`
  justify-content: flex-start;
  width: calc(100vw * (800 / 1920));
  & .swiper-container {
    padding-bottom: calc(100vw * (37 / 1920));
  }
  & .swiper-slide {
    opacity: 1 !important;
  }
  & .swiper-pagination-fraction {
    bottom: calc(100vw * (-6 / 1920));
    left: calc(100vw * (288 / 1920));
    font-size: calc(100vw * (26 / 1920));
  }
  & .swiper-pagination-fraction span {
    font-size: calc(100vw * (26 / 1920));
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    gap: calc(100vw * (18 / 428));
    overflow: hidden;
    & .swiper-container {
      width: 100%;
      padding-bottom: calc(100vw * (12 / 428));
    }
    & .swiper-pagination-fraction {
      bottom: calc(100vw * (-6 / 428));
      left: calc(100vw * (100 / 428));
      font-size: calc(100vw * (26 / 428));
    }
    & .swiper-pagination-fraction span {
      font-size: calc(100vw * (26 / 428));
    }
  }
`;
