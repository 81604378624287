//react
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery"

//css
import styled from "styled-components"

// img
import TopIcon from "../../img/icon/icon_top_arrow.png"


export default function FixedTop() {

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

  return (
    <Container>
        <TopBtn onClick={()=>{scrollTop()}}>
            <TopImg src={TopIcon} />
        </TopBtn>
    </Container>
  );
}
const Container = styled.div`
    margin: 0 auto;
`

const TopBtn = styled.div`
    position: fixed;
    width: 55px; height: 55px;
    background-color: #f1f1f1;
    border-radius: 100%;
    padding: 18px;
    cursor: pointer;
    right: 27px;
    bottom: 100px;
    z-index: 11;
    cursor: pointer;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(55/1320)); height: calc(100vw*(55/1320));
        padding: calc(100vw*(18/1320));
        right: calc(100vw*(27/1320));
        bottom: calc(100vw*(100/1320));
    }
`

const TopImg = styled.img`
    display: block;
    width: 20px; height: 21px;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(21/1320)); height: calc(100vw*(21/1320));
    }
`