import React, { useState } from "react";
import styled from "styled-components";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../components/common/header/Header";
import Footer from "../../components/common/footer/Footer";
import BrandArchive from "../../components/brand/BrandArchive";
import FooterMenu from "../../components/common/footer/FooterMenu";

export default function BrandArchivePage() {
  return (
    <Container>
      <PC>
        <Header />
        <BrandArchive />
        <Footer />
      </PC>
      <Mobile>
        <Header />
        <BrandArchive />
        <Footer />
        <FooterMenu />
      </Mobile>
    </Container>
  );
}
const Container = styled.div`
  @media screen and (max-width: 1024px) {
    padding-bottom: calc(100vw * (68 / 428));
  }
`;
