//react
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import serverController from "../../server/serverController";

//css
import styled from "styled-components";

//page
import PasswordModal from "../common/modal/PasswordModal";
import FaqLayout from "./FaqLayout";
import NewPagination from "../common/pagination/NewPagination";

export default function InquiryList() {
  const [isModal, setIsModal] = useState(false);
  const [faqList, setFaqList] = useState([]);

  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState([]);

  const getFaqList = () => {
    serverController.connectFetchController(`fnqList?page=${page}`, "GET", null, (res) => {
      if (res.result === 1) {
        setPageData(res.pagination);
        setFaqList(res.list);
      }
    });
  };

  useEffect(() => {
    getFaqList();
  }, []);

  return (
    <Container>
      <InquiryListWrap>
        <TitleWrap>
          <Title>자주묻는질문</Title>
        </TitleWrap>

        <FaqLayout type={2} list={faqList} setIsModal={setIsModal} />
        {faqList.length > 0 && <NewPagination page={page} setPage={setPage} pageData={pageData} />}
      </InquiryListWrap>

      {isModal && <PasswordModal setIsModal={setIsModal} />}
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
`;
const InquiryListWrap = styled.div`
  width: 1300px;
  margin: 0 auto;
  padding-bottom: 200px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
    padding-bottom: calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    padding-bottom: calc(100vw * (100 / 428));
  }
`;
const TitleWrap = styled.div`
  width: 100%;
  margin-bottom: 25px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (25 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (25 / 428));
  }
`;
const Title = styled.h2`
  font-size: 24px;
  color: #000;
  font-weight: bold;
  margin-bottom: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
    margin-bottom: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    margin-bottom: calc(100vw * (10 / 428));
  }
`;

const WriteWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const WriteBtn = styled.p`
  width: 100px;
  font-size: 15px;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 8px 0;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #000;
    color: #fff;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    font-size: calc(100vw * (15 / 1320));
    border: calc(100vw * (1 / 1320)) solid #000;
    border-radius: calc(100vw * (10 / 1320));
    padding: calc(100vw * (8 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    font-size: calc(100vw * (15 / 428));
    border: calc(100vw * (1 / 428)) solid #000;
    border-radius: calc(100vw * (10 / 428));
    padding: calc(100vw * (8 / 428)) 0;
  }
`;
