import { useState, useEffect } from 'react';
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery"
import { useSelector } from "react-redux";
import styled from "styled-components"
import serverController from '../../../server/serverController'
//css
import IconLine from "../../../img/icon/icon_point_line.png"
import Commas from "../../../server/Commas";

export default function GongPointInfo({ data1 }) {
    const { mem_point } = useSelector(state => state.userData.userInfo)
    const [data, setData] = useState({})
    const getPoint = async () => {
        await serverController.connectFetchController(
            `point/total`,
            "GET",
            null,
            (res) => {
                if (res?.result === 1) {
                    setData()
                }
            }
        )
    }

    useEffect(() => {
        getPoint()
    }, [])
  return (
    <Container>
        <GongPointInfoWrap>
            <StepBox>
                <Content>
                    <Cont>
                        <Title>사용가능</Title>
                        <AmountBlue>{Commas.numberWithCommas(data1?.totalPoint ?? 0)}<PointBlue>P</PointBlue></AmountBlue>
                    </Cont>
                    <Cont>
                        <Title>적립 된 포인트</Title>
                        <AmountBlue>+{Commas.numberWithCommas(data1?.earnPoint ?? 0)} <PointBlue>P</PointBlue></AmountBlue>
                    </Cont>
                    <Cont>
                        <Title>사용한 포인트</Title>
                        <AmountRed>-{Commas.numberWithCommas(data1?.usedPoint ?? 0)}<PointRed>P</PointRed></AmountRed>
                    </Cont>
                    {/* <Cont>
                        <Extinction>소멸예정 포인트<br/><Subtext>(30일 이내)</Subtext></Extinction>
                        <AmountRed>-30,850 <PointRed>P</PointRed></AmountRed>
                    </Cont> */}
                </Content>
            </StepBox>
            <Text>포인트(WALA)는 30일 이내로 사용하지 않을 시, 소멸됩니다.</Text>
        </GongPointInfoWrap>
    </Container>
  );
}


const Container = styled.div`
    width: 100%;
    padding-bottom: 54px;

    @media screen and (max-width: 1320px) {
        padding-bottom: calc(100vw*(54/1320));
    }

    @media screen and (max-width: 930px) {
        padding-bottom: calc(100vw*(40/428));
        margin-bottom: calc(100vw*(30/428));
        border-bottom: calc(100vw*(16/428)) solid #E9EBEE;
    }
`
const GongPointInfoWrap = styled.div`
    width: 100%;

    @media screen and (max-width: 930px) {
        width: calc(100vw*(388/428));
        margin: 0 auto;
    }
`
const StepBox = styled.div`
    margin: 50px auto 17px;
    padding: 29px 0 36px;
    border: 1px solid #dddddd;
    border-radius: 11px;

    @media screen and (max-width: 1320px) {
        margin: calc(100vw*(50/1320)) auto calc(100vw*(17/1320));
        padding: calc(100vw*(29/1320)) 0 calc(100vw*(36/1320));
        border: calc(100vw*(1/1320)) solid #dddddd;
        border-radius: calc(100vw*(11/1320));
    }

    @media screen and (max-width: 930px) {
        margin: calc(100vw*(20/428)) auto calc(100vw*(15/428));
        padding: 0;
        border: calc(100vw*(1/428)) solid #dddddd;
        border-radius: calc(100vw*(11/428));
        background: url(${IconLine}) no-repeat center center/calc(100vw*(92/428));
    }
`
const Content = styled.div`
    display: flex;
    align-items:center;
    justify-content: center;

    @media screen and (max-width: 930px) {
        flex-wrap: wrap;
    }
`
const Cont = styled.div`
    width: calc(100%/4);
    padding: 0 80px;
    text-align: center;
    border-right: 1px dashed #969696;
    &:last-child{
        border-right:none;
    }

    @media screen and (max-width: 1320px) {
        padding: 0 calc(100vw*(80/1320));
        border-right: calc(100vw*(1/1320)) dashed #969696;
    }

    @media screen and (max-width: 930px) {
        width: calc(100%/2);
        padding: calc(100vw*(30/428));
        border-right: none;
    }
`
const Title = styled.h2`
    font-size: 20px;
    color: #000;
    font-weight: 500;
    margin-bottom: 18px;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(20/1320));
        margin-bottom: calc(100vw*(18/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(18/428));
        margin-bottom: calc(100vw*(18/428));
    }
`
const Extinction = styled.h2`
    font-size: 20px;
    color: #000;
    font-weight: 500;
    margin-bottom: 7px;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(20/1320));
        margin-bottom: calc(100vw*(7/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(18/428));
        margin-bottom: 0;
    }
`
const Subtext = styled.span`
    font-size: 15px;
    color: #000;
    font-weight: 500;
    
    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(15/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(14/428));
    }
`
const AmountBlue = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 22px;
    color: #000;
    
    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(22/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(18/428));
    }
`
const AmountRed = styled(AmountBlue)`
    color: #f24f3c;
`
const PointBlue = styled.span`
    font-size: 20px;
    color: #000;
    margin-left: 5px;
    
    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(20/1320));
        margin-left: calc(100vw*(5/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(18/428));
        margin-left: calc(100vw*(3/428));
    }
`
const PointRed = styled(PointBlue)`
    color: #f24f3c;
`
const Text = styled.p`
    font-size: 16px;
    color: #969696;
    font-weight: 500;
    text-align: right;
    
    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(16/1320));
    }
    @media screen and (max-width: 930px) {
        text-align: center;
        font-size: calc(100vw*(14/428));
    }
`
