import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { imgURL } from "../../../server/appInfoController";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";
import Commas from "../../../server/Commas";

import ProductImg1 from "../../../../images/content/content-detail-prod1.png";
import ProductImg2 from "../../../../images/content/content-detail-prod2.png";
import ProductImg3 from "../../../../images/content/content-detail-prod3.png";

export default function PayProduct() {
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    setItemList(JSON.parse(localStorage.selectList ?? []));
  }, []);

  return (
    <Container>
      <PayProductWrap>
        <TitleWrap>
          <Title>주문상품</Title>
        </TitleWrap>
        <ProductListWrap>
          {itemList.map((value, index) => {
            let cart_items = JSON.parse(value.cart_items);
            console.log(cart_items);
            return (
              <ProductBox>
                <ProductContentWrap>
                  <ProductImgWrap>
                    <ProductImg src={imgURL + cart_items.prd_img} alt="" />
                  </ProductImgWrap>
                  <ProductTextWrap>
                    <ProductText>{cart_items?.prd_title}</ProductText>
                    <ProductPrice>
                      {Commas.numberWithCommas(cart_items.prd_list_price)}원
                    </ProductPrice>
                    <OptionBox>
                      <OptionTitle>옵션:</OptionTitle>
                      {cart_items.data.length > 0
                        ? cart_items.data.map((value, index) => {
                            let opArr = [];
                            for (let i in value.options.optionList) {
                              opArr.push(value.options.optionList[i]);
                            }
                            console.log(opArr);
                            return (
                              <ProductPriceBox>
                                <OptionTextWrap>
                                  {opArr[0].length > 0
                                    ? opArr[0].map((items, index) => {
                                        return (
                                          <React.Fragment>
                                            <ProductOption>
                                              {items.opt_title +
                                                ": " +
                                                items.opt_name}
                                            </ProductOption>
                                            <i>,</i>
                                          </React.Fragment>
                                        );
                                      })
                                    : null}
                                </OptionTextWrap>
                                <ProductCount>{value.count}개</ProductCount>
                              </ProductPriceBox>
                            );
                          })
                        : null}
                    </OptionBox>
                  </ProductTextWrap>
                </ProductContentWrap>
              </ProductBox>
            );
          })}
        </ProductListWrap>
      </PayProductWrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (30 / 428));
    border-top: calc(100vw * (12 / 428)) solid #e9ebee;
  }
`;
const PayProductWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    margin: 0 auto;
  }
`;
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #d9d9d9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (10 / 428));
    border-bottom: none;
  }
`;
const Title = styled.p`
  font-size: 22px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
// Product List
const ProductListWrap = styled.div`
  width: 100%;
  margin-bottom: 30px;
  padding-top: 20px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (30 / 1320));
    padding-top: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (30 / 428));
    padding-top: calc(100vw * (20 / 428));
  }
`;
const ProductBox = styled.div`
  width: 100%;
  margin-bottom: 8px;
  border-radius: 11px;
  border: 1px solid #d9d9d9;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (8 / 1320));
    border-radius: calc(100vw * (11 / 1320));
    border: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (8 / 428));
    border-radius: calc(100vw * (11 / 428));
    border: calc(100vw * (1 / 428)) solid #d9d9d9;
  }
`;
const ProductTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
  background: #f7f7f7;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (15 / 1320)) calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (15 / 428)) calc(100vw * (20 / 428));
  }
`;
const ProductTitle = styled.p`
  font-size: 17px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (17 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const ProductContentWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428));
  }
`;
const ProductImgWrap = styled.div`
  width: 120px;
  height: 120px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (120 / 1320));
    height: calc(100vw * (120 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (80 / 428));
    height: calc(100vw * (80 / 428));
  }
`;
const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ProductTextWrap = styled.div`
  width: calc(100% - 100px);
  padding-left: 15px;

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (100 / 1320)));
    padding-left: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (80 / 428)));
    padding-left: calc(100vw * (15 / 428));
  }
`;
const ProductText = styled.p`
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const OptionTextWrap = styled.div`
  display: flex;
  align-items: center;
  & i {
    font-size: 15px;
    color: #969696;
    padding: 0 2px;
  }
  & i:last-child {
    display: none;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    & i {
      font-size: calc(100vw * (15 / 1320));
      padding: 0 calc(100vw * (2 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (13 / 428));
    & i {
      font-size: calc(100vw * (13 / 428));
      padding: 0 calc(100vw * (2 / 428));
    }
  }
`;
const ProductOption = styled.p`
  font-size: 15px;
  color: #969696;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (13 / 428));
  }
`;
const OptionBox = styled.p`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const OptionTitle = styled.p`
  font-size: 15px;
  margin-right: 5px;
  margin-bottom: 2px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-right: calc(100vw * (5 / 1320));
    margin-bottom: calc(100vw * (2 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-right: calc(100vw * (5 / 428));
    margin-bottom: calc(100vw * (2 / 428));
  }
`;
const ProductPriceBox = styled.div`
  display: flex;
  align-items: flex-end;
  background: #f7f7f7;
  padding: 1px 15px;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 4px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (1 / 1320)) calc(100vw * (15 / 1320));
    border-radius: calc(100vw * (3 / 1320));
    margin-right: calc(100vw * (10 / 1320));
    margin-bottom: calc(100vw * (4 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (1 / 428)) calc(100vw * (15 / 428));
    border-radius: calc(100vw * (3 / 428));
    margin-right: calc(100vw * (10 / 428));
    margin-bottom: calc(100vw * (2 / 428));
  }
`;
const ProductPrice = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-bottom: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const ProductCount = styled.span`
  position: relative;
  font-size: 15px;
  font-weight: normal;
  color: #777777;
  margin-left: 8px;
  padding-left: 8px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 4px;
    display: block;
    width: 1px;
    height: 14px;
    background: #d9d9d9;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (8 / 1320));
    padding-left: calc(100vw * (8 / 1320));
    &:before {
      top: calc(100vw * (4 / 1320));
      width: calc(100vw * (1 / 1320));
      height: calc(100vw * (14 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-left: calc(100vw * (8 / 428));
    padding-left: calc(100vw * (8 / 428));
    &:before {
      top: calc(100vw * (3 / 428));
      width: calc(100vw * (1 / 428));
      height: calc(100vw * (14 / 428));
    }
  }
`;
