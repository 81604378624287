import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const selectColors = [
  {
    colors: ["#E7E3DC", "#DFD2C6", "#CAB19A"],
    title: "톤온톤",
  },
  {
    colors: ["#E27513", "#43770C", "#706C5B"],
    title: "톤앤톤",
  },
  {
    colors: ["#FFD4D9", "#FFF9C5", "#CAE5FF"],
    title: "파스텔",
  },
  {
    colors: ["#9400EF", "#F5DB35", "#CF0070"],
    title: "비비드",
  },
  {
    colors: ["#F9FF0B", "#FF00A4", "#4DFF6F"],
    title: "네온",
  },
  {
    colors: ["#fff", "#000"],
    title: "모노",
  },
];

const StepColorBox = ({ colorItem, setClickColorArr }) => {
  const [isClick, setIsClick] = useState(false);

  useEffect(() => {
    setClickColorArr((prev) => {
      const newArr = [...prev];
      if (isClick) {
        return newArr.concat(colorItem?.title);
      } else {
        return newArr.filter((v) => v !== colorItem?.title);
      }
    });
  }, [isClick]);

  return (
    <StepColor onClick={() => setIsClick(!isClick)}>
      <Colors selected={isClick}>
        {colorItem?.colors.map((color, idx) => (
          <ColorChip key={`${color}-colorChip-${idx}`} color={color}></ColorChip>
        ))}
      </Colors>
      <p>{colorItem?.title}</p>
    </StepColor>
  );
};

export default function StepFour(props) {
  const { clickColorArr, setClickColorArr, postPersonalData } = props;
  const history = useHistory();
  const onClickNextStep = () => {
    if (clickColorArr.length === 0) return;
    postPersonalData();
  };

  useEffect(() => {
    setClickColorArr([]);
  }, []);

  return (
    <Container>
      <SelectColorTextWrap>
        선호하는 색상을 선택해주세요. <em>(중복 선택 가능)</em>
      </SelectColorTextWrap>
      <SelectColorImageWrap>
        {selectColors.map((colorItem, idx) => {
          return (
            <StepColorBox
              key={`${colorItem.title}-selectColor-${idx}`}
              colorItem={colorItem}
              setClickColorArr={setClickColorArr}
            />
          );
        })}
      </SelectColorImageWrap>
      <StepNextButtonWrap>
        <StepPrevButton onClick={() => history.push(`/store/mypage/personal/3`)}>
          이전으로
        </StepPrevButton>
        <StepNextButton isActive={clickColorArr?.length > 0} onClick={onClickNextStep}>
          다음으로
        </StepNextButton>
      </StepNextButtonWrap>
    </Container>
  );
}

const Container = styled.div``;
const SelectColorTextWrap = styled.p`
  text-align: center;
  font-weight: 900;
  font-size: 16px;
  margin-bottom: 40px;
  > em {
    color: #d9d9d9;
  }
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-bottom: calc(100vw * (40 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (40 / 428));
  }
`;
const SelectColorImageWrap = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
const StepNextButtonWrap = styled.div`
  text-align: center;
  display: flex;
  margin-top: 100px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (100 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (100 / 428));
  }
`;
const StepNextButton = styled.button`
  margin: 0 auto;
  background-color: #999;
  color: #fff;
  font-size: 16px;
  height: 59px;
  width: 300px;
  ${({ isActive }) => isActive && `background-color : #000;`}
  @media screen and (max-width: 1320) {
    font-size: calc(100vw * (16 / 1320));
    height: calc(100vw * (59 / 1320));
    width: calc(100vw * (300 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    height: calc(100vw * (59 / 428));
    width: calc(100vw * (185 / 428));
  }
`;
const StepPrevButton = styled(StepNextButton)`
  border: 1px solid #222;
  background-color: #fff;
  color: #222;
`;

const StepColor = styled.li`
  width: 48%;
  margin-bottom: 44px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (44 / 1320));
    text-align: center;
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (44 / 428));
    text-align: center;
  }
`;
const Colors = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  align-items: center;
  opacity: 0.2;
  transition: all 0.3s ease;
  margin-bottom: 10px;
  ${({ selected }) => selected && `opacity : 1;`}
  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const ColorChip = styled.span`
  display: block;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  border: 1px solid #d9d9d9;
  width: 44px;
  height: 44px;
  margin: 0 3px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (44 / 1320));
    height: calc(100vw * (44 / 1320));
    margin: 0 calc(100vw * (3 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (44 / 428));
    height: calc(100vw * (44 / 428));
    margin: 0 calc(100vw * (3 / 428));
  }
`;
