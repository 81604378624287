import styled, { css } from "styled-components";
import {
  pc,
  mobil,
  Media,
  Px,
  Width,
  Height,
  Top,
  Bottom,
  Left,
  Right,
  FontSize,
  Margin,
  Margin2,
  Padding,
  Padding2,
  MT,
  MB,
  ML,
  MR,
  PT,
  PB,
  PL,
  PR,
  LineH,
  mquery,
} from "../common/mediaStlye";

export const MoreButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 188px;
    height: 51px;
    font-size: 18px;
    font-weight: 900;
    border: 2px solid #222;
    border-radius: 37px;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(188/1320));
        height: calc(100vw*(51/1320));
        font-size: calc(100vw*(18/1320));
        border: calc(100vw*(2/1320)) solid #222;
        border-radius: calc(100vw*(37/1320));
    }
    @media screen and (max-width: 930px) {
        width: calc(100vw*(163/428));
        height: calc(100vw*(44/428));
        font-size: calc(100vw*(16/428));
        line-height: 0;
        border: calc(100vw*(2/428)) solid #222;
        border-radius: calc(100vw*(37/428));
    }
`;

export const Container = styled.div`
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 0 0 150px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1000 / 1320));
    padding: 0 0 calc(100vw * (150 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    padding: calc(100vw * (18 / 428)) 0 calc(100vw * (50 / 428));
  }
`;

export const LayOut = styled.div`
  width: 1000px;

    @media screen and (max-width: 930px) {
    width: 100%;
    ${Padding2(0, 0, mobil)}
  }
`;
export const ContentHeading = styled.h2`
  font-size: 35px;
  font-weight: bold;
  color: #222;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (35 / 1320));
  }
    @media screen and (max-width: 930px) {
    ${FontSize(19, mobil)}
  }
`;

// ContentDetailsMain
export const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (20 / 428));
  }
`;

export const DetailMainBanner = styled.div`
  width: 440px;
  height: 480px;
  & div {
    width: 100%;
    height: 100%;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (440 / 1320));
    height: calc(100vw * (480 / 1320));
  }

    @media screen and (max-width: 930px) {
    width: 100%;
    ${Height(300, mobil)}
    ${MB(8, mobil)}
  }
`;

export const DetailMainContents = styled.div`
  flex: 1;
  padding-left: 16px;

  @media screen and (max-width: 1320px) {
    padding-left: calc(100vw * (16 / 1320));
  }

    @media screen and (max-width: 930px) {
    padding-left: 0;
  }
`;

export const DetailMainDesc = styled.div`
  padding: 0 28px;
  margin-bottom: 68px;
  
  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (28 / 1320));
    margin-bottom: calc(100vw * (68 / 1320));
  }

    @media screen and (max-width: 930px) {
    padding: 0;
  }
`;

export const DescHead = styled.div`
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d9d9d9;

  & p {
    font-size: 14px;
    font-weight: 400;
    color: #a5a5a5;
  }

  & h2 {
    font-size: 22px;
    color: #222;
    font-weight: 900;
  }

  & .like-button {
    width: 18px;
  }

  & .like-button img {
    width: 100%;
  }

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (10 / 1320));
    margin-bottom: calc(100vw * (20 / 1320));
    & p {
      font-size: calc(100vw * (14 / 1320));
    }

    & h2 {
      font-size: calc(100vw * (22 / 1320));
    }
    
    & .like-button {
      width: calc(100vw * (18 / 1320));
    }
  }

    @media screen and (max-width: 930px) {
    ${PB(8.5, mobil)}

    & p {
      ${FontSize(14, mobil)}
    }
    & h2 {
      ${FontSize(20, mobil)}
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const LikeButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 14px;
  width: 18px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    bottom: calc(100vw * (14 / 1320));
    width: calc(100vw * (18 / 1320));
  }

    @media screen and (max-width: 930px) {
    ${Width(20, mobil)}
    bottom:calc(100vw * (32 / 428));

    & > img {
      width: 100%;
    }
  }
`;

export const DescContent = styled.div`
  & p {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 14;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    /* padding-right: calc(100vw * (50 / 1920)); */
  }

  & ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }

    @media screen and (max-width: 930px) {
    ${MB(150, mobil)}
    padding: 0;
    font-size: calc(100vw * (14 / 428));

    & p {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      text-align: left;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
    /* & p br + br {display: none;} */
  }
`;

export const MobileTagList = styled.ul`
  width: 100%;
  ${Margin2(17, 0, mobil)}

    @media screen and (max-width: 930px) {
    & .swiper-slide {
      /* flex-shrink: 1; */
      width: auto;
    }
  }
`;

export const Tag = styled.li`
  margin-right: calc(100vw * (8 / 1920));
  margin-bottom: calc(100vw * (10 / 1920));
  padding: calc(100vw * (5 / 1920)) calc(100vw * (19 / 1920));
  font-size: calc(100vw * (14 / 1920));
  background-color: #f2f2f2;
  border-radius: calc(100vw * (41 / 1920));
  color: #000;

    @media screen and (max-width: 930px) {
    margin: 0;
    ${[
      FontSize(13, mobil),
      //    MR(7, mobil),
      Padding2(5, 17, mobil),
    ].join("")}
    max-height: ${Px(28, mobil)};
    white-space: nowrap;
    display: inline-block;
    width: auto;
    line-height: 1;
  }
`;

export const DetailMainGallery = styled.div`
  width: 1000px;
  margin: 0 auto;

  & > div {
    width: 100%;
    height: auto;
  }

  & > div img {
    display: block;
    width: 100%;
  }

    @media screen and (max-width: 930px) {
    width: 100%;
    gap: ${Px(7, mobil)};

    & > div {
      flex: 1;
      ${MB(8, mobil)}
    }
  }
`;

export const FashionsContainer = styled.div`
  padding: calc(100vw * (70 / 1920)) 0 calc(100vw * (50 / 1920));
  display: flex;
  justify-content: flex-start;
  gap: ${Px(15, pc)};
  align-items: center;
  flex-wrap: wrap;
`;

export const FashionItem = styled.div``;
export const Item = styled.div`
  width: calc(100vw * (428 / 1920));
  height: calc(100vw * (413 / 1920));
  /* margin-bottom: calc(100vw * (20 / 1920)); */
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &:hover .item_head {
    opacity: 1;
  }

    @media screen and (max-width: 930px) {
    width: 100%;
    height: auto;
    ${[MB(0, mobil)].join("")}
    & .item_head {
      opacity: 1;
    }
  }
`;

export const ItemImg = styled.div`
  width: 100%; height: 100%;
  line-height: 0;

  & > img {
    width: 100%; height: 100%;
    object-fit: cover;
  }
    @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (250 / 428));
    & > img {
      width: 100%; height: 100%;
      object-fit: cover;
    }
  }
`;

export const ItemDesc = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 22px;
  padding-left: 24px;
  padding: 25px 52px 25px 30px;
  height: 200px;
  background-color: rgba(34, 34, 34, 0.9);
  opacity: 0;
  transition: all 0.3s ease;

  & > em {
    display: block;
    font-size: 13px;
    color: #fff200;
    padding-bottom: 11px;
  }

  & > h4 {
    font-size: 25px;
    font-weight: 900;
    padding-bottom: 19px;
    color: #fff;
  }

  & > p {
    padding-right: 30px;
    /* line-height: 1.5; */
    color: #fff;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
  }

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (22 / 1320));
    padding-left: calc(100vw * (24 / 1320));
    padding: calc(100vw * (25 / 1320)) calc(100vw * (52 / 1320))
      calc(100vw * (25 / 1320)) calc(100vw * (30 / 1320));
    height: calc(100vw * (201 / 1320));

    & > em {
      font-size: calc(100vw * (13 / 1320));
      padding-bottom: calc(100vw * (11 / 1320));
    }

    & > h4 {
      font-size: calc(100vw * (25 / 1320));
      padding-bottom: calc(100vw * (19 / 1320));
    }
    & > p {
      font-size: calc(100vw * (14 / 1320));
      padding-right: calc(100vw * (30 / 1320));
    }
  }

    @media screen and (max-width: 930px) {
    position: static;
    ${[MB(0, mobil), Height(152, mobil), Padding(23, 44, 31, 31, mobil)].join(
      ""
    )}

    & > em {
      ${[FontSize(13, mobil), PB(4.5, mobil)].join("")}
    }
    & > h4 {
      ${[FontSize(20, mobil), PB(9.9, mobil)].join("")}
    }
    & > p {
      ${[FontSize(15, mobil), PR(0, mobil)].join("")}
      -webkit-line-clamp: 1;
    }
  }
`;

// ContentStylingProducts
export const StylingProductsLayout = styled(LayOut)`
  position: relative;
  width: 100%;

    @media screen and (max-width: 930px) {
    width: 100%;
    ${Padding2(0, 0, mobil)}
  }
`;
export const StylingProductsHeadings = styled(ContentHeading)`
  margin-bottom: calc(100vw * (36 / 1920));
  font-size: calc(100vw * (22 / 1920));
  color: #222;
  text-align: left;
  font-weight: 900;
  text-transform: uppercase;

    @media screen and (max-width: 930px) {
    ${FontSize(18, mobil)}
    padding: 0 calc(100vw * (25 / 428));
    margin-bottom: calc(100vw * (40 / 428));
  }
`;
export const ProductsSlider = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & .swiper-container {
    width: 100%;
  }
    @media screen and (max-width: 930px) {
    display: block;
  }
`;

export const ProductsSlide = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

    @media screen and (max-width: 930px) {
    width: 100%;
    height: auto;
  }
`;

export const ProductItemDesc = styled(ItemDesc)`
  padding: 32px 43px 16px 29px;
  width: 100%; height: 123px;
  background-color: rgba(255, 242, 0, 0.53);

  & > em {
    display: block;
    font-size: 10px;
    color: #222;
    padding-bottom: 5px;
  }

  & > h4 {
    font-size: 15px;
    font-weight: 400;
    padding-bottom: 15px;
    color: #606060;
  }

  & > span {
    display: block;
    text-align: right;
  }

  @media screen and (max-width: 1320px) {
    padding: calc(100vw*(32/1320)) calc(100vw*(43/1320)) calc(100vw*(16/1320)) calc(100vw*(29/1320));
    width: 100%; height: calc(100vw*(123/1320));

    & > em {
      font-size: calc(100vw*(10/1320));
      padding-bottom: calc(100vw*(5/1320));
    }

    & > h4 {
      font-size: calc(100vw*(15/1320));
      padding-bottom: calc(100vw*(15/1320));
    }

  }
    @media screen and (max-width: 930px) {
    position: static; height: auto;
    padding: calc(100vw*(15/428)) calc(100vw*(25/428));

    & > em {
      ${FontSize(13, mobil)}
      ${PB(2, mobil)}
    }
    & > h4 {
      font-size: calc(100vw*(15/428));
      ${PB(5, mobil)}
    }
    & > span {
      font-size: calc(100vw*(14/428));
    }
  }
`;
export const ProductItem = styled(Item)`
    position: relative;
    width: 100%; height: 366px;
    background-color: #ebebeb;
    @media screen and (max-width: 1320px) {
      height: calc(100vw*(366/1320));
    }

    @media screen and (max-width: 930px) {
      width: 100%; height: 100%;
    }
`;

export const ProductLikeButton = styled(LikeButton)`
  bottom: auto; top: 20px; right: 20px;
  width: 20px;

  & > img {
    width: 100%;
  }
    @media screen and (max-width: 1320px) {
      bottom: auto; top: 20px; right: 20px;
      width: 20px;
    }

    @media screen and (max-width: 930px) {
      display: flex;
      align-items: center;
      justify-content: center;
      top: calc(100vw*(10/428)); right: calc(100vw*(10/428));
      width: calc(100vw * (35 / 428)); height: calc(100vw * (35 / 428));

    & img {
      width: calc(100vw*(20/428));
    }
  }
`;
// export const ProductItem = styled(Item)``
// export const ProductItem = styled(Item)``
// export const ProductItem = styled(Item)``
