import React, { useState, useEffect } from 'react';
import faker from 'faker';
import { Link, useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import {    
    Layout,
    TabItemsContent,
    TabItemsHeading,
    TabItemConts,
    TabItemImg,
    TabItemDesc,
    TabItemText,
    TabItemLiked,
    TabItemsList
} from './mypage.elements';
import LikedIcon from '../../../images/stylist/liked_icon.svg';
import serverController from '../../../server/serverController';
import { imgURL } from '../../../server/appInfoContoller';
import TabContentItemList from './TabContentItemList';
import TabContentItemList2 from './TabContentItemList2';
import MyPageContentsIntro from './MyPageContentsIntro';

const MyPageContents = ({setActiveIndex ,activeIndex}) => {
    
    const [data, setData] = useState([]);
    const [itemData, setItemData] = useState([])
    
    const history = useHistory()

    const getData = async () => {
        await serverController.connectFetchController(
            `contentslike`,
            "GET",
            null,
            (res) => {
                console.log(res, "컨텐츠 좋아요 목록 res")
                if (res?.result === 1) {
                    setData(res?.list ?? []);
                }
            }
        )
    }

    useEffect(() => {
        getData()
    }, [])

    const getItem = async () => {
        await serverController.connectFetchController(
            `products/like`,
            "GET",
            null,
            (res) => {
                console.log(res, "아이테ㅔ메메메메메멤")
                if (res?.result === 1) {
                    setItemData(res?.list ?? []);
                }
            }
        )
    }

    useEffect(() => {
        getItem()
    }, [])

    const renderItems = () => {
        switch(activeIndex) {
            case 0: 
                return <MyPageContentsIntro data={data} itemData={itemData} setActiveIndex={setActiveIndex}/>
            case 1: 
                return <TabContentItemList  data={data} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
            case 2: 
                return <TabContentItemList2 itemData={itemData} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
            default: 
                return;
        }
    }

    return (
        <Container>
            {renderItems()}
        </Container>
    )
}

const Container = styled.div`
    @media screen and (max-width: 1024px) {
        min-height: calc(100vh - calc(100vw*(450/428)));
    }
`
export default MyPageContents
