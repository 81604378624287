//react
import { useState,useEffect } from "react";
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery"

//css
import styled from "styled-components"

export default function DeleteModal({title,setIsModal,deleteReceiver}) {

  return (
    <Container>
        <Background onClick={()=>{setIsModal(false)}}/>
        <Content>
            <TitleCont>
                <Title>{title}</Title>
            </TitleCont>
            <BtnWrap>
                <CancelButton type="button" onClick={()=>{setIsModal(false)}}>취소</CancelButton>
                <ConfirmButton type="button" onClick={()=>{setIsModal(false); deleteReceiver()}}>확인</ConfirmButton>
            </BtnWrap>
        </Content>
    </Container>
  );
}

const Container = styled.div`
    width: 100%;
`
const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;
`
// 컨텐츠
const Content = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;
    width: 398px;
    height: auto;
    padding: 0 25px 30px;
    background: #fff;
    overflow: hidden;

    @media screen and (max-width: 1320px) {
        width: calc(100vw * (398 / 1320));
        padding: 0 calc(100vw * (25 / 1320)) calc(100vw * (30 / 1320));
    }
    @media screen and (max-width: 930px) {
        width: calc(100vw * (398 / 428));
        padding: 0 calc(100vw * (25 / 428)) calc(100vw * (30 / 428));
    }
`
const TitleCont = styled.div`
    padding: 50px 0 40px;

    @media screen and (max-width: 1320px) {
        padding: calc(100vw * (50 / 1320)) 0 calc(100vw * (40 / 1320));
    }

    @media screen and (max-width: 930px) {
        padding: calc(100vw * (50 / 428)) 0 calc(100vw * (40 / 428)); 
    }
`
const Title = styled.h2`
    color: #333;
    font-weight: 400;
    font-size: 16px;
    text-align: center;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw * (16 / 1320));
    }
    @media screen and (max-width: 930px) {
        font-size: calc(100vw * (16 / 428));
    }
`
const BtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`
const CancelButton = styled.button`
    width: 169px;
    height: 50px;
    color: #333;
    font-weight: 500;
    font-size: 18px;
    background-color: #fff;
    border: 1px solid #000;

    @media screen and (max-width: 1320px) {
        width: calc(100vw * (169 / 1320));
        height: calc(100vw * (50 / 1320));
        font-size: calc(100vw * (18 / 1320));
    }
    @media screen and (max-width: 930px) {
        width: calc(100vw * (169 / 428));
        height: calc(100vw * (50 / 428));
        font-size: calc(100vw * (18 / 428));
    }
`
const ConfirmButton = styled(CancelButton)`
    color: #fff;
    background-color: #000;
`