import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { DateToSuccess } from '../common/commonUse';
import styled from 'styled-components';

import {
    RegisterSuccessWrap,
} from './stylist.element';

import successIcon1 from '../../images/stylist/regist-success-1.png';
import successIcon2 from '../../images/stylist/regist-success-2.png';
import successIcon3 from '../../images/stylist/regist-success-3.png';

const StylistRegisterSuccess = () => {
    const history = useHistory();
    const state = useLocation()
    const { open_type } = useParams();
    const [successData, setSuccessData] = useState([
        {
            src: successIcon1,
            name: "공개",
            desc: "공개하신 컨턴츠는 검색DB에 저장되어 사용자 검색 쿼리와 함께 노출 됩니다."
        },
        {
            src: successIcon2,
            name: "비공개",
            desc: "비공개로 설정하신 컨텐츠는 검색DB에 저장지만 “공개”로 전환하시기 전까지 사용자에게 노출되지 않습니다."
        },
        {
            src: successIcon3,
            name: "“예약 공개”",
            desc: "21.09.09월 00시 공개 됩니다."
        },
    ])
    const [date, setDate] = useState("")
    const [id, setId] = useState("")

    useEffect(() => {
        if (open_type == 2) {
            setDate(history?.location?.state?.date);
            setSuccessData(prev => {
                const newArr = [...prev];
                console.log(DateToSuccess(history?.location?.state?.date))
                newArr[2].desc = `${DateToSuccess(history?.location?.state?.date)} 공개 됩니다.`
                return newArr;
            })
        } else if (open_type === 0) {
            setId(state?.state?.id)
        }
    }, [state])
    return (
        <RegisterSuccessWrap unopend={open_type === "1"}>
            <div className="regist-success-wrap">
                <div>
                    <img src={successData[Number(open_type)].src} alt="" />
                </div>
                <h3>{successData[Number(open_type)].name}로 설정하신 콘텐츠 등록이 완료되었습니다.</h3>
                <p>{successData[Number(open_type)].desc}</p>
                <div className="btn_box">
                    {open_type == 0 && <button onClick={() => history.push(`/content/${state?.state?.id}`)}>콘텐츠 보러가기</button>}
                    <button onClick={() => history.push('/stylist/regist')}>이어서 등록하기</button>
                </div>
            </div>
        </RegisterSuccessWrap>
    )
}

export default StylistRegisterSuccess

const BtnBox = styled.div`
    /* display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; */
    /* gap: 10px; */
`