import React, {useState , useEffect} from 'react';
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";

//components
// import {ArchiveBtn} from '../productManagement/ProductPopup';
// import {ArchiveNoBtn} from '../productManagement/ProductPopup';

//css
import styled from "styled-components"
import "./../../App.css"

//img
import icon from "../../img/brand/benner_icon.svg";
import arrow from "../../img/brand/arrow.png";
import arrow2 from "../../img/brand/arrow2.png";
import del from "../../img/brand/del_icon.png";
import prev from "../../img/brand/prev.png";
import next from "../../img/brand/next.png";
import top from "../../img/brand/top.png";

export default function BrandArchive() {
    // const [popupActive , setPopupActive] = useState(false);
    const [listData, setListData] = useState([]);

    useEffect(() => {
        const brandList = [
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del},
            {date:'2021.05.09', num:'CA0905TT', title:'노드비메이드 트렌치 코트' , email:'nodeBmade@node.com', sail:'협찬', del:del}
        ]
        setListData(brandList)
    }, [])

    return (
        <>
            <PC>
                {/* ${popupActive && <ArchiveBtn/>} */}
                <BrandArchiveWrap>
                    <BrandArchiveHead>
                        <BrandText>Node be Made Incorporation</BrandText>
                        <BrandTextImg>
                            <img src={icon} alt="icon"/>
                        </BrandTextImg>
                    </BrandArchiveHead>

                    <BrandArchiveContentWrap>
                        <BrandArchivePrevWrap>
                            <BradnArchivePrev>
                                <BrandArchiveImg>
                                    <img src={arrow} alt="arrow"/>
                                </BrandArchiveImg>
                                <BrandArchiveText>제품 관리</BrandArchiveText>
                            </BradnArchivePrev>

                            <BrandDisplayWrap>
                                <BrandArchiveImg2>
                                    <img src={arrow2} alt="arrow" />
                                </BrandArchiveImg2>
                                <BrandArchiveText2>제품 Display 바로가기</BrandArchiveText2>
                            </BrandDisplayWrap>
                        </BrandArchivePrevWrap>

                        <BrandArchiveListWrap> 
                            <BrandArchiveListHead>
                                <BrandArchiveInput type="checkbox"></BrandArchiveInput>
                                <BrandArchiveDate>날짜</BrandArchiveDate>
                                <BrandArchiveNum>품번</BrandArchiveNum>
                                <BrandArchiveTitle>품명</BrandArchiveTitle>
                                <BrandArchiveEmail>이메일</BrandArchiveEmail>
                                <BrandArchiveSail>협찬/대여/판매</BrandArchiveSail>
                                <BrandArchiveDel>선택 삭제</BrandArchiveDel>
                                <BrandArchiveDelIcon>
                                    <img src={del} alt="del"/>
                                </BrandArchiveDelIcon>
                            </BrandArchiveListHead>

                            {
                                listData.map((item, index) => {
                                    return(
                                        <BrandArchiveList key={index}>
                                            <BrandArchiveInput type="checkbox"></BrandArchiveInput>
                                            <BrandArchiveListDate>{item.date}</BrandArchiveListDate>
                                            <BrandArchiveListNum>{item.num}</BrandArchiveListNum>
                                            <BrandArchiveListTitle>{item.title}</BrandArchiveListTitle>
                                            <BrandArchiveListEmail>{item.email}</BrandArchiveListEmail>
                                            <BrandArchiveListSail>{item.sail}</BrandArchiveListSail>
                                            <BrandArchiveListDel>
                                                <img src={item.del} alt="del" />
                                            </BrandArchiveListDel>
                                        </BrandArchiveList>
                                    )
                                })
                            }

                            <PagingWrap>
                                <PagingIcon>
                                    <img src={prev} alt="prev" />
                                </PagingIcon>
                                <Paging>1</Paging>
                                <Paging>2</Paging>
                                <Paging>3</Paging>
                                <Paging>4</Paging>
                                <Paging>5</Paging>
                                <PagingIcon>
                                    <img src={next} alt="prev" />
                                </PagingIcon>
                            </PagingWrap>

                        </BrandArchiveListWrap>

                        <TopBtnWrap>
                            <img src={top} alt="top" />
                        </TopBtnWrap>

                    </BrandArchiveContentWrap>
                </BrandArchiveWrap>
            </PC>

            <Mobile>
                {/* ${popupActive && <ArchiveBtn/>} */}
                <BrandArchiveWrap>
                    <BrandArchiveHead>
                        <BrandText>Node be Made Incorporation</BrandText>
                        {/* <BrandTextImg>
                            <img src={icon} alt="icon"/>
                        </BrandTextImg> */}
                    </BrandArchiveHead>

                    <BrandArchiveContentWrap>
                        <BrandArchivePrevWrap>
                            <BrandDisplayWrap>

                                <BrandArchiveImg2>
                                    <img src={arrow2} alt="arrow" />
                                </BrandArchiveImg2>
                                <BrandArchiveText2>제품 Display 바로가기</BrandArchiveText2>
                            </BrandDisplayWrap>

                                <BrandArchiveDelWrap>
                                    <BrandArchiveDel>선택 삭제</BrandArchiveDel>
                                    <BrandArchiveDelIcon> 
                                        <img src={del} alt="del" />
                                    </BrandArchiveDelIcon>
                                </BrandArchiveDelWrap>

                        </BrandArchivePrevWrap>

                        <BrandArchiveListWrap> 
                            <BrandArchiveListHead>
                                <BrandArchiveInput type="checkbox"></BrandArchiveInput>
                                <BrandArchiveDate>날짜</BrandArchiveDate>
                                <BrandArchiveNum>품번</BrandArchiveNum>
                                <BrandArchiveTitle>품명</BrandArchiveTitle>
                                <BrandArchiveEmail>이메일</BrandArchiveEmail>
                                <BrandArchiveSail>협찬/대여/판매</BrandArchiveSail>
                            </BrandArchiveListHead>

                            {
                                listData.map((item, index) => {
                                    return(
                                        <BrandArchiveList key={index}>
                                            <BrandArchiveInput type="checkbox"></BrandArchiveInput>
                                            <BrandArchiveListDate>{item.date}</BrandArchiveListDate>
                                            <BrandArchiveListNum>{item.num}</BrandArchiveListNum>
                                            {/* 길어지면 텍스트 ...처리 되게끔 해야함. */}
                                            <BrandArchiveListTitle>{item.title}</BrandArchiveListTitle>
                                            <BrandArchiveListEmail>{item.email}</BrandArchiveListEmail>
                                            <BrandArchiveListSail>{item.sail}</BrandArchiveListSail>
                                        </BrandArchiveList>
                                    )
                                })
                            }

                            <PagingWrap>
                                <PagingIcon>
                                    <img src={prev} alt="prev" />
                                </PagingIcon>
                                <Paging>1</Paging>
                                <Paging>2</Paging>
                                <Paging>3</Paging>
                                <Paging>4</Paging>
                                <Paging>5</Paging>
                                <PagingIcon>
                                    <img src={next} alt="prev" />
                                </PagingIcon>
                            </PagingWrap>

                        </BrandArchiveListWrap>

                    </BrandArchiveContentWrap>
                </BrandArchiveWrap>

            </Mobile>
        </>
    )
}

const BrandArchiveWrap = styled.div`
    width: 100%;
`
const BrandArchiveHead = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 141px;
    background-color: #f7f7f8;
    padding: 40px 0;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    height: calc(100vw*(141/1300));
    padding: calc(100vw*(40/1300)) 0;
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    height: calc(100vw*(141/428));
    padding: calc(100vw*(40/428)) 0;
}

`
const BrandArchiveContentWrap = styled.div`
    position: relative;
    width: calc(100vw*(1300/1920));
    margin: 57px auto;
    padding-bottom: 153px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    // width: calc(100vw*(900/1300));
    margin: calc(100vw*(57/1300)) auto;
    padding-bottom: calc(100vw*(153/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(428/428));
    margin: calc(100vw*(57/428)) auto;
    padding-bottom: calc(100vw*(153/428));
}

`
const BrandText = styled.p`
    font-family: "Avenir";
    font-weight: 900;
    font-size: 39px;
    text-align: center;
    color: #1f1f1f;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(39/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(20/428));
}

`
const BrandTextImg = styled.div`
    width: 38px;
    height: 38px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(38/1300));
    height: calc(100vw*(38/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(38/428));
    height: calc(100vw*(38/428));
}

`
const BrandArchivePrevWrap = styled.div`
    display: flex;
    justify-content: space-between;

    /* 모바일 */
  @media screen and (max-width: 1025px) {
    padding: 0 calc(100vw*(10/428));
}
`   
const BradnArchivePrev = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    gap: calc(100vw*(25/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    gap: calc(100vw*(25/428));
}

`
const BrandArchiveImg = styled.div`
    width: 8px;
    height: 16px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(8/1300));
    height: calc(100vw*(16/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(8/428));
    height: calc(100vw*(16/428));
}

`
const BrandArchiveText = styled.span`
    font-family: Avenir;
    font-size: 25px;
    font-weight: 900;
    color: #1f1f1f;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(25/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(25/428));
}

`
const BrandDisplayWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    gap: calc(100vw*(10/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    gap: calc(100vw*(10/428));
}

`
const BrandArchiveImg2 = styled.div`
    width: 17px;
    height: 15px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(17/1300));
    height: calc(100vw*(15/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(17/428));
    height: calc(100vw*(15/428));
}

`
const BrandArchiveText2 = styled.span`
    font-family: Avenir;
    font-size: 16px;
    font-weight: 900;
    color: #222222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(16/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(16/428));
}

`
const BrandArchiveListWrap = styled.div`

`
const BrandArchiveListHead = styled.ul`
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
    height: 57px;
    margin-top: 40px;
    padding: 0 60px 0 78px;
    background-color: #f7f7f8;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    height: calc(100vw*(57/1300));
    margin-top: calc(100vw*(40/1300));
    padding: 0;
    white-space: nowrap;
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    height: calc(100vw*(57/428));
    margin-top: calc(100vw*(40/428));
    padding: 0 calc(100vw*(10/428));
    white-space: nowrap;
}

`
const BrandArchiveInput = styled.input`
    width: 20px;
    height: 20px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(20/1300));
    height: calc(100vw*(20/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(20/428));
    height: calc(100vw*(20/428));
}

`
const BrandArchiveDate = styled.li`
    width: 260px;
    text-align: center;
    font-size: 17px;
    font-family: qblique;
    color: #101010;
    font-weight: 500;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(260/1300));
    font-size: calc(100vw*(17/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(100/428));
    font-size: calc(100vw*(13/428));
}

`
const BrandArchiveNum = styled.li`
    font-size: 17px;
    font-family: qblique;
    color: #101010;
    font-weight: 500;
    padding-left: 10px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(17/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(13/428));
}

`
const BrandArchiveTitle = styled.li`
    width: 400px;
    font-size: 17px;
    font-family: qblique;
    color: #101010;
    font-weight: 500;
    text-align: center;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(10/1300));
    padding-left: calc(100vw*(60/1300));
    font-size: calc(100vw*(17/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(100/428));
    padding-left: calc(100vw*(10/428));
    font-size: calc(100vw*(13/428));
}

`
const BrandArchiveEmail = styled.li`
    width: 150px;
    font-size: 17px;
    font-family: qblique;
    color: #101010;
    font-weight: 500;
    text-align: center;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(150/1300));
    font-size: calc(100vw*(17/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(150/428));
    font-size: calc(100vw*(13/428));
}

`
const BrandArchiveSail = styled.li`
    width: 180px;
    padding-left: 50px;
    font-size: 17px;
    font-family: qblique;
    color: #101010;
    font-weight: 500;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(180/1300));
    padding-left: calc(100vw*(50/1300));
    font-size: calc(100vw*(17/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(150/428));
    font-size: calc(100vw*(13/428));
}

`
const BrandArchiveDelIcon = styled.li`
    width: 20px;
    height: 20px;
    list-style: none;

    /* 모바일 */
  @media screen and (max-width: 1025px) {
    list-style: none;
}
`
const BrandArchiveDelWrap = styled.div`
    width: 0px;
    display: flex;
    gap: 5px;
    align-items: center;
`
const BrandArchiveDel = styled.li`
    font-size: 17px;
    font-family: qblique;
    color: #101010;
    font-weight: 500;
    list-style: none;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(17/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(13/428));
    list-style: none;
}
`
const BrandArchiveList = styled.ul`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 23px 35px;
    border-bottom: 1px solid #e6e6e6;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    padding: calc(100vw*(23/1300)) calc(100vw*(20/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
      gap: 10px;
    padding: calc(100vw*(23/428)) calc(100vw*(10/428));
}

`
const BrandArchiveListDate = styled.li`
    font-size: 17px;
    font-family: Avenir;
    color: #616161;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(17/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(12/428));
}

`
const BrandArchiveListNum = styled.li`
    font-size: 17px;
    font-family: Avenir;
    color: #616161;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(17/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(12/428));
}

`
const BrandArchiveListTitle = styled.li`
    font-size: 17px;
    font-family: Avenir;
    color: #616161;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(17/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(12/428));
}

`
const BrandArchiveListEmail = styled.li`
    font-size: 17px;
    font-family: Avenir;
    color: #616161;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(17/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(12/428));
}

`
const BrandArchiveListSail = styled.li`
    font-size: 17px;
    font-family: Avenir;
    color: #616161;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(17/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(12/428));
}

`
const BrandArchiveListDel = styled.li`
    width: 20px;
    height: 24px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(20/1300));
    height: calc(100vw*(24/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(20/428));
    height: calc(100vw*(24/428));
}

`
const PagingWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 77px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    margin-top: calc(100vw*(77/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    margin-top: calc(100vw*(77/428));
}

`
const Paging = styled.span`
    font-size: 16px;
    font-weight: 900;
    color: #a5a5a5;
    font-family: Avenir;
    padding: 0 41px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(16/1300));
    padding: 0 calc(100vw*(41/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    font-size: calc(100vw*(16/428));
    padding: 0 calc(100vw*(25/428));
}

`
const PagingIcon = styled.div`
    width: 12px;
    height: 24px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(12/1300));
    height: calc(100vw*(24/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(12/428));
    height: calc(100vw*(24/428));
}

`
const TopBtnWrap= styled.div`
    position: absolute;
    right: -10%;
    bottom: 8%;
    width: 59px;
    height: 59px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(59/1300));
    height: calc(100vw*(59/1300));
}
  /* 모바일 */
  @media screen and (max-width: 1025px) {
    width: calc(100vw*(59/428));
    height: calc(100vw*(59/428));
}

`