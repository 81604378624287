import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../../components/common/header/Header";
import Complete from "../../component/common/complete/Complete";
import Footer from "../../component/common/StoreFooter";
import FooterMenu from "../../component/common/FooterMenu";
import FixMenu from "../../component/common/FixMenu";

export default function ServiceCompletePage() {
  return (
    <>
      <Header search={true} />
      <Complete
        title="문의글 작성을 완료했습니다."
        btn="문의글 바로가기"
        url="/inquiry"
        rank={false}
      />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
      <FixMenu />
    </>
  );
}
