import React, { useState } from "react";

import Header from "../../component/common/Header";

import FixMenu from "../../component/common/FixMenu";
import BrandDetail from "../../component/brand/BrandDetail";
import MyPageDefaultModal from "../../component/common/modal/MyPageDefaultModal";
import { useHistory, useLocation } from "react-router-dom";

export default function BrandDetailPage() {
  const location = useLocation();
  const history = useHistory();
  const [loginPop, setLoginPop] = useState(false);
  return (
    <>
      {loginPop && (
        <MyPageDefaultModal
          text="로그인 후 이용 가능합니다."
          trueText="로그인"
          trueFunc={() => {
            if (location) return history.push(`/store/login?returnTo=${location.pathname}`);
            history.push(`/store/login`);
          }}
          falseFunc={() => setLoginPop(false)}
        />
      )}
      <Header back />
      <BrandDetail setLoginPop={setLoginPop} />
      <FixMenu />
    </>
  );
}
