import { useEffect } from "react";
import styled from "styled-components";
import LoadingImg from "../../../img/icon/icon_loading.gif";

export default function FullLoading() {
  const body = document.querySelector("body");

  useEffect(() => {
    body.style.overflow = "hidden";
    body.style.height = "100vh";
    return () => {
      body.style.overflow = "auto";
      body.style.height = "auto";
    };
  }, []);

  return (
    <Container>
      <LoadingIconBox>
        <LoadingIconImg src={LoadingImg} alt="loading-icon" />
      </LoadingIconBox>
    </Container>
  );
}
const Container = styled.div`
  height: 100vh;
  background-color: rgb(255 255 255 / 70%);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
`;
const LoadingIconBox = styled.div`
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  @media screen and (max-width: 930px) {
    width: calc(100vw * (140 / 428));
    height: calc(100vw * (140 / 428));
  }
`;
const LoadingIconImg = styled.img`
  width: 100%;
  height: 100%;
`;
