import { useInfiniteQuery } from "react-query";

export default function useInfiniteScroll({ queryKey, requestAPI, options, requestQueryString }) {
  const fetchUrl = async ({ pageParam = 1 }) => {
    const response = await requestAPI({ ...requestQueryString, page: pageParam });
    return response;
  };

  const { data, fetchNextPage, hasNextPage, isLoading, isFetching, isSuccess, isError, refetch } = useInfiniteQuery(
    queryKey,
    (pageParam = 1) => {
      return fetchUrl(pageParam);
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.pagination?.page <= lastPage.pagination?.maxPage ? lastPage.pagination.page + 1 : undefined;
      },
      ...options,
      retry: 0,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isError,
    refetch,
    isSuccess,
  };
}
