import styled from "styled-components";

export default function SoldOutCmp() {
  return (
    <SoldOutWrap>
      <SoldoutLabel>SOLD OUT</SoldoutLabel>
    </SoldOutWrap>
  )
};

const SoldOutWrap = styled.div`
   pointer-events: none;
`
const SoldoutLabel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 0 16px;
  background-color: rgba(0, 0, 0, .3);
  color: #fff;
  word-break: keep-all;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    padding: 0 calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    padding: 0 calc(100vw * (16 / 428));
  }
`