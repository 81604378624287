import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Layout,
    AuthIntroMessage,
} from '../auth.elements';
import {
    LoginIntroBtns,
    LoginButton
} from './Login.elements';

const LoginIntro = () => {
    const history = useHistory();
    const [prevPath, setPrevPath] = React.useState('');

    React.useEffect(() => {
        setPrevPath(sessionStorage.getItem("prevPath"));
    }, []);

    const loginPathHandle = (pageNum) => {
        if (prevPath === '/') {
            history.push(`/login/${pageNum}`);
            return;
        }
        history.push(`/login/${pageNum}?returnTo=${prevPath}`);
    }
    return (
        <Container>
            <Layout>
                <AuthIntroMessage>
                    <h2>Login</h2>
                    <p>WALA에 로그인 후 다양한 서비스를 이용하실 수 있습니다.</p>
                </AuthIntroMessage>

                <LoginIntroBtns>
                    {/* <LoginButton primary onClick={() => history.push('/login/1')}>일반 회원</LoginButton> */}
                    <LoginButton onClick={() => loginPathHandle(2)}>브랜드 회원</LoginButton>
                    <LoginButton onClick={() => loginPathHandle(3)}>스타일리스트 회원</LoginButton>
                </LoginIntroBtns>
            </Layout>
        </Container>
    )
}

export default LoginIntro
