import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import api from "../../../server/serverController";
import styled from "styled-components";
import Arrow from "../../img/icon/section_arrow.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Scrollbar } from "swiper";
import { useSelector } from "react-redux";
import { postLikeProducts } from "../../../hooks/likeHooks";

// img
import IconWishOn from "../../img/icon/icon_wish_on.svg";
import IconWishOff from "../../img/icon/icon_wish_off.svg";

//page
import Commas from "../../server/Commas";
import { imgURL } from "../../server/appInfoController";

export default function MDProduct({ value, setLoginPop }) {
  const history = useHistory();
  const { isLogged } = useSelector((state) => state.userData);

  const [isWish, setIsWish] = useState(value.like_id !== 0);
  const [touch, setTouch] = useState(false);

  const detailClick = () => {
    history.push(`/store/detail/${value.prd_id}`);
  };

  const onHandleLike = async () => {
    const res = await postLikeProducts(value.prd_id);
    setIsWish(res?.result === 1);
  };

  return (
    <Container>
      <BestNewImgBox>
        <BestNewImg src={`${imgURL}${value?.prd_thumbnail}`} alt="hot-img" onClick={detailClick} />
        <PC>
          <WishIcon
            src={isWish ? IconWishOn : IconWishOff}
            alt="wish"
            onClick={() => {
              if (isLogged) {
                onHandleLike();
              } else {
                setLoginPop(true);
              }
            }}
          />
        </PC>
        <Mobile>
          <WishIcon
            src={isWish ? IconWishOn : IconWishOff}
            alt="wish"
            onClick={() => {
              if (isLogged) {
                onHandleLike();
              } else {
                setLoginPop(true);
              }
            }}
            onTouchStart={() => {
              setTouch(true);
            }}
            onTouchEnd={() => {
              setTouch(false);
            }}
            touch={touch}
          />
        </Mobile>
      </BestNewImgBox>
      <MDTextBox onClick={detailClick}>
        <BestNewImgTitle>{value.bc_title || "(BRAND)"}</BestNewImgTitle>
        <BestNewImgText>{value?.prd_name}</BestNewImgText>
        {value?.prd_sale_rate === 0 ? (
          <PriceBox>
            <OriginalPrice original>.</OriginalPrice>
            <BestNewPriceWrap>
              <Price>{Commas.numberWithCommas(value?.prd_list_price)}원</Price>
            </BestNewPriceWrap>
          </PriceBox>
        ) : (
          <PriceBox>
            <OriginalPrice>{Commas.numberWithCommas(value?.prd_price)}원</OriginalPrice>
            <BestNewPriceWrap>
              <SaleRate>{Commas.numberWithCommas(value?.prd_sale_rate)}%</SaleRate>
              <Price>{Commas.numberWithCommas(value?.prd_list_price)}원</Price>
            </BestNewPriceWrap>
          </PriceBox>
        )}
      </MDTextBox>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  /* min-width: calc(100vw * (630 / 1920)); */
  width: 100%;
  padding-right: 90px;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1320px) {
    /* min-width: calc(100vw * (630 / 1320)); */
    padding-right: calc(100vw * (90 / 1320));
    margin-bottom: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    /* min-width: auto; */
    padding-right: 0;
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const BestNewImgBox = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 200px;
  height: 200px;
  overflow: hidden;
  margin-right: 12px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (200 / 1320));
    height: calc(100vw * (200 / 1320));
    margin-right: calc(100vw * (12 / 1320));
  }

  @media screen and (max-width: 930px) {
    border: calc(100vw * (1 / 428)) solid #f8f8f8f8;
    width: calc(100vw * (120 / 428));
    height: calc(100vw * (120 / 428));
    margin-right: calc(100vw * (12 / 428));
  }
`;
const BestNewImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media screen and (max-width: 930px) {
  }
`;
const WishIcon = styled.img`
  transition: ease 0.3s all;
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: block;
  width: 24px;
  height: 24px;

  @media screen and (max-width: 1320px) {
    bottom: calc(100vw * (10 / 1320));
    right: calc(100vw * (10 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    bottom: calc(100vw * (10 / 428));
    right: calc(100vw * (10 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
    ${({ touch }) =>
      touch &&
      `
      scale : 0.85;
        
    `}
  }
`;
const MDTextBox = styled.div`
  cursor: pointer;
  padding-top: 88px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (88 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: 0;
    width: calc(100vw * (246 / 428));
  }
`;
const BestNewImgTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #777777;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const BestNewImgText = styled.p`
  font-size: 15px;
  color: #333333;
  margin-top: 5px;

  word-break: keep-all;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (5 / 428));
  }
`;
const BestNewPriceWrap = styled.div`
  padding-top: 4px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (4 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (4 / 428));
  }
`;
const SaleRate = styled.p`
  color: #333333;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding: 2px 4px;
  margin-right: 5px;
  border-radius: 3px;
  background: #fdf251;
  line-height: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    padding: calc(100vw * (2 / 1320)) calc(100vw * (4 / 1320));
    margin-right: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    padding: calc(100vw * (2 / 428)) calc(100vw * (4 / 428));
    margin-right: calc(100vw * (5 / 428));
  }
`;
const Price = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #333333;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const PriceBox = styled.div`
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (10 / 428));
  }
`;
const OriginalPrice = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #acacac;
  text-decoration: line-through;
  margin-bottom: 4px;
  ${({ original }) => original && `color :transparent;`}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (4 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (4 / 428));
  }
`;
