import React, { useState } from 'react';
import styled from 'styled-components';

import Header from '../../components/common/header/Header';
import Footer from '../../components/common/footer/Footer';

import SearchProduct from '../../components/management/SearchProduct';
import ProductListOrder from '../../components/management/ProductListOrder';
import Pagination from '../../components/management/Pagination';
// import {ArchiveBtnPopup, CompletePopup, UpdateOrderPopup} from '../../components/productManagement/ProductPopup';

export default function ManagementListOrder({activeTab, setActiveTab}){
    return(
        <>
            {/* <ArchiveBtnPopup/> */}
            <Header />
            <SearchProduct/>
            <ProductListOrder activeTab={activeTab} setActiveTab={setActiveTab}/>
            <Pagination/>
            <SaveBtn>저장</SaveBtn>
            <Footer />
        </>
    )
}

const SaveBtn = styled.button`
    display:block; width:278px; margin:0 auto; margin-top:calc(100vw * (105 / 1920)); text-align:center; background:#222222; color:#ffffff; padding:17px 0; font-size:16px;
    @media screen and (max-width:768px){
        margin-top:calc(100vw * (85 / 428));
        max-width:162px; width:100%; padding:16px 0;
    }
`;

