import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { postLikeProducts } from "../../hooks/likeHooks";
import { ip } from "../../server/serverController";
import { imgURL } from "../../server/appInfoContoller";
import { PaymentAction } from "../../store/actionCreators";
import serverController from '../../server/serverController';
import axios from 'axios';

import { StyleSponListItem, PurchaseBox, LikedIcon } from "./stylist.element";

const StylistProductItem = ({ v, myList, mydata }) => {
  const history = useHistory();
  const [isLiked, setIsLiked] = useState(false);
  const moveDetail = (id) => history.push(`/stylist/sponserd/${id}`);

  const src = v.purchase_prd ? imgURL + v?.purchase_prd?.prd_thumbnail : imgURL + v?.prd_thumbnail;

  const name = v?.purchase_prd ? v?.purchase_prd?.prd_name : v?.prd_name;

  const prdId = v?.purchase_prd ? v?.purchase_prd?.prd_id : v?.prd_id;

  const onHandleLike = async () => {
    const res = await postLikeProducts(v?.prd_id);
    setIsLiked(res?.result === 1);
  };

  useEffect(() => {
    const liked = v?.purchase_prd ? v?.purchase_prd?.like_id : v?.like_id;
    setIsLiked(Boolean(liked));
  }, [v]);

  const handleSponPayment = () => {
    PaymentAction.resetData();
    PaymentAction.saveSponserdPaymentInfo(v ?? {});
    history.push(`/stylist/payment`);
  };

  const deletePurChaseCancel = () => {
    const checkCancel = window.confirm('취소하시겠습니까?');
    if (!checkCancel) return;
    axios.delete(`${ip}purchase/cancel?purchaseId=${v?.purchase_id}`)
      .then((res) => {
        if (res.data.result === 1) {
          alert(res.data.msg);
          history.go(0);
        }
        else {
          alert('오류가 발생했습니다. 다시 시도해주세요.');
        }
      })
  };

  const formatStatus = () => {
    switch (v?.purchase_state) {
      case "1":
        return <button style={{ cursor: "pointer" }}>승인대기</button>;
      case "2":
        return (
          <PurchaseBox>
            <button style={{ cursor: "default", background: "#222", color: "#fff" }}>
              승인완료
            </button>
            {/* <button style={{cursor: 'pointer', background: "#222", color: "#fff"}} onClick={handleSponPayment}>결제하기</button> */}
          </PurchaseBox>
        );
      case "3":
        return (
          <button style={{ cursor: "default", background: "#222", color: "#fff" }}>반려</button>
        );
      case "10":
        return (
          <button style={{ cursor: "default", background: "#222", color: "#fff" }}>
            무통장대기
          </button>
        );
      case "20":
        return (
          <PurchaseBox>
            <button style={{ cursor: "default", background: "#222", color: "#fff" }}>
              결제완료
            </button>
            <button
              style={{ cursor: "pointer", background: "#222", color: "#fff" }}
              onClick={handleSponPayment}
            >
              취소요청
            </button>
          </PurchaseBox>
        );
      default:
        return (
          <button style={{ cursor: "pointer" }} onClick={() => moveDetail(prdId)}>
            신청하기
          </button>
        );
    }
  };

  const movePage = () => {
    if (mydata) {
      history.push(`/stylist/detail/${v?.purchase_id}`);
    } else {
      history.push(`/stylist/sponserd/${prdId}`);
    }
  };
  return (
    <StyleSponListItem className="btn_wrap">
      <div className="stylist-spon-thumb">
        <img
          src={src}
          alt={v?.purchase_prd?.prd_name}
          onClick={movePage}
          style={{ cursor: "pointer" }}
        />
        <LikedIcon isLiked={isLiked} onClick={onHandleLike} />
      </div>
      <em>협찬 / 구매</em>
      {myList ? null : <span>{v?.now_count}개</span>}
      <strong>{name}</strong>
      {formatStatus()}
      {(v?.purchase_state === "1" || v?.purchase_state === "2") && (
        <button type="button" onClick={deletePurChaseCancel} style={{ marginTop: "10px" }}>
          협찬 취소
        </button>
      )}
    </StyleSponListItem>
  );
};

export default StylistProductItem;
