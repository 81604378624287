import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { crypto } from "utils";
import IconArrow from "../../img/icon/icon_category_arrow.svg";

export default function CategoryMenu(props) {
  const { asideData } = props;
  const history = useHistory();
  const { main_cat, sub_cat } = useParams();
  const decodedMainCat = crypto.decryptionBASE64(main_cat);
  const currentMenu = asideData.list.find((el) => String(el.catId) === decodedMainCat);
  const [cateShow, setCateShow] = useState(false);
  const clickCateRef = useRef(0);

  const handleClickCategory = (el) => {
    window.scrollTo(0, 0);
    return history.replace(`/store/category/${crypto.encryptionBASE64(el.catId)}/${crypto.encryptionBASE64(el.subCategory[0].catId)}`);
  };

  return (
    <Container>
      <Wrap>
        <MenuSelect
          onClick={() => {
            setCateShow((prev) => !prev);
          }}
        >
          <CategoryWrap hide={false}>
            <CategoryButton type="button" className="category-button">
              <span>{currentMenu?.catName}</span>
              <img src={IconArrow} alt="arrow-icon" />
            </CategoryButton>
            {cateShow && (
              <CategoryList>
                {asideData.list.map((el) => {
                  return (
                    <CategoryListItem
                      key={el.catId}
                      onClick={() => {
                        clickCateRef.current = 0;
                      }}
                    >
                      <a onClick={() => handleClickCategory(el)}>{el.catName}</a>
                    </CategoryListItem>
                  );
                })}
              </CategoryList>
            )}
          </CategoryWrap>
        </MenuSelect>
        <SubMenuList>
          {currentMenu &&
            currentMenu.subCategory.map((el, index) => {
              return (
                <SubMenuListContents
                  key={el.catId}
                  active={clickCateRef.current === index}
                  onClick={() => {
                    clickCateRef.current = index;
                    history.push(`/store/category/${crypto.encryptionBASE64(currentMenu.catId)}/${crypto.encryptionBASE64(el.catId)}`);
                  }}
                >
                  <SubMenuText>{el.catName}</SubMenuText>
                </SubMenuListContents>
              );
            })}
        </SubMenuList>
      </Wrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const Wrap = styled.div`
  width: 100%;
`;

const MenuSelect = styled.div`
  @media screen and (max-width: 930px) {
    & .category-list {
      padding: calc(100vw * (20 / 428)) 0;

      a {
        display: block;
        font-size: calc(100vw * (16 / 428));
        padding: calc(100vw * (15 / 428)) calc(100vw * (25 / 428));
      }
    }
  }
`;
const CategoryWrap = styled.div`
  display: block;
  position: fixed;
  left: 0;
  z-index: 104;
  width: 100%;
  height: auto;
  background-color: #fff;
  transition: ease 0.3s all;
  -webkit-transform: translateZ(0); // safari bug fix
  -webkit-backface-visibility: hidden;
  @media screen and (max-width: 930px) {
    ${({ hide }) =>
      hide
        ? `
      top : 0;
  `
        : `top: calc(100vw * (50 / 428));`}
  }
`;
const CategoryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  & span {
    font-weight: 600;
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));

    & span {
      font-size: calc(100vw * (16 / 428));
      min-width: calc(100vw * (30 / 428));
    }
    & img {
      width: calc(100vw * (24 / 428));
      height: calc(100vw * (24 / 428));
    }
  }
`;
const CategoryList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-top: 1px solid #eeeeee;

  a {
    color: #555555;
    font-weight: 400;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;

    a {
      display: block;
      font-size: calc(100vw * (16 / 428));
      padding: calc(100vw * (15 / 428)) calc(100vw * (25 / 428));
    }
  }
`;
const CategoryListItem = styled.li`
  width: 50%;
  @media screen and (max-width: 930px) {
    position: relative;
    ${({ active }) =>
      active &&
      ` & a{
        font-weight: 600;
        z-index : 1;
      }
      
     `}
  }
`;

const SubMenuList = styled.ul`
  width: 100%;
  display: inline-flex;
  align-items: center;
  background-color: #f9f9f9;
  white-space: nowrap;
  overflow-y: visible;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (45 / 428));
    padding: 0 calc(100vw * (37 / 428));
    margin-top: calc(100vw * (55 / 428));
    transform: translateY(calc(100vw * (55 / 428)));
  }
`;
const SubMenuListContents = styled.li`
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: 930px) {
    color: #333333;
    padding: calc(100vw * (5 / 428)) calc(100vw * (12 / 428));

    ${({ active }) =>
      active &&
      `
      border-radius : calc(100vw * (15 / 428));
        background-color: #000;
        &>span{
          color : #fff;
          z-index : 1;
        }
      }
    `}
  }
`;
const SubMenuText = styled.span`
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
