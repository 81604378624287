import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import serverController from "../../server/serverController";
import styled, { createGlobalStyle, css } from "styled-components";

const CateAnimation = createGlobalStyle`
    /* .category-menu .category-sub-menu {
        display: none;
    }
    .category-menu:hover .category-sub-menu {
        display: block;
    } */
`;

export default function CategoryMenuList() {
  const { main_cat, sub_cat } = useParams();
  const [isMenu, setIsMenu] = useState();
  const history = useHistory();

  const [mainCatelist, setMainCateList] = useState([]);

  const mainCate = async () => {
    await serverController.connectFetchController(
      `category`,
      "GET",
      null,
      (res) => {
        console.log(res, "카테고리 목록 res");
        if (res?.result === 1) {
          setMainCateList(res?.list ?? []);
        }
      },
      (err) => console.error(err)
    );
  };

  useEffect(() => {
    mainCate();
  }, []);

  useEffect(() => {
    setIsClick(false);
  }, [main_cat, sub_cat]);

  const [isClick, setIsClick] = useState(true);
  const movePage = (mainCat, subCat) => {
    history.push(`/store/categorys/${mainCat}/${subCat}?filterF=0&filterP=0`);
  };

  return (
    <React.Fragment>
      <CateAnimation />
      <Container>
        <PC>
          <MyMenuListWrap>
            <MenuListWrap>
              <MenuListBox isClick={isClick} onClick={() => setIsClick(true)}>
                {mainCatelist.map((v, i) => {
                  return (
                    <MenusList key={i}>
                      <ListTitle className="category-menu">
                        {v?.mainCategory?.prd_maincat_name}
                      </ListTitle>
                      <ul className="category-sub-menu">
                        {v?.subCategory?.map((value, j) => {
                          let maincat;
                          if (
                            v?.mainCategory?.prd_maincat_name === "원피스/세트"
                          ) {
                            maincat = "원피스,세트";
                          } else {
                            maincat = v?.mainCategory?.prd_maincat_name;
                          }
                          return (
                            <List
                              MenuOn={value?.prd_subcat_name === sub_cat}
                              onClick={() =>
                                movePage(maincat, value?.prd_subcat_name)
                              }
                            >
                              {value?.prd_subcat_name}
                            </List>
                          );
                        })}
                      </ul>
                      {/* <List MenuOn={pathName.split('/')[2] == "wish"}><Link to={`/store/mypage/wish`}>찜 목록</Link></List> */}
                      {/* <List MenuOn={pathName.split('/')[2] == "lately"}><Link to={`/store/mypage/lately`}>최근 본 상품</Link></List> */}
                    </MenusList>
                  );
                })}
              </MenuListBox>
            </MenuListWrap>
          </MyMenuListWrap>
        </PC>

        <Mobile>
          <MyMenuListWrap>
            <MenuListWrap>
              <MenuListBox>
                {mainCatelist.map((v, i) => {
                  return (
                    <MenusList key={i}>
                      <ListTitle
                        className="category-menu"
                        onClick={() => {
                          setIsMenu(i);
                        }}
                      >
                        {v?.mainCategory?.prd_maincat_name}
                      </ListTitle>
                      <ul className="category-sub-menu">
                        {isMenu == i &&
                          v?.subCategory?.map((value, j) => {
                            return (
                              <List MenuOn={false}>
                                {value?.prd_subcat_name}
                              </List>
                            );
                          })}
                      </ul>
                      {/* <List MenuOn={pathName.split('/')[2] == "wish"}><Link to={`/store/mypage/wish`}>찜 목록</Link></List> */}
                      {/* <List MenuOn={pathName.split('/')[2] == "lately"}><Link to={`/store/mypage/lately`}>최근 본 상품</Link></List> */}
                    </MenusList>
                  );
                })}
                {/* {
                            mainCatelist.map((v, i) => {
                                return (
                                    <MenusList>
                                        <ListTitle>{v.prd_maincat_name}</ListTitle>
                                        {/* {
                                            v.list.map((value, j) => {
                                                return <List MenuOn={pathName.split('/')[2] == "wish"}>value</List>
                                            }) 
                                        } */}
                {/* <List MenuOn={pathName.split('/')[2] == "wish"}><Link to={`/store/mypage/wish`}>찜 목록</Link></List> 
                                        {/* <List MenuOn={pathName.split('/')[2] == "lately"}><Link to={`/store/mypage/lately`}>최근 본 상품</Link></List> 
                                    </MenusList>
                                )
                            })
                        } */}
                {/* <MenusList>
                            <ListTitle>MY</ListTitle>
                            <List MenuOn={pathName.split('/')[2] == "wish"}><Link to={`/store/mypage/wish`}>찜 목록</Link></List>
                            <List MenuOn={pathName.split('/')[2] == "lately"}><Link to={`/store/mypage/lately`}>최근 본 상품</Link></List>
                        </MenusList>

                        <MenusList>
                            <ListTitle>주문관리</ListTitle>
                            <List MenuOn={pathName.split('/')[2] == "order"}><Link to={`/store/mypage/order`}>주문/배송 조회</Link></List>
                            <List MenuOn={pathName.split('/')[2] == "return"}><Link to={`/store/mypage/return`}>취소/교환/반품 조회</Link></List>
                        </MenusList>

                        <MenusList>
                            <ListTitle>쇼핑혜택</ListTitle>
                            <List MenuOn={pathName.split('/')[2] == "gong"}><Link to={`/store/mypage/wala/point`}>포인트(WALA)</Link></List>
                            <List MenuOn={pathName.split('/')[2] == "point"}><Link to={`/store/mypage/point`}>적립금(상품)</Link></List>
                            <List MenuOn={pathName.split('/')[2] == "coupon"}><Link to={`/store/mypage/coupon`}>보유 쿠폰</Link></List>
                        </MenusList>

                        <MenusList>
                            <ListTitle>정보관리</ListTitle>
                            <List MenuOn={pathName.split('/')[2] == "profile"}><Link to={`/store/mypage/profile`}>회원정보 조회/수정</Link></List>
                        </MenusList>

                        <MenusList>
                            <ListTitle>문의내역</ListTitle>
                            <List MenuOn={pathName.split('/')[2] == "question"}><Link to={`/store/mypage/inquiry`}>나의 문의 내역</Link></List>
                            <List MenuOn={pathName.split('/')[2] == "review"}><Link to={`/store/mypage/review`}>내가 작성한 리뷰</Link></List>
                        </MenusList> */}
              </MenuListBox>
            </MenuListWrap>
          </MyMenuListWrap>
        </Mobile>
      </Container>
    </React.Fragment>
  );
}
const Container = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    border-top: calc(100vw * (12 / 428)) solid #e9ebee;
  }
`;
const MyMenuListWrap = styled.div`
  width: 100%;
`;
//menu
const MenuListWrap = styled.div`
  width: 100%;
  padding-top: 25px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (25 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (20 / 428));
  }
`;
const MenuListBox = styled.div`
  width: 1300px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  & .category-sub-menu {
    display: block;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
  }

  @media screen and (max-width: 930px) {
    flex-wrap: wrap;
    width: calc(100vw * (388 / 428));
  }
`;
const MenusList = styled.div`
  width: calc(100% / 5 - 92px);

  @media screen and (max-width: 1320px) {
    width: calc(100% / 5 - calc(100vw * (92 / 1320)));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ListTitle = styled.h3`
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  color: #222222;
  padding-bottom: 10px;
  border-bottom: 1px solid #707070;
  margin-bottom: 16px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (17 / 1320));
    padding-bottom: calc(100vw * (10 / 1320));
    border-bottom: calc(100vw * (1 / 1320)) solid #707070;
    margin-bottom: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding-bottom: calc(100vw * (10 / 428));
    border-bottom: calc(100vw * (1 / 428)) solid #707070;
    margin-bottom: calc(100vw * (14 / 428));
  }
`;
const List = styled.li`
  cursor: pointer;
  font-size: 15px;
  text-align: center;
  color: #222222;
  font-weight: 500;
  padding: 2px;
  margin: 6px 0;
  & a {
    color: #222222;
  }

  ${({ MenuOn }) => {
    return MenuOn
      ? `color: #222222; font-weight: bold; background: #fff200;`
      : ``;
  }}
  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (2 / 1320));
    margin: calc(100vw * (6 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    padding: calc(100vw * (2 / 428));
    margin: calc(100vw * (6 / 428)) 0;
    &:last-child {
      margin-bottom: calc(100vw * (30 / 428));
    }
  }
`;
