//react
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

//css
import 'swiper/swiper-bundle.css';
import styled from 'styled-components';

//img
import IconClosed from '../../images/close-icon2.svg';

export default function CommentModal({setIsModal}) {
    const [list, setList] = useState([1,2,3,4])
    const [reList, setReList] = useState([1,2])
    const [isReCom, setIsReCom] = useState(false)

    return (
        <>
            <Bg onClick={()=>{setIsModal(false)}}/>
            <Wrap>
                <ClosedBtn src={IconClosed} alt="" onClick={()=>{setIsModal(false)}}/>
                <ListBox>
                    {
                        list.map((value,index)=>{
                            return(
                                <div>
                                    <CommentBox>
                                        <Comment><User>user</User> 댓글 내용입니다.</Comment>
                                        <SubBox>
                                            <Date>2022.02.16</Date>
                                            <ReComBtn onClick={()=>{setIsReCom(true)}}>답글달기</ReComBtn>
                                        </SubBox>
                                    </CommentBox>
                                    {
                                        reList.map((value,index)=>{
                                            return(
                                                <ReCommentBox>
                                                    <Comment><User>user</User> 댓글 내용입니다.</Comment>
                                                    <SubBox>
                                                        <Date>2022.02.16</Date>
                                                        <ReComBtn onClick={()=>{setIsReCom(true)}}>답글달기</ReComBtn>
                                                    </SubBox>
                                                </ReCommentBox>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                    <SubmitBox>
                        <TextareaBox>
                            {isReCom ? <ReText>user에게 답글 남기기 <ReClosedBtn src={IconClosed} alt="" onClick={()=>{setIsReCom(false)}}/></ReText> : null}
                            <TextareaInner>
                                <Textarea id=""/> <Btn>등록</Btn>
                            </TextareaInner>
                        </TextareaBox>
                    </SubmitBox>
                </ListBox>
            </Wrap>
        </>
    );
}
const Wrap = styled.div`
    position: fixed; bottom: 0; left: 0;
    width: 100%; height: 70vh;
    padding: calc(100vw*(20/428)) calc(100vw*(25/428)) calc(100vw*(40/428));
    border-radius: calc(100vw*(18/428)) calc(100vw*(18/428)) 0 0;
    box-shadow: 0 calc(100vw*(3/428)) calc(100vw*(6/428)) 0 rgba(0, 0, 0, 0.3);
    background: #fff;
    z-index: 100;
`
const Bg = styled.div`
    position: fixed; top: 0; left: 0;
    width: 100%; height: 100vh;
    padding: calc(100vw*(20/428)) calc(100vw*(25/428)) calc(100vw*(40/428));
    background: rgba(1,1,1,0.8);
    z-index: 30;
`
const ClosedBtn = styled.img`
    display: block;
    margin-left: auto;
    cursor: pointer;
`
const ListBox = styled.div`
    width: 100%; height: 65vh;
    padding: 0 calc(100vw*(15/428)) calc(100vw*(150/428));
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display:none;
    }
`
const CommentBox = styled.div`
    width: 100%;
    padding: calc(100vw*(10/428)) 0;
`
const ReCommentBox = styled.div`
    width: 100%;
    padding-left: calc(100vw*(25/428));
    padding: calc(100vw*(10/428)) 0 calc(100vw*(10/428)) calc(100vw*(25/428));
`
const Comment = styled.p`
    display: flex;
    align-items: center;
    width: 100%;
    font-size: calc(100vw*(14/428));
`
const SubBox = styled.p`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: calc(100vw*(5/428));
`
const Date = styled.p`
    height: calc(100vw*(18/428));
    font-size: calc(100vw*(12/428));
    line-height: 1.9;
    color: #aaa;
    margin-right: calc(100vw*(10/428));
`
const ReComBtn = styled.p`
    width: calc(100vw*(50/428)); height: calc(100vw*(18/428));
    font-size: calc(100vw*(12/428));
    text-align: center;
    color: #aaa;
    cursor: pointer;
`
const User = styled.span`
    display: block;
    font-size: calc(100vw*(15/428));
    font-weight: bold;
    margin-right: calc(100vw*(8/428));
`
const SubmitBox = styled.div`
    position: absolute; bottom: 0; left: 0;
    width: 100%;
    padding: calc(100vw*(25/428)) calc(100vw*(15/428));
    background: #fff;
    border-top: 1px solid #efefef;
`
const TextareaBox = styled.div`
    width: 100%;
    border: 1px solid #aaaaaa;
`
const TextareaInner = styled.div`
    position: relative;
    width: 100%;
    padding: calc(100vw*(10/428)) calc(100vw*(70/428)) calc(100vw*(10/428)) calc(100vw*(10/428));
`
const Textarea = styled.textarea`
    display: block;
    width: 100%;
    font-size: calc(100vw*(14/428));
    border: none;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display:none;
    }
`
const Btn = styled.p`
    position: absolute; top: 50%; right: calc(100vw*(10/428));
    width: calc(100vw*(60/428));
    font-size: calc(100vw*(14/428));
    text-align: center;
    color: #fff;
    background: #000;
    padding: calc(100vw*(4/428)) 0;
    transform: translateY(-50%);
    cursor: pointer;
`
const ReText = styled.p`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: calc(100vw*(12/428));
    color: #B5B5B5;
    background: #F5F5F5;
    padding: calc(100vw*(6/428)) calc(100vw*(10/428));
    cursor: pointer;
`
const ReClosedBtn = styled.img`
    display: block;
    width: calc(100vw*(10/428));
    cursor: pointer;
`