import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";
import { useSelector } from "react-redux";

// controller
import serverController from "../../server/serverController";

//img
import IconSearch from "../../img/icon/icon_header_search_white.svg";
import IconCart from "../../img/icon/icon_header_cart_white.svg";
import IconMenuArrow from "../../img/icon/icon_my_menu_arrow.svg";
import Zigzag from "../../img/myMain_zigzag.svg";
import ZigzagPc from "../../img/myMain_zigzag_pc.svg";
import IconMyPoint from "../../img/icon/icon_my_point.svg";
import IconMyCoupon from "../../img/icon/icon_my_coupon.svg";
import IconMyList from "../../img/icon/icon_my_list.svg";
import IconMySetting from "../../img/icon/icon_my_setting.svg";

// component
import BestItemProductList from "../main/BestItemProductList";
import Commas from "../../server/Commas";

import MyPageLayOutMenu from "./MyPageLayOutMenu";
import MyPageLayOutName from "./MyPageLayOutName";
import MyPageLayOutInfo from "./MyPageLayOutInfo";
import StoreFooter from "../common/StoreFooter";
import { UserDataAction, RecommenderAction } from "../../../store/actionCreators";
import MyRecommenderPop from "../../page/mypage/MyRecommenderPop";

export default function MyMain({ setLoading }) {
  const history = useHistory();
  const [socialEmail, setSocialEmail] = useState(false);

  const [coupons, setCoupons] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [orderListLength, setOrderListLength] = useState(0);

  const [list, setList] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [checks, setChecks] = useState([]);

  const [cartList, setCartList] = useState([]);
  const [orderStatus, setOrderStatus] = useState({});

  const [isRecommenderPop, setIsRecommenderPop] = useState(false);

  const { recommender_cate } = useSelector((state) => state.recommenderData);
  // const { recommender_cate } = useSelector((state) => state.recommenderData);

  // social login 시 비밀번호 변경 없애기
  useEffect(() => {
    if (!userInfo) return;

    let userE = userInfo?.mem_email;
    let isSocialUser = false;
    if (
      userE?.indexOf("google_") !== -1 ||
      userE?.indexOf("apple_") !== -1 ||
      userE?.indexOf(".@privaterelay.appleid") !== -1 ||
      userE?.indexOf("naver_") !== -1 ||
      userE?.indexOf("kakao_") !== -1
    ) {
      isSocialUser = true;
    }
    return setSocialEmail(isSocialUser);
  }, [userInfo]);

  // get coupon list
  const getCouponList = () => {
    serverController.connectFetchController(`coupon/list`, "GET", null, (res) => {
      if (res?.result === 1) {
        let activeCouponArr = [];
        for (let i in res?.coupon_list) {
          if (res?.coupon_list[i].coupon_record_used === 0) {
            activeCouponArr.push(res?.coupon_list[i]);
          }
        }
        setCoupons(activeCouponArr);
      }
    });
  };

  //get mypage user info
  const getMypageUserInfo = () => {
    serverController.connectFetchController(
      `mypage`,
      "POST",
      null,
      (res) => {
        console.log(res, "res");
        setLoading(false);
        if (res?.result === 1 && res.list.mem_recommender !== null) {
          RecommenderAction.updateRecommender({
            recommender_cate: false
          })
        }
        if (res?.result === 1 && res.list.mem_recommender === null && !localStorage.getItem('recommenderPop')) {
          setIsRecommenderPop(true);
          RecommenderAction.updateRecommender({
            recommender_cate: true
          })
        }
        if (res?.result === 1 && res.list.mem_recommender === null && localStorage.getItem('recommenderPop')) {
          RecommenderAction.updateRecommender({
            recommender_cate: true
          })
        }
        if (res?.result === 1) return setUserInfo(res.list);
        alert("잘못된 접근입니다.");
        history.push(`/store`);
      },
      (err) => console.error(err)
    );
  };
  // get order history
  const getOrderHistory = () => {
    serverController.connectFetchController(
      `payment/history?orderType=order&dateType=year&page=${1}&limit=120`,
      "GET",
      null,
      (res) => {
        if (res.result === 1) {
          setOrderListLength(res.payment_list.length || 0);
        }
      }
    );
  };

  const logoutFunc = () => {
    serverController.connectFetchController(
      `logout`,
      "POST",
      null,
      (res) => { },
      (err) => console.error(err)
    );
    if (localStorage.getItem("login-info")) {
      localStorage.removeItem("login-info");
    }
    UserDataAction.logOutRequest();
    history.push("/store");
  };

  // get pc payment status list
  const getPcPaymentStatus = () => {
    serverController.connectFetchController(`payment/status`, "GET", null, (res) => {
      setOrderStatus(res);
    });
  };

  // get cart product
  const getCartProduct = () => {
    serverController.connectFetchController(`cart/list`, "GET", null, (res) => {
      if (res?.result !== 1) return;
      const list = res.list;
      const values = Object.values(list);

      console.log(list, "list");

      const data = values.map((arr) => {
        arr.map((item) => {
          if (typeof item.cart_item === "string") {
            item.cart_item = JSON.parse(item.cart_item);
          }
          return item;
        });
        return arr;
      });

      // for check control
      let allArr = [];
      for (let d in data) {
        for (let f in data[d]) {
          allArr.push(data[d][f]);
        }
      }
      setCartList(allArr.splice(0, 4));

      setLoading(false);
    });
  };

  // wish
  const getLikeItems = () => {
    serverController.connectFetchController(
      `products/like?type=1&page=1&limit=20`,
      "GET",
      null,
      (res) => {
        if (res.result !== 1) return;
        const data = res.list;
        setList([...data.splice(0, 5)]);
        setChecks(Array(list.length).fill(false));
        setLoading(false);
      }
    );
  };

  const getLikeId = () => {
    const indexArr = [];
    checks.forEach((item, index) => {
      if (item === true) {
        indexArr.push(index);
      }
    });
    const likeIds = indexArr.map((v) => list[v].prd_id);
    return [indexArr, likeIds];
  };

  useEffect(() => {
    const [indexArr, likeIds] = getLikeId();
    setSelectedItems((item) => {
      const newArr = [];
      indexArr.forEach((index) => {
        newArr.push(list[index]);
      });
      return newArr;
    });
  }, [checks]);

  useEffect(() => {
    getLikeItems();
    getCouponList();
    getMypageUserInfo();
    getOrderHistory();
    getPcPaymentStatus();
    getCartProduct();
    setChecks(Array(list.length).fill(allCheck));
    if (selectedItems.length === list.length) {
      checks[0] ? setAllCheck(true) : setAllCheck(false);
    } else {
      setAllCheck(false);
    }
  }, []);

  return (
    <>
      <Container>
        {isRecommenderPop && <MyRecommenderPop setIsRecommenderPop={setIsRecommenderPop} falseFunc={() => { setIsRecommenderPop(false) }} />}
        <PC>
          {/* page */}
          <MyPageLayOutName />

          {/* content */}
          <FlexLayOut>
            <MyPageLayOutMenu />
            <ContentSection>
              <MyPageLayOutInfo />
              <MyInfoWrap>
                {/* 진행 상황 */}
                <OrderProgressArea>
                  <OrderProgressList
                    onClick={() => history.push(`/store/mypage/order/0?page=1`)}
                    pointer
                  >
                    <OrderNum>{orderStatus?.paid || 0}</OrderNum>
                    <OrderText>결제완료</OrderText>
                  </OrderProgressList>
                  <OrderProgressList>
                    <OrderNum>{orderStatus?.ready || 0}</OrderNum>
                    <OrderText>상품준비중</OrderText>
                  </OrderProgressList>
                  <OrderProgressList>
                    <OrderNum>{orderStatus?.ing || 0}</OrderNum>
                    <OrderText>배송중</OrderText>
                  </OrderProgressList>
                  <OrderProgressList>
                    <OrderNum>{orderStatus?.done || 0}</OrderNum>
                    <OrderText>배송완료</OrderText>
                  </OrderProgressList>
                </OrderProgressArea>

                <WishListArea>
                  <ListTop>
                    <ListTitle>찜목록</ListTitle>
                    <MoreView to={`/store/mypage/wish?tab=0&page=1`}>더보기 &gt;</MoreView>
                  </ListTop>
                  <WishList>
                    {list?.length > 0 ? (
                      list?.map((listValue) => {
                        return (
                          <BestItemProductList
                            key={`${listValue.prd_id}-wishList`}
                            value={listValue}
                            rawThree={false}
                          />
                        );
                      })
                    ) : (
                      <NoText>찜한 상품이 없습니다.</NoText>
                    )}
                  </WishList>
                </WishListArea>
                {cartList.length > 0 && (
                  <CartListArea>
                    <ListTop>
                      <ListTitle>장바구니</ListTitle>
                      <MoreView to={`/store/cart`}>더보기 &gt;</MoreView>
                    </ListTop>
                    <CartList>
                      {cartList.map((prd, idx) => {
                        return (
                          <BestItemProductList
                            key={`${prd.cart_id}-cartList-${idx}`}
                            value={prd.cart_item}
                            rawThree={true}
                            thumbnail={prd.prd_thumbnail}
                          />
                        );
                      })}
                    </CartList>
                  </CartListArea>
                )}
              </MyInfoWrap>
            </ContentSection>
          </FlexLayOut>
        </PC>

        <Mobile>
          <Header>
            <HeaderMenuWrap>
              <HeaderMenu>
                <Link to={`/store/mypage/setting`}>
                  <HeaderMenuButton>
                    <img src={IconMySetting} alt="" />
                  </HeaderMenuButton>
                </Link>
              </HeaderMenu>
            </HeaderMenuWrap>
            <HeaderMenuWrap>
              <HeaderMenu>
                <Link to={`/store/search`}>
                  <HeaderMenuButton>
                    <img src={IconSearch} alt="search" />
                  </HeaderMenuButton>
                </Link>
              </HeaderMenu>
              <HeaderMenu>
                <Link to={`/store/cart`}>
                  <HeaderMenuButton>
                    <img src={IconCart} alt="cart page" />
                  </HeaderMenuButton>
                </Link>
              </HeaderMenu>
            </HeaderMenuWrap>
          </Header>

          <MyInfoWrap>
            <MyInfoTopArea>
              <UserNameBox>
                <UserName>{userInfo?.mem_name}</UserName>님
              </UserNameBox>
              <UserInfoBox>
                {userInfo.mem_gender && (
                  <UserInfoText>
                    <strong>성별</strong> : {userInfo.mem_gender === 1 ? "여자" : "남자"}
                  </UserInfoText>
                )}
                {userInfo.mem_style && (
                  <UserInfoText>
                    <strong>선호스타일</strong> : {userInfo.mem_style}
                  </UserInfoText>
                )}
                {userInfo.mem_fit && (
                  <UserInfoText>
                    <strong>체형</strong> : {userInfo.mem_fit}
                  </UserInfoText>
                )}
                {userInfo.mem_color && (
                  <UserInfoText>
                    <strong>선호 색상</strong> : {userInfo.mem_color}
                  </UserInfoText>
                )}
                {(userInfo.mem_gender ||
                  userInfo.mem_style ||
                  userInfo.mem_fit ||
                  userInfo.mem_color) && (
                    <UserInfoText last>
                      <Link to="/store/mypage/personal/1">맞춤분석 다시 하러가기 &gt; </Link>
                    </UserInfoText>
                  )}
              </UserInfoBox>
              <ZigzagImgBox>
                <img src={Zigzag} alt="" />
              </ZigzagImgBox>
            </MyInfoTopArea>
            <MyInfoBottomArea>
              <MyInfoListBox>
                <MyInfoList>
                  <MyInfoIcon
                    onClick={() => {
                      history.push(`/store/mypage/point`);
                    }}
                  >
                    <img src={IconMyPoint} alt="" />
                  </MyInfoIcon>
                  <MyInfoTitle>포인트</MyInfoTitle>
                  <MyInfoText>{Commas.numberWithCommas(userInfo?.mem_point ?? 0)}P</MyInfoText>
                </MyInfoList>
                <MyInfoList>
                  <MyInfoIcon
                    onClick={() => {
                      history.push(`/store/mypage/coupon`);
                    }}
                  >
                    <img src={IconMyCoupon} alt="" />
                  </MyInfoIcon>
                  <MyInfoTitle>쿠폰</MyInfoTitle>
                  <MyInfoText>{coupons.length}</MyInfoText>
                </MyInfoList>
                <MyInfoList>
                  <MyInfoIcon
                    onClick={() => {
                      history.push(`/store/mypage/order/0?page=1`);
                    }}
                  >
                    <img src={IconMyList} alt="" />
                  </MyInfoIcon>
                  <MyInfoTitle>주문 내역</MyInfoTitle>
                  <MyInfoText>{orderListLength}</MyInfoText>
                </MyInfoList>
              </MyInfoListBox>
            </MyInfoBottomArea>
          </MyInfoWrap>

          <MyMenuWrap>
            <MenuListArea>
              <MenuListInner>
                <MenuListTitle>MY</MenuListTitle>
                <MenuListBox>
                  <MenuList>
                    <MenuLink to={`/store/mypage/productInquiry?page=1`}>상품 문의 내역</MenuLink>
                  </MenuList>
                  <MenuList>
                    <MenuLink to={`/store/mypage/review?tab=1&page=1`}>나의 리뷰</MenuLink>
                  </MenuList>
                  <MenuList>
                    <MenuLink to={`/store/mypage/wish?tab=0&page=1`}>찜목록</MenuLink>
                  </MenuList>
                </MenuListBox>
              </MenuListInner>

              <MenuListInner>
                <MenuListTitle>정보관리</MenuListTitle>
                <MenuListBox>
                  <MenuList>
                    <MenuLink to={`/store/mypage/profile`}>회원정보 수정</MenuLink>
                  </MenuList>
                  {!socialEmail && (
                    <MenuList>
                      <MenuLink to={`/store/mypage/password`}>비밀번호 변경</MenuLink>
                    </MenuList>
                  )}
                  <MenuList>
                    <MenuLink to={`/store/mypage/adress`}>배송지 관리</MenuLink>
                  </MenuList>
                  <MenuList>
                    <MenuLink to={`/store/mypage/personal/1`}>맞춤분석</MenuLink>
                  </MenuList>
                  {
                    recommender_cate && (
                      <MenuList>
                        <MenuLink to={`/store/mypage/recommender`}>추천인 등록</MenuLink>
                      </MenuList>
                    )
                  }
                  {/* <MenuList>
                    <MenuLink to={`/store/mypage/bank`}>환불계좌 관리</MenuLink>
                  </MenuList> */}
                </MenuListBox>
              </MenuListInner>

              <MenuListInner>
                <MenuListTitle>고객센터</MenuListTitle>
                <MenuListBox>
                  {/* <MenuList>
                    <MenuLink to={`/store/mypage/notice?page=1`}>공지사항</MenuLink>
                  </MenuList> */}
                  <MenuList>
                    <MenuLink to={`/store/mypage/faq?page=1`}>자주묻는 질문</MenuLink>
                  </MenuList>
                  {/* <MenuList>
                    <MenuLink to={`/store/mypage/inquiry?page=1`}>1:1문의</MenuLink>
                  </MenuList> */}
                  <MenuList onClick={logoutFunc}>
                    <MenuLink gray as="div">
                      로그아웃
                    </MenuLink>
                  </MenuList>
                </MenuListBox>
              </MenuListInner>
            </MenuListArea>
          </MyMenuWrap>
        </Mobile>
      </Container>
      <StoreFooter />
    </>
  );
}
const Container = styled.div`
  width: 100%;
  padding: 180px 0 80px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (80 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;

// header
const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #000;

  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;
const HeaderMenuWrap = styled.ul`
  display: flex;
  align-items: center;
`;
const HeaderMenu = styled.li`
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-right: calc(100vw * (15 / 428));
    }
  }
`;
const HeaderMenuButton = styled.div`
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
  }
`;

// MyInfoWrap
const MyInfoWrap = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const UserInfoBox = styled.div`
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
    padding: 0 calc(100vw * (25 / 428));
    font-size: calc(100vw * (12 / 428));
  }
`;
const UserInfoText = styled.p`
  color: #eee;
  > strong {
    font-weight: 700;
    color: #eee;
  }
  ${({ last }) => last && `text-align : right; text-decoration : underline; font-weight : 700;`}
  @media screen and (max-width: 930px) {
    > a {
      color: #eee;
      font-size: calc(100vw * (12 / 428));
    }
    padding-bottom: calc(100vw * (5 / 428));
  }
`;

const ZigzagImgBox = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: auto;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 930px) {
    bottom: calc(100vw * (-10 / 428));
  }
`;
const MyInfoTopArea = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 180px;
  padding: 0 80px 0 50px;
  background: url(${ZigzagPc}) no-repeat center/contain;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (180 / 1320));
    padding: 0 calc(100vw * (80 / 1320)) 0 calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    display: block;
    height: auto;
    padding: calc(100vw * (85 / 428)) 0 calc(100vw * (60 / 428));
    background: #000;
  }
`;
const UserNameBox = styled.div`
  color: #fdf251;
  font-weight: 600;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (28 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;

const UserName = styled.span`
  color: #fdf251;
  font-weight: 600;
  font-size: 34px;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (34 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (34 / 428));
  }
`;

const MyInfoBottomArea = styled.div`
  &::after {
    content: "";
    display: block;
    width: 100%;
    background-color: #000;
  }

  @media screen and (max-width: 930px) {
    &::after {
      height: calc(100vw * (8 / 428));
    }
  }
`;
const MyInfoListBox = styled.ul`
  display: flex;
  align-items: center;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  }
`;
const MyInfoList = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 126px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (126 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 33.333%;
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const MyInfoIcon = styled.div`
  width: 60px;
  height: 60px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(244deg) brightness(99%) contrast(103%);
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (60 / 1320));
    height: calc(100vw * (60 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (60 / 428));
    height: calc(100vw * (60 / 428));

    & img {
      filter: invert(0%) sepia(100%) saturate(24%) hue-rotate(355deg) brightness(112%)
        contrast(105%);
    }
  }
`;
const MyInfoTitle = styled.p`
  color: #999;
  font-weight: 400;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (13 / 1320));
  }

  @media screen and (max-width: 930px) {
    color: #777;
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (7 / 428));
  }
`;
const MyInfoText = styled.p`
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  margin-top: 5px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
    margin-top: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    color: #333;
    font-size: calc(100vw * (20 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;

// MyMenuWrap
const MyMenuWrap = styled.div`
  width: 180px;
  padding-top: 10px;
  border-top: 3px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (180 / 1320));
    padding-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    padding-top: 0;
    padding-bottom: calc(100vw * (180 / 428));
    border-top: 0;
  }
`;

const MenuListArea = styled.ul``;
const MenuListInner = styled.li`
  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #f9f9f9;
  }

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (10 / 428));

    &:not(:last-child) {
      border-bottom: 8px solid #f9f9f9;
    }
  }
`;

const MenuListBox = styled.ul`
  margin: 0 auto;
  padding-top: 23px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (23 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding-top: calc(100vw * (5 / 428));
  }
`;
const MenuList = styled.li`
  width: 100%;
  height: 35px;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (35 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (60 / 428));

    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  }
`;
const MenuListTitle = styled.p`
  color: #999;
  font-weight: 500;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    padding: 0 calc(100vw * (35 / 428));
  }
`;
const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  color: #333;
  font-weight: 500;
  font-size: 16px;

  ${({ gray }) => gray && `color : #999999;`}

  &::after {
    content: "";
    display: block;
    background: url(${IconMenuArrow}) no-repeat center / contain;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding-left: calc(100vw * (10 / 428));

    &::after {
      width: calc(100vw * (24 / 428));
      height: calc(100vw * (24 / 428));
    }
  }
`;

// pc
const ListTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (15 / 1320));
  }
`;
const ListTitle = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 20px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
  }
`;
const MoreView = styled(Link)`
  color: #777;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;

// 진행 상황
const OrderProgressArea = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  margin: 20px 0;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (150 / 1320));
    margin: calc(100vw * (20 / 1320)) 0;
  }
`;
const OrderProgressList = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 25%;

  ${({ pointer }) => pointer && `cursor : pointer;`}

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: block;
    width: 1px;
    height: 60px;
    background-color: #ddd;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      height: calc(100vw * (60 / 1320));
    }
  }
`;
const OrderNum = styled.span`
  color: #333;
  font-weight: 700;
  font-size: 30px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (30 / 1320));
  }
`;
const OrderText = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 20px;
  margin-top: 12px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
    margin-top: calc(100vw * (12 / 1320));
  }
`;

// 찜목록
const WishListArea = styled.div`
  padding: 30px 0 60px;
  border-bottom: 8px solid #f9f9f9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) 0 calc(100vw * (60 / 1320));
  }
`;
const WishList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & .product_box {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1320px) {
    & .product_box {
      &:not(:last-child) {
        margin-right: calc(100vw * (8 / 1320));
      }
    }
  }
`;
const NoText = styled.p`
  width: 100%;
  padding: 280px 0;
  font-size: 16px;
  text-align: center;
  color: #969696;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (280 / 1320)) 0;
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vh * (190 / 925)) 0 0;
    font-size: calc(100vw * (16 / 428));
  }
`;

// 장바구니
const CartListArea = styled.div`
  padding: 30px 0 60px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) 0 calc(100vw * (60 / 1320));
  }
`;
const CartList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & .product_box {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1320px) {
    & .product_box {
      &:not(:last-child) {
        margin-right: calc(100vw * (8 / 1320));
      }
    }
  }
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
