import React, { useEffect, useState, useRef } from "react";
import { PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import AllBrand from "../../component/brand/AllBrand";
import FixMenu from "../../component/common/FixMenu";

// controller
import serverController from "../../server/serverController";

import BrandSearchSection from "../../component/brand/BrandSearchSection";
import BestBrandSection from "../../component/brand/BestBrandSection";
import FullLoading from "../../component/common/modal/FullLoading";
import MyPageDefaultModal from "../../component/common/modal/MyPageDefaultModal";
import { useHistory, useLocation } from "react-router-dom";

export default function BrandPage() {
  const location = useLocation();
  const history = useHistory();
  const [brandsList, setBrandsList] = useState([]);
  const [isLoginPop, setLoginPop] = useState(false);
  const [loading, setLoading] = useState(true);
  const pageRef = useRef(1);
  let isLoad = false;

  // get brand list
  const getBrandsList = () => {
    try {
      serverController.connectFetchController(
        `brand/list?page=${pageRef.current}`,
        "GET",
        null,
        (res) => {
          if (res?.result === 1) {
            setLoading(false);
            if (pageRef.current === 1) {
              setBrandsList(res.data);
            } else {
              setBrandsList((prev) => prev.concat(res?.data));
            }
          }
          if (res.data.length > 0) {
            isLoad = false;
          } else {
            isLoad = true;
          }
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  const checkScrollHeight = () => {
    if (isLoad) return;
    if (window.scrollY + window.innerHeight + 1000 >= document.body.offsetHeight) {
      isLoad = true;
      pageRef.current += 1;
      getBrandsList();
    }
  };

  useEffect(() => {
    const watch = () => {
      window.addEventListener("scroll", checkScrollHeight);
    };
    watch();
    getBrandsList(1);
    return () => {
      window.removeEventListener("scroll", checkScrollHeight);
    };
  }, []);

  const allBrandItems = {
    brandsList,
    setLoginPop,
  };

  return (
    <>
      {isLoginPop && (
        <MyPageDefaultModal
          text="로그인 후 이용 가능합니다."
          trueText="로그인"
          trueFunc={() => {
            if (location) return history.push(`/store/login?returnTo=${location.pathname}`);
            history.push(`/store/login`);
          }}
          falseFunc={() => setLoginPop(false)}
        />
      )}
      {loading && <FullLoading />}
      <Header />
      <PC>
        <BrandSearchSection />
        <BestBrandSection />
      </PC>
      <AllBrand {...allBrandItems} />
      <FixMenu />
    </>
  );
}
