//react
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import Modal from "../../../../server/modal";

//css
import styled, { keyframes } from "styled-components";
import ProductImgage1 from "../../../img/brand_logo3.png";

// img
import CancleIcon from "../../../img/icon/icon_cancle.png";
import IconFile from "../../../img/icon/icon_file.png";

export default function CartModal({ setIsModal }) {
  const history = useHistory();

  return (
    <Container>
      <Background
        onClick={() => {
          setIsModal(false);
        }}
      />
      <Content>
        <TitleCont>
          <Title>
            상품을 장바구니에 담았습니다.
            <br />
            장바구니로 이동하시겠습니까?
          </Title>
        </TitleCont>
        <BtnWrap>
          <Btn
            onClick={() => {
              setIsModal(false);
              Modal.modalClosed();
            }}
          >
            취소
          </Btn>
          <BtnBlue
            onClick={() => {
              setIsModal(false);
              Modal.modalClosed();
              history.push("/store/cart");
            }}
          >
            확인
          </BtnBlue>
        </BtnWrap>
      </Content>
    </Container>
  );
}
// s :: animation
const CartPop = keyframes`
0%{
    transform : translateY(-10px);
		opacity: 0.5;
}
	100% {
		opacity: 1;
	}
`;
const CartBackgroundPop = keyframes`
0%{
		opacity: 0.4;
}
	100% {
		opacity: 1;
	}
`;
// e :: animation
const Container = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  animation: ${CartBackgroundPop} 0.2s ease-in-out;
`;
// 컨텐츠
const Content = styled.div`
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 398px;
  height: fit-content;
  padding: 20px 0 30px;
  background: #fff;
  overflow: hidden;
  animation: ${CartPop} 0.2s ease-out;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (398 / 1320));
    padding: calc(100vw * (20 / 1320)) 0 calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (100 / 428)));
    padding: calc(100vw * (20 / 428)) 0 calc(100vw * (30 / 428));
  }
`;
const TitleCont = styled.div`
  padding: 40px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (40 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (25 / 428)) 0;
  }
`;
const Title = styled.h2`
  line-height: 1.5;
  color: #333;
  font-weight: 400;
  font-size: 16px;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    word-break: keep-all;
  }
`;
const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 348px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (348 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const Btn = styled.p`
  width: 169px;
  color: #333;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #000000;
  padding: 14px 0;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (169 / 1320));
    font-size: calc(100vw * (18 / 1320));
    padding: calc(100vw * (14 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    font-size: calc(100vw * (18 / 428));
    padding: calc(100vw * (14 / 428)) 0;
  }
`;
const BtnBlue = styled(Btn)`
  background: #000;
  color: #fff;
`;
