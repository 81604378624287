import React, { useState, useEffect } from 'react';

import {
    Container,
    Layout,
    StyleSponHead,
    StyleSubHead,
    StyleBackButton,
    StyleSponWrap,
    StyleSponCategorys,
    StyleCatgoryIcon
} from './stylist.element';

import IconAll from '../../images/stylist/icon_all.svg';
import styleType1 from '../../images/stylist/iconmonstr-clothing-1.png';
import styleType2 from '../../images/stylist/iconmonstr-clothing-2.png';
import styleType3 from '../../images/stylist/iconmonstr-clothing-3.png';
import styleType4 from '../../images/stylist/iconmonstr-clothing-4.png';
import styleType5 from '../../images/stylist/iconmonstr-clothing-5.png';
import styleType6 from '../../images/stylist/iconmonstr-clothing-6.png';
import styleType7 from '../../images/stylist/iconmonstr-clothing-7.png';
import styleType8 from '../../images/stylist/iconmonstr-clothing-8.png';
import styleType9 from '../../images/stylist/iconmonstr-clothing-9.png';
import styleType10 from '../../images/stylist/iconmonstr-clothing-10.png';


const StylistRequestHead = ({ setTabIndex }) => {
    const categorys = [
        { src: IconAll, title: "전체보기"},
        { src: styleType1, title: "상의"},
        { src: styleType2, title: "하의"},
        { src: styleType3, title: "원피스/세트"},
        { src: styleType4, title: "스커트"},
        { src: styleType5, title: "아우터 "},
        { src: styleType6, title: "슈즈"},
        { src: styleType7, title: "가방"},
        { src: styleType8, title: "액세서리"},
        { src: styleType9, title: "패션소품"},
        { src: styleType10, title: "빅사이즈"},
    ];

    const [selected, setSelected] = useState(Array(categorys.length).fill(false));
    const onHandleClick = (i) => {
        setSelected(prev => {
            const newArr = Array(categorys.length).fill(false);            
            if (prev[i]) {
                newArr[i] = false;
                setTabIndex(null)
            } else {
                newArr[i] = true;
                const clicked = i;
                setTabIndex(clicked)
            }
            return newArr;
        })     
    }
    useEffect(()=>{
        onHandleClick(0);
    },[])   

    return (
        <>
         <StyleSponCategorys>
            {
                categorys.map((item, i) => {
                    return (
                        <li onClick={() => onHandleClick(i)}>
                            <StyleCatgoryIcon selected={selected[i]}>
                                <img src={item.src} alt="" />
                            </StyleCatgoryIcon>
                            <p>{item.title}</p>
                        </li>
                    )
                })
            }
        </StyleSponCategorys>   
        </>
    )
}

export default StylistRequestHead
