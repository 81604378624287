import React, {useEffect, useState} from 'react';

import styled , {css} from 'styled-components';
import { useSelector } from 'react-redux';

import Header from '../../components/common/header/Header';
import Footer from '../../components/common/footer/Footer';
import ManagementIntro from './ManagementIntro';
import ManagementList from './ManagementList';
import ManagementListOrder from './ManagementListOrder';
import ProductDetailPage from './ProductDetailPage';

export const ManagementIntroPage = ()=> {
    
    return(
        <ManagementIntro/>
    )
}
export const ManagementListPage = ()=> {
    const TabIndex = useSelector(({management})=>{return management.TabIndex});

    return(
        <ManagementList activeTab={TabIndex}/>
    )
}
export const ManagementListOrderPage = ()=> {
    const TabIndex = useSelector(({management})=>{return management.TabIndex});
    return(
        <ManagementListOrder activeTab={TabIndex}/>
    )
}

export const ProductDetailPageOut = ()=> {
    const TabIndex = useSelector(({management})=>{return management.TabIndex});
    return(
        <ProductDetailPage activeTab={TabIndex}/>
    )
}