import { useEffect, useState } from "react";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";
import Header from "../../component/common/Header";
import MyPageHeader from "./MyPageHeader";
import MyBankAccount from "../../component/mypage/bank/MyBankAccount";
import MyPageLayOutMenu from "../../component/mypage/MyPageLayOutMenu";
import MyPageLayOutName from "../../component/mypage/MyPageLayOutName";
import MyPageLayOutInfo from "../../component/mypage/MyPageLayOutInfo";
import { ip } from "../../server/serverController";
import serverController from "../../server/serverController";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function BankAccountPage() {
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();

  const [userName, setUserName] = useState("");
  const [bankNum, setBankNum] = useState("");
  const [bankName, setBankName] = useState("");
  const [selectedValue, setSelectedValue] = useState("selectT선택");

  const validLogin = () => {
    if (isLogged) return;
    alert(`잘못된 접근입니다.`);
    history.push(`/store`);
  };

  const setBankAccount = () => {
    fetch(`${ip}user/account`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        mem_bank_name: selectedValue.split("T")[1],
        mem_bank_code: selectedValue.split("T")[0],
        mem_bank_owner_name: userName,
        mem_bank_account: bankNum,
      }),
    })
      .then((res) => {
        if (res.status !== 200) return alert("다시 시도해주세요.");
        alert("등록이 완료되었습니다.");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const postUserInfo = () => {
    serverController.connectFetchController(
      `mypage`,
      "POST",
      null,
      (res) => {
        setUserName(res.list.mem_bank_owner_name || "");
        setBankNum(res.list.mem_bank_account || "");
        setBankName(res.list.mem_bank_name);
      },
      (err) => console.error(err)
    );
  };

  useEffect(() => {
    validLogin();
    postUserInfo();
  }, []);

  const myBankAccountItems = {
    userName,
    setUserName,
    bankNum,
    setBankNum,
    selectedValue,
    setSelectedValue,
    setBankAccount,
    bankName,
  };

  return (
    <>
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader title={"환불 계좌 관리"} prevBtn />
      </Mobile>

      <MyBankArea>
        <PC>
          <MyPageLayOutName />
        </PC>

        <FlexLayOut>
          <PC>
            <MyPageLayOutMenu />
          </PC>
          <ContentSection>
            <PC>
              <MyPageLayOutInfo />
            </PC>

            <MyBankSection>
              <MyBankAccount {...myBankAccountItems} />
            </MyBankSection>
          </ContentSection>
        </FlexLayOut>
      </MyBankArea>
    </>
  );
}

const MyBankArea = styled.div`
  width: 100%;
  padding: 180px 0 200px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const MyBankSection = styled.div`
  padding-top: 60px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (60 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: 0;
  }
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
