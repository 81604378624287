//react
import { useState,useEffect } from "react";
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery"

//css
import styled from "styled-components"

//page
import CommentLayout from "./CommentLayout"

export default function NoticeComment() {
    const [reComment,setReComment] = useState(false);
    const [reCommentClick,setReCommentClick] = useState(false);
    const [reListClick,setReListClick] = useState(false);

    const commentList = [
        {
            user_name: "닉네임",
            comment_date: "05.11",
            comment_text: "댓글 내용 영역",
        },
        {
            user_name: "홍길동",
            comment_date: "07.24",
            comment_text: "댓글댓글",
        }
    ]
    const recommentList = [
        {
            user_name: "닉네임",
            comment_date: "05.11",
            comment_text: "댓글 내용 영역",
        },
        {
            user_name: "홍길동",
            comment_date: "07.24",
            comment_text: "댓글댓글",
        }
    ]
  return (
    <Container>
        <PC>
            <NoticeCommentWrap>
                <DetailContentWrap>
                    <DetailContent>
                        <DetailTitle>{commentList.length + recommentList.length}개의 댓글</DetailTitle>
                    </DetailContent>

                    <CommentWrap>
                        <CommentBox>
                            <TextArea name="" id="" placeholder="댓글을 작성하시려면 로그인해주시기 바랍니다."/>
                            <CommentBtn>등록</CommentBtn>
                        </CommentBox>
                    </CommentWrap>
                    <CommentListWrap>
                        {
                            commentList.map((value,index)=>{
                                return(
                                    <CommentLayout value={value}/>
                                )
                            })
                        }
                    </CommentListWrap>
                </DetailContentWrap>
            </NoticeCommentWrap>
        </PC>

        <Mobile>
        </Mobile>
    </Container>
  );
}
const Container = styled.div`
    width: 100%;
    @media screen and (max-width: 1320px) {
    }
`
const NoticeCommentWrap = styled.div`
    width: 1300px;
    margin: 0 auto;
    padding-bottom: 200px;
    @media screen and (max-width: 1320px) {
    }
`
const DetailContentWrap = styled.div`
    width: 100%;
    margin-top: 50px;
`
const DetailContent = styled.div`
    width: 100%;
    padding: 25px 0;
    border-bottom: 1px solid #20232A;
`
const DetailTitle = styled.p`
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
`
const CommentWrap = styled.div`
    width: 100%;
    margin-top: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #d9d9d9;
`
const CommentListWrap = styled.div`
    width: 100%;
`
const CommentBox = styled.div`
    position: relative;
    width: 100%;
`
const TextArea = styled.textarea`
    width: 100%; height: 100px;
    padding: 15px 140px 15px 15px;
    border: 1px solid #707070;
    border-radius: 5px;
`
const CommentBtn = styled.p`
    position: absolute; right: 20px; bottom: 20px;
    width: 100px;
    padding: 10px 0;
    border-radius: 10px;
    text-align: center;
    color: #fff;
    background: #525252;
`