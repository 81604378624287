import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Mobile } from "../../MediaQuery";
import { useSelector } from "react-redux";
import styled from "styled-components";
import IconLogo from "../../img/icon/wala_logo.svg";
import IconSearch from "../../img/icon/icon_header_search_black.svg";
import IconCart from "../../img/icon/icon_header_cart_black.svg";
import IconCartBack from "../../img/icon/icon_header_back.svg";
import IconMoHome from "../../img/icon/icon_mo_top_home.svg";
import GnbList from "./GnbList";

export default function ModalHeader({ back = false, setContentsDetail }) {
  const history = useHistory();
  const location = useLocation();
  const { isLogged } = useSelector((state) => state.userData);
  const [isMenuOn, setIsMenuOn] = useState(false);
  const deviceType = useSelector((state) => state.deviceData.device.data);

  useEffect(() => {
    if (!back) return;
    const unListen = history.block((loc, action) => {
      if (action === "POP") {
        setContentsDetail(false);
      }
      return false;
    });

    return () => {
      unListen();
    };
  }, [history, back]);

  return (
    <Mobile>
      <Container>
        <HeaderWrap>
          <HeaderInner>
            {!back ? (
              <LogoWrap>
                <Logo to={`/store`}>
                  <LogoImg src={IconLogo} alt="logo" />
                </Logo>
              </LogoWrap>
            ) : (
              <BackWrap
                onClick={() => {
                  setContentsDetail(false);
                }}
              >
                <BackIcon>
                  <BackIconImg src={IconCartBack} alt="logo" />
                </BackIcon>
              </BackWrap>
            )}
          </HeaderInner>
          {back &&
            <HeaderRight>
              <HomeIconWrap
                onClick={() => {
                  if (deviceType !== "ios") {
                    setContentsDetail(false);
                    setTimeout(() => {
                      history.push(`/store`);
                    }, 0)
                  }
                }}
                onTouchEnd={() => {
                  if (deviceType === "ios") {
                    setContentsDetail(false);
                    new Promise((resolve) => setTimeout(resolve)).then(() => {
                      history.push(`/store`);
                    });
                  }
                }}
              >
                <HomeIconImg src={IconMoHome} alt="home-icon" />
              </HomeIconWrap>
              <SearchIconWrap
                onClick={() => {
                  if (deviceType !== "ios") {
                    setContentsDetail(false);
                    setTimeout(() => {
                      history.push(`/store/search?keyword=`);
                    }, 0)
                  }
                }}
                onTouchEnd={() => {
                  if (deviceType === "ios") {
                    setContentsDetail(false);
                    new Promise((resolve) => setTimeout(resolve)).then(() => {
                      history.push(`/store/search?keyword=`);
                    });
                  }
                }}
              >
                <SearchImg src={IconSearch} alt="search-icon" />
              </SearchIconWrap>
              <CartIconWrap
                onClick={() => {
                  if (deviceType !== "ios") {
                    setContentsDetail(false);
                    setTimeout(() => {
                      if (isLogged) {
                        history.push(`/store/cart`);
                      } else {
                        history.push(`/store/login?returnTo=${location.pathname}`);
                      }
                    }, 0)
                  };
                }}
                onTouchEnd={() => {
                  if (deviceType === "ios") {
                    setContentsDetail(false);
                    new Promise((resolve) => setTimeout(resolve)).then(() => {
                      if (isLogged) {
                        history.push(`/store/cart`);
                      } else {
                        history.push(`/store/login?returnTo=${location.pathname}`);
                      }
                    });
                  };
                }}
              >
                <CartImg src={IconCart} alt="cart-icon" />
              </CartIconWrap>
            </HeaderRight>
          }
          {!back &&
            <HeaderRight>
              <SearchIconWrap
                onClick={() => {
                  setContentsDetail(false);
                  setTimeout(() => {
                    history.push(`/store/search?keyword=`);
                  }, 0)
                }}
              >
                <SearchImg src={IconSearch} alt="search-icon" />
              </SearchIconWrap>
              <CartIconWrap
                onClick={() => {
                  setContentsDetail(false);
                  setTimeout(() => {
                    if (isLogged) {
                      history.push(`/store/cart`);
                    } else {
                      history.push(`/store/login?returnTo=${location.pathname}`);
                    }
                  }, 0)
                }}
              >
                <CartImg src={IconCart} alt="cart-icon" />
              </CartIconWrap>
            </HeaderRight>
          }
        </HeaderWrap>
      </Container>
      {isMenuOn && <GnbList menuOn={isMenuOn} setMenuOn={setIsMenuOn} />}
    </Mobile>
  );
}
const Container = styled.div`
  width: 100%;
  overflow: hidden;
  z-index: 200;
  transition: ease 0.3s all;
  height: 136.85px;
  position: fixed;
  z-index: 200;
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (136.85 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (58 / 428));
  }
`;
const HeaderWrap = styled.div`
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #bfbfbf;
  transition: ease 0.3s all;

  @media screen and (max-width: 930px) {
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    border-bottom: #eeeeee calc(100vw * (1 / 428)) solid;
    position: fixed;
  }
`;
const HeaderInner = styled.div`
  width: 1240px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (102 / 428));
    margin: 0;
  }
`;
const HeaderRight = styled.div`
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (12 / 1320)) calc(100vw * (25 / 1320));
  }
  @media screen and (max-width: 930px) {
    display: flex;
    padding: calc(100vw * (12 / 428)) calc(100vw * (25 / 428));
  }
`;
const SearchIconWrap = styled.div`
  width: 32px;
  height: 32px;
  & > img {
    height: 100%;
  }
  @media screen and (max-width: 1320px) {
    margin-right: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
    margin-right: calc(100vw * (15 / 428));
  }
`;
const SearchImg = styled.img``;
const CartIconWrap = styled.div`
  width: 32px;
  height: 32px;
  & > img {
    height: 100%;
  }
  @media screen and (max-width: 1320px) {
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
  }
`;
const CartImg = styled.img``;
const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (19 / 428)) calc(100vw * (25 / 428));
  }
`;
const Logo = styled(Link)`
  width: 124px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (124 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (110 / 428));
  }
`;

const LogoImg = styled.img`
  display: block;
  width: 100%;
  @media screen and (max-width: 1320px) {
    width: 100%;
  }

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const BackWrap = styled.div`
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (12 / 428)) calc(100vw * (25 / 428));
  }
`;
const BackIcon = styled.div`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
  }
`;
const BackIconImg = styled.img`
  width: 100%;
  height: 100%;
`;

const HomeIconWrap = styled.div`
   width: 32px;
  height: 32px;
  & > img {
    height: 100%;
  }
  @media screen and (max-width: 1320px) {
    margin-right: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
    margin-right: calc(100vw * (15 / 428));
  }
`;

const HomeIcon = styled.div`
  /* @media screen and (max-width: 930px) {
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
  } */
`;
const HomeIconImg = styled.img`
  /* width: 100%;
  height: 100%; */
`;