import React, { useState, useRef, useEffect } from 'react';
import { useParams, useHistory,} from 'react-router-dom';
import { PC, Mobile } from '../common/mediaStlye';
import serverController from '../../server/serverController';
import styled from 'styled-components';

import {
    Container,
    Layout,    
    StyleSubHead,
    StyleBackButton,
    StyleRegistWrap,
    StyleRegistForm,
    StyleRegistField,
    StyleFieldName,
    StyleFieldInput,    
    StyleRegistTextHead,
    StyleFieldTextarea,
    StyleRegistSelect,
    StyleRegistOption,
    StyleFieldButton,
    StyleRegistTips,
    StyleRegistButtons,
    StyleRegistButton
} from './stylist.element';

import icon from "../../img/brand/benner_icon.svg";
import arrow from "../../img/brand/arrow.png";
import notice from "../../img/brand/notice.png";
import close from "../../img/brand/close.png";
import calendar from "../../img/brand/calendar.png";

const StylistContentsModify = () => {
    const params = useParams();
    const {contentsId} = params;
    const history = useHistory();
    const FormRef = useRef()
    const [subContents, setSubContents] = useState([])
    const onHandleGoback = () => history.goBack();    

    const onHandleSubs = e => {
        const {
            target: { files }
        } = e;

        setSubContents(prev => {
            if (files[0]) {
                return prev.concat(files[0])
            } else {
                return prev;
            }
        });
    }

    const onHandleSubmit = e => {
        e.preventDefault();
        const formData = new FormData();        
        formData.append('contents_id',contentsId);
        formData.append('contents_title',conTitle);
        formData.append('contents_tag',conTag);
        formData.append('contents_content',conContents);
        formData.append('contents_link',conLink);
        for (let key of formData.entries()) {
            console.log(key[0], key[1]);          
        }       

        subContents.forEach((el) => {
            formData.append("sub_img", el)
        })        
        formData.append("main_img", conMain);
        formData.append("thumbnail_img", conThumb);

        formData.append("contents_active", active);
        if (active === 3) {
            formData.append("open_date", openDate);
        }
        console.log("===============")
        for (let key of formData.entries()) {
            console.log(key[0], key[1]);          
        }                        
        console.log("===============")
        registContent(formData)
    }

    const isClick = useRef(false);
    const registContent = async (data) => {
        if (isClick.current) return;
        await serverController.connectFetchController(
            `contents`,
            "PUT",
            data,
            (res) => {
                // console.log(res,"컨텐츠 등록res");     
                isClick.current = false;
                if (res?.result === 1) {
                    history.push('/stylist/contents');
                }
            },
            (err) => {
                isClick.current = false;
            }                 
        )
        
    }

    const [openDate, setOpenDate] = useState("");
    const [active, setActive] = useState(0);
    useEffect(()=>{
        console.log(active);
    },[active]);
    const handleOpenDate = (e)=> {
        const value = e.target.value;
        setActive(value);
        switch(value) { //. 공개\n2. 비공개\n3. 오픈 예정\n4. 임시저장
            case 0: 
                setActive(1)
                return;
            case 1: 
                setActive(2)
                return;
            case 2: 
                setActive(3);
                const contents_date = new Date();
                const year = contents_date.getFullYear();
                const month = contents_date.getMonth() + 1;
                const day = contents_date.getDate() + 1;
                const hour = contents_date.getHours();
                const min = contents_date.getMinutes();
                const new_date = `${year + 1}-${month >= 10 ? month : "0" + month}-${day >= 10 ? day : "0" + day} ${hour >= 10 ? hour : "0" + hour}:${min >= 10 ? min : "0" + min}`                
                setOpenDate(new_date)   
                return;
        }
             
    }

    const [modifyData, setModifyData] = useState({});
    const [conTitle, setContitle] = useState('');
    const [conTag, setConTag] = useState('');
    const [conContents, setConContents] = useState('');
    // const [] = useState(); // handelOpen
    const [conMain, setConMain] = useState(null);
    // const [conSub, setConSub] = useState('');
    const [conLink, setConLink] = useState('');
    // const [] = useState();
    const [conThumb, setConThumb] = useState(null);

    /*
    contents_id
    contents_title: 김마리
    contents_tag: 김말이
    contents_content: 김마리
    main_img: (binary)
    thumbnail_img: (binary)
    sub_img: (binary)
    contents_active: 2
    */

    const getStylistModifyDetail = async () => {
        await serverController.connectFetchController(
            `/contents/${contentsId}`,
            "GET",
            null,
            (res) => {
                if(res.result === 1){
                    let modifyOb = res.content;
                    setModifyData(modifyOb);
                }
            }            
        );          
    }
    useEffect(()=>{
        getStylistModifyDetail();
    },[]);

    useEffect(()=>{
        const {
            contents_title,
            contents_tag,
            contents_content,
            contents_active,
            contents_main,
            contents_link,
            contents_thumbnail,
        } = modifyData;
        console.log(contents_thumbnail);
        setContitle(contents_title || '');
        setConTag(contents_tag || '');
        setConContents(contents_content || '');
        setActive(contents_active);
        setConMain(contents_main);
        setConThumb(contents_thumbnail);

        setConLink(contents_link || '');
    },[modifyData]);

    return (
        <Container>
            <Layout sub>
                <PC>
                <StyleSubHead sub>
                        <StyleBackButton onClick={onHandleGoback}>
                            <img src={arrow} alt="arrow"/>
                        </StyleBackButton>
                        <h2>콘텐츠 등록</h2>
                </StyleSubHead>
                </PC>

                <StyleRegistWrap>
                    <StyleRegistForm onSubmit={onHandleSubmit} ref={FormRef} enctype="multipart/form-data">
                            <StyleRegistField>
                                <StyleFieldName>Title</StyleFieldName>
                                <StyleFieldInput 
                                 type="text" placeholder="제품명을 입력해주세요." 
                                value={conTitle || ''}
                                onChange={(e)=>{
                                    let value = e.target.value;
                                    setContitle(value);
                                }}
                                required/>
                            </StyleRegistField>

                            <StyleRegistField>
                                <StyleFieldName>해시태그</StyleFieldName>
                                <StyleFieldInput type="text" placeholder="해시태그를 입력해주세요."
                                value={conTag || ''}
                                onChange={(e)=>{
                                    let value = e.target.value;
                                    setConTag(value);
                                }}
                                required/>
                            </StyleRegistField>                           

                            <StyleRegistField column>
                                <StyleRegistTextHead>설명</StyleRegistTextHead>
                                <StyleFieldTextarea placeholder="설명을 입력해주세요."
                                value={conContents || ''}
                                onChange={(e)=>{
                                    let value = e.target.value;
                                    setConContents(value);
                                }}
                                required/>
                            </StyleRegistField>

                            <StyleRegistField column>
                                <StyleRegistTextHead>상태</StyleRegistTextHead>
                                <StyleRegistSelect
                                value={active}
                                onChange={(e)=>{handleOpenDate(e)}} 
                                required>
                                    <option value={0} hidden>공개 / 비공개 / 오픈 예정</option>
                                    <StyleRegistOption
                                    value={1}
                                    >공개</StyleRegistOption>
                                    <StyleRegistOption
                                    value={2}>비공개</StyleRegistOption>
                                    <StyleRegistOption
                                    value={3}>오픈 예정</StyleRegistOption>
                                </StyleRegistSelect>
                            </StyleRegistField>

                            <StyleRegistField >
                                <StyleFieldName>이미지 (메인)</StyleFieldName>
                                <StyleFieldInput type="file" accept="image/*" placeholder="1장을 업로드 해주세요." 
                                onChange={(e)=>{
                                    let file = e.target.files[0];
                                    setConMain(file);
                                }} required/>
                                <PC>
                                <StyleFieldButton>업로드</StyleFieldButton>
                                </PC>                                
                            </StyleRegistField>
                            <Mobile>
                            <StyleFieldButton>업로드</StyleFieldButton>
                            </Mobile>
                            
                            <StyleRegistField htmlFor="contents_sub">
                                <StyleFieldName>이미지 (서브)</StyleFieldName>
                                <StyleFieldInput onChange={onHandleSubs} id="contents_sub" type="file" accept="image/*" placeholder="최대 4장을 업로드 해주세요."required />
                                <PC>
                                <StyleFieldButton>업로드</StyleFieldButton>
                                </PC>                              
                            </StyleRegistField>                                
                            <Mobile>
                            <StyleFieldButton>업로드</StyleFieldButton>
                            </Mobile>

                            <StyleRegistField htmlFor="products[0].prd_name" name="products[0].prd_name">
                                <StyleFieldName>제품 타이틀</StyleFieldName>
                                <StyleFieldInput id="products[0].prd_name"
                                name="products[0].prd_name" type="text" placeholder="타이틀을 입력해주세요"/>
                            </StyleRegistField>

                            <StyleRegistField>
                                <StyleFieldName>링크</StyleFieldName>
                                <StyleFieldInput 
                                type="text" 
                                value={conLink || ''}
                                onChange={(e)=>{
                                    let value = e.target.value;
                                    setConLink(value);
                                }}
                                />
                                <PC>
                                <StyleFieldButton>제품 링크 추가</StyleFieldButton>
                                </PC>
                            </StyleRegistField>
                            <Mobile>
                            <StyleFieldButton>제품 링크 추가</StyleFieldButton>
                            </Mobile>

                            <StyleRegistField htmlFor="contents_thumbnail">
                                <StyleFieldName>썸네일</StyleFieldName>
                                <StyleFieldInput id="contents_thumbnail"  type="file" accept="image/*" 
                                onChange={(e)=>{
                                    let file = e.target.files[0];
                                    setConThumb(file);
                                }} required/>
                                <PC>
                                <StyleFieldButton>업로드</StyleFieldButton>
                                </PC>                                
                            </StyleRegistField>
                            <Mobile>
                            <StyleFieldButton>업로드</StyleFieldButton>
                            </Mobile>
                    

                    <StyleRegistTips>
                        <p className="tips-save">
                            *임시저장을 하시면 등록 완료 여부와 상관없이 현 상태에서<br/>
                            등록 정보를 임시로 저장 후 원하실 때 수정 및 공개 하실 수 있습니다.
                        </p>
                        <p>
                        *”공개”로 설정 시 즉시 공개가 진행됩니다. <br/>
                        *”비공개”로 설정 시 노출되지 않으며, 상태 변경에 따라 공개가 진행됩니다.<br/>
                        *”오픈 예정”으로 설정 시 노출되지만, 설정하신 날짜까지 결제<br/>
                        또는 협찬 및 대여 신청 버튼이 비활성화 상태로 노출 됩니다.<br/>
                        </p>                        
                    </StyleRegistTips>

                    <StyleRegistButtons>
                        <StyleRegistButton>임시 저장</StyleRegistButton>
                        <StyleRegistButton as="button" type="submit" black>저장</StyleRegistButton>
                    </StyleRegistButtons>
                    </StyleRegistForm>
                </StyleRegistWrap>
                </Layout>
        </Container>
    )
}

export default StylistContentsModify;

