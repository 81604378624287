import React from 'react';
import { imgURL } from '../../../server/appInfoContoller';
import { useHistory } from 'react-router-dom';

import {
    TabItemsContent,
    TabItemsHeading,
    TabItemConts,
    TabItemImg,
    TabItemDesc,
    TabItemText,
    TabItemLiked, MobileTabItemConts,
    TabItemsList,
    MobileTabItemsHeading,
    TabPages,
    PageNum,
    PrevButton,
    NextButton
} from './mypage.elements';

import LikedIcon from '../../../images/stylist/liked_icon.svg';
import TabPrevIcon from '../../../images/tab-arrow-prev.png';
import { Mobile, PC } from '../../common/mediaStlye';

const TabContentItemList = ({ data, setActiveIndex }) => {
    const history = useHistory()
    const headings = ["Celebrity", "Drama", "Professional", "Idol"];

    return (
        <>
            <PC>
                <TabItemsContent>
                    <TabItemsHeading onClick={() => setActiveIndex(0)} style={{ cursor: 'pointer' }}>
                        <h3>
                            <img src={TabPrevIcon} />
                            Contents
                        </h3>
                        <div>
                            <span>총 {data ? data.length : 0}개</span>
                            <span>더보기</span>
                        </div>
                    </TabItemsHeading>

                    <TabItemsList list>
                        {
                            data.map((v, i) => {
                                const src = v?.contents_thumbnail?.split(",")[0]

                                return (
                                    <TabItemConts key={v?.contents_id} onClick={() => history.push(`/content/${v?.contents_id}`)}>
                                        <TabItemImg><img src={imgURL + src} /></TabItemImg>

                                        <TabItemDesc className="items-desc">
                                            <TabItemText>
                                                <em>{headings[v?.c_cat_id - 1]}</em>
                                                <h2>{v?.contents_title}</h2>
                                                <p>{v?.contents_tag}</p>
                                            </TabItemText>
                                        </TabItemDesc>

                                        <TabItemLiked className="item-liked"><img src={LikedIcon} /></TabItemLiked>
                                    </TabItemConts>
                                )
                            })
                        }
                    </TabItemsList>
                    {/* <TabPaging>
                    <PrevButton />

                    <TabPages>
                    {
                        Array(3).fill().map((page, i) => {
                            return <PageNum>{i + 1}</PageNum>
                        })
                    }
                    </TabPages>

                    <NextButton />
                </TabPaging> */}
                </TabItemsContent>
            </PC>

            <Mobile>
                <TabItemsContent>

                    <MobileTabItemsHeading onClick={() => setActiveIndex(0)} style={{ cursor: 'pointer' }}>
                        <h3>
                            <img src={TabPrevIcon} />
                            Contents
                        </h3>
                        <div>
                            <span>총 {data ? data.length : 0}개</span>
                            <span>더보기</span>
                        </div>
                    </MobileTabItemsHeading>
                    <div style={{ padding: "0 20px", display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        <TabItemsList list>
                            {
                                data.map((v, i) => {
                                    const src = v?.contents_thumbnail?.split(",")[0]

                                    return (
                                        <MobileTabItemConts
                                            style={{ width: "48%" }}
                                            key={v?.contents_id}
                                            onClick={() => history.push(`/content/${v?.contents_id}`)}
                                        >
                                            <TabItemImg><img src={imgURL + src} /></TabItemImg>
                                            <TabItemLiked className="item-liked"><img src={LikedIcon} /></TabItemLiked>
                                        </MobileTabItemConts>
                                    )
                                })
                            }
                        </TabItemsList>
                    </div>
                    {/* <TabPaging>22
                    <PrevButton />

                    <TabPages>
                    {
                        Array(3).fill().map((page, i) => {
                            return <PageNum>{i + 1}</PageNum>
                        })
                    }
                    </TabPages>

                    <NextButton />
                </TabPaging> */}
                </TabItemsContent>
            </Mobile>
        </>
    )
}

export default TabContentItemList
