import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

export default function StepOne(props) {
  const { clickGenderBox, setClickGenderBox } = props;
  const history = useHistory();

  const onClickNextButton = () => {
    if (clickGenderBox === -1) return;
    history.push(`/store/mypage/personal/2`);
  };
  return (
    <Container>
      <GenderWrap>
        <GenderBox onClick={() => setClickGenderBox(0)} isSelect={clickGenderBox === 0}>
          <GenderIcon isSelect={clickGenderBox === 0}>
            <strong>M</strong>
          </GenderIcon>
          <GenderTitle>MAN</GenderTitle>
        </GenderBox>
        <GenderBox onClick={() => setClickGenderBox(1)} isSelect={clickGenderBox === 1}>
          <GenderIcon isSelect={clickGenderBox === 1}>
            <strong>W</strong>
          </GenderIcon>
          <GenderTitle>WOMAN</GenderTitle>
        </GenderBox>
      </GenderWrap>
      <StepNextButtonWrap onClick={onClickNextButton}>
        <StepNextButton isActive={clickGenderBox === 0 || clickGenderBox === 1}>
          다음으로
        </StepNextButton>
      </StepNextButtonWrap>
    </Container>
  );
}
const Container = styled.div`
  margin-top: 100px;
  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (100 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (100 / 428));
  }
`;
const GenderWrap = styled.div`
  width: 100%;
  display: flex;
  height: 240px;
  gap: 10px;
  justify-content: center;
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (240 / 1320));
    gap: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (240 / 428));
    gap: calc(100vw * (10 / 428));
  }
`;
const GenderBox = styled.div`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  border: 2px solid #d9d9d9;
  width: 185px;
  ${({ isSelect }) =>
    isSelect &&
    `
    transform : translate(0, -8);
    box-shadow : 5px 5px 20px 0 rgb(0 0 0 / 20%);
  `}
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (185 / 1320));
  }
  @media screen and (max-width: 930px) {
    ${({ isSelect }) =>
      isSelect &&
      `
    transform : translate(0, calc(100vw * (-16 / 1920)));
    box-shadow : 5px 5px 20px 0 rgb(0 0 0 / 20%);
    `}
    width: calc(100vw * (184 / 428));
  }
`;
const GenderIcon = styled.p`
  position: relative;
  transition: all 0.3s ease;
  background-color: #d9d9d9;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
  width: 128px;
  height: 128px;
  margin: 28px auto 22px;
  ${({ isSelect }) => isSelect && `background-color : #FFF200;`}
  > strong {
    position: absolute;
    color: #fff;
    font-weight: 900;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 54px;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (128 / 1320));
    height: calc(100vw * (128 / 1320));
    margin: calc(100vw * (28 / 1320)) auto calc(100vw * (22 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (128 / 428));
    height: calc(100vw * (128 / 428));
    margin: calc(100vw * (28 / 428)) auto calc(100vw * (22 / 428));
    > strong {
      font-size: calc(100vw * (54 / 428));
    }
  }
`;
const GenderTitle = styled.p`
  font-weight: 900;
  color: #a5a5a5;
  text-align: center;
  font-size: 16px;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (13 / 428));
  }
`;
const StepNextButtonWrap = styled.div`
  text-align: center;
  margin-top: 100px;
  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (100 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (100 / 428));
  }
`;
const StepNextButton = styled.button`
  margin: 0 auto;
  background-color: #999;
  color: #fff;
  font-size: 18px;
  height: 59px;
  width: 220px;
  ${({ isActive }) => isActive && `background-color : #000;`}
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    height: calc(100vw * (59 / 1320));
    width: calc(100vw * (220 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    height: calc(100vw * (59 / 428));
    width: calc(100vw * (220 / 428));
  }
`;
