import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { PC, Mobile } from "../../MediaQuery";
import { PaymentAction } from "../../store/actionCreators";
import { useSelector } from "react-redux";
import serverController from "../../server/serverController";
import { loadTossPayments } from "@tosspayments/sdk";
import { numberWithCommas } from "../../components/common/commonUse";
import styled from "styled-components";

import PayTerm from "../../store_src/component/shopping/pay/PayTerm";
const clientKey = "test_ck_ADpexMgkW362EyYLOxp3GbR5ozO0";

const StylistPayPrice = ({ setIsModal }) => {
  const { userInfo } = useSelector((state) => state.userData);
  const {
    payment,
    isPersonal,
    isTermCheck,
    personal,
    location,
    method,
    points,
  } = useSelector((state) => state.paymentData);

  const [totals, setTotals] = useState(0);
  const [shipFees, setShipFees] = useState(0);
  const [finalPrice, setFinalprice] = useState(0);

  const betweenDay = (firstDate, secondDate) => {
    var firstDateObj = new Date(firstDate);
    var secondDateObj = new Date(secondDate);
    var betweenTime = Math.abs(
      secondDateObj.getTime() - firstDateObj.getTime()
    );
    return Math.floor(betweenTime / (1000 * 60 * 60 * 24));
  };

  const makeProductlist = () => {
    const product_list = []; // 주문한 전체 상품 데이터 배열
    let totalCount = 0; // 최종가격
    let orderName = "";
    let dateDiffer = 0;
    let purchase_id = 0;

    payment.forEach((obj, i) => {
      const prdInfo = obj?.purchase_prd;
      dateDiffer = (betweenDay(obj?.start_date, obj?.return_date) ?? 1) + 1;
      orderName = prdInfo?.prd_name;
      purchase_id = obj?.purchase_id ?? 0;

      product_list.push({
        prd_id: prdInfo?.prd_id,
        count: prdInfo.count ?? 1,
        prd_sale_rate: prdInfo?.prd_sale_rate ?? 0,
        prd_sale_price: prdInfo?.prd_sale_price ?? 0, // 선택상품 가격
        prd_list_price: prdInfo?.prd_list_price ?? 0, // 선택상품 가격
        prd_days_price: prdInfo?.prd_days_price ?? 0,
        optionList: {},
        optId: [],
        br_id: prdInfo?.br_id ?? 0,
        br_limit: 0,
        br_shipping_charge: 0,
      });

      totalCount += prdInfo?.prd_days_price * dateDiffer;
    });
    return [
      JSON.stringify(product_list),
      totalCount,
      dateDiffer,
      orderName,
      purchase_id,
    ];
  };

  const checkPersonal = () => {
    const { name, email, phone } = personal;
    const regExpEmail =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    const regExpPhone = /01[016789]-[^0][0-9]{2,3}-[0-9]{3,4}/;

    if (!name) {
      alert("이름을 입력해주세요");
      return false;
    }

    if (!regExpEmail.test(email)) {
      alert("이메일 형식을 맞춰주세요");
      return false;
    }

    if (!regExpPhone.test(phone)) {
      alert("휴대폰 형식으로 입력해주세요. ex) 010-1234-4567");
      return false;
    }
    return true;
  };

  const isClick = useRef(false);
  const handlePayment = async () => {
    if (!isTermCheck) {
      alert("이용약관에 동의해주세요");
      return;
    } else if (!checkPersonal()) {
      return;
    } else if (isClick.current) {
      alert("새로고침해주세요");
      return;
    }
    const formData = new FormData();
    const [product_list, totalCount, dateDiffer, orderName, purchase_id] =
      makeProductlist();

    console.log(product_list, totalCount, dateDiffer, orderName, purchase_id);
    console.log("product_list, totalCount, dateDiffer, orderName, purchase_id");
    // return;
    const orderId = new Date().getTime() + randomStringFunc(10);
    const earn_point = parseInt(((totalCount - points) * (1 / 100)).toFixed(0));

    formData.append("order_id", orderId);
    formData.append("purchase_id", purchase_id);
    formData.append("cart_id", 0);
    formData.append("coupon_id", 0);
    formData.append("rentals_date", dateDiffer);

    formData.append("receiver_name", personal.name);
    formData.append("receiver_email", personal?.email);
    formData.append("receiver_phone", personal?.phone);
    formData.append("receiver_address", location.receiver_address);
    formData.append(
      "receiver_address_detail",
      location.receiver_address_detail
    );
    formData.append("receiver_request", location.receiver_request);

    formData.append("product_list", product_list);
    formData.append("total_price", totalCount);
    formData.append("delivery_fee", 0);
    formData.append("used_point", 0);
    formData.append("earn_point", earn_point);
    formData.append("coupon_discount", 0);

    for (let key of formData.entries()) {
      console.log(key[0], key[1]);
    }

    await serverController.connectFetchController(
      `payment/request-validate`,
      "POST",
      formData,
      (res) => {
        console.log(res, "결제 res");
        if (res?.result == 1) {
          isClick.current = true;
          payToss(orderId, totalCount, orderName);
        }
      },
      (err) => {
        isClick.current = true;
      }
    );
  };

  function randomStringFunc(index) {
    // 대문자 영문
    var chars = "ABCDEFGHIJKLMNOPQRSTUVWXTZ";
    // 반환받을 문자열의 수
    var stringLength = index;
    var randomstring = "";
    for (var i = 0; i < stringLength; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  } // 결제수단

  const payToss = async (orderId, totalCount, orderName) => {
    const tossPayments = await loadTossPayments(clientKey);

    tossPayments.requestPayment(method, {
      amount: totalCount,
      orderId,
      orderName,
      customerName: userInfo.mem_name,
      successUrl: "https://wala.land:8071/success",
      failUrl: "https://wala.land:8071/fail",
    });
    // ttps://{origin}/success?paymentKey={paymentKey}&orderId={orderId}&amount={amount}
  };

  useEffect(() => {
    const [list, totalCount] = makeProductlist();
    // const TotalPrice = totalCount + shipFeeByBrand
    // const shipFeeByBrand = calculateShipFees(payment);
    PaymentAction.checkInfo({ totalPrice: totalCount });
    console.log(totalCount);
    // setShipFees(shipFeeByBrand)
    setTotals(totalCount);
    setFinalprice(totalCount);
  }, [payment]);

  return (
    <Container>
      <PC>
        <PayPriceWrap>
          <PriceWrap>
            <PriceTitleWrap>
              <PriceTitle>결제금액</PriceTitle>
            </PriceTitleWrap>
            <PriceListWrap>
              <PriceList>
                <PriceListTitle>총 상품 금액</PriceListTitle>
                <PriceListTextBold>
                  {numberWithCommas(totals)}원
                </PriceListTextBold>
              </PriceList>
              {/* <PriceList>
                                <PriceListTitle>배송비</PriceListTitle>
                                <PriceListText>{numberWithCommas(shipFees)}원</PriceListText>
                            </PriceList> */}
              {/* <PriceList>
                                <PriceListTitle>포인트 사용</PriceListTitle>
                                <PriceListText>{numberWithCommas(points)}원</PriceListText>
                            </PriceList> */}
              {/* <PriceList>
                                <PriceListTitle>쿠폰 사용</PriceListTitle>
                                <PriceListText>0원</PriceListText>
                            </PriceList> */}
            </PriceListWrap>
            <TotalPriceWrap>
              <TotalPriceInner>
                <TotalPriceTitle>최종 결제 금액</TotalPriceTitle>
                <TotalPrice>
                  <Price>{numberWithCommas(finalPrice)}</Price> 원
                </TotalPrice>
              </TotalPriceInner>
              {/* <TotalPointWrap>
                                <TotalPoint><Point>{(totals * (1 / 100)).toFixed(0)}P</Point> 적립 예정</TotalPoint>
                            </TotalPointWrap> */}
            </TotalPriceWrap>
          </PriceWrap>
          {/* <Link to={`/store/pay/complete`}> */}
          <PayBtnWrap onClick={handlePayment}>
            <PayBtn>
              <PayBtnSub>{numberWithCommas(finalPrice)}</PayBtnSub>원 결제하기
            </PayBtn>
          </PayBtnWrap>
          {/* </Link> */}
          <PayTerm setIsModal={setIsModal} />
        </PayPriceWrap>
      </PC>

      <Mobile>
        <PayPriceWrap>
          <PriceWrap>
            <PriceTitleWrap>
              <PriceTitle>결제금액</PriceTitle>
            </PriceTitleWrap>
            <PriceListWrap>
              <PriceList>
                <PriceListTitle>총 상품 금액</PriceListTitle>
                <PriceListTextBold>
                  {numberWithCommas(totals)}원
                </PriceListTextBold>
              </PriceList>
              {/* <PriceList>
                                <PriceListTitle>배송비</PriceListTitle>
                                <PriceListText>{shipFees}원</PriceListText>
                            </PriceList>
                            <PriceList>
                                <PriceListTitle>포인트 사용</PriceListTitle>
                                <PriceListText>0원</PriceListText>
                            </PriceList>
                            <PriceList>
                                <PriceListTitle>쿠폰 사용</PriceListTitle>
                                <PriceListText>0원</PriceListText>
                            </PriceList> */}
            </PriceListWrap>
            <TotalPriceWrap>
              <TotalPriceInner>
                <TotalPriceTitle>최종 결제 금액</TotalPriceTitle>
                <TotalPrice>
                  <Price>{numberWithCommas(finalPrice)}</Price> 원
                </TotalPrice>
              </TotalPriceInner>
              {/* <TotalPointWrap>
                                <TotalPoint><Point>9999P</Point> 적립 예정</TotalPoint>
                            </TotalPointWrap> */}
            </TotalPriceWrap>
          </PriceWrap>
          {/* <Link to={`/stylist/pay/complete/${orderId}`}> */}
          <PayBtnWrap onClick={handlePayment}>
            <PayBtn>
              <PayBtnSub>{numberWithCommas(finalPrice)}</PayBtnSub>원 결제하기
            </PayBtn>
          </PayBtnWrap>
          {/* </Link> */}
        </PayPriceWrap>
      </Mobile>
    </Container>
  );
};

export default StylistPayPrice;

const Container = styled.div`
  width: 100%;
  border: 1px solid #000000;
  border-radius: 11px;

  @media screen and (max-width: 1320px) {
    border: calc(100vw * (1 / 1320)) solid #e4e4e4;
    border-radius: calc(100vw * (11 / 1320));
  }

  @media screen and (max-width: 930px) {
    border: none;
    border-radius: 0;
    margin-bottom: calc(100vw * (30 / 428));
    border-top: calc(100vw * (12 / 428)) solid #e9ebee;
  }
`;
const PayPriceWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    margin: 0 auto;
  }
`;

//price
const PriceWrap = styled.div`
  width: 100%;
  padding: 30px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const PriceTitleWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    justify-content: space-between;
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (10 / 428));
    border-bottom: none;
  }
`;
const PriceTitle = styled.p`
  font-size: 19px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (19 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const PriceListWrap = styled.div`
  width: 100%;
  margin-top: 15px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (15 / 428));
  }
`;
const PriceList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (12 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (12 / 428)) 0;
  }
`;
const PriceListTitle = styled.p`
  font-size: 16px;
  font-weight: normal;
  color: #969696;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const PriceListText = styled.p`
  font-size: 19px;
  color: #969696;
  font-weight: 100;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (19 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (19 / 428));
  }
`;
const PriceListTextBold = styled(PriceListText)`
  font-weight: bold;
  color: #000;
`;
const TotalPriceWrap = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  padding-top: 25px;
  border-top: 1px solid #d9d9d9;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    margin-top: calc(100vw * (10 / 1320));
    padding-top: calc(100vw * (25 / 1320));
    border-top: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    margin-top: calc(100vw * (10 / 428));
    padding-top: calc(100vw * (25 / 428));
    border-top: calc(100vw * (1 / 428)) solid #d9d9d9;
  }
`;
const TotalPriceInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const TotalPriceTitle = styled.p`
  font-size: 24px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
  }
`;
const TotalPrice = styled.p`
  display: flex;
  align-items: center;
  font-size: 24px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (24 / 428));
  }
`;
const Price = styled.span`
  font-size: 26px;
  color: #000;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (26 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (26 / 428));
  }
`;
const TotalPointWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: bold;
  margin-top: 14px;
  margin-bottom: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    margin-top: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    margin-top: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (15 / 428));
  }
`;
const TotalPoint = styled.p`
  font-size: 18px;
  color: #969696;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const Point = styled.span`
  color: #000;
  font-weight: bold;
`;
// button
const PayBtnWrap = styled.div`
  width: 100%;
  cursor: pointer;

  @media screen and (max-width: 930px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 30;
  }
`;
const PayBtn = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 18px;
  text-align: center;
  color: #fff;
  background: #000;
  padding: 20px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    padding: calc(100vw * (20 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const PayBtnSub = styled.span`
  cursor: pointer;
  font-weight: bold;
  color: #fff;
`;
