import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import FixedTop from "../../component/common/FixedTop";
import MypageMenuList from "../../component/mypage/MypageMenuList";
import MypageCancle from "../../component/mypage/order/MypageCancle";
import Footer from "../../component/common/StoreFooter";
import FooterMenu from "../../component/common/FooterMenu";
import FixMenu from "../../component/common/FixMenu";

export default function ReturnPage() {
  return (
    <>
      <PC>
        <Header />
        <MypageMenuList />
        <MypageCancle />
        <Footer />
        <FixedTop />
      </PC>

      <Mobile>
        <Header />
        <MypageMenuList menu={true} />
        <MypageCancle />
        <Footer />
        <FooterMenu />
        <FixMenu />
      </Mobile>
    </>
  );
}
