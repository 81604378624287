import React from 'react';
import MediaContentsBox from './MediaContentsBox';
import MediaContentsExtra from './MediaContentsExtra';

const FashionNewsContentsBox = () => {
    return (
        <div>
            <MediaContentsBox />
            <MediaContentsExtra />
            {/* <Fashion */}
        </div>
    )
}

export default FashionNewsContentsBox
