//react
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";

//css
import styled from "styled-components";

// img
import IconInquiryType1 from "../../img/icon/icon_product_inquiry_type1.png";
import IconInquiryType2 from "../../img/icon/icon_product_inquiry_type2.png";
import IconInquiryType3 from "../../img/icon/icon_product_inquiry_type3.png";
import IconLock from "../../img/icon/icon_secret_blue.png";
import IconRe from "../../img/icon/icon_recomment.png";

//page
import PasswordModal from "../common/modal/PasswordModal";
import Pagination from "../common/pagination/Pagination";
import Search from "../common/pagination/Search";

export default function InquiryType() {
  return (
    <Container>
      <InquiryTypeWrap>
        <InquiryTypeBox>
          <TypeImgWrap>
            <TypeImg src={IconInquiryType1} alt="Inquiry Type" />
          </TypeImgWrap>
          <TypeTextWrap>
            <TypeTitle>신상품 요청</TypeTitle>
            <TypeText>원하는 브랜드나 상품 입점 요청</TypeText>
          </TypeTextWrap>
        </InquiryTypeBox>
        <InquiryTypeBox>
          <TypeImgWrap>
            <TypeImg src={IconInquiryType2} alt="Inquiry Type" />
          </TypeImgWrap>
          <TypeTextWrap>
            <TypeTitle>상품정보 요청</TypeTitle>
            <TypeText>특정 상품 상세정보 요청</TypeText>
          </TypeTextWrap>
        </InquiryTypeBox>
        <InquiryTypeBox>
          <TypeImgWrap>
            <TypeImg src={IconInquiryType3} alt="Inquiry Type" />
          </TypeImgWrap>
          <TypeTextWrap>
            <TypeTitle>상품 품절입고</TypeTitle>
            <TypeText>품절 상품 입고 일정 문의</TypeText>
          </TypeTextWrap>
        </InquiryTypeBox>
      </InquiryTypeWrap>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
`;
// type img
const InquiryTypeWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #20232a;
  border-radius: 12px;
  padding: 40px 0;
  margin-bottom: 70px;

  @media screen and (max-width: 1320px) {
    border: calc(100vw * (1 / 1320)) solid #20232a;
    border-radius: calc(100vw * (12 / 1320));
    padding: calc(100vw * (40 / 1320)) 0;
    margin-bottom: calc(100vw * (70 / 1320));
  }

  @media screen and (max-width: 930px) {
    flex-wrap: wrap;
    border: calc(100vw * (1 / 428)) solid #707070;
    border-radius: calc(100vw * (12 / 428));
    padding: calc(100vw * (25 / 428)) 0 calc(100vw * (30 / 428));
    margin-bottom: calc(100vw * (30 / 428));
  }
`;
const InquiryTypeBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% / 3);

  @media screen and (max-width: 930px) {
    width: 100%;
    margin-bottom: calc(100vw * (35 / 428));
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const TypeImgWrap = styled.div`
  width: 60px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (60 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (50 / 428));
  }
`;
const TypeImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const TypeTextWrap = styled.div`
  width: 100%;
  margin-top: 25px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (25 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const TypeTitle = styled.div`
  font-size: 26px;
  color: #000;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (26 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
  }
`;
const TypeText = styled.p`
  font-size: 16px;
  margin-top: 10px;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;
