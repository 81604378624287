import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import { useState } from "react";
import styled from "styled-components";

// img
import IconBack from "../../img/icon/icon_header_back.svg";

// modal
import MyPageDefaultModal from "../../component/common/modal/MyPageDefaultModal";
import { imgURL } from "../../server/appInfoController";

// controller
import serverController from "../../server/serverController";

// component
import ReviewFormModal from "../../component/common/modal/ReviewFormModal";

export default function MyReviewDetail({ setReviewDetail, getReviewList, setReviewEdit, reviewV }) {
  const history = useHistory();
  const [isEditModal, setIsEditModal] = useState(null);
  const [reviewRemove, setReviewRemove] = useState(false);

  const [isRemoveButtonTouch, setIsRemoveButtonTouch] = useState(false);
  const [isEditButtonTouch, setIsEditButtonTouch] = useState(false);

  const deleteReview = () => {
    const formData = new FormData();
    formData.append("rev_id", reviewV?.rev_id);

    serverController.connectFetchController(`review/product`, "DELETE", formData, (res) => {
      if (res?.result !== 1) return alert("리뷰 삭제에 실패하였습니다. 다시 시도해주세요.");
      setReviewRemove(false);
      setReviewDetail(false);
      getReviewList();
    });
  };

  useEffect(() => {
    if (isEditModal !== false) return;
    getReviewList();
  }, [isEditModal]);

  return (
    <Container>
      {reviewRemove && (
        <MyPageDefaultModal
          text={"해당 리뷰를 삭제하시겠습니까?"}
          trueFunc={deleteReview}
          falseFunc={() => setReviewRemove(false)}
        />
      )}
      <Wrap>
        <ContentsWrap>
          <PC>
            <PageButtonWrap>
              <PageButtonArea onClick={() => setReviewDetail(false)}>
                <PageButton>
                  <img src={IconBack} alt="back-img" />
                </PageButton>
                <PageButtonTitle>목록으로</PageButtonTitle>
              </PageButtonArea>
            </PageButtonWrap>
          </PC>

          <ListInner>
            <ProductInfoWrap>
              <ImgBox
                onClick={() => {
                  history.push(`/store/detail/${reviewV?.prd_id}`);
                }}
              >
                <img src={`${imgURL}${reviewV?.prd_thumbnail}`} alt="product-img" />
              </ImgBox>
              <TextBox>
                <Brand>{reviewV.bc_title || "(BRAND)"}</Brand>
                <ProductName
                  onClick={() => {
                    history.push(`/store/detail/${reviewV?.prd_id}`);
                  }}
                >
                  {reviewV?.prd_name}
                </ProductName>

                <ProductColor>
                  {reviewV?.option && <span>{reviewV?.option}</span>}
                  {/* <span>1개</span> */}
                </ProductColor>
              </TextBox>
            </ProductInfoWrap>
          </ListInner>
          <BackgroundGray />
          <ListInner>
            <ReviewTextWrap>
              <ReviewSatisfactionBox>
                <ReviewSatisfactionList>
                  <ReviewSatisfaction>
                    {reviewV?.satisfaction === 1 ? "별로예요" : "만족해요"}
                  </ReviewSatisfaction>
                </ReviewSatisfactionList>
                <ReviewSatisfactionList>
                  <ReviewDate> {reviewV?.rev_date.split("T")[0].replaceAll("-", ".")}</ReviewDate>
                </ReviewSatisfactionList>
              </ReviewSatisfactionBox>
              <ReviewTextBox>{reviewV?.rev_content}</ReviewTextBox>
              {reviewV?.rev_image !== null ? (
                <ReviewImgBox>
                  <img src={`${imgURL}${reviewV?.rev_image}`} alt="review-img" />
                </ReviewImgBox>
              ) : null}
            </ReviewTextWrap>
          </ListInner>
        </ContentsWrap>
        <ButtonListWrap>
          <DeleteButton
            type="button"
            onClick={() => {
              setReviewRemove(true);
            }}
            onTouchStart={() => setIsRemoveButtonTouch(true)}
            onTouchEnd={() => setIsRemoveButtonTouch(false)}
            isRemoveButtonTouch={isRemoveButtonTouch}
          >
            삭제
          </DeleteButton>
          <PC>
            {/* <CancelButton
              type="button"
              onClick={() => {
                setIsEditModal(true);
              }}
              onTouchStart={() => setIsEditButtonTouch(true)}
              onTouchEnd={() => setIsEditButtonTouch(false)}
              isEditButtonTouch={isEditButtonTouch}
            >
              수정
            </CancelButton> */}
          </PC>
          <Mobile>
            {/* <CancelButton
              type="button"
              onClick={() => {
                setReviewEdit(true);
              }}
              onTouchStart={() => setIsEditButtonTouch(true)}
              onTouchEnd={() => setIsEditButtonTouch(false)}
              isEditButtonTouch={isEditButtonTouch}
            >
              수정
            </CancelButton> */}
          </Mobile>
        </ButtonListWrap>
      </Wrap>

      {/* 리뷰작성 모달 */}
      {isEditModal && (
        <ReviewFormModal setIsEditModal={setIsEditModal} title={"리뷰수정"} clickItem={reviewV} />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const Wrap = styled.div`
  width: 100%;
`;

const ContentsWrap = styled.div``;

const BackgroundGray = styled.div`
  width: 100%;
  height: 8px;
  background-color: #f9f9f9;
`;

const ListInner = styled.div`
  width: 100%;
  padding: 30px 20px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  }
`;

const ImgBox = styled.div`
  width: 70px;
  height: 70px;
  background-color: #fff;
  border: 1px solid #eee;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (70 / 1320));
    height: calc(100vw * (70 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (70 / 428));
    height: calc(100vw * (70 / 428));
  }
`;
const TextBox = styled.div`
  width: calc(100% - 80px);
  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (80 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (298 / 428));
  }
`;
const Brand = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (6 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (6 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProductColor = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;

  & span {
    color: #777;
    font-weight: 400;
  }
  & span:not(:last-child)::after {
    content: " / ";
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (4 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (4 / 428));
  }
`;
const ReviewEditButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ReviewEditButton = styled.button`
  color: #fff;
  font-weight: 500;
  background-color: #000;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (180 / 428));
    height: calc(100vw * (45 / 428));
    font-size: calc(100vw * (18 / 428));
    margin-top: calc(100vw * (30 / 428));
  }
`;

const ReviewTextWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  }
`;
const ReviewSatisfactionBox = styled.ul`
  display: flex;
  align-items: center;
`;
const ReviewSatisfactionList = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 1px;
    height: 15px;
    background-color: #ddd;
    margin: 0 15px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      height: calc(100vw * (15 / 1320));
      margin: 0 calc(100vw * (15 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      height: calc(100vw * (15 / 428));
      margin: 0 calc(100vw * (15 / 428));
    }
  }
`;
const ReviewSatisfaction = styled.span`
  color: #333;
  font-weight: 600;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const ReviewDate = styled.span`
  color: #777;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ReviewTextBox = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 16px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;
const ReviewImgBox = styled.div`
  width: 240px;
  height: 240px;
  background-color: #fff;
  border: 1px solid #eee;
  margin-top: 20px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (240 / 1320));
    height: calc(100vw * (240 / 1320));
    margin-top: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (240 / 428));
    height: calc(100vw * (240 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;

const ButtonListWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;

  & button:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (100 / 1320));

    & button:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    justify-content: space-between;
    padding: 0 calc(100vw * (25 / 428));
    margin-top: 0;

    & button:not(:last-child) {
      margin-right: 0;
    }
  }
`;
const DeleteButton = styled.button`
  color: #fff;
  font-weight: 500;
  background-color: #000;
  border: 1px solid #000;
  ${({ isRemoveButtonTouch }) => isRemoveButtonTouch && `background-color : #1b1b1b;`}

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (184 / 1320));
    height: calc(100vw * (45 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (184 / 428));
    height: calc(100vw * (45 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
const CancelButton = styled(DeleteButton)`
  color: #000;
  background-color: #fff;
  ${({ isEditButtonTouch }) => isEditButtonTouch && `background-color : #eeeeee;`}
`;

const ContentNone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - calc(100vw * (105 / 428)));
`;
const NoneText = styled.p`
  color: #999;
  font-weight: 400;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (90 / 428));
  }
`;

// pc - page button
const PageButtonWrap = styled.div`
  padding: 14px 20px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (14 / 1320)) calc(100vw * (20 / 1320));
  }
`;
const PageButtonArea = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;
const PageButton = styled.div`
  width: 32px;
  height: 32px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
`;
const PageButtonTitle = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 15px;
  margin-left: 5px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (5 / 1320));
  }
`;
