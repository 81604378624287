import React from "react";
import styled from "styled-components";

export default function MyPageBottom({ textList }) {
  return (
    <Container>
      <BottomInfoUl>
        {textList.map((bottomData, idx) => {
          return (
            <BottomInfoList key={idx + "-btm"}>
              <BottomInfoText>{bottomData}</BottomInfoText>
            </BottomInfoList>
          );
        })}
      </BottomInfoUl>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  padding: 30px 25px 250px;
  margin-top: 100px;
  background-color: #f9f9f9;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) calc(100vw * (25 / 1320)) calc(100vw * (250 / 1320));

    margin-top: calc(100vw * (150 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) calc(100vw * (25 / 428)) calc(100vw * (150 / 428));
    margin-top: calc(100vw * (100 / 428));
  }
`;
const BottomInfoUl = styled.ul`
  list-style-type: none;
`;
const BottomInfoList = styled.li`
  list-style-type: none;
  display: flex;
  align-items: baseline;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (6 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 428));
    }
  }
`;
const BottomInfoText = styled.p`
  line-height: 1.5;
  color: #777;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
