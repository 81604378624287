import React, {useState} from 'react';

import styled, {css} from 'styled-components';

import Header from '../../components/common/header/Header';
import Footer from '../../components/common/footer/Footer';

import SearchProduct from '../../components/management/SearchProduct';
import ProductDetail from '../../components/management/productDetail/ProductDetail';
import ProductDetailTab from '../../components/management/productDetail/ProductDetailTab';
import ProductDetailContents from '../../components/management/productDetail/ProductDetailContents';
import Pagination from '../../components/management/Pagination';

export default function ProductDetailPage({activeTab, setActiveTab}){
    return(
        <>
         <Header />
            <ProductDetailPageWrap>
                <SearchProduct/>
                <Wrapper>
                    <ProductDetail/>
                </Wrapper>
                <ProductDetailTab activeTab={activeTab} setActiveTab={setActiveTab}/>
                <ProductDetailContents activeTab={activeTab}/>
                <Pagination/>
            </ProductDetailPageWrap>
            <Footer />
        </>
    )
}

const Wrapper = styled.div`
    position:relative;
    margin:0 auto;
    width: calc(100vw * (1300 / 1920));

    @media screen and (max-width:1024px){
        width:100%; padding-left:calc(100vw * (25 / 428)); padding-right:calc(100vw * (25 / 428));
        padding-top:36px;
    }
`;

const ProductDetailPageWrap = styled.div`
    padding-bottom:calc(100vw * (232 / 1920));
`;