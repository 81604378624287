import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserDataAction } from '../../../store/actionCreators';
import serverController from '../../../server/serverController';
import IconBack from '../../../images/page-button.png';

import {
    Container,
    Layout,
    AuthIntroMessage,
    Form,
    Label,
    LabelName,
    FindEmailInput,
    FindTelInput,
    FindNubInput
} from '../auth.elements';
import {
    LoginBox,
    LoginIntroMessage,
    LoginSub,
    IdSaveLabel,
    IdSaveCheckbox,
    LoginBottom,
    LoginBtn,
    SignUpLinkBox,
    SignUpLinkButton
} from './FindID.elements';

const FindPassword = () => {
    const history = useHistory();
    const [isBtn, setIsBtn] = useState(true);
    const [email, setEmail] = useState("");
    const [telValue, setTelValue] = useState("");
    const [nubValue, setNubValue] = useState("");

    // 비밀번호찾기 클릭 
    const onClickFindPw = async () => {
        if (!email) {
            alert("이메일을 입력해주세요.");
            return;
        }
        if (!telValue) {
            alert("핸드폰 번호를 입력해주세요.");
            return;
        }
        if (isBtn) {
            await serverController.connectFetchController(
                `/find/pw?mem_email=${email}&mem_phone=${telValue}`,
                "POST", null, (res) => {
                    if (res?.result == 1) {
                        alert('인증번호가 발송되었습니다.')
                        setIsBtn(false)
                    } else {
                        alert('해당 번호로 가입된 계정이 없습니다.')
                    }
                },
                (err) => console.error(err)
            );
        } else {
            await serverController.connectFetchController(
                `send/pw?mem_email=${email}&mem_phone=${telValue}&code=${nubValue}`,
                "POST", null, (res) => {
                    if (res?.result == 1) {
                        alert('임시 비밀번호가 문자로 발송되었습니다.')
                        setIsBtn(false)
                    } else {
                        alert('인증번호가 일치하지 않습니다.')
                    }
                },
                (err) => console.error(err)
            );

        }
    }

    return (
        <Container>
            <Layout>
                <LoginBox>
                    <AuthIntroMessages>
                        <img src={IconBack} alt="" onClick={() => { history.push('/login'); }} />
                        <h2>비밀번호 찾기</h2>
                    </AuthIntroMessages>

                    <Form>
                        <Label>
                            <LabelName>이메일</LabelName>
                            <FindEmailInput value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Label>
                        <Label>
                            <LabelName>핸드폰번호</LabelName>
                            <FindTelInput value={telValue} onChange={(e) => setTelValue(e.target.value)} />
                        </Label>
                        <Label>
                            <LabelName>인증번호</LabelName>
                            <FindNubInput value={nubValue} onChange={(e) => setNubValue(e.target.value)} />
                        </Label>

                        <LoginBottom>
                            <LoginBtn onClick={onClickFindPw}>{isBtn ? "인증번호 전송" : "인증"}</LoginBtn>
                        </LoginBottom>
                    </Form>
                </LoginBox>

                <SignUpLinkBox>
                    <p>아직 WALA 회원이 아니신가요?</p>
                    <SignUpLinkButton onClick={() => history.push('/signup')}>회원가입</SignUpLinkButton>
                </SignUpLinkBox>
            </Layout>
        </Container>
    )
}
const AuthIntroMessages = styled(AuthIntroMessage)`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {position: absolute; bottom: calc(100vw*(28/1920)); left: 0; display: block; cursor: pointer; width: calc(100vw*(15/1920));}

    @media screen and (max-width: 1024px) {
        & img {position: absolute; bottom: calc(100vw*(17/428)); left: 0; display: block; cursor: pointer; width: calc(100vw*(12/428));}
    }
`


export default FindPassword
