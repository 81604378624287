import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import serverController from "../../../server/serverController";

import SearchAdressPop from "./SearchAdressPop";

const PayAdressEditModal = ({ title, setIsAddModal, getData }) => {
  const { mem_id } = useSelector((state) => state.userData.userInfo);

  const [next, setNext] = useState(false);
  const [adrTitle, setAdrTitle] = useState("");
  const [adrName, setAdrName] = useState("");
  const [phone, setPhone] = useState("");

  const [adrZipcode, setAdrZipCode] = useState("");
  const [adrAddress, setAdrAddress] = useState("");
  const [adrDatil, setAdrDatil] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const checkTitleVaildate = () => {
    return adrTitle.length != 0;
  };
  const checkNameVaildate = () => {
    return adrName.length != 0;
  };
  const checkPhoneVaildate = () => {
    return phone.length != 0;
  };
  const checkZipcodeVaildate = () => {
    return adrZipcode.length != 0;
  };
  const checkAddressVaildate = () => {
    return adrAddress.length != 0;
  };
  const checkDatilVaildate = () => {
    return adrDatil.length != 0;
  };

  useEffect(() => {
    if (
      checkTitleVaildate() &&
      checkNameVaildate() &&
      checkPhoneVaildate() &&
      checkZipcodeVaildate() &&
      checkAddressVaildate() &&
      checkDatilVaildate()
    ) {
      setNext(true);
    } else {
      setNext(false);
    }
  }, [adrTitle, adrName, phone, adrZipcode, adrAddress, adrDatil]);

  const handleSubmit = async () => {
    if (!next) {
      alert("빈 공란이 존재합니다.");
      return;
    }

    const receiver = new FormData();
    receiver.append("mem_id", mem_id);
    receiver.append("receiver_title", adrTitle);
    receiver.append("postal_code", adrZipcode);
    receiver.append("receiver_address", adrAddress);
    receiver.append("receiver_address_detail", adrDatil);
    receiver.append("receiver_phone", phone);
    receiver.append("receiver_username", adrName);

    for (let key of receiver.entries()) {
      console.log(key[0], key[1]);
    }
    // return;
    await serverController.connectFetchController(
      `receiver`,
      "POST",
      receiver,
      (res) => {
        console.log(res, "배송지 등록 res");
        if (res?.result === 1) {
          alert("저장되었습니다.");
          getData();
          setIsAddModal(false);
        }
      }
    );
  };

  return (
    <Container className="add_location_modal">
      {/* <Background/> */}
      <Content>
        {isSearch && (
          <SearchAdressPop
            setAdrAddress={setAdrAddress}
            setAdrZipCode={setAdrZipCode}
            setIsSearch={setIsSearch}
            isSearch={isSearch}
          />
        )}
        <TitleCont>
          <Title>{title}</Title>
        </TitleCont>
        <ListWrap>
          <AdressList>
            <InputText>배송지명</InputText>
            <Input
              type="text"
              value={adrTitle}
              onChange={(e) => {
                setAdrTitle(e.target.value);
              }}
            />
          </AdressList>
          <AdressList>
            <InputText>받는사람</InputText>
            <Input
              type="text"
              value={adrName}
              onChange={(e) => {
                setAdrName(e.target.value);
              }}
            />
          </AdressList>
          <AdressList>
            <InputText>연락처</InputText>
            <Input
              type="tel"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </AdressList>
          <AdressListDepth>
            <InputText>주소</InputText>
            <DepthInner>
              <FindBtn onClick={() => setIsSearch(true)}>주소찾기</FindBtn>

              <AdrInput type="text" value={adrZipcode} readonly disabled />
              <FullWrap>
                <AdrInputFull
                  type="text"
                  value={adrAddress}
                  readonly
                  disabled
                />
                <AdrInputFull
                  type="text"
                  value={adrDatil}
                  onChange={(e) => {
                    setAdrDatil(e.target.value);
                  }}
                />
              </FullWrap>
            </DepthInner>
          </AdressListDepth>
        </ListWrap>
        <BtnWrap>
          <Btn onClick={handleSubmit}>저장</Btn>
        </BtnWrap>
      </Content>
    </Container>
  );
};

export default PayAdressEditModal;

const Container = styled.div`
  width: 100%;
  position: relative;
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 101;
`;
// 컨텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  border-radius: 11px;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 200;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (500 / 1320));
    border-radius: calc(100vw * (11 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (60 / 428)));
    height: calc(100vh - calc(100vw * (180 / 428)));
    border-radius: calc(100vw * (11 / 428));
  }
`;
const TitleCont = styled.div`
  padding-bottom: 30px;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (25 / 428));
  }
`;
const ListWrap = styled.div`
  width: 100%;
  height: 440px;
  overflow-y: auto;
  padding: 30px 24px 25px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (440 / 1320));
    padding: calc(100vw * (30 / 1320)) calc(100vw * (24 / 1320))
      calc(100vw * (25 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vh - calc(100vw * (350 / 428)));
    padding: calc(100vw * (20 / 428)) calc(100vw * (15 / 428));
  }
`;
const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #000;
  text-align: center;
  padding-top: 20px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    padding-top: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    padding-top: calc(100vw * (30 / 428));
  }
`;
const AdressList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (20 / 428));
  }
`;
const AdressListDepth = styled(AdressList)`
  align-items: flex-start;
`;
const DepthInner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 100px);

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (100 / 1320)));
  }

  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (100 / 428)));
  }
`;
const FindBtn = styled.p`
  width: 80px;
  height: 45px;
  font-size: 15px;
  line-height: 45px;
  text-align: center;
  color: #000;
  border: 1px solid #000;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (80 / 1320));
    height: calc(100vw * (45 / 1320));
    font-size: calc(100vw * (15 / 1320));
    line-height: calc(100vw * (45 / 1320));
    border-radius: calc(100vw * (5 / 1320));
    margin-right: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (80 / 428));
    height: calc(100vw * (40 / 428));
    font-size: calc(100vw * (15 / 428));
    line-height: calc(100vw * (40 / 428));
    border-radius: calc(100vw * (5 / 428));
    margin-right: calc(100vw * (10 / 428));
  }
`;
const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 24px 30px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (24 / 1320)) calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (24 / 428)) calc(100vw * (30 / 428));
  }
`;
const Btn = styled.p`
  width: 100%;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  padding: 12px 30px;
  background: #000;
  border-radius: 5px;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    padding: calc(100vw * (12 / 1320)) calc(100vw * (30 / 1320));
    border-radius: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    padding: calc(100vw * (10 / 428)) calc(100vw * (30 / 428));
    border-radius: calc(100vw * (5 / 428));
  }
`;
const InputText = styled.p`
  width: 100px;
  font-size: 14px;
  color: #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (80 / 428));
    font-size: calc(100vw * (14 / 428));
  }
`;
const Input = styled.input`
  width: calc(100% - 100px);
  height: 45px;
  padding: 0 15px;
  background-color: #fff;
  border: 1px solid #000000;
  border-radius: 5px;
  color: #222;

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (100 / 1320)));
    height: calc(100vw * (45 / 1320));
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (100 / 428)));
    height: calc(100vw * (40 / 428));
    font-size: calc(100vw * (14 / 428));
  }
`;
const AdrInput = styled(Input)`
  width: calc(100% - 90px);

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (90 / 1320)));
  }

  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (90 / 428)));
  }
`;
const FullWrap = styled.div`
  width: 100%;
`;
const AdrInputFull = styled(Input)`
  width: 100%;
  margin-top: 10px;
  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (10 / 428));
  }
`;
