import React from 'react';
import { Mobile, PC } from '../common/mediaStlye';
import { useHistory } from 'react-router-dom';
import { UserDataAction } from '../../store/actionCreators';
import { useSelector } from 'react-redux';
import serverController from "../../server/serverController";

import {
    Container,
    Layout,
    MyPageHead,
    UserNameBox,
    UserThumb,
    LogoutButton,
    UserAnalysisLink,
} from '../auth/mypage/mypage.elements';

import userThumbImg from '../../images/stylist/style.svg';

const StylistMyInfoHead = () => {
    const history = useHistory();
    const { mem_name } = useSelector(state => state.userData.userInfo)

    const onHandleLogout = () => {
        serverController.connectFetchController(
            `logout`,
            "POST",
            null,
            (res) => { },
            (err) => console.error(err)
        )
        if (localStorage.getItem("login-info")) {
            localStorage.removeItem("login-info");
        }
        history.push("/");
        UserDataAction.logOutRequest();
    };

    return (
        <MyPageHead>
            <UserNameBox>
                <UserThumb><img src={userThumbImg} alt="유저 프로필" /></UserThumb>
                <p>{mem_name}<small>님</small></p>
            </UserNameBox>

            <LogoutButton onClick={onHandleLogout}>로그아웃</LogoutButton>
        </MyPageHead>
    )
}

export default StylistMyInfoHead;
