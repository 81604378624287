import styled, { css } from "styled-components";
import {            
    pc,
    mobil,
    Media,
    Px,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
    mquery,
} from '../common/mediaStlye';

export const IdolSliderWrapper = styled.div`

`

export const IdolSlider = styled.div`
    & .swiper-slide.swiper-slide-active .idol-products{
        opacity: 1;
        transition: opactiy 0.4s ease-in; 
    }
    & .swiper-slide .idol-products{
        opacity: 0;
    }
`

export const IdolSlide = styled.div`
    position:relative;
    display: inline-block;
    cursor: pointer;
`

export const IdolImg = styled.div`
    width: calc(100vw * (748 / 1920));
    height: calc(100vw * (617 / 1920));
    line-height: 0;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ${Media(mquery,[
        Width(295, mobil),
        Height(240, mobil),
    ])} 
`

export const IdolProducts = styled.div`
    position: absolute;
    left: 50%;
    bottom: calc(100vw * (41 / 1920));
    transform: translateX(-50%);
    display: flex;
    justify-content: flex-start;
    gap: ${Px(20, pc)};
    align-items: center;
    width: calc(100vw * (581 / 1920));
`

export const IdolProduct = styled.div`
    width: calc(100vw * (187 / 1920));
    height: calc(100vw * (175 / 1920));
    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

export const IdolSliderBottom = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 100;
    padding-top: calc(100vw * (16 / 1920)); 
`

export const IdolArrows = styled.div`    
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw * (603 / 1920));
    
    ${Media(mquery,[
        "width: 100%"
    ])} 

    & .idol-arrows {
        width: calc(100vw * (26 / 1920));
        line-height: 0;
        cursor: pointer;
    }

    & .idol-arrows > img {
        width: 100%;      

        ${Media(mquery,[
            "width: 13px;",     
        ])}  
    }

    & .swiper-button-prev-idol {
        display: inline-block;
    }

    & .swiper-button-next-idol {
        display: inline-block;
        
    }
`

export const IdolDesc = styled.div`
    flex: 1;
    padding: 0 calc(100vw * (50 / 1920));
    text-align: center;

    & > h4 {
        padding-bottom: calc(100vw * (17 / 1920));
        font-size: calc(100vw * (30 / 1920));
        font-weight: 900;

        ${Media(mquery,[
            FontSize(20, mobil),
            "width: 100%;",
            PB(5, mobil)     
        ])}
    }

    & > p {
        padding: 0 calc(100vw * (30 / 1920));
        font-size: calc(100vw * (15 / 1920));

        ${Media(mquery,[
            FontSize(14, mobil),   
        ])}
    }
`