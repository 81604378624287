import React from 'react';
import Header from '../components/common/header/Header';
import FashionNewsContentsMedia from '../components/news/FashionNewsContentsMedia';
import Footer from '../components/common/footer/Footer';

const FashionNewsMediaPage = () => {
    return (
        <div>
            <Header />
             <FashionNewsContentsMedia />
            <Footer />
        </div>
    )
}

export default FashionNewsMediaPage
