import styled, { css } from "styled-components";
import {
    mquery,
    mobil,
    Px,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
} from '../common/mediaStlye';
const pcVh = 1920;

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    ${[ 
        Padding2(117,0, pcVh)
    ].join("")}
`

export const Layout = styled.div`
    ${Width(1174, pcVh)}

    @media screen and (max-width: ${mquery}px) {
        width: 100%;
        ${Padding2(0,25,mobil)}
    }
`   

export const DetailHeadings = styled.div`
    ${[        
        Padding2(28,25, pcVh),
    ].join("")}
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 2px solid #D9D9D9;
    

    & > h2 {
        ${FontSize(25, pcVh)}
        color: #222;
        font-weight: 700;
    }

    & > span {
        ${FontSize(13, pcVh)}
        color: #A5A5A5;
    }

    @media screen and (max-width: ${mquery}px) {
        flex-direction: column;

        & > h2 {
            ${FontSize(22, mobil)}
            ${PR(50, mobil)}
        }

        & > span {
            display: block;
            width: 100%;
            text-align: right;
            ${FontSize(13, mobil)}
        }
        
    }
`

export const DetailContent = styled.div`
    ${Padding(58,24,0,24, pcVh)}
    
    @media screen and (max-width: ${mquery}px) {
        ${Padding(27.5,0,0,0, mobil)}

        & > h2 {
            ${FontSize(22, mobil)}
            ${PR(50, mobil)}
        }

        & > span {
            display: block;
            width: 100%;
            text-align: right;
            ${FontSize(13, mobil)}
        }
        
    }
`

export const DetailImg = styled.div`
    ${[
        MB(58, pcVh),
        Height(665, pcVh)
    ].join("")}

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
    }

    @media screen and (max-width: ${mquery}px) {        
        ${[
            MB(28, mobil),
            Height(213, mobil)
        ].join("")}
    }
`

export const DetailText = styled.div`
    min-height: ${Px(489, pcVh)};
    ${FontSize(16, pcVh)}
    color: #404040;

    @media screen and (max-width: ${mquery}px) {      
        & > p {
            ${FontSize(16, mobil)}
            ${MB(30, mobil)}
        }        
    }
`
