import React from 'react';

import styled , {css} from 'styled-components';

import Header from '../../components/common/header/Header';
import Footer from '../../components/common/footer/Footer';

import SearchProduct from '../../components/management/SearchProduct';
import ManagementType from '../../components/management/ManagementType';

export default function ManagementIntro(){
    return(
        <>
            <Header />
            <SearchProduct/>
            <ManagementType/>
            <Footer />
        </>
    )
}