import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Mobile, PC } from "../../MediaQuery";
import { imgURL } from "../../server/appInfoController";
import Commas from "../../server/Commas";
import ReactHtmlParser from "react-html-parser";
import { postLikeProducts } from "../../../hooks/likeHooks";
import IconWishOn from "../../img/icon/icon_wish_on.svg";
import IconWishOff from "../../img/icon/icon_wish_off.svg";
import DefaultImg from "../../img/icon/loading_default_img.svg";

export default function PlanItemsList({
  value,
  rawThree = false,
  setLoginPop,
  thumbnail = false,
}) {
  const history = useHistory();
  const { isLogged } = useSelector((state) => state.userData);
  const [isWish, setIsWish] = useState(value.like_id !== 0);
  const [touch, setTouch] = useState(false);

  const onClickDetail = () => {
    history.push(`/store/detail/${value.prdId}`);
  };

  const onHandleLike = async () => {
    const res = await postLikeProducts(value.prdId);
    setIsWish(res?.result === 1);
  };

  return (
    <>
      <PC>
        <Container className="product_box" rawThree={rawThree}>
          <PlanListImgBox rawThree={rawThree}>
            {value.prdThumbnail &&
              <PlanListImg
                src={value?.prdThumbnail ? `${imgURL}${value?.prdThumbnail}` : DefaultImg}
                alt="thumbnail-plan"
                onClick={onClickDetail}
              />
            }
            {/* {value.like_id !== undefined && (
              <WishIcon
                src={isWish ? IconWishOn : IconWishOff}
                alt="wish"
                onClick={() => {
                  if (!isLogged) return setLoginPop(true);
                  onHandleLike();
                }}
              />
            )} */}
          </PlanListImgBox>
          <PlanListTextWrap onClick={onClickDetail}>
            <PlanListImgTextBox>
              <PlanListImgTitle>{value.brandTitleKor || "(BRAND)"}</PlanListImgTitle>
              <PlanListImgText>{value?.prdName}</PlanListImgText>
            </PlanListImgTextBox>
            {value?.prdSaleRate === 0 ? (
              <PriceBox>
                <OriginalPrice original>.</OriginalPrice>
                <PlanListPriceWrap>
                  <Price>{Commas.numberWithCommas(value?.prdPrice)}원</Price>
                </PlanListPriceWrap>
              </PriceBox>
            ) : (
              <PriceBox>
                <OriginalPrice>{Commas.numberWithCommas(value?.prdPrice)}원</OriginalPrice>
                <PlanListPriceWrap>
                  <SaleRate>{Commas.numberWithCommas(value?.prdSaleRate)}%</SaleRate>
                  <Price>{Commas.numberWithCommas(value?.prdListPrice)}원</Price>
                </PlanListPriceWrap>
              </PriceBox>
            )}
          </PlanListTextWrap>
        </Container>
      </PC>

      <Mobile>
        <Container className="product_box">
          <PlanListImgBox rawThree={rawThree}>
            {value.prdThumbnail &&
              <PlanListImg
                src={value?.prdThumbnail ? `${imgURL}${value?.prdThumbnail}` : DefaultImg}
                alt="hot-img"
                onClick={onClickDetail}
              />}
            {/* <WishIcon
              src={isWish ? IconWishOn : IconWishOff}
              alt="wish"
              onClick={() => {
                if (!isLogged) return setLoginPop(true);
                onHandleLike();
              }}
              onTouchStart={() => {
                setTouch(true);
              }}
              onTouchEnd={() => {
                setTouch(false);
              }}
              touch={touch}
            /> */}
          </PlanListImgBox>
          <PlanListTextWrap onClick={onClickDetail}>
            <PlanListImgTextBox>
              <PlanListImgTitle>{value.brandTitleKor || "(BRAND)"}</PlanListImgTitle>
              <PlanListImgText>{ReactHtmlParser(value?.prdName)}</PlanListImgText>
            </PlanListImgTextBox>
            {value?.prdSaleRate === 0 ? (
              <PriceBox>
                <OriginalPrice original>.</OriginalPrice>
                <PlanListPriceWrap>
                  <Price>{Commas.numberWithCommas(value?.prdPrice)}원</Price>
                </PlanListPriceWrap>
              </PriceBox>
            ) : (
              <PriceBox>
                <OriginalPrice>{Commas.numberWithCommas(value?.prdPrice)}원</OriginalPrice>
                <PlanListPriceWrap>
                  <SaleRate>{Commas.numberWithCommas(value?.prdSaleRate)}%</SaleRate>
                  <Price>{Commas.numberWithCommas(value?.prdListPrice)}원</Price>
                </PlanListPriceWrap>
              </PriceBox>
            )}
          </PlanListTextWrap>
        </Container>
      </Mobile>
    </>
  );
}

const Container = styled.div`
  width: 240px;
  align-self: stretch;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 40px;

  ${({ rawThree }) =>
    rawThree &&
    `
      width: 252px;
  `}

  /* &:not(:nth-child(6n)){
    margin-right: 8px;
  } */

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (240 / 1320));
    margin-bottom: calc(100vw * (40 / 1320));

    ${({ rawThree }) =>
    rawThree &&
    `
        width: calc(100vw * (252 / 1320));
    `}/* &:not(:nth-child(6n)){
      margin-right: calc(100vw * (8 / 1320));
    } */
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (186 / 428));
    margin-bottom: calc(100vw * (20 / 428));

    ${({ rawThree }) =>
    rawThree &&
    `
        width: calc(100vw * (120 / 428));

        &:not(:nth-child(3n)){
          margin-right: calc(100vw * (1.5 / 428));
        }
    `}/* &:not(:nth-child(6n)){
      margin-right: 0;
    } */
  }
`;
const PlanListImgBox = styled.div`
  position: relative;
  width: 240px;
  height: 240px;
  overflow: hidden;
  cursor: pointer;
  ${({ rawThree }) =>
    rawThree &&
    `
    height: 252px;
    width: 252px;
  `}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (240 / 1320));
    width: calc(100vw * (240 / 1320));
    ${({ rawThree }) =>
    rawThree &&
    `
      height: calc(100vw * (252 / 1320));
      width: calc(100vw * (252 / 1320));
    `}
  }
  @media screen and (max-width: 930px) {
    border: calc(100vw * (1 / 428)) solid #f8f8f8f8;
    height: calc(100vw * (186 / 428));
    width: 100%;
    ${({ rawThree }) =>
    rawThree &&
    `
      height: calc(100vw * (120 / 428));
      width: calc(100vw * (120 / 428));
    `}
  }
`;
const PlanListImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const PlanListTextWrap = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 131px;
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (131 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (131 / 428));
  }
`;
const PlanListImgTextBox = styled.div``;
const PlanListImgTitle = styled.p`
  font-size: 14px;
  font-weight: 800;
  color: #333333;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;
const PlanListImgText = styled.p`
  font-size: 15px;
  color: #333333;
  margin-top: 5px;
  min-height: 45px;
  word-break: keep-all;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (5 / 1320));
    min-height: calc(100vw * (45 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (5 / 428));
    min-height: calc(100vw * (45 / 428));
  }
`;
const WishIcon = styled.img`
  transition: ease 0.3s all;
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: block;
  width: 24px;
  height: 24px;

  @media screen and (max-width: 1320px) {
    bottom: calc(100vw * (10 / 1320));
    right: calc(100vw * (10 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    bottom: calc(100vw * (10 / 428));
    right: calc(100vw * (10 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
    ${({ touch }) =>
    touch &&
    `
      scale : 0.85;
    `}
  }
`;
const PlanListPriceWrap = styled.div`
  display: flex;
  align-items: center;
`;
const SaleRate = styled.p`
  color: #333333;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding: 2px 4px;
  margin-right: 5px;
  border-radius: 3px;
  background: #fdf251;
  line-height: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    padding: calc(100vw * (2 / 1320)) calc(100vw * (4 / 1320));
    margin-right: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    padding: calc(100vw * (2 / 428)) calc(100vw * (4 / 428));
    margin-right: calc(100vw * (5 / 428));
  }
`;
const Price = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #333333;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const PriceBox = styled.div`
  min-height: 43.5px;

  @media screen and (max-width: 1320px) {
    min-height: calc(100vw * (43.5 / 1320));
  }

  @media screen and (max-width: 930px) {
    min-height: calc(100vw * (43.5 / 428));
  }
`;
const OriginalPrice = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #acacac;
  text-decoration: line-through;
  margin-bottom: 4px;
  ${({ original }) => original && `color :transparent;`}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (4 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (4 / 428));
  }
`;
