import React, { useState } from "react";
import styled from "styled-components";
import qs from "qs";
import { Mobile, PC } from "../../MediaQuery";
import SearchHeader from "../../component/common/SearchHeader";
import StoreSearch from "../../component/search/StoreSearch";
import StoreSearchResult from "../../component/search/StoreSearchResult";
import Footer from "../../component/common/StoreFooter";
import StoreSearchResultIndex from "../../component/search/StoreSearchResultIndex";

// img
import IconSearchIndex from "../../img/icon/icon_search_index.svg";
import { useHistory, useLocation } from "react-router-dom";
import MyPageDefaultModal from "../../component/common/modal/MyPageDefaultModal";

const searchedPrdList = [
  { productName: "룰루레몬" },
  { productName: "레깅스" },
  { productName: "마틸라" },
  { productName: "얼데이" },
  { productName: "홀릭앤플레이" },
  { productName: "티셔츠" },
  { productName: "팬츠" },
];

export default function SearchPage() {
  const location = useLocation();
  const history = useHistory();
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const [loginPop, setLoginPop] = useState(false);
  const [contentsDetail, setContentsDetail] = useState(false);
  const [recentList, setRecentList] = useState([]);

  const [searchWord, setSearchWord] = useState("");

  // split keyword
  const splitKeyword = (value) => {
    let searchKeyword = value.split(searchWord);
    return searchKeyword.map((text, index) => (
      <React.Fragment key={`${index}-splitText`}>
        <SearchResultText>{text}</SearchResultText>
        {searchKeyword.length !== index + 1 ? (
          <SearchResultText style={{ fontWeight: "600" }}>{searchWord}</SearchResultText>
        ) : null}
      </React.Fragment>
    ));
  };

  const searchHeaderItems = {
    inputSearchValue: query.keyword,
    setContentsDetail,
    recentList,
    setRecentList,
    searchWord,
    setSearchWord,
    isDetail: contentsDetail,
  };

  const searchStoreItems = {
    recentList,
    setRecentList,
    setLoginPop,
  };

  const storeSearchResultItems = {
    contentsDetail,
    setContentsDetail,
    setLoginPop,
  };

  return (
    <>
      <PC>
        <SearchHeader />
        <StoreSearch />
        <Footer />
      </PC>

      <Mobile>
        {loginPop && (
          <MyPageDefaultModal
            text="로그인 후 이용 가능합니다."
            trueText="로그인"
            trueFunc={() => {
              if (location) return history.push(`/store/login?returnTo=${location.pathname}`);
              history.push(`/store/login`);
            }}
            falseFunc={() => setLoginPop(false)}
          />
        )}
        <SearchHeader {...searchHeaderItems} />
        {query.keyword !== searchWord && searchWord.length !== 0 ? (
          <SearchResultWrap>
            {searchedPrdList.map((value, index) => (
              <SearchResultBox key={`${value.productName}-productSearchResult-${index}`}>
                <SearchResultIndexIconBox>
                  <SearchResultIndexIcon src={IconSearchIndex} alt="IconSearchIndex" />
                </SearchResultIndexIconBox>
                <SearchResultTextBox
                  key={`${index}-${value.productName}-productName`}
                  onClick={() => {
                    history.push(`/store/search?keyword=${value.productName}`);
                  }}
                >
                  {splitKeyword(value?.productName)}
                </SearchResultTextBox>
              </SearchResultBox>
            ))}
          </SearchResultWrap>
        ) : (
          <>
            {query.index ? (
              <StoreSearchResult {...storeSearchResultItems} />
            ) : (
              <>
                {query.keyword ? (
                  <StoreSearchResultIndex setLoginPop={setLoginPop} />
                ) : (
                  <StoreSearch {...searchStoreItems} />
                )}
              </>
            )}
          </>
        )}
      </Mobile>
    </>
  );
}
const SearchResultWrap = styled.div`
  margin: 0 auto;
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding-top: calc(100vw * (20 / 428));
  }
`;
const SearchResultBox = styled.div`
  display: flex;
  align-items: center;
`;
const SearchResultIndexIconBox = styled.div`
  @media screen and (max-width: 930px) {
    margin: calc(100vw * (13 / 428)) calc(100vw * (10 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const SearchResultIndexIcon = styled.img`
  width: 100%;
  height: 100%;
`;
const SearchResultTextBox = styled.div``;
const SearchResultText = styled.span`
  font-size: calc(100vw * (16 / 428));
`;
const BestRankWrap = styled.div`
  margin: 0 auto;
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    font-size: calc(100vw * (16 / 428));
    line-height: calc(100vw * (19 / 428));
    padding-bottom: calc(100vw * (50 / 428));
  }
`;
