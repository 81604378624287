import React, { useState, useEffect } from "react";
import serverController from "../../server/serverController";
import { imgURL } from "../../server/appInfoContoller";
import { PC, Mobile } from "../common/mediaStlye";
import { useHistory, useParams } from "react-router-dom";
import ContentDetailPage from "../../page/ContentDetailPage";
import DefaultImg from "../../store_src/img/icon/loading_default_img.svg";

import {
  Container,
  LayOut,
  ContentHeading,
  FashionsContainer,
  Item,
  ItemImg,
  ItemDesc,
  MoreButton,
} from "./ContentDetailsPage.element";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation]);

const ContentDetailsFashion = ({ data }) => {
  const history = useHistory();
  const headings = ["Celebrity", "Drama", "Professional", "Idol"];
  const [category, setCategory] = useState(0);
  const [list, setList] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const [page, setPage] = useState(0);

  const [isContentModal, setIsContentModal] = useState(false);
  const [contentId, setContentId] = useState(0);

  const getContents = async () => {
    await serverController.connectFetchController(
      `contentslist/${data?.c_cat_id}?page=${0}`,
      "GET",
      null,
      (res) => {
        console.log(res, "관련 컨텐츠 response");
        if (res?.result === 1) {
          setList(res?.list ?? []);
        }
      }
    );
  };

  useEffect(() => {
    setPage(0);
    getContents();
    setCategory(headings[parseInt(data?.c_cat_id) - 1]);
  }, [data]);

  const getMoreContents = async () => {
    await serverController.connectFetchController(
      `contentslist/${data?.c_cat_id}?page=${page + 1}`,
      "GET",
      null,
      (res) => {
        console.log(res, "관련 컨텐츠 more response");
        if (res?.result === 1) {
          setPage(page + 1);
          setList(list.concat(res?.list ?? []));
          if (res.list?.length < 6) {
            setIsMore(false);
          }
        }
      },
      (err) => console.error(err)
    );
  };

  return (
    <>
      <PC>
        <Container>
          <LayOut>
            <ContentHeading>{category} fashion</ContentHeading>
            <FashionsContainer>
              {list.map((v) => {
                const src = v?.contents_thumbnail?.split(",")[0];

                return (
                  <Item
                    key={v?.contents_id}
                    onClick={() => history.push(`/content/${v?.contents_id}`)}
                  >
                    <ItemImg>
                      <img
                        src={src?.length > 0 ? imgURL + src : DefaultImg}
                        alt={src?.length > 0 ? "contents-img" : "default-img"}
                      />
                    </ItemImg>
                    <ItemDesc className="item_head">
                      <em>{category}</em>
                      <h4>{v?.contents_title}</h4>
                      <p>{v?.contents_intro}</p>
                    </ItemDesc>
                  </Item>
                );
              })}
            </FashionsContainer>
            {isMore && (
              <MoreButton onClick={getMoreContents}>See More</MoreButton>
            )}
          </LayOut>
        </Container>
      </PC>
      <Mobile>
        {data ? (
          <Container>
            <LayOut>
              <ContentHeading
                style={{ marginBottom: "calc(100vw * (33/ 428))" }}
              >
                {category} fashion
              </ContentHeading>
              <FashionsContainer
                style={{ marginBottom: "calc(100vw * (66/ 428))" }}
              >
                {list && Array.isArray(list) && list.length > 0 ? (
                  <Swiper spaceBetween={10} slidesPerView={1.2}>
                    {list.map((v, i) => {
                      const src = v?.contents_thumbnail?.split(",")[0];
                      return (
                        <SwiperSlide key={i}>
                          <Item
                            onClick={() => {
                              setIsContentModal(true);
                              setContentId(v?.contents_id);
                            }}
                          >
                            <ItemImg>
                              <img
                                src={
                                  src?.length > 0 ? imgURL + src : DefaultImg
                                }
                                alt={
                                  src?.length > 0
                                    ? "contents-img"
                                    : "default-img"
                                }
                              />
                            </ItemImg>
                            <ItemDesc className="item_head">
                              <em>{category}</em>
                              <h4>{v?.contents_title}</h4>
                              <p>{v?.contents_intro}</p>
                            </ItemDesc>
                          </Item>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                ) : null}
              </FashionsContainer>
              {/* {
                      isMore && <MoreButton onClick={getMoreContents}>See More</MoreButton>
                  } */}
            </LayOut>
            {isContentModal && (
              <ContentDetailPage
                setIsContentModal={setIsContentModal}
                contentId={contentId}
                setContentId={setContentId}
              />
            )}
          </Container>
        ) : null}
      </Mobile>
    </>
  );
};

export default ContentDetailsFashion;
