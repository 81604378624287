//react
import { useState, useEffect } from "react";

//css
import styled from "styled-components";

// img
import DeleteIcon from "../../store_src/img/icon/icon_option_delete.svg";
import Commas from "../../store_src/server/Commas";

export default function OptionListLayout(props) {
  const { store, item, selectList, optionlist, setSelectList, prdKey } = props;
  const [count, setCount] = useState(item.count);

  const clickBtnM = () => {
    if (count > 1) {
      setSelectList((list) => {
        list[prdKey].count = count - 1;
        return JSON.parse(JSON.stringify(list));
      });
      setCount(parseInt(count) - 1);
    }
  };
  const clickBtnP = () => {
    if (count > 98) return alert("최대 수량은 99개입니다.");
    for (const key in optionlist) {
      const inventory = optionlist[key][optionlist[key].length - 1].opt_inventory;
      if (inventory === count) return;
    }
    setSelectList((list) => {
      list[prdKey].count = count + 1;
      return JSON.parse(JSON.stringify(list));
    });
    setCount(parseInt(count) + 1);
  };

  // delete option
  const deleteOption = async () => {
    delete selectList[prdKey];
    await setSelectList({ ...selectList });
  };

  const getOptionName = (optionlist) => {
    var optionText = "";
    for (let key in optionlist) {
      let list = optionlist[key];
      for (let z = 0; z < list.length; z++) {
        var opt = list[z];
        optionText +=
          opt.opt_name +
          (z + 1 == list.length ? "" : " / ") +
          (opt.opt_price > 0 ? " +" + Commas.numberWithCommas(opt.opt_price) + "원" : "");
      }
    }
    return optionText;
  };

  useEffect(() => {
    setCount(item.count);
  }, [selectList]);

  return (
    <>
      <DeliveryTextWrap>
        <DeliveryText>{getOptionName(optionlist) ? getOptionName(optionlist) : null}</DeliveryText>
        {store && (
          <CountInputWrap>
            <CountMinus onClick={clickBtnM}>-</CountMinus>
            <CountInput value={count} disabled readOnly />
            <CountPlus onClick={clickBtnP}>+</CountPlus>
            <DelOption onClick={() => deleteOption(prdKey)}>
              <DelOptionImg src={DeleteIcon} alt="option-delete-icon" />
            </DelOption>
          </CountInputWrap>
        )}
        {/* <OptionNumberWrap>
                    <OptionNumberInput value={count} onChange={(e) => countTextChange(e)} disabled/>
                    <CountBtnM onClick={()=> clickBtnM()}>-</CountBtnM>
                    <CountBtnP onClick={()=> clickBtnP()}>+</CountBtnP>
                </OptionNumberWrap> */}
      </DeliveryTextWrap>
    </>
  );
}

const DeliveryTextWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
  }

  /* &:last-child{
    border-bottom: 2px solid #000;
  } */

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (15 / 428)) calc(100vw * (10 / 428)) calc(100vw * (15 / 428))
      calc(100vw * (15 / 428));
    /* &:last-child {
      margin-bottom: calc(100vw * (60 / 428));
    } */
  }
`;
const DeliveryText = styled.p`
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  padding-right: 20px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding-right: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    padding-right: calc(100vw * (20 / 428));
  }
`;
const DelOption = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
    margin-left: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
    margin-left: calc(100vw * (20 / 428));
  }
`;
const DelOptionImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
// const OptionNumberWrap = styled.div`
//   position: relative;
// `;
// const OptionNumberInput = styled.input`
//   width: calc(100vw * (130 / 1920));
//   height: calc(100vw * (38 / 1920));
//   border: calc(100vw * (1 / 1920)) solid #d1d1d1;
//   border-radius: calc(100vw * (8 / 1920));
//   font-size: calc(100vw * (15 / 1920));
//   text-align: center;
//   color: #000;

//   @media screen and (max-width: 1024px) {
//     width: calc(100vw * (130 / 428));
//     height: calc(100vw * (38 / 428));
//     border: calc(100vw * (1 / 428)) solid #d1d1d1;
//     border-radius: calc(100vw * (8 / 428));
//     font-size: calc(100vw * (15 / 428));
//   }
// `;
// const CountBtnM = styled.span`
//   position: absolute;
//   top: 50%;
//   left: calc(100vw * (20 / 1920));
//   font-size: calc(100vw * (16 / 1920));
//   color: #707070;
//   transform: translateY(-50%);

//   @media screen and (max-width: 1024px) {
//     left: calc(100vw * (12 / 428));
//     font-size: calc(100vw * (16 / 428));
//     padding: calc(100vw * (10 / 428));
//   }
// `;
// const CountBtnP = styled(CountBtnM)`
//   left: auto;
//   right: calc(100vw * (20 / 1920));

//   @media screen and (max-width: 1024px) {
//     left: auto;
//     right: calc(100vw * (16 / 428));
//   }
// `;

const CountInputWrap = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;
const CountMinus = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  color: #000000;
  font-size: 18px;
  border: 1px solid #000000;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (35 / 1320));
    height: calc(100vw * (35 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (35 / 428));
    height: calc(100vw * (35 / 428));
    font-size: calc(100vw * (19 / 428));
  }
`;
const CountInput = styled.input`
  width: 60px;
  font-size: 15px;
  text-align: center;
  font-weight: 500;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (60 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (60 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;
const CountPlus = styled(CountMinus)``;
