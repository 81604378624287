import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    },
  },
});
