//react
import React, { useEffect, useState } from "react";

// component
import BestItemProductList from "../main/BestItemProductList";
import BrandListContents from "../brand/BrandListContents";
import ContentsList from "../contents/ContentsList";
import SkeletonUI from "../../../newComponents/common/SkeletonUI";

// controller
import serverController from "../../server/serverController";
import { debounce } from "lodash";
import qs from "qs";

//css
import styled from "styled-components";

// icons samples
import Zigzag from "../../img/borderBottom_zigzag_search.svg";
import IconSearch from "../../img/icon/icon_header_search_black.svg";
import SelectBoxArrow from "../../img/icon/icon_selectBox_arrow.svg";

// component
import SearchModal from "../common/modal/SearchModal";
import NewPagination from "../common/pagination/NewPagination";
import { useHistory } from "react-router-dom";

const filterSItems = ["전체", "5만원 이하", "5만원 ~ 10만원", "10만원 ~ 20만원", "20만원 이상"];

const filterFItems = ["최신순", "인기순", "가격 낮은순", "가격 높은순"];

export default function SearchResultMain({ setLoginPop }) {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // click search modal control
  const [searchModalShow, setSearchModalShow] = useState(false);

  const [resultPrdList, setResultPrdList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);

  // select
  const [optionList, setOptionList] = useState("최신순");
  const [option, setOption] = useState(false);

  // filter
  const [clickS, setClickS] = useState(0);
  const [clickF, setClickF] = useState(0);

  const [page, setPage] = useState(query.page ?? 1);
  const [pageData, setPageData] = useState([]);

  // input
  const [inputSearchValue, setInputSearchValue] = useState(query.searchKeyword);
  const [recentList, setRecentList] = useState([]);

  const getResultPrdList = debounce((clickS = 0, clickF = 0) => {
    setIsLoading(true);
    try {
      serverController.connectFetchController(
        `products?&page=${query.page}&limit=12&keyword=${query.searchKeyword}&filter=${clickF}&range=${clickS}`,
        "GET",
        null,
        (res) => {
          if (res?.result !== 1) return;
          setPageData(res.pagination);
          setResultPrdList(res.list);
          setIsLoading(false);
        }
      );
    } catch (e) {
      console.log(e);
    }
  }, 300);

  // get brand list
  const getBrandsList = debounce(() => {
    serverController.connectFetchController(
      `brand/list?keyword=${query.searchKeyword}`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setBrandsList(res?.data);
        }
      }
    );
  }, 300);

  useEffect(() => {
    setTabIndex(query.tabIndex * 1);
  }, [query.tabIndex]);

  useEffect(() => {
    history.push(
      `/store/search/result?page=${page}&searchKeyword=${query.searchKeyword}&tabIndex=${query.tabIndex}`
    );
  }, [page]);

  useEffect(() => {
    getResultPrdList(0, 0);
  }, [query.page]);

  useEffect(() => {
    getResultPrdList(0, 0);
    getBrandsList();
  }, [query.searchKeyword]);

  // search click
  const handleSearchClick = (clickKeyword = inputSearchValue) => {
    if (!query.tabIndex || !query.searchKeyword) return;
    if (clickKeyword.length === 0) return;

    setPage(1);

    let recentListArr = recentList;
    recentListArr.unshift(clickKeyword);
    if (recentListArr.length > 10) {
      recentListArr.pop();
    }
    setRecentList(recentListArr);
    localStorage.setItem("recentList", JSON.stringify([...new Set(recentListArr)]));
    setSearchModalShow(false);
    history.push(`/store/search/result?page=1&searchKeyword=${clickKeyword}&tabIndex=${tabIndex}`);
  };


  return (
    <>
      {searchModalShow && <SearchModal setSearchModalShow={setSearchModalShow} />}
      <Container>
        <Fixed>
          {/* search */}
          <SearchInputWrap>
            <SearchInputBox
              onClick={() => {
                // setSearchModalShow(true);
              }}
            >
              <SearchIcon>
                <img src={IconSearch} alt="searchIcon" />
              </SearchIcon>
              <SearchInput
                value={inputSearchValue}
                onChange={(e) => {
                  setInputSearchValue(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key !== "Enter") return;
                  if (inputSearchValue.length === 0) { return };
                  handleSearchClick();
                }}
              // placeholder="검색어를 입력해주세요."
              // readOnly
              />
            </SearchInputBox>
          </SearchInputWrap>
          {/* tab */}
          <StoreTabWrap>
            <StoreSearchTabBox>
              <StoreSearchTab
                onClick={() => {
                  setTabIndex(0);
                  history.push(`/store/search/result?page=1&searchKeyword=${query.searchKeyword}&tabIndex=0`)
                }}
              >
                상품
              </StoreSearchTab>
              <StoreSearchTab
                onClick={() => {
                  setTabIndex(1);
                  history.push(`/store/search/result?page=1&searchKeyword=${query.searchKeyword}&tabIndex=1`)
                }}
              >
                브랜드
              </StoreSearchTab>
              <StoreSearchTab
                onClick={() => {
                  setTabIndex(2);
                  history.push(`/store/search/result?page=1&searchKeyword=${query.searchKeyword}&tabIndex=2`)
                }}
              >
                컨텐츠
              </StoreSearchTab>
              <StoreSearchTabBottom tabIndex={tabIndex}>
                <StoreSearchTabBottomImg src={Zigzag} alt="zigzag-bottom" />
              </StoreSearchTabBottom>
            </StoreSearchTabBox>
          </StoreTabWrap>
        </Fixed>

        {/* result */}
        <StoreSearchResultWrap>
          {tabIndex === 0 ? (
            <StoreSearchResultProductWrap>
              <>
                {/* rc-slider */}
                {/* <AllBrandFilter>
              <AllBrandFilterSlider>
                <Slider
                  range
                  min={15000}
                  max={600000}
                  allowCross={false} // 교차 허용
                  defaultValue={[0, 200000]}
                  tipFormatter={(value) => `${value}%`}
                />
              </AllBrandFilterSlider>
              <AllBrandFilterInputWrap>
                <FilterInputWrap>
                  <div className="filter-input-wrap">
                    <div className="filter-input-list">
                      <input type="text" className="filter-input"/>
                      <span className="filter-text">만원</span>
                    </div>
                    <div className="filter-input-list">
                      <span className="filter-sub-text">~</span>
                    </div>
                    <div className="filter-input-list">
                      <input type="text" className="filter-input"/>
                      <span className="filter-text">만원</span>
                    </div>
                  </div>
                  <button type="button" className="filter-search-button">검색</button>
                </FilterInputWrap>
                <SelectBoxWrap
                  tabIndex="0"
                  onBlur={() => {
                    setOption(false);
                  }}
                >
                  <SelectedButton
                    onClick={() => {
                      setOption(!option);
                    }}
                  >
                    <SelectedButtonText active={option && true}>
                      {optionList}
                    </SelectedButtonText>
                  </SelectedButton>
                  <SelectedArrowImg
                    onClick={() => {
                      setOption(!option);
                    }}
                    active={option && true}
                    src={SelectBoxArrow}
                    alt="selectArrow"
                  />
                  {option ? (
                    <SelectListBox>
                      {selectData.map((optionData, index) => {
                        return (
                          <SelectBtn
                            key={optionData}
                            onClick={() => {
                              setOption(false);
                              setOptionList(optionData);
                              // setReviewSort(index);
                            }}
                          >
                            {optionData}
                          </SelectBtn>
                        );
                      })}
                    </SelectListBox>
                  ) : null}
                </SelectBoxWrap>
              </AllBrandFilterInputWrap>
            </AllBrandFilter> */}
              </>

              <AllBrandFilter>
                <AllBrandFilterPriceListWrap>
                  <AllBrandFilterPriceListBox>
                    {filterSItems.map((itemS, idx) => {
                      return (
                        <AllBrandFilterPriceList
                          key={itemS}
                          active={clickS === idx}
                          onClick={() => {
                            setResultPrdList([]);
                            setClickS(idx);
                            getResultPrdList(idx, clickF, true);
                          }}
                        >
                          <span>{itemS}</span>
                        </AllBrandFilterPriceList>
                      );
                    })}
                  </AllBrandFilterPriceListBox>
                </AllBrandFilterPriceListWrap>
                <SelectBoxWrap
                  tabIndex="0"
                  onBlur={() => {
                    setOption(false);
                  }}
                >
                  <SelectedButton
                    onClick={() => {
                      setOption(!option);
                    }}
                  >
                    <SelectedButtonText active={option}>{optionList}</SelectedButtonText>
                  </SelectedButton>
                  <SelectedArrowImg
                    onClick={() => {
                      setOption(!option);
                    }}
                    active={option}
                    src={SelectBoxArrow}
                    alt="selectArrow"
                  />
                  {option ? (
                    <SelectListBox>
                      {filterFItems.map((itemF, idx) => {
                        return (
                          <SelectBtn
                            key={itemF}
                            onClick={() => {
                              setResultPrdList([]);
                              setOption(false);
                              setOptionList(itemF);
                              setClickF(idx);
                              getResultPrdList(clickS, idx, true);
                            }}
                          >
                            {itemF}
                          </SelectBtn>
                        );
                      })}
                    </SelectListBox>
                  ) : null}
                </SelectBoxWrap>
              </AllBrandFilter>

              <StoreSearchResultProductArea>
                {isLoading && <SkeletonUI.HorizontalProductList count={6} />}
                {isLoading && <SkeletonUI.HorizontalProductList count={6} />}
                {!isLoading && resultPrdList.length > 0 && (
                  <>
                    {resultPrdList.map((prdValue) => {
                      return (
                        <BestItemProductList
                          key={`${prdValue.prd_id}-prdValue`}
                          value={prdValue}
                          rawThree={false}
                          setLoginPop={setLoginPop}
                        />
                      );
                    })}
                  </>
                )}
                {!isLoading && resultPrdList.length === 0 && (
                  <NoText>검색 결과가 없습니다.</NoText>
                )}
              </StoreSearchResultProductArea>
              {resultPrdList.length > 0 && <NewPagination page={page} setPage={setPage} pageData={pageData} />}
            </StoreSearchResultProductWrap>
          ) : tabIndex === 1 ? (
            <StoreSearchResultBrandWrap>
              {brandsList.length > 0 ? (
                brandsList.map((brandValue, index) => {
                  return (
                    <BrandListContents
                      value={brandValue}
                      key={`${brandValue.brandName}-searchPcBrand-${index}`}
                      widthOne={true}
                      setLoginPop={setLoginPop}
                    />
                  );
                })
              ) : (
                <NoText>검색 결과가 없습니다.</NoText>
              )}
            </StoreSearchResultBrandWrap>
          ) : (
            <ContentsListWrap>
              <ContentsList inputSearchValue={query.searchKeyword} search />
            </ContentsListWrap>
          )}
        </StoreSearchResultWrap>
      </Container>
    </>
  );
}
const Container = styled.div`
  padding-top: 180px;
  position: relative;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (180 / 1320));
  }
`;

const Fixed = styled.div``;

// search
const SearchInputWrap = styled.div`
  padding: 15px 0 35px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (15 / 1320)) 0 calc(100vw * (35 / 1320));
  }
`;
const SearchInputBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 700px;
  margin: 0 auto;
  padding-bottom: 11px;
  border-bottom: 2px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (700 / 1320));
    padding-bottom: calc(100vw * (11 / 1320));
  }
`;
const SearchInput = styled.input`
  width: 100%;
  color: #333333;
  font-weight: 400;
  font-size: 18px;

  &::placeholder {
    color: #acacac;
  }
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
`;
const SearchIcon = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 20px;
  cursor: pointer;

  & > img {
    height: 100%;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
    margin-right: calc(100vw * (20 / 1320));
  }
`;

// tab
const StoreTabWrap = styled.div`
  width: 100%;
  border-bottom: 1px solid #eeeeee;
`;
const StoreSearchTabBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 378px;
  height: 40px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
    height: calc(100vw * (40 / 1320));
  }
`;
const StoreSearchTab = styled.div`
  width: 126px;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (126 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
`;
const StoreSearchTabBottom = styled.div`
  position: absolute;
  left: ${(props) =>
    props.tabIndex === 0
      ? `0px`
      : props.tabIndex === 1
        ? `126px`
        : props.tabIndex === 2
          ? `252px`
          : null};
  bottom: -7px;
  transition: ease 0.3s all;
  width: 126px;

  @media screen and (max-width: 1320px) {
    left: ${(props) =>
    props.tabIndex === 0
      ? `0px`
      : props.tabIndex === 1
        ? `calc(100vw * (126 / 1320))`
        : props.tabIndex === 2
          ? `calc(100vw * (252 / 1320))`
          : null};
    bottom: calc(100vw * (-7 / 1320));
    width: calc(100vw * (126 / 1320));
  }
`;
const StoreSearchTabBottomImg = styled.img`
  width: 100%;
  height: 100%;
`;

// result
const StoreSearchResultWrap = styled.div`
  padding-top: 30px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (30 / 1320));
  }
`;
// result - 상품
const StoreSearchResultProductWrap = styled.div`
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
`;
const StoreSearchResultProductArea = styled.div`
  display: flex;
  flex-wrap: wrap;

  & .product_box {
    &:not(:nth-child(6n)) {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1320px) {
    & .product_box {
      &:not(:nth-child(6n)) {
        margin-right: calc(100vw * (8 / 1320));
      }
    }
  }

  @media screen and (max-width: 930px) {
    & .product_box {
      &:not(:nth-child(6n)) {
        margin-right: 0;
      }
    }
  }
`;
const AllBrandFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 95px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (95 / 1320));
  }
`;
// input two range slider
const AllBrandFilterSlider = styled.div`
  width: 525px;
  padding: 0 8px;

  & .rc-slider-rail {
    background-color: #dddddd;
  }
  & .rc-slider-track {
    // fill
    background-color: #000;
  }

  & .rc-slider-handle {
    // circle
    top: 50%;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 2px solid #000;
    margin-top: -8px;
    opacity: 1;
  }

  & .rc-slider-handle:active,
  & .rc-slider-handle:focus,
  & .rc-slider-handle-dragging {
    // dragging option
    box-shadow: none;
    border-color: #000;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (525 / 1320));
    padding: 0 calc(100vw * (8 / 1320));

    & .rc-slider-handle {
      // circle
      width: calc(100vw * (16 / 1320));
      height: calc(100vw * (16 / 1320));
      margin-top: calc(100vw * (-8 / 1320));
    }
  }
`;
// price filter button list
const AllBrandFilterPriceListWrap = styled.div``;
const AllBrandFilterPriceListBox = styled.ul`
  display: flex;
  align-items: center;
`;
const AllBrandFilterPriceList = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 20px;
  }
  ${({ active }) =>
    active &&
    `
  & span {
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-6deg);
      z-index: 0;
      display: block;
      width: 100%;
      height: 40%;
      background-color: #fdf251;
    }
`}

  & span {
    color: #333333;
    font-weight: 500;
    font-size: 16px;
  }
  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (5 / 1320));
    &:not(:last-child) {
      margin-right: calc(100vw * (20 / 1320));
    }
    & span {
      font-size: calc(100vw * (16 / 1320));
    }
  }
`;

// select wrap
const AllBrandFilterInputWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 669px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (669 / 1320));
  }
`;
const FilterInputWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & .filter-input-wrap,
  .filter-input-list {
    display: flex;
    align-items: center;
  }
  & .filter-input {
    width: 100px;
    height: 40px;
    font-size: 15px;
    font-weight: 400;
    color: #000;
    text-align: center;
    background-color: #fff;
    border: 1px solid #000;
    flex-shrink: 0;
  }
  & .filter-text,
  .filter-sub-text {
    font-size: 15px;
    font-weight: 400;
    color: #000;
  }
  & .filter-text {
    margin-left: 5px;
  }
  & .filter-sub-text {
    margin: 0 12px;
  }
  & .filter-search-button {
    width: 100px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: #000;
    margin-left: 15px;
    flex-shrink: 0;
  }

  @media screen and (max-width: 1320px) {
    & .filter-input {
      width: calc(100vw * (100 / 1320));
      height: calc(100vw * (40 / 1320));
      font-size: calc(100vw * (15 / 1320));
    }
    & .filter-text,
    .filter-sub-text {
      font-size: calc(100vw * (15 / 1320));
    }
    & .filter-text {
      margin-left: calc(100vw * (5 / 1320));
    }
    & .filter-sub-text {
      margin: 0 calc(100vw * (12 / 1320));
    }
    & .filter-search-button {
      width: calc(100vw * (100 / 1320));
      height: calc(100vw * (40 / 1320));
      font-size: calc(100vw * (16 / 1320));
      margin-left: calc(100vw * (15 / 1320));
    }
  }
`;

// select
const SelectBoxWrap = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 145px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (145 / 1320));
  }
`;
const SelectedButton = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background-color: #fff;
  border: 1px solid #dddddd;
  cursor: pointer;
  transition: ease 0.3s all;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (40 / 1320));
    padding: 0 calc(100vw * (10 / 1320));
  }
`;
const SelectedButtonText = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333333;
  transition: ease 0.3s all;

  ${({ active }) => {
    return (
      active &&
      `
        color: #000;
    `
    );
  }}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;
const SelectListBox = styled.ul`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dddddd;

  @media screen and (max-width: 1320px) {
    top: calc(100% + 100vw * (8 / 1320));
    max-height: calc(100vw * (304 / 1320));
  }
`;
const SelectBtn = styled.li`
  width: 100%;
  color: #888;
  font-size: 16px;
  text-align: center;
  padding: 8px 0;
  cursor: pointer;

  &:hover {
    background: #f7f7f7;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (8 / 1320)) 0;
  }
`;
const SelectedArrowImg = styled.img`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  transition: ease 0.3s all;
  ${({ active }) => {
    return (
      active &&
      `
        transform : translateY(-50%) rotate(180deg);
    `
    );
  }}

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (10 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
`;

// result - 브랜드
const StoreSearchResultBrandWrap = styled.div`
  width: 700px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (700 / 1320));
  }
`;
// result - 컨텐츠 리스트
const ContentsListWrap = styled.div``;

const NoText = styled.div`
  width: 100%;
  color: #999;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  padding: 200px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (200 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (210 / 428)) 0 0;
  }
`;
