import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import {
  Layout,
  TabContentHeading,
  TabContent,
  TabFaqButtonWrap,
  TabFaqButton,
  TabFaqTable,
  TabFaqHead,
  TabPaging,
  TabPages,
  PrevButton,
  NextButton,
  PageNum,
} from "./mypage.elements";
import TabFaqItem from "./TabFaqItem";
import QuestionModal from "../../common/pop/QuestionModal";
import serverController from "../../../server/serverController";

const faqList = [
  {
    title: "김소연 패션에 대해서 문의 합니다.",
    date: "2021.06.16",
    isAnswer: "답변 완료",
    content:
      "김소연 패션에 대해서  문의 합니다 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다.김소연 패션에 대해서  문의 합니다 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다.김소연 패션에 대해서  문의 합니다 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다.김소연 패션에 대해서  문의 합니다 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다.김소연 패션에 대해서  문의 합니다 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다.",
    answer: `안녕하세요 답변드립니다. :)

        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)
        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)
        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)`,
  },
  {
    title: "김소연 패션에 대해서 문의 합니다.",
    date: "2021.06.16",
    isAnswer: "답변 완료",
    content:
      "김소연 패션에 대해서  문의 합니다 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다.",
    answer: `안녕하세요 답변드립니다. :)

        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)
        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)
        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)`,
  },
  {
    title: "김소연 패션에 대해서 문의 합니다.",
    date: "2021.06.16",
    isAnswer: "답변 완료",
    content:
      "김소연 패션에 대해서  문의 합니다 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다.",
    answer: `안녕하세요 답변드립니다. :)

        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)
        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)
        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)`,
  },
  {
    title: "김소연 패션에 대해서 문의 합니다.",
    date: "2021.06.16",
    isAnswer: "답변 완료",
    content:
      "김소연 패션에 대해서  문의 합니다 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다.",
    answer: `안녕하세요 답변드립니다. :)

        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)
        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)
        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)`,
  },
  {
    title: "김소연 패션에 대해서 문의 합니다.",
    date: "2021.06.16",
    isAnswer: "답변 완료",
    content:
      "김소연 패션에 대해서  문의 합니다 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다. 김소연 패션에 대해서  문의 합니다.",
    answer: `안녕하세요 답변드립니다. :)

        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)
        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)
        안녕하세요 답변드립니다. :)안녕하세요 답변드립니다. :)`,
  },
];

const InitScrollBar = createGlobalStyle`
    div {
    -ms-overflow-style: in; /* IE and Edge */
    scrollbar-width: initial; /* Firefox */
    }
    div::-webkit-scrollbar {
    display: block; /* Chrome, Safari, Opera*/
    }
`;
const MyPageQuestion = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [list, setList] = useState([]);
  const [isQuestModal, setIsQuestModal] = useState(false);
  const titles = ["날짜", "문의 내용", "답변 결과"];

  const getFaq = async () => {
    await serverController.connectFetchController(
      `inquiry/user`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setList(res?.inquiryList);
        } else {
          console.log("문의내역 없음");
        }
      },
      (err) => console.error(err)
    );
  };

  useEffect(() => {
    getFaq();
  }, []);

  return (
    <>
      <InitScrollBar />

      <Layout>
        <TabFaqButtonWrap>
          <TabFaqButton onClick={() => setIsQuestModal(true)}>문의하기</TabFaqButton>
        </TabFaqButtonWrap>

        <TabContent>
          <div>
            <TabFaqTable>
              <TabFaqHead>
                {titles.map((title, i) => (
                  <div>{title}</div>
                ))}
              </TabFaqHead>
              {list.length > 0 ? (
                list?.map((data, i) => {
                  return (
                    <TabFaqItem data={data} key={i} />
                    // <TabContentRow onClick={() => setIsOpen(prev => !prev)}>
                    // <div className="faq-date">ss{DateToText(data?.inquiry_date, ".")}</div>
                    // <div className="faq-question">{data?.inquiry_content}</div>
                    // <div className="faq-answerd">{data?.inquiry_status}</div>
                    // </TabContentRow>
                  );
                })
              ) : (
                <NoText style={{ paddingTop: "100px", textAlign: "center" }}>
                  문의하신 내역이 없습니다.
                </NoText>
              )}
            </TabFaqTable>
          </div>
        </TabContent>
        {/* <TabPaging>
                        <PrevButton />

                        <TabPages>
                        {
                            Array(5).fill().map((page, i) => {
                                return <PageNum active={tabIndex === i}>{i + 1}</PageNum>
                            })
                        }
                        </TabPages>

                        <NextButton />
                    </TabPaging> */}
      </Layout>

      {isQuestModal && <QuestionModal setIsQuestModal={setIsQuestModal} />}
    </>
  );
};
const NoText = styled.p`
  font-size: calc(100vw * (16 / 1920));
  font-weight: bold;
  color: #aaa;
  text-align: center;
  margin-top: calc(100vw * (20 / 1920));

  @media screen and (max-width: 1024px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (20 / 428));
  }
`;

export default MyPageQuestion;
