import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

//img
import { imgURL } from "../../../server/appInfoController";
import IconLock from "../../../img/icon/icon_Inquiry.svg";
import IconArrow from "../../../img/icon/icon_answer_arrow.svg";

//component, page
import NewPagination from "../../common/pagination/NewPagination";
import ReportModal from "../../common/modal/ReportModal";
import ProductInquiryPostModal from "../../common/modal/ProductInquiryPostModal";

export default function DetailInquiry({ product, inquiry, getInquiry, inquiryPageData }) {
  const { isLogged } = useSelector((state) => state.userData);

  const [inquiryEditModalShow, setInquiryEditModalShow] = useState(false);
  const [page, setPage] = useState(1);

  // report
  const [inquiryReportPop, setInquiryReportPop] = useState(false);
  const [inquiryPkId, setInquiryPkId] = useState(-1);

  useEffect(() => {
    getInquiry();
  }, [inquiryEditModalShow]);

  // s :: items
  const productInquiryEditModalItems = {
    product,
    setInquiryEditModalShow,
  };

  const detailInquiryItemItems = {
    setInquiryReportPop,
    setInquiryPkId,
    isLogged,
  };
  // e :: items

  return (
    <Container>
      {inquiryEditModalShow && <ProductInquiryPostModal {...productInquiryEditModalItems} />}
      {inquiryReportPop && <ReportModal pkId={inquiryPkId} setClosePop={setInquiryReportPop} inq />}
      <DetailInquiryWrap>
        <TitleWrap>
          <TitleInner>
            <TitleText>상품문의({inquiry.length})</TitleText>
            {isLogged && (
              <InquiryBtn
                onClick={() => {
                  setInquiryEditModalShow(true);
                }}
              >
                문의하기
              </InquiryBtn>
            )}
          </TitleInner>
        </TitleWrap>
        <InquiryListWrap>
          {inquiry?.length > 0 ? (
            inquiry?.map((value) => {
              return (
                <DetailInquiryItem key={value.inq_id} value={value} {...detailInquiryItemItems} />
              );
            })
          ) : (
            <DetailInfoText>작성된 문의가 없습니다.</DetailInfoText>
          )}
        </InquiryListWrap>
        {inquiry.length > 0 && (
          <NewPagination pageData={inquiryPageData} page={page} setPage={setPage} />
        )}
      </DetailInquiryWrap>
    </Container>
  );
}

const DetailInquiryItem = ({ value, setInquiryReportPop, setInquiryPkId, isLogged }) => {
  const [inquiryListShow, setInquiryListShow] = useState(false);

  return (
    <InquiryList>
      <InquiryListInner
        onClick={() => {
          setInquiryListShow(!inquiryListShow);
        }}
      >
        <CommentWrap>
          <CommentLeft>
            <CommentLeftDate>{value.inq_date.split("T")[0].replaceAll("-", ".")}</CommentLeftDate>
            <CommentLeftUserName>{value.mem_name || "WALA USER"}</CommentLeftUserName>
          </CommentLeft>
          <CommentRight>
            {value?.comment_type === 1 ? (
              <CommentText answer={true}>답변완료</CommentText>
            ) : (
              <CommentText answer={false}>미답변</CommentText>
            )}
          </CommentRight>
        </CommentWrap>
        {/* 1이면 비밀글 아니면 공개글~ */}
        {value.inq_secret == 1 ? (
          <InquiryPrdTitleWrap>
            <InquiryLock>
              <LockImg src={IconLock} alt="비밀글입니다" />[
              {value.inq_type === 1
                ? "상품문의"
                : value.inq_type === 2
                ? "재입고 문의"
                : value.inq_type === 3
                ? "사이즈 문의"
                : value.inq_type === 4
                ? "배송 문의"
                : "문의"}
              ] 비밀글입니다.
            </InquiryLock>
          </InquiryPrdTitleWrap>
        ) : (
          <InquiryPrdTitleWrap>
            <InquiryPrdText>
              [
              {value.inq_type === 1
                ? "상품문의"
                : value.inq_type === 2
                ? "재입고 문의"
                : value.inq_type === 3
                ? "사이즈 문의"
                : value.inq_type === 4
                ? "배송 문의"
                : "문의"}
              ] {value.inq_title}
            </InquiryPrdText>
          </InquiryPrdTitleWrap>
        )}
      </InquiryListInner>
      {inquiryListShow && (
        <>
          {value.inq_secret == 0 && (
            <AnswerWrap>
              <AnswerInner>
                <AnswerTitle>{value.inq_content}</AnswerTitle>
                {value.inq_images && (
                  <AnswerImgBoxList>
                    {value.inq_images?.split(",") &&
                      value.inq_images?.split(",")?.length > 0 &&
                      value.inq_images.split(",").map((el, idx) => {
                        return (
                          <AnswerImgBox>
                            <img key={idx + "-imgB"} src={imgURL + el} alt="product-img" />
                          </AnswerImgBox>
                        );
                      })}
                  </AnswerImgBoxList>
                )}
                <AnswerReportButton
                  type="button"
                  onClick={() => {
                    if (isLogged) {
                      setInquiryPkId(value.inq_id);
                      setInquiryReportPop(true);
                    } else {
                      return alert(`로그인 후 이용해주세요.`);
                    }
                  }}
                >
                  신고하기
                </AnswerReportButton>

                {value.inq_answer == 2 && (
                  <AnswerTextBox>
                    <AnswerImg>
                      <img src={IconArrow} alt="arrow-icon" />
                    </AnswerImg>
                    <AnswerText>{value.inq_answer_content}</AnswerText>
                  </AnswerTextBox>
                )}
              </AnswerInner>
            </AnswerWrap>
          )}
        </>
      )}
    </InquiryList>
  );
};
const Container = styled.div`
  width: 100%;
  padding-top: 40px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (40 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (30 / 428));
    padding-bottom: calc(100vw * (30 / 428));
  }
`;
const DetailInquiryWrap = styled.div``;
const TitleWrap = styled.div`
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const TitleInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 22px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    display: block;
    margin-bottom: calc(100vw * (35 / 428));
  }
`;
const TitleText = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: #333333;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (15 / 428));
    font-size: calc(100vw * (16 / 428));
  }
`;
const InquiryWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const InquiryBtn = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 45px;
  color: #333333;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (160 / 1320));
    height: calc(100vw * (45 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
const InquiryCount = styled.span`
  font-size: 22px;
  font-weight: 900;
  margin-left: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
    margin-left: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    margin-left: calc(100vw * (15 / 428));
  }
`;
const TitleSub = styled.p`
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #646464;
  margin-left: 20px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    display: block;
    font-size: calc(100vw * (14 / 428));
    margin-left: 0;
    margin-top: calc(100vw * (10 / 428));
    line-height: 1.4;
  }
`;
const TitleSubRed = styled.span`
  font-size: 15px;
  color: #f24f3c;
  font-weight: bold;
  margin-left: 3px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (3 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-left: 0;
  }
`;
//InquiryList
const InquiryListWrap = styled.div``;
const InquiryList = styled.div`
  width: 100%;
  &::after {
    content: "";
    display: block;
    height: 1px;
    margin: 0 auto;
    background-color: #eee;
  }

  @media screen and (max-width: 930px) {
    &::after {
      width: calc(100vw * (378 / 428));
    }
  }
`;
const InquiryListInner = styled.div`
  width: 100%;
  padding: 18px 0 20px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (18 / 1320)) 0 calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (18 / 428)) 0;
  }
`;
const CommentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto 8px;

  @media screen and (max-width: 1320px) {
    margin: 0 auto calc(100vw * (8 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto calc(100vw * (8 / 428));
  }
`;
const CommentLeft = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    line-height: calc(100vw * (21 / 428));
  }
`;
const CommentLeftUserName = styled.span`
  font-size: 15px;
  color: #333;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

const CommentRight = styled.div``;
const CommentText = styled.p`
  font-size: 15px;
  font-weight: 600;

  ${({ answer }) => (answer ? `color : #111111;` : `color : #ACACAC;`)}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const InquiryDateWrap = styled.div`
  width: 100%;
  margin-bottom: 15px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (14 / 428));
  }
`;
const CommentLeftDate = styled.p`
  font-size: 15px;
  margin-right: 12px;
  padding-right: 12px;
  border-right: 1px solid #ddd;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-right: calc(100vw * (12 / 1320));
    padding-right: calc(100vw * (12 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-right: calc(100vw * (12 / 428));
    padding-right: calc(100vw * (12 / 428));
  }
`;
const InquiryDate = styled.p`
  font-size: 15px;
  color: #969696;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (13 / 428));
  }
`;
const InquiryPrdTitleWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  }
`;
const InquiryPrdText = styled.p`
  line-height: 1.5;
  color: #333;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const InquiryQ = styled.p`
  font-size: 18px;
  font-weight: bold;
  padding-right: 8px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    padding-right: calc(100vw * (8 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const InquiryPrdTitle = styled.p`
  font-size: 14px;
  color: #969696;
  margin-bottom: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (6 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (6 / 428));
  }
`;
const InquiryTitle = styled.p`
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const InquiryLock = styled(InquiryTitle)`
  display: flex;
  align-items: center;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const LockImg = styled.img`
  display: block;
  width: 14px;
  margin-right: 4px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (14 / 1320));
    margin-right: calc(100vw * (4 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (18 / 428));
    margin-right: calc(100vw * (2 / 428));
  }
`;
const CommentTitle = styled(InquiryPrdTitle)`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #969696;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const CommentSubTitle = styled.span`
  font-size: 16px;
  margin-right: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-right: calc(100vw * (6 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-right: calc(100vw * (6 / 428));
  }
`;
const CommentTitleWrap = styled.div``;
const InquiryCommentWrap = styled(InquiryPrdTitleWrap)`
  padding-top: 20px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (24 / 428));
  }
`;
const DetailInfoText = styled.p`
  color: #999999;
  font-size: 16px;
  text-align: center;
  padding: 200px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (200 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (200 / 428)) 0;
  }
`;
const AnswerWrap = styled.div`
  padding: 20px 0 30px;
  background-color: #f9f9f9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0 calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (15 / 428)) 0 calc(100vw * (20 / 428));
  }
`;
const AnswerInner = styled.div`
  width: 1220px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1220 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const AnswerTitle = styled.p`
  line-height: 1.5;
  color: #333;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const AnswerReportButton = styled.button`
  color: #6666;
  font-weight: 600;
  font-size: 15px;
  padding: 0;
  margin-top: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (10 / 428)) 0;
    margin-top: calc(100vw * (10 / 428));
  }
`;

const AnswerImgBoxList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (15 / 428));
  }
`;

const AnswerImgBox = styled.div`
  width: 100px;
  height: 100px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    height: calc(100vw * (100 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
    height: calc(100vw * (120 / 428));

    &:not(:last-child) {
      margin-right: calc(100vw * (9 / 428));
    }
  }
`;
const AnswerTextBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 15px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (10 / 428));
    padding: 0;
  }
`;
const AnswerImg = styled.div`
  width: 24px;
  height: 24px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const AnswerText = styled(AnswerTitle)`
  width: 1156px;
  margin-top: 5px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1156 / 1320));
    margin-top: calc(100vw * (5 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (344 / 428));
    margin-top: calc(100vw * (5 / 428));
  }
`;
