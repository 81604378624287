import React, { useState, useEffect } from "react";
import DetailOptionBox from "./DetailOptionBox";

export default function DetailSetOption({
  product,
  productInfo,
  optionList,
  checkSetProductValidation,
  setOptIdList = [],
}) {
  const [options, setOptions] = useState(null);
  const [reset, setReset] = useState(false);
  const [keepOptionList, setKeepOptionList] = useState({});

  var optionalSelectArray = product.prd_additional4.split(",");
  var essenceSelectArray = product.prd_additional5.split(",");

  function createOptionObject() {
    var o = {};
    for (var i = 0; i < optionList.length; i++)
      o[optionList[i].prd_id]
        ? o[optionList[i].prd_id].push(optionList[i])
        : (o[optionList[i].prd_id] = [optionList[i]]);
    return o;
  }

  function getProductById(key) {
    for (var i = 0; i < productInfo.length; i++)
      if (productInfo[i].prd_id == key) return productInfo[i];
    return null;
  }

  useEffect(() => {
    if (optionList) setOptions(createOptionObject());
  }, [optionList]);

  useEffect(() => {
    if (reset) setReset(false);
  }, [reset]);

  const createSelectedItem = (list, isOptional, isSetOptional) => {
    let optInfo = { optionList: {}, optId: [] };
    let result = {};
    let optArr = [];

    for (let pKey in list) {
      for (let key in list[pKey]) {
        if (key === "0") continue;

        let prd = null;
        if (isSetOptional == false) prd = product;
        else prd = getProductById(list[pKey][key].option.prd_id);

        let curProduct = getProductById(list[pKey][key]?.option?.prd_id);

        optArr.push(list[pKey][key].option.opt_id);

        var op = {
          opt_id: list[pKey][key].option.opt_id,
          opt_title: list[pKey][key].option.opt_title,
          opt_name: list[pKey][key].option.opt_name,
          opt_price: list[pKey][key].option.opt_price,
          prd_title: prd.prd_title,
          prd_id: prd.prd_id,
          prd_title: curProduct.prd_title,
          prd_list_price: product.prd_list_price,
          prd_sale_price: product.prd_sale_price,
          prd_earn_price: product.prd_earn_price,
          sale_price: product.sale_price,
          opt_inventory: list[pKey][key].option.opt_inventory,
          //  product : product
        };

        optInfo.product = {
          prd_id: prd.prd_id,
          prd_sale_rate: prd.prd_sale_rate,
          prd_price: prd.prd_price,
        };

        result = {
          prd_id: prd.prd_id,
          prd_img: prd.prd_img,
          prd_price: prd.prd_price,
          prd_sale_rate: prd.prd_sale_rate,
          prd_title: prd.prd_title,
          prd_list_price: product.prd_list_price,
          prd_sale_price: product.prd_sale_price,
          prd_earn_price: product.prd_earn_price,
          sale_price: product.sale_price,
          count: 1,
        };

        optInfo.optionList[list[pKey][key].option.prd_id]
          ? optInfo.optionList[list[pKey][key].option.prd_id].push(op)
          : (optInfo.optionList[list[pKey][key].option.prd_id] = [op]);
        optInfo.optId.push(list[pKey][key].option.opt_id);
      }
    }

    optInfo.isOptional = isOptional;
    optInfo.isSetOptional = isSetOptional;

    result.options = optInfo;
    checkSetProductValidation(result);
    setReset(true);
    setOptIdList(optArr);
  };

  const vaildateSetSelectItem = (option) => {
    let list = JSON.parse(JSON.stringify(keepOptionList));
    if (optionalSelectArray.indexOf(String(option[1].option.prd_id)) == -1) {
      list[option[1].option.prd_id] = option;
      setKeepOptionList(list);
    } else if (optionalSelectArray.indexOf(String(option[1].option.prd_id)) != -1) {
      createSelectedItem({ item: option }, false, false);
      setKeepOptionList({});
    }

    let count = 0;
    let checkVaildItemCount = 0;
    for (let key in options) {
      let prd = getProductById(options[key][0].prd_id);
      if (optionalSelectArray.indexOf(String(prd.prd_id)) != -1) continue;
      count++;
    }

    for (let key in list) {
      if (optionalSelectArray.indexOf(String(list[key][1].option.prd_id)) == -1) {
        checkVaildItemCount++;
      }
    }

    if (checkVaildItemCount == count) {
      createSelectedItem(list, false, count == 1 ? false : true);
      setKeepOptionList({});
    }
  };
  function getOptionRelateList() {
    var opt = {};
    for (var i = 0; i < optionList.length; i++) {
      opt[optionList[i].opt_relate_id]
        ? opt[optionList[i].opt_relate_id].push(optionList[i])
        : (opt[optionList[i].opt_relate_id] = [optionList[i]]);
    }
    return opt;
  }

  const createOptionTagList = () => {
    var renderList = [];
    var count = 1;
    let optionMap = getOptionRelateList();

    for (let key in options) {
      let prd = getProductById(options[key][0].prd_id);

      if (prd == null) continue;

      count++;

      if (optionalSelectArray.indexOf(String(prd.prd_id)) != -1) continue;

      renderList.push(
        <DetailOptionBox
          key={product.prd_id}
          zIndex={40000 / count}
          isSet={essenceSelectArray.length > 0}
          title={prd.prd_title}
          options={optionList}
          product={product}
          type={0}
          vaildateSetSelectItem={vaildateSetSelectItem}
        />
      );
    }

    var sum = 0;
    for (var i = 0; i < optionList.length; i++) {
      sum += optionList[i].opt_inventory;
    }

    if (sum === 0) {
      return <p>옵션이 없습니다.</p>;
    }

    return renderList; //renderList.length == 0 ? <Text style={styles.title}>품절 상품입니다.</Text> : renderList;
  };

  return <div>{reset ? null : createOptionTagList()}</div>;
}
