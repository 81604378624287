import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { imgURL } from '../../server/appInfoContoller';
import { useHistory } from 'react-router-dom';

import {
    MultipleWrapper,
    LayOut,
    ContentHeading2,
    MultipleContentSlider,
    MultipleSlide,
    MultipleLeft,
    MultipleRight,
    MultipleContentImg,
    MultipleProducts,
    MultipleThumbs,
    MultipleContentText,
    MoreButton2
} from './CategoryContents.element';

const CategoryMultipleSlide = ({ data }) => {
    const history = useHistory()
    const leftData = data[0];
    const rightData = data[1];
    const leftSrc = leftData?.contents_thumbnail?.split(",")[0];
    const rightSrc = rightData?.contents_thumbnail?.split(",")[0];


    console.log(rightData)
    return (
        <>
            <MultipleSlide>
                <MultipleLeft onClick={() => history.push(`/content/${leftData?.contents_id}`)}>
                    <MultipleContentImg>
                        <MultipleProducts col={true}>
                            {
                                leftData?.products?.slice(0, 3).map((value, j) => {
                                    return (
                                        <div key={j}><img src={imgURL + value?.prd_thumbnail} alt={value?.prd_content} /></div>
                                    )
                                })
                            }
                        </MultipleProducts>

                        <MultipleThumbs><img src={imgURL + leftSrc} alt={leftData?.contents_intro} /></MultipleThumbs>
                    </MultipleContentImg>

                    <MultipleContentText>
                        <h3>{leftData?.contents_title}</h3>
                        <p>{ReactHtmlParser(leftData?.contents_content)}</p>

                        <div><MoreButton2 onClick={() => history.push(`/content/${leftData?.contents_id}`)}>See More</MoreButton2></div>
                    </MultipleContentText>
                </MultipleLeft>

                <MultipleRight onClick={() => history.push(`/content/${rightData?.contents_id}`)}>
                    {
                        rightData && (
                            <>
                                <MultipleContentText right={true}>
                                    <h3>{rightData?.contents_title}</h3>
                                    <p className="multiple_contents">{ReactHtmlParser(rightData?.contents_content)}</p>
                                    <div><MoreButton2 onClick={() => history.push(`/content/${rightData?.contents_id}`)}>See More</MoreButton2></div>
                                </MultipleContentText>

                                <MultipleContentImg col={true}>
                                    <MultipleThumbs><img src={imgURL + rightSrc} alt={rightData?.contents_intro} /></MultipleThumbs>

                                    <MultipleProducts right={true}>
                                        {
                                            rightData.products?.slice(0, 3).map((v, i) => {
                                                return <div key={i}><img src={imgURL + v?.prd_thumbnail} alt={v?.prd_content} /></div>
                                            })
                                        }


                                        {/* <div><img src={categoryProd5} alt="" /></div>
                                <div><img src={categoryProd6} alt="" /></div> */}
                                    </MultipleProducts>
                                </MultipleContentImg>
                            </>
                        )
                    }


                </MultipleRight>
            </MultipleSlide>
        </>
    )
}

export default CategoryMultipleSlide
