//react
import { useState, useRef, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import { imgURL } from "../../server/appInfoController";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { postLikeProducts } from "../../../hooks/likeHooks";

//css
import styled from "styled-components";
import IconWishOn from "../../img/icon/icon_wish_on.svg";
import IconWishOff from "../../img/icon/icon_wish_off.svg";
import DefaultImg from "../../img/icon/loading_default_img.svg";

//page
import Commas from "../../server/Commas";

export default function BrandItemProductList({ value, rawThree = false, setLoginPop }) {
  const history = useHistory();
  const { isLogged } = useSelector((state) => state.userData);

  const [isWish, setIsWish] = useState(value.like_id !== 0);

  const detailClick = () => {
    history.push(`/store/detail/${value.prd_id}`);
  };

  const onHandleLike = async () => {
    const res = await postLikeProducts(value.prd_id);
    setIsWish(res?.result === 1);
  };

  return (
    <Container className="product_box">
      <PC>
        <BestNewImgBox>
          <BestNewImg
            src={value?.prd_thumbnail ? `${imgURL}${value?.prd_thumbnail}` : DefaultImg}
            alt="hot_img"
            onClick={detailClick}
          />
          <WishIcon
            src={isWish ? IconWishOn : IconWishOff}
            alt="wish"
            onClick={() => {
              if (isLogged) {
                onHandleLike();
              } else {
                setLoginPop(true);
              }
            }}
          />
        </BestNewImgBox>
        <BestNewTextWrap onClick={detailClick}>
          <BestNewImgTitle>{value?.bc_title || "(BRAND)"}</BestNewImgTitle>
          <BestNewImgText>{ReactHtmlParser(value?.prd_name)}</BestNewImgText>
          {value?.prd_sale_rate === 0 ? (
            <PriceBox>
              <BestNewPriceWrap>
                <Price>{Commas.numberWithCommas(value?.prd_list_price)}원</Price>
              </BestNewPriceWrap>
            </PriceBox>
          ) : (
            <PriceBox>
              <OriginalPrice>{Commas.numberWithCommas(value?.prd_price)}원</OriginalPrice>
              <BestNewPriceWrap>
                <SaleRate>{Commas.numberWithCommas(value?.prd_sale_rate)}%</SaleRate>
                <Price>{Commas.numberWithCommas(value?.prd_list_price)}원</Price>
              </BestNewPriceWrap>
            </PriceBox>
          )}
        </BestNewTextWrap>
      </PC>

      <Mobile>
        <BestNewImgBox rawThree={rawThree}>
          <BestNewImg
            src={value?.prd_thumbnail ? `${imgURL}${value?.prd_thumbnail}` : DefaultImg}
            alt="hot_img"
            onClick={detailClick}
          />
          <WishIcon
            src={isWish ? IconWishOn : IconWishOff}
            alt="wish"
            onClick={() => {
              if (isLogged) {
                onHandleLike();
              } else {
                setLoginPop(true);
              }
            }}
          />
        </BestNewImgBox>
        <BestNewTextWrap onClick={detailClick}>
          <BestNewImgTitle>{value.bc_title || "(BRAND)"}</BestNewImgTitle>
          <BestNewImgText>{ReactHtmlParser(value?.prd_name)}</BestNewImgText>
          {value?.prd_sale_rate === 0 ? (
            <PriceBox>
              <OriginalPrice original>.</OriginalPrice>
              <BestNewPriceWrap>
                <Price>{Commas.numberWithCommas(value?.prd_list_price)}원</Price>
              </BestNewPriceWrap>
            </PriceBox>
          ) : (
            <PriceBox>
              <OriginalPrice>{Commas.numberWithCommas(value?.prd_price)}원</OriginalPrice>
              <BestNewPriceWrap>
                <SaleRate>{Commas.numberWithCommas(value?.prd_sale_rate)}%</SaleRate>
                <Price>{Commas.numberWithCommas(value?.prd_list_price)}원</Price>
              </BestNewPriceWrap>
            </PriceBox>
          )}
        </BestNewTextWrap>
      </Mobile>
    </Container>
  );
}

const Container = styled.div`
  width: 240px;
  align-self: stretch;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 40px;

  &:not(:nth-child(5n)) {
    margin-right: 10px;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (240 / 1320));
    margin-bottom: calc(100vw * (40 / 1320));

    &:not(:nth-child(5n)) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (186 / 428));
    margin-bottom: calc(100vw * (20 / 428));

    ${({ rawThree }) =>
    rawThree &&
    `
        width: calc(100vw * (120 / 428));

        &:not(:nth-child(3n)){
          margin-right: calc(100vw * (1.5 / 428));
        }
    `}

    &:not(:nth-child(5n)) {
      margin-right: 0;
    }
  }
`;
const BestNewImgBox = styled.div`
  position: relative;
  width: 240px;
  height: 240px;
  overflow: hidden;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (240 / 1320));
    width: calc(100vw * (240 / 1320));
  }
  @media screen and (max-width: 930px) {
    border: calc(100vw * (1 / 428)) solid #f8f8f8f8;
    height: calc(100vw * (186 / 428));
    width: 100%;
    ${({ rawThree }) =>
    rawThree &&
    `
    height :  calc(100vw * (120 / 428));
    width :  calc(100vw * (120 / 428));
  `}
  }
`;
const BestNewImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const BestNewTextWrap = styled.div`
  width: 100%;
  cursor: pointer;
`;
const BestNewImgTitle = styled.p`
  font-size: 14px;
  font-weight: 800;
  color: #333333;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;
const BestNewImgText = styled.p`
  min-height: 35px;
  font-size: 15px;
  color: #333333;
  margin-top: 5px;

  word-break: keep-all;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    min-height: calc(100vw * (35 / 1320));
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    min-height: calc(100vw * (45 / 428));
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (5 / 428));
  }
`;
const WishIcon = styled.img`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: block;
  width: 24px;
  height: 24px;

  @media screen and (max-width: 1320px) {
    bottom: calc(100vw * (10 / 1320));
    right: calc(100vw * (10 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    bottom: calc(100vw * (10 / 428));
    right: calc(100vw * (10 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const BestNewPriceWrap = styled.div`
  display: flex;
  align-items: center;
`;
const SaleRate = styled.p`
  color: #333333;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding: 2px 4px;
  margin-right: 5px;
  border-radius: 3px;
  background: #fdf251;
  line-height: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    padding: calc(100vw * (2 / 1320)) calc(100vw * (4 / 1320));
    margin-right: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    padding: calc(100vw * (2 / 428)) calc(100vw * (4 / 428));
    margin-right: calc(100vw * (5 / 428));
  }
`;
const Price = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #333333;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const PriceBox = styled.div`
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (10 / 428));
  }
`;
const OriginalPrice = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #acacac;
  text-decoration: line-through;
  margin-bottom: 4px;
  ${({ original }) => original && `color :transparent;`}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (4 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (4 / 428));
  }
`;
