import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Mobile } from "../../MediaQuery";
import api from "../../../server/serverController";
import serverController from "../../../server/serverController";
import Header from "../../component/common/Header";
import ModalHeader from "../../component/common/ModalHeader";
import MainBenner from "../../component/main/MainBenner";
import BestItemSection from "../../component/main/BestItemSection";
import BestNewSection from "../../component/main/BestNewSection";
import BrandRankSection from "../../component/main/BrandRankSection";
import SubBanner from "../../component/main/SubBanner";
import MDSection from "../../component/main/MDSection";
import ContentSection from "../../component/main/ContentSection";
import StoreFooter from "../../component/common/StoreFooter";
import FixMenu from "../../component/common/FixMenu";
import CategorySlideListSection from "../../component/main/CategorySlideListSection";
import GrayZone from "../../component/common/GrayZone";
import ContentsDetail from "../../component/contents/ContentsDetail";
import ItemSection from "../../component/main/ItemSection";
import FullLoading from "../../component/common/modal/FullLoading";
import StoreSearchRecentListSection from "../../component/search/StoreSearchRecentListSection";
import MyPageDefaultModal from "../../component/common/modal/MyPageDefaultModal";
import MainPopup from "../../component/common/mainPopup/MobilePopup";
import { Helmet } from "react-helmet-async";
import PlanShopBnr from "../../component/plan/PlanShopBnr";

export default function MainPage() {
  const history = useHistory();
  const location = useLocation();
  const [newList, setNewList] = useState([]);
  const [scrollV, setScrollV] = useState(true);

  const [loading, setLoading] = useState(false);
  const [loginPop, setLoginPop] = useState(false);

  const [isMobilePopupClose, setIsMobilePopupClose] = useState(false);
  const [popupList, setPopupList] = useState([]);

  // best main cate link
  const [mainCateLinkBest, setMainCateLinkBest] = useState("");
  const [bestItemAll, setBestItemAll] = useState([]);
  const [bestLoading, setBestLoading] = useState(false);

  // md's pick cate link
  const [mainCateLinkMd, setMainCateLinkMd] = useState("");
  const [mdItemAll, setMdItemAll] = useState([]);
  const [mdItemAllMo, setMdItemAllMo] = useState([]);
  const [mdLoading, setMdLoading] = useState(false);

  // contents detail click
  const [detailId, setDetailId] = useState(-1);
  const [contentsDetail, setContentsDetail] = useState(false);

  // random list
  const [randomList, setRandomList] = useState([]);

  // recent list
  const [recentProductList, setRecentProductList] = useState([]);

  async function getProductNew() {
    const path = `product/main/new`;
    const res = await api.get(path);
    if (res?.result === 1) {
      setNewList(res.list);
    }
  }

  const getProductBest = (mainCat = 1) => {
    serverController.connectFetchController(`products?prd_main_cat=${mainCat}&prd_hit=1&limit=6`, "GET", null, (res) => {
      if (res?.result === 1) {
        setMainCateLinkBest(res?.categoryLink?.mainCategoryLink ? res?.categoryLink?.mainCategoryLink : "");
        setBestLoading(false);
      }
    });
  };

  const getProductCeleb = () => {
    serverController.connectFetchController(`product/main/contents`, "GET", null, (res) => {
      if (res?.result === 1) {
        setBestItemAll(res.list);
      } else {
        setBestItemAll([]);
      }
    });
  };

  const getProductMdsPick = (mainCat = 1) => {
    serverController.connectFetchController(`products?prd_hit=1&prd_main_cat=${mainCat}&limit=9`, "GET", null, (res) => {
      if (res?.result === 1) {
        const data = res?.list ?? [];
        let result = [];
        let moResult = [];
        for (let i = 0; i < data.length; i += 2) {
          result.push(data.slice(i, i + 2));
        }
        for (let m = 0; m < data.length; m += 3) {
          moResult.push(data.slice(m, m + 3));
        }
        setMdItemAll(result);
        setMdItemAllMo(moResult);
        setMdLoading(false);
        setMainCateLinkMd(res?.categoryLink?.mainCategoryLink ? res?.categoryLink?.mainCategoryLink : "");
      } else {
        setMdItemAll([]);
        setMdItemAllMo([]);
        setMdLoading(false);
      }
    });
  };

  const getRandomProduct = () => {
    try {
      // serverController.connectFetchController(`products?prd_main_cat=${randomCat}&limit=2`, "GET", null, (res) => {
      //   if (res.list.length > 0) {
      //     let randomNum = Math.floor(Math.random() * res.list.length);
      //     setRandomList((prev) => [...prev, res.list[randomNum]]);
      //   }
      // });
      serverController.connectFetchController(`product/main/etc`, "GET", null, (res) => {
        if (res.list.length > 0) {
          setRandomList(res.list);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getProductsRecentlyList = () => {
    serverController.connectFetchController("products/recently", "GET", null, (res) => {
      if (res?.result === 1) {
        setRecentProductList(res?.list.reverse() ?? []);
      }
    });
  };

  const getPopupList = () => {
    serverController.connectFetchController("popup/list", "GET", null, (res) => {
      if (res?.result === 1) {
        const list = res.list;
        const activeList = list.filter((prev) => prev.popupStatus === 1 && prev.popupType === 1);
        setPopupList(activeList ?? []);
        activeList.length !== 0 && setIsMobilePopupClose(true);
      }
    });
  };

  const handleScroll = () => {
    let isScroll;
    if (window.pageYOffset < 1) {
      isScroll = true;
    } else {
      isScroll = false;
    }
    setScrollV(isScroll);
    return;
  };

  useEffect(() => {
    getProductNew();
    getProductBest();
    getProductCeleb();
    getProductsRecentlyList();
    // getProductMdsPick();
    setLoading(true);

    // random product list
    getRandomProduct();
    // for (let f = 0; f < 2; f++) {
    //   for (let i = 0; i <= 9; i++) {
    //     getRandomProduct(i + 1);
    //   }
    // }

    const watch = () => {
      window.addEventListener("scroll", handleScroll);
    };
    watch();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (!window.ReactNativeWebView) return;
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "back",
        value: "in",
      })
    );

    return () => {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "back",
          value: "out",
        })
      );
    };
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("main_pop_one") === "true") {
      setIsMobilePopupClose(false);
    } else {
      getPopupList();
    }
    if (sessionStorage.getItem("returnTo")) history.push(sessionStorage.getItem("returnTo"));
    return () => {
      sessionStorage.removeItem("returnTo");
    };
  }, []);

  useEffect(() => {
    if (!contentsDetail) return;
    const unListen = history.block((loc, action) => {
      if (action === "POP") {
        setContentsDetail(false);
      }
      return false;
    });

    return () => {
      unListen();
    };
  }, [history, contentsDetail]);

  const bestProductItem = {
    getProductBest,
    getProductCeleb,
    setBestLoading,
    bestLoading,
    setLoginPop,
    mainCateLink: mainCateLinkBest,
    list: bestItemAll,
  };

  const bestNewSectionItems = {
    list: newList,
    setLoginPop,
  };

  const StoreSearchRecentListSectionItems = {
    recentProductList,
    setLoginPop,
  };

  const itemSectionItems = {
    randomList,
    setLoginPop,
  };

  const MdProductItem = {
    mdLoading,
    setMdLoading,
    mainCateLinkMd,
    getProductMdsPick,
  };

  return (
    <>
      {loginPop && (
        <MyPageDefaultModal
          text="로그인 후 이용 가능합니다."
          trueText="로그인"
          trueFunc={() => {
            if (location) return history.push(`/store/login?returnTo=${location.pathname}`);
            history.push(`/store/login`);
          }}
          falseFunc={() => setLoginPop(false)}
        />
      )}
      {loading && (
        <Mobile>
          <FullLoading />
        </Mobile>
      )}
      {contentsDetail ? (
        <>
          <ModalHeader back={contentsDetail} setContentsDetail={setContentsDetail} />
          <ContentsDetail contentsId={detailId} setContentsDetail={setContentsDetail} />
        </>
      ) : (
        <>
          {/* popup */}
          {/* {isMobilePopupClose && (
            <MainPopup setIsMobilePopupClose={setIsMobilePopupClose} list={popupList} />
          )} */}
          <Header scrollV={scrollV} main />
          <FixMenu />
          <MainBenner />
          <Mobile>
            <CategorySlideListSection mainCateLink={mainCateLinkBest} setLoading={setLoading} />
            <GrayZone />
          </Mobile>
          <ContentSection setDetailId={setDetailId} setDetailClick={setContentsDetail} />
          {/* PlanShopBnr */}
          {/* <PlanShopBnr /> */}
          <BestItemSection {...bestProductItem} />
          <BestNewSection {...bestNewSectionItems} />
          <Mobile>{recentProductList.length > 0 && <StoreSearchRecentListSection {...StoreSearchRecentListSectionItems} main />}</Mobile>
          <ItemSection {...itemSectionItems} />
          {/* <SubBanner />
          <MDSection list={mdItemAll} moList={mdItemAllMo} {...MdProductItem} />
          <BrandRankSection /> */}

          {/* <CommunitySection/> */}
          <StoreFooter />
        </>
      )}
    </>
  );
}
// common
export const InfoText = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin: 0 auto;
  padding: 100px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (100 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (100 / 428)) 0;
  }
`;
