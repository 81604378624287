import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PC, Mobile } from "../common/mediaStlye";
import { imgURL } from "../../../server/appInfoContoller";
import ReactHtmlParser from "react-html-parser";

import {
  Container,
  LayOut,
  DetailsWrapper,
  DetailMainBanner,
  DetailMainContents,
  DetailMainDesc,
  DescHead,
  DescContent,
  Tag,
  DetailMainGallery,
  MobileTagList,
} from "./ContentDetailsPage.element";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";

SwiperCore.use([Pagination, Navigation]);

const ContentDetailsMain = ({ data, setIsContentModal, setContentId }) => {
  const history = useHistory();
  const [src, setSrc] = useState(data?.contents_main);
  const [list, setList] = useState([]);
  const [isLiked, setIsLiked] = useState(false);

  const onClick = (e) => {
    if (e.target.src) {
      setSrc(e.target.src);
    }
  };

  useEffect(() => {
    setSrc(imgURL + data?.contents_main);
    const sources = data?.contents_thumbnail
      ?.split(",")
      .filter((v) => v !== "");
    // setSrc(Array.isArray(sources) ? imgURL + sources[0] : "");
    const subImgs = data?.contents_sub?.split(",").filter((v) => v !== "");
    setList(subImgs);
    setIsLiked(data?.like_id);
  }, [data]);

  //   const onHandleLike = async () => {
  //     if(!isLogged){return alert('로그인이 필요한 서비스입니다.')}
  //     const isLike = await postLikeItem(data?.contents_id);
  //     console.log(isLike);
  //     setIsLiked(isLike?.result === 1);
  //   };

  return (
    <React.Fragment>
      <PC>
        <Container>
          <LayOuts>
            <DetailsWrapper>
              <DetailMainBanner>
                <div>
                  <img src={src} />
                </div>
                {/* <img src={src} alt="" /> */}
              </DetailMainBanner>

              <DetailMainContents>
                <DetailMainDesc>
                  <DescHead>
                    <p>
                      {data?.contents_date.split(" ")[0].replace(/-/g, ".")}
                    </p>
                    <h2>{data?.contents_title}</h2>
                  </DescHead>
                  <DescContent>
                    <p>{ReactHtmlParser(data?.contents_content)}</p>
                  </DescContent>
                </DetailMainDesc>
              </DetailMainContents>
            </DetailsWrapper>
            <DetailMainGallerys>
              <div>
                <img src={imgURL + data?.contents_main} />
              </div>
              {list?.map((v, i) => {
                return (
                  <div>
                    <img src={imgURL + v} />
                  </div>
                );
              })}
            </DetailMainGallerys>
          </LayOuts>
        </Container>
      </PC>

      <Mobile>
        <Container>
          {data ? (
            <LayOuts>
              <DetailMainContents>
                <DetailMainDesc>
                  <DescHead>
                    <p>{data?.contents_date}</p>
                    <h2>
                      {data?.contents_title}
                      {/* <LikeButton onClick={onHandleLike}>
                      <img src={isLiked ? likedIcon : likeBtnOff} />
                    </LikeButton> */}
                    </h2>
                  </DescHead>
                  <MobileTagList>
                    {data?.contents_teg?.split(",").length > 0 && (
                      <Swiper spaceBetween={10} slidesPerView="auto">
                        {data?.contents_teg?.split(",").map((v) => (
                          <SwiperSlide>
                            <Tag>#{v}</Tag>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    )}
                  </MobileTagList>
                  <DetailMainBanner>
                    <img src={src} alt="" />
                  </DetailMainBanner>
                  <DetailMainGallerys>
                    {list?.map((v, i) => {
                      return (
                        <div>
                          <img src={imgURL + v} alt="detailMainGaallerysImg" />
                        </div>
                      );
                    })}
                  </DetailMainGallerys>
                </DetailMainDesc>
                <DescContent>
                  {ReactHtmlParser(data?.contents_content)}
                </DescContent>
              </DetailMainContents>
            </LayOuts>
          ) : null}
        </Container>
      </Mobile>
    </React.Fragment>
  );
};

const BackBtnBox = styled.div`
  width: 100%;
  padding: 30px 25px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 428)) calc(100vw * (25 / 428)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) calc(100vw * (25 / 428)) 0;
  }
`;
const BackText = styled.p`
  display: flex;
  align-items: center;
  width: 100px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    font-size: calc(100vw * (13 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    font-size: calc(100vw * (13 / 428));
  }
`;
const BackBtn = styled.img`
  display: block;
  width: 12px;
  padding-right: 10px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (12 / 1320));
    padding-right: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (10 / 428));
    padding-right: calc(100vw * (10 / 428));
  }
`;
const DetailMainGallerys = styled(DetailMainGallery)`
  width: 1000px;
  margin: 0 auto;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1000 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
  }
`;
const LayOuts = styled(LayOut)`
  width: 1000px;
  margin: 50px auto 0;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1000 / 1320));
    margin: calc(100vw * (50 / 1320)) auto 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
  }
`;
export default ContentDetailsMain;
