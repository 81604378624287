import { createAction, handleActions } from "redux-actions";
import produce from "immer";

const UPDATE_SETTING_VERSION = "appSettingAction/updateSettingVersion";

export const updateSettingVersion = createAction(UPDATE_SETTING_VERSION);

const initialState = {
  setting: {
    version: "",
  },
};

export default handleActions(
  {
    [UPDATE_SETTING_VERSION]: (state, action) => {
      return produce(state, (draft) => {
        draft.setting.version = action.payload.setting.version;
      });
    },
  },
  initialState
);
