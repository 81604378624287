//react
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Mobile, PC } from "../../MediaQuery";

import openLink from "../../../server/openLink";

//css
import styled from "styled-components";

//img
import IconBrand from "../../img/mo_nav_bottom/icon_mo_bottom_brand.svg";
import IconContents from "../../img/mo_nav_bottom/icon_mo_bottom_contents.svg";
import IconHome from "../../img/mo_nav_bottom/icon_mo_bottom_home.svg";
import IconMyPage from "../../img/mo_nav_bottom/icon_mo_bottom_mypage.svg";
import IconWish from "../../img/mo_nav_bottom/icon_mo_bottom_wish.svg";

import IconBrandB from "../../img/mo_nav_bottom/icon_mo_bottom_brandB.svg";
import IconContentsB from "../../img/mo_nav_bottom/icon_mo_bottom_contentsB.svg";
import IconHomeB from "../../img/mo_nav_bottom/icon_mo_bottom_homeB.svg";
import IconMyPageB from "../../img/mo_nav_bottom/icon_mo_bottom_mypageB.svg";
import IconWishB from "../../img/mo_nav_bottom/icon_mo_bottom_wishB.svg";

export default function FooterMenu({ black, nowTabRef = "store" }) {
  const { isLogged } = useSelector((state) => state.userData);
  const location = useLocation();

  const [hide, setHide] = useState(false);

  // header scroll control
  useEffect(() => {
    // s :: scroll down fix
    let lastScrollY = 0;
    if (nowTabRef === "contents") {
      const infiniteWrap = document.querySelector("#infinite-scroll-wrap");
      if (infiniteWrap) {
        infiniteWrap?.addEventListener("scroll", () => {
          const scrollY = infiniteWrap?.scrollTop;
          const direction = scrollY > lastScrollY ? true : false;

          lastScrollY = scrollY;

          if (lastScrollY > 130) setHide(direction);
        });
      }
    } else {
      window?.addEventListener("scroll", (e) => {
        const scrollY = window?.scrollY;
        const direction = scrollY > lastScrollY ? true : false;

        lastScrollY = scrollY;

        if (lastScrollY > 130) setHide(direction);

        // bottom hide false
        // if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        //   return setHide(false);
        // }
      });
    }
    return () => { setHide(true) }
    // e :: scroll down fix
  }, []);

  return (
    <>
      <Wrap>
        <Container className="footer" black={black} hide={hide}>
          <FooterWrap>
            <Menu to={`/store`}>
              <MenuImg src={nowTabRef === "store" ? IconHomeB : IconHome} alt="store" />
              <MenuText>홈</MenuText>
            </Menu>
            <Menu to={`/store/brand`}>
              <MenuImg src={nowTabRef === "brand" ? IconBrandB : IconBrand} alt="brand" />
              <MenuText>브랜드</MenuText>
            </Menu>
            {isLogged ? (
              <Menu to={`/store/wish?tab=0&page=1`}>
                <MenuImg src={nowTabRef === "wish" ? IconWishB : IconWish} alt="wish" />
                <MenuText>찜</MenuText>
              </Menu>
            ) : (
              <Menu to={`/store/login?returnTo=${location.pathname}`}>
                <MenuImg src={nowTabRef === "wish" ? IconWishB : IconWish} alt="login" />
                <MenuText>찜</MenuText>
              </Menu>
            )}
            <Menu to={`/store/contents/list`}>
              <MenuImg
                src={nowTabRef === "contents" ? IconContentsB : IconContents}
                alt="contents"
              />
              <MenuText>컨텐츠</MenuText>
            </Menu>
            {!isLogged ? (
              <Menu to={`/store/login?returnTo=${location.pathname}`}>
                <MenuImg src={nowTabRef === "myPage" ? IconMyPageB : IconMyPage} alt="login" />
                <MenuText>로그인</MenuText>
              </Menu>
            ) : (
              <Menu to={`/store/mypage`}>
                <MenuImg src={nowTabRef === "myPage" ? IconMyPageB : IconMyPage} alt="myPage" />
                <MenuText>마이페이지</MenuText>
              </Menu>
            )}
          </FooterWrap>
        </Container>
      </Wrap>
    </>
  );
}

// Footer
const Wrap = styled.div`
  width: 100%;
`;
const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: calc(100vw * (1 / 428)) solid #eeeeee;
  z-index: 100;
  transition: ease 0.3s all;
  ${({ hide }) =>
    hide &&
    `
    bottom : calc(100vw * (-80 / 428));
    opacity : 0.5;
  `}
`;
const FooterWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: calc(100vw * (11 / 428)) calc(100vw * (36 / 428)) calc(100vw * (21 / 428));
`;
const Menu = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 20%;
`;
const MenuImg = styled.img`
  width: calc(100vw * (30 / 428));
`;
const MenuText = styled.span`
  padding-top: calc(100vw * (3 / 428));
  font-size: calc(100vw * (12 / 428));
  line-height: calc(100vw * (15 / 428));
`;
