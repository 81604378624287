import React, { useState, useEffect } from "react";
import { PC, Mobile } from "../common/mediaStlye";
import serverController from "../../server/serverController";
import { Container, Layout } from "./stylist.element";
import StylistSponserdHead from "./StylistSponserdHead";
import StylistSponserdList from "./StylistSponserdList";

const StylistMyList = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [list, setList] = useState([]);
  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    setList([]);
    const tabCheck = tabIndex ?? false;
    serverController.connectFetchController(
      `products/like${tabCheck ? "/" + tabIndex : ""}`,
      "GET",
      null,
      (res) => {
        console.log(res, "res");
        if (res.result == 1) {
          setList(res.list);
        } else {
          setIsLoad(false);
        }
      }
    );
  }, [tabIndex]);

  return (
    <>
      <Container>
        <Layout>
          <StylistSponserdHead
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            look
          />
          <StylistSponserdList
            list={list}
            MoreDataClick={false}
            moreOff={false}
            isLoad={isLoad}
            myList={true}
          />
        </Layout>
      </Container>
    </>
  );
};

export default StylistMyList;
