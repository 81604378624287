//react
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";

//css
import styled from "styled-components";
import ProductImgage1 from "../../../img/brand_logo3.png";

// img
import CancleIcon from "../../../img/icon/icon_cancle.png";
import IconFile from "../../../img/icon/icon_file.png";

export default function PasswordModal({
  checkPwd,
  inquiryList,
  inqIndex,
  setCheckPwd,
  setIsModal,
  inqPwd,
  setInqPwd,
}) {
  const history = useHistory();
  return (
    <Container>
      <Background
        onClick={() => {
          setIsModal(false);
        }}
      />
      <Content>
        <TitleCont>
          <Title>
            이글은 비밀글입니다.
            <br />
            비밀번호를 입력해 주세요.
          </Title>
          <Password
            type="password"
            value={inqPwd}
            onChange={(e) => {
              let value = e.target.value;
              setInqPwd(value);
            }}
          />
        </TitleCont>
        <BtnWrap>
          <Btn
            onClick={() => {
              setInqPwd("");
              setIsModal(false);
            }}
          >
            닫기
          </Btn>
          <BtnBlue
            onClick={() => {
              for (let i = 0; i < inquiryList.length; i++) {
                if (inquiryList[i].inq_id === inqIndex) {
                  if (inqPwd === inquiryList[i].inq_password) {
                    setInqPwd("");
                    history.push(`/store/inquiry/detail/${inqIndex}`);
                  } else {
                    setInqPwd("");
                    alert("비밀번호를 확인해주세요.");
                  }
                }
              }
              setIsModal(false);
            }}
          >
            확인
          </BtnBlue>
        </BtnWrap>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;
// 컨텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  border-radius: 11px;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 100;
  padding: 50px;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (500 / 1320));
    border-radius: calc(100vw * (11 / 1320));
    padding: calc(100vw * (550 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (360 / 428));
    border-radius: calc(100vw * (11 / 428));
    padding: calc(100vw * (30 / 428));
  }
`;
const TitleCont = styled.div`
  padding: 25px 25px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (25 / 1320)) calc(100vw * (25 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (25 / 428)) calc(100vw * (25 / 428)) 0;
  }
`;
const Title = styled.h2`
  font-size: 18px;
  font-weight: normal;
  color: #000;
  text-align: center;
  margin-bottom: 30px;
  line-height: 1.3;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    margin-bottom: calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    margin-bottom: calc(100vw * (30 / 428));
  }
`;
const Password = styled.input`
  width: 100%;
  height: 45px;
  font-size: 16px;
  padding: 15px;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  margin-bottom: 50px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (45 / 1320));
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (15 / 1320));
    border: calc(100vw * (1 / 1320)) solid #d4d4d4;
    border-radius: calc(100vw * (10 / 1320));
    margin-bottom: calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (45 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (15 / 428));
    border: calc(100vw * (1 / 428)) solid #d4d4d4;
    border-radius: calc(100vw * (10 / 428));
    margin-bottom: calc(100vw * (50 / 428));
  }
`;
const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Btn = styled.p`
  width: 100px;
  cursor: pointer;
  padding: 12px 30px;
  text-align: center;
  font-size: 16px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #20232a;
  margin: 0 10px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (12 / 1320)) calc(100vw * (30 / 1320));
    font-size: calc(100vw * (16 / 1320));
    background: #fff;
    border-radius: calc(100vw * (5 / 1320));
    border: calc(100vw * (1 / 1320)) solid #20232a;
    margin: 0 calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (12 / 428)) calc(100vw * (30 / 428));
    font-size: calc(100vw * (16 / 428));
    background: #fff;
    border-radius: calc(100vw * (5 / 428));
    border: calc(100vw * (1 / 428)) solid #20232a;
    margin: 0 calc(100vw * (10 / 428));
  }
`;
const BtnBlue = styled(Btn)`
  background: #525252;
  border: 1px solid #525252;
  color: #fff;

  @media screen and (max-width: 1320px) {
    border: calc(100vw * (1 / 1320)) solid #525252;
  }

  @media screen and (max-width: 930px) {
    border: calc(100vw * (1 / 428)) solid #525252;
  }
`;
