import React, { useEffect, useState } from 'react';
import faker from 'faker';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getCategoryName, getThumbnail } from '../common/commonUse';
import serverController from '../../server/serverController';
import { useParams, useHistory } from 'react-router-dom';
import { Mobile, PC } from '../common/mediaStlye';

import {
    ResultLayout,
    ResultHeadings,
    WalaItemList,
    Item,
    ItemImg,
    ItemDesc,
    MoreButton
} from './SearchResultPage.elements';

import searchImg1 from '../../images/search/search-1.png';
import searchImg2 from '../../images/search/search-2.png';
import searchImg3 from '../../images/search/search-3.png';
import searchImg4 from '../../images/search/search-4.png';
import searchImg5 from '../../images/search/search-5.png';
import searchImg6 from '../../images/search/search-6.png';
import searchImg7 from '../../images/search/search-7.png';
import searchImg8 from '../../images/search/search-8.png';

const ResultsWala = ({ setIsContentModal, setContentId }) => {
    const history = useHistory()
    const { keyword } = useParams();
    const [list, setList] = useState([]);
    const [offset, setOffset] = useState(8);

    const getData = async () => {
        await serverController.connectFetchController(`contentlist?keyword=${keyword}&page=0`, "GET", null, (res) => {
            console.log(res, "검색 왈라컨텐츠 res");
            if (res?.result === 1) {
                setList(res?.list ?? []);
            }
        }, (err) => { console.log(err); }
        );
    };

    useEffect(() => {
        getData()
    }, [keyword])

    const onHandleClick = () => {
        setOffset(prev => prev + 8);
    }

    return (
        <>
            <ResultLayout>
                <ResultHeadings>
                    <h2>"{keyword}" WALA Contents</h2>
                </ResultHeadings>
                <WalaItemList>
                    <PC>
                        {
                            list.length > 0 ? (
                                list?.slice(0, offset).map((v, i) => {
                                    return (
                                        <Item onClick={() => history.push(`/content/${v?.contents_id}`)}>
                                            <ItemImg>
                                                <img src={getThumbnail(v?.contents_thumbnail)} />
                                            </ItemImg>
                                            <ItemDesc className="item_head">
                                                <em>{getCategoryName(v?.c_cat_id)}</em>
                                                <h4>{v?.contents_title}</h4>
                                                <p>{v?.contents_intro}</p>
                                            </ItemDesc>
                                        </Item>
                                    )
                                })
                            ) : (
                                <NoText>검색 결과가 없습니다.</NoText>
                            )
                        }
                    </PC>
                    <Mobile>
                        {
                            list.length > 0 ? (
                                list?.slice(0, offset).map((v, i) => {
                                    return (
                                        <Item onClick={() => { setIsContentModal(true); setContentId(v?.contents_id); }}>
                                            <ItemImg>
                                                <img src={getThumbnail(v?.contents_thumbnail)} />
                                            </ItemImg>
                                            <ItemDesc className="item_head">
                                                <em>{getCategoryName(v?.c_cat_id)}</em>
                                                <h4>{v?.contents_title}</h4>
                                                <p>{v?.contents_intro}</p>
                                            </ItemDesc>
                                        </Item>
                                    )
                                })
                            ) : (
                                <NoText>검색 결과가 없습니다.</NoText>
                            )
                        }
                    </Mobile>
                </WalaItemList>

                {
                    offset < list?.length && <MoreButton onClick={onHandleClick}>See More</MoreButton>
                }
            </ResultLayout>
        </>
    )
}

const NoText = styled.div`
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    color: #aaa;
    text-align: center;
    padding-top: 50px;

    @media screen and (max-width:1320px){
        font-size: calc(100vw * (15 / 1320));
        padding-top: calc(100vw * (50 / 1320));
    }
    @media screen and (max-width:930px){
        font-size: calc(100vw * (15 / 428));
        padding-top: calc(100vw * (50 / 428));
    }
`;

export default ResultsWala
