import React from 'react';
import ResultsWala from './ResultsWala';
import ResultsBlog from './ResultsBlog';
import ResultsFashionNews from './ResultsFashionNews';
import { ResultContainer } from './SearchResultPage.elements';
import ResultsProduct from './ResultsProduct';

const SearchResults = ({ setIsContentModal, setContentId }) => {
    return (
        <ResultContainer>
            <ResultsProduct />
            <ResultsWala setIsContentModal={setIsContentModal} setContentId={setContentId} />
            <ResultsBlog />
            {/* <ResultsFashionNews /> */}
        </ResultContainer>
    )
}

export default SearchResults
