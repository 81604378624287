import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PC, Mobile } from "../../../MediaQuery";
import { useSelector } from "react-redux";
import { UserDataAction } from "../../../store/actionCreators";

import {
  HeaderContainer,
  HeaderContents,
  HeaderTopLeft,
  Logo,
  HeaderTopRight,
  PageBtn,
  MenuImg,
  IconSearch,
} from "./Header.elements";
import HeaderCategoryModal from "./HeaderCategoryModal";
import MiniHeaderCategoryModal from "./MiniHeaderCategoryModal";
import HeaderSearchModal from "./HeaderSearchModal";

import walaLogo from "../../../images/wala-logo.png";
import walaStore from "../../../images/header/wala-store.svg";
import menuBar from "../../../images/menu-bar.svg";
import WalaStoreModal from "../../../images/wala-store-modal.svg";
import menuBarModal from "../../../images/menu-bar-modal.png";
import heartIcon from "../../../images/header/heart-icon.svg";
import accountIcon from "../../../images/header/account-icon.svg";
import brandIcon from "../../../images/header/brand-icon.svg";
import stylistIcon from "../../../images/header/stylist-icon.svg";
import logoutIcon from "../../../images/logout-store.svg";
import SearchBlack from "../../../images/search_icon_black.svg";

const HeaderTop = ({ modal }) => {
  const { isLogged, isNormal, isBrand, isStyle, userInfo } = useSelector(
    (state) => state.userData
  );
  const history = useHistory();
  const [isMenu, setIsMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onHandleLogout = () => {
    alert("로그아웃되셨습니다.");
    history.push("/");
    UserDataAction.logOutRequest();
  };

  return (
    <>
      <PC>
        <HeaderContainer modal={modal}>
          <HeaderContents>
            <HeaderTopLeft>
              {modal ? (
                <>
                  <div onClick={() => setIsMenu(true)}>
                    <img src={menuBarModal} alt="메뉴바" />
                  </div>
                  {/* <div style={{cursor: 'pointer'}} onClick={() => history.push('/store')}><img src={WalaStoreModal} alt="WALA-Store"/></div> */}
                </>
              ) : (
                <>
                  <div onClick={() => setIsMenu(true)}>
                    <img src={menuBar} alt="메뉴바" />
                  </div>
                  {/* <div style={{cursor: 'pointer'}} onClick={() => history.push('/store')}><img src={walaStore} alt="WALA-Store" /></div> */}
                </>
              )}
            </HeaderTopLeft>

            <HeaderTopRight>
              {!isLogged ? (
                <>
                  <PageBtn onClick={() => history.push("/login")} modal={modal}>
                    <span className="is-logged-out">sign in</span>
                  </PageBtn>
                  <PageBtn
                    onClick={() => history.push("/signup")}
                    modal={modal}
                  >
                    <span className="is-logged-out">sign up</span>
                  </PageBtn>
                  <IconSearch
                    src={SearchBlack}
                    alt="검색"
                    onClick={() => setIsOpen(true)}
                  />
                </>
              ) : (
                <>
                  <IconSearch
                    src={SearchBlack}
                    alt="검색"
                    onClick={() => setIsOpen(true)}
                  />
                </>
              )}
              {
                // userInfo.mem_role === "USER" && (
                //     <>
                //     <PageBtn onClick={() => history.push('/mypage')} modal={modal}>
                //         <img src={accountIcon} alt="" />
                //         <span>MY PAGE</span>
                //     </PageBtn>
                //     </>
                // )
              }
              {
                // userInfo.mem_role === "BRAND" && (
                //     <>
                //     <PageBtn onClick={() => {
                //         window.location.href = 'http://3.36.5.45:8073/login';
                //     }} modal={modal}>
                //         <img src={brandIcon} alt="" />
                //         <span>BRAND 관리자페이지</span>
                //     </PageBtn>
                //     {/* <PageBtn onClick={onHandleLogout} modal={modal}>
                //         <img src={logoutIcon} alt="" />
                //         <span>로그아웃</span>
                //     </PageBtn> */}
                //     {/*
                //     <PageBtn onClick={() => history.push('/management')} modal={modal}>
                //         <img src={accountIcon} alt="" />
                //         <span>MY PAGE</span>
                //     </PageBtn> */}
                //     </>
                // )
              }
              {
                // userInfo.mem_role === "STYLELIST" && (
                //     <>
                //     <PageBtn onClick={() => history.push('/stylist/sponserd')} modal={modal}>
                //         <img src={stylistIcon} alt="" />
                //         <span>STYLIST</span>
                //     </PageBtn>
                //     <PageBtn onClick={() => history.push('/stylist/liked')} modal={modal}>
                //         <img src={heartIcon} alt="" />
                //         <span>MY LIST</span>
                //     </PageBtn>
                //     <PageBtn onClick={() => history.push('/stylist/mypage')} modal={modal}>
                //         <img src={accountIcon} alt="" />
                //         <span>MY PAGE</span>
                //     </PageBtn>
                //     </>
                // )
              }
            </HeaderTopRight>
          </HeaderContents>
        </HeaderContainer>
      </PC>

      <Mobile>
        <MenuImg onClick={() => setIsMenu(true)}>
          <img src={menuBar} alt="메뉴바" />
        </MenuImg>

        <IconSearch
          src={SearchBlack}
          alt="검색"
          onClick={() => setIsOpen(true)}
        />
      </Mobile>
      {/* { isMenu && <HeaderCategoryModal setIsMenu={setIsMenu}/> } */}
      {isMenu && <MiniHeaderCategoryModal setIsMenu={setIsMenu} />}
      {isOpen && <HeaderSearchModal setIsOpen={setIsOpen} />}
    </>
  );
};

export default HeaderTop;
