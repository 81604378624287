//react
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";

//css
import styled from "styled-components";

// component
import DeleteModal from "../../common/modal/DeleteModal";
import AdressEditModal from "../../common/modal/AdressEditModal";
import AdressPatchModal from "../../common/modal/AdressPatchModal";

// img
import IconCheckbox from "../../../img/icon/icon_checkbox.png";
import RefreshIcon from "../../../img/icon/icon_refresh.png";
import SpeakIcon from "../../../img/icon/icon_speak.png";

// server
import serverController from "../../../server/serverController";

export default function MyAdress({ setLoading }) {
  const body = document.querySelector("body");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [adressList, setAdressList] = useState([]);
  const [deleteValue, setDeleteValue] = useState({ id: null, index: null });
  const [current, setCurrent] = useState({});

  const getReceivers = () => {
    serverController.connectFetchController(`receivers`, "GET", null, (res) => {
      if (res?.result === 1) {
        setAdressList(res?.list);
      }
      var timeOut = setTimeout(() => {
        setLoading(false);
        clearTimeout(timeOut);
      }, 300);
    });
  };

  // 배송지 삭제
  const deleteReceiver = () => {
    serverController.connectFetchController(
      `receiver/${deleteValue.id}`,
      "DELETE",
      null,
      (res) => {
        if (res?.result === 1) {
          setAdressList((prev) =>
            prev
              .map((v, i) => {
                return i === deleteValue.index ? null : v;
              })
              .filter((v) => v !== null)
          );
        }
      }
    );
  };

  const setMainReceiver = (value) => {
    serverController.connectFetchController(
      `receiver/main/${value?.receiver_id}`,
      "PUT",
      null,
      (res) => {
        if (res?.result === 1) {
          getReceivers();
        }
      }
    );
  };

  const cancelReceiver = (value) => {
    serverController.connectFetchController(
      `receiver/main`,
      "PUT",
      null,
      (res) => {
        if (res?.result === 0) {
          getReceivers();
        }
      }
    );
  };

  useEffect(() => {
    setLoading(true);
    getReceivers();
  }, [isModal, isEditModal]);

  // modal control
  useEffect(() => {
    if (isEditModal) {
      body.style.overflow = "hidden";
      body.style.height = "100vh";
    } else {
      body.style.overflow = "auto";
      body.style.height = "auto";
    }
  }, [isEditModal]);

  useEffect(() => {
    if (isModal) {
      body.style.overflow = "hidden";
      body.style.height = "100vh";
    } else {
      body.style.overflow = "auto";
      body.style.height = "auto";
    }
  }, [isModal]);

  useEffect(() => {
    if (isEditModal) {
      body.style.overflow = "hidden";
      body.style.height = "100vh";
    } else {
      body.style.overflow = "auto";
      body.style.height = "auto";
    }
  }, [isEditModal]);

  return (
    <Container>
      {isDeleteModal && (
        <DeleteModal
          title={"삭제하시겠습니까?"}
          setIsModal={setIsDeleteModal}
          deleteReceiver={deleteReceiver}
        />
      )}
      {isModal && (
        <AdressEditModal title={"배송지 추가"} setIsModal={setIsModal} />
      )}
      {isEditModal && (
        <AdressPatchModal
          title={"배송지 수정"}
          setIsModal={setIsEditModal}
          current={current}
        />
      )}

      <AdressBox>
        <AddBtnWrap>
          <AddBtn
            type="button"
            onClick={() => {
              setIsModal(true);
            }}
          >
            + 신규 배송지 추가
          </AddBtn>
        </AddBtnWrap>
        <Content>
          <AdressListWrap>
            {adressList.map((value, index) => {
              return (
                <AdressList>
                  <div>
                    <AdrTitleWrap>
                      <AdrTitle>{value.receiver_title} </AdrTitle>
                      {value.main_receiver == 1 ? (
                        <Default>기본배송지</Default>
                      ) : null}
                    </AdrTitleWrap>
                    <Adradress>
                      [{value.postal_code}] {value.receiver_address}{" "}
                      {value.receiver_address_detail}
                    </Adradress>
                    <AdrName>
                      {value.receiver_username} {value.receiver_phone}
                    </AdrName>
                  </div>
                  <AdressBtnWrap>
                    <AdressDeleteBtn
                      onClick={() => {
                        setIsDeleteModal(true);
                        setDeleteValue({
                          id: value.receiver_id,
                          index: index,
                        });
                      }}
                    >
                      삭제
                    </AdressDeleteBtn>
                    <AdressModifyBtn
                      onClick={() => {
                        setIsEditModal(true);
                        setCurrent(value);
                      }}
                    >
                      수정
                    </AdressModifyBtn>
                  </AdressBtnWrap>
                </AdressList>
              );
            })}
          </AdressListWrap>
        </Content>
      </AdressBox>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  position: relative;

  @media screen and (max-width: 930px) {
    min-height: calc(100vw * (500 / 428));
  }
`;
const AdressBox = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media screen and (max-width: 930px) {
    flex-direction: column;
  }
`;
const Content = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (90 / 428));
  }
`;

const AddBtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 20px 0 0;
  background-color: #fff;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0 0;
  }
  @media screen and (max-width: 930px) {
    position: fixed;
    top: calc(100vw * (55 / 428));
    left: 0;
    z-index: 9;
    display: block;
    padding: calc(100vw * (20 / 428)) 0;
    border-bottom: 8px solid #f9f9f9;
  }
`;
const AddBtn = styled.button`
  display: block;
  width: 180px;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (180 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
    margin: 0 auto;
  }
`;

//Adress list
const AdressListWrap = styled.div`
  width: 100%;
  border-top: 1px solid #eee;

  @media screen and (max-width: 930px) {
    border-top: 0;
  }
`;
const AdressList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 8px solid #f9f9f9;
  padding: 30px 20px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    display: block;
    padding: calc(100vw * (30 / 428)) calc(100vw * (25 / 428));
  }
`;
const AdrTitleWrap = styled.p`
  display: flex;
  align-items: center;
`;
const AdrTitle = styled.span`
  max-width: calc(100% - 92px);
  color: #333;
  font-weight: 600;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    max-width: calc(100% - calc(100vw * (92 / 1320)));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    max-width: calc(100% - calc(100vw * (92 / 428)));
    font-size: calc(100vw * (16 / 428));
  }
`;
const Default = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82px;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  background-color: #fdf251;
  border-radius: 14px;
  padding: 5px 0;
  margin-left: 10px;
  flex-shrink: 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (82 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (5 / 1320)) 0;
    margin-left: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (82 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (5 / 428)) 0;
    margin-left: calc(100vw * (10 / 428));
  }
`;
const Adradress = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 15px;
  margin-top: 8px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (12 / 428));
  }
`;
const AdrName = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 15px;
  margin-top: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (6 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (6 / 428));
  }
`;
const AdressBtnWrap = styled.div`
  display: flex;
  & button:not(:last-child) {
    margin-right: 10px;
  }
  @media screen and (max-width: 1320px) {
    & button:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));

    & button:not(:last-child) {
      margin-right: calc(100vw * (10 / 428));
    }
  }
`;
const AdressDeleteBtn = styled.button`
  width: 90px;
  height: 35px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  background-color: #000;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (90 / 1320));
    height: calc(100vw * (35 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (90 / 428));
    height: calc(100vw * (35 / 428));
    font-size: calc(100vw * (16 / 428));
  }
`;
const AdressModifyBtn = styled(AdressDeleteBtn)`
  color: #333;
  background-color: #fff;
`;
