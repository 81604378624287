import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useHistory,} from 'react-router-dom';
import { PC, Mobile } from '../common/mediaStlye';
import styled from 'styled-components';
import api from '../../server/serverController';

import {
    Container,
    Layout,    
    StyleSubHead,
    StyleBackButton,
    StyleRegistWrap,
    StyleRegistForm,
    StyleRegistField,
    StyleFieldName,
    StyleFieldInput,    
    StyleRegistTextHead,
    StyleFieldTextarea,
    StyleRegistSelect,
    StyleRegistOption,
    StyleFieldButton,
    StyleRegistTips,
    StyleRegistButtons,
    StyleRegistButton
} from './stylist.element';

import icon from "../../img/brand/benner_icon.svg";
import arrow from "../../img/brand/arrow.png";
import notice from "../../img/brand/notice.png";
import close from "../../img/brand/close.png";
import calendar from "../../img/brand/calendar.png";

const StylistRegister = () => {
    const history = useHistory();
    const FormRef = useRef()
    const [mainContents, setMainContents] = useState([])
    const [subContents, setSubContents] = useState([])
    const [thumbContents, setThumbContents] = useState([])
    
    console.log(mainContents)
    console.log(mainContents.length > 0 ? mainContents[0].name : "업로드전")

    const contentTextRegistData = [{
        title:"Title",
        formObj: {
            name: "contents_title",
            placeholder: "제품명을 입력해주세요.",
            required: true
        }
    },{
        title:"해시태그",
        formObj: {
            name: "contents_tag",
            placeholder: "해시태그를 입력해주세요.",
            required: false
        }      
    },{
        title:"설명",
        formObj: {
            name: "contents_content",
            placeholder: "설명을 입력해주세요.",
            required: true
        },
        isTextarea: true        
    }]

    const [categorys, setCategorys] = useState([])
    const getCategorys = async () => {
        const res = await api.get(`contentsCategory`);
        console.log(res,"카테고리 목록")
        if (res?.result === 1) {
            setCategorys(res?.list ?? []);
        }
    }

    useEffect(() => {
        getCategorys()
    }, [])

    const onHandleGoback = () => history.goBack();    

    const onHandleSubs = (e,type) => {
        const {
            target: { files }
        } = e;
        if(type == 'main'){
            setMainContents(prev => {
                if (files[0]) {
                    return prev.concat(files[0])
                } else {
                    return prev;
                }
            });
        }
        if(type == 'sub'){
            setSubContents(prev => {
                if (files[0]) {
                    return prev.concat(files[0])
                } else {
                    return prev;
                }
            });
        }
        if(type == 'thumb'){
            setThumbContents(prev => {
                if (files[0]) {
                    return prev.concat(files[0])
                } else {
                    return prev;
                }
            });
        }
    }

    const onHandleSubmit = e => {
        e.preventDefault();
        const formData = new FormData(e.target);        
        mainContents.forEach((el) => {
            formData.append("main_img", el)
        })        
        subContents.forEach((el) => {
            formData.append("sub_img", el)
        })        
        thumbContents.forEach((el) => {
            formData.append("thumbnail_img", el)
        })        

        formData.append("contents_active", active);
        if (active === 3) {
            formData.append("open_date", openDate);
        }
        console.log("===============")
        for (let key of formData.entries()) {
            console.log(key[0], key[1]);          
        }                                
        console.log("===============")        
        // return;
        registContent(formData)
    }


    const isClick = useRef(false);
    const registContent = async (data) => {
        if (isClick.current) return;
        // return;
        await api.post(`contents`, data,                      
            (res) => {
                console.log(res,"컨텐츠 등록res");     
                isClick.current = false;
                if (res?.result === 1) {
                    console.log({
                        pathname: `/stylist/regist/success/${active ? active - 1 : 0}`,
                        state : {
                            date: openDate,
                            id: res?.contents_id
                        }
                    })
                    history.push({
                        pathname: `/stylist/regist/success/${active ? active - 1 : 0}`,
                        state : {
                            date: openDate,
                            id: res?.contents_id
                        }
                    });
                }
            },
            (err) => {
                isClick.current = false;
            }                 
        );
        
    }

    const [openDate, setOpenDate] = useState("");
    const [active, setActive] = useState(null);
    const [isOpenDate, setIsOpenDate] = useState(false)
    const [startDate, setStartDate] = useState("")

    const handleDate = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const current = new Date();
        const today = new Date(`${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`)
        const typedDate = new Date(value)
        if (today >= typedDate) {
            alert("금일 제외한 날로 예약가능합니다.")         
            setStartDate("");       
            return 
        } else {
            setStartDate(value);       
        }
    }

    const handleOpenDate = e => {
        const value = e.target.value;
        switch(value) { //. 공개\n2. 비공개\n3. 오픈 예정\n4. 임시저장
            case "공개": 
                setActive(1)
                return;
            case "비공개": 
                setActive(2)
                return;
            case "오픈 예정": 
                setActive(3);
                const contents_date = new Date();
                const year = contents_date.getFullYear();
                const month = contents_date.getMonth() + 1;
                const day = contents_date.getDate() + 1;
                const hour = contents_date.getHours();
                const min = contents_date.getMinutes();
                const new_date = `${year + 1}-${month >= 10 ? month : "0" + month}-${day >= 10 ? day : "0" + day} ${hour >= 10 ? hour : "0" + hour}:${min >= 10 ? min : "0" + min}`                
                setOpenDate(new_date)   
                return;
            default: 
                setActive(null)
        }             
    }

    useEffect(() => {
        setIsOpenDate(active === 3);
        if (active !== 1) setStartDate("")
    }, [active])



    return (
        <Container>
            <Layout sub>
                <PC>
                <StyleSubHead sub>
                        <StyleBackButton onClick={onHandleGoback}>
                            <img src={arrow} alt="arrow"/>
                        </StyleBackButton>
                        <h2>콘텐츠 등록</h2>
                </StyleSubHead>
                </PC>

                <StyleRegistWrap>
                    <StyleRegistForm onSubmit={onHandleSubmit} ref={FormRef} enctype="multipart/form-data">
                        {
                            contentTextRegistData.map((v, i) => {
                                return (
                                    <StyleRegistField htmlFor={v?.formObj?.name} column={v?.isTextarea}>
                                        {
                                            v.isTextarea 
                                            ? (
                                                <>
                                                <StyleRegistTextHead>{v?.title}</StyleRegistTextHead>
                                                <StyleFieldTextarea id={v?.formObj?.name} {...v?.formObj}/>
                                                </>
                                            ) : (
                                                <>
                                                <StyleFieldNames>{v?.title}</StyleFieldNames>
                                                <StyleFieldInputs id={v?.formObj?.name} {...v?.formObj}/>
                                                </>
                                            )
                                        }
                                    </StyleRegistField>
                                )
                            })
                        }                            

                            <StyleRegistField column>
                                <StyleRegistTextHead>카테고리</StyleRegistTextHead>
                                <StyleRegistSelect required name="c_cat_id">
                                    <option value="" hidden style={{color: "#d9d9d9"}}>카테고리 선택</option>
                                    {
                                        categorys.map((v, i) => {
                                            return <StyleRegistOption value={v?.c_cat_id}>{v?.c_cat_name}</StyleRegistOption>        
                                        })
                                    }                                   
                                </StyleRegistSelect>
                            </StyleRegistField>

                            <StyleRegistField column>
                                <StyleRegistTextHead>상태</StyleRegistTextHead>
                                <StyleRegistSelect onChange={handleOpenDate} required>
                                    <option value="" hidden style={{color: "#d9d9d9"}}>공개 / 비공개 / 오픈 예정</option>
                                    <StyleRegistOption value="공개">공개</StyleRegistOption>
                                    <StyleRegistOption value="비공개">비공개</StyleRegistOption>
                                    <StyleRegistOption value="오픈 예정">오픈 예정</StyleRegistOption>
                                </StyleRegistSelect>
                            </StyleRegistField>

                            { isOpenDate && (
                                <>
                                <StyleRegistField htmlFor="open_date">
                                    <StyleFieldNames>공개일</StyleFieldNames>
                                    <StyleFieldInputs type="date" value={startDate} id="open_date" name="start_date" onChange={handleDate} required />
                                </StyleRegistField>
                                </>
                            )}

                            <StyleRegistField htmlFor="contents_main">
                                <StyleFieldNames>이미지 (메인)</StyleFieldNames>
                                <StyleFieldInputs onChange={(e)=>{onHandleSubs(e,'main')}} id="contents_main" type="file" name="main_img" accept="image/*" placeholder="1장을 업로드 해주세요." required/>
                                <FileName>{mainContents.length > 0 ? mainContents[0].name : "파일을 업로드해주세요."}</FileName> 
                                <StyleFieldButtons htmlFor="contents_main">업로드</StyleFieldButtons>                               
                            </StyleRegistField>
                            
                            <StyleRegistField htmlFor="contents_sub">
                                <StyleFieldNames>이미지 (서브)</StyleFieldNames>
                                <StyleFieldInputs onChange={(e)=>{onHandleSubs(e,'sub')}} id="contents_sub" type="file" accept="image/*" placeholder="최대 4장을 업로드 해주세요."required />
                                <FileName>{subContents.length > 0 ? subContents[0].name : "파일을 업로드해주세요."}</FileName> 
                                <StyleFieldButtons htmlFor="contents_sub">업로드</StyleFieldButtons>
                            </StyleRegistField>

                            <StyleRegistField htmlFor="cont_prd_title">
                                <StyleFieldNames>제품 타이틀</StyleFieldNames>
                                <StyleFieldInputs id="cont_prd_title" type="text" placeholder="타이틀을 입력해주세요."/>
                            </StyleRegistField>

                            <StyleRegistField htmlFor="contents_link" name="contents_link">
                                <StyleFieldNames>링크</StyleFieldNames>
                                <StyleFieldInputs name="contents_link" id="contents_link" type="text" placeholder="url을 입력해주세요."/>
                                {/* <PC>
                                <StyleFieldButton>제품 링크 추가</StyleFieldButton>
                                </PC> */}
                            </StyleRegistField>
                            {/* <Mobile>
                            <StyleFieldButton>제품 링크 추가</StyleFieldButton>
                            </Mobile> */}

                            <StyleRegistField htmlFor="contents_thumbnail">
                                <StyleFieldNames>썸네일</StyleFieldNames>
                                <StyleFieldInputs onChange={(e)=>{onHandleSubs(e,'thumb')}} id="contents_thumbnail" name="thumbnail_img" type="file" accept="image/*" required/>
                                <FileName>{thumbContents.length > 0 ? thumbContents[0].name : "파일을 업로드해주세요."}</FileName> 
                                <StyleFieldButtons htmlFor="contents_thumbnail">업로드</StyleFieldButtons>
                            </StyleRegistField>
                    

                    <StyleRegistTips>
                        <p className="tips-save">
                            *임시저장을 하시면 등록 완료 여부와 상관없이 현 상태에서<br/>
                            등록 정보를 임시로 저장 후 원하실 때 수정 및 공개 하실 수 있습니다.
                        </p>
                        <p>
                        *”공개”로 설정 시 즉시 공개가 진행됩니다. <br/>
                        *”비공개”로 설정 시 노출되지 않으며, 상태 변경에 따라 공개가 진행됩니다.<br/>
                        *”오픈 예정”으로 설정 시 노출되지만, 설정하신 날짜까지 결제<br/>
                        또는 협찬 및 대여 신청 버튼이 비활성화 상태로 노출 됩니다.<br/>
                        </p>                        
                    </StyleRegistTips>

                    <StyleRegistButtons>
                        <StyleRegistButton>임시 저장</StyleRegistButton>
                        <StyleRegistButton as="button" type="submit" black>저장</StyleRegistButton>
                    </StyleRegistButtons>
                    </StyleRegistForm>
                </StyleRegistWrap>
                </Layout>
        </Container>
    )
}

const FileName = styled.span`
    position: absolute;
    left: calc(100vw * (162 / 1920));
    top: 50%;
    transform: translateY(-50%);
    color: #A5A5A5;
    font-size: calc(100vw * (16/ 1920));

    @media screen and (max-width: 1024px) {
        display: flex;
        align-items: center;
        left: calc(100vw * (110 / 428)); top: 0;
        height: 100%;
        transform: translateY(0);
        font-size: calc(100vw * (13/ 428));
    }
`
const StyleFieldNames = styled(StyleFieldName)`
    @media screen and (max-width: 1024px) {
        display: flex;
        align-items: center;
        width: calc(100vw * (110/ 428)); height: 100%;
    }
`

const StyleFieldInputs = styled(StyleFieldInput)`
    ::placeholder {
        font-size: calc(100vw * (16/ 1920));
    }
    @media screen and (max-width: 1024px) {
        padding: 0;
        ::placeholder {
            font-size: calc(100vw * (13/ 428));
        }
    }
`
const StyleFieldButtons = styled(StyleFieldButton)`
    @media screen and (max-width: 1024px) {
        position: absolute; top: 0;
        width: calc(100vw * (80/ 428)); height: calc(100vw * (30/ 428));
        font-size: calc(100vw * (13/ 428));
    }
`
export default StylistRegister;

