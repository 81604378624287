import React from 'react';
import {
    ModalContainer,
    MyPageModalContents,
    MyPageModalText,    
    MyPageModalButton
} from './pop.elements';

const MyPageModal = ({ IsModifyPop, setIsModifyPop }) => {
    const closeModal = () => {
        if (setIsModifyPop) setIsModifyPop(false)
    }

    return (
        <ModalContainer>
            <MyPageModalContents>
                <MyPageModalText>
                    <p>회원정보가 수정되었습니다.</p>
                    <MyPageModalButton onClick={closeModal}>확인</MyPageModalButton>
                </MyPageModalText>
            </MyPageModalContents>
        </ModalContainer>
    )
}

export default MyPageModal
