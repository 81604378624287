import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import SubTitleSection from "../../component/common/SubTitleSection";
import BestProduct from "../../component/product/BestProduct";
import AllProduct from "../../component/product/AllProduct";
import Footer from "../../component/common/StoreFooter";
import FooterMenu from "../../component/common/FooterMenu";
import FixMenu from "../../component/common/FixMenu";

export default function ProductPage() {
  return (
    <>
      <PC>
        <Header />
        <SubTitleSection
          title="PRODUCT"
          subText="WALA 스토어의 다양한 제품들을 확인해 보세요."
        />
        <BestProduct />
        <AllProduct />
        <Footer />
        <FixMenu />
      </PC>

      <Mobile>
        <Header />
        <SubTitleSection
          title="PRODUCT"
          subText="WALA 스토어의 다양한 제품들을 확인해 보세요."
        />
        <BestProduct />
        <AllProduct />
        <FooterMenu />
        <FixMenu />
      </Mobile>
    </>
  );
}
