import styled from "styled-components";
import {
  mquery,
  mobil,
  MQ,
  Media,
  css,
  pc,
  Px,
  Width,
  Height,
  Top,
  Bottom,
  Left,
  Right,
  FontSize,
  Margin,
  Margin2,
  Padding,
  Padding2,
  MT,
  MB,
  ML,
  MR,
  PT,
  PB,
  PL,
  PR,
  LineH,
} from "../common/mediaStlye";

export const Container = styled.div`
  ${PT(100, pc)}
  /* ${PB(300, pc)} */
    width:100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${mquery}px) {
    min-height: calc(100vh - calc(100vw * (318 / 428)));
    ${PT(70, mobil)}
  }
`;

export const Layout = styled.div`
  ${Width(648, pc)}

  @media screen and (max-width: ${mquery}px) {
    width: 100%;
    ${Padding2(50, 25, mobil)}
  }
`;

export const PrimaryButton = styled.button`
  ${[Width(487, pc), Height(61, pc), MB(20, pc), FontSize(20, pc)].join("")}
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #222;
`;

export const AuthIntroMessage = styled.div`
  text-align: center;

  & h2 {
    ${[FontSize(36, pc), PB(20, pc)].join("")}
    font-weight: 900;
    text-transform: uppercase;
  }

  & p {
    ${FontSize(15, pc)}
    word-break: keep-all;
  }

  @media screen and (max-width: ${mquery}px) {
    & h2 {
      ${[FontSize(25, mobil), PB(15, mobil), PT(60, mobil)].join("")}
    }

    & p {
      ${Padding2(0, 72, mobil)}
      ${FontSize(16, mobil)}
    }
  }
`;

export const Form = styled.div`
  ${[MT(110, pc), PB(74, pc)].join("")}
  /* border-bottom: 1px solid #A5A5A5; */

    @media screen and (max-width: ${mquery}px) {
    ${MT(50, mobil)}
  }
`;

export const FieldBox = styled.div`
  position: relative;
  width: 100%;

  & input {
    ${PR(200, pc)}
  }

  ${MQ(mquery)} {
    ${MB(28, mobil)}
    & input {
      ${PR(0, mobil)}
    }
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #404040;
  ${MB(34, pc)}

  @media screen and (max-width: ${mquery}px) {
    ${MB(24, mobil)}
  }
  @media ${(props) => props.theme.container} {
    height: calc(100vw * (40 / 428));
    ${MB(8, mobil)}
  }
`;

export const LabelName = styled.span`
  display: block;
  font-size: calc(100vw * (18 / 1920));
  ${[Width(158, pc), PL(13, pc)].join("")}
  line-height: ${Px(41, pc)};

  ${(props) =>
    props.readOnly &&
    css`
      color: #a5a5a5;
    `}

  @media screen and (max-width: ${mquery}px) {
    display: flex;
    align-items: center;
    font-size: calc(100vw * (15 / 428));
    ${PL(11, mobil)}
    ${Width(130, mobil)} height: 100%;
    white-space: nowrap;
    /* max-width: 100px; */
    ${(props) =>
      props.readOnly &&
      css`
        color: #a5a5a5;
      `}
  }
`;

export const Input = styled.input`
  flex: 1;
  width: 100%;
  line-height: ${Px(41, pc)};
  ${PR(150, pc)}
  ${FontSize(18, pc)}   

    ${(props) =>
    props.readOnly &&
    css`
      color: #a5a5a5;
    `}
    ::placeholder {
    ${FontSize(16, pc)}
  }

  @media screen and (max-width: ${mquery}px) {
    padding-right: 0;
    line-height: ${Px(32, mobil)};
    ${FontSize(14, mobil)}

    ::placeholder {
      ${FontSize(14, mobil)}
    }
  }
`;

export const EmailInput = styled(Input).attrs((props) => ({
  type: props.type ?? "email",
  required: true,
  placeholder: props.placeholder
    ? props.placeholder
    : "이메일을 @까지 정확하게 입력하세요.",
}))``;
export const FindEmailInput = styled(Input).attrs((props) => ({
  type: props.type ?? "email",
  required: true,
  placeholder: props.placeholder
    ? props.placeholder
    : "가입시 이메일을 입력해주세요.",
}))``;
export const FindTelInput = styled(Input).attrs((props) => ({
  type: props.type ?? "tel",
  required: true,
  placeholder: props.placeholder
    ? props.placeholder
    : "가입시 핸드폰 번호를 입력해주세요.",
}))``;
export const FindTelNumInput = styled(Input).attrs((props) => ({
  type: props.type ?? "number",
  required: true,
  placeholder: props.placeholder
    ? props.placeholder
    : "인증번호를 입력해주세요.",
}))``;
export const FindNubInput = styled(Input).attrs((props) => ({
  type: props.type ?? "number",
  required: true,
  placeholder: props.placeholder
    ? props.placeholder
    : "인증번호를 입력해주세요.",
}))``;
export const PasswordInput = styled(Input).attrs((props) => ({
  type: "password",
  required: true,
  placeholder: props.placeholder
    ? props.placeholder
    : "영문+숫자+특수문자 조합 8~10자리",
}))``;

export const PasswordCheckInput = styled(Input).attrs((props) => ({
  type: "password",
  required: true,
  placeholder: "입력하신 비밀번호를 한번 더 입력해주세요.",
}))``;

export const CheckboxInput = styled.input.attrs((props) => ({
  type: "checkbox",
}))``;

export const TelephoneInput = styled(Input).attrs((props) => ({
  type: "tel",
  required: true,
  placeholder: props.placeholder
    ? props.placeholder
    : "휴대폰 번호를 입력해주세요.",
}))``;

export const DoubleFieldBox = styled(FieldBox)`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  gap: ${Px(15, pc)};

  & > label span {
    ${[PL(13, pc), PR(34, pc)].join("")}
    width: auto;
  }

  & input {
    ${PR(15, pc)}
  }

  @media screen and (max-width: ${mquery}px) {
    & > label span {
      ${[PL(11, mobil), PR(34, mobil)].join("")}
      width: auto;
    }
  }
`;

export const ErrorMessage = styled.p`
  ${FontSize(14, pc)}
  ${Bottom(-25, pc)}
    position: absolute;
  left: 0;
  color: ${({ success }) => (success ? `#222` : "#FF4343")};

  ${MQ(mquery)} {
    ${FontSize(14, mobil)}
    ${Bottom(30, mobil)}
        ${(props) =>
      props.primary &&
      css`
        ${Bottom(-20, mobil)}
      `}
  }
`;
