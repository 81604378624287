import React from "react";
//react
import { Mobile, PC } from "MediaQuery";
import { useState, useEffect, forwardRef } from "react";
//css
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";

//img
import IconSelectArrow from "store_src/img/icon/icon_bottom_arrow.svg";
import CardTemplate from "newComponents/common/CardTemplate";
//page
import ProductList from "store_src/component/common/product/ProductList";
// import Loading from "store_src/common/modal/Loading";
import Loading from "store_src/component/common/modal/Loading";
import NewPagination from "store_src/component/common/pagination/NewPagination";
import PcProductFilterSection from "newComponents/storeCategory/PcProductFilterSection";
import CategoryAside from "newComponents/storeCategory/CategoryAside";
import { Get } from "api";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import InfiniteScroll from "react-infinite-scroller";
import { queryKeys } from "constants/queryKeys";
import SkeletonUI from "newComponents/common/SkeletonUI";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { crypto } from "utils";
import MyPageDefaultModal from "store_src/component/common/modal/MyPageDefaultModal";

const filterPItems = ["전체", "5만원 이하", "5만원 ~ 10만원", "10만원 ~ 20만원", "20만원 이상"];

const PRICE_LIST = {
  0: "전체",
  1: "5만원 이하",
  2: "5만원 ~ 10만원",
  3: "10만원 ~ 20만원",
  4: "20만원 이상",
};

const FILTER_LIST = {
  0: "최신순",
  1: "인기순",
  2: "가격 낮은순",
  3: "가격 높은순",
};

function StoreCategoryList({ setIsOpenFilter, setIsOpenPrice, children }, ref) {
  const [loginPop, setLoginPop] = useState(false);
  const { main_cat, sub_cat } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { p, s } = queryString.parse(location.search);
  const decodedP = p ? crypto.decryptionBASE64(p) : 0; //가격
  const decodedS = s ? crypto.decryptionBASE64(s) : 0; //필터

  const requestData = {
    requestAPI: Get.getCategoryProductList,
    requestQueryString: {
      limit: 20,
      prd_main_cat: crypto.decryptionBASE64(main_cat),
      prd_sub_cat: crypto.decryptionBASE64(sub_cat),
      filter: decodedS,
      range: decodedP,
    },
    queryKey: [queryKeys.STORE_CATEGORY_PRODUCT_LIST, main_cat, sub_cat, p, s],
    options: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      keepPreviousData: true,
    },
  };

  const { data, hasNextPage, fetchNextPage, isError, isFetching, isLoading, isSuccess, refetch } =
    useInfiniteScroll(requestData);
  console.log("상품목록 API : ", data);

  return (
    <>
      {loginPop && (
        <MyPageDefaultModal
          text="로그인 후 이용 가능합니다."
          trueText="로그인"
          trueFunc={() => {
            if (location) return history.push(`/store/login?returnTo=${location.pathname}`);
            history.push(`/store/login`);
          }}
          falseFunc={() => setLoginPop(false)}
        />
      )}

      {
        <Container>
          <AllBrandWrap>
            <Mobile>
              <AllBrandFilter>
                <AllBrandButton onClick={() => setIsOpenFilter(true)}>
                  <AllBrandText className="text">{FILTER_LIST[decodedS]}</AllBrandText>
                  <AllBrandImgBox className="img-box">
                    <img src={IconSelectArrow} alt="select-arrow" />
                  </AllBrandImgBox>
                </AllBrandButton>

                <AllBrandButton onClick={() => setIsOpenPrice(true)}>
                  <AllBrandText className="text">{PRICE_LIST[decodedP]}</AllBrandText>
                  <AllBrandImgBox className="img-box">
                    <img src={IconSelectArrow} alt="select-arrow" />
                  </AllBrandImgBox>
                </AllBrandButton>
              </AllBrandFilter>
            </Mobile>

            <AllBrandLogoWrap>
              <PC>
                <CategoryMenuWrap>{children}</CategoryMenuWrap>
              </PC>
              <ProductListWrap ref={ref}>
                <PcProductFilterSection />

                <InfiniteScroll
                  className="infinite-scroll-wrapper"
                  loadMore={() => {
                    fetchNextPage();
                  }}
                  hasMore={hasNextPage}
                  threshold={450}
                >
                  <ProductListArea>
                    {isLoading && <SkeletonUI.ResponsiveProductList count={8} />}

                    {isSuccess &&
                      data.pages.map((pageData, index) => {
                        if (!isFetching && index === 0 && pageData.list.length === 0) {
                          return <NoText>해당 카테고리의 상품이 없습니다.</NoText>;
                        }
                        return pageData.list.map((item) => (
                          <CardTemplate key={`${item.prd_id}-prd-${index}`} width="calc(25% - 10px)">
                            <ProductList
                              key={index}
                              value={item}
                              height={"allProduct"}
                              rank={false}
                              setLoginPop={setLoginPop}
                            />
                          </CardTemplate>
                        ));
                      })}
                    {/* {!isLoading && isFetching && <SkeletonUI.ResponsiveProductList count={4} />} */}
                  </ProductListArea>
                </InfiniteScroll>
              </ProductListWrap>
            </AllBrandLogoWrap>
          </AllBrandWrap>
        </Container>
      }
    </>
  );
}
const StoreCategoryListContainer = React.forwardRef(StoreCategoryList);
export default StoreCategoryListContainer;

const Container = styled.div`
  width: 100%;
  padding-top: 20px;
  margin-bottom: 180px;
  position: relative;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (20 / 1320));
    margin-bottom: calc(100vw * (180 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: 0;
    margin-bottom: calc(100vw * (100 / 428));
    min-height: calc(100vw * (400 / 428));
  }
`;
const AllBrandWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const SubTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1300px;
  margin: 30px auto;
  padding: 30px 0;
  border-bottom: 1px solid #646464;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
    margin: calc(100vw * (30 / 1320)) auto;
    padding: calc(100vw * (30 / 1320)) 0;
    border-bottom: 1px solid #646464;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (20 / 428)) auto;
    padding: calc(100vw * (20 / 428)) 0;
    border-bottom: 1px solid #646464;
  }
`;
const SubTitle = styled.h2`
  font-size: 28px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (28 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const FilterWrap = styled.div`
  width: auto;
`;
const AllBrandLogoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;
  padding: 40px 0;
  border-top: 10px solid #f9f9f9;
  position: relative;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    padding: calc(100vw * (40 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    display: block;
    width: 100%;
    padding: 0;
    border-top: 0;
  }
`;
const ProductListWrap = styled.div`
  width: 990px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (990 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;

const ProductListArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 930px) {
    justify-content: space-between;
    margin: calc(100vw * (25 / 428)) auto 0;
  }
`;
const ProductListBox = styled.div`
  width: 240px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  border: 1px solid red;

  &:not(:nth-child(4n)) {
    margin-right: 10px;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (240 / 1320));
    margin-bottom: calc(100vw * (40 / 1320));

    &:not(:nth-child(4n)) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (186 / 428));
    margin-bottom: calc(100vw * (20 / 428));

    &:not(:nth-child(4n)) {
      margin-right: 0;
    }
  }
`;
const NoText = styled.p`
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  padding: 60px 0;
  color: #999999;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (60 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    padding: calc(100vw * (50 / 428)) 0;
  }
`;
const AllBrandFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: 0;
  }
`;

// input two range slider
const AllBrandFilterSlider = styled.div`
  width: 393px;
  padding: 0 8px;

  & .rc-slider-rail {
    background-color: #dddddd;
  }
  & .rc-slider-track {
    // fill
    background-color: #000;
  }

  & .rc-slider-handle {
    // circle
    top: 50%;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 2px solid #000;
    margin-top: -8px;
    opacity: 1;
  }

  & .rc-slider-handle:active,
  & .rc-slider-handle:focus,
  & .rc-slider-handle-dragging {
    // dragging option
    box-shadow: none;
    border-color: #000;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (393 / 1320));
    padding: 0 calc(100vw * (8 / 1320));

    & .rc-slider-handle {
      // circle
      width: calc(100vw * (16 / 1320));
      height: calc(100vw * (16 / 1320));
      margin-top: calc(100vw * (-8 / 1320));
    }
  }
`;
// price filter button list
const AllBrandFilterPriceListWrap = styled.div``;
const AllBrandFilterPriceListBox = styled.ul`
  display: flex;
  align-items: center;
`;
const AllBrandFilterPriceList = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 20px;
  }

  ${({ active }) =>
    active &&
    `
 & span {
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-6deg);
      z-index: 0;
      display: block;
      width: 100%;
      height: 40%;
      background-color: #fdf251;
    }
`}

  & span {
    color: #333333;
    font-weight: 500;
    font-size: 16px;
  }
  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (5 / 1320));
    &:not(:last-child) {
      margin-right: calc(100vw * (20 / 1320));
    }
    & span {
      font-size: calc(100vw * (16 / 1320));
    }
  }
`;

const AllBrandButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;

  :not(:last-child) {
    border-right: 1px solid #dddddd;
  }
  @media screen and (max-width: 930px) {
    width: 50%;
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;
const AllBrandText = styled.span`
  color: #333333;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const AllBrandImgBox = styled.span`
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;

// 카테고리
const CategoryMenuWrap = styled.div`
  width: 180px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (180 / 1320));
  }
`;

const CategoryMainMenuText = styled.span`
  color: #999999;
  font-weight: 500;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
`;
const CategorySubMenuArea = styled.ul`
  margin-bottom: 27px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (27 / 1320));
  }
`;
const CategorySubMenuList = styled.li`
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 1320));
    }
  }
`;
const CategorySubMenu = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;
const CategorySubMenuAll = styled(CategorySubMenu)`
  font-weight: 500;
  text-decoration: underline;
`;
