import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import qs from "qs";

import serverController, { ip } from "../../../server/serverController";
import { UserDataAction } from "../../../store/actionCreators";

import styled from "styled-components";

import IconFacebook from "../../img/icon/icon_facebook.svg";
import IconNaver from "../../img/icon/icon_naver.svg";
import IconApple from "../../img/icon/icon_apple.svg";
import IconKakao from "../../img/icon/icon_kakao.svg";
import IconGoogle from "../../img/icon/icon_google.svg";
import IconCheckbox from "../../img/icon/icon_checkbox.png";
import { useSelector } from "react-redux";
import StoreFooter from "../common/StoreFooter";
import { useCookies } from "react-cookie";


export default function Login() {
  // const query = qs.parse(window.location.search, {
  //   ignoreQueryPrefix: true,
  // });
  const [returnTo, setReturnTo] = useState('store');

  const query = (url) => {
    const splitUrl = url.split("?returnTo=");
    if (splitUrl.length > 1) {
      setReturnTo(splitUrl[1]);
    }
  }
  const { isLogged } = useSelector((state) => state.userData);
  const fcmToken = useSelector((state) => state.permissionData.fcmToken.data);
  const deviceType = useSelector((state) => state.deviceData.device.data);
  const history = useHistory();
  const [idValue, setIdValue] = useState("");
  const [pwValue, setPwValue] = useState("");

  const [loginState, setLoginState] = useState(true);
  const [loginInputState, setLoginInputState] = useState(true);
  const [autoLogin, setAutoLogin] = useState(false);

  const [isLoginButtonTouch, setIsLoginButtonTouch] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(['pass']);


  const postLogin = () => {
    if (idValue.length === 0 || pwValue.length === 0) return setLoginInputState(false);

    const formData = new FormData();
    formData.append("mem_email", idValue);
    formData.append("mem_password", pwValue);
    formData.append("type", 1);

    serverController.connectFetchController(`login`, "POST", formData, (res) => {
      if (res?.result) {
        getUserData();
        handleAutoLogin();
      } else {
        setLoginState(false);
      }
    });
  };

  const putPassAuth = () => {
    if (!cookies?.pass) return;
    serverController.connectFetchController(
      `api/pass`,
      "PUT",
      null,
      (res) => {
        console.log(res.message);
        removeCookie('pass', { path: '/' });
      }
    );
  };

  const getUserData = () => {
    serverController.connectFetchController(
      `mypage`,
      "POST",
      null,
      (res) => {
        if (res?.result === 1) {
          const list = res?.list;
          const userObj = {
            mem_id: list?.mem_id,
            mem_email: list?.mem_email,
            mem_point: list?.mem_point,
            mem_password: list?.mem_password,
            mem_name: list?.mem_name,
            mem_phone: list?.mem_phone,
            mem_alarm: list?.mem_alarm,
            mem_role: list?.mem_role,
            mem_gender: list?.mem_gender,
            mem_style: list?.mem_style,
            mem_fit: list?.mem_fit,
            mem_color: list?.mem_color,
            mem_brand_name: list?.mem_brand_name,
            mem_business_registration: list?.mem_business_registration,
            mem_rank: list?.mem_rank,
            mem_belong: list?.mem_belong,
            mem_celebrity_list: list?.mem_celebrity_list,
            mem_activated: list?.mem_activated,
            mem_register_date: list?.mem_register_date,
          };
          UserDataAction.logInRequest({
            userInfo: userObj,
            isLogged: true,
          });
          if (window.ReactNativeWebView) postPushData();
          if (returnTo) {
            history.goBack();
          } else {
            history.push("/store");
          }
          putPassAuth();
        }
      },
      (err) => console.error(err)
    );
  };

  const postPushData = () => {
    serverController.connectFetchController(
      `push?mem_push_token=${fcmToken}`,
      "POST",
      null,
      (res) => { }
    );
  };

  const onchangeId = (e) => {
    setIdValue(e.target.value);
    setLoginState(true);
    setLoginInputState(true);
  };

  const onchangePassword = (e) => {
    setPwValue(e.target.value);
    setLoginState(true);
    setLoginInputState(true);
  };

  const handleAutoLogin = () => {
    if (!autoLogin) return;
    localStorage.setItem(
      "login-info",
      JSON.stringify({
        userId: idValue,
        userPw: pwValue,
      })
    );
  };

  useEffect(() => {
    if (!isLogged) return;
    // putPassAuth();
    history.push(`/store`);
  }, [isLogged]);

  useEffect(() => {
    query(window.location.search);
    if (sessionStorage.getItem("returnTo")) sessionStorage.removeItem("returnTo");
  }, []);

  return (
    <>
      <Container>
        <LoginWrap>
          <LoginWrapInner>
            <LoginTitleWrap>
              <Title>로그인</Title>
            </LoginTitleWrap>
            <LoginInputWrap>
              <LoginInputInner>
                <LoginInput
                  type="email"
                  placeholder="아이디"
                  value={idValue}
                  border={idValue.length > 0}
                  loginState={loginState && loginInputState}
                  onChange={(e) => onchangeId(e)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") postLogin();
                  }}
                />
              </LoginInputInner>
              <LoginInputInner>
                <LoginInput
                  type="password"
                  placeholder="비밀번호"
                  value={pwValue}
                  border={pwValue.length > 0}
                  loginState={loginState && loginInputState}
                  onChange={(e) => onchangePassword(e)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") postLogin();
                  }}
                />
              </LoginInputInner>
            </LoginInputWrap>
            {!loginInputState && (
              <LoginInfoTextBox>
                <LoginInfoText>아이디 혹은 비밀번호를 입력해주세요.</LoginInfoText>
              </LoginInfoTextBox>
            )}
            {!loginState && (
              <LoginInfoTextBox>
                <LoginInfoText>
                  등록되지 않은 아이디 이거나, <br />
                  아이디 또는 비밀번호가 정보와 일치하지 않습니다.
                </LoginInfoText>
              </LoginInfoTextBox>
            )}
            <LoginBtnWrap
              onTouchStart={() => setIsLoginButtonTouch(true)}
              onTouchEnd={() => setIsLoginButtonTouch(false)}
              onClick={postLogin}
            >
              <LoginBtn isLoginButtonTouch={isLoginButtonTouch}>로그인</LoginBtn>
            </LoginBtnWrap>
            <LoginTextWrap>
              <LoginTextInner>
                <LoginTextList>
                  <LoginTextInner>
                    <Checks>
                      <ChkBox
                        id="auto-login"
                        type="checkbox"
                        name="checkbox"
                        checked={autoLogin}
                        onChange={(e) => setAutoLogin(e.target.checked)}
                        autoLogin={autoLogin}
                      />
                      <ChkBoxLabel htmlFor="auto-login"></ChkBoxLabel>
                    </Checks>
                    <LoginText>자동로그인</LoginText>
                  </LoginTextInner>
                </LoginTextList>
                <LoginTextListRight>
                  <LoginTextList>
                    <LoginText
                      onClick={() => history.push(`/store/find/id?returnTo=${returnTo}`)}
                    >
                      아이디 찾기
                    </LoginText>
                  </LoginTextList>
                  <LoginTextList>
                    <LoginText
                      onClick={() => history.push(`/store/find/pw?returnTo=${returnTo}`)}
                    >
                      {" / "}비밀번호 찾기
                    </LoginText>
                  </LoginTextList>
                </LoginTextListRight>
              </LoginTextInner>
            </LoginTextWrap>
            <SignUpSocialWarp>
              <SocialTextBox>
                <SocialTextWrap>
                  <SocialText>SNS 계정으로 로그인하기</SocialText>
                </SocialTextWrap>
              </SocialTextBox>
              <SocialButtonWrap>
                {deviceType !== "ios" && (
                  <SocialButton
                    onClick={() => {
                      sessionStorage.setItem("returnTo", returnTo);
                      window.location.href = `${ip}/oauth2/authorization/google`;
                    }}
                  >
                    <img src={IconGoogle} alt="google" />
                  </SocialButton>
                )}
                {/* <SocialButton
            onClick={() => {
              window.location.href = `${ip}/oauth2/authorization/facebook`;
            }}
          >
            <img src={IconFacebook} alt="facebook" />
          </SocialButton> */}
                {/* <SocialButton
            onClick={() => {
              window.location.href = `${ip}/oauth2/authorization/naver`;
            }}
          >
            <img src={IconNaver} alt="naver" />
          </SocialButton> */}
                <SocialButton
                  onClick={() => {
                    sessionStorage.setItem("returnTo", returnTo);
                    window.location.href = `${ip}/oauth2/authorization/kakao`;
                  }}
                >
                  <img src={IconKakao} alt="kakao" />
                </SocialButton>
                <SocialButton
                  onClick={() => {
                    sessionStorage.setItem("returnTo", returnTo);
                    window.location.href = `${ip}/apple/login`;
                  }}
                >
                  <img src={IconApple} alt="apple" />
                </SocialButton>
              </SocialButtonWrap>
            </SignUpSocialWarp>
            <SignUpWrap>
              <SignUpText>아직 회원이 아니신가요?</SignUpText>
              <SignUpBtnWrap>
                <SignUpBtn onClick={() => history.push(`/store/signup?returnTo=${returnTo}`)}>
                  회원가입
                </SignUpBtn>
              </SignUpBtnWrap>
            </SignUpWrap>
          </LoginWrapInner>
        </LoginWrap>
      </Container>
      {/* <StoreFooter /> */}
    </>
  );
}
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 200px 0 204px;

  @media screen and (max-width: 930px) {
    min-height: calc(100vh - calc(100vw * (402 / 428)));
    padding: calc(100vw * (80 / 428)) 0 calc(100vw * (80 / 428));
  }
`;
const LoginWrap = styled.div``;
const LoginWrapInner = styled.div`
  width: 378px;
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const LoginTitleWrap = styled.div`
  margin-bottom: 40px;

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (40 / 428));
  }
`;
const Title = styled.h2`
  width: 100%;
  font-size: 26px;
  color: #222222;
  font-weight: 500;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (26 / 428));
  }
`;
//login input
const LoginInputWrap = styled.div`
  width: 100%;
`;
const LoginInfoTextBox = styled.div`
  margin-top: 6px;

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (6 / 428));
  }
`;
const LoginInfoText = styled.p`
  color: #ff006c;
  font-size: 15px;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const LoginInputInner = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (12 / 428));
    }
  }
`;
const LoginInput = styled.input`
  width: 100%;
  height: 50px;
  font-size: 15px;
  border: 1px solid #dddddd;
  padding: 0 10px;
  &::placeholder {
    color: #acacac;
  }
  &:focus {
    border: 1px solid #000;
  }
  ${({ loginState }) =>
    !loginState &&
    `
  border-color: #FF006C;
  `}
  ${({ border }) =>
    border &&
    `
  border-color: #000;
  `}

  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (16 / 428)) calc(100vw * (15 / 428)) calc(100vw * (15 / 428));
  }
`;
const LoginBtnWrap = styled.div`
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const LoginBtn = styled.p`
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 14px 0;
  color: #fff;
  background: #000;
  cursor: pointer;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    padding: calc(100vw * (14 / 428)) 0;
    ${({ isLoginButtonTouch }) => isLoginButtonTouch && `background-color : #1b1b1b`}
  }
`;
const LoginTextWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 15px;

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (15 / 428));
  }
`;
const LoginTextInner = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 930px) {
  }
`;
const LoginTextListRight = styled.div`
  display: flex;
`;
const LoginTextList = styled.li`
  display: flex;
  align-items: center;

  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      font-size: calc(100vw * (14 / 428));
    }
  }
`;
const LoginText = styled.span`
  font-size: 14px;
  color: #777;
  cursor: pointer;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const SignUpWrap = styled.div`
  width: 100%;
  margin-top: 100px;

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (50 / 428));
  }
`;
const SignUpText = styled.p`
  font-size: 15px;
  text-align: center;
  color: #777777;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const SignUpBtnWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 12px;

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (12 / 428));
  }
`;
const SignUpBtn = styled.p`
  width: 100%;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  background-color: #fff;
  border: 1px solid #000;
  padding: 14px 0;
  cursor: pointer;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    padding: calc(100vw * (14 / 428)) 0;
  }
`;

const Checks = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (20 / 1320));
    height: calc(100vw * (20 / 1320));
    margin-right: calc(100vw * (7 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (20 / 428));
    height: calc(100vw * (20 / 428));
    margin-right: calc(100vw * (7 / 428));
  }
`;
const ChkBox = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  &:checked + label::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: #000 url(${IconCheckbox}) no-repeat center/14px;
  }
  @media screen and (max-width: 930px) {
    &:checked + label::after {
      width: calc(100vw * (20 / 428));
      height: calc(100vw * (20 / 428));
    }
  }
`;
const ChkBoxLabel = styled.label`
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  &&::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
  }
  @media screen and (max-width: 1320px) {
    &&::before {
      width: calc(100vw * (20 / 1320));
      height: calc(100vw * (20 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &&::before {
      width: calc(100vw * (20 / 428));
      height: calc(100vw * (20 / 428));
    }
  }
`;
const SignUpSocialWarp = styled.div`
  text-align: center;
  margin-top: 50px;

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (50 / 428));
  }
`;
const SocialButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (30 / 428));
  }
`;
const SocialTextBox = styled.div`
  position: relative;
`;
const SocialTextWrap = styled.div`
  display: inline-block;
  background-color: #fff;
  margin: 0 17px;

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: -1;
    transform: translateY(-50%);
    background-color: #eeeeee;
  }

  @media screen and (max-width: 930px) {
    padding: 0 calc(100vw * (17 / 428));
  }
`;
const SocialText = styled.p`
  color: #333333;
  font-size: 15px;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

const SocialButton = styled.button`
  width: 48px;
  height: 48px;
  padding: 0;
  box-shadow: 0 0 4 #dddddd;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:not(:last-child) {
    margin-right: 30px;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (48 / 428));
    height: calc(100vw * (48 / 428));

    &:not(:last-child) {
      margin-right: calc(100vw * (30 / 428));
    }
  }
`;
