//react
import React, { useState, useEffect, useRef } from "react";
import serverController from "../../../server/serverController";
import { imgURL } from "../../../server/appInfoContoller";

//css
import styled from "styled-components";

//img
import IconDown from "../../img/brand/icon_down.svg";
import IconUp from "../../img/brand/icon_up.svg";
import { useHistory } from "react-router-dom";

export default function BestBrandSection() {
  const history = useHistory();
  const [rankList, setRankList] = useState([]);

  useEffect(() => {
    // get ranking brand list
    serverController.connectFetchController(
      `brand/ranking`,
      "GET",
      null,
      (res) => {
        setRankList(res.data);
      },
      (err) => console.error(err)
    );
  }, []);

  return (
    <>
      <BackGround>
        <Container>
          <BrandWrap>
            <TitleWrap>
              <TitleText>BEST BRAND</TitleText>
            </TitleWrap>

            <RankWrap>
              <RankArea>
                {rankList.length > 0 && (
                  <RankInner>
                    {rankList.map((rankBrandItem) => {
                      return (
                        <RankList
                          key={rankBrandItem.brrId}
                          onClick={() => {
                            history.push(`/store/brand/detail/${rankBrandItem.bcId}?page=1`);
                          }}
                        >
                          <RankListBox>
                            <Brand>{rankBrandItem.brrRank}</Brand>
                            <RankBrandInfo>
                              <RankImg>
                                <img src={imgURL + rankBrandItem.bcLogo} alt="rankBrand-icon" />
                              </RankImg>
                              <AllBrandTextBox>
                                <AllBrandTextTitle>{rankBrandItem.bcTitle}</AllBrandTextTitle>
                                <AllBrandTextText>{rankBrandItem.bcTitle}</AllBrandTextText>
                              </AllBrandTextBox>
                            </RankBrandInfo>
                            <RankCount>
                              <RankCountImg
                                src={
                                  rankBrandItem.brrVariance.indexOf("+") === 0 ? IconUp : IconDown
                                }
                                alt=""
                              />
                              <RankCountText rankState={rankBrandItem?.brrVariance?.indexOf("+") === 0}>
                                {rankBrandItem.brrVariance}
                              </RankCountText>

                            </RankCount>
                          </RankListBox>
                        </RankList>
                      );
                    })}
                  </RankInner>
                )}
              </RankArea>
            </RankWrap>
          </BrandWrap>
        </Container>
      </BackGround>
    </>
  );
}
const BackGround = styled.div``;
const Container = styled.div``;
const BrandWrap = styled.div`
  width: 100%;
`;
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
`;
const TitleText = styled.h2`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  color: #222222;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }
`;
const RankWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px 0;
  margin-top: 15px;
  background-color: #f9f9f9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (15 / 1320)) 0;
    margin-top: calc(100vw * (15 / 1320));
  }
`;

const RankArea = styled.div`
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
`;

const RankInner = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
`;
const RankList = styled.li`
  display: flex;
  align-items: center;

  width: 301px;
  height: 80px;
  background-color: #fff;
  border: 1px solid #eee;
  margin: 5px 0 5px 0;

  &:not(:nth-child(4n)) {
    margin: 5px 12px 5px 0;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (301 / 1320));
    height: calc(100vw * (80 / 1320));
    margin: calc(100vw * (5 / 1320)) 0 calc(100vw * (5 / 1320)) 0;

    &:not(:nth-child(4n)) {
      margin: calc(100vw * (5 / 1320)) calc(100vw * (12 / 1320)) calc(100vw * (5 / 1320)) 0;
    }
  }
`;
const RankListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Brand = styled.p`
  width: 48px;
  color: #333;
  font-weight: 700;
  font-size: 20px;
  text-align: center;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (48 / 1320));
    font-size: calc(100vw * (20 / 1320));
  }
`;
const RankBrandInfo = styled.div`
  display: flex;
  align-items: center;
  width: 187px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (187 / 1320));
  }
`;
const RankImg = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  overflow: hidden;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (50 / 1320));
    height: calc(100vw * (50 / 1320));
  }
`;

const AllBrandTextBox = styled.div`
  margin-left: 15px;
  @media screen and (max-width: 1320px) {
    margin-left: calc(100vw * (15 / 1320));
  }
`;
const AllBrandTextTitle = styled.div`
  color: #333333;
  font-weight: 500;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
`;
const AllBrandTextText = styled.div`
  color: #777777;
  font-weight: 400;
  font-size: 15px;
  margin-top: 2px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (2 / 1320));
  }
`;

const RankCount = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 67px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (67 / 1320));
  }
`;
const RankCountImg = styled.img`
  display: block;
  width: 8px;
  margin-right: 10px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (8 / 1320));
    margin-right: calc(100vw * (10 / 1320));
  }
`;
const RankCountText = styled.span`
  font-size: 16px;
  font-weight: 500;

  ${({ rankState }) => {
    return rankState ? `color: #FF4B4B;` : `color: #4B60FF;`;
  }}
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;
