//react
import { useState,useEffect } from "react";
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery"

//css
import styled from "styled-components"

//page
import ReCommentLayout from "./ReCommentLayout"

export default function CommentLayout({value}) {
    const [reCommentClick,setReCommentClick] = useState(false);
    const [reListClick,setReListClick] = useState(false);

    const recommentList = [
        {
            user_name: "닉네임",
            comment_date: "05.11",
            comment_text: "댓글 내용 영역",
        },
        {
            user_name: "홍길동",
            comment_date: "07.24",
            comment_text: "댓글댓글",
        }
    ]
  return (
    <Container>
        <PC>
            <CommentListBox>
                <TitleWrap>
                    <Title>{value.user_name}</Title>
                    <Title>{value.comment_date}</Title>
                </TitleWrap>
                <CommentTextWrap>
                    <CommentText>{value.comment_text}</CommentText>
                </CommentTextWrap>
                <ReCommemtBtnWrap>
                    <ReCommemtListBtn onClick={()=>{setReListClick(!reListClick)}}>댓글열기({recommentList.length})</ReCommemtListBtn>
                    <ReCommemtBtn onClick={()=>{setReCommentClick(!reCommentClick)}}>답글달기</ReCommemtBtn>
                </ReCommemtBtnWrap>
                {
                    reCommentClick ?
                    <ReCommentWrap>
                        <ReCommentBox>
                            <TextArea name="" id="" placeholder="댓글을 작성하시려면 로그인해주시기 바랍니다."/>
                            <CommentBtn>등록</CommentBtn>
                        </ReCommentBox>
                    </ReCommentWrap>
                    :
                    null
                }
                <ReCommentListWrap>
                    {
                        reListClick ?
                        recommentList.map((value,index)=>{
                            return(
                                <ReCommentLayout value={value} />
                            )
                        })
                        :
                        null
                    }
                </ReCommentListWrap>
            </CommentListBox>
        </PC>

        <Mobile>
        </Mobile>
    </Container>
  );
}
const Container = styled.div`
    width: 100%;
    padding: 25px 0;
    border-bottom: 1px solid #D9D9D9;
    &:last-child {border: none;}
    @media screen and (max-width: 1320px) {
    }
`
const CommentListBox = styled.div`
    width: 100%;
`
const TitleWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
`
const Title = styled.p`
    position: relative;
    font-size: 14px;
    color: #969696;
    padding-right: 14px;
    margin-right: 14px;
    &:after {content:""; position: absolute; top: 0; right: 0; display: block; width: 1px; height: 15px; background: #B5B5B5;}
    &:last-child:after{display: none;}
`
const CommentTextWrap = styled.div`
    width: 100%;
    margin-bottom: 20px;
`
const CommentText = styled.p`
    font-size: 14px;
    color: #525252;
`
const ReCommemtBtnWrap = styled.div`
    width: 100%;
    padding-bottom: 30px;
`
const ReCommentListWrap = styled.div`
    width: 100%;
`
const ReCommemtBtn = styled.span`
    font-size: 14px;
    color: #E87D00;
    cursor: pointer;
`
const ReCommemtListBtn = styled(ReCommemtBtn)`
    color: #525252;
    margin-right: 15px;
`
//comment textarea
const CommentWrap = styled.div`
    width: 100%;
    margin-top: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #d9d9d9;
`
const ReCommentWrap = styled(CommentWrap)`
    padding: 0 45px 30px;
    margin-top: 0;
    border: none;
`
const CommentBox = styled.div`
    position: relative;
    width: 100%;
`
const ReCommentBox = styled(CommentBox)``
const TextArea = styled.textarea`
    width: 100%; height: 100px;
    padding: 15px 140px 15px 15px;
    border: 1px solid #707070;
    border-radius: 5px;
`
const CommentBtn = styled.p`
    position: absolute; right: 20px; bottom: 20px;
    width: 100px;
    padding: 10px 0;
    border-radius: 10px;
    text-align: center;
    color: #fff;
    background: #525252;
`