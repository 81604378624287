import React, { useState, useEffect } from "react";
import { Mobile, PC } from "../../../MediaQuery";
import serverController from "../../server/serverController";

import Header from "../../component/common/Header";
import MyPageHeader from "./MyPageHeader";
import MyCoupon from "../../component/mypage/point/MyCoupon";

import FullLoading from "../../component/common/modal/FullLoading";
import FixMenu from "../../component/common/FixMenu";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import PostCouponModal from "../../component/mypage/point/PostCouponModal";

export default function CouponPage() {
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);

  const [usedCouponList, setUsedCouponList] = useState([]);
  const [activeCouponList, setActiveCouponList] = useState([]);

  const [isCoupon, setIsCoupon] = useState(false);
  const [isUser, setIsUser] = useState(false);

  const [loading, setLoading] = useState(true);


  // 리덕스 스토어에서 isLogged 값이 false로 받아와져서 쿠폰페이지에서 mypage api 호출해서 회원정보를 조회했습니다.
  // 리덕스가 왜 false가 되는지 원인을 찾는다면 다시 수정할 예정입니다.
  const getUserData = () => {
    serverController.connectFetchController(
      `mypage`,
      "POST",
      null,
      (res) => {
        if (res?.result === 1) {
          setIsUser(true);
          setTimeout(() => {
            validLogin();
          }, 0);
        };
        if (res?.result !== 1) {
          alert(`로그인 후 이용해주세요.`);
          history.push(`/store/login?returnTo=/store`);
        }
      },
      (err) => console.error(err)
    );
  };


  const validLogin = () => {
    if (isUser) return; //기존 코드는 로그인정보가 없다.
    // if (isLogged) return; //기존 코드는 로그인정보가 없다.
    // alert(`잘못된 접근입니다.`);
    // history.push(`/store/login?returnTo=/store`);
  };

  useEffect(() => {
    // validLogin();
    getUserData();
  }, []);

  // get coupon list
  const getCouponList = () => {
    serverController.connectFetchController(`coupon/list?type=${tabIndex}`, "GET", null, (res) => {
      if (res?.result === 1) {
        const couponArr = [...res?.coupon_list];
        setUsedCouponList(couponArr);
        setActiveCouponList(couponArr);
      } else {
        return () => {
          alert("잘못된 접근입니다.");
          history.push(`/store`);
        };
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getCouponList();
  }, [tabIndex]);

  const couponItems = {
    usedCouponList,
    activeCouponList,
    tabIndex,
    setTabIndex,
    isCoupon,
    setIsCoupon,
  };

  return (
    <>
      {loading && <FullLoading />}
      {isCoupon && <PostCouponModal setIsCoupon={setIsCoupon} />}
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader title={"나의 쿠폰"} prevBtn />
      </Mobile>
      <MyCoupon {...couponItems} />
      <FixMenu />
    </>
  );
}
