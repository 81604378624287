//react
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import serverController from "../../server/serverController";

//css
import styled from "styled-components";

//img
import IconLock from "../../img/icon/icon_secret_blue.png";
import IconRe from "../../img/icon/icon_recomment.png";

//page
import PasswordModal from "../common/modal/PasswordModal";
import Pagination from "../common/pagination/Pagination";
import Search from "../common/pagination/Search";
import InquiryLayout from "./InquiryLayout";

export default function InquiryList({ isMypage }) {
  const [isModal, setIsModal] = useState(false);
  const [inqIndex, setInqIndex] = useState(0);
  const [inqPwd, setInqPwd] = useState("");
  const [checkPwd, setCheckPwd] = useState(false);

  const [inquiryList, setInquiryList] = useState([]);

  useEffect(() => {
    serverController.connectFetchController(
      `inquiry/one-on-one/list`,
      "GET",
      null,
      function (res) {
        if (res.result == 1) {
          console.log(res);
          setInquiryList(res.inquiry_list);
        }
      }
    );
  }, []);

  return (
    <Container>
      <InquiryListWrap>
        {!isMypage && (
          <TitleWrap>
            <Title>1:1문의</Title>
          </TitleWrap>
        )}
        <InquiryLayout
          type={2}
          setInqIndex={setInqIndex}
          list={inquiryList}
          setIsModal={setIsModal}
        />
        <WriteWrap>
          <Link to={`/store/mypage/inquiry/write`}>
            <WriteBtn>글쓰기</WriteBtn>
          </Link>
        </WriteWrap>
        {/* <Pagination/>
            <Search/> */}
      </InquiryListWrap>

      {isModal && (
        <PasswordModal
          setIsModal={setIsModal}
          inquiryList={inquiryList}
          inqIndex={inqIndex}
          checkPwd={checkPwd}
          setCheckPwd={setCheckPwd}
          inqPwd={inqPwd}
          setInqPwd={setInqPwd}
        />
      )}
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
`;
const InquiryListWrap = styled.div`
  width: 1300px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
  }
`;
const TitleWrap = styled.div`
  width: 100%;
  margin-bottom: 25px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (25 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (25 / 428));
  }
`;
const Title = styled.h2`
  font-size: 24px;
  color: #000;
  font-weight: bold;
  margin-bottom: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
    margin-bottom: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    margin-bottom: calc(100vw * (10 / 428));
  }
`;

const WriteWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const WriteBtn = styled.p`
  width: 100px;
  font-size: 15px;
  border: 1px solid #222222;
  padding: 8px 0;
  text-align: center;
  color: #fff;
  background: #222222;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    font-size: calc(100vw * (15 / 1320));
    border: calc(100vw * (1 / 1320)) solid #000;
    padding: calc(100vw * (8 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    font-size: calc(100vw * (15 / 428));
    border: calc(100vw * (1 / 428)) solid #000;
    padding: calc(100vw * (8 / 428)) 0;
  }
`;
