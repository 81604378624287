import React, { useState, useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";

import Header from "../../component/common/Header";
import ModalHeader from "../../component/common/ModalHeader";
import ContentsList from "../../component/contents/ContentsList";
import FooterMenu from "../../component/common/FooterMenu";
import FullLoading from "../../component/common/modal/FullLoading";
import FixMenu from "../../component/common/FixMenu";

export default function ContentsListPage() {
  const [contentsDetail, setContentsDetail] = useState(false);
  // const [loading, setLoading] = useState(true);

  const contentsItem = {
    // setLoading,
    contentsDetail,
    setContentsDetail,
  };

  return (
    <>
      {/* {loading && <FullLoading />} */}
      <PC>
        <Header infinite />
      </PC>

      <ModalHeader back={contentsDetail} setContentsDetail={setContentsDetail} />

      <ContentsListWrap>
        <ContentsList {...contentsItem} />
      </ContentsListWrap>

      {/* <Footer /> */}
      <Mobile>{!contentsDetail && <FooterMenu nowTabRef="contents" />}</Mobile>

      <FixMenu infinite={!contentsDetail ? true : false} />
    </>
  );
}

// 컨텐츠 리스트
const ContentsListWrap = styled.div`
  /* padding-top: 40px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (40 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (30 / 428));
  } */
`;
