import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import serverController from "../../../server/serverController";
import {
  Container,
  Layout,
  AuthIntroMessage,
  LabelName,
  FindTelInput,
  FindTelNumInput,
  InputWrap,
  BlackButton,
  WhiteButton,
  InfoText,
  FindTelValidationWrap,
  FindTelValidationInputBox,
  Time,
  AlertBox,
} from "./auth.elements";

export default function FindID({ returnTo }) {
  const history = useHistory();

  const [isBtn, setIsBtn] = useState(true);
  const [isShowId, setIsShowId] = useState(false);
  const [isCorrect, setIsCorrect] = useState(true);
  const [isTimer, setIsTimer] = useState(false);
  const [isNan, setIsNan] = useState(false);
  const [isClickReFetch, setIsClickReFetch] = useState(false);
  const [isFindId, setIsFindId] = useState(null);

  const [telValue, setTelValue] = useState("");
  const [num, setNum] = useState("");
  const [numValue, setNumValue] = useState("");
  const [findId, setFindId] = useState("");

  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);

  const postFindIdNum = (reFetch = false) => {
    if (telValue.length === 0) {
      return;
    }
    serverController.connectFetchController(
      `find/id?mem_phone=${telValue}`,
      "POST",
      null,
      (res) => {
        if (res.result === 1) {
          if (reFetch) {
            setIsClickReFetch(true);
          }
          setNum(res.code);
          setFindId(res.email);
          setIsBtn(false);
          setIsTimer(true);
          setMinutes(3);
          setSeconds(0);
          setIsCorrect(true);
        } else {
          setIsFindId(false);
        }
      },
      (err) => console.error(err)
    );
  };

  const onClickFindId = () => {
    if (num !== numValue) {
      setIsCorrect(false);
    } else {
      setIsTimer(false);
      setIsCorrect(true);
      setIsShowId(true);
    }
  };

  const handleTimerRefetch = () => {
    let timeOut = setTimeout(() => {
      setIsClickReFetch(false);
      clearTimeout(timeOut);
    }, 3000);
  };

  const handleTimerSetting = () => {
    if (minutes === undefined || minutes === NaN) {
      setMinutes("00");
    }
    if (seconds === undefined || seconds === NaN) {
      setSeconds("00");
    }
    if (seconds * 1 === 0 && minutes * 1 === 0 && isNan === false) {
      setIsNan(true);
    }
    if (seconds * 1 === 0 && minutes * 1 === 0 && isNan === true) {
      setIsNan(false);
      setIsBtn(true);
      setNumValue("");
      setIsTimer(false);
      alert("인증번호 유효 기간이 끝났습니다. 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    if (!isTimer) return;

    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1);
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(parseInt(minutes) - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [isTimer, minutes, seconds]);

  useEffect(() => {
    handleTimerRefetch();
  }, [isClickReFetch]);

  useEffect(() => {
    handleTimerSetting();
  }, [minutes, seconds]);

  return (
    <>
      <Container>
        <AlertBox show={isClickReFetch}>인증번호가 전송되었습니다.</AlertBox>
        <Layout>
          <LoginBox>
            {!isShowId ? (
              <>
                <AuthIntroMessage>
                  <AuthTitle>아이디찾기</AuthTitle>
                </AuthIntroMessage>
                <InputWrap>
                  <LabelName>휴대폰번호</LabelName>
                  <FindTelInput
                    type="number"
                    findIdIs={isFindId}
                    value={telValue}
                    onChange={(e) => {
                      if (e.target.value.length > 11) return;
                      setTelValue(e.target.value);
                      setIsFindId(true);
                    }}
                  />
                  {isFindId === false ? <InfoText>가입되어 있지 않은 번호입니다.</InfoText> : null}
                </InputWrap>

                {!isBtn ? (
                  <InputWrap>
                    <FindTelValidationWrap>
                      <FindTelValidationInputBox>
                        <FindTelNumInput
                          correct={isCorrect}
                          value={numValue}
                          type="number"
                          onChange={(e) => {
                            if (e.target.value.length > 10) return;
                            setNumValue(e.target.value);
                            setIsCorrect(true);
                          }}
                        />
                        <Time>
                          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                        </Time>
                      </FindTelValidationInputBox>
                      <WhiteButton
                        onClick={() => {
                          postFindIdNum(true);
                          setNumValue("");
                        }}
                      >
                        재전송
                      </WhiteButton>
                    </FindTelValidationWrap>
                    {!isCorrect && <InfoText>인증번호가 일치하지 않습니다.</InfoText>}
                    <BlackButtonSend
                      activity={numValue.length !== 0 ? false : true}
                      onClick={onClickFindId}
                    >
                      확인
                    </BlackButtonSend>
                  </InputWrap>
                ) : (
                  <>
                    <BlackButton
                      onClick={postFindIdNum}
                      activity={telValue.length !== 0 ? false : true}
                    >
                      인증번호 전송
                    </BlackButton>
                  </>
                )}
              </>
            ) : (
              <FindIDResult>
                <Text>
                  가입한 계정의 아이디는 <br /> <b>"{findId}"</b> 입니다.
                </Text>
                <BlackButton onClick={() => history.push(`/store/find/pw?returnTo=${returnTo}`)}>
                  비밀번호 찾기
                </BlackButton>
              </FindIDResult>
            )}

            {!isShowId ? (
              <LoginBottom>
                <WhiteButton onClick={() => history.push(`/store/login?returnTo=${returnTo}`)}>
                  로그인
                </WhiteButton>
              </LoginBottom>
            ) : (
              <FindIDResultBottom>
                <WhiteButton onClick={() => history.push(`/store/login?returnTo=${returnTo}`)}>
                  로그인
                </WhiteButton>
              </FindIDResultBottom>
            )}
          </LoginBox>
        </Layout>
      </Container>
    </>
  );
}

const LoginBox = styled.div``;
const AuthTitle = styled.span`
  font-size: 26px;
  color: #222222;
  font-weight: 500;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (26 / 428));
  }
`;

const BlackButtonSend = styled(BlackButton)`
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (10 / 428));
  }
`;
const Text = styled.p`
  line-height: 1.5;
  color: #222222;
  font-size: 16px;
  text-align: center;
  margin-bottom: 60px;

  & b {
    font-weight: 600;
    font-size: 20px;
  }

  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (60 / 428));
    font-size: calc(100vw * (16 / 428));

    & b {
      font-size: calc(100vw * (20 / 428));
    }
  }
`;

const LoginBottom = styled.div`
  margin-top: 60px;

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (60 / 428));
  }
`;
const FindIDResultBottom = styled.div`
  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (10 / 428));
  }
`;
const FindIDResult = styled.div`
  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (200 / 428));
  }
`;
