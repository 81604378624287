import React from "react";
import styled from "styled-components";
import { PC, Mobile } from "../../components/common/mediaStlye";
import Header from "../../components/common/header/Header";
import FindPassword from "../../components/auth/login/FindPassword";
import Footer from "../../components/common/footer/Footer";
import FooterMenu from "../../components/common/footer/FooterMenu";

const FindPasswordPage = () => {
  return (
    <Container>
      <Header />
      <FindPassword />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
    </Container>
  );
};
const Container = styled.div`
  @media screen and (max-width: 1024px) {
    padding-bottom: calc(100vw * (68 / 428));
  }
`;

export default FindPasswordPage;
