//react
import { Link, useHistory } from "react-router-dom";
import { PC } from "../../../MediaQuery";
import { useState, useEffect } from "react";
import styled from "styled-components";

//server
import serverController from "../../../server/serverController";

//img
import IconCancel from "../../../img/icon/icon_myhaeder_cancle.svg";
import IconSelectArrow from "../../../img/icon/icon_bottom_arrow.svg";
import MyPageBottom from "../../../page/mypage/MyPageBottom";
import MyPageDefaultModal from "./MyPageDefaultModal";

// mypage bottom data
const myPageBottomTextList = [
  "※ 입점상담 신청 시 주의사항 안내",
  "1. 입점상담을 신청하시면 내부 검토 후에 입점 가능 여부와 계약 절차에 대해 안내 드리고 있습니다.",
  "2. 입점검토까지 시일이 다소 소요될 수 있으니 중복 등록과 고객센터로 MD연락처 및 가능여부 문의는 피해주시기 바랍니다.",
  "3. 왈라는 정품 취급을 원칙으로 하며, 직접 디자인하고 생산하는 브랜드 업체만 입점을 허용합니다. (사입품 택갈이 브랜드, 가품취급 입점 불가)",
  "4. 상품관리(MD)와 주문관리(배송/CS처리)가 원활할 수 있도록 담당자가 지정되어야 하며, 불가능한경우 입점이 거절될 수 있습니다.",
];

// option list
const optionList = [
  { title: "선택", value: "-1" },
  { title: "입점/제휴", value: "1" },
  { title: "투자", value: "2" },
  { title: "채용", value: "3" },
  { title: "홍보", value: "4" },
];

const body = document.querySelector("body");

export default function PartnerModal({ setPartnerModalShow }) {
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [inquiryText, setInquiryText] = useState("");
  const [isPopClose, setIsPopClose] = useState(false);
  const [selectedValue, setSelectedValue] = useState("-1");

  function AssociateSubmit() {
    if (selectedValue === "-1") return alert("문의 유형을 선택해주세요.");
    if (name.length === 0) return alert("담당자명을 입력해주세요.");
    if (company.length === 0) return alert("회사명을 입력해주세요.");
    if (email.length === 0) return alert("이메일을 입력해주세요.");
    if (phone.length === 0) return alert("연락처를 입력해주세요.");
    if (title.length === 0) return alert("제목을 입력해주세요.");
    if (inquiryText.length < 10) return alert("문의 내용을 10자 이상 입력해주세요.");

    const formData = new FormData();
    formData.append("affiliation_type", selectedValue);
    formData.append("affiliation_name", name);
    formData.append("affiliation_level", company);
    formData.append("affiliation_email", email);
    formData.append("affiliation_tel", phone);
    formData.append("affiliation_title", title);
    formData.append("affiliation_content", inquiryText);

    serverController.connectFetchController(`affiliation`, "POST", null, (res) => {
      console.log(res, "res");
      if (res.result === 1) {
        alert("제휴문의를 완료하였습니다.");
        setPartnerModalShow(false);
      }
    });
  }

  useEffect(() => {
    body.style.overflow = "hidden";
    body.style.height = "100vh";
    return () => {
      body.style.overflow = "auto";
      body.style.height = "auto";
    };
  }, []);

  return (
    <Container>
      {isPopClose && (
        <MyPageDefaultModal
          text={
            <>
              작성하고 있던 내용이 저장되지 않습니다.
              <br />
              문의 작성을 취소하시겠습니까?
            </>
          }
          trueFunc={() => setPartnerModalShow(false)}
          falseFunc={() => setIsPopClose(false)}
        />
      )}
      <Content>
        <HeaderSection>
          <HeaderInner>
            <p>입점문의</p>
            <CloseButton
              onClick={() => {
                setIsPopClose(true);
              }}
            >
              <img src={IconCancel} alt="cancel-icon" />
            </CloseButton>
          </HeaderInner>
        </HeaderSection>

        <Wrap>
          <ListWrap>
            <FormWrap>
              <FormList>
                <FormFlex>
                  <FormInnerFlex>
                    <FormTitleWrap>
                      <ListTitle>문의 유형</ListTitle>
                    </FormTitleWrap>
                    <SelectButton
                      defaultValue={"-1"}
                      borderActive={selectedValue !== "-1"}
                      onChange={(e) => {
                        setSelectedValue(e.target.value);
                      }}
                    >
                      {optionList.map((item, index) => {
                        return (
                          <option value={item.value} key={`${item.value}-option-select-${index}`}>
                            {item.title}
                          </option>
                        );
                      })}
                    </SelectButton>
                  </FormInnerFlex>
                  <FormInnerFlex>
                    <FormTitleWrap>
                      <ListTitle>회사명</ListTitle>
                    </FormTitleWrap>
                    <Input
                      type="text"
                      value={company}
                      active={company.length > 0}
                      onChange={(e) => {
                        setCompany(e.target.value);
                      }}
                    />
                  </FormInnerFlex>
                  <FormInnerFlex>
                    <FormTitleWrap>
                      <ListTitle>담당자</ListTitle>
                    </FormTitleWrap>
                    <Input
                      type="text"
                      value={name}
                      active={name.length > 0}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </FormInnerFlex>
                </FormFlex>
              </FormList>
              <FormList>
                <FormFlex>
                  <FormInnerFlex>
                    <FormTitleWrap>
                      <ListTitle>이메일</ListTitle>
                    </FormTitleWrap>
                    <Input
                      type="email"
                      value={email}
                      active={email.length > 0}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </FormInnerFlex>
                  <FormInnerFlex>
                    <FormTitleWrap>
                      <ListTitle>연락처</ListTitle>
                    </FormTitleWrap>
                    <Input
                      type="number"
                      value={phone}
                      active={phone.length > 0}
                      onChange={(e) => {
                        if (11 < e.target.value.length) return;
                        setPhone(e.target.value);
                      }}
                    />
                  </FormInnerFlex>
                </FormFlex>
              </FormList>
              <FormList>
                <FormTitleWrap>
                  <ListTitle>제목</ListTitle>
                </FormTitleWrap>
                <Input
                  type="text"
                  value={title}
                  active={title.length > 0}
                  placeholder="제목을 입력해주세요."
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </FormList>
              <FormList>
                <FormTitleWrap>
                  <ListTitle>문의 내용</ListTitle>
                </FormTitleWrap>
                <InquiryTextWrap>
                  <InquiryTextArea
                    value={inquiryText}
                    active={inquiryText.length > 0}
                    placeholder="내용을 입력해주세요."
                    onChange={(e) => {
                      setInquiryText(e.target.value);
                    }}
                  />
                </InquiryTextWrap>
              </FormList>
            </FormWrap>
          </ListWrap>

          <SubmitBtnWrap>
            <Btn type="button" onClick={AssociateSubmit}>
              확인
            </Btn>
          </SubmitBtnWrap>
          <MyPageBottom textList={myPageBottomTextList} />
        </Wrap>
      </Content>

      <PC>
        <Background
          onClick={() => {
            setIsPopClose(true);
          }}
        />
      </PC>
    </Container>
  );
}

const Container = styled.div``;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;

// header
const HeaderSection = styled.div`
  position: relative;
  width: 100%;
  height: 84px;
  overflow: hidden;
  z-index: 100;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
  }
`;
const HeaderInner = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
  background-color: #fff;
  padding: 0 25px;

  & p {
    color: #333;
    font-weight: 500;
    font-size: 20px;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
    padding: 0 calc(100vw * (25 / 1320));
    & p {
      font-size: calc(100vw * (20 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    justify-content: center;
    height: calc(100vw * (55 / 428));
    padding: 0;
    & p {
      font-size: calc(100vw * (16 / 428));
    }
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 1320px) {
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    right: calc(100vw * (13.5 / 428));
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;

// 콘텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  width: 635px;
  height: 716px;
  background: #fff;
  overflow: scroll;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (635 / 1320));
    height: calc(100vw * (716 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: 0;
    left: 0;
    transform: translate(0);
    width: 100%;
    height: 100vh;
    overflow: auto;
  }
`;

const Wrap = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0 0;
  }
`;
// inquiry
const ListWrap = styled.div``;

// from
const FormWrap = styled.div`
  width: 585px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (585 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const FormList = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 428));
    }
  }
`;
const FormFlex = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 930px) {
    display: block;
  }
`;
const FormInnerFlex = styled.div`
  width: 287.5px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (287.5 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 428));
    }
  }
`;

const FormTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;

  @media screen and (max-width: 1320px) {
    margin: 0 0 calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto calc(100vw * (8 / 428));
  }
`;
const ListTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
// select
const SelectButton = styled.select`
  display: block;
  width: 378px;
  height: 50px;
  color: #acacac;
  font-weight: 400;
  font-size: 15px;
  background: #ffffff url(${IconSelectArrow}) no-repeat right 15px center / 24px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0 15px;
  border-radius: 0;

  -webkit-appearance: none; /* 네이티브 외형 감추기*/
  -moz-appearance: none;
  appearance: none;
  font-weight: normal;
  letter-spacing: normal;
  cursor: pointer;

  ${({ borderActive }) =>
    borderActive &&
    `border: 1px solid #000;
    color: #333;
  `}
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
    height: calc(100vw * (50 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
    font-size: calc(100vw * (15 / 1320));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 1320)) center /
      calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 428)) center /
      calc(100vw * (24 / 428));
  }
`;
// input
const Input = styled.input`
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: 0 calc(100vw * (15 / 428));
  }
`;
// textarea
const InquiryTextWrap = styled.div``;
const InquiryTextArea = styled.textarea`
  width: 100%;
  height: 200px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  padding: 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (200 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (200 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (15 / 428)) calc(100vw * (20 / 428));
  }
`;

// button
const SubmitBtnWrap = styled.div`
  width: 428px;
  height: 50px;
  margin: 25px auto 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (428 / 1320));
    height: calc(100vw * (50 / 1320));
    margin: calc(100vw * (25 / 1320)) auto 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    height: calc(100vw * (50 / 428));
    margin: calc(100vw * (20 / 428)) auto 0;
  }
`;
const Btn = styled.button`
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
