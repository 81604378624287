import React from 'react';

import styled , {css} from 'styled-components';

import TestImg from '../../../img/content/content2.png';

export default function ContentsSimpleListEl(){
    return(
        <ListWrap>
            <ProdDate>21.05.09</ProdDate>
            <ProdImgWrap>
                <Img src={TestImg}/>
            </ProdImgWrap>
            <ProdName>by WALA Master</ProdName>
            <ProdTitle>최신 아이돌 Fashion A to Z</ProdTitle>
            <ProdHash>
                #아이돌패션, #최신유행, #나만아
                는 패션, #20대패션, #꾸안꾸패션
            </ProdHash>
        </ListWrap>
    )
}

const ListWrap = styled.div`
    width:calc(100% / 6); padding-left:9px; padding-right:9px;
    margin-bottom:calc(100vw * (56 / 1920));

    @media screen and (max-width:1300px){
        width:calc(100% / 4);
    }

    @media screen and (max-width:768px){
        width:calc(100% / 3); padding-left:6px; padding-right:6px;
    }
`;

const CheckBoxWrap = styled.div`
    margin-bottom:6px;
    text-align:left;
    &:after { content:''; display:block; clear:both; }
`;
const CheckBox = styled.input`
    float:left; width:20px; height:20px; border:2px solid #A5A5A5;
    cursor:pointer;
`;
const ProdDate = styled.p`
    font-size:14px; color:#bfbfbf; line-height:22px;
`;

const ProdImgWrap = styled.div`
    width:100%; height:0; padding-bottom:100%; overflow:hidden;
    vertical-align: top;
`;

const Img = styled.img`
    width:100%; object-fit: cover; vertical-align: top;
`;

const ProdName = styled.p`
    font-size:12px; font-weight:400; color:#A5A5A5; line-height:16px;
    margin:6px 0 3px 0;
`;
const ProdTitle = styled.p`
    font-size:15px; line-height:21px; margin-bottom:13px;
`;
const ProdHash = styled.p`
    font-size:13px; color:#A5A5A5; line-height:18px;
`;