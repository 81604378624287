//react
import styled, { keyframes } from "styled-components";

export default function Toast({ text }) {

  return (
    <Container>
      <Content>
        <TextBox>
          {text}
        </TextBox>
      </Content>
    </Container>
  );
}

// s :: animation
const fadeIn = keyframes`
0%{
		opacity: 0;
}
	100% {
		opacity: 1;
	}
`;

// e :: animation
const fadeOut = keyframes`
0%{
  opacity: 1;
}
	100% {
		opacity: 0;
	}
`;

const Container = styled.div`

`;

// 컨텐츠
const Content = styled.div`
  position: fixed;
  margin: 0 auto;
  bottom: 50px;
  left: 0;
  right: 0;
  z-index: 702;
  width: 398px;
  height: fit-content;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 50px;
  animation: ${fadeIn} 0.5s ease-out, ${fadeOut} 0.5s 1s ease-out;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (398 / 1320));
    bottom: calc(100vw * (50 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (398 / 428));
    bottom: calc(100vw * (100 / 428));
  }
`;

const TextBox = styled.div`
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  padding: 16px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (16 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (16 / 428)) 0;
  }
`;