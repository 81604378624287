import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import qs from "qs";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";

// controller
import serverController, { ip } from "../../server/serverController";

import MyPageHeader from "./MyPageHeader";
import MyQuestion from "../../component/mypage/inquiry/MyQuestion";
import FullLoading from "../../component/common/modal/FullLoading";
import MyInquiryDetail from "../service/MyInquiryDetail";

import MyPageLayOutMenu from "../../component/mypage/MyPageLayOutMenu";
import MyPageLayOutName from "../../component/mypage/MyPageLayOutName";
import MyPageLayOutInfo from "../../component/mypage/MyPageLayOutInfo";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function QuestionPage() {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState(false);
  const [detailItem, setDetailItem] = useState([]);

  // page
  const [pageData, setPageData] = useState([]);

  // for pc pagination
  const [page, setPage] = useState(query.page ?? 1);

  const [questionList, setQuestionList] = useState([]);

  const validLogin = () => {
    if (isLogged) return;
    alert(`잘못된 접근입니다.`);
    history.push(`/store`);
  };

  useEffect(() => {
    validLogin();
  }, []);

  const detailPrev = () => {
    setDetail(false);
  };

  // get question list
  const getQuestionList = (type = 12, period = "month", page = false) => {
    serverController.connectFetchController(
      `inquiry/store?type=${type}&period=${period}&page=${page ? 1 : query.page}`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setPageData(res.pagination);
          setQuestionList(res.inquiryList);
        }
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  // delete question item
  const deleteQuestionItem = (inqId, setState) => {
    const header = new Headers();
    header.append("Content-Type", "application/json");
    const rawBody = JSON.stringify({
      inq_id: inqId,
    });

    const options = {
      method: "DELETE",
      body: rawBody,
      headers: header,
      redirect: "follow",
    };

    fetch(`${ip}/inquiry/store`, options)
      .then((response) => response.json())
      .then((result) => {
        if (result.result === 1) {
          setQuestionList([]);
          setState(false);
          getQuestionList();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const clickPrevButton = () => {
    history.push(`/store/mypage`);
  };

  useEffect(() => {
    const unListen = history.block((loc, action) => {
      if (action === "POP") {
        if (detail) {
          setDetail(false);
        } else {
          clickPrevButton();
        }
        return false;
      }
    });

    return () => {
      unListen();
    };
  }, [history, detail]);

  const myQuestionItems = {
    setDetail,
    setDetailItem,
    detail,
    pageData,
    questionList,
    getQuestionList,
    setQuestionList,
    deleteQuestionItem,
    page,
    setPage,
  };

  const MyInquiryDetailItems = {
    setDetail,
    detailItem,
    deleteQuestionItem,
  };

  return (
    <>
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader title={"1:1 문의"} prevBtn prevFunc={detail ? detailPrev : clickPrevButton} />
      </Mobile>
      {loading && <FullLoading />}
      <MyQuestionArea>
        <PC>
          <MyPageLayOutName />
        </PC>

        <FlexLayOut>
          <PC>
            <MyPageLayOutMenu />
          </PC>
          <ContentSection>
            <PC>
              <MyPageLayOutInfo />
            </PC>

            <MyQuestionSection>
              {detail && <MyInquiryDetail {...MyInquiryDetailItems} />}
              <MyQuestion {...myQuestionItems} />
            </MyQuestionSection>
          </ContentSection>
        </FlexLayOut>
      </MyQuestionArea>
    </>
  );
}

const MyQuestionArea = styled.div`
  width: 100%;
  padding: 180px 0 200px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const MyQuestionSection = styled.div``;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
