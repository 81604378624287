import React, { useState } from 'react'
import styled, { css } from 'styled-components';
import DatePicker, { registerLocale } from "react-datepicker";  // 한국어적용
import ko from 'date-fns/locale/ko'; // 한국어적용
// import useCalendar from './Calendar/useCalendar';


import "react-datepicker/dist/react-datepicker.css";
import './SponDetailCalendar.css';

registerLocale("ko", ko) // 한국어적용
const disableData = [
    "2021-11-23", "2021-11-27", "2021-11-30" 
]

const SponDetailCalendar = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date("2021-11-26"));

    const onChange = (dates) => {
        console.log(dates, "이벤트")
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    return (
        <Wrapper  className="detail_calendar">
            <DatePicker                               
                // selected={startDate}
                // onChange={onChange}
                minDate={new Date()}
                // startDate={startDate}
                // endDate={endDate}
                // selectsRange
                inline
                locale={ko}                
                />
        </Wrapper>
    )
}

export default SponDetailCalendar

const Wrapper = styled.div`
    position: relative;
    margin-bottom: 25px;

`

