import React, { useState, useEffect } from 'react';
import serverController from '../../../server/serverController';

import TabContentItemIntro from './TabContentItemIntro';
import TabContentItemList from './TabContentItemList';
import TabContentItemList2 from './TabContentItemList2';


const TabContentItems = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [data, setData] = useState([]);
    const [itemData, setItemData] = useState([])

    const getData = async () => {
        await serverController.connectFetchController(
            `contentslike`,
            "GET",
            null,
            (res) => {
                console.log(res, "컨텐츠 좋아요 목록 res")
                if (res?.result === 1) {
                    setData(res?.list ?? []);
                }
            }
        )
    }

    useEffect(() => {
        getData()
    }, [])

    const getItem = async () => {
        await serverController.connectFetchController(
            `products/like`,
            "GET",
            null,
            (res) => {
                console.log(res, "아이테ㅔ메메메메메멤")
                if (res?.result === 1) {
                    setItemData(res?.list ?? []);
                }
            }
        )
    }

    useEffect(() => {
        getItem()
    }, [])

    const renderItems = () => {
        switch(activeIndex) {
            case 0: 
                return <TabContentItemIntro data={data} itemData={itemData} setActiveIndex={setActiveIndex}/>
            case 1: 
                return <TabContentItemList data={data} setActiveIndex={setActiveIndex}/>
            case 2: 
                return <TabContentItemList2 itemData={itemData} setActiveIndex={setActiveIndex}/>
            default: 
                return;
        }
    }

    return (
        <div>
            {renderItems()} 
            
        </div>
    )
}

export default TabContentItems
