export const rnView = () => {

    // RN 이슈 해결용 코드 (삭제금지)
    if (window.ReactNativeWebView) {
        if(window.location.pathname.split("/")[1] === "video"){
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: "page",
                  text: "hi",
                })
              );
        }else{
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: "page",
                  text: "bye",
                })
              );
        }
    }
};
