import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalContainer } from '../common/header/Header.elements';

import serverController from '../../server/serverController';

import styled from "styled-components";

const DeleteOpenModal = ({ conId, setConId, setIsOpen, list, setList, index }) => {

    const history = useHistory()

    const onRemove = (index) => {

        setList(prev => prev
            .map((v, i) => i === index ? null : v)
            .filter(v => v !== null)
        )
        setIsOpen(false);
    }

    const deleteData = async () => {
        await serverController.connectFetchController(
            `contents/${conId}`,
            "DELETE",
            null,
            (res) => {
                console.log(res, "내가 등록한 컨텐츠 삭제합니다");
                setConId(null);
            }
        )
    }

    return (
        <>
            <ModalContainer>
                <DeleteBoxWrap>
                    <MainText>선택하신 항목을 삭제하시겠습니까?</MainText>
                    <SubText>선택하신 콘텐츠 일괄 삭제를 진행합니다.</SubText>
                    <ButtonWrap>
                        <WhiteButton onClick={() => {
                            console.log('취소');
                            setIsOpen(false);
                            setConId(null);
                        }}>취소</WhiteButton>
                        <BlackButton onClick={() => {
                            console.log('삭제');
                            onRemove(index);
                            deleteData();
                        }}>삭제</BlackButton>
                    </ButtonWrap>
                </DeleteBoxWrap>
            </ModalContainer>
        </>
    )
}

export default DeleteOpenModal;

export const DeleteBoxWrap = styled.div`
    width: calc(100vw * (751 / 1920));
    height: calc(100vw * (274 / 1920));
    background-color: #fff;
    margin: auto;
    position : absolute;
    left:0;
    right : 0;
    top:0;
    bottom : 0;
    text-align : center;
`
export const MainText = styled.p`
   text-align : center;
   font-size : calc(100vw * (23 / 1920));
   color : #222;
   font-weight: bold;
   padding-top: calc(100vw * (80 / 1920));
`
export const SubText = styled.p`
   text-align : center;
   font-size : calc(100vw * (16 / 1920));
   color : #A5A5A5;
   padding: calc(100vw * (7 / 1920)) 0 calc(100vw * (46 / 1920));;
`
export const ButtonWrap = styled.div`
   display: flex;
   justify-content: space-between;
   padding: 0 calc(100vw * (177 / 1920));;
`
export const WhiteButton = styled.button`
    font-size : calc(100vw * (18 / 1920));
    margin-right :  calc(100vw * ( 8 / 1920));
    color : #222;
    width: 50%;
    border : 1px solid #222;
    line-height: calc(100vw * (24 / 1920));
    padding : calc(100vw * (10 / 1920)) 0 calc(100vw * (12 / 1920));
`
export const BlackButton = styled.button`
    font-size : calc(100vw * (18 / 1920));
    color : #fff;
    background-color: #222;
    width: 50%;
    line-height: calc(100vw * (24 / 1920));
    padding : calc(100vw * (10 / 1920)) 0 calc(100vw * (12 / 1920));
`