import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../server/serverController';
import { imgURL } from '../../server/appInfoContoller';
import { PC, Mobile } from '../common/mediaStlye';
import ReactHtmlParser from 'react-html-parser';

import {
    Container,
    Layout,
    FashionNewsHeadings,
    FashionNewsBackground,
    FashionNewsSlider,
    FashionNewsSlide,
    FashionNewsLeft,
    MainNewsItem,
    MainNewsItemText,
    MainNewsItemImg,
    FashionNewsRight,
    MainNewsRightText,
    MainNewsRightImg,
    MainNewsRightItem
} from './FashionNews.element';

import BackgroundImg from '../../images/news-logo-wala.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper'; 
import "swiper/swiper-bundle.css";
import "./FashionNewsMain.css";
SwiperCore.use([Pagination, Navigation]);

const FashionNewsMain = () => {
    const [newsList, setNewsList] = useState([])
    const [arr, setArr] = useState([])
    const counts = useRef(5);
    const getNews = async () => {
        await api.get(`newsList`, null, 
            (res) => {
                console.log(res, "뉴스 목록 조회")
                if (res?.result === 1) {
                    const data = res?.list ?? [];
                    setNewsList(data)                    
                }
            }
        )
    }

    useEffect(() => {
        getNews()
    }, [])

    useEffect(() => {
        const pageNum = Math.round(newsList.length / (counts.current ?? 0))
        setArr(newsList)
    }, [newsList, counts])

    const history = useHistory()
    const movePage = (id) => {
        const path = `/news/detail/${id}`
        history.push(path)
    }

    return (
        <Container>
            <Layout>
                <FashionNewsHeadings>
                    <strong>WALA PICK!</strong>
                    <h2>fashion NEWS</h2>
                </FashionNewsHeadings>

                <FashionNewsSlider className="fashion-slider">
                    {
                        arr.length > 0 
                        ? (
                            <Swiper
                            navigation
                            pagination
                            >
                                {
                                    arr.map((v, i) => {
                                        const slideListThumb = v[0]
                                        
                                        return (
                                            <SwiperSlide key={slideListThumb?.news_id}>
                                                <FashionNewsSlide>
                                                    <FashionNewsLeft>
                                                        <MainNewsItem>
                                                            <MainNewsItemText>
                                                                <span>스냅</span>
                                                                <h3>{slideListThumb?.news_title}</h3>
                                                                <p>
                                                                {ReactHtmlParser(slideListThumb?.news_content)}
                                                                </p>
                                                            </MainNewsItemText>
                            
                                                            <MainNewsItemImg onClick={() => movePage(slideListThumb?.news_id)}>
                                                                {slideListThumb?.news_img && <img src={imgURL + slideListThumb?.news_img} />}
                                                            </MainNewsItemImg>
                                                        </MainNewsItem>
                                                    </FashionNewsLeft>
                            
                                                    <FashionNewsRight>
                                                    <PC>
                                                    {
                                                        v.filter((value, index) => index !== 0).map((item, i) => {
                                                            return (
                                                                <MainNewsRightItem>
                                                                    <MainNewsRightText>
                                                                        <span>스냅</span>
                                                                        <h3>{item?.news_title}</h3>
                                                                        <p>
                                                                        {ReactHtmlParser(item?.news_content)}
                                                                        </p>
                                                                    </MainNewsRightText>
                        
                                                                    <MainNewsRightImg onClick={() => movePage(item?.news_id)}>
                                                                        {item?.news_img && <img src={imgURL + item?.news_img} />}
                                                                    </MainNewsRightImg>
                                                                </MainNewsRightItem>
                                                            )
                                                        })
                                                    }
                                                    </PC>
                                                    <Mobile>
                                                    {
                                                        v.filter((value, index) => index !== 0).slice(1,3).map((item, i) => {
                                                            return (
                                                                <MainNewsRightItem>
                                                                    <MainNewsRightText>
                                                                        <span>스냅</span>
                                                                        <h3>{item?.news_title}</h3>
                                                                        <p>
                                                                        {ReactHtmlParser(item?.news_content)}
                                                                        </p>
                                                                    </MainNewsRightText>
                        
                                                                    <MainNewsRightImg onClick={() => movePage(item?.news_id)}>
                                                                        {item?.news_img && <img src={imgURL + item?.news_img} />}
                                                                    </MainNewsRightImg>
                                                                </MainNewsRightItem>
                                                            )
                                                        })
                                                    }
                                                    </Mobile>
                                                </FashionNewsRight>
                                                </FashionNewsSlide>
                                        </SwiperSlide>
                                        )
                                    })
                                }    
                            </Swiper>
                        )
                        : null
                    }                    
                    <FashionNewsBackground><img src={BackgroundImg} alt="배경 로고" /></FashionNewsBackground>
                </FashionNewsSlider>
            </Layout>
        </Container>
    )
}

export default FashionNewsMain
