import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import ServiceMenu from "../../component/service/ServiceMenu";
import ProductInquiryList from "../../component/service/ProductInquiryList";
import Footer from "../../component/common/StoreFooter";
import FixedTop from "../../component/common/FixedTop";
import FooterMenu from "../../component/common/FooterMenu";
import FixMenu from "../../component/common/FixMenu";

export default function ProductInquiryPage() {
  return (
    <>
      <PC>
        <Header />
        <ServiceMenu />
        <ProductInquiryList />
        <Footer />
        <FixedTop />
      </PC>

      <Mobile>
        <Header />
        <ServiceMenu />
        <ProductInquiryList />
        <Footer />
        <FooterMenu />
        <FixMenu />
      </Mobile>
    </>
  );
}
