import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import serverController from '../../server/serverController';
import { useParams } from 'react-router-dom';
import StylistSearchList from '../stylist/StylistSearchList';

import {
  ResultLayout,
  ResultHeadings,
  WalaItemList,
  MoreButton
} from './SearchResultPage.elements';


const ResultsProduct = () => {
  const { keyword } = useParams();
  const [list, setList] = useState([]);
  const [moreOff, setMoreOff] = useState(true);
  const [isLoad, setIsLoad] = useState(false);
  const [morePage, setMorePage] = useState(0);

  const getData = async () => {
    setIsLoad(false);
    await serverController.connectFetchController(`product/sponsor?keyword=${keyword}&offset=0&limit=10`, "GET", null, (res) => {
      console.log(res, "검색 왈라 상품 res");
      if (res?.result === 1) {
        setList(res?.productList ?? []);
      }
      setIsLoad(true);
    }, (err) => { console.log(err); }
    );
  };

  useEffect(() => {
    setMorePage(0);
    setMoreOff(true);
    getData();
  }, [keyword])


  const MoreDataClick = async () => {
    await serverController.connectFetchController(`product/sponsor?keyword=${keyword}&offset=${morePage + 8}&limit=8`, "GET", null, (res) => {
      console.log(res, "검색 왈라 상품 추가 res");
      if (res?.result === 1) {
        setList([...list, ...res.productList]);
        setMorePage(morePage + 8);
        if (res?.productList.length === 0) {
          setMoreOff(false);
        }
      }
      setIsLoad(true);
    }, (err) => { console.log(err); }
    );
  }

  return (
    <>
      <ResultLayout>
        <ResultHeadings>
          <h2>"{keyword}" WALA stylist</h2>
        </ResultHeadings>
        <StylistSearchList list={list} isLoad={isLoad} />
        {
          list.length > 7 && moreOff && <MoreButton onClick={MoreDataClick}>See More</MoreButton>
        }
      </ResultLayout>
    </>
  )
}

const NoText = styled.div`
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    color: #aaa;
    text-align: center;
    padding-top: 50px;

    @media screen and (max-width:1320px){
        font-size: calc(100vw * (15 / 1320));
        padding-top: calc(100vw * (50 / 1320));
    }
    @media screen and (max-width:930px){
        font-size: calc(100vw * (15 / 428));
        padding-top: calc(100vw * (50 / 428));
    }
`;

export default ResultsProduct
