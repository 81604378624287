import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

//img
import IconMenuArrow from "../../img/icon/icon_my_menu_arrow.svg";

export default function MyPageLayOutMenu() {
  const { userInfo } = useSelector((state) => state.userData);
  const { recommender_cate } = useSelector((state) => state.recommenderData);
  const pathName = window.location.pathname;
  const [socialEmail, setSocialEmail] = useState(false);

  // social login 시 비밀번호 변경 없애기
  useEffect(() => {
    let userE = userInfo?.mem_email;
    if (userInfo) {
      if (
        userE?.indexOf("google_") !== -1 ||
        userE?.indexOf("apple_") !== -1 ||
        userE?.indexOf("naver_") !== -1 ||
        userE?.indexOf("kakao_") !== -1
      ) {
        setSocialEmail(true);
      } else {
        setSocialEmail(false);
      }
    }
  }, [userInfo]);

  return (
    <Container>
      <MenuListArea>
        <MenuListInner>
          <MenuListTitle>주문관리</MenuListTitle>
          <MenuListBox>
            <MenuList MenuOn={pathName.split("/")[3] === "order" && pathName.split("/")[4] === "0"}>
              <MenuLink to={`/store/mypage/order/0?page=1`}>주문/배송조회</MenuLink>
            </MenuList>
            <MenuList MenuOn={pathName.split("/")[3] === "order" && pathName.split("/")[4] === "3"}>
              <MenuLink to={`/store/mypage/order/3`}>취소/교환/반품 조회</MenuLink>
            </MenuList>
          </MenuListBox>
        </MenuListInner>

        <MenuListInner>
          <MenuListTitle>MY</MenuListTitle>
          <MenuListBox>
            <MenuList MenuOn={pathName.split("/")[3] === "productInquiry"}>
              <MenuLink to={`/store/mypage/productInquiry?page=1`}>상품 문의 내역</MenuLink>
            </MenuList>
            <MenuList MenuOn={pathName.split("/")[3] === "review"}>
              <MenuLink to={`/store/mypage/review?tab=1&page=1`}>나의 리뷰</MenuLink>
            </MenuList>
            <MenuList MenuOn={pathName.split("/")[3] === "wish"}>
              <MenuLink to={`/store/mypage/wish?tab=0&page=1`}>찜목록</MenuLink>
            </MenuList>
            <MenuList MenuOn={pathName.split("/")[3] === "point"}>
              <MenuLink to={`/store/mypage/point`}>포인트</MenuLink>
            </MenuList>
            <MenuList MenuOn={pathName.split("/")[3] === "coupon"}>
              <MenuLink to={`/store/mypage/coupon`}>쿠폰</MenuLink>
            </MenuList>
          </MenuListBox>
        </MenuListInner>

        <MenuListInner>
          <MenuListTitle>정보관리</MenuListTitle>
          <MenuListBox>
            <MenuList MenuOn={pathName.split("/")[3] === "profile"}>
              <MenuLink to={`/store/mypage/profile`}>회원정보 수정</MenuLink>
            </MenuList>
            {!socialEmail && (
              <MenuList MenuOn={pathName.split("/")[3] === "password"}>
                <MenuLink to={`/store/mypage/password`}>비밀번호 변경</MenuLink>
              </MenuList>
            )}
            <MenuList MenuOn={pathName.split("/")[3] === "adress"}>
              <MenuLink to={`/store/mypage/adress`}>배송지 관리</MenuLink>
            </MenuList>
            <MenuList MenuOn={pathName.split("/")[3] === "personal"}>
              <MenuLink to={`/store/mypage/personal/1`}>맞춤분석</MenuLink>
            </MenuList>
            {recommender_cate && (
              <MenuList MenuOn={pathName.split("/")[3] === "personal"}>
                <MenuLink to={`/store/mypage/recommender`}>추천인 등록</MenuLink>
              </MenuList>
            )}

            {/* <MenuList MenuOn={pathName.split("/")[3] === "bank"}>
              <MenuLink to={`/store/mypage/bank`}>환불계좌 관리</MenuLink>
            </MenuList> */}
          </MenuListBox>
        </MenuListInner>

        <MenuListInner>
          <MenuListTitle>고객센터</MenuListTitle>
          <MenuListBox>
            {/* <MenuList MenuOn={pathName.split("/")[3] === "notice"}>
              <MenuLink to={`/store/mypage/notice?page=1`}>공지사항</MenuLink>
            </MenuList> */}
            <MenuList MenuOn={pathName.split("/")[3] === "faq"}>
              <MenuLink to={`/store/mypage/faq?page=1`}>자주묻는 질문</MenuLink>
            </MenuList>
            {/* <MenuList MenuOn={pathName.split("/")[3] === "inquiry"}>
              <MenuLink to={`/store/mypage/inquiry?page=1`}>1:1문의</MenuLink>
            </MenuList> */}
          </MenuListBox>
        </MenuListInner>
      </MenuListArea>
    </Container>
  );
}

const Container = styled.div`
  width: 180px;
  padding-top: 10px;
  border-top: 3px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (180 / 1320));
    padding-top: calc(100vw * (10 / 1320));
  }
`;

const MenuListArea = styled.ul``;
const MenuListInner = styled.li`
  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #f9f9f9;
  }

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }
`;

const MenuListBox = styled.ul`
  margin: 0 auto;
  padding-top: 23px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (23 / 1320));
  }
`;
const MenuList = styled.li`
  width: 100%;
  height: 35px;
  overflow: hidden;
  ${({ MenuOn }) => {
    return (
      MenuOn &&
      `
        & a{
          font-weight: 500;
          text-decoration: underline;
        }
      `
    );
  }}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (35 / 1320));
  }
`;
const MenuListTitle = styled.p`
  color: #999;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
`;
const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  color: #333;
  font-weight: 500;
  font-size: 16px;

  &::after {
    content: "";
    display: block;
    background: url(${IconMenuArrow}) no-repeat center / contain;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;
