import React from "react";
import { PC, Mobile } from "../../components/common/mediaStlye";
import Header from "../../components/common/header/Header";
import SignUpBrand from "../../components/auth/signup/SignUpBrand";
import Footer from "../../components/common/footer/Footer";

const SignUpBrandPage = () => {
  return (
    <>
      <Header />
      <SignUpBrand />
      <Footer />
    </>
  );
};

export default SignUpBrandPage;
