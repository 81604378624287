import { useEffect, useState } from "react";
import { Mobile, PC } from "../../MediaQuery";
import { useHistory } from "react-router-dom";
import qs from "qs";
import styled from "styled-components";

// component
import Header from "../../component/common/Header";
import FixMenu from "../../component/common/FixMenu";
import MyPageHeader from "./MyPageHeader";
import MyReviewDetail from "./MyReviewDetail";
import MyReview from "./MyReview";
import ReviewEditForm from "../../component/mypage/order/ReviewEditForm";
import FullLoading from "../../component/common/modal/FullLoading";

import MyPageLayOutMenu from "../../component/mypage/MyPageLayOutMenu";
import MyPageLayOutName from "../../component/mypage/MyPageLayOutName";
import MyPageLayOutInfo from "../../component/mypage/MyPageLayOutInfo";

// controller
import serverController from "../../server/serverController";
import { useSelector } from "react-redux";
import NewPagination from "../../component/common/pagination/NewPagination";

export default function ReveiwPage() {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const history = useHistory();
  const { isLogged } = useSelector((state) => state.userData);

  // header
  const [hide, setHide] = useState(false);

  const [reviewList, setReviewList] = useState([]);
  const [possibleReviewList, setPossibleReviewList] = useState([]);
  const [reviewIndex, setReviewIndex] = useState(null);
  const [reviewDetail, setReviewDetail] = useState(false);
  const [reviewEdit, setReviewEdit] = useState(false);
  const [prevButtonClick, setPrevButtonClick] = useState(false);

  const [isWriteModalMo, setIsWriteModalMo] = useState(false);

  const [loading, setLoading] = useState(true);

  const [possiblePage, setPossiblePage] = useState(query.page ?? 1);
  const [possiblePageData, setPossiblePageData] = useState([]);

  const [writtenPage, setWrittenPage] = useState(query.page ?? 1);
  const [writtenPageData, setWrittenPageData] = useState([]);

  const validLogin = () => {
    if (isLogged) return;
    alert(`잘못된 접근입니다.`);
    history.push(`/store`);
  };
  // get written review list
  const getReviewList = () => {
    serverController.connectFetchController(
      `review/user?page=${query.page}&limit=20`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setWrittenPageData(res.pagination);
          setReviewList([...res.reviewList]);
        } else {
          return () => {
            alert("잘못된 접근입니다.");
            history.push("/store");
          };
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  // get possible list
  const getPossibleList = () => {
    serverController.connectFetchController(
      `payment/review/possible?page=${query.page}&limit=20`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setPossiblePageData(res.pagination);
          setLoading(false);
          setPossibleReviewList(res.payment_list);
        } else {
          alert("잘못된 접근입니다.");
          history.push("/store");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const headerPrevFuncReviewDetail = () => {
    setReviewDetail(!reviewDetail);
    setReviewEdit(false);
    if (prevButtonClick) setPrevButtonClick(false);
  };

  const pushMypageHome = () => {
    history.push(`/store/mypage`);
  };

  const headerPrevFuncReviewEdit = () => {
    setPrevButtonClick(true);
  };

  // header scroll control
  useEffect(() => {
    validLogin();
    // s :: scroll down fix
    let lastScrollY = 0;

    window?.addEventListener("scroll", (e) => {
      const scrollY = window?.scrollY;

      const direction = scrollY > lastScrollY ? true : false;

      lastScrollY = scrollY;

      if (lastScrollY > 130) setHide(direction);
    });

    // e :: scroll down fix
  }, []);

  useEffect(() => {
    getReviewList();
    getPossibleList();
  }, [reviewEdit]);

  useEffect(() => {
    history.push(`/store/mypage/review?tab=0&page=${possiblePage}`);
  }, [possiblePage]);

  useEffect(() => {
    history.push(`/store/mypage/review?tab=1&page=${writtenPage}`);
  }, [writtenPage]);

  useEffect(() => {
    if (query.tab === "0") {
      getPossibleList();
    } else {
      getReviewList();
    }
  }, [query.page]);

  const MyReviewDetailItems = {
    setReviewDetail,
    getReviewList,
    setReviewEdit,
  };

  const ReviewEditFormItems = {
    prevButtonClick,
    setPrevButtonClick,
    setReviewEdit,
  };

  const MyReviewItems = {
    setReviewDetail,
    setReviewIndex,
    setReviewEdit,
    isWriteModalMo,
    setIsWriteModalMo,
    hide,
  };

  return (
    <>
      {loading && <FullLoading />}
      <PC>
        <Header />
      </PC>
      <Mobile>
        {isWriteModalMo ? (
          <MyPageHeader
            title={"리뷰"}
            prevBtn
            prevFunc={() => {
              setIsWriteModalMo(false);
            }}
          />
        ) : (
          <>
            {reviewDetail && reviewEdit ? (
              <MyPageHeader title={"리뷰"} prevBtn prevFunc={headerPrevFuncReviewEdit} />
            ) : (
              <MyPageHeader
                title={"리뷰"}
                prevBtn
                prevFunc={reviewDetail ? headerPrevFuncReviewDetail : pushMypageHome}
              />
            )}
          </>
        )}
      </Mobile>

      <MyReviewArea>
        {/* page */}
        <PC>
          <MyPageLayOutName />
        </PC>

        {/* content */}
        <FlexLayOut>
          <PC>
            <MyPageLayOutMenu />
          </PC>

          <ContentSection>
            <PC>
              <MyPageLayOutInfo />
            </PC>

            <MyReviewSection>
              {/* content */}
              {reviewDetail && !reviewEdit ? (
                <MyReviewDetail {...MyReviewDetailItems} reviewV={reviewList[reviewIndex]} />
              ) : reviewDetail && reviewEdit ? (
                <ReviewEditForm
                  {...ReviewEditFormItems}
                  reviewV={reviewList[reviewIndex]}
                  returnFunc={headerPrevFuncReviewDetail}
                />
              ) : !reviewDetail ? (
                <>
                  <MyReview
                    {...MyReviewItems}
                    list={reviewList}
                    possibleList={possibleReviewList}
                  />
                  {(query.tab === "0" && possibleReviewList.length > 0) ||
                  (query.tab === "1" && reviewList.length > 0) ? (
                    <NewPagination
                      page={query.tab === "0" ? possiblePage : writtenPage}
                      setPage={query.tab === "0" ? setPossiblePage : setWrittenPage}
                      pageData={query.tab === "0" ? possiblePageData : writtenPageData}
                    />
                  ) : null}
                </>
              ) : null}
              {!isWriteModalMo && <FixMenu />}
            </MyReviewSection>
          </ContentSection>
        </FlexLayOut>
      </MyReviewArea>
    </>
  );
}

const MyReviewArea = styled.div`
  width: 100%;
  padding: 180px 0 200px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const MyReviewSection = styled.div`
  width: 100%;
  /* padding: 20px 0 0;
  
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0 0;
  }

  @media screen and (max-width: 930px) {
    padding: 0 0 calc(100vw * (100 / 428));
  } */
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
