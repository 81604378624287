import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { PC, Mobile } from "../common/mediaStlye";
import { numberWithCommas } from "../common/commonUse";
import ReactHtmlParser from "react-html-parser";
import serverController from "../../server/serverController";
import modal from "../../server/modal";
import openLink from "../../server/openLink";
import { imgURL } from "../../server/appInfoContoller";
import { postLikeProducts } from "../../hooks/likeHooks";

import {
  Container,
  Layout,
  DetailWrap,
  ProdImgWrap,
  Img,
  InformContainer,
  TagWrap,
  Tag,
  InformContents,
  InformDesc,
  InformReceiptLeft,
  InformReceiptRight,
  Row,
  InformPayButtons,
  Title,
  Text,
  ProdPreviewImgWrap,
  InformReceipt,
  ProdPrice,
  PreviewImg,
  PreviewImgCover,
  TitleWrap,
  TitleBtns,
} from "./stylist.element";
import QuestionModal from "../common/pop/QuestionModal";
import SponRequestModal from "../common/pop/SponRequestModal";
import SponDetailCalendar from "./SponDetailCalendar";

import ProductImg01 from "../../img/brand/product_img01.jpg";
import shareIcon from "../../images/stylist/share-icon.png";
import likedIcon from "../../images/stylist/liked_icon.svg";
import likeIcon from "../../images/stylist/like_icon.svg";

const StylistSponserdDetail = () => {
  const { prd_id } = useParams();
  const history = useHistory();
  const [isQuestModal, setIsQuestModal] = useState(false);
  const [isSponModal, setIsSponModal] = useState(false);

  const [data, setData] = useState(null);
  const [isLiked, setIsLiked] = useState(false);

  const getData = async () => {
    await serverController.connectFetchController(
      `product/${prd_id}`,
      "GET",
      null,
      (res) => {
        console.log(res, "제품 상세 res");
        if (res?.result === 1) {
          setData(res);
          setIsLiked(Boolean(res?.product?.like_count));
        }
      }
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const onHandleLike = async () => {
    const res = await postLikeProducts(data?.product?.prd_id);
    setIsLiked(res?.result === 1);
  };

  const pageLinkClick = (id, type, link) => {
    if (type == 3) {
      openLink.openLinkEvent(link);
      // window.open(`${link}`);
    } else {
      alert("런칭 준비중입니다 :D");
      // history.push(`/store/detail/${id}`);
    }
  };

  const checkClick = (id) => {
    serverController.connectFetchController(
      `/purchase/check/${id}`,
      "GET",
      null,
      (res) => {
        console.log(res);
        if (res.result == 1) {
          modal.modalOpen();
          setIsSponModal(true);
          setCurrent(data);
        } else {
          alert("이미 신청한 제품이거나 신청 가능한 재고가 없는 제품 입니다.");
        }
      }
    );
  };

  const [current, setCurrent] = useState(null);

  return (
    <>
      <Container>
        <Layout sub>
          <DetailWrap>
            <ProdImgWrap>
              {data ? (
                <Img src={imgURL + data?.product?.prd_thumbnail} />
              ) : null}
            </ProdImgWrap>
            <InformContainer>
              <TagWrap>
                {data?.product?.prd_tag?.split(",").map((v) => {
                  return <Tag>{v}</Tag>;
                })}
              </TagWrap>

              <TitleWrap>
                <Title>{data?.product?.prd_name}</Title>
                <TitleBtns>
                  <button className="like-button" onClick={onHandleLike}>
                    <img src={isLiked ? likedIcon : likeIcon} alt="좋아요" />
                  </button>
                  {/* <button className="share-button"><img src={shareIcon} alt="좋아요"/></button> */}
                </TitleBtns>
              </TitleWrap>

              <InformContents>
                <InformDesc>
                  {ReactHtmlParser(data?.product?.prd_detail2)}
                </InformDesc>

                <InformReceipt>
                  <InformReceiptLeft>
                    <div>
                      <strong>총수량 : </strong>
                      <span>
                        {numberWithCommas(data?.total?.totalCount ?? 0)}
                      </span>
                    </div>
                    <div>
                      <strong>협찬 중 : </strong>
                      <span>
                        {numberWithCommas(data?.total?.purchaseCount ?? 0)}
                      </span>
                    </div>
                    <div>
                      <strong>현재 수량 : </strong>
                      <span>{numberWithCommas(data?.total?.count ?? 0)}</span>
                    </div>
                  </InformReceiptLeft>

                  <InformReceiptRight>
                    <div>
                      <strong>협찬 가격(일) </strong>
                      <p>
                        <span>
                          {numberWithCommas(data?.product?.prd_days_price ?? 0)}
                        </span>
                        <small>원</small>
                      </p>
                    </div>
                    <div>
                      <strong>구매 가격(개)</strong>
                      <p>
                        <span>
                          {numberWithCommas(data?.product?.prd_list_price ?? 0)}
                        </span>
                        <small>원</small>
                      </p>
                    </div>
                  </InformReceiptRight>
                </InformReceipt>

                {/* <SponDetailCalendar /> */}

                <InformPayButtons>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsQuestModal(true)}
                  >
                    문의하기
                  </span>

                  <div>
                    <button
                      className="inform-pay"
                      onClick={() => {
                        pageLinkClick(
                          data?.product?.prd_id,
                          data?.product?.prd_type,
                          data?.product?.prd_link
                        );
                      }}
                    >
                      구매하기
                    </button>
                    <button
                      className="inform-rent"
                      onClick={() => {
                        checkClick(data?.product?.prd_id);
                      }}
                    >
                      협찬 신청
                    </button>
                  </div>
                </InformPayButtons>

                <PC>
                  <Row mOrder={1}>
                    <ProdPreviewImgWrap>
                      {/* <ProdPreviewImg src={ProductImg01}/>
                                    <ProdPreviewImg src={ProductImg01}/>
                                    <ProdPreviewImg src={ProductImg01}/>
                                    <ProdPreviewImg src={ProductImg01}/> */}
                    </ProdPreviewImgWrap>
                  </Row>
                </PC>
              </InformContents>
            </InformContainer>
          </DetailWrap>
        </Layout>
      </Container>

      {isQuestModal && <QuestionModal setIsQuestModal={setIsQuestModal} />}
      {isSponModal && (
        <SponRequestModal
          current={current}
          setIsSponModal={setIsSponModal}
          data={data}
          getDetailData={getData}
        />
      )}
    </>
  );
};

export default StylistSponserdDetail;
function ProdPreviewImg({ src }) {
  return (
    <PreviewImg>
      <Img src={src} />
      <PreviewImgCover />
    </PreviewImg>
  );
}
