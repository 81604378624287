import React, { useState } from 'react';
import { PC, Mobile } from '../../common/mediaStlye';

import {
    UserInfoBox,
    UserInfoTabs,
    UserInfoTab,
    TabContents,
    MobileTab    
} from './mypage.elements';
import TabContentUser from './TabContentUser';
import TabContentFaq from './TabContentFaq';
import TabContentItems from './TabContentItems';

import mypageArrowIcon from '../../../images/mypage-arrow.png';
import Modal from '../../../server/modal';

const tabTitles = ["회원정보", "문의 목록", "보관 콘텐츠 / 아이템", "회원탈퇴"];

const MyPageUserWalaInfo = ({setIsExitModal}) => {
    const [activeIndex, setActiveIndex] = useState("0");

    const tabRender = () => {
        switch(activeIndex) {
            case "0":
                return (
                    <TabContents>                
                        <TabContentUser />
                    </TabContents>
                )
            case "1":
                return (
                    <TabContents>                
                        <TabContentFaq />    
                    </TabContents>
                )    
            case "2": 
                return (
                    <TabContents last>
                        <TabContentItems />
                    </TabContents>
                )
            default: 
                return;
        }
    }
    const tabClick = (i) => {
        if(i !== 3){
            setActiveIndex(""+ i)
        }else{
            Modal.modalOpen();
            setIsExitModal(true);
        }
    }

    return (
        <>
        <UserInfoBox>
            <PC>
            <UserInfoTabs>
                {
                    tabTitles.map((tab, i) => (
                        <UserInfoTab
                        key={i}
                        selected={i == activeIndex}
                        onClick={() => tabClick(i)}
                        >
                            {tab}
                        </UserInfoTab>
                    ))
                }               
            </UserInfoTabs>            
            {tabRender()}       
            </PC>            
        </UserInfoBox>        
        </>
    )
}

export default MyPageUserWalaInfo

            

