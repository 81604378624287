//react
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import { useState, useEffect } from "react";
import styled from "styled-components";

// controller
import serverController, { ip } from "../../../server/serverController";
import qs from "qs";

//img
import IconAnswerArrow from "../../../img/icon/icon_answer_arrow.svg";
import IconBack from "../../../img/icon/icon_header_back.svg";

// modal
import MyPageDefaultModal from "../../common/modal/MyPageDefaultModal";
import { imgURL } from "../../../server/appInfoController";

// component
import MyPageLayOutMenu from "../MyPageLayOutMenu";
import MyPageLayOutName from "../MyPageLayOutName";
import MyPageLayOutInfo from "../MyPageLayOutInfo";

export default function MyProductInquiryDetail() {
  //페이지 이동
  const history = useHistory();
  const goBackFunc = () => {
    history.goBack();
  };
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const [inquiryRemove, setInquiryRemove] = useState(false);
  const [inqInfo, setInqInfo] = useState({});

  // get inquiry detail info
  const getInquiryDetail = () => {
    serverController.connectFetchController(`inquiry/${query.inqId}`, "GET", null, (res) => {
      console.log(res, "res");
      if (res.result === 1) {
        setInqInfo(res.inquiry);
      }
    });
  };

  // delete review
  const deleteReview = () => {
    var header = new Headers();
    header.append("Content-Type", "application/json");
    var rawBody = JSON.stringify({
      inq_id: query.inqId.toString(),
    });

    var options = {
      method: "DELETE",
      body: rawBody,
      headers: header,
      redirect: "follow",
    };

    fetch(`${ip}/inquiry/store`, options)
      .then((response) => response.json())
      .then((result) => {
        if (result.result === 1) {
          history.push(`/store/mypage/productInquiry?page=1`);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getInquiryDetail();
  }, []);

  return (
    <Container>
      {/* TODO PUB :: MyReviewDetail.js 에서 가져옴. */}
      {inquiryRemove && (
        <MyPageDefaultModal
          text={"해당 문의를 삭제하시겠습니까?"}
          trueFunc={deleteReview}
          falseFunc={() => setInquiryRemove(false)}
        />
      )}

      {/* page */}
      <PC>
        <MyPageLayOutName />
      </PC>

      {/* content */}
      <FlexLayOut>
        <PC>
          <MyPageLayOutMenu />
        </PC>

        <ContentSection>
          <PC>
            <MyPageLayOutInfo />
          </PC>

          {/* 문의 내역 상세 */}
          <Wrap>
            <PageButtonWrap>
              <PageButtonArea
                onClick={() => {
                  goBackFunc();
                }}
              >
                <PageButton>
                  <img src={IconBack} alt="back-img" />
                </PageButton>
                <PageButtonTitle>목록으로</PageButtonTitle>
              </PageButtonArea>
            </PageButtonWrap>
            <ContentsWrap>
              <ListInner>
                <ProductInfoWrap>
                  <ImgBox
                    onClick={() => {
                      history.push(`/store/detail/${inqInfo.prd_id}`);
                    }}
                  >
                    <img src={imgURL + inqInfo?.prd_thumbnail} alt="product-img" />
                  </ImgBox>
                  <TextBox>
                    <InquirySatisfactionBox>
                      <InquirySatisfactionList>
                        <InquiryDate>
                          {inqInfo?.inq_date?.split("T")[0]?.replaceAll("-", ".")}
                        </InquiryDate>
                      </InquirySatisfactionList>
                      <InquirySatisfactionList
                        onClick={() => {
                          getInquiryDetail(inqInfo?.inq_id);
                        }}
                      >
                        <InquirySatisfaction complete={inqInfo?.inq_answer !== 1}>
                          {inqInfo?.inq_answer === 1 ? "답변대기중 " : "답변완료 "}
                          &gt;
                        </InquirySatisfaction>
                      </InquirySatisfactionList>
                    </InquirySatisfactionBox>

                    <Brand>{inqInfo?.bc_title || "(BRAND)"}</Brand>
                    <ProductName>{inqInfo?.prd_name || "(상품 이름 없음)"}</ProductName>
                  </TextBox>
                </ProductInfoWrap>
              </ListInner>
              <BackgroundGray />
              <InquiryTextWrap>
                <InquiryTextBox>
                  <InquiryTextList>
                    <InquiryText>
                      [
                      {inqInfo?.inq_type === 1
                        ? "신상품 요청"
                        : inqInfo?.inq_type === 2
                        ? "상품정보 요청"
                        : inqInfo?.inq_type === 3
                        ? "상품품절 입고"
                        : inqInfo?.inq_type === 4
                        ? "스타일리스트 문의"
                        : inqInfo?.inq_type === 5
                        ? "홈페이지 문의"
                        : inqInfo?.inq_type === 6
                        ? "1대1 문의"
                        : null}
                      ] {inqInfo?.inq_title}
                    </InquiryText>
                  </InquiryTextList>
                  <InquiryTextList>
                    <InquiryText>{inqInfo?.inq_content}</InquiryText>
                    {inqInfo.inq_images && (
                      <InquiryImgBoxList>
                        {inqInfo.inq_images?.split(",") &&
                          inqInfo.inq_images?.split(",")?.length > 0 &&
                          inqInfo.inq_images.split(",").map((el, idx) => {
                            return (
                              <InquiryImgBox>
                                <img key={idx + "-imgB"} src={imgURL + el} alt="inqInfo-img" />
                              </InquiryImgBox>
                            );
                          })}
                      </InquiryImgBoxList>
                    )}
                  </InquiryTextList>
                </InquiryTextBox>
                {inqInfo?.inq_answer === 2 && (
                  <AnswerTextBox>
                    <AnswerIconImg>
                      <img src={IconAnswerArrow} alt="" />
                    </AnswerIconImg>
                    <AnswerText>{inqInfo?.inq_answer_content}</AnswerText>
                  </AnswerTextBox>
                )}
              </InquiryTextWrap>
            </ContentsWrap>
            <ButtonListWrap>
              <InquiryDeleteButton
                type="button"
                onClick={() => {
                  setInquiryRemove(true);
                }}
              >
                삭제
              </InquiryDeleteButton>
            </ButtonListWrap>
          </Wrap>
        </ContentSection>
      </FlexLayOut>
    </Container>
  );
}

const Container = styled.div`
  padding: 180px 0 144px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (144 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const Wrap = styled.div`
  width: 100%;
`;

// 콘텐츠
const ContentsWrap = styled.div``;

const BackgroundGray = styled.div`
  width: 100%;
  background-color: #f9f9f9;
  height: 8px;
`;

const ListInner = styled.div`
  position: relative;
  width: 100%;
  padding: 30px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 990px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (990 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;

const ImgBox = styled.div`
  width: 70px;
  height: 70px;
  background-color: #fff;
  border: 1px solid #eee;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (70 / 1320));
    height: calc(100vw * (70 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (70 / 428));
    height: calc(100vw * (70 / 428));
  }
`;
const TextBox = styled.div`
  width: calc(100% - 80px);

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (80 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (298 / 428));
  }
`;
const Brand = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 3px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (3 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (6 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const InquirySatisfactionBox = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const InquirySatisfactionList = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 1px;
    height: 14px;
    margin: 0 12px;
    background-color: #ddd;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      height: calc(100vw * (14 / 1320));
      margin: 0 calc(100vw * (12 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      height: calc(100vw * (14 / 428));
      margin: 0 calc(100vw * (12 / 428));
    }
  }
`;
const InquirySatisfaction = styled.span`
  color: ${(props) => (props.complete ? "#333" : "#ACACAC")};
  font-weight: ${(props) => (props.complete ? "600" : "400")};
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const InquiryDate = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

const InquiryTextWrap = styled.div``;

const InquiryTextBox = styled.div`
  padding: 20px 0;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) calc(100vw * (25 / 428));
  }
`;
const InquiryTextList = styled.div`
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eee;
    margin: 20px 0;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 1320)) 0;
    }
  }

  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 428)) 0;
    }
  }
`;
const InquiryText = styled.p`
  width: 990px;
  margin: 0 auto;
  color: #333;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (990 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (15 / 428));
  }
`;

const InquiryImgBoxList = styled.div`
  display: flex;
  align-items: center;
  width: 990px;
  margin: 15px auto 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (990 / 1320));
    margin: calc(100vw * (15 / 1320)) auto 0;
  }
  @media screen and (max-width: 930px) {
    width: auto;
    margin-top: calc(100vw * (15 / 428));
  }
`;
const InquiryImgBox = styled.div`
  width: 100px;
  height: 100px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    height: calc(100vw * (100 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
    height: calc(100vw * (120 / 428));

    &:not(:last-child) {
      margin-right: calc(100vw * (9 / 428));
    }
  }
`;

const AnswerTextBox = styled(InquiryTextBox)`
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
  padding: 25px 25px 50px;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (25 / 1320)) calc(100vw * (25 / 1320)) calc(100vw * (50 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (25 / 428)) calc(100vw * (25 / 428)) calc(100vw * (50 / 428));
    margin-top: 0;
  }
`;
const AnswerText = styled(InquiryText)`
  width: calc(100% - 34px);
  margin-top: 5px;

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (34 / 1320)));
    margin-top: calc(100vw * (5 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (344 / 428));
    margin-top: calc(100vw * (15 / 428));
  }
`;
const AnswerIconImg = styled.div`
  width: 24px;
  height: 24px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;

// button
const ButtonListWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (50 / 1320));
  }
  @media screen and (max-width: 930px) {
    justify-content: space-between;
    padding: 0 calc(100vw * (25 / 428));
    margin-top: calc(100vw * (20 / 428));
  }
`;
const InquiryDeleteButton = styled.button`
  width: 184px;
  height: 45px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (184 / 1320));
    height: calc(100vw * (45 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (184 / 428));
    height: calc(100vw * (45 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;

// pc - page button
const PageButtonWrap = styled.div`
  padding: 14px 20px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (14 / 1320)) calc(100vw * (20 / 1320));
  }
`;
const PageButtonArea = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;
const PageButton = styled.div`
  width: 32px;
  height: 32px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
`;
const PageButtonTitle = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 15px;
  margin-left: 5px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (5 / 1320));
  }
`;
