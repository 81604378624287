//react
import { useState, useRef, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import { imgURL } from "../../../server/appInfoController";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { postLikeProducts } from "../../../../hooks/likeHooks";

//css
import styled from "styled-components";
import IconWishOn from "../../../img/icon/icon_wish_on.svg";
import IconWishOff from "../../../img/icon/icon_wish_off.svg";

//page
import Commas from "../../../server/Commas";
import SoldOutCmp from "../../product/SoldOutCmp";

export default function ProductListThree({ value, height, setPop = null, setLoginPop }) {
  const history = useHistory();
  const { isLogged } = useSelector((state) => state.userData);
  const [isWish, setIsWish] = useState(value.like_id !== 0);
  const [touch, setTouch] = useState(false);

  const detailClick = () => {
    if (setPop) setPop(false);
    history.push(`/store/detail/${value.prd_id}`);
  };

  const onHandleLike = async () => {
    const res = await postLikeProducts(value.prd_id);
    setIsWish(res?.result === 1);
  };
  return (
    <Container className="product_box">
      <BestNewImgBox className={height}>
        <BestNewImg
          src={`${imgURL}${value?.prd_thumbnail?.split(",")[0]}`}
          alt="hot-img"
          onClick={detailClick}
        />
        {value?.prd_featured !== 1 && <SoldOutCmp />}
        <WishIcon
          src={isWish ? IconWishOn : IconWishOff}
          alt="wish"
          onClick={() => {
            if (isLogged) {
              onHandleLike();
            } else {
              return setLoginPop(true);
            }
          }}
          onTouchStart={() => {
            setTouch(true);
          }}
          onTouchEnd={() => {
            setTouch(false);
          }}
          touch={touch}
        />
      </BestNewImgBox>
      <BestNewTextWrap onClick={detailClick}>
        <BestNewImgTextBox>
          <BestNewImgTitle>{value.bc_title || "(BRAND)"}</BestNewImgTitle>
          <BestNewImgText>{ReactHtmlParser(value?.prd_name)}</BestNewImgText>
        </BestNewImgTextBox>
        {value?.prd_sale_rate === 0 ? (
          <PriceBox>
            <OriginalPrice original>.</OriginalPrice>
            <BestNewPriceWrap>
              <Price>{Commas.numberWithCommas(value?.prd_list_price)}원</Price>
            </BestNewPriceWrap>
          </PriceBox>
        ) : (
          <PriceBox>
            <OriginalPrice>{Commas.numberWithCommas(value?.prd_price)}원</OriginalPrice>
            <BestNewPriceWrap>
              <SaleRate>{Commas.numberWithCommas(value?.prd_sale_rate)}%</SaleRate>
              <Price>{Commas.numberWithCommas(value?.prd_list_price)}원</Price>
            </BestNewPriceWrap>
          </PriceBox>
        )}
      </BestNewTextWrap>
    </Container>
  );
}

const Container = styled.div`
  align-self: stretch;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 120px;

  &:not(:last-child) {
    margin-right: 20px;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (120 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (20 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
    padding-bottom: calc(100vw * (20 / 428));

    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;
const BestNewImgBox = styled.div`
  position: relative;
  width: 100%;
  height: 120px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #f8f8f8;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (120 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (120 / 428));
    width: calc(100vw * (120 / 428));
  }
`;
const BestNewImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const BestNewTextWrap = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 131px;
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (131 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (131 / 428));
  }
`;
const BestNewImgTextBox = styled.div``;
const BestNewImgTitle = styled.p`
  font-size: 14px;
  font-weight: 800;
  padding-top: 10px;
  color: #333333;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    padding-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    padding-top: calc(100vw * (10 / 428));
  }
`;
const BestNewImgText = styled.p`
  font-size: 15px;
  height: 48px;
  padding-top: 5px;
  color: #333333;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding-top: calc(100vw * (6 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (41 / 428));
    font-size: calc(100vw * (15 / 428));
    padding-top: calc(100vw * (5 / 428));
    word-break: keep-all;
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
const WishIcon = styled.img`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: block;
  width: 24px;
  transition: ease 0.3s all;

  @media screen and (max-width: 1320px) {
    bottom: calc(100vw * (10 / 1320));
    right: calc(100vw * (10 / 1320));
    width: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    bottom: calc(100vw * (10 / 428));
    right: calc(100vw * (10 / 428));
    width: calc(100vw * (24 / 428));
    ${({ touch }) =>
    touch &&
    `
      scale : 0.85;
    `}
  }
`;
const BestNewPriceWrap = styled.div`
  display: flex;
  align-items: center;
`;
const SaleRate = styled.p`
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  padding: 2px 4.5px;
  color: #333333;
  border-radius: 3px;
  background: #fdf251;
  line-height: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    padding: calc(100vw * (2 / 1320)) calc(100vw * (4 / 1320));
    line-height: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (12 / 428));
    padding: calc(100vw * (4 / 428)) calc(100vw * (4 / 428)) 0;
    line-height: calc(100vw * (18 / 428));
  }
`;
const Price = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin: 0 5px;
  color: #333333;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin: 0 calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin: 0 calc(100vw * (5 / 428));
  }
`;
const PriceBox = styled.div`
  min-height: 43.5px;
  /* margin-top: 10px; */

  @media screen and (max-width: 1320px) {
    min-height: calc(100vw * (43.5 / 1320));
    /* margin-top: calc(100vw * (10 / 1320)); */
  }

  @media screen and (max-width: 930px) {
    min-height: calc(100vw * (43.5 / 428));
    /* margin-top: calc(100vw * (10 / 428)); */
  }
`;
const OriginalPrice = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #acacac;
  text-decoration: line-through;
  padding-bottom: 4px;
  ${({ original }) => original && `color :transparent;`}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    padding-bottom: calc(100vw * (4 / 428));
  }
`;
