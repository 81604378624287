import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { Mobile } from "../../MediaQuery";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { imgURL } from "../../../server/appInfoContoller";

//img
import iconMore from "../../img/icon/icon_more_button.svg";
import BannerArrow from "../../img/icon/icon_bannerArrow.svg";
import DefaultImg from "../../img/icon/loading_default_img.svg";
import ContentsDetailMain from "../contents/ContentsDetailMain";
import ContentProduct from "../main/ContentProduct";

SwiperCore.use([Navigation]);

export default function StoreSearchContentSection({ contentsList }) {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const history = useHistory();

  const [isPcContentsClick, setIsPcContentsClick] = useState(false);

  return (
    <>
      {isPcContentsClick && <ContentsDetailMain setContentsDetail={setIsPcContentsClick} />}
      <Container>
        <Mobile>
          {contentsList.length > 0 && (
            <BestNewWrap>
              <TitleWrap>
                <TitleText>CONTENTS</TitleText>
                <TitleMoreTextButton
                  onClick={() => history.push(`/store/search?keyword=${query.keyword}&index=2`)}
                >
                  <TitleMoreText>더 보기</TitleMoreText>
                  <TitleMoreImg src={iconMore} alt="more-icon" />
                </TitleMoreTextButton>
              </TitleWrap>
              <BestNewListWrap>
                <BestNewInner>
                  <Swiper
                    slidesPerView={1}
                    onSlideChange={(e) => setCurrentIndex(e.activeIndex)}
                    navigation={{
                      prevEl: document.querySelector("#prev-arrow"),
                      nextEl: document.querySelector("#next-arrow"),
                    }}
                  >
                    {contentsList.length > 1 && (
                      <SwiperNavWrap>
                        <SwiperNavBox>
                          <SwiperNavL id="prev-arrow">
                            <img src={BannerArrow} alt="arrow" />
                          </SwiperNavL>
                          <SwiperNavR id="next-arrow">
                            <img src={BannerArrow} alt="arrow" />
                          </SwiperNavR>
                        </SwiperNavBox>
                      </SwiperNavWrap>
                    )}
                    {contentsList.map((value) => {
                      return (
                        <SwiperSlide key={`${value.contents_id}-mainContents`}>
                          <MainContentWrap>
                            <MainContentBox>
                              <img
                                src={
                                  value?.contents_main
                                    ? `${imgURL}${value?.contents_main}`
                                    : DefaultImg
                                }
                                alt={value?.contents_main ? "contents-img" : "default-img"}
                              />
                            </MainContentBox>
                            <ContentTitle>{value?.contents_title}</ContentTitle>
                          </MainContentWrap>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </BestNewInner>
              </BestNewListWrap>
              {contentsList.length > 0 && (
                <ContentProductWrap>
                  <ContentProduct value={contentsList[currentIndex]} />
                </ContentProductWrap>
              )}
            </BestNewWrap>
          )}
        </Mobile>
      </Container>
    </>
  );
}

const Container = styled.div`
  padding: 80px 0;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (80 / 1320)) 0;
    width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (40 / 428)) 0 calc(100vw * (20 / 428));
    width: calc(100vw * (378 / 428));
  }
`;
const BestNewWrap = styled.div``;
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1320px) {
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    margin-bottom: calc(100vw * (16 / 428));
  }
`;
const TitleText = styled.h2`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const TitleMoreTextButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const TitleMoreText = styled.span`
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const TitleMoreImg = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 5px;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
    margin-left: calc(100vw * (5 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
    margin-left: calc(100vw * (5 / 428));
  }
`;
const BestNewListWrap = styled.div`
  width: 100%;
  padding-top: 20px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: 0;
  }
`;
const BestNewInner = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const MainContentWrap = styled.div`
  width: 406px;
  cursor: pointer;
  margin-right: 11px;
  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (406 / 1320));
    margin-right: calc(100vw * (11 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const ContentTitle = styled.p`
  font-size: 15px;
  margin-top: 10px;
  color: #333333;
  min-height: 48px;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-top: calc(100vw * (10 / 1320));
    min-height: calc(100vw * (48 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-top: calc(100vw * (10 / 428));
    line-height: calc(100vw * (21 / 428));
    min-height: calc(100vw * (43 / 428));
  }
`;
const MainContentBox = styled.div`
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
  overflow: hidden;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (300 / 1320));
    margin-bottom: calc(100vw * (10 / 1320));
    border-radius: calc(100vw * (17 / 1320));
    & p {
      font-size: calc(100vw * (16 / 1320));
      margin-top: calc(100vw * (15 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (378 / 428));
    width: calc(100vw * (378 / 428));
    margin-bottom: calc(100vw * (10 / 428));
    border-radius: calc(100vw * (20 / 428));
    & p {
      font-size: calc(100vw * (13 / 428));
      margin-top: calc(100vw * (12 / 428));
      line-height: 1.3;
    }
  }
`;
const ContentProductWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 15px;
  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (12 / 428));
  }
`;
const SwiperNavWrap = styled.div`
  position: absolute;
  z-index: 3;
  @media screen and (max-width: 930px) {
    top: calc(100vw * (160 / 428));
  }
`;
const SwiperNavBox = styled.div`
  width: 1240px;
  position: relative;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const SwiperNavL = styled.div`
  height: 70px;
  width: 70px;
  z-index: 3;
  transform: rotate(180deg);
  position: absolute;
  cursor: pointer;
  & > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (70 / 1320));
    width: calc(100vw * (70 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (48 / 428));
    width: calc(100vw * (48 / 428));
  }
`;
const SwiperNavR = styled.div`
  height: 70px;
  width: 70px;
  z-index: 3;
  right: 0;
  position: absolute;
  cursor: pointer;
  & > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (70 / 1320));
    width: calc(100vw * (70 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (48 / 428));
    width: calc(100vw * (48 / 428));
  }
`;
