import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import { HelmetProvider } from "react-helmet-async";
import { CookiesProvider } from "react-cookie";
import { RecoilRoot } from "recoil";

// IE9의 경우
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import { BrowserRouter } from "react-router-dom";

if (process.env.NODE_ENV === "development") {
  const { worker } = require("./mock/broswer");
  worker.start();
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <React.StrictMode>
          <CookiesProvider>
            <HelmetProvider>
              <RecoilRoot>
                <App />
              </RecoilRoot>
            </HelmetProvider>
          </CookiesProvider>
        </React.StrictMode>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
