import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import serverController from "../../server/serverController";
import styled from "styled-components";
import PlanListWrap from "./PlanListWrap"
import { Mobile, PC } from "../../MediaQuery";
import { imgURL } from "../../../server/appInfoContoller";
import HtmlParser from "react-html-parser";
import axios from "axios"
import { ip } from "../../server/serverController"
import Header from "../common/Header"
import FixMenu from "../common/FixMenu"

export default function PlanShopSection() {
  const history = useHistory();
  const location = useLocation();
  const { event_id } = useParams();
  const [event, setEvent] = useState([]);
  const [eventCategory, setEventCategory] = useState([]);
  const [eventDate, setEventDate] = useState("");
  const [tabIndex, setTabIndex] = useState("");
  const [categoryArr, setCategoryArr] = useState([]);
  const [isChkUrl, setIsChkUrl] = useState(false);

  const getPlanData = async () => {
    let result = await serverController.connectFetchController(`api/event/${event_id}${location.search}`, "GET", null);
    if (result?.result === 0) {
      alert(result.message);
      history.push("/store");
    }
    if (result?.result === 1) {
      setEvent(result.event);
      setEventDate(eventDateFunc(result.event));
      setEventCategory(result.eventCategoryList);
      chkAbleDate(result.event.eventStartDatetime, result.event.eventEndDatetime);
      setTabIndex(0);
      setCategoryArr(addCategoryArrFunc(result.eventCategoryList));
    }
  };

  const chkAbleDate = (startDate, endDate) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);
    const now = new Date();
    if (newStartDate <= now && newEndDate >= now) {
    } else {
      alert(`기획전 진행 기간이 아닙니다.`);
      history.push(`/store`);
    }
  };

  const eventDateFunc = (event) => {
    const startYear = event.eventStartDatetime.substr(0, 4);
    const startMonth = event.eventStartDatetime.substr(5, 2);
    const startDate = event.eventStartDatetime.substr(8, 2);
    const endYear = event.eventEndDatetime.substr(0, 4);
    const endMonth = event.eventEndDatetime.substr(5, 2);
    const endDate = event.eventEndDatetime.substr(8, 2);
    const printDate = `${startYear}.${startMonth}.${startDate}~${endYear}.${endMonth}.${endDate}`;
    return printDate
  };

  const addCategoryArrFunc = (categoryListArr) => {
    return categoryListArr.map(category => category.eventCategoryId);
  };

  useEffect(() => {
    getPlanData();
  }, []);

  // useEffect(() => {
  //   chkAbleDate(event.eventDatetime, event.eventEndDatetime)
  // }, [event]);

  return (
    <>
      <Header back />
      <FixMenu />
      {event !== "" &&
        <Container
        >
          <PlanDescWrap>
            <PlanTitle>{event.eventTitle}</PlanTitle>
            <PlanDate>{eventDate}</PlanDate>
          </PlanDescWrap>
          <PlanMainImgWrap>
            <PC>
              {event && HtmlParser(event.eventContent)}
            </PC>
            <Mobile>
              {event && HtmlParser(event.eventContent)}
            </Mobile>
          </PlanMainImgWrap>
          <PlanListWrap eventCategory={eventCategory} tabIndex={tabIndex} setTabIndex={setTabIndex} categoryArr={categoryArr} />
        </Container>
      }
    </>
  )
}

const Container = styled.section`
  position: relative;
  width: 1240px;
  margin: 0 auto;
  padding-top: 180px;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    padding-top: calc(100vw * (180 / 1320));
    font-size: calc(100vw * (16 / 1320));

  };

  @media screen and (max-width: 930px) {
    width: 100%;
    padding-top: calc(100vw * (20 / 428));
    font-size: calc(100vw * (16 / 428));
  };
`

const PlanDescWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 0 20px;

  @media screen and (max-width: 1320px) {
    padding: 0 0 calc(100vw * (20/ 1320));
  };

  @media screen and (max-width: 930px) {
    padding: 0 calc(100vw * (15 / 428)) calc(100vw * (10 / 428));
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  };
  
`
const PlanTitle = styled.h1`
  font-size: 20px;
  font-weight: bold;
  
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20/ 1320));
  };

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    padding-bottom: calc(100vw * (5 / 428));
  };
`
const PlanDate = styled.p`
  color: #999;
`
const PlanMainImgWrap = styled.div`
  width: 1080px;
  margin: 0 auto;
  min-height: 200px;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1080 / 1320));
    min-height: calc(100vw * (200 / 1320));
  };

  @media screen and (max-width: 930px) {
    width: calc(100vw * (382 / 428));
    min-height: calc(100vw * (100 / 428));
    text-align: center;
    width: 100%;
  };
  & img {
    width: 100% !important;
    height: auto !important;
  }
   

`