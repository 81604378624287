import React from "react";
import { PC, Mobile } from "../../MediaQuery";
import styled from "styled-components";

import Header from "../../components/common/header/Header";
import StylistPayment from "../../components/stylist/StylistPayment";
import Footer from "../../components/common/footer/Footer";
import FooterMenu from "../../components/common/footer/FooterMenu";

const StylistPaymentPage = () => {
  return (
    <Container>
      <Header isPay />
      <StylistPayment />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
    </Container>
  );
};
const Container = styled.div`
  @media screen and (max-width: 1024px) {
    padding-bottom: calc(100vw * (68 / 428));
  }
`;

export default StylistPaymentPage;
