//react
import React from "react";

//css
import styled from "styled-components";

// components
import BrandListContents from "./BrandListContents";

export default function AllBrand(props) {
  const { brandsList, setLoginPop } = props;
  const tab = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  return (
    <Container>
      <AllBrandWrap>
        {tab.map((alphabetCate, idx) => {
          const brandList = brandsList.filter(
            (brandName) =>
              brandName?.bcTitle?.charAt(0)?.toLowerCase() === alphabetCate.toLowerCase()
          );
          return (
            <div key={`${alphabetCate}-brandName-${idx}`}>
              {brandList.length > 0 && (
                <AllBrandBox>
                  <AllBrandTitleWrap>
                    <AllBrandTitle>
                      <AllBrandTitleText>{alphabetCate}</AllBrandTitleText>
                      {/* <AllBrandTitleLength>({brandList.length})</AllBrandTitleLength> */}
                    </AllBrandTitle>
                  </AllBrandTitleWrap>
                  <AllBrandListWrap>
                    {brandList.map((brandValue, index) => {
                      return (
                        <BrandListContents
                          value={brandValue}
                          key={`${brandValue.bcId}-brandList-${index}`}
                          setLoginPop={setLoginPop}
                        />
                      );
                    })}
                  </AllBrandListWrap>
                </AllBrandBox>
              )}
            </div>
          );
        })}
      </AllBrandWrap>
    </Container>
  );
}
const Container = styled.div`
  width: 1240px;
  padding-bottom: 540px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    padding-bottom: calc(100vw * (540 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding-bottom: calc(100vw * (196 / 428));
  }
`;
const AllBrandWrap = styled.div`
  width: 100%;
`;
const AllBrandTitleWrap = styled.div`
  display: inline-block;
`;
const AllBrandTitle = styled.div``;
const AllBrandTitleText = styled.span`
  color: #333333;
  font-size: 24px;
  font-weight: 700;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const AllBrandTitleLength = styled.span`
  color: #999999;
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-left: calc(100vw * (8 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (6 / 428));
  }
`;
const AllBrandListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  & .brand-list:not(:nth-child(3n)) {
    margin-right: 53px;
  }

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (15 / 1320));

    & .brand-list:not(:nth-child(3n)) {
      margin-right: calc(100vw * (53 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    display: block;
    margin-top: calc(100vw * (5 / 428));

    & .brand-list:not(:nth-child(3n)) {
      margin-right: 0;
    }
  }
`;
const AllBrandBox = styled.div`
  padding-top: 120px;

  &:not(:last-child) {
    margin-bottom: 80px;
  }

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (120 / 1320));
    &:not(:last-child) {
      margin-bottom: calc(100vw * (80 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (80 / 428));

    &:not(:last-child) {
      margin-bottom: calc(100vw * (50 / 428));
    }
  }
`;

const SubTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1300px;
  margin: 30px auto;
  padding: 30px 0;
  border-bottom: 1px solid #646464;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
    margin: calc(100vw * (30 / 1320)) auto;
    padding: calc(100vw * (30 / 1320)) 0;
    border-bottom: 1px solid #646464;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (20 / 428)) auto;
    padding: calc(100vw * (20 / 428)) 0;
    border-bottom: 1px solid #646464;
  }
`;
const SubTitle = styled.h2`
  font-size: 30px;
  font-weight: 900;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const FilterWrap = styled.div`
  width: auto;
`;
const FilterText = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #000;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const FilterImg = styled.img`
  width: 12px;
  margin-left: 4px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (12 / 1320));
    margin-left: calc(100vw * (4 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (12 / 428));
    margin-left: calc(100vw * (4 / 428));
  }
`;
//logo
const AllBrandLogoWrap = styled.div`
  width: 1300px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
  justify-content: flex-start;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
  }
`;
const AllLogoListWrap = styled.div`
  width: calc(100% / 5);

  @media screen and (max-width: 930px) {
    width: calc(100% / 3);
  }
`;
const LogoListInner = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (40 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    flex-wrap: wrap;
    justify-content: center;
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const LogoListImgWrap = styled.div`
  width: 90px;
  height: 90px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (90 / 1320));
    height: calc(100vw * (90 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (90 / 428));
    height: calc(100vw * (90 / 428));

    overflow: hidden;
    box-shadow: 0 calc(100vw * (3 / 428)) calc(100vw * (6 / 428)) 0 rgba(0, 0, 0, 0.16);
  }
`;
const LogoListImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const LogoListTextWrap = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const LogoListText = styled.p`
  font-size: 18px;
  padding-left: 20px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    padding-left: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    text-align: center;
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const MoreBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (100 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (30 / 428));
  }
`;
const MoreBtn = styled.p`
  font-size: 15px;
  padding: 12px 40px;
  border: 1px solid #646464;
  color: #646464;
  cursor: pointer;
  &:hover {
    background: #646464;
    color: #fff;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (12 / 1320)) calc(100vw * (40 / 1320));
    border: calc(100vw * (1 / 1320)) solid #646464;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (12 / 428)) calc(100vw * (40 / 428));
    border: calc(100vw * (1 / 428)) solid #646464;
  }
`;
