import React, { forwardRef } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import IconCategoryHide from "store_src/img/icon/icon_category_hide.svg";
import IconCategoryShow from "store_src/img/icon/icon_category_show.svg";
import { crypto } from "utils";

function CategoryAsideComp({ item }, ref) {
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const { main_cat, sub_cat } = useParams();
  const location = useLocation();
  const decodedMainCat = crypto.decryptionBASE64(main_cat);
  const decodedSubCat = crypto.decryptionBASE64(sub_cat);

  const history = useHistory();

  const scrollFunc = () => {
    let height =
      parseInt(window.getComputedStyle(ref?.current.firstChild).marginBottom) + ref?.current.firstChild.offsetHeight;
    window.scrollTo(0, window.pageYOffset + ref?.current.getBoundingClientRect().top - height * 2);
  };

  React.useEffect(() => {
    if (decodedMainCat === String(item.catId)) {
      return setIsCategoryOpen(true);
    }
    setIsCategoryOpen(false);
  }, [location.pathname]);

  return (
    <S.CategoryAside>
      <CategoryMainMenu active={!isCategoryOpen}>
        <CategoryMainMenuText
          onClick={() => setIsCategoryOpen((prev) => !prev)}
          active={decodedMainCat === String(item.catId)}
        >
          {item.catName}
          <img src={isCategoryOpen ? IconCategoryHide : IconCategoryShow} alt="icon" />
        </CategoryMainMenuText>

        {isCategoryOpen && (
          <CategorySubMenuArea>
            {item?.subCategory.map((subCateItem) => {
              return (
                <CategorySubMenuList
                  key={subCateItem.catId}
                  onClick={() => {
                    history.replace(
                      `/store/category/${crypto.encryptionBASE64(item.catId)}/${crypto.encryptionBASE64(
                        subCateItem.catId,
                      )}`,
                    );
                    scrollFunc();
                  }}
                >
                  {decodedSubCat === String(subCateItem.catId) ? (
                    <CategorySubMenuAll>{subCateItem.catName}</CategorySubMenuAll>
                  ) : (
                    <CategorySubMenu>{subCateItem.catName}</CategorySubMenu>
                  )}
                </CategorySubMenuList>
              );
            })}
          </CategorySubMenuArea>
        )}
      </CategoryMainMenu>
    </S.CategoryAside>
  );
}

const CategoryAside = React.forwardRef(CategoryAsideComp);
export default CategoryAside;

const CategoryMainMenu = styled.div`
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  padding: 18px 0;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (18 / 1320)) 0;

    &::after {
      width: calc(100vw * (24 / 1320));
      height: calc(100vw * (24 / 1320));
    }
  }
`;

const CategorySubMenu = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;

const CategorySubMenuAll = styled(CategorySubMenu)`
  font-weight: 500;
  text-decoration: underline;
`;

const CategorySubMenuArea = styled.ul`
  margin-bottom: 27px;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (27 / 1320));
  }
`;

const CategoryMainMenuText = styled.span`
  color: #999999;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => (props.active ? "#000" : "#999")};
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
`;

const CategorySubMenuList = styled.li`
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 1320));
    }
  }
`;

const S = {
  CategoryAside: styled.div`
    border-bottom: 1px solid #eeeeee;
  `,
};
