//react
import { Link, useHistory, useParams } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState, useEffect } from "react";

//css
import styled from "styled-components";

//img
import iconArrow from "../../img/icon/icon_filter_arrow.png";
import IconPrevBtn from "../../img/icon/icon_pagination_prev_bk.png";
import IconNextBtn from "../../img/icon/icon_pagination_next.png";
// import HotImg1 from "../../../images/content/content-detail-prod1.png"
// import HotImg2 from "../../../images/content/content-detail-prod2.png"
// import HotImg3 from "../../../images/content/content-detail-prod3.png"
// import HotImg4 from "../../../images/category/category-prod-2.png"
import HotImg1 from "../../../images/content/content-detail-prod1.png";
import HotImg2 from "../../../images/content/content-detail-prod2.png";
import HotImg3 from "../../../images/content/content-detail-prod3.png";
import HotImg4 from "../../../images/category/category-prod-1.png";

import Zigzag from "../../img/borderBottom_zigzag_search.svg";

//page
import ProductList from "../../component/common/product/ProductList";
import Pagination from "../common/pagination/Pagination";

export default function SearchProduct({ list }) {
  const { keyword, index } = useParams();
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Container>
      <Mobile>
        <StoreSearchWrap>
          <StoreSearchTabBox>
            <StoreSearchTab
              onClick={() => {
                setTabIndex(0);
              }}
            >
              상품
            </StoreSearchTab>
            <StoreSearchTab
              onClick={() => {
                setTabIndex(1);
              }}
            >
              브랜드
            </StoreSearchTab>
            <StoreSearchTab
              onClick={() => {
                setTabIndex(2);
              }}
            >
              컨텐츠
            </StoreSearchTab>
            <StoreSearchTabBottom tabIndex={index}>
              <StoreSearchTabBottomImg src={Zigzag} alt="zigzag-bottom" />
            </StoreSearchTabBottom>
          </StoreSearchTabBox>
        </StoreSearchWrap>
      </Mobile>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  color: #333333;
  font-size: 16px;
`;
const StoreSearchWrap = styled.div`
  padding: 80px 0 180px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (80 / 1320)) 0 calc(100vw * (180 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (15 / 428)) 0 0;
  }
`;
const StoreSearchTabBox = styled.div`
  display: flex;
  position: relative;
  border-bottom: #eeeeee 1px solid;
  text-align: center;
  padding: 15px 0;

  @media screen and (max-width: 930px) {
    border-bottom: #eeeeee solid calc(100vw * (1 / 428));
    padding: calc(100vw * (15 / 428)) calc(100vw * (25 / 428));
    font-size: calc(100vw * (16 / 428));
    line-height: calc(100vw * (21 / 428));
  }
`;
const StoreSearchTab = styled.div`
  width: 50%;
`;
const StoreSearchTabBottom = styled.div`
  position: absolute;
  bottom: -7px;
  transition: ease 0.3s all;
  @media screen and (max-width: 930px) {
    left: ${(props) =>
      props.tabIndex === 0
        ? `calc(100vw * (25 / 428))`
        : props.tabIndex === 1
        ? `calc(100vw * (150 / 428))`
        : props.tabIndex === 2
        ? `calc(100vw * (277 / 428))`
        : null};
    bottom: calc(100vw * (-7 / 428));
    width: calc(100vw * (129 / 428));
  }
`;
const StoreSearchTabBottomImg = styled.img`
  width: 100%;
  height: 100%;
`;
