import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { PC , Mobile } from '../../common/mediaStlye';
import { useSelector } from 'react-redux';
import { SignUpAction } from '../../../store/actionCreators';
import serverController from '../../../server/serverController';

import {
    Container,
    Layout,
    AuthIntroMessage,          
    FieldBox,
    DoubleFieldBox,
    Label,    
    LabelName,
    EmailInput,
    PasswordInput,
    PasswordCheckInput,
    TelephoneInput,
    Input
} from '../auth.elements';
import {
    SignupFormWrapper,    
    SubCheckButton,
    SignupBox,
    SignupButton,
    MobileSubBox
} from './signup.element';
import SignupNameInput from './SignupNameInput';
import SignupEmailInput from './SignupEmailInput';
import SignupPasswordInput from './SignupPasswordInput';
import SignupPhoneInput from './SignupPhoneInput';
import SignupStylistInfoInput from './SignupStylistInfoInput';
import SignupBusinessRegistrationInput from './SignupBusinessRegistrationInput';
import SignUpGuideLines from './SignUpGuideLines';

const SignUpStylist = () => {
    const history = useHistory();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");    
    const [phone, setPhone] = useState("");
    const [belong, setBelong] = useState("")
    const [celebList, setCelebList] = useState("")    
    const [businessFile, setBusinessFile] = useState(null)

    const [term, setTerm] = useState(false);
    const [policy, setPolicy] = useState(false);
    const [next, setNext] = useState(false);

    const isClick = useRef(false);
    const [isCheck, setIsCheck] = useState({
        isEmail: false,
        isPassword: false,
        isStylistInfo: false,        
        isBusinessFile: false,
        isTerm: false,
        isPolicy: false,
    })  

    useEffect(() => {       
        const validateArr = [];
        for(const key in isCheck) {
            validateArr.push(isCheck[key]);
        }
        isClick.current = !validateArr.includes(false);

        if(isClick.current === true){
            setNext(true)
        }else{
            setNext(false)
        }    
    }, [isCheck])

    const returnFormData = () => {
        const formData = new FormData();
        formData.append("mem_name", name);     
        formData.append("mem_email",email);
        formData.append("mem_password", password);                        
        formData.append("pdf", businessFile);
        formData.append("mem_belong", belong);
        formData.append("mem_celebrity_list", celebList); 
        formData.append("mem_phone",phone);       
        return formData;
    }

    const signUp = async () => {
        await serverController.connectFetchController(
            `stylelistjoin`,
            "POST",
            returnFormData(),
            (res) => {
                console.log(res, "회원가입 response");
                history.push(`/signup/success`);
            },
            (err) => console.error(err)
        )
    }

    const onHandleSubmit = (e) => {
        e.preventDefault();
        if (isClick.current === true) {                     
            // console.table([email, password, phone, brandName, businessFile]);
            isClick.current = false;
            signUp()            
        }
    };


    const handleTermCheck = (checked) => {
        setTerm(checked)

        if (checked) {
            setIsCheck(prev => ({...prev, isTerm: true }))
        } else {
            setIsCheck(prev => ({...prev, isTerm: false }))
        }
    };
    const handlePolicyCheck = (checked) => {
        setPolicy(checked)

        if (checked) {
            setIsCheck(prev => ({...prev, isPolicy: true }))
        } else {
            setIsCheck(prev => ({...prev, isPolicy: false }))
        }
    };
    
    return (
        <Container>
            <Layout>
                    <AuthIntroMessage>
                        <h2>Stylist Member REGISTER</h2>
                        <p>스타일리스트 회원가입을 통해 다양한 서비스를 이용해 보세요!</p>
                    </AuthIntroMessage>
                    
                    <SignupFormWrapper onSubmit={onHandleSubmit}>
                        <SignupNameInput 
                            setName={setName} 
                            setIsCheck={setIsCheck} 
                        />

                        <SignupEmailInput 
                            setEmail={setEmail} 
                            setIsCheck={setIsCheck} 
                        />

                        <SignupPasswordInput 
                            setPassword={setPassword}                             
                            setIsCheck={setIsCheck} 
                        />

                        <SignupStylistInfoInput
                            setIsCheck={setIsCheck} 
                            setBelong={setBelong}
                            setCelebList={setCelebList}
                        />

                        <SignupBusinessRegistrationInput 
                            setBusinessFile={setBusinessFile}
                            setIsCheck={setIsCheck} 
                        />

                        <SignupPhoneInput
                            setPhone={setPhone}                                
                            setIsCheck={setIsCheck} 
                        />                    
                    <SignupBox>
                        <SignUpGuideLines term={term} policy={policy} handleTermCheck={handleTermCheck} handlePolicyCheck={handlePolicyCheck}/>
                        <SignupButton type="submit" on={next}>계정 만들기</SignupButton>
                    </SignupBox>
                    </SignupFormWrapper>

            </Layout>
        </Container>
    )
}

export default SignUpStylist

