import React from 'react';
import faker, { fake } from 'faker';
import { Mobile, PC } from '../../MediaQuery';
import {
    Container,
    Layout,
    Contents,
    ContentHeadings,
    ContentLarge,
    Item,
    ItemImg,
    ItemDesc,
    BlogIcon,
    ContentOthers,
    OthersItem,
    OthersHead,
    OthersThumb,
    OthersTitle,
} from './FashionNewsContents.element';
import blogIcon from '../../images/news-blog-icon.png';

const BlogContentsBox = () => {
    return (
        <Container grey>
            <Layout>
                <ContentHeadings>
                    <em>WALA PICK!</em>
                    <h2>Blog Contents </h2>
                </ContentHeadings>

                <Contents>
                    <ContentLarge>
                        <PC>
                        {
                            Array(2).fill().map((item, i) =>{
                                return (
                                    <Item key={i}>
                                        <ItemImg>
                                            <img src={faker.image.image()} />
                                        </ItemImg>
                                        <ItemDesc className="item_head">                
                                            <h4>펜트하우스3 9화 이지아 패션 </h4>
                                            <p>와인색 수트로 세련된 패션을 완성한 유진!
                                            그리고 펜트하우스 17회 유진 패션을 더욱 고급스럽게 완성해 준 아이템은
                                            바로 유진 귀걸이였어요 +_+</p>           
                                        </ItemDesc>
                                        <BlogIcon><img src={blogIcon} alt="출처: 네이버 블로그"/></BlogIcon>
                                    </Item>
                                )
                            })
                        }
                        </PC>
                        <Mobile>
                        <Item>
                            <ItemImg>
                                <img src={faker.image.image()} />
                            </ItemImg>
                            <ItemDesc className="item_head">                
                                <h4>펜트하우스3 9화 이지아 패션 </h4>
                                <p>와인색 수트로 세련된 패션을 완성한 유진!
                                그리고 펜트하우스 17회 유진 패션을 더욱 고급스럽게 완성해 준 아이템은
                                바로 유진 귀걸이였어요 +_+</p>           
                            </ItemDesc>
                            <BlogIcon><img src={blogIcon} alt="출처: 네이버 블로그"/></BlogIcon>
                        </Item>
                        </Mobile>
                    </ContentLarge>

                    <ContentOthers>
                        <PC>
                        {
                            Array(4).fill().map((item, i) => {
                                return (
                                    <OthersItem key={i}>
                                        <OthersHead>
                                            <BlogIcon><img src={blogIcon} alt="출처: 네이버 블로그"/></BlogIcon>
                                            <span>2021.06.16</span>
                                        </OthersHead>

                                        <OthersThumb>
                                            <img src={faker.image.image()} />
                                        </OthersThumb>

                                        <OthersTitle>
                                        펜트하우스2 2회 유진 귀걸이 & 반지 디디에두보, 우아한 패션 룩
                                        </OthersTitle>
                                    </OthersItem>
                                )
                            })
                        }
                        </PC>

                        <Mobile>
                        {
                            Array(2).fill().map((item, i) => {
                                return (
                                    <OthersItem key={i}>
                                        <OthersHead>
                                            <BlogIcon><img src={blogIcon} alt="출처: 네이버 블로그"/></BlogIcon>
                                            <span>2021.06.16</span>
                                        </OthersHead>

                                        <OthersThumb>
                                            <img src={faker.image.image()} />
                                        </OthersThumb>

                                        <OthersTitle>
                                            <p>
                                                펜트하우스2 2회 유진 귀걸이 & 반지 디디에두보, 우아한 패션 룩
                                            </p>
                                        </OthersTitle>
                                    </OthersItem>
                                )
                            })
                        }
                        </Mobile>
                    </ContentOthers>
                </Contents>
            </Layout>
        </Container>
    )
}

export default BlogContentsBox
