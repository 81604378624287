import api from "../server/serverController";

const postLikeItem = async (id) => {
  const res = await api.post(
    `contentslike/${id}`,
    null,
    (res) => {},
    (err) => console.error(err)
  );
  return res;
};

const postLikeProducts = async (id) => {
  const res = await api.post(
    `prdlike/${id}`,
    null,
    (res) => {},
    (err) => console.error(err)
  );
  return res;
};

export { postLikeItem, postLikeProducts };
