import React, { useState } from 'react';

import {
    ModalContainer,
    InviteContents,
    InviteContent,
    ModalButton,
    InviteCloseButton,
    InviteHeadings
} from './pop.elements';

import closeIcon from '../../../images/close-icon.svg';
import successIcon from '../../../images/invite_success.svg';

const InviteModal = ({ setIsModifyPop }) => {
    const [isInvite, setIsInvite] = useState(false)
    const closeModal = () => {
        setIsModifyPop(false)
    }

    return (
        <ModalContainer>
            <InviteContents>
                <InviteHeadings>
                    <span>
                    초대하기
                    </span>
                    <InviteCloseButton onClick={closeModal}><img src={closeIcon} alt="닫기"/></InviteCloseButton>
                </InviteHeadings>

                {
                    !isInvite
                    ? (
                        <InviteContent>
                            <div>
                                <span>이메일</span>
                                <input placeholder="초대하실 이메일을 입력해주세요." />
                            </div>
                            <button onClick={() => setIsInvite(true)}>초대하기</button>
                        </InviteContent>
                    ) : (
                        <InviteContent>
                            <div className="invite_success">
                                <img src={successIcon} alt="성공" />
                                <em> 초대 이메일을 전송 완료했습니다.</em>
                            </div>
                            <button onClick={() => setIsModifyPop(false)}>확인하기</button>
                        </InviteContent>
                    )
                }              
                
            </InviteContents>
        </ModalContainer>

    )
}

export default InviteModal;
