import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import serverController from "../../server/serverController";
import StepOne from "../../component/mypage/personalInfo/StepOne";
import StepTwo from "../../component/mypage/personalInfo/StepTwo";
import StepThree from "../../component/mypage/personalInfo/StepThree";
import StepFour from "../../component/mypage/personalInfo/StepFour";
import MyPageHeader from "./MyPageHeader";
import StepComplete from "../../component/mypage/personalInfo/StepComplete";
import { useSelector } from "react-redux";
import MyPageDefaultModal from "../../component/common/modal/MyPageDefaultModal";
import { PC } from "../../MediaQuery";
import MyPageLayOutName from "../../component/mypage/MyPageLayOutName";
import MyPageLayOutMenu from "../../component/mypage/MyPageLayOutMenu";
import MyPageLayOutInfo from "../../component/mypage/MyPageLayOutInfo";
import Header from "../../component/common/Header";

export default function MyPersonalInfoPage() {
  const params = useParams();
  const history = useHistory();
  const { userInfo } = useSelector((state) => state.userData);

  const [isReturn, setIsReturn] = useState(false);

  // step 1
  const [clickGenderBox, setClickGenderBox] = useState(-1);

  // step 2
  const [clickStyleArr, setClickStyleArr] = useState([]);

  // step 3
  const [clickBodyTypeArr, setClickBodyTypeArr] = useState([]);

  // step 4
  const [clickColorArr, setClickColorArr] = useState([]);

  const postPersonalData = () => {
    if (userInfo.mem_style > 0) {
      const postFormData = new FormData();
      postFormData.append("mem_email", userInfo.mem_email);
      postFormData.append("mem_password", userInfo.mem_password);
      postFormData.append("mem_phone", userInfo.mem_phone);
      postFormData.append("mem_name", userInfo.mem_name);
      postFormData.append("mem_gender", clickGenderBox);
      postFormData.append("mem_style", clickStyleArr);
      postFormData.append("mem_fit", clickBodyTypeArr);
      postFormData.append("mem_color", clickColorArr);
      serverController.connectFetchController(
        `join`,
        "POST",
        postFormData,
        (res) => {
          return history.push(`/store/mypage/personal/complete`);
        },
        (err) => console.error(err)
      );
    } else {
      const editFormData = new FormData();
      editFormData.append("mem_gender", clickGenderBox);
      editFormData.append("mem_style", clickStyleArr);
      editFormData.append("mem_fit", clickBodyTypeArr);
      editFormData.append("mem_color", clickColorArr);

      serverController.connectFetchController(
        `user/style`,
        "PUT",
        editFormData,
        (res) => {
          console.log(res, "맞춤분석 업데이트 response");
          return history.push(`/store/mypage/personal/complete`);
        },
        (err) => console.error(err)
      );
    }
  };

  const StepOneItems = {
    clickGenderBox,
    setClickGenderBox,
  };

  const StepTwoItems = {
    clickStyleArr,
    setClickStyleArr,
    clickGenderBox,
  };

  const StepThreeItems = {
    clickBodyTypeArr,
    setClickBodyTypeArr,
    clickGenderBox,
  };

  const StepFourItems = {
    clickColorArr,
    setClickColorArr,
    postPersonalData,
  };

  useEffect(() => {
    const unListen = history.block((loc, action) => {
      if (action === "POP") {
        if (params.step === "complete") {
          history.push(`/store/mypage`);
        } else {
          setIsReturn(true);
        }
        return false;
      }
    });

    return () => {
      unListen();
    };
  }, [history, params.step]);

  return (
    <>
      <PC>
        <Header />
      </PC>
      <Container>
        {isReturn && (
          <MyPageDefaultModal
            text={
              <>
                지금까지의 내용이 저장되지 않습니다.
                <br />
                맞춤분석을 취소하시겠습니까?
              </>
            }
            trueFunc={() => history.push(`/store/mypage`)}
            falseFunc={() => setIsReturn(false)}
          />
        )}
        <PC>
          <MyPageLayOutName />
        </PC>
        <FlexLayOut>
          <PC>
            <MyPageLayOutMenu />
          </PC>
          <ContentSection>
            <PC>
              <MyPageLayOutInfo />
            </PC>

            <MyPageHeader
              title="맞춤분석"
              prevBtn
              prevFunc={() => {
                if (params.step === "complete") return history.push(`/store/mypage`);
                setIsReturn(true);
              }}
            />
            <PersonalInfoWarp>
              <PersonalTextBox>
                <PersonalTitle>PERSONAL INFORMATION</PersonalTitle>
                {params.step < 5 && (
                  <PersonalStep>
                    <strong>0{params.step}</strong> / 04
                  </PersonalStep>
                )}
              </PersonalTextBox>
              {params.step === "1" && <StepOne {...StepOneItems} />}
              {params.step === "2" && <StepTwo {...StepTwoItems} />}
              {params.step === "3" && <StepThree {...StepThreeItems} />}
              {params.step === "4" && <StepFour {...StepFourItems} />}
              {params.step === "complete" && <StepComplete {...StepFourItems} />}
            </PersonalInfoWarp>
          </ContentSection>
        </FlexLayOut>
      </Container>
    </>
  );
}
const Container = styled.div`
  position: relative;
  padding: 180px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (180 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const PersonalInfoWarp = styled.div`
  padding-top: 50px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (50 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: calc(100vw * (50 / 428)) 0;
  }
`;
const PersonalTextBox = styled.div`
  margin-bottom: 100px;
  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (100 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (100 / 428));
  }
`;
const PersonalTitle = styled.p`
  font-weight: 900;
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (30 / 1320));
    margin-bottom: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (25 / 428));
    margin-bottom: calc(100vw * (20 / 428));
  }
`;
const PersonalStep = styled.p`
  text-align: center;
  color: #d9d9d9;
  > strong {
    color: #222;
  }
`;
