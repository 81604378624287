import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

export default function ErrorPage() {
  const history = useHistory();
  return (
    <ErrorPageWrap>
      <ErrorPageTop>
        <BackgroundText>404</BackgroundText>
        <ErrorPageTitleText>
          요청한 페이지를 찾을 수 없습니다.
        </ErrorPageTitleText>
      </ErrorPageTop>
      <ErrorPageBottom>
        <ErrorPageText>
          페이지가 존재하지 않거나, 사용할 수 없는 페이지 입니다!
          <br />
          입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.
        </ErrorPageText>
        <ErrorPageButton
          onClick={() => {
            history.push("/");
          }}
        >
          홈으로 돌아가기
        </ErrorPageButton>
      </ErrorPageBottom>
    </ErrorPageWrap>
  );
}
const ErrorPageWrap = styled.div`
  text-align: center;
  padding: 341px;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (341 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (219 / 428)) 0;
  }
`;
const BackgroundText = styled.span`
  color: #f2f2f2;
  font-weight: 800;
  font-size: 150px;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (150 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (98 / 428));
  }
`;
const ErrorPageTitleText = styled.p`
  font-size: 28px;
  font-weight: 600;
  position: absolute;
  width: 100%;
  bottom: 17px;
  @media screen and (max-width: 1320px) {
    bottom: calc(100vw * (17 / 1320));
    font-size: calc(100vw * (28 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    bottom: calc(100vw * (7 / 428));
  }
`;
const ErrorPageTop = styled.div`
  position: relative;
  margin-bottom: 8px;
  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (13 / 428));
  }
`;
const ErrorPageBottom = styled.div`
  margin: 0 auto;
  width: 450px;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (450 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (356 / 428));
    font-size: calc(100vw * (15 / 428));
    line-height: calc(100vw * (21 / 428));
  }
`;
const ErrorPageText = styled.p`
  color: #777777;
`;
const ErrorPageButton = styled.button`
  background-color: #000;
  color: #fff;
  height: 60px;
  width: 100%;
  font-size: 18px;
  margin-top: 90px;
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (60 / 1320));
    font-size: calc(100vw * (18 / 1320));
    margin-top: calc(100vw * (90 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
    margin-top: calc(100vw * (100 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
