import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";
import { useParams } from "react-router-dom";

// controller
import serverController from "../../../server/serverController";
import cloneDeep from "lodash/cloneDeep";
import qs from "qs";

//img
import MoreIcon from "../../../img/icon/icon_more_button.svg";
import IconBack from "../../../img/icon/icon_header_back.svg";
import OrderArrowIcon from "../../../img/icon/icon_order_arrow.svg";
import { imgURL } from "../../../server/appInfoController";
import Commas from "../../../server/Commas";

// component
import MyPageLayOutMenu from "../MyPageLayOutMenu";
import MyPageLayOutName from "../MyPageLayOutName";
import MyPageLayOutInfo from "../MyPageLayOutInfo";
import MyDeliveryTrackingModal from "../../common/modal/MyDeliveryTrackingModal";
import OrderCancelModal from "../../common/modal/OrderCancelModal";
import OrderReturnModal from "../../common/modal/OrderReturnModal";
import OrderProductDetail from "../../order/OrderProductDetail";
import OrderedAdressEditModal from "../../common/modal/OrderedAdressEditModal";
import OrderCheckPop from "../../common/modal/OrderCheckPop";
import ReviewFormModalOrder from "../../common/modal/ReviewFormModalOrder";
import ReviewFormOrder from "../../common/modal/ReviewFormOrder";
import Toast from "../../common/modal/Toast";

export default function OrderDetailMain({ returnModal, setReturnModal }) {
  //페이지 이동
  const history = useHistory();
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const goBackFunc = () => {
    history.goBack();
  };
  const { order_Id } = useParams();

  const [isModal, setIsModal] = useState(false);
  const [isOrderCancelModal, setIsOrderCancelModal] = useState(false);
  const [isOrderReturnModal, setIsOrderReturnModal] = useState(false);
  const [isAddressModal, setIsAddressModal] = useState(false);
  const [isDeliverycheckModal, setIsDeliverycheckModal] = useState(false);

  const [userEmail, setUserEmail] = useState("");

  const [clickOrderItem, setClickOrderItem] = useState({});
  const [clickState, setClickState] = useState(0);

  const [list, setList] = useState([]);
  const [orderInfoList, setOrderInfoList] = useState([]);

  const [clickOrderDetail, setClickOrderDetail] = useState("return");

  const [isCheckPop, setIsCheckPop] = useState(false);
  const [mogId, setMogId] = useState("");


  const [isEditModalPc, setIsEditModalPc] = useState("");
  const [isWriteModalMo, setIsWriteModalMo] = useState("");
  const [clickItem, setClickItem] = useState({});
  const [product, setProduct] = useState({});

  const [deliveryName, setDeliveryName] = useState("");
  const [deliveryNum, setDeliveryNum] = useState("");
  const [deleveryCode, setDeleveryCode] = useState("");
  const [vbankName, setVbankName] = useState("");
  const [vbankNum, setVbankNum] = useState("");
  const [vbankDate, setVbankDate] = useState("");

  // get Detail item
  const getPaymentHistory = () => {
    serverController.connectFetchController(`payment/history/${order_Id}`, "GET", null, (res) => {
      if (res?.result === 1) {
        const keys = Object.keys(res?.payment_list);
        const values = Object.values(res?.payment_list);
        let arr = [];
        const v = cloneDeep(values);

        for (let i in v) {
          v[i].unshift(keys[i]);
          arr.push(v[i]);
        }
        setList(arr);
        setOrderInfoList(res.order_info);
        settingSocialEmail(res.order_info.memEmail);
        const paymentList = res?.payment_list[Object.keys(res?.payment_list)[0]][0];
        setVbankName(paymentList.vbank_name);
        setVbankNum(paymentList.vbank_num);
        setVbankDate(paymentList.vbank_date.split('T')[0]);
      } else {
        alert("잘못된 접근입니다.");
        history.push(`/store`);
      }
    });
  };

  // post request confirm (주문 확정)
  const postRequest = (item) => {
    serverController.connectFetchController(
      `payment/request-confirm?mogId=${item}`,
      "POST",
      null,
      (res) => {
        if (res.result === 1) {
          alert("구매가 확정되었습니다.");
          getPaymentHistory();
        } else {
          return alert("다시 시도해주세요. 문제가 계속 될 경우 고객센터에 문의바랍니다.");
        }
      }
    );
  };

  const postCancelOrder = (item) => {
    serverController.connectFetchController(
      `payment/cancel-request?mogId=${item}`,
      "POST",
      null,
      (res) => {
        if (res.result === 1) {
          alert(res.message);
          setIsCheckPop(false);
          getPaymentHistory();
        } else {
          setIsCheckPop(false);
          return alert("다시 시도해주세요. 문제가 계속 될 경우 고객센터에 문의바랍니다.");
        }
      }
    );
  };

  // get status
  const getStatus = (mog_order_status) => {
    switch (mog_order_status) {
      case 0:
        return "미입금";
      case 5:
        return "옵션변경요청";
      case 6:
        return "배송지 변경요청";
      case 10:
        return "무통장 대기";
      case 11:
        return "결제취소";
      case 12:
        return "결제실패";
      case 13:
        return "신규주문";
      case 14:
        return "상품준비중";
      case 20:
        return "결제완료";
      case 30:
        return "배송준비";
      case 41:
        return "배송중";
      case 42:
        return "배송완료";
      case 43:
        return "부분 배송중";
      case 44:
        return "구매 확정";
      case 50:
        return "반품요청";
      case 51:
        return "반품중";
      case 52:
        return "반품완료";
      case 53:
        return "반품수거 접수완료";
      case 54:
        return "반품 수거중";
      case 55:
        return "반품 수거완료";
      case 57:
        return "교환 요청";
      case 58:
        return "교환 접수";
      case 59:
        return "교환 수거중";
      case 60:
        return "교환 수거완료";
      case 61:
        return "교환중";
      case 62:
        return "교환완료";
      case 70:
        return "주문취소";
      case 71:
        return "취소 요청";
      case 72:
        return "취소 대기";
      case 73:
        return "취소 거부";
      default:
        return;
    }
  };

  const settingSocialEmail = (email) => {
    let socialEmail = "";
    if (email?.indexOf("google_") !== -1) {
      socialEmail = email?.split("google_")[1];
    } else if (email?.indexOf("apple_") !== -1) {
      socialEmail = email?.split("apple_")[1];
    } else if (email?.indexOf("naver_") !== -1) {
      socialEmail = email?.split("naver_")[1];
    } else if (email?.indexOf("kakao_") !== -1) {
      socialEmail = email?.split("kakao_")[1];
    } else {
      socialEmail = email;
    }
    return setUserEmail(socialEmail);
  };

  useEffect(() => { }, []);

  useEffect(() => {
    if (!isOrderCancelModal || !isOrderReturnModal) {
      getPaymentHistory();
    }
  }, [order_Id, isOrderCancelModal, isOrderReturnModal]);

  // s :: handle
  const handleOrderCancel = (orderItem) => {
    // 주문 취소 form
    setClickOrderItem(orderItem);
    setIsOrderCancelModal(true);
  };

  const handleOrderReturn = (orderItem) => {
    // 상품 반품 form
    setClickOrderItem(orderItem);
    setIsOrderReturnModal(true);
  };

  const handleReturnDetail = (orderItem) => {
    // 교환 상세 modal
    setClickOrderItem(orderItem);
    setReturnModal(true);
  };
  // e :: handle

  const orderProductDetailItems = {
    clickOrderItem,
    setReturnModal,
    clickOrderDetail,
  };
  const orderCancelModalItems = {
    setIsOrderCancelModal,
    clickOrderItem,
  };
  const orderReturnModalItems = {
    setIsOrderReturnModal,
    clickOrderItem,
    clickState,
  };

  const handleCopyClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyText("복사되었습니다.");
      setIsCopyToast(true);
    } catch (e) {
      setCopyText("복사에 실패하였습니다.");
      setIsCopyToast(true);
    }
  };

  const orderNumRef = useRef(null);
  const bankNumRef = useRef(null);

  const [isCopyToast, setIsCopyToast] = useState(false);
  const [copyText, setCopyText] = useState("");

  useEffect(() => {
    isCopyToast === true && setTimeout(() => {
      setIsCopyToast(false);
    }, 1300);
  }, [isCopyToast]);

  return (
    <>
      {/* cancel modal */}
      {isOrderCancelModal && <OrderCancelModal {...orderCancelModalItems} />}
      {/* 반품/교환 모달 */}
      {isOrderReturnModal && <OrderReturnModal {...orderReturnModalItems} />}
      {/* return modal */}
      {returnModal && <OrderProductDetail {...orderProductDetailItems} />}
      {/* 배송지 변경 모달 */}
      {isAddressModal && < OrderedAdressEditModal orderId={query.orderId} setIsAddressModal={setIsAddressModal} getPaymentHistory={getPaymentHistory} />}
      {/* 배송조회 모달 */}
      {isDeliverycheckModal && <MyDeliveryTrackingModal setIsDeliverycheckModal={setIsDeliverycheckModal} deliveryName={deliveryName} deliveryNum={deliveryNum} deleveryCode={deleveryCode} />}
      {/* 주문취소 요청 확인 팝업 */}
      {isCheckPop && (
        <OrderCheckPop
          textT="해당 상품은 출고가 되었을 수도 있습니다."
          textB="정확한 취소는 판매자에게 문의 바랍니다."
          trueText="확인"
          trueFunc={() => {
            postCancelOrder(mogId);
          }}
          falseFunc={() => setIsCheckPop(false)}
        />
      )}
      {/* 리뷰 모달 */}
      {isEditModalPc && (
        <ReviewFormModalOrder
          setIsEditModal={setIsEditModalPc}
          title={"리뷰작성"}
          clickItem={clickItem}
        />
      )}
      {isWriteModalMo && (
        <ReviewFormOrder
          setIsWriteModalMo={setIsWriteModalMo}
          title={"리뷰작성"}
          clickItem={clickItem}

        />
      )}
      {/* 스낵바 */}
      {isCopyToast && <Toast text={copyText} />}

      <Container returnModal={returnModal}>
        {/* page */}
        <PC>
          <MyPageLayOutName />
        </PC>

        {/* content */}
        <FlexLayOut>
          <PC>
            <MyPageLayOutMenu />
          </PC>

          <ContentSection>
            <PC>
              <MyPageLayOutInfo />
            </PC>

            {/* 주문 상세 */}
            <OrderDetailWrap returnModal={returnModal}>
              <PC>
                <PageButtonWrap>
                  <PageButtonArea>
                    <PageButton onClick={goBackFunc}>
                      <img src={IconBack} alt="back-img" />
                    </PageButton>
                    <PageButtonTitle>주문상세</PageButtonTitle>
                  </PageButtonArea>
                </PageButtonWrap>
              </PC>

              <OrderDetailArea>
                <OrderDetailSection>
                  <OrderDetailInner>
                    <ListTitle>
                      <span>주문번호 </span>
                      <span ref={orderNumRef}>{query.orderId || "확인 불가능"}</span>
                      {query.orderId && <button type="button" onClick={() => { handleCopyClipBoard(orderNumRef.current.textContent) }}>복사</button>}
                    </ListTitle>

                    <ListDate>
                      {orderInfoList?.txnDate?.replaceAll("-", ".") || "주문 일시 데이터 없음"}
                    </ListDate>
                  </OrderDetailInner>
                </OrderDetailSection>

                {/* 상품 정보 >> 결제완료 - 구매확정 */}
                {list.map((listItem, index) => {
                  const listBrand = listItem[0];
                  let listArr = [];
                  for (let i in listItem) {
                    if (i !== "0") {
                      listArr.push(listItem[i]);
                    }
                  }

                  return (
                    <OrderDetailSection key={index}>
                      <OrderDetailInner>
                        <OrderDetailInfo>
                          <BrandTextWrap
                            onClick={() => {
                              history.push(`/store/brand/detail/${listArr[0]?.bc_id}?page=1`);
                            }}
                          >
                            <BrandText>{listBrand}</BrandText>
                            <MoreImg src={MoreIcon} alt="more-icon" />
                          </BrandTextWrap>
                          {listArr.map((orderItem) => {
                            return (
                              <>
                                {orderItem.mog_order_status === 57 ? (
                                  <>
                                    <OrderDetailListWrap>
                                      <OrderDetailList>
                                        <ProductInfoWrap>
                                          <ImgBox
                                            onClick={() => {
                                              history.push(`/store/detail/${orderItem?.prd_id}`);
                                            }}
                                          >
                                            <img
                                              src={`${imgURL}${orderItem?.prd_thumbnail}`}
                                              alt="product-thumbnail"
                                            />
                                          </ImgBox>
                                          <TextBox>
                                            <TextBoxInner>
                                              <ProductIng textColor="gray">
                                                {getStatus(orderItem.mog_order_status)}
                                              </ProductIng>
                                              <Brand>{orderItem?.bc_title || "(BRAND)"}</Brand>
                                              <ProductName
                                                onClick={() => {
                                                  history.push(`/store/detail/${orderItem.prd_id}`);
                                                }}
                                              >
                                                {orderItem?.prd_name}
                                              </ProductName>
                                              {orderItem?.optionInfo?.length > 0 &&
                                                orderItem?.optionInfo?.map((optionItem, idx) => {
                                                  return (
                                                    <ProductColor
                                                      key={optionItem.optName + "-" + idx}
                                                    >
                                                      {optionItem.optName +
                                                        " / " +
                                                        orderItem.orderCount +
                                                        "개"}
                                                    </ProductColor>
                                                  );
                                                })}
                                              <Price>
                                                {Commas.numberWithCommas(orderItem?.prd_list_price)}
                                                원
                                              </Price>
                                            </TextBoxInner>
                                            <TextBoxLine>
                                              <Line />
                                              <LineImg src={OrderArrowIcon} alt="" />
                                            </TextBoxLine>
                                            <TextBoxInner>
                                              {/* <ProductIng textColor="pink">배송완료</ProductIng> */}
                                              <Brand>{orderItem?.bc_title || "(BRAND)"}</Brand>
                                              <ProductName
                                                onClick={() =>
                                                  history.push(`/store/detail/${orderItem?.prd_id}`)
                                                }
                                              >
                                                {orderItem?.prd_name}
                                              </ProductName>

                                              <ProductColor>
                                                {orderItem?.swapOptionInfo?.length > 0 &&
                                                  orderItem?.swapOptionInfo?.map(
                                                    (option, index) => {
                                                      return (
                                                        <>
                                                          {index !== 0
                                                            ? `${option.optName}, `
                                                            : `${option.optName}`}
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                {` / ${orderItem.swapOrderCount}개`}
                                              </ProductColor>
                                            </TextBoxInner>
                                          </TextBox>
                                        </ProductInfoWrap>
                                        {orderItem.mog_delivery_name && (
                                          <OrderInfoWrap>
                                            <ListBox>
                                              <List>
                                                <Title>택배사</Title>
                                                <Text>{orderItem.mog_delivery_name}</Text>
                                              </List>
                                              <List>
                                                <Title>운송장 번호</Title>
                                                <Text>{orderItem.mog_delivery_num}</Text>
                                              </List>
                                            </ListBox>
                                          </OrderInfoWrap>
                                        )}
                                        <BtnWrap>
                                          <BtnList>
                                            <BtnWtMedium
                                              type="button"
                                              onClick={() => {
                                                setClickOrderDetail("return");
                                                handleReturnDetail(orderItem);
                                              }}
                                            >
                                              교환상세
                                            </BtnWtMedium>
                                            <BtnWtMedium
                                              type="button"
                                              onClick={() => {
                                                return alert("준비중입니다.");
                                                history.push("/store/mypage/delivery/tracking");
                                              }}
                                            >
                                              교환현황
                                            </BtnWtMedium>
                                          </BtnList>
                                        </BtnWrap>
                                      </OrderDetailList>
                                    </OrderDetailListWrap>
                                  </>
                                ) : orderItem.mog_order_status === 0 ? (
                                  <>
                                    <OrderDetailListWrap>
                                      <OrderDetailList>
                                        <ProductInfoWrap>
                                          <ImgBox
                                            onClick={() => {
                                              history.push(`/store/detail/${orderItem?.prd_id}`);
                                            }}
                                          >
                                            <img
                                              src={`${imgURL}${orderItem?.prd_thumbnail}`}
                                              alt="product-thumbnail"
                                            />
                                          </ImgBox>
                                          <TextBox>
                                            <TextBoxInner>
                                              <ProductIng textColor="pink">
                                                {" "}
                                                {getStatus(orderItem.mog_order_status)}
                                              </ProductIng>
                                              <Brand> {orderItem?.bc_title || "(BRAND)"}</Brand>
                                              <ProductName
                                                onClick={() => {
                                                  history.push(`/store/detail/${orderItem.prd_id}`);
                                                }}
                                              >
                                                {orderItem?.prd_name}
                                              </ProductName>
                                              {orderItem?.optionInfo?.length > 0 &&
                                                orderItem?.optionInfo?.map((optionItem, idx) => {
                                                  return (
                                                    <ProductColor
                                                      key={`${optionItem.optName} - ${idx}`}
                                                    >
                                                      {`${optionItem.optName} / ${orderItem.orderCount}개`}
                                                    </ProductColor>
                                                  );
                                                })}
                                              <Price>
                                                {Commas.numberWithCommas(orderItem?.prd_list_price)}
                                                원
                                              </Price>
                                            </TextBoxInner>
                                          </TextBox>
                                        </ProductInfoWrap>
                                        {orderItem.mog_delivery_name && (
                                          <OrderInfoWrap>
                                            <ListBox>
                                              <List>
                                                <Title>택배사</Title>
                                                <Text>{orderItem.mog_delivery_name}</Text>
                                              </List>
                                              <List>
                                                <Title>운송장 번호</Title>
                                                <Text>{orderItem.mog_delivery_num}</Text>
                                              </List>
                                            </ListBox>
                                          </OrderInfoWrap>
                                        )}
                                        <BtnWrap>
                                          {/* <BtnList>
                                            <BtnWtMedium
                                              type="button"
                                              onClick={() => {
                                                setClickOrderDetail("cancel");
                                                handleReturnDetail(orderItem);
                                              }}
                                            >
                                              반품상세
                                            </BtnWtMedium>
                                            <BtnWtMedium
                                              type="button"
                                              onClick={() => {
                                                return alert("준비중입니다.");
                                                history.push("/store/mypage/delivery/tracking");
                                              }}
                                            >
                                              반품현황
                                            </BtnWtMedium>
                                          </BtnList> */}
                                        </BtnWrap>
                                      </OrderDetailList>
                                    </OrderDetailListWrap>
                                  </>
                                ) : (
                                  <OrderDetailListWrap key={orderItem?.mog_id}>
                                    <OrderDetailList>
                                      <ProductInfoWrap>
                                        <ImgBox
                                          onClick={() => {
                                            history.push(`/store/detail/${orderItem?.prd_id}`);
                                          }}
                                        >
                                          <img
                                            src={`${imgURL}${orderItem?.prd_thumbnail}`}
                                            alt="product-thumbnail"
                                          />
                                        </ImgBox>
                                        <TextBox>
                                          <TextBoxInner>
                                            {orderItem.mog_order_status === 41 &&
                                              <BtnWt onClick={() => {
                                                setIsDeliverycheckModal(true);
                                                setDeliveryName(orderItem.mog_delivery_name);
                                                setDeliveryNum(orderItem.mog_delivery_num);
                                                setDeleveryCode(orderItem.mog_delivery_code);
                                              }}>
                                                배송조회
                                              </BtnWt>}
                                            <ProductIng
                                              textColor={
                                                orderItem.mog_order_status === 20 ||
                                                  orderItem.mog_order_status === 14
                                                  ? "black"
                                                  : "pink"
                                              }
                                            >
                                              {getStatus(orderItem.mog_order_status)}
                                            </ProductIng>
                                            <Brand>{orderItem?.bc_title || "(BRAND)"}</Brand>
                                            <ProductName
                                              onClick={() => {
                                                history.push(`/store/detail/${orderItem.prd_id}`);
                                              }}
                                            >
                                              {orderItem?.prd_name}
                                            </ProductName>
                                            {orderItem?.optionInfo?.length > 0 &&
                                              orderItem?.optionInfo?.map((optionItem, idx) => {
                                                return (
                                                  <ProductColor
                                                    key={`${optionItem.optName}-${idx}`}
                                                  >
                                                    {`${optionItem.optName} / `}
                                                  </ProductColor>
                                                );
                                              })}
                                            <ProductColor>
                                              {`${orderItem.orderCount}개`}
                                            </ProductColor>
                                            <Price>
                                              {Commas.numberWithCommas(orderItem?.prd_list_price)}원
                                            </Price>
                                          </TextBoxInner>
                                        </TextBox>
                                      </ProductInfoWrap>
                                      {orderItem.mog_delivery_name && (
                                        <OrderInfoWrap>
                                          <ListBox>
                                            <List>
                                              <Title>택배사</Title>
                                              <Text>{orderItem.mog_delivery_name}</Text>
                                            </List>
                                            <List>
                                              <Title>운송장 번호</Title>
                                              <Text>{orderItem.mog_delivery_num}</Text>
                                            </List>
                                          </ListBox>
                                        </OrderInfoWrap>
                                      )}
                                      <BtnWrap>
                                        {
                                          orderItem.mog_order_status === 20 ?
                                            // 20 결제완료  
                                            (<>
                                              <BtnList>
                                                <BtnWt
                                                  type="button"
                                                  onClick={() => {
                                                    setClickState(0);
                                                    setIsAddressModal(true);
                                                    setMogId(orderItem.mog_id);
                                                  }}
                                                >
                                                  배송지 변경
                                                </BtnWt>
                                              </BtnList>
                                              <BtnList>
                                                <BtnWt
                                                  type="button"
                                                  onClick={() => handleOrderCancel(orderItem)}
                                                >
                                                  주문취소
                                                </BtnWt>
                                              </BtnList>
                                            </>
                                            )
                                            : orderItem.mog_order_status === 30 || orderItem.mog_order_status === 14 ?
                                              // 30 배송준비
                                              (
                                                <BtnList>
                                                  <BtnGrey
                                                    type="button"
                                                    onClick={() => {
                                                      setIsCheckPop(true);
                                                      setMogId(orderItem.mog_id)
                                                    }}
                                                  >
                                                    주문취소 요청
                                                  </BtnGrey>
                                                </BtnList>
                                              )
                                              : orderItem.mog_order_status === 41 || orderItem.mog_order_status === 42 ?
                                                // 42 배송완료
                                                (<>
                                                  <BtnList>
                                                    <BtnBk
                                                      type="button"
                                                      onClick={() => {
                                                        postRequest(orderItem.mog_id);
                                                      }}
                                                    >
                                                      구매 확정
                                                    </BtnBk>
                                                  </BtnList>
                                                  <BtnList>
                                                    <BtnWtMedium
                                                      type="button"
                                                      onClick={() => {
                                                        setClickState(0);
                                                        handleOrderReturn(orderItem);
                                                      }}
                                                    >
                                                      반품하기
                                                    </BtnWtMedium>
                                                    <BtnWtMedium
                                                      type="button"
                                                      onClick={() => {
                                                        setClickState(1);
                                                        handleOrderReturn(orderItem);
                                                      }}
                                                    >
                                                      교환하기
                                                    </BtnWtMedium>
                                                  </BtnList>
                                                </>
                                                )
                                                : orderItem.mog_order_status === 44 && orderItem.reviewCompleted === 0 ?
                                                  // 44 구매확정 - 댓글 작성 전
                                                  (<BtnList>
                                                    <PC>
                                                      <BtnBk
                                                        type="button"
                                                        onClick={() => {
                                                          setIsEditModalPc(true);
                                                          setClickItem(orderItem);

                                                        }}
                                                      >
                                                        리뷰쓰기
                                                      </BtnBk>
                                                    </PC>
                                                    <Mobile>
                                                      <BtnBk
                                                        type="button"
                                                        onClick={() => {
                                                          setIsWriteModalMo(true);
                                                          setClickItem(orderItem);

                                                        }}
                                                      >
                                                        리뷰쓰기
                                                      </BtnBk>
                                                    </Mobile>
                                                  </BtnList>
                                                  ) : orderItem.mog_order_status === 44 && orderItem.reviewCompleted === 1 ?
                                                    // 44 구매확정 - 댓글 작성 후
                                                    (<BtnList>
                                                      <BtnGrey
                                                        type="button"
                                                        disabled
                                                      >
                                                        리뷰 작성 완료
                                                      </BtnGrey>
                                                    </BtnList>)
                                                    : orderItem.mog_order_status === 70 ? (
                                                      // 주문 취소 완료
                                                      <BtnList>
                                                        <BtnWt
                                                          type="button"
                                                          onClick={() => {
                                                            handleReturnDetail(orderItem);
                                                            setClickOrderDetail("cancel");
                                                          }}
                                                        >
                                                          주문취소 완료
                                                        </BtnWt>
                                                      </BtnList>
                                                    )
                                                      : orderItem.mog_order_status === 71 ? (
                                                        //주문취소 요청 중
                                                        <BtnList>
                                                          <BtnGrey
                                                            type="button"
                                                            disabled
                                                          >
                                                            주문취소 요청 중
                                                          </BtnGrey>
                                                        </BtnList>
                                                      )
                                                        : null
                                        }
                                        {/* {orderItem.mog_order_status === 20 ||
                                          orderItem.mog_order_status === 42 ? (
                                          <>
                                            <BtnList>
                                              <BtnWtSmall
                                                type="button"
                                                onClick={() => {
                                                  setClickState(0);
                                                  handleOrderReturn(orderItem);
                                                }}
                                              >
                                                반품하기
                                              </BtnWtSmall>
                                              <BtnWtSmall
                                                type="button"
                                                onClick={() => {
                                                  setClickState(1);
                                                  handleOrderReturn(orderItem);
                                                }}
                                              >
                                                교환하기
                                              </BtnWtSmall>
                                              <BtnWtSmall
                                                type="button"
                                                onClick={() => handleOrderCancel(orderItem)}
                                              >
                                                주문취소
                                              </BtnWtSmall>
                                            </BtnList>
                                            <BtnList>
                                              <BtnBk
                                                type="button"
                                                onClick={() => {
                                                  postRequest(orderItem.mog_id);
                                                }}
                                              >
                                                구매 확정
                                              </BtnBk>
                                            </BtnList>
                                          </>
                                        ) : orderItem.mog_order_status === 44 ? (
                                          <BtnList></BtnList>
                                        ) : orderItem.mog_order_status === 71 ||
                                          orderItem.mog_order_status === 70 ? (
                                          <BtnList>
                                            <BtnWt
                                              type="button"
                                              onClick={() => {
                                                handleReturnDetail(orderItem);
                                                setClickOrderDetail("cancel");
                                              }}
                                            >
                                              취소상세
                                            </BtnWt>
                                          </BtnList>
                                        ) : orderItem.mog_order_status === 14 ? (
                                          <BtnList>
                                            <BtnBk
                                              type="button"
                                              onClick={() => handleOrderCancel(orderItem)}
                                            >
                                              주문취소
                                            </BtnBk>
                                          </BtnList>
                                        ) : orderItem.mog_order_status === 30 ||
                                          orderItem.mog_order_status === 41 ? (
                                          <BtnList>
                                            <BtnBk
                                              type="button"
                                              onClick={() => {
                                                postRequest(orderItem.mog_id);
                                              }}
                                            >
                                              구매 확정
                                            </BtnBk>
                                          </BtnList>
                                        ) : null} */}
                                      </BtnWrap>
                                    </OrderDetailList>
                                  </OrderDetailListWrap>
                                )
                                }
                              </>
                            );
                          })}
                        </OrderDetailInfo>
                      </OrderDetailInner>
                    </OrderDetailSection>
                  );
                })}

                {/* 정보 */}
                <OrderDetailSection>
                  <OrderDetailInner>
                    <OrderTitle>• 주문자 정보</OrderTitle>
                    <OrderInfoListInner>
                      <OrderInfoListBox>
                        <OrderInfoListBoxInner>
                          <OrderInfoList>
                            <OrderSubTitle>주문자</OrderSubTitle>
                            <OrderTextLong>
                              {orderInfoList?.memName || "주문자 데이터 없음"}
                            </OrderTextLong>
                          </OrderInfoList>
                          <OrderInfoList>
                            <OrderSubTitle>이메일</OrderSubTitle>
                            <OrderTextLong>{userEmail || "이메일 데이터 없음"}</OrderTextLong>
                          </OrderInfoList>
                          <OrderInfoList>
                            <OrderSubTitle>휴대폰번호</OrderSubTitle>
                            <OrderTextLong>
                              {orderInfoList?.memPhone || "휴대폰번호 데이터 없음"}
                            </OrderTextLong>
                          </OrderInfoList>
                        </OrderInfoListBoxInner>
                      </OrderInfoListBox>
                    </OrderInfoListInner>
                  </OrderDetailInner>
                  <OrderDetailInner>
                    <OrderTitle>• 결제정보</OrderTitle>
                    <OrderInfoListInner>
                      <OrderInfoListBox>
                        <OrderInfoListBoxInner>
                          <OrderInfoList>
                            {/* <OrderSubTitleBold>주문 금액</OrderSubTitleBold>
                            <OrderTextBold>
                              {Commas.numberWithCommas(orderInfoList?.txnTotalPrice * 1 ?? 0)}원
                            </OrderTextBold> */}
                          </OrderInfoList>
                          <OrderInfoList>
                            <OrderSubTitleBold>상품 금액</OrderSubTitleBold>
                            <OrderText>
                              {Commas.numberWithCommas(orderInfoList?.txnAllPrice * 1)}원
                            </OrderText>
                          </OrderInfoList>
                          {/* <OrderInfoList>
                          <OrderSubTitle>배송비</OrderSubTitle>
                          <OrderText>20,000원</OrderText>
                        </OrderInfoList> */}
                        </OrderInfoListBoxInner>
                      </OrderInfoListBox>
                      <OrderInfoListBox>
                        <OrderInfoListBoxInner>
                          {/* <OrderInfoList>
                          <OrderSubTitleBold>할인금액</OrderSubTitleBold>
                          <OrderTextBold>(-)10,000원</OrderTextBold>
                        </OrderInfoList> */}
                          <OrderInfoList>
                            <OrderSubTitle>포인트 할인</OrderSubTitle>
                            <OrderText>
                              {Commas.numberWithCommas(orderInfoList?.txnUsedPoint * 1 ?? 0)}원
                            </OrderText>
                          </OrderInfoList>
                          <OrderInfoList>
                            <OrderSubTitle>쿠폰 할인</OrderSubTitle>
                            <OrderText>
                              {" "}
                              {Commas.numberWithCommas(orderInfoList?.txnCouponPrice * 1 ?? 0)}원
                            </OrderText>
                          </OrderInfoList>
                        </OrderInfoListBoxInner>
                      </OrderInfoListBox>
                      <OrderInfoListBox>
                        <OrderInfoListBoxInner>
                          <OrderInfoList>
                            <OrderAllAmountTitle>총 결제금액</OrderAllAmountTitle>
                            <OrderAllAmountText>
                              {" "}
                              {Commas.numberWithCommas(orderInfoList?.txnTotalPrice * 1 ?? 0)}원
                            </OrderAllAmountText>
                          </OrderInfoList>
                          <OrderInfoList>
                            <OrderSubTitle>결제방법</OrderSubTitle>
                            <OrderText>
                              {orderInfoList?.paymentMethod || "결제방법 데이터 없음"}
                            </OrderText>
                          </OrderInfoList>
                          {vbankName && (
                            <>
                              <OrderInfoList>
                                <OrderSubTitle>은행명</OrderSubTitle>
                                <OrderText>
                                  {vbankName}
                                </OrderText>
                              </OrderInfoList>
                              <OrderInfoList>
                                {/* {vbankNum && ( */}
                                <OrderSubTitle>계좌번호</OrderSubTitle>
                                <OrderText>
                                  <span ref={bankNumRef}>{vbankNum || "결제방법 데이터 없음"}</span>
                                  <button type="button" onClick={() => { handleCopyClipBoard(bankNumRef.current.textContent) }}>복사</button>
                                </OrderText>
                                {/* )} */}
                              </OrderInfoList>
                              <OrderInfoList>
                                <OrderSubTitle>입금기한</OrderSubTitle>
                                <OrderText>
                                  {vbankDate} 까지
                                </OrderText>
                              </OrderInfoList>
                              {/* <OrderInfoList>
                            <OrderSubTitle>예금주</OrderSubTitle>
                            <OrderText>
                            </OrderText>
                          </OrderInfoList> */}
                            </>
                          )}

                        </OrderInfoListBoxInner>
                        {/* 환불 관련 */}
                        {/* <OrderInfoListBoxInner>
                        <OrderInfoList>
                          <OrderAllAmountTitle>환불금액</OrderAllAmountTitle>
                          <OrderAllAmountText>100,000원</OrderAllAmountText>
                        </OrderInfoList>
                        <OrderInfoList>
                          <OrderSubTitle>환불 상품 금액</OrderSubTitle>
                          <OrderText>10,000원</OrderText>
                        </OrderInfoList>
                        <OrderInfoList>
                          <OrderSubTitle>환불 차감 금액</OrderSubTitle>
                          <OrderText>0원</OrderText>
                        </OrderInfoList>
                      </OrderInfoListBoxInner> */}
                      </OrderInfoListBox>
                    </OrderInfoListInner>
                  </OrderDetailInner>
                  {/* 배송지 정보 */}
                  <OrderDetailInner>
                    <OrderTitle>• 배송지 정보</OrderTitle>
                    <OrderInfoListInner>
                      <OrderInfoListBox>
                        <OrderInfoListBoxInner>
                          <OrderInfoList>
                            <OrderSubTitle>받는사람</OrderSubTitle>
                            <OrderTextLong>
                              {orderInfoList?.receiverName || "받는사람 데이터 없음"}
                            </OrderTextLong>
                          </OrderInfoList>
                          <OrderInfoList>
                            <OrderSubTitle>휴대폰번호</OrderSubTitle>
                            <OrderTextLong>
                              {orderInfoList?.receiverPhone || "휴대폰번호 데이터 없음"}
                            </OrderTextLong>
                          </OrderInfoList>
                          <OrderInfoList>
                            <OrderSubTitle>주소</OrderSubTitle>
                            <OrderTextLong>
                              {orderInfoList?.receiverAddress +
                                orderInfoList?.receiverDetailAddress || "주소 데이터 없음"}
                            </OrderTextLong>
                          </OrderInfoList>
                          {orderInfoList?.receiverMemo !== "" && (
                            <OrderInfoList>
                              <OrderSubTitle>배송메모</OrderSubTitle>
                              <OrderTextLong>{orderInfoList?.receiverMemo}</OrderTextLong>
                            </OrderInfoList>
                          )}
                        </OrderInfoListBoxInner>
                      </OrderInfoListBox>
                    </OrderInfoListInner>
                  </OrderDetailInner>
                </OrderDetailSection>
              </OrderDetailArea>
            </OrderDetailWrap>
          </ContentSection>
        </FlexLayOut>

        <PC>
          {/* 배송조회 모달 */}
          {isModal && <MyDeliveryTrackingModal setIsModal={setIsModal} />}
        </PC>
      </Container>
    </>
  );
}

const Container = styled.div`
  padding: 180px 0 250px;
  ${({ returnModal }) => returnModal && `display : none;`}

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (250 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: 0 0 calc(100vw * (150 / 428));
  }
`;
const OrderDetailWrap = styled.div`
  @media screen and (max-width: 930px) {
  }
`;
const OrderDetailArea = styled.div``;

const OrderDetailSection = styled.section`
  padding: 30px 0;
  &:not(:last-child) {
    border-bottom: 10px solid #f9f9f9;
  }
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const OrderDetailInner = styled.div`
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 5px;
    background-color: #f9f9f9;
    margin: 30px 0;
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (30 / 1320)) 0;
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      height: 10px;
      margin: calc(100vw * (30 / 428)) 0;
    }
  }
`;

const OrderDetailInfo = styled.div`
  width: 990px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (990 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;

// 주문 번호
const ListTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 16px;
  padding: 0 20px;

  /* 드래그 허용 */
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-user-drag: text;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: 0 calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }

  > span{
    font-weight: 500;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    -webkit-user-drag: text;
  }
  > button{
    font-size: 12px;
    border: 1px solid #777;
    border-radius: 10%;
    color: #777;
    margin-left: 6px;
    padding: 0 4px;
    @media screen and (max-width: 1320px) {
      font-size: calc(100vw * (12 / 1320));
      margin-left: calc(100vw * (6 / 1320));
      padding: 0 calc(100vw * (4 /1320));
    }
    @media screen and (max-width: 930px) {
      font-size: calc(100vw * (12 / 428));
      margin-left: calc(100vw * (4 / 428));
      padding: 0 calc(100vw * (4 / 428));
    }
  }
`;
const ListDate = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 15px;
  padding: 0 20px;
  margin-top: 5px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: 0 calc(100vw * (20 / 1320));
    margin-top: calc(100vw * (5 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    padding: 0 calc(100vw * (25 / 428));
    margin-top: calc(100vw * (5 / 428));
  }
`;

// 상품 정보
const BrandTextWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (10 / 428));
  }
`;
const BrandText = styled.p`
  color: #111;
  font-weight: 600;
  font-size: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const MoreImg = styled.img`
  width: 24px;
  height: 24px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;

const OrderDetailListWrap = styled.ul`
  margin-top: 20px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const OrderDetailList = styled.li`
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eee;
    margin: 30px 0;
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (30 / 1320)) 0;
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (30 / 428));
    }
    &:not(:last-child)::after {
      display: none;
    }
  }
`;
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImgBox = styled.div`
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 1px solid #eee;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    height: calc(100vw * (100 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    height: calc(100vw * (100 / 428));
  }
`;
const TextBox = styled.div`
  width: calc(100% - 112px);
  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (112 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (266 / 428));
  }
`;
const TextBoxInner = styled.div`
  position: relative;
  & > button{
    position: absolute;
    top: 0;
    right: 0;
    @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    }
    @media screen and (max-width: 930px) {
      font-size: calc(100vw * (15 / 428));
      width: auto;
      height: auto;
      top: calc(100vw * (-4 / 428));
      padding: calc(100vw * (2 / 428)) calc(100vw * (8 / 428));
    }
  }
`;

const ProductIng = styled.p`
  font-weight: 600;
  ${({ textColor }) => {
    if (textColor === "black") return `color: #111;`;
    else if (textColor === "pink") return `color: #FF006C;`;
    else if (textColor === "gray") return `color: #999;`;
  }}
  font-size: 16px;
  margin-bottom: 12px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-bottom: calc(100vw * (12 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (12 / 428));
  }
`;
const Brand = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (6 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (6 / 428));
  }
`;
const ProductName = styled.span`
  cursor: pointer;
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  display: inline-block;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProductColor = styled.p`
  display: inline;
  color: #777;
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (4 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (4 / 428));
  }
`;
const Price = styled.p`
  color: #333;
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;

// 상품 정보 라인
const TextBoxLine = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 0;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eee;
`;
const LineImg = styled.img`
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  width: 26px;
  height: 26px;

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (18 / 1320));
    width: calc(100vw * (26 / 1320));
    height: calc(100vw * (26 / 1320));
  }
  @media screen and (max-width: 930px) {
    right: calc(100vw * (18 / 428));
    width: calc(100vw * (26 / 428));
    height: calc(100vw * (26 / 428));
  }
`;

// button
const BtnWrap = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    display: block;
    margin-top: calc(100vw * (30 / 428));
  }
`;
const BtnList = styled.li`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 10px;
  }
  & button:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
    & button:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    justify-content: space-between;
    width: 100%;
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: calc(100vw * (10 / 428));
    }

    & button:not(:last-child) {
      margin-right: 0;
    }
  }
`;
const BtnWt = styled.button`
  padding: 0 20px;
  height: 40px;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (20 / 1320));
    height: calc(100vw * (40 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
const BtnWtMedium = styled(BtnWt)`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (184 / 428));
  }
`;
const BtnWtSmall = styled(BtnWt)`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
  }
`;
const BtnBk = styled(BtnWt)`
  color: #fff;
  background-color: #000;
`;
const BtnBkMedium = styled(BtnBk)`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (184 / 428));
  }
`;
const BtnBkSmall = styled(BtnBk)`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
  }
`;

const BtnGrey = styled.button`
width: 130px;
  height: 40px;
  color: #888;
  font-weight: 500;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #888;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (134 / 1320));
    height: calc(100vw * (40 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;


// 결제 정보
const OrderTitle = styled.p`
  width: 990px;
  margin: 0 auto;
  color: #333;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (990 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    font-size: calc(100vw * (16 / 428));
  }
`;
const OrderInfoListInner = styled.div`
  width: 990px;
  margin: 20px auto 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (990 / 1320));
    margin: calc(100vw * (20 / 1320)) auto 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (20 / 428)) auto 0;
  }
`;
const OrderInfoListBox = styled.div`
  width: 506px;
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eee;
    margin: 20px 0;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (506 / 1320));
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 1320)) 0;
    }
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 428)) 0;
    }
  }
`;
const OrderInfoListBoxInner = styled.ul`
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000;
    margin: 20px 0;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 1320)) 0;
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 428)) 0;
    }
  }
`;
const OrderInfoList = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (12 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (12 / 428));
    }
  }
`;
const OrderSubTitle = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const OrderText = styled(OrderSubTitle)`
  color: #333;
  /* 드래그 허용 */
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-user-drag: text;
  > span{
    /* 드래그 허용 */
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    -webkit-user-drag: text;
  }
  > button{
    font-size: 12px;
    color: #777;
    border: 1px solid #777;
    border-radius: 10%;
    margin-left: 6px;
    padding: 0 4px;
    @media screen and (max-width: 1320px) {
      font-size: calc(100vw * (12 / 1320));
      margin-left: calc(100vw * (6 / 1320));
      padding: 0 calc(100vw * (4 / 1320));
    }
    @media screen and (max-width: 930px) {
    font-size: calc(100vw * (12 / 428));
    margin-left: calc(100vw * (4 / 428));
    padding: 0 calc(100vw * (4 / 428));
    }
  }
`;
const OrderTextLong = styled(OrderText)`
  width: calc(100% - 96px);
  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (96 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (282 / 428));
  }
`;
const OrderSubTitleBold = styled(OrderSubTitle)`
  color: #333;
  font-weight: 500;
`;
const OrderTextBold = styled(OrderText)`
  font-weight: 600;
`;
const OrderAllAmountTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const OrderAllAmountText = styled.p`
  color: #333;
  font-weight: 600;
  font-size: 20px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
  }
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;

// pc - page button
const PageButtonWrap = styled.div`
  padding: 14px 20px;
  border-bottom: 1px solid #eee;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (14 / 1320)) calc(100vw * (20 / 1320));
  }
`;
const PageButtonArea = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;
const PageButton = styled.div`
  width: 32px;
  height: 32px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
`;
const PageButtonTitle = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 15px;
  margin-left: 5px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (5 / 1320));
  }
`;
// order text wrap
const ListBox = styled.ul`
  padding: 20px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (10 / 428)) 0;
  }
`;
const List = styled.li`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const Title = styled.p`
  line-height: 1.6;
  color: #777;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const Text = styled(Title)`
  width: calc(100% - 96px);
  color: #333;

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (96 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (266 / 428));
  }
`;
const OrderInfoWrap = styled.div``;
