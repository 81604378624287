import React, { useState } from 'react';
import styled from 'styled-components';

import Header from '../common/header/Header';
import Footer from '../common/footer/Footer';

import SearchProduct from './SearchProduct';
import {DescTitleAndText} from '../../components/management/Description';
import {ManagementTap} from '../../components/management/ManagementTap';
import {ProductListTab} from './ListTab';
import ProductListEl from './product/ProductListEl';

import ContenstSimpleListEl from './contents/ContenstSimpleListEl';

export default function ProductList({activeTab}) {
    const [viewSet, setViewSet] = useState('list');
    // const [productListTab, setProductListTab] = useState(0);

    const ListView = (viewSet)=>{
        switch(viewSet){
            case 'list':
                return(
                    <ProductListWrap>
                        {Array(8).fill().map(()=> {
                            return(
                                <ProductListEl setViewSet={setViewSet}/>
                            )
                        })}
                    </ProductListWrap>
                )
                break;
            case 'simple':
                return(
                    <ContentsSimpleWrap>
                        {
                            Array(8).fill().map(()=> {
                                return(
                                    <ContenstSimpleListEl/>
                                )
                            })
                        }
                    </ContentsSimpleWrap>
                )
                break;
        }
    }

    return(
        <>
            { viewSet == 'changeOrder' && <DescTitleAndText
            title={'제품 노출 순서 변경'}
            text={<p>Drag & Drop을 통해 제품 Display 순서를 변경하실 수 있습니다.<br/>변경 된 순서는 아래 “저장” 버튼을 클릭하여야 제품 Display에 반영 됩니다.</p>}/>
            }
            <ManagementTap activeTab={activeTab}/>
            <Wrapper>
                <ProductListTab setViewSet={setViewSet}/>
                {ListView(viewSet)}
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    position:relative;
    margin:0 auto;
    width: calc(100vw * (1300 / 1920));

    @media screen and (max-width:1024px){
        width:100%; padding-left:calc(100vw * (25 / 428)); padding-right:calc(100vw * (25 / 428));
        padding-top:36px;
    }
`;

const ProductListWrap = styled.div`
    padding-bottom:calc(100vw * (87 / 1920));  
`;

const ContentsSimpleWrap = styled.div`
    width:100%; display:flex; flex-wrap:wrap;
    margin-left:-9px; margin-right:-9px; padding:0 calc(100vw * (64 / 1920));
    &:after { content:''; display:block; clear:both; }
    
    @media screen and (max-width:1024px){
        margin-left:-6px; margin-right:-6px;
    }
`;
