import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";

// controller
import qs from "qs";
import { imgURL } from "../../server/appInfoController";

//img
import ProductImg from "../../img/product/pay_product_img1.png";
import OrderArrowIcon from "../../img/icon/icon_order_arrow.svg";
import IconBack from "../../img/icon/icon_header_back.svg";
import MoreIcon from "../../img/icon/icon_more_button.svg";

import MyPageBottom from "../../page/mypage/MyPageBottom";

// component
import MyPageLayOutMenu from "../mypage/MyPageLayOutMenu";
import MyPageLayOutName from "../mypage/MyPageLayOutName";
import MyPageLayOutInfo from "../mypage/MyPageLayOutInfo";
import Commas from "../../server/Commas";

export default function OrderProductDetail({ setReturnModal, clickOrderItem, clickOrderDetail }) {
  //페이지 이동
  const history = useHistory();
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  // get status
  const getStatus = (mog_order_status) => {
    switch (mog_order_status) {
      case 0:
        return "미입금";
      case 5:
        return "옵션변경요청";
      case 6:
        return "배송지 변경요청";
      case 10:
        return "무통장 대기";
      case 11:
        return "결제취소";
      case 12:
        return "결제실패";
      case 13:
        return "신규주문";
      case 14:
        return "상품준비중";
      case 20:
        return "결제완료";
      case 30:
        return "배송준비";
      case 41:
        return "배송중";
      case 42:
        return "배송완료";
      case 43:
        return "부분 배송중";
      case 44:
        return "구매 확정";
      case 50:
        return "반품요청";
      case 51:
        return "반품중";
      case 52:
        return "반품완료";
      case 53:
        return "반품수거 접수완료";
      case 54:
        return "반품 수거중";
      case 55:
        return "반품 수거완료";
      case 57:
        return "교환 요청";
      case 58:
        return "교환 접수";
      case 59:
        return "교환 수거중";
      case 60:
        return "교환 수거완료";
      case 61:
        return "교환중";
      case 62:
        return "교환완료";
      case 70:
        return "주문취소";
      case 71:
        return "취소 요청";
      case 72:
        return "취소 대기";
      case 73:
        return "취소 거부";
      default:
        return;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // 취소 정책
  // mypage bottom data
  const myPageBottomTextList = [
    <>
      - 상품 수령 후 7일 이내 접수 된 교환/반품 유효합니다.
      <br />
      - 수령 후 세탁, 향수 사용 등 상품의 가치가 하락한 경우에는 교환이나 반품이 불가합니다.
      <br />
      - 받으신 택배사가 아닌 다른 택배사를 이용하여 반품하실 경우 추가 비용이 발생 할 수 있습니다.
      <br />
      - 구매 하실 때 할인 받으셨던 쿠폰 할인액은 반환 됩니다.
      <br />
      - 제품의 택(tag)이 분실/훼손 된 경우 반품이 불가능 합니다.
      <br />
      - 사은품을 사용하셨거나 분실한 경우 반품이 불가능 합니다.
      <br />
      - 제품 박스를 분실하거나 훼손한 경우 반품이 거절 되거나 비용이 청구 될 수 있습니다.
      <br />
      - 교환의 경우, 브랜드사의 기준에 따라 선수거 후 교환 출고가 진행될 수 있습니다.
      <br />
      - 교환 택배비는 고객센터로 문의 주시면 상담원이 확인하여 안내 드리겠습니다.
      <br />- 교환 상품이 품절 될 경우 취소 안내를 받을 수 있습니다.
    </>,
  ];

  return (
    <Container>
      {/* page */}
      <PC>
        <MyPageLayOutName />
      </PC>

      {/* content */}
      <FlexLayOut>
        <PC>
          <MyPageLayOutMenu />
        </PC>

        <ContentSection>
          <PC>
            <MyPageLayOutInfo />
          </PC>

          {/* 주문 상세 */}
          <OrderDetailWrap>
            <PC>
              <PageButtonWrap>
                <PageButtonArea
                  onClick={() => {
                    setReturnModal(false);
                  }}
                >
                  <PageButton>
                    <img src={IconBack} alt="back-img" />
                  </PageButton>
                  <PageButtonTitle>주문상세</PageButtonTitle>
                </PageButtonArea>
              </PageButtonWrap>
            </PC>

            <OrderDetailArea>
              {/* 주문 번호 */}
              <OrderDetailSection>
                <OrderDetailInner>
                  <ListTitle>주문번호 {query.orderId}</ListTitle>
                  <ListDate>
                    {clickOrderItem?.mog_payment_date?.replaceAll("-", ".") ||
                      "취소 일자 데이터 없음"}
                  </ListDate>
                </OrderDetailInner>
              </OrderDetailSection>

              {/* 상품 정보 */}
              <OrderDetailSection>
                <OrderDetailInner>
                  <OrderDetailInfo>
                    <BrandTextWrap
                      onClick={() => {
                        history.push(`/store/brand/detail/${clickOrderItem.bc_id}?page=1`);
                      }}
                    >
                      <BrandText>{clickOrderItem.bc_title || "(BRAND)"}</BrandText>
                      <MoreImg src={MoreIcon} alt="more-icon" />
                    </BrandTextWrap>
                    {/* 상품 정보 >> 주문(구매)취소 */}
                    {clickOrderDetail === "cancel" ? (
                      <OrderDetailListWrap>
                        <OrderDetailList>
                          <ProductInfoWrap>
                            <ImgBox
                              onClick={() => {
                                history.push(`/store/detail/${clickOrderItem.prd_id}`);
                              }}
                            >
                              <img
                                src={
                                  clickOrderItem.prd_thumbnail
                                    ? `${imgURL}${clickOrderItem.prd_thumbnail}`
                                    : ProductImg
                                }
                                alt={
                                  clickOrderItem.prd_thumbnail ? "product-thumbnail" : "default-img"
                                }
                              />
                            </ImgBox>
                            <TextBox>
                              <TextBoxInner>
                                <ProductIng textColor="pink">
                                  {getStatus(clickOrderItem.mog_order_status)}
                                </ProductIng>
                                <ProductName
                                  onClick={() => {
                                    history.push(`/store/detail/${clickOrderItem.prd_id}`);
                                  }}
                                >
                                  {clickOrderItem.prd_name}
                                </ProductName>
                                {clickOrderItem?.optionInfo?.length > 0 &&
                                  clickOrderItem?.optionInfo?.map((optionItem, idx) => {
                                    return (
                                      <ProductColor key={`${optionItem.optName}-${idx}`}>
                                        {`${optionItem.optName} / ${clickOrderItem.orderCount}개`}
                                      </ProductColor>
                                    );
                                  })}
                                <Price>
                                  {Commas.numberWithCommas(clickOrderItem.prd_list_price)}원
                                </Price>
                              </TextBoxInner>
                            </TextBox>
                          </ProductInfoWrap>
                        </OrderDetailList>
                      </OrderDetailListWrap>
                    ) : (
                      <OrderDetailListWrap>
                        {/* 상품 정보 >> 교환 */}
                        <OrderDetailList>
                          <ProductInfoWrap>
                            <ImgBox
                              onClick={() => {
                                history.push(`/store/brand/detail/${clickOrderItem.bc_id}?page=1`);
                              }}
                            >
                              <img
                                src={
                                  clickOrderItem.prd_thumbnail
                                    ? `${imgURL}${clickOrderItem.prd_thumbnail}`
                                    : ProductImg
                                }
                                alt={
                                  clickOrderItem.prd_thumbnail ? "product-thumbnail" : "default-img"
                                }
                              />
                            </ImgBox>
                            <TextBox>
                              <TextBoxInner>
                                <ProductIng textColor="gray">
                                  {getStatus(clickOrderItem.mog_order_status)}
                                </ProductIng>
                                <ProductName
                                  onClick={() => {
                                    history.push(`/store/detail/${clickOrderItem.prd_id}`);
                                  }}
                                >
                                  {clickOrderItem.prd_name}
                                </ProductName>
                                {clickOrderItem?.optionInfo?.length > 0 &&
                                  clickOrderItem?.optionInfo?.map((optionItem, idx) => {
                                    return (
                                      <ProductColor key={`${optionItem.optName}-${idx}`}>
                                        {`${optionItem.optName} / ${clickOrderItem.orderCount}개`}
                                      </ProductColor>
                                    );
                                  })}
                                <Price>
                                  {Commas.numberWithCommas(clickOrderItem.prd_list_price)}원
                                </Price>
                              </TextBoxInner>
                              <TextBoxLine>
                                <Line />
                                <LineImg src={OrderArrowIcon} alt="" />
                              </TextBoxLine>
                              <TextBoxInner>
                                {/* <ProductIng textColor="pink">
                                  {getStatus(clickOrderItem.mog_order_status)}
                                </ProductIng> */}
                                <ProductName
                                  onClick={() => {
                                    history.push(`store/detail/${clickOrderItem.prd_id}`);
                                  }}
                                >
                                  {clickOrderItem.prd_name}
                                </ProductName>
                                <ProductColor>
                                  {clickOrderItem?.swapOptionInfo?.length > 0 &&
                                    clickOrderItem?.swapOptionInfo?.map((option, index) => {
                                      return (
                                        <>
                                          {index !== 0
                                            ? `${option.optName}, `
                                            : `${option.optName}`}
                                        </>
                                      );
                                    })}
                                  {` / ${clickOrderItem.swapOrderCount}개`}
                                </ProductColor>
                              </TextBoxInner>
                            </TextBox>
                          </ProductInfoWrap>
                        </OrderDetailList>
                      </OrderDetailListWrap>
                    )}
                  </OrderDetailInfo>

                  <CancelInfoListBox>
                    <OrderInfoListBoxInner>
                      <OrderInfoList>
                        <OrderSubTitle>
                          {clickOrderDetail === "cancel" ? "취소" : "교환"}요청일
                        </OrderSubTitle>
                        <OrderTextLong>
                          {clickOrderItem?.mog_cancel_date?.replaceAll("-", ".") ||
                            "일자 데이터 없음"}
                        </OrderTextLong>
                      </OrderInfoList>
                      <OrderInfoList>
                        <OrderSubTitle>
                          {clickOrderDetail === "cancel" ? "취소" : "교환"}완료일
                        </OrderSubTitle>
                        <OrderTextLong>
                          {clickOrderItem?.mog_cancel_date?.replaceAll("-", ".") ||
                            "일자 데이터 없음"}
                        </OrderTextLong>
                      </OrderInfoList>
                      <OrderInfoList>
                        <OrderSubTitle>
                          {clickOrderDetail === "cancel" ? "취소" : "교환"} 사유
                        </OrderSubTitle>
                        <OrderTextLong>{clickOrderItem?.mog_cancel_reason || "-"}</OrderTextLong>
                      </OrderInfoList>
                      {/* <OrderInfoList>
                        <OrderSubTitle>상세 사유</OrderSubTitle>
                        <OrderTextLong>-</OrderTextLong>
                      </OrderInfoList> */}
                    </OrderInfoListBoxInner>
                  </CancelInfoListBox>
                </OrderDetailInner>
              </OrderDetailSection>

              {/* 반품 상세 정보 */}
              {/* <OrderDetailSection>
                <OrderTitleBox>
                  <OrderTitle>• 반품 비용</OrderTitle>
                </OrderTitleBox>
                <CancelInfoListBox>
                  <OrderInfoListBoxInner>
                    <OrderInfoList>
                      <OrderSubTitle>반품 비용</OrderSubTitle>
                      <OrderTextLong>5,000원</OrderTextLong>
                    </OrderInfoList>
                    <OrderInfoList>
                      <OrderSubTitle>지불방법</OrderSubTitle>
                      <OrderTextLong>쇼핑몰 계좌로 송금</OrderTextLong>
                    </OrderInfoList>
                  </OrderInfoListBoxInner>
                </CancelInfoListBox>
              </OrderDetailSection>
              <OrderDetailSection>
                <OrderTitleBox>
                  <OrderTitle>• 반품수거방법</OrderTitle>
                </OrderTitleBox>
                <CancelInfoListBox>
                  <OrderInfoListBoxInner>
                    <OrderInfoList>
                      <OrderSubTitle>수거방법</OrderSubTitle>
                      <OrderTextLong>직접 보낼게요</OrderTextLong>
                    </OrderInfoList>
                  </OrderInfoListBoxInner>
                </CancelInfoListBox>
              </OrderDetailSection> */}

              {/* 교환 상세 정보 */}
              {/* <OrderDetailSection>
                <OrderTitleBox>
                  <OrderTitle>• 교환 비용</OrderTitle>
                </OrderTitleBox>
                <CancelInfoListBox>
                  <OrderInfoListBoxInner>
                    <OrderInfoList>
                      <OrderSubTitle>교환 비용</OrderSubTitle>
                      <OrderTextLong>5,000원</OrderTextLong>
                    </OrderInfoList>
                    <OrderInfoList>
                      <OrderSubTitle>지불방법</OrderSubTitle>
                      <OrderTextLong>쇼핑몰 계좌로 송금</OrderTextLong>
                    </OrderInfoList>
                    <OrderInfoList>
                      <OrderSubTitle>계좌정보</OrderSubTitle>
                      <OrderTextLong>
                        기업은행 12345678901234<br/>
                        예금주 : 왈라
                      </OrderTextLong>
                    </OrderInfoList>
                  </OrderInfoListBoxInner>
                </CancelInfoListBox>
              </OrderDetailSection>
              <OrderDetailSection>
                <OrderTitleBox>
                  <OrderTitle>• 교환수거방법</OrderTitle>
                </OrderTitleBox>
                <CancelInfoListBox>
                  <OrderInfoListBoxInner>
                    <OrderInfoList>
                      <OrderSubTitle>수거방법</OrderSubTitle>
                      <OrderTextLong>수거해주세요</OrderTextLong>
                    </OrderInfoList>
                  </OrderInfoListBoxInner>
                </CancelInfoListBox>
              </OrderDetailSection> */}

              {/* 배송지 정보 */}
              <OrderDetailSection>
                <OrderTitleBox>
                  <OrderTitle>• 배송지 정보</OrderTitle>
                </OrderTitleBox>
                <CancelInfoListBox>
                  <OrderInfoListBoxInner>
                    <OrderInfoList>
                      <OrderSubTitle>받는사람</OrderSubTitle>
                      <OrderTextLong>
                        {clickOrderItem.receiver_name || "받는사람 데이터 없음"}
                      </OrderTextLong>
                    </OrderInfoList>
                    <OrderInfoList>
                      <OrderSubTitle>휴대폰번호</OrderSubTitle>
                      <OrderTextLong>
                        {clickOrderItem.receiver_phone || "휴대폰 번호 데이터 없음"}
                      </OrderTextLong>
                    </OrderInfoList>
                    <OrderInfoList>
                      <OrderSubTitle>주소</OrderSubTitle>
                      <OrderTextLong>
                        {clickOrderItem.receiver_zipcode
                          ? "[" + clickOrderItem.receiver_zipcode + "]"
                          : "주소 데이터 없음"}
                        {clickOrderItem.receiver_address + clickOrderItem.receiver_address_detail}
                      </OrderTextLong>
                    </OrderInfoList>
                    <OrderInfoList>
                      <OrderSubTitle>배송메모</OrderSubTitle>
                      <OrderTextLong>{clickOrderItem.receiver_request || "-"}</OrderTextLong>
                    </OrderInfoList>
                  </OrderInfoListBoxInner>
                </CancelInfoListBox>
              </OrderDetailSection>

              {/* 결제 정보 */}
              {clickOrderDetail === "cancel" && (
                <OrderDetailSection>
                  <OrderTitleBox>
                    <OrderTitle>• 결제정보</OrderTitle>
                  </OrderTitleBox>
                  <RefundInfo>
                    <OrderDetailInner>
                      <OrderInfoListBox>
                        <OrderInfoListBoxInner>
                          <OrderInfoList>
                            <RefundText>
                              총{" "}
                              <RefundPrice>
                                <span>
                                  {Commas.numberWithCommas(clickOrderItem.mog_dis_price)}원
                                </span>
                              </RefundPrice>{" "}
                              환불
                            </RefundText>
                          </OrderInfoList>
                          <OrderInfoList>
                            <OrderSubTitleBold>환불 금액</OrderSubTitleBold>
                            <OrderTextBold>
                              {Commas.numberWithCommas(clickOrderItem.prd_list_price)}원
                            </OrderTextBold>
                          </OrderInfoList>
                          <OrderInfoList>
                            <OrderSubTitleBold>포인트 환불</OrderSubTitleBold>
                            <OrderTextBold>
                              {Commas.numberWithCommas(clickOrderItem.txn_used_point)}원
                            </OrderTextBold>
                          </OrderInfoList>
                        </OrderInfoListBoxInner>
                      </OrderInfoListBox>
                    </OrderDetailInner>
                  </RefundInfo>
                  <OrderDetailInner>
                    <OrderInfoListInner>
                      <OrderInfoListBox>
                        <OrderInfoListBoxInner>
                          {/* <OrderInfoList>
                            <OrderSubTitleBold>주문 금액</OrderSubTitleBold>
                            <OrderTextBold>
                              {" "}
                              {Commas.numberWithCommas(clickOrderItem.mog_dis_price)}원
                            </OrderTextBold>
                          </OrderInfoList> */}
                          <OrderInfoList>
                            <OrderSubTitleBold>상품 금액</OrderSubTitleBold>
                            <OrderText>
                              {Commas.numberWithCommas(clickOrderItem.prd_list_price)}원
                            </OrderText>
                          </OrderInfoList>
                          <OrderInfoList>
                            <OrderSubTitle>배송비</OrderSubTitle>
                            <OrderText>0원</OrderText>
                          </OrderInfoList>
                        </OrderInfoListBoxInner>
                        <OrderInfoListBoxInner>
                          {/* <OrderInfoList>
                          <OrderSubTitleBold>할인금액</OrderSubTitleBold>
                          <OrderTextBold>(-)1,000원</OrderTextBold>
                        </OrderInfoList> */}
                          <OrderInfoList>
                            <OrderSubTitle>포인트 사용</OrderSubTitle>
                            <OrderText>
                              {Commas.numberWithCommas(clickOrderItem.txn_used_point)}원
                            </OrderText>
                          </OrderInfoList>
                        </OrderInfoListBoxInner>
                        <OrderInfoListBoxInner>
                          <OrderInfoList>
                            <OrderAllAmountTitle>총 결제금액</OrderAllAmountTitle>
                            <OrderAllAmountText>
                              {Commas.numberWithCommas(clickOrderItem.mog_dis_price)}원
                            </OrderAllAmountText>
                          </OrderInfoList>
                          <OrderInfoList>
                            <OrderSubTitle>결제방법</OrderSubTitle>
                            <OrderText>{clickOrderItem.mog_payment_method}</OrderText>
                          </OrderInfoList>
                        </OrderInfoListBoxInner>
                      </OrderInfoListBox>
                    </OrderInfoListInner>
                    {/* 환불 관련 */}
                    {/* <RefundInfoPrice>
                    <OrderInfoList>
                      <OrderAllAmountTitle>환불 차감 금액</OrderAllAmountTitle>
                      <OrderAllAmountText>100,000원</OrderAllAmountText>
                    </OrderInfoList>
                    <OrderInfoList>
                      <OrderSubTitle>환불 상품 금액</OrderSubTitle>
                      <OrderText>10,000원</OrderText>
                    </OrderInfoList>
                    <OrderInfoList>
                      <OrderSubTitle>환불 차감 금액</OrderSubTitle>
                      <OrderText>0원</OrderText>
                    </OrderInfoList>
                  </RefundInfoPrice> */}
                  </OrderDetailInner>
                </OrderDetailSection>
              )}
            </OrderDetailArea>
          </OrderDetailWrap>
          <MyPageBottom textList={myPageBottomTextList} />
        </ContentSection>
      </FlexLayOut>
    </Container>
  );
}

const Container = styled.div`
  padding: 180px 0 80px;
  z-index: 99999999;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (80 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const OrderDetailWrap = styled.div``;
const OrderDetailArea = styled.div``;

const OrderDetailSection = styled.section`
  padding: 30px 0;
  &:not(:last-child) {
    border-bottom: 10px solid #f9f9f9;
  }
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const OrderTitleBox = styled.div`
  width: 990px;
  margin: 0 auto 20px;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (990 / 1320));
    margin: 0 auto calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto calc(100vw * (20 / 428));
  }
`;
const OrderTitle = styled.p`
  color: #333;
  font-weight: 500;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;

const OrderDetailInner = styled.div`
  width: 100%;
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  }
`;

const OrderDetailInfo = styled.div`
  width: 990px;
  margin: 0 auto;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eee;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (990 / 1320));
    &::after {
      margin-bottom: calc(100vw * (30 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    &::after {
      display: none;
    }
  }
`;

// 주문 번호
const ListTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 16px;
  padding: 0 20px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: 0 calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: 0;
  }
`;
const ListDate = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 15px;
  padding: 0 20px;
  margin-top: 5px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: 0 calc(100vw * (20 / 1320));
    margin-top: calc(100vw * (5 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    padding: 0;
    margin-top: calc(100vw * (5 / 428));
  }
`;

// 상품 정보
const BrandTextWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (10 / 428));
  }
`;
const BrandText = styled.p`
  color: #111;
  font-weight: 600;
  font-size: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const MoreImg = styled.img`
  width: 24px;
  height: 24px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;

const OrderDetailListWrap = styled.ul`
  padding: 20px 0 50px;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0 calc(100vw * (50 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: 0;
    margin-top: calc(100vw * (20 / 428));
  }
`;
const OrderDetailList = styled.li`
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (30 / 428));
    }
  }
`;
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImgBox = styled.div`
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 1px solid #eee;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    height: calc(100vw * (100 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    height: calc(100vw * (100 / 428));
  }
`;
const TextBox = styled.div`
  width: calc(100% - 112px);
  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (112 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (266 / 428));
  }
`;
const TextBoxInner = styled.div``;
const ProductIng = styled.p`
  font-weight: 600;
  ${({ textColor }) => {
    if (textColor === "black") return `color: #111;`;
    else if (textColor === "pink") return `color: #FF006C;`;
    else if (textColor === "gray") return `color: #999;`;
  }}
  font-size: 16px;
  margin-bottom: 12px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-bottom: calc(100vw * (12 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (12 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProductColor = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (4 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (4 / 428));
  }
`;
const Price = styled.p`
  color: #333;
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;

// 상품 정보 라인
const TextBoxLine = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 0;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eee;
`;
const LineImg = styled.img`
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  width: 26px;
  height: 26px;

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (18 / 1320));
    width: calc(100vw * (26 / 1320));
    height: calc(100vw * (26 / 1320));
  }
  @media screen and (max-width: 930px) {
    right: calc(100vw * (18 / 428));
    width: calc(100vw * (26 / 428));
    height: calc(100vw * (26 / 428));
  }
`;

// 결제 정보
const RefundInfo = styled.div`
  width: 495px;
  padding: 20px 0;
  margin: 0 0 20px;
  background-color: #f9f9f9;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (495 / 1320));
    padding: calc(100vw * (20 / 1320)) 0;
    margin: 0 0 calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    padding: calc(100vw * (20 / 428)) 0;
    margin: calc(100vw * (20 / 428)) 0 0;
  }
`;
const RefundText = styled.div`
  width: 100%;
  color: #333;
  font-weight: 500;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const RefundPrice = styled.p`
  position: relative;
  display: inline-block;

  & span {
    position: relative;
    z-index: 1;
    font-weight: 600;
  }

  &::after {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    content: "";
    display: inline-block;
    width: 100%;
    background-color: #fdf251;
  }

  @media screen and (max-width: 930px) {
    &::after {
      height: calc(100vw * (9 / 428));
    }
  }
`;

const OrderInfoListInner = styled.div`
  width: 100%;
  @media screen and (max-width: 930px) {
    margin: calc(100vw * (20 / 428)) 0 0;
  }
`;
const CancelInfoListBox = styled.div`
  width: 495px;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (495 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
    padding-top: calc(100vw * (20 / 428));
  }
`;
const OrderInfoListBox = styled.div`
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eee;
  }

  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 428)) 0;
    }
  }
`;
const OrderInfoListBoxInner = styled.div`
  width: 495px;
  padding: 0 25px;

  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eee;
    margin: 20px 0;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (495 / 1320));
    padding: 0 calc(100vw * (25 / 1320));
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 1320)) 0;
    }
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    padding: 0;
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 428)) 0;
    }
  }
`;

// 환불 관련
const RefundInfoPrice = styled.div`
  width: 495px;
  padding: 0 25px;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000;
    margin: 20px 0;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (495 / 1320));
    padding: 0 calc(100vw * (25 / 1320));
    &::before {
      margin: calc(100vw * (20 / 1320)) 0;
    }
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    padding: 0;
    &::before {
      margin: calc(100vw * (20 / 428)) 0;
    }
  }
`;

const OrderInfoList = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (12 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (12 / 428));
    }
  }
`;
const OrderSubTitle = styled.p`
  line-height: 1.5;
  color: #777;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const OrderText = styled(OrderSubTitle)`
  color: #333;
`;
const OrderTextLong = styled(OrderText)`
  width: calc(100% - 96px);
  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (96 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (282 / 428));
  }
`;
const OrderSubTitleBold = styled(OrderSubTitle)`
  color: #333;
  font-weight: 500;
`;
const OrderTextBold = styled(OrderText)`
  font-weight: 600;
`;
const OrderAllAmountTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const OrderAllAmountText = styled.p`
  color: #333;
  font-weight: 600;
  font-size: 20px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
  }
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;

// pc - page button
const PageButtonWrap = styled.div`
  padding: 14px 20px;
  border-bottom: 1px solid #eee;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (14 / 1320)) calc(100vw * (20 / 1320));
  }
`;
const PageButtonArea = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;
const PageButton = styled.div`
  width: 32px;
  height: 32px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
`;
const PageButtonTitle = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 15px;
  margin-left: 5px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (5 / 1320));
  }
`;
