import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { PaymentAction } from "../../../../store/actionCreators";
import styled from "styled-components";

import IconCheckbox from "../../../img/icon/icon_checkbox.png";

export default function PayInfo() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [socialEmail, setSocialEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [check, setCheck] = useState(false);
  const [infoTextState, setInfoTextState] = useState(false);

  const { userInfo } = useSelector((state) => state.userData);
  const { location } = useSelector((state) => state.paymentData);

  useEffect(() => {
    if (name && email && phone) {
      PaymentAction.savePersonalInfo({ name, email, phone });
      PaymentAction.checkInfo({ isPersonal: true });
    } else {
      PaymentAction.savePersonalInfo({ name: "", email: "", phone: "" });
      PaymentAction.checkInfo({ isPersonal: false });
    }
  }, [name, email, phone]);

  useEffect(() => {
    let userE = userInfo?.mem_email;
    if (userInfo) {
      if (userE?.indexOf("google_") !== -1) {
        setSocialEmail(userE?.split("google_")[1]);
      } else if (userE?.indexOf("apple_") !== -1) {
        setSocialEmail(userE?.split("apple_")[1]);
      } else if (userE?.indexOf("naver_") !== -1) {
        setSocialEmail(userE?.split("naver_")[1]);
      } else if (userE?.indexOf("kakao_") !== -1) {
        setSocialEmail(userE?.split("kakao_")[1]);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    setName(check ? location.receiver_username : "");
    setPhone(check ? location.receiver_phone : "");
    if (socialEmail.length > 0) {
      setEmail(check ? socialEmail : "");
    } else {
      setEmail(check ? userInfo.mem_email : "");
    }
    PaymentAction.checkInfo({ isDefault: check });
  }, [check]);

  useEffect(() => {
    if (phone.indexOf("-") !== -1) {
      setInfoTextState(true);
    } else {
      setInfoTextState(false);
    }
  }, [phone]);

  return (
    <Container>
      <PayInfoWrap>
        <TitleWrap>
          <Title>주문자</Title>
          <Checks>
            <span>배송지 정보와 동일</span>
            <ChkBox
              id={0}
              type="checkbox"
              checked={check}
              onChange={(e) => {
                setCheck((prev) => !prev);
              }}
            />
            <ChkBoxLabel htmlFor={0}></ChkBoxLabel>
          </Checks>
        </TitleWrap>
        <InfoWrap>
          <InfoBox>
            <InfoTitleWrap>
              <InfoTitle>
                이름<Sub>*</Sub>
              </InfoTitle>
            </InfoTitleWrap>
            <InfoInputWrap>
              <InfoInput
                placeholder="이름을 입력해주세요."
                readOnly={check}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </InfoInputWrap>
          </InfoBox>
          <InfoBox>
            <InfoTitleWrap>
              <InfoTitle>
                이메일<Sub>*</Sub>
              </InfoTitle>
            </InfoTitleWrap>
            <InfoInputWrap>
              <InfoInput
                placeholder="이메일 형식으로 입력해주세요."
                readOnly={check}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InfoInputWrap>
          </InfoBox>
          <InfoBox>
            <InfoTitleWrap>
              <InfoTitle>
                휴대전화<Sub>*</Sub>
              </InfoTitle>
            </InfoTitleWrap>
            <InfoInputWrap>
              <InfoInput
                placeholder="- 를 제외한 숫자만 입력해주세요."
                readOnly={check}
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </InfoInputWrap>
          </InfoBox>
          {infoTextState && (
            <InfoText>* - 를 제외한 숫자만 입력해주세요.</InfoText>
          )}
        </InfoWrap>
      </PayInfoWrap>
    </Container>
  );
}

const Checks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;

  & span {
    font-size: 15px;
    margin-right: 5px;
  }

  @media screen and (max-width: 1024px) {
    width: calc(100vw * (145 / 428));
    height: calc(100vw * (20 / 428));
    & span {
      font-size: calc(100vw * (14 / 428));
      margin-right: calc(100vw * (5 / 428));
    }
  }
`;
const ChkBoxLabel = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-indent: -9999px;
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    width: 16px;
    height: 16px;
    background: #000;
    border: 2px solid #000;
    border-radius: 5px;
    background: #000 url(${IconCheckbox}) no-repeat center/14px;
  }

  @media screen and (max-width: 1320px) {
    &&::before {
      top: calc(100vw * (-10 / 1320));
      width: calc(100vw * (16 / 1320));
      height: calc(100vw * (16 / 1320));
      border: calc(100vw * (2 / 1320)) solid #000;
      border-radius: calc(100vw * (5 / 1320));
      background: #000 url(${IconCheckbox}) no-repeat
        center/calc(100vw * (14/1320));
    }
  }

  @media screen and (max-width: 1024px) {
    &&::before {
      top: calc(100vw * (-10 / 428));
      width: calc(100vw * (16 / 428));
      height: calc(100vw * (16 / 428));
      border: calc(100vw * (2 / 428)) solid #000;
      border-radius: calc(100vw * (5 / 428));
      background: #000 url(${IconCheckbox}) no-repeat
        center/calc(100vw * (14/428));
    }
  }
`;
const ChkBox = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  & + label::after {
    display: block;
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    width: 16px;
    height: 16px;
    background: #fff;
    border: 2px solid #000;
    border-radius: 5px;
  }

  &:checked + label::after {
    display: none;
  }

  @media screen and (max-width: 1320px) {
    & + label::after {
      top: calc(100vw * (-10 / 1320));
      width: calc(100vw * (16 / 1320));
      height: calc(100vw * (16 / 1320));
      border: calc(100vw * (2 / 1320)) solid #000;
      border-radius: calc(100vw * (5 / 1320));
    }
    &:checked + label::after {
      top: calc(100vw * (-10 / 1320));
      width: calc(100vw * (16 / 1320));
      height: calc(100vw * (16 / 1320));
      border: calc(100vw * (2 / 1320)) solid #000;
      border-radius: calc(100vw * (5 / 1320));
    }
  }

  @media screen and (max-width: 1024px) {
    & + label::after {
      top: calc(100vw * (-10 / 428));
      width: calc(100vw * (16 / 428));
      height: calc(100vw * (16 / 428));
      border: calc(100vw * (2 / 428)) solid #000;
      border-radius: calc(100vw * (5 / 428));
    }
    &:checked + label::after {
      top: calc(100vw * (-10 / 428));
      width: calc(100vw * (16 / 428));
      height: calc(100vw * (16 / 428));
      border: calc(100vw * (2 / 428)) solid #000;
      border-radius: calc(100vw * (5 / 428));
    }
  }
`;

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: calc(100vw * (30 / 428));
    border-top: calc(100vw * (12 / 428)) solid #e9ebee;
  }
`;
const PayInfoWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 1024px) {
    width: calc(100vw * (388 / 428));
    margin: 0 auto;
  }
`;
const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #d9d9d9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 1024px) {
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (10 / 428));
    border-bottom: none;
  }
`;
const Title = styled.p`
  font-size: 22px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 1024px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
// Info
const InfoWrap = styled.div`
  width: 100%;
  margin-top: 12px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (12 / 1320));
  }

  @media screen and (max-width: 1024px) {
    margin-top: calc(100vw * (12 / 428));
  }
`;
const InfoBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (12 / 1320)) 0;
  }

  @media screen and (max-width: 1024px) {
    padding: calc(100vw * (12 / 428)) 0;
  }
`;
const InfoTitleWrap = styled.div`
  width: 100px;
  padding: 12px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (12 / 1320)) 0;
  }

  @media screen and (max-width: 1024px) {
    padding: calc(100vw * (12 / 428)) 0;
  }
`;
const InfoTitle = styled.p`
  display: flex;
  align-items: center;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 1024px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const Sub = styled.span`
  font-size: 13px;
  color: #f24f3c;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (13 / 1320));
  }

  @media screen and (max-width: 1024px) {
    font-size: calc(100vw * (13 / 428));
  }
`;
const InfoInputWrap = styled.div`
  width: 100%;
`;
const InfoInput = styled.input`
  width: 480px;
  height: 45px;
  font-size: 15px;
  color: #525252;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  padding: 0 10px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (480 / 1320));
    height: calc(100vw * (45 / 1320));
    font-size: calc(100vw * (15 / 1320));
    border: calc(100vw * (1 / 1320)) solid #e4e4e4;
    border-radius: calc(100vw * (5 / 1320));
    padding: 0 calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: calc(100vw * (45 / 428));
    font-size: calc(100vw * (15 / 428));
    border: calc(100vw * (1 / 428)) solid #e4e4e4;
    border-radius: calc(100vw * (5 / 428));
    padding: 0 calc(100vw * (10 / 428));
  }
`;

// infoText
const InfoText = styled.p`
  font-size: 12px;
  color: #969696;
  padding-left: 100px;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (12 / 1320));
    padding-left: calc(100vw * (100 / 1320));
  }
  @media screen and (max-width: 1024px) {
    font-size: calc(100vw * (8 / 428));
    padding-left: calc(100vw * (80 / 428));
  }
`;
