//react
import { useRef } from "react";
import styled, { keyframes } from "styled-components";
import serverController from "../../../server/serverController";
import { useSelector } from "react-redux";
import { RecommenderAction } from "../../../store/actionCreators";

export default function MyRecommenderPop({ setIsRecommenderPop, falseFunc }) {
  const recommenderName = useRef();

  const user = useSelector((state) => state)

  const trueFunc = () => {
    const chkId = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const inputData = recommenderName.current.value.replace(/ /g, '');
    if (inputData.length !== 0 && chkId.test(inputData)) {
      postRecommender(inputData);
      setIsRecommenderPop(false);
    };
    console.log(user);
    if (inputData.length === 0) return alert("추천인 아이디를 입력해주세요.");
    if (!chkId.test(inputData)) return alert("아이디 양식에 맞게 입력해주세요.");
  };

  const postRecommender = (inputData) => {
    serverController.connectFetchController(
      `api/user/recommender?recommender=${inputData}`,
      "PUT",
      null,
      (res) => {
        console.log(res, "res");
        if (res?.result === 1) {
          localStorage.setItem('recommenderPop', 'true');
          alert('추전인 정보가 등록되었습니다.');
          RecommenderAction.updateRecommender({
            recommender_cate: false
          })
        }
      },
      (err) => console.error(err)
    );
  };

  const dontShowFunc = () => {
    localStorage.setItem('recommenderPop', 'true');
    setIsRecommenderPop(false);
  };

  return (
    <Container>
      <Background onClick={falseFunc} />
      <Content>
        <TextBox>추천인 아이디를 입력해주세요.</TextBox>
        <RecommenderInput ref={recommenderName} type="text" placeholder="example@example.com"></RecommenderInput>
        <ButtonListWrap>

          <CancelButton onClick={falseFunc} type="button">
            취소
          </CancelButton>
          <ConfirmButton onClick={trueFunc} type="button">
            저장
          </ConfirmButton>
        </ButtonListWrap>
        <DontShowButton onClick={dontShowFunc}>
          다시 보지 않기
        </DontShowButton>
      </Content>
    </Container>
  );
}

// s :: animation
const LoginPop = keyframes`
0%{
    transform : translateY(-10px);
		opacity: 0.5;
}
	100% {
		opacity: 1;
	}
`;
const LoginBackgroundPop = keyframes`
0%{
		opacity: 0.4;
}
	100% {
		opacity: 1;
	}
`;
// e :: animation

const Container = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 700;
  animation: ${LoginBackgroundPop} 0.2s ease-in-out;
`;

// 컨텐츠
const Content = styled.div`
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 702;
  width: 398px;
  height: fit-content;
  padding: 0 25px;
  background: #fff;
  animation: ${LoginPop} 0.2s ease-out;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (398 / 1320));
    padding: 0 calc(100vw * (25 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (398 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;

const TextBox = styled.div`
  color: #333;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  padding: 50px 0 40px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    padding: calc(100vw * (50 / 1320)) 0 calc(100vw * (40 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    padding: calc(100vw * (50 / 428)) 0 calc(100vw * (40 / 428));
  }
`;

const RecommenderInput = styled.input`
  width: 100%;
  padding: 16px 5px;
  border: 1px solid #000;
  margin-bottom: 20px;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (16 / 1320)) calc(100vw * (10 / 1320));
    margin-bottom: calc(100vw * (20 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (16 / 428)) calc(100vw * (10 / 428));
    margin-bottom: calc(100vw * (20 / 428));
    font-size: calc(100vw * (16 / 428));
  }
`;
const ButtonListWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const ConfirmButton = styled.button`
  width: 169px;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (169 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (169 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
const CancelButton = styled(ConfirmButton)`
  color: #000;
  background-color: #fff;
`;


const DontShowButton = styled.button`
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #969696;
  text-decoration: underline;
  padding: 20px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
    font-size: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
    font-size: calc(100vw * (14 / 428));
  }
  /* &::before, ::after{
    content: "";
    position: absolute;
    left: 0;
    height: 18px;
    width: 2px;
    background-color: #000;
    @media screen and (max-width: 1320px) {
    height: calc(100vw * (18 / 1320));
    }
    @media screen and (max-width: 930px) {
      height: calc(100vw * (18 / 428));
    }
  }
  &::before{
    transform: rotate(45deg);
  }
  &::after{
    transform: rotate(-45deg);
  } */
 

`