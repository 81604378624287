import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import serverController from '../../server/serverController';
import { postLikeProducts } from '../../hooks/likeHooks';
import { imgURL } from '../../server/appInfoContoller';

import {
    Layout,
    StylistSponserdWrap,
    StyleSponList,
    StyleSponListItem,
    LikedIcon,
    Paging,
    NoneLi,
    MoreButton,
    LodingWrap,
    LodingImg,
} from './stylist.element';
import StylistProductItem from './StylistProductItem';

import sponProductImg from '../../images/stylist/FZX699AHE6F1ENM_02_large-grey.png';
import IconLoding from '../../img/icon_loding.svg';


const StylistSponserdList = ({ list, moreOff, MoreDataClick, isLoad, myList, mydata }) => {
    const history = useHistory();

    return (
        <StylistSponserdWrap>
            <StyleSponList>
                {
                    list.length > 0 ?
                        list.map((v, i) => {
                            return <StylistProductItem v={v} key={v?.purchase_id} myList={myList} mydata={mydata} />
                        })
                        : <NoneLi>
                            {
                                isLoad && "해당 유형의 상품이 없습니다."
                            }
                        </NoneLi>
                }
            </StyleSponList>
            <LodingWrap>
                {!isLoad && <LodingImg src={IconLoding} alt="" />}
                {
                    isLoad && moreOff && list.length > 0 ?
                        <MoreButton onClick={MoreDataClick}>See More</MoreButton>
                        :
                        null
                }
            </LodingWrap>
            {/* {
                !(list.length === 0) && <>
                <Paging>
                    <button className="prev-button" title="이전"></button>
                    <ul>
                    {                    
                        [1,2,3,4,5].map((item, i) => {
                            return <li>{item}</li>
                        })
                    }
                    </ul>
                    <button className="next-button" title="다음"></button>
                </Paging>
                </>
            } */}

        </StylistSponserdWrap>
    )
}

export default StylistSponserdList

