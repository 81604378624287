import React, { useEffect, useState } from "react";
import styled from "styled-components";

export default function ContentsPagination({ page, setPage, pageData, noScroll = false }) {
  const lastNum = pageData?.endPage;

  const [currentPageArr, setCurrentPageArr] = useState([]);

  const makePageArr = () => {
    let pageArr = [];
    for (let i = pageData.startPage; i <= pageData.endPage; i++) {
      pageArr.push(i);
    }
    setCurrentPageArr(pageArr);
  }

  useEffect(() => {
    if (lastNum) {
      makePageArr();
    }
    if (noScroll) return;
    const Timeout = setTimeout(() => {
      window.scrollTo({
        // top: 0,
        // behavior: "smooth",
      });
      clearTimeout(Timeout);
    }, 100);

  }, [page, lastNum, pageData]);


  return (
    <PaginationWrap>
      <ArrowButton
        onClick={() => {
          setPage(page * 1 - 1);
        }}
        disabled={page * 1 === 1}
      >
        &lt;
      </ArrowButton>
      {currentPageArr.map((item, i) => {
        return (
          <NumberButton
            key={item}
            onClick={() => {
              setPage(item);
            }}
            bold={page * 1 === item}
          >
            {item}
          </NumberButton>
        );
      })}
      <ArrowButton
        onClick={() => {
          setPage(page * 1 + 1);
        }}
        disabled={page * 1 === pageData?.maxPage}
      >
        &gt;
      </ArrowButton>
    </PaginationWrap>
  );
}
const PaginationWrap = styled.div`
  width: fit-content;
  margin: auto;
  padding: 40px 0;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (40 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const ArrowButton = styled.button`
  font-weight: 600;
  padding: 0 15px;
  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (15 / 1320));
  }
  ${({ disabled }) => disabled && `color : #dddddd;`}
  @media screen and (max-width: 930px) {
    padding: 0 calc(100vw * (15 / 428));
  }
`;
const NumberButton = styled.button`
  ${({ bold }) => bold && `font-weight : 800;`}
  font-size : 18px;
  padding: 0 15px;
  @media screen and (max-width: 1320px) {
    font-size: 0 calc(100vw * (18 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    padding: 0 calc(100vw * (15 / 428));
  }
`;
