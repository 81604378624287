import { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import serverController from "../../../server/serverController";
import styled from "styled-components";
import ContentsPagination from "../../component/common/pagination/ContentsPagination";
import ContentsReviewList from "./ContentsReviewList"
import { useSelector } from "react-redux";
import MyPageDefaultModal from "../common/modal/MyPageDefaultModal";


export default function ContentsReviewWrap({ clickContentsId, reviewData, getContentsReview, page, setPage }) {
  const { isLogged } = useSelector(state => state.userData);
  const [loginPop, setLoginPop] = useState(false);
  const history = useHistory();
  const location = useLocation();
  // 댓글 내용 저장 스테이트
  const [inquiryText, setInquiryText] = useState("");
  const reviewMaxLength = 400;

  // 댓글 보내기
  const postContentsReview = async () => {
    if (inquiryText.replace(/ /g, '').length === 0) return alert('댓글을 입력해주세요.');
    if (inquiryText.replace(/\n/g, '').length === 0) return alert('댓글을 입력해주세요.');
    await serverController.connectFetchController(`api/content/comment?contentsCommentContents=${inquiryText}&contentsId=${clickContentsId}`, "POST", null, (res) => {
      setInquiryText("");
    });
    getContentsReview();
  };


  useEffect(() => {
    inquiryText.length >= reviewMaxLength && setInquiryText(inquiryText.substring(0, 400));
  }, [inquiryText]);

  useEffect(() => {
  }, []);

  return (
    <>
      {loginPop && (
        <MyPageDefaultModal
          text="로그인 후 이용 가능합니다."
          trueText="로그인"
          trueFunc={() => {
            setTimeout(() => {
              if (location) return history.push(`/store/login?returnTo=/store`);
              history.push(`/store/login`);
            }, 0)
          }}
          falseFunc={() => setLoginPop(false)}
        />
      )}
      <ContentsDetailReviewTitle>
        {reviewData && <ReviewTitleText>댓글({reviewData.commentCount})</ReviewTitleText>}
        {!reviewData && <ReviewTitleText>댓글(0)</ReviewTitleText>}
      </ContentsDetailReviewTitle>
      <ContentsTextAreaWrap active={inquiryText.length > 0}>
        <ContentsTextArea
          value={inquiryText}
          placeholder="내용을 입력해주세요."
          onChange={(e) => {
            setInquiryText(e.target.value);
            !isLogged && setInquiryText('');
          }}
          onFocus={() => {
            !isLogged && setLoginPop(true);
          }}
        />
        <SubmitBtnWrap>
          <TextLength>
            <strong>{inquiryText.length}</strong>
            / 400
          </TextLength>
          <Btn
            disabled={inquiryText.length === 0}
            type="button"
            onClick={() => {
              inquiryText.length > 0 && postContentsReview();
            }}
          >
            등록
          </Btn>
        </SubmitBtnWrap>
      </ContentsTextAreaWrap>
      <ContentsDetailReviewWrap>
        {/* 댓글 배열 map 컴포넌트화 */}
        {reviewData && <ContentsReviewList reviewData={reviewData} getContentsReview={getContentsReview} page={page} />}
        {reviewData && <ContentsPagination page={reviewData.pagination.page} pageData={reviewData.pagination} setPage={setPage} />}
        {!reviewData && <Empty>댓글이 없습니다.</Empty>}
      </ContentsDetailReviewWrap>
    </>
  );
}


// review

const ContentsDetailReviewTitle = styled.div`
  width: 100%;
  margin-bottom: 22px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
    margin-bottom: calc(100vw * (22 / 428));
  }
`
const ReviewTitleText = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  position: relative;
  

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
  
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    padding: 0 calc(100vw * (7 / 428));
    &::after{
      content: "";
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      background-color: #fdf251;
      z-index: -1;
      bottom: calc(100vw * (-2 / 428));
      height: calc(100vw * (12 / 428));
    }
}
`
// textarea
const ContentsTextAreaWrap = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  /* padding: 15px; */
  ${({ active }) => active && `border: 1px solid #000;`}

  /* @media screen and (max-width: 1320px) {
    height: calc(100vw * (246 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (246 / 428));
  } */
`;
const ContentsTextArea = styled.textarea`
  width: 100%;
  height: 140px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  padding: 15px;
  border: none;
  
  &:disabled {
    background-color: #f9f9f9;
    border: none;
  }
  &::placeholder {
    color: #acacac;
  }
  
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (140 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (140 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (15 / 428));
  }
 
`;
const SubmitBtnWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  border-top: 1px solid #bcbcbc;
  padding: 10px;
  box-sizing: border-box;
  white-space: nowrap;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    padding: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: calc(100vw * (5 / 428));
  }
`;
const TextLength = styled.span`
    font-size: 15px;
    color: #999;
    line-height: 15px;
    @media screen and (max-width: 1320px) {
      font-size: calc(100vw * (15 / 1320));
      line-height: calc(100vw * (15 / 1320));
    }
    @media screen and (max-width: 930px) {
      font-size: calc(100vw * (15 / 428));
      line-height: calc(100vw * (15 / 428));
    }
    & strong{
      color: #111;
      padding-right: 5px;
    }
`

const Btn = styled.button`
  width: 50px;
  height: 100%;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  background-color: #000;
  border: 1px solid #000;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (54 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;

const ContentsDetailReviewWrap = styled.div`
  margin-top: 20px;
  @media screen and (max-width: 1320px) {
      margin-top: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
      margin-top: calc(100vw * (20 / 428));
  }
`
const Empty = styled.div`
  color: #999;
  width: 100%;
  text-align: center;
  margin-top: 100px;
  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (100 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (100 / 428));
  }
`