import { useState, useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";
import { useHistory } from "react-router-dom";

import Header from "../../component/common/Header";

import MyPageHeader from "./MyPageHeader";
import OrderDetailMain from "../../component/mypage/order/OrderDetailMain";
import { useSelector } from "react-redux";

export default function OrderDetailPage() {
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();
  const [returnModal, setReturnModal] = useState(false);

  const goBackFunc = () => {
    setReturnModal(false);
  };

  const validLogin = () => {
    if (isLogged) return;
    alert(`잘못된 접근입니다.`);
    history.push(`/store`);
  };

  useEffect(() => {
    validLogin();
  }, []);

  const orderDetailMainItem = {
    returnModal,
    setReturnModal,
  };

  // TODO :: pop
  useEffect(() => {
    if (!returnModal) return;
    const unListen = history.block((loc, action) => {
      if (action === "POP") {
        goBackFunc();
      }
      return false;
    });

    return () => {
      unListen();
    };
  }, [history, returnModal]);

  return (
    <>
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader
          title={"주문상세"}
          deleteBtn={returnModal}
          prevBtn={!returnModal}
          deleteFunc={goBackFunc}
        />
      </Mobile>
      <OrderDetailMain {...orderDetailMainItem} />
    </>
  );
}
