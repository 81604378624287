import React, { useState, useEffect, useCallback } from 'react';
import serverController from '../../../server/serverController';
import { PC, Mobile } from '../../../MediaQuery';

import {  
    FieldBox,
    Label,    
    LabelName,
    EmailInput,
    ErrorMessage   
} from '../auth.elements';
import {    
    SubCheckButton, 
    MobileSubBox
} from './signup.element';

const SignupEmailInput = ({ isCheck,setEmail, setIsCheck }) => {
    const [value, setValue] = useState("");
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    const onHandleChange = (e) => {       
        setValue(e.target.value)        
    };

    const onHandleCheck = async () => {
        await serverController.connectFetchController(`overlap/email?mem_email=${value}&type=2`,"POST",null,(res) => {
                console.log(res, "이메일 중복체크 결과 response");
                
                if (!value.includes("@")) { // 이메일 형식
                    setIsError(true);
                    setIsSuccess(false);
                    setErrorMessage("*이메일 형식으로 생성해주세요.");                    
                } else if (res?.result === 1) {  // 중복     
                    setIsError(true);
                    setIsSuccess(false);    
                    setErrorMessage("*중복된 이메일 아이디가 있습니다.");                                    
                } else { // 사용가능
                    setIsError(false);
                    setIsSuccess(true);                                       
                }
            },
            (err) => console.error(err)
        )
    }
    
    useEffect(() => {
        setEmail(value)
        setIsCheck(prev => ({...prev, isEmail: isSuccess })); 
    }, [isSuccess])

    useEffect(() => {
       if (isSuccess) {
           setIsSuccess(false);
       }
    }, [value])


    return (
        <>
        <PC>
        <FieldBox>
            <Label>
                <LabelName>이메일</LabelName>
                <EmailInput
                    required
                    value={value}
                    onChange={onHandleChange}
                />
            </Label>
            { isError && <ErrorMessage>{errorMessage}</ErrorMessage> }
            { isSuccess && <ErrorMessage success>*사용가능한 이메일 입니다.</ErrorMessage> }
            
            <SubCheckButton onClick={onHandleCheck}>중복 확인</SubCheckButton>
        </FieldBox>
        </PC>

        <Mobile>
            <FieldBox>
                <Label>
                    <LabelName>이메일</LabelName>
                    <EmailInput value={value} onChange={onHandleChange} />
                </Label>
                { isError && <ErrorMessage>{errorMessage}</ErrorMessage> }
                { isSuccess && <ErrorMessage success>*사용가능한 이메일 입니다.</ErrorMessage> }

                <MobileSubBox>
                    <SubCheckButton onClick={onHandleCheck}>중복 확인</SubCheckButton>
                </MobileSubBox>
            </FieldBox>
        </Mobile>
        </>
    )
}

export default SignupEmailInput
