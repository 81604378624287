import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Mobile, PC } from "../../MediaQuery";
import { Link } from "react-router-dom";
import styled from "styled-components";
import serverController from "../../server/serverController";

import {
  Wrapper,
  Section,
  SectionHeadings,
  MoreButton,
} from "./DramaBanner.elements";
import { DramaItemsContainer, ItemBackground } from "./MainDrama.elements";
import MainDramaItem from "./MainDramaItem";

import ArrowLeft from "../../images/drama_arrow_left.png";
import ArrowRight from "../../images/drama_arrow_right.png";

const MainDramaBanner = ({
  isContentModal,
  setIsContentModal,
  contentId,
  setContentId,
}) => {
  const [list, setList] = useState([]);
  const [load, setLoad] = useState(true);
  const listSectionRef = useRef(null);
  let isLoad = 0;

  function getList() {
    serverController.connectFetchController(
      `main/contentslist?offset=${list.length}&limit=20`,
      "GET",
      null,
      (res) => {
        console.log(res, "메인 drama 인트로 response");
        if (res?.result === 1) {
          setList((e) => {
            return e.concat(res?.list);
          });
          isLoad = false;
          setLoad(false);
        }
      },
      (err) => console.error(err)
    );
  }

  const checkScrollHeight = async () => {
    if (isLoad || load) {
      return;
    }
    const bottom =
      listSectionRef.current.scrollHeight - listSectionRef.current.scrollTop <=
      listSectionRef.current.clientHeight + 50;
    if (bottom) {
      isLoad = true;
      setLoad(true);
      getList();
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Section
        ref={listSectionRef}
        onScroll={checkScrollHeight}
        className="scroll_section"
      >
        <Wrapper>
          <DramaItemsContainers>
            {list.length > 0
              ? list.map((v, i) => {
                  return (
                    <MainDramaItem
                      value={v}
                      index={i}
                      isContentModal={isContentModal}
                      setIsContentModal={setIsContentModal}
                      contentId={contentId}
                      setContentId={setContentId}
                    />
                  );
                })
              : null}
          </DramaItemsContainers>

          {/* <MoreButton><Link to="/category/2">See More</Link></MoreButton> */}
        </Wrapper>
      </Section>
    </>
  );
};

const DramaItemsContainers = styled(DramaItemsContainer)`
  width: calc(100vw * (1300 / 1920));
  margin: 0 auto;
  padding-bottom: calc(100vw * (53 / 1920));

  & .swiper-slide {
    display: flex;
    align-items: center;
    opacity: 0;
    transition: 1s;
  }
  & .swiper-container {
    height: 100%;
  }
  & .swiper-slide.swiper-slide-active {
    opacity: 1;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding-top: calc(100vw * (10 / 375));
    & .swiper-slide {
      align-items: flex-start;
      opacity: 1;
    }
  }
`;
export default MainDramaBanner;
