import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import serverController from "../../server/serverController";
import styled from "styled-components";

import ContentDetailsMain from "./ContentDetailsMain";
import ContentStylingProducts from "./ContentStylingProducts";
import ContentDetailsFashion from "./ContentDetailsFashion";

const ContentDetails = ({ setIsContentModal, contentId, setContentId }) => {
  const { contents_id } = useParams();

  const [data, setData] = useState(null);
  const getDetails = async () => {
    await serverController.connectFetchController(
      `contents/${contents_id ? contents_id : contentId}`,
      "GET",
      null,
      (res) => {
        console.log(res, "디테일 response");
        if (res?.result === 1) {
          setData(res?.content ?? {});
        }
      }
    );
  };

  useEffect(() => {
    getDetails();
  }, [contents_id]);

  return (
    <Container>
      <ContentDetailsMain data={data} setIsContentModal={setIsContentModal} setContentId={setContentId} />
      <ContentStylingProducts data={data} />
      <ContentDetailsFashion data={data} />
    </Container>
  );
};

export default ContentDetails;

const Container = styled.div`
  @media screen and (max-width: 1024px) {
    overflow-y: auto;
    height: 100%;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
