import { useEffect, useRef } from "react";
import { Mobile, PC } from "../../MediaQuery";

import MyPageHeader from "./MyPageHeader";
import MyDeliveryTracking from "../../component/mypage/delivery/MyDeliveryTracking";

export default function MyDeliveryTrackingPage() {

  return (
    <>
      <Mobile>
        {/* TODO PUB :: 주문상세 페이지에서 페이지 이동 시, 타이틀 분기처리 부탁드립니다! */}
        {/* 배송조회 / 반품현황 */}
        <MyPageHeader title={"배송조회"} prevBtn />
        <MyDeliveryTracking/>
      </Mobile>
    </>
  );
}
