import { bindActionCreators } from "redux";
import * as manageAction from "./modules/management";
import * as userDataAction from "./modules/userData";
import * as signupDataAction from "./modules/signupData";
import * as productDetailsAction from "./modules/prodDetailData";
import * as paymentAction from "./modules/paymentData";
import * as permissionDataAction from "./modules/permissionData";
import * as deviceDataAction from "./modules/deviceData";
import * as settingVersionAction from "./modules/settingVersionData";
import * as recommenderAction from "./modules/recommenderData";
import store from "./index";

const { dispatch } = store;

export const ManagementAction = bindActionCreators(manageAction, dispatch);
export const UserDataAction = bindActionCreators(userDataAction, dispatch);
export const SignUpAction = bindActionCreators(signupDataAction, dispatch);
export const ProductDetailsAction = bindActionCreators(productDetailsAction, dispatch);
export const PaymentAction = bindActionCreators(paymentAction, dispatch);
export const RecommenderAction = bindActionCreators(recommenderAction, dispatch);

// s :: app
export const PermissionAction = bindActionCreators(permissionDataAction, dispatch);
export const DeviceAction = bindActionCreators(deviceDataAction, dispatch);
export const SettingVersionAction = bindActionCreators(settingVersionAction, dispatch);
// e :: app
