//react
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery"

//css
import styled from "styled-components"

// img
import CancleIcon from "../../../img/icon/icon_cancle.png"

export default function TermModal({setIsModal}) {

  return (
    <Container>
    <Background onClick={()=>{setIsModal(false)}}/>
        <Content>
            <TitleBox>
                <Cancle>
                    <CancleImg src={CancleIcon} onClick={()=>{setIsModal(false)}}/>
                </Cancle>
                <Title>이용약관</Title>
            </TitleBox>
            <ScrollBox>
                <ScrollContent>
                    <CouponName>이용약관 제목 영역</CouponName>
                    <Product>이용약관 내용 영역</Product>
                </ScrollContent>
            </ScrollBox>
            
            <Close>
                <CloseBtn onClick={()=>{setIsModal(false)}}>닫기</CloseBtn>
            </Close>
        </Content>
    </Container>
  );
}


const Container = styled.div`
    width: 100%;
`
// 배경
const Background = styled.div`
    position: fixed; top: 0; left: 0;
    width: 100%; height: 100%;
    background: rgba(0,0,0,0.3);
    z-index: 100;

    @media screen and (max-width: 930px) {
        z-index: 60;
    }
`
const Content = styled.div`
    position: fixed; top: 50%; left: 50%;
    width: 420px;
    border-radius: 11px;
    transform: translate(-50%,-50%);
    background:#f7f7f7;
    z-index: 100;
    overflow: hidden;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(420/1320));
    }

    @media screen and (max-width: 930px) {
        width: 100%;
        top: auto; left: 0; bottom: 0;
        transform: translate(0);
        border-radius: calc(100vw*(11/428)) calc(100vw*(11/428)) 0 0;
    }
`
const Cancle = styled.div`
    margin-bottom: 2px;
    text-align: right;

    @media screen and (max-width: 1320px) {
        margin-bottom: calc(100vw*(2/1320));
    }
    @media screen and (max-width: 930px) {
        margin-bottom: calc(100vw*(2/428));
    }
`
const CancleImg =styled.img`
    cursor: pointer;
    width: 14px; height: 14px;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(14/1320)); height: calc(100vw*(14/1320));
    }

    @media screen and (max-width: 930px) {
        width: calc(100vw*(14/428)); height: calc(100vw*(14/428));
    }
`
const TitleBox = styled.div`
    background: #fff;
    padding: 25px;

    @media screen and (max-width: 1320px) {
        padding: calc(100vw*(25/1320));
    }

    @media screen and (max-width: 930px) {
        padding: calc(100vw*(20/428));
    }
`
const Title = styled.h2`
    font-size: 18px;
    font-weight: bold;
    color: #000;
    text-align: center;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(18/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(18/428));
    }
`
const ScrollBox = styled.div`
    width: 100%; height: calc(100vh - 450px);
    padding: 0 15px;

    @media screen and (max-width: 1320px) {
        height: calc(100vh - calc(100vw*(450/1320)));
        padding: 0 calc(100vw*(15/1320));
    }

    @media screen and (max-width: 930px) {
        height: calc(100vh - calc(100vw*(400/428)));
        padding: 0;
    }
`
const ScrollContent = styled.div`
    height: 100%;
    padding: 20px 0;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 4px;
        background: #f7f7f7;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #adadad;
        border-radius: 4px;
    }

    @media screen and (max-width: 1320px) {
        padding: calc(100vw*(20/1320)) 0;
        &::-webkit-scrollbar {
            width: calc(100vw*(5/1320));
            border-radius: calc(100vw*(4/1320));
        }
        &::-webkit-scrollbar-thumb {
            border-radius: calc(100vw*(4/1320));
        }
    }

    @media screen and (max-width: 930px) {
        padding: calc(100vw*(20/428));
        &::-webkit-scrollbar {
            width: calc(100vw*(5/428));
            border-radius: calc(100vw*(4/428));
        }
        &::-webkit-scrollbar-thumb {
            border-radius: calc(100vw*(4/428));
        }
    }
`
const CouponName = styled.div`
    font-size: 16px;
    color: #000;
    font-weight: bold;
    margin-bottom: 6px;
    line-height: 1.36;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(16/1320));
        margin-bottom: calc(100vw*(6/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(18/428));
        margin-bottom: calc(100vw*(6/428));
    }
`
const Product =styled.div`
    font-size: 13px;
    color: #000;
    font-weight: 500;
    margin-bottom: 6px;
    line-height: 1.36;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(13/1320));
        margin-bottom: calc(100vw*(6/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(13/428));
        margin-bottom: calc(100vw*(6/428));
    }
`
const Close = styled.div`
    width: 100%;
    padding: 24px;
    
    @media screen and (max-width: 1320px) {
        padding: calc(100vw*(24/1320));
    }

    @media screen and (max-width: 930px) {
        padding: calc(100vw*(24/428));
    }
`
const CloseBtn = styled.p`
    cursor: pointer;
    width: 100%;
    padding: 16px 0;
    color: #fff;
    background: #000;
    border-radius: 11px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    
    @media screen and (max-width: 1320px) {
        padding: calc(100vw*(16/1320)) 0;
        border-radius: calc(100vw*(11/1320));
        font-size: calc(100vw*(14/1320));
    }

    @media screen and (max-width: 930px) {
        padding: calc(100vw*(15/428)) 0;
        border-radius: calc(100vw*(11/428));
        font-size: calc(100vw*(16/428));
    }
`