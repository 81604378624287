import React from 'react';
import { Mobile, PC } from '../../common/mediaStlye';
import { useSelector } from 'react-redux';
import { UserDataAction } from '../../../store/actionCreators';
import serverController from '../../../server/serverController';
import { useHistory } from 'react-router-dom';

import {
    MyPageHead,
    UserNameBox,
    UserThumb,
    LogoutButton,
    UserAnalysisLink,
} from './mypage.elements';
import userThumbImg from '../../../images/signup/basic.svg';
import anaylsisIcon from '../../../images/analysis-arrow.png';

const MyPageHeadings = () => {
    const history = useHistory();
    const { isLogged, userInfo } = useSelector(state => state.userData);

    const onHandleLogout = () => {
        UserDataAction.logOutRequest();
        serverController.connectFetchController(
            `logout`,
            "POST",
            null,
            (res) => {
                console.log(res, "로그아웃 response");
                history.push(`/login`);
            },
            (err) => console.error(err)
        )
        history.push('/');
    }


    return (
        <MyPageHead>
            <UserNameBox>
                <UserThumb><img src={userThumbImg} alt="유저 프로필" /></UserThumb>
                <p>{userInfo?.mem_name}<small>님</small></p>
            </UserNameBox>

            <PC>
                <LogoutButton onClick={onHandleLogout}>로그아웃</LogoutButton>
            </PC>
            <Mobile>
                <UserAnalysisLink style={{ padding: "10px 20px" }} onClick={() => history.push(`/step/normal/1`)}>
                    <span>맞춤분석 다시 하러가기</span>
                    <img style={{ marginLeft: "10px", width: "10px" }} src={anaylsisIcon} />
                </UserAnalysisLink>
            </Mobile>
        </MyPageHead>
    )
}

export default MyPageHeadings

