import React from "react";
import { PC, Mobile } from "../../components/common/mediaStlye";
import Header from "../../components/common/header/Header";
import SignUpSteps from "../../components/auth/signup/SignUpSteps";
import Footer from "../../components/common/footer/Footer";

const SignUpNormalMoreInfoPage = () => {
  return (
    <>
      <Header />
      <SignUpSteps />
      <Footer />
    </>
  );
};

export default SignUpNormalMoreInfoPage;
