import styled, { css } from "styled-components";
import {
  pc,
  mobil,
  mquery,
  MQ,
  Media,
  Px,
  Width,
  Height,
  Top,
  Bottom,
  Left,
  Right,
  FontSize,
  Margin,
  Margin2,
  Padding,
  Padding2,
  MT,
  MB,
  ML,
  MR,
  PT,
  PB,
  PL,
  PR,
  LineH,
} from "../common/mediaStlye";

import likeIcon from "../../images/stylist/like_icon.svg";
import likedIcon from "../../images/stylist/liked_icon.svg";
import prevIcon from "../../images/prev-button-arrow.png";
import prevOnIcon from "../../images/prev-button-arrow-on.png";
import nextIcon from "../../images/next-button-arrow.png";
import nextOnIcon from "../../images/next-button-arrow-on.png";
import select from "../../img/brand/select.png";

export const MoreButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: calc(100vw * (188 / 1920));
  height: calc(100vw * (51 / 1920));
  font-size: calc(100vw * (18 / 1920));
  font-weight: 900;
  border: 2px solid #222;
  border-radius: calc(100vw * (37 / 1920));

  @media ${(props) => props.theme.container} {
    ${[Width(163, mobil), Height(44, mobil), FontSize(16, mobil)].join("")}
    line-height: 0;
    border-radius: ${Px(37, mobil)};
  }
`;
export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Layout = styled.div`
  ${Width(1316, pc)}
  /* ${PT(131, pc)} */

    ${(props) =>
    props.sub &&
    css`
      ${Width(1098, pc)}
    `}

    ${MQ(mquery)} {
    width: 100%;
    ${Padding2(0, 25, mobil)}
  }
`;

export const StyleSponHead = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  & > img {
    ${Width(17, pc)}
  }

  & > span {
    ${FontSize(18, pc)}
    font-weight: 700;
  }

  ${MQ(mquery)} {
    & > img {
      ${Width(17, mobil)}
    }
    & > span {
      ${FontSize(14, mobil)}
      font-weight: 700;
    }
  }
`;

export const StyleSponWrap = styled.div`
  width: 100%;
  border-bottom: 1px solid #dfdfdf;

  ${MQ(mquery)} {
    ${Padding(60, 0, 0, 0, mobil)}
  }
`;

export const StyleSponCategorys = styled.ul`
  display: flex;
  justify-content: space-between;
  ${Padding(72, 0, 30, 0, pc)}

  & > li {
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }

  & img {
    width: ${Px(22, pc)};
    height: ${Px(22, pc)};
    object-fit: contain;
  }

  & p {
    ${FontSize(16, pc)}
    padding-left: 5px;
    color: #555;
  }

  ${MQ(mquery)} {
    flex-wrap: wrap;

    & > li {
      width: 20%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      ${MB(18, mobil)}
    }

    & img {
      max-width: ${Px(22, mobil)};
    }

    & p {
      ${FontSize(14, mobil)}
    }
  }
`;

export const StyleCatgoryIcon = styled.div`
  ${Width(40, pc)}
  ${Height(40, pc)}
        display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #d9d9d9;
  transition: all 0.3s ease;

  ${(props) =>
    props.selected &&
    css`
      background-color: #fff200;
      & + p {
        font-weight: bold;
        color: #000;
      }
      & img {
        filter: invert(1);
      }
    `}

  ${MQ(mquery)} {
    ${Width(48, mobil)}
    ${Height(48, mobil)}
            ${MB(7, mobil)}            

            & > img {
      ${Width(22, mobil)}
      ${Height(22, mobil)}
    }
  }
`;

export const StylistSponserdWrap = styled.div`
  ${Width(1174, pc)}
  margin: 0 auto ${Px(150, pc)};

  ${MQ(mquery)} {
    width: 100%;
    ${Padding2(0, 25, mobil)}
    margin: 0 auto ${Px(150, mobil)};
  }
`;

export const StyleSponList = styled.ul`
  ${PT(42, pc)}
  ${MB(113, pc)}
    display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${Px(20, pc)};
  row-gap: ${Px(66, pc)};

  ${MQ(mquery)} {
    ${PT(40, mobil)}
    ${MB(70, mobil)}
        gap: 3%;
  }
`;

export const StyleSearchList = styled(StyleSponList)`
  padding: 0;
  margin: 0;
  gap: 0;
  padding: calc(100vw * (40 / 1920)) calc(100vw * (26 / 1920));     
  margin-bottom: calc(100vw * (100 / 1920));

`

export const StyleSponListItem = styled.li`
  text-align: center;
  width: 18%;
  ${Height(415, pc)}

  & .stylist-spon-thumb {
    position: relative;
    width: 100%;
    height: ${Px(211, pc)};

    ${MB(26, pc)}
  }

  & .stylist-spon-thumb img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  & > em {
    ${FontSize(14, pc)}
    ${MB(8, pc)}
  }

  & > span {
    ${MB(8, pc)}
    ${FontSize(15, pc)}
        display: block;
    color: #a5a5a5;
  }

  & > strong {
    ${FontSize(15, pc)}
    ${MB(16, pc)}
        ${Height(42, pc)}
        display: block;
    font-weight: 700;
  }

  & button {
    ${Height(36, pc)}
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid #222;
    border-radius: ${Px(27, pc)};
  }

  ${MQ(mquery)} {
    width: 48%;

    ${Height(425, mobil)}

    & .stylist-spon-thumb {
      ${Height(211, mobil)}
      ${MB(26, mobil)}
    }

    & > em {
      ${FontSize(14, mobil)}
      ${MB(8, mobil)}
    }

    & > span {
      ${MB(8, mobil)}
      ${FontSize(15, mobil)}
    }

    & > strong {
      display: block;
      ${Height(35, mobil)}
      ${FontSize(15, mobil)}
            ${MB(16, mobil)}
    }

    & button {
      ${FontSize(14, mobil)}
      ${Height(36, mobil)}            
            border-radius: ${Px(27, mobil)};
    }
  }
`;

export const StyleSearchListItem = styled(StyleSponListItem)`
  width: 25%;
  box-sizing: border-box;
  padding-left: 20px;
  ${Height(340, pc)}
  & > strong {
    ${FontSize(18, pc)}
  }

  ${MQ(mquery)} {
    width: 48%;
    ${Height(300, mobil)}

    & > strong {
    ${FontSize(15, mobil)}
  }
  }
`

export const LikedIcon = styled.div`
  ${Width(24, pc)}
  ${Height(24, pc)}
    position: absolute;
  ${Right(18, pc)}
  ${Top(18, pc)}
    background: url(${({ isLiked }) =>
    isLiked ? likedIcon : likeIcon}) no-repeat center;
  background-size: contain;
  cursor: pointer;

  ${MQ(mquery)} {
    ${Width(19, mobil)}
    ${Height(19, mobil)}
        ${Right(15, mobil)}
        ${Top(15, mobil)}
  }
`;

export const Paging = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & ul {
    display: flex;
    justify-content: space-between;
    ${Margin2(0, 72, pc)}
    ${Width(209, pc)}
  }

  & button {
    ${Width(12, pc)}
    ${Height(24, pc)}
        background-size: cover;
  }

  & .prev-button {
    background: url(${prevIcon}) no-repeat center;
  }

  & .next-button {
    background: url(${nextIcon}) no-repeat center;
  }

  ${MQ(mquery)} {
    & ul {
      ${Margin2(0, 0, mobil)}
      ${Width(209, mobil)}
            ${Padding2(0, 30, mobil)}
    }

    & button {
      ${Width(16, mobil)}
      ${Height(24, mobil)}
    }
  }
`;

// 상세페이지 stylist

export const clear = `&:after { display:block; content:""; clear:both; }`;

export const DetailWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  ${Padding(100, 0, 150, 0, pc)}

  ${MQ(mquery)} {
    flex-direction: column;
    ${Padding(100, 0, 150, 0, mobil)}
  }
`;

export const ProdImgWrap = styled.div`
  width: calc(100vw * (426 / 1920));
  /* height: 100%; */
  height: ${Px(600, pc)};
  margin-right: calc(100vw * (40 / 1920));
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-right: 0;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const Img = styled.img`
  width: 100%;
  display: block;
`;

export const InformContainer = styled.div`
  width: calc(100vw * (526 / 1920));
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-right: 0;
    padding: 0 calc(100vw * (15 / 428));
  }
`;

export const InformWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Text = styled.p`
  font-size: 16px;
  color: #222222;
  line-height: 22px;
`;

export const TagWrap = styled.div`
  margin-left: -4px;
  margin-right: -4px;
  margin-bottom: 38px;

  ${MQ(mquery)} {
    ${MT(53, mobil)}
    ${MB(28, mobil)}
        white-space: nowrap;
  }
`;

export const Tag = styled.p`
  font-size: 14px;
  display: inline-block;
  padding: 5px 26px;
  margin: 0 4px;
  margin-bottom: 7px;
  background: #f2f2f2;
  border-radius: 41px;
  font-weight: 500;
  &:before {
    content: "#";
    margin-right: 3px;
  }
`;

export const ProdPriceWrap = styled.div`
  border-bottom: 1px solid #222222;
  padding: 0 9px 9px 21px;
  float: right;
`;
export const ProdPrice = styled.p`
  font-size: 20px;
  color: #222222;
  &:before {
    content: "협찬 가격 (개)";
    font-size: 16px;
    margin-right: 32px;
  }
  &:after {
    content: "원";
    font-size: 16px;
    color: #a5a5a5;
    margin-left: 11px;
  }
`;

export const ProdPreviewImgWrap = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: calc(100vw * (27 / 428));
  }
`;
export const PreviewImg = styled.div`
  position: relative;
  display: inline-block;
  width: calc(25% - 10px);
  height: auto;
`;
export const PreviewImgCover = styled.div`
  position: relative;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.36);
`;

export const Row = styled.div`
  ${clear};
  position: relative;
  ${(props) => props.fl && `float:${props.fl}`}
  @media screen and (max-width:768px) {
    ${(props) => props.mOrder && `order:${props.mOrder}`}
  }
`;
export const Col = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 26px;
  ${(props) => props.wrap && `flex-wrap:wrap`}
`;

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #a5a5a5;
  ${Padding(10, 0, 10, 0, pc)}
`;

export const TitleBtns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* ${Width(48, pc)} */
  /* ${Margin2(0, 15, pc)} */

  & button {
    padding: 0;
  }
  & button img {
    ${Width(20, pc)}
  }

  ${MQ(mquery)} {
    /* ${Width(48, mobil)} */
    /* ${Margin2(0, 15, mobil)} */
    & button img {
      ${Width(20, mobil)}
    }
  }
`;

export const Title = styled.h2`
  flex: 1;
  ${FontSize(22, pc)}
  ${LineH(30, pc)}
    font-weight: 900;

  ${MQ(mquery)} {
    ${FontSize(22, mobil)}
    ${LineH(30, mobil)}
  }
`;

export const InformContents = styled.div`
  ${PT(20, pc)}

  ${MQ(mquery)} {
    ${PT(20, mobil)}
  }
`;

export const InformDesc = styled.div`
  ${FontSize(16, pc)}
  ${PR(160, pc)}
    ${MB(30, pc)}

    ${MQ(mquery)} {
    ${FontSize(16, mobil)}
    ${PR(0, mobil)}
        ${MB(30, mobil)}
  }
`;

export const InformReceipt = styled.div`
  width: 100%;
  /* background-color: #F5F5F5; */
  ${Padding(30, 30, 30, 30, pc)}
  ${MB(10, pc)}
    border: 1px solid #d9d9d9;
  border-radius: calc(100vw * (22 / 1920));

  ${MQ(mquery)} {
    ${Padding(22, 24, 22, 24, mobil)}
    ${MB(10, mobil)}
        border-radius: calc(100vw*(22/428));
  }
`;
export const InformReceiptLeft = styled.div`
  /* ${Height(80, pc)} */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc(100vw * (80 / 1920));
  margin-bottom: calc(100vw * (30 / 1920));

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  & strong {
    ${FontSize(16, pc)}
    color: #222;
  }

  & span {
    ${FontSize(16, pc)}
    color: #A5A5A5;
    margin-left: calc(100vw * (8 / 1920));
  }

  ${MQ(mquery)} {
    width: 100%;
    margin-bottom: calc(100vw * (20 / 428));

    & > div {
      align-items: flex-end;
    }

    & strong {
      ${FontSize(16, mobil)}
    }

    & span {
      ${FontSize(16, mobil)}
    }
  }
`;
export const InformReceiptRight = styled.div`
  width: 100%;

  & > div {
    ${Padding(10, 21, 0, 21, pc)}
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & > div:first-child {
    ${PT(0, pc)}
    ${PB(10, pc)}
        border-bottom: 1px solid #D9D9D9;
  }

  & strong {
    ${FontSize(16, pc)}
    color: #222;
  }

  & span {
    ${FontSize(20, pc)}
    color: #222;
  }

  & small {
    ${ML(13, pc)}
    ${FontSize(16, pc)}
        color: #A5A5A5;
  }

  ${MQ(mquery)} {
    width: 100%;
    padding: 0 calc(100vw * (20 / 428));

    & > div {
      ${Padding(0, 0, 0, 0, mobil)}
    }

    & > div:first-child {
      ${PT(0, mobil)}
      ${PB(5, mobil)}
    }

    & strong {
      ${FontSize(15, mobil)}
    }

    & span {
      ${FontSize(18, mobil)}
    }

    & small {
      ${ML(13, mobil)}
      ${FontSize(15, mobil)}
    }
  }
`;

export const InformPayButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  ${MT(25, pc)}
  ${MB(26, pc)}

    & span {
    ${FontSize(18, pc)}
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid #a5a5a5;
    color: #a5a5a5;
  }

  & div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${Px(6, pc)};
  }

  & button {
    ${Width(168, pc)}
    ${Height(51, pc)}
        ${FontSize(18, pc)}
        display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #222;
  }

  & .inform-pay {
    color: #222;
  }

  & .inform-rent {
    background-color: #222;
    color: #fff;
  }

  ${MQ(mquery)} {
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & span {
      ${FontSize(16, mobil)}
      ${MB(18, mobil)}
    }

    & div {
      gap: ${Px(6, mobil)};
    }

    & button {
      ${Width(168, mobil)}
      ${Height(51, mobil)}
            ${FontSize(18, mobil)}
    }
  }
`;

// 스타일 제품 컨텐츠 등록
export const StyleSubHead = styled.div`
  display: flex;
  align-items: center;

  & h2 {
    ${FontSize(25, pc)}
    ${ML(25, pc)}
        font-weight: 900;
    color: #1f1f1f;
  }

  ${(props) =>
    props.sub &&
    css`
      ${MT(50, pc)}
    `}

  ${MQ(mquery)} {
    ${MB(70, mobil)}

    & h2 {
      ${FontSize(25, mobil)}
    }

    ${(props) =>
    props.sub &&
    css`
        ${MT(80, mobil)}
        ${MB(10, mobil)}
      `}
  }
`;
export const StyleBackButton = styled.div`
  ${Width(8, pc)}
  cursor: pointer;
  & img {
    display: block;
  }

  ${MQ(mquery)} {
    ${Width(8, mobil)}
  }
`;

export const StyleRegistWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  & input[type="file"] {
    display: none !important;
  }
`;

export const StyleRegistForm = styled.form`
  ${Width(648, pc)}

  ${MQ(mquery)} {
    width: 100%;
    ${MT(85, mobil)}
    width: 100%;
  }
`;

export const StyleRegistField = styled.label`
  ${MB(40, pc)}
  ${Height(40, pc)}
    position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #404040;

  ${(props) =>
    props.column &&
    css`
      height: auto;
      flex-direction: column;
      align-items: flex-start;
      border: none;
      height: auto;
    `}

  ${MQ(mquery)} {
    ${MB(20, mobil)}
    ${Height(40, mobil)}
        ${(props) =>
    props.column &&
    css`
        height: auto;
      `}
  }
`;

export const StyleFieldName = styled.span`
  ${Width(132, pc)}
  ${Padding2(0, 14, pc)}
    ${FontSize(16, pc)}
    font-weight: 700;

  ${MQ(mquery)} {
    ${Width(80, mobil)}
    ${Padding2(0, 14, mobil)}
        ${FontSize(14, mobil)}
  }
`;

export const StyleFieldInput = styled.input`
  flex: 1;
  height: 100%;
  ${FontSize(16, pc)}
  ${Padding2(0, 25, pc)}
    font-weight: 700;
  color: #222;

  ::placeholder {
    ${FontSize(14, pc)}
    color: #A5A5A5;
  }

  ${MQ(mquery)} {
    ${FontSize(16, mobil)}
    ${Padding2(0, 25, mobil)}
        ::placeholder {
      ${FontSize(14, mobil)}
    }
  }
`;

export const StyleRegistTextHead = styled.div`
  ${Width(132, pc)}
  ${Padding2(0, 14, pc)}
    ${FontSize(18, pc)}
    ${MB(10, pc)}
    font-weight: 700;
  color: #222;
  text-align: left;

  ${MQ(mquery)} {
    ${Width(132, mobil)}
    ${Padding2(0, 14, mobil)}
        ${FontSize(14, mobil)}
        ${MB(10, mobil)}
  }
`;

export const StyleFieldTextarea = styled.textarea`
  ${Height(191, pc)}
  ${FontSize(16, pc)}
    ${Padding2(22, 30, pc)}
    
    
    width: 100%;
  color: #222;
  border: 1px solid #222;
  resize: none;

  ::placeholder {
    color: #d9d9d9;
  }

  ${MQ(mquery)} {
    ${Height(191, mobil)}
    ${FontSize(16, mobil)}
        ${Padding2(10, 20, mobil)}
        ${MT(10, mobil)}
  }
`;

export const StyleRegistSelect = styled.select`
  width: 100%;
  ${Height(53, pc)}
  ${FontSize(16, pc)}
    ${Padding2(0, 10, pc)}
    ${FontSize(16, pc)}        
    ${MT(5, pc)}
    /* color: #d9d9d9;     */
    border: 1px solid #222;
  text-align: center;

  -webkit-appearance: none;
  appearance: none;
  background: url(${select}) no-repeat 97% 50% / contain;
  background-size: 10px;

  ${MQ(mquery)} {
    ${Height(53, mobil)}
    ${FontSize(16, mobil)}
        ${Padding2(0, 10, mobil)}
        ${FontSize(16, mobil)}        
        ${MT(5, mobil)}
  }
`;
export const StyleRegistOption = styled.option`
  text-align: center;
`;

export const StyleFieldButton = styled.label`
  ${Width(143, pc)}
  ${Height(49, pc)}
    ${Right(25, pc)}
    ${Bottom(10, pc)}
    ${FontSize(16, pc)}
    position: absolute;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  line-height: 0;
  border: 1px solid #404040;

  ${MQ(mquery)} {
    ${Width(121, mobil)}
    ${Height(43, mobil)}
        ${Right(0, mobil)}
        ${Bottom(0, mobil)}
        ${FontSize(16, mobil)}

        position: static;
    margin: auto 0 auto auto;
  }
`;

export const StyleRegistTips = styled.div`
  ${Width(648, pc)}
  ${MB(110, pc)}

    & .tips-save {
    ${MB(27, pc)}
  }

  ${MQ(mquery)} {
    width: 100%;
    ${MB(110, mobil)}
    ${MT(50, mobil)}
        
        & .tips-save {
      ${MB(27, mobil)}
    }
  }
`;

export const StyleRegistButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${Px(10, pc)};
  ${MB(150, pc)}
`;
export const StyleRegistButton = styled.div`
  ${Width(278, pc)}
  ${Height(60, pc)}
    ${FontSize(16, pc)}

    display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  color: #222;
  border: 1px solid #222;
  cursor: pointer;

  ${(props) =>
    props.black &&
    css`
      background: #222;
      color: #fff;
    `}

  ${MQ(mquery)} {
    ${Width(150, mobil)}
    ${Height(45, mobil)}
        ${FontSize(16, mobil)}
  }
`;

export const RegisterSuccessWrap = styled.div`
  ${Padding(100, 0, 150, 0, pc)}

  & .regist-success-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    ${Height(627, pc)}
    background-color: rgba(255,249, 170, 21);
  }

  & .regist-success-wrap div {
    ${Width(142, pc)}
    ${MB(32, pc)}
  }

  ${(props) =>
    props.unopend &&
    css`
      & .regist-success-wrap {
        background-color: #f2f2f2;
      }
    `}

  & img {
    width: 100%;
    height: auto;
  }

  & h3 {
    ${FontSize(25, pc)}
    ${MB(10, pc)}
        font-weight: 900;
    color: #222222;
  }

  & p {
    ${MB(80, pc)}
  }

  & .btn_box {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* width: 500px; */
  }

  & button {
    /* flex: 1; */
    ${Width(245, pc)}
    ${Height(49, pc)}
        color: #fff;
    background-color: #404040;
  }

  ${MQ(mquery)} {
    ${Padding(100, 0, 150, 0, mobil)}

    & .regist-success-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: auto;
      ${Padding2(150, 10, mobil)}
      background-color: rgba(255,249, 170, 21);
      text-align: center;
    }

    & .regist-success-wrap div {
      ${Width(86, mobil)}
      ${MB(32, mobil)}
    }

    ${(props) =>
    props.unopend &&
    css`
        & .regist-success-wrap {
          background-color: #f2f2f2;
        }
      `}

    & img {
      width: 100%;
      height: auto;
    }

    & h3 {
      ${FontSize(16, mobil)}
      ${MB(10, mobil)}
            font-weight: 900;
      color: #222222;
    }

    & p {
      ${FontSize(13, mobil)}
      ${MB(80, mobil)}
            letter-spacing: -1px;
    }

    & button {
      ${Width(245, mobil)}
      ${Height(49, mobil)}
            color: #fff;
      ${FontSize(13, mobil)}
      background-color: #404040;
    }
  }
`;

export const PurchaseBox = styled.div`
  display: flex;
  gap: ${Px(5, pc)};
  & button {
    flex: 1;
    ${FontSize(16, pc)}
  }

  @media screen and (max-width: 930px) {
    & button {
      font-size: calc(100vw * (14 / 428));
    }
  }
`;

export const NoneLi = styled.li`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${Padding2(100, 0, pc)}
  ${FontSize(20, pc)}

    ${MQ(mquery)} {
    ${Padding2(50, 0, mobil)}
    ${FontSize(20, mobil)}
  }
`;
export const LodingWrap = styled.div`
min-height: 100px;
padding-bottom: calc(100vw * (60 / 1920));
${MQ(mquery)} {
  min-height: 50px;
  padding-bottom: 0;
}
`
export const LodingImg = styled.img`
  display: block;
  width: calc(100vw * (70 / 1920));
  margin: 0 auto calc(100vw * (12 / 1920));
  animation: rotation 3s infinite;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  ${MQ(mquery)} {
    width: calc(100vw * (70 / 428));
    margin: 0 auto calc(100vw * (12 / 428));
  }
`;
