import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";

//css
import styled from "styled-components";

//img
import OrderImg1 from "../../../img/brand_logo1.png";
import OrderImg2 from "../../../img/brand_logo3.png";
import OrderImg3 from "../../../img/brand_logo4.png";
import IconArrow from "../../../img/icon/icon_selectPay_arrow.png";

//page
import Commas from "../../../server/Commas";
import { imgURL } from "../../../server/appInfoController";

export default function CancelForm() {
  const { state } = useLocation();
  const [list, setList] = useState([]);
  const [cancelType, setCancelType] = useState("");

  useEffect(() => {
    console.log(state, "결제 취소 정보");
    if (state) setList(state?.orders);
  }, [state]);

  const handleCancelType = (e) => {
    setCancelType(e.target.value);
  };

  return (
    <Container>
      <CancelFormWrap>
        <SubTitleWrap>
          <SubTitle>주문 취소 요청</SubTitle>
        </SubTitleWrap>
        <CancleListWrap>
          <ListTitleWrap>
            <ListTitle>취소 요청 상품 확인</ListTitle>
          </ListTitleWrap>
          <ListWrap>
            {list.map((value, index) => {
              return (
                <ListBox>
                  <ListInfoWrap>
                    <ListImgWrap>
                      <ListImg
                        src={imgURL + value?.prd_thumbnail}
                        alt="주문상품 이미지"
                      />
                    </ListImgWrap>
                    <ProductTextWrap>
                      <ProductText>{value?.prd_name}</ProductText>
                      {/* {
                                                    (value?.productList?.optionList) && 
                                                    Object.entries(value?.productList?.optionList).map((v, i) => v[1]).map((v, i) => {
                                                        console.log(v)
                                                        return <div>{v?.opt_name}</div>
                                                    })
                                                } */}
                      {value?.optionInfo.length > 0 &&
                        value?.optionInfo?.map((value, index) => {
                          return (
                            <ProductOption>
                              {value?.optTitle}
                              <ProductCount>{value?.optName}</ProductCount>
                            </ProductOption>
                          );
                        })}

                      <ProductPrice>
                        {Commas.numberWithCommas(value?.mog_total_price ?? 0)}
                        <ProductCount>{value?.orderCount ?? 1}개</ProductCount>
                      </ProductPrice>
                    </ProductTextWrap>
                  </ListInfoWrap>
                </ListBox>
              );
            })}
            <FromWrap>
              <FromTitleWrap>
                <ListTitle>취소 사유 입력</ListTitle>
              </FromTitleWrap>
              <FromBox>
                <CancleTypeWrap>
                  <CancleTypeList name="cancel_type" id="">
                    <CancleType value="">취소 사유를 선택해 주세요.</CancleType>
                    <CancleType value="">상품 필요 없어짐</CancleType>
                    <CancleType value="">다른 상품 구매함</CancleType>
                    <CancleType value="">타 쇼핑몰에서 구매함</CancleType>
                    <CancleType value="">배송이 너무 느림</CancleType>
                    <CancleType value="">상품 배송 지연 됨</CancleType>
                  </CancleTypeList>
                </CancleTypeWrap>
                <CancleTextWrap>
                  <CancleText
                    placeholder="취소 사유를 입력해 주세요."
                    name="cancel_text"
                  />
                </CancleTextWrap>
              </FromBox>
            </FromWrap>
          </ListWrap>
        </CancleListWrap>
      </CancelFormWrap>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  margin-bottom: 40px;
  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (40 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (100 / 428));
  }
`;
const CancelFormWrap = styled.div`
  width: 1300px;
  margin: 0 auto;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
  }
`;
const SubTitleWrap = styled.div`
  padding-top: 80px;
  margin-bottom: 40px;
  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (80 / 1320));
    margin-bottom: calc(100vw * (40 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (50 / 428));
    margin-bottom: calc(100vw * (30 / 428));
  }
`;
const SubTitle = styled.h2`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (25 / 428));
  }
`;
//list
const CancleListWrap = styled.div`
  width: 100%;
`;
const ListTitleWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #d9d9d9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (40 / 428)) 0 0;
  }
`;
const FromTitleWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  margin-top: 15px;
  border-bottom: 1px solid #d9d9d9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
    margin-top: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
    margin-top: calc(100vw * (15 / 428));
  }
`;
const ListTitle = styled.p`
  position: relative;
  font-size: 20px;
  font-weight: bold;
  padding-right: 14px;
  margin-right: 14px;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 22px;
    background: #646464;
  }
  &:last-child:after {
    display: none;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
    padding-right: calc(100vw * (14 / 1320));
    margin-right: calc(100vw * (14 / 1320));
    &:after {
      height: calc(100vw * (22 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding-right: calc(100vw * (14 / 428));
    margin-right: calc(100vw * (14 / 428));
    &:after {
      height: calc(100vw * (22 / 428));
    }
  }
`;
const ListWrap = styled.div`
  width: 100%;
`;
const ListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  padding: 22px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (22 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (22 / 428)) 0;
  }
`;
const ListInfoWrap = styled.div`
  display: flex;
  align-items: center;
  width: auto;
`;
const ListImgWrap = styled.div`
  width: 115px;
  height: 115px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (115 / 1320));
    height: calc(100vw * (115 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (115 / 428));
    height: calc(100vw * (115 / 428));
  }
`;
const ListImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ProductTextWrap = styled.div`
  padding-left: 20px;

  @media screen and (max-width: 1320px) {
    padding-left: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-left: calc(100vw * (20 / 428));
  }
`;
const ProductText = styled.p`
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;
const ProductOption = styled.p`
  font-size: 15px;
  color: #969696;
  margin: 8px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin: calc(100vw * (8 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 1320));
    margin: calc(100vw * (8 / 428)) 0;
  }
`;
const ProductPrice = styled.p`
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (17 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;
const ProductCount = styled.span`
  position: relative;
  font-size: 15px;
  font-weight: normal;
  color: #969696;
  margin-left: 8px;
  padding-left: 8px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    display: block;
    width: 1px;
    height: 14px;
    background: #d9d9d9;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (8 / 1320));
    padding-left: calc(100vw * (8 / 1320));
    &:before {
      top: calc(100vw * (2 / 1320));
      height: calc(100vw * (14 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (8 / 428));
    padding-left: calc(100vw * (8 / 428));
    &:before {
      top: calc(100vw * (2 / 428));
      height: calc(100vw * (14 / 428));
    }
  }
`;
// from
const FromWrap = styled.div`
  width: 100%;
  padding: 25px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (25 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (25 / 428)) 0;
  }
`;
const FromBox = styled.div`
  width: 100%;
  padding: 20px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const CancleTypeWrap = styled.div`
  width: 100%;
`;
const CancleTypeList = styled.select`
  width: 420px;
  height: 45px;
  font-size: 15px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  padding: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(${IconArrow}) no-repeat center right 15px/10px;
  border-radius: 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (420 / 1320));
    height: calc(100vw * (45 / 1320));
    font-size: calc(100vw * (15 / 1320));
    border-radius: calc(100vw * (5 / 1320));
    padding: calc(100vw * (12 / 1320));
    background: url(${IconArrow}) no-repeat center right
      calc(100vw * (15 / 1320)) / calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    height: calc(100vw * (45 / 428));
    font-size: calc(100vw * (15 / 428));
    border-radius: calc(100vw * (5 / 428));
    padding: calc(100vw * (12 / 428));
    margin: 0 auto;
    background: url(${IconArrow}) no-repeat center right
      calc(100vw * (15 / 428)) / calc(100vw * (10 / 428));
  }
`;
const CancleType = styled.option`
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const CancleTextWrap = styled.div`
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const CancleText = styled.textarea`
  width: 100%;
  height: 250px;
  font-size: 15px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  padding: 12px 16px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (250 / 1320));
    font-size: calc(100vw * (15 / 1320));
    border-radius: calc(100vw * (5 / 1320));
    padding: calc(100vw * (12 / 1320)) calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (250 / 428));
    font-size: calc(100vw * (15 / 428));
    border-radius: calc(100vw * (5 / 428));
    padding: calc(100vw * (12 / 428)) calc(100vw * (16 / 428));
  }
`;
