//react
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { Mobile, PC } from "../../../MediaQuery";
import { imgURL } from "../../../server/appInfoController";

//css
import styled from "styled-components";

export default function OrderProduct2({ value }) {
  const [orderStatus, setOrderStatus] = useState("");
  useEffect(() => {
    if (value?.mog_order_status === 71) {
      setOrderStatus("취소 요청");
    } else if (value?.mog_order_status === 72) {
      setOrderStatus("취소 대기");
    } else if (value?.mog_order_status === 73) {
      setOrderStatus("취소 거부");
    }
  }, []);

  return (
    <Container>
      <PC>
        <OrderProductWrap>
          <ProductImgWrap>
            <ProductImg src={imgURL + value?.prd_thumbnail} />
          </ProductImgWrap>
          <ProductInfoWrap>
            <Link to={`/store/mypage/order/detail/${value?.mog_id}`}>
              <div>
                <Brand>{value?.br_name || "BRAND"}</Brand>
                <ProductName>{value?.prd_name || "Product"}</ProductName>
                {/* <Option>옵션 (+{value?.option}원)</Option> */}
              </div>
            </Link>
          </ProductInfoWrap>
          {/* <OrderNumber>{value?.order_number}</OrderNumber> */}
          <Day>{value?.mog_payment_date || "확인바람"}</Day>
          {/* <Amount>{value?.amount}개</Amount> */}
          <Price>{value?.prd_list_price}</Price>
          <Step>{orderStatus || "확인바람"}</Step>
        </OrderProductWrap>
      </PC>

      <Mobile>
        <MbTitleWrap>
          <MbTitle>주문번호 : {value?.order_id}</MbTitle>
          <MbTitle>주문일자 : {value?.mog_payment_date || "확인바람"}</MbTitle>
        </MbTitleWrap>
        <OrderProductWrap>
          <ProductImgWrap>
            <ProductImg src={imgURL + value?.prd_thumbnail} />
          </ProductImgWrap>
          <ProductInfoWrap>
            <Link to={`/store/mypage/order/detail`}>
              <InfoTextWrap>
                <Brand>{value?.brand_name}</Brand>
                <ProductName>{value?.product_name}</ProductName>
                <Option>옵션 (+{value?.option}원)</Option>
              </InfoTextWrap>
            </Link>
            <InfoPriceWrap>
              <Price>{value?.prd_list_price}</Price>
              {/* <Amount>{value?.amount}개</Amount> */}
            </InfoPriceWrap>
            <Step>{orderStatus || "확인바람"}</Step>
          </ProductInfoWrap>
        </OrderProductWrap>
      </Mobile>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  @media screen and (max-width: 1320px) {
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }
  @media screen and (max-width: 930px) {
    border-top: calc(100vw * (1 / 428)) solid #707070;
    border-bottom: none;
    &:last-child {
      border-bottom: 1px solid #000;
    }
  }
`;
const OrderProductWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px 20px 15px;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) calc(100vw * (30 / 1320))
      calc(100vw * (20 / 1320)) calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    align-items: flex-start;
    padding: calc(100vw * (20 / 428)) 0 calc(100vw * (30 / 428));
    border-top: calc(100vw * (1 / 428)) solid #d9d9d9;
    border-bottom: none;
  }
`;
const ProductImgWrap = styled.div`
  width: 86px;
  height: 86px;
  overflow: hidden;
  margin-right: 20px;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (86 / 1320));
    height: calc(100vw * (86 / 1320));
    margin-right: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (90 / 428));
    height: calc(100vw * (90 / 428));
    margin-right: 0;
  }
`;
const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Brand = styled.div`
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin-bottom: 9px;
  cursor: pointer;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    margin-bottom: calc(100vw * (9 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const ProductName = styled.div`
  display: block;
  cursor: pointer;
  width: 500px;
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin-bottom: 7px;
  padding-right: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (500 / 1320));
    font-size: calc(100vw * (18 / 1320));
    margin-bottom: calc(100vw * (7 / 1320));
    padding-right: calc(100vw * (50 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (6 / 428));
    padding-right: 0;
  }
`;
const Option = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #969696;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const OrderNumber = styled.p`
  width: 100px;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
`;
const Day = styled.p`
  width: 140px;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
`;
const Amount = styled.p`
  width: 80px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (80 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    position: relative;
    width: auto;
    font-size: calc(100vw * (14 / 428));
    margin-left: calc(100vw * (8 / 428));
    padding-left: calc(100vw * (8 / 428));
    color: #969696;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw * (1 / 428));
      height: calc(100vw * (16 / 428));
      background: #d9d9d9;
    }
  }
`;
const Price = styled.div`
  width: 200px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (200 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: auto;
    font-size: calc(100vw * (16 / 428));
  }
`;
const Step = styled.p`
  width: 100px;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: bold;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (15 / 428));
    text-align: left;
    margin-top: calc(100vw * (18 / 428));
  }
`;
const ProductInfoWrap = styled.div`
  width: calc(100% - 655px);

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (655 / 1320)));
  }

  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (90 / 428)));
    padding-left: calc(100vw * (15 / 428));
  }
`;
//
const MbTitleWrap = styled.div`
  display: flex;
  align-items: center;
  padding: calc(100vw * (20 / 428)) 0;
`;
const MbTitle = styled.p`
  position: relative;
  font-size: calc(100vw * (14 / 428));
  font-weight: bold;
  margin-right: calc(100vw * (10 / 428));
  padding-right: calc(100vw * (10 / 428));
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100vw * (1 / 428));
    height: calc(100vw * (16 / 428));
    background: #646464;
  }
  &:last-child {
    margin: 0;
    padding: 0;
  }
  &:last-child:after {
    display: none;
  }
`;
const InfoTextWrap = styled.div`
  width: 100%;
`;
const InfoPriceWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: calc(100vw * (12 / 428));
`;
