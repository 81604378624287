import { useEffect, useRef, useState } from "react";
import { Mobile, PC } from "../../MediaQuery";
import { useHistory } from "react-router-dom";

import Header from "../../component/common/Header";
import FixedTop from "../../component/common/FixedTop";
import MypageMenuList from "../../component/mypage/MypageMenuList";
import MypageWishList from "../../component/mypage/my/MypageWishList";
import Footer from "../../component/common/StoreFooter";
import FullLoading from "../../component/common/modal/FullLoading";

import MyPageHeader from "./MyPageHeader";
import FixMenu from "../../component/common/FixMenu";

export default function WishPage() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  return (
    <>
      {/* <PC>
        <Header />
        <MypageMenuList />
        <MypageWishList />
        <Footer />
        <FixedTop />
      </PC> */}

      {loading && <FullLoading />}
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader
          title={"찜 목록"}
          prevBtn
          prevFunc={() => {
            history.push(`/store/mypage`);
          }}
        />
      </Mobile>
      <MypageWishList setLoading={setLoading} />
      <FixMenu />
    </>
  );
}
