import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";
import serverController from "../../server/serverController";
import { imgURL } from "../../../server/appInfoContoller";

export default function PlanShopBnr() {
  const history = useHistory();
  const [eventList, setEventList] = useState([]);

  const getPlanList = () => {
    serverController.connectFetchController("api/event/list", "GET", null, (res) => {
      if (res?.result === 1) {
        // console.log(res);
        setEventList(res.event);
      }
    });
  };

  useEffect(() => {
    getPlanList();
  }, []);

  return (
    <Container>
      <PC>
        {eventList.map((el, i) => {
          return (
            <BannerWrap key={"plan_bnr" + i} onClick={() => { history.push(`store/plan/${el.eventId}`) }}>
              <img src={imgURL + el.eventImagePc} alt="기획전 배너 pc" />
            </BannerWrap>
          )
        })
        }

      </PC>
      <Mobile>
        {eventList.map((el, i) => {
          return (
            <BannerWrap onClick={() => { history.push(`store/plan/${el.eventId}`) }}>
              <img src={imgURL + el.eventImageMobile} alt="기획전 배너 mobile" />
            </BannerWrap>
          )
        })
        }
      </Mobile>
    </Container>
  )
};


const Container = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 80px;
  
  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (80 / 1320));
    &::after {
      height: calc(100vw * (440 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    padding-bottom: 0;
    background: none;
    &::after {
      display: none;
    }
  }
`;

const BannerWrap = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;

  & img{
    width: 100%;
    height: auto;
  }
`;