import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Commas from "../../../server/Commas";
import styled from "styled-components";
import { PaymentAction } from "../../../../store/actionCreators";
import serverController from "../../../server/serverController";

export default function PayPoint({ totalPrice }) {
  //   const { mem_point } = useSelector((state) => state.userData.userInfo);
  const [memPoint, setMemPoint] = useState(0);
  const [userPoint, setUserPoint] = useState(0);
  const [max, setMax] = useState(0);
  const [point, setPoint] = useState(0);

  const { payment } = useSelector((state) => state.paymentData);

  useEffect(() => {
    serverController.connectFetchController(
      `mypage`,
      "POST",
      null,
      (res) => {
        console.log(res, "유저정보(mypage) response");
        if (res?.result === 1) {
          setUserPoint(res.list.mem_point);
          setMemPoint(res.list.mem_point);
        }
      },
      (err) => console.error(err)
    );
  }, []);

  useEffect(() => {
    setMax(parseInt((totalPrice * 0.9).toFixed(0)));
  }, [payment, totalPrice]);

  const handlePoint = (e) => {
    const num = e.target.value;

    if (num > max) {
      setPoint(max);
    } else if (num < 0) {
      return;
    } else if (num > userPoint) {
      setPoint(0);
    } else {
      const per = num % 10;
      if (per === 0) {
        setPoint(num);
      } else if (num < 10) {
        setPoint(num);
      } else if (num >= 10) {
        setPoint(Math.round(num / 10) * 10);
      }
    }
  };

  useEffect(() => {
    if (memPoint) {
      setUserPoint((memPoint ?? 0) - point);
      PaymentAction.savePoint({ points: point });
    } else {
      return;
    }
  }, [point, memPoint]);

  const handleAllPoint = () => {
    if (userPoint > max) {
      setPoint(max);
    } else if (userPoint <= max) {
      setPoint(userPoint);
    }
  };

  return (
    <Container>
      <PayPointWrap>
        <TitleWrap>
          <Title>포인트</Title>
          <TitleSub>*전체 상품 가격의 90%까지 사용하실 수 있습니다.</TitleSub>
        </TitleWrap>
        <PointWrap>
          <Point type="number" value={point} onChange={handlePoint} />
          <AllPoint onClick={handleAllPoint}>전액사용</AllPoint>
        </PointWrap>
        <PointTextWrap>
          <PointText>
            사용 가능 포인트
            <PointSub>{Commas.numberWithCommas(userPoint)}P</PointSub>
          </PointText>
        </PointTextWrap>
      </PayPointWrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (30 / 428));
    border-top: calc(100vw * (12 / 428)) solid #e9ebee;
  }
`;
const PayPointWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    margin: 0 auto;
  }
`;
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #d9d9d9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 930px) {
    justify-content: space-between;
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (10 / 428));
    border-bottom: none;
  }
`;
const Title = styled.p`
  font-size: 22px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const TitleSub = styled.p`
  font-size: 15px;
  color: #969696;
  margin-left: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-left: calc(100vw * (5 / 428));
  }
`;
// Point
const PointWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const Point = styled.input`
  width: 430px;
  height: 45px;
  font-size: 15px;
  color: #525252;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 0 20px;
  background: #f7f7f7;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (430 / 1320));
    height: calc(100vw * (45 / 1320));
    font-size: calc(100vw * (15 / 1320));
    border: calc(100vw * (1 / 1320)) solid #e4e4e4;
    border-radius: calc(100vw * (5 / 1320));
    padding: 0 calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (100 / 428)));
    height: calc(100vw * (45 / 428));
    font-size: calc(100vw * (15 / 428));
    border: calc(100vw * (1 / 428)) solid #e4e4e4;
    border-radius: calc(100vw * (5 / 428));
    padding: 0 calc(100vw * (20 / 428));
  }
`;
const AllPoint = styled.p`
  width: 90px;
  font-size: 16px;
  text-align: center;
  color: #000;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 6px 0;
  margin-left: 20px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (90 / 1320));
    font-size: calc(100vw * (16 / 1320));
    border: calc(100vw * (1 / 1320)) solid #000;
    border-radius: calc(100vw * (5 / 1320));
    padding: calc(100vw * (6 / 1320)) 0;
    margin-left: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (85 / 428));
    font-size: calc(100vw * (15 / 428));
    border: calc(100vw * (1 / 428)) solid #000;
    border-radius: calc(100vw * (5 / 428));
    padding: calc(100vw * (12 / 428)) 0;
    margin-left: calc(100vw * (15 / 428));
  }
`;
const PointTextWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (10 / 428));
  }
`;
const PointText = styled.p`
  font-size: 16px;
  color: #525252;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const PointSub = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-left: 4px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-left: calc(100vw * (4 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-left: calc(100vw * (4 / 428));
  }
`;
