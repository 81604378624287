import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Mobile, PC } from '../common/mediaStlye';
import styled from 'styled-components';
import serverController from '../../server/serverController';
import { imgURL } from '../../server/appInfoContoller';
import { useSelector } from 'react-redux';

import {
    Wrapper,
    LayOut,
    ContentHeading,
    FashionsContainer,
    Item,
    ItemImg,
    ItemDesc,
    MoreButton
} from './CategoryContents.element';

import detailThumbimg1 from '../../images/content/content-detail-fashion1.png';
import detailThumbimg2 from '../../images/content/content-detail-fashion2.png';
import detailThumbimg3 from '../../images/content/content-detail-fashion3.png';
import detailThumbimg4 from '../../images/content/content-detail-fashion4.png';
import detailThumbimg5 from '../../images/content/content-detail-fashion5.png';
import detailThumbimg6 from '../../images/content/content-detail-fashion6.png';
import detailThumbimg7 from '../../images/content/content-detail-fashion7.png';
import detailThumbimg8 from '../../images/content/content-detail-fashion8.png';
import detailThumbimg9 from '../../images/content/content-detail-fashion9.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation]);

const CategoryFashions = () => {
    const history = useHistory()
    const { category_id } = useParams();
    const { isLogged } = useSelector(state => state.userData);

    const thumbs = [detailThumbimg1, detailThumbimg2, detailThumbimg3, detailThumbimg4, detailThumbimg5, detailThumbimg6, detailThumbimg7, detailThumbimg8, detailThumbimg9,];
    const headings = ["Celebrity", "Drama", "Professional", "Idol"];

    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [isMore, setIsMore] = useState(true);

    const getCategoryBanners = async () => {
        await serverController.connectFetchController(
            `contentslist/${category_id}`,
            "GET",
            null,
            (res) => {
                console.log(res, "카테고리 패션컴포넌트 목록 res");
                if (res?.result === 1) {
                    setList(res.list ?? []);
                }
            },
            (err) => console.error(err)
        )
    };

    useEffect(() => {
        getCategoryBanners()
    }, [category_id, isLogged])

    const getMoreContents = async () => {
        await serverController.connectFetchController(
            `contentslist/${category_id}?page=${page + 1}`,
            "GET",
            null,
            (res) => {
                console.log(res, "카테고리 패션컴포넌트 관련 컨텐츠 more response");
                if (res?.result === 1) {
                    setPage(prev => prev + 1);
                    setList(prev => prev.concat(res?.list ?? []));
                    if (res?.list.length < 8) {
                        setIsMore(false);
                    }
                }
            }
        )
    };

    return (
        <>
            <PC>
                <Wrapper>
                    <LayOut>
                        <ContentHeading>{headings[parseInt(category_id) - 1]} fashion</ContentHeading>
                        <FashionsContainer>
                            {
                                list.map((v) => {
                                    const src = v?.contents_thumbnail?.split(",")[0]

                                    return (
                                        <Item
                                            key={v?.contents_id}
                                            onClick={() => history.push(`/content/${v?.contents_id}`)}
                                        >
                                            <ItemImg>
                                                <img src={imgURL + src} alt={v?.contents_intro} />
                                            </ItemImg>
                                            <ItemDesc className="item_head">
                                                <em>{headings[parseInt(category_id) - 1]}</em>
                                                <h4>{v?.contents_title}</h4>
                                                <div>
                                                    {/* {ReactHtmlParser(v?.contents_content)} */}
                                                </div>
                                                {/* <p>Iro Mini Dress와 제이에스티나귀걸이
                                            지안비토로시 구두로 스타일링 해주었습니다.</p>            */}
                                            </ItemDesc>
                                        </Item>
                                    )
                                })
                            }
                        </FashionsContainer>
                        {isMore && <MoreButton onClick={getMoreContents}>See More</MoreButton>}
                    </LayOut>
                </Wrapper>
            </PC>

            <Mobile>
                <Wrapper>
                    <LayOut>
                        <ContentHeading>{headings[parseInt(category_id) - 1]} fashion</ContentHeading>
                        <FashionsContainer>
                            {
                                list.length !== 0 &&
                                <Swiper
                                    loop
                                    slidesPerView="auto"
                                >
                                    {
                                        list.map((v) => {
                                            const src = v?.contents_thumbnail?.split(",")[0]

                                            return (
                                                <SwiperSlide key={v?.contents_id}>
                                                    <Item onClick={() => history.push(`/content/${v?.contents_id}`)}>
                                                        <ItemImg>
                                                            <img src={imgURL + src} alt={v?.contents_intro} />
                                                        </ItemImg>
                                                        <ItemDesc className="item_head">
                                                            <em>{headings[parseInt(category_id) - 1]}</em>
                                                            <h4>{v?.contents_title}</h4>
                                                            <div>
                                                                {ReactHtmlParser(v?.contents_content)}
                                                            </div>
                                                        </ItemDesc>
                                                    </Item>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            }
                        </FashionsContainer>
                        <MoreButton onClick={getMoreContents}>See More</MoreButton>
                    </LayOut>
                </Wrapper>
            </Mobile>
        </>
    )
}

export default CategoryFashions;
