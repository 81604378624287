import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SignUpAction } from '../../../store/actionCreators';

import {
    Layout
} from '../auth.elements';
import {
    StepContainer,
    StepContents,
    StepHeadings,
    GenderForm,
    Gender,
    GenderMark,
    StepNextBox,
    SignupButton
} from './signup.element';
const SignUpStepOne = ({ step, url }) => {
    const history = useHistory();
    const [isSelect, setIsSelect] = useState({
        left: false,
        right: false
    })

    const onClickLeft = () => setIsSelect(prev => ({ right: false, left: true }));
    const onClickRight = () => setIsSelect(prev => ({ left: false, right: true }));

    const nextStep = (skip) => {
        if (skip) {
            SignUpAction.sendPersonalNormalInfo({ mem_gender: null }) // 0 남자 1 여자
            history.push(`/${url}/normal/2`);
        } else if (isSelect?.left || isSelect?.right) {
            SignUpAction.sendPersonalNormalInfo({ mem_gender: isSelect?.left ? 1 : 0 }) // 0 남자 1 여자 
            history.push(`/${url}/normal/2`)
        } else {
            alert("성별을 골라주세요.")
        }
    };


    return (
        <>
            <StepContents >
                <StepHeadings>
                    <h2>Personal infomation</h2>
                    <p><em>0{step} / </em><span>04</span></p>
                </StepHeadings>

                <GenderForm>
                    <Gender isSelect={isSelect.left} onClick={onClickLeft}>
                        <GenderMark>
                            <strong>W</strong>
                        </GenderMark>
                        <span>woman</span>
                    </Gender>

                    <Gender isSelect={isSelect.right} onClick={onClickRight}>
                        <GenderMark>
                            <strong>m</strong>
                        </GenderMark>
                        <span>man</span>
                    </Gender>
                </GenderForm>

                <StepNextBox>
                    <SignupButton onClick={() => nextStep()}>다음으로</SignupButton>
                    {/* <p onClick={() => nextStep(true)}>스킵하기</p> */}
                </StepNextBox>
            </StepContents>

        </>
    )
}

export default SignUpStepOne
