//react
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import { DateToText } from "../../../components/common/commonUse";
import HtmlParser from "react-html-parser";

//css
import styled from "styled-components";

// img
import IconInquiryType1 from "../../img/icon/icon_product_inquiry_type1.png";
import IconInquiryType2 from "../../img/icon/icon_product_inquiry_type2.png";
import IconInquiryType3 from "../../img/icon/icon_product_inquiry_type3.png";
import IconLock from "../../img/icon/icon_secret_blue.png";
import IconRe from "../../img/icon/icon_recomment.png";

//page
import PasswordModal from "../common/modal/PasswordModal";
import Pagination from "../common/pagination/Pagination";
import Search from "../common/pagination/Search";
import InquiryType from "./InquiryType";

export default function FaqLayout({ list, listComment }) {
  const history = useHistory();

  const detailClick = () => {
    // return history.push("/faq/detail");
  };

  // listComment.map((value,index)=>{
  //     return(
  //         <Cont>
  //             <Division></Division>
  //             <Subject>
  //                 <SubjectText onClick={() => {detailClick()}}>
  //                     <IconReComment src={IconRe} alt="답글"/>
  //                     A. {value.subject}
  //                 </SubjectText>
  //             </Subject>
  //             {/* <View>{value.view}</View> */}
  //             <Date>{value.date}</Date>
  //         </Cont>
  //     )
  // })

  return (
    <Container>
      <PC>
        <Content>
          <Thead>
            <DivisionTit>NO</DivisionTit>
            <SubjectTit>제목</SubjectTit>
            {/* <ViewTit>조회</ViewTit> */}
            <DateTit>작성일</DateTit>
          </Thead>
          {list.length > 0 ? (
            list?.map((value, index) => {
              const { fnq_title, fnq_content, fnq_date } = value;

              return (
                <React.Fragment>
                  <Cont>
                    <Division>{index + 1}</Division>
                    <Subject>
                      <SubjectText
                        onClick={() => {
                          detailClick();
                        }}
                      >
                        Q. {fnq_title}
                      </SubjectText>
                    </Subject>
                    {/* <View>{value.view}</View> */}
                    <Date>{"20" + DateToText(fnq_date, "-", true)}</Date>
                  </Cont>
                  {(fnq_content !== null && (
                    <Cont>
                      <Division></Division>
                      <Subject>
                        <SubjectText
                          onClick={() => {
                            detailClick();
                          }}
                        >
                          <IconReComment src={IconRe} alt="답글" />
                          A. {HtmlParser(fnq_content)}
                        </SubjectText>
                      </Subject>
                      {/* <View>{value.view}</View> */}
                      <Date>{"20" + DateToText(fnq_date, "-", true)}</Date>
                    </Cont>
                  )) ||
                    null}
                </React.Fragment>
              );
            })
          ) : (
            <NoText>자주 묻는 질문 리스트가 없습니다.</NoText>
          )}
        </Content>
      </PC>

      <Mobile>
        <Content>
          {list.length > 0 ? (
            list?.map((value, index) => {
              const { fnq_title, fnq_content, fnq_date } = value;
              return (
                <React.Fragment>
                  <Cont>
                    <Division>{index}</Division>
                    <MbTitleWrap>
                      <Subject>
                        <SubjectText
                          onClick={() => {
                            detailClick();
                          }}
                        >
                          Q. {fnq_title}
                        </SubjectText>
                      </Subject>
                      <MbTextWrap>
                        {/* <View>조회 {value.view}</View> */}
                        <Date>{DateToText(fnq_date, "-", true)}</Date>
                      </MbTextWrap>
                    </MbTitleWrap>
                  </Cont>
                  <Cont>
                    <Division></Division>
                    <MbTitleWrap>
                      <Subject>
                        <SubjectText
                          onClick={() => {
                            detailClick();
                          }}
                        >
                          <IconReComment src={IconRe} alt="답글" />
                          Re : {fnq_content}
                        </SubjectText>
                      </Subject>
                      <MbTextWrap>
                        {/* <View>조회 {value.view}</View> */}
                        <Date>{DateToText(fnq_date, "-", true)}</Date>
                      </MbTextWrap>
                    </MbTitleWrap>
                  </Cont>
                </React.Fragment>
              );
            })
          ) : (
            <NoText>자주 묻는 질문 리스트가 없습니다.</NoText>
          )}
        </Content>
      </Mobile>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
// form
const Content = styled.div`
  width: 100%;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;

  @media screen and (max-width: 1320px) {
    border-top: calc(100vw * (1 / 1320)) solid #000;
    border-bottom: calc(100vw * (1 / 1320)) solid #000;
  }

  @media screen and (max-width: 1320px) {
    border-top: calc(100vw * (1 / 1320)) solid #000;
    border-bottom: calc(100vw * (1 / 1320)) solid #000;
  }
`;
const Thead = styled.div`
  display: flex;
  align-items: center;
  padding: 21px 0;
  text-align: center;
  color: #525252;
  font-weight: bold;
  font-size: 15px;
  border-bottom: 1px solid #d9d9d9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (21 / 1320)) 0;
    font-size: calc(100vw * (15 / 1320));
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }
`;
const DivisionTit = styled.div`
  width: 163px;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (163 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }
`;
const SubjectTit = styled(DivisionTit)`
  width: 800px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (800 / 1320));
  }
`;
const ViewTit = styled(DivisionTit)`
  width: 141px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (141 / 1320));
  }
`;
const DateTit = styled(DivisionTit)`
  width: 175px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (175 / 1320));
  }
`;
const Cont = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding: 22px 0;
  border-bottom: 1px solid #d9d9d9;
  &:last-child {
    border-bottom: none;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (22 / 1320)) 0;
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    align-items: flex-start;
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (22 / 428)) 0;
    border-bottom: calc(100vw * (1 / 428)) solid #d9d9d9;
  }
`;
const Division = styled.div`
  width: 163px;
  color: #969696;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (163 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (60 / 428));
  }
`;
const Subject = styled.div`
  display: flex;
  align-items: center;
  width: 800px;
  /* cursor: pointer; */

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (800 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const SubjectText = styled.div`
  display: flex;
  align-items: center;
  width: 600px;
  text-align: left;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (600 / 1320));
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (15 / 428));
  }
`;
const IconReComment = styled.img`
  display: block;
  width: 13px;
  margin-right: 6px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (13 / 1320));
    margin-right: calc(100vw * (6 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (13 / 428));
    margin-right: calc(100vw * (6 / 428));
  }
`;
const User = styled.div`
  width: 169px;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (169 / 1320));
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: auto;
    font-size: calc(100vw * (13 / 428));
    text-align: center;
    margin-right: calc(100vw * (6 / 428));
    padding-right: calc(100vw * (6 / 428));
    border-right: calc(100vw * (1 / 428)) solid #969696;
    &:last-child {
      border-right: 0;
    }
  }
`;
const View = styled(User)`
  width: 141px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (141 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: auto;
    text-align: center;
  }
`;
const Date = styled(User)`
  width: 175px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (175 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: auto;
    text-align: center;
  }
`;
// mobile
const MbTitleWrap = styled.div`
  width: calc(100% - calc(100vw * (60 / 428)));
`;
const MbTextWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: calc(100vw * (12 / 428));
`;
const NoText = styled.p`
  width: 100%;
  text-align: center;
  padding: 100px 0;
  font-size: 15px;
  color: #999;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (100 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (13 / 428));
    padding: calc(100vw * (50 / 428)) 0;
  }
`;
