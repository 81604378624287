import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PC, Mobile } from "../../components/common/mediaStlye";
import styled from "styled-components";

import MyPageHeader from "../../components/common/header/MyPageHeader";
import Header from "../../components/common/header/Header";
import StylistContentsModify from "../../components/stylist/stylistContentsModify";
import Footer from "../../components/common/footer/Footer";
import FooterMenu from "../../components/common/footer/FooterMenu";
import serverController from "../../server/serverController";

const StylistModify = () => {
  const params = useParams();
  const { contentsId } = params;
  console.log(contentsId);

  const [listData, setListData] = useState([]);

  const getStylistModify = async () => {
    await serverController.connectFetchController(
      `/stylist/contentslist?mem_id=1&page=0`,
      "GET",
      null,
      (res) => {}
    );
  };

  useEffect(() => {
    getStylistModify();
  }, []);

  return (
    <Container>
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader title="콘텐츠 등록" />
      </Mobile>
      <StylistContentsModify />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
    </Container>
  );
};
const Container = styled.div`
  @media screen and (max-width: 1024px) {
    padding-bottom: calc(100vw * (68 / 428));
  }
`;

export default StylistModify;
