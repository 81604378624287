import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import faker from "faker";
import styled from "styled-components";
import { PC, Mobile } from "../common/mediaStlye";
import { imgURL } from "../../server/appInfoContoller";
import ReactHtmlParser from "react-html-parser";
import { postLikeItem, postLikeProducts } from "../../hooks/likeHooks";
import DefaultImg from "../../store_src/img/icon/loading_default_img.svg";
import HeaderSearchModal from "../common/header/HeaderSearchModal";
import SearchBlack from "../../images/search_icon_black.svg";
import IconLogo from "../../store_src/img/icon/wala_logo.svg";

import {
  Container,
  LayOut,
  DetailsWrapper,
  DetailMainBanner,
  DetailMainContents,
  DetailMainDesc,
  DescHead,
  DescContent,
  LikeButton,
  Tag,
  DetailMainGallery,
  MobileTagList,
} from "./ContentDetailsPage.element";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { useSelector } from "react-redux";

import likeBtnOff from "../../images/stylist/like_icon.svg";
import likedIcon from "../../images/stylist/liked_icon.svg";
import IconBack from "../../img/common/prevBtn.svg";
import DetailBanner from "../../images/details-banner.png";
import detailThumbimg1 from "../../images/content/content-detail-thumb1.png";
import detailThumbimg2 from "../../images/content/content-detail-thumb2.png";
import detailThumbimg3 from "../../images/content/content-detail-thumb3.png";
import detailThumbimg4 from "../../images/content/content-detail-thumb4.png";
import { DateToText } from "../common/commonUse";

SwiperCore.use([Pagination, Navigation]);

const ContentDetailsMain = ({ data, setIsContentModal, setContentId }) => {
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  const [src, setSrc] = useState(data?.contents_main);
  const [list, setList] = useState([]);
  const [isLiked, setIsLiked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  console.log(data, "src");

  const thumbs = [
    detailThumbimg1,
    detailThumbimg2,
    detailThumbimg3,
    detailThumbimg4,
  ];

  const onClick = (e) => {
    if (e.target.src) {
      setSrc(e.target.src);
    }
  };

  useEffect(() => {
    return (() => {
      setIsOpen(false);
    })
  }, []);

  useEffect(() => {
    setSrc(imgURL + data?.contents_main);
    const sources = data?.contents_thumbnail
      ?.split(",")
      .filter((v) => v !== "");
    // setSrc(Array.isArray(sources) ? imgURL + sources[0] : "");
    const subImgs = data?.contents_sub?.split(",").filter((v) => v !== "");
    setList(subImgs);
    setIsLiked(data?.like_id);
  }, [data]);

  const onHandleLike = async () => {
    if (!isLogged) return alert("로그인이 필요한 서비스입니다.");
    const isLike = await postLikeItem(data?.contents_id);
    console.log(isLike);
    setIsLiked(isLike?.result === 1);
  };

  return (
    <>
      <PC>
        <Container>
          <LayOut>
            <DetailsWrapper>
              <DetailMainBanner>
                <div onClick={onClick}>
                  <img
                    src={data?.contents_main ? src : DefaultImg}
                    alt={data?.contents_main ? "contents-img" : "default-img"}
                  />
                </div>
                {/* <img src={src} alt="" /> */}
              </DetailMainBanner>

              <DetailMainContents>
                <DetailMainDesc>
                  <DescHead>
                    <p>
                      {data?.contents_date.split(" ")[0].replace(/-/g, ".")}
                    </p>
                    <h2>{data?.contents_title}</h2>
                    <LikeButton className="like-button" onClick={onHandleLike}>
                      <img
                        src={isLiked ? likedIcon : likeBtnOff}
                        alt="like-button"
                      />
                    </LikeButton>
                  </DescHead>

                  <DescContent>
                    <p>{ReactHtmlParser(data?.contents_content)}</p>
                    <ul>
                      {data?.contents_teg?.split(",").map((v, i) => (
                        <Tag key={i}>#{v}</Tag>
                      ))}
                    </ul>
                  </DescContent>
                </DetailMainDesc>

                <DetailMainGallery>
                  <div onClick={onClick}>
                    <img
                      src={
                        data?.contents_main
                          ? imgURL + data?.contents_main
                          : DefaultImg
                      }
                      alt={data?.contents_main ? "contents-img" : "default-img"}
                    />
                  </div>
                  {list?.map((v, i) => {
                    return (
                      <div onClick={onClick} key={i}>
                        <img
                          src={v ? imgURL + v : DefaultImg}
                          alt={v ? "list-img" : "default-img"}
                        />
                      </div>
                    );
                  })}
                </DetailMainGallery>
              </DetailMainContents>
            </DetailsWrapper>
          </LayOut>
        </Container>
      </PC>

      <Mobile>
        <BackBtnBox>
          {isOpen && <HeaderSearchModal setIsOpen={setIsOpen} />}
          <BackText
            onClick={() => {
              setIsContentModal(false);
              setContentId();
            }}
          >
            <BackBtn src={IconBack} alt="" />
            뒤로가기
          </BackText>
          <Logo onClick={(e) => {

            e.preventDefault();
            history.push(`/`);
            setIsContentModal(false);
            window.location.reload();
          }}>
            <LogoImg src={IconLogo} alt="logo" />
          </Logo>
          <SearchWrap>
            <SearchIcon src={SearchBlack} onClick={() => { setIsOpen(true) }} />
          </SearchWrap>
        </BackBtnBox>
        <Container>
          {data ? (
            <LayOut>
              <DetailMainContents>
                <DetailMainDesc>
                  <DescHead>
                    <p>{data?.contents_date}</p>
                    <h2>
                      {data?.contents_title}
                      <LikeButton onClick={onHandleLike}>
                        <img
                          src={isLiked ? likedIcon : likeBtnOff}
                          alt="like-button"
                        />
                      </LikeButton>
                    </h2>
                  </DescHead>
                  <MobileTagList>
                    {data?.contents_teg?.split(",").length > 0 && (
                      <Swiper
                        // loop
                        // pagination={{clickable: true}}
                        spaceBetween={10}
                        slidesPerView="auto"
                      >
                        {data?.contents_teg?.split(",").map((v, i) => (
                          <SwiperSlide key={i}>
                            <Tag>#{v}</Tag>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    )}
                  </MobileTagList>
                  <DetailMainBanner>
                    <img
                      src={
                        data?.contents_main
                          ? imgURL + data?.contents_main
                          : DefaultImg
                      }
                      alt={data?.contents_main ? "contents-img" : "default-img"}
                    />
                  </DetailMainBanner>
                  <DetailMainGallery>
                    <Swiper spaceBetween={7} slidesPerView={2.2}>
                      <SwiperSlide>
                        <ImgBox onClick={onClick}>
                          <img
                            src={
                              data?.contents_main
                                ? imgURL + data?.contents_main
                                : DefaultImg
                            }
                            alt={
                              data?.contents_main
                                ? "contents-img"
                                : "default-img"
                            }
                          />
                        </ImgBox>
                      </SwiperSlide>
                      {data?.contents_sub?.split(",").map((item, i) => {
                        if (item === "") return;
                        return (
                          <SwiperSlide key={i}>
                            <div onClick={onClick}>
                              <img
                                src={item ? imgURL + item : DefaultImg}
                                alt={item ? "item-img" : "default-img"}
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </DetailMainGallery>
                </DetailMainDesc>
                <DescContent>
                  {ReactHtmlParser(data?.contents_content)}
                </DescContent>
              </DetailMainContents>
            </LayOut>
          ) : null}
        </Container>
      </Mobile>
    </>
  );
};

const BackBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 25px 0;

  @media screen and (max-width: 1024px) {
    position: fixed;
    background-color: #fff;
    z-index: 2;
    padding: calc(100vw * (18 / 428)) calc(100vw * (25 / 428));
  }
`;
const BackText = styled.p`
  display: flex;
  align-items: center;
  width: 100px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    width: calc(100vw * (100 / 428));
    font-size: calc(100vw * (13 / 428));
  }
`;

const SearchWrap = styled.div`
  width : calc(100vw * (100 / 428));
  display: flex;
  justify-content: end;
`
const SearchIcon = styled.img`
  
`

const BackBtn = styled.img`
  display: block;
  width: 12px;
  padding-right: 10px;
  @media screen and (max-width: 1024px) {
    width: calc(100vw * (10 / 428));
    padding-right: calc(100vw * (10 / 428));
  }
`;
const ImgBox = styled.div`
  @media screen and (max-width: 1024px) {
    height: calc(100vw * (168 / 428));
  }
`;

export const Logo = styled.h1`
width: calc(100vw * (110 / 490));
  position: relative;
  cursor: pointer;
  font-weight: 900;
  text-align: center;
  justify-content: center;
`;

const LogoImg = styled.img`
  display: block;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  
  @media screen and (max-width: 1320px) {
    width: 100%;
  }

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
export default ContentDetailsMain;
