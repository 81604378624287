import React, { useState, useEffect } from "react";
import styled from "styled-components";
import IconAccessory from "../../img/mobileMainIcons/icon_mo_main_accessory.svg";
import IconBag from "../../img/mobileMainIcons/icon_mo_main_bag.svg";
import IconBig from "../../img/mobileMainIcons/icon_mo_main_big.png";
import IconKids from "../../img/mobileMainIcons/icon_mo_main_kids.svg";
import IconBottom from "../../img/mobileMainIcons/icon_mo_main_bottom.svg";
import IconGlasses from "../../img/mobileMainIcons/icon_mo_main_glasses.svg";
import IconOuter from "../../img/mobileMainIcons/icon_mo_main_outer.svg";
import IconSet from "../../img/mobileMainIcons/icon_mo_main_set.svg";
import IconShoes from "../../img/mobileMainIcons/icon_mo_main_shoes.svg";
import IconSkirt from "../../img/mobileMainIcons/icon_mo_main_skirt.svg";
import IconBeauty from "../../img/mobileMainIcons/icon_mo_main_beauty.svg";
import IconLiving from "../../img/mobileMainIcons/icon_mo_main_living.svg";
import IconTop from "../../img/mobileMainIcons/icon_mo_main_top.svg";
import { useHistory } from "react-router-dom";
import { crypto } from "utils/crypto";

const CateData = [
  { icon: IconOuter, text: "아우터", url: 5 },
  { icon: IconTop, text: "상의", url: 1 },
  { icon: IconBottom, text: "하의", url: 2 },
  { icon: IconSkirt, text: "스커트", url: 4 },
  { icon: IconSet, text: "원피스/세트", url: 3 },
  { icon: IconAccessory, text: "악세사리", url: 8 },
  { icon: IconBag, text: "가방", url: 7 },
  { icon: IconShoes, text: "슈즈", url: 6 },
  { icon: IconBeauty, text: "뷰티", url: 9 },
  { icon: IconLiving, text: "리빙", url: 11 },
  { icon: IconKids, text: "키즈", url: 10 },
];

export default function CategoryListSection({ mainCateLink, setLoading }) {
  const history = useHistory();
  const [cateListItem, setCateListItem] = useState([]);

  const getMainLink = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(mainCateLink, requestOptions)
      .then((result) => result.json())
      .then((result) => {
        setCateListItem(result.list);
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const getSubId = (urlId) => {
    let subId = 0;
    for (let i in cateListItem) {
      if (cateListItem[i].prd_maincat_id === urlId) {
        subId = cateListItem[i].prd_subcat_id;
      }
    }
    return subId;
  };

  useEffect(() => {
    if (mainCateLink === "") return;
    getMainLink();
  }, [mainCateLink]);

  return (
    <Container>
      <CateWrapTop>
        {CateData.map((value) => {
          return (
            <IconWrap
              key={`${value.text}-cate1`}
              onClick={() => {
                history.push(`/store/category/${crypto.encryptionBASE64(value.url)}/${crypto.encryptionBASE64(getSubId(value.url))}`);
              }}
            >
              <IconImage>
                <img src={value.icon} alt="cate-icon" />
              </IconImage>
              <IconText>{value.text}</IconText>
            </IconWrap>
          );
        })}
      </CateWrapTop>
    </Container>
  );
}
const Container = styled.div`
  width:  100%;
  position: relative;
  @media screen and (max-width: 930px) {
    background: none;
    padding: calc(100vw * (22 / 428)) 0;
  }
`;
const CateWrapTop = styled.div`
   display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  -webkit-flex-wrap: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 930px) {
  }
`;
const IconWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 930px) {
    padding: 0 calc(100vw * (5 / 428));
    &:first-child{
      padding-left: calc(100vw * (20 / 428));
    }
    &:last-child{
      padding-right: calc(100vw * (20 / 428));
    }
    ${({ touch }) => touch && `background-color : #999;`}/* height: calc(100vw * (52 / 428)); */
  }
`;
const IconImage = styled.div`
  > img {
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 930px) {
    min-height: calc(100vw * (30 / 428));
    height: calc(100vw * (36 / 428));
    min-width: calc(100vw * (62 / 428));
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
`;
const IconText = styled.div`
  color: #222222;
  white-space: nowrap;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (13 / 428));
    padding-top: calc(100vw * (10 / 428));
  }
`;
