import React, { useEffect, useState } from "react";
import { PC, Mobile } from "../../MediaQuery";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Header from "../../components/common/header/Header";
import SearchResults from "../../components/search/SearchResults";
import Footer from "../../components/common/footer/Footer";
import FooterMenu from "../../components/common/footer/FooterMenu";
import ContentDetailPage from "../ContentDetailPage";

const SearchResultPage = () => {
  const [isContentModal, setIsContentModal] = useState(false);
  const [contentId, setContentId] = useState(0);
  const params = useParams();

  useEffect(() => {
    setIsContentModal(false);
  }, [params]);

  return (
    <Container>
      <Header />
      <SearchResults
        setIsContentModal={setIsContentModal}
        setContentId={setContentId}
      />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>

      {isContentModal && (
        <ContentDetailPage
          setIsContentModal={setIsContentModal}
          setContentId={setContentId}
          contentId={contentId}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: calc(100vw * (68 / 428));
`;

export default SearchResultPage;
