import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { imgURL } from "../../server/appInfoController";
import serverController from "../../server/serverController";
import styled from "styled-components";
import IconLikeButton from "../../img/brand/icon_brand_likeButton.svg";
import IconLikeButtonOn from "../../img/brand/icon_brand_likeButton_on.svg";

export default function BrandListContents({ value, widthOne = false, setLoginPop }) {
  const history = useHistory();
  const { isLogged } = useSelector((state) => state.userData);
  const [likeState, setLikeState] = useState(false);

  const postLikeBrand = () => {
    serverController.connectFetchController(
      `brand/like/${value.bcId}`,
      "POST",
      null,
      (res) => {
        if (res.result === 1) {
          setLikeState(!likeState);
        }
      },
      (err) => console.error(err)
    );
  };

  useEffect(() => {
    setLikeState(value.like === 1);
  }, [value]);

  return (
    <AllBrandListContents widthOne={widthOne} className="brand-list">
      <AllBrandListImgBox onClick={() => history.push(`/store/brand/detail/${value.bcId}?page=1`)}>
        <AllBrandListImg src={`${imgURL}${value.bcLogo}`} alt="allBrandImg" />
      </AllBrandListImgBox>
      <AllBrandTextWrap>
        <AllBrandTextBox onClick={() => history.push(`/store/brand/detail/${value.bcId}?page=1`)}>
          <AllBrandTextTitle>{value.bcTitle}</AllBrandTextTitle>
          <AllBrandTextText>{value.bcTitleKor}</AllBrandTextText>
        </AllBrandTextBox>
        <AllBrandLikeBox
          onClick={() => {
            if (!isLogged) return setLoginPop(true);
            postLikeBrand();
          }}
        >
          <AllBrandLikeImg
            src={likeState ? IconLikeButtonOn : IconLikeButton}
            alt="brandLikeButtonImg"
          />
        </AllBrandLikeBox>
      </AllBrandTextWrap>
    </AllBrandListContents>
  );
}
const AllBrandListContents = styled.div`
  display: flex;
  align-items: center;
  width: 378px;
  padding: 15px 0;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;

  ${({ widthOne }) =>
    widthOne &&
    `
      width: 100%;
  `}

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
    padding: calc(100vw * (15 / 1320)) 0;

    ${({ widthOne }) =>
      widthOne &&
      `
        width: 100%;
    `}
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    padding: calc(100vw * (15 / 428)) 0;
  }
`;
const AllBrandListImgBox = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (50 / 1320));
    height: calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (50 / 428));
    height: calc(100vw * (50 / 428));
  }
`;
const AllBrandListImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const AllBrandTextBox = styled.div`
  font-size: 15px;
  margin-left: 15px;
  width: 80%;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (15 / 428));
  }
`;
const AllBrandTextTitle = styled.div`
  color: #333333;
  font-weight: 500;
  margin-bottom: 2px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (2 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (2 / 428));
  }
`;

const AllBrandTextText = styled.div`
  font-weight: 400;
  color: #777777;
`;
const AllBrandLikeBox = styled.div`
  width: 24px;
  height: 24px;
  margin-left: auto;
  align-self: center;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const AllBrandLikeImg = styled.img`
  width: 100%;
  height: 100%;
`;
const AllBrandTextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
