const size = {
    desktop: "1920px",
    container : "1024px",
    mobile: "768px",
    loginRow: "710px",
  }
  
  export default function theme() {
    return {
      desktop: `(max-width: ${size.desktop})`,
      container: `(max-width: ${size.container})`,
      mobile: `(max-width: ${size.mobile})`,
      loginRow: `(max-width: ${size.loginRow})`,
    }
  }