import React from "react";
import DaumPostcode from "react-daum-postcode";
import styled from "styled-components";

const SearchAdressPop = ({
  setAdrAddress,
  setAdrZipCode,
  setIsSearch,
  isSearch,
}) => {
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setAdrZipCode(data?.zonecode);
    setAdrAddress(fullAddress);
    setIsSearch(false);

    test(fullAddress);
  };
  const postCodeStyle = {
    // display: "block",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "9999",
  };

  function test(road) {
    var api = "0d6ed103d31bec8d46352082483c5598";
    const headers = { Authorization: "KakaoAK " + api };
    const url =
      "https://dapi.kakao.com/v2/local/search/address.json?&query=" +
      encodeURI(road);

    return fetch(`${url}`, {
      method: "GET",
      headers: headers,
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        console.log(data, "주소 정보");
        const location = data.documents[0];
        setAdrAddress(location.address_name);
        setAdrZipCode(location.road_address.zone_no);
        setIsSearch(false);
      })
      .catch(function (e) {
        console.error(e);
        // setIsSearch(false);
      });
  }

  return (
    <>
      <DaumPostcode
        onComplete={handleComplete}
        autoClose={true}
        animation={true}
        style={postCodeStyle}
        // isSearch={isSearch}
        isSearch={true}
      />
    </>
  );
};

export default SearchAdressPop;
