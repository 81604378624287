import React from 'react';

import styled , {css} from  'styled-components';

export default function Pagination(){
    return(
        <PaginationContainer>
            <PaginationWrap>
                <NaviBtn type={'Prev'} active={false}/>
                <PageNumWrap>
                    <PageNum active>1</PageNum>
                    <PageNum>2</PageNum>
                    <PageNum>3</PageNum>
                    <PageNum>4</PageNum>
                    <PageNum>5</PageNum>
                </PageNumWrap>
                <NaviBtn type={'Next'} active={true}/>
            </PaginationWrap>
        </PaginationContainer>
    )
}

const PaginationContainer = styled.div`
    margin-top:calc(100vw * (60 / 1920));
    @media screen and (max-width:768px){
        margin-top:calc(100vw * (70 / 428));
    }
`;
const PaginationWrap = styled.div`
    display:flex; justify-content: center;
`;

const NaviBtn = styled.button`
    width:24px; height:24px; 
    
    ${({active})=>{
        return !active ?
        css`
        border-top:4px solid #D3D3D3;
        border-left:4px solid #D3D3D3;
        `
        : 
        css`
        border-top:4px solid #FDF151;
        border-left:4px solid #FDF151;
        `
    }}

    ${props => props.type == 'Prev' && `transform:rotate(-45deg)`}
    ${props => props.type == 'Next' && `transform:rotate(135deg)`}  
`;

const PageNumWrap = styled.div`
    padding:0 calc(100vw * (52 / 1920));
`;

const PageNum = styled.button`
    font-size:16px; font-weight:700; padding:4px 10px; margin:0 10px; color:#A5A5A5;
    ${props => props.active && `color:#222222;`}
`;