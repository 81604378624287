import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { PC , Mobile } from '../../common/mediaStlye';
import { useSelector } from 'react-redux';
import { SignUpAction } from '../../../store/actionCreators';
import serverController from '../../../server/serverController';

import {
    Container,
    Layout,
    AuthIntroMessage,  
    Form,              
    FieldBox,
    DoubleFieldBox,
    Label,    
    LabelName,
    EmailInput,
    PasswordInput,
    PasswordCheckInput,
    TelephoneInput,
    Input
} from '../auth.elements';
import {
    SignupFormWrapper,    
    SubCheckButton,
    SignupBox,
    SignupButton,
    MobileSubBox
} from './signup.element';
import SignupEmailInput from './SignupEmailInput';
import SignupPasswordInput from './SignupPasswordInput';
import SignupPhoneInput from './SignupPhoneInput';
import SignupBrandNameInput from './SignupBrandNameInput';
import SignupBusinessRegistrationInput from './SignupBusinessRegistrationInput';
import SignupBrandRepresntInput from './SignupBrandRepresntInput';
import SignUpGuideLines from './SignUpGuideLines';

const SignUpBrand = () => {
    const history = useHistory();
    const data = useSelector(state => state.signupData);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");    
    const [phone, setPhone] = useState("");    
    const [brandName, setBrandName] = useState("")
    const [businessFile, setBusinessFile] = useState(null);
    const [name, setName] = useState("");
    const [rank, setRank] = useState("")

    const [term, setTerm] = useState(false);
    const [policy, setPolicy] = useState(false);
    const [next, setNext] = useState(false);

    const isClick = useRef(false);
    const [isCheck, setIsCheck] = useState({
        isEmail: false,
        isPassword: false,
        isBrandName: false,
        isBusinessFile: false,        
        isBrandInfo: false,        
        isPhone: false,
        isTerm: false,
        isPolicy: false,
    })
    console.log(isCheck)

    useEffect(() => {       
        const validateArr = [];
        for(const key in isCheck) {
            validateArr.push(isCheck[key]);
        }
        isClick.current = !validateArr.includes(false);      

        if(isClick.current === true){
            setNext(true)
        }else{
            setNext(false)
        }  
    }, [isCheck])

    const returnFormData = () => {
        const formData = new FormData();
        formData.append("mem_email",email);
        formData.append("mem_password", password);        
        formData.append("mem_brand_name",brandName);
        formData.append("pdf",businessFile);
        formData.append("mem_name", name);        
        formData.append("mem_rank",rank);// 직함
        formData.append("mem_phone",phone);
        
        return formData;
    }
    
    const isSignUp = useRef(true);
    const signUp = async () => {
        if(isSignUp.current){
            isSignUp.current = false;
            await serverController.connectFetchController(
                `brandjoin`,
                "POST",
                returnFormData(),
                (res) => {
                    isSignUp.current = true;
                    console.log(res, "회원가입 response");
                    if (res?.result === 1) {
                        history.push(`/signup/success`);
                    }
                },
                (err) => console.error(err)
            )
        }
        
    }

    const onHandleSubmit = (e) => {
        e.preventDefault();
        if (isClick.current === true) {          
            isClick.current = false;
            signUp()            
        }
    };

    const handleTermCheck = (checked) => {
        setTerm(checked)

        if (checked) {
            setIsCheck(prev => ({...prev, isTerm: true }))
        } else {
            setIsCheck(prev => ({...prev, isTerm: false }))
        }
    };
    const handlePolicyCheck = (checked) => {
        setPolicy(checked)

        if (checked) {
            setIsCheck(prev => ({...prev, isPolicy: true }))
        } else {
            setIsCheck(prev => ({...prev, isPolicy: false }))
        }
    };

    
    return (
        <Container>
            <Layout>
                    <AuthIntroMessage>
                        <h2>Brand member REGISTER</h2>
                        <p>브랜드 회원 가입을 통해 다양한 서비스를 이용해 보세요!</p>
                    </AuthIntroMessage>
                    
                    <Form onSubmit={onHandleSubmit}>                       
                        <SignupEmailInput 
                            setEmail={setEmail} 
                            setIsCheck={setIsCheck}
                            isCheck={isCheck}
                        />

                        <SignupPasswordInput 
                            setPassword={setPassword}                             
                            setIsCheck={setIsCheck} 
                        />

                        <SignupBrandNameInput 
                            setBrandName={setBrandName}
                            setIsCheck={setIsCheck} 
                        />                        
                      
                        <SignupBusinessRegistrationInput 
                            setBusinessFile={setBusinessFile}
                            setIsCheck={setIsCheck} 
                        />
                        
                        <SignupBrandRepresntInput
                            setName={setName}
                            setRank={setRank}
                            setIsCheck={setIsCheck}
                        />
                       
                        <SignupPhoneInput
                            setPhone={setPhone}                                                  
                            setIsCheck={setIsCheck} 
                        />

                        <SignupBox>
                            <SignUpGuideLines term={term} policy={policy} handleTermCheck={handleTermCheck} handlePolicyCheck={handlePolicyCheck}/>
                            <SignupButton on={next} type="submit">계정 만들기</SignupButton>
                        </SignupBox>
                    </Form>

                   
            </Layout>
        </Container>
    )
}

export default SignUpBrand
