import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import MyPageHeader from "./MyPageHeader";
import MyReviewDetail from "./MyReviewDetail";
import MyPageDefaultModal from "../../component/common/modal/MyPageDefaultModal";

export default function ReviewDetailPage() {
  return (
    <>
      <PC></PC>

      <Mobile>
        {/* 리뷰삭제 확인 모달 */}
        {/* <MyPageDefaultModal text={"해당 리뷰를 삭제하시겠습니까?"}/> */}

        <MyPageHeader title={"리뷰"} prevBtn />
        <MyReviewDetail />
      </Mobile>
    </>
  );
}
