//react
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery"
import { Swiper,SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination,Navigation } from 'swiper';

//css
import 'swiper/swiper-bundle.css';
import styled from "styled-components"

//img
// import bestbrand1 from "../../img/banner/bestbrand_img1.png"
// import bestbrand2 from "../../img/banner/bestbrand_img2.png"
// import bestbrand3 from "../../img/banner/bestbrand_img3.png"
// import bestbrandSub1 from "../../img/banner/bestbrand_subimg1-1.png"
// import bestbrandSub3 from "../../img/banner/bestbrand_subimg2-1.png"
// import bestbrandSub5 from "../../img/banner/bestbrand_subimg3-1.png"
import bestbrand1 from '../../../images/content/content-detail-prod1.png';
import bestbrand2 from '../../../images/content/content-detail-prod2.png';
import bestbrand3 from '../../../images/content/content-detail-prod3.png';
import bestbrandSub1 from '../../../images/category/category-prod-1.png';
import bestbrandSub3 from '../../../images/category/category-prod-2.png';
import bestbrandSub5 from '../../../images/category/category-prod-3.png';
//page
import Commas from "../../server/Commas"

export default function BestProduct() {
    SwiperCore.use([Pagination,Navigation ]);

    const bestList = [
        {
            src : bestbrand1,
            prd_title : "Fendi",
            prd_detail: "제품에 대한 한줄 설명",
            sub1 : bestbrandSub1,
            sub1_title: "크림 데님 쇼츠",
            sub1_price: 64000,
        },
        {
            src : bestbrand2,
            prd_title : "띵크오브FENDI ",
            prd_detail: "제품에 대한 한줄 설명",
            sub1 : bestbrandSub3,
            sub1_title: "크림 데님 쇼츠",
            sub1_price: 64000,
        },
        {
            src : bestbrand3,
            prd_title : "Fendi",
            prd_detail: "제품에 대한 한줄 설명",
            sub1 : bestbrandSub5,
            sub1_title: "크림 데님 쇼츠",
            sub1_price: 64000,
        },
    ]

  return (
    <Container>
        <PC>
            <BestWrap>
                <SubTitleWrap>
                    <SubTitle>BEST PRODUCT</SubTitle>
                </SubTitleWrap>
                <BestSlideWrap className="BestBrandWrap">
                    {
                        <Swiper
                            slidesPerView={3}
                            loop={true}
                            centeredSlides={true}
                            navigation={{
                                navigation: {
                                    nextEl: ".review-swiper-button-next",
                                    prevEl: ".review-swiper-button-prev",
                                },
                            }}
                        >
                            {
                                bestList.map((value,index)=>{
                                    return (
                                        <SwiperSlide key={"best product benner" + index}>
                                            <Slide>
                                                <SlideImgWrap>
                                                    <SlideImgBox>
                                                        <SlideImg src={value.src} alt="" />
                                                    </SlideImgBox>
                                                    <SlideImgTextWrap>
                                                        <SlideImgTextInner>
                                                            <SlideImgTitle>{value.prd_title}</SlideImgTitle>
                                                            <SlideImgText>{value.prd_detail}</SlideImgText>
                                                        </SlideImgTextInner>
                                                    </SlideImgTextWrap>
                                                </SlideImgWrap>
                                                <SlidePrdWrap>
                                                    <SlidePrdBox>
                                                        <SlidePrdimgWrap>
                                                            <SlidePrdimg src={value.sub1} alt="" />
                                                        </SlidePrdimgWrap>
                                                        <SlidePrdTextWrap>
                                                            <SlidePrdTitle>{value.sub1_title}</SlidePrdTitle>
                                                            <SlidePrdPrice>{Commas.numberWithCommas(value.sub1_price)}<PriceWon>원</PriceWon></SlidePrdPrice>
                                                        </SlidePrdTextWrap>
                                                    </SlidePrdBox>
                                                </SlidePrdWrap>
                                            </Slide>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    }
                </BestSlideWrap>
            </BestWrap>
        </PC>

        <Mobile>
            <BestWrap>
                <SubTitleWrap>
                    <SubTitle>BEST PRODUCT</SubTitle>
                </SubTitleWrap>
                <BestSlideWrap className="BestBrandWrap">
                    {
                        <Swiper
                            slidesPerView={1.1}
                            centeredSlides={true}
                            pagination={{ clickable: true }}
                        >
                            {
                                bestList.map((value,index)=>{
                                    return (
                                        <SwiperSlide key={"best product benner" + index}>
                                            <Slide>
                                                <SlideImgWrap>
                                                    <SlideImgBox>
                                                        <SlideImg src={value.src} alt="" />
                                                    </SlideImgBox>
                                                    <SlideImgTextWrap>
                                                        <SlideImgTextInner>
                                                            <SlideImgTitle>{value.prd_title}</SlideImgTitle>
                                                            <SlideImgText>{value.prd_detail}</SlideImgText>
                                                        </SlideImgTextInner>
                                                    </SlideImgTextWrap>
                                                </SlideImgWrap>
                                                <SlidePrdWrap>
                                                    <SlidePrdBox>
                                                        <SlidePrdimgWrap>
                                                            <SlidePrdimg src={value.sub1} alt="" />
                                                        </SlidePrdimgWrap>
                                                        <SlidePrdTextWrap>
                                                            <SlidePrdTitle>{value.sub1_title}</SlidePrdTitle>
                                                            <SlidePrdPrice>{Commas.numberWithCommas(value.sub1_price)}<PriceWon>원</PriceWon></SlidePrdPrice>
                                                        </SlidePrdTextWrap>
                                                    </SlidePrdBox>
                                                </SlidePrdWrap>
                                            </Slide>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    }
                </BestSlideWrap>
            </BestWrap>
        </Mobile>
    </Container>
  );
}
const Container = styled.div`
    width: 100%;
    padding-top: 50px;
    margin-bottom: 100px;
    
    @media screen and (max-width: 1320px) {
        padding-top: calc(100vw*(50/1320));
        margin-bottom: calc(100vw*(100/1320));
    }

    @media screen and (max-width: 930px) {
        padding-top: calc(100vw*(40/428));
        margin-bottom: calc(100vw*(70/428));
    }
`
const BestWrap = styled.div`
    width: 1420px;
    margin: 0 auto;
    
    @media screen and (max-width: 1430px) {
        width: calc(100vw*(1420/1430));
    }
    @media screen and (max-width: 1320px) {
        width: calc(100vw*(1280/1320));
    }

    @media screen and (max-width: 930px) {
        width: 100%;
    }
`
const SubTitleWrap = styled.div`
    width: 1300px;
    margin: 60px auto;
    
    @media screen and (max-width: 1320px) {
        width: calc(100vw*(1250/1320));
        margin: calc(100vw*(60/1320)) auto;
    }
    @media screen and (max-width: 930px) {
        width: calc(100vw*(388/428));
        margin: calc(100vw*(20/428)) auto calc(100vw*(30/428));
        padding-bottom: calc(100vw*(24/428));
        border-bottom: calc(100vw*(1/428)) solid #707070;
    }
`
const SubTitle = styled.h2`
    font-size: 30px;
    font-weight: 900;
    
    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(30/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(18/428));
        text-align: center;
    }
`
//slide
const BestSlideWrap = styled.div`
    width: 100%;
`
const Slide = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 350px;
    
    @media screen and (max-width: 1320px) {
        width: calc(100vw*(350/1320));
    }

    @media screen and (max-width: 930px) {
        width: 100%;
        padding: 0 calc(100vw*(8/428));
    }
`
const SlideImgWrap = styled.div`
    position: relative;
    width: 100%;
`
const SlideImgBox = styled.div`
        width: 350px; height: 350px;
        
        overflow: hidden;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    
    @media screen and (max-width: 1320px) {
        width: calc(100vw*(350/1320)); height: calc(100vw*(350/1320));
        
        box-shadow: 0 calc(100vw*(3/1320)) calc(100vw*(6/1320)) 0 rgba(0, 0, 0, 0.16);
    }

    @media screen and (max-width: 930px) {
        width: 100%; height: calc(100vw*(360/428));
        
        box-shadow: none;
    }
`
const SlideImg = styled.img`
    width: 100%; height: 100%;
    object-fit: cover;
`
const SlideImgTextWrap = styled.div`
    position: absolute; top: 0; left: 0;
    width: 350px; height: 350px;
    background: rgba(1,1,1,0.3);
    
    
    @media screen and (max-width: 1320px) {
        width: calc(100vw*(350/1320)); height: calc(100vw*(350/1320));
        
    }

    @media screen and (max-width: 930px) {
        width: 100%; height: calc(100vw*(360/428));
        
    }
`
const SlideImgTextInner = styled.div`
    position: absolute; bottom: 0; left: 0;
    padding: 0 28px 30px;
    
    @media screen and (max-width: 1320px) {
        padding: 0 calc(100vw*(28/1320)) calc(100vw*(30/1320));
    }

    @media screen and (max-width: 930px) {
        padding: 0 calc(100vw*(28/428)) calc(100vw*(30/428));
    }
`
const SlideImgTitle = styled.p`
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    padding: 15px 0;
    
    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(24/1320));
        padding: calc(100vw*(15/1320)) 0;
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(26/428));
        padding: calc(100vw*(15/428)) 0;
    }
`
const SlideImgText = styled.p`
    font-size: 18px;
    color: #fff;
    
    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(18/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(18/428));
    }
`
const SlidePrdWrap = styled.div`
    width: 100%; height: 100%;
    object-fit: cover;
    padding: 20px 0;
    
    @media screen and (max-width: 1320px) {
        padding: calc(100vw*(20/1320)) 0;
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(15/428));
    }
`
const SlidePrdBox = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0;
    
    @media screen and (max-width: 1320px) {
        padding: calc(100vw*(10/1320)) 0;
    }

    @media screen and (max-width: 930px) {
        padding: calc(100vw*(10/428)) 0;
    }
`
const SlidePrdimgWrap = styled.div`
    width: 90px; height: 90px;
    
    overflow: hidden;
    
    @media screen and (max-width: 1320px) {
        width: calc(100vw*(90/1320)); height: calc(100vw*(90/1320));
        
    }

    @media screen and (max-width: 930px) {
        width: calc(100vw*(90/428)); height: calc(100vw*(90/428));
        
    }
`
const SlidePrdimg = styled.img`
    width: 100%; height: 100%;
    object-fit: cover;
`
const SlidePrdTextWrap = styled.div`
    width: calc(100% - 90px);
    padding-left: 30px;
    
    @media screen and (max-width: 1320px) {
        width: calc(100% - calc(100vw*(90/1320)));
        padding-left: calc(100vw*(30/1320));
    }

    @media screen and (max-width: 930px) {
        width: calc(100% - calc(100vw*(90/428)));
        padding-left: calc(100vw*(30/428));
    }
`
const SlidePrdTitle = styled.p`
    font-size: 16px;
    color: #646464;
    margin-bottom: 15px;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(16/1320));
        margin-bottom: calc(100vw*(15/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(16/428));
        margin-bottom: calc(100vw*(12/428));
    }
`
const SlidePrdPrice = styled.p`
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    
    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(20/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(20/428));
    }
`
const PriceWon = styled.p`
    font-size: 16px;
    
    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(16/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(17/428));
    }
`