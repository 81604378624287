import React from 'react';
import faker from 'faker';
import { useHistory } from 'react-router-dom';
import {
    Layout,
    TabItemsContent,
    TabItemsHeading,
    MobileTabItemConts,
    TabItemImg,
    TabItemDesc,
    TabItemText,
    TabItemLiked,
    MobileTabItemsList,
    TabPaging,
    TabPages,
    PageNum,
    PrevButton,
    NextButton
} from './mypage.elements';
import LikedIcon from '../../../images/stylist/liked_icon.svg';
import TabPrevIcon from '../../../images/tab-arrow-prev.png';

const MyInfoContentItems = () => {
    const history = useHistory()
    return (
        <Layout>
            <TabItemsContent>
                <TabItemsHeading style={{ cursor: 'pointer' }}>
                    <h3>
                        <img src={TabPrevIcon} onClick={() => history.goBack()} />
                        ITEM
                    </h3>
                    <div>
                        <span>총 24개</span>
                        <span>더보기</span>
                    </div>
                </TabItemsHeading>

                <MobileTabItemsList>
                    {
                        Array(6).fill().map((item, i) => {
                            return (
                                <MobileTabItemConts key={i}>
                                    <TabItemImg className="item-thumb"><img src={faker.image.image()} /></TabItemImg>

                                    {/* <TabItemDesc className="items-desc">
                                    <TabItemText>
                                        <em>Celebrity</em>
                                        <h2>크리스탈  경찰수업 제작발표회 </h2>
                                        <p>
                                            Arely 크레이프 캐디 홀터 점프수트 랄프로렌 점프수트
                                        </p>
                                    </TabItemText>
                                </TabItemDesc> */}

                                    <TabItemLiked className="item-liked"><img src={LikedIcon} /></TabItemLiked>
                                </MobileTabItemConts>
                            )
                        })
                    }
                </MobileTabItemsList>
                <TabPaging>
                    <PrevButton />

                    <TabPages>
                        {
                            Array(3).fill().map((page, i) => {
                                return <PageNum key={i}>{i + 1}</PageNum>
                            })
                        }
                    </TabPages>

                    <NextButton />
                </TabPaging>
            </TabItemsContent>
        </Layout>
    )
}

export default MyInfoContentItems;
