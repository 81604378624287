// 서버 ip
// export const ip = "https://wala.land:8071/"; //빌드배포 런칭용
// export const ip = "https://localhost:8071/"; //로컬/
// export const ip = "https://192.168.1.54:8071/"; //로컬/
// export const ip = "http://3.36.5.45:8071/api/"; // 빌드배포 full버전

export const ip = process.env.NODE_ENV === "production" ? "https://wala.land:8071/" : "http://localhost:8071/";

const requestServer = async (path, method, body, callBack, errorCallBack, token) => {
  return fetch(`${ip}${path}`, {
    credentials: "include",
    method,
    ...(body && { body: body }),
    ...(token && { token: token }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (callBack) callBack(data);
      return data;
    })
    .catch((err) => {
      if (errorCallBack) errorCallBack(err);
      console.error(err);
    });
};

const api = {
  connectFetchController: async (path, method, body, callBack, errorCallBack) => {
    return fetch(`${ip}${path}`, {
      credentials: "include",
      method: method,
      body: body ? body : null,
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (callBack) callBack(data);
        return data;
      })
      .catch(function (err) {
        if (errorCallBack) errorCallBack(err);
        console.error(err);
      });
  },
  get: (path, body, callBack, errorCallBack, token) => requestServer(path, "GET", body, callBack, errorCallBack, token),
  post: (path, body, callBack, errorCallBack, token) => requestServer(path, "POST", body, callBack, errorCallBack, token),
  put: (path, body, callBack, errorCallBack, token) => requestServer(path, "PUT", body, callBack, errorCallBack, token),
  delete: (path, body, callBack, errorCallBack, token) => requestServer(path, "DELETE", body, callBack, errorCallBack, token),
};

export default api;
