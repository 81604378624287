import { useEffect } from "react";
import { Mobile, PC } from "../../../MediaQuery";

import Header from "../../component/common/Header";
import MyPageHeader from "../mypage/MyPageHeader";
import OrderReturnExchange from "../../component/order/OrderReturnExchange";

export default function OrderReturnExchangePage() {
  return (
    <>
      <PC>
        <Header />
      </PC>
      <Mobile>
        {/* TODO PUB :: 주문상세 페이지에서 페이지 이동 시, 타이틀 분기처리 부탁드립니다! */}
        {/* 상품교환 / 상품반품 */}
        <MyPageHeader title={"상품반품/교환"} prevBtn/>
      </Mobile>
      <OrderReturnExchange/>
    </>
  );
}
