import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

//img
import IconPage from "../../img/icon/icon_page_button.svg";

export default function MyPageLayOutName() {
  return (
    <Container>
      <PageList>
        <PageText>home</PageText>
      </PageList>
      <PageList>
        <PageText>마이페이지</PageText>
      </PageList>
    </Container>
  );
}

const Container = styled.ul`
  display: flex;
  align-items: center;
  width: 1240px;
  margin: 0 auto 15px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    margin: 0 auto calc(100vw * (15 / 1320));
  }
`;
const PageList = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background: url(${IconPage}) no-repeat center / contain;
  }

  &:last-child span {
    color: #333;
    font-weight: 600;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      width: calc(100vw * (24 / 1320));
      height: calc(100vw * (24 / 1320));
    }
  }
`;
const PageText = styled.span`
  color: #555;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
`;