import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { PC, Mobile } from "../../../MediaQuery";
import serverController from "../../../server/serverController";
import { useSelector } from "react-redux";
import { UserDataAction } from "../../../store/actionCreators";
import styled, { css } from "styled-components";

import {
  CategoryModal,
  CategoryModalHead,
  OpenMenuBar,
  CategoryModalList,
  CategoryModalListItem,
  CategoryModalSub,
  ModalSubItem,
} from "./Header.elements";

import open_menu_bar from "../../../images/open-menu-modal.png";
import walaStoreModal from "../../../images/wala-store-modal.svg";
import modalSubArrowIcon from "../../../images/modal-sub-arrow.png";
import myPageIcon from "../../../images/mypage-icon.png";
import walaStore from "../../../images/header/wala-store.svg";
import menuBar from "../../../images/menu-bar.png";
// import walaStoreModal from '../../../images/wala-store-modal.png';
import menuBarModal from "../../../images/menu-bar-modal.png";
import heartIcon from "../../../images/heart-dark.svg";
import accountIcon from "../../../images/account-dark.svg";
import brandIcon from "../../../images/brand-dark.svg";
import stylistIcon from "../../../images/stylist-dark.svg";
import logoutIcon from "../../../images/logout-store.svg";

export default function HeaderCategoryModal({ setIsMenu }) {
  const history = useHistory();
  const { isLogged, userInfo } = useSelector((state) => state.userData);

  const categoryArr = [
    {
      eng: "Celebrity fashion",
      kor: "샐럽 패션",
      link: "/category/1",
    },
    {
      eng: "drama fashion",
      kor: "드라마 패션",
      link: "/category/2",
    },
    {
      eng: "professional fashion",
      kor: "전문 패션",
      link: "/category/3",
    },
    {
      eng: "idol fashion",
      kor: "아이돌 패션",
      link: "/category/4",
    },
    {
      eng: "FASHION NEWS",
      kor: "패션 뉴스",
      link: "/news",
    },
  ];

  const onHandleLogout = () => {
    alert("로그아웃되셨습니다.");
    history.push("/");
    UserDataAction.logOutRequest();
  };

  const onHandleClick = (link) => {
    setIsMenu(false);
    history.push(link);
  };

  const moveAdmin = () => {
    window.location.href = "https://wala.land:8073/dashboard";
  };

  return (
    <>
      <PC>
        <Blank className="blank" onClick={() => setIsMenu(false)} />
        <CategoryModal>
          <CategoryModalHead>
            <OpenMenuBar onClick={() => setIsMenu(false)}>
              <img src={open_menu_bar} alt="메뉴바" />
            </OpenMenuBar>
          </CategoryModalHead>

          <CategoryModalList>
            {categoryArr.map((item, i) => (
              <CategoryModalListItem
                key={i}
                onClick={() => onHandleClick(item.link)}
              >
                {item.eng}
                <p>{item.kor}</p>
              </CategoryModalListItem>
            ))}
          </CategoryModalList>
        </CategoryModal>
      </PC>

      <Mobile>
        <CategoryModal>
          <CategoryModalHead>
            <OpenMenuBar onClick={() => setIsMenu(false)}>
              <img src={open_menu_bar} alt="메뉴바" />
            </OpenMenuBar>
          </CategoryModalHead>

          <CategoryModalList>
            {categoryArr.map((item, i) => (
              <CategoryModalListItem
                key={i}
                onClick={() => onHandleClick(item.link)}
              >
                {item.eng}
                {/* <p>{item.kor}</p> */}
              </CategoryModalListItem>
            ))}
          </CategoryModalList>
          <CategoryModalSub>
            {!isLogged && (
              <>
                <Link to="/login">
                  <ModalSubItem>
                    <div>로그인</div>
                    <img src={modalSubArrowIcon} />
                  </ModalSubItem>
                </Link>

                <Link to="/signup">
                  <ModalSubItem>
                    <div>회원가입</div>
                    <img src={modalSubArrowIcon} />
                  </ModalSubItem>
                </Link>
              </>
            )}
            {userInfo.mem_role === "USER" && (
              <>
                <ModalSubItem onClick={() => history.push("/mypage")}>
                  <div>
                    <img src={heartIcon} />
                    MYLIST
                  </div>
                  <img src={modalSubArrowIcon} />
                </ModalSubItem>
                <ModalSubItem onClick={() => history.push("/mypage")}>
                  <div>
                    <img src={accountIcon} />
                    MY PAGE
                  </div>
                  <img src={modalSubArrowIcon} />
                </ModalSubItem>
              </>
            )}
            {userInfo.mem_role === "BRAND" && (
              <>
                <ModalSubItem onClick={() => history.push("/brandmain")}>
                  <div>
                    <img src={brandIcon} />
                    BRAND
                  </div>
                  <img src={modalSubArrowIcon} />
                </ModalSubItem>
                <ModalSubItem onClick={moveAdmin}>
                  <div>
                    <img src={brandIcon} />
                    BRAND 관리자페이지
                  </div>
                </ModalSubItem>
                <ModalSubItem onClick={onHandleLogout}>
                  <div>
                    <IconLogout
                      src={logoutIcon}
                      alt=""
                      style={{ filter: "brightness(10)" }}
                    />
                    로그아웃
                  </div>
                </ModalSubItem>

                {/* <ModalSubItem onClick={() => history.push('/brandarchive')}>
                        <div><img src={heartIcon} />MY LIST</div>
                        <img src={modalSubArrowIcon} />
                    </ModalSubItem>                    
                    <ModalSubItem onClick={() => history.push('/management')} >
                        <div><img src={accountIcon} />MY PAGE</div>
                        <img src={modalSubArrowIcon} />
                    </ModalSubItem>                     */}
              </>
            )}
            {userInfo.mem_role === "STYLELIST" && (
              <>
                <ModalSubItem onClick={() => history.push("/stylist/sponserd")}>
                  <div>
                    <img src={stylistIcon} />
                    STYLIST
                  </div>
                  <img src={modalSubArrowIcon} />
                </ModalSubItem>
                <ModalSubItem onClick={() => history.push("/stylist/mypage/1")}>
                  <div>
                    <img src={heartIcon} />
                    MY LIST
                  </div>
                  <img src={modalSubArrowIcon} />
                </ModalSubItem>
                <ModalSubItem onClick={() => history.push("/stylist/mypage")}>
                  <div>
                    <img src={accountIcon} />
                    MY PAGE
                  </div>
                  <img src={modalSubArrowIcon} />
                </ModalSubItem>
              </>
            )}
            {/* <ModalSubItem store style={{cursor: 'pointer'}} onClick={() => history.push('/store')}>
                <div><img src={walaStoreModal} alt="wala-store" /></div>
                <img src={modalSubArrowIcon} />
            </ModalSubItem> */}
          </CategoryModalSub>
        </CategoryModal>
      </Mobile>
    </>
  );
}

const Blank = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: none;
  z-index: 100;
`;
const IconLogout = styled.img`
  width: calc(100vw * (12 / 1920));
`;
