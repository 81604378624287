import React, { useEffect, useState, useRef } from 'react'; 
import $ from 'jquery';
import api from '../../server/serverController';
import { useSelector } from 'react-redux';

import { Section, Wrapper } from './Main.elements';
import { IntroList, ControllBox, ProgressBar } from './MainIntro.elements';   
import MainIntroItem from './MainIntroItem';

import SwiperCore, { Pagination, Navigation, EffectFade, Autoplay } from 'swiper'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";
import './MainIntro.css';
// SwiperCore.use([Pagination, Navigation, EffectFade]);
SwiperCore.use([Pagination, Navigation, EffectFade, Autoplay]);

const MainIntro = () => {           
    const progressEl = useRef(null);
    const swiperOptions = {
        pagination: {
            el: '.intro-pagination',            
            type: 'fraction',
            formatFractionCurrent: function (number) {
                return '0' + number;
            },
            formatFractionTotal: function (number) {
                return ('0' + number).slice(-2);
            },           
        },
        navigation: {
            nextEl: '.next-button',
            prevEl: '.swiper-button-prev'
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        loop: true,                 
        fadeEffect: { crossFade: true },
        effect: "fade",         
        onInit: function () {
            $(".progress-bar").removeClass("animate");            
            $(".progress-bar").addClass("animate");            
        },
        onSlideChangeTransitionStart: function () {
            $(".progress-bar").removeClass("animate");                        
        },
        onSlideChangeTransitionEnd: function () {
            $(".progress-bar").addClass("animate");
        }        
    } 

    const { isLogged } = useSelector(state => state.userData);
    const [list, setList] = useState([]);
    
    const getData = async () => {        
        await api.get(`bannerlist?type=main`,null, 
            (res) => {
                console.log(res, "메인 배너리스트 response")
                if (res?.result === 0) {
                    setList(res?.list ?? []);
                } 
            }
        );
    }

    useEffect(() => {
        getData();
    }, [])
 
    return (
        <Section>
           <Wrapper className="intro-swiper">
               <IntroList className="intro-swiper">       
               {
                   list.length > 0 ? (
                    <Swiper {...swiperOptions}>   
                            {
                                list.map((v, i) => {
                                    return (
                                        <SwiperSlide key={v?.contents_id}>
                                            <MainIntroItem value={v} index={i}/>
                                        </SwiperSlide>
                                    )
                                })                        
                            }
                    </Swiper>               
                   ) : null
               }            
                </IntroList>

                <ControllBox>
                    <div className="intro-pagination"></div>
                    <ProgressBar>                        
                            <div className="progress-bar" ref={progressEl}>
                                <span className="progress-dot"></span>
                            </div>
                    </ProgressBar>
                    <div className="next-button">NEXT</div>                                 
                </ControllBox>               
           </Wrapper>
        </Section>
    )
}

export default MainIntro;
                
                

                   
                        
                        
                       
