import React, {useState , useEffect} from 'react';
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";

//components
// import {RegistBtn} from '../productManagement/ProductPopup';
// import {RegistNoBtn} from '../productManagement/ProductPopup';

//css
import styled from "styled-components"
import "./../../App.css"

//img
import icon from "../../img/brand/benner_icon.svg";
import arrow from "../../img/brand/arrow.png";
import notice from "../../img/brand/notice.png";
import close from "../../img/brand/close.png";
import calendar from "../../img/brand/calendar.png";
import select from "../../img/brand/select.png";

export default function BrandRegistation() {

    return (
        <>
            <PC>
                {/* ${popupActive && <RegistBtn/>} */}
                <BrandRegistWrap>
                    <BrandRegistHead>
                        <BrandText>Node be Made Incorporation</BrandText>
                        <BrandTextImg>
                            <img src={icon} alt="icon"/>
                        </BrandTextImg>
                    </BrandRegistHead>

                    <BrandRegistContentWrap>
                        <BrandRegistPrevWrap>
                            <BradnRegistPrev>
                                <BrandRegistImg>
                                    <img src={arrow} alt="arrow"/>
                                </BrandRegistImg>
                                <BrandRegistText>제품 관리</BrandRegistText>
                            </BradnRegistPrev>
                        </BrandRegistPrevWrap>

                        <BrandRegistForm>
                            <BrandRegistIdWrap>
                                <BrandRegistId>Title</BrandRegistId>
                                <BrandRegistIdInput type="text" placeholder="제품명을 입력해주세요." />
                            </BrandRegistIdWrap>

                            <BrandRegistNumWrap>
                                <BrandRegistId>품번</BrandRegistId>
                                <BrandRegistNumInput type="text" placeholder="품번을 입력해주세요." />
                            </BrandRegistNumWrap>

                            <BrandRegistValueWrap>
                                <BrandRegistValue>구분</BrandRegistValue>
                                <BrandRegistValueSelect>
                                    <option value="" hidden>상의 / 하의 / 코트 / 점퍼</option>
                                    <BrandRegistValueOption>상의</BrandRegistValueOption>
                                    <BrandRegistValueOption>하의</BrandRegistValueOption>
                                    <BrandRegistValueOption>코트</BrandRegistValueOption>
                                    <BrandRegistValueOption>점퍼</BrandRegistValueOption>
                                </BrandRegistValueSelect>
                            </BrandRegistValueWrap>

                            <BrandRegistDateWrap>
                                <BrandRegistDate>날짜</BrandRegistDate>
                                <BrandRegistDateInput />
                            </BrandRegistDateWrap>


                            <BrandRegistValueWrap>
                                <BrandRegistValue>상태</BrandRegistValue>
                                <BrandRegistValueSelect>
                                    <option value="" hidden>공개 / 비공개 / 오픈 예정</option>
                                    <BrandRegistValueOption>공개</BrandRegistValueOption>
                                    <BrandRegistValueOption>비공개</BrandRegistValueOption>
                                    <BrandRegistValueOption>오픈 예정</BrandRegistValueOption>
                                </BrandRegistValueSelect>
                            </BrandRegistValueWrap>

                            <BrandRegistNoticeWrap>
                                <BrandRegistNum>
                                    <span>No</span>
                                    <BrandRegistNotice>
                                        <img src={notice} alt="icon"/>
                                    </BrandRegistNotice>
                                </BrandRegistNum>
                                <BrandRegistNumInput type="text" placeholder="품번을 입력해주세요." />
                            </BrandRegistNoticeWrap>

                            <BrandRegistCheckWrap>
                                <BrandRegistCheckText>방식</BrandRegistCheckText>

                                {/* 체크박스 선택하면 검은색으로 색 변함. */}
                                <BrandRegistCheckContent>
                                    <BrandRegistCheckInputWrap>
                                        <BrandRegistCheckLabel>대여</BrandRegistCheckLabel>
                                        <BrandRegistCheck type="checkbox"/>
                                    </BrandRegistCheckInputWrap>

                                    <BrandRegistCheckInputWrap>
                                        <BrandRegistCheckLabel>방식</BrandRegistCheckLabel>
                                        <BrandRegistCheck type="checkbox"/>
                                    </BrandRegistCheckInputWrap>

                                    <BrandRegistCheckInputWrap>
                                        <BrandRegistCheckLabel>판매</BrandRegistCheckLabel>
                                        <BrandRegistCheck type="checkbox" />
                                    </BrandRegistCheckInputWrap>
                                </BrandRegistCheckContent>
                            </BrandRegistCheckWrap>

                            <BrandRegistNumWrap>
                                <BrandRegistNum>가격</BrandRegistNum>
                                <BrandRegistNumInput type="text" placeholder="310,000" />
                            </BrandRegistNumWrap>

                            <BrandRegistNumWrap>
                                <BrandRegistNum>수량</BrandRegistNum>
                                <BrandRegistNumInput type="text" placeholder="20" />
                            </BrandRegistNumWrap>

                            <BrandRegistNumWrap>
                                <BrandRegistNum>가격 (협찬,일)</BrandRegistNum>
                                <BrandRegistNumInput type="text" placeholder="310,000" />
                            </BrandRegistNumWrap>

                            <BrandRegistNumWrap>
                                <BrandRegistNum>수량</BrandRegistNum>
                                <BrandRegistNumInput type="text" placeholder="20" />
                            </BrandRegistNumWrap>

                            <BrandRegistNumWrap>
                                <BrandRegistNum>가격 (판매,개)</BrandRegistNum>
                                <BrandRegistNumInput type="text" placeholder="1,234,098" />
                            </BrandRegistNumWrap>

                            <BrandRegistSizeWrap>
                                
                                <BrandRegistSizeText>사이즈표 (상의)</BrandRegistSizeText>
                                    <BrandRegistSizeTable>
                                        <BrandRegistSizeInner>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSizeHead>어깨</BrandRegistSizeHead>
                                            </BrandRegistSizeContent>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSize>00</BrandRegistSize>
                                            </BrandRegistSizeContent>
                                        </BrandRegistSizeInner>

                                        <BrandRegistSizeInner>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSizeHead>가슴</BrandRegistSizeHead>
                                            </BrandRegistSizeContent>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSize>00</BrandRegistSize>
                                            </BrandRegistSizeContent>
                                        </BrandRegistSizeInner>

                                        <BrandRegistSizeInner>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSizeHead>총장</BrandRegistSizeHead>
                                            </BrandRegistSizeContent>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSize>00</BrandRegistSize>
                                            </BrandRegistSizeContent>
                                        </BrandRegistSizeInner>

                                        <BrandRegistSizeInner>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSizeHead>팔길이</BrandRegistSizeHead>
                                            </BrandRegistSizeContent>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSize>00</BrandRegistSize>
                                            </BrandRegistSizeContent>
                                        </BrandRegistSizeInner>

                                        <BrandRegistSizeInner>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSizeHead>허리</BrandRegistSizeHead>
                                            </BrandRegistSizeContent>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSize>00</BrandRegistSize>
                                            </BrandRegistSizeContent>
                                        </BrandRegistSizeInner>

                                        <BrandRegistSizeInner>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSizeHead>암홀</BrandRegistSizeHead>
                                            </BrandRegistSizeContent>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSize>00</BrandRegistSize>
                                            </BrandRegistSizeContent>
                                        </BrandRegistSizeInner>

                                    </BrandRegistSizeTable>

                                        <BrandRegistNumWrap>
                                            <BrandRegistNum>컬러</BrandRegistNum>
                                            <BrandRegistNumInput type="text" placeholder="컬러를 입력해주세요." />
                                        </BrandRegistNumWrap>

                                        <BrandRegistImgWrap>
                                            <BrandRegistNum>이미지 (메인) </BrandRegistNum>
                                            <BrandRegistNumInput type="text" placeholder="1장을 업로드 해주세요." />
                                            <BrandRegistImgBtn>업로드</BrandRegistImgBtn>
                                        </BrandRegistImgWrap>

                                        <BrandRegistImgWrap>
                                            <BrandRegistNum>이미지 (서브) </BrandRegistNum>
                                            <BrandRegistNumInput type="text" placeholder="1장을 업로드 해주세요." />
                                            <BrandRegistImgBtn>업로드</BrandRegistImgBtn>
                                        </BrandRegistImgWrap>

                                        <BrandRegistTextWrap>
                                            <BrandRegistTextHead>설명</BrandRegistTextHead>
                                                <BrandRegistTextArea placeholder="설명을 입력해주세요."/>
                                        </BrandRegistTextWrap>

                                        <BrandRegistNumWrap>
                                            <BrandRegistNum>해시태그</BrandRegistNum>
                                            <BrandRegistHashInput type="text" placeholder="해시태그를 입력후 엔터로 추가해주세요." />
                                        </BrandRegistNumWrap>

                                        <BrandRegistHashWrap>
                                            <BrandRegistHashList>
                                                <BrandRegistHashText>#이지아</BrandRegistHashText>
                                            </BrandRegistHashList>
                                            <BrandRegistHashList>
                                                <BrandRegistHashText>#원피스</BrandRegistHashText>
                                            </BrandRegistHashList>
                                            <BrandRegistHashList>
                                                <BrandRegistHashText>#샌들</BrandRegistHashText>
                                            </BrandRegistHashList>
                                            <BrandRegistHashList>
                                                <BrandRegistHashText>#가죽 백</BrandRegistHashText>
                                            </BrandRegistHashList>
                                            <BrandRegistHashList>
                                                <BrandRegistHashText>#헤어클립</BrandRegistHashText>
                                            </BrandRegistHashList>
                                        </BrandRegistHashWrap>

                                        <BrandRegistInfoTextWrap>
                                            <BrandRegistInfoText>
                                            *임시저장을 하시면 등록 완료 여부와 상관없이 현 상태에서 <br/>
                                            등록 정보를 임시로 저장 후 원하실 때 수정 및 공개 하실 수 있습니다.  
                                            </BrandRegistInfoText>

                                            <BrandRegistInfoText>
                                            *”공개”로 설정 시 즉시 공개가 진행됩니다.
                                            *”비공개”로 설정 시 노출되지 않으며, 상태 변경에 따라 공개가 진행됩니다.
                                            *”오픈 예정”으로 설정 시 노출되지만, 설정하신 날짜까지 결제
                                            또는 협찬 및 대여 신청 버튼이 비활성화 상태로 노출 됩니다.  
                                            </BrandRegistInfoText>
                                        </BrandRegistInfoTextWrap>

                                        <BrandRegistBtnWrap>
                                            <BrandRegistBtn type="button">임시 저장</BrandRegistBtn>
                                            <BrandRegistSaveBtn type="button">저장</BrandRegistSaveBtn>
                                        </BrandRegistBtnWrap>
                            </BrandRegistSizeWrap>
                        </BrandRegistForm>

                    </BrandRegistContentWrap>
                </BrandRegistWrap>
            </PC>

            <Mobile>
                {/* ${popupActive && <RegistBtn/>} */}
                <BrandRegistWrap>
                    <BrandRegistHead>
                        <BrandText>Node be Made Incorporation</BrandText>
                        {/* <BrandTextImg>
                            <img src={icon} alt="icon"/>
                        </BrandTextImg> */}
                    </BrandRegistHead>

                    <BrandRegistContentWrap>
                        <BrandRegistPrevWrap>
                        </BrandRegistPrevWrap>

                        <BrandRegistForm>
                            <BrandRegistIdWrap>
                                <BrandRegistId>Title</BrandRegistId>
                                <BrandRegistIdInput type="text" placeholder="제품명을 입력해주세요." />
                            </BrandRegistIdWrap>

                            <BrandRegistNumWrap>
                                <BrandRegistId>품번</BrandRegistId>
                                <BrandRegistNumInput type="text" placeholder="품번을 입력해주세요." />
                            </BrandRegistNumWrap>

                            <BrandRegistValueWrap>
                                <BrandRegistValue>구분</BrandRegistValue>
                                <BrandRegistValueSelect required>
                                <option value="" hidden>상의 / 하의 / 코트 / 점퍼</option>
                                    <BrandRegistValueOption>상의</BrandRegistValueOption>
                                    <BrandRegistValueOption>하의</BrandRegistValueOption>
                                    <BrandRegistValueOption>코트</BrandRegistValueOption>
                                    <BrandRegistValueOption>점퍼</BrandRegistValueOption>
                                </BrandRegistValueSelect>
                            </BrandRegistValueWrap>

                            <BrandRegistDateWrap>
                                <BrandRegistDate>날짜</BrandRegistDate>
                                <BrandRegistDateInput/>
                            </BrandRegistDateWrap>


                            <BrandRegistValueWrap>
                                <BrandRegistValue>상태</BrandRegistValue>
                                <BrandRegistValueSelect required>
                                    <option value="" hidden>공개 / 비공개 / 오픈 예정</option>
                                    <BrandRegistValueOption>공개</BrandRegistValueOption>
                                    <BrandRegistValueOption>비공개</BrandRegistValueOption>
                                    <BrandRegistValueOption>오픈 예정</BrandRegistValueOption>
                                </BrandRegistValueSelect>
                            </BrandRegistValueWrap>

                            <BrandRegistNoticeWrap>
                                <BrandRegistNum>
                                    <span>No</span>
                                    <BrandRegistNotice>
                                        <img src={notice} alt="icon"/>
                                    </BrandRegistNotice>
                                </BrandRegistNum>
                                <BrandRegistNumInput type="text" placeholder="품번을 입력해주세요." />
                            </BrandRegistNoticeWrap>

                            <BrandRegistCheckWrap>
                                <BrandRegistCheckText>방식</BrandRegistCheckText>

                                <BrandRegistCheckContent>
                                    <BrandRegistCheckInputWrap>
                                        <BrandRegistCheck type="checkbox"/>
                                        <BrandRegistCheckLabel>대여</BrandRegistCheckLabel>
                                    </BrandRegistCheckInputWrap>

                                    <BrandRegistCheckInputWrap>
                                        <BrandRegistCheck type="checkbox"/>
                                        <BrandRegistCheckLabel>방식</BrandRegistCheckLabel>
                                    </BrandRegistCheckInputWrap>

                                    <BrandRegistCheckInputWrap>
                                        <BrandRegistCheck type="checkbox" />
                                        <BrandRegistCheckLabel>판매</BrandRegistCheckLabel>
                                    </BrandRegistCheckInputWrap>
                                </BrandRegistCheckContent>
                            </BrandRegistCheckWrap>

                            <BrandRegistNumWrap>
                                <BrandRegistNum>가격</BrandRegistNum>
                                <BrandRegistNumInput type="text" placeholder="310,000" />
                            </BrandRegistNumWrap>

                            <BrandRegistNumWrap>
                                <BrandRegistNum>수량</BrandRegistNum>
                                <BrandRegistNumInput type="text" placeholder="20" />
                            </BrandRegistNumWrap>

                            <BrandRegistNumWrap>
                                <BrandRegistNum>가격 (협찬,일)</BrandRegistNum>
                                <BrandRegistNumInput type="text" placeholder="310,000" />
                            </BrandRegistNumWrap>

                            <BrandRegistNumWrap>
                                <BrandRegistNum>수량</BrandRegistNum>
                                <BrandRegistNumInput type="text" placeholder="20" />
                            </BrandRegistNumWrap>

                            <BrandRegistNumWrap>
                                <BrandRegistNum>가격 (판매,개)</BrandRegistNum>
                                <BrandRegistNumInput type="text" placeholder="1,234,098" />
                            </BrandRegistNumWrap>

                            <BrandRegistSizeWrap>
                                
                                <BrandRegistSizeText>사이즈표 (상의)</BrandRegistSizeText>
                                    <BrandRegistSizeTable>
                                        <BrandRegistSizeInner>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSizeHead>어깨</BrandRegistSizeHead>
                                            </BrandRegistSizeContent>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSize>00</BrandRegistSize>
                                            </BrandRegistSizeContent>
                                        </BrandRegistSizeInner>

                                        <BrandRegistSizeInner>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSizeHead>가슴</BrandRegistSizeHead>
                                            </BrandRegistSizeContent>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSize>00</BrandRegistSize>
                                            </BrandRegistSizeContent>
                                        </BrandRegistSizeInner>

                                        <BrandRegistSizeInner>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSizeHead>총장</BrandRegistSizeHead>
                                            </BrandRegistSizeContent>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSize>00</BrandRegistSize>
                                            </BrandRegistSizeContent>
                                        </BrandRegistSizeInner>

                                        <BrandRegistSizeInner>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSizeHead>팔길이</BrandRegistSizeHead>
                                            </BrandRegistSizeContent>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSize>00</BrandRegistSize>
                                            </BrandRegistSizeContent>
                                        </BrandRegistSizeInner>

                                        <BrandRegistSizeInner>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSizeHead>허리</BrandRegistSizeHead>
                                            </BrandRegistSizeContent>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSize>00</BrandRegistSize>
                                            </BrandRegistSizeContent>
                                        </BrandRegistSizeInner>

                                        <BrandRegistSizeInner>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSizeHead>암홀</BrandRegistSizeHead>
                                            </BrandRegistSizeContent>
                                            <BrandRegistSizeContent>
                                                <BrandRegistSize>00</BrandRegistSize>
                                            </BrandRegistSizeContent>
                                        </BrandRegistSizeInner>

                                    </BrandRegistSizeTable>

                                        <BrandRegistNumWrap>
                                            <BrandRegistNum>컬러</BrandRegistNum>
                                            <BrandRegistNumInput type="text" placeholder="컬러를 입력해주세요." />
                                        </BrandRegistNumWrap>

                                        <BrandRegistImgWrap>
                                            <BrandRegistNum>이미지 (메인) </BrandRegistNum>
                                            <BrandRegistNumInput type="text" placeholder="1장을 업로드 해주세요." />
                                        </BrandRegistImgWrap>
                                        <BensEwfiaarImfBtnWrap>
                                            <BrandRegistImgBtn>업로드</BrandRegistImgBtn>
                                        </BensEwfiaarImfBtnWrap>

                                        <BrandRegistImgWrap>
                                            <BrandRegistNum>이미지 (서브) </BrandRegistNum>
                                            <BrandRegistNumInput type="text" placeholder="1장을 업로드 해주세요." />
                                        </BrandRegistImgWrap>
                                        <BensEwfiaarImfBtnWrap>
                                            <BrandRegistImgBtn>업로드</BrandRegistImgBtn>
                                        </BensEwfiaarImfBtnWrap>

                                        <BrandRegistTextWrap>
                                            <BrandRegistTextHead>설명</BrandRegistTextHead>
                                                <BrandRegistTextArea placeholder="설명을 입력해주세요."/>
                                        </BrandRegistTextWrap>

                                        <BrandRegistNumWrap>
                                            <BrandRegistNum>해시태그</BrandRegistNum>
                                            <BrandRegistHashInput type="text" placeholder="해시태그를 입력후 엔터로 추가해주세요." />
                                        </BrandRegistNumWrap>

                                        <BrandRegistHashWrap>
                                            <BrandRegistHashList>
                                                <BrandRegistHashText>#이지아</BrandRegistHashText>
                                            </BrandRegistHashList>
                                            <BrandRegistHashList>
                                                <BrandRegistHashText>#원피스</BrandRegistHashText>
                                            </BrandRegistHashList>
                                            <BrandRegistHashList>
                                                <BrandRegistHashText>#샌들</BrandRegistHashText>
                                            </BrandRegistHashList>
                                            <BrandRegistHashList>
                                                <BrandRegistHashText>#가죽 백</BrandRegistHashText>
                                            </BrandRegistHashList>
                                            <BrandRegistHashList>
                                                <BrandRegistHashText>#헤어클립</BrandRegistHashText>
                                            </BrandRegistHashList>
                                        </BrandRegistHashWrap>

                                        <BrandRegistInfoTextWrap>
                                            <BrandRegistInfoText>
                                            *임시저장을 하시면 등록 완료 여부와 상관없이 현 상태에서 <br/>
                                            등록 정보를 임시로 저장 후 원하실 때 수정 및 공개 하실 수 있습니다.  
                                            </BrandRegistInfoText>

                                            <BrandRegistInfoText>
                                            *”공개”로 설정 시 즉시 공개가 진행됩니다. <br/>
                                            *”비공개”로 설정 시 노출되지 않으며, 상태 변경에 따라 공개가 진행됩니다. <br/>
                                            *”오픈 예정”으로 설정 시 노출되지만, 설정하신 날짜까지 결제 <br/>
                                            또는 협찬 및 대여 신청 버튼이 비활성화 상태로 노출 됩니다.  
                                            </BrandRegistInfoText>
                                        </BrandRegistInfoTextWrap>

                                        <BrandRegistBtnWrap>
                                            <BrandRegistBtn type="button">임시 저장</BrandRegistBtn>
                                            <BrandRegistSaveBtn type="button">저장</BrandRegistSaveBtn>
                                        </BrandRegistBtnWrap>

                            </BrandRegistSizeWrap>
                        </BrandRegistForm>
                    </BrandRegistContentWrap>
                </BrandRegistWrap>

            </Mobile>
        </>
    )
}
const TestEl = styled.input`
    width: 648px;
    height: 53px;
    padding: 0 10px;
    margin-top: 10px;
    font-size: 16px;
    border: 1px solid #222;
    text-align: center;

    &::-webkit-calendar-picker-indicator {
    background:url(${calendar}) no-repeat center / contain; 
    color: #000;
    width: 20px;
    height: 20px;
    }
     /* 테블릿 */
   @media screen and (max-width: 1300px) {
     width: calc(100vw*(648/1300));
     height: calc(100vw*(53/1300));
     padding: 0 calc(100vw*(10/1300));
     font-size: calc(100vw*(16/1300));
     margin-top: calc(100vw*(10/1300));
 }
   /* 모바일 */
   @media screen and (max-width: 780px) {
     width: calc(100vw*(375/428));
     height: calc(100vw*(53/428));
     padding: 0 calc(100vw*(10/428));
     font-size: calc(100vw*(16/428));
     margin-top: calc(100vw*(10/428));
 }
`;
const BrandRegistDateInput = styled(TestEl).attrs(props => ({
    type: 'date',    
}))`
`

const BrandRegistWrap = styled.div`
    width: 100%;
`
const BrandRegistHead = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 141px;
    background-color: #f7f7f8;
    padding: 40px 0;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    height: calc(100vw*(141/1300));
    padding: calc(100vw*(40/1300)) 0;
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    height: calc(100vw*(108/428));
    padding: calc(100vw*(40/428)) 0;
}

`
const BrandRegistContentWrap = styled.div`
    position: relative;
    width: calc(100vw*(1300/1920));
    margin: 57px auto;
    padding-bottom: 153px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    margin: calc(100vw*(57/1300)) auto;
    padding-bottom: calc(100vw*(153/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    width: calc(100vw*(428/428));
    margin: calc(100vw*(57/428)) auto;
    padding-bottom: calc(100vw*(153/428));
}
`
const BrandText = styled.p`
    font-family: "Avenir";
    font-weight: 900;
    font-size: 39px;
    text-align: center;
    color: #1f1f1f;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(39/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    font-size: calc(100vw*(20/428));
}
`
const BrandTextImg = styled.div`
    width: 38px;
    height: 38px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(38/1300));
    height: calc(100vw*(38/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    width: calc(100vw*(38/428));
    height: calc(100vw*(38/428));
}
`
const BrandRegistPrevWrap = styled.div`
    display: flex;
    justify-content: space-between;
`   
const BradnRegistPrev = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    gap: calc(100vw*(25/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    gap: calc(100vw*(25/428));
}
`
const BrandRegistImg = styled.div`
    width: 8px;
    height: 16px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(8/1300));
    height: calc(100vw*(16/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    width: calc(100vw*(8/428));
    height: calc(100vw*(16/428));
}
`
const BrandRegistText = styled.span`
    font-family: Avenir;
    font-size: 25px;
    font-weight: 900;
    color: #1f1f1f;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(25/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    font-size: calc(100vw*(25/428));
}
`
const BrandRegistForm = styled.form`
    width: 648px;
    margin: 50px auto;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(648/1300));
    margin: calc(100vw*(50/1300)) auto;
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    width: calc(100vw*(375/428));
    margin: calc(100vw*(50/428)) auto;
}
`
const BrandRegistIdWrap = styled.div`
    border-bottom: 1px solid #404040;
    padding: 10px 0;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    padding: calc(100vw*(10/1300)) 0;
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    padding: calc(100vw*(10/428)) 0;
}
`
const BrandRegistId = styled.label`
    width: 50px;
    font-size: 18px;
    font-family: Avenir;
    color: #222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(50/1300));
    font-size: calc(100vw*(18/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    width: calc(100vw*(50/428));
    font-size: calc(100vw*(18/428));
}
`
const BrandRegistIdInput = styled.input`
    font-size: 16px;
    font-family: Avenir;
    padding-left: 100px;
    color: #d9d9d9;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(16/1300));
    padding-left: calc(100vw*(100/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    font-size: calc(100vw*(16/428));
    padding-left: calc(100vw*(50/428));
}
`
const BrandRegistNumWrap = styled.div`
    display: flex;
    border-bottom: 1px solid #404040;
    padding: 10px 0;
    margin-top: 30px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    padding: calc(100vw*(10/1300)) 0;
    margin-top: calc(100vw*(30/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    padding: calc(100vw*(10/428)) 0;
    margin-top: calc(100vw*(30/428));
}
`
const BrandRegistNum = styled.label`
    display: flex;
    width: 109px;
    font-size: 18px;
    font-family: Avenir;
    color: #222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(109/1300));
    font-size: calc(100vw*(18/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    width: calc(100vw*(109/428));
    font-size: calc(100vw*(18/428));
}
`
const BrandRegistNumInput = styled.input`
    font-size: 16px;
    font-family: Avenir;
    padding-left: 90px;
    color: #d9d9d9;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(16/1300));
    padding-left: calc(100vw*(90/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    font-size: calc(100vw*(16/428));
    padding-left: calc(100vw*(50/428));
}
` 
const BrandRegistDateWrap= styled.div`
    margin-top: 30px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    margin-top: calc(100vw*(30/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    margin-top: calc(100vw*(30/428));
}
`
const BrandRegistDate = styled.p`
    font-size: 18px;
    font-family: Avenir;
    color: #222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(18/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    font-size: calc(100vw*(18/428));
}
`
const BrandRegistValueWrap = styled.div`
    margin-top: 30px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    margin-top: calc(100vw*(30/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    margin-top: calc(100vw*(30/428));
}
`
const BrandRegistValue = styled.span`
    font-size: 18px;
    font-family: Avenir;
    color: #222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(18/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    font-size: calc(100vw*(18/428));
}
`
const BrandRegistValueSelect = styled.select`
    width: 648px;
    height: 53px;
    font-size: 16px;
    padding: 0 10px;
    text-align: center;
    color: #d9d9d9;
    margin-top: 5px;
    border: 1px solid #222;

    
    /* 테블릿 */
    @media screen and (max-width: 1300px) {
    width: calc(100vw*(648/1300));
    height: calc(100vw*(53/1300));
    padding: 0 calc(100vw*(10/1300));
    font-size: calc(100vw*(16/1300));
    margin-top: calc(100vw*(5/1300));
}
/* 모바일 */
@media screen and (max-width: 780px) {
    width: calc(100vw*(375/428));
    height: calc(100vw*(53/428));
    padding: 0 calc(100vw*(10/428));
    font-size: calc(100vw*(16/428));
    margin-top: calc(100vw*(5/428));
}

    & {
        -webkit-appearance:none; 
        appearance:none;
        background: url(${select}) no-repeat 97% 50%/ contain; 
        background-size: 10px;
    }

`
const BrandRegistNoticeWrap = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #404040;
    padding: 10px 0;
    margin-top: 30px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    padding: calc(100vw*(10/1300)) 0;
    margin-top: calc(100vw*(30/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    padding: calc(100vw*(10/428)) 0;
    margin-top: calc(100vw*(30/428));
}
`
const BrandRegistValueOption = styled.option`
`
const BrandRegistNotice = styled.div`
    margin-left: 10px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    margin-left: calc(100vw*(10/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    margin-left: calc(100vw*(10/428));
}
`
const BrandRegistCheckWrap = styled.div`
    margin-top: 30px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    margin-top: calc(100vw*(30/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    margin-top: calc(100vw*(30/428));
}
`
const BrandRegistCheckContent = styled.div`
    display: flex;
    margin-top: 30px;
    gap: 20%;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    margin-top: calc(100vw*(30/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    margin-top: calc(100vw*(30/428));
}
`
const BrandRegistCheckText = styled.p`
    font-family: Avenir;
    font-size: 18px;
    color: #222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(18/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    font-size: calc(100vw*(18/428));
}
`
const BrandRegistCheck = styled.input`
    width: 20px;
    height: 20px;
    margin-left: 5px;
    color: #222;
    background-color: #222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(20/1300));
    height: calc(100vw*(20/1300));
    margin-left: calc(100vw*(5/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    width: calc(100vw*(20/428));
    height: calc(100vw*(20/428));
    margin-left: calc(100vw*(5/428));
}
`

const BrandRegistCheckInputWrap = styled.div`
    display: flex;
    align-items: center;
`
const BrandRegistCheckLabel = styled.label`
    font-size: 16px;
    font-family: Avenir;
    color: #222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(16/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    font-size: calc(100vw*(16/428));
    padding-left: calc(100vw*(10/428));
}
`
const BrandRegistSizeWrap = styled.div`
    margin-top: 30px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    margin-top: calc(100vw*(30/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    margin-top: calc(100vw*(30/428));
}
`
const BrandRegistSizeText = styled.p`
    font-size: 18px;
    margin-bottom: 10px;
    font-family: Avenir;
    color: #222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(18/1300));
    margin-bottom: calc(100vw*(10/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    font-size: calc(100vw*(18/428));
    margin-bottom: calc(100vw*(10/428));
}
`
const BrandRegistSizeTable = styled.div`
    display: flex;
    gap: 7px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    gap: calc(100vw*(7/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    gap: calc(100vw*(7/428));
    flex-wrap: wrap;
    justify-content: center;
}
`
const BrandRegistSizeInner = styled.div`
    display: flex;
    flex-direction: column;

    /* 모바일 */
  @media screen and (max-width: 780px) {
    display: grid;
}
`
const BrandRegistSizeContent = styled.div`
    width: 102px;
    height: 53px;
    padding: 15px 0;
    text-align: center;
    border: 1px solid #222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(102/1300));
    height: calc(100vw*(53/1300));
    padding: calc(100vw*(15/1300)) 0;
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    width: calc(100vw*(102/428));
    height: calc(100vw*(53/428));
    padding: calc(100vw*(15/428)) 0;
}
`
const BrandRegistSizeHead = styled.p`
    font-size: 15px;
    font-family: Avenir;
    color: #222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(15/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    font-size: calc(100vw*(15/428));
}
`
const BrandRegistSize = styled.p`
    font-size: 15px;
    font-family: Avenir;
    color: #222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(15/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    font-size: calc(100vw*(15/428));
}
`
const BrandRegistImgWrap = styled.div`
    display: flex;
    position: relative;
    border-bottom: 1px solid #404040;
    padding: 10px 0;
    margin-top: 30px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    margin-top: calc(100vw*(30/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    margin-top: calc(100vw*(30/428));
}
`
const BensEwfiaarImfBtnWrap = styled.div`
    /* 모바일 */
  @media screen and (max-width: 780px) {
    text-align: right;
}
`
const BrandRegistImgBtn = styled.button`
    position: absolute;
    right: 0;
    bottom: 10px;
    width: 143px;
    height: 49px;
    font-size: 16px;
    color: #222222;
    border: 1px solid #222222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    bottom: calc(100vw*(10/1300));
    width: calc(100vw*(143/1300));
    height: calc(100vw*(49/1300));
    font-size: calc(100vw*(16/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    position: static;
    margin-top: calc(100vw*(10/428));
    bottom: calc(100vw*(10/428));
    width: calc(100vw*(143/428));
    height: calc(100vw*(49/428));
    font-size: calc(100vw*(16/428));
}
`
const BrandRegistTextWrap = styled.div`
    margin-top: 45px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    margin-top: calc(100vw*(45/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    margin-top: calc(100vw*(45ß/428));
}
`
const BrandRegistTextHead = styled.p`
    font-family: Avenir;
    font-size: 18px;
    color: #222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    margin-top: calc(100vw*(80/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    margin-top: calc(100vw*(50/428));
}
`
const BrandRegistTextArea = styled.textarea`
    width: 648px;
    height: 191px;
    font-size: 16px;
    padding: 10px 20px;
    color: #d9d9d9;
    margin-top: 10px;
    border: 1px solid #222;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(648/1300));
    height: calc(100vw*(191/1300));
    font-size: calc(100vw*(16/1300));
    padding: calc(100vw*(10/1300)) calc(100vw*(20/1300));
    margin-top: calc(100vw*(10/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    width: calc(100vw*(378/428));
    height: calc(100vw*(191/428));
    font-size: calc(100vw*(16/428));
    padding: calc(100vw*(10/428)) calc(100vw*(20/428));
    margin-top: calc(100vw*(10/428));
}
`
const BrandRegistHashInput = styled.input`
    width: 100%;
    font-size: 16px;
    font-family: Avenir;
    padding-left: 20px;
    color: #d9d9d9;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(16/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    font-size: calc(100vw*(16/428));
}
`
const BrandRegistHashWrap = styled.div`
    display: flex;
    position: relative;
    gap: 8px;
    margin-top: 21px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    gap: calc(100vw*(8/1300));
    margin-top: calc(100vw*(21/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    flex-wrap: wrap;
    gap: calc(100vw*(8/428));
    margin-top: calc(100vw*(21/428));
}
`
const BrandRegistHashText = styled.p`
    font-size: 14px;
    color: #000;
    text-align: center;

    &::after {
        content: url(${close});
        width: 8px;
        height: 8px;
        padding-left: 5px;
}

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(14/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    font-size: calc(100vw*(13/428));
}

`
const BrandRegistHashList = styled.div`
    width: 93px;
    height: 29px;
    padding: 3px 0px;
    border-radius: 41px;
    background-color: #f2f2f2;
    text-align: center;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(93/1300));
    height: calc(100vw*(29/1300));
    padding: calc(100vw*(3/1300)) 0;
    border-radius: calc(100vw*(41/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    width: calc(100vw*(93/428));
    height: calc(100vw*(29/428));
    padding: calc(100vw*(5/428)) 0;
    border-radius: calc(100vw*(41/428));
}
`
const BrandRegistInfoTextWrap = styled.div`
    margin-top: 70px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    margin-top: calc(100vw*(70/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    margin-top: calc(100vw*(70/428));
}
`
const BrandRegistInfoText = styled.p`
    font-size: 16px;
    color: #222;
    margin-top: 30px;
    font-family: Avenir;
    font-weight: 500;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    font-size: calc(100vw*(16/1300));
    margin-top: calc(100vw*(30/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    font-size: calc(100vw*(16/428));
    margin-top: calc(100vw*(30/428));
}
`
const BrandRegistBtnWrap = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 53px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    margin-top: calc(100vw*(53/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    margin-top: calc(100vw*(53/428));
}
`
const BrandRegistBtn = styled.button`
    width: 278px;
    height: 59px;
    border: 1px solid #222;
    font-size: 16px;
    text-align: center;
    color: #222;
    padding: 15px 0;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(278/1300));
    height: calc(100vw*(59/1300));
    font-size: calc(100vw*(16/1300));
    padding: calc(100vw*(15/1300)) 0;
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    width: calc(100vw*(185/428));
    height: calc(100vw*(59/428));
    font-size: calc(100vw*(16/428));
    padding: calc(100vw*(15/428)) 0;
}
`
const BrandRegistSaveBtn = styled.button`
    width: 278px;
    height: 59px;
    background-color: #222;
    font-size: 16px;
    text-align: center;
    color: #fff;
    padding: 15px 0;
    margin-left: 10px;

    /* 테블릿 */
  @media screen and (max-width: 1300px) {
    width: calc(100vw*(278/1300));
    height: calc(100vw*(59/1300));
    font-size: calc(100vw*(16/1300));
    padding: calc(100vw*(15/1300)) 0;
    margin-left: calc(100vw*(10/1300));
}
  /* 모바일 */
  @media screen and (max-width: 780px) {
    width: calc(100vw*(185/428));
    height: calc(100vw*(59/428));
    font-size: calc(100vw*(16/428));
    padding: calc(100vw*(15/428)) 0;
    margin-left: calc(100vw*(10/428));
}
`