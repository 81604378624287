import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { PC, Mobile } from "../mediaStlye";
import faker from "faker";
import styled from "styled-components";

import {
  ModalContainer,
  HeaderWrapper,
  Logo,
  SearchBox,
  SearchContainer,
  SearchInput,
  SearchButton,
  RecommendContainer,
  KeywordsContainer,
  MobileModalHeader,
  MobileSearchBox,
  MobileSearchBtn,
} from "./Header.elements";
import HeaderTop from "./HeaderTop";

import walaLogo from "../../../images/wala-logo-modal.png";
import menuBarModal from "../../../images/menu-bar-modal.png";
import search_icon from "../../../images/search_icon.png";

const dummyKeword = Array(4)
  .fill()
  .map((v) => (v = "# " + faker.commerce.productName()));

const dummyKeword2 = Array(4)
  .fill()
  .map((v) => (v = "# " + faker.commerce.product()));

const HeaderSearchModal = ({ setIsOpen }) => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [keywords, setKeywords] = useState([]);
  // const searchInputRef = useRef(null);

  const onHandleSubmit = (e) => {
    e.preventDefault();
    history.push(`/search/${searchValue}`);
    setIsOpen(false);
  };

  // useEffect(() => {
  //   searchInputRef.current.focus();
  // }, []);

  return (
    <>
      <PC>
        <ModalContainer search>
          <div
            className="background-search-modal"
            onClick={() => setIsOpen(false)}
          ></div>
          <HeaderWrapperPc>
            {/* <HeaderTop modal={true} /> */}

            {/* <Logo style={{ color: "#fff" }} onClick={() => history.push("/")}> */}
            {/* <img src={walaLogo} alt="WALA" /> */}
            {/* WALA */}
            {/* </Logo> */}

            <SearchContainer isScroll={false} modal={true}>
              <SearchBox modal={true} onSubmit={onHandleSubmit}>
                <div style={{ position: "relative" }}>
                  <SearchInput
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    isScroll={true}
                    placeholder={"검색어를 입력하세요"}
                  />

                  <SearchButton type="submit">
                    <img src={search_icon} />
                  </SearchButton>
                </div>
              </SearchBox>

              {/* <RecommendContainer>
                        <KeywordsContainer>
                            <h3>추천 검색어</h3>
                            {
                                dummyKeword.map((v, i) => {
                                    return <span>{v}</span>
                                })
                            }
                        </KeywordsContainer>

                        <KeywordsContainer>
                            <h3>연관 검색어</h3>
                            {
                                dummyKeword2.map((v, i) => {
                                    return <span>{v}</span>
                                })
                            }
                        </KeywordsContainer>                    
                    </RecommendContainer>                     */}
            </SearchContainer>
          </HeaderWrapperPc>
        </ModalContainer>
      </PC>

      <Mobile>
        <ModalContainer modal={true}>
          <div
            className="background-search-modal"
            onClick={() => setIsOpen(false)}
          ></div>
          <MobileModalHeader>
            <Logo onClick={() => history.push("/")}>
              {/* <img src={walaLogo} alt="WALA" /> */}
            </Logo>
          </MobileModalHeader>

          <MobileSearchBox as="form" modal={true} onSubmit={onHandleSubmit}>
            <SearchInput
              // ref={searchInputRef}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              isScroll={true}
              placeholder={"검색어를 입력하세요"}
            />
            <MobileSearchBtn as="button" type="submit" modal>
              <img src={search_icon} />
            </MobileSearchBtn>
          </MobileSearchBox>

          {/* <RecommendContainer>
                    <KeywordsContainer>
                        <h3>추천 검색어</h3>
                        {
                            dummyKeword.map((v, i) => {
                                return <span>{v}</span>
                            })
                        }
                    </KeywordsContainer>

                    <KeywordsContainer>
                        <h3>연관 검색어</h3>
                        {
                            dummyKeword2.map((v, i) => {
                                return <span>{v}</span>
                            })
                        }
                    </KeywordsContainer>                    
                </RecommendContainer> */}
        </ModalContainer>
      </Mobile>
    </>
  );
};

export default HeaderSearchModal;

const HeaderWrapperPc = styled.div`
  padding: calc(100vw * (30 / 1920));
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;

  @media ${(props) => props.theme.container} {
    box-sizing: border-box;
  }
`;
