import React from "react";
import styled from "styled-components";

export default function MyPageBottomModal({ textList }) {
  return (
    <Container>
      {textList.length > 0 && (
        <BottomInfoUl>
          {textList.map((bottomData) => {
            return (
              <BottomInfoList>
                <BottomInfoText>{bottomData}</BottomInfoText>
              </BottomInfoList>
            );
          })}
        </BottomInfoUl>
      )}
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 10px;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) calc(100vw * (10 / 1320));
  }
`;
const BottomInfoUl = styled.ul``;
const BottomInfoList = styled.li`
  display: flex;
  align-items: baseline;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
  &::before {
    content: "∙";
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (6 / 1320));
    }
  }
`;
const BottomInfoText = styled.p`
  line-height: 1.5;
  color: #777;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
`;
