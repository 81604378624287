import { useEffect } from "react";
import { Mobile, PC } from "../../../MediaQuery";

import Header from "../../component/common/Header";
import MyPageHeader from "../mypage/MyPageHeader";
import OrderCancel from "../../component/order/OrderCancel";

export default function OrderCancelPage() {
  return (
    <>
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader title={"주문취소"} prevBtn/>
      </Mobile>
      <OrderCancel/>
    </>
  );
}
