import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import serverController from '../../../server/serverController';
import { Mobile, PC } from '../../common/mediaStlye';

import {
    MyPageProfileBox,
    ProfileInfo,
    ProfileHeading,
    ProfileContents,
    ProfileGender,
    ContentDetail,
    ProfilePlusButton
} from './mypage.elements';

import womanIcon from '../../../images/signup/gender-woman.png';
import typeIcon1 from '../../../images/signup/mypage-type-1.png';
import typeIcon2 from '../../../images/signup/mypage-type-2.png';
import typeIcon3 from '../../../images/signup/mypage-type-3.png';
import typeIcon4 from '../../../images/signup/mypage-type-4.png';
import typeIcon5 from '../../../images/signup/mypage-type-5.png';
import searchEngineIcon from '../../../images/signup/search-engines-bro.png';
import { useHistory } from 'react-router-dom';

const dummyStyles = ["에슬레저룩", "유니섹스룩", "이지룩", "클래식룩",];
const typeBodys = [
    { title: '모래시계형', icon: typeIcon1 },
    { title: '타원형', icon: typeIcon2 },
    { title: '역삼각형', icon: typeIcon3 },
    { title: '직사각형', icon: typeIcon4 },
    { title: '삼각형', icon: typeIcon5 },
]

const MyPageUserProfileInfo = () => {
    const [myInfo, setMyInfo] = useState([]);
    const history = useHistory()

    const getIconByType = (type) => {
        switch (type) {
            case "모래시계형":
                return <img src={typeIcon1} alt="" />
            case "타원형":
                return <img src={typeIcon2} alt="" />
            case "역삼각형":
                return <img src={typeIcon3} alt="" />
            case "직사각형":
                return <img src={typeIcon4} alt="" />
            case "삼각형":
                return <img src={typeIcon5} alt="" />
            default:
                return;
        }
    }

    useEffect(() => {
        serverController.connectFetchController(`mypage`, "POST", null, function (res) {
            if (res.result == 1) {
                console.log(res.list)
                setMyInfo(res.list);
            }
        });
    }, []);

    return (
        <>
            <PC>
                <MyPageProfileBox>
                    <ProfileInfo>
                        <ProfileHeading first>성별</ProfileHeading>
                        <div>
                            <ProfileGender>
                                <em>{myInfo.mem_gender == 0 ? "M" : "W"}</em>
                            </ProfileGender>
                            <p>{myInfo.mem_gender == 0 ? "남성" : "여성"}</p>
                        </div>
                    </ProfileInfo>

                    <ProfileInfo>
                        <ProfileHeading>선호 스타일</ProfileHeading>
                        <ProfileContents>
                            {
                                myInfo.mem_style?.split(",").map((v, i) => <ContentDetail key={i}>{v}</ContentDetail>)
                            }
                        </ProfileContents>
                    </ProfileInfo>

                    <ProfileInfo>
                        <ProfileHeading>체형</ProfileHeading>
                        <ProfileContents>
                            {
                                myInfo.mem_fit?.split(",").map((v, i) => (
                                    <ContentDetail key={i}>
                                        {getIconByType(v)}{v}
                                    </ContentDetail>
                                ))
                            }
                        </ProfileContents>
                    </ProfileInfo>

                    <ProfileInfo>
                        <ProfileHeading>선호 색상</ProfileHeading>
                        <ProfileContents>
                            {
                                myInfo.mem_color?.split(",").map((v, i) => <ContentDetail key={i}>{v}</ContentDetail>)
                            }
                        </ProfileContents>
                    </ProfileInfo>

                    <ProfileInfo style={{ cursor: "pointer" }} onClick={() => history.push(`/step/normal/1`)}>
                        <div className="search-engine-container">
                            <div className="search-engine-link">
                                <img src={searchEngineIcon} />
                            </div>
                            <em>맞춤분석 다시 하러가기</em>
                        </div>
                    </ProfileInfo>

                </MyPageProfileBox>
            </PC>

            <Mobile>
                <div>
                    <MyPageProfileBox>
                        <ProfileInfo>
                            <ProfileHeading first>성별</ProfileHeading>
                            <ProfileContents>
                                <ContentDetail>
                                    {myInfo.mem_gender === 0 ? "남성" : "여성"}
                                </ContentDetail>
                            </ProfileContents>
                        </ProfileInfo>

                        <ProfileInfo>
                            <ProfileHeading>선호 스타일</ProfileHeading>
                            <ProfileContents>
                                {
                                    myInfo.mem_style?.split(",").map((v, i) => <ContentDetail key={i}>{v}</ContentDetail>)
                                }
                            </ProfileContents>
                        </ProfileInfo>

                        <ProfileInfo>
                            <ProfileHeading>체형</ProfileHeading>
                            <ProfileContents>
                                {
                                    myInfo.mem_fit?.split(",").map((v, i) => (
                                        <ContentDetail key={i}>
                                            {getIconByType(v)}{v}
                                        </ContentDetail>
                                    ))
                                }
                            </ProfileContents>
                        </ProfileInfo>

                        <ProfileInfo>
                            <ProfileHeading>선호 색상</ProfileHeading>
                            <ProfileContents>
                                {
                                    myInfo.mem_color?.split(",").map((v, i) => <ContentDetail key={i}>{v}</ContentDetail>)
                                }
                            </ProfileContents>
                        </ProfileInfo>
                    </MyPageProfileBox>
                </div>
            </Mobile>
        </>
    )
}

export default MyPageUserProfileInfo
