import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Layout,
    AuthIntroMessage,
} from '../auth.elements';
import {
    LoginIntroBtns,
    LoginButton
} from '../login/Login.elements';


const SignUpIntro = () => {
    const history = useHistory();

    return (
        <Container>
            <Layout>
                <AuthIntroMessage>
                    <h2>REGISTER</h2>
                    <p>WALA 회원가입을 하시면 다양한 서비스를 이용하실 수 있습니다.</p>
                </AuthIntroMessage>

                <LoginIntroBtns>
                    {/* <LoginButton primary onClick={() => history.push('/signup/normal')}>일반 회원</LoginButton> */}
                    <LoginButton onClick={() => history.push('/signup/brand')}>브랜드 회원</LoginButton>
                    <LoginButton onClick={() => history.push('/signup/stylist')}>스타일리스트 회원</LoginButton>
                </LoginIntroBtns>
            </Layout>
        </Container>
    )
}

export default SignUpIntro
