import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import serverController from '../../../server/serverController';

import {
    ModalContainer,
    QuestModalContents,
    QuestModalHeadings,    
    QuestCloseButton,
    QuestFormWrapper,
    QuestCategorys,
    QuestForm,
    QuestInput,
    QuestTextarea,
    QuestFile,
    QuestFileInput,
    QuestModalButton
} from './pop.elements';
import QuestionSuccessModal from './QuestionSuccessModal';

import ClipIcon from '../../../images/signup/iconmonstr-paperclip.png';
import styled from 'styled-components';

const QuestionModal = ({ setIsQuestModal}) => {
    const { prd_id } = useParams()
    const [isSuccess, setIsSuccess] = useState(false);
    const [title, setTitle] = useState("")
    const [contents, setContents] = useState("")    
    const [file, setFile] = useState(null)
    

    const postForm = () => {
        const formData = new FormData();
        formData.append("inq_title", title);
        formData.append("inq_content", contents);
        formData.append("prd_id", prd_id ? prd_id : 0);
        // formData.append("file", file);
        return formData;
    }
    console.log(prd_id)

    const requestQuestion = async () => {
        await serverController.connectFetchController(
            `inquiry/one-on-one`,
            "POST",
            postForm(),
            (res) => {
                console.log(res, "문의 등록 res");
                if (res?.result === 1) {
                    alert('문의 등록이 완료되었습니다.')
                    setIsQuestModal(false)
                }
            }
        )
    }

    const onHandleInput = (e) => {
        const { name, files } = e.target;
        if (name === "quest-title") {
            setTitle(e.target.value)
        } else if (name === "quest-content") {
            setContents(e.target.value);
        } else if (name === "quest-file") {          

           const data = files[0];
           setFile(data);
        }
    }

    const onHandleSubmit = e => {
        e.preventDefault();
        if (!title || !contents) {
            alert("제목 또는 내용을 입력해주세요");
            return;
        } else if (title && contents) {
            requestQuestion()
        }
    }

    return (
        <>
        <ModalContainer>
            <QuestModalContents>
                <QuestModalHeadings>문의 하기</QuestModalHeadings>

                <QuestCloseButton onClick={() => setIsQuestModal(false)} />

                <QuestFormWrapper>
                    <QuestCategorys>
                        <select>
                            {/* <option>일반 문의</option> */}
                            <option>상품 문의</option>
                        </select>
                    </QuestCategorys>

                    <QuestForm onSubmit={onHandleSubmit}>
                        <QuestInput 
                            name="quest-title"
                            placeholder="제목을 입력해주세요." 
                            value={title}
                            onChange={onHandleInput}
                            required
                        />
                        <QuestTextarea 
                            name="quest-content"
                            placeholder="내용을 입력해주세요." 
                            value={contents}
                            onChange={onHandleInput}
                            required
                        />
                        {/* <QuestFile> */}
                            {/* <QuestLabel>

                            </QuestLabel> */}
                            {/* <QuestFileInput 
                                name="quest-file"
                                placeholder="+ 첨부파일 추가" 
                                type="file"
                                onChange={onHandleInput}
                                // required
                            />
                            <img src={ClipIcon} alt="" /> */}
                        {/* </QuestFile> */}
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '60px'}}>
                            {/* 파일첨부 주석 풀면 marginTop 지우기! */}
                            <QuestModalButton as="button" type="submit">보내기</QuestModalButton>
                        </div>
                    </QuestForm>
                </QuestFormWrapper>
                
            </QuestModalContents>
        </ModalContainer>
        {isSuccess && <QuestionSuccessModal setIsQuestModal={setIsQuestModal} />}
        </>
    )
}

export default QuestionModal