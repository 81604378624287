import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from "styled-components";

import Modal from '../../../server/modal';
import serverController from '../../../server/serverController';

export default function AssociateModal({setIsAssociate}) {
    const [next, setNext] = useState(false)
    const [type, setType] = useState(0)
    const [compony, setCompony] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')

    console.log(compony)
    console.log(name)
    console.log(email)
    console.log(phone)
    console.log(title)
    console.log(content)

    useEffect(()=>{
        if(compony.length > 0 && name.length > 0 && email.length > 0 && phone.length > 0 && title.length > 0 && content.length > 0){
            setNext(true)
        }else{
            setNext(false)
        }
    },[compony,name,email,phone,title,content])

    const AssociateSubmit = () => {
        if(next){
            serverController.connectFetchController(`affiliation?affiliation_type=${type+1}&affiliation_name=${name}&affiliation_level=${compony}&affiliation_tel=${phone}&affiliation_email=${email}&affiliation_title=${title}&affiliation_content=${content}`,"POST",null,function(res){
                if(res.result == 1) {
                    alert('제휴문의를 완료하였습니다.')
                    setIsAssociate(false);
                    Modal.modalClosed()
                }
            });
        }else{
            alert('작성양식을 모두 채워주세요.')
        }
    }

    return (
        <Container>
            <ModalBox>
                <Title>제휴제안 내용</Title>
                <Row>
                    <SubTitle>제휴구분</SubTitle>
                    <Select name="Associate" onChange={(e) => setType(e.target.value)}>
                        <option value={0}>입점/제휴</option>
                        <option value={1}>투자</option>
                        <option value={2}>채용</option>
                        <option value={3}>홍보</option>
                    </Select>
                </Row>
                <Row>
                    <SubTitle>회사명</SubTitle>
                    <Input type="text" placeholder="회사명을 입력해주세요." value={compony} onChange={(e)=>{setCompony(e.target.value)}}/>
                </Row>
                <Row>
                    <SubTitle>담당자</SubTitle>
                    <Input type="text" placeholder="담당자를 입력해주세요." value={name} onChange={(e)=>{setName(e.target.value)}}/>
                </Row>
                <Row>
                    <SubTitle>이메일</SubTitle>
                    <Input type="email" placeholder="이메일을 입력해주세요." value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                </Row>
                <Row>
                    <SubTitle>연락처</SubTitle>
                    <Input type="tel" placeholder="연락처를 입력해주세요." value={phone} onChange={(e)=>{setPhone(e.target.value)}}/>
                </Row>
                <Row>
                    <SubTitle>제목</SubTitle>
                    <Input type="text" placeholder="제목을 입력해주세요." value={title} onChange={(e)=>{setTitle(e.target.value)}}/>
                </Row>
                <Row>
                    <SubTitle>내용</SubTitle>
                    <Textarea name="" id="" placeholder="내용을 입력해주세요." value={content} onChange={(e)=>{setContent(e.target.value)}}/>
                </Row>
                <BtnBox>
                    <Btn onClick={()=>{setIsAssociate(false); Modal.modalClosed()}}>취소</Btn>
                    <Btn onClick={AssociateSubmit}>확인</Btn>
                </BtnBox>
            </ModalBox>
        </Container>
    );
}

const Container = styled.div`
    position: fixed; left: 0; top: 0; bottom: 0; right: 0;
    width: 100%; height: 100%;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.3);
`
const Title = styled.p`
    font-size: calc(100vw*(30/1920));
    text-align: center;
    font-weight: 900;
    padding-bottom: calc(100vw*(40/1920));
    
    @media screen and (max-width: 1024px) {
        font-size: calc(100vw*(24/428));
        padding-bottom: calc(100vw*(30/428));
    }
`
const ModalBox = styled.div`
    width: calc(100vw*(900/1920)); max-height: 85vh;
    padding: calc(100vw*(40/1920)) calc(100vw*(100/1920));
    position: relative;
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.4);
    overflow-y: auto;
    
    @media screen and (max-width: 1024px) {
        width: calc(100vw*(380/428));
        padding: calc(100vw*(25/428)) calc(100vw*(18/428));
    }
`
const Row = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: calc(100vw*(12/1920)) calc(100vw*(20/1920));
    border-bottom: 1px solid #000;

    @media screen and (max-width: 1024px) {
        padding: calc(100vw*(10/428));
    }
`
const SubTitle = styled.p`
    width: calc(100vw*(140/1920)); height: calc(100vw*(40/1920));
    font-size: calc(100vw*(18/1920));
    line-height: calc(100vw*(43/1920));
    
    @media screen and (max-width: 1024px) {
        font-size: calc(100vw*(15/428));
        width: calc(100vw*(90/428)); height: calc(100vw*(35/428));
        line-height: calc(100vw*(38/428));
    }
`
const Select = styled.select`
    width: calc(100% - calc(100vw*(140/1920))); height: calc(100vw*(40/1920));
    font-size: calc(100vw*(16/1920));
    
    @media screen and (max-width: 1024px) {
        width: calc(100% - calc(100vw*(140/428))); height: calc(100vw*(35/428));
        font-size: calc(100vw*(14/428));
    }
`
const Input = styled.input`
    width: calc(100% - calc(100vw*(140/1920))); height: calc(100vw*(40/1920));
    font-size: calc(100vw*(16/1920));
    &::placeholder{color: #bbbbbb; font-size: calc(100vw*(15/1920));}
    
    @media screen and (max-width: 1024px) {
        width: calc(100% - calc(100vw*(140/428))); height: calc(100vw*(35/428));
        font-size: calc(100vw*(14/428));
        &::placeholder{color: #bbbbbb; font-size: calc(100vw*(14/428));}
    }
`
const Textarea = styled.textarea`
    width: calc(100% - calc(100vw*(140/1920))); height: calc(100vw*(150/1920));
    font-size: calc(100vw*(16/1920));
    border: none;
    &::placeholder{color: #bbbbbb;}
    
    @media screen and (max-width: 1024px) {
        width: calc(100% - calc(100vw*(140/428))); height: calc(100vw*(100/428));
        font-size: calc(100vw*(15/428));
    }
`
const BtnBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    @media screen and (max-width: 1024px) {
    }
`
const Btn = styled.p`
    width: calc(100vw*(140/1920));
    font-size: calc(100vw*(16/1920));
    text-align: center;
    padding: calc(100vw*(8/1920)) 0;
    margin: calc(100vw*(20/1920)) calc(100vw*(8/1920));
    background: #222;
    color: #fff;
    box-sizing: border-box;
    border: 1px solid #222;
    cursor: pointer;
    &:first-child {background: #fff; color: #222;}
    
    @media screen and (max-width: 1024px) {
        width: calc(100vw*(120/428));
        font-size: calc(100vw*(15/428));
        padding: calc(100vw*(8/428)) 0;
        margin: calc(100vw*(20/428)) calc(100vw*(6/428));
    }
`