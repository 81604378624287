//react
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { loadTossPayments } from "@tosspayments/sdk";
import { Mobile, PC } from "../../../MediaQuery";

//css
import styled from "styled-components";

// controller
import serverController from "../../../server/serverController";
import Commas from "../../../server/Commas";

//img
import IconSelectArrow from "../../../img/icon/icon_bottom_arrow.svg";
import IconChkOn from "../../../img/icon/icon_chk_on.svg";
import IconChkOff from "../../../img/icon/icon_chk_off.svg";
import IconPage from "../../../img/icon/icon_page_button.svg";

// component
import { PaymentAction } from "../../../../store/actionCreators";
import PayAddressModal from "../../common/modal/PayAdressModal";
import TermsModal from "../../common/modal/TermsModal";
import { imgURL } from "../../../server/appInfoController";
import axios from "axios";
import { ip } from "../../../server/serverController"

// term modal data
import sktPassAgreeText from "./SktPassAgreeText";
import privateGuideText from "./PrivateGuideText";
import termText from "./TermText";

// message options
const optionList = [
  "배송시 요청사항을 선택해주세요.",
  "부재시 문앞에 놓아주세요.",
  "부재시 경비실에 맡겨 주세요.",
  "부재시 전화 또는 문자 주세요.",
  "택배함에 넣어 주세요.",
  "파손 위험 상품입니다. 배송시 주의해 주세요.",
  "배송 전에 연락주세요.",
];

export default function PayMain() {
  const { location } = useSelector((state) => state.paymentData);
  const { userInfo } = useSelector((state) => state.userData);

  const clientKey = "live_ck_OEP59LybZ8BlZLQpA9WV6GYo7pRe"; // live key
  // const clientKey = "test_ck_d26DlbXAaV0BYWADWl48qY50Q9RB"; // test key

  // product toggle control
  const [isToggle, setIsToggle] = useState(false);

  // modal control
  const [isModal, setIsModal] = useState(false);
  const [termsModalShow, setTermsModalShow] = useState(false);
  const [isPrivateGuideShow, setIsPrivateGuideShow] = useState(false);
  const [isPassModalShow, setIsPassModalShow] = useState(false);
  const [isPassProduct, setIsPassProduct] = useState(false);

  // address
  const [addressTitle, setAddressTitle] = useState("");
  const [addressName, setAddressName] = useState("");
  const [addressPhone, setAddressPhone] = useState("");
  const [address, setAddress] = useState("");
  const [addressReceiver, setAddressReceiver] = useState(0);
  const [sameAddress, setSameAddress] = useState(false);

  // orderer Info
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [socialEmail, setSocialEmail] = useState("");

  // option
  const [selectedValue, setSelectedValue] = useState("배송시 요청사항을 선택해주세요.");

  // product item list
  const [productItemList, setProductItemList] = useState([]);

  // coupon info
  const [couponList, setCouponList] = useState([]);
  const [selectedCouponValue, setSelectedCouponValue] = useState("-1");

  // point info
  const [point, setPoint] = useState("0");
  const [allUserPoint, setAllUserPoint] = useState(0);
  const [userPoint, setUserPoint] = useState(0);
  const [finalAllPoint, setFinalAllPoint] = useState(0);

  const [isChkEventPrd, setIsChkEventPrd] = useState(true);

  // pay method
  const [method, setMethod] = useState("카드");

  // pay info section
  const [payPrdTitle, setPayPrdTitle] = useState("");
  const [finalPrice, setFinalPrice] = useState(0);
  const [couponPrice, setCouponPrice] = useState(0);
  const [priceInfo, setPriceInfo] = useState({
    prdListPrice: 0,
    productCount: 0,
    totalPrice: 0,
    originalPrice: 0,
    price: 0,
    optionPrice: 0,
    salePrice: 0,
  });

  // checked
  const [checkedV, setCheckedV] = useState(false);
  const [checkArr, setCheckArr] = useState(
    {
      ageAgree: false,
      termUseAgree: false,
      privateGuideAgree: false,
      sktPassAgree: false,
    }
  );

  useEffect(() => {

    const { ageAgree, termUseAgree, privateGuideAgree, sktPassAgree } = checkArr;
    if (isPassProduct) {
      if (ageAgree && termUseAgree && privateGuideAgree && sktPassAgree) { setCheckedV(true) }
      else { setCheckedV(false) };
    };
    if (!isPassProduct) {
      if (ageAgree && termUseAgree && privateGuideAgree) { setCheckedV(true) }
      else { setCheckedV(false) };
    };
  }, [checkArr]);

  const onChangeCheckedV = () => {
    if (isPassProduct && !checkedV) {
      setCheckArr((prev) => ({
        ...prev,
        ageAgree: true,
        termUseAgree: true,
        privateGuideAgree: true,
        sktPassAgree: true,
      }))
    }
    if (isPassProduct && checkedV) {
      setCheckArr((prev) => ({
        ...prev,
        ageAgree: false,
        termUseAgree: false,
        privateGuideAgree: false,
        sktPassAgree: false,
      }))
    }
    if (!isPassProduct && !checkedV) {
      setCheckArr((prev) => ({
        ...prev,
        ageAgree: true,
        termUseAgree: true,
        privateGuideAgree: true,
      }))
    }
    if (!isPassProduct && checkedV) {
      setCheckArr((prev) => ({
        ...prev,
        ageAgree: false,
        termUseAgree: false,
        privateGuideAgree: false,
      }))
    }
  }

  // final pay fetch click state
  let isClick = useRef(false);

  //get mypage user info
  const getMypageUserInfo = () => {
    serverController.connectFetchController(
      `mypage`,
      "POST",
      null,
      (res) => {
        if (res?.result === 1) {
          setUserPoint(res.list.mem_point);
          setAllUserPoint(res.list.mem_point);
          return;
        };

      },
      (err) => console.error(err)
    );
  };

  // get coupon list data
  const getCoupon = () => {
    serverController.connectFetchController(`coupon/list?type=0`, "GET", null, (res) => {
      if (res?.result === 1) {
        const couponArr = [...res?.coupon_list];
        const usefulArr = [];
        couponArr.map((prev) => {
          if (prev.coupon_limit <= priceInfo.prdListPrice) {
            if (prev.coupon_type === "percent") {
              usefulArr.push(prev);
            } else {
              if (prev.coupon_discount <= priceInfo.prdListPrice * 0.5) {
                usefulArr.push(prev);
              }
            }
          }
        });
        setCouponList(usefulArr);
      }
    });
  };

  const chkEventPrd = () => {
    const cartItemId = [];
    for (const i in productItemList) {
      const cartItem = JSON.parse(productItemList[i].cart_items);
      const prdId = cartItem.prd_id;
      cartItemId.push({ "prdId": prdId });
    };
    axios.post(`${ip}payment/validation/benefit`, cartItemId)
      .then((res) => {
        //false일떄 나옴
        res.data.result == 0 && setIsChkEventPrd(true);
        res.data.result == 1 && setIsChkEventPrd(false);
      })
      .catch((err) => {
        console.log(err);
      })
  };

  // get location data items
  const getReceiversInfo = () => {
    serverController.connectFetchController(`receivers`, "GET", null, (res) => {
      if (res.result === 1) {
        const item = res?.list[0];
        PaymentAction.saveLocationInfo({
          ...item,
        });
      }
    });
  };

  // get cart price
  const setCartPrice = (option) => {
    let price = 0;
    let optionPrice = 0;
    let salePrice = 0;
    let totalPrice = 0;
    let prdListPrice = 0;
    let originalPrice = 0;
    let addPoint = 0;
    let options = option.data;

    for (let j = 0; j < options.length; j++) {
      let p = 0;
      let ogp = 0;
      let op = 0;
      let sp = 0;
      let pt = 0;
      let pl = 0;
      let optionList = options[j].options.optionList;

      for (let calKey in optionList) {
        for (let z = 0; z < optionList[calKey].length; z++) {
          op += optionList[calKey][z].opt_price;
        }
      }

      if (options[j].options.isOptional == false) {
        if (options[j].prd_price == 0) {
          ogp += options[j].prd_list_price;
          p += options[j].prd_list_price;
        } else {
          ogp += options[j].prd_price;
          sp += options[j].sale_price;
          p += Math.round(
            options[j].prd_price - (options[j].prd_price * options[j].prd_sale_rate) / 100
          );
        }
        pt += options[j].prd_earn_price;
        pl += options[j].prd_list_price;
      }

      originalPrice += ogp * options[j].count;
      price += p * options[j].count;
      optionPrice += op * options[j].count;
      salePrice += sp * options[j].count;
      totalPrice += (p + op) * options[j].count;
      addPoint += pt * options[j].count;
      prdListPrice += (pl + op) * options[j].count;
    }

    return {
      prdListPrice: prdListPrice,
      totalPrice: totalPrice,
      originalPrice: originalPrice,
      price: price,
      optionPrice: optionPrice,
      salePrice: salePrice,
      addPoint: addPoint || 0,
    };
  };

  // get total price
  const setTotalPrice = () => {
    let productInfoTemp = {
      prdListPrice: 0,
      productCount: 0,
      totalPrice: 0,
      originalPrice: 0,
      price: 0,
      optionPrice: 0,
      salePrice: 0,
      addPoint: 0,
    };

    productItemList.map((value) => {
      let cart_items = JSON.parse(value.cart_items);
      let info = setCartPrice(cart_items);

      setPayPrdTitle(cart_items.prd_title);
      //   setPayPrdId(cart_items.prd_id)
      productInfoTemp.totalPrice += info.totalPrice;
      productInfoTemp.originalPrice += info.originalPrice;
      productInfoTemp.price += info.price;
      productInfoTemp.optionPrice += info.optionPrice;
      productInfoTemp.salePrice += info.originalPrice - info.prdListPrice;
      productInfoTemp.addPoint += info.addPoint;
      productInfoTemp.prdListPrice += info.prdListPrice;
      productInfoTemp.productCount++;
    });
    setPriceInfo(productInfoTemp);
  };

  // set coupon list
  const setCouponInfo = (couponValue) => {
    if (couponValue === "-1") return;
    PaymentAction.saveCouponInfo({
      ...couponList[couponValue],
    });
  };

  useEffect(() => {
    if (!priceInfo) return;
    getCoupon();
  }, [priceInfo]);

  // save method
  useEffect(() => {
    PaymentAction.saveMethodInfo(method);
  }, [method]);

  // set location data
  useEffect(() => {
    if (!location) return;
    setAddressTitle(location?.receiver_title);
    setAddressName(location?.receiver_username);
    setAddressPhone(location?.receiver_phone);
    setAddress(`${location.receiver_address} ${location.receiver_address_detail}`);
    setAddressReceiver(location?.main_receiver);
  }, [location]);

  useEffect(() => {
    // set social email
    if (!userInfo) return;
    let userE = userInfo?.mem_email;
    let socialEmail = "";
    if (userE?.indexOf("google_") !== -1) {
      socialEmail = userE?.split("google_")[1];
    } else if (userE?.indexOf("apple_") !== -1) {
      socialEmail = userE?.split("apple_")[1];
    } else if (userE?.indexOf("naver_") !== -1) {
      socialEmail = userE?.split("naver_")[1];
    } else if (userE?.indexOf("kakao_") !== -1) {
      socialEmail = userE?.split("kakao_")[1];
    }
    // set user point
    setSocialEmail(socialEmail);
  }, [userInfo]);

  // set orderer data
  useEffect(() => {
    if (sameAddress) {
      setName(addressName);
      // if (socialEmail && socialEmail?.length > 0) {
      //   setEmail(socialEmail);
      // } else {
      //   setEmail(userInfo.mem_email);
      // }
      setPhone(addressPhone);
    } else {
      setName("");
      setEmail("");
      setPhone("");
    }
  }, [sameAddress]);

  // set product list data
  useEffect(() => {
    getMypageUserInfo();
    getReceiversInfo();
    setProductItemList(JSON.parse(localStorage.selectList ?? []));
  }, []);

  useEffect(() => {
    if (isModal === false && location?.receiver_username === "") {
      getReceiversInfo();
    }
  }, [isModal])

  useEffect(() => {
    getIncludePassPrd();
    if (productItemList[0] !== undefined) {
      setTotalPrice();
    }
    chkEventPrd();
  }, [productItemList]);

  // get coupon price
  useEffect(() => {
    if (couponList?.length !== 0 && selectedCouponValue != -1) {
      if (couponList[selectedCouponValue]?.coupon_type === "percent") {
        setCouponPrice(
          (priceInfo.prdListPrice / 100) * couponList[selectedCouponValue]?.coupon_discount ?? 0
        );
      } else {
        setCouponPrice(couponList[selectedCouponValue]?.coupon_discount);
      }
    } else {
      setCouponPrice(0);
    }
  }, [couponList, selectedCouponValue, priceInfo]);

  // point handle
  useEffect(() => {
    if (allUserPoint === 0) return;
    setUserPoint(allUserPoint - point);
    PaymentAction.savePoint({ points: point });
  }, [point, allUserPoint]);

  // set final price
  useEffect(() => {
    if (couponList[selectedCouponValue] !== undefined) {
      if (couponList[selectedCouponValue].coupon_type === "percent") {
        setFinalPrice(
          priceInfo.prdListPrice -
          ((priceInfo.prdListPrice * 1) / 100) *
          couponList[selectedCouponValue]?.coupon_discount -
          point || 0
        );
        setFinalAllPoint(
          priceInfo.prdListPrice -
          ((priceInfo.prdListPrice * 1) / 100) * couponList[selectedCouponValue]?.coupon_discount
        );
      } else {
        setFinalPrice(
          priceInfo.prdListPrice - couponList[selectedCouponValue]?.coupon_discount - point
        );
        setFinalAllPoint(priceInfo.prdListPrice - couponList[selectedCouponValue]?.coupon_discount);
      }
    } else {
      setFinalPrice(priceInfo.prdListPrice - point);
      setFinalAllPoint(priceInfo.prdListPrice);
    }
  }, [point, couponList, selectedCouponValue, priceInfo]);

  // random string func for orderId
  const randomStringFunc = () => {
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXTZ";
    let randomString = "";
    for (let i = 0; i < 10; i++) {
      const randomNum = Math.floor(Math.random() * chars.length);
      randomString += chars.substring(randomNum, randomNum + 1);
    }
    return randomString;
  };

  const getIsInventory = async (pc) => {
    for (const i in productItemList) {
      const cartItem = JSON.parse(productItemList[i].cart_items);
      const cartItemCount = cartItem.data[0].count;
      const options = cartItem.data[0].options;
      const optId = options.optId[options.optId.length - 1];

      let result = await serverController.connectFetchController(
        `product/${optId}/inventory?count=${cartItemCount}`,
        "GET",
        null);

      if (result.result !== 1) return alert("일시적인 오류입니다. 다시 시도해주세요.");
      if (result.isItPossible === "Y") {

      }
      else {
        alert(`상품명 '${cartItem.prd_title}'의 재고 보유 수량이 부족합니다. 해당 상품을 제외하고 결제를 진행해주세요.`);
        return;
      }
    }
    PaymentFetch(pc);
  };

  // skt pass product include
  const getPrdList = () => {
    const prdList = []
    for (const i in productItemList) {
      const cartItem = JSON.parse(productItemList[i].cart_items);
      prdList.push(cartItem?.prd_id);
    }
    return prdList;
  }

  const getIncludePassPrd = async () => {
    const prdList = getPrdList();
    if (prdList.length === 0) return;
    let result = await serverController.connectFetchController(
      `products/privacy-policy?prdList=${prdList.join(',')}`,
      "GET",
      null);
    if (result?.result === 1 && result?.checkResult === 1) {
      setIsPassProduct(true);
    }
  }


  // final pay fetch
  const PaymentFetch = (pc) => {
    //상품 품절일 때 브레이크로 반복문 빠져나감. 그리고 아래 함수 계속 실행.
    if (!location.postal_code || !location.receiver_address || !location.receiver_address_detail) {
      alert("배송지 정보를 등록해주세요.");
      return;
    };
    const chkEmail = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (name.length === 0 || email.length === 0 || phone.length === 0)
      return alert("주문자 정보를 모두 입력해주세요.");

    if (!chkEmail.test(email)) return alert("이메일을 양식에 맞게 입력해주세요.");

    if (!checkedV) return alert("필수 이용약관에 동의해주세요.");

    if (isClick.current) return alert("종료 후 다시 시도해주세요.");

    const formData = new FormData();
    const orderId = new Date().getTime() + randomStringFunc();
    const getPoint = (finalPrice * (1 / 100)).toFixed(0) * 1;

    let cartArr = [];
    for (let i in productItemList) {
      cartArr.push(JSON.parse(productItemList[i].cart_items));
    }

    formData.append("product_list", JSON.stringify(cartArr));
    formData.append("order_id", orderId);
    formData.append("receiver_name", name);
    formData.append("receiver_email", email);
    formData.append("receiver_phone", phone);
    formData.append("postal_code", location.postal_code);
    formData.append("receiver_zipcode", location?.receiver_zipcode);
    formData.append("receiver_address", location.receiver_address);
    formData.append("receiver_address_detail", location.receiver_address_detail);

    formData.append("total_price", finalPrice);
    formData.append("delivery_fee", 0);
    formData.append("used_point", point);
    formData.append("earn_point", getPoint);
    formData.append("coupon_discount", couponList[selectedCouponValue]?.coupon_discount ?? 0);
    if (couponList[selectedCouponValue]?.coupon_record_id !== undefined) {
      formData.append("coupon_record_id", couponList[selectedCouponValue]?.coupon_record_id);
    }
    if (selectedValue !== "배송시 요청사항을 선택해주세요.") {
      formData.append("receiver_request", selectedValue);
    };
    if (isPassProduct) {
      formData.append("privacyPolicy", 1);
    }
    formData.append("receiver_request", location.receiver_request);
    formData.append("all_price", finalPrice);

    serverController.connectFetchController(
      `payment/request-validate`,
      "POST",
      formData,
      (res) => {
        if (res?.result === 1) {
          payToss(orderId, finalPrice, pc);
        }
        isClick.current = true;
      },
      (err) => {
        isClick.current = true;
      }
    );
  };

  // toss payments
  const payToss = async (orderId, totalCount, pc) => {
    const tossPayments = await loadTossPayments(clientKey);

    tossPayments.requestPayment(method, {
      amount: totalCount,
      orderId: orderId,
      orderName: payPrdTitle,
      customerName: name,
      mobilePhone: pc ? null : true,
      successUrl: `https://wala.land:8071/payment/success`,
      failUrl: "https://wala.land/store/mypage/return/complete",
      appScheme: "walaland://app",
    });
  };

  return (
    <Container>
      <PC>
        <PageListBox>
          <PageList>
            <PageText>장바구니</PageText>
          </PageList>
          <PageList className="active">
            <PageText>주문/결제</PageText>
          </PageList>
          <PageList>
            <PageText>결제완료</PageText>
          </PageList>
        </PageListBox>
      </PC>

      <ListWrap>
        <PayLeftWrap>
          <PayLeftArea>
            <ListArea>
              <ListBox>
                <TitleWrap>
                  <Title>배송지 정보</Title>
                  <AddressChangeButton
                    type="button"
                    onClick={() => {
                      setIsModal(true);
                      setSameAddress(false);
                    }}
                  >
                    변경
                  </AddressChangeButton>
                </TitleWrap>
                <PayInfoWrap>
                  {addressTitle.length === 0 && (
                    <AddressList>
                      <AddressTitleWrap>
                        <AddressText>등록된 배송지가 없습니다. 배송지를 추가해주세요.</AddressText>
                      </AddressTitleWrap>
                    </AddressList>
                  )}
                  <AddressList>
                    <AddressTitleWrap>
                      <AddressTitle>{addressTitle}</AddressTitle>
                      {addressReceiver === 1 && <AddressDefault>기본배송지</AddressDefault>}
                    </AddressTitleWrap>
                    <AddressText>{address}</AddressText>
                    <AddressName>{`${addressName} ${addressPhone}`}</AddressName>
                  </AddressList>
                  <InputList>
                    <PC>
                      <InputText>배송시 요청사항</InputText>
                    </PC>
                    <SelectButton
                      borderActive={selectedValue !== "배송시 요청사항을 선택해주세요."}
                      onChange={(e) => {
                        setSelectedValue(e.target.value);
                      }}
                    >
                      {optionList.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </SelectButton>
                  </InputList>
                </PayInfoWrap>
              </ListBox>
              <ListBox>
                <TitleWrap>
                  <Title>주문자 정보</Title>
                  <ChkBox>
                    <ChkInput
                      type="checkbox"
                      id="saveChk"
                      checked={sameAddress}
                      onChange={() => {
                        if (!location.postal_code || !location.receiver_address || !location.receiver_address_detail) {
                          alert("배송지 정보를 등록해주세요.");
                          return;
                        }
                        setSameAddress(!sameAddress);
                      }
                      }
                    />
                    <ChkLabel htmlFor="saveChk">
                      <ChkImg className="chk-img" />
                      <ChkText>배송지 정보와 동일</ChkText>
                    </ChkLabel>
                  </ChkBox>
                </TitleWrap>
                <PayInfoWrap>
                  <InputList>
                    <InputText>
                      이름<TitleMark>*</TitleMark>
                    </InputText>
                    <Input
                      type="text"
                      value={name}
                      active={name.length > 0}
                      placeholder="이름을 입력해주세요."
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </InputList>
                  <InputList>
                    <InputText>
                      이메일<TitleMark>*</TitleMark>
                    </InputText>
                    <Input
                      type="text"
                      value={email}
                      active={email?.length > 0}
                      placeholder="이메일을 입력해주세요."
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </InputList>
                  <InputList>
                    <InputText>
                      휴대폰번호<TitleMark>*</TitleMark>
                    </InputText>
                    <Input
                      type="text"
                      value={phone}
                      active={phone?.length > 0}
                      placeholder="-를 제외한 숫자만 입력해주세요."
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </InputList>
                </PayInfoWrap>
              </ListBox>
              {/* 주문상품 - productItemList */}
              <ListBox>
                <TitleWrap onClick={() => setIsToggle(!isToggle)}>
                  <Title>주문상품</Title>
                  <Mobile>
                    <ArrowImgBox BtnOn={isToggle}>
                      <img src={IconSelectArrow} alt="arrow" />
                    </ArrowImgBox>
                  </Mobile>
                </TitleWrap>
                <PC>
                  <ProductListWrap>
                    {productItemList.map((productItem) => {
                      const cartItem = JSON.parse(productItem.cart_items);

                      return (
                        <ProductListArea key={cartItem.prd_id}>
                          <ProductBoxWrap>
                            <ProductBoxList>
                              <ProductInfo>
                                <ProductImgBox>
                                  <img
                                    src={
                                      cartItem?.prd_img?.split(",")?.length > 0
                                        ? `${imgURL}${cartItem?.prd_img?.split(",")[0]}`
                                        : `${imgURL}${cartItem?.prd_img}`
                                    }
                                    alt="product-img"
                                  />
                                </ProductImgBox>
                                <ProductTextBox>
                                  <ProductName>{cartItem.prd_title}</ProductName>
                                  {cartItem.data.length > 0 &&
                                    cartItem.data.map((optionListV) => {
                                      let optionArr = [];
                                      for (let i in optionListV.options.optionList) {
                                        optionArr.push(optionListV.options.optionList[i]);
                                      }
                                      return (
                                        <>
                                          {optionArr[0]?.length > 0 &&
                                            optionArr[0]?.map((optionItem) => {
                                              return (
                                                <ProductColor key={optionItem.opt_id}>
                                                  {`${optionItem.opt_name} / `}
                                                </ProductColor>
                                              );
                                            })}
                                          <ProductColor>
                                            {`${optionListV.count}개`}
                                          </ProductColor>
                                        </>
                                      );
                                    })}

                                  <ProductPriceWrap>
                                    <ProductPriceInner>
                                      {cartItem.prd_sale_rate !== 0 &&
                                        <ProductSaleRate>
                                          {`${cartItem.prd_sale_rate}%`}
                                        </ProductSaleRate>
                                      }
                                      <ProductPrice>
                                        {Commas.numberWithCommas(cartItem?.prd_list_price)}원
                                      </ProductPrice>
                                    </ProductPriceInner>
                                  </ProductPriceWrap>
                                </ProductTextBox>
                              </ProductInfo>
                            </ProductBoxList>
                          </ProductBoxWrap>
                        </ProductListArea>
                      );
                    })}
                  </ProductListWrap>
                </PC>
                <Mobile>
                  {isToggle && (
                    <ProductListWrap>
                      {productItemList.map((productItem) => {
                        const cartItem = JSON.parse(productItem.cart_items);
                        return (
                          <ProductListArea key={cartItem.prd_id}>
                            <ProductBoxWrap>
                              <ProductBoxList>
                                <ProductInfo>
                                  <ProductImgBox>
                                    <img
                                      src={
                                        cartItem?.prd_img?.split(",")?.length > 0
                                          ? `${imgURL}${cartItem.prd_img.split(",")[0]}`
                                          : `${imgURL}${cartItem.prd_img}`
                                      }
                                      alt="product-img"
                                    />
                                  </ProductImgBox>
                                  <ProductTextBox>
                                    <ProductName>{cartItem.prd_title}</ProductName>
                                    {cartItem.data.length > 0 &&
                                      cartItem.data.map((optionListV) => {
                                        let optionArr = [];
                                        for (let i in optionListV.options.optionList) {
                                          optionArr.push(optionListV.options.optionList[i]);
                                        }
                                        return (
                                          <>
                                            {optionArr[0]?.length > 0 &&
                                              optionArr[0]?.map((optionItem) => {
                                                return (
                                                  <ProductColor key={optionItem.opt_id}>
                                                    {`${optionItem.opt_name} / `}
                                                  </ProductColor>
                                                );
                                              })}
                                            <ProductColor>
                                              {`${optionListV.count}개`}
                                            </ProductColor>
                                          </>
                                        );
                                      })}

                                    <ProductPriceWrap>
                                      <ProductPriceInner>
                                        {cartItem.prd_sale_rate !== 0 && (
                                          <ProductSaleRate>
                                            {`${cartItem.prd_sale_rate}%`}
                                          </ProductSaleRate>
                                        )}
                                        <ProductPrice>
                                          {Commas.numberWithCommas(cartItem?.prd_list_price)}원
                                        </ProductPrice>
                                      </ProductPriceInner>
                                    </ProductPriceWrap>
                                  </ProductTextBox>
                                </ProductInfo>
                              </ProductBoxList>
                            </ProductBoxWrap>
                          </ProductListArea>
                        );
                      })}
                    </ProductListWrap>
                  )}
                </Mobile>
              </ListBox>
              {couponList?.length > 0 && isChkEventPrd === false ? (
                <ListBox>
                  <TitleWrap>
                    <Title>쿠폰</Title>
                    <SubText>보유 쿠폰 {couponList?.length}장</SubText>
                  </TitleWrap>
                  <PayInfoWrap>
                    <SelectButton
                      onChange={(e) => {
                        setSelectedCouponValue(e.target.value);
                        setCouponInfo(e.target.value);
                        setPoint(0);
                      }}
                      borderActive={selectedCouponValue !== "-1"}
                    >
                      <option value="-1">선택안함</option>
                      {couponList.map((couponItem, index) => {
                        return (
                          <option key={`coupon-${couponItem.coupon_record_id}`} value={index}>
                            {couponItem.coupon_title +
                              " " +
                              Commas.numberWithCommas(couponItem.coupon_discount ?? 0)}
                            {couponItem.coupon_type === "percent" ? "%" : "원"}
                          </option>
                        );
                      })}
                    </SelectButton>
                  </PayInfoWrap>
                </ListBox>
              ) : (null)}
              {/* 포인트 - point */}
              {isChkEventPrd === false && (
                <ListBox>
                  <TitleWrap>
                    <Title>포인트</Title>
                    <SubText>
                      사용 가능 포인트{" "}
                      <PointText>{Commas.numberWithCommas(userPoint ?? 0)} P</PointText>
                    </SubText>
                  </TitleWrap>
                  <PayInfoWrap>
                    <InputList>
                      <PC>
                        <InputText>포인트 사용</InputText>
                      </PC>
                      <InputFlex>
                        <Input
                          type="number"
                          value={point}
                          active={point !== "0"}
                          onChange={(e) => {
                            if (allUserPoint * 1 === 0) return;
                            if (allUserPoint < priceInfo.prdListPrice - couponPrice) {
                              if (e.target.value > allUserPoint) {
                                return setPoint(allUserPoint);
                              } else {
                                return setPoint(e.target.value);
                              }
                            } else {
                              if (e.target.value > (priceInfo.prdListPrice - couponPrice) * 0.5) {
                                return setPoint(
                                  priceInfo.prdListPrice - couponPrice - finalAllPoint * 0.5
                                );
                              } else {
                                return setPoint(e.target.value);
                              }
                            }
                          }}
                        />
                        <PointUseAllButton
                          type="button"
                          onClick={() => {
                            if (allUserPoint * 1 > finalAllPoint * 1 - finalAllPoint * 0.5) {
                              setPoint((finalAllPoint - finalAllPoint * 0.5).toFixed());
                            } else {
                              setPoint(allUserPoint.toFixed());
                            }
                          }}
                        >
                          모두 사용
                        </PointUseAllButton>
                      </InputFlex>
                    </InputList>
                  </PayInfoWrap>
                </ListBox>
              )}
              <ListBox>
                <TitleWrap>
                  <Title>결제수단</Title>
                </TitleWrap>
                <PayInfoWrap>
                  <PaymentButtonWrap>
                    <PaymentButton
                      type="button"
                      BtnOn={method === "카드"}
                      onClick={() => setMethod("카드")}
                    >
                      신용카드
                    </PaymentButton>
                    {/* <PaymentButton
                      type="button"
                      BtnOn={method === "가상계좌"}
                      onClick={() => setMethod("가상계좌")}
                    >
                      무통장입금
                    </PaymentButton> */}
                  </PaymentButtonWrap>
                </PayInfoWrap>
              </ListBox>
            </ListArea>
          </PayLeftArea>
        </PayLeftWrap>

        {/* pay right */}
        <PayRightWrap>
          <PayRightArea>
            {/* 결제정보 */}
            <PayRightInfo>
              <ListArea>
                <ListBox>
                  <TitleWrap>
                    <Title>결제정보</Title>
                  </TitleWrap>
                  <PayInfoWrap>
                    <PriceListWrap>
                      <PriceList>
                        <PriceListTitle>총 상품 금액</PriceListTitle>
                        <PriceListText>
                          {Commas.numberWithCommas(priceInfo.prdListPrice)}원
                        </PriceListText>
                      </PriceList>
                      {selectedCouponValue !== "-1" && (
                        <PriceList>
                          <PriceListTitle>쿠폰 사용</PriceListTitle>
                          <PriceListText>- {Commas.numberWithCommas(couponPrice)}원</PriceListText>
                        </PriceList>
                      )}
                      {point !== "0" && point !== "" && (
                        <PriceList>
                          <PriceListTitle>포인트 사용</PriceListTitle>
                          <PriceListText>- {Commas.numberWithCommas(point)}원</PriceListText>
                        </PriceList>
                      )}
                    </PriceListWrap>
                    <TotalPriceWrap>
                      <TotalPriceInner>
                        <TotalPriceTitle>총 결제금액</TotalPriceTitle>
                        <TotalPrice>
                          {Commas.numberWithCommas(
                            finalPrice
                            // + productItemList.length * 2500
                          )}
                          원
                        </TotalPrice>
                      </TotalPriceInner>
                      <TotalPoint>
                        {Commas.numberWithCommas((finalPrice * (1 / 100)).toFixed(0))}P 적립예정
                      </TotalPoint>
                      <TotalPoint>* 포인트는 최종 결제 금액의 50%까지 사용 가능합니다.</TotalPoint>
                    </TotalPriceWrap>
                  </PayInfoWrap>
                </ListBox>
              </ListArea>
            </PayRightInfo>
            <TermChkListArea>
              <TermChkListInner>
                <TermChkList>
                  <TermInput
                    type="checkbox"
                    id="check-All"
                    checked={checkedV}
                    onChange={onChangeCheckedV}
                  />
                  <TermLabel htmlFor="check-All">
                    <TermChk className="agree-check" />
                    <TermTextAll>[필수]아래 내용에 모두 동의합니다.</TermTextAll>
                  </TermLabel>
                </TermChkList>
              </TermChkListInner>

              <TermChkListInner>
                <TermChkList>
                  <TermInput
                    type="checkbox"
                    id="check-age"
                    checked={checkArr.ageAgree}
                    onChange={() => {
                      setCheckArr((prev) => ({
                        ...prev,
                        ageAgree: !prev.ageAgree
                      }))
                    }}
                  />
                  <TermLabel htmlFor="check-age">
                    <TermChk className="agree-check" />
                    <TermTextBtnWrap>
                      <TermText>[필수] 만 14세 이상입니다.</TermText>
                    </TermTextBtnWrap>
                  </TermLabel>
                </TermChkList>
                <TermChkList>
                  <TermInput
                    type="checkbox"
                    id="check-termuse"
                    checked={checkArr.termUseAgree}
                    onChange={() => {
                      setCheckArr((prev) => ({
                        ...prev,
                        termUseAgree: !prev.termUseAgree
                      }))
                    }}
                  />
                  <TermLabel htmlFor="check-termuse">
                    <TermChk className="agree-check" />
                    <TermTextBtnWrap>
                      <TermText Text>[필수] 개인정보 수집·이용동의</TermText>
                      <ViewButton type="button" onClick={() => setTermsModalShow(true)}>
                        보기
                      </ViewButton>
                    </TermTextBtnWrap>
                  </TermLabel>
                </TermChkList>
                <TermChkList>
                  <TermInput
                    type="checkbox"
                    id="check-private"
                    checked={checkArr.privateGuideAgree}
                    onChange={() => {
                      setCheckArr((prev) => ({
                        ...prev,
                        privateGuideAgree: !prev.privateGuideAgree
                      }))
                    }}
                  />
                  <TermLabel htmlFor="check-private">
                    <TermChk className="agree-check" />
                    <TermTextBtnWrap>
                      <TermText Text>[필수] 개인정보 처리 위탁 동의</TermText>
                      <ViewButton type="button" onClick={() => setIsPrivateGuideShow(true)}>
                        보기
                      </ViewButton>
                    </TermTextBtnWrap>
                  </TermLabel>
                </TermChkList>
                {isPassProduct && (
                  <TermChkList>
                    <TermInput
                      type="checkbox"
                      id="check-sktpass"
                      checked={checkArr.sktPassAgree}
                      onChange={() => {
                        setCheckArr((prev) => ({
                          ...prev,
                          sktPassAgree: !prev.sktPassAgree
                        }))
                      }}
                    />
                    <TermLabel htmlFor="check-sktpass">
                      <TermChk className="agree-check" />
                      <TermTextBtnWrap>
                        <TermText Text>[필수] PASS전용 상품 혜택지급을 위한<br />개인정보 제3자 제공동의</TermText>
                        <ViewButton type="button" onClick={() => setIsPassModalShow(true)}>
                          보기
                        </ViewButton>
                      </TermTextBtnWrap>
                    </TermLabel>
                  </TermChkList>
                )}
              </TermChkListInner>
            </TermChkListArea>
            <PC>
              <AllPaymentButton
                type="button"
                onClick={() => {
                  getIsInventory(true);
                }}
              >
                주문하기
              </AllPaymentButton>
            </PC>
            <Mobile>
              <AllPaymentButton
                type="button"
                onClick={() => {
                  getIsInventory(false);
                }}
              >
                <span>
                  {" "}
                  {Commas.numberWithCommas(
                    finalPrice
                    //  + productItemList.length * 2500
                  )}
                </span>
                원 결제하기
              </AllPaymentButton>
            </Mobile>
          </PayRightArea>
        </PayRightWrap>
      </ListWrap>

      {isModal && <PayAddressModal setIsModal={setIsModal} />}

      {termsModalShow && (
        <TermsModal title={"개인정보 수집·이용동의"} text={termText} setTermsModalShow={setTermsModalShow} />
      )}

      {isPrivateGuideShow && (
        <TermsModal title={"개인정보 처리 위탁 동의"} text={privateGuideText} setTermsModalShow={setIsPrivateGuideShow} />
      )}

      {isPassModalShow && (
        <TermsModal title={`PASS전용 상품 혜택 지급을 위한 개인정보\n제3자 제공 동의`} text={sktPassAgreeText} setTermsModalShow={setIsPassModalShow} />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 180px 0 275px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (275 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0 0 calc(100vw * (208 / 428));
  }
`;

const ListWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 30px auto 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    margin: calc(100vw * (30 / 1320)) auto 0;
  }
  @media screen and (max-width: 930px) {
    display: block;
    width: 100%;
    margin: 0;
  }
`;
const ListArea = styled.ul`
  width: 100%;
`;
const ListBox = styled.li`
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
    margin: 50px 0;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (50 / 1320)) 0;
    }
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0;
    border-bottom: calc(100vw * (10 / 428)) solid #f9f9f9;
    &:not(:last-child)::after {
      display: none;
    }
  }
`;
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  }
`;
const Title = styled.p`
  color: #333;
  font-weight: 700;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-weight: 500;
    font-size: calc(100vw * (16 / 428));
  }
`;
const TitleMark = styled.span`
  color: #ff006c;
`;
const PayInfoWrap = styled.div`
  margin: 30px auto 0;

  @media screen and (max-width: 1320px) {
    margin: calc(100vw * (30 / 1320)) auto 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (20 / 428)) auto 0;
  }
`;
// 배송지 정보
const AddressChangeButton = styled.button`
  color: #777;
  font-weight: 500;
  font-size: 15px;
  padding: 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const AddressList = styled.div`
  margin-bottom: 20px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (20 / 428));
  }
`;
const AddressTitleWrap = styled.p`
  display: flex;
  align-items: center;
`;
const AddressTitle = styled.span`
  max-width: calc(100% - 92px);
  color: #333;
  font-weight: 600;
  font-size: 17px;

  @media screen and (max-width: 1320px) {
    max-width: calc(100% - calc(100vw * (92 / 1320)));
    font-size: calc(100vw * (17 / 1320));
  }
  @media screen and (max-width: 930px) {
    max-width: calc(100% - calc(100vw * (92 / 428)));
    font-size: calc(100vw * (16 / 428));
  }
`;
const AddressDefault = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82px;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  padding: 5px 0;
  margin-left: 10px;
  background-color: #fdf251;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (82 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (5 / 1320)) 0;
    margin-left: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (82 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (5 / 428)) 0;
    margin-left: calc(100vw * (10 / 428));
  }
`;
const AddressText = styled.p`
  line-height: 1.5;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  margin-top: 8px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-top: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (8 / 428));
  }
`;
const AddressName = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 16px;
  margin-top: 4px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-top: calc(100vw * (4 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (6 / 428));
  }
`;
// 주문자 정보
const InputList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    display: block;
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 428));
    }
  }
`;

const InputText = styled.p`
  width: 250px;
  color: #333;
  font-weight: 400;
  font-size: 16px;
  flex-shrink: 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (250 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (15 / 428));
    margin-bottom: calc(100vw * (8 / 428));
  }
`;
const InputFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1;
`;
const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 16px;
  padding: 0 15px;
  background-color: #fff;
  border: 1px solid #ddd;

  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (16 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: 0 calc(100vw * (15 / 428));
  }
`;

// 주문자 정보 - input check
const ChkBox = styled.div``;
const ChkInput = styled.input`
  display: none;

  &:checked + label .chk-img {
    background: url(${IconChkOn}) no-repeat center / contain;
  }
`;
const ChkLabel = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;
const ChkImg = styled.div`
  width: 24px;
  height: 24px;
  background: url(${IconChkOff}) no-repeat center / contain;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const ChkText = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (10 / 428));
  }
`;

// 주문상품
const ArrowImgBox = styled.div`
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));

    transition: ease 0.3s all;
    ${({ BtnOn }) => {
    return BtnOn && `transform: rotate(180deg);`;
  }}
  }
`;

const ProductListWrap = styled.div`
  margin: 30px 0 0;

  @media screen and (max-width: 1320px) {
    margin: calc(100vw * (30 / 1320)) 0 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (20 / 428)) auto 0;
    transition: ease 0.3s all;
  }
`;
const ProductListArea = styled.div``;
const Brand = styled.p`
  color: #111;
  font-weight: 600;
  font-size: 15px;
  padding: 0 0 8px;
  border-bottom: 1px solid #000;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: 0 0 calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    padding: 0 0 calc(100vw * (10 / 428));
  }
`;
const ProductBoxWrap = styled.ul``;
const ProductBoxList = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const ProductInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const ProductImgBox = styled.div`
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 1px solid #eee;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    height: calc(100vw * (100 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    height: calc(100vw * (100 / 428));
  }
`;
const ProductTextBox = styled.div`
  width: calc(100% - 112px);

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (112 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (266 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProductColor = styled.p`
  display: inline;
  color: #777;
  font-weight: 400;
  font-size: 15px;
  margin-top: 4px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (4 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (4 / 428));
  }
`;
const ProductPriceWrap = styled.div``;
const ProductPriceOriginal = styled.p`
  color: #777;
  font-weight: 500;
  font-size: 14px;
  text-decoration: line-through;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;
const ProductPriceInner = styled.p`
  margin-top: 4px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (4 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (6 / 428));
  }
`;
const ProductSaleRate = styled.span`
  color: #333;
  font-weight: 700;
  font-size: 14px;
  padding: 2px 4.5px;
  margin-right: 5px;
  border-radius: 3px;
  background: #fdf251;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    padding: calc(100vw * (2 / 1320)) calc(100vw * (4.5 / 1320));
    margin-right: calc(100vw * (5 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    padding: calc(100vw * (2 / 428)) calc(100vw * (4.5 / 428));
    margin-right: calc(100vw * (5 / 428));
  }
`;
const ProductPrice = styled.span`
  color: #333;
  font-weight: 700;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-weight: 600;
    font-size: calc(100vw * (14 / 428));
  }
`;

// 쿠폰
const SubText = styled.p`
  position: relative;
  color: #777;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: -30px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-bottom: calc(100vw * (-30 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: 0;
  }
`;

// 포인트
const PointText = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const PointUseAllButton = styled.button`
  display: block;
  width: 140px;
  height: 50px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  background-color: #000;
  border: 1px solid #000;
  margin-left: 6px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (16 / 1320));
    margin-left: calc(100vw * (6 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (16 / 428));
    margin-left: calc(100vw * (8 / 428));
  }
`;

// 결제수단
const PaymentButtonWrap = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 930px) {
    justify-content: space-between;
    width: 100%;
  }
`;
const PaymentButton = styled.button`
  width: 184px;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  transition: ease 0.3s all;
  &:not(:last-child) {
    margin-right: 10px;
  }

  ${({ BtnOn }) => {
    return BtnOn && `border-color: #000;`;
  }}

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (184 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    &:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (184 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (15 / 428));
    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;

// 결제정보
const PriceListWrap = styled.ul``;
const PriceList = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 14px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (14 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (14 / 428));
    }
  }
`;
const PriceListTitle = styled.span`
  color: #777;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const PriceListText = styled(PriceListTitle)`
  color: #333;
  font-weight: 600;
`;
const TotalPriceWrap = styled.div`
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000;
    margin: 20px 0;
  }

  @media screen and (max-width: 1320px) {
    &::before {
      margin: calc(100vw * (20 / 1320)) 0;
    }
  }
  @media screen and (max-width: 930px) {
    &::before {
      margin: calc(100vw * (20 / 428)) 0;
    }
  }
`;
const TotalPriceInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TotalPriceTitle = styled.span`
  color: #333;
  font-weight: 600;
  font-size: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const TotalPrice = styled(TotalPriceTitle)`
  font-weight: 700;
`;
const TotalPoint = styled.p`
  color: #333;
  font-weight: 400;
  text-align: right;
  font-size: 14px;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;

// pay left
const PayLeftWrap = styled.div`
  width: 830px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (830 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const PayLeftArea = styled.div`
  width: 100%;
`;
// pay right
const PayRightWrap = styled.div`
  width: 380px;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (380 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const PayRightArea = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  position: fixed;
  width: inherit;
  top: 250px;
  transition: ease 0.5s all;

  @media screen and (max-width: 1320px) {
    top: calc(100vw * (250 / 1320));
  }

  @media screen and (max-width: 930px) {
    top: 0;
    border: 0;
    width: 100%;
    position: relative;
  }
`;
const PayRightInfo = styled.div`
  width: 100%;
  padding: 30px 25px;
  border-bottom: 10px solid #f9f9f9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) calc(100vw * (25 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: 0;
    border-bottom: 0;
  }
`;
// term
const TermChkListArea = styled.div`
  width: 330px;
  margin: 0 auto;
  padding: 30px 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (330 / 1320));
    padding: calc(100vw * (30 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const TermChkListInner = styled.ul`
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eee;
    margin: 25px 0 20px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (25 / 1320)) 0 calc(100vw * (20 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 428)) 0 calc(100vw * (25 / 428));
    }
  }
`;
const TermChkList = styled.li`
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (25 / 428));
    }
  }
`;

// term check
const TermInput = styled.input`
  display: none;

  &:checked + label .agree-check {
    background: url(${IconChkOn}) no-repeat center / cover;
  }
`;
const TermLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const TermChk = styled.span`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background: url(${IconChkOff}) no-repeat center / cover;
  margin-right: 10px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
    margin-right: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (26 / 428));
    height: calc(100vw * (26 / 428));
    margin-right: calc(100vw * (10 / 428));
  }
`;
const TermTextBtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const TermTextAll = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 17px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (17 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (17 / 428));
  }
`;
const TermText = styled(TermTextAll)`
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ViewButton = styled.button`
  flex-shrink: 0;
  color: #333;
  font-weight: 400;
  text-decoration: underline;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

// 결제 버튼
const AllPaymentButton = styled.button`
  width: 100%;
  height: 50px;
  font-size: 18px;
  color: #fdf251;
  font-weight: 500;
  background-color: #000;
  border: 1px solid #000;

  & span {
    color: #fdf251;
    font-weight: 600;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    position: fixed;
    left: 0;
    bottom: 0;
    height: calc(100vw * (80 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;

// select
const SelectButton = styled.select`
  flex: 1;
  display: block;
  width: 100%;
  height: 50px;
  color: #acacac;
  font-weight: 400;
  font-size: 16px;
  padding: 0 15px;
  background-color: #fff;
  border-radius: 0;
  border: 1px solid #ddd;

  -webkit-appearance: none; /* 네이티브 외형 감추기*/
  -moz-appearance: none;
  appearance: none;
  font-weight: normal;
  letter-spacing: normal;
  background: #ffffff url(${IconSelectArrow}) no-repeat right 15px center / 24px;
  cursor: pointer;

  &.focus {
    color: #333;
    border: 1px solid #000;
  }
  ${({ borderActive }) =>
    borderActive &&
    ` color: #333;
    border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (16 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 1320)) center /
      calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: 0 calc(100vw * (15 / 428));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 428)) center /
      calc(100vw * (24 / 428));
  }
`;
const SaveBtn = styled.button`
  display: block;
  color: #fff;
  font-weight: 500;
  background-color: #000;
  border: 1px solid #000;
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
    margin-top: calc(100vw * (30 / 428));
  }
`;

// page name
const PageListBox = styled.ul`
  display: flex;
  align-items: center;
  width: 1240px;
  margin: 0 auto;
  padding-bottom: 15px;
  border-bottom: 3px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    padding-bottom: calc(100vw * (13 / 1320));
  }
`;
const PageList = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background: url(${IconPage}) no-repeat center / contain;
    margin: 0 10px;
  }

  &.active span {
    color: #333;
    font-weight: 600;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      width: calc(100vw * (24 / 1320));
      height: calc(100vw * (24 / 1320));
      margin: 0 calc(100vw * (10 / 1320));
    }
  }
`;
const PageText = styled.span`
  color: #555;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
`;
