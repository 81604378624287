//react
import { useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import { useState } from "react";
import styled from "styled-components";

//img
import IconMarkA from "../../img/icon/icon_a.svg";
import IconMarkQ from "../../img/icon/icon_q.svg";
import IconBack from "../../img/icon/icon_header_back.svg";

import MyInquiryEditModal from "../../component/common/modal/MyInquiryEditModal";
import MyPageDefaultModal from "../../component/common/modal/MyPageDefaultModal";
import { imgURL } from "../../server/appInfoController";

export default function MyInquiryDetail({
  setDetail,
  detailItem,
  deleteQuestionItem,
}) {
  //페이지 이동
  const history = useHistory();

  const [inquiryRemove, setInquiryRemove] = useState(false);
  const [detailShow, setDetailShow] = useState(false);

  return (
    <Container>
      {detailShow && (
        <MyInquiryEditModal
          setDetailShow={setDetailShow}
          btnText={"저장하기"}
          detailItem={detailItem}
          edit
        />
      )}

      {inquiryRemove && (
        <MyPageDefaultModal
          text={"해당 문의를 삭제하시겠습니까?"}
          trueFunc={() => {
            deleteQuestionItem(detailItem.inq_id, setDetail);
          }}
          falseFunc={() => setInquiryRemove(false)}
        />
      )}

      <Wrap>
        <PC>
          <PageButtonWrap>
            <PageButtonArea
              onClick={() => {
                setDetail(false);
              }}
            >
              <PageButton>
                <img src={IconBack} alt="back-img" />
              </PageButton>
              <PageButtonTitle>목록으로</PageButtonTitle>
            </PageButtonArea>
          </PageButtonWrap>
        </PC>

        <ContentsWrap>
          <Contents>
            <ListBox>
              <List>
                <ListInner>
                  <ListTopWrap>
                    <SatisfactionBox>
                      <Date>
                        {detailItem.inq_date.split("T")[0].replaceAll("-", "/")}
                      </Date>
                      <Satisfaction>
                        {detailItem.inq_answer === 1 ? "답변대기" : "답변완료"}
                      </Satisfaction>
                    </SatisfactionBox>
                  </ListTopWrap>
                  <ListTextWrap>
                    <MarkImg>
                      <img src={IconMarkQ} alt="" />
                    </MarkImg>
                    <TextBox>
                      <Title>{detailItem.inq_title}</Title>
                      <Text>{detailItem.inq_content}</Text>
                    </TextBox>
                  </ListTextWrap>
                  {
                    detailItem?.inq_images &&
                    <ImgBoxList>
                      {detailItem?.inq_images?.split(",")?.length > 0 &&
                        detailItem?.inq_images?.split(",")?.map((imgItem) => {
                          return (
                            <ImgBox>
                              <img src={imgURL + imgItem} alt="question-img" />
                            </ImgBox>
                          );
                        })}
                    </ImgBoxList>
                  }
                  {detailItem.prd_thumbnail > 0 && (
                    <ListImgBoxWrap>
                      <ListImg>
                        <img
                          src={imgURL + detailItem.prd_thumbnail}
                          alt="prd-thumbnail"
                        />
                      </ListImg>
                    </ListImgBoxWrap>
                  )}
                </ListInner>
                {detailItem.inq_answer === 2 && (
                  <AnswerTextWrap>
                    <AnswerTextBox>
                      <MarkImg>
                        <img src={IconMarkA} alt="" />
                      </MarkImg>
                      <AnswerText>{detailItem?.inq_answer_content}</AnswerText>
                    </AnswerTextBox>
                  </AnswerTextWrap>
                )}
              </List>
            </ListBox>
          </Contents>
        </ContentsWrap>
        {detailItem.inq_answer === 1 && (
          <ButtonListWrap>
            <DeleteButton
              onClick={() => {
                setInquiryRemove(true);
              }}
              type="button"
            >
              삭제
            </DeleteButton>
            {/* <PC>
              <CancelButton
                type="button"
                onClick={() => {
                  history.push("/store/mypage/inquiry/edit?edit=T");
                }}
              >
                수정
              </CancelButton>
            </PC>
            <Mobile>
              <CancelButton
                type="button"
                onClick={() => {
                  setDetailShow(true);
                }}
              >
                수정
              </CancelButton>
            </Mobile> */}
          </ButtonListWrap>
        )}
      </Wrap>
    </Container>
  );
}

const Container = styled.div``;

const Wrap = styled.div`
  width: 100%;
`;

// 콘텐츠
const ContentsWrap = styled.div``;
const Contents = styled.div``;

const ListBox = styled.ul``;
const List = styled.li`
  position: relative;
`;

const ListInner = styled.div`
  width: 992px;
  margin: 0 auto;
  padding: 30px 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (992 / 1320));
    padding: calc(100vw * (30 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (50 / 428));
  }
`;

const ListTopWrap = styled.div``;
const SatisfactionBox = styled.div`
  display: flex;
  align-items: center;

  & span {
    display: flex;
    align-items: center;
  }

  & span:not(:last-child)::after {
    content: "";
    display: block;
    width: 1px;
    height: 14px;
    margin: 0 12px;
    background-color: #ddd;
  }

  @media screen and (max-width: 1320px) {
    & span:not(:last-child)::after {
      height: calc(100vw * (14 / 1320));
      margin: 0 calc(100vw * (12 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    & span:not(:last-child)::after {
      height: calc(100vw * (14 / 428));
      margin: 0 calc(100vw * (12 / 428));
    }
  }
`;
const Date = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const Satisfaction = styled.span`
  color: ${(props) => (props.complete ? "#333" : "#ACACAC")};
  font-weight: ${(props) => (props.complete ? "600" : "400")};
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

const ListTextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin: calc(100vw * (10 / 428)) 0;
  }
`;
const MarkImg = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;

const TextBox = styled.div`
  width: calc(100% - 32px);
  padding-top: 3px;

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (32 / 1320)));
    padding-top: calc(100vw * (3 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (346 / 428));
    padding-top: calc(100vw * (3 / 428));
  }
`;

const ImgBoxList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (15 / 428));
  }
`;
const ImgBox = styled.div`
  width: 100px;
  height: 100px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:not(:last-child){
    margin-right: 10px;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    height: calc(100vw * (100 / 1320));

    &:not(:last-child){
      margin-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
    height: calc(100vw * (120 / 428));

    &:not(:last-child){
      margin-right: calc(100vw * (9 / 428));
    }
  }
`;
const Title = styled.p`
  color: #333;
  font-weight: 500;
  line-height: 1.5;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const Text = styled(Title)`
  font-weight: 400;
  margin-top: 8px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (8 / 428));
  }
`;

// 이미지 영역
const ListImgBoxWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const ListImg = styled.div`
  width: 100px;
  height: 100px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    height: calc(100vw * (100 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
    height: calc(100vw * (120 / 428));

    &:not(:last-child) {
      margin-right: calc(100vw * (9 / 428));
    }
  }
`;

// 답변
const AnswerTextWrap = styled.div`
  background-color: #f9f9f9;
`;

const AnswerTextBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 992px;
  margin: 0 auto;
  padding: 25px 0 50px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (992 / 1320));
    padding: calc(100vw * (25 / 1320)) 0 calc(100vw * (50 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: calc(100vw * (25 / 428)) 0 calc(100vw * (50 / 428));
  }
`;
const AnswerText = styled.p`
  width: calc(100% - 32px);
  color: #333;
  font-weight: 400;
  line-height: 1.5;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (32 / 1320)));
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (346 / 428));
    padding-top: calc(100vw * (3 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;

const ButtonListWrap = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 20px;

  & button:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));

    & button:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    justify-content: space-between;
    padding: 0 calc(100vw * (25 / 428));
    margin: calc(100vw * (30 / 428)) 0;

    & button:not(:last-child) {
      margin-right: 0;
    }
  }
`;
const DeleteButton = styled.button`
  width: 184px;
  height: 45px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    width: 100%;
    height: calc(100vw * (45 / 1320));
    width: calc(100vw * (184 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (45 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
const CancelButton = styled(DeleteButton)`
  color: #000;
  background-color: #fff;
`;

// pc - page button
const PageButtonWrap = styled.div`
  padding: 14px 20px;
  border-bottom: 1px solid #eee;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (14 / 1320)) calc(100vw * (20 / 1320));
  }
`;
const PageButtonArea = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;
const PageButton = styled.div`
  width: 32px;
  height: 32px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
`;
const PageButtonTitle = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 15px;
  margin-left: 5px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (5 / 1320));
  }
`;
