import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SignUpAction } from '../../../store/actionCreators';

import {
    Layout
} from '../auth.elements';
import {
    StepContainer,
    StepContents,
    StepHeadings,
    GenderForm,
    Gender,
    GenderMark,
    StepForm,
    StepMessage,
    StepButtonBox,
    StepNextBox,
    StepStyles,
    StepStyle,
    SignupButton
} from './signup.element';

import stepimg1 from '../../../images/signup/step-style-1.png';
import stepimg2 from '../../../images/signup/step-style-2.png';
import stepimg3 from '../../../images/signup/step-style-3.png';
import stepimg4 from '../../../images/signup/step-style-4.png';
import stepimg5 from '../../../images/signup/step-style-5.png';
import stepimg6 from '../../../images/signup/step-style-6.png';

const styleImageArr = [
    { src: stepimg1, text: "에슬레저룩" },
    { src: stepimg2, text: "유니섹스룩" },
    { src: stepimg3, text: "이지룩" },
    { src: stepimg4, text: "클래식룩" },
    { src: stepimg5, text: "톤온톤룩" },
    { src: stepimg6, text: "페미닌룩" },
];

const StepStyleBox = ({ value, setStyles }) => {
    const [isClick, setIsClick] = useState(false);
    const onClick = () => {
        setIsClick(prev => !prev);
    }

    useEffect(() => {
        setStyles(prev => {
            const newArr = [...prev];
            if (isClick) {
                return newArr.concat(value?.text)
            } else {
                return newArr.filter(v => v !== value?.text);
            }
        })
    }, [isClick])

    return (
        <StepStyle isClick={isClick} onClick={onClick}>
            <img src={value?.src} alt={value?.text} />
            <div>{value?.text}</div>
        </StepStyle>
    )
}


const SignUpStepTwo = ({ step, url }) => {
    const history = useHistory();
    const [styles, setStyles] = useState([]);
    const prevStep = () => history.push(`/${url}/normal/1`)

    const nextStep = (skip) => {
        if (skip) {
            SignUpAction.sendPersonalNormalInfo({ mem_style: [] }) // 0 남자 1 여자
            history.push(`/${url}/normal/3`);
        } else if (styles?.length > 0) {
            SignUpAction.sendPersonalNormalInfo({ mem_style: styles }) // 0 남자 1 여자
            history.push(`/${url}/normal/3`)
        } else {
            alert("선호하시는 스타일을 골라주세요.")
        }
    };


    return (
        <>
            <StepContents>
                <StepHeadings>
                    <h2>Personal infomation</h2>
                    <p><em>0{step} / </em><span>04</span></p>
                </StepHeadings>

                <StepForm>
                    <StepMessage>선호하시는 스타일을 선택해 주세요. <em>(중복 선택 가능)</em></StepMessage>

                    <StepStyles>
                        {
                            styleImageArr.map((value, i) => {
                                return <StepStyleBox
                                    key={i}
                                    value={value}
                                    setStyles={setStyles}
                                />
                            })
                        }
                    </StepStyles>
                </StepForm>

                <StepNextBox>
                    <StepButtonBox>
                        <SignupButton prev onClick={prevStep}>이전으로</SignupButton>
                        <SignupButton onClick={() => nextStep()}>다음으로</SignupButton>
                    </StepButtonBox>

                    {/* <p  onClick={() => nextStep(true)}>스킵하기</p> */}
                </StepNextBox>
            </StepContents>
        </>
    )
}

export default SignUpStepTwo;
