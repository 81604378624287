//react
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

//css
import "swiper/swiper-bundle.css";
import styled from "styled-components";

//img
import IconMute from "../../images/video/icon_mute.svg";
import IconSound from "../../images/video/icon_sound.svg";
import IconLikeOn from "../../images/stylist/icon_video_like_on.svg";
import IconLikeOff from "../../images/stylist/icon_video_like_off.svg";
import IconComment from "../../images/stylist/icon_comment.svg";

// server
import openLink from "../../server/openLink";
import { imgURL } from "../../server/appInfoContoller";
import serverController from "../../server/serverController";

export default function TvSlide({
  item,
  index,
  setIsModal,
  videoIdx,
  isMuted,
  setIsMuted,
  togglePlay,
}) {
  const { isLogged } = useSelector((state) => state.userData);
  const [likeType, setLikeType] = useState(item.reels_like == 1);

  // 릴스 데이터 fetching
  const likeClick = (id) => {
    if (isLogged) {
      serverController.connectFetchController(
        `reels/like?reels_id=${id}`,
        "POST",
        null,
        (res) => {
          console.log(res);
          if (res?.result == 1) {
            setLikeType(true);
          } else {
            setLikeType(false);
          }
        }
      );
    } else {
      alert("로그인이 필요합니다.");
    }
  };

  const pageLinkClick = (id, type, link) => {
    if (type == 3) {
      openLink.openLinkEvent(link);
      // window.open(`${link}`);
    } else {
      alert("런칭 준비중입니다 :D");
      // history.push(`/store/detail/${id}`);
    }
  };

  return (
    <>
      <TvVideo
        onClick={togglePlay}
        className={`video-${index + 1 === videoIdx ? "active" : "deactivate"}`}
        id={item?.reels_id}
        src={imgURL + item?.reels_video}
        width="100%"
        height="100vh"
        muted={isMuted}
        loop
        autoPlay
        playsInline
      />
      <TvBottom>
        <TvTitleWrap>
          {isMuted ? (
            <Mute src={IconMute} onClick={() => setIsMuted(false)} />
          ) : (
            <Sound src={IconSound} onClick={() => setIsMuted(true)} />
          )}
          <LikeBtn
            src={likeType ? IconLikeOn : IconLikeOff}
            alt=""
            onClick={() => {
              likeClick(item?.reels_id);
            }}
          />
          {/* <CommoneBtn src={IconComment} alt="" onClick={()=>{setIsModal(true)}}/> */}
          <TvTitle>{item?.reels_title}</TvTitle>
          <TvText>{item?.reels_contents}</TvText>
        </TvTitleWrap>
        <Swiper slidesPerView={3.4} spaceBetween={15}>
          {item?.reelsProductList.length > 0
            ? item?.reelsProductList.map((v, i) => {
                return (
                  <SwiperSlide>
                    <ProductBox
                      onClick={() => {
                        pageLinkClick(v?.prd_id, v?.prd_type, v?.prd_link);
                      }}
                    >
                      <ProductImg src={imgURL + v.prd_thumbnail} alt="" />
                    </ProductBox>
                  </SwiperSlide>
                );
              })
            : null}
        </Swiper>
      </TvBottom>
    </>
  );
}
const TvVideo = styled.video`
  object-fit: cover;
  position: relative;
  display: block;
  width: 100%;
  height: 100vh;
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
`;
const TvBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: calc(100vw * (20 / 428)) calc(100vw * (25 / 428))
    calc(100vw * (75 / 428));

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(1, 1, 1, 0.8), rgba(1, 1, 1, 0));
  }
`;
const TvTitleWrap = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: calc(100vw * (20 / 428));
  z-index: 2;
`;
const Sound = styled.img`
  z-index: 1;
  width: calc(100vw * (26 / 428));
  padding-bottom: calc(100vw * (15 / 428));
  margin-left: 0;
`;
const Mute = styled(Sound)``;

const TvTitle = styled.p`
  font-size: calc(100vw * (18 / 428));
  font-weight: bold;
  color: #fff;
`;
const LikeBtn = styled.img`
  display: block;
  width: calc(100vw * (20 / 428));
  margin-bottom: calc(100vw * (10 / 428));
  cursor: pointer;
`;
const CommoneBtn = styled.img`
  position: absolute;
  top: calc(100vw * (128 / 428));
  right: 0;
  display: block;
  width: calc(100vw * (26 / 428));
  cursor: pointer;
`;
const TvText = styled.div`
  font-size: calc(100vw * (16 / 428));
  font-weight: bold;
  margin-top: calc(100vw * (12 / 428));
  line-height: 1.3;
  color: #fff;
  & > p {
    color: #fff;
  }
`;
const ProductBox = styled.div`
  width: 100%;
  height: calc(100vw * (100 / 428));
  border-radius: calc(100vw * (8 / 428));
  overflow: hidden;
  cursor: pointer;
`;
const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
