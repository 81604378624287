import styled, { css } from "styled-components";

const SkeletonItem = css`
  width: 100%;
  height: 30px;
  background-color: #f2f2f2;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  @keyframes skeleton-gradient {
    0% {
      background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
      background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
      background-color: rgba(165, 165, 165, 0.1);
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: skeleton-gradient 1.5s infinite ease-in-out;
  }
`;

const List = () => {
  return (
    <Wrapper>
      {new Array(3).fill("").map((_, i) => (
        <Card key={i}>
          <Title />
          <Content />
          <Bottom>
            <Circle />
            <Title />
          </Bottom>
        </Card>
      ))}
    </Wrapper>
  );
};

const detail = () => {
  return (
    <Wrapper>
      <Title />
      <PieceBlock>
        <div>
          <Piece />
          <Piece margin="0 0 0 10px" />
          <Piece margin="0 0 0 10px" />
        </div>
        <Piece />
      </PieceBlock>
      <Content height="280px" />
      <Content height="300px" />
      <Bottom>
        <Circle />
        <Title />
      </Bottom>
    </Wrapper>
  );
};

const ResponsiveProductList = (props) => {
  return (
    <StyledResponsiveProductList>
      {new Array(props.count ?? 8).fill("").map((_, i) => (
        <StyledProductCard key={i}>
          <Content />
          <Title />
          <PieceBlock>
            <Piece />
          </PieceBlock>
          <div>
            <Piece margin="0 10px 0 0" />
            <Piece />
          </div>
        </StyledProductCard>
      ))}
    </StyledResponsiveProductList>
  );
};

const HorizontalProductList = (props) => {
  return (
    <StyledHorizontalProductList>
      {new Array(props.count ?? 6).fill("").map((_, i) => (
        <StyledProductCard key={i}>
          <Content height="180px" />
          <Title />
          <PieceBlock>
            <Piece />
          </PieceBlock>
          <div>
            <Piece margin="0 10px 0 0" />
            <Piece />
          </div>
        </StyledProductCard>
      ))}
    </StyledHorizontalProductList>
  );
};

const oneLineList = () => {
  return (
    <Wrapper>
      {new Array(5).fill("").map((_, i) => (
        <Title key={i} />
      ))}
    </Wrapper>
  );
};

const AsidePc = () => {
  return (
    <StyledAsidePc>
      {new Array(20).fill("").map((_, i) => (
        <Title key={i} />
      ))}
    </StyledAsidePc>
  );
};

const ResponsiveContentsList = (props) => {
  return (
    <ContentsListLoading>
      {new Array(props.count ?? 8).fill("").map((_, i) => (
        <StyledContentCard key={i}>
          <ContentsContent height="260px" />
          <Title />
        </StyledContentCard>
      ))}
    </ContentsListLoading>
  );
};

const StyledAsidePc = styled.ul`
  background-color: #fff;
`;

const Wrapper = styled.ul`
  background-color: #fff;
  padding: 0 10px;
`;

const StyledResponsiveProductList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
`;

const StyledHorizontalProductList = styled.div`
  width: 100%;
  display: flex;
  background-color: #fff;
`;

const StyledProductCard = styled.div`
  margin: 5px;
  width: calc(25% - 10px);
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 930px) {
    width: calc(50% - 10px);
  }
`;

const Card = styled.li`
  border: 1px solid #aaa;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 10px;
`;

const Title = styled.div`
  ${SkeletonItem}
  margin-bottom: 10px;
`;
const PieceBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Piece = styled.div`
  ${SkeletonItem}
  display: inline-block;
  height: 30px;
  width: 60px;
  margin: ${(props) => (props.margin ? props.margin : "0")};
`;

const Content = styled.div`
  ${SkeletonItem};
  height: ${(props) => (props.height ? props.height : "240px")};
  margin-bottom: 10px;
`;

const Bottom = styled.div`
  display: flex;
  gap: 12px;
`;

const Circle = styled.div`
  ${SkeletonItem};
  width: 35px;
  height: 30px;
  border-radius: 50%;
  background-color: #f2f2f2;
  position: relative;
  overflow: hidden;
`;

const ContentsListLoading = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  @media screen and (max-width: 1320px) {
    max-width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    max-width: calc(100vw * (378 / 428));
  }
`;

const StyledContentCard = styled.div`
  margin: 5px;
  width: calc(25% - 10px);
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;

const ContentsContent = styled.div`
  ${SkeletonItem};
  border-radius: 20px;
  height: 280px;
  margin-bottom: 10px;
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (280 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (378 / 428));
    border-radius: calc(100vw * (20 / 428));
  }
`;

const SkeletonUI = {
  List,
  detail,
  oneLineList,
  ResponsiveProductList,
  HorizontalProductList,
  AsidePc,
  ResponsiveContentsList,
};

export default SkeletonUI;
