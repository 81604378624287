//react
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import serverController from "../../../server/serverController";
//css
import { useSelector } from "react-redux";
import styled from "styled-components";

// img
import ArrImg from "../../../img/icon/icon_pagination_next_gray.png";
import UseIcon from "../../../img/icon/icon_use.png";
import AccmulateIcon from "../../../img/icon/icon_accumulate.png";

// page
import GongPointInfo from "./GongPointInfo";
import PointList from "./PointList";
import Mypagefilter from "../Mypagefilter";
import { useEffect, useState } from "react";

export default function MypagePoint() {
  const [data, setData] = useState({});
  useEffect(() => {
    serverController.connectFetchController(
      `point/total`,
      "GET",
      null,
      function (res) {
        console.log(res, "포인트");
        if (res.result == 1) {
          setData(res?.totalPoint);
        }
      }
    );
  }, []);

  const list = [
    {
      src: UseIcon,
      year: "2021",
      month: "06",
      day: "15",
      detail: "결제 사용",
      product_name: "Pink Cashmere Jumper 세트",
      point: "-50",
    },
    {
      src: AccmulateIcon,
      year: "2021",
      month: "05",
      day: "06",
      detail: "Premium 요금제 결제",
      product_name: "",
      point: "+30,000",
    },
    {
      src: UseIcon,
      year: "2021",
      month: "04",
      day: "15",
      detail: "결제 사용",
      product_name:
        "Pink Cashmere Jumper 세트Pink Cashmere Jumper 세트Pink Cashmere Jumper 세트Pink Cashmere Jumper 세트Pink Cashmere Jumper 세트",
      point: "-50",
    },
    {
      src: UseIcon,
      year: "2021",
      month: "04",
      day: "12",
      detail: "결제 사용",
      product_name: "Pink Cashmere Jumper 세트",
      point: "-50",
    },
    {
      src: AccmulateIcon,
      year: "2021",
      month: "04",
      day: "06",
      detail: "적립",
      product_name: "미디길이 치마",
      point: "+1,000",
    },
  ];

  const { mem_point } = useSelector((state) => state.userData.userInfo);

  return (
    <Container>
      <TitleBox>
        <TitleFlex>
          <Title>포인트(WALA)</Title>
          <SubTitle>이용하시는 샵마다 매월 WALA 포인트가 지급됩니다.</SubTitle>
        </TitleFlex>
        <ShortcutBtn>
          <ShortcutBtnText>
            바로가기
            <Arr src={ArrImg} />
          </ShortcutBtnText>
        </ShortcutBtn>
      </TitleBox>

      <GongPointInfo data1={data} />
      <Mypagefilter />

      <PointListWrap>
        {list.map((value, index) => {
          return <PointList key={"pointList" + index} value={value} />;
        })}
      </PointListWrap>
      <MoreBtn>더보기 +</MoreBtn>
    </Container>
  );
}

const Container = styled.div`
  width: 1300px;
  margin: 0 auto;
  padding: 180px 0 200px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
    padding: calc(100vw * (35 / 1320)) 0 calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    padding: calc(100vw * (35 / 428)) 0 calc(100vw * (200 / 428));
  }
`;
// title
const TitleBox = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 23px;
  border-bottom: 1px solid #000;
  padding: 25px 0;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (23 / 1320));
    border-bottom: calc(100vw * (1 / 1320)) solid #000;
    padding: calc(100vw * (25 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    display: block;
    width: calc(100vw * (388 / 428));
    padding-bottom: calc(100vw * (23 / 428));
    border-bottom: calc(100vw * (1 / 428)) solid #000;
    padding: calc(100vw * (25 / 428)) 0;
    margin: 0 auto;
  }
`;
const TitleFlex = styled.div`
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 930px) {
    display: block;
  }
`;
const Title = styled.h2`
  font-size: 24px;
  color: #000;
  font-weight: bold;
  margin-right: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
    margin-right: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    margin-bottom: calc(100vw * (20 / 428));
    text-align: center;
  }
`;
const SubTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #969696;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    text-align: center;
  }
`;
const ShortcutBtn = styled.div`
  display: flex;

  @media screen and (max-width: 930px) {
    width: 100%;
    margin-top: calc(100vw * (10 / 428));
    justify-content: center;
  }
`;
const ShortcutBtnText = styled.p`
  cursor: pointer;
  font-size: 14px;
  color: #646464;
  font-weight: bold;
  line-height: 12px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    line-height: calc(100vw * (12 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    line-height: calc(100vw * (14 / 428));
  }
`;
const Arr = styled.img`
  width: 5px;
  margin-left: 22px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (5 / 1320));
    margin-left: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (5 / 428));
    margin-left: calc(100vw * (15 / 428));
  }
`;
const PointListWrap = styled.div`
  width: 100%;
  padding-bottom: 18px;
  border-top: 1px solid #000;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (18 / 1320));
    border-top: calc(100vw * (1 / 1320)) solid #000;
  }

  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (20 / 428));
    border-top: none;
  }
`;
const MoreBtn = styled.div`
  display: block;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  width: 198px;
  height: 54px;
  line-height: 54px;
  border-radius: 5px;
  border: 1px solid #20232a;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (17 / 1320));
    width: calc(100vw * (198 / 1320));
    height: calc(100vw * (54 / 1320));
    line-height: calc(100vw * (54 / 1320));
    border-radius: calc(100vw * (5 / 1320));
    border: calc(100vw * (1 / 1320)) solid #20232a;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    width: calc(100vw * (200 / 428));
    height: calc(100vw * (54 / 428));
    line-height: calc(100vw * (54 / 428));
    border-radius: calc(100vw * (5 / 428));
    border: calc(100vw * (1 / 428)) solid #20232a;
  }
`;
