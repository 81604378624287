import React, { useState, useEffect, useCallback } from 'react';
import api from '../../../server/serverController';
import { PC, Mobile } from '../../../MediaQuery';

import {  
    FieldBox,
    Label,    
    LabelName,
    TelephoneInput,
    Input,
    ErrorMessage    
} from '../auth.elements';
import {    
    SubCheckButton, 
    MobileSubBox
} from './signup.element';

const SignupPhoneInput = ({ setPhone, setIsCheck }) => {
    const [value, setValue] = useState("");
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [confirm, setConfirm] = useState("");
    const [serverNum, setServerNum] = useState(null);
    const [isConfirmError, setIsConfirmError] = useState(false);
    const [confirmErrorMessage, setConfirmErrorMessage] = useState("");
    
    const [isSuccess, setIsSuccess] = useState(false);

    const regPhoneNum = (num) => {
        num = num.split("-").join("");
        
        //1. 모두 숫자인지 체크
        const checkNum = Number.isInteger(Number(num));
        
        //2. 앞 세자리가 010으로 시작하는지 체크
        const checkStartNum = num.slice(0, 3) === '010' ? true : false
        
        //3. 010을 제외한 나머지 숫자가 7 혹은 8자리인지 체크
        const checkLength = num.slice(3).length === 7 || num.slice(3).length === 8 ? true : false
        
        //4. 123 모두 true면 true를, 아니면 false를 반환
        return checkNum && checkStartNum && checkLength ? true : false
    }

    const onHandleChange = (e) => {       
        const data = e.target.value;
        setValue(data)
        if (setPhone) setPhone(data);      
          
        if (!regPhoneNum(data)) {
            setIsError(true)
            setIsSuccess(false)
            setErrorMessage("*유효하지 않는 번호입니다.")
        } else {
            setIsError(false);
        }
    }; 

    const onHandlePhone = async (e) => {       
        if(value == '') return alert('휴대폰 번호를 입력해주세요.')
        if(isError || value.length < 0) return

        await api.post(
            `overlap/phone?mem_phone=${value}`,
            null,
            (res) => {
                console.log(res, "인증번호 response")
                if(res?.result === 1) { 
                    alert("인증번호가 발송됐습니다.")
                    setServerNum(res?.identify_num);
                } else if (res?.result === 0) {
                    alert("현재 가입되어 있는 연락처입니다.")
                }
            },
            (err) => console.error(err)
        )
    }; 

    const onHandlePhoneConfirm = (e) => {
        if (!serverNum) {
            setIsSuccess(false)
            setIsConfirmError(true);
            setConfirmErrorMessage("본인 인증을 먼저 진행해주세요")
        } else if (confirm !== serverNum) {
            setIsSuccess(false)
            setIsConfirmError(true);
            setConfirmErrorMessage("*인증번호가 맞지 않습니다.");            
        } else if (confirm === serverNum){
            setIsSuccess(true)
            setIsConfirmError(false);
            setIsCheck(prev => ({...prev, isPhone: true }))
        }
    }

    useEffect(() => {
        setPhone(value)
        setIsCheck(prev => ({...prev, isPhone: isSuccess })); 
    }, [isSuccess])


    return (
        <>
        <PC>
        <FieldBox>
            <Label>
                <LabelName>본인 인증</LabelName>
                <TelephoneInput
                    value={value}
                    onChange={onHandleChange}                
                />
            </Label> 
            { isError && <ErrorMessage>{errorMessage}</ErrorMessage> }

            <SubCheckButton onClick={onHandlePhone}>인증번호 받기</SubCheckButton>
        </FieldBox>        

        <FieldBox>
            <Label>
                <LabelName>인증번호 확인</LabelName>
                <Input 
                required
                value={confirm}
                onChange={e => setConfirm(e.target.value)}
                />
            </Label> 
            { isConfirmError && <ErrorMessage>{confirmErrorMessage}</ErrorMessage> }
            { isSuccess && <ErrorMessage success>*인증번호가 일치합니다.</ErrorMessage> }

            <SubCheckButton onClick={onHandlePhoneConfirm}>확인</SubCheckButton>
        </FieldBox>          
        </PC>

        <Mobile>
        <FieldBox>
            <Label>
                <LabelName>본인 인증</LabelName>
                <TelephoneInput
                value={value}
                onChange={onHandleChange}  
                />
            </Label> 
            
            { isError && <ErrorMessage>{errorMessage}</ErrorMessage> }

            <MobileSubBox>
                <SubCheckButton onClick={onHandlePhone}>인증번호 받기</SubCheckButton>
            </MobileSubBox>
        </FieldBox>     

        <FieldBox>
            <Label>
                <LabelName>인증번호 확인</LabelName>
                <Input 
                required
                value={confirm}
                onChange={e => setConfirm(e.target.value)}
                />
            </Label> 

            { isConfirmError && <ErrorMessage>{confirmErrorMessage}</ErrorMessage> }
            { isSuccess && <ErrorMessage success>*인증번호가 일치합니다.</ErrorMessage> }

            <MobileSubBox>
                <SubCheckButton onClick={onHandlePhoneConfirm}>확인</SubCheckButton>
            </MobileSubBox>
        </FieldBox> 
        </Mobile>
        </>
    )
}

export default SignupPhoneInput
