//react
import React, { useState, useEffect } from "react";

//css
import styled from "styled-components";

// controller
import { ip } from "../../../server/serverController";

// elements
import { AlertBox } from "../../login/auth.elements";

import axios from 'axios';

export default function MyPassword() {
  const check = /^(?=.*[a-zA-Z])((?=.*\d)(?=.*\W)).{8,10}$/;
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");

  const [correct, setCorrect] = useState(true);
  const [lengthValidate, setLengthValidate] = useState(true);
  const [isValidate, setValidate] = useState(true);
  const [toastModalShow, setToastModalShow] = useState(false);

  const postNewPassword = async () => {
    if (passwordValue.length < 6) return setLengthValidate(false);
    if (!check.test(passwordValue)) return setValidate(false);
    if (passwordValue !== passwordRepeat) return setCorrect(false);

    // axios.put(`${ip}user/pw?mem_password=${passwordValue}`)
    //   // .then((res) => res.json())
    //   .then((res) => {
    //     console.log(res, "res");
    //     // if (res.status === 200) {
    //     //   setToastModalShow(true);
    //     // }
    //   })
    //   .catch((res) => {
    //     console.log(res.status);
    //   });

    try {
      const params = new URLSearchParams();
      params.append('mem_password', passwordValue)
      const res = await axios.put(`${ip}user/pw`,
        params
      );
      console.log('res: ', res);

    } catch (error) {
      console.log('@@: ', error);

    }



    // fetch(`${ip}user/pw`, {
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   method: "PUT",
    //   body: JSON.stringify({ mem_password: passwordValue }),
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     console.log(res, "res");
    //     // if (res.status === 200) {
    //     //   setToastModalShow(true);
    //     // }
    //   })
    //   .catch((res) => {
    //     console.log(res.status);
    //   });
  };

  useEffect(() => {
    setTimeout(() => {
      setToastModalShow(false);
    }, 3000);
  }, [toastModalShow]);

  return (
    <Container>
      <ProfileBox>
        <Content>
          <ProfileListWrap>
            <ProfileInputWrap>
              <InputTitleWrap>
                <InputTitle>새 비밀번호</InputTitle>
              </InputTitleWrap>
              <ProfileInputInner>
                <ProfileInput
                  type="password"
                  placeholder="영문+숫자+특수문자 조합 8~10자리"
                  active={passwordValue.length > 0}
                  value={passwordValue}
                  correct={!isValidate || (correct ? lengthValidate : correct)}
                  onChange={(e) => {
                    setCorrect(true);
                    setLengthValidate(true);
                    setValidate(true);
                    setPasswordValue(e.target.value);
                  }}
                />
              </ProfileInputInner>
            </ProfileInputWrap>
            <ProfileInputWrap>
              <InputTitleWrap>
                <InputTitle>새 비밀번호 확인</InputTitle>
              </InputTitleWrap>
              <ProfileInputInner>
                <ProfileInputFlex>
                  <ProfileInput
                    type="password"
                    placeholder="비밀번호를 다시 입력해주세요."
                    active={passwordRepeat.length > 0}
                    value={passwordRepeat}
                    correct={!isValidate || (correct ? lengthValidate : correct)}
                    onChange={(e) => {
                      setCorrect(true);
                      setLengthValidate(true);
                      setValidate(true);
                      setPasswordRepeat(e.target.value);
                    }}
                  />
                </ProfileInputFlex>
                {!correct ? (
                  <ProfileInputFlex>
                    <ValidatedText>비밀번호가 일치하지 않습니다.</ValidatedText>
                  </ProfileInputFlex>
                ) : !lengthValidate && correct ? (
                  <ProfileInputFlex>
                    <ValidatedText>6글자 이상 입력해주세요.</ValidatedText>
                  </ProfileInputFlex>
                ) : null}
                {!isValidate && (
                  <ProfileInputFlex>
                    <ValidatedText>영문,숫자,특수문자 8~10자리로 입력해주세요.</ValidatedText>
                  </ProfileInputFlex>
                )}
              </ProfileInputInner>
            </ProfileInputWrap>
          </ProfileListWrap>

          <SaveBtn type="button" onClick={postNewPassword}>
            변경하기
          </SaveBtn>
        </Content>
      </ProfileBox>
      <AlertBox show={toastModalShow}>비밀번호가 변경되었습니다.</AlertBox>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 70px 0 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (70 / 1320)) 0 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0 0;
  }
`;
const ProfileBox = styled.div``;

const Content = styled.div`
  width: 378px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;

//Profile input
const ProfileListWrap = styled.div`
  width: 100%;
`;
const ProfileInputWrap = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 428));
    }
  }
`;
const InputTitleWrap = styled.div`
  width: 100%;
`;
const InputTitle = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProfileInputInner = styled.div`
  width: 100%;
  margin-top: 8px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (8 / 428));
  }
`;
const ProfileInputFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (8 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (8 / 428));
    }
  }
`;
const ProfileInput = styled.input`
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0 15px;
  flex: 1;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}
  ${({ correct }) => !correct && `border: 1px solid #FF006C;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: 0 calc(100vw * (15 / 428));
  }
`;

const ValidatedText = styled.p`
  color: #ff006c;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

// 저장버튼
const SaveBtn = styled.button`
  width: 100%;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;
  margin-top: 30px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
    margin-top: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
    margin-top: calc(100vw * (30 / 428));
  }
`;
