import React from 'react';
import {
    ModalContainer,
    QuestionSuccessContents,
    MyPageModalText,    
    MyPageModalButton
} from './pop.elements';

const QuestionSuccessModal = ({setIsQuestModal, }) => {
    const closeModal = () => {
        setIsQuestModal(false)
    }

    return (
        <ModalContainer>
            <QuestionSuccessContents>
                <MyPageModalText>
                    <p>
                        문의하신 내용이 정상적으로 접수 되었습니다. <br/>
                        <small>문의에 대한 답변은 문의 목록에서 확인 가능합니다.</small>
                    </p>
                    
                    <MyPageModalButton onClick={closeModal}>확인</MyPageModalButton>
                </MyPageModalText>
            </QuestionSuccessContents>
        </ModalContainer>
    )
}

export default QuestionSuccessModal;
