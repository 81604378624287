import React, { useState, useEffect } from 'react';
import { Mobile, PC } from '../../MediaQuery';
import { useSelector } from 'react-redux';
import api from '../../server/serverController';

import { Wrapper, Section, SectionHeadings, MoreButton } from './Main.elements';
import { IssueItemContainer, PrevButton, NextButton, IssueSlideWrapper } from './MainIssue.element';
import IssueItem from './IssueItem';

import ArrowLeft from '../../images/drama_arrow_left.png'; 
import ArrowRight from '../../images/drama_arrow_right.png'; 


import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Pagination, Navigation, EffectFade, Autoplay,Scrollbar
} from 'swiper'; 
import "swiper/swiper-bundle.css";
SwiperCore.use([Pagination, Navigation, EffectFade, Autoplay, Scrollbar]);

const MainIssue = () => {    
    const { isLogged } = useSelector(state => state.userData);
    const [list, setList] = useState([]);
    const getData = async () => {        
        await api.get(
            `main/contentslist/${3}`,
            null,
            (res) => {
                console.log(res, "메인 pro 컨텐츠 response")
                if (res?.result === 1) {
                    setList(res?.list ?? []);
                } 
            },
            (err) => console.error(err)            
        );
        // await api.get(`newsList?news_kind=1`, null,
        //     (res) => {
        //         console.log(res, "메인 뉴스 목록 조회 res");
        //         if (res?.result === 1) {
        //             setList(res?.list ?? []);
        //         } 
        //     }
        // )
    }

    useEffect(() => {
        getData();
    }, [isLogged])

    return (
        <>
        <PC>
        <Section>
            <Wrapper>
                <SectionHeadings>New Issue</SectionHeadings>
                <IssueSlideWrapper className="issue-slide-wrapper">
                    <IssueItemContainer>
                        {
                            list.length > 0 ? (
                                <Swiper                                 
                                    slidesPerView={3}
                                    loop                                        
                                    navigation={{
                                        prevEl: '.swiper-button-prev-unique',
                                        nextEl: '.swiper-button-next-unique',
                                    }}                                             
                                    >
                                        {
                                            list.map((v, i) => {
                                                return (
                                                    <SwiperSlide key={v?.contents_id}>
                                                        <IssueItem value={v} />
                                                        {/* <IssueItem src={mainIssueImg1}/>
                                                        <IssueItem src={mainIssueImg2}/>
                                                        <IssueItem src={mainIssueImg3}/>    */}
                                                    </SwiperSlide>
                                                )
                                            })                        
                                        }
                                </Swiper>                        
                            ) : null
                        }                                    
                    </IssueItemContainer>
                    <PrevButton className="swiper-button-prev-unique"><img src={ArrowLeft} /></PrevButton>
                    <NextButton className="swiper-button-next-unique"><img src={ArrowRight} /></NextButton>
                 </IssueSlideWrapper>
             </Wrapper>
         </Section>
         </PC>
         
         <Mobile>
         <Section>
            <Wrapper>
                <SectionHeadings>New Issue</SectionHeadings>
                <IssueSlideWrapper className="issue-slide-wrapper">
                    <IssueItemContainer>         
                        {
                            list.length > 0 ? (
                                <Swiper                         
                                    loop                                                
                                    spaceBetween={10}             
                                    slidesPerView={'auto'}
                                    >
                                        {
                                            list.map((v, i) => {
                                                return (
                                                    <SwiperSlide key={v?.contents_id}>
                                                        <IssueItem value={v} key={v?.contents_id}/>
                                                    </SwiperSlide>
                                                )
                                            })                        
                                        }
                                </Swiper>                        
                            ) : null
                        }                            
                    </IssueItemContainer>                   
                 </IssueSlideWrapper>
             </Wrapper>
         </Section>
         </Mobile>
         </>
     )
 }
 
 export default MainIssue;
                    
                    

