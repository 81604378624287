import React, { useState, useEffect } from 'react';
import { Link,useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { imgURL } from '../../../server/appInfoController';
import { Mobile, PC } from "../../../MediaQuery"
import styled from "styled-components"
import Commas from "../../../server/Commas";
import { DateToText } from '../../../../components/common/commonUse';

import ProductImg1 from "../../../../images/content/content-detail-prod1.png"
import ProductImg2 from "../../../../images/content/content-detail-prod2.png"
import ProductImg3 from "../../../../images/content/content-detail-prod3.png"

export default function StylistPayProduct() {
    const { payment } = useSelector(state => state.paymentData)
    const [list, setList] = useState([]);
    const [prdPrices,setPrdPrices] = useState(0)

    useEffect(() => {
        setList(payment ?? [])
    }, [payment])

    const betweenDay = (firstDate, secondDate) => {         
        var firstDateObj = new Date(firstDate);
        var secondDateObj = new Date(secondDate);
        var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime());
        return Math.floor(betweenTime / (1000 * 60 * 60 * 24));
    }

    return (
        <Container>
            <PayProductWrap>
                <TitleWrap>
                    <Title>주문상품</Title>
                </TitleWrap>
                <ProductListWrap>
                    {
                        list.map((value,index)=>{
                            const prdInfo = value?.purchase_prd ?? {}
                            const dateDiffer = (betweenDay(value?.start_date, value?.return_date) ?? 1) + 1
                            return(
                                <ProductBox>
                                    <ProductTitleWrap>
                                        <ProductTitle>{DateToText(value?.start_date ?? "", ".")} ~ {DateToText(value?.return_date ?? "", ".")}</ProductTitle>
                                        <ProductTitle>{Commas.numberWithCommas(dateDiffer * prdInfo?.prd_days_price)}원</ProductTitle>
                                    </ProductTitleWrap>
                                    <ProductContentWrap> 
                                        <ProductImgWrap>
                                            <ProductImg src={imgURL + prdInfo?.prd_main_img} alt="" />
                                        </ProductImgWrap>
                                        <ProductTextWrap>
                                            <ProductText>{prdInfo?.prd_name}</ProductText>
                                            {/* <ProductOption>옵션</ProductOption> */}
                                            <ProductPrice>
                                                {Commas.numberWithCommas(dateDiffer * prdInfo?.prd_days_price)}
                                            </ProductPrice>
                                        </ProductTextWrap>
                                    </ProductContentWrap>
                                </ProductBox>
                            )
                        })
                    }
                </ProductListWrap>
            </PayProductWrap>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    margin-bottom: 20px;

    @media screen and (max-width: 1320px) {
        margin-bottom: calc(100vw*(20/1320));
    }

    @media screen and (max-width: 930px) {
        margin-bottom: calc(100vw*(30/428));
        border-top: calc(100vw*(12/428)) solid #E9EBEE;
    }
`
const PayProductWrap = styled.div`
    width: 100%;

    @media screen and (max-width: 930px) {
        width: calc(100vw*(388/428));
        margin: 0 auto;
    }
` 
const TitleWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #d9d9d9;

    @media screen and (max-width: 1320px) {
        padding: calc(100vw*(20/1320)) 0;
        border-bottom: calc(100vw*(1/1320)) solid #d9d9d9;
    }

    @media screen and (max-width: 930px) {
        padding: calc(100vw*(30/428)) 0 calc(100vw*(10/428));
        border-bottom: none;
    }
` 
const Title = styled.p`
    font-size: 22px;
    font-weight: bold;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(22/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(18/428));
    }
` 
// Product List
const ProductListWrap = styled.div`
    width: 100%;
` 
const ProductBox = styled.div`
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
    border-radius: 11px;
    border: 1px solid #d9d9d9;
    overflow: hidden;

    @media screen and (max-width: 1320px) {
        margin-top: calc(100vw*(20/1320));
        margin-bottom: calc(100vw*(30/1320));
        border-radius: calc(100vw*(11/1320));
        border: calc(100vw*(1/1320)) solid #d9d9d9;
    }

    @media screen and (max-width: 930px) {
        margin-top: calc(100vw*(20/428));
        margin-bottom: calc(100vw*(30/428));
        border-radius: calc(100vw*(11/428));
        border: calc(100vw*(1/428)) solid #d9d9d9;
    }
` 
const ProductTitleWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 20px;
    background: #F7F7F7;

    @media screen and (max-width: 1320px) {
        padding: calc(100vw*(15/1320)) calc(100vw*(20/1320));
    }
    
    @media screen and (max-width: 930px) {
        padding: calc(100vw*(15/428)) calc(100vw*(20/428));
    }
` 
const ProductTitle = styled.p`
    font-size: 17px;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(17/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(16/428));
    }
` 
const ProductContentWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;

    @media screen and (max-width: 1320px) {
        padding: calc(100vw*(20/1320));
    }

    @media screen and (max-width: 930px) {
        padding: calc(100vw*(20/428));
    }
` 
const ProductImgWrap = styled.div`
    width: 80px; height: 80px;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(80/1320)); height: calc(100vw*(80/1320));
    }

    @media screen and (max-width: 930px) {
        width: calc(100vw*(80/428)); height: calc(100vw*(80/428));
    }
` 
const ProductImg = styled.img`
    width: 100%; height: 100%;
    object-fit: cover;
` 
const ProductTextWrap = styled.div`
    width: 100%;
    padding-left: 15px;

    @media screen and (max-width: 1320px) {
        padding-left: calc(100vw*(15/1320));
    }

    @media screen and (max-width: 930px) {
        padding-left: calc(100vw*(15/428));
    }
` 
const ProductText = styled.p`
    font-size: 15px;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(15/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(15/428));
    }
` 
const ProductOption = styled.p`
    font-size: 15px;
    color: #969696;
    margin: 8px 0;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(15/1320));
        margin: calc(100vw*(8/1320)) 0;
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(13/428));
        margin: calc(100vw*(8/428)) 0;
    }
` 
const ProductPrice = styled.p`
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: bold;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(17/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(17/428));
    }
` 
const ProductCount = styled.span`
    position: relative;
    font-size: 15px;
    font-weight: normal;
    color: #969696;
    margin-left: 8px;
    padding-left: 8px;
    &:before {content: ''; position: absolute; left: 0; top: 2px; display: block; width: 1px; height: 14px; background: #d9d9d9;}

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(15/1320));
        margin-left: calc(100vw*(8/1320));
        padding-left: calc(100vw*(8/1320));
        &:before {top: calc(100vw*(2/1320)); width: calc(100vw*(1/1320)); height: calc(100vw*(14/1320));}
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(15/428));
        margin-left: calc(100vw*(8/428));
        padding-left: calc(100vw*(8/428));
        &:before {top: calc(100vw*(2/428)); width: calc(100vw*(1/428)); height: calc(100vw*(14/428));}
    }
` 