import React, { useState } from "react";
import styled from "styled-components";

import HtmlParser from "react-html-parser";
import { imgURL } from "../../../server/appInfoController";

// image
import SelectBoxArrow from "../../../img/icon/icon_selectBox_arrow.svg";


export default function DetailProductImg({ product }) {
  const [moreButtonClick, setMoreButtonClick] = useState(false);
  const imgBoxHeight =
    document?.getElementById("product-img-wrap")?.offsetHeight;

  const IMG_URL_TOP1 = `${imgURL}contents/1685521026190_상세페이지_이벤트배너_패스.jpg`;
  const IMG_URL_TOP2 = `${imgURL}contents/1685441311831_2.jpg`;
  const IMG_URL_FOOT = `${imgURL}contents/1685441311844_3.jpg`;
  const PASS_PRD = [6001808, 6001807, 6001806, 6001805, 6001804, 6001803, 6001802, 6001801, 6001800, 6001799, 6001798, 6001797, 6001796, 6001795, 6001794, 6001793, 6001792, 6000219, 6000218, 6000217, 6000216, 6000215, 6000214, 6000213, 6000212, 6000211, 6000210, 6000209, 6000208, 6000207, 6000206, 6000205, 6000204, 6000203, 6000202, 6000201, 6000200, 6000183, 6000182, 6000181, 6000180, 6000179, 6000178, 6000177, 6000176, 6000175, 6000174, 6000173, 6000172, 6000171, 6000170, 6000169, 6000168, 6000167, 6000166, 6000165, 6000164, 6000163, 6000162, 6000161, 6000160, 6000159, 6000158, 6000157, 6000156, 6000155, 6000154, 6000153, 6000152, 6000151, 6000150, 6000149, 6000148, 6000147, 6000146, 6000145, 6000144, 6000143, 6000142, 6000141, 6000140, 6000139, 6000138, 6000137, 6000136, 6000135, 6000134, 6000133, 6000132, 6000131, 6000130, 6000129, 6000128, 6000127, 6000126, 6000125, 6000124, 6000123, 6000122, 6000121, 6000120, 6000119, 6000118, 6000117, 6000116, 6000115, 6000114, 6000113, 6000112, 6000111, 6000110];


  const findPassPrd = () => {
    const prdCode = Number(product.prd_code);
    return PASS_PRD.includes(prdCode);
  };

  return (
    <Container>
      <ProductImgWrap
        imgBoxHeight={imgBoxHeight}
        moreButtonClick={moreButtonClick}
      >
        <ProductImgInner>
          <ProductImgBox id="product-img-wrap">
            {product && findPassPrd() &&
              (
                <>
                  <img src={IMG_URL_TOP1} alt="pass_bnr" />
                  <img src={IMG_URL_TOP2} alt="pass_bnr" />
                </>
              )}
            {product && HtmlParser(product.prd_detail2)}
            {product && findPassPrd() && <img src={IMG_URL_FOOT} alt="pass_bnr" />}
          </ProductImgBox>
        </ProductImgInner>
        {/* {!moreButtonClick && imgBoxHeight >= 680 && (
          <>
            <BackGround />
            <ProductMoreButtonBox
              onClick={() => {
                setMoreButtonClick(true);
              }}
            >
              <ProductMoreButton>상품 정보 더보기</ProductMoreButton>
              <ProductMoreImg src={SelectBoxArrow} alt="selectArrow" />
            </ProductMoreButtonBox>
          </>
        )} */}
      </ProductImgWrap>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  padding: 20px 0 50px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0 calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0 calc(100vw * (30 / 428));
    width: calc(100vw * (378 / 428));
  }
`;
const ProductImgWrap = styled.div`
  width: 860px;
  margin: 0 auto;
  position: relative;

  /* ${({ moreButtonClick, imgBoxHeight }) =>
    !moreButtonClick &&
    imgBoxHeight >= 680 &&
    `height : 1635px; overflow : hidden;`} */

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (860 / 1320));

    /* ${({ moreButtonClick, imgBoxHeight }) =>
    !moreButtonClick &&
    imgBoxHeight >= 680 &&
    `height : calc(100vw * (1635 / 1320)); overflow : hidden;`}
  } */
  }

  @media screen and (max-width: 930px) {
    width: 100%;

    /* ${({ moreButtonClick, imgBoxHeight }) =>
    !moreButtonClick &&
    imgBoxHeight >= 680 &&
    `height : calc(100vw * (680 / 428)); overflow : hidden;`} */
  }
`;
const ProductImgInner = styled.div`
  width: 100%;
`;
const ProductImgBox = styled.div`
  width: 100%;
  /* width: calc(100% / 2); */
  text-align: center;

  & img {
    width: 100% !important;
    height: auto !important;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 1320px) {
    & img {
      margin-bottom: calc(100vw * (30 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    & img {
      margin-bottom: calc(100vw * (30 / 428));
    }
  }
`;
const BackGround = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(transparent, #fff);

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (120 / 428));
  }
`;

const ProductMoreButtonBox = styled.button`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 338px;
  height: 55px;
  background-color: #fff;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (338 / 1320));
    height: calc(100vw * (55 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (338 / 428));
    height: calc(100vw * (55 / 428));
  }
`;
const ProductMoreButton = styled.div`
  color: #333333;
  font-weight: 500;
  font-size: 18px;
  margin-right: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    margin-right: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    margin-right: calc(100vw * (10 / 428));
  }
`;
const ProductMoreImg = styled.img`
  width: 24px;
  height: 24px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (24 / 1320));
    width: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (24 / 428));
    width: calc(100vw * (24 / 428));
  }
`;
const ProductImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ProductFlexImg = styled.img`
  display: block;
  width: 100%;
  height: 285px;
  object-fit: cover;
  &:first-child {
    margin-bottom: 50px;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (285 / 1320));
    &:first-child {
      margin-bottom: calc(100vw * (50 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (280 / 428));
    margin-bottom: calc(100vw * (30 / 428));

    &:first-child {
      margin-bottom: calc(100vw * (30 / 428));
    }
  }
`;
//sub
const ProductImgSubWrap = styled.div`
  width: 100%;
  background: #f1f1f1;
  padding-top: 100px;
  padding-bottom: 200px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (100 / 1320));
    padding-bottom: calc(100vw * (200 / 1320));
  }
`;
const ImgSubWrap = styled.div`
  width: 1000px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1000 / 1320));
  }
`;
const ImgSub = styled.img`
  display: block;
  width: 100%;
`;
