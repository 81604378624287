//react
import React, { useState, useEffect } from "react";
import serverController from "../../../server/serverController";

//css
import styled from "styled-components";

import SearchAdressPop from "./SearchAdressPop";

//img
import IconClose from "../../../img/icon/icon_popup_close.svg";
import IconChkOn from "../../../img/icon/icon_chk_on.svg";
import IconChkOff from "../../../img/icon/icon_chk_off.svg";

export default function OrderedAdressEditModal({ orderId, setIsAddressModal, getPaymentHistory }) {
  // { title, setIsModal, current, getData = false }
  const body = document.querySelector("body");
  const [adrTitle, setAdrTitle] = useState("");
  const [adrName, setAdrName] = useState("");
  const [phone, setPhone] = useState("");

  const [adrZipCode, setAdrZipCode] = useState("");
  const [adrAddress, setAdrAddress] = useState("");
  const [adrDetail, setAdrDetail] = useState("");
  const [mainCheck, setMainCheck] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const addressData = {
    setAdrAddress,
    setAdrZipCode,
    setIsSearch,
    isSearch,
  };

  const handlePatch = async () => {
    if (
      adrName.length === 0 ||
      phone.length === 0 ||
      adrZipCode.length === 0 ||
      adrAddress.length === 0 ||
      adrDetail.length === 0
    ) {
      return alert("배송지 정보를 모두 입력해주세요.");
    }

    await serverController.connectFetchController(`payment/address?receiverName=${adrName}&receiverPhone=${phone}&zipcode=${adrZipCode}&basicAddress=${adrAddress}&detailAddress=${adrDetail}&orderId=${orderId}`, "PUT", null, (res) => {
      if (res?.result === 1) {
        setIsAddressModal(false);
        // if (getData !== false) {
        //   getData();
        // }
        getPaymentHistory();
        alert("변경되었습니다.");
      };
      if (res?.result === 0) {
        alert("등록에 실패하였습니다. 다시 시도해주세요.");
      }
    });
  };


  // modal background overflow hidden
  useEffect(() => {
    if (isSearch) {
      body.style.overflow = "hidden";
      body.style.height = "100vh";
    } else {
      body.style.overflow = "auto";
      body.style.height = "auto";
    }
  }, [isSearch]);

  useEffect(() => {
    let isActive = false;
    if (
      adrName.length === 0 ||
      phone.length === 0 ||
      adrZipCode.length === 0 ||
      adrAddress.length === 0 ||
      adrDetail.length === 0
    ) {
      isActive = false;
    } else {
      isActive = true;
    }
    setIsButtonActive(isActive);
  }, [adrName, phone, adrZipCode, adrAddress, adrDetail]);

  return (
    <Container>
      <Background
        onClick={() => {
          if (isSearch) {
            setIsSearch(false);
          } else {
            setIsAddressModal(false);
          }
        }}
      />
      <Content>
        <CloseButton
          type="button"
          onClick={() => {
            if (isSearch) {
              setIsSearch(false);
            } else {
              setIsAddressModal(false);
            }
          }}
        >
          닫기
        </CloseButton>
        <TitleCont>
          <Title>배송지 변경</Title>
        </TitleCont>
        <ListWrap isSearch={isSearch}>
          {isSearch && (
            <SearchAddressPopWrap>
              <SearchAdressPop {...addressData} />
            </SearchAddressPopWrap>
          )}
          <ListArea>
            <AddressList>
              <InputText>받는사람</InputText>
              <Input
                type="text"
                value={adrName}
                active={adrName.length > 0}
                placeholder="수령인을 입력해주세요."
                onChange={(e) => {
                  setAdrName(e.target.value);
                }}
              />
            </AddressList>
            <AddressList>
              <InputText>휴대폰번호</InputText>
              <Input
                type="number"
                value={phone}
                active={phone.length > 0}
                placeholder="-를 제외한 숫자만 입력해주세요."
                onChange={(e) => {
                  if (e.target.value.length > 11) return;
                  setPhone(e.target.value);
                }}
              />
            </AddressList>
            <AddressList>
              <InputText>주소</InputText>
              <DepthInner>
                <AdrInputFlex>
                  <AdrInput
                    type="text"
                    value={adrZipCode}
                    active={adrZipCode.length > 0}
                    readOnly
                    onChange={(e) => {
                      setAdrZipCode(e.target.value);
                    }}
                  />
                  <FindBtn type="button" onClick={() => setIsSearch(true)}>
                    주소찾기
                  </FindBtn>
                </AdrInputFlex>
                <FullWrap>
                  <AdrInputFull
                    type="text"
                    value={adrAddress}
                    active={adrAddress.length > 0}
                    readOnly
                    onChange={(e) => {
                      setAdrAddress(e.target.value);
                    }}
                  />
                  <AdrInputFull
                    type="text"
                    value={adrDetail}
                    active={adrDetail.length > 0}
                    placeholder="상세 주소를 입력해주세요."
                    onChange={(e) => {
                      setAdrDetail(e.target.value);
                    }}
                  />
                </FullWrap>
              </DepthInner>
            </AddressList>
          </ListArea>
        </ListWrap>
        {!isSearch && (
          <SaveBtnWrap>
            <SaveBtn
              type="button"
              onClick={() => {
                if (!isButtonActive) return;
                handlePatch();
              }}
              isButtonActive={isButtonActive}
            >
              변경
            </SaveBtn>
          </SaveBtnWrap>
        )}
        <CloseButton
          type="button"
          onClick={() => {
            if (isSearch) {
              setIsSearch(false);
            } else {
              setIsAddressModal(false);
            }
          }}
        >
          닫기
        </CloseButton>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 105;
`;

// 컨텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 520px;
  background-color: #fff;
  z-index: 105;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (520 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (398 / 428));
  }
`;

const TitleCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 83px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (83 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (71 / 428));
  }
`;
const Title = styled.h2`
  color: #333;
  font-weight: 500;
  font-size: 20px;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;

const ListWrap = styled.div`
  width: 100%;
  max-height: 457px;
  overflow-y: auto;
  position: relative;

  ${({ isSearch }) => isSearch && `overflow : hidden;`}

  @media screen and (max-width: 1320px) {
    max-height: calc(100vw * (457 / 1320));
  }
  @media screen and (max-width: 930px) {
    max-height: calc(100vh * (508 / 925));
  }
`;
const ListArea = styled.div`
  position: relative;
  width: 460px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (460 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (348 / 428));
  }
`;
const AddressList = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 428));
    }
  }
`;

const DepthInner = styled.div``;
const AdrInputFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const FindBtn = styled.button`
  width: 100px;
  height: 50px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  margin-left: 8px;
  background-color: #000;
  border: 1px solid #000;
  flex-shrink: 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (16 / 1320));
    margin-left: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (16 / 428));
    margin-left: calc(100vw * (8 / 428));
  }
`;

const SaveBtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (110 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (110 / 428));
  }
`;
const SaveBtn = styled.button`
  display: block;
  width: 220px;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;
  margin: 0 auto;
  ${({ isButtonActive }) =>
    !isButtonActive &&
    `
  background-color : #bbbbbb;
  border : solid #bbbbbb 1px;
  `}

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (220 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (348 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
const InputText = styled.p`
  width: 100%;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 8px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-bottom: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-bottom: calc(100vw * (8 / 428));
  }
`;
const Input = styled.input`
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  padding: 0 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  transition: ease 0.3s all;
  flex: 1;
  &::placeholder {
    color: #acacac;
  }
  &:focus {
    border: 1px solid #000;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: 0 calc(100vw * (15 / 428));
  }
`;
const AdrInput = styled(Input)`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (240 / 428));
  }
`;
const FullWrap = styled.div`
  width: 100%;
`;
const AdrInputFull = styled(Input)`
  width: 100%;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (10 / 428));
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  width: 32px;
  height: 32px;
  font-size: 0;
  background: url(${IconClose}) no-repeat center / cover;

  @media screen and (max-width: 1320px) {
    top: calc(100vw * (25 / 1320));
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: calc(100vw * (15 / 428));
    right: calc(100vw * (18 / 428));
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
  }
`;

// input check
const AdrChkWrap = styled.div`
  margin-top: 15px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (15 / 428));
  }
`;
const AdrChkInput = styled.input`
  display: none;

  &:checked + label .chk-img {
    background: url(${IconChkOn}) no-repeat center / contain;
  }
`;
const AdrChkLabel = styled.label`
  display: inline-flex;
  align-items: center;
`;
const AdrChk = styled.div`
  width: 24px;
  height: 24px;
  background: url(${IconChkOff}) no-repeat center / contain;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const AdrChkText = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (10 / 428));
  }
`;

// search pop
const SearchAddressPopWrap = styled.div`
  /* position: absolute; */
  /* width: 100%;
  height: 100%; */
  max-height: calc(100vh * (516 / 1080));
  z-index: 9;
  @media screen and (max-width: 930px) {
    width: calc(100vh * (398 / 925));
    height: calc(100vh * (425 / 925));
  }
`;
