import styled, { css, keyframes } from "styled-components";
import {            
    pc,
    mobil,
    mquery,
    Media,
    Px,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
} from '../common/mediaStlye';

export const DramaItemsContainer = styled.div`
    padding-bottom: calc(100vw * (53 / 1920));
`

export const Item = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    margin: 0 auto;
    @media ${props => props.theme.container} {
        flex-direction: column;
    }
`

export const DramaItemLeft = styled.div`
    cursor: pointer;
`

export const DramaImg = styled.div`
    width: calc(100vw * (600 / 1920));
    height: calc(100vw * (600 / 1920));
    line-height: 0;
    border-radius: calc(100vw * (22 / 1920));
    overflow: hidden;

    & img {
        width: 100%;
        height:100%;
        object-fit: cover;
    }
    @media ${props => props.theme.container} {
        position: relative;
        width: 100%;
        border-radius: calc(100vw * (20 / 428));
        ${[
            Height(320, mobil),
        ].join("")}
    }
`

export const DramaItemRight = styled.div`
    flex: 1;
`

export const ItemHead = styled.div`    
    ${Height(170, pc)}
    overflow: hidden;
    width: 100%;
    padding:calc(100vw * (25 / 1920))  calc(100vw * (31 / 1920)) calc(100vw * (32 / 1920));

    & > h4 {
        font-size: calc(100vw * (30 / 1920));
        font-weight: 900;
        padding-bottom: calc(100vw * (17 / 1920));
        color: #222;
        line-height: 1;
    }
    & > p {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #222;
        line-height: 1.6;
    }

    @media ${props => props.theme.container} {
        padding: 0;
        height: auto;
        /* ${Height(60, mobil)} */
        ${MB(15, mobil)}

        & h4 {
            ${[
                FontSize(18, mobil),
                PB(7, mobil)
            ].join("")}
        }

        & p {
            ${FontSize(16, mobil)}
            color: #222;
            line-height: 1.5;
        }
        & > p {
            -webkit-line-clamp: 2;
            line-height: 1.6;
        }
    }
`

export const ItemProducts = styled.div`
    display: flex;    
    position: relative;
    padding: calc(100vw * (37 / 1920)) calc(100vw * (30 / 1920));
    @media ${props => props.theme.container} {
        padding:0;        
        flex-grow: 1;
        justify-content: space-between;
        gap: ${Px(24, mobil)};
        ${MT(20, mobil)}
        ${PB(0, mobil)}

    }
`

export const ItemBackground = styled.div`
    position: absolute;
    left: -100%;
    top: calc(100vw * (280 / 1920));
    z-index: -1;
    width: 200vw;
    height: calc(100vw * (340 / 1920));
    background-color: #dbdbdb;
    /* color: #dac619; */
    @media ${props => props.theme.container} {
        left:-10%;
        right:-10%;
        top: auto;
        
        ${[
            Height(209, mobil),
            Bottom(200, mobil),
        ].join("")}
    }
`

export const Product = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-right: calc(100vw * (40 / 1920));
    width: calc(100vw * (188 / 1920));
    cursor: pointer;

    .main_drama_thumb {
        margin-bottom: calc(100vw * (14 / 1920));
        width: 100%;
    }

    & img {
        width: 100%;
        height: calc(100vw * (262 / 1920));
        object-fit: cover;
    }
    & > strong {
        display: inline-block;
        width: 100%; height: calc(100vw * (18/ 1920));
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;        
        font-size: calc(100vw * (15 / 1920));
        line-height: 1.4;
        font-weight: 900;
    }

    @media ${props => props.theme.container} {     
        width: calc(100% / 3 - calc(100vw*(12/428)));
        /* flex: 1; */
        margin-right: 0;

        & .main_drama_thumb {

        }

        & img {
            object-fit: contain;
            ${[
                Height(100, mobil)                
            ].join("")}
        }

        & strong {
            height: calc(100vw * (18/ 428));
            ${FontSize(13, mobil)}
        }
        
    }
`

export const Arrows = styled.div`

`

export const ControllBox = styled.div`
    width : calc(100vw * (194 / 1920));
    position: absolute;
    right: 0;
    bottom:0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: calc(100vw * (20 / 1920));
    font-weight: 400;
    & .swiper-pagination-fraction {  
        font-size: calc(100vw * ( 39 / 1920));   
        margin:0 calc(100vw * (15 / 1920)) 0;
        color: #D9D9D9;        
        text-align: center;
    }
    & .swiper-pagination-fraction span {font-weight: bold;}

    & .swiper-pagination-current {         
        color: #8B8B8B;
        font-weight: 400;
        font-size: calc(100vw * ( 39 / 1920));
    }
    & .swiper-pagination-total {
        color: #D9D9D9;        
        font-weight: 400;
        font-size: calc(100vw * ( 39 / 1920));
    }
    
    & .arrow-button {
        position: absolute; top: calc(100vw * (-59 / 1920));
        transform :translateY(-4px);
        width: calc(100vw * (14 / 1920));
        height: calc(100vw * (27 / 1920));
        line-height: 0;
        cursor: pointer;
    }
    & .arrow-button.drama-next-button {left: calc(100vw * (150 / 1920));}
    & .arrow-button.drama-prev-button {left: 0;}
    & .arrow-button > img {
        width: 100%;
        height: 100%;
    }

    @media ${props => props.theme.container} {
        width : calc(100vw * (105 / 428));
        ${[
            Right(0,mobil),
            Bottom(310, mobil)
        ].join("")}
        & .swiper-pagination-fraction {  
            color: #fff;
            ${[
                FontSize(24, mobil),
                Margin2(0, 8, mobil)
            ].join("")}              
            letter-spacing: -2px;
        }

        & .swiper-pagination-current {         
            color: #8B8B8B;
            ${[
                FontSize(24, mobil),                
            ].join("")}
        }
        & .swiper-pagination-total {
            color: #fff;
            ${[
                FontSize(24, mobil),                
            ].join("")}
        }
        
        & .arrow-button {
            display: none;
        }
        & .arrow-button.drama-next-button {left: auto; right: 0;}
        & .arrow-button.drama-prev-button {left: calc(100vw * (-45/ 428));}
    }
`