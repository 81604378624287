import React from 'react';
import { PC, Mobile } from '../../MediaQuery';
import styled, { css } from 'styled-components';
import { UserDataAction } from '../../store/actionCreators';
import { useHistory } from 'react-router-dom';
import serverController from '../../server/serverController';

import {
    LogoutButton
} from '../auth/mypage/mypage.elements';


export default function ManagementType() {
    const history = useHistory();

    const onHandleLogout = () => {
        UserDataAction.logOutRequest();
        serverController.connectFetchController(
            `logout`,
            "POST",
            null,
            (res) => {
                console.log(res, "로그아웃 response");
                // history.push(`/signup/success`);
            },
            (err) => console.error(err)
        )
        history.push('/');
    }

    return (
        <>
            <Mobile>
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '25px' }}>
                    <LogoutButton onClick={onHandleLogout}>로그아웃</LogoutButton>
                </div>
            </Mobile>
            <ManagementWrap>
                <ManagementContainer>
                    <ManagementTypeEl>
                        <TypeHead onClick={() => {
                            history.push('/management/list')
                        }}>
                            <Title>협찬</Title>
                            <Arrow />
                        </TypeHead>
                        <InformWrap>
                            <InformEl>
                                <Col>
                                    <Dt>신규 신청:</Dt>
                                    <Dl>04</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>미 처리 신청:</Dt>
                                    <Dl>11</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>처리 대기:</Dt>
                                    <Dl>03</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>반려:</Dt>
                                    <Dl>25</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>협찬 중:</Dt>
                                    <Dl>15</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>반납 임박:</Dt>
                                    <Dl>09</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>반납 완료:</Dt>
                                    <Dl>09</Dl>
                                </Col>
                            </InformEl>
                        </InformWrap>
                        <IssueWrap>
                            <IssueEl>
                                <Dt>문의</Dt>
                                <Dl>15건</Dl>
                            </IssueEl>
                            <IssueEl>
                                <Dt>결제</Dt>
                                <Dl>31건</Dl>
                            </IssueEl>
                            <IssueEl>
                                <Dt>이미지 공유</Dt>
                                <Dl>22건</Dl>
                            </IssueEl>
                        </IssueWrap>
                    </ManagementTypeEl>
                    <ManagementTypeEl>
                        <TypeHead onClick={() => {
                            history.push('/management/list')
                        }}>
                            <Title>일반대여</Title>
                            <Arrow />
                        </TypeHead>
                        <InformWrap>
                            <InformEl>
                                <Col>
                                    <Dt>21.07.20 신규 신청:</Dt>
                                    <Dl>04</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>미 처리 신청:</Dt>
                                    <Dl>11</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>처리 대기:</Dt>
                                    <Dl>03</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>협찬 중:</Dt>
                                    <Dl>25</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>반납 임박:</Dt>
                                    <Dl>15</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>반납 완료:</Dt>
                                    <Dl>09</Dl>
                                </Col>
                            </InformEl>
                        </InformWrap>
                        <IssueWrap>
                            <IssueEl>
                                <Dt>문의</Dt>
                                <Dl>15건</Dl>
                            </IssueEl>
                            <IssueEl>
                                <Dt>결제</Dt>
                                <Dl>31건</Dl>
                            </IssueEl>
                            <IssueEl>
                                <Dt>이미지 공유</Dt>
                                <Dl>22건</Dl>
                            </IssueEl>
                        </IssueWrap>
                    </ManagementTypeEl>
                    <ManagementTypeEl>
                        <TypeHead onClick={() => {
                            history.push('/management/list')
                        }}>
                            <Title>판매</Title>
                            <Arrow />
                        </TypeHead>
                        <InformWrap>
                            <InformEl>
                                <Col>
                                    <Dt>일간 판매량:</Dt>
                                    <Dl>333</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>결제 대기:</Dt>
                                    <Dl>55</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>배송 준비:</Dt>
                                    <Dl>22</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>배송1 완료:</Dt>
                                    <Dl>11</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>환불 요청:</Dt>
                                    <Dl>03</Dl>
                                </Col>
                            </InformEl>
                            <InformEl>
                                <Col>
                                    <Dt>환불 처리 결과:</Dt>
                                    <Dl>03</Dl>
                                </Col>
                            </InformEl>
                        </InformWrap>
                        <IssueWrap>
                            <IssueEl>
                                <Dt>문의</Dt>
                                <Dl>15건</Dl>
                            </IssueEl>
                            <IssueEl>
                                <Dt>결제</Dt>
                                <Dl>31건</Dl>
                            </IssueEl>
                            <IssueEl>
                                <Dt>이미지 공유</Dt>
                                <Dl>22건</Dl>
                            </IssueEl>
                        </IssueWrap>
                    </ManagementTypeEl>
                </ManagementContainer>
            </ManagementWrap>
        </>
    )
}
const Arrow = styled.div`
    border-right:3px solid #6A6A6A; border-bottom:3px solid #6A6A6A; width:16px; height:16px; display:inline-block; transform:translateY(-50%) rotate(-45deg); line-height:inherit; vertical-align:center; margin-left:2px; position:absolute; right:calc(100vw * (36 / 1920)); top:50%;
    @media screen and (max-width:1024px){
        border-right:2px solid #BEBEBE; border-bottom:2px solid #BEBEBE;
        right:calc(100vw * (25 / 428));
    }
`;

const ManagementWrap = styled.div`
    position:relative;
    margin:0 auto;
    width: calc(100vw * (1300 / 1920));
    margin-top:36px;
    margin-bottom:calc(100vw * (147 / 1920));

    @media screen and (max-width:768px){
        width:100%; padding-left:calc(100vw * (25 / 428)); padding-right:calc(100vw * (25 / 428));
        padding-top:36px;
    }
`;
const ManagementContainer = styled.div`
    width:100%; display:flex; justify-content: space-between;
    

    @media screen and (max-width:1024px){
        flex-direction:column;
    }
`;
const ManagementTypeEl = styled.div`
    width:calc(33.33333% - (32px / 3)); border:1px solid #A5A5A5; border-radius:21px; overflow:hidden; padding-bottom:36px;
    display:flex; flex-direction: column;

    @media screen and (max-width:1024px){
        width:100%;
        &:not(:last-child){
            margin-bottom:30px;
        }
    }
`;
const TypeHead = styled.div`
    padding:28px calc(100vw * (36 / 1920)) 34px; background:#F7F7F8; position:relative; cursor:pointer;

    @media screen and (max-width:768px){
        padding-top:26px; padding-bottom:30px;
    }
    
`;
const Title = styled.p`
    text-align:center; font-size:25px; font-weight:700;
`;
const InformWrap = styled.div`
    padding:0 calc(100vw * (36 / 1920));
    margin:calc(100vw * (26 / 1920)) 0 calc(100vw * (36 / 1920));
    @media screen and (max-width:1024px){
        padding:0 calc(100vw * (25 / 428));
    }
`;
const InformEl = styled.div`
    border-bottom:1px solid #A5A5A5;
    @media screen and (max-width:1024px){
        border-color:#D8D8D8;
    }
`;
const Col = styled.div`
    display:flex; justify-content: space-between; width:100%; padding:8px 22px;
`;
const Dt = styled.p`
    font-size:15px; font-weight:900;
`;
const Dl = styled.p`
    font-size:15px; font-weight:900;
`;
const IssueWrap = styled.div`
    display:flex; justify-content:space-between;
    padding:0 calc(100vw * (36 / 1920)); margin-top:auto;

    @media screen and (max-width:1300px) and (min-width:1025px){
        flex-direction:column;
    }
    @media screen and (max-width:1024px){
        margin-top:36px; padding:0 calc(100vw * (25 / 428));
    }
`;
const IssueEl = styled.div`
    width:calc(33.33333% - (12px / 3)); border:1px solid #A5A5A5;
    text-align:center;
    ${Dt}{
        font-size:17px; font-weight:700; padding:10px 0; border-bottom:1px solid #A5A5A5; background:#F7F7F8;

        @media screen and (max-width:1024px){
            padding:calc(100vw * (10 / 428)) 0;
            border-color:#D8D8D8;
        }
    }
    ${Dl}{
        font-size:15px; font-weight:700; padding:10px 0;
    }

    @media screen and (max-width:1300px) and (min-width:1025px){
        width:100%;
        &:not(:last-child) {
            margin-bottom:14px;
        }
    }
`;