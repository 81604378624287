import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import Complete from "../../component/common/complete/Complete";
import Footer from "../../component/common/StoreFooter";
import FooterMenu from "../../component/common/FooterMenu";
import FixMenu from "../../component/common/FixMenu";

export default function InquiryCompletePage() {
  return (
    <>
      <Header search={true} />
      <Complete
        title="문의글 작성을 완료했습니다."
        btn="문의목록 바로가기"
        url="/store/mypage/inquiry?page=1"
        rank={false}
      />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
      <FixMenu />
    </>
  );
}
