import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import MyPageHeader from "../mypage/MyPageHeader";
import DetailPhotoReview from "../../component/product/detail/DetailPhotoReview";

export default function PhotoReviewPage() {
  return (
    <>
      <PC></PC>

      <Mobile>
        <MyPageHeader title={"포토리뷰"} prevBtn />
        <DetailPhotoReview />
      </Mobile>
    </>
  );
}
