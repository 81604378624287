import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import serverController from "../../store_src/MediaQuery";
import { Mobile, PC } from "MediaQuery";
import { Get } from "api/index";
import { queryKeys } from "constants/queryKeys";
import usePlanInfiniteScroll from "hooks/usePlanInfiniteScroll";
import PlanPrdList from "newComponents/storePlan/PlanPrdList";
import InfiniteScroll from "react-infinite-scroller";

export default function StorePlanProductList({ planData, children }) {
  const searchParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const tab = searchParams.get("tab");
  const limit = searchParams.get("limit");
  const body = document.querySelector("body");

  useEffect(() => {
    body.style.overflow = "visible";
    return () => {};
  }, []);

  const eventCategoryId = tab ? Number(tab) : planData.eventCategoryList[0].eventCategoryId;

  const requestData = {
    requestAPI: Get.getPlanList,
    requestQueryString: {
      eventCategoryId: eventCategoryId,
    },
    queryKey: [queryKeys.STORE_PLAN_LIST, Number(tab)],
    options: {
      retry: 0,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      keepPreviousData: true,
    },
  };

  const { data, hasNextPage, fetchNextPage, isError, isFetching, isLoading, isSuccess, refetch } =
    usePlanInfiniteScroll(requestData);
  console.log("data: ", data);

  return (
    <Container>
      <PlanNavWrap>{children}</PlanNavWrap>
      {isSuccess && (
        <PlanInner
          className="infinite-scroll-wrapper"
          loadMore={() => {
            fetchNextPage();
          }}
          hasMore={hasNextPage}
          threshold={450}
        >
          {data.pages.map((arr) =>
            arr.eventProductList.map((product, index) => {
              return <PlanPrdList key={index} value={product} />;
            }),
          )}
          {/* {data.pages[0].eventProductList.map((product, index) => {
            return <PlanPrdList key={index} value={product} />;
          })} */}
        </PlanInner>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: auto;
  scroll-margin-top: 300px;
  @media screen and (max-width: 1320px) {
    scroll-margin-top: calc(100vw * (300 / 1320));
  }

  @media screen and (max-width: 930px) {
    scroll-margin-top: calc(100vw * (120 / 428));
  }
`;

const PlanNavWrap = styled.div`
  position: sticky;
  max-width: 1280px;
  margin: auto;
  width: 100%;
  top: 109px;
  z-index: 4;

  @media screen and (max-width: 1320px) {
    top: calc(100vw * (109 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: calc(100vw * (55 / 428));
  }
`;
const PlanInner = styled(InfiniteScroll)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-top: 50px;
  min-height: 500px;

  & .product_box {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (50 / 1320));

    & .product_box {
      &:not(:last-child) {
        margin-right: calc(100vw * (8 / 1320));
      }
    }
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (20 / 428));
    padding-bottom: calc(100vw * (20 / 428));
    justify-content: space-between;
    width: calc(100vw * (378 / 428));

    & .product_box {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
`;
