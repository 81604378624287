//react
import { useState } from "react";

//css
import styled from "styled-components";

import HtmlParser from "react-html-parser";

// img
import ProductDetailImg from "../../../img/product/product_detail_sub_img.png";

//page
import Pagination from "../../common/pagination/Pagination";
import { imgURL } from "../../../server/appInfoController";

export default function MyNotice({ noticeList }) {
  return (
    <Container>
      <NoticeListWrap>
        <Content>
          <Cont>
            <NoticeUl>
              {noticeList.length > 0 ? (
                noticeList.map((noticeItem, index) => {
                  return <NoticeLi noticeItem={noticeItem}></NoticeLi>;
                })
              ) : (
                <NoText>공지사항이 없습니다.</NoText>
              )}
            </NoticeUl>
          </Cont>
        </Content>
      </NoticeListWrap>
    </Container>
  );
}

const NoticeLi = ({ noticeItem }) => {
  const [active, setActive] = useState(false);
  return (
    <>
      <TitleWrap
        onClick={() => {
          setActive(!active);
        }}
      >
        <Title>{noticeItem.notice_title}</Title>
        <Date>{noticeItem.notice_modify_date.replaceAll("-", ".")}</Date>
      </TitleWrap>
      {active && (
        <AnswerWrap className="answer-text">
          <AnswerInner>
            <AnswerText>{HtmlParser(noticeItem.notice_content)}</AnswerText>
            <AnswerImg>
              <img src={imgURL + noticeItem.notice_thumbnail} alt="product-img" />
              <img src={imgURL + noticeItem.notice_img} alt="product-img" />
            </AnswerImg>
          </AnswerInner>
        </AnswerWrap>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
`;
const NoticeListWrap = styled.div`
  @media screen and (max-width: 930px) {
    padding: 0 0 calc(100vw * (20 / 428));
  }
`;
const Content = styled.div``;
const Cont = styled.div``;
const NoticeUl = styled.div``;
// const NoticeLi = styled.li``;
const TitleWrap = styled.div`
  border-bottom: 1px solid #eee;
  background-color: #fff;
  padding: 16px 20px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (16 / 1320)) calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (16 / 428)) calc(100vw * (25 / 428));
  }
`;
const Title = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const Date = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;

const AnswerWrap = styled.div``;
const AnswerInner = styled.div`
  background-color: #f9f9f9;
  padding: 30px 35px 50px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) calc(100vw * (35 / 1320)) calc(100vw * (50 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) calc(100vw * (25 / 428)) calc(100vw * (40 / 428));
  }
`;
const AnswerText = styled.div`
  color: #333;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const AnswerImg = styled.div`
  max-width: 680px;
  width: 100%;
  height: auto;
  margin: 0 auto;

  & img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  @media screen and (max-width: 1320px) {
    max-width: calc(100vw * (680 / 1320));
  }
  @media screen and (max-width: 930px) {
    max-width: 100%;
  }
`;

const NoText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: #999;
  font-size: 15px;
  padding: 60px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (60 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-top: calc(100vh * (330 / 925));
    padding: calc(100vw * (50 / 428)) 0;
  }
`;
