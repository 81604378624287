import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import casualM from "../../../img/personalInfo/man-casual.jpg";
import classicM from "../../../img/personalInfo/man-classic.jpg";
import dandyismM from "../../../img/personalInfo/man-dandyism.jpg";
import minimalM from "../../../img/personalInfo/man-minimal.jpg";
import modernM from "../../../img/personalInfo/man-modern.jpg";
import sportsM from "../../../img/personalInfo/man-sports.jpg";
import streetM from "../../../img/personalInfo/man-street.jpg";
import uniqueM from "../../../img/personalInfo/man-unique.jpg";

import casualW from "../../../img/personalInfo/woman-casual.jpg";
import classicW from "../../../img/personalInfo/woman-classic.jpg";
import feminineW from "../../../img/personalInfo/woman-feminine.jpg";
import minimalW from "../../../img/personalInfo/woman-minimal.jpg";
import modernW from "../../../img/personalInfo/woman-modern.jpg";
import romanticW from "../../../img/personalInfo/woman-romantic.jpg";
import sportsW from "../../../img/personalInfo/woman-sports.jpg";
import streetW from "../../../img/personalInfo/woman-street.jpg";

const manStyleImageArr = [
  { src: casualM, text: "캐쥬얼" },
  { src: classicM, text: "클래식" },
  { src: dandyismM, text: "댄디" },
  { src: minimalM, text: "미니멀" },
  { src: modernM, text: "모던" },
  { src: sportsM, text: "스포츠" },
  { src: streetM, text: "스트릿" },
  { src: uniqueM, text: "유니크" },
];
const womanStyleImageArr = [
  { src: casualW, text: "캐쥬얼" },
  { src: classicW, text: "클래식" },
  { src: feminineW, text: "페미닌" },
  { src: minimalW, text: "미니멀" },
  { src: modernW, text: "모던" },
  { src: romanticW, text: "로맨틱" },
  { src: sportsW, text: "스포츠" },
  { src: streetW, text: "스트릿" },
];

const StyleImageBoxComp = ({ styleImg, setClickStyleArr }) => {
  const [isClick, setIsClick] = useState(false);

  const clickImgBox = (clickV) => {
    setClickStyleArr((prevArr) => {
      const newArr = [...prevArr];
      if (clickV) {
        return newArr.concat(styleImg.text);
      } else {
        return newArr.filter((item) => item !== styleImg.text);
      }
    });
  };

  return (
    <StyleImageBox
      onClick={() => {
        setIsClick(!isClick);
        clickImgBox(!isClick);
      }}
    >
      <img src={styleImg.src} alt={styleImg.text} />
      <StyleImageBoxText isActive={isClick}>{styleImg.text || "no data"}</StyleImageBoxText>
    </StyleImageBox>
  );
};

export default function StepTwo(props) {
  const { clickStyleArr, setClickStyleArr, clickGenderBox } = props;
  const history = useHistory();
  const [styleArr, setStyleArr] = useState([]);

  const onClickNextStep = () => {
    if (clickStyleArr.length === 0) return;
    history.push(`/store/mypage/personal/3`);
  };

  useEffect(() => {
    setClickStyleArr([]);
    setStyleArr(clickGenderBox === 0 ? manStyleImageArr : womanStyleImageArr);
  }, [clickGenderBox]);

  return (
    <Container>
      <StyleTextWrap>
        선호하시는 스타일을 선택해주세요. <em>(중복 선택 가능)</em>
      </StyleTextWrap>
      <StyleImageWrap>
        {styleArr.map((styleImg, idx) => {
          return (
            <StyleImageBoxComp
              key={`${styleImg}-step2-${idx}`}
              styleImg={styleImg}
              setClickStyleArr={setClickStyleArr}
            />
          );
        })}
      </StyleImageWrap>
      <StepNextButtonWrap>
        <StepPrevButton
          onClick={() => {
            history.push(`/store/mypage/personal/1`);
          }}
        >
          이전으로
        </StepPrevButton>
        <StepNextButton isActive={clickStyleArr.length > 0} onClick={onClickNextStep}>
          다음으로
        </StepNextButton>
      </StepNextButtonWrap>
    </Container>
  );
}

const Container = styled.div``;
const StyleTextWrap = styled.p`
  text-align: center;
  font-weight: 900;
  > em {
    color: #d9d9d9;
  }
  font-size: 16px;
  margin-bottom: 40px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-bottom: calc(100vw * (40 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (40 / 428));
  }
`;
const StyleImageWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 800px;
  margin: 0 auto;
  row-gap: 8px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (800 / 1320));
    row-gap: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    row-gap: calc(100vw * (8 / 428));
  }
`;
const StyleImageBox = styled.div`
  width: 49%;
  position: relative;
  height: 500px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (500 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (217 / 428));
  }
`;
const StyleImageBoxText = styled.p`
  opacity: 0;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease;
  font-size: 16px;
  ${({ isActive }) => isActive && `opacity : 1;`}
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const StepNextButtonWrap = styled.div`
  text-align: center;
  display: flex;
  margin-top: 100px;
  width: 800px;
  margin: 100px auto 0;

  @media screen and (max-width: 1320) {
    width: calc(100vw * (800 / 1320));
    margin: calc(100vw * (100 / 1320)) auto 0;
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    margin: calc(100vw * (100 / 428)) 0 0;
  }
`;
const StepNextButton = styled.button`
  margin: 0 auto;
  background-color: #999;
  color: #fff;
  font-size: 16px;
  height: 59px;
  width: 300px;
  ${({ isActive }) => isActive && `background-color : #000;`}
  @media screen and (max-width: 1320) {
    font-size: calc(100vw * (16 / 1320));
    height: calc(100vw * (59 / 1320));
    width: calc(100vw * (300 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    height: calc(100vw * (59 / 428));
    width: calc(100vw * (185 / 428));
  }
`;
const StepPrevButton = styled(StepNextButton)`
  border: 1px solid #222;
  background-color: #fff;
  color: #222;
`;
