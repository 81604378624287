import { enableAllPlugins } from 'immer';
import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
enableAllPlugins();

const UPDATE_PRODUCT_INFO = 'prodDetailData/UPDATE_PRODUCT_INFO'; 
const SELECT_OPTIONS = 'prodDetailData/SELECT_OPTIONS'; 
const RESET_DATA = 'prodDetailData/RESET_DATA'; 

export const updateProductInfo = createAction(UPDATE_PRODUCT_INFO);
export const selectOptions = createAction(SELECT_OPTIONS);
export const resetData = createAction(RESET_DATA);

const initialState = {
    productInfo: {
        br_id: "",
        br_name: "",
        cate_arr: "",
        contents: "",
        contents_id: "",
        count_map: "",
        like_count: "",
        like_id: "",
        mem_id: "",
        mem_username: "",
        po_all: "",
        prd_bonus: "",
        prd_code: "",
        prd_content: "",
        prd_date: "",
        prd_detail1: "",
        prd_detail2: "",
        prd_earn_rate: "",
        prd_etc_price: "",
        prd_excel: "",
        prd_featured: "",
        prd_hit: "",
        prd_id: "",
        prd_img: "",
        prd_main_cat: "",
        prd_main_img: "",
        prd_memo: "",
        prd_name: "",
        prd_price: "",
        prd_sale_price: "",
        prd_sale_rate: "",
        prd_search_date1: "",
        prd_search_date2: "",
        prd_stock_qty: "",
        prd_sub_cat: "",
        prd_sub_img: "",
        prd_tag: "",
        prd_thumbnail: "",
        prd_type: "",
        prd_url: "",
        ptag_id: "",
        search_br: "",
        search_cat: "",
        search_cat_str: "",
        search_date_type: "",
        search_keyword: "",
        search_m_cat: "",
        search_m_cat_str: "",
        search_s_cat: "",
        search_s_cat_str: "",
        search_type: "",
        status_all: "",
        status_all_str: "",
        status_arr: "",
        status_str: "",
        title_list: "",
        prd_additional4:"",
        prd_additional5:"",
    },
    options: [],
    selectOptions: {},
    brand: {
        br_activated:"",
        br_address:"",
        br_address1:"",
        br_address2:"",
        br_business_num:"",
        br_code:"",
        br_date:"",
        br_delivery_day:"",
        br_delivery_method:"",
        br_delivery_period:"",
        br_description:"",
        br_end_time:"",
        br_fee:"",
        br_hover_img:"",
        br_id:"",
        br_limit:"",
        br_logo:"",
        br_memo:"",
        br_name:"",
        br_open_date:"",
        br_owner_name:"",
        br_phone:"",
        br_refund_caution:"",
        br_refund_charge:"",
        br_refund_method:"",
        br_seller_num:"",
        br_shipping_charge:"",
        br_start_time:"",
        br_tell:"",
        br_type:"",
        br_zipcode:"",
        mem_id:"",
        search_keyword:"",
        search_type:"",
    }
};


export default handleActions({
    [UPDATE_PRODUCT_INFO]: (state, action) => {
        return produce(state, draft => {            
            draft.productInfo = action.payload.productInfo 
            ? Object.assign({}, draft.productInfo, action.payload.productInfo) 
            : draft.draft.productInfo;
            
            draft.options = action.payload.options ?? draft.options;
            draft.brand = action.payload.brand ?? draft.brand
        });
    },       
    [SELECT_OPTIONS]: (state, action) => {
        return produce(state, draft => {            
            draft.selectOptions = action.payload.selectOptions ?? draft.selectOptions;            
        });
    },       
    [RESET_DATA]: (state, action) => {
        return produce(state, draft => {            
            draft.selectOptions = {};           
        });
    },   
}, initialState);