import styled from "styled-components";
import {
  Mobile,
  PC,
  MQ,
  mobil,
  mquery,
  Media,
  css,
  pc,
  Px,
  Width,
  Height,
  Top,
  Bottom,
  Left,
  Right,
  FontSize,
  Margin,
  Margin2,
  Padding,
  Padding2,
  MT,
  MB,
  ML,
  MR,
  PT,
  PB,
  PL,
  PR,
  LineH,
} from "../../common/mediaStlye";
import { PrimaryButton } from "../auth.elements";
import nextButtonIcon from "../../../images/next-button-arrow.png";
import nextButtonIconOn from "../../../images/next-button-arrow-on.png";
import prevButtonIcon from "../../../images/prev-button-arrow.png";
import prevButtonIconOn from "../../../images/prev-button-arrow-on.png";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${MQ(mquery)} {
    /* ${Padding(35, 0, 0, 0, mobil)} */
    border-bottom: 1px solid 1px #a5a5a5;
  }
`;

export const Layout = styled.div`
  ${Width(1176, pc)}

  ${MQ(mquery)} {
    width: 100%;
    min-height: calc(100vh - calc(100vw * (450 / 428)));
    ${Padding2(0, 25, mobil)}
    ${MT(50, mobil)}
  }
`;
export const MyPageHead = styled.div`
  ${[Padding2(30, 45, pc), MB(17, pc)].join("")}
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #222;

  ${MQ(mquery)} {
    display: block;
    ${MB(45, mobil)}
    border-bottom: none;
    padding: 0;
  }
`;

export const UserNameBox = styled.div`
  display: flex;
  align-items: center;

  & > p {
    ${[FontSize(32, pc), ML(14, pc)].join("")}
    color: #000;
    font-weight: 900;
  }

  & small {
    ${FontSize(20, pc)}
    color: 000;
    font-weight: 400;
  }

  ${MQ(mquery)} {
    & > p {
      ${[FontSize(30, mobil), ML(12, mobil)].join("")}
    }

    & small {
      ${FontSize(15, mobil)}
    }
  }
`;

export const UserThumb = styled.div`
  ${Width(50, pc)}
  & > img {
    width: 100%;
    vertical-align: middle;
  }

  ${MQ(mquery)} {
    ${Width(39, mobil)}
  }
`;

export const LogoutButton = styled.button`
  ${[Width(98, pc), Height(32, pc), FontSize(16, pc), MT(18, pc)].join("")}
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a5a5a5;
  border-radius: ${Px(15, pc)};
  color: #a5a5a5;
  cursor: pointer;

  ${MQ(mquery)} {
    text-align: right;
    ${[
      Width(98, mobil),
      Height(32, mobil),
      FontSize(16, mobil),
      MT(18, mobil),
    ].join("")}
    border-radius: ${Px(15, mobil)};
  }
`;
// MyPageUserProfileInfo
export const MyPageProfileBox = styled.div`
  ${Height(198, pc)}
  ${MB(30, pc)}
    display: flex;
  flex-grow: 1;
  gap: ${Px(8, pc)};
  align-items: center;

  ${MQ(mquery)} {
    height: auto;
    align-items: flex-start;
  }
`;

export const ProfileInfo = styled.div`
  ${Padding(17, 15, 0, 15, pc)}

  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  height: 100%;

  & p {
    ${FontSize(14, pc)}
    text-align: center;
    color: #a5a5a5;
  }
  & em {
    ${FontSize(14, pc)}
    text-align: center;
    color: #a5a5a5;
  }

  & .search-engine-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .search-engine-container > div {
    ${[Width(108, pc), MB(20, pc), MT(7, pc)].join("")}
  }

  & .search-engine-container > div img {
    width: 100%;
  }

  & .search-engine-container > em {
    ${FontSize(16, pc)}
  }

  ${MQ(mquery)} {
    border: none;
    flex: none;
  }
  @media screen and (max-width: 1024px) {
    & p {
      ${FontSize(14, mobil)}
    }
    & em {
      ${FontSize(14, mobil)}
    }
  }
`;

export const ProfileHeading = styled.strong`
  ${[FontSize(16, pc), MB(13, pc)].join("")}
  display: block;
  font-weight: 900;

  ${(props) =>
    props.first &&
    css`
      ${MB(23, pc)}
    `}

  ${MQ(mquery)} {
    font-weight: 700;
    ${FontSize(17, mobil)}
  }
`;

export const ProfileContents = styled.div`
  /* flex: 1; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  /* height: 100%; */
  gap: ${Px(5, pc)};
  /* flex-grow:1; */
  /* gap: ${Px(5, pc)}; */

  ${MQ(mquery)} {
    flex-direction: column;
  }
`;

export const ProfileGender = styled.div`
  ${[Width(75, pc), Height(75, pc), MB(7, pc)].join("")}
  position: relative;
  background-color: #fff200;
  border-radius: 50%;

  & > em {
    ${FontSize(35, pc)}
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    line-height: 0;
    font-weight: 900;
    margin-top: ${Px(5, pc)};
    user-select: none;
  }
`;

export const ContentDetail = styled.div`
  ${[Height(33, pc), FontSize(14, pc)].join("")}
  width: 48%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f7f7;
  color: #a5a5a5;
  border: 1px solid #e2e2e2;

  & > img {
    ${MR(4, pc)}
  }

  ${MQ(mquery)} {
    width: 100%;
    height: auto;
    ${Padding2(2, 13, mobil)}
    ${FontSize(16, mobil)}
        ${MB(5, mobil)}
  }
`;

// MyPageUserWalaInfo
export const UserInfoBox = styled.div`
  display: flex;
  ${MB(100, pc)}

  ${MQ(mquery)} {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const UserInfoTabs = styled.ul`
  ${Width(260, pc)}

  ${MQ(mquery)} {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${MB(20, mobil)}
  }
`;
export const UserInfoTab = styled.li`
  ${[Height(50, pc), FontSize(16, pc)].join("")}
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222;
  cursor: pointer;

  ${(props) =>
    props.selected &&
    css`
      color: #fff;
      background-color: #222;
    `}

  ${MQ(mquery)} {
    ${[Height(50, mobil), FontSize(15, mobil)].join("")}
    letter-spacing: calc(100vw*(-0.8/428));
    width: 100%;
  }
`;

export const TabContents = styled.div`
  width: calc(100% - calc(100vw * (260 / 1920)));
  ${[Padding(0, 0, 0, 90, pc)].join("")}
  flex: 1;

  ${(props) =>
    props.last &&
    css`
      padding: 0;
      ${PL(50, pc)}
    `}

  ${MQ(mquery)} {
    width: 100%;
    ${[Padding(0, 0, 0, 0, mobil)].join("")}
  }
`;

export const TabContent = styled.div``;

export const TabContentHeading = styled.div`
  ${[FontSize(18, pc), MT(20, pc)].join("")}
  font-weight: 900;
  color: #404040;

  ${MQ(mquery)} {
    ${MT(0, mobil)}
    ${FontSize(18, mobil)}
        ${Padding2(0, 25, mobil)}
        ${MB(75, mobil)}
        ${Height(67, mobil)}
        display: flex;
    align-items: center;
    background-color: #f4f4f4;
  }
`;

export const TabUserForm = styled.div`
  ${Padding(58, 0, 0, 25, pc)}

  & span {
    ${[FontSize(18, pc), Width(180, pc)].join("")}
    color: #222;
  }
  & input {
    ${FontSize(16, pc)}
    color: #222;
  }

  & input::placeholder {
    ${FontSize(14, pc)}
  }

  ${MQ(mquery)} {
    ${Padding(25, 0, 0, 0, mobil)}
    & span {
      display: flex;
      align-items: center;
      height: 100%;
    }
    & span {
      ${[FontSize(16, mobil), Width(120, mobil), PL(5, mobil)].join("")}
      color: #222;
      font-weight: 400;
    }
    & input {
      ${FontSize(16, mobil)}
      color: #222;
    }

    & > div > div {
      ${MB(30, mobil)}
    }

    & input::placeholder {
      ${FontSize(14, mobil)}
    }
  }
`;

export const ModifyButton = styled(PrimaryButton)`
  ${[Width(175, pc), Height(49, pc), FontSize(16, pc)].join("")}
  background-color: #222;
  color: #fff;
  border: 1px solid #222;

  ${MQ(mquery)} {
    ${[Width(220, mobil), Height(59, mobil), FontSize(16, mobil)].join("")}
  }
`;

//TabContentFaq
export const TabFaqButtonWrap = styled.div`
  ${[PT(38, pc), PB(45, pc), PR(14, pc)].join("")}
  display: flex;
  justify-content: flex-end;
`;

export const TabFaqButton = styled.button`
  ${[Width(122, pc), Height(38, pc), FontSize(16, pc)].join("")}
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #222;
  border-radius: ${Px(25, pc)};

  ${MQ(mquery)} {
    ${[Width(122, mobil), Height(38, mobil), FontSize(16, mobil)].join("")}
    border-radius: ${Px(25, mobil)};
  }
`;

export const TabFaqTable = styled.div`
  width: 100%;

  ${MQ(mquery)} {
    width: 100%;
  }
`;

export const TabFaqHead = styled.div`
  ${[FontSize(18, pc), Height(62, pc)].join("")}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #404040;

  & > div:first-child {
    ${[Width(180, pc)].join("")}
  }

  & > div:nth-child(2) {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > div:last-child {
    ${Width(200, pc)}
    text-align: center;
  }

  ${MQ(mquery)} {
    ${FontSize(18, mobil)}
    ${Height(62, mobil)}
        text-align: center;

    & > div:first-child {
      ${[Width(120, mobil)].join("")}
    }

    & > div:last-child {
      ${Width(98, mobil)}
    }
  }
`;

export const TabContentFaqRow = styled.div`
  border-bottom: 1px solid #a5a5a5;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    padding-bottom: calc(100vw * (15 / 428));
  }
`;
export const TabContentRow = styled(TabFaqHead)`
  border: none;
`;

export const TabFaqQuestion = styled.div`
  ${[Height(100, pc), Padding(15, 10, 19, 31, pc), MB(14, pc)]}
  border: 1px solid #EDEDED;
  border-radius: ${Px(15, pc)};

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: auto;
    min-height: calc(100vw * (60 / 428));
    padding: calc(100vw * (10 / 428));
    margin-bottom: calc(100vw * (10 / 428));
    border-radius: ${Px(10, mobil)};
  }
`;

export const TabFaqText = styled.p`
  overflow-y: scroll;
  overflow-x: hidden;
  word-break: break-all;
  height: 100%;
  font-size: calc(100vw * (15 / 1920));

  @media screen and (max-width: 1024px) {
    font-size: calc(100vw * (16 / 428));
  }
`;

export const TabFaqAnswerd = styled.div`
  ${[
    Width(760, pc),
    Height(160, pc),
    Padding(15, 13, 34, 20, pc),
    MB(15, pc),
  ].join("")}

  background-color: #F8F7F7;
  border-radius: ${Px(15, pc)};

  & .faq-answer-head {
  }
  & .faq-answer-content {
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: auto;
    min-height: calc(100vw * (100 / 428));
    padding: calc(100vw * (10 / 428));
    margin-bottom: calc(100vw * (15 / 428));
  }
`;

export const TabFaqAnswerText = styled.div`
  overflow-y: scroll;
  height: 100%;
  ${PR(44, pc)}

  & .faq-answer-head {
    ${[FontSize(16, pc), PL(30, pc), PB(10, pc), MB(10, pc)].join("")}
    color: #A5A5A5;
    border-bottom: 1px solid #a5a5a5;
  }

  & .faq-answer-content {
    display: flex;
  }

  & .faq-answer-content p {
    flex: 1;
  }

  & .faq-answer-icon {
    ${Width(44, pc)}
    display: flex;
    justify-content: center;
  }

  & .faq-answer-icon img {
    ${[Width(12, pc), Height(16, pc)]}
  }
  @media screen and (max-width: 1024px) {
    & .faq-answer-head {
      ${[FontSize(16, mobil), PL(18, mobil), PB(10, mobil), MB(0, mobil)].join(
        ""
      )}
    }

    & .faq-answer-content p {
      flex: 1;
      margin-top: calc(100vw * (10 / 428));
    }

    & .faq-answer-icon {
      ${Width(44, mobil)}
      display: flex;
      justify-content: center;
    }

    & .faq-answer-icon img {
      margin-top: calc(100vw * (10 / 428));
      ${[Width(12, mobil), Height(16, mobil)]}
    }
  }
`;
export const TabPaging = styled.div`
  ${MT(38, pc)}
  display: flex;
  justify-content: center;
  align-items: center;

  ${MQ(mquery)} {
    ${MT(38, mobil)}
    ${MB(100, mobil)}
  }
`;
const PagingButtonCss = css`
  ${[Width(12, pc), Height(18, pc)].join("")}
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 0;

  ${MQ(mquery)} {
    ${[Width(12, mobil), Height(18, mobil)].join("")}
  }
`;
export const PrevButton = styled.button`
  ${PagingButtonCss}
  background-image: url(${(props) =>
    !props.actvie ? prevButtonIcon : prevButtonIconOn});
`;

export const NextButton = styled.button`
  ${PagingButtonCss}
  background-image: url(${(props) =>
    !props.actvie ? nextButtonIcon : nextButtonIconOn});
`;

export const TabPages = styled.div`
  ${[Margin2(0, 42, pc), Width(134, pc)].join("")}
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${MQ(mquery)} {
    ${[Margin(0, 42, 0, 42, mobil), Width(211, mobil)].join("")}
  }
`;
export const PageNum = styled.div`
  ${FontSize(16, pc)}
  transform: translateY(2px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a5a5a5;
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      color: #222;
    `}

  ${MQ(mquery)} {
    ${FontSize(16, mobil)}
  }
`;

// TabContentItems

export const TabItemsContent = styled.div`
  ${[MB(45, pc), PT(25, pc)].join("")}
  max-width: ${Px(915, pc)};

  ${MQ(mquery)} {
    max-width: 100%;
    ${MT(43, mobil)}/* ${MB(167, mobil)} */
  }
`;
export const TabItemsHeading = styled.div`
  ${[MB(35, pc), PL(20, pc), PR(20, pc), PB(15, pc)].join("")}
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #222;

  & h3 {
    ${FontSize(18, pc)}
  }

  & h3 img {
    ${MR(30, pc)}
    display: inline-block;
    margin-top: -5px;
  }

  & > div span {
    ${[FontSize(14, pc), Margin2(0, 7, pc)].join("")}
    position: relative;
    display: inline-block;
    color: #a5a5a5;
  }

  & > div span:first-child::after {
    content: "";
    width: 1px;
    height: 10px;
    position: absolute;
    right: -7px;
    top: 50%;
    transform: translateY(-50%);
    background: #d9d9d9;
  }

  ${MQ(mquery)} {
    ${[MB(35, mobil), PL(20, mobil), PR(20, mobil), PB(5, mobil)].join("")}
    & h3 {
      ${FontSize(18, mobil)}
    }

    & > div span {
      ${[FontSize(14, mobil), Margin2(0, 7, mobil)].join("")}
    }
  }
`;

export const MobileTabItemsHeading = styled(TabItemsHeading)`
  ${MQ(mquery)} {
    ${PL(20, mobil)}
    ${PR(20, mobil)}
        ${Margin(0, 25, 35, 25, mobil)}
        & h3 {
      ${FontSize(18, mobil)}
    }

    & > div span {
      ${[FontSize(14, mobil), Margin2(0, 7, mobil)].join("")}
    }
  }
`;

export const TabItemsList = styled.ul`
  display: flex;
  justify-content: flex-start;
  gap: ${Px(10, pc)};
  ${Padding2(0, 22, pc)}

  ${(props) =>
    props.list &&
    css`
      flex-wrap: wrap;
      row-gap: ${Px(13, pc)};
    `}
`;

export const MobileTabItemsList = styled(TabItemsList)`
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
`;
export const TabItemConts = styled.li`
  position: relative;
  cursor: pointer;

  :hover .items-desc,
  :hover .item-liked {
    opacity: 1;
  }
`;

export const MobileTabItemConts = styled(TabItemConts)`
  display: inline-block;
  margin: 0 ${Px(2, mobil)};
  ${Height(184, mobil)}

  &  .item-thumb img {
    ${Width(184, mobil)}
    ${Height(184, mobil)}
  }

  ${MQ(mquery)} {
    ${[MB(3, mobil)].join("")}
  }
`;

export const TabItemImg = styled.div`
  ${[Height(256, pc)].join("")}
  width: ${Px(266, pc)};
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    line-height: 0;
    object-fit: cover;
  }

  ${MQ(mquery)} {
    ${[Height(184, mobil)].join("")}
    Width: 100%;
  }
`;

export const TabItemDesc = styled.div`
  ${[Width(264, pc), Height(150, pc), Padding(26, 17, 42, 22, pc)].join("")}
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(34, 34, 34, 0.9);
  opacity: 0;
  transition: all 0.3s ease;

  ${(props) =>
    props.second &&
    css`
      ${Height(120, pc)}
      background-color: rgba(255,242, 0, 0.53);
    `}
`;

export const TabItemText = styled.div`
  & em {
    ${FontSize(12, pc)}
    color: #FFF200;
  }
  & h2 {
    ${[FontSize(16, pc), MB(10, pc)].join("")}
    font-weight: 900;
    color: #fff;
  }

  & p {
    ${FontSize(13, pc)}
    color: #fff;
  }

  ${(props) =>
    props.second &&
    css`
      & p {
        text-align: right;
      }

      & em {
        color: #222;
      }

      & h2 {
        color: #606060;
        font-weight: 700;
      }
    `}
`;

export const TabItemLiked = styled.div`
  ${[Width(18, pc), Right(17, pc), Top(17, pc)].join("")}

  position: absolute;
  opacity: 0;
  transition: all 0.3s ease;

  & img {
    width: 100%;
  }

  ${MQ(mquery)} {
    ${[Width(18, mobil), Right(17, mobil), Top(17, mobil)].join("")}
    opacity: 1;
  }
`;

export const UserAnalysisLink = styled.div`
  display: inline-block;
  background: #fff200;
  border-radius: 26px;
  letter-spacing: -2px;
  ${Padding2(12, 15, mobil)}

  & img {
    ${Width(10, mobil)}
    ${ML(3, mobil)}
        transform: translateY(2px);
  }
`;

export const ProfilePlusButton = styled.div`
  ${Width(37, mobil)}
  ${Height(37, mobil)}    
    display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff200;
  border-radius: 50%;
  line-height: 0;
  margin: ${Px(30, mobil)} auto ${Px(70, mobil)};
  cursor: pointer;

  & > span {
    ${FontSize(35, mobil)}
    font-weight: 400;
    transform: translateY(1px);
  }
`;

export const MobileTab = styled.div`
  cursor: pointer;
  ${Padding2(0, 25, mobil)}
  ${FontSize(17, mobil)}
    ${Height(75, mobil)}
    display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #a5a5a5;

  & > img {
    ${Width(7, mobil)}
  }
`;
