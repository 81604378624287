import styled from "styled-components";
import { css } from "../common/mediaStlye";

export const Container = styled.div`
  padding: 180px 0 204px;

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (60 / 428)) 0 calc(100vw * (80 / 428));
  }
`;

export const Layout = styled.div`
  width: 378px;
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;

export const PrimaryButton = styled.button`
  width: 487px;
  height: 61px;
  margin-bottom: 20px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #222;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (487 / 1320));
    height: calc(100vw * (61 / 1320));
    margin-bottom: calc(100vw * (20 / 1320));
    font-size: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (60 / 428));
    margin-bottom: calc(100vw * (18 / 428));
    font-size: calc(100vw * (16 / 428));
  }
`;

export const AuthIntroMessage = styled.div`
  margin-bottom: 40px;

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (40 / 428));
  }
`;

export const Form = styled.form.attrs()`
  @media screen and (max-width: 1320px) {
  }

  @media screen and (max-width: 930px) {
  }
`;

export const FieldBox = styled.div`
  position: relative;
  width: 100%;
  & input {
    padding-right: 200px;
  }

  @media screen and (max-width: 1320px) {
    & input {
      padding-right: calc(100vw * (200 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (28 / 428));
    & input {
      padding-right: 0;
    }
  }
`;

export const Label = styled.label``;

export const LabelName = styled.p`
  color: #333333;
  font-size: 15px;
  margin-bottom: 10px;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-bottom: calc(100vw * (10 / 428));
  }
`;

export const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 50px;
  font-size: 15px;
  padding: 0 15px;
  border: 1px solid #dddddd;
  &::placeholder {
    color: #acacac;
  }
  &:focus {
    border: 1px solid #000;
  }

  ${(props) =>
    props.readOnly &&
    css`
      color: #dddddd;
    `}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: 0 calc(100vw * (15 / 428));
  }
`;

export const DoubleFieldBox = styled(FieldBox)`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  gap: 15px;
  & > label span {
    padding-left: 13px;
    padding-right: 34px;
    width: auto;
  }
  & input {
    padding-right: 15px;
  }

  @media screen and (max-width: 1320px) {
    gap: calc(100vw * (15 / 1320));
    & > label span {
      padding-left: calc(100vw * (13 / 1320));
      padding-right: calc(100vw * (34 / 1320));
      width: auto;
    }
    & input {
      padding-right: calc(100vw * (15 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    gap: calc(100vw * (15 / 428));
    & > label span {
      padding-left: calc(100vw * (11 / 428));
      padding-right: calc(100vw * (34 / 428));
      width: auto;
    }
    & input {
      padding-right: calc(100vw * (15 / 428));
    }
  }
`;

export const ErrorMessage = styled.p`
  position: absolute;
  left: 0;
  bottom: -25px;
  font-size: 14px;
  color: ${({ success }) => (success ? `#222` : "#FF4343")};

  @media screen and (max-width: 1320px) {
    bottom: calc(100vw * (-25 / 1320));
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    bottom: calc(100vw * (-30 / 428));
    font-size: calc(100vw * (14 / 428));

    ${(props) =>
    props.primary &&
    css`
        bottom: calc(100vw * (-20 / 428));
      `}
  }
`;
export const InputWrap = styled.div`
  margin-bottom: 10px;

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
export const AlertBox = styled.div`
  position: fixed;
  left: 50%;
  bottom: calc(100vh * (120 / 1080));
  transform: translateX(-50%);
  transition: ease 0.2s all;
  z-index: -999;
  width: 905px;
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  padding: 13px 0;
  background-color: rgba(0,0,0,0.5);
  border-radius: 6px;
  opacity: 0%;
  ${({ show }) => {
    return (
      show &&
      `
        z-index : 1000;
        opacity : 100%;
      `
    );
  }}
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (905 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (13 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    bottom: calc(100vw * (25 / 428));
    width: calc(100vw * (378 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (13 / 428)) 0;
    border-radius: calc(100vw * (6 / 428));
  }
`;
export const BlackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background-color: #000;
  border: solid #bbbbbb 1px;
  ${({ activity }) => {
    return (
      activity &&
      `
           background-color:#BBBBBB;
        `
    );
  }}
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
export const WhiteButton = styled(BlackButton)`
  background-color: #fff;
  border: #000000 1px solid;
  color: #111111;
  @media screen and (max-width: 930px) {
    border-width: calc(100vw * (1 / 428));
  }
`;
export const FindTelValidationWrap = styled.div`
  display: flex;
  @media screen and (max-width: 930px) {
    & > :last-child {
      width: calc(100vw * (100 / 428));
      margin-left: calc(100vw * (8 / 428));
    }
  }
`;
export const FindTelValidationInputBox = styled.div`
  position: relative;
  width: 690px;
  @media screen and (max-width: 1240px) {
    width: calc(100vw * (690 / 1240));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (270 / 428));
  }
`;
export const InfoText = styled.p`
  font-size: 15px;
  color: #ff006c;
  padding: 6px 0 5px;
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (6 / 428)) 0 calc(100vw * (5 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;
export const Time = styled.span`
  color: #333333;
  font-size: 15px;
  position: absolute;
  top: 11px;
  right: 15px;

  @media (max-width: 1297px) {
    font-size: calc(100vw * (15 / 1280));
    /* top: calc(100vw * (11 / 1280));
        right: calc(100vw * (146 / 1280)); */
  }

  @media (max-width: 1023px) {
    font-size: calc(100vw * (15 / 428));
    top: calc(100vw * (16 / 428));
    right: calc(100vw * (15 / 428));
  }
`;
export const EmailInput = styled(Input).attrs((props) => ({
  type: props.type ?? "email",
  required: true,
  placeholder: props.placeholder ? props.placeholder : "이메일을 @까지 정확하게 입력하세요.",
}))``;

export const FindEmailInput = styled(Input).attrs((props) => ({
  type: props.type ?? "email",
  required: true,
  placeholder: props.placeholder ? props.placeholder : "이메일을 입력해주세요.",
}))`
  ${({ findPwIs }) => findPwIs === false && `border-color : #FF006C`}
`;
export const FindTelInput = styled(Input).attrs((props) => ({
  type: props.type ?? "tel",
  required: true,
  placeholder: props.placeholder ? props.placeholder : "-를 제외한 숫자만 입력해주세요.",
}))`
  ${({ findPwIs }) => findPwIs === false && `border-color : #FF006C`}
  ${({ findIdIs }) => findIdIs === false && `border-color : #FF006C`}
`;
export const FindTelNumInput = styled(Input).attrs((props) => ({
  type: props.type ?? "number",
  required: true,
  placeholder: props.placeholder ? props.placeholder : "인증번호를 입력해주세요.",
}))`
  ${({ correct }) => correct === false && `border-color : #FF006C`}
`;
export const FindNubInput = styled(Input).attrs((props) => ({
  type: props.type ?? "number",
  required: true,
  placeholder: props.placeholder ? props.placeholder : "인증번호를 입력해주세요.",
}))``;
export const PasswordInput = styled(Input).attrs((props) => ({
  type: "password",
  required: true,
  placeholder: props.placeholder ? props.placeholder : "영문+숫자+특수문자 조합 8~10자리",
}))``;

export const PasswordCheckInput = styled(Input).attrs((props) => ({
  type: "password",
  required: true,
  placeholder: "입력하신 비밀번호를 한번 더 입력해주세요.",
}))``;

export const CheckboxInput = styled.input.attrs((props) => ({
  type: "checkbox",
}))``;

export const TelephoneInput = styled(Input).attrs((props) => ({
  type: "tel",
  required: true,
  placeholder: props.placeholder ? props.placeholder : "휴대폰 번호를 입력해주세요.",
}))``;
