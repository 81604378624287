import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Mobile, PC } from "../../MediaQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import { InfoText } from "../../page/main/MainPage";
import SwiperArrow from "../../img/icon/icon_swiperArrow.svg";
import SwiperArrowL from "../../img/icon/icon_swiperArrowL.svg";
import SwiperArrowG from "../../img/icon/icon_swiperArrow_gray.svg";
import SwiperArrowGL from "../../img/icon/icon_swiperArrow_grayL.svg";
import MDProduct from "./MDProduct";
import Loading from "../common/modal/Loading";
import MyPageDefaultModal from "../common/modal/MyPageDefaultModal";

export default function MDSection(props) {
  const { mdLoading, setMdLoading, list, moList, mainCateLinkMd, getProductMdsPick } = props;

  const location = useLocation();

  const history = useHistory();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [loginPop, setLoginPop] = useState(false);
  const [buttonClick, setButtonClick] = useState(false);
  const [cateList, setCateList] = useState([]);
  const [swiper, setSwiper] = useState();
  const clickCate = useRef(null);

  const getMainCateLink = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(mainCateLinkMd, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCateList(result.list);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (mainCateLinkMd === "") return;
    getMainCateLink();
  }, [mainCateLinkMd]);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(0, 0, true);
    }
  }, [moList]);

  return (
    <Container>
      {loginPop && (
        <MyPageDefaultModal
          text="로그인 후 이용 가능합니다."
          trueText="로그인"
          trueFunc={() => {
            if (location) return history.push(`/store/login?returnTo=${location.pathname}`);
            history.push(`/store/login`);
          }}
          falseFunc={() => setLoginPop(false)}
        />
      )}
      <BestNewWrap>
        <TitleWrap>
          <TitleText>MD’s PICK</TitleText>
          <SwiperArrowWrap>
            <SwiperNavPrev
              onClick={() => setButtonClick(!buttonClick)}
              ref={prevRef}
              id="swiper-prev-nav-MdPick-mo"
            ></SwiperNavPrev>
            <SwiperNavNext
              onClick={() => setButtonClick(!buttonClick)}
              ref={nextRef}
              id="swiper-next-nav-MdPick-mo"
            ></SwiperNavNext>
          </SwiperArrowWrap>
        </TitleWrap>
        <CateWrap>
          {cateList.map((value, index) => {
            return (
              <CateTitle
                active={
                  clickCate.current === null && index === 0 ? true : index === clickCate.current
                }
                onClick={() => {
                  setMdLoading(true);
                  getProductMdsPick(value.prd_maincat_id);
                  clickCate.current = index;
                }}
                key={`${value.prd_maincat_name}-${value.c_cat_id}`}
              >
                {value.prd_maincat_name}
              </CateTitle>
            );
          })}
        </CateWrap>
        <BestNewListWrap>
          {mdLoading ? (
            <Loading />
          ) : (
            <React.Fragment>
              <PC>
                <BestNewInner>
                  <Swiper
                    slidesPerView={2}
                    slidesPerGroup={1}
                    navigation={{
                      prevEl: prevRef.current,
                      nextEl: nextRef.current,
                    }}
                  >
                    {list.length > 0 ? (
                      list.map((items, index) => {
                        return (
                          <SwiperSlide key={`${index}-pc-list`}>
                            {items.map((value, index) => {
                              return (
                                <MDProduct
                                  key={`MD${index}`}
                                  value={value}
                                  setLoginPop={setLoginPop}
                                />
                              );
                            })}
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <InfoText>해당 상품이 없습니다.</InfoText>
                    )}
                  </Swiper>
                </BestNewInner>
              </PC>

              <Mobile>
                <BestNewInner>
                  <Swiper
                    slidesPerView={1}
                    centeredSlides={true}
                    slidesPerGroup={1}
                    allowTouchMove={false}
                    onSwiper={setSwiper}
                    navigation={{
                      prevEl: prevRef.current,
                      nextEl: nextRef.current,
                    }}
                  >
                    {moList.length > 0 ? (
                      moList.map((items, idx) => {
                        return (
                          <SwiperSlide key={`${idx}-mo-list`}>
                            {items.map((moItem) => {
                              return (
                                <MDProduct
                                  key={moItem.prd_id}
                                  value={moItem}
                                  setLoginPop={setLoginPop}
                                />
                              );
                            })}
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <InfoText>해당 상품이 없습니다.</InfoText>
                    )}
                  </Swiper>
                </BestNewInner>
              </Mobile>
            </React.Fragment>
          )}
          {/* <SeeMoreBtn onClick={moreItems}>See More</SeeMoreBtn> */}
        </BestNewListWrap>
      </BestNewWrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 56px 0 100px;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (56 / 1320)) 0 calc(100vw * (100 / 1320));
    width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (47 / 428));
    padding-bottom: calc(100vw * (60 / 428));
    width: auto;
    margin: auto;
  }
`;
const BestNewWrap = styled.div``;
const TitleWrap = styled.div`
  width: 1240px;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (16 / 428));
    width: calc(100vw * (378 / 428));
  }
`;
const TitleText = styled.h2`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const BestNewListWrap = styled.div`
  width: 100%;
  padding-top: 15px;
  position: relative;
  min-height: 500px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (15 / 1320));
    min-height: calc(100vw * (500 / 1320));
  }

  @media screen and (max-width: 930px) {
    min-height: calc(100vw * (300 / 428));
    padding-top: 0;
    background: none;
  }
`;
const BestNewInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;

  & .swiper-container {
    width: 100%;
  }

  & .swiper-container-horizontal > .swiper-scrollbar {
    width: 200px;
    height: 3px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 1320px) {
    & .swiper-container-horizontal > .swiper-scrollbar {
      height: calc(100vw * (3 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));

    & .swiper-container-horizontal > .swiper-scrollbar {
      width: calc(100vw * (150 / 428));
      height: calc(100vw * (3 / 428));
    }
  }
`;

// 리뉴얼
const SwiperArrowWrap = styled.div`
  display: flex;
  align-items: center;
`;
const SwiperNavPrev = styled.div`
  cursor: pointer;
  height: 36px;
  width: 36px;
  background-image: url(${SwiperArrowL});
  &.swiper-button-disabled {
    background-image: url(${SwiperArrowGL});
  }
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (36 / 1320));
    width: calc(100vw * (36 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (36 / 428));
    width: calc(100vw * (36 / 428));
  }
`;
const SwiperNavNext = styled.div`
  cursor: pointer;
  height: 36px;
  width: 36px;
  background-image: url(${SwiperArrow});
  &.swiper-button-disabled {
    background-image: url(${SwiperArrowG});
  }
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (36 / 1320));
    width: calc(100vw * (36 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (36 / 428));
    width: calc(100vw * (36 / 428));
  }
`;
const CateWrap = styled.div`
  display: flex;
  @media screen and (max-width: 930px) {
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
    padding: 0 calc(100vw * (25 / 428)) calc(100vw * (20 / 428));
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const CateTitle = styled.div`
  position: relative;
  font-size: 16px;
  color: #999999;
  font-weight: 400;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 22px;
  }

  &::after {
    display: none;
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2px;
    background-color: #000;
  }

  ${({ active }) =>
    active &&
    `
      color: #333;
      font-weight: 700;

      &::after {
        display: block;
      }
    `}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (22 / 1320));
    }

    &::after {
      bottom: calc(100vw * (-5 / 1320));
      height: calc(100vw * (2 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));

    &:not(:last-child) {
      margin-right: calc(100vw * (20 / 428));
    }

    &::after {
      bottom: calc(100vw * (-5 / 428));
      height: calc(100vw * (2 / 428));
    }
  }
`;
