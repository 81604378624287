import React from "react";
import { PC, Mobile } from "../../components/common/mediaStlye";
import Header from "../../components/common/header/Header";
import SignUpIntro from "../../components/auth/signup/SignUpIntro";
import Footer from "../../components/common/footer/Footer";
const SignUpPage = () => {
  return (
    <>
      <Header />
      <Header />
      <SignUpIntro />
      <Footer />
    </>
  );
};

export default SignUpPage;
