import styled from "styled-components";

export default function Loading() {
  return (
    <Container>
      <div className="loading-con">
        <div className="svg-loader">
          <svg
            className="svg-container"
            height="50"
            width="50"
            viewBox="0 0 100 100"
          >
            <circle className="loader-svg bg" cx="50" cy="50" r="43"></circle>
            <circle
              className="loader-svg animate"
              cx="50"
              cy="50"
              r="43"
            ></circle>
          </svg>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 19;

  & .svg-loader {
    display: flex;
    position: relative;
    align-content: space-around;
    justify-content: center;
  }

  & .loader-svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    fill: none;
    stroke-width: 11px;
    stroke-linecap: round;
    stroke: #fdf251;
  }

  & .loader-svg.bg {
    stroke-width: 11px;
    stroke: #f9f9f9;
  }

  & .animate {
    stroke-dasharray: 242.6;
    animation: fill-animation 1s cubic-bezier(1, 1, 1, 1) 0s infinite;
  }

  @keyframes fill-animation {
    0% {
      stroke-dasharray: 40 242.6;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 141.3;
      stroke-dashoffset: 141.3;
    }
    100% {
      stroke-dasharray: 40 242.6;
      stroke-dashoffset: 282.6;
    }
  }
`;
