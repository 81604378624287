import React, { useState } from 'react'
import Calendar from 'react-calendar';
import styled, { css } from 'styled-components';


import 'react-calendar/dist/Calendar.css';

const SponRequestCalendar = () => {
    const [value, onChange] = useState(new Date());
    return (
        <Wrapper>
            <Calendar
                onChange={onChange}
                value={value}
                allowPartialRange={true}
            />
        </Wrapper>
    )
}

export default SponRequestCalendar

const Wrapper = styled.div`
    position: relative;
`