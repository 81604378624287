import { imgURL } from '../../server/appInfoContoller';

// " , " 넣기
function InsertComma(int){
    return (String(int).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'))
}

// 날짜 -> 텍스트 변환
function DateText(date, text){
    let month = String(date.getMonth()+1).length;
    let _date = String(date.getDate()).length;
    if(month == 1){
        month = "0"+String(date.getMonth()+1);
    }
    if(_date == 1){
        _date = "0"+String(date.getDate());
    }
    return (`${date.getFullYear()}${text}${month}${text}${_date}`)
}

function timeText(date, text) {
    const DATE = new Date(date);
    const hour = DATE.getHours();
    const min = DATE.getMinutes();

    return (`${hour > 10 ? hour : "0" + hour}${text}${min > 10 ? min : "0" + min}`)
}

function DateToText(date, text, isCut){
    // const dateObj = new Date(date.replace(/-/g, "/"));
    const dateObj = new Date(date);      
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth()+1 >= 10 
    ? dateObj.getMonth()+1
    : "0" + (dateObj.getMonth()+1);
    const day =  dateObj.getDate()+1 >= 10 
    ? dateObj.getDate()+1
    : "0" + (dateObj.getDate()+1);
    
    return (`${isCut ? ("" + year).slice(2,4) : year}${text}${month}${text}${day}`)
}

function DateToSuccess(date, text){
    const dateObj = new Date(date);      
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth()+1 >= 10 
    ? dateObj.getMonth()+1
    : "0" + (dateObj.getMonth()+1);
    const day =  dateObj.getDate() >= 10 
    ? dateObj.getDate()
    : "0" + (dateObj.getDate());
    const time = dateObj.getHours() >= 10 
    ? dateObj.getHours()
    : "0" + dateObj.getHours()
    
    return (`${year}.${month}.${day} ${time}시`)
}


// 문자열 자르고 "..."넣기
function SliceText(text, int){
    let newText = text;
    newText=newText.substring(0, int) + "...";
    return newText;
}

function getCategoryName(cat_id) {
    const headings = ["Celebrity", "Drama", "Professional", "Idol"];
    return headings[parseInt(cat_id) - 1];
}

function getThumbnail(thumbData) {    
    return imgURL + thumbData?.split(",")[0];
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const getStatus = (mog_order_status) => {
    switch(mog_order_status) {
        case 0 : return "미입금"
        case 5 : return "옵션변경요청"
        case 6 : return "배송지 변경요청"
        case 10:  return "무통장 대기"
        case 11:  return "결제취소"
        case 12:  return "결제실패"
        case 13:  return "신규주문"
        case 14:  return "상품준비중"
        case 20:  return "결제완료"
        case 30:  return "배송준비"
        case 41:  return "배송중"
        case 42:  return "배송완료"
        case 43:  return "부분 배송중"
        case 44:  return "구매 확정"
        case 50:  return "반품요청"
        case 51:  return "반품중"
        case 52:  return "반품완료"
        case 53:  return "반품수거 접수완료"
        case 54:  return "반품 수거중"
        case 55:  return "반품 수거완료"
        case 57:  return "교환 요청"
        case 58:  return "교환 접수"
        case 59:  return "교환 수거중"
        case 60:  return "교환 수거완료"
        case 61:  return "교환중"
        case 62:  return "교환완료"
        case 70:  return "주문취소"
        case 71:  return "취소 요청"
        case 72:  return "취소 대기"
        case 73:  return "취소 거부"            
        default:
            return;
    }
}

export {InsertComma, DateText,DateToText, SliceText, getCategoryName, getThumbnail, DateToSuccess, numberWithCommas, getStatus, timeText}