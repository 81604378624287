import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { imgURL } from "../../../server/appInfoContoller";
import serverController from "../../../server/serverController";
import DefaultImg from "../../img/icon/loading_default_img.svg";

export default function ContentProductSmall({ value }) {
  const [productList, setProductList] = useState([]);

  const getProducts = () => {
    serverController.connectFetchController(
      `contents/products/${value?.contents_id}`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setProductList(res.list);
        }
      },
      (err) => console.error(err)
    );
  };

  const onClickWindowUrl = (url) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "linking",
          value: url,
        })
      );
    } else window.open(url, "_blank");
  };

  useEffect(() => {
    getProducts();
  }, [value]);

  return (
    <ContentProductWrap>
      {productList.map((v, i) => {
        return (
          <ProductWrap key={v?.prd_id} onClick={() => onClickWindowUrl(v?.prd_link)}>
            <ImageWrap>
              <img
                src={v?.prd_main_img ? `${imgURL}${v?.prd_main_img}` : DefaultImg}
                alt={v?.prd_main_img ? "item-images" : "default-img"}
              />
            </ImageWrap>
            {/* <ProductName>{v?.prd_name}</ProductName> */}
          </ProductWrap>
        );
      })}
    </ContentProductWrap>
  );
}
const ContentProductWrap = styled.div`
  position: absolute;
  left: 10px;
  bottom: 10px;
  /* z-index: 10; */
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* width: 100%;
  margin-top: 15px; */
  @media screen and (max-width: 1320px) {
    left: calc(100vw * (14 / 1320));
    bottom: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    left: calc(100vw * (14 / 428));
    bottom: calc(100vw * (14 / 428));
  }
`;

const ProductWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  &:last-child {
    margin-right: 0;
  }
  cursor: pointer;
  @media screen and (max-width: 930px) {
    margin-right: calc(100vw * (10 / 428));
  }
`;
const ImageWrap = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 10px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (60 / 1320));
    height: calc(100vw * (60 / 1320));
    margin-right: calc(100vw * (10/ 1320));
  }
  @media screen and (max-width: 930px) {
    border: calc(100vw * (1 / 428)) solid #f8f8f8f8;
    height: calc(100vw * (70 / 428));
    width: calc(100vw * (70 / 428));
    margin-right: calc(100vw * (4 / 428));
    overflow: hidden;
  }
`;
const ProductName = styled.p`
  font-size: 15px;
  width: 135px;
  padding-top: 10px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 44px;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    width: calc(100vw * (135 / 1320));
    padding-top: calc(100vw * (10 / 1320));
    min-height: calc(100vw * (44 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    padding-top: calc(100vw * (5 / 428));
    width: calc(100vw * (120 / 428));
  }
`;
