import styled, { css } from "styled-components";
import {
  mobil,
  mquery,
  Media,
  Px,
  pc,
  Width,
  Height,
  Top,
  Bottom,
  Left,
  Right,
  FontSize,
  Margin,
  Margin2,
  Padding,
  Padding2,
  MT,
  MB,
  ML,
  MR,
  PT,
  PB,
  PL,
  PR,
  LineH,
} from "../../common/mediaStlye";
import { CheckboxInput } from "../auth.elements";

export const PrimaryButton = styled.p`
  ${[Width(487, pc), Height(61, pc), MB(20, pc), FontSize(20, pc)].join("")}
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #222;
  cursor: pointer;
`;
export const LoginIntroBtns = styled.div`
  ${[MT(111, pc), MB(433, pc)].join("")}
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: ${mquery}px) {
    ${[MT(91, mobil), MB(150, mobil)].join("")}
  }
`;
export const LoginButton = styled(PrimaryButton)`
  ${(props) =>
    props.primary &&
    css`
      background-color: #fff200;
      color: #101010;
    `}

  @media screen and (max-width: ${mquery}px) {
    ${[Height(55, mobil), MB(15, mobil), FontSize(20, mobil)].join("")}
    width: 100%;
  }
`;

// Login
export const LoginBox = styled.div`
  /* @media screen and (max-width: ${mquery}px) {
        & form {margin-top: calc(100vw*(30/428));}
    } */
`;

export const LoginBottom = styled.div`
  ${[Padding2(57, 0, pc), MB(45, pc)].join("")}
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #a5a5a5;

  @media screen and (max-width: ${mquery}px) {
    ${PB(40, mobil)}
    ${MT(100, mobil)}
        ${MB(30.9, mobil)}
  }
`;
export const LoginPageBtn = styled.div`
  width: 100%;
  height: calc(100vw * (61 / 1920));
  margin-bottom: calc(100vw * (20 / 1920));
  font-size: calc(100vw * (20 / 1920));
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #222;
  cursor: pointer;
  @media screen and (max-width: ${mquery}px) {
    width: 100%;
    height: calc(100vw * (55 / 428));
    margin-bottom: calc(100vw * (20 / 428));
    font-size: calc(100vw * (20 / 428));
  }
`;

export const LoginBtn = styled(LoginButton).attrs((props) => ({
  type: "submit",
}))`
  width: 100%;
  margin-bottom: 8px;
`;

export const LoginSub = styled.div`
  ${FontSize(15, pc)}
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > ul {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & li {
    color: #a5a5a5;
    cursor: pointer;
  }
  & li:first-child {
    ${MR(12, pc)}
    position: relative;
  }
  & li:first-child::after {
    ${Height(11, pc)}
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    background-color: #c1c1c1;
  }
  & li:last-child {
    ${ML(12, pc)}
  }

  @media screen and (max-width: ${mquery}px) {
    ${FontSize(13, mobil)}
    ${MB(80, mobil)}

        & li:first-child {
      ${PR(12, mobil)}
      position: relative;
    }

    & li:first-child::after {
      ${Height(11, mobil)}
    }

    & li:last-child {
      ${PL(12, mobil)}
    }
  }
`;

export const IdSaveLabel = styled.label`
  ${PL(12, pc)}
  & > input {
    ${MR(10, pc)}
  }

  & > span {
    color: #a5a5a5;
  }

  @media screen and (max-width: ${mquery}px) {
    & > input {
      ${MR(6, mobil)}
    }
  }
`;
export const IdSaveCheckbox = styled(CheckboxInput)`
  vertical-align: middle;
`;

// sign up
export const SignUpLinkBox = styled.div`
  ${MB(175, pc)}
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > p {
    ${MB(16, pc)}
  }

  @media screen and (max-width: ${mquery}px) {
    ${MB(145, mobil)}

    & > p {
      ${MB(16, mobil)}
    }
  }
`;
export const SignUpLinkButton = styled(LoginButton)`
  background-color: #fff;
  border: 1px solid #222;
  color: #222;
`;
