//react
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";

// img
import IconCancel from "../../../img/icon/icon_myhaeder_cancle.svg";

export default function TermsModal({ title, text, setTermsModalShow }) {
  return (
    <Container>
      <Content>
        {/* header */}
        <HeaderSection>
          <HeaderInner>
            <p>{title}</p>
            <DeleteButton onClick={() => setTermsModalShow(false)}>
              <img src={IconCancel} alt="deleteButton" />
            </DeleteButton>
          </HeaderInner>
        </HeaderSection>

        <TextSection>
          <TextBox>{text}</TextBox>
        </TextSection>
      </Content>

      <PC>
        <Background onClick={() => setTermsModalShow(false)} />
      </PC>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;
// 컨텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
  width: 635px;
  height: 716px;
  background: #fff;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (635 / 1320));
    height: calc(100vw * (716 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: 0;
    left: 0;
    transform: translate(0);
    width: 100%;
    height: 100vh;
    overflow: auto;
  }
`;

// header
const HeaderSection = styled.div`
  position: relative;
  width: 100%;
  height: 84px;
  overflow: hidden;
  z-index: 99;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
  }
`;
const HeaderInner = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
  background-color: #fff;
  padding: 0 25px;
  white-space: pre-wrap;
  & p {
    color: #333;
    font-weight: 500;
    font-size: 20px;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
    padding: 0 calc(100vw * (25 / 1320));
    & p {
      font-size: calc(100vw * (20 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    justify-content: center;
    height: calc(100vw * (55 / 428));
    padding: 0;
    & p {
      font-size: calc(100vw * (16 / 428));
      text-align: center;
    }
  }
`;
const DeleteButton = styled.div`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 1320px) {
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    right: calc(100vw * (13.5 / 428));
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;

const TextSection = styled.div`
  height: calc(100% - 114px);
  padding: 0 25px;
  overflow: auto;

  @media screen and (max-width: 1320px) {
    height: calc(100% - calc(100vw * (114 / 1320)));
    padding: 0 calc(100vw * (25 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: auto;
    padding: calc(100vw * (30 / 428)) calc(100vw * (25 / 428));
    overflow: hidden;
  }
`;
const TextBox = styled.div`
  line-height: 1.5;
  color: #333;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
