import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import serverController from "../../../server/serverController";
import { useSelector } from "react-redux";
import { Container } from "../login/auth.elements";

export default function ContentsDetailReview({ reviewData, setReviewData, getContentsReview, page }) {
  const { isLogged } = useSelector(state => state.userData);
  const storeMemId = useSelector(state => state.userData.userInfo.mem_id);
  const updateTextRef = useRef();
  const textDivRef = useRef([]);
  const textAreaRef = useRef([]);
  textDivRef.current = [];
  textAreaRef.current = [];
  const [updateInputValue, setUpdateInputValue] = useState('');
  const [isUpdatePop, setIsUpdatePop] = useState(false);
  const [clickCommentId, setClickCommentId] = useState('');
  const updateInputRef = useRef();

  // 댓글 삭제
  const deleteContentsReview = async (comment_id) => {
    if (window.confirm('댓글을 삭제하시겠습니까?') === true) {
      await serverController.connectFetchController(`api/content/comment/${comment_id}`, "DELETE", null, (res) => {
        if (res.result === 0) {
          alert(res.message);
          return;
        }
      });
      getContentsReview();
    } else {
      return;
    };
  };

  // 댓글 수정
  const putContentsReview = async () => {
    if (updateInputValue.replace(/ /g, '').length === 0) return alert('댓글을 입력해주세요.');
    if (updateInputValue.replace(/\n/g, '').length === 0) return alert('댓글을 입력해주세요.');
    await serverController.connectFetchController(`api/content/comment?contentsCommentContents=${updateInputValue}&contentsCommentId=${clickCommentId}`, "PUT", null, (res) => {
      if (res.result === 0) return alert(res.message);
      if (res.result === 1) alert("수정되었습니다.");
    });
    setUpdateInputValue('');
    getContentsReview();
    setIsUpdatePop(false);
  };

  const compareReviewData = (compareValue) => {
    // updateInputValue !== compareValue && setIsUpdatePop(true);
    setIsUpdatePop(!isUpdatePop);
    setTimeout(() => {

    }, 0);

  }

  const cutDate = (date) => {
    const cuttedDate = date.split(' ');
    return cuttedDate[0];
  };

  useEffect(() => {
    setIsUpdatePop(false);
    console.log(storeMemId);
  }, [page])

  return (
    <CommentContainer>

      {isUpdatePop &&
        <UpdateWrap>
          <UpdateTextArea ref={updateInputRef} onChange={(e) => { setUpdateInputValue(e.target.value); }} value={updateInputValue} maxLength="400" />
          <UpdateBtnWrap>
            <UpdateBtn onClick={putContentsReview}>수정</UpdateBtn>
            <UpdateBtn onClick={() => { setIsUpdatePop(false) }}>취소</UpdateBtn>
          </UpdateBtnWrap>
        </UpdateWrap>
      }
      {
        reviewData.commentList.length > 0 && (reviewData.commentList.map((commentListEl, index) => {
          return (
            <CommentWrap key={`commentListEl-item-${reviewData.commentList.contentsCommentId}-${index}`}>
              <CommentInner>
                <CommentTop>
                  <CommentLeft>
                    {/* <CommentLeftUserName>익명</CommentLeftUserName> */}
                    <CommentLeftDate>{cutDate(commentListEl.contentsCommentDatetime)}</CommentLeftDate>
                  </CommentLeft>
                  {isLogged && storeMemId === commentListEl.memId ?
                    <CommentRight>
                      {/* 수정하기버튼 */}
                      <button
                        onClick={() => {
                          setUpdateInputValue(commentListEl.contentsCommentContents);
                          compareReviewData(commentListEl.contentsCommentContents);
                          setClickCommentId(commentListEl.contentsCommentId);
                        }}
                      >수정</button>
                      <button onClick={() => { deleteContentsReview(commentListEl.contentsCommentId) }}>삭제</button>
                    </CommentRight> : null}
                </CommentTop>

                {/* 모달 열렸을 때 나옴 */}
                <CommentText>
                  {commentListEl.contentsCommentContents}
                </CommentText>
              </CommentInner>
            </CommentWrap>
          );
        }))
      }
    </CommentContainer>
  )
};

const CommentContainer = styled.div`
  position: relative;
`;

const UpdateWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;
  border: 1px solid #bcbcbc;
  padding: 10px;
  /* position: absolute; */
  background-color: #fff;
  /* z-index: 10; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%,-50%); */

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (120 / 1320));
    padding: calc(100vw * (10 / 1320));
  }
  
  @media screen and (max-width: 930px) {
    height: calc(100vw * (120 / 428));
    padding: calc(100vw * (5 / 428));
  }
`;
const UpdateTextArea = styled.textarea`
  width: 100%;
  height: 100%;
  overflow: auto;
  resize: none;
  border: none;
  background: none;
  outline: none;
  font-size: 15px;
  @media screen and (max-width: 1320px) {
    /* font-size: calc(100vw * (15 / 1320)); */
  }
  
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`
const UpdateBtnWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const UpdateBtn = styled.button`
  width: 50px;
  height: 30px;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
  background-color: #000;
  cursor: pointer;
  
  &:last-child{
    margin: 0;
    color: #000;
    background-color: #fff;
    border: 1px solid #000;

  }
  
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (50 / 1320));
    height: calc(100vw * (30 / 1320));
    font-size: calc(100vw * (15 / 1320));
    margin-bottom: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (54 / 428));
    height: calc(100vw * (30 / 428));
    font-size: calc(100vw * (15 / 428));
    margin-bottom: calc(100vw * (10 / 428));
  }
`;

// review list
const CommentWrap = styled.div`
  width: 100%;
  &::after {
    content: "";
    display: block;
    height: 1px;
    margin: 0 auto;
    background-color: #eee;
  }
`;
const CommentInner = styled.div`
  padding: 18px 0 20px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (18 / 1320)) 0 calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (10 / 428)) 0 calc(100vw * (10 / 428));
  }
`
const CommentTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto 8px;
`;

const CommentLeft = styled.div`
  display: flex;
  align-items: flex-end;
`;
const CommentRight = styled.div`
  display: flex;
  align-items: flex-end;
  & button{
    font-size: 15px;
    color: #999;
    position: relative;

    @media screen and (max-width: 1320px) {
      font-size: calc(100vw * (15 / 1320));
    }
    
    @media screen and (max-width: 930px) {
      font-size: calc(100vw * (15 / 428));
    }
  };
  & button::after{
    content: '';
    background-color: #ddd;
    position: absolute;
    top: 0;
    right: -1px;
    width: 1px;
    height: 24px;
    
    @media screen and (max-width: 1320px) {
      height: calc(100vw * (24 / 1320));
    }
    
    @media screen and (max-width: 930px) {
      height: calc(100vw * (24 / 428));
    }
  };
  & button:last-child::after{
    content: '';
    width: 0;
  }

`;
const CommentLeftUserName = styled.span`
  font-size: 15px;
  color: #333;
  margin-right: 12px;
  padding-right: 12px;
  border-right: 1px solid #ddd;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-right: calc(100vw * (12 / 1320));
    padding-right: calc(100vw * (12 / 1320));
  }
  
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-right: calc(100vw * (12 / 428));
    padding-right: calc(100vw * (12 / 428));
  }
`;
const CommentLeftDate = styled.span`
  font-size: 15px;
  color: #999;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

const CommentText = styled.article`
  font-size: 15px;
  word-break: break-all;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

