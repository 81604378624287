import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";

// controller
import serverController from "../../server/serverController";

//img
import ZigzagPc from "../../img/myMain_zigzag_pc.svg";
import IconMyPoint from "../../img/icon/icon_my_point.svg";
import IconMyCoupon from "../../img/icon/icon_my_coupon.svg";

// component
import { UserDataAction } from "../../../store/actionCreators";
import Commas from "../../server/Commas";

export default function MyPageLayOutInfo({}) {
  const history = useHistory();
  const { userInfo } = useSelector((state) => state.userData);

  const [couponsLength, setCouponsLength] = useState(0);

  // get coupon length
  const getCouponLength = () => {
    serverController.connectFetchController(`coupon/list?type=0`, "GET", null, (res) => {
      if (res?.result === 1) {
        setCouponsLength(res?.coupon_list.length);
      }
    });
  };

  // logout
  const postLogout = () => {
    serverController.connectFetchController(
      `logout`,
      "POST",
      null,
      (res) => {},
      (err) => console.error(err)
    );
    if (localStorage.getItem("login-info")) {
      localStorage.removeItem("login-info");
    }
    UserDataAction.logOutRequest();
    history.push("/store/");
  };

  useEffect(() => {
    getCouponLength();
  }, []);

  return (
    <Container>
      <UserNameBox>
        <UserNameText>
          <UserName>{userInfo?.mem_name || "WALA USER"}</UserName>님
        </UserNameText>
        <UserInfoBox>
          {userInfo.mem_gender && (
            <UserInfoText>
              <strong>성별</strong> : {userInfo.mem_gender === 1 ? "여자" : "남자"}
            </UserInfoText>
          )}
          {userInfo.mem_style && (
            <UserInfoText>
              <strong>선호스타일</strong> : {userInfo.mem_style}
            </UserInfoText>
          )}
          {userInfo.mem_fit && (
            <UserInfoText>
              <strong>체형</strong> : {userInfo.mem_fit}
            </UserInfoText>
          )}
          {userInfo.mem_color && (
            <UserInfoText>
              <strong>선호 색상</strong> : {userInfo.mem_color}
            </UserInfoText>
          )}
          {/* {(userInfo.mem_gender ||
            userInfo.mem_style ||
            userInfo.mem_fit ||
            userInfo.mem_color) && (
            <UserInfoText last>
              <Link to="/store/mypage/personal/1">맞춤분석 다시 하러가기 > </Link>
            </UserInfoText>
          )} */}
        </UserInfoBox>
        <LogoutButton type="button" onClick={postLogout}>
          로그아웃
        </LogoutButton>
      </UserNameBox>
      <MyInfoListBox>
        <MyInfoList
          onClick={() => {
            history.push(`/store/mypage/point`);
          }}
        >
          <MyInfoIcon>
            <img src={IconMyPoint} alt="myPoint-icon" />
          </MyInfoIcon>
          <MyInfoTitle>포인트</MyInfoTitle>
          <MyInfoText>{Commas.numberWithCommas(userInfo?.mem_point ?? 0)}P</MyInfoText>
        </MyInfoList>

        <MyInfoList
          onClick={() => {
            history.push(`/store/mypage/coupon`);
          }}
        >
          <MyInfoIcon>
            <img src={IconMyCoupon} alt="myCoupon-icon" />
          </MyInfoIcon>
          <MyInfoTitle>쿠폰</MyInfoTitle>
          <MyInfoText>{couponsLength || 0}</MyInfoText>
        </MyInfoList>
      </MyInfoListBox>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 300px;
  padding: 0 80px 0 50px;
  background: url(${ZigzagPc}) no-repeat center/contain;
  background-color: #000;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (300 / 1320));
    padding: 0 calc(100vw * (80 / 1320)) 0 calc(100vw * (50 / 1320));
  }
`;
const UserNameBox = styled.div`
  font-size: 28px;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 50px 0 30px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (28 / 1320));
    padding: calc(100vw * (50 / 1320)) 0 calc(100vw * (30 / 1320));
  }
`;

const UserNameText = styled.p`
  color: #fdf251;
  font-weight: 600;
  font-size: 34px;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (34 / 1320));
  }
`;
const UserName = styled.span`
  color: #fdf251;
  font-weight: 600;
  font-size: 34px;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (34 / 1320));
  }
`;

const MyInfoListBox = styled.ul`
  display: flex;
  align-items: center;
`;
const MyInfoList = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 126px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (126 / 1320));
  }
`;
const MyInfoIcon = styled.div`
  width: 60px;
  height: 60px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(244deg) brightness(99%) contrast(103%);
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (60 / 1320));
    height: calc(100vw * (60 / 1320));
  }
`;
const MyInfoTitle = styled.p`
  color: #999;
  font-weight: 400;
  font-size: 14px;
  margin-top: 13px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (13 / 1320));
  }
`;
const MyInfoText = styled.p`
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  margin-top: 5px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
    margin-top: calc(100vw * (5 / 1320));
  }
`;

const LogoutButton = styled.button`
  color: #999;
  font-weight: 500;
  font-size: 17px;
  text-decoration: underline;
  padding: 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (17 / 1320));
  }
`;
const UserInfoBox = styled.div`
  font-size: 12px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (12 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (12 / 428));
  }
`;
const UserInfoText = styled.p`
  color: #eee;
  padding-top: 5px;
  > strong {
    font-weight: 700;
    color: #eee;
  }
  > a {
    color: #eee;
  }
  ${({ last }) =>
    last &&
    `text-align : right; text-decoration : underline; font-weight : 700;padding-top : 15px;`}
  @media screen and (max-width: 930px) {
    > a {
      color: #eee;
      font-size: calc(100vw * (12 / 428));
    }
    padding-bottom: calc(100vw * (5 / 428));
  }
`;
