import React, { useState, useEffect  } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useSelector } from 'react-redux';
import serverController from '../../server/serverController';

import {
    TabContentHeading,
    TabContent,
    TabFaqButtonWrap,
    TabFaqButton,
    TabFaqTable,
    TabFaqHead,     
    TabPaging,
    TabPages,
    PrevButton,
    NextButton,
    PageNum,
    TabContentRow
} from '../auth/mypage/mypage.elements';
import TabFaqItem from '../auth/mypage/TabFaqItem';
import QuestionModal from '../common/pop/QuestionModal';

const InitScrollBar = createGlobalStyle`
    div {
    -ms-overflow-style: in; /* IE and Edge */
    scrollbar-width: initial; /* Firefox */
    }
    div::-webkit-scrollbar {
    display: block; /* Chrome, Safari, Opera*/
    }
`

const TabStylistFaq = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [isQuestModal, setIsQuestModal] = useState(false);
    const [list, setList] = useState([]);
    const titles = ["날짜", "문의 내용", "답변 결과"];
    const [isOpen, setIsOpen] = useState(false)

    const getFaq = async () => {
        await serverController.connectFetchController(
            `inquiry/user`,
            "GET",
            null,
            (res) => {
                console.log(res, "문의내역");
                if (res?.result === 1) {
                    setList(res?.inquiryList)
                } else {
                    console.log("문의내역 없음")
                }
            },
            (err) => console.error(err)
        )
    };

    useEffect(() => {
        getFaq()
    }, [isQuestModal])

    
    return (
        <>
        <InitScrollBar />
        <div>
            <TabContentHeading>문의 목록</TabContentHeading>
{/*             
            <TabFaqButtonWrap>
                <TabFaqButton onClick={() => setIsQuestModal(true)}>문의하기</TabFaqButton>
            </TabFaqButtonWrap> */}
            
            <TabContent>
                <div>
                    <TabFaqTable>
                        <TabFaqHead>
                        { titles.map((title, i) => <div>{title}</div>) }                    
                        </TabFaqHead>
                    </TabFaqTable>

                    {
                        list.length > 0 ? (
                            list?.map((data, i) => {
                                return (
                                    <TabFaqItem data={data} key={i}/>
                                    // <TabContentRow onClick={() => setIsOpen(prev => !prev)}>
                                    // <div className="faq-date">ss{DateToText(data?.inquiry_date, ".")}</div>                                       
                                    // <div className="faq-question">{data?.inquiry_content}</div>                                       
                                    // <div className="faq-answerd">{data?.inquiry_status}</div>                  
                                    // </TabContentRow>   
                                )
                            })
                        ) : (
                            <NoText style={{paddingTop: "100px", textAlign: "center"}}>
                                문의하신 내역이 없습니다.
                            </NoText>
                        )
                    }

                </div>
            </TabContent>
            
                    {/* <TabPaging>
                        <PrevButton />

                        <TabPages>
                        {
                            Array(5).fill().map((page, i) => {
                                return <PageNum active={tabIndex === i}>{i + 1}</PageNum>
                            })
                        }
                        </TabPages>

                        <NextButton />
                    </TabPaging> */}
        </div>

        { isQuestModal && <QuestionModal setIsQuestModal={setIsQuestModal} />}
        </>    
    )
}

const NoText = styled.p`
    font-size: calc(100vw * (16 / 1920));
    font-weight: bold;
    color: #aaa;
    text-align: center;
    margin-top: calc(100vw * (20 / 1920));

    @media screen and (max-width:1024px){
        font-size: calc(100vw * (15 / 428));
        margin-top: calc(100vw * (20 / 428));
    }
`;

export default TabStylistFaq
