import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import serverController from "../../../server/serverController";
import { RecommenderAction } from "../../../../store/actionCreators";



export default function MyRecommender() {
  const history = useHistory();
  const recommenderName = useRef();

  const trueFunc = () => {
    const chkId = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const inputData = recommenderName.current.value.replace(/ /g, '');
    if (inputData.length !== 0 && chkId.test(inputData)) {
      postRecommender(inputData);
    };
    if (inputData.length === 0) return alert("추천인 아이디를 입력해주세요.");
    if (!chkId.test(inputData)) return alert("아이디 양식에 맞게 입력해주세요.");

  };

  const postRecommender = (inputData) => {
    serverController.connectFetchController(
      `api/user/recommender?recommender=${inputData}`,
      "PUT",
      null,
      (res) => {
        console.log(res, "res");
        if (res?.result === 1) {
          localStorage.setItem('recommenderPop', 'true');
          alert('추전인 정보가 등록되었습니다.');
          history.push(`/store/mypage`);
          RecommenderAction.updateRecommender({
            recommender_cate: false
          })
        };

      },
      (err) => console.error(err)
    );
  };

  return (
    <Container>
      <ProfileBox>
        <Content>
          <TextBox>추천인 아이디를 입력해주세요.</TextBox>
          <RecommenderInput ref={recommenderName} type="text" placeholder="example@example.com"></RecommenderInput>
          <ButtonListWrap>
            <ConfirmButton onClick={trueFunc} type="button">
              저장
            </ConfirmButton>
          </ButtonListWrap>
        </Content>
      </ProfileBox>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 70px 0 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (70 / 1320)) 0 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0 0;
  }
`;
const ProfileBox = styled.div``;

const Content = styled.div`
  margin: auto;
  width: 398px;
  height: fit-content;
  padding: 0 25px 30px;
  background: #fff;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (398 / 1320));
    padding: 0 calc(100vw * (25 / 1320)) calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (398 / 428));
    padding: 0 calc(100vw * (25 / 428)) calc(100vw * (30 / 428));
  }
`;

const TextBox = styled.div`
  color: #333;
  font-weight: 400;
  font-size: 15px;
  padding: 0 0 40px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: 0 0 calc(100vw * (40 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    padding: 0 0 calc(100vw * (40 / 428));
  }
`;

const RecommenderInput = styled.input`
  width: 100%;
  padding: 16px 5px;
  border: 1px solid #000;
  margin-bottom: 20px;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (16 / 1320)) calc(100vw * (10 / 1320));
    margin-bottom: calc(100vw * (20 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (16 / 428)) calc(100vw * (10 / 428));
    margin-bottom: calc(100vw * (20 / 428));
    font-size: calc(100vw * (16 / 428));
  }
`;
const ButtonListWrap = styled.div`
  width: 100%;
`;
const ConfirmButton = styled.button`
  width: 100%;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
