import React from 'react';

//css
import styled, { css } from 'styled-components';

import { useHistory } from 'react-router-dom';

//img
import IconUpate from '../../../img/common/icon_pencil.png';
import IconDelete from '../../../img/common/icon_removeBtn.png';
import TestImg from '../../../img/content/content2.png';

export default function ProductListEl({setViewSet}) { 
    const history = useHistory();

    return(
        <ListWrap>
            <ListContainer>
                <ProdPreviewWrap>
                    <ImgBox onClick={() => history.push('/management/list/detail')}>
                        <Img src={TestImg}/>
                    </ImgBox>
                    <InformBox>
                        <Row type={'inform'}>
                            <Col>
                                <PreviewTitle long>상품명</PreviewTitle>
                                <PreviewText>노드비메이드 타이백 트렌치코트</PreviewText>
                            </Col>
                        </Row>
                        <Row type={'inform'}>
                            <Col justify={'space-between'}>
                                <Col>
                                    <PreviewTitle long>노출 순서</PreviewTitle>
                                    <PreviewText>1</PreviewText>
                                </Col>
                                <ProdBtn fl={'right'} onClick={()=>{
                                    setViewSet('changeOrder'); history.push('/management/list/order');
                                }}><MoHide>노출 </MoHide>순서 변경</ProdBtn>
                            </Col>
                        </Row>
                        <Row type={'inform'}>
                            <Col>
                                <PreviewTitle long>상품 코드</PreviewTitle>
                                <PreviewText>NBM12345FW</PreviewText>
                            </Col>
                        </Row>
                        <Row>
                            <Col justify={'space-between'}>
                                <Col>
                                    <PreviewTitle long>21.05.09</PreviewTitle>
                                    <ProdState>공개중</ProdState>
                                </Col>
                                <ProdBtn fl={'right'}><MoHide>제품 </MoHide>상태 변경</ProdBtn>
                            </Col>
                        </Row>
                    </InformBox>
                </ProdPreviewWrap>
                <ProdInformWrap>
                    <InformContainer>
                        <GrayBox>
                            <Row>      
                                <Col>
                                    <Col mr={'26px'}>
                                        <Title>신규신청</Title>
                                        <Text>03</Text>
                                    </Col>
                                    <Col>
                                        <Title>협찬승인</Title>
                                        <Text>05</Text>
                                    </Col>
                                </Col>
                                <Col>
                                    <Col mr={'26px'}>
                                        <Title>문의접수</Title>
                                        <Text>05</Text>
                                    </Col>

                                    <Col>
                                        <Title>반납예정</Title>
                                        <Text>05</Text>
                                    </Col>
                                    <ViewMore>보기<Arrow/></ViewMore>
                                </Col>
                            </Row>
                        </GrayBox>
                        <Row>
                            <Row>
                                <Col mb={'11px'} justify={'space-between'}>
                                    <Col>
                                        <PreviewTitle short>협찬</PreviewTitle>
                                        <PreviewText mr={'calc(100vw * (30 / 1920))'}>10개</PreviewText>
                                        <PreviewText>300,000(원)</PreviewText>
                                    </Col>
                                    <ProdBtn fl={'right'}>가격<MoHide>&nbsp;</MoHide>/<MoHide>&nbsp;</MoHide>수량 변경</ProdBtn>
                                </Col>
                            </Row>
                            <Row>
                                <Col mb={'11px'}>
                                    <PreviewTitle short>등록</PreviewTitle>
                                    <PreviewText>nodebemade_no1@gmail.com</PreviewText>
                                </Col>
                            </Row>
                        </Row>
                    </InformContainer>
                    <CorrectWrap>
                        <CorrectBtn icon={IconUpate}>수정</CorrectBtn>
                        <CorrectBtn icon={IconDelete}>삭제</CorrectBtn>
                    </CorrectWrap>
                </ProdInformWrap>
            </ListContainer>
        </ListWrap>
    )
}



const clear = `&:after { display:block; content:""; clear:both; }`;
const marginSet = (props)=> { return(`margin-top: ${props.mt || 0};
margin-right: ${props.mr || 0};
margin-bottom: ${props.mb || 0};
margin-left: ${props.ml || 0};`) };

const MoHide = styled.span`
    @media screen and (max-width:768px){
        display:none;
    }
`

const Arrow = styled.div`
    border-right:1px solid #CBCBCB; border-bottom:1px solid #CBCBCB; width:7px; height:7px; display:inline-block; transform:rotate(-45deg); line-height:inherit; vertical-align:center; margin-bottom:1px; margin-left:2px;
`;

const ListWrap = styled.div`
    padding:0 calc(100vw * (64 / 1920));
    margin-top:30px;
    &:first-child { margin-top:0; }
    @media screen and (max-width:1300px){
        padding:0;
    }
`;
const ListContainer = styled.div`
    width:100%; color:#222222; box-sizing:border-box;
    border-bottom:1px solid #D9D9D9;
    padding:0 0 34px;
    
    ${clear};
    display:flex; flex-direction:row;

    @media screen and (max-width:1300px){
        padding:0; flex-direction:column;
    }
    @media screen and (max-width:768px){
        padding-bottom:calc(100vw * (48 / 768));
    }
`;

const ProdPreviewWrap = styled.div`
    position:relative; vertical-align:top; width:50%; padding-right:calc(100vw * (32 / 1920)); border-right:1px solid #D9D9D9;
    ${clear}; display:flex; flex-direction:row;
    @media screen and (max-width:1300px){
        width:100%; margin-bottom:24px; padding-right:0; border:none;
    }
`;

const ProdInformWrap = styled.div`
    position:relative; vertical-align:top; width:50%; 
    padding-left:calc(100vw * (32 / 1920));
    ${clear}; 
    display:flex; flex-direction:row;
    @media screen and (max-width:1300px){
        width:100%; padding-left:0;
    }
    @media screen and (max-width:768px){
        flex-direction:column;
    }
`;

const InformContainer = styled.div`
    position:relative; width:calc(100% - 98px); 
    padding-right:calc(100vw * (18 / 1920));
    @media screen and (max-width:768px){
        width:100%; padding-right:0;
    }
`;

const ImgBox = styled.div`
    width:126px; height:126px; vertical-align:top;
    background:#cccccc; overflow:hidden;
    cursor: pointer;
    
    @media screen and (max-width:768px){
        width:102px; height:102px;
    }
`;
const Img = styled.img`
    width:100%; object-fit: cover;
`;

const InformBox = styled.div`
    position:relative; width: calc(100% - 126px); padding-left:16px;
    &:after { content:""; display:block; clear:both; }

    @media screen and (max-width:768px){
        width: calc(100% - 102px);
    }
`;
const Row = styled.div`
    position:relative;
    ${props=>props.type == 'inform' && css`
        margin-bottom:calc(100vw * (12 / 1920));
        @media screen and (max-width:768px){
            margin-bottom:calc(100vw * (3 / 428));
        }
    `}
`;

const Col = styled.div`
    ${props => marginSet(props)};
    height:auto; width:auto;
    display:flex; position:relative;
    ${props => props.justify && css`
        justify-content:${props.justify};
    `}
`;

const PreviewTitle = styled.p`
    font-size:16px; line-height:24px; font-weight:bold;
    ${props => props.long && css`
        width:78px;
    `}
    ${props => props.short && css`
        width:60px;
    `}

    @media screen and (max-width:768px){
        font-size:14px;
        ${props => props.long && css`
        width:64px;
    `}
    ${props => props.short && css`
        width:38px;
    `}
    }
`
const PreviewText = styled.p`
    ${props => marginSet(props)};
    font-size:15px; line-height:24px; word-break:keep-all;
    @media screen and (max-width:768px){
        font-size:14px;
    }
`
const ProdState = styled.p`
    line-height:24px;
    @media screen and (max-width:768px){
        font-size:14px;
    }
`
const Title = styled.p`
    font-size:14px; line-height:24px; margin-right:calc(100vw * (26 / 1920));
    @media screen and (max-width:768px){
        font-size:13px;
    }
`
const Text = styled.p`
    font-size:14px; line-height:24px;
    @media screen and (max-width:768px){
        font-size:13px;
    }
`

const ProdBtn = styled.button`
    display:block; font-size:14px; border:1px solid #222222; border-radius:17px; padding:1px calc(100vw * (22 / 1920)) 3px; 
    box-sizing:border-box;
    float:${props => props.fl || 'none'};
    
    @media screen and (max-width:768px){
        padding:1px calc(100vw * (20 / 768)) 3px;
    }
`;

const GrayBox = styled.div`
    margin-bottom:12px;
    background:#F7F7F8; padding:6px 16px; position:relative;
    ${clear};
`;

const ViewMore = styled.a`
    font-size:15px; color:#CBCBCB; position:absolute; right:0; bottom:0;
`

const CorrectWrap = styled.div`
    width:98px; 
    @media screen and (max-width:768px){
        width:unset; display:flex; justify-content: right;
    }
`;

const CorrectBtn = styled.button`
    width:98px; height:46px; background:#222222; border-radius:24px; color:#ffffff; font-size:15px; font-weight:900; margin-bottom:7px;
    position:relative;
    &:after { content:''; display:inline-block; width:17px; height:17px; background:url(${props => props.icon}) no-repeat center /contain; margin-left:15px; margin-bottom:-3px; }

    @media screen and (max-width:768px){
        width:80px; height:35px; font-size:13px; margin-bottom:0;
        &:first-child { margin-right:5px; }
        &:after { width:12px; height:12px; margin-left:15px; margin-bottom:-1px; }
    }

`;
