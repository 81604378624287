//react
import styled, { keyframes } from "styled-components";

export default function MyPageDefaultModal({
  textT,
  textB,
  trueFunc,
  falseFunc,
  trueText = "확인",
  falseText = "취소",
}) {
  return (
    <Container>
      <Background onClick={falseFunc} />
      <Content>
        <TextBox>
          {textT}
          <br />
          {textB}
        </TextBox>
        <ButtonListWrap>
          <CancelButton onClick={falseFunc} type="button">
            {falseText}
          </CancelButton>
          <ConfirmButton onClick={trueFunc} type="button">
            {trueText}
          </ConfirmButton>
        </ButtonListWrap>
      </Content>
    </Container>
  );
}

// s :: animation
const LoginPop = keyframes`
0%{
    transform : translateY(-10px);
		opacity: 0.5;
}
	100% {
		opacity: 1;
	}
`;
const LoginBackgroundPop = keyframes`
0%{
		opacity: 0.4;
}
	100% {
		opacity: 1;
	}
`;
// e :: animation

const Container = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 700;
  animation: ${LoginBackgroundPop} 0.2s ease-in-out;
`;

// 컨텐츠
const Content = styled.div`
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 702;
  width: 398px;
  height: fit-content;
  padding: 0 25px 30px;
  background: #fff;
  overflow: hidden;
  animation: ${LoginPop} 0.2s ease-out;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (398 / 1320));
    padding: 0 calc(100vw * (25 / 1320)) calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (398 / 428));
    padding: 0 calc(100vw * (25 / 428)) calc(100vw * (30 / 428));
  }
`;

const TextBox = styled.div`
  color: #333;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  padding: 50px 0 40px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (50 / 1320)) 0 calc(100vw * (40 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (50 / 428)) 0 calc(100vw * (40 / 428));
  }
`;

const ButtonListWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const ConfirmButton = styled.button`
  width: 169px;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (169 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (169 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
const CancelButton = styled(ConfirmButton)`
  color: #000;
  background-color: #fff;
`;
