import axios from "axios";

const config = {
  baseURL: process.env.REACT_APP_SERVER,
};

export const instance = axios.create(config);

const responseBody = (response) => {
  return response.data;
};

export const requests = {
  download: (url) => instance.get(url, { responseType: "blob" }).then(responseBody),
  getImage: (url) => instance.get(url, { responseType: "blob" }).then((response) => window.URL.createObjectURL(new Blob([response.data]))),
  get: (url) => instance.get(url).then(responseBody),
  post: (url, body, config) => instance.post(url, body, config).then(responseBody),
  put: (url, body) => instance.put(url, body).then(responseBody),
  delete: (url) => instance.delete(url).then(),
};

export const Get = {
  //카테고리 베스트 아이템 API
  getCategoryBestList: ({ prd_main_cat, prd_sub_cat }) =>
    requests.get(`/product/best?limit=6&prd_main_cat=${prd_main_cat}&prd_sub_cat=${prd_sub_cat}`),
  //모든 카테고리 리스트
  getCategoryList: () => requests.get(`/category/all`),
  //카테고리 상품 목록
  getCategoryProductList: ({ page = 1, limit, prd_main_cat, prd_sub_cat, filter, range }) =>
    requests.get(`/products?page=${page}&limit=${limit}&prd_main_cat=${prd_main_cat}&prd_sub_cat=${prd_sub_cat}&filter=${filter}&range=${range}`),
  // 기획전 카테고리 조회
  getPlanData: ({ event_id }) => requests.get(`api/event/${event_id}`),
  // 기획전 상품리스트 전체보기
  getPlanAllList: ({ eventCategoryId }) => requests.get(`api/event/${eventCategoryId}/product?offset=0&limit=200`),
  // 기획전 상품리스트
  // getPlanList: ({ eventCategoryId, offset }) => requests.get(`api/event/${eventCategoryId}/product?offset=${offset}&limit=10`),
  getPlanList: ({ eventCategoryId, offset = 0 }) => requests.get(`api/event/${eventCategoryId}/product?offset=${offset}&limit=10`),
};

export const Post = {
  /*********************************** when item click ********************************/
  gasd: (body) => requests.post("/my-page/archive/recently/video", body),
};

export const Delete = {
  /*********************************** race-controller ********************************/
  deletePost: (data) => requests.delete("/race/mission/post", data),
};

export const Put = {
  //관심 키워드 수정
  updateKeyword: (body) => requests.put("/my-page/keyword", body),
};
