import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import qs from "qs";
import styled from "styled-components";
import { Mobile, PC } from "../../MediaQuery";

// controller
import serverController from "../../server/serverController";

// component
import MyPageHeader from "./MyPageHeader";
import MyOrderList from "../../component/mypage/order/MyOrderList";
import Header from "../../component/common/Header";
import MyOrderTab from "../../component/mypage/order/MyOrderTab";
import FixMenu from "../../component/common/FixMenu";
import NewPagination from "../../component/common/pagination/NewPagination";

import MyPageLayOutMenu from "../../component/mypage/MyPageLayOutMenu";
import MyPageLayOutName from "../../component/mypage/MyPageLayOutName";
import MyPageLayOutInfo from "../../component/mypage/MyPageLayOutInfo";

// order component
import FullLoading from "../../component/common/modal/FullLoading";
import { useSelector } from "react-redux";

export default function OrderPage() {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();
  const params = useParams();

  const [hide, setHide] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(query.page ?? 1);
  const [pageData, setPageData] = useState([]);

  // get status
  const getStatus = (mog_order_status) => {
    switch (mog_order_status) {
      case 0:
        return "미입금";
      case 5:
        return "옵션변경요청";
      case 6:
        return "배송지 변경요청";
      case 10:
        return "무통장 대기";
      case 11:
        return "결제취소";
      case 12:
        return "결제실패";
      case 13:
        return "신규주문";
      case 14:
        return "상품준비중";
      case 20:
        return "결제완료";
      case 30:
        return "배송준비";
      case 41:
        return "배송중";
      case 42:
        return "배송완료";
      case 43:
        return "부분 배송중";
      case 44:
        return "구매 확정";
      case 50:
        return "반품요청";
      case 51:
        return "반품중";
      case 52:
        return "반품완료";
      case 53:
        return "반품수거 접수완료";
      case 54:
        return "반품 수거중";
      case 55:
        return "반품 수거완료";
      case 57:
        return "교환 요청";
      case 58:
        return "교환 접수";
      case 59:
        return "교환 수거중";
      case 60:
        return "교환 수거완료";
      case 61:
        return "교환중";
      case 62:
        return "교환완료";
      case 70:
        return "주문취소";
      case 71:
        return "취소 요청";
      case 72:
        return "취소 대기";
      case 73:
        return "취소 거부";
      default:
        return;
    }
  };

  // get order history
  const getOrderHistory = (type) => {
    if (type) {
      serverController.connectFetchController(
        `payment/history?orderType=${type}&dateType=year&page=${query.page}&limit=10`,
        "GET",
        null,
        (res) => {
          if (res?.result === 1) {
            const list = res.payment_list;
            setOrderList(list);
            setPageData(res.pagination);
          }
          //  else {
          //   alert("일시적 오류입니다. 문제가 지속될 경우 고객센터로 문의바랍니다.");
          // }
          setLoading(false);
        }
      );
    }
  };

  const validLogin = () => {
    if (isLogged) return;
    alert(`잘못된 접근입니다.`);
    history.push(`/store`);
  };

  const settingTypeId = () => {
    let type = "";
    if (params.tab_id === "0") {
      type = "order";
    } else if (params.tab_id === "1") {
      type = "ing";
    } else if (params.tab_id === "2") {
      type = "finish";
    } else if (params.tab_id === "3") {
      type = "cancel";
    }
    return getOrderHistory(type);
  };

  useEffect(() => {
    if (params.tab_id) {
      setPage(1);
      settingTypeId();
    }
  }, [params.tab_id]);

  const prevFunc = () => {
    history.push(`/store/mypage`);
  };

  // header scroll control
  useEffect(() => {
    // s :: scroll down fix
    let lastScrollY = 0;

    window?.addEventListener("scroll", (e) => {
      const scrollY = window?.scrollY;

      const direction = scrollY > lastScrollY ? true : false;

      lastScrollY = scrollY;

      if (lastScrollY > 130) setHide(direction);
    });

    // e :: scroll down fix
  }, []);

  useEffect(() => {
    history.push(`/store/mypage/order/${params.tab_id}?page=${page}`);
  }, [page]);

  useEffect(() => {
    validLogin();
  }, []);

  useEffect(() => {
    settingTypeId();
  }, [query.page]);

  return (
    <>
      {loading && <FullLoading />}
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader title={"주문/배송"} prevBtn prevFunc={prevFunc} />
      </Mobile>

      <MyOrderTabArea>
        {/* page */}
        <PC>
          <MyPageLayOutName />
        </PC>

        {/* content */}
        <FlexLayOut>
          <PC>
            <MyPageLayOutMenu />
          </PC>

          <ContentSection>
            <PC>
              <MyPageLayOutInfo />
            </PC>

            {/* tab */}
            <MyOrderSection>
              <MyOrderTab tabIndex={params.tab_id} hide={hide} />
              <MyOrderList orderList={orderList} getStatus={getStatus} tabIndex={params.tab_id} />
              {orderList.length > 3 && <FixMenu />}
              {pageData.maxPage !== 1 && orderList.length > 0 && (
                <NewPagination page={page} setPage={setPage} pageData={pageData} />
              )}
            </MyOrderSection>
          </ContentSection>
        </FlexLayOut>
      </MyOrderTabArea>
    </>
  );
}

const MyOrderTabArea = styled.div`
  width: 100%;
  padding: 180px 0 200px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const MyOrderSection = styled.div`
  padding: 20px 0 0;
  background-color: #fff;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0 0;
  }

  @media screen and (max-width: 930px) {
    padding: 0 0 calc(100vw * (100 / 428));
    background-color: #f9f9f9;
  }
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
