import { enableAllPlugins } from "immer";
import { createAction, handleActions } from "redux-actions";
import produce from "immer";
enableAllPlugins();

const SAVE_PRODUCT_PAYMENT_INFO = "paymentData/SAVE_PRODUCT_PAYMENT_INFO";
const SAVE_SPONSERD_PAYMENT_INFO = "paymentData/SAVE_SPONSERD_PAYMENT_INFO";
const SAVE_PERSONAL_INFO = "paymentData/SAVE_PERSONAL_INFO";
const SAVE_COUPON_INFO = "paymentData/SAVE_COUPON_INFO";
const SAVE_LOCATION_INFO = "paymentData/SAVE_LOCATION_INFO";
const SAVE_METHOD_INFO = "paymentData/SAVE_METHOD_INFO";
const CHECK_INFO = "paymentData/CHECK_INFO";
const SAVE_POINT = "paymentData/SAVE_POINT";
const REQUEST_PAYMENT_SUCCESS = "paymentData/REQUEST_PAYMENT_SUCCESS";
const RESET_DATA = "paymentData/RESET_DATA";

export const saveProductsPaymentInfo = createAction(SAVE_PRODUCT_PAYMENT_INFO);
export const saveSponserdPaymentInfo = createAction(SAVE_SPONSERD_PAYMENT_INFO);
export const savePersonalInfo = createAction(SAVE_PERSONAL_INFO);
export const saveCouponInfo = createAction(SAVE_COUPON_INFO);
export const saveLocationInfo = createAction(SAVE_LOCATION_INFO);
export const saveMethodInfo = createAction(SAVE_METHOD_INFO);
export const checkInfo = createAction(CHECK_INFO);
export const savePoint = createAction(SAVE_POINT);
export const requestPaymentSuccess = createAction(REQUEST_PAYMENT_SUCCESS);
export const resetData = createAction(RESET_DATA);

const initialState = {
  payment: [],
  options: [],
  points: 0,
  coupons: {},
  personal: {
    name: "",
    email: "",
    phone: "",
  },
  location: {
    receiver_zipcode: "",
    receiver_address: "",
    receiver_address_detail: "",
    receiver_request: "",
    receiver_title: "",
    receiver_username: "",
    receiver_phone: "",
    receiver_state: false,
  },
  method: "카드",
  isPersonal: false,
  isTermCheck: false,
  isDefault: false,
  totalPrice: 0,
};

export default handleActions(
  {
    [SAVE_PRODUCT_PAYMENT_INFO]: (state, action) => {
      return produce(state, (draft) => {
        draft.payment = action.payload
          ? draft.payment.concat(action.payload)
          : draft.payment;
      });
    },
    [SAVE_SPONSERD_PAYMENT_INFO]: (state, action) => {
      return produce(state, (draft) => {
        draft.payment = action.payload
          ? draft.payment.concat(action.payload)
          : draft.payment;
      });
    },
    [SAVE_PERSONAL_INFO]: (state, action) => {
      return produce(state, (draft) => {
        draft.personal = action.payload ?? draft.personal;
      });
    },
    [SAVE_COUPON_INFO]: (state, action) => {
      return produce(state, (draft) => {
        draft.coupons = action.payload ?? draft.coupons;
      });
    },
    [SAVE_LOCATION_INFO]: (state, action) => {
      return produce(state, (draft) => {
        draft.location = action.payload
          ? Object.assign({}, { ...draft.location }, action.payload)
          : draft.location;
      });
    },
    [SAVE_METHOD_INFO]: (state, action) => {
      return produce(state, (draft) => {
        draft.method = action.payload ?? draft.method;
      });
    },
    [CHECK_INFO]: (state, action) => {
      return produce(state, (draft) => {
        draft.isPersonal = action.payload.isPersonal ?? draft.isPersonal;
        draft.isTermCheck = action.payload.isTermCheck ?? draft.isTermCheck;
        draft.isDefault = action.payload.isDefault ?? draft.isDefault;
        draft.totalPrice = action.payload.totalPrice ?? draft.totalPrice;
      });
    },
    [SAVE_POINT]: (state, action) => {
      return produce(state, (draft) => {
        draft.points = action.payload.points ?? draft.points;
      });
    },
    [REQUEST_PAYMENT_SUCCESS]: (state, action) => {
      return produce(state, (draft) => {
        draft.payment = [];
        draft.options = [];
        draft.points = 0;
        draft.coupons = {};
        draft.personal = {
          name: "",
          email: "",
          phone: "",
        };
      });
    },
    [RESET_DATA]: (state, action) => {
      return produce(state, (draft) => {
        draft.payment = [];
        draft.options = [];
        draft.points = 0;
        draft.coupons = {};
        draft.personal = {
          name: "",
          email: "",
          phone: "",
        };
        draft.isRequire = false;
      });
    },
  },
  initialState
);
