import { useEffect, useState } from "react";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";

import Header from "../../component/common/Header";
import FullLoading from "../../component/common/modal/FullLoading";

import MyInquiryEditModal from "../../component/common/modal/MyInquiryEditModal";

import MyPageLayOutMenu from "../../component/mypage/MyPageLayOutMenu";
import MyPageLayOutName from "../../component/mypage/MyPageLayOutName";
import MyPageLayOutInfo from "../../component/mypage/MyPageLayOutInfo";

export default function MyInquiryEditPage() {
  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState(false);
  const [detailItem, setDetailItem] = useState([]);

  const myQuestionItems = {
    setDetail,
    setLoading,
    setDetailItem,
    detail,
  };

  const detailPrev = () => {
    setDetail(false);
  };

  return (
    <>
      <PC>
        <Header />
      </PC>

      <MyQuestionArea>
        {/* page */}
        <PC>
          <MyPageLayOutName />
        </PC>

        <FlexLayOut>
          <PC>
            <MyPageLayOutMenu />
          </PC>

          {/* content */}
          <ContentSection>
            <PC>
              <MyPageLayOutInfo />
            </PC>

            <MyQuestionSection>
              <MyInquiryEditModal btnText={"등록하기"} />
            </MyQuestionSection>
          </ContentSection>
        </FlexLayOut>
      </MyQuestionArea>
    </>
  );
}

const MyQuestionArea = styled.div`
  width: 100%;
  padding: 180px 0 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 0;
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const MyQuestionSection = styled.div``;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
