import { useEffect, useState } from "react";
import { Mobile, PC } from "../../MediaQuery";

// component
import Header from "../../component/common/Header";
import MyPageHeader from "./MyPageHeader";
import MyProductInquiryDetail from "../../component/mypage/inquiry/MyProductInquiryDetail";

// controller
import serverController from "../../server/serverController";

export default function MyProductInquiryDetailPage() {

  return (
    <>
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader title={"문의내역"} prevBtn />
      </Mobile>
      <MyProductInquiryDetail/>
    </>
  );
}
