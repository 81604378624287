import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";

import MyPageHeader from "./MyPageHeader";
import MyPointList from "../../component/mypage/point/MyPointList";
import FixMenu from "../../component/common/FixMenu";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function PointPage() {
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();

  const validLogin = () => {
    if (isLogged) return;
    alert(`잘못된 접근입니다.`);
    history.push(`/store`);
  };

  useEffect(() => {
    validLogin();
  }, []);

  return (
    <>
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader title={"포인트"} prevBtn />
      </Mobile>
      <MyPointList />
      <FixMenu />
    </>
  );
}
