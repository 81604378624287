import React from 'react';

import styled, {css} from 'styled-components';
import { ManagementAction } from '../../store/actionCreators';

export const ManagementTap = ({activeTab})=>{
    return(
        <ManagementTabWrap>
            <TabContainer>
                <TabWrap>
                    <TabBtn isTabActive={activeTab + 1} onClick={()=>{ManagementAction.updateActiveTab({TabIndex:0})}}>제품 관리</TabBtn>
                    <TabBtn isTabActive={activeTab + 1} onClick={()=>{ManagementAction.updateActiveTab({TabIndex:1})}}>콘텐츠 관리</TabBtn>
                </TabWrap>
            </TabContainer>
        </ManagementTabWrap>
    )
}

export const ProductOrderTab = ({})=>{
    return(
        <ProductOrderTabWrap>
            <TabContainer>
                <TabWrap>
                    <TabBtn>협찬</TabBtn>
                    <TabBtn>일반대여</TabBtn>
                    <TabBtn>판매</TabBtn>
                </TabWrap>
            </TabContainer>
        </ProductOrderTabWrap>
    )
}




const TabContainer = styled.div`
    

    @media screen and (max-width:768px){
        width:100%;
    }
`;
const TabWrap = styled.div`

`;
const TabBtn = styled.button`
    &:nth-child(${props => props.isTabActive}) { box-shadow: inset 0 -5px 0 #222222; }
`;

const ManagementTabWrap = styled.div`
    ${TabContainer}{
        width: calc(100vw * (1300 / 1920)); margin:0 auto; border-bottom:1px solid #cccccc;
        padding-top:calc(100vw * (76 / 1920));
        @media screen and (max-width:768px){
            width:100%; padding-left:calc(100vw * (25 / 428)); padding-right:calc(100vw * (25 / 428));
        }
    }
    ${TabBtn}{
        font-size:18px; line-height:25px; font-weight:700; color:#222222; padding:18px calc(100vw * (60 / 1920));
        @media screen and (max-width:768px){
        width:50%;
    }
    }
`;

const ProductOrderTabWrap = styled.div`
    ${TabContainer}{
        width: calc(100vw * (1500 / 1920)); margin:0 auto; border-bottom:2px solid #222222;
        padding:calc(100vw * (76 / 1920)) calc(100vw * (100 / 1920)) 0;
        @media screen and (max-width:768px){
            width:100%;
        }
    }
    ${TabBtn}{
        font-size:16px; line-height:22px; font-weight:400; color:#222222; padding:20px calc(100vw * (25 / 1920));
        position:relative;
        &:not(:last-child):after { content:""; display:inline-block;  height:11px; width:1px; background:#D9D9D9; position:absolute; right:0; top:50%; transform:translateY(-50%); }

        @media screen and (max-width:768px){
            padding:12px 15px;
        }
    }
`;