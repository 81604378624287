import React from "react";

import Header from "../../component/common/Header";
import Login from "../../component/login/Login";
import ScrollToTop from "components/common/ScrollToTop";

export default function LoginPage() {
  return (
    <>
      <Header back url={`/store`} />
      <Login />
      <ScrollToTop />
    </>
  );
}
