import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Mobile } from "../../MediaQuery";
import { useParams } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import Header from "../../store_src/component/common/Header";
import AllProduct from "../../store_src/component/product/AllProduct";
import FixMenu from "../../store_src/component/common/FixMenu";
import FooterMenu from "../../store_src/component/common/FooterMenu";
import CategoryMenu from "../../store_src/component/product/CategoryMenu";
import RankProduct from "../../store_src/component/product/RankProduct";
import IconClose from "../../store_src/img/icon/icon_popup_close.svg";
import serverController from "../../server/serverController";
import qs from "qs";
import MyPageDefaultModal from "../../store_src/component/common/modal/MyPageDefaultModal";
import { PRODUCT_LIST_FILTER, PRODUCT_PRICE_FILTER } from "../../constants/filter";
import StoreCategoryBestContainer from "../../containers/storeCategoryContainer/StoreCategoryBestContainer";
import MobileProductActionSheet from "../../newComponents/storeCategory/MobileProductActionSheet";
import DarkBackground from "newComponents/common/DarkBackground";
import { Get } from "api";
import { queryKeys } from "constants/queryKeys";
import useRequestQuery from "hooks/useRequestQuery";
import StoreCategoryListContainer from "containers/storeCategoryContainer/StoreCategoryListContainer";
import CategoryAside from "newComponents/storeCategory/CategoryAside";
import SkeletonUI from "newComponents/common/SkeletonUI";
import queryString from "query-string";
import { crypto } from "utils";

const body = document.querySelector("body");

//range = 0 1234 가격
//filter 0 12 34

//filterF 가격
//filterF 최신순??

export default function StoreCategoryContainer() {
  const [purchaseState, setPurchaseState] = useState(false);
  const [isOpenPrice, setIsOpenPrice] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const { p, s } = queryString.parse(location.search);
  const decodedP = p ? crypto.decryptionBASE64(p) : 0;
  const decodedS = s ? crypto.decryptionBASE64(s) : 0;
  const params = new URLSearchParams(location.search);
  const ProductListRef = useRef();

  const requestData = {
    requestAPI: Get.getCategoryList,
    requestData: {},
    queryKey: [queryKeys.STORE_CATEGORY_LIST],
    options: {
      retry: 0,
      staleTime: 10 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
      keepPreviousData: true,
    },
  };

  const { data: asideData, isSuccess, isError, isLoading } = useRequestQuery(requestData);
  console.log("모든 카테고리 리스트 API : ", asideData);

  // filter
  // useEffect(() => {
  //   if (filterShowF) {
  //     body.style.overflow = "hidden";
  //     body.style.height = "100vh";
  //   } else {
  //     body.style.overflow = "auto";
  //     body.style.height = "auto";
  //   }
  // }, [filterShowF]);

  // useEffect(() => {
  //   if (filterShowP) {
  //     body.style.overflow = "hidden";
  //     body.style.height = "100vh";
  //   } else {
  //     body.style.overflow = "auto";
  //     body.style.height = "auto";
  //   }
  // }, [filterShowP]);

  // overflow hidden or auto
  // useEffect(() => {
  //   if (purchaseState) {
  //     body.style.overflow = "hidden";
  //     body.style.height = "100vh";
  //   } else {
  //     body.style.overflow = "auto";
  //     body.style.height = "auto";
  //   }
  // }, [purchaseState]);

  const handleClickFilterAction = (index) => {
    const strIndex = index.toString();
    if (decodedS === 0) {
      params.set("s", crypto.encryptionBASE64(strIndex));
      setIsOpenFilter(false);
      return history.replace(`${location.pathname}?${params.toString()}`);
    }
    if (decodedS !== 0) {
      params.set("s", crypto.encryptionBASE64(strIndex));
      setIsOpenFilter(false);
      return history.replace(`${location.pathname}?${params.toString()}`);
    }
  };

  const handleClickPriceAction = (index) => {
    const strIndex = index.toString();
    if (decodedP === 0) {
      params.set("p", crypto.encryptionBASE64(strIndex));
      setIsOpenPrice(false);
      return history.replace(`${location.pathname}?${params.toString()}`);
    }
    if (decodedP !== 0) {
      params.set("p", crypto.encryptionBASE64(strIndex));
      setIsOpenPrice(false);
      return history.replace(`${location.pathname}?${params.toString()}`);
    }
  };

  return (
    <>
      <CateContainer>
        {purchaseState && <BackGroundGray onClick={() => setPurchaseState(false)} />}

        {asideData && (
          <Mobile>
            <CategoryMenu asideData={asideData} />
          </Mobile>
        )}

        <Header back url={`/store`} />

        <StoreCategoryBestContainer />
        <StoreCategoryListContainer
          ref={ProductListRef}
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenPrice={setIsOpenPrice}
        >
          {isLoading && <SkeletonUI.AsidePc />}
          {isSuccess &&
            asideData.list.map((menuData) => {
              return <CategoryAside ref={ProductListRef} key={menuData.catId} item={menuData} />;
            })}
        </StoreCategoryListContainer>

        <Mobile>
          <FooterMenu />
        </Mobile>
      </CateContainer>

      {isOpenFilter && (
        <>
          <DarkBackground status={() => setIsOpenFilter(false)}>
            <MobileProductActionSheet
              listItem={PRODUCT_LIST_FILTER}
              onClickAction={handleClickFilterAction}
              onClickClose={() => setIsOpenFilter(false)}
              targetQueryString={Number(decodedS)}
            />
          </DarkBackground>
        </>
      )}

      {isOpenPrice && (
        <>
          <DarkBackground status={() => setIsOpenPrice(false)}>
            <MobileProductActionSheet
              listItem={PRODUCT_PRICE_FILTER}
              onClickAction={handleClickPriceAction}
              onClickClose={() => setIsOpenPrice(false)}
              targetQueryString={Number(decodedP)}
            />
          </DarkBackground>
        </>
      )}
      <FixMenu />
    </>
  );
}

const BackGroundGray = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 40%;
  position: fixed;
  z-index: 99;
`;
const CateContainer = styled.div`
  padding-top: 180px;
  position: relative;
  @media screen and (max-width: 930px) {
    padding-top: 0;
    min-height: calc(100vw * (700 / 428));
  }
`;
