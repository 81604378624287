import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DateToText } from '../../common/commonUse';
import api from '../../../server/serverController';

import {
    FieldBox,
    Label,
    LabelName,
    EmailInput,
    PasswordInput,
    TelephoneInput,
    NameInput,
    Input
} from '../myinfoauth.elements';
import {
    SubCheckButton
} from '../signup/signup.element';
import {
    TabContentHeading,
    TabContent,
    TabUserForm,
    ModifyButton
} from './mypage.elements';

import MyPageModal from '../../common/pop/MyPageModal';

const TabContentUser = () => {
    const { isLogged, userInfo } = useSelector(state => state.userData);
    const history = useHistory();

    const [isPhone, setIsPhone] = useState(false);
    const [isPassword, setIsPassword] = useState(false);
    const [isModifyPop, setIsModifyPop] = useState(false);


    const [newPhone, setNewPhone] = useState("")
    const [certiNum, setCertiNum] = useState("");
    const [confirm, setConfirm] = useState("")
    const [isCertificate, setIsCertificate] = useState(false)

    const getPhoneAuthentication = async () => {
        // const regExpPhone = /01[016789]-[^0][0-9]{2,3}-[0-9]{3,4}/;       
        // if (!regExpPhone.test(newPhone)) {
        //     return alert("휴대폰 형식에 맞춰 입력해주세요. ex)010-1234-5678")
        // }
        const path = `overlap/phone?mem_phone=${newPhone}`;
        const res = await api.post(path);
        console.log(res, "전화인증요청 res");
        if (res?.result === 1) {
            alert("전화 인증메세지 발송했습니다.")
            setCertiNum(res?.identify_num);
            // setIsPhone(false);
        } else {
            alert(res.message + '입니다')
        }
    }

    const handlePhoneConfirm = () => {
        if (!certiNum) {
            alert("새 연락처 인증번호 받기를 먼저 진행해주세요.")
            // setIsConfirmError(true);
            // setConfirmErrorMessage("본인 인증을 먼저 진행해주세요")
        } else if (certiNum === confirm) {
            alert("인증 성공했습니다.");
            // setIsPhone(false);
            setIsCertificate(true);
        } else if (certiNum !== confirm) {
            alert("맞지 않는 인증번호입니다.")
        }
    }

    const requestModified = async () => {
        if (isCertificate) {
            const formData = new FormData();
            formData.append("mem_phone", newPhone)

            const res = await api.post(`user/update`, formData);
            console.log(res, "수정요청");
            if (res?.result === 1) {
                history.go(0);
            }
        }
    }

    return (
        <>
            <div>
                <TabContentHeading>회원정보 수정</TabContentHeading>
                <TabContent>
                    <TabUserForm>
                        <div>
                            <FieldBox>
                                <Label>
                                    <LabelName>이름</LabelName>
                                    <NameInput value={userInfo?.mem_name} readOnly />
                                </Label>
                            </FieldBox>

                            <FieldBox>
                                <Label>
                                    <LabelName>연락처</LabelName>
                                    <TelephoneInput placeholder="입력된 휴대폰 번호가 없습니다" value={userInfo?.mem_phone} readOnly />
                                </Label>
                                <SubCheckButton onClick={() => setIsPhone(true)}>연락처 변경</SubCheckButton>
                            </FieldBox>

                            {
                                isPhone && (
                                    <>
                                        <FieldBox>
                                            <Label>
                                                <LabelName>새 연락처</LabelName>
                                                <TelephoneInput placeholder="새 연락처를 입력해주세요." value={newPhone} onChange={(e) => setNewPhone(e.target.value)} />
                                            </Label>
                                            <SubCheckButton onClick={getPhoneAuthentication}>인증번호 받기</SubCheckButton>
                                        </FieldBox>

                                        <FieldBox>
                                            <Label>
                                                <LabelName>인증번호</LabelName>
                                                <Input placeholder="인증번호를 입력해주세요." value={confirm} onChange={(e) => setConfirm(e.target.value)} />
                                            </Label>
                                            <SubCheckButton onClick={handlePhoneConfirm}>확인</SubCheckButton>
                                        </FieldBox>
                                    </>
                                )
                            }

                            <FieldBox>
                                <Label>
                                    <LabelName>이메일</LabelName>
                                    <EmailInput value={userInfo?.mem_email} readOnly />
                                </Label>
                            </FieldBox>

                            <FieldBox>
                                <Label>
                                    <LabelName>현재 비밀번호</LabelName>
                                    <PasswordInput value={'*****'} readOnly />
                                </Label>
                                <SubCheckButton onClick={() => setIsPassword(true)}>비밀번호 변경</SubCheckButton>
                            </FieldBox>

                            {
                                isPassword && (
                                    <>
                                        <FieldBox>
                                            <Label>
                                                <LabelName>새 비밀번호</LabelName>
                                                <PasswordInput />
                                            </Label>

                                        </FieldBox>
                                        <FieldBox>
                                            <Label>
                                                <LabelName>새 비밀번호 확인</LabelName>
                                                <PasswordInput placeholder="입력하신 비밀번호를 한번 더 입력해주세요" />
                                            </Label>
                                            <SubCheckButton onClick={() => setIsPassword(false)}>확인</SubCheckButton>
                                        </FieldBox>
                                    </>
                                )
                            }

                            <FieldBox>
                                <Label>
                                    <LabelName readOnly>계정 생성일</LabelName>
                                    <Input value={DateToText(userInfo?.mem_register_date, ".")} readOnly />
                                </Label>
                            </FieldBox>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ModifyButton onClick={() => {
                                setIsModifyPop(true)
                                requestModified()
                            }}>수정 완료</ModifyButton>
                        </div>

                    </TabUserForm>
                </TabContent>
            </div>
            {isModifyPop && <MyPageModal setIsModifyPop={setIsModifyPop} />}
        </>
    )
}

export default TabContentUser;
