import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Mobile, PC } from "../../../MediaQuery";
import GrayZone from "../GrayZone";

import ProductListThree from "../product/ProductListThree";
import { imgURL } from "../../../server/appInfoController";
import { useHistory } from "react-router-dom";

export default function ProductToCartModal({ listWith, thumbs, setClickCartPop, clickCartPop }) {
  const history = useHistory();

  const [closeButtonTouch, setCloseButtonTouch] = useState(false);
  return (
    <FixMenuWrap clickCartPop={clickCartPop}>
      <FixMenuStepWrap>
        <FixMenuTop>
          <LeftSection>
            <img src={`${imgURL}${thumbs}`} alt="product-img" />
          </LeftSection>
          <RightSection>
            <TextTop>장바구니에 상품이 추가 되었습니다.</TextTop>
            <TextBottom
              onClick={() => {
                history.push(`/store/cart`);
              }}
            >
              장바구니 확인 &gt;
            </TextBottom>
          </RightSection>
        </FixMenuTop>
        <GrayZone />
        <Mobile>
          <FixMenuBottom>
            <BottomTitle>다른 고객님이 함께 본 제품</BottomTitle>
            <BottomItemWrap>
              {listWith.map((recentPrdValue, index) => {
                return (
                  <ProductListThree
                    key={`withProduct-${index}`}
                    value={recentPrdValue}
                    setPop={setClickCartPop}
                  />
                );
              })}
            </BottomItemWrap>
          </FixMenuBottom>
        </Mobile>
        <FixCloseButton
          onClick={() => {
            setClickCartPop(false);
          }}
          onTouchStart={() => {
            setCloseButtonTouch(true);
          }}
          onTouchEnd={() => {
            setCloseButtonTouch(false);
          }}
          closeButtonTouch={closeButtonTouch}
        >
          닫기
        </FixCloseButton>
      </FixMenuStepWrap>
    </FixMenuWrap>
  );
}

// s :: animation
const transitionY = keyframes`
0%{
    transform : translateY(300px);
		opacity: 0.5;
}
	100% {
		opacity: 1;
	}
`;
// e :: animation
const FixMenuWrap = styled.div`
  background-color: #fff;
  transition: ease 0.3s all;
  animation: ${transitionY} 0.3s ease-in-out;
`;
const FixMenuStepWrap = styled.div``;
const FixMenuTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (25 / 428));
  }
`;
const LeftSection = styled.div`
  object-fit: cover;
  > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (80 / 428));
    height: calc(100vw * (80 / 428));
  }
`;
const RightSection = styled.div`
  text-align: right;
  font-weight: 500;
`;
const FixMenuBottom = styled.div`
  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (50 / 428));
  }
`;

const BottomTitle = styled.p`
  color: #333333;
  font-weight: 500;
  margin: 0 auto;
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (20 / 428));
    line-height: calc(100vw * (25 / 428));
  }
`;
const BottomItemWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  -webkit-flex-wrap: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 930px) {
    gap: calc(100vw * (9 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;
const TextTop = styled.p`
  @media screen and (max-width: 930px) {
    color: #333333;
    line-height: calc(100vw * (23 / 428));
    padding: calc(100vw * (12 / 428)) 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
const TextBottom = styled.button`
  color: #ff006c;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const FixCloseButton = styled.button`
  color: #333333;
  font-weight: 500;
  width: 100%;
  text-align: center;
  transition: ease 0.3s all;
  ${({ closeButtonTouch }) =>
    closeButtonTouch &&
    `
  background-color : #eeeeee;
  `}
  @media screen and (max-width: 930px) {
    height: calc(100vw * (80 / 428));
    font-size: calc(100vw * (18 / 428));
    padding: 0 calc(100vw * (36 / 428));
    border-top: #eeeeee solid 1px;
  }
`;
