import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { imgURL } from "../../../server/appInfoController";
import { Mobile, PC } from "../../../MediaQuery";

import SelectBoxArrow from "../../../img/icon/icon_selectBox_arrow.svg";
import IconChkOn from "../../../img/icon/icon_chk_on.svg";
import IconChkOff from "../../../img/icon/icon_chk_off.svg";

//page
import Pagination from "../../common/pagination/Pagination";
import ReviewDetailModal from "../../common/modal/ReviewDetailModal";
import PhotoReviewModal from "../../common/modal/PhotoReviewModal";

import ReportModal from "../../common/modal/ReportModal";
import { useSelector } from "react-redux";
import NewPagination from "../../common/pagination/NewPagination";

export default function DetailReview({
  reviewItems,
  reviewPage,
  setReviewPage,
  setReviewSort,
  photoCheck,
  setPhotoCheck,
  photoReviewItems,
}) {
  const history = useHistory();
  const { isLogged } = useSelector((state) => state.userData);
  const [clickReviewData, setClickReviewData] = useState([]);

  const [photoModal, setPhotoModal] = useState(false);
  const [photoListModal, setPhotoListModal] = useState(false);

  const [optionList, setOptionList] = useState("최신순");
  const [option, setOption] = useState(false);

  const selectData = ["최신순", "오래된순"];

  // report
  const [reportPop, setReportPop] = useState(false);
  const [reviewReportPkId, setReviewReportPkId] = useState(-1);

  const { reviewList = [], pagination, satisfactionPercent, totalReviewCount } = reviewItems;

  return (
    <Container>
      {/* 포토리뷰 상세 모달 */}
      {photoModal && (
        <ReviewDetailModal setPhotoModal={setPhotoModal} reviewData={clickReviewData} />
      )}
      {reportPop && <ReportModal setClosePop={setReportPop} pkId={reviewReportPkId} />}

      {/* 포토리뷰 모달 */}
      {photoListModal && <PhotoReviewModal setPhotoListModal={setPhotoListModal} />}

      {photoReviewItems.reviewList.length > 0 ? (
        <DetailReviewWrap>
          <TitleBox>
            <ReviewCount>리뷰({totalReviewCount || 0})</ReviewCount>
            <TitleSub>{Math.ceil(satisfactionPercent || 0)}%가 만족한 상품이에요</TitleSub>
          </TitleBox>
          <Mobile>
            <PhotoReviewBox>
              {photoReviewItems.reviewList.map((photoReviewV, index) => {
                return (
                  <>
                    {index !== 3 ? (
                      <PhotoReviewList
                        onClick={() => {
                          setPhotoModal(true);
                          setClickReviewData([photoReviewItems.reviewList[index]]);
                        }}
                      >
                        <PhotoReviewImg>
                          <img src={imgURL + photoReviewV.rev_image} alt="" />
                        </PhotoReviewImg>
                      </PhotoReviewList>
                    ) : photoReviewItems.totalReviewCount - 4 === 0 ? (
                      <PhotoReviewList
                        onClick={() => {
                          setPhotoModal(true);
                          setClickReviewData([photoReviewItems.reviewList[index]]);
                        }}
                      >
                        <PhotoReviewImg>
                          <img src={`${imgURL}${photoReviewV.rev_image}`} alt="photoReview-item" />
                        </PhotoReviewImg>
                      </PhotoReviewList>
                    ) : (
                      <PhotoReviewList
                        onClick={() => {
                          history.push(
                            `/store/review/photo?prdId=${photoReviewItems.reviewList[0].prd_id}`
                          );
                        }}
                      >
                        <PhotoReviewImg>
                          <img src={`${imgURL}${photoReviewV.rev_image}`} alt="photoReview-item" />
                        </PhotoReviewImg>
                        <PhotoReviewMoreText>
                          +{" "}
                          {photoReviewItems.totalReviewCount > 1000
                            ? "999"
                            : photoReviewItems.totalReviewCount - 4}
                        </PhotoReviewMoreText>
                      </PhotoReviewList>
                    )}
                  </>
                );
              })}
            </PhotoReviewBox>
          </Mobile>
          <PC>
            <PhotoReviewBox>
              {photoReviewItems.reviewList.map((photoReviewV, index) => {
                return (
                  <>
                    {index !== 9 ? (
                      <PhotoReviewList
                        onClick={() => {
                          setPhotoModal(true);
                          setClickReviewData([photoReviewItems.reviewList[index]]);
                        }}
                      >
                        <PhotoReviewImg>
                          <img src={`${imgURL}${photoReviewV.rev_image}`} alt="photoReview-item" />
                        </PhotoReviewImg>
                      </PhotoReviewList>
                    ) : photoReviewItems.totalReviewCount - 10 === 0 ? (
                      <PhotoReviewList
                        onClick={() => {
                          setPhotoModal(true);
                          setClickReviewData([photoReviewItems.reviewList[index]]);
                        }}
                      >
                        <PhotoReviewImg>
                          <img src={imgURL + photoReviewV.rev_image} alt="" />
                        </PhotoReviewImg>
                      </PhotoReviewList>
                    ) : (
                      <PhotoReviewList
                        onClick={() => {
                          setPhotoListModal(true);
                        }}
                      >
                        <PhotoReviewImg>
                          <img src={imgURL + photoReviewV.rev_image} alt="" />
                        </PhotoReviewImg>
                        <PhotoReviewMoreText>
                          +{" "}
                          {photoReviewItems.totalReviewCount > 1000
                            ? "999"
                            : photoReviewItems.totalReviewCount - 10}
                        </PhotoReviewMoreText>
                      </PhotoReviewList>
                    )}
                  </>
                );
              })}
            </PhotoReviewBox>
          </PC>

          <ReviewTopCateBox>
            <ReviewTopCateRight>
              <ReviewTopCateRightCheck
                id={0}
                type="checkbox"
                name="checkbox"
                checked={photoCheck}
              ></ReviewTopCateRightCheck>
              <ReviewTopCateRightCheckLabel
                checked={photoCheck}
                htmlFor={0}
                onClick={() => {
                  setPhotoCheck(!photoCheck);
                }}
              >
                <ReviewChkImg className="chk-img" />
                <ReviewTopCateRightText>포토리뷰 모아보기</ReviewTopCateRightText>
              </ReviewTopCateRightCheckLabel>
            </ReviewTopCateRight>
            <DetailSelectBox>
              <SelectBoxWrap
                tabIndex="0"
                onBlur={() => {
                  setOption(false);
                }}
              >
                <SelectedButton
                  onClick={() => {
                    setOption(!option);
                  }}
                >
                  <SelectedButtonText active={option && true}>{optionList}</SelectedButtonText>
                </SelectedButton>
                <SelectedArrowImg
                  onClick={() => {
                    setOption(!option);
                  }}
                  active={option && true}
                  src={SelectBoxArrow}
                  alt="selectArrow"
                />
                {option ? (
                  <SelectListBox>
                    {selectData.map((optionData, index) => {
                      return (
                        <SelectBtn
                          key={optionData}
                          onClick={() => {
                            setOption(false);
                            setOptionList(optionData);
                            setReviewSort(index);
                          }}
                        >
                          {optionData}
                        </SelectBtn>
                      );
                    })}
                  </SelectListBox>
                ) : null}
              </SelectBoxWrap>
            </DetailSelectBox>
          </ReviewTopCateBox>
          <ReviewListWrap>
            {reviewList.length > 0
              ? reviewList.map((reviewV, index) => {
                  return (
                    <>
                      <ReviewList key={index + "review list"}>
                        <ReviewTitleBox>
                          <ReviewPrdTitleBox>
                            <ReviewPrdTitle>
                              {reviewV?.satisfaction === 0 ? "만족해요" : "별로예요"}
                            </ReviewPrdTitle>
                          </ReviewPrdTitleBox>
                          <ReviewDateWrap>
                            <ReviewDateInner>
                              <ReviewWriter>{reviewV.mem_email.slice(0, 3) + "****"}</ReviewWriter>
                              <ReviewDate>
                                {reviewV?.rev_date.split("T")[0].replaceAll("-", ".")}
                              </ReviewDate>
                              {reviewV?.rev_fixed === 1 && (
                                <ReviewModifyText>수정됨</ReviewModifyText>
                              )}
                            </ReviewDateInner>

                            <ReviewReportButton
                              type="button"
                              onClick={() => {
                                if (isLogged) {
                                  setReviewReportPkId(reviewV?.rev_id);
                                  setReportPop(true);
                                } else {
                                  alert("로그인 후 이용가능합니다.");
                                }
                              }}
                            >
                              신고하기
                            </ReviewReportButton>
                          </ReviewDateWrap>
                        </ReviewTitleBox>
                        <ReviewContentWrap
                          onClick={() => {
                            setClickReviewData([reviewList[index]]);
                            setPhotoModal(true);
                          }}
                        >
                          {reviewV?.rev_image ? (
                            <ReviewImgWrap>
                              <ReviewImg src={imgURL + reviewV.rev_image} alt="review image" />
                            </ReviewImgWrap>
                          ) : null}
                          <ReviewTextWrap img={reviewV?.rev_image}>
                            <ReviewText>{reviewV?.rev_content}</ReviewText>
                          </ReviewTextWrap>
                        </ReviewContentWrap>
                      </ReviewList>
                    </>
                  );
                })
              : null}
            {reviewList.length < totalReviewCount && (
              <NewPagination
                pageData={pagination}
                page={reviewPage}
                setPage={setReviewPage}
                noScroll
              />
            )}
          </ReviewListWrap>
        </DetailReviewWrap>
      ) : (
        <ReviewInfoText>등록된 리뷰가 없습니다.</ReviewInfoText>
      )}
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  padding: 20px 0 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0 0;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (60 / 428));
    margin: 0 auto;
  }
`;
const DetailReviewWrap = styled.div`
  width: 100%;
`;
const TitleBox = styled.div`
  padding: 20px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: 0 0 calc(100vw * (20 / 428));
  }
`;
const ReviewCount = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #333333;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const TitleSub = styled.p`
  color: #333333;
  font-weight: 600;
  font-size: 24px;
  margin-top: 5px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
    margin-top: calc(100vw * (5 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (17 / 428));
    margin-top: calc(100vw * (5 / 428));
  }
`;

const PhotoReviewBox = styled.ul`
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 20px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (15 / 428));
  }
`;
const PhotoReviewList = styled.li`
  position: relative;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-right: calc(100vw * (6 / 428));
    }
  }
`;
const PhotoReviewImg = styled.div`
  width: 115px;
  height: 115px;
  background-color: #fff;
  border: 1px solid #eee;
  overflow: hidden;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (115 / 1320));
    height: calc(100vw * (115 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (90 / 428));
    height: calc(100vw * (90 / 428));
  }
`;
const PhotoReviewMoreText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.6);

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;

const ReviewTopCateBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (13 / 428)) calc(100vw * (10 / 428));
  }
`;
const ReviewTopCateRight = styled.div`
  display: flex;
  align-items: center;
`;
const ReviewTopCateRightCheck = styled.input`
  display: none;

  &:checked + label .chk-img {
    background: url(${IconChkOn}) no-repeat center / contain;
  }
`;
const ReviewTopCateRightCheckLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const ReviewChkImg = styled.div`
  width: 24px;
  height: 24px;
  background: url(${IconChkOff}) no-repeat center / contain;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const ReviewTopCateRightText = styled.div`
  color: #333;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (10 / 428));
  }
`;

// 순별 select
const DetailSelectBox = styled.div``;
const SelectBoxWrap = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 100%;
`;
const SelectedButton = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 50px;
  padding: 0 10px;
  margin-right: 42px;
  cursor: pointer;
  transition: ease 0.3s all;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    padding: 0 calc(100vw * (10 / 1320));
    margin-right: calc(100vw * (42 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (40 / 428));
    padding: 0 calc(100vw * (15 / 428));
    margin-right: calc(100vw * (32 / 428));
  }
`;

const SelectedButtonText = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333333;
  transition: ease 0.3s all;

  ${({ active }) => {
    return (
      active &&
      `
        color: #000;
    `
    );
  }}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 900px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

const SelectListBox = styled.ul`
  position: absolute;
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dddddd;

  @media screen and (max-width: 900px) {
    top: calc(100% + 100vw * (8 / 428));
    max-height: calc(100vw * (304 / 428));
  }
`;
const SelectBtn = styled.li`
  width: 100%;
  color: #888;
  font-size: 16px;
  text-align: center;
  padding: 8px 0;
  cursor: pointer;

  &:hover {
    background: #f7f7f7;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (8 / 1320)) 0;
  }

  @media screen and (max-width: 900px) {
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (8 / 428)) 0;
  }
`;
const SelectedArrowImg = styled.img`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  transition: ease 0.3s all;
  ${({ active }) => {
    return (
      active &&
      `
        transform : translateY(-50%) rotate(180deg);
    `
    );
  }}

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (10 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 900px) {
    right: calc(100vw * (10 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;

//ReviewList
const ReviewListWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 10px 0 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (10 / 1320)) 0 0;
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const ReviewList = styled.div`
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #eeeeee;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const ReviewTitleBox = styled.div`
  width: 100%;
  padding-bottom: 5px;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (5 / 428));
  }
`;

const ReviewDateWrap = styled.div`
  position: relative;
`;
const ReviewDateInner = styled.div`
  display: flex;
  width: 100%;
  color: #777777;
  font-size: 15px;
  & span:not(:last-child) {
    padding-right: 12px;
    margin-right: 12px;
    border-right: 1px solid #ddd;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    & span:not(:last-child) {
      padding-right: calc(100vw * (12 / 1320));
      margin-right: calc(100vw * (12 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    & span:not(:last-child) {
      padding-right: calc(100vw * (12 / 428));
      margin-right: calc(100vw * (12 / 428));
    }
  }
`;
const ReviewWriter = styled.span`
  color: #777777;
`;
const ReviewStore = styled.p`
  font-size: 14px;
  margin-bottom: 4px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (4 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (4 / 428));
  }
`;
const ReviewDate = styled(ReviewWriter)``;
const ReviewReportButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  color: #6666;
  font-weight: 600;
  font-size: 15px;
  padding: 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (10 / 428)) 0;
  }
`;
const ReviewModifyText = styled(ReviewWriter)``;
const ReviewPrdTitleBox = styled.div`
  width: 100%;
  padding-bottom: 10px;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (10 / 428));
  }
`;
const ReviewPrdTitle = styled.p`
  color: #333333;
  font-weight: 600;
  font-size: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (17 / 428));
  }
`;
const ReviewContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (5 / 428));
  }
`;
const ReviewImgWrap = styled.div`
  width: 90px;
  height: 90px;
  overflow: hidden;
  border: 1px solid #eee;
  background-color: #fff;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (90 / 1320));
    height: calc(100vw * (90 / 1320));
    border-radius: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (90 / 428));
    height: calc(100vw * (90 / 428));
    border-radius: calc(100vw * (5 / 428));
  }
`;
const ReviewImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ReviewTextWrap = styled.div`
  width: ${(props) => (props.img ? "calc(100% - 100px)" : "100%")};

  @media screen and (max-width: 1320px) {
    width: ${(props) => (props.img ? "calc(100% - calc(100vw * (100 / 1320)))" : "100%")};
  }

  @media screen and (max-width: 930px) {
    width: ${(props) => (props.img ? "calc(100vw * (278 / 428))" : "100%")};
  }
`;
const ReviewText = styled.p`
  width: 100%;
  font-size: 16px;

  color: #333;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ReviewInfoText = styled.p`
  color: #999999;
  font-size: 16px;
  text-align: center;
  padding: 180px 0;
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (180 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (150 / 428)) 0;
  }
`;
