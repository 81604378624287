import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import serverController from "store_src/server/serverController";
import styled from "styled-components";
import PlanListWrap from "store_src/component/plan/PlanListWrap";
import { Mobile, PC } from "../../MediaQuery";
import HtmlParser from "react-html-parser";
import axios from "axios";
import { ip } from "store_src/server/serverController";
import Header from "store_src/component/common/Header";
import FixMenu from "store_src/component/common/FixMenu";
import { Get } from "api/index";
import { queryKeys } from "constants/queryKeys";
import useRequestQuery from "hooks/useRequestQuery";
import Moment from "react-moment";

export default function StorePlanShopContainer({ planData }) {
  return (
    <>
      <Header back />
      <FixMenu />
      {planData && (
        <Container>
          <PlanDescWrap>
            <PlanTitle>{planData.event.eventTitle}</PlanTitle>
            <PlanDate>
              <Moment format="YYYY.MM.DD">{planData.event.eventStartDatetime}</Moment>
              {"~"}
              <Moment format="YYYY.MM.DD">{planData.event.eventEndDatetime}</Moment>
            </PlanDate>
          </PlanDescWrap>
          <PlanMainImgWrap>
            <PC>{planData && HtmlParser(planData.event.eventContent)}</PC>
            <Mobile>{planData && HtmlParser(planData.event.eventContent)}</Mobile>
            {planData.event.eventId === 16 && (
              <ClickAreaWrap>
                <a className="ios_app_btn" href="https://apps.apple.com/us/app/wala-%EC%99%88%EB%9D%BC/id6443930204">
                  ios 앱 다운로드 버튼
                </a>
                <a
                  className="android_app_btn"
                  href="https://play.google.com/store/apps/details?id=com.walaland&amp;hl=ko&amp;gl=US"
                >
                  안드로이드 앱 다운로드 버튼
                </a>
              </ClickAreaWrap>
            )}
          </PlanMainImgWrap>
        </Container>
      )}
    </>
  );
}

const Container = styled.section`
  position: relative;
  width: 1240px;
  margin: 0 auto;
  padding-top: 180px;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    padding-top: calc(100vw * (180 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    padding-top: 0;
    font-size: calc(100vw * (16 / 428));
  }
`;

const PlanDescWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 0 20px;

  @media screen and (max-width: 1320px) {
    padding: 0 0 calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0 calc(100vw * (15 / 428)) calc(100vw * (10 / 428));
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
`;
const PlanTitle = styled.h1`
  font-size: 20px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    padding-bottom: calc(100vw * (5 / 428));
    text-align: center;
  }
`;
const PlanDate = styled.p`
  color: #999;

  & > * {
    color: #999;
  }
`;
const PlanMainImgWrap = styled.div`
  width: 1080px;
  position: relative;
  margin: 0 auto;
  min-height: 100vh;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1080 / 1320));
    min-height: calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (382 / 428));
    min-height: calc(100vw * (100 / 428));
    text-align: center;
    width: 100%;
  }
  & img {
    width: 100% !important;
    height: auto !important;
  }
`;

const ClickAreaWrap = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  & .ios_app_btn {
    font-size: 0;
    display: block;
    position: absolute;
    text-decoration: none;
    font-size: 0;
    /* border: 1px solid red; */
    inset: 87.3% 15% 9% 15%;

    cursor: pointer;
  }
  & .android_app_btn {
    font-size: 0;
    display: block;
    position: absolute;
    text-decoration: none;
    font-size: 0;
    /* border: 1px solid red; */
    inset: 93.1% 15% 3.2% 15%;
    cursor: pointer;
  }
`;
