import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";

import { postLikeProducts } from "../../../hooks/likeHooks";

// controller
import { imgURL } from "../../server/appInfoController";
import Commas from "../../server/Commas";

//icon
import IconWishOn from "../../img/icon/icon_wish_on.svg";
import IconWishOff from "../../img/icon/icon_wish_off.svg";
import MyPageDefaultModal from "../common/modal/MyPageDefaultModal";
import SkeletonUI from "newComponents/common/SkeletonUI";

export default function RankProduct({ list, isSuccess, isLoading }) {
  const history = useHistory();
  const location = useLocation();
  const [loginPop, setLoginPop] = useState(false);
  return (
    <Container>
      {loginPop && (
        <MyPageDefaultModal
          text="로그인 후 이용 가능합니다."
          trueText="로그인"
          trueFunc={() => {
            if (location) return history.push(`/store/login?returnTo=${location.pathname}`);
            history.push(`/store/login`);
          }}
          falseFunc={() => setLoginPop(false)}
        />
      )}
      <>
        <PC>
          <Title>BEST ITEM</Title>
          {
            <RankProductWrap>
              {isLoading && <SkeletonUI.HorizontalProductList />}
              {isSuccess && list && (
                <Swiper slidesPerView={6} spaceBetween={8}>
                  {list.map((rankPrd, index) => {
                    return (
                      <SwiperSlide key={rankPrd.prd_id}>
                        <RankProductItem rankPrd={rankPrd} index={index} setLoginPop={setLoginPop} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </RankProductWrap>
          }
        </PC>
        <Mobile>
          {
            <RankProductWrap>
              <Swiper slidesPerView={2.8} spaceBetween={10}>
                {isSuccess && list && (
                  <>
                    {list.map((rankPrd, index) => {
                      return (
                        <SwiperSlide key={rankPrd.prd_id}>
                          <RankProductItem rankPrd={rankPrd} index={index} setLoginPop={setLoginPop} />
                        </SwiperSlide>
                      );
                    })}
                  </>
                )}
              </Swiper>
            </RankProductWrap>
          }
        </Mobile>
      </>
    </Container>
  );
}
const RankProductItem = ({ rankPrd, index, setLoginPop }) => {
  const history = useHistory();
  const { isLogged } = useSelector((state) => state.userData);
  const [isWish, setIsWish] = useState(rankPrd.like_id !== 0);
  const [touch, setTouch] = useState(false);
  const onHandleLike = async () => {
    const res = await postLikeProducts(rankPrd.prd_id);
    setIsWish(res?.result === 1);
  };

  return (
    <SlideList>
      <div className="img-box">
        <img
          src={`${imgURL}${rankPrd.prd_thumbnail}`}
          className="product-img"
          alt="img"
          onClick={() => {
            history.push(`/store/detail/${rankPrd.prd_id}`);
          }}
        />
        <WishButton
          className="wish-button"
          onTouchStart={() => {
            setTouch(true);
          }}
          onTouchEnd={() => {
            setTouch(false);
          }}
          touch={touch}
        >
          <input
            type="checkbox"
            id={"chk-heart-" + index}
            checked={isWish}
            onChange={() => {
              if (isLogged) {
                onHandleLike();
              } else {
                setLoginPop(true);
              }
            }}
          />
          <label htmlFor={"chk-heart-" + index} />
        </WishButton>
        <span className="rank">{index + 1}</span>
      </div>
      <div
        className="text-box"
        onClick={() => {
          history.push(`/store/detail/${rankPrd.prd_id}`);
        }}
      >
        <div className="img-text-wrap">
          <p className="brand">{rankPrd.bc_title || "(BRAND)"}</p>
          <p className="title">{rankPrd.prd_name}</p>
        </div>
        {rankPrd.prd_sale_rate === 0 ? (
          <div className="price-wrap">
            <p className="price-original-trans">.</p>
            <p className="price-text-list">
              <span className="price">{Commas.numberWithCommas(rankPrd.prd_price)}원</span>
            </p>
          </div>
        ) : (
          <div className="price-wrap">
            <p className="price-original">{Commas.numberWithCommas(rankPrd.prd_price)}원</p>
            <p className="price-text-list">
              <span className="sale-rate">{rankPrd.prd_sale_rate}%</span>
              <span className="price">{Commas.numberWithCommas(rankPrd.prd_list_price)}원</span>
            </p>
          </div>
        )}
      </div>
    </SlideList>
  );
};

const Container = styled.div`
  width: 1240px;
  margin: 0 auto;
  padding: 40px 0 50px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    padding: calc(100vw * (40 / 1320)) 0 calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0 calc(100vw * (30 / 428));
  }
`;
const Title = styled.div`
  color: #111;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
    margin-bottom: calc(100vw * (15 / 1320));
  }
`;

const RankProductWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    & .swiper-container {
      padding-left: calc(100vw * (25 / 428));
    }
  }
`;

const SlideList = styled.div`
  width: 100%;
  cursor: pointer;
  margin: 10px 0;
  box-shadow: rgb(0 0 0 / 4%) 0px 4px 16px 0px;

  & .img-box {
    position: relative;
    height: 200px;
    overflow: hidden;
  }

  & .product-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .wish-button {
  }

  & .rank {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    background-color: #000000;
  }

  & .text-box {
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 131px;
    padding: 0 10px 10px;
    & .price-wrap {
      min-height: 43.5px;
    }
    p {
      &.brand {
        color: #333;
        font-weight: 800;
        font-size: 14px;
        word-break: keep-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        white-space: normal;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-top: 5px;
        margin-top: 10px;
      }
      &.title {
        color: #333333;
        font-weight: 400;
        font-size: 15px;
        word-break: keep-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 5px;
      }
      &.price-original {
        font-weight: 500;
        color: #777777;
        font-size: 14px;
        text-decoration: line-through;
        margin-bottom: 4px;
      }
      &.price-original-trans {
        color: transparent;
        margin-bottom: 4px;
      }
    }
    span {
      &.sale-rate {
        font-weight: 700;
        color: #333333;
        font-size: 14px;
        padding: 2px 4.5px;
        margin-right: 5px;
        border-radius: 3px;
        background: #fdf251;
      }
      &.price {
        font-weight: 700;
        color: #333333;
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 1320px) {
    & .img-box {
      width: 100%;
      height: calc(100vw * (200 / 1320));
    }
    & .rank {
      width: calc(100vw * (28 / 1320));
      height: calc(100vw * (28 / 1320));
      font-size: calc(100vw * (16 / 1320));
    }
    & .text-box {
      height: calc(100vw * (131 / 1320));
      padding: 0 calc(100vw * (10 / 1320)) calc(100vw * (10 / 1320));
      & .price-wrap {
        min-height: calc(100vw * (43.5 / 1320));
      }
      p {
        &.brand {
          font-size: calc(100vw * (14 / 1320));
          margin-top: calc(100vw * (10 / 1320));
        }
        &.title {
          font-size: calc(100vw * (15 / 1320));
          margin-top: calc(100vw * (5 / 1320));
        }
        &.price-original {
          font-size: calc(100vw * (14 / 1320));
          margin-bottom: calc(100vw * (4 / 1320));
        }
        &.price-original-trans {
          color: transparent;
          margin-bottom: calc(100vw * (4 / 1320));
        }
      }
      span {
        &.sale-rate {
          font-size: calc(100vw * (14 / 1320));
          padding: calc(100vw * (2 / 1320)) calc(100vw * (4.5 / 1320));
          margin-right: calc(100vw * (5 / 1320));
        }
        &.price {
          font-size: calc(100vw * (16 / 1320));
        }
      }
    }
  }

  @media screen and (max-width: 930px) {
    box-shadow: rgb(0 0 0 / 4%) 0px 2px 14px 0px;
    
    & .img-box {
      width: 100%;
      height: calc(100vw * (120 / 428));
      border: 1px solid #f8f8f8;
    }

    & .rank {
      width: calc(100vw * (28 / 428));
      height: calc(100vw * (28 / 428));
      font-size: calc(100vw * (16 / 428));
    }

    & .text-box {
      height: calc(100vw * (131 / 428));
      padding: 0 5px 5px;
      & .price-wrap {
        min-height: calc(100vw * (43.5 / 428));
      }
      p {
        &.brand {
          font-size: calc(100vw * (14 / 428));
          margin-top: calc(100vw * (10 / 428));
        }
        &.title {
          min-height: calc(100vw * (38 / 428));
          font-size: calc(100vw * (15 / 428));
          margin-top: calc(100vw * (5 / 428));
        }
        &.price-original {
          font-size: calc(100vw * (13 / 428));
          margin-bottom: calc(100vw * (5 / 428));
        }
      }
      span {
        &.sale-rate {
          font-size: calc(100vw * (12 / 428));
          padding: calc(100vw * (2 / 428)) calc(100vw * (4.5 / 428));
          margin-right: calc(100vw * (5 / 428));
        }
        &.price {
          font-size: calc(100vw * (14 / 428));
        }
      }
    }
  }
`;
const WishButton = styled.button`
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  width: 24px;
  height: 24px;
  padding: 0;
  & input {
    display: none;

    &:checked + label {
      background: url(${IconWishOn}) no-repeat center / contain;
    }
  }

  & label {
    display: block;
    width: 100%;
    height: 100%;
    background: url(${IconWishOff}) no-repeat center / contain;
    cursor: pointer;
  }
  @media screen and (max-width: 1320px) {
    right: calc(100vw * (10 / 1320));
    bottom: calc(100vw * (10 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    right: calc(100vw * (10 / 428));
    bottom: calc(100vw * (10 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
    ${({ touch }) => touch && `scale : 0.93;`}
  }
`;
