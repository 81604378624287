import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../components/common/header/Header";
import Footer from "../../components/common/footer/Footer";
import MainIntro from "../../components/main/MainIntro";
import CategoryFaces from "../../components/category/CategoryFaces";
import MainCeleb from "../../components/main/MainCeleb";
import MainDramaBanner from "../../components/main/MainDramaBanner";
import MainIssue from "../../components/main/MainIssue";
import MainIdol from "../../components/main/MainIdol";
import FooterMenu from "../../components/common/footer/FooterMenu";
import ContentDetailPage from "../ContentDetailPage";

import { rnView } from "../../server/rnView";

const Main = () => {
  const [isContentModal, setIsContentModal] = useState(false);
  const [contentId, setContentId] = useState(0);
  return (
    <Wrapper onClick={rnView}>
      <Header search={true} main={true} />
      <Inner>
        <MainDramaBanner
          isContentModal={isContentModal}
          setIsContentModal={setIsContentModal}
          contentId={contentId}
          setContentId={setContentId}
        />
      </Inner>
      <Mobile>
        <FooterMenu />
      </Mobile>

      {isContentModal && (
        <ContentDetailPage
          setIsContentModal={setIsContentModal}
          setContentId={setContentId}
          contentId={contentId}
        />
      )}
    </Wrapper>
  );
};

export default Main;

const Wrapper = styled.div`
  width: 100%;
`;
const Inner = styled.div`
  width: 100%;
  height: calc(100vh - calc(100vw * (64 / 1920)));

  @media screen and (max-width: 1024px) {
    height: 100vh;
    padding-top: calc(100vw * (50 / 428));
  }
`;
