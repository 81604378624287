import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import { useSelector } from "react-redux";
import serverController from "../../server/serverController";
//css
import styled from "styled-components";

// img
import IconCheckbox from "../../img/icon/icon_checkbox.png";
import IconArrow from "../../img/icon/icon_select_arrow.png";

export default function InquiryWrite({ title, rank, isOne }) {
  const history = useHistory();
  const [inqTitle, setInqTitle] = useState("");
  const [inqText, setInqText] = useState("");
  const [inqType, setInqType] = useState(0);
  const [prdId, setPrdId] = useState(-1);
  const { prd_id } = useParams();

  useEffect(() => {
    if (prd_id) {
      setPrdId(prd_id);
    }
  }, [prd_id]);

  const checkContents = () => {
    if (inqType === 0) {
      alert("문의 분류를 선택해주세요");
      return true;
    } else if (!inqTitle) {
      alert("제목을 입력해주세요");
      return true;
    } else if (!inqText) {
      alert("내용을 입력해주세요");
      return true;
    }
    return false;
  };

  const postInquiry = async () => {
    if (checkContents()) {
      return;
    }
    if (prdId !== -1) {
      const formData = new FormData();
      formData.append("br_id", 1); // brand pk
      formData.append("prd_id", prdId); // product pk
      formData.append("inq_type", inqType); // 문의 타입
      formData.append("inq_title", inqTitle); // 문의 제목
      formData.append("inq_content", inqText); // 문의 내용
      formData.append("inq_secret", 0); // 비밀 문의 여부
      formData.append("inq_password", 0); // 비밀 문의 비밀번호
      formData.append("inq_answer_mem_id", 0); // 답변자 유저 pk

      serverController.connectFetchController(
        `inquiry/product/${prdId}`,
        "POST",
        formData,
        (res) => {
          if (res?.result == 1) {
            console.log(res, "문의 등록 res");
            history.push(`/store/mypage/inquiry/complete`);
          }
        },
        (err) => console.error(err)
      );
    } else {
      serverController.connectFetchController(
        `inquiry/one-on-one?inq_type=${inqType}&br_id=${1}&prd_id=-1&inq_title=${inqTitle}&inq_content=${inqText}&inq_secret=${0}`,
        "POST",
        null,
        (res) => {
          if (res?.result == 1) {
            console.log(res, "문의 등록 res");
            history.push(`/store/mypage/inquiry/complete`);
          }
        },
        (err) => console.error(err)
      );
    }
  };

  const TextOnChange = (value) => {
    if (inqText.length > 300) {
      let i = value.substring(0, 300);
      setInqText(i);
      alert("문의내용은 300자를 초과할 수 없습니다.");
    } else {
      setInqText(value);
    }
  };

  return (
    <Container>
      <InquiryWriteWrap>
        <TitleWrap>
          <Title>{title}</Title>
        </TitleWrap>

        <WriteWrap>
          <WriteBox>
            <WriteTitleWrap>
              <WriteTitle>제목</WriteTitle>
            </WriteTitleWrap>
            <WriteInputWrap>
              <WriteInput
                type="text"
                value={inqTitle}
                onChange={(e) => setInqTitle(e.target.value)}
                placeholder="제목을 입력해주세요."
              />
            </WriteInputWrap>
          </WriteBox>
          <WriteBox>
            <WriteTitleWrap>
              <WriteTitle>분류</WriteTitle>
            </WriteTitleWrap>
            <WriteSelectWrap>
              <WriteSelect
                name=""
                id=""
                onChange={(e) => setInqType(e.target.value)}
              >
                <WriteOption value="">선택해 주세요.</WriteOption>
                <WriteOption value={1}>신상품 요청</WriteOption>
                <WriteOption value={2}>상품정보 요청</WriteOption>
                <WriteOption value={3}>상품품절 입고</WriteOption>
                {/* <WriteOption value={4}>스타일리스트 문의</WriteOption> */}
                <WriteOption value={5}>홈페이지 문의</WriteOption>
                <WriteOption value={6}>1대1 문의</WriteOption>
              </WriteSelect>
            </WriteSelectWrap>
          </WriteBox>
          <WriteBox>
            <WriteTitleWrap>
              <WriteTitle>내용</WriteTitle>
              {/* <CheckboxWrap>
                            <Checks>
                                <ChkBox id={1} type="checkbox"  name="checkbox" checked={secret} />
                                <ChkBoxLabel htmlFor={1} onClick={() => setSecret(prev => !prev)}></ChkBoxLabel>
                            </Checks>
                            <CheckboxText>비밀글로 등록</CheckboxText>
                        </CheckboxWrap> */}
            </WriteTitleWrap>
            <WriteTextareaWrap>
              <WriteTextarea
                name=""
                id="write_id"
                placeholder="내용을 입력해주세요."
                value={inqText}
                onChange={(e) => TextOnChange(e.target.value)}
                onBlur={(e) => TextOnChange(e.target.value)}
              />
              <GideText>
                <span>{inqText.length}</span>/300
              </GideText>
            </WriteTextareaWrap>
          </WriteBox>
        </WriteWrap>
        <WriteBottomWrap>
          <BottomInner>
            {/* <FileWrap>
                        <FileText>첨부파일 <span>{file && file?.name}</span></FileText>
                        <FileBtnWrap>
                            <FileBtn type="file" id="filepic" onChange={imagesChange} accept=".gif, .jpg, .png"/>
                            <FileBtnLabel active={file} for="filepic">파일 선택</FileBtnLabel>                            
                        </FileBtnWrap>
                    </FileWrap> */}
            {/* <WriteBox>
                        <WriteTitleWrap><WriteTitle>비밀번호</WriteTitle></WriteTitleWrap>
                        <WriteInputWrap>
                            <WriteInput type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </WriteInputWrap>
                    </WriteBox> */}
          </BottomInner>
          <BtnWrap>
            {/* <Link to={`/store/service/complete`}> */}
            <Btn onClick={postInquiry}>저장하기</Btn>
            {/* </Link> */}
          </BtnWrap>
        </WriteBottomWrap>
      </InquiryWriteWrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const InquiryWriteWrap = styled.div`
  width: 1300px;
  margin: 0 auto;
  padding: 0 100px 200px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    padding: 0 calc(100vw * (100 / 1320)) calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    padding: 0 calc(100vw * (10 / 428)) calc(100vw * (100 / 428));
  }
`;
const TitleWrap = styled.div`
  width: 100%;
  padding-top: 80px;
  margin-bottom: 40px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (80 / 1320));
    margin-bottom: calc(100vw * (40 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (80 / 428));
    margin-bottom: calc(100vw * (30 / 428));
  }
`;
const Title = styled.h2`
  font-size: 30px;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (25 / 428));
  }
`;

// form
const WriteWrap = styled.div`
  width: 100%;
  border-top: 2px solid #20232a;
  padding: 40px 100px 0;
  margin-bottom: 40px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (40 / 1320)) calc(100vw * (100 / 1320)) 0;
    margin-bottom: calc(100vw * (40 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (40 / 428)) 0 0;
    margin-bottom: calc(100vw * (40 / 428));
  }
`;
const WriteBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 14px 0;
  border-bottom: 1px solid #222222;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (14 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (10 / 428)) 0;
  }
`;
const WriteTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (90 / 428));
  }
`;
const WriteTitle = styled.p`
  font-size: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const WriteInputWrap = styled.div`
  width: calc(100% - 100px);

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (100 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (90 / 428)));
  }
`;
const WriteInput = styled.input`
  width: 100%;
  height: 46px;
  font-size: 16px;
  &::placeholder {
    color: #bbbbbb;
    font-size: 16px;
  }

  @media screen and (max-width: 1320px) {
    &::placeholder {
      color: #bbbbbb;
      font-size: calc(100vw * (16 / 1320));
    }
    height: calc(100vw * (46 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    &::placeholder {
      color: #bbbbbb;
      font-size: calc(100vw * (14 / 428));
    }
    height: calc(100vw * (46 / 428));
    font-size: calc(100vw * (14 / 428));
  }
`;
const WriteSelectWrap = styled.div`
  width: calc(100% - 100px);

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (100 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (90 / 428)));
  }
`;
const WriteSelect = styled.select`
  width: 100%;
  height: 46px;
  font-size: 16px;
  border: 1px solid #999999;
  padding: 0 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(${IconArrow}) no-repeat center right 15px/10px;
  border-radius: 0;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (46 / 1320));
    font-size: calc(100vw * (16 / 1320));
    text-align: left;
    padding: 0 calc(100vw * (10 / 1320));
    background: url(${IconArrow}) no-repeat center right
      calc(100vw * (15 / 1320)) / calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (46 / 428));
    font-size: calc(100vw * (14 / 428));
    text-align: left;
    padding: 0 calc(100vw * (10 / 428));
    background: url(${IconArrow}) no-repeat center right
      calc(100vw * (15 / 428)) / calc(100vw * (10 / 428));
  }
`;
const WriteOption = styled.option`
  font-size: 16px;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const WriteTextareaWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% - 100px);

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (100 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (90 / 428)));
  }
`;
const WriteTextarea = styled.textarea`
  width: 100%;
  height: 200px;
  margin: 20px 0;
  font-size: 16px;
  padding: 10px 0;
  border: none;
  &::placeholder {
    color: #bbbbbb;
    font-size: 16px;
  }

  @media screen and (max-width: 1320px) {
    &::placeholder {
      font-size: calc(100vw * (16 / 1320));
    }
    height: calc(100vw * (200 / 1320));
    margin: calc(100vw * (20 / 1320)) 0;
    padding: calc(100vw * (10 / 1320)) 0;
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    &::placeholder {
      font-size: calc(100vw * (15 / 428));
    }
    height: calc(100vw * (150 / 428));
    margin: 0;
    padding: calc(100vw * (15 / 428)) 0;
    font-size: calc(100vw * (14 / 428));
  }
`;
const GideText = styled.p`
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    bottom: calc(100vw * (20 / 1320));
    right: calc(100vw * (20 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    bottom: calc(100vw * (20 / 428));
    right: calc(100vw * (20 / 428));
    font-size: calc(100vw * (14 / 428));
  }
`;

//button
const WriteBottomWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 1320px) {
    display: block;
    margin-top: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    display: block;
    margin-top: calc(100vw * (20 / 428));
  }
`;
const BottomInner = styled.div``;
const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const Btn = styled.p`
  width: 140px;
  font-size: 16px;
  padding: 10px 0;
  text-align: center;
  background: #000;
  color: #fff;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (10 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (15 / 428)) 0;
  }
`;
//file
const FileWrap = styled.div`
  width: 100%;
  margin-bottom: 30px;

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (30 / 428));
  }
`;
const FileText = styled.p`
  font-size: 16px;
  & > span {
    display: inline-block;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 15px;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const FileBtnWrap = styled.div`
  width: 100%;
  height: 32px;
  margin-top: 25px;

  @media screen and (max-width: 930px) {
    height: calc(100vw * (32 / 428));
    margin-top: calc(100vw * (25 / 428));
  }
`;
const FileBtn = styled.input`
  display: none;
`;
const FileBtnLabel = styled.label`
  display: block;
  width: 100px;
  font-size: 14px;
  border-radius: 10px;
  padding: 8px 0;
  text-align: center;
  background: ${({ active }) => (active ? "#A0A0A0" : "#0c0c0c")};
  color: #fff;
  cursor: pointer;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    font-size: calc(100vw * (14 / 428));
    border-radius: calc(100vw * (10 / 428));
    padding: calc(100vw * (8 / 428)) 0;
  }
`;

//checkbox
const Checks = styled.div`
  height: 24px;

  @media screen and (max-width: 930px) {
    height: calc(100vw * (18 / 428));
  }
`;
const ChkBox = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  & + label::after {
    display: block;
    content: "";
    position: absolute;
    top: -15px;
    left: 0;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid #000;
    border-radius: 100%;
  }

  :checked + label::after {
    display: none;
    content: "";
    position: absolute;
    top: -15px;
    left: 0;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid #000;
    border-radius: 100%;
  }

  @media screen and (max-width: 930px) {
    & + label::after {
      top: calc(100vw * (-15 / 428));
      width: calc(100vw * (18 / 428));
      height: calc(100vw * (18 / 428));
      border: calc(100vw * (2 / 428)) solid #000;
    }
    &:checked + label::after {
      top: calc(100vw * (-15 / 428));
      width: calc(100vw * (18 / 428));
      height: calc(100vw * (18 / 428));
      border: calc(100vw * (2 / 428)) solid #000;
    }
  }
`;
const ChkBoxLabel = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  &&::before {
    content: "";
    position: absolute;
    top: -15px;
    left: 0;
    width: 20px;
    height: 20px;
    background: #000;
    border: 2px solid #000;
    border-radius: 100%;
    background: #000 url(${IconCheckbox}) no-repeat center/14px;
  }

  @media screen and (max-width: 930px) {
    &&::before {
      top: calc(100vw * (-15 / 428));
      width: calc(100vw * (18 / 428));
      height: calc(100vw * (18 / 428));
      border: calc(100vw * (2 / 428)) solid #000;
      background: #000 url(${IconCheckbox}) no-repeat
        center/calc(100vw * (14/428));
    }
  }
`;
