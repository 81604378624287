import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import FixedTop from "../../component/common/FixedTop";
import MypageMenuList from "../../component/mypage/MypageMenuList";
import MypageReview from "../../component/mypage/inquiry/MypageReview";
import Footer from "../../component/common/StoreFooter";
import FooterMenu from "../../component/common/FooterMenu";
import FixMenu from "../../component/common/FixMenu";
import ReviewForm from "../../component/mypage/order/ReviewForm";

import MyPageHeader from "./MyPageHeader";
import ReviewEditForm from "../../component/mypage/order/ReviewEditForm";
import MyPageDefaultModal from "../../component/common/modal/MyPageDefaultModal";
import { useHistory } from "react-router-dom";

export default function WriteReviewPage() {
  const history = useHistory();
  const goBackFunc = () => {
    history.goBack();
  };

  return (
    <>
      <PC>
        <Header />
        <MypageMenuList />
        <ReviewForm />
        <Footer />
        <FixedTop />
      </PC>

      <Mobile>
        {/* 리뷰작성 취소 모달 */}
        {/* <MyPageDefaultModal text={<>작성하고 있던 내용이 저장되지 않습니다.<br/>리뷰 작성을 취소하시겠습니까?</>}/> */}

        {/* <Header /> */}
        {/* <MypageMenuList menu={true} /> */}
        <MyPageHeader title={"리뷰"} deleteBtn deleteFunc={goBackFunc} />
        {/* <ReviewEditForm /> */}
        <ReviewForm />
        {/* <Footer /> */}
        {/* <FooterMenu /> */}
        {/* <FixMenu /> */}
      </Mobile>
    </>
  );
}
