import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PC, Mobile } from "../../components/common/mediaStlye";
import styled from "styled-components";

import Header from "../../components/common/header/Header";
import MyPageHeader from "../../components/common/header/MyPageHeader";
import MyPageContents from "../../components/auth/mypage/MyPageContents";
import Footer from "../../components/common/footer/Footer";
import FooterMenu from "../../components/common/footer/FooterMenu";

const MyInfoContentsPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const history = useHistory();

  useEffect(() => {
    window.addEventListener("resize", function (event) {
      if (window.innerWidth > 1024) {
        console.log("마이페이지로 이동!!");
        history.push("/mypage");
      }
    });
  }, []);

  return (
    <Container>
      <MyPageHeader title="보관콘텐츠/아이템" activeIndex={activeIndex} />
      <MyPageContents
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
    </Container>
  );
};

const Container = styled.div`
  @media screen and (max-width: 1024px) {
    padding-bottom: calc(100vw * (68 / 428));
  }
`;

export default MyInfoContentsPage;
