//react
import React ,{ useState,useEffect,useRef } from 'react';
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery"

//css
import styled from "styled-components"

//img
import NewitemsText from "../../img/newitems_text.png"
import HotImg1 from "../../../images/content/content-detail-prod1.png"
import HotImg2 from "../../../images/content/content-detail-prod2.png"
import HotImg3 from "../../../images/content/content-detail-prod3.png"
import HotImg4 from "../../../images/category/category-prod-2.png"


//page
import Commas from "../../server/Commas"
import Pagination from "../common/pagination/Pagination"
import ProductList from "../common/product/ProductList"

export default function NewProduct() {

    const productList = [
        {
            src: HotImg1,
            title: "디디에두보",
            text: "프린 바이 손튼 브레가찌, 히든세일",
            prd_sale_rate: "29",
            prd_price: "105000",
            prd_list_price: "75000"
        },
        {
            src: HotImg2,
            title: "디디에두보",
            text: "봄과 여름 사이 시선집중 신상!",
            prd_sale_rate: "29",
            prd_price: "105000",
            prd_list_price: "75000"
        },
        {
            src: HotImg3,
            title: "디디에두보",
            text: "카린 카울넥 시퀸 드레스",
            prd_sale_rate: "29",
            prd_price: "105000",
            prd_list_price: "75000"
        },
        {
            src: HotImg4,
            title: "디디에두보",
            text: "스와로브스키 맛집! 바로 여기",
            prd_sale_rate: "29",
            prd_price: "105000",
            prd_list_price: "75000"
        },
        {
            src: HotImg1,
            title: "디디에두보",
            text: "프린 바이 손튼 브레가찌, 히든세일",
            prd_sale_rate: "29",
            prd_price: "105000",
            prd_list_price: "75000"
        },
        {
            src: HotImg2,
            title: "디디에두보",
            text: "봄과 여름 사이 시선집중 신상!",
            prd_sale_rate: "29",
            prd_price: "105000",
            prd_list_price: "75000"
        },
        {
            src: HotImg3,
            title: "디디에두보",
            text: "카린 카울넥 시퀸 드레스",
            prd_sale_rate: "29",
            prd_price: "105000",
            prd_list_price: "75000"
        },
        {
            src: HotImg4,
            title: "디디에두보",
            text: "스와로브스키 맛집! 바로 여기",
            prd_sale_rate: "29",
            prd_price: "105000",
            prd_list_price: "75000"
        },
        {
            src: HotImg3,
            title: "디디에두보",
            text: "카린 카울넥 시퀸 드레스",
            prd_sale_rate: "29",
            prd_price: "105000",
            prd_list_price: "75000"
        },
        {
            src: HotImg4,
            title: "디디에두보",
            text: "스와로브스키 맛집! 바로 여기",
            prd_sale_rate: "29",
            prd_price: "105000",
            prd_list_price: "75000"
        }
    ]

  return (
    <Container>
        <BestItemsWrap>
            <BestItemsListWrap>
                {
                    productList.map((value,index)=>{
                        if(index > 5){return}
                        return (
                            <ProductList key={index} value={value} height={"allProduct"} rank={true}/>
                        )
                    })
                }
                <ItemsImgWrap><ItemsImg src={NewitemsText}/></ItemsImgWrap>
            </BestItemsListWrap>
            
            <BestItemsListWrap>
                {
                    productList.map((value,index)=>{
                        if(index < 6){return}
                        if(index > 9){return}
                        return (
                            <ProductList key={index} value={value} height={"allProduct"} rank={true}/>
                        )
                    })
                }
            </BestItemsListWrap>

            {/* Pagination */}
            <Pagination/>
        </BestItemsWrap>
    </Container>
  );
}
const Container = styled.div`
    width: 100%;
    padding-top: 60px;
    margin-bottom: 180px;

    @media screen and (max-width: 1320px) {
        padding-top: calc(100vw*(60/1320));
        margin-bottom: calc(100vw*(180/1320));
    }

    @media screen and (max-width: 930px) {
        padding-top: calc(100vw*(40/428));
        margin-bottom: calc(100vw*(100/428));
    }
`
const BestItemsWrap = styled.div`
    width: 1300px;
    margin: 0 auto;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(1250/1320));
    }

    @media screen and (max-width: 930px) {
        width: calc(100vw*(388/428));
    }
`
//list
const BestItemsListWrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`
const ItemsImgWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100%/2 - 80px);
    margin-bottom: 140px;

    @media screen and (max-width: 1320px) {
        width: calc(100%/2 - calc(100vw*(80/1320)));
        margin-bottom: calc(100vw*(140/1320));
    }

    @media screen and (max-width: 930px) {
        width: calc(100vw*(340/428));
        margin: 0 auto calc(100vw*(150/1320));
    }
`
const ItemsImg = styled.img`
    width: 500px;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(500/1320));
    }

    @media screen and (max-width: 930px) {
        width: calc(100vw*(340/428));
    }
`