import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../mediaStlye";

import {
  FooterContainer,
  FooterTop,
  FooterBot,
  FooterLeft,
  FooterRight,
  FooterBotWrapper,
  FtText,
  FtMenuText,
  FooterStoreButton,
} from "./Footer.elements";
import TermOfUseModal from "../pop/TermOfUseModal";
import PrivateGuideModal from "../pop/PrivateGuideModal";
import AssociateModal from "../pop/AssociateModal";

import faceBookIcon from "../../../images/footer/iconmonstr-facebook-1.svg";
import instagramIcon from "../../../images/footer/iconmonstr-instagram-1.svg";
import faceBookIconWh from "../../../images/footer/iconmonstr-facebook_wh.svg";
import instagramIconWh from "../../../images/footer/iconmonstr-instagram_wh.svg";
import IconMore from "../../../img/common/moreBtn.svg";
import IconMoreOff from "../../../img/common/moreBtn_off.svg";
import youtubeIcon from "../../../images/footer/iconmonstr-youtube-1.svg";

import Modal from "../../../server/modal";

// const footerTopData = ["회사소개","이용약관","개인정보처리방침","제휴문의","문의하기","FAQ","공지사항"];
const footerTopData = ["회사소개", "이용약관", "개인정보처리방침", "제휴문의", "문의하기"];

const footerBotData1 = [
  "상호명: 주식회사 왈라(WALA Inc.)",
  "대표자: 이성이",
  "주소: 서울특별시 강남구 언주로165길 7-3, 2층 (신사동 세경빌딩)",
];
const footerBotData2 = [
  "사업자등록번호: 271-86-01681",
  "통신판매업신고: 2022-서울강남-00109",
  "개인정보관리책임자: 노다니엘(daniel@wala-land.com)",
  "호스팅사업자: ㈜왈라",
  "대표번호: 070-8667-7016",
];
const footerMobileData = [
  "상호명: 주식회사 왈라(WALA Inc.)",
  "대표자: 이성이",
  "주소: 서울특별시 강남구 언주로165길 7-3, 2층 (신사동 세경빌딩)",
  "사업자등록번호: 271-86-01681",
  "통신판매업신고: 2022-서울강남-00109",
  "개인정보관리책임자: 노다니엘(daniel@wala-land.com)",
  "호스팅사업자: ㈜왈라",
  "대표번호: 070-8667-7016",
  "COPYRIGHTⓒ 2022 ㈜왈라 ALL RIGHTS RESERVED",
];
const footerMenuList = [
  "회사소개",
  "이용약관",
  "개인정보처리방침",
  "제휴문의",
  "문의하기",
  // "FAQ",
  // "공지사항",
];

const snsData = [
  {
    alt: "instagram",
    src: instagramIcon,
    url: "https://www.instagram.com/walaland_fashion/",
  },
  //    { alt: "facebook", src: faceBookIcon },
  //    { alt: "twitter", src: twitterIcon },
  //    { alt: "youtube", src: youtubeIcon },
];
const mbsnsData = [
  {
    alt: "instagram",
    src: instagramIconWh,
    url: "https://www.instagram.com/walaland_fashion/",
  },
  //    { alt: "facebook", src: faceBookIconWh },
];

const Footer = () => {
  const [isOpenTerm, setIsOpenTerm] = useState(false);
  const [isOpenPrivate, setIsOpenPrivate] = useState(false);
  const [isAssociate, setIsAssociate] = useState(false);
  const [mbMenu, setMbMenu] = useState(false);
  const history = useHistory();

  const handleOpenTerm = () => {
    setIsOpenTerm(true);
    Modal.modalOpen();
  };
  const handlePrivate = () => {
    setIsOpenPrivate(true);
    Modal.modalOpen();
  };
  const handleAssociate = () => {
    setIsAssociate(true);
    Modal.modalOpen();
  };

  const pageMoveClick = (url) => {
    return history.push(`${url}`);
  };

  return (
    <>
      <PC>
        <FooterContainer>
          <FooterTop>
            <ul>
              {footerTopData.map((item, i) => {
                if (i === 0) return <li key={i}><span><a href="http://w-a-l-a.com/html/main/main" target="_blank" rel="noreferrer">{item}</a></span></li>
                // if (i === 1) return <li onClick={handleOpenTerm} key={i}><span>{item}</span></li>
                // if (i === 2) return <li onClick={handlePrivate} key={i}><span>{item}</span></li>
                if (i === 1)
                  return (
                    <li onClick={handleOpenTerm} key={i}>
                      <span>{item}</span>
                    </li>
                  );
                if (i === 2)
                  return (
                    <li onClick={handlePrivate} key={i}>
                      <span>{item}</span>
                    </li>
                  );
                if (i === 3)
                  return (
                    <li onClick={handleAssociate} key={i}>
                      <span>{item}</span>
                    </li>
                  );
                if (i === 4)
                  return (
                    <li
                      onClick={() => {
                        pageMoveClick("/inquiry/write");
                      }}
                      key={i}
                    >
                      <span>{item}</span>
                    </li>
                  );
                // if (i === 4) return <li onClick={()=>{pageMoveClick('/faq')}} key={i}><span>{item}</span></li>
                // if (i === 5) return <li onClick={()=>{pageMoveClick('/notice')}} key={i}><span>{item}</span></li>
                return (
                  <li key={i}>
                    <span>{item}</span>
                  </li>
                );
              })}
            </ul>
          </FooterTop>

          <FooterBotWrapper>
            <FooterBot>
              <FooterLeft>
                <p>
                  {footerBotData1.map((item, i) => (
                    <span key={i}>{item}</span>
                  ))}
                </p>
                <p>
                  {footerBotData2.map((item, i) => (
                    <span key={i}>{item}</span>
                  ))}
                </p>
                <em>COPYRIGHTⓒ 2022 ㈜왈라 ALL RIGHTS RESERVED</em>
              </FooterLeft>

              <FooterRight>
                <ul className="sns_footer">
                  {snsData.map((item, i) => {
                    return (
                      <li key={i}>
                        <img
                          src={item.src}
                          alt={item.alt}
                          onClick={() => {
                            window.open(`${item.url}`);
                          }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </FooterRight>
            </FooterBot>
          </FooterBotWrapper>
        </FooterContainer>
      </PC>

      <Mobile>
        <FooterContainer>
          <ul className="sns_footer">
            <FooterStoreButton
              onClick={() => {
                window.location.href = "https://wala.land/store";
              }}
            ></FooterStoreButton>
            {mbsnsData.map((item, i) => {
              return (
                <li key={i} style={{ cursor: "pointer" }}>
                  {" "}
                  <img
                    src={item.src}
                    alt={item.alt}
                    onClick={() => {
                      window.open(`${item.url}`);
                    }}
                  />{" "}
                </li>
              );
            })}
          </ul>

          <FooterBotWrapper>
            <FtText>
              {footerMobileData.map((item, index) => {
                if (!mbMenu) {
                  return (
                    <>
                      {index == 0 && (
                        <p key={index}>
                          <span
                            onClick={() => {
                              setMbMenu(true);
                            }}
                          >
                            {item}
                          </span>
                          <img
                            src={IconMore}
                            alt="더보기"
                            onClick={() => {
                              setMbMenu(true);
                            }}
                          />
                        </p>
                      )}
                    </>
                  );
                } else {
                  return (
                    <p
                      key={index}
                      onClick={() => {
                        index == 0 ? setMbMenu(false) : setMbMenu(true);
                      }}
                    >
                      <span>{item}</span>
                      {index == 0 && (
                        <img
                          src={IconMoreOff}
                          alt="더보기"
                          onClick={() => {
                            setMbMenu(false);
                          }}
                        />
                      )}
                    </p>
                  );
                }
              })}
            </FtText>
            <FtMenuText>
              {footerMenuList.map((item, index) => {
                if (index === 0) return <p><span key={index}><a href="http://w-a-l-a.com/html/main/main" target="_blank" rel="noreferrer">{item}</a></span></p>
                // if (index === 1) return <p><span key={index} onClick={handleOpenTerm}>{item}</span></p>
                // if (index === 2) return <p><span key={index} onClick={handlePrivate}>{item}</span></p>
                if (index === 1)
                  return (
                    <p>
                      <span key={index} onClick={handleOpenTerm}>
                        {item}
                      </span>
                    </p>
                  );
                if (index === 2)
                  return (
                    <p>
                      <span key={index} onClick={handlePrivate}>
                        {item}
                      </span>
                    </p>
                  );
                if (index === 3)
                  return (
                    <p onClick={handleAssociate} key={index}>
                      <span>{item}</span>
                    </p>
                  );
                if (index === 4)
                  return (
                    <p
                      onClick={() => {
                        pageMoveClick("/inquiry/write");
                      }}
                      key={index}
                    >
                      <span>{item}</span>
                    </p>
                  );
                // if (index === 4) return <p onClick={()=>{pageMoveClick('/faq')}} key={index}><span>{item}</span></p>
                // if (index === 5) return <p onClick={()=>{pageMoveClick('/notice')}} key={index}><span>{item}</span></p>
                return (
                  <p>
                    <span key={index}>{item}</span>
                  </p>
                );
              })}
            </FtMenuText>
          </FooterBotWrapper>
        </FooterContainer>
      </Mobile>
      {isOpenTerm && <TermOfUseModal setIsOpenTerm={setIsOpenTerm} />}
      {isOpenPrivate && (
        <PrivateGuideModal setIsOpenPrivate={setIsOpenPrivate} />
      )}
      {isAssociate && <AssociateModal setIsAssociate={setIsAssociate} />}
    </>
  );
};

export default Footer;
