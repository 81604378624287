import React, { useState } from "react";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { postLikeProducts } from "../../../hooks/likeHooks";
import IconWishOn from "../../img/icon/icon_wish_on.svg";
import IconWishOff from "../../img/icon/icon_wish_off.svg";
import { imgURL } from "../../server/appInfoController";
import Commas from "../../server/Commas";
import iconMore from "../../img/icon/icon_more_button.svg";
import SoldOutCmp from "../product/SoldOutCmp";

export default function StoreSearchItemSection(props) {
  const { list, setLoginPop } = props;
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const history = useHistory();

  return (
    <Container>
      <ItemWrap>
        <TitleWrap>
          <TitleText>PRODUCTS</TitleText>
          {list.length === 4 && (
            <TitleMoreTextButton
              onClick={() => history.push(`/store/search?keyword=${query.keyword}&index=0&page=1`)}
            >
              <TitleMoreText>더 보기</TitleMoreText>
              <TitleMoreImg src={iconMore} alt="moreIcon" />
            </TitleMoreTextButton>
          )}
        </TitleWrap>
        <ItemListWrap>
          <ItemInner>
            {list.length > 0 ? (
              list.map((randomItem, index) => {
                return (
                  <RandomListItem
                    key={`random-item-${randomItem.prd_id}-${index}`}
                    randomItem={randomItem}
                    setLoginPop={setLoginPop}
                  />
                );
              })
            ) : (
              <InfoText>해당 상품이 없습니다.</InfoText>
            )}
          </ItemInner>
        </ItemListWrap>
      </ItemWrap>
    </Container>
  );
}

const RandomListItem = ({ randomItem, setLoginPop }) => {
  const history = useHistory();
  const { isLogged } = useSelector((state) => state.userData);
  const [isWish, setIsWish] = useState(randomItem.like_id !== 0);
  const [touch, setTouch] = useState(false);

  const onClickDetail = () => {
    history.push(`/store/detail/${randomItem.prd_id}`);
  };

  const onHandleLike = async () => {
    const res = await postLikeProducts(randomItem.prd_id);
    setIsWish(res?.result === 1);
  };
  return (
    <ItemList key={randomItem.prd_id}>
      <ItemImgBox>
        <ItemImg
          src={`${imgURL}${randomItem.prd_thumbnail}`}
          alt="hot-img"
          onClick={onClickDetail}
        />
        {randomItem?.prd_featured !== 1 && <SoldOutCmp />}
        <PC>
          <WishIcon
            src={isWish ? IconWishOn : IconWishOff}
            alt="wish"
            onClick={() => {
              if (!isLogged) return setLoginPop(true);
              onHandleLike();
            }}
          />
        </PC>
        <Mobile>
          <WishIcon
            src={isWish ? IconWishOn : IconWishOff}
            alt="wish"
            onClick={() => {
              if (!isLogged) return setLoginPop(true);
              onHandleLike();
            }}
            onTouchStart={() => {
              setTouch(true);
            }}
            onTouchEnd={() => {
              setTouch(false);
            }}
            touch={touch}
          />
        </Mobile>
      </ItemImgBox>
      <BestNewTextWrap onClick={onClickDetail}>
        <BestNewImgTextBox>
          <ItemImgTitle>{randomItem.bc_title || "(BRAND)"}</ItemImgTitle>
          <ItemImgText>{randomItem.prd_name}</ItemImgText>
        </BestNewImgTextBox>
        {randomItem?.prd_sale_rate === 0 ? (
          <PriceBox>
            <OriginalPrice original>.</OriginalPrice>
            <BestNewPriceWrap>
              <Price>{Commas.numberWithCommas(randomItem?.prd_list_price)}원</Price>
            </BestNewPriceWrap>
          </PriceBox>
        ) : (
          <PriceBox>
            <OriginalPrice>{Commas.numberWithCommas(randomItem?.prd_price)}원</OriginalPrice>
            <BestNewPriceWrap>
              <SaleRate>{Commas.numberWithCommas(randomItem?.prd_sale_rate)}%</SaleRate>
              <Price>{Commas.numberWithCommas(randomItem?.prd_list_price)}원</Price>
            </BestNewPriceWrap>
          </PriceBox>
        )}
      </BestNewTextWrap>
    </ItemList>
  );
};

const Container = styled.div`
  padding: 80px 0;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (80 / 1320)) 0;
    width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (40 / 428)) 0 calc(100vw * (20 / 428));
    width: calc(100vw * (378 / 428));
  }
`;
const ItemWrap = styled.div`
  width: 1240px;
  margin: 0 auto;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: auto;
    margin: auto;
  }
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1320px) {
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    margin-bottom: calc(100vw * (16 / 428));
  }
`;
const TitleText = styled.h2`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
  color: #222222;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const TitleMoreTextButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const TitleMoreText = styled.span`
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const TitleMoreImg = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 5px;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
    margin-left: calc(100vw * (5 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
    margin-left: calc(100vw * (5 / 428));
  }
`;
const ItemListWrap = styled.div`
  width: 100%;
  position: relative;

  @media screen and (max-width: 930px) {
    background: none;
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
    min-height: calc(100vw * (300 / 428));
  }
`;
const ItemInner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

// 콘텐츠
const ItemList = styled.div`
  width: 590px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 10px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (590 / 1320));
    margin-bottom: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: calc(100vw * (186 / 428));
    margin-bottom: calc(100vw * (20 / 428));
  }
`;

const ItemImgBox = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (200 / 1320));
    width: calc(100vw * (200 / 1320));
  }
  @media screen and (max-width: 930px) {
    border: calc(100vw * (1 / 428)) solid #f8f8f8f8;
    height: calc(100vw * (186 / 428));
    width: 100%;
  }
`;
const ItemImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const BestNewTextWrap = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 131px;
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (131 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (131 / 428));
  }
`;
const BestNewImgTextBox = styled.div``;
const ItemImgTitle = styled.p`
  font-size: 14px;
  font-weight: 800;
  color: #333333;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;
const ItemImgText = styled.p`
  height: 40px;
  font-size: 15px;
  color: #333333;
  margin-top: 5px;

  word-break: keep-all;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    min-height: calc(100vw * (40 / 1320));
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    min-height: calc(100vw * (38 / 428));
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (5 / 428));
  }
`;
const WishIcon = styled.img`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: block;
  width: 24px;
  height: 24px;
  transition: ease 0.3s all;

  @media screen and (max-width: 1320px) {
    bottom: calc(100vw * (10 / 1320));
    right: calc(100vw * (10 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    bottom: calc(100vw * (10 / 428));
    right: calc(100vw * (10 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
    ${({ touch }) =>
    touch &&
    `
      scale : 0.85;
        
    `}
  }
`;
const BestNewPriceWrap = styled.div`
  display: flex;
  align-items: center;
`;
const SaleRate = styled.p`
  color: #333333;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding: 2px 4px;
  margin-right: 5px;
  border-radius: 3px;
  background: #fdf251;
  line-height: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    padding: calc(100vw * (2 / 1320)) calc(100vw * (4 / 1320));
    margin-right: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    padding: calc(100vw * (2 / 428)) calc(100vw * (4 / 428));
    margin-right: calc(100vw * (5 / 428));
  }
`;
const Price = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #333333;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const PriceBox = styled.div`
  min-height: 43.5px;

  @media screen and (max-width: 1320px) {
    min-height: calc(100vw * (43.5 / 1320));
  }

  @media screen and (max-width: 930px) {
    min-height: calc(100vw * (43.5 / 428));
  }
`;
const OriginalPrice = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #acacac;
  text-decoration: line-through;
  margin-bottom: 4px;
  ${({ original }) => original && `color :transparent;`}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (4 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (4 / 428));
  }
`;
// common
const InfoText = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin: 0 auto;
  padding: 100px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (100 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (100 / 428)) 0;
  }
`;
