import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    FieldBox,
    Label,
    LabelName,
    EmailInput,
    PasswordInput,
    TelephoneInput,
    NameInput,
    Input
} from '../myinfoauth.elements';
import {
    MobileSubCheckButton,
    MobileSubBox
} from '../signup/signup.element';
import {
    Layout,
    TabContentHeading,
    TabContent,
    TabUserForm,
    ModifyButton
} from './mypage.elements';
import { DateToText } from '../../common/commonUse';
import MyPageModal from '../../common/pop/MyPageModal';
import api from '../../../server/serverController';

const MyPageAccount = () => {
    const { isLogged, userInfo } = useSelector(state => state.userData);
    const history = useHistory();

    const [isPhone, setIsPhone] = useState(false);
    const [isPassword, setIsPassword] = useState(false);
    const [isModify, setIsModify] = useState(false);
    const [isModifyPop, setIsModifyPop] = useState(false);


    const [newPhone, setNewPhone] = useState("")
    const [certiNum, setCertiNum] = useState("");
    const [confirm, setConfirm] = useState("")
    const [isCertificate, setIsCertificate] = useState(false)

    const getPhoneAuthentication = async () => {
        // const regExpPhone = /01[016789]-[^0][0-9]{2,3}-[0-9]{3,4}/;       
        // if (!regExpPhone.test(newPhone)) {
        //     return alert("휴대폰 형식에 맞춰 입력해주세요. ex)010-1234-5678")
        // }
        const path = `overlap/phone?mem_phone=${newPhone}`;
        const res = await api.post(path);
        console.log(res, "전화인증요청 res");
        if (res?.result === 1) {
            alert("전화 인증메세지 발송했습니다.")
            setCertiNum(res?.identify_num);
            // setIsPhone(false);
        } else {
            alert(res.message + '입니다')
        }
    }

    const handlePhoneConfirm = () => {
        if (!certiNum) {
            alert("새 연락처 인증번호 받기를 먼저 진행해주세요.")
            // setIsConfirmError(true);
            // setConfirmErrorMessage("본인 인증을 먼저 진행해주세요")
        } else if (certiNum === confirm) {
            alert("인증 성공했습니다.");
            // setIsPhone(false);
            setIsCertificate(true);
        } else if (certiNum !== confirm) {
            alert("맞지 않는 인증번호입니다.")
        }
    }

    const requestModified = async () => {
        if (isModify === false) {
            return setIsModify(true)
        } else {
            if (isCertificate) {
                const formData = new FormData();
                formData.append("mem_phone", newPhone)

                const res = await api.post(`user/update`, formData);
                console.log(res, "수정요청");
                if (res?.result === 1) {
                    setIsModifyPop(true);
                    setIsModify(false)
                    setIsPhone(false)
                }
            } else {
                setIsModify(false)
                setIsPhone(false)
            }
        }

    }

    return (
        <>
            <div>
                <TabContentHeading>{isModify ? "회원정보 수정" : "회원정보"}</TabContentHeading>
                <Layout>
                    <TabContent>
                        <TabUserForm>
                            <div style={{ marginBottom: 'calc(100vw *(100 / 428))' }}>
                                <FieldBox>
                                    <Label>
                                        <LabelName>이름</LabelName>
                                        <NameInput value={userInfo?.mem_name} readOnly />
                                    </Label>
                                </FieldBox>

                                <FieldBox>
                                    <Label>
                                        <LabelName>연락처</LabelName>
                                        <TelephoneInput value={userInfo?.mem_phone} readOnly />
                                    </Label>
                                    {isModify && (
                                        <MobileSubBox>
                                            <MobileSubCheckButton onClick={() => setIsPhone(prev => !prev)}>연락처 변경</MobileSubCheckButton>
                                        </MobileSubBox>
                                    )}
                                </FieldBox>

                                {
                                    isPhone && (
                                        <>
                                            <FieldBox>
                                                <Label>
                                                    <LabelName>새 연락처</LabelName>
                                                    <TelephoneInput placeholder="새 연락처를 입력해주세요." value={newPhone} onChange={(e) => setNewPhone(e.target.value)} />
                                                </Label>
                                                <MobileSubBox>
                                                    <MobileSubCheckButton onClick={getPhoneAuthentication}>인증번호 받기</MobileSubCheckButton>
                                                </MobileSubBox>

                                            </FieldBox>

                                            <FieldBox>
                                                <Label>
                                                    <LabelName>인증번호</LabelName>
                                                    <Input placeholder="인증번호를 입력해주세요." value={confirm} onChange={(e) => setConfirm(e.target.value)} />
                                                </Label>
                                                <MobileSubBox>
                                                    <MobileSubCheckButton onClick={handlePhoneConfirm}>확인</MobileSubCheckButton>
                                                </MobileSubBox>

                                            </FieldBox>
                                        </>
                                    )
                                }

                                <FieldBox>
                                    <Label>
                                        <LabelName>이메일</LabelName>
                                        <EmailInput placeholder={"김왈라"} value={userInfo?.mem_email} readOnly />
                                    </Label>
                                </FieldBox>

                                <FieldBox>
                                    <Label>
                                        <LabelName>비밀번호</LabelName>
                                        <PasswordInput placeholder="*****" value="00000" />
                                    </Label>
                                    {isModify && (
                                        <MobileSubBox>
                                            <MobileSubCheckButton onClick={() => setIsPassword(prev => !prev)}>비밀번호 변경</MobileSubCheckButton>
                                        </MobileSubBox>
                                    )}
                                </FieldBox>

                                {
                                    isPassword && (
                                        <>
                                            <FieldBox>
                                                <Label>
                                                    <LabelName>새 비밀번호</LabelName>
                                                    <PasswordInput />
                                                </Label>

                                            </FieldBox>
                                            <FieldBox>
                                                <Label>
                                                    <LabelName>새 비밀번호 확인</LabelName>
                                                    <PasswordInput placeholder="입력하신 비밀번호를 한번 더 입력해주세요" />
                                                </Label>
                                                <MobileSubBox>
                                                    <MobileSubCheckButton onClick={() => setIsPassword(false)}>확인</MobileSubCheckButton>
                                                </MobileSubBox>
                                            </FieldBox>
                                        </>
                                    )
                                }

                                <FieldBox>
                                    <Label>
                                        <LabelName readOnly>계정 생성일</LabelName>
                                        <Input value={DateToText(userInfo?.mem_register_date, ".")} readOnly />
                                    </Label>
                                </FieldBox>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 'calc(100vw *(243 / 428))' }}>
                                <ModifyButton onClick={requestModified}>{isModify ? "수정 완료" : "회원정보 수정"}</ModifyButton>
                            </div>

                        </TabUserForm>
                    </TabContent>
                </Layout>
            </div>
            {isModifyPop && <MyPageModal setIsModifyPop={setIsModifyPop} />}
        </>
    )
}

export default MyPageAccount
