import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import MyPageHeader from "../mypage/MyPageHeader";
import PayMain from "../../component/shopping/pay/PayMain";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function PayPage() {
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();

  const validLogin = () => {
    if (isLogged) return;
    alert(`잘못된 접근입니다.`);
    history.push(`/store`);
  };

  useEffect(() => {
    validLogin();
  }, []);

  return (
    <>
      {/* <PC>
        <Header/>
        <ShopStep title={"주문/결제"} on={2}/>
        <Pay/>
        <Footer/>
        <FixedTop/>
      </PC> */}

      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader title={"주문/결제"} prevBtn />
      </Mobile>
      <PayMain />

      {/* <Header/>
        <ShopStep title={"주문/결제"} on={2}/>
        <Pay/> */}
    </>
  );
}
