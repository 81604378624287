import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import { useSelector } from "react-redux";
import styled from "styled-components";

// controller
import serverController from "../../../server/serverController";

import { imgURL } from "../../../server/appInfoController";

import { MyPageButtonComp } from "../../common/MyPageButtonComp";
import MyPageBottom from "../../../page/mypage/MyPageBottom";
import MyPageDefaultModal from "../../common/modal/MyPageDefaultModal";

// img
import IconFileUpload from "../../../img/icon/icon_upload.svg";
import IconFileUploadCancel from "../../../img/icon/icon_upload_cancel.svg";
import { InfoText } from "../../login/auth.elements";

// mypage bottom data
const myPageBottomTextList = [
  "포인트는 최초 작성한 리뷰를 기준으로 지급됩니다.",
  "사진 첨부 시 캡처, 도용, 유사 상품 촬영, 동일 상품 여부 식별이 불가능한 경우에는 등록이 거절되며 사유는 별도 안내되지 않습니다.",
  "상품과 무관한 내용이나 사진, 동일 문자 반복 등의 부적합한 리뷰는 사전 경고 없이 사진 및 포인트 회수될 수 있습니다.",
];

const BtnList = [
  {
    text: "만족해요",
    value: 0,
  },
  {
    text: "별로예요",
    value: 1,
  },
];

export default function ReviewEditForm({
  reviewV = [],
  setReviewEdit,
  prevButtonClick,
  setPrevButtonClick,
  returnFunc,
}) {
  const history = useHistory();
  const permission = useSelector((state) => state.permissionData.permission.state);
  const [satisfaction, setSatisfaction] = useState(0); // 상품은 어떠셨나요 섹션
  const [reviewText, setReviewText] = useState("");
  const [isModal, setIsModal] = useState(null);
  const [imageArr, setImageArr] = useState([]);
  const [previewImageArr, setPreviewImageArr] = useState([]);

  const [photoSection, setPhotoSection] = useState(true);

  // put Review
  const setReviewDetail = () => {
    const formData = new FormData();
    formData.append("rev_id", reviewV.rev_id);
    formData.append("rev_content", reviewText);
    formData.append("satisfaction", satisfaction);
    formData.append("mog_id", reviewV.mog_id);
    if (imageArr[0] !== "" && imageArr.length !== 0) {
      formData.append("rev_image_file", imageArr[0]);
    } else if (imageArr.length === 0) {
      formData.append("rev_image_file", "");
    }

    serverController.connectFetchController(`review/product`, "PUT", formData, (res) => {
      if (res.result === 1) {
        setIsModal(true);
        setReviewEdit(false);
      }
    });
  };

  const uploadImage = (e) => {
    let arr = [];
    for (var i = 0; e.target.files.length > i; i++) {
      arr.push(e.target.files[i]);
    }
    setImageArr(arr);
  };

  const handleImageUpload = (e) => {
    const fileArr = e.target.files;
    let fileArrUrl = [];
    let file = null;

    for (let i = 0; i < fileArr.length; i++) {
      file = fileArr[i];

      let reader = new FileReader();
      reader.onload = () => {
        fileArrUrl[i] = reader.result;
        setPreviewImageArr([...fileArrUrl]);
      };
      reader.readAsDataURL(file);
    }
  };

  const deleteButtonClick = () => {
    setImageArr([]);
    setPreviewImageArr([]);
  };

  const returnFalseButtonFunc = () => {
    setPrevButtonClick(false);
  };

  useEffect(() => {
    if (isModal === false) {
      history.push(`/store/mypage/order`);
    }
  }, [isModal]);

  useEffect(() => {
    // 최초 바인딩
    if (reviewV.rev_image !== null) {
      setPreviewImageArr([imgURL + reviewV?.rev_image]);
      setImageArr([""]);
      // 이미지 업로드 안하면 imageArr 보내지 마!!
    }
    setReviewText(reviewV?.rev_content ?? "");
    setSatisfaction(reviewV?.satisfaction || 0);
  }, [reviewV]);

  // test zone
  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "permission",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!permission) {
      setPhotoSection(false);
    }
  }, [permission]);

  return (
    <Container>
      {prevButtonClick && (
        <MyPageDefaultModal
          text={
            <>
              작성하고 있던 내용이 저장되지 않습니다.
              <br />
              리뷰 작성을 취소하시겠습니까?
            </>
          }
          trueFunc={returnFunc}
          falseFunc={() => {
            returnFalseButtonFunc();
          }}
        />
      )}
      <Mobile>
        <ReviewFromWrap>
          <ReviewListWrap>
            <ListInner>
              <ProductInfoWrap>
                <ImgBox
                  onClick={() => {
                    history.push(`/store/detail/${reviewV.prd_id}`);
                  }}
                >
                  <img src={`${imgURL}${reviewV.prd_thumbnail}`} alt="product-img" />
                </ImgBox>
                <TextBox>
                  <Brand>{reviewV.bc_title || "(BRAND)"}</Brand>
                  <ProductName
                    onClick={() => {
                      history.push(`/store/detail/${reviewV.prd_id}`);
                    }}
                  >
                    {reviewV.prd_name}
                  </ProductName>
                  <ProductColor>
                    {reviewV.option && <span>{reviewV.option}</span>}
                    {/* <span>1개</span> */}
                  </ProductColor>
                </TextBox>
              </ProductInfoWrap>
            </ListInner>

            <BackgroundGray />

            <ListWrap>
              <FromWrap>
                <FromTitleWrap>
                  <ListTitle>상품은 어떠셨나요?</ListTitle>
                </FromTitleWrap>
                <FromBox>
                  <ReviewTextWrap>
                    <BtnWrap>
                      {BtnList.map((value, index) => {
                        return (
                          <MyPageButtonComp
                            active={index === satisfaction}
                            key={index + "btnList"}
                            state={index}
                            value={value}
                            setSatisfaction={setSatisfaction}
                          ></MyPageButtonComp>
                        );
                      })}
                    </BtnWrap>
                  </ReviewTextWrap>
                </FromBox>
              </FromWrap>
              <FromWrap>
                <FromTitleWrap>
                  <ListTitle>후기를 작성해주세요.</ListTitle>
                  <ReviewTextLength>({reviewText.length}/500)</ReviewTextLength>
                </FromTitleWrap>
                <FromBox>
                  <ReviewTextWrap>
                    <ReviewText
                      placeholder="구매하신 제품의 후기를 작성해주세요."
                      name="review_text"
                      value={reviewText}
                      onChange={(e) => {
                        setReviewText(e.target.value);
                      }}
                    />
                  </ReviewTextWrap>
                </FromBox>
              </FromWrap>
              {photoSection ? (
                <ImageWrap>
                  <ImageBox>
                    <FromTitleWrap>
                      <ListTitle>사진올리기(선택)</ListTitle>
                      <ListSubTitle>*사진 첨부하기(최대1장)</ListSubTitle>
                    </FromTitleWrap>

                    {/* 사진이미지 파일 올리기 */}
                    {imageArr.length === 0 && (
                      <FileReviseBtnWrap>
                        <FileReviseBtn
                          type="file"
                          id="filePic"
                          accept="image/*"
                          onChange={(e) => {
                            handleImageUpload(e);
                            uploadImage(e);
                          }}
                        />

                        <FileBtnLabel for="filePic" />
                      </FileReviseBtnWrap>
                    )}
                  </ImageBox>
                  {imageArr.map((el, index) => {
                    return (
                      <UploadImageBox key={index + "imageBox"}>
                        <DeleteButton onClick={deleteButtonClick} />
                        <UploadImage alt="previewImg" src={previewImageArr[index]} />
                      </UploadImageBox>
                    );
                  })}
                </ImageWrap>
              ) : (
                <InfoText>* 이미지 업로드는 '사진 접근 허용' 후 이용 가능합니다.</InfoText>
              )}
            </ListWrap>

            <MyPageBottom textList={myPageBottomTextList} />

            <SubmitBtnWrap>
              <Btn
                as="div"
                onClick={() => {
                  if (reviewText.length < 5) {
                    return alert("리뷰를 5자 이상 작성해주세요.");
                  } else {
                    setReviewDetail();
                  }
                }}
              >
                등록하기
              </Btn>
            </SubmitBtnWrap>
          </ReviewListWrap>
        </ReviewFromWrap>
      </Mobile>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
  }
`;
const ReviewFromWrap = styled.div``;

const BackgroundGray = styled.div`
  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (8 / 428));
    background-color: #f9f9f9;
  }
`;
//info
const ListInner = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;

const ImgBox = styled.div`
  background-color: #fff;
  border: 1px solid #eee;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (70 / 428));
    height: calc(100vw * (70 / 428));
  }
`;
const TextBox = styled.div`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (298 / 428));
  }
`;
const Brand = styled.p`
  color: #333;
  font-weight: 400;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (6 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProductColor = styled.p`
  color: #777;
  font-weight: 400;

  & span {
    color: #777;
    font-weight: 400;
  }
  & span:not(:last-child)::after {
    content: " / ";
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (4 / 428));
  }
`;

//list
const ReviewListWrap = styled.div`
  position: relative;
  width: 100%;
`;
const FromTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const ListSubTitle = styled.p`
  color: #777;
  font-weight: 400;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const ListTitle = styled.p`
  color: #333;
  font-weight: 500;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const ListWrap = styled.div`
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: calc(100vw * (30 / 428)) 0 0;
  }
`;

// from
const FromWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (30 / 428));
    }
  }
`;
const FromBox = styled.div``;

const ReviewTextWrap = styled.div``;
const ReviewText = styled.textarea`
  width: 100%;
  color: #333;
  font-weight: 400;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  &:focus {
    border: 1px solid #000;
  }

  &::placeholder {
    color: #acacac;
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (200 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (15 / 428)) calc(100vw * (20 / 428));
  }
`;
const ReviewTextLength = styled.span`
  color: #333;
  font-weight: 400;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 930px) {
  }
`;

const SubmitBtnWrap = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;

  @media screen and (max-width: 930px) {
    height: calc(100vw * (80 / 428));
  }
`;
const Btn = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: 500;
  background-color: #000;
  border: 1px solid #000;
  cursor: pointer;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;

// image upload
const ImageWrap = styled.div``;

const UploadImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const UploadImageBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #eee;
  cursor: pointer;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (140 / 428));
    height: calc(100vw * (140 / 428));
  }
`;
const DeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background: url(${IconFileUploadCancel}) no-repeat center / contain;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (28 / 428));
    height: calc(100vw * (28 / 428));
  }
`;

const ImageBox = styled.div``;

// file upload
const FileReviseBtnWrap = styled.div``;
const FileReviseBtn = styled.input`
  display: none;
`;
const FileBtnLabel = styled.label`
  display: block;
  background: url(${IconFileUpload}) no-repeat center / contain;
  cursor: pointer;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (140 / 428));
    height: calc(100vw * (140 / 428));
  }
`;
