import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { Mobile, PC } from "MediaQuery";

export default function PlanCategory({ id, item }) {
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const allListViewRef = useRef();
  const allListViewRefM = useRef();
  const planListContainer = document.getElementById("planListContainer");
  const searchParams = new URLSearchParams(window.location.search);
  const location = useLocation();

  const tab = Number(searchParams.get("tab"));
  const scrollElementRef = useRef(null);

  const findParams = () => {
    if (location.search.includes("pass") && location.search.includes("code")) {
      return "&code=" + searchParams.get("code") + "&pass=" + searchParams.get("pass");
    }
    if (location.search.includes("pass")) {
      return "&pass=" + searchParams.get("pass");
    }
    if (location.search.includes("code")) {
      return "&code=" + searchParams.get("code");
    }
    return "";
  };

  useEffect(() => {
    setTabIndex(tab);
  }, []);

  return (
    <>
      <PC>
        <PlanNavUl>
          <PlanNavLi
            active={tabIndex === 0}
            onClick={() => {
              setTabIndex(0);
              history.replace(`/store/plan/${id}?limit=100${findParams()}`);
            }}
          >
            전체보기
          </PlanNavLi>
          {item.map((el) => {
            return (
              <PlanNavLi
                active={tabIndex === el.eventCategoryId}
                onClick={() => {
                  setTabIndex(el.eventCategoryId);
                  history.replace(`/store/plan/${id}?tab=${el.eventCategoryId}&limit=10${findParams()}`);
                }}
                key={"event_nav_li" + el.eventCategoryId}
              >
                {el.eventCategoryTitle}
              </PlanNavLi>
            );
          })}
        </PlanNavUl>
      </PC>
      <Mobile>
        <PlanNavUlM>
          <PlanNavLiM
            active={tabIndex === 0}
            onClick={() => {
              setTabIndex(0);
              history.replace(`/store/plan/${id}?limit=100${findParams()}`);
              // planListContainer.scrollIntoView();
            }}
          >
            전체보기
          </PlanNavLiM>
          {item.map((el) => {
            return (
              <PlanNavLiM
                active={tabIndex === el.eventCategoryId}
                onClick={() => {
                  setTabIndex(el.eventCategoryId);
                  history.replace(`/store/plan/${id}?tab=${el.eventCategoryId}&limit=10${findParams()}`);
                }}
                key={"event_nav_li" + el.eventCategoryId}
              >
                {el.eventCategoryTitle}
              </PlanNavLiM>
            );
          })}
        </PlanNavUlM>
      </Mobile>
    </>
  );
}

const PlanNavUl = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
  background-color: #fff;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: 0;
  }
`;
const PlanNavUlM = styled.ul`
  position: relative;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  padding: 0 calc(100vw * (24 / 428));
  background-color: #fff;
  border-bottom: 1px solid #eee;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const PlanNavLi = styled.li`
  position: relative;
  width: 20%;
  border: 1px solid #ccc;
  border-left: none;
  height: 50px;
  cursor: pointer;
  padding: 10px 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.active
      ? css`
          background-color: #555;
          color: #fff;
          font-weight: bold;
        `
      : css`
          background-color: #fff;
          color: #000;
          font-weight: 400;
        `};

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (10 / 1320)) 0;
    height: calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (10 / 428)) 0;
    height: calc(100vw * (50 / 428));
    width: 50%;
  }

  &:nth-child(5n + 1) {
    border-left: 1px solid #ccc;
  }
  &:nth-child(n + 6) {
    border-top: none;
  }
`;

const PlanNavLiM = styled.li`
  padding: calc(100vw * (12 / 428)) calc(100vw * (10 / 428));
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.active
      ? css`
          font-weight: bold;
          border-bottom: 2px solid #111;
        `
      : css`
          font-weight: 400;
          border-bottom: 2px solid #fff;
        `};
`;
