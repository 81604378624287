import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
    StyleSponHead,
    StyleSponWrap,
    StyleSponCategorys,
    StyleCatgoryIcon
} from './stylist.element';

import IconAll from '../../images/stylist/icon_all.svg';
import styleType1 from '../../images/stylist/iconmonstr-clothing-1.png';
import styleType2 from '../../images/stylist/iconmonstr-clothing-2.png';
import styleType3 from '../../images/stylist/iconmonstr-clothing-3.png';
import styleType4 from '../../images/stylist/iconmonstr-clothing-4.png';
import styleType5 from '../../images/stylist/iconmonstr-clothing-5.png';
import styleType6 from '../../images/stylist/iconmonstr-clothing-6.png';
import styleType7 from '../../images/stylist/iconmonstr-clothing-7.png';
import styleType8 from '../../images/stylist/iconmonstr-clothing-8.png';
import styleType9 from '../../images/stylist/iconmonstr-clothing-9.png';
import styleType10 from '../../images/stylist/iconmonstr-clothing-10.png';
import styleHeadIcon from '../../images/stylist/iconmonstr-arrow-55.png';

const StylistSponserdHead = ({ look, tabIndex, setTabIndex }) => {
    const history = useHistory();

    const categorys = [
        { src: IconAll, title: "전체보기" },
        { src: styleType1, title: "상의" },
        { src: styleType2, title: "하의" },
        { src: styleType3, title: "원피스/세트" },
        { src: styleType4, title: "스커트" },
        { src: styleType5, title: "아우터 " },
        { src: styleType6, title: "슈즈" },
        { src: styleType7, title: "가방" },
        { src: styleType8, title: "액세서리" },
        { src: styleType9, title: "패션소품" },
        { src: styleType10, title: "빅사이즈" },
    ];


    return (
        <StyleSponWrap>
            <div>
                {/* {
                !look && <>
                    <StyleSponHead onClick={() => history.push('/stylist/regist')}>
                        <img src={styleHeadIcon} alt="" />
                        <span>컨텐츠 등록 바로가기</span>
                    </StyleSponHead>            
                </>
            } */}

                <StyleSponCategorys>
                    {
                        categorys.map((item, i) => {
                            return (
                                <li onClick={() => setTabIndex(i)}>
                                    <StyleCatgoryIcon selected={tabIndex == i}>
                                        <img src={item.src} alt="" />
                                    </StyleCatgoryIcon>
                                    <p>{item.title}</p>
                                </li>
                            )
                        })
                    }
                </StyleSponCategorys>
            </div>
        </StyleSponWrap>
    )
}

export default StylistSponserdHead
