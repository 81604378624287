import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

export default function StepComplete() {
  const history = useHistory();
  return (
    <CompleteBox>
      <CompleteTitle>맞춤분석이 완료되었습니다.</CompleteTitle>
      <CompleteText>마이페이지에서 다시 이용하실 수 있습니다.</CompleteText>
      <CompleteButton onClick={() => history.push(`/store/mypage`)}>마이페이지 이동</CompleteButton>
    </CompleteBox>
  );
}
const CompleteBox = styled.div`
  text-align: center;
  font-size: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const CompleteTitle = styled.p`
  margin-bottom: 10px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const CompleteText = styled.p`
  color: #a5a5a5;
  font-size: 16px;
  margin-bottom: 100px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-bottom: calc(100vw * (100 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (100 / 428));
  }
`;
const CompleteButton = styled.button`
  margin: 0 auto;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  height: 59px;
  width: 220px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    height: calc(100vw * (59 / 1320));
    width: calc(100vw * (220 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    height: calc(100vw * (59 / 428));
    width: calc(100vw * (220 / 428));
  }
`;
