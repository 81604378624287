import styled, { css, keyframes } from "styled-components";
import {            
    pc,
    mobil,
    MQ,
    mquery,
    Media,
    Px,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
} from '../common/mediaStlye';

export const ItemWrapper = styled.div`
    display: flex;
    height: calc(100vw * (669 / 1920));
    margin-bottom: calc(100vw * (53 / 1920));

    @media ${props => props.theme.container} {
        flex-direction: column;
        height: auto;
        margin-bottom: 0;
    }
`
export const ItemLeft = styled.div`
    width: calc(100vw * (507 / 1920));
    margin-right:calc(100vw * (3 / 1920));

    @media ${props => props.theme.container} {
        width: 100%;
    }
`

export const ItemRight = styled.div`
    flex: 1;
    display: flex;
    flex-direction:column;
`

export const ItemRtop = styled.div`
    display: flex;    
    justify-content:space-between;
    height: calc(100vw * (333 / 1920));
    margin-bottom: calc(100vw * (3 / 1920));
    & > div {
        width:33%;
    }
`

export const ItemRbot = styled.div`
    display: flex;    
    flex: 1;
    & > div {
        flex: 1;
        max-height: ${Px(333,pc)};
    }

    & > div:first-child {
        margin-right:calc(100vw * (3 / 1920));
    }
`

export const Item = styled.div`
    /* flex: 1; */
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    cursor: pointer;

    &:hover .item_head {
        opacity: 1;
    }

    ${props => props.mobileMain && css`
        ${[
            Height(498, mobil),
            MB(15.8, mobil)
        ].join("")}
    `}
`

export const ItemImg = styled.div`
    width: 100%;
    height:100%;
    line-height: 0;
    
    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ${MQ(mquery)} {
        ${props => props.sub ? Height(277, mobil) : css`height:100%;`}        
    }

   
`

export const ItemDesc = styled.div`
    position: absolute;
    left: 0;
    bottom:0;
    opacity: 0;
    padding: calc(100vw * (25/ 1920)) calc(100vw * (52 / 1920)) calc(100vw * (25/ 1920)) calc(100vw * (30 / 1920));
    width: 100%;
    background-color: rgba(34,34,34,0.9);
    height: ${({rtop}) => rtop ? 'calc(100vw * (133 / 1920))' : 'calc(100vw * (201 / 1920))'} ;    
    transition: all 0.3s ease;

    & > em {
        font-size: calc(100vw * (13 / 1920));
        color: #FFF200;
        padding-bottom: calc(100vw * (11 / 1920));
    }

    & > h4 {
        font-size: calc(100vw * (25 / 1920));
        padding-bottom: calc(100vw * (19 / 1920));
        color: #fff;
    }

    & > p {
        font-size: calc(100vw * (14 / 1920));
        padding-right: calc(100vw * (30 / 1920));
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #fff;
    }
    & p {
        color: #fff;
    }

    ${({rtop}) => rtop && css`        

        & > h4 {
            font-size: calc(100vw * (20 / 1920));
        }
    `}

    @media ${props => props.theme.container} {
        ${[
            Height(171, mobil),
            Padding(25,50,40,26,mobil)
        ].join("")}

        & > img{
            ${[
                Width(171, mobil),
                Padding(25,50,40,26,mobil)
            ].join("")}
        }

        & > em {
            ${[
                FontSize(13, mobil),
                PB(8, mobil)
            ].join("")}
        }
        & > h4 {
            ${[
                FontSize(22, mobil),
                PB(12, mobil)
            ].join("")}
        }
        & > p {
            ${[
                FontSize(14, mobil),
            ].join("")}
        }
    }
`

export const ItemDoubleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    gap: ${Px(8, mobil)};
    ${MB(50, mobil)}

    &:last-child :last-child img {
        ${Height(277, mobil)}
    }

    & .item_head {
        ${[
            Height(80, mobil),
            Padding(10,8,12,10,mobil)
        ].join("")}
    }

    & .item_head em {
        ${PB(1, mobil)}
    }

    & .item_head h4 {
        ${FontSize(15, mobil)}
        width: 100%;
        line-height: 1.2;
        font-weight: 900;   
    }

    & .item_img {
        flex: 1;
        ${Height(244, mobil)}
    }
`







