import React, { useState, useEffect, useRef } from "react";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";

export default function GrayZone() {
  return <BackgroundGray />;
}

const BackgroundGray = styled.div`
  width: 100%;
  height: 10px;
  background-color: #f9f9f9;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (14 / 428));
  }
`;
