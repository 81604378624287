//react
import { PC, Mobile } from "../../../MediaQuery";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import styled from "styled-components";

//img
import IconCancel from "../../../img/icon/icon_myhaeder_cancle.svg";
import IconFileUpload from "../../../img/icon/icon_upload.svg";
import IconFileUploadCancel from "../../../img/icon/icon_upload_cancel.svg";
import IconSelectArrow from "../../../img/icon/icon_bottom_arrow.svg";
import IconBack from "../../../img/icon/icon_header_back.svg";

// controller
import serverController from "../../../server/serverController";
import qs from "qs";

import MyPageBottom from "../../../page/mypage/MyPageBottom";
import MyPageDefaultModal from "./MyPageDefaultModal";
import { useHistory } from "react-router-dom";
import { InfoText } from "../../login/auth.elements";
import { imgURL } from "../../../server/appInfoController";

// option list
const optionList = [
  { title: "선택", value: "0" },
  // { title: "전체문의", value: "12" },
  { title: "상품문의", value: "5" },
  { title: "주문/결제문의", value: "6" },
  { title: "배송문의", value: "7" },
  { title: "교환/취소문의", value: "8" },
  { title: "계정문의", value: "9" },
  { title: "기타", value: "10" },
];

// mypage bottom data
const myPageBottomTextList = ["1:1 문의 유의 사항"];

export default function MyInquiryEditModal({
  setDetailShow,
  btnText,
  detailItem = [],
  edit = false,
}) {
  const history = useHistory();
  const { userInfo } = useSelector((state) => state.userData);
  const permission = useSelector((state) => state.permissionData.permission.state);
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [inquiryText, setInquiryText] = useState("");
  const [selectedValue, setSelectedValue] = useState("0");

  // edit
  const [imageNameArr, setImageNameArr] = useState([]);

  const [inquiryRemove, setInquiryRemove] = useState(false);

  const [photoSection, setPhotoSection] = useState(true);

  // imgs
  const [imageArr, setImageArr] = useState([]);
  const [previewImageArr, setPreviewImageArr] = useState([]);

  // post questionData
  const postQuestionData = () => {
    if (selectedValue === "0") {
      return alert("문의 유형을 선택해주세요.");
    }
    if (title?.length < 5) {
      return alert("제목을 5글자 이상 입력해주세요.");
    }
    if (inquiryText?.length < 10) {
      return alert("문의 내용을 10글자 이상 입력해주세요.");
    }
    const formData = new FormData();
    const inquiryData = JSON.stringify({
      bc_id: "0",
      prd_id: "0",
      inq_type: selectedValue,
      inq_title: title,
      inq_content: inquiryText,
      inq_secret: "0",
    });
    const blobData = new Blob([inquiryData], { type: "application/json" });
    const blobNullData = new Blob([], { type: "application/json" });

    formData.append("inquiry", blobData);
    if (imageArr?.length > 0) {
      for (let i in imageArr) {
        formData.append("images", imageArr[i]);
      }
    } else {
      formData.append("images", blobNullData);
    }

    serverController.connectFetchController(
      `inquiry/store`,
      "POST",
      formData,
      (res) => {
        if (res?.result === 1) {
          console.log(res, "res");
          if (window.innerWidth > 930) {
            history.push(`/store/mypage/inquiry?page=1`);
          } else {
            setDetailShow(false);
          }
          return alert("문의가 정상적으로 접수되었습니다.");
        } else return alert("문의가 정상적으로 접수되지 않았습니다. 다시 시도해주세요.");
      },
      (err) => console.error(err)
    );
  };

  // put edit inquiry
  const putEditInquiry = () => {
    if (selectedValue === "0") {
      return alert("문의 유형을 선택해주세요.");
    }
    if (title.length < 5) {
      return alert(`제목을 5글자 이상 입력해주세요.`);
    }
    if (inquiryText.length < 10) {
      return alert("문의 내용을 10글자 이상 입력해주세요.");
    }
    var upLoadImgText = "";
    for (let i in imageNameArr) {
      if (previewImageArr.length !== i * 1 + 1) {
        upLoadImgText = upLoadImgText + imageNameArr[i] + ",";
      } else {
        upLoadImgText = upLoadImgText + imageNameArr[i];
      }
    }

    const formData = new FormData();
    const inquiryData = JSON.stringify({
      bc_id: "0",
      prd_id: null,
      inq_type: selectedValue,
      inq_title: title,
      inq_images: upLoadImgText,
      inq_content: inquiryText,
      inq_secret: "1",
    });

    const blobData = new Blob([inquiryData], { type: "application/json" });
    const blobNullData = new Blob([], { type: "application/json" });

    formData.append("inquiry", blobData);
    if (imageArr.length > 0) {
      console.log("length 1");
      for (let i in imageArr) {
        formData.append("images", imageArr[i]);
      }
    } else {
      console.log("length 0");
      formData.append("images", blobNullData);
    }
    serverController.connectFetchController(
      `inquiry/store`,
      "PUT",
      formData,
      (res) => {
        console.log(res, "put res");
        if (res?.result === 1) {
          setDetailShow(false);
        }
      },
      (err) => console.error(err)
    );
  };

  // s :: about imgs
  const uploadImage = (e) => {
    let arr = [...imageArr];
    for (var i = 0; e.target.files.length > i; i++) {
      arr.unshift(e.target.files[i]);
    }
    setImageArr(arr);
  };

  const handleImageUpload = (e) => {
    const fileArr = e.target.files;
    let fileArrUrl = [];
    let file = null;
    const imgArr = [...previewImageArr];

    for (let i = 0; i < fileArr.length; i++) {
      file = fileArr[i];

      let reader = new FileReader();
      reader.onload = () => {
        fileArrUrl[i] = reader.result;
        imgArr.unshift(fileArrUrl[i]);
        setPreviewImageArr(imgArr);
      };
      reader.readAsDataURL(file);
    }
  };
  const deleteButtonClick = (index) => {
    let imgArr = [];
    let prvArr = [];
    for (let i in previewImageArr) {
      if (i != index) {
        imgArr.push(imageArr[i]);
        prvArr.push(previewImageArr[i]);
      }
    }
    setImageArr(imgArr);
    setPreviewImageArr(prvArr);
  };
  const deleteButtonClickEdit = (index, uploadImg) => {
    let prvArr = [];
    console.log(previewImageArr, "previewImageArr");
    for (let i in previewImageArr) {
      if (i != index) {
        prvArr.push(previewImageArr[i]);
      }
    }
    if (prvArr.length === 0) {
      return () => {
        setImageNameArr([]);
        setPreviewImageArr([]);
      };
    }
    setImageNameArr(prvArr);
    setPreviewImageArr(prvArr);
  };
  // e :: about imgs

  useEffect(() => {
    if (edit) {
      setTitle(detailItem?.inq_title);
      setInquiryText(detailItem?.inq_content);
    }
  }, [edit]);

  // test zone
  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "permission",
        })
      );
    }
  }, []);
  useEffect(() => {
    if (!permission) {
      setPhotoSection(false);
    }
  }, [permission]);

  // edit
  useEffect(() => {
    if (edit) {
      if (detailItem?.inq_images?.split(",")?.length > 0) {
        setPreviewImageArr(detailItem?.inq_images?.split(",")); /// [..., ...], 프리뷰
        setImageNameArr(detailItem?.inq_images?.split(",")); /// [..., ...], put name arr
      }
    }
  }, [edit]);

  return (
    <Container>
      <Mobile>
        {inquiryRemove && (
          <MyPageDefaultModal
            text={
              <>
                작성하고 있던 내용이 저장되지 않습니다.
                <br />
                문의 작성을 취소하시겠습니까?
              </>
            }
            trueFunc={() => setDetailShow(false)}
            falseFunc={() => setInquiryRemove(false)}
          />
        )}
      </Mobile>
      <PC>
        {inquiryRemove && (
          <MyPageDefaultModal
            text={
              <>
                작성하고 있던 내용이 저장되지 않습니다.
                <br />
                문의 작성을 취소하시겠습니까?
              </>
            }
            trueFunc={() => {
              history.push(`/store/mypage/inquiry?page=1`);
            }}
            falseFunc={() => setInquiryRemove(false)}
          />
        )}
      </PC>

      <Content>
        {/* header */}
        <Mobile>
          <HeaderSection>
            <HeaderInner>
              <p>{edit ? "1:1 문의 수정" : "1:1 문의 작성"}</p>
              <CloseButton
                onClick={() => {
                  setInquiryRemove(true);
                }}
              >
                <img src={IconCancel} alt="cancel-icon" />
              </CloseButton>
            </HeaderInner>
          </HeaderSection>
        </Mobile>

        <Wrap>
          <PC>
            <PageButtonWrap>
              <PageButtonArea
                onClick={() => {
                  setInquiryRemove(true);
                }}
              >
                <PageButton>
                  <img src={IconBack} alt="back-img" />
                </PageButton>
                <PageButtonTitle>목록으로</PageButtonTitle>
              </PageButtonArea>
            </PageButtonWrap>
          </PC>

          <ListWrap>
            <FormWrap>
              <FormList>
                <FormTitleWrap>
                  <ListTitle>문의 유형</ListTitle>
                </FormTitleWrap>
                <SelectButton
                  defaultValue={"0"}
                  borderActive={selectedValue !== "0"}
                  onChange={(e) => {
                    setSelectedValue(e.target.value);
                  }}
                >
                  {optionList.map((item) => {
                    return (
                      <option value={item.value} key={item.value}>
                        {item.title}
                      </option>
                    );
                  })}
                </SelectButton>
              </FormList>
              <FormList>
                <FormTitleWrap>
                  <ListTitle>휴대폰번호</ListTitle>
                </FormTitleWrap>
                <InputPhone
                  type="number"
                  value={
                    userInfo?.mem_phone && userInfo?.mem_phone?.length > 0
                      ? userInfo.mem_phone
                      : phone
                  }
                  active={phone?.length > 0}
                  placeholder="-를 제외한 숫자만 입력해주세요."
                  readOnly={userInfo?.mem_phone && userInfo?.mem_phone?.length > 0}
                  onChange={(e) => {
                    if (e.target.value.length > 11) return;
                    setPhone(e.target.value);
                  }}
                />
              </FormList>
              <FormList>
                <FormTitleWrap>
                  <ListTitle>제목</ListTitle>
                </FormTitleWrap>
                <Input
                  type="text"
                  value={title}
                  active={title.length > 0}
                  placeholder="제목을 입력해주세요."
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </FormList>
              <FormList>
                <FormTitleWrap>
                  <ListTitle>문의 내용</ListTitle>
                </FormTitleWrap>
                <InquiryTextWrap>
                  <InquiryTextArea
                    value={inquiryText}
                    active={inquiryText.length > 0}
                    placeholder="내용을 입력해주세요."
                    onChange={(e) => {
                      setInquiryText(e.target.value);
                    }}
                  />
                </InquiryTextWrap>
              </FormList>
            </FormWrap>

            {photoSection ? (
              <ImageWrap>
                <FormTitleWrap>
                  <ListTitle>사진첨부하기(선택)</ListTitle>
                  <ListSubTitle>*최대3장</ListSubTitle>
                </FormTitleWrap>
                {!edit ? (
                  <ImageInner>
                    {imageArr.length < 3 && (
                      <ImageList>
                        <ImageBox>
                          <FileReviseBtnWrap>
                            <FileReviseBtn
                              type="file"
                              id="filePic"
                              accept="image/*"
                              onChange={(e) => {
                                if (e.target.files.length + imageArr.length > 3) {
                                  return alert("파일은 최대 3개까지 선택 가능합니다.");
                                }
                                handleImageUpload(e);
                                uploadImage(e);
                              }}
                            />
                            <FileBtnLabel for="filePic" />
                          </FileReviseBtnWrap>
                        </ImageBox>
                      </ImageList>
                    )}
                    {previewImageArr.map((_, index) => {
                      return (
                        <ImageList key={index + "-img"}>
                          <UploadImageBox>
                            <UploadDeleteButton
                              onClick={() => {
                                deleteButtonClick(index);
                              }}
                            />
                            <UploadImage alt="previewImg" src={previewImageArr[index]} />
                          </UploadImageBox>
                        </ImageList>
                      );
                    })}
                  </ImageInner>
                ) : (
                  <ImageInner>
                    {previewImageArr?.length < 3 && (
                      <ImageList>
                        <ImageBox>
                          <FileReviseBtnWrap>
                            <FileReviseBtn
                              type="file"
                              id="filePic"
                              accept="image/*"
                              onChange={(e) => {
                                handleImageUpload(e);
                                uploadImage(e);

                                // preview arr 추가
                                let prvImgArr = [...previewImageArr];
                                prvImgArr.push(e?.target?.files[0]?.name);
                                setPreviewImageArr(prvImgArr);

                                // name arr 추가
                                let prvImgNameArr = [...imageNameArr];
                                prvImgNameArr.push(e?.target?.files[0]?.name);
                                setImageNameArr(prvImgNameArr);
                              }}
                            />
                            <FileBtnLabel for="filePic" />
                          </FileReviseBtnWrap>
                        </ImageBox>
                      </ImageList>
                    )}
                    {previewImageArr?.map((PrvItem, index) => {
                      // -1이면 기존 업로드 이미지
                      console.log(PrvItem.indexOf("data:image/"), "test");
                      return (
                        <ImageList key={index + "-img"}>
                          <UploadImageBox>
                            <UploadDeleteButton
                              onClick={() => {
                                deleteButtonClickEdit(index, PrvItem.indexOf("data:image/") === 0);
                              }}
                            />
                            <UploadImage
                              alt="previewImg"
                              src={
                                PrvItem.indexOf("data:image/") === 0 ? PrvItem : imgURL + PrvItem
                              }
                            />
                          </UploadImageBox>
                        </ImageList>
                      );
                    })}
                  </ImageInner>
                )}
              </ImageWrap>
            ) : (
              <InfoText>* 이미지 업로드는 '사진 접근 허용' 후 이용 가능합니다.</InfoText>
            )}
          </ListWrap>

          <SubmitBtnWrap>
            <Btn
              type="button"
              onClick={() => {
                if (edit) {
                  putEditInquiry();
                } else {
                  postQuestionData();
                }
              }}
            >
              {edit || query?.edit === "T" ? "수정하기" : btnText}
            </Btn>
          </SubmitBtnWrap>

          {/* <MyPageBottom textList={myPageBottomTextList} /> */}
        </Wrap>
      </Content>
    </Container>
  );
}

const Container = styled.div``;

const Wrap = styled.div`
  width: 100%;
  padding-bottom: 180px;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (180 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (80 / 428));
  }
`;

// header
const HeaderSection = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 99;

  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
  }
`;
const HeaderInner = styled.div`
  width: 100%;

  & p {
    color: #333;
    font-weight: 500;
  }
  @media screen and (max-width: 930px) {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vw * (55 / 428));
    background-color: #fff;

    & p {
      font-size: calc(100vw * (16 / 428));
    }
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 930px) {
    right: calc(100vw * (13.5 / 428));
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;

// 콘텐츠
const Content = styled.div`
  overflow: hidden;

  @media screen and (max-width: 930px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background: #fff;
  }
`;

// inquiry
const ListWrap = styled.div`
  margin-top: 30px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: 0;
  }
`;

// from
const FormWrap = styled.div`
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const FormList = styled.div`
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (30 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 428));
    }
  }
`;

const FormTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;

  @media screen and (max-width: 1320px) {
    margin: 0 0 calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto calc(100vw * (8 / 428));
  }
`;
const ListTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const ListSubTitle = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;

// select
const SelectButton = styled.select`
  display: block;
  width: 378px;
  height: 50px;
  color: #acacac;
  font-weight: 400;
  font-size: 15px;
  background: #ffffff url(${IconSelectArrow}) no-repeat right 15px center / 24px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0 15px;
  border-radius: 0;

  -webkit-appearance: none; /* 네이티브 외형 감추기*/
  -moz-appearance: none;
  appearance: none;
  font-weight: normal;
  letter-spacing: normal;
  cursor: pointer;

  ${({ borderActive }) =>
    borderActive &&
    `border: 1px solid #000;
    color: #333;
  `}
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
    height: calc(100vw * (50 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
    font-size: calc(100vw * (15 / 1320));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 1320)) center /
      calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 428)) center /
      calc(100vw * (24 / 428));
  }
`;
// input
const Input = styled.input`
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;
const InputPhone = styled(Input)`
  width: 378px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
// textarea
const InquiryTextWrap = styled.div``;
const InquiryTextArea = styled.textarea`
  width: 100%;
  height: 200px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  padding: 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (200 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (200 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (15 / 428)) calc(100vw * (20 / 428));
  }
`;

// button
const SubmitBtnWrap = styled.div`
  width: 378px;
  height: 50px;
  margin: 100px auto 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
    height: calc(100vw * (50 / 1320));
    margin: calc(100vw * (100 / 1320)) auto 0;
  }
  @media screen and (max-width: 930px) {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: calc(100vw * (80 / 428));
    margin: 0;
  }
`;
const Btn = styled.button`
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;

// image upload
const ImageWrap = styled.div`
  margin-top: 30px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const ImageInner = styled.ul`
  display: flex;

  white-space: nowrap;
  overflow-y: visible;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  @media screen and (max-width: 930px) {
    padding-left: calc(100vw * (25 / 428));
  }
`;
const ImageList = styled.li`
  &:not(:last-child) {
    padding-right: 10px;
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      padding-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      padding-right: calc(100vw * (10 / 428));
    }
  }
`;
const ImageBox = styled.div``;

const UploadImageBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  background-color: #fff;
  border: 1px solid #eee;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    height: calc(100vw * (140 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (140 / 428));
    height: calc(100vw * (140 / 428));
  }
`;
const UploadImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const UploadDeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 28px;
  height: 28px;
  background: url(${IconFileUploadCancel}) no-repeat center / contain;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (28 / 1320));
    height: calc(100vw * (28 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (28 / 428));
    height: calc(100vw * (28 / 428));
  }
`;

// file upload
const FileReviseBtnWrap = styled.div``;
const FileReviseBtn = styled.input`
  display: none;
`;
const FileBtnLabel = styled.label`
  display: block;
  width: 140px;
  height: 140px;
  background: url(${IconFileUpload}) no-repeat center / contain;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    height: calc(100vw * (140 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (140 / 428));
    height: calc(100vw * (140 / 428));
  }
`;

// pc - page button
const PageButtonWrap = styled.div`
  padding: 14px 20px;
  border-bottom: 1px solid #eee;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (14 / 1320)) calc(100vw * (20 / 1320));
  }
`;
const PageButtonArea = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;
const PageButton = styled.div`
  width: 32px;
  height: 32px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
`;
const PageButtonTitle = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 15px;
  margin-left: 5px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (5 / 1320));
  }
`;
