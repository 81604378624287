//react
import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";

// controller
import serverController from "../../../server/serverController";

//img
import IconCancel from "../../../img/icon/icon_myhaeder_cancle.svg";
import IconLogo from "../../../img/icon/wala_logo.svg";
import Zigzag from "../../../img/borderBottom_zigzag_search.svg";
import IconSearch from "../../../img/icon/icon_header_search_black.svg";
import IconSearchIndex from "../../../img/icon/icon_search_index.svg";
import IconDelete from "../../../img/icon/icon_keyword_delete.svg";

// component
import ProductListThree from "../../common/product/ProductListThree";
import MyPageDefaultModal from "./MyPageDefaultModal";

// 더미데이터
const searchedPrdList = [
  { productName: "룰루레몬" },
  { productName: "레깅스" },
  { productName: "마틸라" },
  { productName: "얼데이" },
  { productName: "홀릭앤플레이" },
  { productName: "티셔츠" },
  { productName: "팬츠" },
];

export default function SearchModal({ setSearchModalShow }) {
  const history = useHistory();
  const { location } = useLocation();
  const [tabIndex, setTabIndex] = useState(0);
  const [inputSearchValue, setInputSearchValue] = useState("");

  const [recentProductList, setRecentProductList] = useState([]);
  const [bestKeywordList, setBestKeywordList] = useState([]);

  const [loginPop, setLoginPop] = useState(false);
  const [recentList, setRecentList] = useState([]);

  // split keyword
  const handleSplitKeyword = (value) => {
    let searchKeyword = value.split(inputSearchValue);
    return searchKeyword.map((text, index) => (
      <React.Fragment key={index + "text"}>
        <SearchResultText>{text}</SearchResultText>
        {searchKeyword.length !== index + 1 ? (
          <SearchResultText style={{ fontWeight: "600" }}>{inputSearchValue}</SearchResultText>
        ) : null}
      </React.Fragment>
    ));
  };

  // search click
  const handleSearchClick = (clickKeyword = inputSearchValue) => {
    if (clickKeyword.length === 0) return;
    let recentListArr = recentList;
    recentListArr.unshift(clickKeyword);
    if (recentListArr.length > 10) {
      recentListArr.pop();
    }
    setRecentList(recentListArr);
    localStorage.setItem("recentList", JSON.stringify([...new Set(recentListArr)]));
    setSearchModalShow(false);
    history.push(`/store/search/result?page=1&searchKeyword=${clickKeyword}&tabIndex=${tabIndex}`);
  };

  // delete one recent item
  const deleteRecentItem = (idx) => {
    let recentListArr = recentList;
    recentListArr.splice(idx, 1);
    setRecentList([...recentListArr]);
    localStorage.setItem("recentList", JSON.stringify([...recentListArr]));
  };

  // get best keyword
  const getBestKeyword = () => {
    serverController.connectFetchController(
      `keyword/best?type=${tabIndex + 1}`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          const listArr = [...res.list];
          setBestKeywordList(listArr);
        }
      }
    );
  };
  // get recentProductList
  const getRecentProductList = () => {
    serverController.connectFetchController("products/recently", "GET", null, (res) => {
      if (res?.result === 1) {
        setRecentProductList(res?.list.reverse().splice(0, 9));
      }
    });
  };

  useEffect(() => {
    // get recentProductList
    getRecentProductList();
    // set recentList
    setRecentList(JSON.parse(localStorage.getItem("recentList")) || []);
  }, []);

  useEffect(() => {
    getBestKeyword();
  }, [tabIndex]);

  return (
    <Container>
      <Background
        onClick={() => {
          setSearchModalShow(false);
        }}
      />
      {loginPop && (
        <MyPageDefaultModal
          text="로그인 후 이용가능합니다."
          trueText="로그인"
          trueFunc={() => {
            if (location) return history.push(`/store/login?returnTo=${location.pathname}`);
            history.push(`/store/login`);
          }}
          falseFunc={() => setLoginPop(false)}
        />
      )}
      <Content>
        {/* header */}
        <HeaderSection>
          <HeaderInner>
            <LogoWrap>
              <LogoImg to={`/store`}>
                <img src={IconLogo} alt="logo" />
              </LogoImg>
            </LogoWrap>
            <CloseButton onClick={() => setSearchModalShow(false)}>
              <img src={IconCancel} alt="cancel-icon-img" />
            </CloseButton>
          </HeaderInner>
        </HeaderSection>

        <Wrap>
          {/* search */}
          <SearchInputWrap>
            <SearchInputBox>
              <SearchIcon
                onClick={() => {
                  if (inputSearchValue.length === 0) return;
                  handleSearchClick();
                }}
              >
                <img src={IconSearch} alt="searchIcon" />
              </SearchIcon>
              <SearchInput
                value={inputSearchValue}
                onChange={(e) => {
                  setInputSearchValue(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key !== "Enter") return;
                  if (inputSearchValue.length === 0) return;
                  handleSearchClick();
                }}
                placeholder="검색어를 입력해주세요."
              />
            </SearchInputBox>
          </SearchInputWrap>

          {/* tab */}
          <StoreTabWrap>
            <StoreSearchTabBox>
              <StoreSearchTab onClick={() => setTabIndex(0)}>상품</StoreSearchTab>
              <StoreSearchTab onClick={() => setTabIndex(1)}>브랜드</StoreSearchTab>
              <StoreSearchTab onClick={() => setTabIndex(2)}>컨텐츠</StoreSearchTab>
              <StoreSearchTabBottom tabIndex={tabIndex}>
                <StoreSearchTabBottomImg src={Zigzag} alt="zigzag-bottom" />
              </StoreSearchTabBottom>
            </StoreSearchTabBox>
          </StoreTabWrap>

          <StoreSearchWrap>
            {inputSearchValue?.length > 0 ? (
              // search result list
              <SearchResultWrap>
                {searchedPrdList.map((value, index) => {
                  return (
                    <SearchResultBoxList>
                      <SearchResultBox>
                        <SearchResultIndexIconBox>
                          <img src={IconSearchIndex} alt="IconSearchIndex" />
                        </SearchResultIndexIconBox>
                        <SearchResultTextBox
                          key={`${index}${value.productName}`}
                          onClick={() => setInputSearchValue(value.productName)}
                        >
                          {handleSplitKeyword(value?.productName)}
                        </SearchResultTextBox>
                      </SearchResultBox>
                    </SearchResultBoxList>
                  );
                })}
              </SearchResultWrap>
            ) : (
              <SearchResultContentWrap>
                {recentList.length > 0 && (
                  <RecentKeywordWrap>
                    <BestRankTitleBox>
                      <BestRankTitle>최근 검색어</BestRankTitle>
                    </BestRankTitleBox>
                    <RecentKeywordListArea>
                      {recentList.map((recentItem, idx) => {
                        return (
                          <RecentKeywordList key={recentItem}>
                            <RecentKeywordBox>
                              <RecentKeywordText
                                onClick={() => {
                                  setSearchModalShow(false);
                                  history.push(
                                    `/store/search/result?page=1&searchKeyword=${recentItem}&tabIndex=${tabIndex}`
                                  );
                                }}
                              >
                                {recentItem}
                              </RecentKeywordText>
                              <RecentKeywordDeleteButton
                                type="button"
                                onClick={() => deleteRecentItem(idx)}
                              />
                            </RecentKeywordBox>
                          </RecentKeywordList>
                        );
                      })}
                    </RecentKeywordListArea>
                  </RecentKeywordWrap>
                )}

                {/* BEST Brand */}
                {/* <BestRankWrap>
                  <BestRankTitleBox>
                    <BestRankTitle>
                      {tabIndex === 0
                        ? "인기검색어"
                        : tabIndex === 1
                        ? "BEST BRAND"
                        : tabIndex === 2
                        ? "BEST CONTENTS"
                        : null}
                    </BestRankTitle>
                  </BestRankTitleBox>
                  <BestRankListBox>
                    <BestRankList>
                      {bestKeywordList.length > 0 &&
                        bestKeywordList.map((bestRankValue, index) => {
                          return (
                            <BestRankContent
                              key={bestRankValue.keyword}
                              onClick={() => handleSearchClick(bestRankValue.keyword)}
                            >
                              {`${index + 1}. ${bestRankValue.keyword}`}
                            </BestRankContent>
                          );
                        })}
                    </BestRankList>
                  </BestRankListBox>
                </BestRankWrap> */}

                {/* 최근 본 상품 */}
                {recentProductList?.length > 0 && (
                  <RecentProductWrap>
                    <BestRankTitleBox>
                      <BestRankTitle>최근 본 상품</BestRankTitle>
                    </BestRankTitleBox>
                    <RecentProductListBox>
                      {recentProductList?.map((recentPrdValue, idx) => {
                        return (
                          <ProductListThree
                            key={`${recentPrdValue.prd_id}-pcRecentList-${idx}`}
                            value={recentPrdValue}
                            setLoginPop={setLoginPop}
                          />
                        );
                      })}
                    </RecentProductListBox>
                  </RecentProductWrap>
                )}
              </SearchResultContentWrap>
            )}
          </StoreSearchWrap>
        </Wrap>
      </Content>
    </Container>
  );
}

const Container = styled.div``;

const Wrap = styled.div`
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
`;
// header
const HeaderSection = styled.div`
  height: 80px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (80 / 1320));
  }
`;
const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1240px;
  height: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
`;
const CloseButton = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
`;

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
`;
const LogoImg = styled(Link)`
  width: 120px;
  height: 28px;

  & img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (120 / 1320));
    height: calc(100vw * (28 / 1320));
  }
`;

// 콘텐츠
const Content = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: auto;
  background: #fff;
  overflow: hidden;
`;

// search
const SearchInputWrap = styled.div`
  padding: 15px 0 35px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (15 / 1320)) 0 calc(100vw * (35 / 1320));
  }
`;
const SearchInputBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 700px;
  margin: 0 auto;
  padding-bottom: 11px;
  border-bottom: 2px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (700 / 1320));
    padding-bottom: calc(100vw * (11 / 1320));
  }
`;
const SearchInput = styled.input`
  width: 100%;
  color: #333333;
  font-weight: 400;
  font-size: 18px;

  &::placeholder {
    color: #acacac;
  }
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
`;
const SearchIcon = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 20px;
  cursor: pointer;

  & > img {
    height: 100%;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
    margin-right: calc(100vw * (20 / 1320));
  }
`;

// tab
const StoreTabWrap = styled.div`
  width: 100%;
  border-bottom: 1px solid #eeeeee;
`;
const StoreSearchTabBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 378px;
  height: 40px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
    height: calc(100vw * (40 / 1320));
  }
`;
const StoreSearchTab = styled.div`
  width: 126px;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (126 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
`;
const StoreSearchTabBottom = styled.div`
  position: absolute;
  left: ${(props) =>
    props.tabIndex === 0
      ? `0px`
      : props.tabIndex === 1
        ? `126px`
        : props.tabIndex === 2
          ? `252px`
          : null};
  bottom: -7px;
  transition: ease 0.3s all;
  width: 126px;

  @media screen and (max-width: 1320px) {
    left: ${(props) =>
    props.tabIndex === 0
      ? `0px`
      : props.tabIndex === 1
        ? `calc(100vw * (126 / 1320))`
        : props.tabIndex === 2
          ? `calc(100vw * (252 / 1320))`
          : null};
    bottom: calc(100vw * (-7 / 1320));
    width: calc(100vw * (126 / 1320));
  }
`;
const StoreSearchTabBottomImg = styled.img`
  width: 100%;
  height: 100%;
`;

const StoreSearchWrap = styled.div`
  padding: 30px 0 80px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) 0 calc(100vw * (80 / 1320));
  }
`;

// search result list
const SearchResultWrap = styled.div`
  width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1200 / 1320));
  }
`;
const SearchResultBoxList = styled.div`
  margin: 13px 0;

  @media screen and (max-width: 1320px) {
    margin: calc(100vw * (13 / 1320)) 0;
  }
`;
const SearchResultBox = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;
const SearchResultIndexIconBox = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
    margin-right: calc(100vw * (10 / 1320));
  }
`;
const SearchResultTextBox = styled.div``;
const SearchResultText = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;

const SearchResultContentWrap = styled.div`
  & section:not(:last-child) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 1320px) {
    & section:not(:last-child) {
      margin-bottom: calc(100vw * (40 / 1320));
    }
  }
`;

// 최근 검색어
const RecentKeywordWrap = styled.section``;
const RecentKeywordListArea = styled.ul`
  display: inline-flex;
  flex-wrap: wrap;
`;
const RecentKeywordList = styled.li`
  margin: 6px 10px 6px 0;

  @media screen and (max-width: 1320px) {
    margin: calc(100vw * (6 / 1320)) calc(100vw * (10 / 1320)) calc(100vw * (6 / 1320)) 0;
  }
`;
const RecentKeywordBox = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 5px 8px 15px;
  background-color: #f8f8f8;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (8 / 1320)) calc(100vw * (5 / 1320)) calc(100vw * (8 / 1320))
      calc(100vw * (15 / 1320));
  }
`;
const RecentKeywordText = styled.span`
  font-size: 17px;
  color: #333;
  font-weight: 400;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (17 / 1320));
  }
`;
const RecentKeywordDeleteButton = styled.button`
  width: 24px;
  height: 24px;
  margin-left: 10px;
  background: url(${IconDelete}) no-repeat center/contain;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
    margin-left: calc(100vw * (10 / 1320));
  }
`;

// BEST Brand
const BestRankWrap = styled.section``;
const BestRankTitleBox = styled.div`
  margin-bottom: 9px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (9 / 1320));
  }
`;
const BestRankTitle = styled.span`
  font-size: 16px;
  color: #333;
  font-weight: 500;
  padding-right: 12px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding-right: calc(100vw * (12 / 1320));
  }
`;
const BestRankListBox = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;
const BestRankList = styled.li`
  margin: 6px 30px 6px 0;
  line-height: 1.3;

  @media screen and (max-width: 1320px) {
    margin: calc(100vw * (6 / 1320)) calc(100vw * (30 / 1320)) calc(100vw * (6 / 1320)) 0;
  }
`;
const BestRankContent = styled(BestRankTitle)`
  cursor: pointer;
`;

// 최근 본 상품
const RecentProductWrap = styled.section``;

const RecentProductListBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
