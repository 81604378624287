import qs from "qs";

import Header from "../../component/common/Header";
import FindID from "../../component/login/FindID";

export default function StoreFindIDPage() {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  return (
    <>
      <Header back={true} url={`/store/login?returnTo=${query.returnTo}`} />
      <FindID returnTo={query.returnTo} />
    </>
  );
}
