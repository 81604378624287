import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";
import serverController from "../../server/serverController";
import { useSelector } from "react-redux";

import IconClosed from "../../img/icon/modal-close-icon.png";
import IconHome from "../../img/icon/icon_home_wh.png";

export default function GnbList({ menuOn, setMenuOn }) {
  const history = useHistory();
  const location = useLocation();
  const { isLogged } = useSelector((state) => state.userData);
  const gnbList = [
    {
      path: "/store/category/woman",
      title: "WOMAN",
    },
    {
      path: "/store/category/man",
      title: "MAN",
    },
    {
      path: "/store/category/new",
      title: "NEW",
    },
    {
      path: "/store/brand",
      title: "BRAND",
    },
  ];

  const [list, setList] = useState([]);
  const getCategorys = async () => {
    await serverController.connectFetchController(`mainCategory`, "GET", null, (res) => {
      console.log(res, "모달 카테고리 목록 res");
      if (res?.result === 1) {
        console.log(res?.list, "list");
        setList(res?.list ?? []);
      }
    });
  };

  useEffect(() => {
    getCategorys();
  }, []);

  const onHandleClick = (v) => {
    history.push(v.path);
    setMenuOn(false);
  };

  return (
    <>
      <Bg
        onClick={() => {
          setMenuOn(false);
        }}
      />
      <Container>
        <GnbWrap>
          <HomeWrap href="/">
            <Home src={IconHome} alt="" />
          </HomeWrap>
          <GnbListWrap>
            {gnbList.map((v, i) => {
              return (
                <GnbTab onClick={() => onHandleClick(v)}>
                  {v.title}
                  {i === 0 ? (
                    <ul>
                      {list.map((item, i) => {
                        return <li>{item?.prd_maincat_name}</li>;
                      })}
                    </ul>
                  ) : i === 1 ? (
                    <ul>
                      {list.map((item, i) => {
                        return <li>{item?.prd_maincat_name}</li>;
                      })}
                    </ul>
                  ) : null}
                </GnbTab>
              );
            })}
            {/* <GnbListText to={`/store/product`}>PRODUCT</GnbListText>
                    <GnbListText to={`/store/best`}>BEST</GnbListText>
                    <GnbListText to={`/store/new`}>NEW</GnbListText>
                    <GnbListText to={`/store/event`}>EVENT</GnbListText> */}
          </GnbListWrap>

          {isLogged ? (
            <div>
              <GnbListMenu as="div" onClick={() => history.push(`/store/mypage`)}>
                MYPAGE
              </GnbListMenu>
              <GnbListMenu as="div">SERVICE</GnbListMenu>
            </div>
          ) : (
            <div>
              <GnbListMenu
                as="div"
                onClick={() => history.push(`/store/login?returnTo=${location.pathname}`)}
              >
                SIGN IN
              </GnbListMenu>
              <GnbListMenu as="div" onClick={() => history.push(`/store/signup`)}>
                SIGN UP
              </GnbListMenu>
            </div>
          )}
        </GnbWrap>
        <ClosedBtn
          src={IconClosed}
          alt=""
          onClick={() => {
            setMenuOn(false);
          }}
        />
      </Container>
    </>
  );
}
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  padding-top: calc(100vw * (150 / 1920));
  width: 60%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.87);
  z-index: 500;
  overflow: hidden;

  @media screen and (max-width: 530px) {
    width: 80%;
    padding-top: 200px;
  }
`;

const GnbTab = styled.div`
  display: block;
  /* width: 200px; */
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin: 50px auto;
  cursor: pointer;

  & > ul {
    display: none;
    padding-top: 15px;
    /* justify-content: center;
        align-items: center; */
    gap: 10px;
    width: 100%;
  }
  :hover ul {
    /* display: flex; */
    display: block;
  }

  & li {
    color: #fff;
    margin-bottom: 10px;
    font-size: 20px;
  }

  @media screen and (max-width: 530px) {
    width: calc(100vw * (200 / 428));
    font-size: calc(100vw * (25 / 428));
    margin: calc(100vw * (30 / 428)) auto;
  }
`;
const HomeWrap = styled.a`
  display: block;
  width: 150px;
  margin: 0 auto;
  @media screen and (max-width: 530px) {
    width: calc(100vw * (140 / 428));
  }
`;
const Home = styled.img`
  display: block;
  width: 100%;
`;
const Bg = styled.i`
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100vh;
  z-index: 400;
  overflow: hidden;
`;
const GnbWrap = styled.div`
  position: relative;
  width: 100%;
  z-index: 10;
  margin: 0 auto;
  overflow: hidden;
`;
const GnbListWrap = styled.ul`
  width: 100%;
  padding: 20px;
  @media screen and (max-width: 530px) {
    padding: calc(100vw * (20 / 428));
  }
`;
const GnbListText = styled(Link)`
  display: block;
  width: 200px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin: 50px auto;
  cursor: pointer;
  @media screen and (max-width: 530px) {
    width: calc(100vw * (200 / 428));
    font-size: calc(100vw * (25 / 428));
    margin: calc(100vw * (30 / 428)) auto;
  }
`;
const GnbListMenu = styled(Link)`
  display: block;
  width: 200px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin: 30px auto;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  @media screen and (max-width: 530px) {
    width: calc(100vw * (200 / 428));
    font-size: calc(100vw * (25 / 428));
    margin: calc(100vw * (20 / 428)) auto;
  }
`;
const ClosedBtn = styled.img`
  position: absolute;
  top: 50px;
  right: 50px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  @media screen and (max-width: 530px) {
    top: calc(100vw * (50 / 428));
    right: calc(100vw * (50 / 428));
    width: calc(100vw * (25 / 428));
    height: calc(100vw * (25 / 428));
  }
`;
