import React, { useState, useEffect } from 'react';
import { PC, Mobile } from '../common/mediaStlye';
import serverController from '../../server/serverController';

import { Container, Layout } from './stylist.element';
import StylistSponserdHead from './StylistSponserdHead';
import StylistSponserdList from './StylistSponserdList';

const StylistSponsored = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [morePage, setMorePage] = useState(0);
    const [isLoad, setIsLoad] = useState(false);
    const [list, setList] = useState([]);
    const [moreOff, setMoreOff] = useState(true);

    useEffect(() => {
        setMorePage(0);
        setMoreOff(true);
        setIsLoad(false);
        setList([]);
        serverController.connectFetchController(`purchases?type=1&offset=0&limit=10${tabIndex ? "&prd_main_cat=" + tabIndex : ""}`, "GET", null, function (res) {
            if (res.result == 1) {
                console.log(res)
                setList(res.list)
                if (res.list.length < 10) setMoreOff(false)
            }
            setIsLoad(true);
        });
    }, [tabIndex]);

    const MoreDataClick = () => {
        setIsLoad(false);
        const tabCheck = tabIndex ?? false;
        serverController.connectFetchController(`purchases?type=1&offset=${morePage + 10}&limit=10${tabCheck ? "&prd_main_cat=" + tabIndex : ""}`, "GET", null, function (res) {
            if (res.result == 1) {
                console.log(res)
                setList([...list, ...res.list])
                if (res.list.length < 10) setMoreOff(false)
                setMorePage(morePage + 10)
            }
            setIsLoad(true);
        });
    }

    return (
        <>
            <Container>
                <Layout>
                    <StylistSponserdHead tabIndex={tabIndex} setTabIndex={setTabIndex} />
                    <StylistSponserdList list={list} MoreDataClick={MoreDataClick} moreOff={moreOff} isLoad={isLoad} />
                </Layout>
            </Container>
        </>
    )
}

export default StylistSponsored
