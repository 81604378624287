import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import InquiryDetail from "../../component/service/InquiryDetail";
import Footer from "../../component/common/StoreFooter";
import FixedTop from "../../component/common/FixedTop";
import FooterMenu from "../../component/common/FooterMenu";
import FixMenu from "../../component/common/FixMenu";

export default function PrdInquiryDetailPage() {
  return (
    <>
      <PC>
        <Header />
        <InquiryDetail title="상품문의" rank={true} type={1} />
        <Footer />
        <FixedTop />
      </PC>

      <Mobile>
        <Header />
        <InquiryDetail title="상품문의" rank={true} type={1} />
        <Footer />
        <FooterMenu />
        <FixMenu />
      </Mobile>
    </>
  );
}
