//react
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

//css
import styled from "styled-components";
//icon
import SwiperArrow from "../../../img/icon/icon_swiperArrow.svg";
import SwiperArrowL from "../../../img/icon/icon_swiperArrowL.svg";
import SwiperArrowG from "../../../img/icon/icon_swiperArrow_gray.svg";
import SwiperArrowGL from "../../../img/icon/icon_swiperArrow_grayL.svg";

//component
import ProductList from "../../../component/common/product/ProductList";
import MyPageDefaultModal from "../../common/modal/MyPageDefaultModal";

SwiperCore.use([Navigation]);
export default function WithProduct({ listWith }) {
  const history = useHistory();
  const location = useLocation();

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [buttonClick, setButtonClick] = useState(false);
  const [loginPop, setLoginPop] = useState(false);

  // button 아이콘 gray 바꿔주기
  const disablePrevButton = document.querySelector(
    "#swiper-prev-nav-newItem.swiper-button-disabled img"
  );
  const prevButton = document.querySelector("#swiper-prev-nav-newItem img");
  const disableNextButton = document.querySelector(
    "#swiper-next-nav-newItem.swiper-button-disabled img"
  );
  const nextButton = document.querySelector("#swiper-next-nav-newItem img");
  useEffect(() => {
    if (disablePrevButton) {
      disablePrevButton.setAttribute("src", SwiperArrowGL);
    } else if (prevButton !== null) {
      prevButton.setAttribute("src", SwiperArrowL);
    }
    if (disableNextButton) {
      disableNextButton.setAttribute("src", SwiperArrowG);
    } else if (nextButton !== null) {
      nextButton.setAttribute("src", SwiperArrow);
    }
    console.log(listWith.length);
  }, [buttonClick]);

  return (
    <Container>
      {loginPop && (
        <MyPageDefaultModal
          text="로그인 후 이용가능합니다."
          trueText="로그인"
          trueFunc={() => {
            if (location) return history.push(`/store/login?returnTo=${location.pathname}`);
            history.push(`/store/login`);
          }}
          falseFunc={() => setLoginPop(false)}
        />
      )}
      <PC>
        <BestNewWrap>
          <TitleWrap>
            <TitleText>다른 고객님이 함께 본 제품</TitleText>
            {listWith.length > 5 && <SwiperArrowWrap>
              <SwiperNav
                ref={prevRef}
                id="swiper-prev-nav-newItem"
                onClick={() => {
                  setButtonClick(!buttonClick);
                }}
              >
                <img src={SwiperArrowGL} alt="arrow" />
              </SwiperNav>
              <SwiperNav
                ref={nextRef}
                id="swiper-next-nav-newItem"
                onClick={() => {
                  setButtonClick(!buttonClick);
                }}
              >
                <img src={SwiperArrow} alt="arrow" />
              </SwiperNav>
            </SwiperArrowWrap>}
          </TitleWrap>

          <BestNewListWrap>
            {listWith.length > 5 &&
              <BestNewInner className="withProduct">
                {
                  <Swiper
                    slidesPerView={listWith.length <= 2 ? 2 : 5}
                    slidesPerGroup={1}
                    spaceBetween={10}
                    navigation={{
                      prevEl: prevRef.current,
                      nextEl: nextRef.current,
                    }}
                  >
                    {listWith.map((value, index) => {
                      return (
                        <SwiperSlide key={"with product" + index}>
                          <ProductList
                            key={index}
                            value={value}
                            height={"withProductImg"}
                            rank={false}
                            setLoginPop={setLoginPop}
                            square
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                }
              </BestNewInner>}
            {
              listWith.length <= 5 &&
              <BestNewInnerMax5>
                {
                  listWith.map((value, index) => {
                    return (
                      <ProductList
                        key={index}
                        value={value}
                        height={"withProductImg"}
                        rank={false}
                        setLoginPop={setLoginPop}
                        square
                      />
                    )
                  })
                }
              </BestNewInnerMax5>
            }
          </BestNewListWrap>
        </BestNewWrap>
      </PC>

      <Mobile>
        <BestNewWrap>
          <TitleWrap>
            <TitleText>다른 고객님이 함께 본 제품</TitleText>
          </TitleWrap>

          <BestNewListWrap>
            {listWith.length > 2 &&
              <BestNewInner className="withProduct">
                {
                  <Swiper slidesPerView={2} spaceBetween={10}>
                    {listWith.map((value, index) => {
                      return (
                        <SwiperSlide key={"with product" + index}>
                          <ProductList
                            key={index}
                            value={value}
                            height={"withProductImg"}
                            rank={false}
                            setLoginPop={setLoginPop}
                            square
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                }
              </BestNewInner>}
            {
              listWith.length <= 2 &&
              <BestNewInnerMax5>
                {
                  listWith.map((value, index) => {
                    return (
                      <ProductList
                        key={"with product" + index}
                        value={value}
                        height={"withProductImg"}
                        rank={false}
                        setLoginPop={setLoginPop}
                        square
                      />
                    )
                  })
                }
              </BestNewInnerMax5>
            }
          </BestNewListWrap>
        </BestNewWrap>
      </Mobile>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  padding: 53px 0 200px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (53 / 1320)) 0 calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0 calc(100vw * (200 / 428));
  }
`;
const BestNewWrap = styled.div``;
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;
  margin-bottom: 18px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    margin-bottom: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin-bottom: calc(100vw * (15 / 428));
  }
`;
const TitleText = styled.h2`
  position: relative;
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  color: #222222;
  padding: 0 10px;

  &::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 20px;
    background-color: #fdf251;
    z-index: -1;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
    padding: 0 calc(100vw * (10 / 1320));

    &::before {
      content: "";
      bottom: calc(100vw * (-1 / 1320));
      height: calc(100vw * (20 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    padding: 0 calc(100vw * (7 / 428));

    &::before {
      content: "";
      bottom: calc(100vw * (-1 / 428));
      height: calc(100vw * (12 / 428));
    }
  }
`;
const BestNewListWrap = styled.div`
  width: 100%;
`;
const BestNewInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    height: 100%;
  }
`;
const BestNewInnerMax5 = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  flex-wrap: nowrap;
  max-width: 1240px;

  @media screen and (max-width: 1320px) {
    max-width: calc(100vw * (1240 / 1320));
    /* padding: calc(100vw * (28 / 1320)); */
  }
  @media screen and (max-width: 930px) {
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding: calc(100vw * (28 / 428));
  }
  & > div{
    width: 242px;
    @media screen and (max-width: 1320px) {
      width: calc(100vw * (242 / 1320));
  }
    @media screen and (max-width: 930px) {
      width: calc(100vw * (186 / 428));
  }
  }
`;

// swiper - arrow
const SwiperArrowWrap = styled.div`
  display: flex;
  align-items: center;
`;
const SwiperNav = styled.div`
  width: 36px;
  height: 36px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (36 / 1320));
    height: calc(100vw * (36 / 1320));
  }
`;
