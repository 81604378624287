//react
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";

//css
import styled from "styled-components";

//img
import ProductImg from "../../../img/product/pay_product_img1.png";

export default function PayCancelComplete({ title, text }) {
  const history = useHistory();

  return (
    <Container>

      <Mobile>
        <CompleteWrap>
          {/* order info */}
          <OrderInfoWrap>
            <OrderImgArea>
              <img src={ProductImg} alt="product-img" />
            </OrderImgArea>
            <OrderTextArea>
              <OrderTitleBox>
                <OrderTitle>{title}</OrderTitle>
                <OrderTextBox>
                  <OrderSubText>{text}</OrderSubText>
                </OrderTextBox>
              </OrderTitleBox>
            </OrderTextArea>
            <OrderButtonArea>
              <ConfirmButton
                type="button"
                onClick={() => {
                  history.push("/store");
                }}
              >
                확인
              </ConfirmButton>
            </OrderButtonArea>
          </OrderInfoWrap>

        </CompleteWrap>
      </Mobile>
    </Container>
  );
}
const Container = styled.div``;
const CompleteWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (193 / 428));
  }
`;

// 이후 코드
// order info
const OrderInfoWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: calc(100vw * (80 / 428)) 0 calc(100vw * (40 / 428));
  }

`;
const OrderImgArea = styled.div`
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (200 / 428));
    height: calc(100vw * (180 / 428));
  }
`;
const OrderTextArea = styled.div``;
const OrderTitleBox = styled.div`

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (50 / 428));
  }
`;
const OrderTitle = styled.p`
  color: #222;
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
  }
`;
const OrderTextBox = styled.div`

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (30 / 428));
  }
`;
const OrderText = styled.p`
  color: #333;
  font-weight: 500;
  text-align: center;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const OrderSubText = styled(OrderText)`
  font-weight: 400;

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (12 / 428));
  }
`;

// 무통장 입금 시
const BankTextListBox = styled.ul`

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (50 / 428));
  }
`;
const BankTextList = styled.li`
  display: flex;
  align-items: center;

  @media screen and (max-width: 930px) {
    &:not(:last-child){
      margin-bottom: calc(100vw * (10 / 428));
    }
  }
`;
const BankTitle = styled.span`
  color: #777;
  font-weight: 400;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (96 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;
const BankText = styled.span`
  color: #333;
  font-weight: 500;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const OrderButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (50 / 428));
  }
`;
const ConfirmButton = styled.button`
  width: 100%;
  color: #fff;
  font-weight: 500;
  background-color: #000;
  border: 1px solid #000;

  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;