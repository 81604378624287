import React, { useState } from 'react';
import styled from 'styled-components';
import TermOfUseModal from '../../common/pop/TermOfUseModal';
import PrivateGuideModal from '../../common/pop/PrivateGuideModal';

import IconChk from '../../../images/icon_chk_off.svg';
import IconChkOn from '../../../images/icon_chk_on.svg';

import Modal from '../../../server/modal';

const SignUpGuideLines = ({handleTermCheck,handlePolicyCheck}) => {
    const [isOpenTerm, setIsOpenTerm] = useState(false);
    const [isOpenPrivate, setIsOpenPrivate] = useState(false);

    const handleOpenTerm = () => {setIsOpenTerm(true); Modal.modalOpen()}
    const handlePrivate = () => {setIsOpenPrivate(true); Modal.modalOpen()}

    return (
        <>
        <ChkWrap>
            <Chks>
                <ChkInput type="checkbox" name="" id="chkTerm" onChange={(e) => handleTermCheck(e.target.checked)}/>
                <ChkLabel htmlFor="chkTerm">이용약관에 동의합니다.</ChkLabel>
                <View onClick={handleOpenTerm}>(보기)</View>
            </Chks>
            <Chks>
                <ChkInput type="checkbox" name="" id="chkPolicy" onChange={(e) => handlePolicyCheck(e.target.checked)}/>
                <ChkLabel htmlFor="chkPolicy">개인정보 수집에 동의합니다.</ChkLabel>
                <View onClick={handlePrivate}>(보기)</View>
            </Chks>
        </ChkWrap>
            
            {/* <p>가입하시면 왈라의 <em onClick={handleOpenTerm}>이용약관</em>과 <em onClick={handlePrivate}>개인정보 수집</em>에 동의하게 됩니다.</p> */}
            
            { isOpenTerm && <TermOfUseModal setIsOpenTerm={setIsOpenTerm}/>}
            { isOpenPrivate && <PrivateGuideModal setIsOpenPrivate={setIsOpenPrivate}/>}
        </>  
    )
}

const ChkWrap = styled.div`
    width:100%;
    padding-bottom: calc(100vw*(25/1920));

    @media screen and (max-width: 1024px) {
        padding-bottom: calc(100vw*(25/428));
    }
`
const Chks = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(100vw*(10/1920)) 0;

    @media screen and (max-width: 1024px) {
        padding: calc(100vw*(5/428)) 0;
    }
`
const View = styled.p`
    font-size: calc(100vw*(14/1920));
    color: #A5A5A5;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        font-size: calc(100vw*(13/428));
    }
`
// checkbox
const ChkBox = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`
const ChkLabel = styled.label`
    display: flex;
    align-items: center;
    &&::before {
        content: '';
        display: block;
        width: calc(100vw * (28 / 1920));
        height: calc(100vw * (28 / 1920));
        margin-right: calc(100vw * (15 / 1920));
        text-align: center;
        background: url(${IconChk}) no-repeat center/calc(100vw * (28/1920));
    }
    @media screen and (max-width: 1024px) {
        &&::before {
            width: calc(100vw * (20 / 428));
            height: calc(100vw * (20 / 428));
            margin-right: calc(100vw * (10 / 428));
            background: url(${IconChk}) no-repeat center/calc(100vw * (20/428));
        }
    }
`
const ChkInput = styled.input`
    &:checked + label::before {
        background-image: url(${IconChkOn});
    }
`


export default SignUpGuideLines
