import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import serverController from '../../server/serverController';
import { postLikeProducts } from '../../hooks/likeHooks';
import { imgURL } from '../../server/appInfoContoller';
import styled from 'styled-components';

import {
  Layout,
  StylistSponserdWrap,
  StyleSearchList,
  StyleSponListItem,
  LikedIcon,
  Paging,
  NoneLi,
  MoreButton,
  LodingWrap,
  LodingImg
} from './stylist.element';
import StylistSearchProductItem from './StylistSearchProductItem';

import sponProductImg from '../../images/stylist/FZX699AHE6F1ENM_02_large-grey.png';
import IconLoding from '../../img/icon_loding.svg';


const StylistSearchList = ({ list, isLoad, myList, mydata }) => {
  return (
    <StyleSearchList>
      {
        list.length > 0 ?
          list.map((v, i) => {
            return <StylistSearchProductItem v={v} key={v?.purchase_id} myList={myList} mydata={mydata} />
          })
          : <NoText>
            {
              isLoad && "검색 결과가 없습니다."
            }
          </NoText>
      }
      {/* {!isLoad && <LodingImg src={IconLoding} alt="" />} */}
    </StyleSearchList>
  )
}

const NoText = styled.div`
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    color: #aaa;
    text-align: center;
    padding-top: 50px;

    @media screen and (max-width:1320px){
        font-size: calc(100vw * (15 / 1320));
        padding-top: calc(100vw * (50 / 1320));
    }
    @media screen and (max-width:930px){
        font-size: calc(100vw * (15 / 428));
        padding-top: calc(100vw * (50 / 428));
    }
`

export default StylistSearchList

