import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import { useSelector } from "react-redux";
import styled from "styled-components";

//img
import IconFileUpload from "../../img/icon/icon_upload.svg";
import IconFileUploadCancel from "../../img/icon/icon_upload_cancel.svg";
import ProductImg from "../../img/product/pay_product_img1.png";
import IconSelectArrow from "../../img/icon/icon_bottom_arrow.svg";
import IconChkOn from "../../img/icon/icon_chk_on.svg";
import IconChkOff from "../../img/icon/icon_chk_off.svg";

// component
import PayAddressModal from "../common/modal/PayAdressModal";
import MyPageBottomModal from "../../page/mypage/MyPageBottomModal";
import { InfoText } from "../login/auth.elements";
import Commas from "../../server/Commas";
import { imgURL } from "../../server/appInfoController";

// mypage bottom data
const myPageBottomTextList = [];
const myPageBottomTextListQ = [];

// option list - 반품
const optionList = [
  { title: "사유를 선택해 주세요.", value: "0" },
  { title: "상품이 필요하지 않음", value: "12" },
  { title: "다른 상품을 구매함", value: "5" },
  { title: "타 쇼핑몰에서 구매함", value: "6" },
  { title: "배송이 너무 느림", value: "7" },
  { title: "상품 배송 지연 됨", value: "8" },
];

// option list - 교환 (기존 상품의 옵션 리스트를 뿌려줘야 할 것 같다.)
const optionListQ = [
  { title: "사유를 선택해 주세요.", value: "0" },
  { title: "사이즈 교환", value: "1" },
  { title: "색상 교환", value: "2" },
];

const bankList = [
  { value: "select", title: "선택" },
  { value: "002", title: "산업은행" },
  { value: "003", title: "기업은행" },
  { value: "004", title: "KB국민은행" },
  { value: "007", title: "수협은행" },
  { value: "011", title: "NH농협은행" },
  { value: "012", title: "농.축협은행" },
  { value: "023", title: "SC제일은행" },
  { value: "027", title: "한국씨티은행" },
  { value: "088", title: "신한은행" },
  { value: "089", title: "케이뱅크" },
  { value: "090", title: "카카오뱅크" },
  { value: "031", title: "대구은행" },
  { value: "032", title: "부산은행" },
  { value: "034", title: "광주은행" },
  { value: "035", title: "제주은행" },
  { value: "037", title: "전북은행" },
  { value: "039", title: "경남은행" },
  { value: "045", title: "새마을금고" },
  { value: "048", title: "신협" },
];

const addressList = [
  "배송시 요청사항을 선택해주세요.",
  "부재시 문앞에 놓아주세요.",
  "부재시 경비실에 맡겨 주세요.",
  "부재시 전화 또는 문자 주세요.",
  "택배함에 넣어 주세요.",
  "파손 위험 상품입니다. 배송시 주의해 주세요.",
  "배송 전에 연락주세요.",
];

export default function OrderReturnExchange({ item, state }) {
  const history = useHistory();
  const permission = useSelector((state) => state.permissionData.permission.state);
  const { location } = useSelector((state) => state.paymentData);

  // modal control
  const [isModal, setIsModal] = useState(null);

  // bank
  const [inquiryText, setInquiryText] = useState("");
  const [userName, setUserName] = useState("");
  const [bankNum, setBankNum] = useState("");
  const [selectedValue, setSelectedValue] = useState("selectT선택");

  // reason value
  const [reasonSelectedValue, setReasonSelectedValue] = useState("0");

  // address
  const [selectedAddressValue, setSelectedAddressValue] =
    useState("배송시 요청사항을 선택해주세요.");
  const [postCode, setPostCode] = useState("");
  const [addressTitle, setAddressTitle] = useState("");
  const [addressName, setAddressName] = useState("");
  const [addressPhone, setAddressPhone] = useState("");
  const [address, setAddress] = useState("");
  const [addressReceiver, setAddressReceiver] = useState(0);
  const [sameAddress, setSameAddress] = useState(false);

  const [photoSection, setPhotoSection] = useState(true);

  // return check
  const [returnWay, setReturnWay] = useState(0);
  const [returnAmountCheck, setReturnAmountCheck] = useState(0);

  // imgs
  const [imageArr, setImageArr] = useState([]);
  const [previewImageArr, setPreviewImageArr] = useState([]);

  // set location data
  useEffect(() => {
    setPostCode(item?.receiver_zipcode);
    setAddressTitle(item?.receiver_title);
    setAddressName(item?.receiver_name);
    setAddressPhone(item?.receiver_phone);
    setAddress(item.receiver_address + ` ` + item.receiver_address_detail);
    setAddressReceiver(item?.main_receiver);
  }, []);

  useEffect(() => {
    if (isModal === false) {
      setAddressTitle(location?.receiver_title);
      setAddressName(location?.receiver_username);
      setAddressPhone(location?.receiver_phone);
      setAddress(location.receiver_address + ` ` + location.receiver_address_detail);
      setAddressReceiver(location?.main_receiver);
    }
  }, [location]);

  // test zone
  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "permission",
        })
      );
    }
  }, []);

  // s :: about imgs
  const uploadImage = (e) => {
    let arr = [...imageArr];
    for (var i = 0; e.target.files.length > i; i++) {
      arr.unshift(e.target.files[i]);
    }
    setImageArr(arr);
  };

  const handleImageUpload = (e) => {
    const fileArr = e.target.files;
    let fileArrUrl = [];
    let file = null;
    const imgArr = [...previewImageArr];

    for (let i = 0; i < fileArr.length; i++) {
      file = fileArr[i];

      let reader = new FileReader();
      reader.onload = () => {
        fileArrUrl[i] = reader.result;
        imgArr.unshift(fileArrUrl[i]);
        setPreviewImageArr(imgArr);
      };
      reader.readAsDataURL(file);
    }
  };
  const deleteButtonClick = (index) => {
    let imgArr = [];
    let prvArr = [];
    for (let i in previewImageArr) {
      if (i != index) {
        imgArr.push(imageArr[i]);
        prvArr.push(previewImageArr[i]);
      }
    }
    setImageArr(imgArr);
    setPreviewImageArr(prvArr);
  };
  // e :: about imgs

  useEffect(() => {
    if (!permission) {
      setPhotoSection(false);
    }
  }, [permission]);

  return (
    <Container>
      {isModal && <PayAddressModal setIsModal={setIsModal} />}
      <Wrap>
        <PC>
          <SectionLeft>
            {/* product info */}
            <SectionInfo>
              <SectionInner>
                <ProductInfoWrap>
                  <ImgBox
                    onClick={() => {
                      history.push(`/store/detail/${item.prd_id}`);
                    }}
                  >
                    <img
                      src={item.prd_thumbnail ? imgURL + item.prd_thumbnail : ProductImg}
                      alt={item.prd_thumbnail ? "product-thumbnail" : "product-default-img"}
                    />
                  </ImgBox>
                  <TextBox>
                    <TextBoxInner>
                      <ProductName
                        onClick={() => {
                          history.push(`/store/detail/${item.prd_id}`);
                        }}
                      >
                        {item.prd_name}
                      </ProductName>
                      {item?.optionInfo?.length > 0 &&
                        item?.optionInfo?.map((optionItem, idx) => {
                          return (
                            <ProductColor key={optionItem.optName + "-" + idx}>
                              {optionItem.optName + " / " + item.orderCount + "개"}
                            </ProductColor>
                          );
                        })}
                      <Price>{Commas.numberWithCommas(item?.prd_list_price)}원</Price>
                    </TextBoxInner>
                  </TextBox>
                </ProductInfoWrap>
              </SectionInner>
            </SectionInfo>

            <MyPageBottomModal
              textList={state === 0 ? myPageBottomTextList : myPageBottomTextListQ}
            />

            <BtnWrap>
              <Btn type="button">{state === 0 ? "반품" : "교환"} 요청하기</Btn>
            </BtnWrap>
          </SectionLeft>

          <SectionRight>
            {/* form */}
            <SectionReason>
              <SectionInner>
                <FormList>
                  <FormTitleWrap>
                    <ListTitle>{state === 0 ? "반품" : "교환"}사유</ListTitle>
                  </FormTitleWrap>
                  <SelectButton
                    defaultValue="0"
                    borderActive={reasonSelectedValue !== "0"}
                    onChange={(e) => {
                      setReasonSelectedValue(e.target.value);
                    }}
                  >
                    {state === 0
                      ? optionList.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.title}
                            </option>
                          );
                        })
                      : optionListQ.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.title}
                            </option>
                          );
                        })}
                  </SelectButton>
                </FormList>
                <FormList>
                  <FormTitleWrap>
                    <ListTitle>상세사유(선택)</ListTitle>
                  </FormTitleWrap>
                  <InquiryTextWrap>
                    <InquiryTextArea
                      value={inquiryText}
                      active={inquiryText.length > 0}
                      placeholder="상세 사유를 입력해 주세요."
                      onChange={(e) => {
                        setInquiryText(e.target.value);
                      }}
                    />
                  </InquiryTextWrap>
                </FormList>
              </SectionInner>
              {photoSection ? (
                <ImageWrap>
                  <FormTitleWrap>
                    <ListTitle>사진첨부하기(선택)</ListTitle>
                    <ListSubTitle>*최대3장</ListSubTitle>
                  </FormTitleWrap>
                  <ImageInner>
                    {imageArr.length < 3 && (
                      <ImageList>
                        <ImageBox>
                          <FileReviseBtnWrap>
                            <FileReviseBtn
                              type="file"
                              id="filePic"
                              accept="image/*"
                              onChange={(e) => {
                                if (e.target.files.length + imageArr.length > 3) {
                                  return alert("파일은 최대 3개까지 선택 가능합니다.");
                                }
                                handleImageUpload(e);
                                uploadImage(e);
                              }}
                            />
                            <FileBtnLabel for="filePic" />
                          </FileReviseBtnWrap>
                        </ImageBox>
                      </ImageList>
                    )}
                    {previewImageArr.map((_, index) => {
                      return (
                        <ImageList key={index + "-img"}>
                          <UploadImageBox>
                            <UploadDeleteButton
                              onClick={() => {
                                deleteButtonClick(index);
                              }}
                            />
                            <UploadImage alt="previewImg" src={previewImageArr[index]} />
                          </UploadImageBox>
                        </ImageList>
                      );
                    })}
                  </ImageInner>
                </ImageWrap>
              ) : (
                <InfoText>* 이미지 업로드는 '사진 접근 허용' 후 이용 가능합니다.</InfoText>
              )}
            </SectionReason>

            {/* 반품/교환 >> 수거방법 */}
            <Section>
              <SectionChkInner>
                <OrderTitleBox>
                  <OrderTitle>수거방법</OrderTitle>
                </OrderTitleBox>
                <OrderInfoListInner>
                  <ChkListBox>
                    <ChkList>
                      <ChkButton>
                        <ChkInput
                          name="return-way"
                          type="radio"
                          id="chk-way-1"
                          checked={returnWay === 0 ? true : false}
                          onChange={() => {
                            setReturnWay(0);
                          }}
                        />
                        <ChkLabel htmlFor="chk-way-1">
                          <ChkImg className="chk-img" />
                          <ChkText>수거해주세요</ChkText>
                        </ChkLabel>
                      </ChkButton>
                    </ChkList>
                    <ChkList>
                      <ChkButton>
                        <ChkInput
                          name="return-way"
                          type="radio"
                          id="chk-way-2"
                          checked={returnWay === 1 ? true : false}
                          onChange={() => {
                            setReturnWay(1);
                          }}
                        />
                        <ChkLabel htmlFor="chk-way-2">
                          <ChkImg className="chk-img" />
                          <ChkText>직접 보낼게요</ChkText>
                        </ChkLabel>
                      </ChkButton>
                    </ChkList>
                  </ChkListBox>
                </OrderInfoListInner>
              </SectionChkInner>
            </Section>

            {/* 반품 >> 반품 비용 */}
            {state === 0 ? (
              <>
                <Section>
                  <SectionChkInner>
                    <OrderTitleBox>
                      <OrderTitle>반품 비용</OrderTitle>
                      <OrderTitle>5,000원</OrderTitle>
                    </OrderTitleBox>
                    <OrderInfoListInner>
                      <ChkListBox>
                        <ChkList>
                          <ChkButton>
                            <ChkInput
                              name="return-amount"
                              type="radio"
                              id="chk-amount-1"
                              onChange={() => {
                                setReturnAmountCheck(0);
                              }}
                              checked={returnAmountCheck === 0 ? true : false}
                            />
                            <ChkLabel htmlFor="chk-amount-1">
                              <ChkImg className="chk-img" />
                              <ChkText>환불금에서 차감</ChkText>
                            </ChkLabel>
                          </ChkButton>
                        </ChkList>
                        <ChkList>
                          <ChkButton>
                            <ChkInput
                              name="return-amount"
                              type="radio"
                              id="chk-amount-2"
                              onChange={() => {
                                setReturnAmountCheck(1);
                              }}
                              checked={returnAmountCheck === 1 ? true : false}
                            />
                            <ChkLabel htmlFor="chk-amount-2">
                              <ChkImg className="chk-img" />
                              <ChkText>쇼핑몰 계좌로 송금</ChkText>
                            </ChkLabel>
                          </ChkButton>
                          {returnAmountCheck === 1 && (
                            <BankInfo>
                              <BankInfoListBox>
                                <BankInfoList>
                                  <BankInfoTitle>계좌</BankInfoTitle>
                                  <BankInfoText>농협 012345678901234</BankInfoText>
                                </BankInfoList>
                                <BankInfoList>
                                  <BankInfoTitle>예금주</BankInfoTitle>
                                  <BankInfoText>왈라</BankInfoText>
                                </BankInfoList>
                              </BankInfoListBox>
                            </BankInfo>
                          )}
                        </ChkList>
                      </ChkListBox>
                    </OrderInfoListInner>
                  </SectionChkInner>
                </Section>
                {/* 반품 >> 환불 예상금액 */}
                <Section>
                  <SectionInner>
                    <OrderTitleBox>
                      <OrderTitle>환불 예상금액</OrderTitle>
                    </OrderTitleBox>
                    <OrderInfoListInner>
                      <OrderInfoListBox>
                        <OrderInfoListBoxInner>
                          <OrderInfoList>
                            <OrderSubTitleBold>취소 예상 금액</OrderSubTitleBold>
                            <OrderTextBold>
                              {Commas.numberWithCommas(item.mog_dis_price)}원
                            </OrderTextBold>
                          </OrderInfoList>
                        </OrderInfoListBoxInner>
                      </OrderInfoListBox>
                      <OrderInfoListBox>
                        <OrderInfoListBoxInner>
                          <OrderInfoList>
                            <OrderSubTitle>상품 금액</OrderSubTitle>
                            <OrderText>{Commas.numberWithCommas(item.prd_list_price)}원</OrderText>
                          </OrderInfoList>
                          {item.txn_used_point !== 0 && (
                            <OrderInfoList>
                              <OrderSubTitle>포인트 사용</OrderSubTitle>
                              <OrderText>
                                (-)
                                {Commas.numberWithCommas(item.txn_used_point)}원
                              </OrderText>
                            </OrderInfoList>
                          )}
                          {item.coupon_discount !== 0 && (
                            <OrderInfoList>
                              <OrderSubTitle>쿠폰 사용</OrderSubTitle>
                              <OrderText>
                                (-)
                                {Commas.numberWithCommas(item.coupon_discount)}원
                              </OrderText>
                            </OrderInfoList>
                          )}
                        </OrderInfoListBoxInner>
                      </OrderInfoListBox>
                    </OrderInfoListInner>
                  </SectionInner>
                </Section>

                {/* 반품 >> 환불받을 계좌 */}
                <Section>
                  <SectionInner>
                    <OrderTitleBox>
                      <OrderTitle>환불받을 계좌</OrderTitle>
                    </OrderTitleBox>
                    <OrderInfoListInner>
                      <BankListBox>
                        <BankList>
                          <InputText>예금주</InputText>
                          <Input
                            type="text"
                            value={userName}
                            active={userName.length > 0}
                            placeholder="예금주명을 입력해주세요."
                            onChange={(e) => {
                              setUserName(e.target.value);
                            }}
                          />
                        </BankList>
                        <BankList>
                          <InputText>은행</InputText>
                          <SelectButton
                            defaultValue="select"
                            borderActive={selectedValue !== "selectT선택"}
                            onChange={(e) => {
                              setSelectedValue(e.target.value);
                            }}
                          >
                            {bankList.map((item) => {
                              return (
                                <option key={item.value} value={item.value + "T" + item.title}>
                                  {item.title}
                                </option>
                              );
                            })}
                          </SelectButton>
                        </BankList>
                        <BankList>
                          <InputText>계좌번호</InputText>
                          <Input
                            type="number"
                            value={bankNum}
                            active={bankNum.length > 0}
                            placeholder="계좌번호를 입력해주세요."
                            onChange={(e) => {
                              if (e.target.value.length > 20) return;
                              setBankNum(e.target.value);
                            }}
                          />
                        </BankList>
                      </BankListBox>
                    </OrderInfoListInner>
                  </SectionInner>
                </Section>
              </>
            ) : (
              <>
                {/* 교환 >> 교환 비용 */}
                <Section>
                  <SectionChkInner>
                    <OrderTitleBox>
                      <OrderTitle>교환 비용</OrderTitle>
                      <OrderTitle>5,000원</OrderTitle>
                    </OrderTitleBox>
                    <OrderInfoListInner>
                      <ChkListBox>
                        <ChkList>
                          <ChkButton>
                            <ChkInput
                              name="exchange-amount"
                              type="radio"
                              id="chk-exchange-1"
                              onChange={() => {
                                setReturnAmountCheck(0);
                              }}
                              checked={returnAmountCheck === 0 ? true : false}
                            />
                            <ChkLabel htmlFor="chk-exchange-1">
                              <ChkImg className="chk-img" />
                              <ChkText>상품과 함께 동봉</ChkText>
                            </ChkLabel>
                          </ChkButton>
                        </ChkList>
                        <ChkList>
                          <ChkButton>
                            <ChkInput
                              name="exchange-amount"
                              type="radio"
                              id="chk-exchange-2"
                              onChange={() => {
                                setReturnAmountCheck(1);
                              }}
                              checked={returnAmountCheck === 1 ? true : false}
                            />
                            <ChkLabel htmlFor="chk-exchange-2">
                              <ChkImg className="chk-img" />
                              <ChkText>쇼핑몰 계좌로 송금</ChkText>
                            </ChkLabel>
                          </ChkButton>
                          {returnAmountCheck === 1 && (
                            <BankInfo>
                              <BankInfoListBox>
                                <BankInfoList>
                                  <BankInfoTitle>계좌</BankInfoTitle>
                                  <BankInfoText>농협 012345678901234</BankInfoText>
                                </BankInfoList>
                                <BankInfoList>
                                  <BankInfoTitle>예금주</BankInfoTitle>
                                  <BankInfoText>왈라</BankInfoText>
                                </BankInfoList>
                              </BankInfoListBox>
                            </BankInfo>
                          )}
                        </ChkList>
                      </ChkListBox>
                    </OrderInfoListInner>
                  </SectionChkInner>
                </Section>

                {/* 교환 >> 배송지 정보 */}
                <SectionAddressInfo>
                  <SectionChkInner>
                    <OrderTitleBox>
                      <OrderTitle>배송지 정보</OrderTitle>
                      <AddressChangeButton
                        type="button"
                        onClick={() => {
                          setIsModal(true);
                          setSameAddress(false);
                        }}
                      >
                        변경
                      </AddressChangeButton>
                    </OrderTitleBox>
                    <OrderInfoListInner>
                      <AddressInfoBox>
                        <AddressInfo>
                          <AddressTitleWrap>
                            <AddressTitle>우리집</AddressTitle>
                            <AddressDefault>기본배송지</AddressDefault>
                          </AddressTitleWrap>
                          <AddressText>
                            [06159] 서울 강남구 테헤란로 425(삼성동, 신일빌딩), 신일빌딩 11층
                          </AddressText>
                          <AddressName>김왈라 01012345678</AddressName>
                        </AddressInfo>
                        <SelectButton
                          borderActive={selectedAddressValue !== "배송시 요청사항을 선택해주세요."}
                          onChange={(e) => {
                            setSelectedAddressValue(e.target.value);
                          }}
                        >
                          {addressList.map((item) => {
                            return (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </SelectButton>
                      </AddressInfoBox>
                    </OrderInfoListInner>
                  </SectionChkInner>
                </SectionAddressInfo>
              </>
            )}
          </SectionRight>
        </PC>
        <Mobile>
          {/* product info */}
          <Section>
            <SectionInner>
              <ProductInfoWrap>
                <ImgBox
                  onClick={() => {
                    history.push(`/store/detail/${item.prd_id}`);
                  }}
                >
                  <img
                    src={item.prd_thumbnail ? imgURL + item.prd_thumbnail : ProductImg}
                    alt={item.prd_thumbnail ? "product-thumbnail" : "product-default-img"}
                  />
                </ImgBox>
                <TextBox>
                  <TextBoxInner>
                    <ProductName
                      onClick={() => {
                        history.push(`/store/detail/${item.prd_id}`);
                      }}
                    >
                      {item.prd_name}
                    </ProductName>
                    {item?.optionInfo?.length > 0 &&
                      item?.optionInfo?.map((optionItem, idx) => {
                        return (
                          <ProductColor key={optionItem.optName + "-" + idx}>
                            {optionItem.optName + " / " + item.orderCount + "개"}
                          </ProductColor>
                        );
                      })}
                    <Price> {Commas.numberWithCommas(item?.prd_list_price)}원</Price>
                  </TextBoxInner>
                </TextBox>
              </ProductInfoWrap>
            </SectionInner>
          </Section>

          {/* form */}
          <Section>
            <SectionInner>
              <FormList>
                <SelectButton
                  defaultValue="0"
                  borderActive={reasonSelectedValue !== "0"}
                  onChange={(e) => {
                    setReasonSelectedValue(e.target.value);
                  }}
                >
                  {state === 0
                    ? optionList.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.title}
                          </option>
                        );
                      })
                    : optionListQ.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.title}
                          </option>
                        );
                      })}
                </SelectButton>
              </FormList>
              <FormList>
                <InquiryTextWrap>
                  <InquiryTextArea
                    value={inquiryText}
                    active={inquiryText.length > 0}
                    placeholder="상세 사유를 입력해 주세요."
                    onChange={(e) => {
                      setInquiryText(e.target.value);
                    }}
                  />
                </InquiryTextWrap>
              </FormList>
            </SectionInner>
            {photoSection ? (
              <ImageWrap>
                <FormTitleWrap>
                  <ListTitle>사진첨부하기(선택)</ListTitle>
                  <ListSubTitle>*최대3장</ListSubTitle>
                </FormTitleWrap>
                <ImageInner>
                  {imageArr.length < 3 && (
                    <ImageList>
                      <ImageBox>
                        <FileReviseBtnWrap>
                          <FileReviseBtn
                            type="file"
                            id="filePic"
                            accept="image/*"
                            onChange={(e) => {
                              if (e.target.files.length + imageArr.length > 3) {
                                return alert("파일은 최대 3개까지 선택 가능합니다.");
                              }
                              handleImageUpload(e);
                              uploadImage(e);
                            }}
                          />
                          <FileBtnLabel for="filePic" />
                        </FileReviseBtnWrap>
                      </ImageBox>
                    </ImageList>
                  )}
                  {previewImageArr.map((_, index) => {
                    return (
                      <ImageList key={index + "-img"}>
                        <UploadImageBox>
                          <UploadDeleteButton
                            onClick={() => {
                              deleteButtonClick(index);
                            }}
                          />
                          <UploadImage alt="previewImg" src={previewImageArr[index]} />
                        </UploadImageBox>
                      </ImageList>
                    );
                  })}
                </ImageInner>
              </ImageWrap>
            ) : (
              <InfoText>* 이미지 업로드는 '사진 접근 허용' 후 이용 가능합니다.</InfoText>
            )}
          </Section>

          {/* 반품/교환 >> 수거방법 */}
          <Section>
            <SectionChkInner>
              <OrderTitleBox>
                <OrderTitle>수거방법</OrderTitle>
              </OrderTitleBox>
              <OrderInfoListInner>
                <ChkListBox>
                  <ChkList>
                    <ChkButton>
                      <ChkInput
                        name="return-way"
                        type="radio"
                        id="chk-way-1"
                        checked={returnWay === 0 ? true : false}
                        onChange={() => {
                          setReturnWay(0);
                        }}
                      />
                      <ChkLabel htmlFor="chk-way-1">
                        <ChkImg className="chk-img" />
                        <ChkText>수거해주세요</ChkText>
                      </ChkLabel>
                    </ChkButton>
                  </ChkList>
                  <ChkList>
                    <ChkButton>
                      <ChkInput
                        name="return-way"
                        type="radio"
                        id="chk-way-2"
                        checked={returnWay === 1 ? true : false}
                        onChange={() => {
                          setReturnWay(1);
                        }}
                      />
                      <ChkLabel htmlFor="chk-way-2">
                        <ChkImg className="chk-img" />
                        <ChkText>직접 보낼게요</ChkText>
                      </ChkLabel>
                    </ChkButton>
                  </ChkList>
                </ChkListBox>
              </OrderInfoListInner>
            </SectionChkInner>
          </Section>

          {state === 0 ? (
            <>
              {/* 반품 >> 반품 비용 */}
              <Section>
                <SectionChkInner>
                  <OrderTitleBox>
                    <OrderTitle>반품 비용</OrderTitle>
                    <OrderTitle>5,000원</OrderTitle>
                  </OrderTitleBox>
                  <OrderInfoListInner>
                    <ChkListBox>
                      <ChkList>
                        <ChkButton>
                          <ChkInput
                            name="return-amount"
                            type="radio"
                            id="chk-amount-1"
                            onChange={() => {
                              setReturnAmountCheck(0);
                            }}
                            checked={returnAmountCheck === 0 ? true : false}
                          />
                          <ChkLabel htmlFor="chk-amount-1">
                            <ChkImg className="chk-img" />
                            <ChkText>환불금에서 차감</ChkText>
                          </ChkLabel>
                        </ChkButton>
                      </ChkList>
                      <ChkList>
                        <ChkButton>
                          <ChkInput
                            name="return-amount"
                            type="radio"
                            id="chk-amount-2"
                            onChange={() => {
                              setReturnAmountCheck(1);
                            }}
                            checked={returnAmountCheck === 1 ? true : false}
                          />
                          <ChkLabel htmlFor="chk-amount-2">
                            <ChkImg className="chk-img" />
                            <ChkText>쇼핑몰 계좌로 송금</ChkText>
                          </ChkLabel>
                        </ChkButton>
                        {returnAmountCheck === 1 && (
                          <BankInfo>
                            <BankInfoListBox>
                              <BankInfoList>
                                <BankInfoTitle>계좌</BankInfoTitle>
                                <BankInfoText>농협 012345678901234</BankInfoText>
                              </BankInfoList>
                              <BankInfoList>
                                <BankInfoTitle>예금주</BankInfoTitle>
                                <BankInfoText>왈라</BankInfoText>
                              </BankInfoList>
                            </BankInfoListBox>
                          </BankInfo>
                        )}
                      </ChkList>
                    </ChkListBox>
                  </OrderInfoListInner>
                </SectionChkInner>
              </Section>

              {/* 반품 >> 환불 예상금액 */}
              <Section>
                <SectionInner>
                  <OrderTitleBox>
                    <OrderTitle>환불 예상금액</OrderTitle>
                  </OrderTitleBox>
                  <OrderInfoListInner>
                    <OrderInfoListBox>
                      <OrderInfoListBoxInner>
                        <OrderInfoList>
                          <OrderSubTitleBold>취소 예상 금액</OrderSubTitleBold>
                          <OrderTextBold>
                            {" "}
                            {Commas.numberWithCommas(item.mog_dis_price)}원
                          </OrderTextBold>
                        </OrderInfoList>
                      </OrderInfoListBoxInner>
                    </OrderInfoListBox>
                    <OrderInfoListBox>
                      <OrderInfoListBoxInner>
                        <OrderInfoList>
                          <OrderSubTitle>상품 금액</OrderSubTitle>
                          <OrderText> {Commas.numberWithCommas(item.prd_list_price)}원</OrderText>
                        </OrderInfoList>
                        {item.txn_used_point !== 0 && (
                          <OrderInfoList>
                            <OrderSubTitle>포인트 사용</OrderSubTitle>
                            <OrderText>
                              (-)
                              {Commas.numberWithCommas(item.txn_used_point)}원
                            </OrderText>
                          </OrderInfoList>
                        )}
                        {item.coupon_discount !== 0 && (
                          <OrderInfoList>
                            <OrderSubTitle>쿠폰 사용</OrderSubTitle>
                            <OrderText>
                              (-)
                              {Commas.numberWithCommas(item.coupon_discount)}원
                            </OrderText>
                          </OrderInfoList>
                        )}
                      </OrderInfoListBoxInner>
                    </OrderInfoListBox>
                  </OrderInfoListInner>
                </SectionInner>
              </Section>

              {/* 반품 >> 환불받을 계좌 */}
              <Section>
                <SectionInner>
                  <OrderTitleBox>
                    <OrderTitle>환불받을 계좌</OrderTitle>
                  </OrderTitleBox>
                  <OrderInfoListInner>
                    <BankListBox>
                      <BankList>
                        <InputText>예금주</InputText>
                        <Input
                          type="text"
                          value={userName}
                          active={userName.length > 0}
                          placeholder="예금주명을 입력해주세요."
                          onChange={(e) => {
                            setUserName(e.target.value);
                          }}
                        />
                      </BankList>
                      <BankList>
                        <InputText>은행</InputText>
                        <SelectButton
                          defaultValue="select"
                          borderActive={selectedValue !== "selectT선택"}
                          onChange={(e) => {
                            setSelectedValue(e.target.value);
                          }}
                        >
                          {bankList.map((item) => {
                            return (
                              <option key={item.value} value={item.value + "T" + item.title}>
                                {item.title}
                              </option>
                            );
                          })}
                        </SelectButton>
                      </BankList>
                      <BankList>
                        <InputText>계좌번호</InputText>
                        <Input
                          type="number"
                          value={bankNum}
                          active={bankNum.length > 0}
                          placeholder="계좌번호를 입력해주세요."
                          onChange={(e) => {
                            if (e.target.value.length > 20) return;
                            setBankNum(e.target.value);
                          }}
                        />
                      </BankList>
                    </BankListBox>
                  </OrderInfoListInner>
                </SectionInner>
              </Section>
            </>
          ) : (
            <>
              {/* 교환 >> 교환 비용 */}
              <Section>
                <SectionChkInner>
                  <OrderTitleBox>
                    <OrderTitle>교환 비용</OrderTitle>
                    <OrderTitle>5,000원</OrderTitle>
                  </OrderTitleBox>
                  <OrderInfoListInner>
                    <ChkListBox>
                      <ChkList>
                        <ChkButton>
                          <ChkInput
                            name="exchange-amount"
                            type="radio"
                            id="chk-exchange-1"
                            onChange={() => {
                              setReturnAmountCheck(0);
                            }}
                            checked={returnAmountCheck === 0 ? true : false}
                          />
                          <ChkLabel htmlFor="chk-exchange-1">
                            <ChkImg className="chk-img" />
                            <ChkText>상품과 함께 동봉</ChkText>
                          </ChkLabel>
                        </ChkButton>
                      </ChkList>
                      <ChkList>
                        <ChkButton>
                          <ChkInput
                            name="exchange-amount"
                            type="radio"
                            id="chk-exchange-2"
                            onChange={() => {
                              setReturnAmountCheck(1);
                            }}
                            checked={returnAmountCheck === 1 ? true : false}
                          />
                          <ChkLabel htmlFor="chk-exchange-2">
                            <ChkImg className="chk-img" />
                            <ChkText>쇼핑몰 계좌로 송금</ChkText>
                          </ChkLabel>
                        </ChkButton>
                        {/* TODO PUB :: 계좌 정보 - 쇼핑몰 계좌로 송금 선택시, 보이게 부탁드립니다. */}
                        {returnAmountCheck === 1 && (
                          <BankInfo>
                            <BankInfoListBox>
                              <BankInfoList>
                                <BankInfoTitle>계좌</BankInfoTitle>
                                <BankInfoText>농협 012345678901234</BankInfoText>
                              </BankInfoList>
                              <BankInfoList>
                                <BankInfoTitle>예금주</BankInfoTitle>
                                <BankInfoText>왈라</BankInfoText>
                              </BankInfoList>
                            </BankInfoListBox>
                          </BankInfo>
                        )}
                      </ChkList>
                    </ChkListBox>
                  </OrderInfoListInner>
                </SectionChkInner>
              </Section>

              {/* 교환 >> 배송지 정보 */}
              <Section>
                <SectionChkInner>
                  <OrderTitleBox>
                    <OrderTitle>배송지 정보</OrderTitle>
                    <AddressChangeButton
                      type="button"
                      onClick={() => {
                        setIsModal(true);
                        setSameAddress(false);
                      }}
                    >
                      변경
                    </AddressChangeButton>
                  </OrderTitleBox>
                  <OrderInfoListInner>
                    <AddressInfoBox>
                      <AddressInfo>
                        <AddressTitleWrap>
                          <AddressTitle>{addressTitle}</AddressTitle>
                          {addressReceiver === 1 && <AddressDefault>기본배송지</AddressDefault>}
                        </AddressTitleWrap>
                        <AddressText>
                          {postCode && [{ postCode }]} {address}
                        </AddressText>
                        <AddressName>{addressName + " " + addressPhone}</AddressName>
                      </AddressInfo>
                      <SelectButton
                        borderActive={selectedAddressValue !== "배송시 요청사항을 선택해주세요."}
                        onChange={(e) => {
                          setSelectedAddressValue(e.target.value);
                        }}
                      >
                        {addressList.map((item) => {
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </SelectButton>
                    </AddressInfoBox>
                  </OrderInfoListInner>
                </SectionChkInner>
              </Section>
            </>
          )}
        </Mobile>
      </Wrap>

      <Mobile>
        <BtnWrap>
          <Btn type="button">{state === 0 ? "반품" : "교환"} 요청하기</Btn>
        </BtnWrap>
      </Mobile>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 0 30px;

  @media screen and (max-width: 1320px) {
    padding: 0 0 calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: 0 0 calc(100vw * (80 / 428));
  }
`;
const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1190px;
  height: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1190 / 1320));
  }
  @media screen and (max-width: 930px) {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const SectionLeft = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 428px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (428 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const SectionRight = styled.div`
  width: 742px;
  height: 100%;
  overflow: auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (742 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    height: auto;
  }
`;

const Section = styled.section`
  padding: 30px 0;
  &:not(:last-child) {
    border-bottom: 10px solid #f9f9f9;
  }

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const SectionInfo = styled.section`
  &:not(:last-child) {
    border-bottom: 10px solid #f9f9f9;
  }
`;
const SectionReason = styled.section`
  padding-bottom: 30px;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-bottom: 0;
  }
`;
const SectionAddressInfo = styled.section`
  padding-top: 30px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-top: 0;
  }
`;

const SectionInner = styled.div`
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const SectionChkInner = styled.div``;

const OrderTitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const OrderTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const OrderInfoListInner = styled.div`
  margin-top: 20px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;

// product info
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px 20px;

  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (10 / 1320)) calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;

const ImgBox = styled.div`
  width: 70px;
  height: 70px;
  background-color: #fff;
  border: 1px solid #eee;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (70 / 1320));
    height: calc(100vw * (70 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    height: calc(100vw * (100 / 428));
  }
`;
const TextBox = styled.div`
  width: calc(100% - 80px);
  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (80 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (266 / 428));
  }
`;
const TextBoxInner = styled.div``;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProductColor = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (4 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (4 / 428));
  }
`;
const Price = styled.p`
  color: #333;
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;

// form
const FormList = styled.div`
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (30 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 428));
    }
  }
`;

const FormTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;

  @media screen and (max-width: 1320px) {
    margin: 0 0 calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto calc(100vw * (8 / 428));
  }
`;
const ListTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const ListSubTitle = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;

// select
const SelectButton = styled.select`
  display: block;
  width: 100%;
  height: 50px;
  color: #acacac;
  font-weight: 400;
  font-size: 15px;
  background: #ffffff url(${IconSelectArrow}) no-repeat right 15px center / 24px;
  border: 1px solid #ddd;
  padding: 0 15px;
  border-radius: 0;

  -webkit-appearance: none; /* 네이티브 외형 감추기*/
  -moz-appearance: none;
  appearance: none;
  font-weight: normal;
  letter-spacing: normal;
  cursor: pointer;

  &.focus {
    color: #333;
    border: 1px solid #000;
  }

  ${({ borderActive }) =>
    borderActive &&
    `border: 1px solid #000;
  color: #333;
  `}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
    font-size: calc(100vw * (15 / 1320));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 1320)) center /
      calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 428)) center /
      calc(100vw * (24 / 428));
  }
`;
// input
const Input = styled.input`
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;
// textarea
const InquiryTextWrap = styled.div``;
const InquiryTextArea = styled.textarea`
  width: 100%;
  height: 198px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (198 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (200 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (15 / 428)) calc(100vw * (20 / 428));
  }
`;

// image upload
const ImageWrap = styled.div`
  margin-top: 30px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const ImageInner = styled.ul`
  display: flex;

  white-space: nowrap;
  overflow-y: visible;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  @media screen and (max-width: 930px) {
    padding-left: calc(100vw * (25 / 428));
  }
`;
const ImageList = styled.li`
  &:not(:last-child) {
    padding-right: 10px;
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      padding-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      padding-right: calc(100vw * (10 / 428));
    }
  }
`;
const ImageBox = styled.div``;

const UploadImageBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  background-color: #fff;
  border: 1px solid #eee;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    height: calc(100vw * (140 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (140 / 428));
    height: calc(100vw * (140 / 428));
  }
`;
const UploadImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const UploadDeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 28px;
  height: 28px;
  background: url(${IconFileUploadCancel}) no-repeat center / contain;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (28 / 1320));
    height: calc(100vw * (28 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (28 / 428));
    height: calc(100vw * (28 / 428));
  }
`;

// file upload
const FileReviseBtnWrap = styled.div``;
const FileReviseBtn = styled.input`
  display: none;
`;
const FileBtnLabel = styled.label`
  display: block;
  width: 140px;
  height: 140px;
  background: url(${IconFileUpload}) no-repeat center / contain;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    height: calc(100vw * (140 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (140 / 428));
    height: calc(100vw * (140 / 428));
  }
`;

// 취소 정보
const OrderInfoListBox = styled.div`
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eee;
  }
  &:not(:last-child)::after {
    margin: 15px 0;
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (15 / 1320)) 0;
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 428)) 0;
    }
  }
`;
const OrderInfoListBoxInner = styled.ul`
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000;
  }

  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 428)) 0;
    }
  }
`;
const OrderInfoList = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (12 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (12 / 428));
    }
  }
`;
const OrderSubTitle = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const OrderText = styled(OrderSubTitle)`
  color: #333;
`;
const OrderSubTitleBold = styled(OrderSubTitle)`
  color: #333;
  font-weight: 500;
`;
const OrderTextBold = styled(OrderText)`
  font-weight: 600;
`;

// input check
const ChkListBox = styled.ul``;
const ChkList = styled.li`
  &:not(:last-child) {
    margin-bottom: 25px;
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (25 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (25 / 428));
    }
  }
`;
const ChkButton = styled.div`
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const ChkInput = styled.input`
  display: none;

  &:checked + label .chk-img {
    background: url(${IconChkOn}) no-repeat center / contain;
  }
`;
const ChkLabel = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;
const ChkImg = styled.div`
  width: 24px;
  height: 24px;
  background: url(${IconChkOff}) no-repeat center / contain;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const ChkText = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (10 / 428));
  }
`;

const BankInfo = styled.div`
  width: 378px;
  background-color: #f9f9f9;
  padding: 15px 0;
  margin-top: 18px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
    padding: calc(100vw * (15 / 1320)) 0;
    margin-top: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    padding: calc(100vw * (15 / 428)) 0;
    margin-top: calc(100vw * (15 / 428));
  }
`;
const BankInfoListBox = styled.ul`
  width: 258px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (258 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (308 / 428));
  }
`;
const BankInfoList = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 428));
    }
  }
`;
const BankInfoTitle = styled.p`
  width: 60px;
  color: #999;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (60 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (60 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;
const BankInfoText = styled(BankInfoTitle)`
  width: calc(100% - 60px);
  color: #333;

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (60 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (60 / 428)));
  }
`;

// 환불받을 계좌
const BankListBox = styled.ul``;
const BankList = styled.li`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 428));
    }
  }
`;
const InputText = styled.p`
  width: 100%;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 8px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-bottom: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-bottom: calc(100vw * (8 / 428));
  }
`;

// 배송지 정보
const AddressChangeButton = styled.button`
  color: #777;
  font-weight: 500;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const AddressInfoBox = styled.div`
  margin: 20px 0 0;
  @media screen and (max-width: 1320px) {
    margin: calc(100vw * (20 / 1320)) 0 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (20 / 428)) auto 0;
  }
`;
const AddressInfo = styled.div`
  margin-bottom: 20px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (20 / 428));
  }
`;
const AddressTitleWrap = styled.p`
  display: flex;
  align-items: center;
`;
const AddressTitle = styled.span`
  max-width: calc(100% - 92px);
  color: #333;
  font-weight: 600;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    max-width: calc(100% - calc(100vw * (92 / 1320)));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    max-width: calc(100% - calc(100vw * (92 / 428)));
    font-size: calc(100vw * (16 / 428));
  }
`;
const AddressDefault = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82px;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  background-color: #fdf251;
  border-radius: 14px;
  padding: 5px 0;
  margin-left: 10px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (82 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (5 / 1320)) 0;
    margin-left: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (82 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (5 / 428)) 0;
    margin-left: calc(100vw * (10 / 428));
  }
`;
const AddressText = styled.p`
  line-height: 1.5;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  margin-top: 8px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (8 / 428));
  }
`;
const AddressName = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 15px;
  margin-top: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (6 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (6 / 428));
  }
`;

// button
const BtnWrap = styled.div`
  width: 100%;
  flex-shrink: 0;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (50 / 428)) auto 0;
  }
`;
const Btn = styled.button`
  width: 100%;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
