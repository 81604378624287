//react
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { Mobile } from "../../../MediaQuery";

// component
import BestItemProductList from "../main/BestItemProductList";
import BrandListContents from "../brand/BrandListContents";
import ContentsList from "../contents/ContentsList";
import NewPagination from "../common/pagination/NewPagination";
import FooterMenu from "../common/FooterMenu";

// controller
import serverController from "../../server/serverController";

//css
import styled from "styled-components";

import IconSelectArrow from "../../img/icon/icon_bottom_arrow.svg";
import IconClose from "../../img/icon/icon_popup_close.svg";
import Loading from "../common/modal/Loading";

import { debounce } from "lodash";

const body = document.querySelector("body");

const filterSItems = ["전체", "5만원 이하", "5만원 ~ 10만원", "10만원 ~ 20만원", "20만원 이상"];

const filterFItems = ["최신순", "인기순", "가격 낮은순", "가격 높은순"];

export default function StoreSearchResult(props) {
  const history = useHistory();
  const { contentsDetail, setContentsDetail, setLoginPop } = props;
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const [resultPrdList, setResultPrdList] = useState([]);
  const [hide, setHide] = useState(false);

  const [filterShowF, setFilterShowF] = useState(false);
  const [filterShowS, setFilterShowS] = useState(false);
  const [brandsList, setBrandsList] = useState([]);

  const [page, setPage] = useState(query.page ?? 1);
  const [pageData, setPageData] = useState([]);

  // for fetch loading
  const [isBrandFetchLoading, setBrandFetchLoading] = useState(true);

  // filter
  const [clickS, setClickS] = useState(0);
  const [clickF, setClickF] = useState(0);

  // for product infinite scroll
  // let isLoad = false;

  // for brand infinite scroll
  const pageRef = useRef(1);
  let isBrandLoad = false;
  const [isBrandAllLoaded, setIsBrandAllLoaded] = useState(false);

  // const getResultPrdList = debounce((clickS = 0, clickF = 0) => {
  //   try {
  //     serverController.connectFetchController(
  //       `products?&page=${query.page}&limit=12&keyword=${query.searchKeyword}&filter=${clickF}&range=${clickS}`,
  //       "GET",
  //       null,
  //       (res) => {
  //         if (res?.result !== 1) return;
  //         setPageData(res.pagination);
  //         setResultPrdList(res.list);
  //       }
  //     );
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, 300);


  const getResultPrdList = (S = clickS, F = clickF, filterClick = false) => {
    try {
      serverController.connectFetchController(
        // `products?prd_type=1&offset=${filterClick ? 0 : resultPrdList.length}&limit=9&keyword=${query.keyword
        // }&filter=${F}&range=${S}`,
        `products?&page=${page}&limit=10&keyword=${query.keyword}&filter=${F}&range=${S}`,
        "GET",
        null,
        (res) => {
          if (res?.result !== 1) return;
          setPageData(res.pagination);
          setResultPrdList(res.list);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  // get brand list
  const getBrandsList = () => {
    try {
      serverController.connectFetchController(
        `brand/list?keyword=${query.keyword}&page=${pageRef.current}`,
        "GET",
        null,
        (res) => {
          setBrandFetchLoading(false);
          if (res?.result === 1) {
            if (pageRef.current === 1) {
              setBrandsList(res?.data);
            } else {
              setBrandsList((prev) => prev.concat(res?.data));
            }
          }
          if (res.data.length === 0) {
            setIsBrandAllLoaded(true);
          } else {
            isBrandLoad = false;
          }
        }
      );
    } catch (e) {
      setBrandFetchLoading(false);
      alert("일시적인 오류입니다. 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    console.log(query);
  }, []);

  useEffect(() => {
    if (!query.keyword) return;
    if (query.keyword.length > 0) {
      getResultPrdList();
      getBrandsList();
    }
  }, [query.keyword]);

  useEffect(() => {
    if (filterShowF) {
      body.style.overflow = "hidden";
      body.style.height = "100vh";
    } else {
      body.style.overflow = "auto";
      body.style.height = "auto";
    }
  }, [filterShowF]);

  useEffect(() => {
    if (filterShowS) {
      body.style.overflow = "hidden";
      body.style.height = "100vh";
    } else {
      body.style.overflow = "auto";
      body.style.height = "auto";
    }
  }, [filterShowS]);

  // product infinite scroll check
  // const onScrollProductListHeight = (e) => {
  //   if (isLoad) return;

  //   const { scrollHeight, offsetHeight, scrollTop } = e.target;
  //   const digitBottom = 1000;

  //   const isBottom = scrollHeight <= offsetHeight + scrollTop + digitBottom;
  //   if (isBottom) {
  //     isLoad = true;
  //     getResultPrdList();
  //   }
  // };

  // brand infinite scroll check
  const onScrollBrandListHeight = (e) => {
    if (isBrandLoad || isBrandAllLoaded) return;
    const { scrollHeight, offsetHeight, scrollTop } = e.target;
    const digitBottom = 1500;

    const isBottom = scrollHeight <= offsetHeight + scrollTop + digitBottom;
    if (isBottom) {
      isBrandLoad = true;
      pageRef.current += 1;
      getBrandsList();
    }
  };

  // // header scroll control
  useEffect(() => {
    const infiniteWrap = document.querySelector("#infinite-scroll-wrap");
    // s :: scroll down fix
    let lastScrollY = 0;

    infiniteWrap?.addEventListener("scroll", (e) => {
      const scrollY = infiniteWrap?.scrollTop;
      const direction = scrollY > lastScrollY ? true : false;

      lastScrollY = scrollY;

      if (lastScrollY > 130) setHide(direction);
    });
    // e :: scroll down fix

    // s :: brand scroll check
    const watch = () => {
      window.addEventListener("scroll", onScrollBrandListHeight);
    };
    watch();
    getBrandsList(1);
    return () => {
      window.removeEventListener("scroll", onScrollBrandListHeight);
    };
    // e :: brand scroll check
  }, []);

  useEffect(() => {
    setHide(false);
  }, [query.index]);

  useEffect(() => {
    setPage(query.page);
  }, [query.page]);

  useEffect(() => {
    if (query.index == 0 && page != query.page) {
      history.push(
        `/store/search?keyword=${query.keyword}&index=${query.index}&page=${page}`
      )
    };
    getResultPrdList();
  }, [page]);

  return (
    <Container
      id={query.index !== "2" && "infinite-scroll-wrap"}
    // onScroll={query.index === "0" ? onScrollProductListHeight : onScrollBrandListHeight}
    >
      <Mobile>
        {filterShowF && (
          <>
            <ModalBg
              onClick={() => {
                setFilterShowF(false);
              }}
            />
            <FilterListModal>
              <ModalWrap>
                <ModalUl>
                  {filterFItems.map((itemF, idx) => {
                    return (
                      <ModalLi
                        key={itemF}
                        active={clickF === idx}
                        onClick={() => {
                          setClickF(idx);
                          getResultPrdList(clickS, idx, true);
                        }}
                      >
                        <span>{itemF}</span>
                      </ModalLi>
                    );
                  })}
                </ModalUl>
                <ModalCloseButton onClick={() => setFilterShowF(false)}>닫기</ModalCloseButton>
              </ModalWrap>
            </FilterListModal>
          </>
        )}
        {filterShowS && (
          <>
            <ModalBg onClick={() => setFilterShowS(false)} />
            <FilterListModal>
              <ModalWrap className="modal-wrap">
                <ModalUl>
                  {filterSItems.map((itemS, idx) => {
                    return (
                      <ModalLi
                        key={itemS}
                        active={clickS === idx}
                        onClick={() => {
                          setClickS(idx);
                          getResultPrdList(idx, clickF, true);
                        }}
                      >
                        <span>{itemS}</span>
                      </ModalLi>
                    );
                  })}
                </ModalUl>
                <ModalCloseButton
                  onClick={() => {
                    setFilterShowS(false);
                  }}
                >
                  닫기
                </ModalCloseButton>
              </ModalWrap>
            </FilterListModal>
          </>
        )}
        <StoreSearchResultWrap>
          {query.index === "0" && (
            <AllBrandFilter hide={hide}>
              <AllBrandButton
                onClick={() => {
                  setFilterShowF(!filterShowF);
                }}
              >
                <AllBrandText className="text">{filterFItems[clickF]}</AllBrandText>
                <AllBrandImgBox className="img-box">
                  <img src={IconSelectArrow} alt="" />
                </AllBrandImgBox>
              </AllBrandButton>
              <AllBrandButton onClick={() => setFilterShowS(!filterShowS)}>
                <AllBrandText className="text">{filterSItems[clickS]}</AllBrandText>
                <AllBrandImgBox className="img-box">
                  <img src={IconSelectArrow} alt="" />
                </AllBrandImgBox>
              </AllBrandButton>
            </AllBrandFilter>
          )}

          {query.index === "0" ? (
            <>
              <StoreSearchResultProductWrap>
                {resultPrdList.length > 0 ? (
                  resultPrdList.map((prdValue, index) => {
                    return (
                      <BestItemProductList
                        key={`${prdValue.prd_id}-searchPrd-${index}`}
                        value={prdValue}
                        rawThree={false}
                        setLoginPop={setLoginPop}
                      />
                    );
                  }
                  )
                ) : (
                  <SearchInfoText>검색된 상품이 없습니다.</SearchInfoText>
                )}
                <FooterMenu />
              </StoreSearchResultProductWrap>
              {resultPrdList.length > 0 && <NewPagination page={page} setPage={setPage} pageData={pageData} />}
            </>
          ) : query.index === "1" ? (
            <StoreSearchResultBrandWrap>
              {isBrandFetchLoading ? (
                <Loading />
              ) : (
                <>
                  {brandsList.length > 0 ? (
                    brandsList.map((brandValue, index) => {
                      return (
                        <BrandListContents value={brandValue} key={`${brandValue.bcId}-${index}`} />
                      );
                    })
                  ) : (
                    <SearchInfoText>검색된 브랜드가 없습니다.</SearchInfoText>
                  )}
                </>
              )}
            </StoreSearchResultBrandWrap>
          ) : (
            <ContentsListWrap>
              <ContentsList
                contentsDetail={contentsDetail}
                setContentsDetail={setContentsDetail}
                search={true}
                inputSearchValue={query.keyword}
              />
            </ContentsListWrap>
          )}
        </StoreSearchResultWrap>
      </Mobile>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  color: #333333;
  font-size: 16px;
  position: relative;
  
  @media screen and (max-width: 930px) {
    /* width: calc(100vw * (388 / 428)); */
    /* height: calc(100vh - calc(100vw * (65 / 428))); */
    min-height: calc(100vw * (500 / 428));
  }
`;
const StoreSearchResultProductWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-between;

  @media screen and (max-width: 1320px) {
  }

  @media screen and (max-width: 930px) {
    /* gap: calc(100vw * (9 / 428)); */
    width: calc(100vw * (378 / 428));
    padding-top: calc(100vw * (65 / 428));
  }
`;
const StoreSearchResultBrandWrap = styled.div`
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
  }

  @media screen and (max-width: 930px) {
    /* gap: calc(100vw * (9 / 428)); */
    width: calc(100vw * (388 / 428));
  }
`;
const StoreSearchResultWrap = styled.div`
  padding: 80px 0 180px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (80 / 1320)) 0 calc(100vw * (180 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;

// 컨텐츠 리스트
const ContentsListWrap = styled.div`
  padding-top: 40px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (40 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-top: 0;
  }
`;

// 필터 탭버튼
const AllBrandFilter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  transition: ease 0.3s all;
  @media screen and (max-width: 930px) {
    top: calc(100vw * (55 / 428));
    position: fixed;
    left: 0;
    z-index: 9;
    ${({ hide }) =>
    hide &&
    `
      top : 0;
      opacity : 0.7;
    `}
  }
`;
const AllBrandButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;

  :not(:last-child) {
    border-right: 1px solid #dddddd;
  }
  @media screen and (max-width: 930px) {
    width: 50%;
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;
const AllBrandText = styled.span`
  color: #333333;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const AllBrandImgBox = styled.span`
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
// 필터 팝업
const FilterListModal = styled.div`
  & .close-button {
  }

  & .modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

const ModalWrap = styled.div`
  position: fixed;
  z-index: 106;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
`;
const ModalUl = styled.ul`
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (54 / 428)) calc(100vw * (25 / 428));
  }
`;
const ModalLi = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ active }) =>
    active &&
    `
& span {
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-6deg);
      z-index: 0;
      display: block;
      background-color: #fdf251;
    }
`}

  span {
    color: #333333;
    font-weight: 500;
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    ${({ active }) =>
    active &&
    `
 & span {
        position: relative;
        z-index: 1;
      }
      &::after {
        width: calc(100vw * (56 / 428));
        height: calc(100vw * (12 / 428));
      }
`}
  }
`;
const ModalBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 105;
  background-color: rgba(0, 0, 0, 0.4);
`;
const ModalCloseButton = styled.button`
  position: absolute;
  font-size: 0;
  background: url(${IconClose}) no-repeat center / cover;
  @media screen and (max-width: 930px) {
    top: calc(100vw * (20 / 428));
    right: calc(100vw * (25 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const SearchInfoText = styled.p`
  width: 100%;
  color: #999;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  padding: 200px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (200 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (210 / 428)) 0 0;
  }
`;
