import styled from "styled-components";

import ProductListThree from "../common/product/ProductListThree";

export default function StoreSearchResultIndex({ recentProductList, setLoginPop }) {
  return (
    <RecentProductWrap>
      <RecentProductTitleBox>
        <RecentProductTitle>최근 본 상품</RecentProductTitle>
      </RecentProductTitleBox>
      <RecentProductListBox>
        {recentProductList?.map((recentPrdValue, idx) => {
          return (
            <ProductListThree
              key={`${recentPrdValue.prd_id}-recentV-${idx}`}
              value={recentPrdValue}
              setLoginPop={setLoginPop}
            />
          );
        })}
      </RecentProductListBox>
    </RecentProductWrap>
  );
}
const RecentProductWrap = styled.div`
  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (15 / 428));
  }
`;
const RecentProductTitleBox = styled.div`
  margin: 0 auto;
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding-bottom: calc(100vw * (15 / 428));
  }
`;
const RecentProductTitle = styled.span`
  font-weight: 500;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const RecentProductListBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  -webkit-flex-wrap: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 930px) {
    gap: calc(100vw * (9 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;
