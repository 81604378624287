import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useHistory,Link } from 'react-router-dom';
import { imgURL } from '../../server/appInfoContoller';
import { DateToText } from '../common/commonUse';

import IconUpate from '../../img/common/icon_pencil.png';
import IconDelete from '../../img/common/icon_removeBtn.png';
import TestImg from '../../img/content/content2.png';
import DeleteOpenModal from './DeleteOpenModal';

export default function StylistContentsList({list, setList}) { 
    const history = useHistory();

    useEffect(()=>{
        console.log('id:',conId);
    },[conId]);
    const [isOpen, setIsOpen] = useState(false);
    const [index, setIndex] = useState()
    const [conId, setConId] = useState(null);
    const actives = ["공개중", "비공개", "오픈 예정"];

    return(
        <>
        {
            list?.map((v, i) => {
                return (
                    <ListWrap key={v?.contents_id}>
                        <ListContainer>
                            <ProdPreviewWrap>
                                <ImgBox onClick={() => history.push(`/content/${v?.contents_id}`)}>
                                    <Img src={imgURL + v?.contents_thumbnail}/>
                                </ImgBox>
                                <InformBox>
                                    <Row type={'inform'}>
                                        <Col>
                                            <PreviewTitle long>컨텐츠명</PreviewTitle>
                                            <PreviewText>{v?.contents_title}</PreviewText>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col justify={'space-between'}>
                                            <Col>
                                                <PreviewTitle long>{DateToText(v?.contents_date, ".", true)}</PreviewTitle>
                                                <ProdState>{v.contents_active && actives[v.contents_active - 1]}</ProdState>
                                            </Col>
                                            <ProdBtn fl={'right'}><MoHide>제품 </MoHide>상태 변경</ProdBtn>
                                        </Col>
                                    </Row>
                                </InformBox>
                            </ProdPreviewWrap>
                            <ProdInformWrap>
                                <InformContainer>
                                    {/* <GrayBox>
                                        <Row>      
                                            <Col>
                                                <Col mr={'26px'}>
                                                    <Title>신규신청</Title>
                                                    <Text>03</Text>
                                                </Col>
                                                <Col>
                                                    <Title>협찬승인</Title>
                                                    <Text>05</Text>
                                                </Col>
                                            </Col>
                                            <Col>
                                                <Col mr={'26px'}>
                                                    <Title>문의접수</Title>
                                                    <Text>05</Text>
                                                </Col>

                                                <Col>
                                                    <Title>반납예정</Title>
                                                    <Text>05</Text>
                                                </Col>
                                                <ViewMore>보기<Arrow/></ViewMore>
                                            </Col>
                                        </Row>
                                    </GrayBox> */}
                                    {/* <Row>
                                        <Row>
                                            <Col mb={'11px'} justify={'space-between'}>
                                                <Col>
                                                    <PreviewTitle short>협찬</PreviewTitle>
                                                    <PreviewText mr={'calc(100vw * (30 / 1920))'}>10개</PreviewText>
                                                    <PreviewText>300,000(원)</PreviewText>
                                                </Col>
                                                <ProdBtn fl={'right'}>가격<MoHide>&nbsp;</MoHide>/<MoHide>&nbsp;</MoHide>수량 변경</ProdBtn>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col mb={'11px'}>
                                                <PreviewTitle short>등록</PreviewTitle>
                                                <PreviewText>nodebemade_no1@gmail.com</PreviewText>
                                            </Col>
                                        </Row>
                                    </Row> */}
                                </InformContainer>
                                <CorrectWrap>
                                <StylistModify onClick={()=> {  
                                    setIndex(i); 
                                    history.push(`/stylist/stylistModify/${list[i].contents_id}`);
                                    console.log(i,'index');
                                    }} list={list[i]}><CorrectBtn icon={IconUpate}>수정</CorrectBtn>
                                </StylistModify>
                                <CorrectBtn icon={IconDelete} 
                                onClick={() => {
                                    setIndex(i); setIsOpen(true);
                                    setConId(v?.contents_id);
                                }}>삭제</CorrectBtn>
                                </CorrectWrap>
                            </ProdInformWrap>
                        </ListContainer>
                    </ListWrap>
                )
            })
        }
         { isOpen && <DeleteOpenModal conId={conId} setConId={setConId}setIsOpen={setIsOpen} list ={list} setList={setList} index={index}/>}
        </>
    )
}



const clear = `&:after { display:block; content:""; clear:both; }`;
const marginSet = (props)=> { return(`margin-top: ${props.mt || 0};
margin-right: ${props.mr || 0};
margin-bottom: ${props.mb || 0};
margin-left: ${props.ml || 0};`) };

const MoHide = styled.span`
    @media screen and (max-width:1024px){
        display:none;
    }
`
const StylistModify = styled.div`
`

const Arrow = styled.div`
    border-right:1px solid #CBCBCB; border-bottom:1px solid #CBCBCB; width:calc(100vw * (7 / 1920)); height:calc(100vw * (7 / 1920)); display:inline-block; transform:rotate(-45deg); line-height:inherit; vertical-align:center; margin-bottom:1px; margin-left:calc(100vw * (2 / 1920));
`;

const ListWrap = styled.div`
    padding:0 calc(100vw * (64 / 1920));
    margin-top:calc(100vw * (30 / 1920));
    &:first-child { margin-top:0; }
    @media screen and (max-width:1300px){
        padding:0;
    }
`;
const ListContainer = styled.div`
    width:100%; color:#222222; box-sizing:border-box;
    border-bottom:1px solid #D9D9D9;
    padding:0 0 calc(100vw * (34 / 1920));
    
    ${clear};
    display:flex; flex-direction:row;

    @media screen and (max-width:1024px){
        display: block;
        padding-bottom:calc(100vw * (20 / 428));
    }
`;

const ProdPreviewWrap = styled.div`
    position:relative; vertical-align:top; width:80%; padding-right:calc(100vw * (32 / 1920)); border-right:1px solid #D9D9D9;
    ${clear}; display:flex; flex-direction:row;

    @media screen and (max-width:1024px){
        width:100%; margin-bottom:calc(100vw * (24 / 428)); padding-right:0; border:none;
    }
`;

const ProdInformWrap = styled.div`
    position:relative; vertical-align:top; width:20%; 
    padding-left:calc(100vw * (32 / 1920));
    ${clear}; 
    display:flex; flex-direction:row;
    
    @media screen and (max-width:1024px){
        width:100%; padding-left:0;
        flex-direction:column;
    }
`;

const InformContainer = styled.div`
    position:relative;
     /* width:calc(100% - 98px);  */
    padding-right:calc(100vw * (18 / 1920));

    @media screen and (max-width:1024px){
        width:100%; padding-right:0;
    }
`;

const ImgBox = styled.div`
    width:calc(100vw * (126 / 1920)); height:calc(100vw * (126 / 1920)); vertical-align:top;
    background:#cccccc; overflow:hidden;
    cursor: pointer;
    
    @media screen and (max-width:1024px){
        width:calc(100vw * (102 / 428)); height:calc(100vw * (102 / 428));
    }
`;
const Img = styled.img`
    width:100%; height: 100%; object-fit: cover;
`;

const InformBox = styled.div`
    position:relative; width: calc(100% - calc(100vw * (126 / 1920))); padding-left: calc(100vw * (16 / 1920));
    &:after { content:""; display:block; clear:both; }

    @media screen and (max-width:1024px){
        width: calc(100% - calc(100vw * (102 / 428)));
    }
`;
const Row = styled.div`
    position:relative;
    ${props=>props.type == 'inform' && css`
        margin-bottom:calc(100vw * (12 / 1920));
        
        @media screen and (max-width:1024px){
            margin-bottom:calc(100vw * (3 / 428));
        }
    `}
`;

const Col = styled.div`
    ${props => marginSet(props)};
    height:auto; width:auto;
    display:flex; position:relative;
    ${props => props.justify && css`
        justify-content:${props.justify};
    `}
`;

const PreviewTitle = styled.p`
    font-size:calc(100vw * (16 / 1920)); line-height:calc(100vw * (24 / 1920)); font-weight:bold;
    ${props => props.long && css`
        width:calc(100vw * (78 / 1920));
    `}
    ${props => props.short && css`
        width:calc(100vw * (60 / 1920));
    `}

    @media screen and (max-width:1024px){
        display: none;
        font-size: calc(100vw * (14 / 428));
        ${props => props.long && css`
        width:calc(100vw * (64 / 428));
    `}
    ${props => props.short && css`
        width:calc(100vw * (38 / 428));
    `}
    }
`
const PreviewText = styled.p`
    ${props => marginSet(props)};
    font-size:calc(100vw * (15 / 1920)); line-height:1.6; word-break:keep-all;
    @media screen and (max-width:1024px){
        line-height:1.3;
        margin-bottom: calc(100vw * (8 / 428));
        font-size:calc(100vw * (14 / 428));
    }
`
const ProdState = styled.p`
    font-size:calc(100vw * (15 / 1920));
    line-height:1.6;
    @media screen and (max-width:1024px){
        font-weight: bold;
        font-size:calc(100vw * (14 / 428));
    }
`
const ProdBtn = styled.button`
    display:block; font-size:calc(100vw * (14 / 1920)); border:1px solid #222222; border-radius:calc(100vw * (17 / 1920)); padding:1px calc(100vw * (22 / 1920)) calc(100vw * (3 / 1920)); 
    box-sizing:border-box;
    float:${props => props.fl || 'none'};
    
    @media screen and (max-width:1024px){
        font-size:calc(100vw * (14 / 428));
        padding:1px calc(100vw * (20 / 428)) calc(100vw * (3 / 428));
    }
`;


const CorrectWrap = styled.div`
    width:calc(100vw * (98 / 1920)); 
    @media screen and (max-width:1024px){
        width:unset; display:flex; justify-content: right;
    }
`;

const CorrectBtn = styled.button`
    width:calc(100vw * (98 / 1920)); height:calc(100vw * (46 / 1920)); background:#222222; border-radius:calc(100vw * (24 / 1920));
    color:#ffffff; font-size:calc(100vw * (15 / 1920)); font-weight:900; margin-bottom:calc(100vw * (7 / 1920));
    position:relative;
    &>a{
        width:calc(100vw * (98 / 1920)); height:calc(100vw * (46 / 1920)); background:#222222; border-radius:calc(100vw * (24 / 1920));
        color:#ffffff; font-size:calc(100vw * (15 / 1920)); font-weight:900; margin-bottom:calc(100vw * (7 / 1920));
        position:relative;
    }
    &:after { content:''; display:inline-block; width:calc(100vw * (17 / 1920)); height:calc(100vw * (17 / 1920)); background:url(${props => props.icon}) no-repeat center /contain; margin-left:calc(100vw * (15 / 1920)); margin-bottom:calc(100vw * (-3 / 1920)); }

    @media screen and (max-width:1024px){
        width:calc(100vw * (80 / 428)); height:calc(100vw * (35 / 428)); font-size:calc(100vw * (13 / 428)); margin-bottom:0;
        &>a{
            width:calc(100vw * (80 / 428)); height:calc(100vw * (35 / 428)); font-size:calc(100vw * (13 / 428)); margin-bottom:0;
        }
        &:first-child { margin-right:calc(100vw * (5 / 428)); }
        &:after { width:12px; height:calc(100vw * (12 / 428)); margin-left:calc(100vw * (15 / 428)); margin-bottom:-1px; }
    }

`;
