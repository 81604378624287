import React, { useState, useEffect } from "react";
import DetailSetOptionSelectBox from "./DetailSetOptionSelectBox";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function DetailOptionBox({
  zIndex,
  product,
  title,
  options,
  vaildateSetSelectItem,
  type,
}) {
  const [indexList, setIndexList] = useState(product);

  function indexHelper() {
    let firstIndex = { opt_id: 0, option: null };
    let count = 0;
    let idxList = {};
    while (true) {
      var curOption = null;

      for (var i = 0; i < options.length; i++) {
        if (options[i].opt_relate_id == firstIndex.opt_id) {
          curOption = options[i];
          break;
        }
      }

      if (curOption == null) {
        setIndexList(idxList);
        return;
      }

      idxList[count++] = firstIndex;
      firstIndex = { opt_id: curOption.opt_id, option: null };
    }
  }

  useEffect(() => {
    indexHelper();
  }, []);

  function getOptionRelateList() {
    var optionList = {};

    for (var i = 0; i < options.length; i++) {
      optionList[options[i].opt_relate_id]
        ? optionList[options[i].opt_relate_id].push(options[i])
        : (optionList[options[i].opt_relate_id] = [options[i]]);
    }

    return optionList;
  }

  function initSelectTag() {
    let optionMap = getOptionRelateList();
    let idx = 0;
    let firstIndex = indexList[idx];
    let result = [];

    while (true) {
      var curOption = null;

      if (firstIndex == null) {
        return result;
      }

      for (var i = 0; i < options.length; i++) {
        if (options[i].opt_relate_id == firstIndex.opt_id) {
          curOption = options[i];
          break;
        }
      }

      if (curOption == null) {
        return result;
      }

      let list = [];
      for (let i = 0; i < options.length; i++) {
        if (options[i].opt_relate_id === firstIndex.opt_id) {
          let soldout =
            !optionMap[options[i].opt_id] &&
            options[i].opt_inventory === 0 &&
            options[i].opt_last === "Y";
          list.push({
            idx: idx + 1,
            option: options[i],
            label: soldout
              ? options[i].opt_name
              : options[i].opt_name +
              (options[i].opt_price > 0
                ? " + " + numberWithCommas(options[i].opt_price) + "원"
                : ""),
            soldout: soldout,
          });
        }
      }

      zIndex -= 2000;
      result.push(
        <DetailSetOptionSelectBox
          key={idx}
          zIndex={zIndex}
          title={curOption.opt_title}
          list={idx == 0 || indexList[idx].option ? list : null}
          clickSelect={(e) => {
            if (e.soldout) {
              return false;
            }

            let il = JSON.parse(JSON.stringify(indexList));
            let c = e.idx;
            il[c] = { opt_id: e.option.opt_id, option: e.option };

            let checkDone = 0;
            for (var i = 0; i < options.length; i++) {
              if (options[i].opt_relate_id == il[c].opt_id) {
                checkDone++;
              }
            }

            if (checkDone == 0) {
              vaildateSetSelectItem(il);
            }

            while (il[c + 1]) {
              for (var i = 0; i < options.length; i++) {
                if (options[i].opt_relate_id == il[c].opt_id) {
                  il[c + 1] = { opt_id: options[i].opt_id, option: null };
                  break;
                }
              }
              c++;
            }

            setIndexList(il);
            return true;
          }}
        />
      );

      firstIndex = indexList[++idx];
    }
  }

  return <div>{initSelectTag()}</div>;
}
