import React, { useState } from "react";
import styled from "styled-components";

import { ip } from "../../../server/serverController";
import IconClose from "../../../img/icon/icon_popup_close.svg";

export default function PostCouponModal(props) {
  const { setIsCoupon } = props;
  const [code, setCode] = useState("");

  // TODO :: coupon code post
  const postCouponCode = () => {
    const headersData = new Headers();
    headersData.append("Content-Type", "application/json");

    const bodyData = JSON.stringify({
      couponCode: "ddd",
    });

    const option = {
      method: "POST",
      headers: headersData,
      body: bodyData,
      redirect: "follow",
    };

    fetch(`${ip}coupon/regist`, option)
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.error("error : ", error));
  };

  return (
    <>
      <Container>
        <CloseButton
          type="button"
          onClick={() => {
            setIsCoupon(false);
          }}
        >
          닫기
        </CloseButton>
        <Title>쿠폰등록</Title>
        <CouponInputBox>
          <Input
            type="text"
            value={code}
            placeholder="쿠폰 번호를 입력해주세요."
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
          <SubmitButton onClick={postCouponCode}>등록</SubmitButton>
        </CouponInputBox>
        <InfoBox></InfoBox>
      </Container>
      <Background onClick={() => setIsCoupon(false)} />
    </>
  );
}
const Container = styled.div`
  z-index: 106;
  background-color: #fff;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 800px;
  margin: auto;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (800 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const Title = styled.p`
  padding-left: 30px;
  font-size: 18px;
  padding-top: 30px;

  @media screen and (max-width: 1320px) {
    padding-left: calc(100vw * (30 / 1320));
    font-size: calc(100vw * (18 / 1320));
    padding-top: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    text-align: center;
    padding-left: 0;
    font-size: calc(100vw * (18 / 428));
    padding-top: calc(100vw * (20 / 428));
  }
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 105;
`;

const CouponInputBox = styled.div`
  width: 100%;
  display: flex;
  padding: 60px 30px;
  gap: 10px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (60 / 1320)) calc(100vw * (30 / 1320));
    gap: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (60 / 428)) calc(100vw * (30 / 428));
    gap: calc(100vw * (10 / 428));
  }
`;
const SubmitButton = styled.button`
  height: fit-content;
  background-color: #000;
  color: #fff;
  height: 50px;
  width: 80px;
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    width: calc(100vw * (80 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    width: calc(100vw * (80 / 428));
  }
`;
const Input = styled.input`
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  padding: 0 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  transition: ease 0.3s all;
  flex: 1;
  &::placeholder {
    color: #acacac;
  }
  &:focus {
    border: 1px solid #000;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: 0 calc(100vw * (15 / 428));
  }
`;
const CloseButton = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  width: 32px;
  height: 32px;
  font-size: 0;
  background: url(${IconClose}) no-repeat center / cover;

  @media screen and (max-width: 1320px) {
    top: calc(100vw * (25 / 1320));
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: calc(100vw * (15 / 428));
    right: calc(100vw * (18 / 428));
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
  }
`;
const InfoBox = styled.div``;
