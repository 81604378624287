import styled from "styled-components";

import ProductListThree from "../common/product/ProductListThree";

export default function StoreSearchRecentListSection(props) {
  const { recentProductList, setLoginPop, main } = props;

  return (
    <RecentProductWrap main={main}>
      <RecentProductTitleBox>
        <RecentProductTitle>Recently Viewed</RecentProductTitle>
      </RecentProductTitleBox>
      <RecentProductListBox>
        {recentProductList?.map((recentPrdValue, idx) => {
          return (
            <ProductListThree
              key={`${recentPrdValue.prd_id}-search-recentV-${idx}`}
              value={recentPrdValue}
              setLoginPop={setLoginPop}
            />
          );
        })}
      </RecentProductListBox>
    </RecentProductWrap>
  );
}
const RecentProductWrap = styled.div`
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0 calc(100vw * (40 / 428));
    ${({ main }) =>
    main &&
    `padding: calc(100vw * (40 / 428)) 0 calc(100vw * (40 / 428)); border-top: calc(100vw * (1 / 428)) #eee solid;`}
  }
`;
const RecentProductTitleBox = styled.div`
  margin: 0 auto;
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding-bottom: calc(100vw * (15 / 428));
  }
`;
const RecentProductTitle = styled.span`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
  color: #222222;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const RecentProductListBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  -webkit-flex-wrap: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 930px) {
    gap: calc(100vw * (9 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;
