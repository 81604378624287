import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { Mobile, PC } from "../common/mediaStlye";
import styled from "styled-components";
import serverController from "../../server/serverController";
import { imgURL } from "../../server/appInfoContoller";
import openLink from "../../server/openLink";
import { useSelector } from "react-redux";

import {
  Wrapper,
  DramaSlider,
  DramaSlide,
  DramaBackground,
  DramaSlideContent,
  DramaSlideText,
  DramaSlideProducts,
  WhiteMoreBtn,
} from "./CategoryContents.element";
import banner from "../../images/category-banner.png";

import detailThumbimg1 from "../../images/content/content-detail-prod1.png";
import detailThumbimg2 from "../../images/content/content-detail-prod2.png";
import detailThumbimg3 from "../../images/content/content-detail-prod3.png";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import "./CategoryDramaMain.css";

const CategoryDramaMain = () => {
  const history = useHistory();
  const thumbs = [detailThumbimg1, detailThumbimg2, detailThumbimg3];
  const { isLogged } = useSelector((state) => state.userData);
  const { category_id } = useParams();

  const [list, setList] = useState([]);
  const getCategoryBanners = async () => {
    await serverController.connectFetchController(
      // `main/contentslist/${2}`,
      `bannerlist?c_cat_id=${category_id}`,
      "GET",
      null,
      (res) => {
        console.log(res, "드라마 카테고리 배너 목록 res");
        if (res?.result === 1) {
          setList(res.list ?? []);
        }
      },
      (err) => console.error(err)
    );
  };

  const pageLinkClick = (id, type, link) => {
    if (type == 3) {
      openLink.openLinkEvent(link);
      // window.open(`${link}`);
    } else {
      alert("런칭 준비중입니다 :D");
      // history.push(`/store/detail/${id}`);
    }
  };

  useEffect(() => {
    getCategoryBanners();
  }, [category_id, isLogged]);

  return (
    <>
      <PC>
        <Wrapper>
          <DramaSlider className="drama-slider">
            <Swiper navigation loop>
              {list.map((v, i) => {
                // const src = v?.contents_thumbnail?.split(",")[0];

                return (
                  <SwiperSlide key={v?.contents_id}>
                    <DramaSlide>
                      <DramaBackground>
                        <img src={imgURL + v?.ban_image} />
                      </DramaBackground>
                      <DramaSlideContent>
                        <DramaSlideText>
                          <h3>{v?.ban_title}</h3>
                          <div>{ReactHtmlParser(v?.ban_intro)}</div>
                          {/* <p>{v?.contents_teg}</p>/ */}
                          <WhiteMoreBtn
                            as="a"
                            href={"http://" + v?.ban_url}
                            target="_blank"
                          >
                            See More
                          </WhiteMoreBtn>
                        </DramaSlideText>

                        <DramaSlideProducts>
                          {v?.prdList?.slice(0, 2).map((value, j) => {
                            return (
                              <div
                                key={j}
                                onClick={() => {
                                  pageLinkClick(
                                    value.prd_id,
                                    value.prd_type,
                                    value.prd_link
                                  );
                                }}
                              >
                                <img
                                  src={imgURL + value?.prd_thumbnail}
                                  alt=""
                                />
                              </div>
                            );
                          })}
                          {/* <div><img src={thumbs[0]} alt="" /></div>
                                            <div><img src={thumbs[1]} alt="" /></div>
                                            <div><img src={thumbs[2]} alt="" /></div> */}
                        </DramaSlideProducts>
                      </DramaSlideContent>
                    </DramaSlide>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </DramaSlider>
        </Wrapper>
      </PC>

      <Mobile>
        <MWrapper>
          <DramaSlider className="drama-slider">
            <DramaSlide>
              <DramaBackground as="a" target="_blank" href={list[0]?.ban_url}>
                <img src={imgURL + list[0]?.ban_image} />
              </DramaBackground>
              <DramaSlideContent>
                <DramaSlideText>
                  <h3>{list[0]?.ban_title}</h3>
                  <p>{ReactHtmlParser(list[0]?.ban_intro)}</p>
                  {/* <WhiteMoreBtn>See More</WhiteMoreBtn> */}
                </DramaSlideText>

                <DramaSlideProducts>
                  {list[0]?.prdList && (
                    <Swiper loop spaceBetween={10} slidesPerView={2.2}>
                      {list[0]?.prdList?.map((item, i) => {
                        return (
                          <SwiperSlide key={item?.prd_id}>
                            <div
                              className="drama-thumb"
                              onClick={() => {
                                pageLinkClick(
                                  item?.prd_id,
                                  item?.prd_type,
                                  item?.prd_link
                                );
                              }}
                            >
                              <img src={imgURL + item?.prd_thumbnail} alt="" />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  )}
                </DramaSlideProducts>
              </DramaSlideContent>
            </DramaSlide>
          </DramaSlider>
        </MWrapper>
      </Mobile>
    </>
  );
};

export default CategoryDramaMain;

const MWrapper = styled.div`
  margin-bottom: calc(100vw * (30 / 428));
`;
