const SktPassAgreeText = [
  <article className="pass-wrap" style={{ letterSpacing: '-0.5px' }}>
    <br />
    <p>"PASS x 왈라 특별 기획전" 상품 구매에 따른 혜택 지급을 위한 개인정보 제3자 제공 동의서.</p>
    <br />
    <p>"PASS x 왈라 특별 기획전" 상품 구매에 따른 혜택 지급을 위하여, 아래와 같이 본인의 개인정보를 제3자에게 제공함에 동의 합니다.</p>
    <table style={{ width: "100%", marginBottom: '20px' }}>
      <thead style={{ backgroundColor: "#ddd" }}>
        <tr>
          <th style={{ border: "1px solid #ddd", padding: "5px 10px" }}>제공 대상</th>
          <th style={{ border: "1px solid #ddd", padding: "5px 10px" }}>업무 내용</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ border: "1px solid #ddd", padding: "5px 10px" }}>SKT</td>
          <td style={{ border: "1px solid #ddd", padding: "5px 10px" }}>PASS 회원확인, PASS 머니지급</td>
        </tr>
      </tbody>
    </table>
    <table style={{ width: "100%", marginBottom: '20px' }}>
      <thead style={{ backgroundColor: "#ddd", padding: "5px 10px" }}>
        <tr><th style={{ border: "1px solid #ddd", padding: "5px 10px" }}>제공 항목</th></tr>
      </thead>
      <tbody>
        <tr><td style={{ border: "1px solid #ddd", padding: "5px 10px" }}>성명, 휴대폰번호</td></tr>
      </tbody>
    </table>
    <table style={{ width: "100%", marginBottom: '20px' }}>
      <thead style={{ backgroundColor: "#ddd", padding: "5px 10px" }}>
        <tr><th style={{ border: "1px solid #ddd", padding: "5px 10px" }}>이용 및 보유기간</th></tr>
      </thead>
      <tbody>
        <tr><td style={{ border: "1px solid #ddd", padding: "5px 10px" }}>프로모션 혜택 지급 후 3개월 내 파기</td></tr>
      </tbody>
    </table>
    <div>
      <p>※ 이 내용은 이벤트 참여를 위한 개인정보 제3자 제공동의 입니다.</p>
      <p>동의를 거부할 권리가 있으나, 동의하지 않을 경우 이벤트 혜택을 제공받으실  수 없습니다.</p>
    </div>
  </article>
];

export default SktPassAgreeText;