//react
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

//css
import "swiper/swiper-bundle.css";
import styled from "styled-components";

// component
import TvSlide from "./TvSlide";
import CommentModal from "./CommentModal";

// server
import serverController from "../../server/serverController";

//img
import IconPause from "../../images/video/icon_pause.svg";

export default function TvMain() {
  const history = useHistory();

  const [isModal, setIsModal] = useState(false);
  const [pgnInfo, setPgnInfo] = useState({});
  const [videoIdx, setVideoIdx] = useState(1);
  const [pageIdx, setPageIdx] = useState(1);
  const [isMuted, setIsMuted] = useState(true);
  const [isPause, setIsPause] = useState(false);

  const [videoList, setVideoList] = useState([]);
  const [deVideoList, setDeVideoList] = useState([]);
  const [activeVideo, setActiveVideo] = useState({});

  // 최초 첫 영상(2개) 불러오기
  useEffect(() => {
    fetchReelsList();
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "page",
          text: "hi",
        })
      );
    }
    return () => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "page",
            text: "bye",
          })
        );
      }
    };
  }, []);

  // 마지막 릴스에 도달했을 때 fetching
  useEffect(() => {
    if (videoIdx === videoList.length && pageIdx <= pgnInfo.endPage)
      fetchReelsList(pageIdx);
  }, [videoIdx]);

  // setState
  useEffect(() => {
    if (videoList.length) {
      if (document.querySelectorAll(".video-deactivate"))
        setDeVideoList(document.querySelectorAll(".video-deactivate"));
      if (document.querySelector(".video-active"))
        setActiveVideo(document.querySelector(".video-active"));
    }
  }, [videoList, videoIdx]);

  // 비활성 비디오들
  useEffect(() => {
    if (deVideoList.length) {
      deVideoList.forEach((video) => {
        video.muted = true;
        video.pause();
        video.currentTime = 0;
      });
    }
  }, [deVideoList]);

  // 현재 비디오
  useEffect(() => {
    if (Object.keys(activeVideo).length) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "stoped",
            id: document.querySelector("#mute-btn"),
          })
        );
      }

      setIsPause(false);

      if (isMuted) activeVideo.muted = true;
      else activeVideo.muted = false;

      const promise = activeVideo.play();

      if (promise !== undefined) {
        promise.catch(() => {
          setIsMuted(true);
          activeVideo.muted = true;
        });
      }
    }
  }, [activeVideo]);

  // 릴스 데이터 fetching
  const fetchReelsList = (pageIdx = 1) => {
    serverController.connectFetchController(
      `reels/list?page=${pageIdx}`,
      "GET",
      null,
      (res) => {
        console.log(res);
        if (res) {
          if (res?.result == 1) {
            console.log("res :", res);
            setPgnInfo(res?.pagination);
            setVideoList([...videoList, ...res.list]);
            setPageIdx(pageIdx + 1);
          }
        }
      }
    );
  };

  // 영상 재생, 일시정지 토글링
  const togglePlay = () => {
    setIsPause((prevState) => !prevState);

    if (!isPause) activeVideo.pause();
    else activeVideo.play();
  };

  // ios 100vh
  let vh = 0;
  useEffect(() => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    document.getElementById("container").style.setProperty("--vh", `${vh}px`);
  }, []);

  return (
    <>
      <TvMainWrap id={"container"}>
        <VideoBg>
          <Swiper
            onSlideChange={(e) => setVideoIdx(e.activeIndex + 1)}
            slidesPerView={1}
            direction={"vertical"}
          >
            {videoList.map((item, index) => {
              return (
                <SwiperSlide
                  key={index + "main video list"}
                  style={{ background: "black" }}
                >
                  <React.Fragment>
                    <TvSlide
                      item={item}
                      index={index}
                      setIsModal={setIsModal}
                      videoIdx={videoIdx}
                      isMuted={isMuted}
                      setIsMuted={setIsMuted}
                      togglePlay={togglePlay}
                    />
                  </React.Fragment>
                </SwiperSlide>
              );
            })}
            {isPause ? <Pause src={IconPause} onClick={togglePlay} /> : null}
          </Swiper>
        </VideoBg>
      </TvMainWrap>
      {isModal ? <CommentModal setIsModal={setIsModal} /> : null}
    </>
  );
}
const TvMainWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;

  @supports (-webkit-touch-callout: none) {
    max-height: -webkit-fill-available;
  }
`;
const VideoBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  & .swiper-container {
    width: 100%;
    height: 100%;
  }
  /* & .swiper-slide {background: #000;
        &::after {content: ''; position: absolute; top: 0; left: 0; display: block;
        width: 100%; height: calc(100vw*(70/428)); background: #000; z-index: 2;}} */
  & .swiper-slide .video-js {
    width: 100%;
    height: 100vh;
  }
  & .swiper-slide .video-js .vjs-control-bar {
    background-color: rgba(255, 255, 255, 0.7);
  }
`;
const Pause = styled.img`
  position: absolute;
  z-index: 1;
  width: calc(100vw * (56 / 428));
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
