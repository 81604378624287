import React, {useState} from 'react';

import styled , {css} from 'styled-components';

import ProductImg01 from '../../../img/brand/product_img01.jpg';

export default function ProductDetailContents({activeTab}){

    function View(activeTab){
        switch(activeTab){
            case 0:
                return(
                    Array(14).fill().map(()=>{
                        return(
                            <ProductListOne/>
                        )
                    })
                )
                break;
            // case 1:
                
            // case 2:
            // case 3:
            // case 4:
            // case 5:
            default:
                return(
                    <ContentsNone>신규신청 내역이 없습니다.</ContentsNone>
                )
        }
    }
    return(
        <DetailContentsWrap>
            {View(activeTab)}
        </DetailContentsWrap>
    )
}

function ProductListOne(){
    return(
        <ProductWrap>
            <ProductImg src={ProductImg01}/>
            <ProdTitle>노드비메이드 타이백 트렌치 코트</ProdTitle>
            <BtnWrap>
                <Btn>승인</Btn>
                <Btn black>반려</Btn>
            </BtnWrap>
        </ProductWrap>
    )
}
function ProductListTwo(){
    return(
        <ProductWrap>
            
        </ProductWrap>
    )
}
const DetailContentsWrap = styled.div`
    width: calc(100vw * (1324 / 1920)); margin:0 auto;
    padding-top:calc(100vw * (74 / 1920));
    @media screen and (max-width:1024px){
        width:100%;
        padding-left:calc(100vw * (25 / 428));
        padding-right:calc(100vw * (25 / 428));
    }
`;
const ProductWrap = styled.div`
    display:inline-block; width:calc((100% / 7) - 16px);
    margin:0 8px; margin-bottom:60px;
    @media screen and (max-width:1024px){
        width:calc((100% / 3) - 16px);
        margin-bottom:calc(100vw * (40 / 428));
    }
`;

const ProductImg = styled.div`
    width:100%; padding-top:122%;
    background:#CBCBCB;
    /* ${props => props.src && `
        background:url(${ProductImg01}) no-repeat center / contain;
    `} */
`
const ProdTitle = styled.div`
    font-size:14px; padding:8px 0;
`

const BtnWrap = styled.div`

`;

const Btn = styled.button`
    width:100%; display:inline-block; padding:8px 0; font-size:15px; line-height:21px;
    border-radius:19px; border:1px solid #CBCBCB;
    &:first-child { margin-bottom:10px; }
    ${props=>props.black && `
        background:#222222; color:#ffffff; border:0;
    `}
    @media screen and (max-width:1024px){
        padding:6px 0;
    }
`;

const ContentsNone = styled.div`
    padding-top:calc(100vw * (194 / 1920));
    padding-bottom:calc(100vw * (418 / 1920));
    text-align:center; font-size:18px; color:#A5A5A5;
`

