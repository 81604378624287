//react
import React from "react";
import { useHistory } from "react-router-dom";
import qs from "qs";

//css
import styled from "styled-components";
import BrandListContents from "../brand/BrandListContents";

import iconMore from "../../img/icon/icon_more_button.svg";

export default function StoreSearchBrandSection(props) {
  const { list, setLoginPop } = props;
  const history = useHistory();
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  return (
    <Container>
      <AllBrandWrap>
        {list.length > 0 && (
          <AllBrandBox>
            <AllBrandTitleWrap>
              <AllBrandTitleText>BRANDS</AllBrandTitleText>
              {list.length === 5 && (
                <TitleMoreTextButton
                  onClick={() => history.push(`/store/search?keyword=${query.keyword}&index=1`)}
                >
                  <TitleMoreText>더 보기</TitleMoreText>
                  <TitleMoreImg src={iconMore} alt="moreIcon" />
                </TitleMoreTextButton>
              )}
            </AllBrandTitleWrap>
            <AllBrandListWrap>
              {list.map((brandValue, index) => {
                return (
                  <BrandListContents
                    value={brandValue}
                    key={`${brandValue.bcId}-brandList-${index}`}
                    setLoginPop={setLoginPop}
                  />
                );
              })}
            </AllBrandListWrap>
          </AllBrandBox>
        )}
      </AllBrandWrap>
    </Container>
  );
}
const Container = styled.div`
  width: 1240px;
  padding: 80px 0 540px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    padding: calc(100vw * (80 / 1320)) 0 calc(100vw * (540 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: 0;
    padding: calc(100vw * (40 / 428)) 0 calc(100vw * (20 / 428));
  }
`;
const AllBrandWrap = styled.div`
  width: 100%;
`;
const AllBrandTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1320px) {
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    margin-bottom: calc(100vw * (16 / 428));
  }
`;
const AllBrandTitleText = styled.span`
  color: #333333;
  font-size: 24px;
  font-weight: 700;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const AllBrandTitleLength = styled.span`
  color: #999999;
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-left: calc(100vw * (8 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (6 / 428));
  }
`;
const AllBrandListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  & .brand-list:not(:nth-child(3n)) {
    margin-right: 53px;
  }

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (15 / 1320));

    & .brand-list:not(:nth-child(3n)) {
      margin-right: calc(100vw * (53 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    display: block;
    margin-top: calc(100vw * (5 / 428));

    & .brand-list:not(:nth-child(3n)) {
      margin-right: 0;
    }
  }
`;
const AllBrandBox = styled.div`
  &:not(:last-child) {
    margin-bottom: 80px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (80 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (50 / 428));
    }
  }
`;

const TitleMoreTextButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const TitleMoreText = styled.span`
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const TitleMoreImg = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 5px;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
    margin-left: calc(100vw * (5 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
    margin-left: calc(100vw * (5 / 428));
  }
`;
