import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { imgURL } from "../../../server/appInfoController";

// common
import { numberWithCommas } from "../../../../components/common/commonUse";

export default function MyOrderList({ orderList, getStatus, tabIndex }) {
  //페이지 이동
  const history = useHistory();
  return (
    <Container>
      <Wrap>
        <ContentsWrap>
          {orderList.length > 0 ? (
            <Contents>
              <ListBox>
                {orderList.map((listV) => {
                  return (
                    <List key={listV.rev_id}>
                      <ListTop>
                        <DateWrap>
                          <Date>{listV.mog_payment_date.split(" ")[0].replaceAll("-", ". ")}</Date>
                          <OrderNum>{listV.order_id}</OrderNum>
                        </DateWrap>
                        <DetailButton
                          type="button"
                          onClick={() => {
                            history.push(
                              `/store/mypage/order/detail/${listV?.txn_id}?orderId=${listV.order_id}`
                            );
                          }}
                        >
                          주문상세보기 &gt;
                        </DetailButton>
                      </ListTop>
                      <ListInner>
                        <ProductInfoWrap>
                          <ImgBox
                            onClick={() => {
                              history.push(
                                `/store/mypage/order/detail/${listV?.txn_id}?orderId=${listV.order_id}`
                              );
                            }}
                          >
                            <img src={`${imgURL}${listV.prd_thumbnail}`} alt="product-thumbnail" />
                          </ImgBox>
                          <TextBox>
                            <ProductIng
                              textColor={
                                listV.mog_order_status === 20 || listV.mog_order_status === 14
                                  ? "black"
                                  : "pink"
                              }
                            >
                              {getStatus(listV.mog_order_status)}
                            </ProductIng>
                            <Brand>{listV.bc_title || "(BRAND)"}</Brand>
                            <ProductName
                              onClick={() => {
                                history.push(
                                  `/store/mypage/order/detail/${listV?.txn_id}?orderId=${listV.order_id}`
                                );
                              }}
                            >
                              {listV.prd_name}
                            </ProductName>
                            <ProductColor>
                              {listV.optionInfo.map((optionV, idx) => {
                                return (
                                  <span key={`${optionV.optName}-${idx}`}>
                                    {`${optionV.optName}`}
                                  </span>
                                );
                              })}
                              &nbsp;/ {listV.orderCount}개
                            </ProductColor>
                            <Price>{numberWithCommas(listV.mog_dis_price * 1)}원</Price>
                          </TextBox>
                        </ProductInfoWrap>
                      </ListInner>
                    </List>
                  );
                })}
              </ListBox>
            </Contents>
          ) : (
            <ContentNone>
              {tabIndex === "0" ? (
                <NoneText>주문 내역이 없습니다.</NoneText>
              ) : tabIndex === "1" ? (
                <NoneText>배송중인 내역이 없습니다.</NoneText>
              ) : tabIndex === "2" ? (
                <NoneText>배송완료 내역이 없습니다.</NoneText>
              ) : tabIndex === "3" ? (
                <NoneText>취소/환불 내역이 없습니다.</NoneText>
              ) : null}
            </ContentNone>
          )}
        </ContentsWrap>
      </Wrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (50 / 428));
  }
`;
const Wrap = styled.div`
  width: 100%;
`;
const TabBox = styled.div`
  position: relative;
  display: flex;
  font-weight: 500;
  text-align: center;
  border-bottom: 1px solid #eee;

  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;

const ContentsWrap = styled.div`
  @media screen and (max-width: 930px) {
    background-color: #f9f9f9;
  }
`;
const Contents = styled.div`
  padding: 25px 0 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (25 / 1320)) 0 0;
  }
  @media screen and (max-width: 930px) {
    min-height: calc(100vh - calc(100vw * (105 / 428)));
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (129 / 428));
  }
`;

const ListBox = styled.ul``;
const List = styled.li`
  &:not(:last-child) {
    margin: 0 auto 25px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin: 0 auto calc(100vw * (25 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (398 / 428));
    margin: 0 auto;
    &:not(:last-child) {
      margin: 0 auto calc(100vw * (30 / 428));
    }
  }
`;

const ListTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 930px) {
    align-items: flex-end;
  }
`;
const DateWrap = styled.div`
  display: flex;
  align-items: center;
  & span:not(:last-child)::after {
    content: "";
    display: block;
    width: 1px;
    height: 12px;
    margin: 0 12px;
    background-color: #dddddd;
  }
  @media screen and (max-width: 1320px) {
    & span:not(:last-child)::after {
      height: calc(100vw * (12 / 1320));
      margin: 0 calc(100vw * (12 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    display: block;
    & span:not(:last-child)::after {
      display: none;
    }
    & span:not(:last-child) {
      margin-bottom: calc(100vw * (5 / 428));
    }
  }
`;
const Date = styled.span`
  display: flex;
  align-items: center;
  color: #333;
  font-weight: 500;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const OrderNum = styled(Date)`
  /* 드래그 허용 */
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-user-drag: text;
`;
const DetailButton = styled.button`
  color: #777;
  font-weight: 500;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

const ListInner = styled.div`
  width: 100%;
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #eee;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (10 / 428));
    border: 0;
  }
`;
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 20px;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (25 / 1320)) calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428));
  }
`;

const ImgBox = styled.div`
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 1px solid #eee;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    height: calc(100vw * (100 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    height: calc(100vw * (100 / 428));
  }
`;
const TextBox = styled.div`
  width: calc(100% - 112px);
  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (112 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (246 / 428));
  }
`;
const ProductIng = styled.p`
  font-weight: 600;
  ${({ textColor }) => {
    if (textColor === "black") return `color: #111;`;
    else if (textColor === "pink") return `color: #FF006C;`;
  }}
  font-size: 16px;
  margin-bottom: 12px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-bottom: calc(100vw * (12 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (12 / 428));
  }
`;
const Brand = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (6 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (6 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProductColor = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;
  word-break: keep-all;
  
  & > span {
    color: #777;
  }
  & > span::after:not(:last-child) {
    content: "/";
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (4 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (4 / 428));
  }
`;
const Price = styled.p`
  color: #333;
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;

const ContentNone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 930px) {
    height: calc(100vh - calc(100vw * (105 / 428)));
  }
`;
const NoneText = styled.p`
  color: #999;
  font-weight: 400;
  font-size: 16px;
  padding: 200px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (200 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (90 / 428));
    padding: 0;
  }
`;
