import React from "react";
import { PC, Mobile } from "../../components/common/mediaStlye";
import Header from "../../components/common/header/Header";
import SignUpStylist from "../../components/auth/signup/SignUpStylist";
import Footer from "../../components/common/footer/Footer";

const SignUpStylistPage = () => {
  return (
    <div>
      <Header />
      <Mobile>
        <Header />
      </Mobile>
      <SignUpStylist />
      <Footer />
    </div>
  );
};

export default SignUpStylistPage;
