import React from "react";
import styled from "styled-components";
import { PC } from "MediaQuery";
import SelectBoxArrow from "store_src/img/icon/icon_selectBox_arrow.svg";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { crypto } from "utils";

const filterPItems = ["전체", "5만원 이하", "5만원 ~ 10만원", "10만원 ~ 20만원", "20만원 이상"];

const FILTER_LIST = {
  0: "최신순",
  1: "인기순",
  2: "가격 낮은순",
  3: "가격 높은순",
};

export default function PcProductFilterSection() {
  const [isOpenFilter, setIsOpenFilter] = React.useState(false);
  const location = useLocation();
  const { p, s } = queryString.parse(location.search);
  const decodedP = p ? crypto.decryptionBASE64(p) : 0;
  const decodedS = s ? crypto.decryptionBASE64(s) : 0;

  const params = new URLSearchParams(location.search);
  const history = useHistory();

  React.useEffect(() => {
    setIsOpenFilter(false);
  }, []);

  const handleClickPriceFilter = (index) => {
    const strIndex = index.toString();
    if (decodedP === 0) {
      params.set("p", crypto.encryptionBASE64(strIndex));
      return history.replace(`${location.pathname}?${params.toString()}`);
    }
    if (decodedP !== 0) {
      params.set("p", crypto.encryptionBASE64(strIndex));
      return history.replace(`${location.pathname}?${params.toString()}`);
    }
  };

  const handleClickListFilter = (index) => {
    const strIndex = index.toString();
    const queryP = params.get("s");
    setIsOpenFilter(false);

    if (!queryP) {
      params.set("s", crypto.encryptionBASE64(strIndex));
      return history.replace(`${location.pathname}?${params.toString()}`);
    }
    params.set("s", crypto.encryptionBASE64(strIndex));
    return history.replace(`${location.pathname}?${params.toString()}`);
  };

  return (
    <PC>
      <S.PcProductFIlterSection>
        <AllBrandFilterPriceListWrap>
          <AllBrandFilterPriceListBox>
            {filterPItems.map((itemP, index) => {
              return (
                <AllBrandFilterPriceList key={itemP} active={Number(decodedP) === index} onClick={() => handleClickPriceFilter(index)}>
                  <span>{itemP}</span>
                </AllBrandFilterPriceList>
              );
            })}
          </AllBrandFilterPriceListBox>
        </AllBrandFilterPriceListWrap>

        <SelectBoxWrap tabIndex="0" onBlur={() => {}}>
          <SelectedButton
            onClick={() => {
              setIsOpenFilter((prev) => !prev);
            }}
          >
            <SelectedButtonText active={false}>{FILTER_LIST[decodedS]}</SelectedButtonText>
          </SelectedButton>

          <SelectedArrowImg
            onClick={() => {
              setIsOpenFilter((prev) => !prev);
            }}
            active={!isOpenFilter}
            src={SelectBoxArrow}
            alt="selectArrow"
          />

          {isOpenFilter && (
            <SelectListBox>
              {Object.entries(FILTER_LIST).map((el, index) => {
                return (
                  <SelectBtn
                    key={el[0]}
                    onClick={() => {
                      handleClickListFilter(index);
                    }}
                  >
                    {el[1]}
                  </SelectBtn>
                );
              })}
            </SelectListBox>
          )}
        </SelectBoxWrap>
      </S.PcProductFIlterSection>
    </PC>
  );
}

const SelectBtn = styled.li`
  width: 100%;
  color: #888;
  font-size: 16px;
  text-align: center;
  padding: 8px 0;
  cursor: pointer;

  &:hover {
    background: #f7f7f7;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (8 / 1320)) 0;
  }
`;

const SelectListBox = styled.ul`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dddddd;

  @media screen and (max-width: 1320px) {
    top: calc(100% + 100vw * (8 / 1320));
    max-height: calc(100vw * (304 / 1320));
  }
`;

const SelectedArrowImg = styled.img`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  transition: ease 0.3s all;
  ${({ active }) => {
    return (
      active &&
      `
        transform : translateY(-50%) rotate(180deg);
    `
    );
  }}

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (10 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
`;

const SelectedButtonText = styled.p`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
  transition: ease 0.3s all;
  padding-left: 5px;

  ${({ active }) => {
    return (
      active &&
      `
        color: #000;
    `
    );
  }}

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;

const SelectedButton = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background-color: #fff;
  border: 1px solid #dddddd;
  cursor: pointer;
  transition: ease 0.3s all;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (40 / 1320));
    padding: 0 calc(100vw * (10 / 1320));
  }
`;

const AllBrandFilterPriceList = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 20px;
  }

  ${({ active }) =>
    active &&
    `
 & span {
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-6deg);
      z-index: 0;
      display: block;
      width: 100%;
      height: 40%;
      background-color: #fdf251;
    }
`}

  & span {
    color: #333333;
    font-weight: 500;
    font-size: 16px;
  }
  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (5 / 1320));
    &:not(:last-child) {
      margin-right: calc(100vw * (20 / 1320));
    }
    & span {
      font-size: calc(100vw * (16 / 1320));
    }
  }
`;

const AllBrandFilterPriceListBox = styled.ul`
  display: flex;
  align-items: center;
`;

const SelectBoxWrap = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 145px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (145 / 1320));
  }
`;

const AllBrandFilterPriceListWrap = styled.div``;

const S = {
  PcProductFIlterSection: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;

    @media screen and (max-width: 1320px) {
      margin-bottom: calc(100vw * (30 / 1320));
    }

    @media screen and (max-width: 930px) {
      margin-bottom: 0;
    }
  `,
};
