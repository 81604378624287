//react
import { useHistory } from "react-router-dom";
import { PC, Mobile } from "../../../MediaQuery";
import { useEffect, useState } from "react";
import styled from "styled-components";

//img
import IconCancel from "../../../img/icon/icon_myhaeder_cancle.svg";
import IconSelectArrow from "../../../img/icon/icon_bottom_arrow.svg";
import ProductImg from "../../../img/product/pay_product_img1.png";
import IconFileUpload from "../../../img/icon/icon_upload.svg";
import IconFileUploadCancel from "../../../img/icon/icon_upload_cancel.svg";
import IconChkOn from "../../../img/icon/icon_chk_on.svg";
import IconChkOff from "../../../img/icon/icon_chk_off.svg";

// controller
import serverController, { ip } from "../../../server/serverController";

import MyPageBottomModal from "../../../page/mypage/MyPageBottomModal";
import PayAddressModal from "../../common/modal/PayAdressModal";
import Commas from "../../../server/Commas";
import { imgURL } from "../../../server/appInfoController";
import { InfoText } from "../../login/auth.elements";

// mypage bottom data
const myPageBottomTextList = [
  <>
    - 상품 수령 후 7일 이내 접수 된 교환/반품 유효합니다.
    <br />
    - 수령 후 세탁, 향수 사용 등 상품의 가치가 하락한 경우에는 교환이나 반품이 불가합니다.
    <br />
    - 받으신 택배사가 아닌 다른 택배사를 이용하여 반품하실 경우 추가 비용이 발생 할 수 있습니다.
    <br />
    - 구매 하실 때 할인 받으셨던 쿠폰 할인액은 반환 됩니다.
    <br />
    - 제품의 택(tag)이 분실/훼손 된 경우 반품이 불가능 합니다.
    <br />
    - 사은품을 사용하셨거나 분실한 경우 반품이 불가능 합니다.
    <br />
    - 제품 박스를 분실하거나 훼손한 경우 반품이 거절 되거나 비용이 청구 될 수 있습니다.
    <br />
    - 교환의 경우, 브랜드사의 기준에 따라 선수거 후 교환 출고가 진행될 수 있습니다.
    <br />
    - 교환 택배비는 고객센터로 문의 주시면 상담원이 확인하여 안내 드리겠습니다.
    <br />- 교환 상품이 품절 될 경우 취소 안내를 받을 수 있습니다.
  </>,
];

export default function OrderReturnModal({ setIsOrderReturnModal, clickOrderItem, clickState }) {
  // clickState : 0 - 반품하기, 1 - 교환하기

  const history = useHistory();

  // modal control
  const [isModal, setIsModal] = useState(null);

  const [inquiryText, setInquiryText] = useState("");
  const [optionList, setOptionList] = useState([]);

  // return check
  const [optionId, setOptionId] = useState(-1);
  const [optionLast, setOptionLast] = useState("");

  // get inventory info
  const getInventoryInfo = () => {
    serverController.connectFetchController(
      `product/inventory/${clickOrderItem.prd_id}`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setOptionList(res.option);
          console.log(optionList, "optionList");
        } else {
          alert("잘못된 접근입니다. 문제가 계속 될 경우 관리자에게 문의바랍니다.");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  // post trade
  const postTradeInfo = () => {
    if (inquiryText.length <= 10) return alert(`상세 사유를 10자 이상 입력해주세요.`);
    if (clickState === 1 && optionId === "-1") return alert(`교환 옵션을 선택해주세요.`);
    if (clickState === 1 && optionLast === "N") return alert(`올바른 교환 옵션을 선택해주세요.`);

    const header = new Headers();
    header.append("Content-Type", "application/json");
    const rawBody = JSON.stringify({
      mogId: clickOrderItem.mog_id,
      mogOrderStatus: clickState === 0 ? 50 : 57,
      reason: inquiryText,
      optId: clickState === 0 ? null : optionId,
      count: clickState === 0 ? null : clickOrderItem.orderCount,
    });

    const options = {
      method: "POST",
      body: rawBody,
      headers: header,
      redirect: "follow",
    };

    fetch(`${ip}payment/trade`, options)
      .then((response) => response.json())
      .then((result) => {
        if (result.result === 1) {
          alert(result.message);
          setIsOrderReturnModal(false);
        } else {
          alert("다시 시도해주세요.");
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getInventoryInfo();
  }, []);

  return (
    <Container>
      <Background onClick={() => setIsOrderReturnModal(false)} />
      <Content isModal={isModal}>
        <HeaderSection>
          <HeaderInner>
            <p>{clickState === 0 ? "상품 반품" : "상품 교환"}</p>
            <CloseButton
              onClick={() => {
                setIsOrderReturnModal(false);
              }}
            >
              <img src={IconCancel} alt="cancel-icon" />
            </CloseButton>
          </HeaderInner>
        </HeaderSection>

        <Wrap isModal={isModal}>
          <ExchangeContainer>
            {isModal && <PayAddressModal setIsModal={setIsModal} />}
            <ExchangeWrap>
              <PC>
                <SectionLeft>
                  {/* product info */}
                  <SectionInfo>
                    <SectionInner>
                      <ProductInfoWrap>
                        <ImgBox
                          onClick={() => {
                            history.push(`/store/detail/${clickOrderItem.prd_id}`);
                          }}
                        >
                          <img
                            src={
                              clickOrderItem.prd_thumbnail
                                ? `${imgURL}${clickOrderItem.prd_thumbnail}`
                                : ProductImg
                            }
                            alt={
                              clickOrderItem.prd_thumbnail
                                ? "product-thumbnail"
                                : "product-default-img"
                            }
                          />
                        </ImgBox>
                        <TextBox>
                          <TextBoxInner>
                            <ProductName
                              onClick={() => {
                                history.push(`/store/detail/${clickOrderItem.prd_id}`);
                              }}
                            >
                              {clickOrderItem.prd_name}
                            </ProductName>
                            {clickOrderItem?.optionInfo?.length > 0 &&
                              clickOrderItem?.optionInfo?.map((optionItem, idx) => {
                                return (
                                  <ProductColor key={optionItem.optName + "-" + idx}>
                                    {optionItem.optName + " / " + clickOrderItem.orderCount + "개"}
                                  </ProductColor>
                                );
                              })}
                            <Price>
                              {Commas.numberWithCommas(clickOrderItem?.prd_list_price)}원
                            </Price>
                          </TextBoxInner>
                        </TextBox>
                      </ProductInfoWrap>
                    </SectionInner>
                  </SectionInfo>

                  <MyPageBottomModal textList={myPageBottomTextList} />

                  <BtnWrap>
                    <Btn type="button" onClick={postTradeInfo}>{clickState === 0 ? "반품" : "교환"} 요청하기</Btn>
                  </BtnWrap>
                </SectionLeft>

                <SectionRight>
                  {/* form */}
                  <SectionReason>
                    <SectionInner>
                      {clickState === 1 &&
                        <FormList>
                          <FormTitleWrap>
                            <ListTitle>교환옵션</ListTitle>
                          </FormTitleWrap>
                          <SelectButton
                            defaultValue={-1}
                            borderActive={optionId !== -1}
                            onChange={(e) => {
                              setOptionId(e.target.value);
                              let dataLast = e.target.options[e.target.selectedIndex].dataset.last;
                              setOptionLast(dataLast);
                            }}
                          >
                            {optionList &&
                              optionList.map((item) => {
                                return (
                                  <option data-last={item?.opt_last} key={item?.opt_id} value={item?.opt_id} disabled={item.opt_inventory === 0}>
                                    {`[${item.opt_title}] ${item.opt_name} (잔여 수량 : ${item.opt_inventory})`}
                                  </option>
                                );
                              })}
                          </SelectButton>
                        </FormList>
                      }
                      <FormList>
                        <FormTitleWrap>
                          <ListTitle>상세사유(선택)</ListTitle>
                        </FormTitleWrap>
                        <InquiryTextWrap>
                          <InquiryTextArea
                            value={inquiryText}
                            active={inquiryText.length > 0}
                            placeholder="상세 사유를 입력해 주세요."
                            onChange={(e) => {
                              setInquiryText(e.target.value);
                            }}
                          />
                        </InquiryTextWrap>
                      </FormList>
                    </SectionInner>
                    <>
                      {/* {photoSection ? (
                      <ImageWrap>
                        <FormTitleWrap>
                          <ListTitle>사진첨부하기(선택)</ListTitle>
                          <ListSubTitle>*최대3장</ListSubTitle>
                        </FormTitleWrap>
                        <ImageInner>
                          {imageArr.length < 3 && (
                            <ImageList>
                              <ImageBox>
                                <FileReviseBtnWrap>
                                  <FileReviseBtn
                                    type="file"
                                    id="filePic"
                                    accept="image/*"
                                    onChange={(e) => {
                                      if (
                                        e.target.files.length +
                                          imageArr.length >
                                        3
                                      ) {
                                        return alert(
                                          "파일은 최대 3개까지 선택 가능합니다."
                                        );
                                      }
                                      handleImageUpload(e);
                                      uploadImage(e);
                                    }}
                                  />
                                  <FileBtnLabel for="filePic" />
                                </FileReviseBtnWrap>
                              </ImageBox>
                            </ImageList>
                          )}
                          {previewImageArr.map((_, index) => {
                            return (
                              <ImageList key={index + "-img"}>
                                <UploadImageBox>
                                  <UploadDeleteButton
                                    onClick={() => {
                                      deleteButtonClick(index);
                                    }}
                                  />
                                  <UploadImage
                                    alt="previewImg"
                                    src={previewImageArr[index]}
                                  />
                                </UploadImageBox>
                              </ImageList>
                            );
                          })}
                        </ImageInner>
                      </ImageWrap>
                    ) : (
                      <InfoText>
                        * 이미지 업로드는 '사진 접근 허용' 후 이용 가능합니다.
                      </InfoText>
                    )} */}
                    </>
                  </SectionReason>

                  {/* 반품/교환 >> 수거방법 */}
                  {/* <Section>
                    <SectionChkInner>
                      <OrderTitleBox>
                        <OrderTitle>수거방법</OrderTitle>
                      </OrderTitleBox>
                      <OrderInfoListInner>
                        <ChkListBox>
                          <ChkList>
                            <ChkButton>
                              <ChkInput
                                name="return-way"
                                type="radio"
                                id="chk-way-1"
                                checked={returnWay === 0 ? true : false}
                                onChange={() => {
                                  setReturnWay(0);
                                }}
                              />
                              <ChkLabel htmlFor="chk-way-1">
                                <ChkImg className="chk-img" />
                                <ChkText>수거해주세요</ChkText>
                              </ChkLabel>
                            </ChkButton>
                          </ChkList>
                          <ChkList>
                            <ChkButton>
                              <ChkInput
                                name="return-way"
                                type="radio"
                                id="chk-way-2"
                                checked={returnWay === 1 ? true : false}
                                onChange={() => {
                                  setReturnWay(1);
                                }}
                              />
                              <ChkLabel htmlFor="chk-way-2">
                                <ChkImg className="chk-img" />
                                <ChkText>직접 보낼게요</ChkText>
                              </ChkLabel>
                            </ChkButton>
                          </ChkList>
                        </ChkListBox>
                      </OrderInfoListInner>
                    </SectionChkInner>
                  </Section> */}

                  {/* 반품 >> 반품 비용 */}
                  <>
                    {/*} {clickState === 0 ? (
                    <>
                      <Section>
                        <SectionChkInner>
                          <OrderTitleBox>
                            <OrderTitle>반품 비용</OrderTitle>
                            <OrderTitle>5,000원</OrderTitle>
                          </OrderTitleBox>
                          <OrderInfoListInner>
                            <ChkListBox>
                              <ChkList>
                                <ChkButton>
                                  <ChkInput
                                    name="return-amount"
                                    type="radio"
                                    id="chk-amount-1"
                                    onChange={() => {
                                      setReturnAmountCheck(0);
                                    }}
                                    checked={
                                      returnAmountCheck === 0 ? true : false
                                    }
                                  />
                                  <ChkLabel htmlFor="chk-amount-1">
                                    <ChkImg className="chk-img" />
                                    <ChkText>환불금에서 차감</ChkText>
                                  </ChkLabel>
                                </ChkButton>
                              </ChkList>
                              <ChkList>
                                <ChkButton>
                                  <ChkInput
                                    name="return-amount"
                                    type="radio"
                                    id="chk-amount-2"
                                    onChange={() => {
                                      setReturnAmountCheck(1);
                                    }}
                                    checked={
                                      returnAmountCheck === 1 ? true : false
                                    }
                                  />
                                  <ChkLabel htmlFor="chk-amount-2">
                                    <ChkImg className="chk-img" />
                                    <ChkText>쇼핑몰 계좌로 송금</ChkText>
                                  </ChkLabel>
                                </ChkButton>
                                {returnAmountCheck === 1 && (
                                  <BankInfo>
                                    <BankInfoListBox>
                                      <BankInfoList>
                                        <BankInfoTitle>계좌</BankInfoTitle>
                                        <BankInfoText>
                                          농협 012345678901234
                                        </BankInfoText>
                                      </BankInfoList>
                                      <BankInfoList>
                                        <BankInfoTitle>예금주</BankInfoTitle>
                                        <BankInfoText>왈라</BankInfoText>
                                      </BankInfoList>
                                    </BankInfoListBox>
                                  </BankInfo>
                                )}
                              </ChkList>
                            </ChkListBox>
                          </OrderInfoListInner>
                        </SectionChkInner>
                      </Section>
                      <Section>
                        <SectionInner>
                          <OrderTitleBox>
                            <OrderTitle>환불 예상금액</OrderTitle>
                          </OrderTitleBox>
                          <OrderInfoListInner>
                            <OrderInfoListBox>
                              <OrderInfoListBoxInner>
                                <OrderInfoList>
                                  <OrderSubTitleBold>
                                    취소 예상 금액
                                  </OrderSubTitleBold>
                                  <OrderTextBold>
                                    {Commas.numberWithCommas(
                                      clickOrderItem.mog_dis_price
                                    )}
                                    원
                                  </OrderTextBold>
                                </OrderInfoList>
                              </OrderInfoListBoxInner>
                            </OrderInfoListBox>
                            <OrderInfoListBox>
                              <OrderInfoListBoxInner>
                                <OrderInfoList>
                                  <OrderSubTitle>상품 금액</OrderSubTitle>
                                  <OrderText>
                                    {Commas.numberWithCommas(
                                      clickOrderItem.prd_list_price
                                    )}
                                    원
                                  </OrderText>
                                </OrderInfoList>
                                {clickOrderItem.txn_used_point !== 0 && (
                                  <OrderInfoList>
                                    <OrderSubTitle>포인트 사용</OrderSubTitle>
                                    <OrderText>
                                      (-)
                                      {Commas.numberWithCommas(
                                        clickOrderItem.txn_used_point
                                      )}
                                      원
                                    </OrderText>
                                  </OrderInfoList>
                                )}
                                {clickOrderItem.coupon_discount !== 0 && (
                                  <OrderInfoList>
                                    <OrderSubTitle>쿠폰 사용</OrderSubTitle>
                                    <OrderText>
                                      (-)
                                      {Commas.numberWithCommas(
                                        clickOrderItem.coupon_discount
                                      )}
                                      원
                                    </OrderText>
                                  </OrderInfoList>
                                )}
                              </OrderInfoListBoxInner>
                            </OrderInfoListBox>
                          </OrderInfoListInner>
                        </SectionInner>
                      </Section>
                      <Section>
                        <SectionInner>
                          <OrderTitleBox>
                            <OrderTitle>환불받을 계좌</OrderTitle>
                          </OrderTitleBox>
                          <OrderInfoListInner>
                            <BankListBox>
                              <BankList>
                                <InputText>예금주</InputText>
                                <Input
                                  type="text"
                                  value={userName}
                                  active={userName.length > 0}
                                  placeholder="예금주명을 입력해주세요."
                                  onChange={(e) => {
                                    setUserName(e.target.value);
                                  }}
                                />
                              </BankList>
                              <BankList>
                                <InputText>은행</InputText>
                                <SelectButton
                                  defaultValue="select"
                                  borderActive={selectedValue !== "selectT선택"}
                                  onChange={(e) => {
                                    setSelectedValue(e.target.value);
                                  }}
                                >
                                  {bankList.map((item) => {
                                    return (
                                      <option
                                        key={item.value}
                                        value={item.value + "T" + item.title}
                                      >
                                        {item.title}
                                      </option>
                                    );
                                  })}
                                </SelectButton>
                              </BankList>
                              <BankList>
                                <InputText>계좌번호</InputText>
                                <Input
                                  type="number"
                                  value={bankNum}
                                  active={bankNum.length > 0}
                                  placeholder="계좌번호를 입력해주세요."
                                  onChange={(e) => {
                                    if (e.target.value.length > 20) return;
                                    setBankNum(e.target.value);
                                  }}
                                />
                              </BankList>
                            </BankListBox>
                          </OrderInfoListInner>
                        </SectionInner>
                      </Section>
                    </>
                  ) : (
                    <>
                      <Section>
                        <SectionChkInner>
                          <OrderTitleBox>
                            <OrderTitle>교환 비용</OrderTitle>
                            <OrderTitle>5,000원</OrderTitle>
                          </OrderTitleBox>
                          <OrderInfoListInner>
                            <ChkListBox>
                              <ChkList>
                                <ChkButton>
                                  <ChkInput
                                    name="exchange-amount"
                                    type="radio"
                                    id="chk-exchange-1"
                                    onChange={() => {
                                      setReturnAmountCheck(0);
                                    }}
                                    checked={
                                      returnAmountCheck === 0 ? true : false
                                    }
                                  />
                                  <ChkLabel htmlFor="chk-exchange-1">
                                    <ChkImg className="chk-img" />
                                    <ChkText>상품과 함께 동봉</ChkText>
                                  </ChkLabel>
                                </ChkButton>
                              </ChkList>
                              <ChkList>
                                <ChkButton>
                                  <ChkInput
                                    name="exchange-amount"
                                    type="radio"
                                    id="chk-exchange-2"
                                    onChange={() => {
                                      setReturnAmountCheck(1);
                                    }}
                                    checked={
                                      returnAmountCheck === 1 ? true : false
                                    }
                                  />
                                  <ChkLabel htmlFor="chk-exchange-2">
                                    <ChkImg className="chk-img" />
                                    <ChkText>쇼핑몰 계좌로 송금</ChkText>
                                  </ChkLabel>
                                </ChkButton>
                                {returnAmountCheck === 1 && (
                                  <BankInfo>
                                    <BankInfoListBox>
                                      <BankInfoList>
                                        <BankInfoTitle>계좌</BankInfoTitle>
                                        <BankInfoText>
                                          농협 012345678901234
                                        </BankInfoText>
                                      </BankInfoList>
                                      <BankInfoList>
                                        <BankInfoTitle>예금주</BankInfoTitle>
                                        <BankInfoText>왈라</BankInfoText>
                                      </BankInfoList>
                                    </BankInfoListBox>
                                  </BankInfo>
                                )}
                              </ChkList>
                            </ChkListBox>
                          </OrderInfoListInner>
                        </SectionChkInner>
                      </Section>
                      <SectionAddressInfo>
                        <SectionChkInner>
                          <OrderTitleBox>
                                <OrderTitle>배송지 정보</OrderTitle> */}
                    {/* <AddressChangeButton
                              type="button"
                              onClick={() => {
                                setIsModal(true);
                                setSameAddress(false);
                              }}
                            >
                              변경
                            </AddressChangeButton> */}
                    {/* </OrderTitleBox>
                          <OrderInfoListInner>
                            <AddressInfoBox>
                              <AddressInfo>
                                <AddressTitleWrap>
                                  <AddressTitle>{addressTitle}</AddressTitle>
                                  {addressReceiver === 1 && (
                                    <AddressDefault>기본배송지</AddressDefault>
                                  )}
                                </AddressTitleWrap>
                                <AddressText>
                                  {postCode && [{ postCode }]} {address}
                                </AddressText>
                                <AddressName>
                                  {addressName + " " + addressPhone}
                                </AddressName>
                              </AddressInfo>
                              <SelectButton
                                borderActive={
                                  selectedAddressValue !==
                                  "배송시 요청사항을 선택해주세요."
                                }
                                onChange={(e) => {
                                  setSelectedAddressValue(e.target.value);
                                }}
                              >
                                {addressList.map((item) => {
                                  return (
                                    <option key={item} value={item}>
                                      {item}
                                    </option>
                                  );
                                })}
                              </SelectButton>
                            </AddressInfoBox>
                          </OrderInfoListInner>
                        </SectionChkInner>
                      </SectionAddressInfo>
                    </>
                  )}*/}
                  </>
                  <InfoText>
                    * 사용하신 쿠폰/포인트는 구매하신 상품 모두 반품하실 때 반환됩니다. 자세한
                    사항은 고객센터로 문의주세요.
                  </InfoText>
                </SectionRight>
              </PC>
              <Mobile>
                {/* product info */}
                <Section>
                  <SectionInner>
                    <ProductInfoWrap>
                      <ImgBox
                        onClick={() => {
                          history.push(`/store/detail/${clickOrderItem.prd_id}`);
                        }}
                      >
                        <img
                          src={
                            clickOrderItem.prd_thumbnail
                              ? `${imgURL}${clickOrderItem.prd_thumbnail}`
                              : ProductImg
                          }
                          alt={
                            clickOrderItem.prd_thumbnail
                              ? "product-thumbnail"
                              : "product-default-img"
                          }
                        />
                      </ImgBox>
                      <TextBox>
                        <TextBoxInner>
                          <ProductName
                            onClick={() => history.push(`/store/detail/${clickOrderItem.prd_id}`)}
                          >
                            {clickOrderItem.prd_name}
                          </ProductName>
                          {clickOrderItem?.optionInfo?.length > 0 &&
                            clickOrderItem?.optionInfo?.map((optionItem, idx) => {
                              return (
                                <ProductColor key={optionItem.optName + "-" + idx}>
                                  {optionItem.optName + " / " + clickOrderItem.orderCount + "개"}
                                </ProductColor>
                              );
                            })}
                          <Price>
                            {" "}
                            {Commas.numberWithCommas(clickOrderItem?.prd_list_price)}원
                          </Price>
                        </TextBoxInner>
                      </TextBox>
                    </ProductInfoWrap>
                  </SectionInner>
                </Section>

                {/* form */}
                <Section>
                  <SectionInner>
                    {clickState !== 0 && (
                      <FormList>
                        <SelectButton
                          defaultValue={-1}
                          borderActive={optionId !== -1}
                          onChange={(e) => {
                            setOptionId(e.target.value);
                            let dataLast = e.target.options[e.target.selectedIndex].dataset.last;
                            setOptionLast(dataLast);
                          }}
                        >
                          {optionList &&
                            optionList.map((item) => {
                              return (
                                <option
                                  data-last={item?.opt_last}
                                  key={item?.opt_id}
                                  value={item?.opt_id}
                                  disabled={item.opt_inventory === 0}
                                >
                                  {`[${item.opt_title}${item.opt_inventory === 0 ? "_품절" : ""}] ${item.opt_name
                                    } (잔여 수량 : ${item.opt_inventory})`}
                                </option>
                              );
                            })}
                        </SelectButton>
                      </FormList>
                    )}
                    <FormList>
                      <InquiryTextWrap>
                        <InquiryTextArea
                          value={inquiryText}
                          active={inquiryText.length > 0}
                          placeholder="상세 사유를 입력해 주세요."
                          onChange={(e) => {
                            setInquiryText(e.target.value);
                          }}
                        />
                      </InquiryTextWrap>
                    </FormList>
                  </SectionInner>
                  <>
                    {/* {photoSection ? (
                    <ImageWrap>
                      <FormTitleWrap>
                        <ListTitle>사진첨부하기(선택)</ListTitle>
                        <ListSubTitle>*최대3장</ListSubTitle>
                      </FormTitleWrap>
                      <ImageInner>
                        {imageArr.length < 3 && (
                          <ImageList>
                            <ImageBox>
                              <FileReviseBtnWrap>
                                <FileReviseBtn
                                  type="file"
                                  id="filePic"
                                  accept="image/*"
                                  onChange={(e) => {
                                    if (
                                      e.target.files.length + imageArr.length >
                                      3
                                    ) {
                                      return alert(
                                        "파일은 최대 3개까지 선택 가능합니다."
                                      );
                                    }
                                    handleImageUpload(e);
                                    uploadImage(e);
                                  }}
                                />
                                <FileBtnLabel for="filePic" />
                              </FileReviseBtnWrap>
                            </ImageBox>
                          </ImageList>
                        )}
                        {previewImageArr.map((_, index) => {
                          return (
                            <ImageList key={index + "-img"}>
                              <UploadImageBox>
                                <UploadDeleteButton
                                  onClick={() => {
                                    deleteButtonClick(index);
                                  }}
                                />
                                <UploadImage
                                  alt="previewImg"
                                  src={previewImageArr[index]}
                                />
                              </UploadImageBox>
                            </ImageList>
                          );
                        })}
                      </ImageInner>
                    </ImageWrap>
                  ) : (
                    <InfoText>
                      * 이미지 업로드는 '사진 접근 허용' 후 이용 가능합니다.
                    </InfoText>
                  )} */}
                  </>
                </Section>

                {/* 반품/교환 >> 수거방법 */}
                {/* <Section>
                  <SectionChkInner>
                    <OrderTitleBox>
                      <OrderTitle>수거방법</OrderTitle>
                    </OrderTitleBox>
                    <OrderInfoListInner>
                      <ChkListBox>
                        <ChkList>
                          <ChkButton>
                            <ChkInput
                              name="return-way"
                              type="radio"
                              id="chk-way-1"
                              checked={returnWay === 0}
                              onChange={() => {
                                setReturnWay(0);
                              }}
                            />
                            <ChkLabel htmlFor="chk-way-1">
                              <ChkImg className="chk-img" />
                              <ChkText>수거해주세요</ChkText>
                            </ChkLabel>
                          </ChkButton>
                        </ChkList>
                        <ChkList>
                          <ChkButton>
                            <ChkInput
                              name="return-way"
                              type="radio"
                              id="chk-way-2"
                              checked={returnWay === 1}
                              onChange={() => {
                                setReturnWay(1);
                              }}
                            />
                            <ChkLabel htmlFor="chk-way-2">
                              <ChkImg className="chk-img" />
                              <ChkText>직접 보낼게요</ChkText>
                            </ChkLabel>
                          </ChkButton>
                        </ChkList>
                      </ChkListBox>
                    </OrderInfoListInner>
                  </SectionChkInner>
                </Section> */}

                {clickState === 0 ? (
                  <>
                    {/* 반품 >> 반품 비용 */}
                    {/* <Section>
                      <SectionChkInner>
                        <OrderTitleBox>
                          <OrderTitle>반품 비용</OrderTitle>
                          <OrderTitle>5,000원</OrderTitle>
                        </OrderTitleBox>
                        <OrderInfoListInner>
                          <ChkListBox>
                            <ChkList>
                              <ChkButton>
                                <ChkInput
                                  name="return-amount"
                                  type="radio"
                                  id="chk-amount-1"
                                  onChange={() => {
                                    setReturnAmountCheck(0);
                                  }}
                                  checked={
                                    returnAmountCheck === 0 ? true : false
                                  }
                                />
                                <ChkLabel htmlFor="chk-amount-1">
                                  <ChkImg className="chk-img" />
                                  <ChkText>환불금에서 차감</ChkText>
                                </ChkLabel>
                              </ChkButton>
                            </ChkList>
                            <ChkList>
                              <ChkButton>
                                <ChkInput
                                  name="return-amount"
                                  type="radio"
                                  id="chk-amount-2"
                                  onChange={() => {
                                    setReturnAmountCheck(1);
                                  }}
                                  checked={
                                    returnAmountCheck === 1 ? true : false
                                  }
                                />
                                <ChkLabel htmlFor="chk-amount-2">
                                  <ChkImg className="chk-img" />
                                  <ChkText>쇼핑몰 계좌로 송금</ChkText>
                                </ChkLabel>
                              </ChkButton>
                              {returnAmountCheck === 1 && (
                                <BankInfo>
                                  <BankInfoListBox>
                                    <BankInfoList>
                                      <BankInfoTitle>계좌</BankInfoTitle>
                                      <BankInfoText>
                                        농협 012345678901234
                                      </BankInfoText>
                                    </BankInfoList>
                                    <BankInfoList>
                                      <BankInfoTitle>예금주</BankInfoTitle>
                                      <BankInfoText>왈라</BankInfoText>
                                    </BankInfoList>
                                  </BankInfoListBox>
                                </BankInfo>
                              )}
                            </ChkList>
                          </ChkListBox>
                        </OrderInfoListInner>
                      </SectionChkInner>
                    </Section> */}

                    {/* 반품 >> 환불 예상금액 */}
                    {/* <Section>
                      <SectionInner>
                        <OrderTitleBox>
                          <OrderTitle>환불 예상금액</OrderTitle>
                        </OrderTitleBox>
                        <OrderInfoListInner>
                          <OrderInfoListBox>
                            <OrderInfoListBoxInner>
                              <OrderInfoList>
                                <OrderSubTitleBold>
                                  취소 예상 금액
                                </OrderSubTitleBold>
                                <OrderTextBold>
                                  {" "}
                                  {Commas.numberWithCommas(
                                    clickOrderItem.mog_dis_price
                                  )}
                                  원
                                </OrderTextBold>
                              </OrderInfoList>
                            </OrderInfoListBoxInner>
                          </OrderInfoListBox>
                          <OrderInfoListBox>
                            <OrderInfoListBoxInner>
                              <OrderInfoList>
                                <OrderSubTitle>상품 금액</OrderSubTitle>
                                <OrderText>
                                  {" "}
                                  {Commas.numberWithCommas(
                                    clickOrderItem.prd_list_price
                                  )}
                                  원
                                </OrderText>
                              </OrderInfoList>
                              {clickOrderItem.txn_used_point !== 0 && (
                                <OrderInfoList>
                                  <OrderSubTitle>포인트 사용</OrderSubTitle>
                                  <OrderText>
                                    (-)
                                    {Commas.numberWithCommas(
                                      clickOrderItem.txn_used_point
                                    )}
                                    원
                                  </OrderText>
                                </OrderInfoList>
                              )}
                              {clickOrderItem.coupon_discount !== 0 && (
                                <OrderInfoList>
                                  <OrderSubTitle>쿠폰 사용</OrderSubTitle>
                                  <OrderText>
                                    (-)
                                    {Commas.numberWithCommas(
                                      clickOrderItem.coupon_discount
                                    )}
                                    원
                                  </OrderText>
                                </OrderInfoList>
                              )}
                            </OrderInfoListBoxInner>
                          </OrderInfoListBox>
                        </OrderInfoListInner>
                      </SectionInner>
                                    </Section> */}

                    {/* 반품 >> 환불받을 계좌 */}
                    {/*   <Section>
                      <SectionInner>
                        <OrderTitleBox>
                          <OrderTitle>환불받을 계좌</OrderTitle>
                        </OrderTitleBox>
                        <OrderInfoListInner>
                          <BankListBox>
                            <BankList>
                              <InputText>예금주</InputText>
                              <Input
                                type="text"
                                value={userName}
                                active={userName.length > 0}
                                placeholder="예금주명을 입력해주세요."
                                onChange={(e) => {
                                  setUserName(e.target.value);
                                }}
                              />
                            </BankList>
                            <BankList>
                              <InputText>은행</InputText>
                              <SelectButton
                                defaultValue="select"
                                borderActive={selectedValue !== "selectT선택"}
                                onChange={(e) => {
                                  setSelectedValue(e.target.value);
                                }}
                              >
                                {bankList.map((item) => {
                                  return (
                                    <option
                                      key={item.value}
                                      value={item.value + "T" + item.title}
                                    >
                                      {item.title}
                                    </option>
                                  );
                                })}
                              </SelectButton>
                            </BankList>
                            <BankList>
                              <InputText>계좌번호</InputText>
                              <Input
                                type="number"
                                value={bankNum}
                                active={bankNum.length > 0}
                                placeholder="계좌번호를 입력해주세요."
                                onChange={(e) => {
                                  if (e.target.value.length > 20) return;
                                  setBankNum(e.target.value);
                                }}
                              />
                            </BankList>
                          </BankListBox>
                        </OrderInfoListInner>
                      </SectionInner>
                              </Section> */}
                  </>
                ) : (
                  <>
                    {/* 교환 >> 교환 비용 */}
                    {/* <Section>
                      <SectionChkInner>
                        <OrderTitleBox>
                          <OrderTitle>교환 비용</OrderTitle>
                          <OrderTitle>5,000원</OrderTitle>
                        </OrderTitleBox>
                        <OrderInfoListInner>
                          <ChkListBox>
                            <ChkList>
                              <ChkButton>
                                <ChkInput
                                  name="exchange-amount"
                                  type="radio"
                                  id="chk-exchange-1"
                                  onChange={() => {
                                    setReturnAmountCheck(0);
                                  }}
                                  checked={
                                    returnAmountCheck === 0 ? true : false
                                  }
                                />
                                <ChkLabel htmlFor="chk-exchange-1">
                                  <ChkImg className="chk-img" />
                                  <ChkText>상품과 함께 동봉</ChkText>
                                </ChkLabel>
                              </ChkButton>
                            </ChkList>
                            <ChkList>
                              <ChkButton>
                                <ChkInput
                                  name="exchange-amount"
                                  type="radio"
                                  id="chk-exchange-2"
                                  onChange={() => {
                                    setReturnAmountCheck(1);
                                  }}
                                  checked={
                                    returnAmountCheck === 1 ? true : false
                                  }
                                />
                                <ChkLabel htmlFor="chk-exchange-2">
                                  <ChkImg className="chk-img" />
                                  <ChkText>쇼핑몰 계좌로 송금</ChkText>
                                </ChkLabel>
                              </ChkButton>
                              {returnAmountCheck === 1 && (
                                <BankInfo>
                                  <BankInfoListBox>
                                    <BankInfoList>
                                      <BankInfoTitle>계좌</BankInfoTitle>
                                      <BankInfoText>
                                        농협 012345678901234
                                      </BankInfoText>
                                    </BankInfoList>
                                    <BankInfoList>
                                      <BankInfoTitle>예금주</BankInfoTitle>
                                      <BankInfoText>왈라</BankInfoText>
                                    </BankInfoList>
                                  </BankInfoListBox>
                                </BankInfo>
                              )}
                            </ChkList>
                          </ChkListBox>
                        </OrderInfoListInner>
                      </SectionChkInner>
                    </Section> */}

                    {/* 교환 >> 배송지 정보 */}
                    {/*  <Section>
                      <SectionChkInner>
                        <OrderTitleBox>
                  <OrderTitle>배송지 정보</OrderTitle> */}
                    {/* <AddressChangeButton
                            type="button"
                            onClick={() => {
                              setIsModal(true);
                              setSameAddress(false);
                            }}
                          >
                            변경
                          </AddressChangeButton> */}
                    {/*  </OrderTitleBox>
                        <OrderInfoListInner>
                          <AddressInfoBox>
                            <AddressInfo>
                              <AddressTitleWrap>
                                <AddressTitle>{addressTitle}</AddressTitle>
                                {addressReceiver === 1 && (
                                  <AddressDefault>기본배송지</AddressDefault>
                                )}
                              </AddressTitleWrap>
                              <AddressText>
                                {postCode && [{ postCode }]} {address}
                              </AddressText>
                              <AddressName>
                                {addressName + " " + addressPhone}
                              </AddressName>
                            </AddressInfo>
                            <SelectButton
                              borderActive={
                                selectedAddressValue !==
                                "배송시 요청사항을 선택해주세요."
                              }
                              onChange={(e) => {
                                setSelectedAddressValue(e.target.value);
                              }}
                            >
                              {addressList.map((item) => {
                                return (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </SelectButton>
                          </AddressInfoBox>
                        </OrderInfoListInner>
                      </SectionChkInner>
                            </Section> </ExchangeContainer>*/}
                  </>
                )}
              </Mobile>
            </ExchangeWrap>

            <Mobile>
              <BtnWrap>
                <Btn type="button" onClick={postTradeInfo}>
                  {clickState === 0 ? "반품" : "교환"} 요청하기
                </Btn>
              </BtnWrap>
              <InfoTextBox>
                <InfoText>
                  * 사용하신 쿠폰/포인트는 구매하신 상품 모두 반품하실 때 반환됩니다. 자세한 사항은
                  고객센터로 문의주세요.
                </InfoText>
              </InfoTextBox>
            </Mobile>
          </ExchangeContainer>
        </Wrap>
      </Content>
    </Container>
  );
}

const ExchangeContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 0 30px;

  @media screen and (max-width: 1320px) {
    padding: 0 0 calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: 0 0 calc(100vw * (80 / 428));
  }
`;
const ExchangeWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1190px;
  height: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1190 / 1320));
  }
  @media screen and (max-width: 930px) {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const SectionLeft = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 428px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (428 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const SectionRight = styled.div`
  width: 742px;
  height: 100%;
  overflow: auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (742 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    height: auto;
  }
`;

const Section = styled.section`
  padding: 30px 0;
  &:not(:last-child) {
    border-bottom: 10px solid #f9f9f9;
  }

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const SectionInfo = styled.section`
  &:not(:last-child) {
    border-bottom: 10px solid #f9f9f9;
  }
`;
const SectionReason = styled.section`
  padding-bottom: 30px;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-bottom: 0;
  }
`;
const SectionAddressInfo = styled.section`
  padding-top: 30px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-top: 0;
  }
`;

const SectionInner = styled.div`
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const SectionChkInner = styled.div``;

const OrderTitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const OrderTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const OrderInfoListInner = styled.div`
  margin-top: 20px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;

// product info
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px 20px;

  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (10 / 1320)) calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;

const ImgBox = styled.div`
  width: 70px;
  height: 70px;
  background-color: #fff;
  border: 1px solid #eee;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (70 / 1320));
    height: calc(100vw * (70 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    height: calc(100vw * (100 / 428));
  }
`;
const TextBox = styled.div`
  width: calc(100% - 80px);
  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (80 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (266 / 428));
  }
`;
const TextBoxInner = styled.div``;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProductColor = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (4 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (4 / 428));
  }
`;
const Price = styled.p`
  color: #333;
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;

// form
const FormList = styled.div`
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (30 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 428));
    }
  }
`;

const FormTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;

  @media screen and (max-width: 1320px) {
    margin: 0 0 calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto calc(100vw * (8 / 428));
  }
`;
const ListTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const ListSubTitle = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;

// select
const SelectButton = styled.select`
  display: block;
  width: 100%;
  height: 50px;
  color: #acacac;
  font-weight: 400;
  font-size: 15px;
  background: #ffffff url(${IconSelectArrow}) no-repeat right 15px center / 24px;
  border: 1px solid #ddd;
  padding: 0 15px;
  border-radius: 0;

  -webkit-appearance: none; /* 네이티브 외형 감추기*/
  -moz-appearance: none;
  appearance: none;
  font-weight: normal;
  letter-spacing: normal;
  cursor: pointer;

  &.focus {
    color: #333;
    border: 1px solid #000;
  }

  ${({ borderActive }) =>
    borderActive &&
    `border: 1px solid #000;
  color: #333;
  `}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
    font-size: calc(100vw * (15 / 1320));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 1320)) center /
      calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 428)) center /
      calc(100vw * (24 / 428));
  }
`;
// input
const Input = styled.input`
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;
// textarea
const InquiryTextWrap = styled.div``;
const InquiryTextArea = styled.textarea`
  width: 100%;
  height: 198px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (198 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (200 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (15 / 428)) calc(100vw * (20 / 428));
  }
`;

// image upload
const ImageWrap = styled.div`
  margin-top: 30px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const ImageInner = styled.ul`
  display: flex;

  white-space: nowrap;
  overflow-y: visible;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  @media screen and (max-width: 930px) {
    padding-left: calc(100vw * (25 / 428));
  }
`;
const ImageList = styled.li`
  &:not(:last-child) {
    padding-right: 10px;
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      padding-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      padding-right: calc(100vw * (10 / 428));
    }
  }
`;
const ImageBox = styled.div``;

const UploadImageBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  background-color: #fff;
  border: 1px solid #eee;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    height: calc(100vw * (140 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (140 / 428));
    height: calc(100vw * (140 / 428));
  }
`;
const UploadImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const UploadDeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 28px;
  height: 28px;
  background: url(${IconFileUploadCancel}) no-repeat center / contain;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (28 / 1320));
    height: calc(100vw * (28 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (28 / 428));
    height: calc(100vw * (28 / 428));
  }
`;

// file upload
const FileReviseBtnWrap = styled.div``;
const FileReviseBtn = styled.input`
  display: none;
`;
const FileBtnLabel = styled.label`
  display: block;
  width: 140px;
  height: 140px;
  background: url(${IconFileUpload}) no-repeat center / contain;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    height: calc(100vw * (140 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (140 / 428));
    height: calc(100vw * (140 / 428));
  }
`;

// 취소 정보
const OrderInfoListBox = styled.div`
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eee;
  }
  &:not(:last-child)::after {
    margin: 15px 0;
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (15 / 1320)) 0;
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 428)) 0;
    }
  }
`;
const OrderInfoListBoxInner = styled.ul`
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000;
  }

  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 428)) 0;
    }
  }
`;
const OrderInfoList = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (12 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (12 / 428));
    }
  }
`;
const OrderSubTitle = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const OrderText = styled(OrderSubTitle)`
  color: #333;
`;
const OrderSubTitleBold = styled(OrderSubTitle)`
  color: #333;
  font-weight: 500;
`;
const OrderTextBold = styled(OrderText)`
  font-weight: 600;
`;

// input check
const ChkListBox = styled.ul``;
const ChkList = styled.li`
  &:not(:last-child) {
    margin-bottom: 25px;
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (25 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (25 / 428));
    }
  }
`;
const ChkButton = styled.div`
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const ChkInput = styled.input`
  display: none;

  &:checked + label .chk-img {
    background: url(${IconChkOn}) no-repeat center / contain;
  }
`;
const ChkLabel = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;
const ChkImg = styled.div`
  width: 24px;
  height: 24px;
  background: url(${IconChkOff}) no-repeat center / contain;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const ChkText = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (10 / 428));
  }
`;

const BankInfo = styled.div`
  width: 378px;
  background-color: #f9f9f9;
  padding: 15px 0;
  margin-top: 18px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
    padding: calc(100vw * (15 / 1320)) 0;
    margin-top: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    padding: calc(100vw * (15 / 428)) 0;
    margin-top: calc(100vw * (15 / 428));
  }
`;
const BankInfoListBox = styled.ul`
  width: 258px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (258 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (308 / 428));
  }
`;
const BankInfoList = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 428));
    }
  }
`;
const BankInfoTitle = styled.p`
  width: 60px;
  color: #999;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (60 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (60 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;
const BankInfoText = styled(BankInfoTitle)`
  width: calc(100% - 60px);
  color: #333;

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (60 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (60 / 428)));
  }
`;

// 환불받을 계좌
const BankListBox = styled.ul``;
const BankList = styled.li`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 428));
    }
  }
`;
const InputText = styled.p`
  width: 100%;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 8px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-bottom: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-bottom: calc(100vw * (8 / 428));
  }
`;

// 배송지 정보
const AddressChangeButton = styled.button`
  color: #777;
  font-weight: 500;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const AddressInfoBox = styled.div`
  margin: 20px 0 0;
  @media screen and (max-width: 1320px) {
    margin: calc(100vw * (20 / 1320)) 0 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (20 / 428)) auto 0;
  }
`;
const AddressInfo = styled.div`
  margin-bottom: 20px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (20 / 428));
  }
`;
const AddressTitleWrap = styled.p`
  display: flex;
  align-items: center;
`;
const AddressTitle = styled.span`
  max-width: calc(100% - 92px);
  color: #333;
  font-weight: 600;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    max-width: calc(100% - calc(100vw * (92 / 1320)));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    max-width: calc(100% - calc(100vw * (92 / 428)));
    font-size: calc(100vw * (16 / 428));
  }
`;
const AddressDefault = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82px;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  background-color: #fdf251;
  border-radius: 14px;
  padding: 5px 0;
  margin-left: 10px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (82 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (5 / 1320)) 0;
    margin-left: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (82 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (5 / 428)) 0;
    margin-left: calc(100vw * (10 / 428));
  }
`;
const AddressText = styled.p`
  line-height: 1.5;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  margin-top: 8px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (8 / 428));
  }
`;
const AddressName = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 15px;
  margin-top: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (6 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (6 / 428));
  }
`;

// button
const BtnWrap = styled.div`
  width: 100%;
  flex-shrink: 0;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (50 / 428)) auto 0;
  }
`;
const Btn = styled.button`
  width: 100%;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;

const Container = styled.div``;

const Wrap = styled.div`
  width: 100%;
  height: calc(100% - 84px);

  @media screen and (max-width: 1320px) {
    height: calc(100% - calc(100vw * (84 / 1320)));
  }
  @media screen and (max-width: 930px) {
    height: auto;
    ${({ isModal }) => isModal && `height : 100vh;`}
  }
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;

// header
const HeaderSection = styled.div`
  position: relative;
  width: 100%;
  height: 84px;
  overflow: hidden;
  z-index: 99;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
  }
`;
const HeaderInner = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
  background-color: #fff;
  padding: 0 25px;

  & p {
    color: #333;
    font-weight: 500;
    font-size: 20px;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
    padding: 0 calc(100vw * (25 / 1320));

    & p {
      font-size: calc(100vw * (20 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    justify-content: center;
    height: calc(100vw * (55 / 428));
    padding: 0;

    & p {
      font-size: calc(100vw * (16 / 428));
    }
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    right: calc(100vw * (13.5 / 428));
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;

// 콘텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  width: 1240px;
  height: 650px;
  background: #fff;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    height: calc(100vw * (690 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: 0;
    left: 0;
    transform: translate(0);
    width: 100%;
    height: 100vh;
    overflow: auto;
    ${({ isModal }) => isModal && `overflow : hidden;`}
  }
`;

// info text
const InfoTextBox = styled.div`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (15 / 428)) auto 0;
  }
`;
