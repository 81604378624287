//react
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import { useParams } from "react-router-dom";
import HtmlParser from "react-html-parser";

import { imgURL } from "../../server/appInfoController";

import { DateToSuccess } from "../../../components/common/commonUse";

import serverController from "../../server/serverController";

//css
import styled from "styled-components";

// img
import IconPrev from "../../img/icon/icon_list_prev.png";
import IconNext from "../../img/icon/icon_list_next.png";
import NoticeImg from "../../img/notice_detail_img.png";

// img
import NoticeComment from "./comment/NoticeComment";

export default function NoticeDetail() {
  const params = useParams();
  const { not_id } = params;
  const [noticeDetail, setNoticeDetail] = useState([]);

  const getNoticeDetail = () => {
    serverController.connectFetchController(
      `notice/${not_id}`,
      "POST",
      null,
      function (res) {
        if (res.result == 1) {
          let detailGet = res.notice;
          setNoticeDetail(detailGet);
        }
      }
    );
  };

  useEffect(() => {
    getNoticeDetail();
  }, []);

  return (
    <Container>
      <InquiryWriteWrap>
        <TitleWrap>
          <Title>공지사항</Title>
        </TitleWrap>
        <DetailContentWrap>
          <DetailContent>
            <DetailTitle>{noticeDetail?.title}</DetailTitle>
            <TitleSubWrap>
              <TitleSub>WALA스토어</TitleSub>
              <TitleSub>조회 {noticeDetail?.notice_view_count}</TitleSub>
              <TitleSub>
                {DateToSuccess(noticeDetail?.notice_create_date, "")}
              </TitleSub>
            </TitleSubWrap>
          </DetailContent>
          <DetailTextWrap>
            <DetailTextInner>
              <DetailImg
                src={imgURL + noticeDetail?.notice_img}
                alt="공지사항 이미지"
              />
              <div>{HtmlParser(noticeDetail?.notice_content)}</div>
            </DetailTextInner>
          </DetailTextWrap>
        </DetailContentWrap>

        {/* <ListBtnWrap>
                <Link to={`/store/notice`}>
                    <ListBtn>목록</ListBtn>
                </Link>
            </ListBtnWrap>
            <BtnWrap>
                <BtnPrev>
                    <PrevTitle><ArrowImg src={IconPrev} alt="이전글로 이동"/>이전글</PrevTitle>
                    <PrevText>이전글이 없습니다.</PrevText>
                </BtnPrev>
                <BtnNext>
                    <NextTitle><ArrowImg src={IconNext} alt="다음글로 이동"/>다음글</NextTitle>
                    <NextText>WALA스토어 공지사항입니다.</NextText>
                </BtnNext>
            </BtnWrap>
            
            <NoticeComment/> */}
      </InquiryWriteWrap>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
`;
const InquiryWriteWrap = styled.div`
  width: 1300px;
  margin: 0 auto;
  padding-bottom: 200px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
    padding-bottom: calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    padding-bottom: calc(100vw * (100 / 428));
  }
`;
const TitleWrap = styled.div`
  width: 100%;
  padding-top: 80px;
  margin-bottom: 40px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (80 / 1320));
    margin-bottom: calc(100vw * (40 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (30 / 428));
    margin-bottom: calc(100vw * (25 / 428));
  }
`;
const Title = styled.h2`
  font-size: 30px;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
  }
`;
// form
const DetailContentWrap = styled.div`
  width: 100%;
  border-top: 1px solid #20232a;
  margin-top: 40px;

  @media screen and (max-width: 1320px) {
    border-top: calc(100vw * (1 / 1320)) solid #20232a;
    margin-top: calc(100vw * (40 / 1320));
  }

  @media screen and (max-width: 930px) {
    border-top: calc(100vw * (1 / 428)) solid #20232a;
    margin-top: calc(100vw * (40 / 428));
  }
`;
const DetailContent = styled.div`
  width: 100%;
  padding: 20px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const DetailTitle = styled.p`
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-bottom: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
    margin-bottom: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    margin-bottom: calc(100vw * (18 / 428));
  }
`;
const TitleSubWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const TitleSub = styled.p`
  position: relative;
  font-size: 14px;
  color: #969696;
  padding-right: 14px;
  margin-right: 14px;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 1px;
    height: 15px;
    background: #b5b5b5;
  }
  &:last-child:after {
    display: none;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    padding-right: calc(100vw * (14 / 1320));
    margin-right: calc(100vw * (14 / 1320));
    &:after {
      width: calc(100vw * (1 / 1320));
      height: calc(100vw * (15 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (13 / 428));
    padding-right: calc(100vw * (14 / 428));
    margin-right: calc(100vw * (14 / 428));
    &:after {
      width: calc(100vw * (1 / 428));
      height: calc(100vw * (15 / 428));
    }
  }
`;
const DetailTextWrap = styled.div`
  width: 100%;
  padding: 30px 0 50px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #20232a;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) 0 calc(100vw * (50 / 1320));
    border-top: calc(100vw * (1 / 1320)) solid #d9d9d9;
    border-bottom: calc(100vw * (1 / 1320)) solid #20232a;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (50 / 428));
    border-top: calc(100vw * (1 / 428)) solid #d9d9d9;
    border-bottom: calc(100vw * (1 / 428)) solid #20232a;
  }
`;
const DetailTextInner = styled.div`
  width: 100%;
  margin-bottom: 30px;
  padding: 0 20px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (30 / 1320));
    padding: 0 calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (30 / 428));
    padding: 0 calc(100vw * (20 / 428));
  }
`;
const DetailImg = styled.img`
  display: block;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (20 / 428));
  }
`;
//button
const ListBtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const ListBtn = styled.p`
  width: 100px;
  font-size: 15px;
  border-radius: 10px;
  padding: 10px 0;
  text-align: center;
  background: #222222;
  color: #fff;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    border-radius: calc(100vw * (10 / 1320));
    padding: calc(100vw * (10 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    border-radius: calc(100vw * (10 / 428));
    padding: calc(100vw * (10 / 428)) 0;
  }
`;
//next notice
const BtnWrap = styled.div`
  width: 100%;
`;
const BtnPrev = styled.div`
  display: flex;
  align-items: center;
  padding: 22px 0;
  border-bottom: 1px solid #d9d9d9;
  &:last-child {
    border: none;
  }

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (22 / 1320)) 0;
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (22 / 428)) 0;
    border-bottom: calc(100vw * (1 / 428)) solid #d9d9d9;
  }
`;
const BtnNext = styled(BtnPrev)``;
const PrevTitle = styled.p`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  padding-left: 32px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding-left: calc(100vw * (32 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    padding-left: 0;
  }
`;
const NextTitle = styled(PrevTitle)``;
const ArrowImg = styled.img`
  display: block;
  width: 10px;
  margin-right: 5px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (10 / 1320));
    margin-right: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (10 / 428));
    margin-right: calc(100vw * (5 / 428));
  }
`;
const PrevText = styled.p`
  font-size: 15px;
  color: #969696;
  padding-left: 20px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding-left: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    padding-left: calc(100vw * (20 / 428));
  }
`;
const NextText = styled(PrevText)``;
