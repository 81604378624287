import styled from "styled-components";
import React from "react";

export default function DarkBackground({ status, children }) {
  const darkBackgroundRef = React.useRef(null);

  const onClickDarkBackground = (e) => {
    if (e.target === darkBackgroundRef.current) {
      status();
    }
  };

  return (
    <S.DarkBackground ref={darkBackgroundRef} onClick={onClickDarkBackground}>
      {children}
    </S.DarkBackground>
  );
}

const S = {
  DarkBackground: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 105;
    background-color: rgba(0, 0, 0, 0.4);
  `,
};
