import { Mobile, PC } from "../../../MediaQuery";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

//img
import IconCancel from "../../../img/icon/icon_myhaeder_cancle.svg";
import IconSelectArrow from "../../../img/icon/icon_bottom_arrow.svg";
import IconChkOn from "../../../img/icon/icon_chk_on.svg";
import IconChkOff from "../../../img/icon/icon_chk_off.svg";
import IconFileUpload from "../../../img/icon/icon_upload.svg";
import IconFileUploadCancel from "../../../img/icon/icon_upload_cancel.svg";

// controller
import serverController from "../../../server/serverController";

import MyPageBottom from "../../../page/mypage/MyPageBottom";
import MyPageBottomModal from "../../../page/mypage/MyPageBottomModal";
import MyPageDefaultModal from "./MyPageDefaultModal";
import { imgURL } from "../../../server/appInfoController";
import { InfoText } from "../../login/auth.elements";

// mypage bottom data
const myPageBottomTextList = [];
const myPageBottomTextListQ = [];

// option list
const optionList = [
  // { title: "전체문의", value: "11" },
  { title: "상품문의", value: "1" },
  { title: "재입고 문의", value: "2" },
  { title: "사이즈문의", value: "3" },
  { title: "배송문의", value: "4" },
];

const optionListQ = [
  { title: "선택", value: "0" },
  // { title: "전체문의", value: "12" },
  { title: "상품문의", value: "5" },
  { title: "주문/결제문의", value: "6" },
  { title: "배송문의", value: "7" },
  { title: "교환/취소문의", value: "8" },
  { title: "계정문의", value: "9" },
  { title: "기타", value: "10" },
];

const body = document.querySelector("body");

export default function ProductInquiryEditModal({
  setInquiryEditModalShow,
  product = [],
  question = false,
  edit = false,
}) {
  const permission = useSelector((state) => state.permissionData.permission.state);
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [inquiryText, setInquiryText] = useState("");
  const [secretCheck, setSecretCheck] = useState(false);
  const [selectedValue, setSelectedValue] = useState("1");
  const [inquiryRemove, setInquiryRemove] = useState(false);

  const [photoSection, setPhotoSection] = useState(true);

  // imgs
  const [imageArr, setImageArr] = useState([]);
  const [previewImageArr, setPreviewImageArr] = useState([]);

  // edit
  const [imageNameArr, setImageNameArr] = useState([]);

  // post inquiry
  const submitInquiry = () => {
    if (selectedValue === "0") {
      return alert("문의 유형을 선택해주세요.");
    }
    if (title.length < 5) {
      return alert(`제목을 5글자 이상 입력해주세요.`);
    }
    if (inquiryText.length < 10) {
      return alert("문의 내용을 10글자 이상 입력해주세요.");
    }

    const formData = new FormData();
    const inquiryData = JSON.stringify({
      bc_id: product?.length > 0 ? product.bc_id : "0",
      prd_id: product?.length > 0 ? product.prd_id : "0",
      inq_type: selectedValue,
      inq_title: title,
      inq_content: inquiryText,
      inq_secret: secretCheck ? "1" : "0",
    });
    const blobData = new Blob([inquiryData], { type: "application/json" });
    const blobNullData = new Blob([], { type: "application/json" });

    formData.append("inquiry", blobData);
    if (imageArr.length > 0) {
      for (let i in imageArr) {
        formData.append("images", imageArr[i]);
      }
    } else {
      formData.append("images", blobNullData);
    }

    serverController.connectFetchController(
      `inquiry/store`,
      "POST",
      formData,
      (res) => {
        if (res?.result === 1) {
          alert(res.msg);
          setInquiryEditModalShow(false);
        } else {
          alert("[오류] 다시 시도해주세요.");
        }
      },
      (err) => console.error(err)
    );
  };

  // put edit inquiry
  const putEditInquiry = () => {
    if (selectedValue === "0") {
      return alert("문의 유형을 선택해주세요.");
    }
    if (title.length < 5) {
      return alert(`제목을 5글자 이상 입력해주세요.`);
    }
    if (inquiryText.length < 10) {
      return alert("문의 내용을 10글자 이상 입력해주세요.");
    }
    var upLoadImgText = "";
    for (let i in imageNameArr) {
      if (previewImageArr.length !== i * 1 + 1) {
        upLoadImgText = upLoadImgText + imageNameArr[i] + ",";
      } else {
        upLoadImgText = upLoadImgText + imageNameArr[i];
      }
    }

    const formData = new FormData();
    const inquiryData = JSON.stringify({
      bc_id: product?.length > 0 ? product.bc_id : "0",
      prd_id: product?.length > 0 ? product.prd_id : "0",
      inq_type: selectedValue,
      inq_title: title,
      inq_images: upLoadImgText,
      inq_content: inquiryText,
      inq_secret: secretCheck ? "1" : "0",
    });

    const blobData = new Blob([inquiryData], { type: "application/json" });
    const blobNullData = new Blob([], { type: "application/json" });

    formData.append("inquiry", blobData);
    if (imageArr.length > 0) {
      for (let i in imageArr) {
        formData.append("images", imageArr[i]);
      }
    } else {
      formData.append("images", blobNullData);
    }
    serverController.connectFetchController(
      `inquiry/store`,
      "PUT",
      formData,
      (res) => {
        if (res?.result === 1) {
          setInquiryEditModalShow(false);
        }
      },
      (err) => console.error(err)
    );
  };

  // s :: about imgs
  const uploadImage = (e) => {
    let arr = [...imageArr];
    for (var i = 0; e.target.files.length > i; i++) {
      arr.unshift(e.target.files[i]);
    }
    setImageArr(arr);
  };
  const handleImageUpload = (e) => {
    const fileArr = e.target.files;
    let fileArrUrl = [];
    let file = null;
    const imgArr = [...previewImageArr];

    for (let i = 0; i < fileArr.length; i++) {
      file = fileArr[i];

      let reader = new FileReader();
      reader.onload = () => {
        fileArrUrl[i] = reader.result;
        imgArr.unshift(fileArrUrl[i]);
        setPreviewImageArr(imgArr);
      };
      reader.readAsDataURL(file);
    }
  };
  const deleteButtonClick = (index) => {
    let imgArr = [];
    let prvArr = [];
    for (let i in previewImageArr) {
      if (i != index) {
        imgArr.push(imageArr[i]);
        prvArr.push(previewImageArr[i]);
      }
    }
    setImageArr(imgArr);
    setPreviewImageArr(prvArr);
  };
  const deleteButtonClickEdit = (index, uploadImg) => {
    let prvArr = [];
    for (let i in previewImageArr) {
      if (i != index) {
        prvArr.push(previewImageArr[i]);
      }
    }
    if (prvArr.length === 0) {
      return () => {
        setImageNameArr([]);
        setPreviewImageArr([]);
      };
    }
    setImageNameArr(prvArr);
    setPreviewImageArr(prvArr);
  };
  // e :: about imgs

  // edit
  useEffect(() => {
    if (edit) {
      setTitle(product.inq_title);
      setInquiryText(product.inq_content);
      setSecretCheck(product.inq_secret === 0 ? false : true);
      setSelectedValue(product.inq_type.toString());
      if (product?.inq_images?.split(",")?.length > 0) {
        setPreviewImageArr(product?.inq_images?.split(",")); /// [..., ...], 프리뷰
        setImageNameArr(product?.inq_images?.split(",")); /// [..., ...], put name arr
      }
    }
  }, [edit]);

  useEffect(() => {
    body.style.overflow = "hidden";
    body.style.height = "100vh";
    return () => {
      body.style.overflow = "auto";
      body.style.height = "auto";
    };
  }, []);

  // test zone
  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "permission",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!permission) {
      setPhotoSection(false);
    }
    // previewImageArr(프리뷰), imageArr(file), imageNameArr만 컨트롤하면됨
  }, [permission]);

  return (
    <Container>
      {inquiryRemove && (
        <MyPageDefaultModal
          text={
            <>
              작성하고 있던 내용이 저장되지 않습니다.
              <br />
              문의 작성을 취소하시겠습니까?
            </>
          }
          trueFunc={() => setInquiryEditModalShow(false)}
          falseFunc={() => setInquiryRemove(false)}
        />
      )}

      <PC>
        <Background
          onClick={() => {
            setInquiryRemove(true);
          }}
        />
      </PC>
      <Content>
        {/* header */}
        <HeaderSection>
          <HeaderInner>
            <p>{!question ? "문의하기" : "1:1 문의 작성"}</p>
            <CloseButton
              onClick={() => {
                setInquiryRemove(true);
              }}
            >
              <img src={IconCancel} alt="cancel-icon" />
            </CloseButton>
          </HeaderInner>
        </HeaderSection>

        <Wrap>
          <ListWrap>
            <ListWrapLeft>
              <FormList>
                <FormTitleWrap>
                  <ListTitle>문의 유형</ListTitle>
                </FormTitleWrap>
                <SelectButton
                  defaultValue={"1"}
                  borderActive={selectedValue !== "0"}
                  onChange={(e) => {
                    setSelectedValue(e.target.value);
                  }}
                >
                  {!question
                    ? optionList.map((item) => {
                      return (
                        <option value={item.value} key={item.value}>
                          {item.title}
                        </option>
                      );
                    })
                    : optionListQ.map((item) => {
                      return (
                        <option value={item.value} key={item.value}>
                          {item.title}
                        </option>
                      );
                    })}
                </SelectButton>
              </FormList>
              {question && (
                <FormList>
                  <FormTitleWrap>
                    <ListTitle>휴대폰번호</ListTitle>
                  </FormTitleWrap>
                  <InputPhone
                    type="text"
                    // value={userInfo ? userInfo.mem_phone : phone}
                    active={phone.length > 0}
                    placeholder="-를 제외한 숫자만 입력해주세요."
                    // readOnly={userInfo}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </FormList>
              )}
              <FormList>
                <FormTitleWrap>
                  <ListTitle>문의 제목</ListTitle>
                </FormTitleWrap>
                <Input
                  type="text"
                  value={title}
                  active={title.length > 0}
                  placeholder="문의 제목을 입력해주세요."
                  onChange={(e) => {
                    if (e.target.value.length > 20) return;
                    setTitle(e.target.value);
                  }}
                />
              </FormList>
              <SubmitBtn type="button" onClick={submitInquiry}>
                {!question ? "등록하기" : "저장하기"}
              </SubmitBtn>
            </ListWrapLeft>

            <ListWrapRight>
              <FormWrap>
                <FormList>
                  <FormTitleWrap>
                    <ListTitle>문의 내용</ListTitle>
                    <PC>
                      <InquiryTextLength>({inquiryText.length}/500)</InquiryTextLength>
                    </PC>
                  </FormTitleWrap>
                  <InquiryTextWrap>
                    <InquiryTextArea
                      value={inquiryText}
                      active={inquiryText.length > 0}
                      placeholder="문의 내용을 입력해주세요."
                      onChange={(e) => {
                        if (e.target.value.length > 500) return;
                        setInquiryText(e.target.value);
                      }}
                    />
                    <Mobile>
                      <InquiryTextLength>({inquiryText.length}/500)</InquiryTextLength>
                    </Mobile>
                  </InquiryTextWrap>
                </FormList>
                <Mobile>
                  <FormList>
                    <SecretChkWrap>
                      <ChkBox>
                        <ChkInput
                          id={"chk"}
                          type="checkbox"
                          name="checkbox"
                          onChange={() => {
                            setSecretCheck(!secretCheck);
                          }}
                          checked={secretCheck}
                        />
                        <ChkLabel htmlFor={"chk"}>
                          <ChkImg className="chk-img" />
                          <ChkText>비밀글</ChkText>
                        </ChkLabel>
                      </ChkBox>
                      {secretCheck && (
                        <SecretChkText>답변은 mypage에서 확인가능합니다.</SecretChkText>
                      )}
                    </SecretChkWrap>
                  </FormList>
                </Mobile>
              </FormWrap>
              {photoSection ? (
                <ImageWrap>
                  <FormTitleWrap>
                    <ListTitle>사진첨부하기(선택)</ListTitle>
                    <ListSubTitle>*최대3장</ListSubTitle>
                  </FormTitleWrap>
                  {!edit ? (
                    <ImageInner>
                      {imageArr.length < 3 && (
                        <ImageList>
                          <ImageBox>
                            <FileReviseBtnWrap>
                              <FileReviseBtn
                                type="file"
                                id="filePic"
                                accept="image/*"
                                onChange={(e) => {
                                  handleImageUpload(e);
                                  uploadImage(e);
                                }}
                              />
                              <FileBtnLabel for="filePic" />
                            </FileReviseBtnWrap>
                          </ImageBox>
                        </ImageList>
                      )}
                      {imageArr.map((_, index) => {
                        return (
                          <ImageList key={index + "-img"}>
                            <UploadImageBox>
                              <UploadDeleteButton
                                onClick={() => {
                                  deleteButtonClick(index);
                                }}
                              />
                              <UploadImage alt="previewImg" src={previewImageArr[index]} />
                            </UploadImageBox>
                          </ImageList>
                        );
                      })}
                    </ImageInner>
                  ) : (
                    <ImageInner>
                      {previewImageArr?.length < 3 && (
                        <ImageList>
                          <ImageBox>
                            <FileReviseBtnWrap>
                              <FileReviseBtn
                                type="file"
                                id="filePic"
                                accept="image/*"
                                onChange={(e) => {
                                  handleImageUpload(e);
                                  uploadImage(e);

                                  // preview arr 추가
                                  let prvImgArr = [...previewImageArr];
                                  prvImgArr.push(e?.target?.files[0]?.name);
                                  setPreviewImageArr(prvImgArr);

                                  // name arr 추가
                                  let prvImgNameArr = [...imageNameArr];
                                  prvImgNameArr.push(e?.target?.files[0]?.name);
                                  setImageNameArr(prvImgNameArr);
                                }}
                              />
                              <FileBtnLabel for="filePic" />
                            </FileReviseBtnWrap>
                          </ImageBox>
                        </ImageList>
                      )}
                      {previewImageArr?.map((PrvItem, index) => {
                        // -1이면 기존 업로드 이미지
                        return (
                          <ImageList key={`${index}-preview-img`}>
                            <UploadImageBox>
                              <UploadDeleteButton
                                onClick={() => {
                                  deleteButtonClickEdit(
                                    index,
                                    PrvItem.indexOf("data:image/") === 0
                                  );
                                }}
                              />
                              <UploadImage
                                alt="previewImg"
                                src={
                                  PrvItem.indexOf("data:image/") === 0
                                    ? PrvItem
                                    : `${imgURL}${PrvItem}`
                                }
                              />
                            </UploadImageBox>
                          </ImageList>
                        );
                      })}
                    </ImageInner>
                  )}
                </ImageWrap>
              ) : (
                <InfoText>* 이미지 업로드는 '사진 접근 허용' 후 이용 가능합니다.</InfoText>
              )}
            </ListWrapRight>
          </ListWrap>

          <Mobile>
            <MyPageBottom textList={!question ? myPageBottomTextList : myPageBottomTextListQ} />
          </Mobile>
        </Wrap>
      </Content>
      <Mobile>
        <SubmitBtn
          type="button"
          onClick={() => {
            if (edit) {
              putEditInquiry();
            } else {
              submitInquiry();
            }
          }}
        >
          {!question ? "등록하기" : "저장하기"}
        </SubmitBtn>
      </Mobile>
    </Container>
  );
}

const Container = styled.div``;

const Wrap = styled.div`
  width: 100%;
  height: calc(100% - 84px);
  padding-bottom: 30px;

  @media screen and (max-width: 1320px) {
    height: calc(100% - calc(100vw * (84 / 1320)));
    padding-bottom: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: auto;
    padding-bottom: calc(100vw * (80 / 428));
  }
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 101;
`;

// header
const HeaderSection = styled.div`
  position: relative;
  width: 100%;
  height: 84px;
  overflow: hidden;
  z-index: 99;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
  }
`;
const HeaderInner = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
  background-color: #fff;
  padding: 0 25px;

  & p {
    color: #333;
    font-weight: 500;
    font-size: 20px;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
    padding: 0 calc(100vw * (25 / 1320));

    & p {
      font-size: calc(100vw * (20 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    justify-content: center;
    height: calc(100vw * (55 / 428));
    padding: 0;

    & p {
      font-size: calc(100vw * (16 / 428));
    }
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    right: calc(100vw * (13.5 / 428));
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;

// 콘텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  width: 1240px;
  height: 650px;
  background: #fff;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    height: calc(100vw * (650 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: 0;
    left: 0;
    transform: translate(0);
    width: 100%;
    height: 100vh;
    overflow: auto;
  }
`;

// inquiry
const ListWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1190px;
  height: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1190 / 1320));
  }
  @media screen and (max-width: 930px) {
    display: block;
    width: 100%;
    height: auto;
  }
`;
const ListWrapLeft = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 428px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (428 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ListWrapRight = styled.div`
  width: 742px;
  height: 100%;
  overflow: auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (742 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    height: auto;
  }
`;
//info
const ListInner = styled.div`
  width: 100%;
  padding: 0 10px 20px;
  border-bottom: 8px solid #f9f9f9;

  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (10 / 1320)) calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;

const ImgBox = styled.div`
  width: 70px;
  height: 70px;
  background-color: #fff;
  border: 1px solid #eee;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (70 / 1320));
    height: calc(100vw * (70 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (70 / 428));
    height: calc(100vw * (70 / 428));
  }
`;
const TextBox = styled.div`
  width: calc(100% - 80px);
  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (80 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (298 / 428));
  }
`;
const Brand = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (6 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (6 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

// from
const FormWrap = styled.div`
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding-top: calc(100vw * (20 / 428));
  }
`;
const FormList = styled.div`
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (30 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 428));
    }
  }
`;

// select
const SelectButton = styled.select`
  display: block;
  width: 100%;
  height: 50px;
  color: #acacac;
  font-weight: 400;
  font-size: 15px;
  background: #ffffff url(${IconSelectArrow}) no-repeat right 15px center / 24px;
  border: 1px solid #ddd;
  padding: 0 15px;
  border-radius: 0;

  -webkit-appearance: none; /* 네이티브 외형 감추기*/
  -moz-appearance: none;
  appearance: none;
  font-weight: normal;
  letter-spacing: normal;
  cursor: pointer;

  ${({ borderActive }) =>
    borderActive &&
    `border: 1px solid #000;
  color: #333;
  `}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
    font-size: calc(100vw * (15 / 1320));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 1320)) center /
      calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 428)) center /
      calc(100vw * (24 / 428));
  }
`;
// input
const Input = styled.input`
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;
const InputPhone = styled(Input)``;

// textarea
const InquiryTextWrap = styled.div`
  position: relative;
`;
const InquiryTextArea = styled.textarea`
  width: 100%;
  height: 270px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  padding: 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (270 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (200 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (15 / 428)) calc(100vw * (20 / 428));
  }
`;
const InquiryTextLength = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    position: absolute;
    right: calc(100vw * (10 / 428));
    bottom: calc(100vw * (10 / 428));
    font-size: calc(100vw * (14 / 428));
  }
`;

// button
// const SubmitBtnWrap = styled.div`
//   width: 100%;
//   height: 50px;
//   flex-shrink: 0;

//   @media screen and (max-width: 1320px) {
//     height: calc(100vw * (50 / 1320));
//   }

//   @media screen and (max-width: 930px) {
//     position: fixed;
//     left: 0;
//     bottom: 0;
//     height: calc(100vw * (80 / 428));
//   }
// `;
const SubmitBtn = styled.button`
  position: absolute;
  bottom: 30px;
  width: 428px;
  /* width: 100%; */
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;
  margin-top: 20px;
  cursor: pointer;
  flex-shrink: 0;
  z-index: 101;

  @media screen and (max-width: 1320px) {
    bottom: calc(100vw * (30 / 1320));
    width: calc(100vw * (428 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
    margin-top: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    position: fixed;
    left: 0;
    bottom: 0;
    height: calc(100vw * (80 / 428));
    font-size: calc(100vw * (18 / 428));
    margin-top: 0;
  }
`;

// check
const SecretChkWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ChkBox = styled.div`
  display: flex;
  align-items: center;
`;
const ChkInput = styled.input`
  display: none;

  &:checked + label .chk-img {
    background: url(${IconChkOn}) no-repeat center / contain;
  }
`;
const ChkLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const ChkImg = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  background: url(${IconChkOff}) no-repeat center / contain;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const ChkText = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (10 / 428));
  }
`;
const SecretChkText = styled.span`
  color: #ff006c;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
// image upload
const ImageWrap = styled.div`
  margin-top: 30px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const FormTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;

  @media screen and (max-width: 1320px) {
    margin: 0 0 calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto calc(100vw * (8 / 428));
  }
`;
const ListTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const ListSubTitle = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;

const ImageInner = styled.ul`
  display: flex;

  white-space: nowrap;
  overflow-y: visible;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  @media screen and (max-width: 930px) {
    padding-left: calc(100vw * (25 / 428));
  }
`;
const ImageList = styled.li`
  &:not(:last-child) {
    padding-right: 10px;
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      padding-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      padding-right: calc(100vw * (10 / 428));
    }
  }
`;
const ImageBox = styled.div``;

const UploadImageBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  background-color: #fff;
  border: 1px solid #eee;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    height: calc(100vw * (140 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (140 / 428));
    height: calc(100vw * (140 / 428));
  }
`;
const UploadImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const UploadDeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 28px;
  height: 28px;
  background: url(${IconFileUploadCancel}) no-repeat center / contain;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (28 / 1320));
    height: calc(100vw * (28 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (28 / 428));
    height: calc(100vw * (28 / 428));
  }
`;

// file upload
const FileReviseBtnWrap = styled.div``;
const FileReviseBtn = styled.input`
  display: none;
`;
const FileBtnLabel = styled.label`
  display: block;
  width: 140px;
  height: 140px;
  background: url(${IconFileUpload}) no-repeat center / contain;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    height: calc(100vw * (140 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (140 / 428));
    height: calc(100vw * (140 / 428));
  }
`;
