import React, { useState, useCallback } from 'react';

import {  
    FieldBox,
    Label,    
    LabelName,
    Input,    
} from '../auth.elements';

const SignupBrandNameInput = ({ setBrandName, setIsCheck }) => {
    const [value, setValue] = useState(""); 

    const onHandleChange = (e) => {       
        const data = e.target.value;
        setValue(data)        
        setBrandName(data)

        if (data) {
            setIsCheck(prev => ({...prev, isBrandName: true }))
        } else {
            setIsCheck(prev => ({...prev, isBrandName: false }))
        }
    }; 


    return (
        <>
        <FieldBox>
            <Label>
                <LabelName>사업자(브랜드명)</LabelName>
                <Input 
                required
                value={value}
                onChange={onHandleChange}
                />
            </Label> 
        </FieldBox>
        </>
    )
}

export default SignupBrandNameInput
