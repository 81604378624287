//react
import React, { useState, useRef, useEffect } from 'react';
import { Link,useHistory,useParams } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery"
import serverController from '../../server/serverController';

//css
import styled from "styled-components"


export default function Complete({title,btn,url,rank}) {
    const [bankData, setBankData] = useState([]);
    const params = useParams();
    const {orderId} = params;

    const getVbankData = async () => {
        await serverController.connectFetchController( `vbank/${orderId.split('=')[1]}`,"GET", null,(res) => {
            setBankData(res)
        });          
    }

    useEffect(()=>{
        getVbankData()
    },[]);

  return (
    <Container>
        <CompleteWrap>
            <CompleteTitleWrap>
                <CompleteTitle>{title}</CompleteTitle>
                <BankTitle>무통장 계좌</BankTitle>
                <BankText>{bankData.vbank_name} {bankData.vbank_num}</BankText>
            </CompleteTitleWrap>
            <CompleteBtnWrap>
                <Link to={`${url}`}>
                    <CompleteBtn>{btn}</CompleteBtn>
                </Link>
            </CompleteBtnWrap>
        </CompleteWrap>
    </Container>
  );
}
const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; min-height: calc(100vh - 149px);

    @media screen and (max-width: 1320px) {
        min-height: calc(100vh - calc(100vw*(149/1320)));
    }

    @media screen and (max-width: 930px) {
        min-height: calc(100vh - calc(100vw*(119/428)));
    }
`
const CompleteWrap = styled.div`
    width: 100%;
    padding-bottom: 100px;

    @media screen and (max-width: 1320px) {
        padding-bottom: calc(100vw*(100/1320));
    }
    
    @media screen and (max-width: 930px) {
        padding-bottom: calc(100vw*(100/428));
    }
`
const CompleteTitleWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 80px;

    @media screen and (max-width: 1320px) {
        margin-bottom: calc(100vw*(80/1320));
    }
    
    @media screen and (max-width: 930px) {
        margin-top: calc(100vw*(20/428));
        margin-bottom: calc(100vw*(80/428));
    }
`
const CompleteTitle = styled.p`
    width: 100%;
    font-size: 26px;
    text-align: center;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(26/1320));
    }
    
    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(22/428));
    }
`
const CompleteBtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
const CompleteBtn = styled.p`
    width: 450px;
    font-size: 20px;
    text-align: center;
    color: #fff;
    background: #000;
    border-radius: 5px;
    padding: 20px 0;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(450/1320));
        font-size: calc(100vw*(20/1320));
        border-radius: calc(100vw*(5/1320));
        padding: calc(100vw*(20/1320)) 0;
    }
    
    @media screen and (max-width: 930px) {
        width: calc(100vw*(388/428));
        font-size: calc(100vw*(20/428));
        border-radius: calc(100vw*(5/428));
        padding: calc(100vw*(20/428)) 0;
    }
`
const BankTitle = styled.p`
    width: 100%;
    font-size: 17px;
    text-align: center;
    color: #000;
    border-radius: 5px;
    padding-top: 30px;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(17/1320));
        border-radius: calc(100vw*(5/1320));
        padding-top: calc(100vw*(30/1320));
    }
    
    @media screen and (max-width: 930px) {
        width: calc(100vw*(388/428));
        font-size: calc(100vw*(17/428));
        border-radius: calc(100vw*(5/428));
        padding-top: calc(100vw*(25/428));
    }
`
const BankText = styled(BankTitle)`
    font-size: 15px;
    padding-top: 5px;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(15/1320));
        padding-top: calc(100vw*(5/1320));
    }
    
    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(15/428));
        padding-top: calc(100vw*(5/428));
    }
`