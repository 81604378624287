import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import { useState } from "react";
import styled, { css } from "styled-components";

// common
import { numberWithCommas } from "../../../../components/common/commonUse";

// img
import Zigzag from "../../../img/borderBottom_zigzag.svg";
import ZigzagPcSmall from "../../../img/borderBottom_zigzag_small.svg";

// component
import MyPageLayOutMenu from "../MyPageLayOutMenu";
import MyPageLayOutName from "../MyPageLayOutName";
import MyPageLayOutInfo from "../MyPageLayOutInfo";

export default function MyCoupon({
  usedCouponList,
  activeCouponList,
  tabIndex,
  setTabIndex,
  isCoupon,
  setIsCoupon,
}) {
  //페이지 이동

  // coupon_active === 1 ? "사용가능" : "사용/만료"

  return (
    <Container>
      {/* page */}
      <PC>
        <MyPageLayOutName />
      </PC>

      {/* content */}
      <FlexLayOut>
        <PC>
          <MyPageLayOutMenu />
        </PC>

        <ContentSection>
          <PC>
            <MyPageLayOutInfo />
          </PC>

          {/* 문의 내역 상세 */}
          <Wrap>
            <TabArea>
              <TabBox>
                <Tab
                  onClick={() => {
                    setTabIndex(0);
                  }}
                >
                  보유
                </Tab>
                <Tab
                  onClick={() => {
                    setTabIndex(1);
                  }}
                >
                  사용/만료
                </Tab>
                <TabBottom tabIndex={tabIndex}>
                  <PC>
                    <TabBottomImg src={ZigzagPcSmall} alt="bottom" />
                  </PC>
                  <Mobile>
                    <TabBottomImg src={Zigzag} alt="zigzag-bottom" />
                  </Mobile>
                </TabBottom>
              </TabBox>
            </TabArea>

            <ContentsWrap>
              {/* 리뷰 */}
              {tabIndex === 0 ? (
                <>
                  <Contents>
                    <CouponPostBox>
                      <CouponPostButton
                        onClick={() => {
                          setIsCoupon(!isCoupon);
                        }}
                      >
                        쿠폰등록
                      </CouponPostButton>
                    </CouponPostBox>
                    <ListBox>
                      {activeCouponList.length > 0 ? (
                        <>
                          {activeCouponList.map((couponV) => {
                            return (
                              <List key={couponV.coupon_record_id}>
                                <Coupon>
                                  <CouponPrice>
                                    {numberWithCommas(couponV.coupon_discount)}
                                    {couponV.coupon_type === "percent" ? "%" : "원"}
                                  </CouponPrice>
                                  <CouponTextBox>
                                    <CouponName>{couponV.coupon_title}</CouponName>
                                    {couponV.coupon_limit !== 0 && (
                                      <CouponText>
                                        {numberWithCommas(couponV.coupon_limit)}원 이상 구매시 사용
                                        가능
                                      </CouponText>
                                    )}
                                  </CouponTextBox>
                                  <CouponDate>
                                    {couponV.coupon_end_date.replaceAll("-", ". ")}
                                    까지
                                  </CouponDate>
                                </Coupon>
                              </List>
                            );
                          })}
                        </>
                      ) : (
                        <NoneText>사용 가능한 쿠폰이 없습니다.</NoneText>
                      )}
                    </ListBox>
                  </Contents>
                </>
              ) : (
                <>
                  <Contents>
                    <ListBox>
                      {usedCouponList.length > 0 ? (
                        <>
                          {usedCouponList.map((couponV) => {
                            return (
                              <List key={couponV.coupon_record_id}>
                                <Coupon end>
                                  <CouponPrice>
                                    {numberWithCommas(couponV.coupon_discount)}
                                    {couponV.coupon_type === "percent" ? "%" : "원"}
                                  </CouponPrice>
                                  <CouponTextBox>
                                    <CouponName>{couponV.coupon_title}</CouponName>
                                    {couponV.coupon_limit !== 0 && (
                                      <CouponText>
                                        {numberWithCommas(couponV.coupon_limit)}원 이상 구매시 사용
                                        가능
                                      </CouponText>
                                    )}
                                  </CouponTextBox>
                                  <CouponDate>
                                    {couponV.coupon_end_date.replaceAll("-", ". ")}
                                    까지
                                  </CouponDate>
                                </Coupon>
                              </List>
                            );
                          })}
                        </>
                      ) : (
                        <NoneText>사용 만료된 쿠폰이 없습니다.</NoneText>
                      )}
                    </ListBox>
                  </Contents>
                </>
              )}
            </ContentsWrap>
          </Wrap>
        </ContentSection>
      </FlexLayOut>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  position: relative;
  padding-top: 180px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (180 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-top: 0;
  }
`;
const Wrap = styled.div`
  width: 100%;
  padding-top: 20px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-top: 0;
  }
`;

const TabArea = styled.div`
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #eee;

  @media screen and (max-width: 930px) {
    position: fixed;
    top: calc(100vw * (55 / 428));
    left: 0;
    z-index: 9;
  }
`;
const TabBox = styled.div`
  position: relative;
  display: flex;
  width: 200px;
  height: 50px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (200 / 1320));
    height: calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;
const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 2);
  height: 100%;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const TabBottom = styled.div`
  position: absolute;
  left: ${(props) => (props.tabIndex === 0 ? `0` : `100px`)};
  bottom: -7px;
  transition: ease 0.3s all;
  width: 100px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    left: ${(props) => (props.tabIndex === 0 ? `0` : `calc(100vw * (100 / 1320))`)};
    bottom: calc(100vw * (-7 / 1320));
    width: calc(100vw * (100 / 1320));
  }
  @media screen and (max-width: 930px) {
    left: ${(props) =>
      props.tabIndex === 0 ? `calc(100vw * (25 / 428))` : `calc(100vw * (214 / 428))`};
    bottom: calc(100vw * (-7 / 428));
    width: calc(100vw * (189 / 428));
  }
`;
const TabBottomImg = styled.img`
  width: 100%;
  height: 100%;
`;

const ContentsWrap = styled.div`
  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (50 / 428));
  }
`;
const Contents = styled.div``;

const ListBox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (15 / 1320)) 0 0;
  }
  @media screen and (max-width: 930px) {
    display: block;
    margin-top: 0;
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const List = styled.li`
  padding: 5px 0;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (5 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: 0;
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 428));
    }
  }
`;

// 쿠폰
const Coupon = styled.div`
  width: 501px;
  border: 1px solid #000;
  border-radius: 6px;
  overflow: hidden;
  margin: 0 auto;
  padding: 15px 20px;
  cursor: pointer;

  ${(props) =>
    props.end &&
    css`
      border: 1px solid #dddddd;
      & p {
        color: #acacac;
      }
      & span {
        color: #acacac;
      }
    `}

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (501 / 1320));
    padding: calc(100vw * (15 / 1320)) calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding: calc(100vw * (15 / 428)) calc(100vw * (20 / 428));
  }
`;
const CouponPrice = styled.p`
  color: #333;
  font-weight: 700;
  font-size: 22px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (22 / 428));
  }
`;
const CouponTextBox = styled.div`
  margin-top: 8px;
  & p:not(:last-child) {
    margin-bottom: 3px;
  }

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (8 / 1320));
    & p:not(:last-child) {
      margin-bottom: calc(100vw * (3 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (8 / 428));
    & p:not(:last-child) {
      margin-bottom: calc(100vw * (3 / 428));
    }
  }
`;
const CouponName = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const CouponText = styled(CouponName)``;
const CouponDate = styled.span`
  color: #999;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (5 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (5 / 428));
  }
`;

// 없을 때
const NoneText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #999;
  font-weight: 400;
  font-size: 16px;
  padding: 200px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (200 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding: 0;
    margin-top: calc(100vh * (330 / 925));
  }
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;

const CouponPostBox = styled.div`
  position: relative;
  margin: auto;
  margin-top: 5px;
  width: 1032px;
  height: 36px;
  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (5 / 1320));
    width: calc(100vw * (1032 / 1320));
    height: calc(100vw * (36 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (5 / 428));
    width: calc(100vw * (378 / 428));
    height: calc(100vw * (36 / 428));
  }
`;
const CouponPostButton = styled.button`
  position: absolute;
  padding: 7px 10px;
  right: 0;
  top: 4px;
  border: 1px solid #333;
  border-radius: 3px;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (7 / 1320)) calc(100vw * (10 / 1320));
    top: calc(100vw * (4 / 1320));
    border-radius: calc(100vw * (3 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (10 / 428)) calc(100vw * (14 / 428));
    top: calc(100vw * (4 / 428));
    border-radius: calc(100vw * (3 / 428));
  }
`;
