import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { PC, Mobile } from '../../common/mediaStlye';
import { SignUpAction } from '../../../store/actionCreators';

import {
    Container,
    Layout,
    AuthIntroMessage,
    Form,      
    FieldBox,
    Label,    
    LabelName,
    EmailInput,
    PasswordInput,
    PasswordCheckInput,
    TelephoneInput,
    Input
} from '../auth.elements';
import {    
    SubCheckButton,
    SignupBox,
    SignupButton,
    MobileSubBox
} from './signup.element';
import SignupNameInput from './SignupNameInput';
import SignupEmailInput from './SignupEmailInput';
import SignupPasswordInput from './SignupPasswordInput';
import SignupPhoneInput from './SignupPhoneInput';
import SignUpGuideLines from './SignUpGuideLines';


const SignUpNormal = () => {
    const history = useHistory();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");

    const [term, setTerm] = useState(false);
    const [policy, setPolicy] = useState(false);
    const [next, setNext] = useState(false);

    const isClick = useRef(false);
    
    const [isCheck, setIsCheck] = useState({
        isName: false,
        isEmail: false,
        isPassword: false,        
        isPhone: false,
        isTerm: false,
        isPolicy: false,
    })

    useEffect(() => {       
        const validateArr = [];
        for(const key in isCheck) {
            validateArr.push(isCheck[key]);
        }
        isClick.current = !validateArr.includes(false);

        if(isClick.current === true){
            setNext(true)
        }else{
            setNext(false)
        }
    }, [isCheck])

    
    const onHandleSubmit = (e) => {
        e.preventDefault();
        if (isClick.current === true) {         
            isClick.current = false;
            SignUpAction.sendNormalInfo({
                mem_email: email,
                mem_password: password,      
                mem_phone: phone,
                mem_name: name,
            })
            history.push("/signup/normal/1");
        }
    };


    const handleTermCheck = (checked) => {
        setTerm(checked)

        if (checked) {
            setIsCheck(prev => ({...prev, isTerm: true }))
        } else {
            setIsCheck(prev => ({...prev, isTerm: false }))
        }
    };
    const handlePolicyCheck = (checked) => {
        setPolicy(checked)

        if (checked) {
            setIsCheck(prev => ({...prev, isPolicy: true }))
        } else {
            setIsCheck(prev => ({...prev, isPolicy: false }))
        }
    };

    return (
        <Container>
            <Layout>
                    <AuthIntroMessage>
                        <h2>REGISTER</h2>
                        <p>WALA 회원가입을 하시면 다양한 서비스를 이용하실 수 있습니다.</p>
                    </AuthIntroMessage>

                    
                    <Form onSubmit={onHandleSubmit}>
                        <SignupNameInput 
                            setName={setName} 
                            setIsCheck={setIsCheck} 
                        />

                        <SignupEmailInput 
                            setEmail={setEmail} 
                            setIsCheck={setIsCheck} 
                        />

                        <SignupPasswordInput 
                            setPassword={setPassword}                             
                            setIsCheck={setIsCheck} 
                        />
                        
                        <SignupPhoneInput
                            setPhone={setPhone}                                         
                            setIsCheck={setIsCheck} 
                        />
                           
                        <SignupBox>
                            <SignUpGuideLines term={term} policy={policy} handleTermCheck={handleTermCheck} handlePolicyCheck={handlePolicyCheck}/>
                            <SignupButton type="submit" on={next}>계정 만들기</SignupButton>
                        </SignupBox>                            
                    </Form>
                    

                    {/* <Mobile>
                    <Form>
                        <SignupEmailInput setEmail={setEmail} setIsCheck={setIsCheck} />
                        <SignupEmailInput setEmail={setEmail} setIsCheck={setIsCheck} />

                        <SignupPasswordInput setPassword={setPassword} setIsCheck={setIsCheck} />

                        <SignupPhoneInput
                            setPhone={setPhone}                              
                            setIsCheck={setIsCheck} 
                        />   

                        <SignupBox>
                            <SignUpGuideLines />
                            <SignupButton type="submit">계정 만들기</SignupButton>
                        </SignupBox>                            
                    </Form>
                    </Mobile> */}
            </Layout>
        </Container>
    )
}

export default SignUpNormal
