import React from "react";
import { Mobile, PC } from "../../MediaQuery";
import { useHistory } from "react-router-dom";

//css
import styled from "styled-components";

import signupsuccessImg from "../../../images/signup-success.png";

const SignUpSuccess = () => {
  const history = useHistory();

  return (
    <div>
      <MbContainer>
        <Content>
          <SuccessImgBox>
            <img src={signupsuccessImg} alt="회원가입 완료 이미지" />
          </SuccessImgBox>
          <SuccessTextBox>
            <SuccessTitle>환영합니다!</SuccessTitle>
            <SuccessSubTitle>WALA 회원가입이 완료 되었습니다 :)</SuccessSubTitle>
            <Text>로그인후 서비스를 이용하실 수 있습니다.</Text>
          </SuccessTextBox>
          <LoginButton
            on={true}
            onClick={() => history.push("/store/login")}
          >
            로그인
          </LoginButton>
        </Content>
      </MbContainer>
    </div>
  );
};

export default SignUpSuccess;

const MbContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: calc(100vh * (230 / 1024)) 0;

  @media screen and (max-width: 930px) {
    display: block;
    padding: calc(100vw * (140 / 428)) 0 0;
  }
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const SuccessImgBox = styled.div`
  width: 80px;
  height: 80px;

  & img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (80 / 428));
    height: calc(100vw * (80 / 428));
  }
`;
const SuccessTextBox = styled.div`
  text-align: center;
  margin-top: 40px;

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (40 / 428));
  }
`;
const SuccessTitle = styled.h2`
  line-height: 1.5;
  color: #222;
  font-weight: 600;
  font-size: 24px;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (24 / 428));
  }
`;
const SuccessSubTitle = styled(SuccessTitle)`
  font-size: 20px;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
  }
`;
const Text = styled.p`
  color: #999;
  font-weight: 400;
  font-size: 14px;
  margin-top: 15px;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (15 / 428));
  }
`;
const LoginButton = styled.button`
  width: 100%;
  height: 50px;
  color: #111;
  font-weight: 500;
  font-size: 18px;
  margin-top: 130px;
  background-color: #fff;
  border: 1px solid #000;

  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
    margin-top: calc(100vw * (130 / 428));
  }
`;