import React from 'react';

import styled , {css} from 'styled-components';
import {useHistory} from 'react-router-dom';

import { imgURL } from '../../server/appInfoContoller';
import { DateToText } from '../common/commonUse';

export default function StylistContentsListSimple({list, setList}){
    const history = useHistory();
    return(
        <Container>
            {
                list?.map((item, index)=>{
                    const {
                        contents_id,
                        contents_date,
                        contents_title,
                        contents_tag,
                        contents_thumbnail
                    } = item;
                    return (
                        <ListWrap key={contents_id}>
                            <ProdDate>{DateToText(contents_date, ".", true)}</ProdDate>
                            <ProdImgWrap
                            onClick={()=>{
                                history.push(`/stylist/stylistModify/${contents_id}`);
                            }}
                            >
                                <Img src={imgURL + contents_thumbnail}/>
                            </ProdImgWrap>
                            {/* <ProdName>by WALA Master</ProdName> */}
                            <ProdTitle>{contents_title}</ProdTitle>
                            <ProdHash>
                                {contents_tag}
                            </ProdHash>
                        </ListWrap>
                    )
                })
            }
        </Container>
    )
}
const Container = styled.div`
    display:flex; height:auto; flex-wrap:wrap;
    padding:0 calc(100vw * (64 / 1920));
    margin-top:calc(100vw * (20 / 1920));
    @media screen and (max-width:1300px){
        padding:0;
    }
`;
const ListWrap = styled.div`
    width:calc(100% / 6); padding-left:calc(100vw * (9 / 1920)); padding-right:calc(100vw * (9 / 1920));
    margin-bottom:calc(100vw * (56 / 1920));

    @media screen and (max-width:1300px){
        width:calc(100% / 4);
    }

    @media screen and (max-width:768px){
        width:calc(100% / 3); padding-left:calc(100vw * (6 / 1920)); padding-right:calc(100vw * (6 / 1920));
    }
`;

const CheckBoxWrap = styled.div`
    margin-bottom:calc(100vw * (6 / 1920));
    text-align:left;
    &:after { content:''; display:block; clear:both; }
`;
const CheckBox = styled.input`
    float:left; width:calc(100vw * (20 / 1920)); height:calc(100vw * (20 / 1920)); border:calc(100vw * (2 / 1920)) solid #A5A5A5;
    cursor:pointer;
`;
const ProdDate = styled.p`
    font-size:calc(100vw * (14 / 1920)); color:#bfbfbf; line-height:calc(100vw * (22 / 1920));
`;

const ProdImgWrap = styled.div`
    width:100%; height:0; padding-bottom:100%; overflow:hidden;
    vertical-align: top;
`;

const Img = styled.img`
    width:100%; height: 100%; object-fit: cover; vertical-align: top;
`;

const ProdName = styled.p`
    font-size:calc(100vw * (12 / 1920)); font-weight:400; color:#A5A5A5; line-height:calc(100vw * (16 / 1920));
    margin:calc(100vw * (6 / 1920)) 0 calc(100vw * (3 / 1920)) 0;
`;
const ProdTitle = styled.p`
    font-size:calc(100vw * (15 / 1920)); line-height:calc(100vw * (21 / 1920)); margin-bottom:calc(100vw * (13 / 1920));
`;
const ProdHash = styled.p`
    font-size:calc(100vw * (13 / 1920)); color:#A5A5A5; line-height:calc(100vw * (18 / 1920));
`;