import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import InquiryDetail from "../../component/service/InquiryDetail";
import Footer from "../../component/common/StoreFooter";
import FooterMenu from "../../component/common/FooterMenu";
import FixMenu from "../../component/common/FixMenu";

import MyPageHeader from "../mypage/MyPageHeader";
import MyInquiryDetail from "./MyInquiryDetail";

export default function InquiryDetailPage() {
  return (
    <>
      <PC>
        <Header search={true} />
        <InquiryDetail title="1:1문의" rank={true} type={2} />
        <Footer />
        <FixMenu />
      </PC>
      <Mobile>
        <MyPageHeader title={"1:1 문의"} prevBtn/>
        <MyInquiryDetail/>
        {/* <InquiryDetail title="1:1문의" rank={true} type={2} /> */}
        {/* <FooterMenu /> */}
      </Mobile>
    </>
  );
}
