import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import serverController from "../../../server/serverController";
import { PaymentAction } from "../../../../store/actionCreators";

import styled from "styled-components";

import IconCheckbox from "../../../img/icon/icon_checkbox.png";
import TrashIcon from "../../../img/icon/icon_trash.png";
import MypageProduct from "../../common/product/MypageProduct";

export default function MypageLatelyList() {
  const [checks, setChecks] = useState([]);
  const [list, setList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allCheck, setAllCheck] = useState(false);

  const getLatelyItems = async () => {
    await serverController.connectFetchController(
      "products/recently",
      "GET",
      null,
      (res) => {
        console.log(res, "최근 본 상품 목록 조회 res");
        if (res?.result === 1) {
          setList(res?.list.reverse() ?? []);
        }
      }
    );
  };

  const getLatelyId = () => {
    const indexArr = [];
    checks.forEach((item, i) => {
      if (item === true) {
        indexArr.push(i);
      }
    });
    const latelyId = indexArr.map((v) => list[v].prd_id);
    return [indexArr, latelyId];
  };

  const deleteLatelyItems = async () => {
    const [indexArr, latelyId] = getLatelyId();

    let success = false;
    for (let i = 0; i < latelyId.length; i++) {
      await serverController.connectFetchController(
        `products/recently/${latelyId[i]}`,
        "GET",
        null,
        (res) => {
          console.log(res, "최근 본 상품 삭제 res ");
          if (res?.result === 1) {
            success = true;
          }
        }
      );
    }
    if (success) {
      alert("삭제 성공하셨습니다.");
      setList((prev) => {
        const newArr = [...prev];
        indexArr.forEach((index) => {
          newArr[index] = null;
        });
        return newArr.filter((v) => v !== null);
      });
      // getLatelyItems()
    }
  };

  const handleAllCheck = (e) => {
    e.preventDefault();
    setAllCheck(!allCheck);
    setChecks(Array(list.length).fill(!allCheck));
  };

  useEffect(() => {
    getLatelyItems();
    setChecks(Array(list.length).fill(allCheck));
  }, []);

  useEffect(() => {
    const [indexArr, latelyId] = getLatelyId();
    setSelectedItems((item) => {
      const newArr = [];
      indexArr.forEach((index) => {
        newArr.push(list[index]);
      });
      return newArr;
    });
  }, [checks]);

  useEffect(() => {
    if (selectedItems.length === list.length) {
      checks[0] ? setAllCheck(true) : setAllCheck(false);
    } else {
      setAllCheck(false);
    }
  });

  return (
    <Container>
      <PC>
        <MypageLatelyListWrap>
          <TitleWrap>
            <Title>최근 본 상품</Title>
          </TitleWrap>
          <LatelyListWrap>
            <LatelyListTitle>
              <ChecksWrap>
                <Checks>
                  <ChkBox
                    id={0}
                    checked={allCheck}
                    type="checkbox"
                    name="checkbox"
                  />
                  <ChkBoxAllLabel
                    onClick={(e) => handleAllCheck(e)}
                    htmlFor={0}
                  >
                    전체선택
                  </ChkBoxAllLabel>
                </Checks>
                <Bar></Bar>
                <Trash onClick={deleteLatelyItems}>
                  <TrashImg src={TrashIcon} />
                </Trash>
              </ChecksWrap>
              <InfoTitleWrap>
                <ProductTit>상품정보</ProductTit>
              </InfoTitleWrap>
              <PriceTitleWrap>
                <PriceTit>판매가</PriceTit>
              </PriceTitleWrap>
              <DateTitleWrap>
                <Keeptit>날짜</Keeptit>
              </DateTitleWrap>
            </LatelyListTitle>
            <LatelyList>
              {list.length > 0 ? (
                list.map((value, index) => {
                  return (
                    <MypageProduct
                      key={"LatelyList" + index}
                      value={value}
                      rank={false}
                      setChecks={setChecks}
                      checks={checks}
                      index={index}
                    />
                  );
                })
              ) : (
                <NoText>조회할 목록이 없습니다.</NoText>
              )}
            </LatelyList>
          </LatelyListWrap>
        </MypageLatelyListWrap>
      </PC>

      <Mobile>
        <MypageLatelyListWrap>
          <TitleWrap>
            <Title>최근 본 상품</Title>
          </TitleWrap>
          <LatelyListWrap>
            <LatelyListTitle>
              <ChecksWrap>
                <Checks>
                  <ChkBox
                    checked={allCheck}
                    id={0}
                    type="checkbox"
                    name="checkbox"
                  />
                  <ChkBoxAllLabel
                    onClick={(e) => handleAllCheck(e)}
                    checked={allCheck}
                    htmlFor={0}
                  >
                    전체선택
                  </ChkBoxAllLabel>
                </Checks>
                <Trash onClick={deleteLatelyItems}>
                  <TrashImg src={TrashIcon} />
                </Trash>
              </ChecksWrap>
            </LatelyListTitle>
            <LatelyList>
              {list.length > 0 ? (
                list.map((value, index) => {
                  return (
                    <MypageProduct
                      key={"wishList" + index}
                      value={value}
                      rank={false}
                      setChecks={setChecks}
                      checks={checks}
                      index={index}
                    />
                  );
                })
              ) : (
                <NoText>조회할 목록이 없습니다.</NoText>
              )}
            </LatelyList>
          </LatelyListWrap>
        </MypageLatelyListWrap>
      </Mobile>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const MypageLatelyListWrap = styled.div`
  width: 1300px;
  margin: 0 auto;
  padding: 80px 0 180px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
    padding: calc(100vw * (80 / 1320)) 0 calc(100vw * (180 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    padding: calc(100vw * (40 / 428)) 0 calc(100vw * (80 / 428));
  }
`;
const TitleWrap = styled.div`
  width: 100%;
  margin-bottom: 25px;

  @media screen and (max-width: 1320px) {
    margin-bottom: calc(100vw * (25 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (15 / 428));
  }
`;
const Title = styled.h2`
  font-size: 24px;
  color: #000;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
    text-align: center;
  }
`;
// list
const LatelyListWrap = styled.div`
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;

  @media screen and (max-width: 1320px) {
    border-width: calc(100vw * (1 / 1320));
  }

  @media screen and (max-width: 930px) {
    border-top: none;
    border-width: calc(100vw * (1 / 428));
  }
`;
const LatelyListTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 0;
  border-bottom: 1px solid #d9d9d9;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (25 / 1320)) 0;
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (15 / 428)) 0;
    border-bottom: calc(100vw * (1 / 428)) solid #000;
  }
`;
const LatelyList = styled.div`
  width: 100%;
`;
const ChecksWrap = styled.div`
  width: 260px;
  padding-left: 10px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (260 / 1320));
    padding-left: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    justify-content: space-between;
    width: 100%;
    padding-left: 0;
  }
`;
const InfoTitleWrap = styled.div`
  width: calc(100% - 660px);

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (660 / 1320)));
  }
`;
const PriceTitleWrap = styled.div`
  width: 150px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (150 / 1320));
  }
`;
const DateTitleWrap = styled.div`
  width: 250px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (250 / 1320));
  }
`;
const ProductTit = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #646464;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
`;
const PriceTit = styled(ProductTit)``;
const Keeptit = styled(ProductTit)``;

// trash
const Trash = styled.div`
  width: 19px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (19 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (19 / 428));
  }
`;
const TrashImg = styled.img`
  width: 100%;
`;
const Bar = styled.div`
  width: 1px;
  height: 19px;
  background: #707070;
  margin: 0 44px 0 35px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1 / 1320));
    height: calc(100vw * (19 / 1320));
    margin: 0 calc(100vw * (44 / 1320)) 0 calc(100vw * (35 / 1320));
  }
`;

//checkbox
const Checks = styled.div`
  height: 24px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (24 / 428));
  }
`;
const ChkBox = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  & + label::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid #000;
    border-radius: 100%;
  }
  :checked + label::after {
    display: none;
  }

  @media screen and (max-width: 1320px) {
    &:checked + label::after {
      width: calc(100vw * (20 / 1320));
      height: calc(100vw * (20 / 1320));
      border: calc(100vw * (2 / 1320)) solid #000;
    }
  }

  @media screen and (max-width: 930px) {
    &:checked + label::after {
      width: calc(100vw * (18 / 428));
      height: calc(100vw * (18 / 428));
      border: calc(100vw * (2 / 428)) solid #000;
    }
  }
`;
const ChkBoxAllLabel = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 42px;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  &&::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: #000;
    border: 2px solid #000;
    border-radius: 100%;
    background: #000 url(${IconCheckbox}) no-repeat center/14px;
  }

  @media screen and (max-width: 1320px) {
    padding-left: calc(100vw * (42 / 1320));
    font-size: calc(100vw * (16 / 1320));
    &&::before {
      width: calc(100vw * (20 / 1320));
      height: calc(100vw * (20 / 1320));
      border: calc(100vw * (2 / 1320)) solid #000;
      background: #000 url(${IconCheckbox}) no-repeat
        center/calc(100vw * (14/1320));
    }
  }
  @media screen and (max-width: 930px) {
    padding-left: calc(100vw * (30 / 428));
    font-size: calc(100vw * (14 / 428));
    &&::before {
      width: calc(100vw * (18 / 428));
      height: calc(100vw * (18 / 428));
      border: calc(100vw * (2 / 428)) solid #000;
      background: #000 url(${IconCheckbox}) no-repeat
        center/calc(100vw * (14/428));
    }
  }
`;
const NoText = styled.p`
  width: 100%;
  padding: 100px 0;
  font-size: 16px;
  text-align: center;
  color: #969696;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (100 / 1320)) 0;
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (50 / 428)) 0;
    font-size: calc(100vw * (15 / 428));
  }
`;
