import {
    styled,
    css,
    Mobile,PC,
    MQ,
    pc,
    mobil,
    mquery,
    Media,
    Px,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
} from '../common/mediaStlye';

export const Wrapper = styled.div`
    position:relative;
    margin:0 auto;
    width: calc(100vw * (1300 / 1920));
    padding-top: calc(100vw * (50 / 1920));

    @media screen and (max-width:1024px){
        width:100%;
        padding-left:calc(100vw * (25 / 428));padding-right:calc(100vw * (25 / 428));
        padding-top:calc(100vw * (60 / 428));
    }
`;

export const ContentsListWrap = styled.div`
    padding-bottom:calc(100vw * (147 / 1920));
`;

export const ContentsSimpleWrap = styled.div`
    width:100%; display:flex; flex-wrap:wrap;
    margin-left:calc(100vw * (-9 / 1920)); margin-right:calc(100vw * (-9 / 1920)); padding:0 calc(100vw * (64 / 1920));
    &:after { content:''; display:block; clear:both; }
    @media screen and (max-width:1300px){
        padding:0;
    }
    @media screen and (max-width:1024px){
        margin-left:calc(100vw * (-6 / 428)); margin-right:calc(100vw * (-6 / 428));
    }
`;


export const TabContainer = styled.div`
    

    @media screen and (max-width:1024px){
        width:100%;
    }
`;
export const TabWrap = styled.div`
    display: flex;
    align-items: center;
    & img {width: calc(100vw*(15/1920)); cursor: pointer;}

    @media screen and (max-width:1024px){
        & img {width: calc(100vw*(12/428)); margin-top: calc(100vw*(24/428)); margin-right: calc(100vw*(15/428));}
    }

`;
export const TabBtn = styled.div`
    &:nth-child(${props => props.isTabActive}) { box-shadow: inset 0 calc(100vw * (-5 / 1920)) 0 #222222; }
`;

export const ManagementTabWrap = styled.div`
    ${TabContainer}{
        width: calc(100vw * (1300 / 1920)); margin:0 auto; border-bottom:1px solid #cccccc;
    }
    ${TabBtn}{
        font-size:calc(100vw * (18 / 1920)); line-height: calc(100vw * (25 / 1920)); font-weight:700; color:#222222; padding:calc(100vw * (18 / 1920)) calc(100vw * (60 / 1920));
    }
    @media screen and (max-width:1024px){
        ${TabContainer}{
            width:100%; padding-left:0; padding-right:0;
        }
        ${TabBtn}{
            font-size:calc(100vw * (18 / 428)); line-height:calc(100vw * (25 / 428));
            padding:calc(100vw * (50 / 428)) 0 calc(100vw * (20 / 428));
        }
    }
`;

export const ProductOrderTabWrap = styled.div`
    ${TabContainer}{
        width: calc(100vw * (1500 / 1920)); margin:0 auto; border-bottom:calc(100vw * (2 / 1920)) solid #222222;
        padding:calc(100vw * (76 / 1920)) calc(100vw * (100 / 1920)) 0;
        @media screen and (max-width:1024px){
            width:100%;
        }
    }
    ${TabBtn}{
        font-size:calc(100vw * (16 / 1920)); line-height:calc(100vw * (22 / 1920)); font-weight:400; color:#222222; padding:calc(100vw * (20 / 1920)) calc(100vw * (25 / 1920));
        position:relative;
        &:not(:last-child):after { content:""; display:inline-block;  height:calc(100vw * (11 / 1920)); width:1px; background:#D9D9D9; position:absolute; right:0; top:50%; transform:translateY(-50%); }

        @media screen and (max-width:1024px){
            padding:calc(100vw * (12 / 1920)) calc(100vw * (15 / 1920));
        }
    }
`;

export const ListContainer = styled.div`
    padding-bottom:calc(100vw * (147 / 1920));

    @media screen and (max-width:1024px){
        min-height: calc(100vh - calc(100vw * (537/ 428)));
        padding-bottom:calc(100vw * (50 / 428));
    }
`;