import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";
import qs from "qs";

import serverController from "../../server/serverController";

import Header from "../../component/common/Header";
import MyPageHeader from "./MyPageHeader";
import MyFaq from "../../component/mypage/faq/MyFaq";
import FullLoading from "../../component/common/modal/FullLoading";

import MyPageLayOutMenu from "../../component/mypage/MyPageLayOutMenu";
import MyPageLayOutName from "../../component/mypage/MyPageLayOutName";
import MyPageLayOutInfo from "../../component/mypage/MyPageLayOutInfo";
import NewPagination from "../../component/common/pagination/NewPagination";

export default function MyFaqPage() {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const history = useHistory();
  const [faqList, setFaqList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [type, setType] = useState(0);

  const [page, setPage] = useState(query.page ?? 1);
  const [pageData, setPageData] = useState([]);

  //* type(int)
  // 주문/결제 : 1
  // 배송 : 2
  // 교환/취소 : 3
  // 회원 : 4
  // 기타 : 5

  // get faq list
  const getFapList = () => {
    serverController.connectFetchController(
      `faqList?type=${type}&page=${page}`,
      "GET",
      null,
      (res) => {
        if (res.result === 1) {
          setPageData(res.pagination);
          setFaqList(res.list);
          setPageLoading(false);
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    setPage(1);
  }, [type]);

  useEffect(() => {
    history.push(`/store/mypage/faq?page=1`);
  }, [page]);

  useEffect(() => {
    getFapList();
  }, [query.page, type]);

  const fapItems = {
    faqList,
    type,
    setType,
    loading,
    setLoading,
  };

  return (
    <>
      <PC>
        <Header />
      </PC>
      <Mobile>
        <MyPageHeader title={"FAQ"} prevBtn prevFunc={() => history.push(`/store/mypage`)} />
      </Mobile>
      {pageLoading && <FullLoading />}

      <MyFaqArea>
        {/* page */}
        <PC>
          <MyPageLayOutName />
        </PC>

        <FlexLayOut>
          <PC>
            <MyPageLayOutMenu />
          </PC>

          {/* content */}
          <ContentSection>
            <PC>
              <MyPageLayOutInfo />
            </PC>

            <MyFaqSection>
              <MyFaq {...fapItems} />
              {faqList.length > 0 && (
                <NewPagination page={page} setPage={setPage} pageData={pageData} />
              )}
            </MyFaqSection>
          </ContentSection>
        </FlexLayOut>
      </MyFaqArea>
    </>
  );
}

const MyFaqArea = styled.div`
  width: 100%;
  padding: 180px 0 200px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const MyFaqSection = styled.div`
  padding-top: 20px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: 0;
  }
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
