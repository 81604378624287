import React, { useState, useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";
import serverController from "./server/serverController";
import { UserDataAction, PermissionAction, DeviceAction, SettingVersionAction } from "./store/actionCreators";
import { Mobile } from "./MediaQuery";
import { Route, Router, useHistory, Switch, useLocation } from "react-router-dom";
import "./App.css";
import { rnView } from "./server/rnView";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useSelector } from "react-redux";
import { ip } from "./server/serverController";

import ScrollToTop from "./components/common/ScrollToTop";
import Main from "./page/main/main";
import VideoPage from "./page/VideoPage";
import CategoryPage from "./page/CategoryPage";
import SearchResultPage from "./page/search/SearchResultPage";
import ContentDetailPage from "./page/ContentDetailPage";
import FashionNewsPage from "./page/FashionNewsPage";
import FashionNewsBlogPage from "./page/FashionNewsBlogPage";
import FashionNewsMediaPage from "./page/FashionNewsMediaPage";
import FashionNewsDetailsPage from "./page/FashionNewsDetailsPage";
import LoginIntroPage from "./page/auth/LoginIntroPage";
import LoginPage from "./page/auth/LoginPage";
import FindIDPage from "./page/auth/FindIDPage";
import FindPasswordPage from "./page/auth/FindPasswordPage";
import MyInfoPage from "./page/auth/MyInfoPage";
import MyInfoAccountPage from "./page/auth/MyInfoAccountPage";
import MyInfoQuestionPage from "./page/auth/MyInfoQuestionPage";
import MyInquiryEditPage from "./store_src/page/mypage/MyInquiryEditPage";
import MyInfoContentsPage from "./page/auth/MyInfoContentsPage";
import MyInfoContentStorePage from "./page/auth/MyInfoContentStorePage";
import MYInfoContentItemsPage from "./page/auth/MYInfoContentItemsPage";
import SignUpPage from "./page/auth/SignUpPage";
import SignUpNormalPage from "./page/auth/SignUpNormalPage";
import SignUpNormalMoreInfoPage from "./page/auth/SignUpNormalMoreInfoPage";
import SignUpBrandPage from "./page/auth/SignUpBrandPage";
import SignUpStylistPage from "./page/auth/SignUpStylistPage";
import SignUpSuccessPage from "./page/auth/SignUpSuccessPage";
import StepSuccessPage from "./page/auth/StepSuccessPage";

import { ManagementIntroPage } from "./page/management/Management";
import { ManagementListPage } from "./page/management/Management";
import { ManagementListOrderPage } from "./page/management/Management";
import ProductDetailPage from "./page/management/ProductDetailPage";

import BrandMainPage from "./page/brand/BrandMainPage";
import BrandArchivePage from "./page/brand/BrandArchivePage";
import BrandRegistationPage from "./page/brand/BrandRegistationPage";

import StylistSponserdPage from "./page/stylist/StylistSponserdPage";
import StylistMylistPage from "./page/stylist/StylistMylistPage";
import StylistSponserdDetailPage from "./page/stylist/StylistSponserdDetailPage";
import StylistPaymentPage from "./page/stylist/StylistPaymentPage";
import StylistRegisterPage from "./page/stylist/StylistRegisterPage";
import StylistMyPage from "./page/stylist/StylistMyPage";
import StylistMyRequestPage from "./page/stylist/StylistMyRequestPage";
import StylistContentsPage from "./page/stylist/StylistContentsPage";
import StylistRegisterSuccessPage from "./page/stylist/StylistRegisterSuccessPage";
import StylistModify from "./page/stylist/StylistModify";
import SponDetailPage from "./page/stylist/SponDetailPage";
import SponCompletePage from "./page/stylist/SponCompletePage";

import InquiryWritePage from "./page/main/InquiryWritePage";
import testPage from "./page/testPage";

// wala-store
/* page */
//main
import MainPage from "./store_src/page/main/MainPage";
import FooterMenu from "./store_src/component/common/FooterMenu";

// search
import SearchPage from "./store_src/page/search/SearchPage";
import SearchResultMainPage from "./store_src/page/search/SearchResultMainPage";

//sub
import StoreCategoryPage from "./store_src/page/product/StoreCategoryPage";
import CategoryProductsPage from "./store_src/page/product/CategoryProductsPage";
import SearchProductsPage from "./store_src/page/product/SearchProductsPage";
import CategorySubCatPage from "./store_src/page/product/CategorySubCatPage";
import ProductPage from "./store_src/page/product/ProductPage";
import BrandPage from "./store_src/page/brand/BrandPage";
import BrandDetailPage from "./store_src/page/brand/BrandDetailPage";
import EventPage from "./store_src/page/event/EventPage";
import StoreContentDetailPage from "./store_src/page/main/StoreContentDetailPage";
import NewPage from "./store_src/page/new/NewPage";
import BestPage from "./store_src/page/best/BestPage";
import WishPage from "./store_src/page/wish/WishPage";
import PlanShopPage from "./store_src/page/plan/PlanShopPage";

//product detail
import StoreProductDetailPage from "./store_src/page/product/ProductDetailPage";

//photo review
import PhotoReviewPage from "./store_src/page/product/PhotoReviewPage";

//login
import StoreLoginPage from "./store_src/page/login/LoginPage";
import StoreSignUpPage from "./store_src/page/login/SignUpPage";
import StoreSuccessPage from "./store_src/page/login/StoreSuccessPage";
import SocialLoginPage from "./store_src/component/login/SocialLoginPage";
import StoreFindIDPage from "./store_src/page/login/StoreFindIDPage";
import StoreFindPWPage from "./store_src/page/login/StoreFindPWPage";

// order
import OrderCancelPage from "./store_src/page/order/OrderCancelPage";
import OrderReturnExchangePage from "./store_src/page/order/OrderReturnExchangePage";
import OrderProductDetailPage from "./store_src/page/order/OrderProductDetailPage";

//mypage
import MypagePage from "./store_src/page/mypage/MypagePage";
import MySettingPage from "./store_src/page/mypage/MySettingPage";
import MyWishPage from "./store_src/page/mypage/WishPage";
import LatelyPage from "./store_src/page/mypage/LatelyPage";
import OrderPage from "./store_src/page/mypage/OrderPage";
import OrderDetailPage from "./store_src/page/mypage/OrderDetailPage";
import ReturnPage from "./store_src/page/mypage/ReturnPage";
import ReturnWritePage from "./store_src/page/mypage/ReturnWritePage";
import ReturnCompletePage from "./store_src/page/mypage/ReturnCompletePage";
import GongPointPage from "./store_src/page/mypage/GongPointPage";
import PointPage from "./store_src/page/mypage/PointPage";
import CouponPage from "./store_src/page/mypage/CouponPage";
import ProfilePage from "./store_src/page/mypage/ProfilePage";
import PasswordPage from "./store_src/page/mypage/PasswordPage";
import AdressPage from "./store_src/page/mypage/AdressPage";
import BankAccountPage from "./store_src/page/mypage/BankAccountPage";
import MyNoticePage from "./store_src/page/mypage/MyNoticePage";
import MyFaqPage from "./store_src/page/mypage/MyFaqPage";
import QuestionPage from "./store_src/page/mypage/QuestionPage";
import ReviewPage from "./store_src/page/mypage/ReviewPage";
import ReviewDetailPage from "./store_src/page/mypage/ReviewDetailPage";
import WriteReviewPage from "./store_src/page/mypage/WriteReviewPage";
import MyProductInquiryPage from "./store_src/page/mypage/MyProductInquiryPage";
import MyProductInquiryDetailPage from "./store_src/page/mypage/MyProductInquiryDetailPage";
import MyDeliveryTrackingPage from "./store_src/page/mypage/MyDeliveryTrackingPage";
import MyPersonalInfoPage from "./store_src/page/mypage/MyPersonalInfoPage";
import MyRecommenderPage from "./store_src/page/mypage/MyRecommenderPage";

//service
import NoticePage from "./store_src/page/service/NoticePage";
import NoticeDetailPage from "./store_src/page/service/NoticeDetailPage";
import ProductInquiryPage from "./store_src/page/service/ProductInquiryPage";
import PrdInquiryDetailPage from "./store_src/page/service/PrdInquiryDetailPage";
// 팝업 변경으로 인해 삭제 처리
// import PrdInquiryWritePage from "./store_src/page/service/PrdInquiryWritePage";
import InquiryPage from "./store_src/page/service/InquiryPage";
import InquiryDetailPage from "./store_src/page/service/InquiryDetailPage";
import StoreInquiryWritePage from "./store_src/page/service/StoreInquiryWritePage";
import InquiryCompletePage from "./store_src/page/service/InquiryCompletePage";
import FaqPage from "./store_src/page/service/FaqPage";
import FaqDetailPage from "./store_src/page/service/FaqDetailPage";
import ServiceCompletePage from "./store_src/page/service/ServiceCompletePage";
import ContentsListPage from "./store_src/page/contents/ContentsListPage";
import ContentsDetailMainPage from "./store_src/page/contents/ContentsDetailMainPage";
import CartPage from "./store_src/page/shopping/CartPage";
import PayPage from "./store_src/page/shopping/PayPage";
import PayCompletePage from "./store_src/page/shopping/PayCompletePage";
import PayCancelCompletePage from "./store_src/page/shopping/PayCancelCompletePage";
import ErrorPage from "./store_src/page/ErrorPage";
import SplashPage from "./store_src/page/SplashPage";
import { Helmet } from "react-helmet-async";
import { QueryClientProvider } from "react-query";
import { queryClient } from "react-query/queryClient";
import { ReactQueryDevtools } from "react-query/devtools";

if (process.env.NODE_ENV === "production") {
  console.log = function no_console() { };
  console.warn = function no_console() { };
  console.info = function no_console() { };
}

function App() {
  const { pathname } = useLocation();
  const history = useHistory();
  const [footerMenuState, setFooterMenuState] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(['pass']);

  useEffect(() => {
    if (pathname.includes('store')) {
      window.location.href = 'https://wala-land.com'
    }
  }, [pathname])

  React.useLayoutEffect(() => {
    (() => {
      const prevPath = sessionStorage.getItem("currentPath");
      sessionStorage.setItem("prevPath", prevPath);
      sessionStorage.setItem("currentPath", pathname);
    })();
  }, [pathname]);

  const putPassAuth = () => {
    if (!cookies?.pass) return;
    serverController.connectFetchController(
      `api/pass`,
      "PUT",
      null,
      (res) => {
        console.log(res.message);
        removeCookie('pass', { path: '/' });
      }
    );
  };

  const getUserData = async () => {
    await serverController.connectFetchController(
      `mypage`,
      "POST",
      null,
      (res) => {
        if (res?.result === 1) {
          const list = res?.list ?? {};
          UserDataAction.logInRequest({
            userInfo: {
              mem_point: list?.mem_point,
              mem_activated: list?.mem_activated,
              mem_alarm: list?.mem_alarm,
              mem_belong: list?.mem_belong,
              mem_brand_name: list?.mem_brand_name,
              mem_business_registration: list?.mem_business_registration,
              mem_celebrity_list: list?.mem_celebrity_list,
              mem_color: list?.mem_color,
              mem_email: list?.mem_email,
              mem_fit: list?.mem_fit,
              mem_gender: list?.mem_gender,
              mem_id: list?.mem_id,
              mem_name: list?.mem_name,
              mem_password: list?.mem_password,
              mem_phone: list?.mem_phone,
              mem_rank: list?.mem_rank,
              mem_register_date: list?.mem_register_date,
              mem_role: list?.mem_role,
              mem_style: list?.mem_style,
              password: list?.password,
              username: list?.username,
            },
            isLogged: true,
          });
          putPassAuth();
        }
      },
      (err) => console.error(err)
    );
  };

  // useEffect(() => {
  // window.addEventListener("resize", function (event) {
  //   if (
  //     (window.innerWidth >= 1024 && isWidth === false) ||
  //     (window.innerWidth < 1024 && isWidth)
  //   ) {
  //     this.window.location.reload();
  //     window.scrollTo(0, 0);
  //   }
  // });
  // }, []);

  useEffect(() => {
    getUserData();
    getAutoLoginInfo();
    rnView();
  }, []);

  // for safari scroll top smooth
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  // s :: auto login
  const getAutoLoginInfo = () => {
    const loginUserInfo = JSON.parse(localStorage.getItem("login-info"));
    if (loginUserInfo) {
      serverController.connectFetchController(
        `login?mem_email=${loginUserInfo.userId}&mem_password=${loginUserInfo.userPw}&type=1`,
        "POST",
        null,
        (res) => {
          if (res?.result === true) {
            getUserData();
          } else {
            return alert("자동로그인에 실패하였습니다.");
          }
        },
        (err) => console.error(err)
      );
    }
  };
  // e :: auto login

  useEffect(() => {
    return history.listen(() => {
      rnView();
    });
  }, [history]);

  // s :: react native web view
  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "getFcmToken",
        })
      );
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "deviceCheck",
        })
      );
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "versionCheck",
        })
      );
    }
  }, []);

  useEffect(() => {
    // webView post message
    if (window.ReactNativeWebView) {
      //RN 통신용
      const listener = (event) => {
        const { data, type } = JSON.parse(event.data);
        switch (type) {
          case "permission":
            PermissionAction.updatePMType({
              permission: {
                state: data,
              },
            });
            break;
          case "getFcmToken":
            PermissionAction.updateFcmToken({
              fcmToken: {
                data: data,
              },
            });
            break;
          case "deviceCheck":
            // alert(data + " : deviceType"); // ios, android
            DeviceAction.deviceType({
              device: {
                data: data,
              },
            });
            break;
          case "versionCheck":
            SettingVersionAction.updateSettingVersion({
              setting: {
                version: data,
              },
            });
            break;
          default:
            break;
        }
      };

      document.addEventListener("message", listener);
      window.addEventListener("message", listener);
    }
  }, []);

  // e :: react native web view

  useEffect(() => {
    // var out = setTimeout(() => {
    //   window.scrollTo(0, 0);
    //   clearTimeout(out);
    // }, 50);

    if (pathname === "/store" || pathname === "/store/") {
      return setFooterMenuState("store");
    } else if (pathname === "/store/brand" || pathname === "/store/brand/") {
      return setFooterMenuState("brand");
    } else if (pathname === "/store/wish" || pathname === "/store/wish/") {
      return setFooterMenuState("wish");
    } else if (pathname === "/store/mypage" || pathname === "/store/mypage/") {
      return setFooterMenuState("myPage");
    } else {
      return setFooterMenuState("");
    }
  }, [pathname]);

  // const findCookieRuuid = () => {
  //   if (cookies.ruuid !== undefined && isLogged === false) {
  //     axios.post(`${ip}api/login/force`, {
  //       token: `${cookies.ruuid}`
  //     }).then(() => { getUserData() })
  //       .catch((err) => { console.log(err) });
  //   };
  // };
  // useEffect(() => {
  //   findCookieRuuid();
  // }, [history.location.pathname]);

  return (
    <>
      <Helmet>
        <meta property="og:title" content="왈라 랜드 컨텐츠 :: WALALAND" />
        <meta property="og:site_name" content="왈라 랜드 컨텐츠 :: WALALAND" />
        <meta name="description" content="지금 바로 '왈라'에서 매일 업데이트 되는 셀럽들의 패션 콘텐츠와 트렌디한 패션 정보를 확인해보세요!" />
        <title>왈라 랜드 컨텐츠 :: WALALAND</title>
        {/* <meta
          property="og:image"
          content="{{ url_for('img', filename='walaLogo.png') }}"
        /> */}
        <meta property="og:url" content="https://wala.land" />
      </Helmet>
      {/* <ScrollToTop /> */}
      {footerMenuState.length > 0 && (
        <Mobile>
          <FooterMenu nowTabRef={footerMenuState} />
        </Mobile>
      )}
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/video" component={VideoPage} />

          <Route exact path="/category/:category_id" component={CategoryPage} />

          <Route exact path="/content/:contents_id" component={ContentDetailPage} />

          <Route exact path="/search" component={SearchResultPage} />
          <Route exact path="/search/:keyword" component={SearchResultPage} />

          <Route exact path="/news" component={FashionNewsPage} />
          <Route exact path="/news/blog" component={FashionNewsBlogPage} />
          <Route exact path="/news/media" component={FashionNewsMediaPage} />
          <Route exact path="/news/detail/:newsId" component={FashionNewsDetailsPage} />

          <Route exact path="/mypage" component={MyInfoPage} />
          <Route exact path="/mypage/account" component={MyInfoAccountPage} />
          <Route exact path="/mypage/questions" component={MyInfoQuestionPage} />
          <Route exact path="/mypage/contents" component={MyInfoContentsPage} />
          <Route exact path="/mypage/contents/store" component={MyInfoContentStorePage} />
          <Route exact path="/mypage/contents/items" component={MYInfoContentItemsPage} />

          {/* auth */}
          <Route exact path="/login" component={LoginIntroPage} />
          <Route exact path="/login/:type_id" component={LoginPage} />
          <Route exact path="/find/id" component={FindIDPage} />
          <Route exact path="/find/password" component={FindPasswordPage} />
          <Route exact path="/signup" component={SignUpPage} />
          <Route exact path="/signup/normal" component={SignUpNormalPage} />
          <Route exact path="/:url/normal/:step" component={SignUpNormalMoreInfoPage} />
          <Route exact path="/signup/brand" component={SignUpBrandPage} />
          <Route exact path="/signup/stylist" component={SignUpStylistPage} />
          <Route exact path="/signup/success" component={SignUpSuccessPage} />
          <Route exact path="/step/success" component={StepSuccessPage} />

          {/* 왈라 브랜드 */}
          {/* 제품관리 */}
          <Route exact path="/management" component={ManagementIntroPage} />
          <Route exact path="/management/list" component={ManagementListPage} />
          <Route exact path="/management/list/order" component={ManagementListOrderPage} />
          <Route exact path="/management/list/detail" component={ProductDetailPage} />

          <Route exact path="/brandmain" component={BrandMainPage} />
          <Route exact path="/brandarchive" component={BrandArchivePage} />
          <Route exact path="/brandregist" component={BrandRegistationPage} />

          {/* 스타일리스트 */}
          <Route exact path="/stylist/sponserd" component={StylistSponserdPage} />
          <Route exact path="/stylist/liked" component={StylistMylistPage} />
          <Route exact path="/stylist/sponserd/:prd_id" component={StylistSponserdDetailPage} />
          <Route exact path="/stylist/payment" component={StylistPaymentPage} />
          <Route exact path="/stylist/regist" component={StylistRegisterPage} />
          <Route exact path="/stylist/regist/success/:open_type" component={StylistRegisterSuccessPage} />
          <Route exact path="/stylist/mypage" component={StylistMyPage} />
          <Route exact path="/stylist/mypage/:statusId" component={StylistMyRequestPage} />
          <Route exact path="/stylist/contents" component={StylistContentsPage} />
          <Route exact path="/stylist/stylistModify/:contentsId" component={StylistModify} />
          <Route exact path="/stylist/detail/:purchasePrd" component={SponDetailPage} />
          <Route exact path="/stylist/pay/complete/:orderId" component={SponCompletePage} />

          {/* 고객센터 */}
          <Route exact path="/notice" component={NoticePage} />
          <Route exact path="/notice/detail/:not_id" component={NoticeDetailPage} />
          <Route exact path="/inquiry" component={InquiryPage} />
          <Route exact path="/inquiry/detail/:inq_id" component={InquiryDetailPage} />
          <Route exact path="/inquiry/write" component={InquiryWritePage} />
          <Route exact path="/faq" component={FaqPage} />
          <Route exact path="/faq/detail" component={FaqDetailPage} />

          <Route exact path="/service/complete" component={ServiceCompletePage} />

          {process.env.NODE_ENV !== "production" && <Route exact path="/test" component={testPage} />}

          {/* /store 경로, 모든 하위경로 컴포넌트 */}
          <AllSubpathsStore footerMenuState={footerMenuState} setFooterMenuState={setFooterMenuState} />

          {/* 404에러 페이지 */}
          <Route exact path="*" component={ErrorPage} />
        </Switch>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </>
  );
}

const AllSubpathsStore = () => {
  return (
    <>
      <Helmet>
        <meta property="og:title" content="왈라 랜드 :: WALALAND" />
        <meta property="og:site_name" content="왈라 랜드 :: WALALAND" />
        <meta
          name="description"
          content="트렌디한 셀럽 패션 정보와 상품 정보는 왈라 스토어에서! ‘왈라 스토어’에서 최신 셀럽 패션 정보와 상품을 구경해보세요!"
        />
        <meta property="og:url" content="https://wala.land/store" />
        <title>왈라 랜드 :: WALALAND </title>
      </Helmet>
      {/* WALA - STORE */}
      <Route exact path="/store" component={MainPage} />
      <Route exact path="/store/splash" component={SplashPage} />

      {/* sub */}
      <Route exact path="/store/search/:index/:keyword" component={SearchProductsPage} />
      <Route exact path="/store/search" component={SearchPage} />
      <Route exact path="/store/search/result" component={SearchResultMainPage} />
      {/* <Route exact path="/store/category" component={StoreCategoryPage}/> */}
      <Route exact path="/store/category/:main_cat/:sub_cat" component={CategoryProductsPage} />
      {/* <Route
          exact
          path="/store/category/:main_cat/:sub_cat"
          component={CategorySubCatPage}
        /> */}
      <Route exact path="/store/product" component={ProductPage} />
      <Route exact path="/store/brand" component={BrandPage} />
      <Route exact path="/store/wish" component={WishPage} />
      <Route exact path="/store/brand/detail/:brandId" component={BrandDetailPage} />
      <Route exact path="/store/event" component={EventPage} />
      <Route exact path="/store/plan/:event_id" component={PlanShopPage} />

      {/* <Route
        exact
        path="/store/content/:contents_id"
        component={StoreContentDetailPage}
      /> */}
      {/* <Route exact path="/store/event/detail" component={EventDetailPage}/> */}
      <Route exact path="/store/new" component={NewPage} />
      <Route exact path="/store/best" component={BestPage} />

      {/* product detail */}
      <Route exact path="/store/detail/:prd_id" component={StoreProductDetailPage} />

      {/* photo review */}
      <Route exact path="/store/review/photo" component={PhotoReviewPage} />

      {/* login */}
      <Route exact path="/store/login" component={StoreLoginPage} />
      <Route exact path="/store/signup" component={StoreSignUpPage} />
      <Route exact path="/store/signup/success" component={StoreSuccessPage} />
      <Route exact path="/social_success" component={SocialLoginPage} />
      <Route exact path="/store/find/id" component={StoreFindIDPage} />
      <Route exact path="/store/find/pw" component={StoreFindPWPage} />

      {/* order */}
      <Route exact path="/store/order/cancel" component={OrderCancelPage} />
      <Route exact path="/store/order/return" component={OrderReturnExchangePage} />
      <Route exact path="/store/order/product/detail" component={OrderProductDetailPage} />

      {/* mypage */}
      <Route exact path="/store/mypage" component={MypagePage} />
      <Route exact path="/store/mypage/setting" component={MySettingPage} />
      <Route exact path="/store/mypage/wish" component={MyWishPage} />
      <Route exact path="/store/mypage/lately" component={LatelyPage} />
      <Route exact path="/store/mypage/order/:tab_id" component={OrderPage} />
      <Route exact path="/store/mypage/order/detail/:order_Id" component={OrderDetailPage} />
      <Route exact path="/store/mypage/return" component={ReturnPage} />
      <Route exact path="/store/mypage/delivery/tracking" component={MyDeliveryTrackingPage} />

      <Route exact path="/store/mypage/return/write" component={ReturnWritePage} />
      <Route exact path="/store/mypage/return/complete" component={ReturnCompletePage} />

      <Route exact path="/store/mypage/wala/point" component={GongPointPage} />
      <Route exact path="/store/mypage/point" component={PointPage} />
      <Route exact path="/store/mypage/coupon" component={CouponPage} />
      <Route exact path="/store/mypage/profile" component={ProfilePage} />
      <Route exact path="/store/mypage/password" component={PasswordPage} />
      <Route exact path="/store/mypage/adress" component={AdressPage} />
      <Route exact path="/store/mypage/bank" component={BankAccountPage} />
      <Route exact path="/store/mypage/notice" component={MyNoticePage} />
      <Route exact path="/store/mypage/faq" component={MyFaqPage} />
      <Route exact path="/store/mypage/inquiry" component={QuestionPage} />
      <Route exact path="/store/mypage/inquiry/edit" component={MyInquiryEditPage} />
      <Route exact path="/store/mypage/inquiry/detail" component={InquiryDetailPage} />
      <Route exact path="/store/mypage/inquiry/write" component={StoreInquiryWritePage} />
      <Route exact path="/store/mypage/inquiry/complete" component={InquiryCompletePage} />
      <Route exact path="/store/mypage/review" component={ReviewPage} />
      <Route exact path="/store/mypage/review/detail" component={ReviewDetailPage} />
      {/* write_review 임시 주석처리 */}
      <Route exact path="/store/mypage/write_review/:txn_id" component={WriteReviewPage} />

      <Route exact path="/store/mypage/productInquiry" component={MyProductInquiryPage} />
      <Route exact path="/store/mypage/productInquiry/detail" component={MyProductInquiryDetailPage} />
      <Route exact path="/store/mypage/personal/:step" component={MyPersonalInfoPage} />
      <Route exact path="/store/mypage/recommender" component={MyRecommenderPage} />

      {/* service */}
      <Route exact path="/store/notice" component={NoticePage} />
      <Route exact path="/store/notice/detail/:not_id" component={NoticeDetailPage} />
      <Route exact path="/store/productInquiry" component={ProductInquiryPage} />
      <Route exact path="/store/productInquiry/detail" component={PrdInquiryDetailPage} />
      {/* 팝업 변경으로 인해 삭제 처리 */}
      {/* <Route
          exact
          path="/store/productInquiry/write/:prd_id"
          component={PrdInquiryWritePage}
        /> */}
      <Route exact path="/store/inquiry" component={InquiryPage} />
      <Route exact path="/store/inquiry/detail/:inq_id" component={InquiryDetailPage} />
      <Route exact path="/store/inquiry/write" component={InquiryWritePage} />
      <Route exact path="/store/faq" component={FaqPage} />
      <Route exact path="/store/faq/detail" component={FaqDetailPage} />
      <Route exact path="/store/service/complete" component={ServiceCompletePage} />

      {/* contents */}
      <Route exact path="/store/contents/list" component={ContentsListPage} />
      <Route exact path="/store/contents/detail" component={ContentsDetailMainPage} />

      {/* shopping */}
      <Route exact path="/store/cart" component={CartPage} />
      <Route exact path="/store/pay" component={PayPage} />
      <Route exact path="/store/pay/complete" component={PayCompletePage} />
      <Route exact path="/store/pay/cancel/complete" component={PayCancelCompletePage} />
    </>
  );
};

export default App;
