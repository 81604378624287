import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";

// controller
import { imgURL } from "../../../server/appInfoContoller";
import { ip } from "../../server/serverController";
import modalOpen from "../../../server/modal";

// elements
import { AlertBox } from "../login/auth.elements";

//img
import IconShareKakao from "../../img/icon/icon_share_kakao.svg";
import IconShareUrl from "../../img/icon/icon_share_url.svg";

import IconClose from "../../img/icon/icon_popup_close.svg";


export default function ContentsShare({ contentsDetailData, setSharePop }) {
  const { pathname, location } = useLocation();
  const [alertBoxShow, setAlertBoxShow] = useState(false);

  const copyTextUrl = () => {
    navigator?.clipboard?.writeText("https://wala.land/store/contents/list?id=" + contentsDetailData?.contents_id).then(() => {
      setAlertBoxShow(true);
    });
  };

  useEffect(() => {
    alertBoxShow === true &&
      setTimeout(() => {
        setAlertBoxShow(false);
      }, 2000);
  }, [alertBoxShow]);

  // s :: share kakao
  useEffect(() => {
    let cleanUpFunc = true;
    if (cleanUpFunc) {
      shareKakaoFunc();
    } else {
      return;
    }
    return () => {
      cleanUpFunc = false;
    };
  }, []);

  const shareKakaoFunc = () => {
    // let productImgUrl = null;
    // if (product?.prd_main_img?.split(",")?.length) {
    //   productImgUrl = imgURL + product?.prd_main_img?.split(",")[0];
    // } else {
    //   productImgUrl = imgURL + product.prd_main_img;
    // }
    if (window.Kakao) {
      const kakaoSetting = window.Kakao;
      if (!kakaoSetting.isInitialized()) kakaoSetting.init("37a1ddb33e426b6bf6c53795c7c72df9");

      kakaoSetting.Link.createDefaultButton({
        container: "#kakao-share-button",
        objectType: "feed",
        content: {
          title: contentsDetailData?.contents_title,
          description: "WALALAND",
          imageUrl: `${imgURL}${contentsDetailData?.contents_main}`,
          link: {
            mobileWebUrl: "https://wala.land/store/contents/list?id=" + contentsDetailData?.contents_id,
            webUrl: "https://wala.land/store/contents/list?id=" + contentsDetailData?.contents_id,
          },
        },
        buttons: [
          {
            title: "웹으로 보기",
            link: {
              mobileWebUrl: "https://wala.land/store/contents/list?id=" + contentsDetailData?.contents_id,
              webUrl: "https://wala.land/store/contents/list?id=" + contentsDetailData?.contents_id,
            },
          },
        ],
      });
    }
  };
  // e:: share kakao
  return (
    <>
      <AlertBoxCustom show={alertBoxShow}>링크가 복사되었습니다.</AlertBoxCustom>
      <Mobile>
        <ModalBg
          onClick={() => {
            setSharePop(false);
          }}
        />
      </Mobile>
      <ShareModal>
        <ModalWrap>
          <ShareUl>
            <ShareList id="kakao-share-button">
              <ShareImg>
                <img src={IconShareKakao} alt="kakao-share-icon" />
              </ShareImg>
              <Mobile>
                <ShareText>카카오톡</ShareText>
              </Mobile>
            </ShareList>
            <ShareList onClick={copyTextUrl}>
              <ShareImg>
                <img src={IconShareUrl} alt="share-icon" />
              </ShareImg>
              <Mobile>
                <ShareText>링크 복사</ShareText>
              </Mobile>
            </ShareList>
          </ShareUl>
          <Mobile>
            <ModalCloseButton
              onClick={() => {
                setSharePop(false);
              }}
            >
              닫기
            </ModalCloseButton>
          </Mobile>
        </ModalWrap>
      </ShareModal>
    </>
  );
};


// share modal
const ShareModal = styled.div`

  @media screen and (max-width: 1320px) {
    
  }
  @media screen and (max-width: 930px) {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    position: fixed;
    z-index: 106;
    left: 0;
    top: auto;
    bottom: 0;
    transform: translateX(0);
    width: 100%;
    height: auto;
    background-color: #fff;
    /* border-top-left-radius: 50px;
    border-top-right-radius: 50px; */
  }
`;
const ModalWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media screen and (max-width: 930px) {
    display: block;
    height: auto;
  }
`;
const ShareUl = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (40 / 428)) 0;
  }
`;
const ShareList = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  &:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-right: calc(100vw * (60 / 428));
    }
  }
`;
const ShareImg = styled.div`
  width: 26px;
  height: 26px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (26 / 1320));
    height: calc(100vw * (26 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (56 / 428));
    height: calc(100vw * (56 / 428));
  }
`;
const ShareText = styled.p`
  color: #777777;
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;

const ModalBg = styled.div`
  @media screen and (max-width: 930px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 105;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;
const ModalCloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 25px;
  width: 24px;
  height: 24px;
  font-size: 0;
  background: url(${IconClose}) no-repeat center center / contain;

  @media screen and (max-width: 1320px) {
    top: calc(100vw * (20 / 1320));
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    position: static;
    width: 100%;
    height: calc(100vw * (80 / 428));
    font-size: calc(100vw * (18 / 428));
    color: #333333;
    font-weight: 500;
    text-align: center;
    padding: 0 calc(100vw * (36 / 428));
    background: #fff;
    border-top: 1px solid #eeeeee;
  }
`;
// alert box
const AlertBoxCustom = styled(AlertBox)`
  @media screen and (max-width: 930px) {
    bottom: calc(100vw * (170 / 428));
  }
`;
