import React, { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import SubTitleSection from "../../component/common/SubTitleSection";
import BestItems from "../../component/best/BestItems";
import Footer from "../../component/common/StoreFooter";
import FixMenu from "../../component/common/FixMenu";
import FooterMenu from "../../component/common/FooterMenu";

export default function BestPage() {
  return (
    <>
      <PC>
        <Header />
        <SubTitleSection title="BEST" subText="WALA 스토어에 입점한 BEST ITEM들을 확인해 보세요." />
        <BestItems />
        <Footer />
        <FixMenu />
      </PC>

      <Header />
      <SubTitleSection title="BEST" subText="WALA 스토어에 입점한 BEST ITEM들을 확인해 보세요." />
      <BestItems />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
      <FixMenu />
    </>
  );
}
