import React from 'react';
import styled from 'styled-components';
import { Mobile, PC } from "../MediaQuery";

import VideoHeader from '../components/common/header/VideoHeader';
import TvMain from '../components/video/TvMain';
import FooterMenu from "../components/common/footer/FooterMenu";

const VideoPage = () => {   
    return (
        <Wrapper>
            <VideoHeader/>
            <TvMain />
        <Mobile>
            <FooterMenu black={true}/>
        </Mobile>
        </Wrapper>
    )
}

export default VideoPage;

const Wrapper = styled.div`
    width: 100%;
`
            
            
            
            