import React, { useState, useCallback, useEffect } from 'react';

import {  
    FieldBox,
    Label,    
    LabelName,
    Input,    
} from '../auth.elements';

const SignupStylistInfoInput = ({ setBelong, setCelebList, setIsCheck }) => {
    const [value, setValue] = useState(""); 
    const [value2, setValue2] = useState("");

    useEffect(() => {
        if(value && value2){
            setIsCheck(prev => ({
                ...prev, 
                isStylistInfo: true
            }))
            setBelong(value)
            setCelebList(value2)
        }else{
            setIsCheck(prev => ({
                ...prev, 
                isStylistInfo: false
            }))
        }

    }, [value, value2])   


    return (
        <>
        <FieldBox>
            <Label>
                <LabelName>소속</LabelName>
                <Input 
                required
                value={value}
                onChange={e => setValue(e.target.value)}
                />
            </Label> 
        </FieldBox>
        <FieldBox>
            <Label>
                <LabelName>셀럽 리스트</LabelName>
                <Input
                required
                value={value2}
                onChange={e => setValue2(e.target.value)}
                />
            </Label> 
        </FieldBox>
        </>
    )
}

export default SignupStylistInfoInput
