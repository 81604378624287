import React, { useState, useEffect } from "react";
import styled from "styled-components";

// controller
import serverController from "../../../server/serverController";

import qs from "qs";

//page
import ReviewDetailModal from "../../common/modal/ReviewDetailModal";
import { imgURL } from "../../../server/appInfoController";

import TextImg from "../../../img/best_item_img1.png";
import NewPagination from "../../common/pagination/NewPagination";
import { useHistory, useParams } from "react-router-dom";

export default function DetailPhotoReview() {
  const { prd_id } = useParams();
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [photoReviewList, setPhotoReviewList] = useState([]);
  const [photoModal, setPhotoModal] = useState(false);
  const [index, setIndex] = useState(0);

  // get photo review data
  const getPhotoReviews = () => {
    serverController.connectFetchController(
      `review/product/${query.prdId || prd_id}?page=${page}&limit=20&type=3`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setPageData(res.pagination);
          setPhotoReviewList(res.reviewList);
        }
      }
    );
  };

  useEffect(() => {
    if (query.prdId || prd_id) {
      getPhotoReviews();
    }
  }, [prd_id, query.prdId]);

  return (
    <Container>
      {photoModal && (
        <ReviewDetailModal
          setPhotoModal={setPhotoModal}
          reviewData={photoReviewList}
          index={index}
        />
      )}

      <Wrap>
        <PhotoReviewBox>
          {photoReviewList.map((photoReviewItem, index) => {
            return (
              <PhotoReviewList
                key={photoReviewItem.rev_id}
                onClick={() => {
                  setPhotoModal(true);
                  setIndex(index);
                }}
              >
                <PhotoReviewImg>
                  <img src={`${imgURL}${photoReviewItem.rev_image}`} alt="photoReview-img" />
                </PhotoReviewImg>
              </PhotoReviewList>
            );
          })}
        </PhotoReviewBox>
      </Wrap>
      <Wrap>
        {photoReviewList.length > 0 && (
          <NewPagination page={page} setPage={setPage} pageData={pageData} />
        )}
      </Wrap>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
const Wrap = styled.div``;

const PhotoReviewBox = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1200 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    margin: 0;
  }
`;
const PhotoReviewList = styled.li`
  cursor: pointer;

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (1 / 428));

    &:not(:nth-child(3n)) {
      margin-right: calc(100vw * (1 / 428));
    }
  }
`;
const PhotoReviewImg = styled.div`
  width: 150px;
  height: 150px;
  background-color: #fff;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (150 / 1320));
    height: calc(100vw * (150 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (142 / 428));
    height: calc(100vw * (142 / 428));
  }
`;
