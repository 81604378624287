import React, { useState, useEffect, useCallback } from 'react';

import {  
    DoubleFieldBox,
    Label,    
    LabelName,
    Input,    
} from '../auth.elements';

const SignupBrandNameInput = ({ setRank, setName, setIsCheck }) => {
    const [value, setValue] = useState(""); 
    const [value2, setValue2] = useState("");

    useEffect(() => {
        if(value && value2){
            setIsCheck(prev => ({
                ...prev, 
                isBrandInfo: true
            }))
            setName(value);
            setRank(value2)
        }else{
            setIsCheck(prev => ({
                ...prev, 
                isBrandInfo: false
            }))
        }
    }, [value, value2])   

    return (
        <>
        <DoubleFieldBox>
            <Label>
                <LabelName>성함</LabelName>
                <Input 
                    required
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />                                
            </Label> 

            <Label>
                <LabelName>직함</LabelName>
                <Input 
                    required
                    value={value2}
                    onChange={e => setValue2(e.target.value)}
                />                                
            </Label> 
        </DoubleFieldBox>
        </>
    )
}

export default SignupBrandNameInput

