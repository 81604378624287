import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Mobile, PC } from "../../common/mediaStlye";
import { Link } from "react-router-dom";

import { Container, Layout, MobileTab } from "./mypage.elements";
import { UserDataAction } from "../../../store/actionCreators";

import MyPageHeadings from "./MyPageHeadings";
import MyPageUserProfileInfo from "./MyPageUserProfileInfo";
import MyPageUserWalaInfo from "./MyPageUserWalaInfo";

import mypageArrowIcon from "../../../images/mypage-arrow.png";
import ExitModal from "../../common/pop/ExitModal";
import serverController from "../../../server/serverController";
import Modal from "../../../server/modal";

const MyPage = () => {
  const [isExitModal, setIsExitModal] = useState(false);
  const history = useHistory();

  const exitClick = () => {
    serverController.connectFetchController(
      `user/exit`,
      "PUT",
      null,
      function (res) {
        if (res.result == 1) {
          console.log(res + "회원탈퇴 완료");
          UserDataAction.logOutRequest();
          history.push(`/login`);
        }
      }
    );
  };

  return (
    <>
      <PC>
        <Container>
          <Layout>
            <MyPageHeadings />
            <MyPageUserProfileInfo />
            <MyPageUserWalaInfo setIsExitModal={setIsExitModal} />
          </Layout>
        </Container>
      </PC>
      <Mobile>
        <Container style={{ borderBottom: "1px solid #a5a5a5" }}>
          <Layout>
            <MyPageHeadings />
            <MyPageUserProfileInfo />
          </Layout>
        </Container>

        <div style={{ marginBottom: "calc(100vw * (181 / 428))" }}>
          <Link to="/mypage/account">
            <MobileTab>
              <span>회원정보</span>
              <img src={mypageArrowIcon} />
            </MobileTab>
          </Link>
          <Link to="/mypage/questions">
            <MobileTab>
              <span>문의목록</span>
              <img src={mypageArrowIcon} />
            </MobileTab>
          </Link>
          <Link to="/mypage/contents">
            <MobileTab>
              <span>보관 콘텐츠/아이템</span>
              <img src={mypageArrowIcon} />
            </MobileTab>
          </Link>
          <MobileTab
            onClick={() => {
              setIsExitModal(true);
              Modal.modalOpen();
            }}
          >
            <span>회원탈퇴</span>
          </MobileTab>
        </div>
      </Mobile>
      {isExitModal && (
        <ExitModal setIsExitModal={setIsExitModal} exitClick={exitClick} />
      )}
    </>
  );
};

export default MyPage;
