import React, { useEffect, useState } from "react";
import styled from "styled-components";
import IconSelectArrow from "../../../img/icon/icon_bottom_arrow.svg";
import MyPageBottom from "../../../page/mypage/MyPageBottom";

const bankList = [
  { value: "select", title: "선택" },
  { value: "002", title: "산업은행" },
  { value: "003", title: "기업은행" },
  { value: "004", title: "KB국민은행" },
  { value: "007", title: "수협은행" },
  { value: "011", title: "NH농협은행" },
  { value: "012", title: "농.축협은행" },
  { value: "023", title: "SC제일은행" },
  { value: "027", title: "한국씨티은행" },
  { value: "088", title: "신한은행" },
  { value: "089", title: "케이뱅크" },
  { value: "090", title: "카카오뱅크" },
  { value: "031", title: "대구은행" },
  { value: "032", title: "부산은행" },
  { value: "034", title: "광주은행" },
  { value: "035", title: "제주은행" },
  { value: "037", title: "전북은행" },
  { value: "039", title: "경남은행" },
  { value: "045", title: "새마을금고" },
  { value: "048", title: "신협" },
];

const myPageBottomTextList = ["환불 계좌 유의사항"];

export default function MyBankAccount(props) {
  const {
    userName,
    setUserName,
    bankNum,
    setBankNum,
    selectedValue,
    setSelectedValue,
    setBankAccount,
    bankName,
  } = props;

  const SaveButtonClick = () => {
    if (userName.length === 0 || selectedValue === "selectT선택" || bankNum.length === 0) {
      return alert("모든 정보를 입력해주세요.");
    } else {
      setBankAccount();
    }
  };

  return (
    <Container>
      <ListWrap>
        <ListArea>
          <AddressList>
            <InputText>예금주</InputText>
            <Input
              type="text"
              value={userName}
              active={userName.length > 0}
              placeholder="예금주명을 입력해주세요."
              onChange={(e) => {
                setUserName(e.target.value);
              }}
            />
          </AddressList>
          <AddressList>
            <InputText>은행</InputText>
            <SelectButton
              borderActive={selectedValue !== "selectT선택"}
              onChange={(e) => {
                setSelectedValue(e.target.value);
              }}
            >
              {bankList.map((item) => {
                return (
                  <option
                    key={item.value}
                    value={`${item.value}T${item.title}`}
                    selected={item.title === bankName}
                  >
                    {item.title}
                  </option>
                );
              })}
            </SelectButton>
          </AddressList>
          <AddressList>
            <InputText>계좌번호</InputText>
            <Input
              type="number"
              value={bankNum}
              active={bankNum.length > 0}
              placeholder="계좌번호를 입력해주세요."
              onChange={(e) => {
                if (e.target.value.length > 20) return;
                setBankNum(e.target.value);
              }}
            />
          </AddressList>
        </ListArea>
        <SaveBtn onClick={SaveButtonClick} type="button">
          저장
        </SaveBtn>
      </ListWrap>

      <MyPageBottom textList={myPageBottomTextList} />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const ListWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0 0;
  }
`;
const ListArea = styled.div`
  width: 378px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const AddressList = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 428));
    }
  }
`;

const InputText = styled.p`
  width: 100%;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 8px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-bottom: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-bottom: calc(100vw * (8 / 428));
  }
`;
const Input = styled.input`
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  transition: ease 0.3s all;
  padding: 0 15px;
  flex: 1;

  &::placeholder {
    color: #acacac;
  }
  &:focus {
    border: 1px solid #000;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: 0 calc(100vw * (15 / 428));
  }
`;
const SelectButton = styled.select`
  display: block;
  width: 100%;
  height: 50px;
  color: #acacac;
  font-weight: 400;
  font-size: 15px;
  background: #ffffff url(${IconSelectArrow}) no-repeat right 15px center / 24px;
  border: 1px solid #ddd;
  padding: 0 15px;
  transition: ease 0.3s all;
  border-radius: 0;

  -webkit-appearance: none; /* 네이티브 외형 감추기*/
  -moz-appearance: none;
  appearance: none;
  font-weight: normal;
  letter-spacing: normal;
  cursor: pointer;

  &.focus {
    color: #333;
    border: 1px solid #000;
  }

  ${({ borderActive }) =>
    borderActive &&
    `border: 1px solid #000;
  color: #333;
  `}
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
    font-size: calc(100vw * (15 / 1320));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 1320)) center /
      calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 428)) center /
      calc(100vw * (24 / 428));
  }
`;
const SaveBtn = styled.button`
  display: block;
  width: 378px;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;
  margin: 30px auto 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (378 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
    margin: calc(100vw * (30 / 1320)) auto 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
    margin: calc(100vw * (30 / 428)) auto 0;
  }
`;
