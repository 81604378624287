import React, {useState, useEffect} from 'react';

import styled, { css } from 'styled-components';

import IconContensView from '../../img/common/icon_contentsView.png';
import IconContensList from '../../img/common/icon_contentsList.png';
import IconReverse from '../../img/common/icon_reverse.png';

export const ProductListTab = ({setViewSet}) =>{

    const [sort, setSort] = useState(true);
    const [view, setView] = useState(true);
    const [btnState, setBtnState] = useState('');
    
    function toggle([toggle,setToggle], dataSet, result1, result2){
        let sortReturn = '';
        if(toggle){
            sortReturn = result1;
        }else if(!toggle){
            sortReturn = result2;
        }
        setToggle(!toggle);
        console.log(toggle, toggle);
        return dataSet(sortReturn);
    }

    function select(){

    }


    return(
        <TabContainer>
            <TabWrap>
                <Row>
                    <Col>
                        <MoFl>
                            <ContentsViewBtn view={view} icon={view ? IconContensView : IconContensList} onClick={()=>{toggle([view, setView], setViewSet, 'simple', 'list')}}>{view ? '콘텐츠 간단 보기' : '콘텐츠 LIST 보기'}</ContentsViewBtn>
                            <InformBtnWrap moOrder={2}>
                                <InformBtn>협찬</InformBtn>
                                <InformBtn>일반대여</InformBtn>
                                <InformBtn>판매</InformBtn>
                            </InformBtnWrap>
                        </MoFl>
                        <OrderBtnWrap order={1}>
                            <ProdOrderAllBtn>노출 순서 전체 변경</ProdOrderAllBtn>
                            <ProdOrderBtn>
                                <Selected onClick={()=>{toggle([sort,setSort], setBtnState ,'desc', 'asc')}} sort={sort}>제품 정렬</Selected>
                                <SelectWrap sort={sort}>
                                    <Select>노출 순</Select>
                                    <Select>최신 순</Select>
                                    <Select>오래된 순</Select>
                                </SelectWrap>
                            </ProdOrderBtn>
                        </OrderBtnWrap>
                    </Col>
                </Row>
            </TabWrap>
        </TabContainer>
    )
}

const clear = `&:after { display:block; content:""; clear:both; }`

const TabContainer = styled.div`
    padding:calc(100vw * (65 / 1920)) calc(100vw * (64 / 1920)) calc(100vw * (30 / 1920));

    @media screen and (max-width:1300px) {
        padding:calc(100vw * (65 / 1920)) 0 calc(100vw * (30 / 1920));
    }
`;

const TabWrap = styled.div`

`;

const Row = styled.div`
    position:relative;
`;

const Col = styled.div`
    ${clear}; position:relative;
`;
const MoFl = styled.div`
    @media screen and (max-width:1024px){
        float: left;
    }
`;

const ContentsViewBtn = styled.button`
    float:left; position:relative; font-size:calc(100vw * (18 / 1920)); font-weight:900; display:flex; align-items: center;
    &:before { width:calc(100vw * (28 / 1920)); height:calc(100vw * (28 / 1920)); content:''; display:inline-block; background:url(${props => props.icon}) no-repeat center / contain ; margin-right:calc(100vw * (5 / 1920)); }

    @media screen and (max-width:1024px){
        float:none;
        margin-bottom:calc(100vw * (20 / 428)); font-size:calc(100vw * (14 / 428)); 
        &:before { width:calc(100vw * (20 / 428)); height:calc(100vw * (20 / 428)); }
    }
`

const InformBtnWrap = styled.div`
    position:absolute; left:50%; top:0; transform:translateX(-50%);
    @media screen and (max-width:1024px){
        position:relative; display:block;
    }
`

const InformBtn = styled.button`
    font-size:calc(100vw * (16 / 1920)); position:relative;
    padding: 0 calc(100vw * (16 / 1920)); 
    &:not(:last-child):after { content:""; display:inline-block;  height:11px; width:1px; background:#D9D9D9; position:absolute; right:0; top:50%; transform:translateY(-50%); }
    
    @media screen and (max-width:1024px){
        font-size:13px; padding: 0 calc(100vw * (18 / 768)); 
    }
`

const OrderBtnWrap = styled.div`
    float:right;
    ${clear};
    @media screen and (max-width:1024px) {
        flex-direction:column;
    }
`
const ProdOrderBtn = styled.button`
    text-align:right; font-size:calc(100vw * (15 / 1920)); position:relative;

    @media screen and (max-width:1024px){
        font-size:calc(100vw * (15 / 428));
    }
    
`
const SelectWrap = styled.div`
    position:absolute; top:calc(100vw * (24 / 1920)); left:0; width:100%; border:1px solid #D5D5D5; background:#ffffff;
    z-index:10; display:none;
    ${props=>!props.sort && `display:block`}
    
    @media screen and (max-width:1024px){
        top:calc(100vw * (24 / 428));
    }
`
const Selected = styled.button`
    display:block;
    &:after { 
        content:''; display:inline-block; width:0; height:0;
        border-top:8px solid #222222;
        border-left:5px solid transparent;
        border-right:5px solid transparent;
        border-bottom:8px solid none;
        margin-left:6px; margin-bottom:1px;
        transform:rotate(0);
        ${props=>!props.sort && `transform:rotate(180deg)`}
     }
    @media screen and (max-width:1024px) {
        order:2; font-size:14px; float:right;
        &:after { 
            border-top:6px solid #222222;
            border-left:4px solid transparent;
            border-right:4px solid transparent;
            border-bottom:6px solid none;
            margin-bottom:2px;
        }
    }
`
const Select = styled.button`
    display:block; width:100%; padding:5px 0;
`

const ProdOrderAllBtn = styled.button`
    text-align:right; font-size:15px; font-weight:700; margin-left:calc(100vw * (18 / 1920)); float:right; display:block;
    &:after { 
        content:''; display:inline-block; width:15px; height:15px; background:url(${IconReverse}) no-repeat center / contain ; margin-bottom:-2px; margin-left:2px;
     }
    @media screen and (max-width:1024px) {
        order:1; font-size:14px; float:none; margin-bottom:20px;
        &:after { 
            margin-bottom:-3px;
        }
    }
`

