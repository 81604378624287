//react
import { Mobile, PC } from "../../../MediaQuery";
import { useState } from "react";
import styled from "styled-components";

//img
import IconCancel from "../../../img/icon/icon_myhaeder_cancle.svg";
import IconSelectArrow from "../../../img/icon/icon_bottom_arrow.svg";
import IconChkOn from "../../../img/icon/icon_chk_on.svg";
import IconChkOff from "../../../img/icon/icon_chk_off.svg";
import IconFileUpload from "../../../img/icon/icon_upload.svg";
import IconFileUploadCancel from "../../../img/icon/icon_upload_cancel.svg";

// controller
import { ip } from "../../../server/serverController";

export default function ReportModal({ pkId, setClosePop, inq = false }) {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");

  const postReport = () => {
    if (title.length < 5) return alert("제목을 5글자 이상 작성해주세요.");
    if (text.length < 10) return alert("내용을 10글자 이상 작성해주세요.");

    var header = new Headers();
    header.append("Content-Type", "application/json");

    var rawBodyReport = JSON.stringify({
      reportType: inq ? "2" : "1",
      reportTarget: pkId.toString(),
      reportTitle: title,
      reportContents: text,
    });

    var options = {
      method: "POST",
      body: rawBodyReport,
      headers: header,
      redirect: "follow",
    };

    fetch(`${ip}report`, options)
      .then((response) => response.json())
      .then((res) => {
        if (res.result === 1) {
          setClosePop(false);
        }
      })
      .catch((error) => console.error("error : " + error));
  };

  return (
    <Container>
      <Background onClick={() => setClosePop(false)} />

      <Content>
        {/* header */}
        <HeaderSection>
          <HeaderInner>
            <p>신고하기</p>
            <CloseButton onClick={() => setClosePop(false)}>
              <img src={IconCancel} alt="cancel-icon" />
            </CloseButton>
          </HeaderInner>
        </HeaderSection>

        <Wrap>
          <ListWrap>
            <FormWrap>
              <FormList>
                <FormTitleWrap>
                  <ListTitle>신고 제목</ListTitle>
                </FormTitleWrap>
                <Input
                  type="text"
                  value={title}
                  active={title.length > 0}
                  placeholder="신고 제목을 입력해주세요."
                  onChange={(e) => {
                    if (e.target.value.length > 40)
                      return alert(`제목은 40자 이내로 작성해주세요.`);
                    setTitle(e.target.value);
                  }}
                />
              </FormList>
              <FormList>
                <FormTitleWrap>
                  <ListTitle>신고 내용</ListTitle>
                  <PC>
                    <TextLength>({text.length}/500)</TextLength>
                  </PC>
                </FormTitleWrap>
                <TextWrap>
                  <TextArea
                    value={text}
                    active={text.length > 0}
                    placeholder="신고 내용을 입력해주세요."
                    onChange={(e) => {
                      if (e.target.value.length > 500) return;
                      setText(e.target.value);
                    }}
                  />
                  <Mobile>
                    <TextLength>({text.length}/500)</TextLength>
                  </Mobile>
                </TextWrap>
              </FormList>
            </FormWrap>
          </ListWrap>

          <SubmitBtnWrap>
            <Btn type="button" onClick={postReport}>
              신고하기
            </Btn>
          </SubmitBtnWrap>
        </Wrap>
      </Content>
    </Container>
  );
}

const Container = styled.div``;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 101;
`;
const Wrap = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 30px;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding-bottom: 0;
  }
`;

// header
const HeaderSection = styled.div`
  position: relative;
  width: 100%;
  height: 84px;
  overflow: hidden;
  z-index: 99;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
  }
`;
const HeaderInner = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 84px;
  background-color: #fff;
  padding: 0 25px;

  & p {
    color: #333;
    font-weight: 500;
    font-size: 20px;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
    padding: 0 calc(100vw * (25 / 1320));

    & p {
      font-size: calc(100vw * (20 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
    padding: 0;

    & p {
      font-size: calc(100vw * (16 / 428));
    }
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    right: calc(100vw * (13.5 / 428));
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;

// 콘텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 201;
  width: 520px;
  height: auto;
  background: #fff;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (520 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: 0;
    left: 0;
    transform: translate(0);
    width: 100%;
    height: 100vh;
    overflow: auto;
  }
`;

// inquiry
const ListWrap = styled.div``;

//info
const ListInner = styled.div`
  width: 100%;
  border-bottom: 8px solid #f9f9f9;

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 460px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (460 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;

const ImgBox = styled.div`
  background-color: #fff;
  border: 1px solid #eee;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (70 / 428));
    height: calc(100vw * (70 / 428));
  }
`;
const TextBox = styled.div`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (298 / 428));
  }
`;
const Brand = styled.p`
  color: #333;
  font-weight: 400;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (6 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

// from
const FormWrap = styled.div`
  width: 460px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (460 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    padding-top: calc(100vw * (20 / 428));
  }
`;
const FormList = styled.div`
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (30 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 428));
    }
  }
`;

// select
const SelectButton = styled.select`
  display: block;
  width: 100%;
  color: #acacac;
  font-weight: 400;
  background-color: #fff;
  border: 1px solid #ddd;

  -webkit-appearance: none; /* 네이티브 외형 감추기*/
  -moz-appearance: none;
  appearance: none;
  font-weight: normal;
  letter-spacing: normal;
  cursor: pointer;

  ${({ borderActive }) =>
    borderActive &&
    `border: 1px solid #000;
  color: #333;
  `}
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 428)) center /
      calc(100vw * (24 / 428));
  }
`;
// input
const Input = styled.input`
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;
// textarea
const TextWrap = styled.div`
  position: relative;
`;
const TextArea = styled.textarea`
  width: 100%;
  height: 314px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  padding: 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (314 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (200 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (15 / 428)) calc(100vw * (20 / 428));
  }
`;
const TextLength = styled.span`
  color: #333;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    position: absolute;
    right: calc(100vw * (10 / 428));
    bottom: calc(100vw * (10 / 428));
    font-size: calc(100vw * (14 / 428));
  }
`;

// button
const SubmitBtnWrap = styled.div`
  width: 460px;
  height: 50px;
  flex-shrink: 0;
  margin: 20px auto 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (460 / 1320));
    height: calc(100vw * (50 / 1320));
    margin: calc(100vw * (20 / 1320)) auto 0;
  }
  @media screen and (max-width: 930px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(100vw * (80 / 428));
    margin: 0;
  }
`;
const Btn = styled.button`
  width: 100%;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;
  cursor: pointer;
  flex-shrink: 0;
  z-index: 101;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    position: fixed;
    left: 0;
    bottom: 0;
    height: calc(100vw * (80 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;

// check
const SecretChkWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ChkBox = styled.div`
  display: flex;
  align-items: center;
`;
const ChkInput = styled.input`
  display: none;

  &:checked + label .chk-img {
    background: url(${IconChkOn}) no-repeat center / contain;
  }
`;
const ChkLabel = styled.label`
  display: flex;
  align-items: center;
`;
const ChkImg = styled.span`
  display: block;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
    background: url(${IconChkOff}) no-repeat center / contain;
  }
`;
const ChkText = styled.span`
  color: #333;
  font-weight: 400;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (10 / 428));
  }
`;
const SecretChkText = styled.span`
  color: #ff006c;
  font-weight: 400;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
// image upload
const ImageWrap = styled.div`
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const FormTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;

  @media screen and (max-width: 1320px) {
    margin: 0 0 calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto calc(100vw * (8 / 428));
  }
`;
const ListTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const ListSubTitle = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;

const ImageInner = styled.ul`
  display: flex;

  white-space: nowrap;
  overflow-y: visible;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  @media screen and (max-width: 930px) {
    padding-left: calc(100vw * (25 / 428));
  }
`;
const ImageList = styled.li`
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      padding-right: calc(100vw * (10 / 428));
    }
  }
`;
const ImageBox = styled.div``;

const UploadImageBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #eee;
  cursor: pointer;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (140 / 428));
    height: calc(100vw * (140 / 428));
  }
`;
const UploadImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const UploadDeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background: url(${IconFileUploadCancel}) no-repeat center / contain;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (28 / 428));
    height: calc(100vw * (28 / 428));
  }
`;

// file upload
const FileReviseBtnWrap = styled.div``;
const FileReviseBtn = styled.input`
  display: none;
`;
const FileBtnLabel = styled.label`
  display: block;
  background: url(${IconFileUpload}) no-repeat center / contain;
  cursor: pointer;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (140 / 428));
    height: calc(100vw * (140 / 428));
  }
`;
