import React from 'react';

import { Wrapper } from '../components/Global.elements';
import Header from '../components/common/header/Header';
import Footer from '../components/common/footer/Footer';
import CategoryContents from '../components/category/CategoryContents';

const CategoryPage = () => {
    return (
        <Wrapper>
            <Header />
            <CategoryContents />
            <Footer />
        </Wrapper>
    )
}

export default CategoryPage
