import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";

// controller
import serverController from "../../server/serverController";

//img
import IconSelectArrow from "../../img/icon/icon_bottom_arrow.svg";
import DefaultImg from "../../img/icon/loading_default_img.svg";
import { imgURL } from "../../server/appInfoController";
import Commas from "../../server/Commas";
import { InfoText } from "../login/auth.elements";

const optionList = [
  { title: "취소 사유를 선택해 주세요." },
  { title: "상품이 필요하지 않음" },
  { title: "다른 상품을 구매함" },
  { title: "타 쇼핑몰에서 구매함" },
  { title: "배송이 너무 느림" },
  { title: "상품 배송 지연 됨" },
  { title: "기타" },
];

export default function OrderCancel({ setIsOrderCancelModal, item }) {
  console.log(item, "item");
  //페이지 이동
  const history = useHistory();

  const [inquiryText, setInquiryText] = useState("");
  const [selectedValue, setSelectedValue] = useState("취소 사유를 선택해 주세요.");

  const postCancel = () => {
    if (selectedValue === "취소 사유를 선택해 주세요.") {
      return alert("취소 사유를 선택해 주세요.");
    }
    const formData = new FormData();
    formData.append(
      "cancelReason",
      inquiryText.length > 0 ? selectedValue + "," + inquiryText : selectedValue
    ); // 환불 사유
    formData.append("mog_id", item.mog_id); // 부분 취소용
    // formData.append("orderId", query.orderId); // 전체 취소용
    serverController.connectFetchController(`payment/request-cancel`, "POST", formData, (res) => {
      console.log(res, "res");
      if (res.result == 1) {
        alert("취소 요청이 완료되었습니다.");
        setIsOrderCancelModal(false);
      } else {
        alert("[" + res.message + "] 관리자에게 문의바랍니다.");
      }
    });
  };
  return (
    <Container>
      <Wrap>
        <Mobile>
          <Section>
            <SectionInner>
              <ProductInfoWrap>
                <ImgBox>
                  <img
                    src={item.prd_thumbnail ? `${imgURL}${item.prd_thumbnail}` : DefaultImg}
                    alt="product-thumbnail"
                  />
                </ImgBox>
                <TextBox>
                  <TextBoxInner>
                    <ProductName>{item.prd_name || "PRODUCT"}</ProductName>
                    {item?.optionInfo?.length > 0 &&
                      item?.optionInfo?.map((optionItem, idx) => {
                        return (
                          <ProductColor key={`${optionItem.optName}-${idx}`}>
                            {`${optionItem.optName} / ${item.orderCount}개`}
                          </ProductColor>
                        );
                      })}
                    <Price>{Commas.numberWithCommas(item?.prd_list_price)}원</Price>
                  </TextBoxInner>
                </TextBox>
              </ProductInfoWrap>
            </SectionInner>
          </Section>

          <Section>
            <SectionInner>
              <FormList>
                <FormTitleWrap>
                  <ListTitle>취소사유</ListTitle>
                </FormTitleWrap>
                <SelectButton
                  defaultValue={"취소 사유를 선택해 주세요."}
                  borderActive={selectedValue !== "취소 사유를 선택해 주세요."}
                  onChange={(e) => {
                    setSelectedValue(e.target.value);
                  }}
                >
                  {optionList.map((item) => {
                    return <option key={item.value}>{item.title}</option>;
                  })}
                </SelectButton>
              </FormList>
              <FormList>
                <FormTitleWrap>
                  <ListTitle>상세사유(선택)</ListTitle>
                </FormTitleWrap>
                <InquiryTextWrap>
                  <InquiryTextArea
                    value={inquiryText}
                    active={inquiryText.length > 0}
                    placeholder="상세 사유를 입력해 주세요."
                    onChange={(e) => {
                      if (e.target.value.length > 500) return;
                      setInquiryText(e.target.value);
                    }}
                  />
                </InquiryTextWrap>
              </FormList>
            </SectionInner>
          </Section>

          {/* 취소 금액 */}
          <Section>
            <SectionInner>
              <OrderTitle>취소 금액 확인</OrderTitle>
              <OrderInfoListInner>
                <OrderInfoListBox>
                  <OrderInfoListBoxInner>
                    <OrderInfoList>
                      <OrderSubTitle>상품 금액</OrderSubTitle>
                      <OrderText>{Commas.numberWithCommas(item.prd_list_price)}원</OrderText>
                    </OrderInfoList>
                    <OrderInfoList>
                      <OrderSubTitle>배송비</OrderSubTitle>
                      <OrderText>0원</OrderText>
                    </OrderInfoList>
                    {item.txn_used_point !== 0 && (
                      <OrderInfoList>
                        <OrderSubTitle>포인트 사용</OrderSubTitle>
                        <OrderText>{Commas.numberWithCommas(item.txn_used_point)}원</OrderText>
                      </OrderInfoList>
                    )}
                    {item.coupon_discount !== 0 && (
                      <OrderInfoList>
                        <OrderSubTitle>쿠폰 할인</OrderSubTitle>
                        <OrderText>{Commas.numberWithCommas(item.coupon_discount)}원</OrderText>
                      </OrderInfoList>
                    )}
                  </OrderInfoListBoxInner>
                </OrderInfoListBox>
                <OrderInfoListBox>
                  <OrderInfoListBoxInner>
                    <OrderInfoList>
                      <OrderSubTitleBold>취소 예상 금액</OrderSubTitleBold>
                      <OrderTextBold>{Commas.numberWithCommas(item.mog_dis_price)}원</OrderTextBold>
                    </OrderInfoList>
                    <OrderInfoList>
                      <OrderSubTitle>결제방법</OrderSubTitle>
                      <OrderText>{item?.mog_payment_method || "확인바람"}</OrderText>
                    </OrderInfoList>
                  </OrderInfoListBoxInner>
                </OrderInfoListBox>
              </OrderInfoListInner>
            </SectionInner>
            <BtnWrap onClick={postCancel}>
              <Btn type="button">확인</Btn>
            </BtnWrap>
          </Section>
        </Mobile>

        <PC>
          <SectionLeft>
            {/* product info */}
            <Section>
              <SectionInner>
                <ProductInfoWrap>
                  <ImgBox
                    onClick={() => {
                      history.push(`/store/detail/${item.prd_id}`);
                    }}
                  >
                    <img
                      src={item.prd_thumbnail ? `${imgURL}${item.prd_thumbnail}` : DefaultImg}
                      alt="product-thumbnail"
                    />
                  </ImgBox>
                  <TextBox>
                    <TextBoxInner>
                      <BrandName>{item.bc_title || "(BRAND)"}</BrandName>
                      <ProductName
                        onClick={() => {
                          history.push(`/store/detail/${item.prd_id}`);
                        }}
                      >
                        {item.prd_name || "PRODUCT"}
                      </ProductName>
                      {item?.optionInfo?.length > 0 &&
                        item?.optionInfo?.map((optionItem, idx) => {
                          return (
                            <ProductColor key={`${optionItem.optName}-${idx}`}>
                              {`${optionItem.optName} / ${item.orderCount}개`}
                            </ProductColor>
                          );
                        })}
                      <Price>{Commas.numberWithCommas(item?.prd_list_price)}원</Price>
                    </TextBoxInner>
                  </TextBox>
                </ProductInfoWrap>
              </SectionInner>
            </Section>

            {/* 취소 금액 */}
            <OrderSection>
              <OrderSectionInner>
                <OrderTitle>취소 금액 확인</OrderTitle>
                <OrderInfoListInner>
                  <OrderInfoListBox>
                    <OrderInfoListBoxInner>
                      <OrderInfoList>
                        <OrderSubTitle>상품 금액</OrderSubTitle>
                        <OrderText> {Commas.numberWithCommas(item.prd_list_price)}원</OrderText>
                      </OrderInfoList>
                      <OrderInfoList>
                        <OrderSubTitle>배송비</OrderSubTitle>
                        <OrderText>0원</OrderText>
                      </OrderInfoList>
                      {item.txn_used_point !== 0 && (
                        <OrderInfoList>
                          <OrderSubTitle>포인트 사용</OrderSubTitle>
                          <OrderText>{Commas.numberWithCommas(item.txn_used_point)}원</OrderText>
                        </OrderInfoList>
                      )}
                      {item.coupon_discount !== 0 && (
                        <OrderInfoList>
                          <OrderSubTitle>쿠폰 할인</OrderSubTitle>
                          <OrderText>{Commas.numberWithCommas(item.coupon_discount)}원</OrderText>
                        </OrderInfoList>
                      )}
                    </OrderInfoListBoxInner>
                  </OrderInfoListBox>
                  <OrderInfoListBox>
                    <OrderInfoListBoxInner>
                      <OrderInfoList>
                        <OrderSubTitleBold>취소 예상 금액</OrderSubTitleBold>
                        <OrderTextBold>
                          {" "}
                          {Commas.numberWithCommas(item.mog_dis_price)}원
                        </OrderTextBold>
                      </OrderInfoList>
                      <OrderInfoList>
                        <OrderSubTitle>결제방법</OrderSubTitle>
                        <OrderText> {item?.mog_payment_method || "확인바람"}</OrderText>
                      </OrderInfoList>
                    </OrderInfoListBoxInner>
                  </OrderInfoListBox>
                </OrderInfoListInner>
              </OrderSectionInner>
              <BtnWrap onClick={postCancel}>
                <Btn type="button">확인</Btn>
              </BtnWrap>
            </OrderSection>
          </SectionLeft>

          <SectionRight>
            {/* form */}
            <Section>
              <SectionInner>
                <FormList>
                  <FormTitleWrap>
                    <ListTitle>취소사유</ListTitle>
                  </FormTitleWrap>
                  <SelectButton
                    defaultValue={"취소 사유를 선택해 주세요."}
                    borderActive={selectedValue !== "취소 사유를 선택해 주세요."}
                    onChange={(e) => {
                      setSelectedValue(e.target.value);
                    }}
                  >
                    {optionList.map((item) => {
                      return <option key={item.value}>{item.title}</option>;
                    })}
                  </SelectButton>
                </FormList>
                <FormList>
                  <FormTitleWrap>
                    <ListTitle>상세사유(선택)</ListTitle>
                  </FormTitleWrap>
                  <InquiryTextWrap>
                    <InquiryTextArea
                      value={inquiryText}
                      active={inquiryText.length > 0}
                      placeholder="상세 사유를 입력해 주세요."
                      onChange={(e) => {
                        if (e.target.value.length > 500) return;
                        setInquiryText(e.target.value);
                      }}
                    />
                  </InquiryTextWrap>
                </FormList>
              </SectionInner>
            </Section>
            <InfoTextBox>
              <InfoText>
                * 사용하신 쿠폰/포인트는 구매하신 상품 모두 반품하실 때 반환됩니다. 자세한 사항은
                고객센터로 문의주세요.
              </InfoText>
            </InfoTextBox>
          </SectionRight>
        </PC>
        <Mobile>
          <InfoTextBox>
            <InfoText>
              * 사용하신 쿠폰/포인트는 구매하신 상품 모두 반품하실 때 반환됩니다. 자세한 사항은
              고객센터로 문의주세요.
            </InfoText>
          </InfoTextBox>
        </Mobile>
      </Wrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 30px;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (30 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: 0 0 calc(100vw * (80 / 428));
  }
`;
const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1190px;
  height: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1190 / 1320));
  }
  @media screen and (max-width: 930px) {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const SectionLeft = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 428px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (428 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const SectionRight = styled.div`
  width: 742px;
  height: 100%;
  overflow: auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (742 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    height: auto;
  }
`;

const Section = styled.section`
  &:not(:last-child) {
    border-bottom: 10px solid #f9f9f9;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const OrderSection = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media screen and (max-width: 930px) {
    display: block;
    height: auto;
  }
`;

const SectionInner = styled.div`
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const OrderSectionInner = styled(SectionInner)`
  width: 100%;
  height: 100%;
  padding: 30px 10px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: auto;
    padding: 0;
  }
`;

// product info
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px 20px;

  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (10 / 1320)) calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;

const ImgBox = styled.div`
  width: 70px;
  height: 70px;
  background-color: #fff;
  border: 1px solid #eee;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (70 / 1320));
    height: calc(100vw * (70 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    height: calc(100vw * (100 / 428));
  }
`;
const TextBox = styled.div`
  width: calc(100% - 80px);
  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (80 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (266 / 428));
  }
`;
const TextBoxInner = styled.div``;
const BrandName = styled.p`
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProductColor = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (4 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (4 / 428));
  }
`;
const Price = styled.p`
  color: #333;
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;

// form
const FormList = styled.div`
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (30 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (20 / 428));
    }
  }
`;

const FormTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;

  @media screen and (max-width: 1320px) {
    margin: 0 0 calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto calc(100vw * (8 / 428));
  }
`;
const ListTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const ListSubTitle = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;

// select
const SelectButton = styled.select`
  display: block;
  width: 100%;
  height: 50px;
  color: #acacac;
  font-weight: 400;
  font-size: 15px;
  background: #ffffff url(${IconSelectArrow}) no-repeat right 15px center / 24px;
  border: 1px solid #ddd;
  padding: 0 15px;
  border-radius: 0;

  -webkit-appearance: none; /* 네이티브 외형 감추기*/
  -moz-appearance: none;
  appearance: none;
  font-weight: normal;
  letter-spacing: normal;
  cursor: pointer;

  ${({ borderActive }) =>
    borderActive &&
    `border: 1px solid #000;
    color: #333;
  `}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
    font-size: calc(100vw * (15 / 1320));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 1320)) center /
      calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
    background: #ffffff url(${IconSelectArrow}) no-repeat right calc(100vw * (15 / 428)) center /
      calc(100vw * (24 / 428));
  }
`;
// input
const Input = styled.input`
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (15 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;
// textarea
const InquiryTextWrap = styled.div``;
const InquiryTextArea = styled.textarea`
  width: 100%;
  height: 390px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  padding: 15px;

  &:read-only {
    background-color: #f9f9f9;
    border: 1px solid #000;
  }
  &::placeholder {
    color: #acacac;
  }

  ${({ active }) => active && `border: 1px solid #000;`}

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (390 / 1320));
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (200 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (15 / 428)) calc(100vw * (20 / 428));
  }
`;

// 취소 정보
const OrderTitle = styled.p`
  color: #333;
  font-weight: 500;
  font-size: 16px;
  border-bottom: 2px solid #000;
  padding-bottom: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding-bottom: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    padding-bottom: calc(100vw * (15 / 428));
  }
`;
const OrderInfoListInner = styled.div`
  margin-top: 20px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (20 / 428));
  }
`;
const OrderInfoListBox = styled.div`
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eee;
    margin: 20px 0;
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 1320)) 0;
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 428)) 0;
    }
  }
`;
const OrderInfoListBoxInner = styled.ul`
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000;
    margin: 20px 0;
  }
  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 1320)) 0;
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 428)) 0;
    }
  }
`;
const OrderInfoList = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (12 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (12 / 428));
    }
  }
`;
const OrderSubTitle = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const OrderText = styled(OrderSubTitle)`
  color: #333;
`;
const OrderSubTitleBold = styled(OrderSubTitle)`
  color: #333;
  font-weight: 500;
`;
const OrderTextBold = styled(OrderText)`
  font-weight: 600;
`;

// button
const BtnWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  flex-shrink: 0;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (50 / 428)) auto 0;
  }
`;
const Btn = styled.button`
  width: 100%;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;

// info text
const InfoTextBox = styled.div`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (15 / 428)) auto 0;
  }
`;
