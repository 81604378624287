import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import serverController from "../../../server/serverController";
import { useSelector } from "react-redux";
import { UserDataAction } from "../../../store/actionCreators";
import { SignUpAction } from "../../../store/actionCreators";

import { Layout } from "../auth.elements";
import {
  StepContainer,
  StepContents,
  StepHeadings,
  StepForm,
  StepMessage,
  StepButtonBox,
  StepNextBox,
  StepColors,
  StepColor,
  Colors,
  Color,
  SignupButton,
} from "./signup.element";

const selectColors = [
  {
    colors: ["#E7E3DC", "#DFD2C6", "#CAB19A"],
    title: "톤온톤",
  },
  {
    colors: ["#E27513", "#43770C", "#706C5B"],
    title: "톤앤톤",
  },
  {
    colors: ["#FFD4D9", "#FFF9C5", "#CAE5FF"],
    title: "파스텔",
  },
  {
    colors: ["#9400EF", "#F5DB35", "#CF0070"],
    title: "비비드",
  },
  {
    colors: ["#F9FF0B", "#FF00A4", "#4DFF6F"],
    title: "네온",
  },
  {
    colors: ["#fff", "#000"],
    title: "모노",
  },
];

const StepColorBox = ({ value, setStyles, i }) => {
  const [isClick, setIsClick] = useState(false);
  const colorClick = () => {
    setIsClick((prev) => !prev);
  };

  useEffect(() => {
    setStyles((prev) => {
      const newArr = [...prev];
      if (isClick) {
        return newArr.concat(value?.title);
      } else {
        return newArr.filter((v) => v !== value?.title);
      }
    });
  }, [isClick]);

  return (
    <StepColor onClick={colorClick} id="test">
      <Colors selected={isClick}>
        {value?.colors.map((color, j) => (
          <Color key={j} color={color}></Color>
        ))}
      </Colors>
      <p>{value?.title}</p>
    </StepColor>
  );
};

const SignUpStepFour = ({ step, url }) => {
  const data = useSelector((state) => state.signupData);
  const history = useHistory();
  const [styles, setStyles] = useState([]);

  const returnFormData = () => {
    const formData = new FormData();
    formData.append("mem_email", data.mem_email);
    formData.append("mem_password", data.mem_password);
    formData.append("mem_phone", data.mem_phone);
    formData.append("mem_name", data.mem_name);
    formData.append("mem_gender", data.mem_gender);
    formData.append("mem_style", data.mem_style);
    formData.append("mem_fit", data.mem_fit);
    formData.append("mem_color", styles);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    return formData;
  };

  const editFormData = () => {
    const formData = new FormData();
    formData.append("mem_gender", data.mem_gender);
    formData.append("mem_style", data.mem_style);
    formData.append("mem_fit", data.mem_fit);
    formData.append("mem_color", styles);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    return formData;
  };

  const stepUp = async () => {
    if (url == "signup") {
      await serverController.connectFetchController(
        `join`,
        "POST",
        returnFormData(),
        (res) => {
          console.log(res, "맞춤분석 response");
          return history.push(`/signup/success`);
        },
        (err) => console.error(err)
      );
    }

    if (url == "step") {
      await serverController.connectFetchController(
        `user/style`,
        "PUT",
        editFormData(),
        (res) => {
          console.log(res, "맞춤분석 업데이트 response");
          return history.push(`/step/success`);
        },
        (err) => console.error(err)
      );
    }
  };

  const prevStep = () => history.push(`/signup/normal/3`);
  const nextStep = (skip) => {
    if (skip) {
      SignUpAction.sendPersonalNormalInfo({ mem_color: [] }); // 0 남자 1 여자
      stepUp();
    } else if (styles?.length > 0) {
      SignUpAction.sendPersonalNormalInfo({ mem_color: styles }); // 0 남자 1 여자
      stepUp();
    } else {
      alert("선호하시는 색상을 골라주세요.");
    }
  };

  console.log(styles, "styles");
  console.log(data, "data");

  return (
    <StepContainer>
      <Layout>
        <StepContents>
          <StepHeadings>
            <h2>Personal infomation</h2>
            <p>
              <em>0{step} / </em>
              <span>04</span>
            </p>
          </StepHeadings>

          <StepForm>
            <StepMessage>
              선호하는 색상을 선택해 주세요. <em>(중복 선택 가능)</em>
            </StepMessage>

            <StepColors>
              {selectColors.map((value, i) => {
                return <StepColorBox value={value} setStyles={setStyles} index={i} key={i} />;
              })}
            </StepColors>
          </StepForm>

          <StepNextBox>
            <StepButtonBox>
              <SignupButton prev onClick={prevStep}>
                이전으로
              </SignupButton>
              <SignupButton onClick={() => nextStep()}>다음으로</SignupButton>
            </StepButtonBox>

            {/* <p onClick={() => nextStep(true)}>스킵하기</p> */}
          </StepNextBox>
        </StepContents>
      </Layout>
    </StepContainer>
  );
};

export default SignUpStepFour;
