import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Container,
    Layout
} from '../auth.elements';
import SignUpStepOne from './SignUpStepOne';
import SignUpStepTwo from './SignUpStepTwo';
import SignUpStepThree from './SignUpStepThree';
import SignUpStepFour from './SignUpStepFour';

const SignUpSteps = () => {
    const params = useParams();

    const renderSteps = (step) => {
        switch (step) {
            case '1':
                return <SignUpStepOne step={step} url={params.url} />
            case '2':
                return <SignUpStepTwo step={step} url={params.url} />
            case '3':
                return <SignUpStepThree step={step} url={params.url} />
            case '4':
                return <SignUpStepFour step={step} url={params.url} />
            default:
                return;
        }
    }

    return (
        <Container>
            <Layout>
                {
                    renderSteps(params.step)
                }
            </Layout>
        </Container>
    )
}

export default SignUpSteps
