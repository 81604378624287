import React from 'react';

//css
import styled, { css } from 'styled-components';

export default function ProductListOrderEl({isActive}){
    return(
        <ListWrap isActive={isActive}>
            <Row>
                <Col>
                    <ListHead isActive={isActive}>1</ListHead>
                    <Col>
                        <Text>노드비메이드 트렌치 코트</Text>
                        <Text>NBM1234WF(상품코드)</Text>
                        <Text>21.05.09(등록일)</Text>
                        <Text>협찬</Text>
                    </Col>
                </Col>
            </Row>
        </ListWrap>
    )
}
const marginSet = (props)=> { return(`margin-top: ${props.mt || 0};
margin-right: ${props.mr || 0};
margin-bottom: ${props.mb || 0};
margin-left: ${props.ml || 0};`) };

const ListWrap = styled.div`
    width:100%; color:#222222; box-sizing:border-box; border:2px solid #D9D9D9; margin-top:8px; &:first-child { margin-top:0; } padding-right:calc(100vw * (52 / 1920));

    ${({isActive}) => {
         return isActive &&
         `
         border:2px solid #FDF151
         `
     }}
`;

const Row = styled.div`
    position:relative;
`;

const Col = styled.div`
    ${props => marginSet(props)}; height:auto; width:100%; position:relative;
    display:flex; justify-content:space-between; align-items: center; 
`;


const ListHead = styled.div`
    font-size:16px; line-height:22px;
    margin-right:calc(100vw * (48 / 1920));
    font-size:18px;
    color:#ffffff;
    border-right:2px solid #D9D9D9;
    padding:18px 26px;
    background:#1F1F1F;
    font-weight:500;

    ${({isActive}) => {
         return isActive &&
         `
            border-right:2px solid #FDF151;
            background:#FDF151;
         `
     }}

`

const Text = styled.p`
    font-size:16px; line-height:22px;
`;