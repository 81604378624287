import React from 'react';
import Header from '../components/common/header/Header';
import FashionNewsDetails from '../components/news/FashionNewsDetails';
import Footer from '../components/common/footer/Footer';

const FashionNewsDetailsPage = () => {
    return (
        <div>
            <Header />
            <FashionNewsDetails />
            <Footer />
        </div>
    )
}

export default FashionNewsDetailsPage;