import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import womanBody1 from "../../../../images/signup/woman-body-1.png";
import womanBody2 from "../../../../images/signup/woman-body-2.png";
import womanBody3 from "../../../../images/signup/woman-body-3.png";
import womanBody4 from "../../../../images/signup/woman-body-4.png";
import womanBody5 from "../../../../images/signup/woman-body-5.png";
import womanBodyHover1 from "../../../../images/signup/woman-body-hover1.png";
import womanBodyHover2 from "../../../../images/signup/woman-body-hover2.png";
import womanBodyHover3 from "../../../../images/signup/woman-body-hover3.png";
import womanBodyHover4 from "../../../../images/signup/woman-body-hover4.png";
import womanBodyHover5 from "../../../../images/signup/woman-body-hover5.png";
import manBody1 from "../../../../images/signup/man-body-1.png";
import manBody2 from "../../../../images/signup/man-body-2.png";
import manBody3 from "../../../../images/signup/man-body-3.png";
import manBody4 from "../../../../images/signup/man-body-4.png";
import manBody5 from "../../../../images/signup/man-body-5.png";
import manBodyHover1 from "../../../../images/signup/man-body-hover1.png";
import manBodyHover2 from "../../../../images/signup/man-body-hover2.png";
import manBodyHover3 from "../../../../images/signup/man-body-hover3.png";
import manBodyHover4 from "../../../../images/signup/man-body-hover4.png";
import manBodyHover5 from "../../../../images/signup/man-body-hover5.png";
import { Mobile } from "../../../../components/common/mediaStlye";
import { PC } from "../../../MediaQuery";

const typeBodys = ["모래시계형", "타원형", "역삼각형", "직사각형", "삼각형"];
const manBodys = [manBody1, manBody2, manBody3, manBody4, manBody5];
const manBodysHover = [manBodyHover1, manBodyHover2, manBodyHover3, manBodyHover4, manBodyHover5];
const womanBodys = [womanBody1, womanBody2, womanBody3, womanBody4, womanBody5];
const womanBodysHover = [
  womanBodyHover1,
  womanBodyHover2,
  womanBodyHover3,
  womanBodyHover4,
  womanBodyHover5,
];

const BodyTypeBoxComp = ({ setClickBodyTypeArr, bodyItem }) => {
  const [isClick, setIsClick] = useState(false);

  useEffect(() => {
    setClickBodyTypeArr((prev) => {
      const newArr = [...prev];
      if (isClick) {
        return newArr.concat(bodyItem?.type);
      } else {
        return newArr.filter((v) => v !== bodyItem?.type);
      }
    });
  }, [isClick]);

  return (
    <BodyTypeImageBox onClick={() => setIsClick(!isClick)}>
      <img src={isClick ? bodyItem.bodyHover : bodyItem.body} alt={bodyItem.type} />
    </BodyTypeImageBox>
  );
};

export default function StepThree(props) {
  const { clickBodyTypeArr, setClickBodyTypeArr, clickGenderBox } = props;
  const history = useHistory();
  const [list, setList] = useState([]);

  const loadBodys = (mem_gender) => {
    if (mem_gender === 0) {
      const newArr = [];
      manBodys.forEach((body, index) => {
        const newObj = {};
        newObj.type = typeBodys[index];
        newObj.body = body;
        newObj.bodyHover = manBodysHover[index];
        newArr.push(newObj);
      });
      setList(newArr);
    } else if (mem_gender === 1) {
      const newArr = [];
      womanBodys.forEach((body, index) => {
        const newObj = {};
        newObj.type = typeBodys[index];
        newObj.body = body;
        newObj.bodyHover = womanBodysHover[index];
        newArr.push(newObj);
      });
      setList(newArr);
    }
  };

  const onClickNextStep = () => {
    if (clickBodyTypeArr.length === 0) return;
    history.push(`/store/mypage/personal/4`);
  };

  useEffect(() => {
    loadBodys(clickGenderBox);
  }, [clickGenderBox]);

  useEffect(() => {
    console.log(list, "list");
  }, [list]);

  useEffect(() => {
    setClickBodyTypeArr([]);
  }, []);

  return (
    <Container>
      <BodyTypeTextWrap>
        체형을 선택해주세요. <em>(중복 선택 가능)</em>
      </BodyTypeTextWrap>
      <BodyTypeImageWrap>
        <Mobile>
          <BodyTypeInner>
            {list.map((bodyItem, idx) => {
              return (
                <BodyTypeBoxComp
                  key={`${bodyItem.body}-bodyItem-${idx}`}
                  setClickBodyTypeArr={setClickBodyTypeArr}
                  bodyItem={bodyItem}
                  index={idx}
                />
              );
            })}
          </BodyTypeInner>
        </Mobile>
        <PC>
          {list.map((bodyItem, idx) => {
            return (
              <BodyTypeBoxComp
                key={`${bodyItem.body}-bodyItem-${idx}`}
                setClickBodyTypeArr={setClickBodyTypeArr}
                bodyItem={bodyItem}
                index={idx}
              />
            );
          })}
        </PC>
      </BodyTypeImageWrap>
      <StepNextButtonWrap>
        <StepPrevButton onClick={() => history.push(`/store/mypage/personal/2`)}>
          이전으로
        </StepPrevButton>
        <StepNextButton isActive={clickBodyTypeArr.length > 0} onClick={onClickNextStep}>
          다음으로
        </StepNextButton>
      </StepNextButtonWrap>
    </Container>
  );
}

const Container = styled.div``;
const BodyTypeTextWrap = styled.p`
  text-align: center;
  font-weight: 900;
  font-size: 16px;
  margin-bottom: 40px;
  > em {
    color: #d9d9d9;
  }
  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-bottom: calc(100vw * (40 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (40 / 428));
  }
`;
const BodyTypeImageWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  overflow-x: scroll;
  row-gap: 8px;

  @media screen and (max-width: 1320) {
    row-gap: calc(100vw * (8 / 1320));
  }
  @media screen and (max-width: 930px) {
    row-gap: calc(100vw * (8 / 428));
  }
`;
const StepNextButtonWrap = styled.div`
  text-align: center;
  display: flex;
  margin-top: 100px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (100 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (100 / 428));
  }
`;
const StepNextButton = styled.button`
  margin: 0 auto;
  background-color: #999;
  color: #fff;
  font-size: 16px;
  height: 59px;
  width: 300px;
  ${({ isActive }) => isActive && `background-color : #000;`}
  @media screen and (max-width: 1320) {
    font-size: calc(100vw * (16 / 1320));
    height: calc(100vw * (59 / 1320));
    width: calc(100vw * (300 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    height: calc(100vw * (59 / 428));
    width: calc(100vw * (185 / 428));
  }
`;
const StepPrevButton = styled(StepNextButton)`
  border: 1px solid #222;
  background-color: #fff;
  color: #222;
`;

const BodyTypeInner = styled.div`
  width: 770px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (770 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (770 / 428));
  }
`;
const BodyTypeImageBox = styled.div`
  position: relative;
  cursor: pointer;
`;
