import styled from "styled-components";
import IconClose from "../../store_src/img/icon/icon_popup_close.svg";

export default function MobileProductActionSheet({ listItem, onClickAction, onClickClose, targetQueryString }) {
  return (
    <S.MobileProductActionSheet>
      <ModalWrap>
        <ModalUl>
          {listItem.map((itemF, index) => {
            return (
              <ModalLi active={Number(targetQueryString) === index} key={itemF} onClick={() => onClickAction(index)}>
                <span>{itemF}</span>
              </ModalLi>
            );
          })}
        </ModalUl>
        <ModalCloseButton onClick={() => onClickClose()}>닫기</ModalCloseButton>
      </ModalWrap>
    </S.MobileProductActionSheet>
  );
}

const ModalCloseButton = styled.button`
  position: absolute;
  font-size: 0;
  background: url(${IconClose}) no-repeat center / cover;
  @media screen and (max-width: 930px) {
    top: calc(100vw * (20 / 428));
    right: calc(100vw * (25 / 428));
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;

const ModalUl = styled.ul`
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (54 / 428)) calc(100vw * (25 / 428));
  }
`;

const ModalLi = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ active }) =>
    active
      ? `
    & span {
      position: relative;
      z-index: 1;
      color: #333333;
    font-weight: 500;
    }
    &::after {
      content: "";
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-6deg);
      z-index: 0;
      display: block;
      background-color: #fdf251;
    }
    `
      : `
  &>span {
    
  }
  `}
  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));

    ${({ active }) =>
      active &&
      ` & span {
        position: relative;
        z-index: 1;
      }
      &::after {
        width: calc(100vw * (56 / 428));
        height: calc(100vw * (12 / 428));
      }`}
  }
`;

const ModalWrap = styled.div`
  position: fixed;
  z-index: 106;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
`;

const S = {
  MobileProductActionSheet: styled.div`
    & .close-button {
    }

    & .modal-bg {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
    }
  `,
};
