import React from "react";
import { PC, Mobile } from "../../components/common/mediaStlye";
import Header from "../../components/common/header/Header";
import SignUpNormal from "../../components/auth/signup/SignUpNormal";
import Footer from "../../components/common/footer/Footer";

const SignUpNormalPage = () => {
  return (
    <>
      <Header />
      <SignUpNormal />
      <Footer />
    </>
  );
};

export default SignUpNormalPage;
