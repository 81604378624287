import React, { useState, useCallback } from 'react';

import {  
    FieldBox,
    Label,    
    LabelName,
    Input,    
} from '../auth.elements';

const SignupNameInput = ({ setName, setIsCheck }) => {
    const [value, setValue] = useState(""); 

    const onHandleChange = (e) => {       
        const data = e.target.value;
        setValue(data)        
        setName(data)

        if (data) {
            setIsCheck(prev => ({...prev, isName: true }))
        } else {
            setIsCheck(prev => ({...prev, isName: false }))
        }
    }; 


    return (
        <>
        <FieldBox>
            <Label>
                <LabelName>이름</LabelName>
                <Input
                required
                value={value}
                onChange={onHandleChange}
                placeholder='이름을 입력해주세요.'
                />
            </Label> 
        </FieldBox>
        </>
    )
}

export default SignupNameInput
