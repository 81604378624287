import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";

import Header from "../../component/common/Header";
import MyPageHeader from "./MyPageHeader";
import MyRecommender from "../../component/mypage/info/MyRecommender";

import MyPageLayOutMenu from "../../component/mypage/MyPageLayOutMenu";
import MyPageLayOutName from "../../component/mypage/MyPageLayOutName";
import MyPageLayOutInfo from "../../component/mypage/MyPageLayOutInfo";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function MyRecommenderPage() {
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();

  const validLogin = () => {
    if (isLogged) return;
    alert(`잘못된 접근입니다.`);
    history.push(`/store`);
  };

  useEffect(() => {
    validLogin();
  }, []);
  return (
    <>
      <PC>
        <Header />
      </PC>

      <Mobile>
        <MyPageHeader title={"추천인 등록"} prevBtn />
      </Mobile>

      <MyPasswordArea>
        {/* page */}
        <PC>
          <MyPageLayOutName />
        </PC>

        <FlexLayOut>
          <PC>
            <MyPageLayOutMenu />
          </PC>

          {/* content */}
          <ContentSection>
            <PC>
              <MyPageLayOutInfo />
            </PC>

            <MyPasswordSection>
              <MyRecommender />
            </MyPasswordSection>
          </ContentSection>
        </FlexLayOut>
      </MyPasswordArea>
    </>
  );
}

const MyPasswordArea = styled.div`
  width: 100%;
  padding: 180px 0 200px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (200 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const MyPasswordSection = styled.div`
  width: 100%;
`;

// pc - layout
const FlexLayOut = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const ContentSection = styled.div`
  width: 1032px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1032 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
