//react
import { useHistory } from "react-router-dom";
import { PC, Mobile } from "../../../MediaQuery";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import styled from "styled-components";

//img
import IconCancel from "../../../img/icon/icon_myhaeder_cancle.svg";

// controller
import serverController from "../../../server/serverController";

import OrderCancel from "../../order/OrderCancel";
import MyPageBottom from "../../../page/mypage/MyPageBottom";

// 취소 정책
// mypage bottom data
const myPageBottomTextList = [
  <>
    - 상품 수령 후 7일 이내 접수 된 교환/반품 유효합니다.
    <br />
    - 수령 후 세탁, 향수 사용 등 상품의 가치가 하락한 경우에는 교환이나 반품이
    불가합니다.
    <br />
    - 받으신 택배사가 아닌 다른 택배사를 이용하여 반품하실 경우 추가 비용이 발생
    할 수 있습니다.
    <br />
    - 구매 하실 때 할인 받으셨던 쿠폰 할인액은 반환 됩니다.
    <br />
    - 제품의 택(tag)이 분실/훼손 된 경우 반품이 불가능 합니다.
    <br />
    - 사은품을 사용하셨거나 분실한 경우 반품이 불가능 합니다.
    <br />
    - 제품 박스를 분실하거나 훼손한 경우 반품이 거절 되거나 비용이 청구 될 수
    있습니다.
    <br />
    - 교환의 경우, 브랜드사의 기준에 따라 선수거 후 교환 출고가 진행될 수
    있습니다.
    <br />
    - 교환 택배비는 고객센터로 문의 주시면 상담원이 확인하여 안내 드리겠습니다.
    <br />- 교환 상품이 품절 될 경우 취소 안내를 받을 수 있습니다.
  </>,
];

export default function OrderCancelModal({
  setIsOrderCancelModal,
  clickOrderItem,
}) {
  return (
    <Container>
      <Background
        onClick={() => {
          setIsOrderCancelModal(false);
        }}
      />

      <Content>
        {/* header */}
        <HeaderSection>
          <HeaderInner>
            <p>주문취소</p>
            <CloseButton
              onClick={() => {
                setIsOrderCancelModal(false);
              }}
            >
              <img src={IconCancel} alt="cancel-icon" />
            </CloseButton>
          </HeaderInner>
        </HeaderSection>

        <Wrap>
          <OrderCancel
            // setInquiryText={setInquiryText}
            setIsOrderCancelModal={setIsOrderCancelModal}
            item={clickOrderItem}
          />
        </Wrap>
      </Content>
      <MyPageBottom textList={myPageBottomTextList} />
    </Container>
  );
}

const Container = styled.div``;

const Wrap = styled.div`
  width: 100%;
  height: calc(100% - 84px);

  @media screen and (max-width: 1320px) {
    height: calc(100% - calc(100vw * (84 / 1320)));
  }
  @media screen and (max-width: 930px) {
    height: auto;
  }
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;

// header
const HeaderSection = styled.div`
  position: relative;
  width: 100%;
  height: 84px;
  overflow: hidden;
  z-index: 99;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
  }
`;
const HeaderInner = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
  background-color: #fff;
  padding: 0 25px;

  & p {
    color: #333;
    font-weight: 500;
    font-size: 20px;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
    padding: 0 calc(100vw * (25 / 1320));

    & p {
      font-size: calc(100vw * (20 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    justify-content: center;
    height: calc(100vw * (55 / 428));
    padding: 0;

    & p {
      font-size: calc(100vw * (16 / 428));
    }
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    right: calc(100vw * (13.5 / 428));
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;

// 콘텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  width: 1240px;
  height: 650px;
  background: #fff;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    height: calc(100vw * (650 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: 0;
    left: 0;
    transform: translate(0);
    width: 100%;
    height: 100vh;
    overflow: auto;
  }
`;
