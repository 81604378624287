import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import serverController from "../../../server/serverController";
import { InfoText } from "../../page/main/MainPage";
import { Mobile, PC } from "../../MediaQuery";
import Loading from "../common/modal/Loading";
import PlanItemList from "./PlanItemList";
import { useInView } from 'react-intersection-observer';
import GrayZone from "../common/GrayZone";

export default function PlanListWrap({ eventCategory, tabIndex, setTabIndex, categoryArr }) {
  const body = document.querySelector("body");
  const allListViewRef = useRef();
  const allListViewRefM = useRef();
  const PlanWrapRef = useRef();
  const [loginPop, setLoginPop] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllLoading, setIsAllLoading] = useState(false);

  // 상품 담는 state
  const [PlanItemAll, setPlanItemAll] = useState([]);
  const [planDataAll, setPlanDataAll] = useState({});
  const [chkData, setChkData] = useState(null);
  const [scrollIndex, setScrollIndex] = useState(1);
  const [nextOffset, setNextOffset] = useState(0);
  const [response, setResponse] = useState({});

  const handleClick = (id, ref) => {
    ref.current.classList.remove("active");
    if (tabIndex !== id) {
      setTabIndex(id);
      setIsLoading(false);
    }
    PlanWrapRef.current.scrollIntoView();
  };

  const allViewClick = () => {
    if (tabIndex !== 0) {
      setTabIndex(0);
      getProductList();
      setIsAllLoading(false);
    }
    PlanWrapRef.current.scrollIntoView();
  };

  const getProductList = async (categoryId, offset) => {
    // 전체보기
    if (categoryId === 0) {
      let allView = await serverController.connectFetchController(
        `api/event/${eventCategory[0].eventCategoryId}/product?offset=0&limit=200`,
        "GET",
        null,
      );
      if (categoryId === 0 && allView.result === 1) {
        setScrollIndex(1);
        setPlanDataAll([allView]);
        setChkData(allView.result);
        setIsAllLoading(true);
      };
    };
    // 일반 카테고리
    if (categoryId > 0) {
      let categoryView = await serverController.connectFetchController(
        `api/event/${categoryId}/product?offset=${offset}&limit=20`,
        "GET",
        null,
      );
      if (categoryId > 0 && categoryView.result === 1) {
        if (categoryView?.result === 1) {
          setPlanItemAll(categoryView.eventProductList);
          setChkData(categoryView.result);
          setNextOffset(categoryView.nextOffset);
          setIsLoading(true);
        }
      }
    };

  };

  // 일반 카테고리 리스트 concat
  const concatProductList = (categoryId) => {
    serverController.connectFetchController(
      `api/event/${categoryId}/product?offset=${nextOffset}&limit=20`,
      "GET",
      null,
      (res) => {
        setResponse(res);
      }
    );
  };

  useEffect(() => {
    if (response?.result === 1 && response.nextOffset !== nextOffset) {
      setNextOffset(response.nextOffset);
      setPlanItemAll((prevContents) => {
        return prevContents.concat(response.eventProductList);
      });
    }
  }, [response]);

  // 전체 카테고리 리스트 concat
  const allViewConcatProductList = () => {
    serverController.connectFetchController(
      `api/event/${categoryArr[scrollIndex]}/product?offset=0&limit=200`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setPlanDataAll((prevContents) => {
            return prevContents.concat([res]);
          });
          setScrollIndex((prev) => {
            console.log(prev)
            return prev + 1;
          });
        }
      }
    );
  };

  useEffect(() => {
    body.style.overflow = "visible";
  }, []);


  useEffect(() => {
    getProductList(tabIndex, 0);
  }, [tabIndex]);

  // useEffect(() => {
  //   //무한스크롤
  //   if (inView) {
  //     tabIndex === 0 && allViewConcatProductList();
  //     tabIndex > 0 && concatProductList(tabIndex);
  //   }
  // }, [inView]);

  const allSentinelRef = useRef(null);
  useEffect(() => {
    console.log('sentine')
    const sentinel = allSentinelRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          tabIndex === 0 && allViewConcatProductList();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );
    if (sentinel) observer.observe(sentinel);
    return () => {
      if (sentinel) observer.unobserve(sentinel);
    };
  }, [allSentinelRef, isAllLoading, scrollIndex]);

  const sentinelRef = useRef(null);
  useEffect(() => {
    console.log('sentine')
    const sentinel = sentinelRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          tabIndex > 0 && concatProductList(tabIndex);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        // threshold: 1,
      }
    );
    if (sentinel) observer.observe(sentinel);
    return () => {
      if (sentinel) observer.unobserve(sentinel);
    };
  }, [sentinelRef, isLoading]);


  return (
    <Container
    >
      {chkData !== null &&
        <PlanNavWrap>
          <PC>
            <PlanNavUl>
              <PlanNavLi
                className={tabIndex === 0 ? 'active' : undefined}
                ref={allListViewRef}
                onClick={allViewClick}
              >
                전체보기
              </PlanNavLi>
              {eventCategory.map((el, i) => {
                return (
                  <PlanNavLi
                    className={el.eventCategoryId === tabIndex ? 'active' : undefined}
                    key={"event_nav_li" + el.eventCategoryId}
                    onClick={() => {
                      handleClick(el.eventCategoryId, allListViewRef);
                    }}
                  >
                    {el.eventCategoryTitle}
                  </PlanNavLi>
                )
              })}
            </PlanNavUl>
          </PC>
          <Mobile>
            <PlanNavUlM>
              <PlanNavLiM
                className={tabIndex === 0 ? 'active' : undefined}
                ref={allListViewRefM}
                onClick={allViewClick}
              >
                전체보기
              </PlanNavLiM>
              {eventCategory.map((el, i) => {
                return (
                  <PlanNavLiM
                    className={el.eventCategoryId === tabIndex ? 'active' : undefined}
                    key={"event_nav_li_m" + el.eventCategoryId}
                    onClick={() => { handleClick(el.eventCategoryId, allListViewRefM) }}
                  >
                    {el.eventCategoryTitle}
                  </PlanNavLiM>
                )
              })}
            </PlanNavUlM>
          </Mobile>
        </PlanNavWrap>
      }
      {chkData !== null &&
        <PlanItemsWrap>
          <PlanContentWrap ref={PlanWrapRef}>
            {
              /* 전체보기 레이아웃 */
              tabIndex === 0 &&
              <PlanListBox>
                {planDataAll?.map((el, i) => {
                  return (
                    <>
                      <TitleWrap key={"all-product-category-list" + i}>
                        <TitleText>{planDataAll[i].eventCategory?.eventCategoryTitle}</TitleText>
                      </TitleWrap>
                      {!isAllLoading ? (
                        <Loading />
                      ) : (
                        <>
                          <PlanInner>
                            {
                              planDataAll[i].eventProductList?.map((value, index) => {
                                return (
                                  <PlanItemList
                                    key={`${value.prdName}-${index}`}
                                    value={value}
                                    setLoginPop={setLoginPop}
                                    rank
                                  />
                                );
                              })
                            }
                          </PlanInner>
                          <GrayZone />
                        </>
                      )}
                    </>
                  )
                })}
                <div ref={allSentinelRef}></div>
              </PlanListBox>
            }
            {
              /* 전체보기 제외 레이아웃 */
              tabIndex > 0 &&
              <PlanListBox>
                {!isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <PlanInner className="test">
                      {
                        PlanItemAll?.map((value, index) => {
                          return (
                            <PlanItemList
                              key={`${value.prdName}-${index}`}
                              value={value}
                              setLoginPop={setLoginPop}
                              rank
                            />
                          );
                        })
                      }
                      <div ref={sentinelRef}></div>
                    </PlanInner>
                  </>
                )}
              </PlanListBox>
            }
          </PlanContentWrap>
        </PlanItemsWrap>
      }

    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`;

const PlanNavWrap = styled.div`
  position: sticky;
  width: 100%;
  top: 109px;
  z-index: 4;
  
  @media screen and (max-width: 1320px) {
    top: calc(100vw * (109 / 1320));
  };
  @media screen and (max-width: 930px) {
    top: calc(100vw * (55 / 428));
    }
`
const PlanNavUl = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
  background-color: #fff;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (20 / 1320));
  };

  @media screen and (max-width: 930px) {
    padding-top: 0;
  };
`
const PlanNavLi = styled.li`
  position: relative;
  width: 20%;
  border: 1px solid #ccc;
  border-left: none;
  height: 50px;
  cursor: pointer;
  padding: 10px 0;
  text-align: center;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (10 / 1320)) 0;
    height: calc(100vw * (50 / 1320));
  };

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (10 / 428)) 0;
    height: calc(100vw * (50 / 428));
    width: 50%;
    
  };

  &:nth-child(5n+1){
    border-left: 1px solid #ccc;
  }
  &:nth-child(n+6){
    border-top: none;
  }

  &.active{
    background-color: #555;
    color: #fff;
    font-weight: bold;
  }

  /* &::after{
    content: ">";
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
  } */
`
const PlanNavUlM = styled.ul`
  position: relative;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  padding: 0 calc(100vw * (24 / 428));
  background-color: #fff;
  border-bottom: 1px solid #eee;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
  display: none;
}
`

const PlanNavLiM = styled.li`
  padding:  calc(100vw * (12 / 428)) calc(100vw * (10 / 428));
  cursor: pointer;
  border-bottom: 2px solid #fff;
  
&.active{
    font-weight: bold;
    border-bottom: 2px solid #111;
  }
`;

const PlanItemsWrap = styled.div`
  width: 100%;
  /* padding-top: 80px; */
  padding-bottom: 60px;

  @media screen and (max-width: 1320px) {
    /* padding-top: calc(100vw * (80 / 1320)); */
    padding-bottom: calc(100vw * (60 / 1320));
  }

  @media screen and (max-width: 930px) {
    /* padding-top: calc(100vw * (40 / 428)); */
    padding: calc(100vw * (40 / 428)) 0 calc(100vw * (40 / 428));
  }
`
const PlanContentWrap = styled.div`
  width: 1240px;
  margin: 0 auto;
  scroll-margin-top: 300px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    scroll-margin-top: calc(100vw * (300 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: auto;
    margin: auto;
    scroll-margin-top: calc(100vw * (120 / 428));
  }
`;

const TitleWrap = styled.div`
  font-weight: bold;
  text-align: center;
  padding: 30px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) 0;
  };

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0;
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  };

`;
const TitleText = styled.h2`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #222222;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    justify-content: center;
  }
`;
const PlanListBox = styled.div`
  width: 100%;
  padding-top: 20px;
  position: relative;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (20 / 1320));
    /* min-height: calc(100vw * (500 / 1320)); */
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (20 / 428));
    background: none;
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
    /* min-height: calc(100vw * (300 / 428)); */
  }
`;
const PlanInner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-bottom: 20px;

  & .product_box {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (20 / 1320));

    & .product_box {
      &:not(:last-child) {
        margin-right: calc(100vw * (8 / 1320));
      }
    }
  }

  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (20 / 428));
    justify-content: space-between;

    & .product_box {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
`;