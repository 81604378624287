import React, {useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import serverController from '../../server/serverController';

import { CategoryWrapper } from './CategoryContents.element';
import CategoryFashions from './CategoryFashions';
import CategoryMain from './CategoryMain';
import CategoryMultipleMain from './CategoryMultipleMain';
import CategoryMultipleContents from './CategoryMultipleContents';
import CategoryDramaMain from './CategoryDramaMain';
import CategoryFaces from './CategoryFaces';

const CategoryContents = () => {
    const { category_id } = useParams();        
    
    const categoryRender = () => {
        switch(category_id) {
            case "1": // 셀럽
                return (
                    <>
                    <CategoryMain />
                    <CategoryMultipleContents />
                    <CategoryFashions /> 
                    </>
                )
            case "2":  // 드라마
                return (
                    <>
                    {/* <CategoryMain /> */}
                    <CategoryDramaMain />
                    {/* <CategoryFaces/>               */}
                    <CategoryMultipleContents/>
                    <CategoryFashions/> 
                    </>
                )
            case "3": // 전문
                return (
                    <>
                    <CategoryMain reverse />
                    <CategoryMultipleContents/>
                    <CategoryFashions/> 
                    </>
                )
            case "4": // 아이돌
                return (
                    <> 
                    <CategoryMain />
                    {/* <CategoryMultipleMain /> */}
                    {/* <CategoryFaces/>                     */}
                    <CategoryMultipleContents/>
                    <CategoryFashions/> 
                    </>
                )
            default: 
                return;
        }
    }

    return (
        <CategoryWrapper>
           {categoryRender()} 
        </CategoryWrapper>
    )
}

export default CategoryContents
