import React, { useRef, useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";

// controller
import { imgURL } from "../../../../server/appInfoContoller";
import serverController, { ip } from "../../../server/serverController";
import Commas from "../../../server/Commas";
import modalOpen from "../../../../server/modal";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";

// elements
import { AlertBox } from "../../login/auth.elements";

//img
import IconShareKakao from "../../../img/icon/icon_share_kakao.svg";
import IconShareUrl from "../../../img/icon/icon_share_url.svg";

import IconWishOn from "../../../img/icon/icon_wish_on.svg";
import IconWishOff from "../../../img/icon/icon_wish_off.svg";
import IconClose from "../../../img/icon/icon_popup_close.svg";
import DefaultThumbnailImg from "../../../img/icon/loading_default_img.svg";

import { PaymentAction } from "../../../../store/actionCreators";
import { postLikeProducts } from "../../../../hooks/likeHooks";
import CartModal from "./CartModal";
import Modal from "../../common/modal/Modal";

// option
import DetailSetOption from "../../../../components/option/DetailSetOption";
import OptionListLayout from "../../../../components/option/OptionListLayout";
import MyPageDefaultModal from "../../common/modal/MyPageDefaultModal";

SwiperCore.use([Navigation, Pagination]);

export default function DetailInfo({ product, optionList, setOptionList }) {
  const history = useHistory();
  const { pathname, location } = useLocation();
  const { isLogged } = useSelector((state) => state.userData);
  const data = useSelector((state) => state?.prodDetailData?.productInfo);

  const { brand, productInfo } = useSelector((state) => state?.prodDetailData);

  const [isModal, setIsModal] = useState(false);
  const [isCartModal, setIsCartModal] = useState(false);

  const [addressDataBool, setAddressDataBool] = useState(false);
  const [addressModalPop, setAddressModalPop] = useState(false);

  // share modal
  const [shareModalShow, setShareModalShow] = useState(false);

  // link paste - AlertBox show state
  const [alertBoxShow, setAlertBoxShow] = useState(false);

  // option
  const [optIdList, setOptIdList] = useState([]);
  const [selectList, setSelectList] = useState({});
  const [shopOption, setShopOption] = useState(false);
  const [optionText, setOptionText] = useState("");

  const [isLiked, setIsLiked] = useState(false);

  // s :: option
  useEffect(() => {
    if (product.prd_id && optionList.length === 0) {
      const data = {
        1617853119962: {
          prd_id: product.prd_id,
          prd_img: product.prd_main_img,
          prd_price: product.prd_price,
          prd_sale_rate: product.prd_sale_rate,
          sale_price: product.sale_price,
          prd_title: data?.prd_name,
          prd_list_price: product.prd_list_price,
          prd_sale_price: product.prd_sale_price,
          prd_earn_price: product.prd_earn_price,
          count: 1,
          options: {
            optionList: {},
            optId: [],
            product: {
              prd_id: product.prd_id,
              prd_sale_rate: product.prd_sale_rate,
              sale_price: product.sale_price,
              prd_price: product.prd_price,
            },
            isOptional: false,
            isSetOptional: false,
          },
        },
      };
      setSelectList(data);
    } else setSelectList({});
  }, [optionList]);

  // get address data
  const getReceiversInfo = () => {
    serverController.connectFetchController(`receivers`, "GET", null, (res) => {
      if (res.result === 0) {
        setAddressDataBool(true);
      }
    });
  };

  const getOptionTextList = (option) => {
    var optionText = "";
    var value = option;
    for (let i in value) {
      for (let key in value[i].options.optionList) {
        // optionText += "[옵션: ";
        let optionlist = value[i].options.optionList[key];
        optionText += value[i].options.isSetOptional == true ? value[i].prd_title + ":" : "";
        for (let z = 0; z < optionlist.length; z++) {
          var opt = optionlist[z];
          optionText += opt.opt_name + (z + 1 == optionlist.length ? "" : "/");
        }
        // optionText += "]";
      }
    }
    return optionText;
  };

  const checkSetProductValidation = (opt) => {
    let options = opt.options;

    for (let key in selectList) {
      if (JSON.stringify(selectList[key].options) == JSON.stringify(options)) {
        alert("이미 선택된 옵션입니다.");
        return;
      }
    }

    var selectOption = JSON.parse(JSON.stringify(selectList));
    var date_id = new Date().getTime().toString();
    selectOption[date_id] = opt;

    getOptionTextList(selectOption);
    setSelectList(selectOption);

    setOptionText(getOptionTextList(selectOption));
  };

  function getOptionName(optionlist) {
    var optionText = "";
    for (let key in optionlist) {
      // optionText += "[옵션: ";
      let list = optionlist[key];
      for (let z = 0; z < list.length; z++) {
        var opt = list[z];
        optionText +=
          opt.opt_name +
          (z + 1 == list.length ? "" : " / ") +
          (opt.opt_price > 0 ? " +" + Commas.numberWithCommas(opt.opt_price) + "원" : "");
      }
      // optionText += "]";
    }
    return optionText;
  }

  function getOptionListLayout(optArray, prdKey, optionlist) {
    optArray.push(
      <OptionListLayout
        key={prdKey}
        store={true}
        prdKey={prdKey}
        item={selectList[prdKey]}
        selectList={selectList}
        shopOption={shopOption}
        getOptionName={getOptionName}
        optionlist={optionlist}
        setSelectList={setSelectList}
      />
    );
  }

  function modalOptionList() {
    var optArray = [];
    for (let prdKey in selectList) {
      let optionlist = selectList[prdKey].options.optionList;
      getOptionListLayout(optArray, prdKey, optionlist);
      // if(prdKey > 0) return
    }
    return optArray;
  }
  // e :: option

  useEffect(() => {
    if (product) setIsLiked(product.like_id !== 0 && product.like_id !== undefined);
  }, [product]);

  const cartClick = async () => {
    if (!isLogged) {
      return setIsModal(true);
    }

    if (Object.keys(selectList).length === 0) {
      return alert("옵션을 선택해주세요.");
    }

    var count = 0;
    for (let key in selectList) {
      if (selectList[key].options.isOptional == false && selectList[key].prd_id == product.prd_id)
        count++;
    }

    if (shopOption) {
      if (count === 0) {
        alert("필수 상품을 선택해주세요");
        return false;
      }
    }

    for (const key in selectList) {
      const formData = new FormData();
      formData.append(
        "cart_item",
        JSON.stringify({
          bc_title: product.bc_title,
          prd_id: product.prd_id,
          prd_type: product.prd_type,
          prd_price: product.prd_price,
          prd_sale_rate: product.prd_sale_rate,
          sale_price: product.sale_price,
          prd_title: data?.prd_name,
          prd_detail1: product.prd_detail1,
          prd_detail2: product.prd_detail2,
          prd_img: product.prd_main_img,
          prd_earn_price: product.prd_earn_price,
          prd_earn_rate: product.prd_earn_rate,
          prd_list_price: product.prd_list_price,
          prd_sale_price: product.prd_sale_price,
          count: 1,
          data: [selectList[key]],
        })
      );

      formData.append("prd_id", product.prd_id);
      formData.append(
        "opt_id",
        selectList[key].options.optId[selectList[key].options.optId.length - 1]
      );
      await serverController.connectFetchController(`cart/products`, "POST", formData, (res) => {
        if (res?.result === 1) {
          setIsCartModal(true);
          modalOpen();
          setSelectList({});
        }
      });
    }
  };

  const payClick = async () => {
    if (!isLogged) {
      return setIsModal(true);
    }
    if (addressDataBool) {
      return setAddressModalPop(true);
    }

    var count = 0;
    for (let key in selectList) {
      if (selectList[key].options.isOptional == false && selectList[key].prd_id == product.prd_id)
        count++;
    }
    if (count == 0) {
      alert("상품 및 옵션을 선택해주세요.");
      return;
    }

    var resultArray = [];
    for (var key in selectList) {
      resultArray.push(selectList[key]);
    }
    if (Object.keys(selectList).length === 0) {
      alert("상품 및 옵션을 선택해주세요.");
      return false;
    }

    localStorage.selectList = JSON.stringify([
      {
        cart_items: JSON.stringify({
          prd_id: product.prd_id,
          prd_type: product.prd_type,
          prd_price: product.prd_price,
          prd_sale_rate: product.prd_sale_rate,
          sale_price: product.sale_price,
          prd_title: data?.prd_name,
          prd_detail1: product.prd_detail1,
          prd_detail2: product.prd_detail2,
          prd_img: product.prd_main_img,
          prd_earn_price: product.prd_earn_price,
          prd_earn_rate: product.prd_earn_rate,
          prd_list_price: product.prd_list_price,
          prd_sale_price: product.prd_sale_price,
          count: 1,
          data: resultArray,
        }),
      },
    ]).toString();
    onHandleOrder();
    history.push("/store/pay");
  };

  const onHandleLike = async () => {
    const res = await postLikeProducts(productInfo?.prd_id);
    setIsLiked(res?.result === 1);

    if (res?.result === 0) {
      setIsModal(true);
    }
  };

  const getIsInventory = async () => {
    if (Object.keys(selectList).length === 0) return alert("옵션을 선택해주세요.");
    for (const key in selectList) {
      const optId = selectList[key].options.optId[selectList[key].options.optId.length - 1];
      await serverController.connectFetchController(
        `product/${optId}/inventory?count=${selectList[key].count}`,
        "GET",
        null,
        (res) => {
          if (res.result !== 1) return alert("일시적인 오류입니다. 다시 시도해주세요.");
          if (res.isItPossible === "Y") {
            payClick();
          } else {
            return alert("재고 보유 수량이 부족합니다. 다시 확인해주세요.");
          }
        }
      );
    }
  };

  //단순 selectList 에 대한 돈계산.
  function calculatePrice(list) {
    var totalPrice = 0;
    var count = 0;

    for (var key in list) {
      var price = 0;
      var optionList = list[key].options.optionList;
      count++;
      for (var calKey in optionList) {
        for (var i = 0; i < optionList[calKey].length; i++) {
          price += optionList[calKey][i].opt_price;
        }
      }

      if (list[key].options.isOptional == false) {
        // price += Math.floor((list[key].prd_price) - ((list[key].prd_price * list[key].prd_sale_rate) / 100));
        price += list[key].prd_list_price;
      }

      price *= list[key].count;
      totalPrice += price;
    }

    //배송비 추후 따로 받아와야함
    if (count > 0) totalPrice += 0; //shipPrice;

    return totalPrice;
  }

  const onHandleOrder = () => {
    PaymentAction.saveProductsPaymentInfo(selectList);
    history.push(`/store/pay`);
  };

  useEffect(() => {
    setTimeout(() => {
      setAlertBoxShow(false);
    }, 3000);
  }, [alertBoxShow]);

  useEffect(() => {
    getReceiversInfo();
  }, []);

  const shareModalCompItems = {
    setShareModalShow,
    product,
    pathname,
    setAlertBoxShow,
  };

  return (
    <Container>
      {shareModalShow && <ShareModalComp {...shareModalCompItems} />}
      <AlertBoxCustom show={alertBoxShow}>링크가 복사되었습니다.</AlertBoxCustom>
      {isModal && (
        <MyPageDefaultModal
          text="로그인 후 이용가능합니다."
          trueText="로그인"
          trueFunc={() => {
            if (window.location.pathname) return history.push(`/store/login?returnTo=${window.location.pathname}`);
            history.push(`/store/login`);
          }}
          falseFunc={() => setIsModal(false)}
        />
      )}
      {addressModalPop && (
        <MyPageDefaultModal
          text="배송지 입력 후 정상 이용 가능합니다."
          trueText="입력하러 가기"
          trueFunc={() => {
            history.push(`/store/mypage/adress`);
          }}
          falseFunc={() => setAddressModalPop(false)}
        />
      )}

      <DetailInfoWrap id="detailInfo">
        {product?.prd_main_img?.split(",")?.length > 1 ? (
          <DetailImgBox>
            <Swiper
              autoplay={{ delay: 8000 }}
              slidesPerView={1}
              centeredSlides={true}
              pagination={{
                type: "fraction",
              }}
            >
              {product?.prd_main_img?.split(",")?.map((prdImg, idx) => {
                return (
                  <SwiperSlide key={prdImg}>
                    <DetailImg src={`${imgURL}${prdImg}`} alt={"prdImg-slide-" + idx} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </DetailImgBox>
        ) : (
          <DetailImgBox>
            <DetailImg
              src={
                product?.prd_main_img ? `${imgURL}${product?.prd_main_img}` : DefaultThumbnailImg
              }
              alt={product?.prd_main_img ? "prd-main-img" : "prd-main-img-undefined"}
            />
          </DetailImgBox>
        )}

        <DetailInfoTextWrap>
          <DetailInfoTitleBox>
            <BrandBox>
              <BrandText
                onClick={() => {
                  history.push(`/store/brand/detail/${product.bc_id}?page=1`);
                }}
              >
                {product?.bc_title || "(BRAND)"}
              </BrandText>
            </BrandBox>
            <TitleWrap>
              <TitleBox>
                <Title>{data?.prd_name || "(PRODUCT NAME)"}</Title>
              </TitleBox>
              <PriceBox>
                <Price>{Commas.numberWithCommas(data?.prd_price ?? 0)}</Price>
                <SaleWrap>
                  <SaleArea>
                    <SaleRate>{data?.prd_sale_rate || 0}%</SaleRate>
                    <SalePrice>{Commas.numberWithCommas(data?.prd_list_price ?? 0)}원</SalePrice>
                  </SaleArea>
                  <ShareButton
                    type="button"
                    onClick={() => {
                      setShareModalShow(!shareModalShow);
                    }}
                  >
                    공유하기
                  </ShareButton>
                </SaleWrap>
              </PriceBox>
            </TitleWrap>
          </DetailInfoTitleBox>
          <InfoBoxWrap>
            <InfoBoxTbody>
              <InfoBox>
                <InfoBoxTitle>추가혜택</InfoBoxTitle>
                <InfoBoxText>최대 약 {data?.prd_list_price * 0.01}p 적립</InfoBoxText>
              </InfoBox>
              {/* <InfoBox>
              <InfoBoxTitle rowSpan="2">배송정보</InfoBoxTitle>
              <InfoBoxText>오후 1시까지 결제하면 오늘 출발</InfoBoxText>
            </InfoBox> */}
            </InfoBoxTbody>
          </InfoBoxWrap>

          {/* <DetailsOptions /> */}

          <PC>
            {data?.prd_featured === 1 &&
              <DetailSetOption
                product={product}
                productInfo={[product]}
                checkSetProductValidation={checkSetProductValidation}
                optionList={optionList}
                setOptIdList={setOptIdList}
              />}
            {data?.prd_featured !== 1 && <Title>품절상품입니다.</Title>}
            <OptionListWrap>{modalOptionList()}</OptionListWrap>

            <OptionWrap>
              <OptionPriceBox>
                <OptionPriceTitle>총 금액</OptionPriceTitle>
                <OptionPriceText>
                  {Commas.numberWithCommas(calculatePrice(selectList))}원
                </OptionPriceText>
              </OptionPriceBox>
            </OptionWrap>
            <PayBtnWrap>
              <WishWrap onClick={onHandleLike}>
                {product && <WishImg src={isLiked ? IconWishOn : IconWishOff} alt="wish off" />}
              </WishWrap>
              {product.prd_featured === 1 &&
                <CartBtn onClick={() => {
                  if (product.prd_featured !== 1) return;
                  cartClick();
                }}>장바구니</CartBtn>
              }

              <PayBtn
                featured={product.prd_featured}
                onClick={() => {
                  if (product.prd_featured !== 1) return;
                  getIsInventory();
                }}
              >
                {product?.prd_featured === 1 && "바로구매"}
                {product?.prd_featured !== 1 && product?.prd_featured !== undefined && "SOLD OUT"}
              </PayBtn>
            </PayBtnWrap>
          </PC>
        </DetailInfoTextWrap>
      </DetailInfoWrap>

      {isCartModal && <CartModal setIsModal={setIsCartModal} />}
    </Container>
  );
}

const ShareModalComp = ({ setShareModalShow, product, pathname, setAlertBoxShow }) => {
  const copyTextUrl = () => {
    navigator?.clipboard?.writeText("https://wala.land" + pathname).then(() => {
      setShareModalShow(false);
      setAlertBoxShow(true);
    });
  };

  // s :: share kakao
  useEffect(() => {
    let cleanUpFunc = true;
    if (cleanUpFunc) {
      shareKakaoFunc();
    } else {
      return;
    }
    return () => {
      cleanUpFunc = false;
    };
  }, []);

  const shareKakaoFunc = () => {
    let productImgUrl = null;
    if (product?.prd_main_img?.split(",")?.length) {
      productImgUrl = imgURL + product?.prd_main_img?.split(",")[0];
    } else {
      productImgUrl = imgURL + product.prd_main_img;
    }
    if (window.Kakao) {
      const kakaoSetting = window.Kakao;
      if (!kakaoSetting.isInitialized()) kakaoSetting.init("37a1ddb33e426b6bf6c53795c7c72df9");

      kakaoSetting.Link.createDefaultButton({
        container: "#kakao-share-button",
        objectType: "commerce",
        content: {
          title: product.prd_name,
          description: "WALA",
          imageUrl: productImgUrl,
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
        commerce: {
          productName: product.prd_name,
          regularPrice: product.prd_price,
          discountRate: product.prd_sale_rate,
          discountPrice: product.prd_list_price,
        },
        buttons: [
          {
            title: "웹으로 보기",
            link: {
              mobileWebUrl: window.location.href,
              webUrl: window.location.href,
            },
          },
        ],
      });
    }
  };
  // e :: share kakao
  return (
    <>
      <Mobile>
        <ModalBg
          onClick={() => {
            setShareModalShow(false);
          }}
        />
      </Mobile>
      <ShareModal>
        <ModalWrap>
          <ShareUl>
            <ShareList id="kakao-share-button">
              <ShareImg>
                <img src={IconShareKakao} alt="kakao-share-icon" />
              </ShareImg>
              <ShareText>카카오톡</ShareText>
            </ShareList>
            <ShareList onClick={copyTextUrl}>
              <ShareImg>
                <img src={IconShareUrl} alt="share-icon" />
              </ShareImg>
              <ShareText>링크 복사</ShareText>
            </ShareList>
          </ShareUl>
          <ModalCloseButton
            onClick={() => {
              setShareModalShow(false);
            }}
          >
            닫기
          </ModalCloseButton>
        </ModalWrap>
      </ShareModal>
    </>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 180px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (180 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: 0;
  }
`;
const DetailHistoryWrap = styled.div`
  width: 1300px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1250 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
  }
`;
const DetailHistoryInner = styled.div`
  display: flex;
  align-items: center;
`;
const DetailHistory = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #8a8888;
  &:last-child {
    color: #000;
    font-weight: bold;
  }
  &:after {
    display: block;
    content: ">";
    font-size: 14px;
    color: #8a8888;
    margin: 0 15px;
  }
  &:last-child:after {
    display: none;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    &:after {
      font-size: calc(100vw * (14 / 1320));
      margin: 0 calc(100vw * (15 / 1320));
    }
  }
`;
//img slide
const SwiperNavWrap = styled.div`
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const SwiperNavBox = styled.div`
  width: 1240px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
`;
const SwiperNavL = styled.div`
  height: 70px;
  width: 70px;
  z-index: 3;
  transform: rotate(180deg);
  cursor: pointer;
  & > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (70 / 1320));
    width: calc(100vw * (70 / 1320));
  }
`;
const SwiperNavR = styled.div`
  height: 70px;
  width: 70px;
  z-index: 3;
  cursor: pointer;
  & > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (70 / 1320));
    width: calc(100vw * (70 / 1320));
  }
`;

const DetailInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    display: block;
    width: 100%;
  }
`;
const DetailInfoInner = styled.div`
  display: flex;
  padding: 40px 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (40 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    display: block;
    padding: 0;
  }
`;
const DetailSlideWrap = styled.div`
  position: relative;
  width: calc(100% / 2);
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  padding-left: 14px;

  @media screen and (max-width: 1320px) {
    padding-left: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    padding-left: 0;
  }
`;
const DetailImgBox = styled.div`
  width: 560px;
  height: 600px;
  overflow: hidden;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (560 / 1320));
    height: calc(100vw * (600 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    min-height: calc(100vw * (400 / 428));
    max-height: calc(100vw * (600 / 428));
    height: auto;
  }
`;
const DetailImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const DetailSubImgWrap = styled.div`
  width: 74px;
  height: 74px;
  border-radius: 8px;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (74 / 1320));
    height: calc(100vw * (74 / 1320));
    border-radius: calc(100vw * (8 / 1320));
  }
`;
const DetailSubImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

//info text
const DetailInfoTextWrap = styled.div`
  width: 640px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (640 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const DetailInfoTextInner = styled.div`
  padding: 0 35px;

  @media screen and (max-width: 1320px) {
    padding: 0 calc(100vw * (35 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const DetailInfoTitleBox = styled.div`
  width: 100%;
`;
const BrandBox = styled.div`
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 2px solid #000;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (12 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-bottom: calc(100vw * (12 / 428));
  }
`;
const WishWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border: 1px solid #dddddd;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (50 / 1320));
    height: calc(100vw * (50 / 1320));
  }
`;
const WishImg = styled.img`
  width: 24px;
  height: 24px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
`;
const BrandText = styled.span`
  color: #777777;
  font-weight: 600;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const TitleWrap = styled.div`
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #ddd;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const TitleBox = styled.div`
  width: 100%;
`;
const Title = styled.p`
  line-height: 1.5;
  color: #333;
  font-size: 20px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (20 / 428));
  }
`;
const PriceBox = styled.div`
  width: 100%;
  padding-top: 20px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (20 / 428));
  }
`;
const Price = styled.p`
  font-size: 15px;
  text-decoration: line-through;
  color: #777777;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const SaleWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const SaleArea = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
  padding-top: 5px;

  @media screen and (max-width: 1320px) {
    padding-top: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (5 / 428));
  }
`;
const SaleRate = styled.p`
  color: #333333;
  font-weight: 700;
  font-size: 17px;
  padding: 2px 5px;
  margin-right: 5px;
  border-radius: 5px;
  background: #fdf251;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (17 / 1320));
    padding: calc(100vw * (2 / 1320)) calc(100vw * (5 / 1320));
    margin-right: calc(100vw * (5 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (17 / 428));
    padding: calc(100vw * (2 / 428)) calc(100vw * (5 / 428));
    margin-right: calc(100vw * (5 / 428));
  }
`;
const SalePrice = styled.p`
  color: #333333;
  font-weight: 700;
  font-size: 22px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (22 / 428));
  }
`;
const ShareButton = styled.button`
  width: 82px;
  height: 35px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (82 / 1320));
    height: calc(100vw * (35 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (82 / 428));
    height: calc(100vw * (35 / 428));
    font-size: calc(100vw * (15 / 428));
  }
`;

const InfoBoxWrap = styled.table`
  width: 100%;
  margin: 20px 0 40px;

  @media screen and (max-width: 1320px) {
    margin: calc(100vw * (20 / 1320)) 0 calc(100vw * (40 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin: calc(100vw * (20 / 428)) 0;
  }
`;
const InfoBoxTbody = styled.tbody``;
const InfoBox = styled.tr``;
const InfoBoxTitle = styled.td`
  width: 80px;
  font-size: 16px;
  color: #999999;
  vertical-align: top;
  padding-bottom: 10px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (80 / 1320));
    font-size: calc(100vw * (16 / 1320));
    padding-bottom: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (72 / 428));
    font-size: calc(100vw * (15 / 428));
    padding-bottom: calc(100vw * (10 / 428));
  }
`;
const InfoBoxText = styled.td`
  width: calc(100% - 80px);
  font-size: 16px;
  color: #333333;
  vertical-align: top;
  padding-bottom: 6px;

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (80 / 1320)));
    font-size: calc(100vw * (16 / 1320));
    padding-bottom: calc(100vw * (6 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (72 / 428)));
    font-size: calc(100vw * (15 / 428));
    padding-bottom: calc(100vw * (6 / 428));
  }
`;
//option
const OptionWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 20px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) 0 calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    display: block;
    padding: 0;
  }
`;
const OptionCountWrap = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 930px) {
    justify-content: space-between;
    padding: calc(100vw * (15 / 428)) 0;
  }
`;
const CountTitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;

  @media screen and (max-width: 1320px) {
    margin-right: calc(100vw * (40 / 1320));
  }
`;
const CountTitle = styled.p`
  font-size: 20px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    font-weight: bold;
  }
`;
const CountInputWrap = styled.div`
  display: flex;
  align-items: center;
`;
const CountMinus = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  font-size: 18px;
  color: #646464;
  border: 1px solid #646464;
  border-radius: 100%;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (35 / 1320));
    height: calc(100vw * (35 / 1320));
    font-size: calc(100vw * (18 / 1320));
    border: calc(100vw * (1 / 1320)) solid #646464;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (35 / 428));
    height: calc(100vw * (35 / 428));
    font-size: calc(100vw * (17 / 428));
    border: calc(100vw * (1 / 428)) solid #646464;
  }
`;
const CountInput = styled.input`
  width: 50px;
  font-size: 18px;
  text-align: center;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
const CountPlus = styled(CountMinus)`
  color: #000;
  border-color: #000;
`;
const OptionPriceBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const OptionPriceTitle = styled.p`
  color: #333;
  font-weight: bold;
  font-size: 24px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const OptionPriceText = styled.p`
  font-size: 24px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (24 / 428));
  }
`;
//btn
const PayBtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const CartBtn = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 285px;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #000;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (285 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
`;
const PayBtn = styled(CartBtn)`
  width: ${props => (props.featured !== 1 ? "580px" : "285px")};
  background: ${props => (props.featured !== 1 ? "#888" : "#000")};
  border: ${props => (props.featured !== 1 ? "1px solid #888" : "1px solid #000")};
  color: #fff;
  @media screen and (max-width: 1320px) {
    width: ${props => (props.featured !== 1 ? "calc(100vw * (580 / 1320));" : "calc(100vw * (285 / 1320));")};
  }
`;
const OptionListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`;
const BackBtn = styled.img`
  position: absolute;
  top: calc(100vw * (12 / 428));
  left: calc(100vw * (12 / 428));
  display: block;
  width: calc(100vw * (24 / 428));
  padding: calc(100vw * (5 / 428));
  cursor: pointer;
`;

// share modal
const ShareModal = styled.div`
  position: absolute;
  top: 262px;
  left: calc(50% + 448px);
  transform: translateX(-50%);
  z-index: 106;
  width: 342px;
  height: 185px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 1320px) {
    top: calc(100vw * (262 / 1320));
    left: calc(50% + calc(100vw * (448 / 1320)));
    width: calc(100vw * (342 / 1320));
    height: calc(100vw * (185 / 1320));
  }
  @media screen and (max-width: 930px) {
    position: fixed;
    left: 0;
    top: auto;
    bottom: 0;
    transform: translateX(0);
    width: 100%;
    height: auto;
  }
`;
const ModalWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media screen and (max-width: 930px) {
    display: block;
    height: auto;
  }
`;
const ShareUl = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (40 / 428)) 0;
  }
`;
const ShareList = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  &:not(:last-child) {
    margin-right: 50px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-right: calc(100vw * (50 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-right: calc(100vw * (60 / 428));
    }
  }
`;
const ShareImg = styled.div`
  width: 56px;
  height: 56px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (56 / 1320));
    height: calc(100vw * (56 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (56 / 428));
    height: calc(100vw * (56 / 428));
  }
`;
const ShareText = styled.p`
  color: #777777;
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;

const ModalBg = styled.div`
  @media screen and (max-width: 930px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 105;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;
const ModalCloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 25px;
  width: 24px;
  height: 24px;
  font-size: 0;
  background: url(${IconClose}) no-repeat center center / contain;

  @media screen and (max-width: 1320px) {
    top: calc(100vw * (20 / 1320));
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    position: static;
    width: 100%;
    height: calc(100vw * (80 / 428));
    font-size: calc(100vw * (18 / 428));
    color: #333333;
    font-weight: 500;
    text-align: center;
    padding: 0 calc(100vw * (36 / 428));
    background: #fff;
    border-top: 1px solid #eeeeee;
  }
`;

// alert box
const AlertBoxCustom = styled(AlertBox)`
  @media screen and (max-width: 930px) {
    bottom: calc(100vw * (170 / 428));
  }
`;
