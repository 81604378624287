import { createAction, handleActions } from "redux-actions";
import produce from "immer";

const UPDATE_PERMISSION = "userInfoAction/updatePMType";
const UPDATE_FCMTOKEN = "userInfoAction/updateFcmToken";

export const updatePMType = createAction(UPDATE_PERMISSION);
export const updateFcmToken = createAction(UPDATE_FCMTOKEN);

const initialState = {
  permission: {
    state: true,
  },
  fcmToken: {
    data: "",
  },
};

export default handleActions(
  {
    [UPDATE_PERMISSION]: (state, action) => {
      return produce(state, (draft) => {
        draft.permission.state = action.payload.permission.state;
      });
    },
    [UPDATE_FCMTOKEN]: (state, action) => {
      return produce(state, (draft) => {
        draft.fcmToken.data = action.payload.fcmToken.data;
      });
    },
  },
  initialState
);
