import { useEffect, useState } from "react";
import serverController from "../../../server/serverController";
import styled from "styled-components";
import { imgURL } from "../../server/appInfoController";
import NewPagination from "../../component/common/pagination/NewPagination";

export default function ContentsYouTubeWrap({ video }) {

  return (
    <ContentsYoutubeBox>
      <ContentsYoutube
        type="video/webm"
        src={video}
      />
    </ContentsYoutubeBox>
  );
};


// 유튜브
const ContentsYoutubeBox = styled.div`
  width: 100%;
  /* overflow: hidden; */
  height: 429px;
  margin-bottom: 10px;
  
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (429 / 1320));
    margin-bottom: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (680 / 930));
    margin-bottom: calc(100vw * (10 / 930));
  }
`;
const ContentsYoutube = styled.embed`
  width: 100%;
  height: 100%;
`;