import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import faker from 'faker';
import serverController from '../../server/serverController';
import { PC, Mobile } from '../common/mediaStlye';
import { useParams, useHistory } from 'react-router-dom';

import {
    ResultLayout,
    ResultHeadings,
    NewsItemList,
    NewsItem,
    NewsItemImg,
    NewsItemText,
    MoreButton
} from './SearchResultPage.elements';

import searchImg1 from '../../images/search/search-1.png';
import searchImg2 from '../../images/search/search-2.png';
import searchImg3 from '../../images/search/search-3.png';
import searchImg4 from '../../images/search/search-4.png';

const API_KEY = "AIzaSyB3N_3efhV8z8wytTYajWXAG5uXesp0urc";
const SEARCH_ENGINE_KEY = "7be9901eb25d1f2c6";

const ResultsFashionNews = () => {
    const history = useHistory()
    const { keyword } = useParams();
    const dummyImages = [searchImg2, searchImg2, searchImg1, searchImg4];
    const [offset, setOffset] = useState(4);

    const requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    const [list, setList] = useState([]);
    const getData = async () => {
        const GOOGLE_PATH = `https://www.googleapis.com/customsearch/v1?key=${API_KEY}&cx=${SEARCH_ENGINE_KEY}&q=${keyword}&start=1&filter=0`;
        const res = await fetch(GOOGLE_PATH, requestOptions)
            .then(res => res.json())
            .then(data => data)
            .catch(error => console.log('error', error));
        console.log(res)

        if (res?.items) {
            setList(res.items ?? [])
        }
    }

    useEffect(() => {
        getData()
    }, [keyword])

    const onHandleClick = () => {
        setOffset(prev => prev + 4);
    }

    return (
        <ResultLayout>
            <ResultHeadings>
                <h2>"{keyword}" FASHION NEWS</h2>
            </ResultHeadings>

            <NewsItemList>
                <PC>
                    {
                        list?.slice(0, offset).map((v, i) => {
                            return (
                                <NewsItem key={i} as="a" href={v?.link} target="_blank">
                                    <NewsItemText>
                                        <h4>{ReactHtmlParser(v?.htmlTitle)}</h4>
                                        <span>09.24</span>
                                        <p>{ReactHtmlParser(v?.htmlSnippet)}</p>
                                    </NewsItemText>
                                    <NewsItemImg><img src={v?.pagemap?.cse_image && v?.pagemap?.cse_image[0].src} /></NewsItemImg>
                                </NewsItem>
                            )
                        })
                    }
                </PC>
                <Mobile>
                    {
                        list?.slice(0, offset).map((v, i) => {
                            return (
                                <NewsItem key={i} as="a" href={v?.link} target="_blank">
                                    <NewsItemText>
                                        <h4>{ReactHtmlParser(v?.htmlTitle)}</h4>
                                        <span>09.24</span>
                                        <p>{ReactHtmlParser(v?.htmlSnippet)}</p>
                                    </NewsItemText>
                                    <NewsItemImg><img src={v?.pagemap?.cse_image && v?.pagemap?.cse_image[0].src} /></NewsItemImg>
                                </NewsItem>
                            )
                        })
                    }
                </Mobile>
            </NewsItemList>

            {
                offset < list?.length && <MoreButton onClick={onHandleClick}>See More</MoreButton>
            }
        </ResultLayout>
    )
}

export default ResultsFashionNews
