import React, {useEffect, useState} from 'react';
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery"
import styled from "styled-components";

import StylistPayPrice from './StylistPayPrice'
import PayFrom from '../../store_src/component/shopping/pay/PayFrom';
import TermModal from '../../store_src/component/common/modal/TermModal';

const StylistPayment = () => {
    const [isModal,setIsModal] = useState(false);

    return (
        <div>
            <CartStepWrap>
                <MyTitleWrap>
                    <Title>협찬 결제</Title>
                </MyTitleWrap>
            </CartStepWrap>

            <Container>
                <PayInfoWrap>
                    <PayFromWrap>
                        <PayFrom isStylist/>
                    </PayFromWrap>
                    <PayPriceWrap>
                        <StylistPayPrice setIsModal={setIsModal}/>
                    </PayPriceWrap>
                </PayInfoWrap>
                {
                    isModal &&
                    <TermModal setIsModal={setIsModal}/>
                }
            </Container>
        </div>
    )
}

export default StylistPayment

const Container = styled.div`
    width: 100%;
    margin-bottom: 200px;

    @media screen and (max-width: 1320px) {
        margin-bottom: calc(100vw*(200/1320));
    }

    @media screen and (max-width: 1024px) {
        margin-bottom: calc(100vw*(100/428));
    }
`
const CartStepWrap = styled.div`
    width: 1300px;
    margin: 0 auto;
    padding: 0 0 60px;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(1250/1320));
        padding: 0 0 calc(100vw*(60/1320));
    }

    @media screen and (max-width: 1024px) {
        width: calc(100vw*(388/428));
        padding: calc(100vw*(25/428)) 0;
    }
`
// title
const MyTitleWrap = styled.div`
    /* padding: 25px 0; */

    @media screen and (max-width: 1320px) {
        padding: calc(100vw*(25/1320)) 0;
    }

    @media screen and (max-width: 1024px) {
        padding: calc(100vw*(20/428)) 0;
    }
`
const Title = styled.h2`
    font-size: 30px;
    color: #20232b;
    font-weight: bold;
    text-align: center;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(30/1320));
    }

    @media screen and (max-width: 1024px) {
        font-size: calc(100vw*(25/428));
    }
`
const PayInfoWrap = styled.div`
    width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    border-top: 1px solid #000;
    padding-top: 30px;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(1250/1320));
        border-top: calc(100vw*(1/1320)) solid #000;
        padding-top: calc(100vw*(30/1320));
    }
    
    @media screen and (max-width: 1024px) {
        display: block;
        width: 100%;
        border-top: none;
        padding-top: 0;
    }
` 
const PayFromWrap = styled.div`
    width: calc(100% - 445px);
    padding-right: 50px;

    @media screen and (max-width: 1320px) {
        width: calc(100% - calc(100vw*(445/1320)));
        padding-right: calc(100vw*(50/1320));
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
        padding-right: 0;
    }
` 
const PayPriceWrap = styled.div`
    width: 445px;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(445/1320));
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
    }
` 
