import { useEffect, useState } from "react";
import { PC } from "../../MediaQuery";

import Header from "../../component/common/Header";

import MyMain from "../../component/mypage/MyMain";
import FullLoading from "../../component/common/modal/FullLoading";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function MypagePage() {
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const validLogin = () => {
    if (isLogged) return;
    alert(`잘못된 접근입니다.`);
    history.push(`/store`);
  };

  useEffect(() => {
    validLogin();
  }, []);

  return (
    <>
      {loading && <FullLoading />}
      <PC>
        <Header />
      </PC>
      <MyMain setLoading={setLoading} />
    </>
  );
}
