import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import serverController from '../../../server/serverController';
import modal from '../../../server/modal';
import styled from 'styled-components'
import { numberWithCommas } from '../commonUse';

import {
    pc,
    mobil,
    mquery,
    MQ,
    Media,
    Px,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
} from '../../common/mediaStlye';

import {
    ModalContainer,
    QuestModalContents,
    QuestModalHeadings,
    QuestCloseButton,
} from './pop.elements';
import {
    RequestForm,
    RequstLabel,
    RequestFormWrapper,
    RequstPeriod,
    DateInputBox,
    DateInput,
    RequestAgree,
    RequestButtonBox,
    RequestButton,
    RequestSelect,
    RequestTitle
} from './SponRequestModal.elements';
import SponRequestCalendar from './SponRequestCalendar';
import SponDetailCalendar from '../../stylist/SponDetailCalendar';
import RequestCalendar from './RequestCalendar';
import Calendar from '../calendar';

// option
import DetailSetOption from '../../option/DetailSetOption';
import OptionListLayout from '../../option/OptionListLayout';

import { useEffect } from 'react';

const SponRequestModal = ({ setIsSponModal, current, data, getDetailData }) => {
    const { mem_name } = useSelector(state => state.userData.userInfo);
    const { prd_id } = useParams();
    const memData = useSelector(state => state.userData.userInfo);

    const [agree, setAgree] = useState(false);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const [stylistCompany, setStylistCompany] = useState(memData.mem_belong)
    const [stylistName, setStylistName] = useState(mem_name)
    const [celebName, setCelebName] = useState("")
    const [workName, setWorkName] = useState("")
    const [deliveryType, setDeliveryType] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalDays, setTotalDays] = useState("")

    // option
    const [optionList, setOptionList] = useState([]);
    const [optIdList, setOptIdList] = useState([]);
    const [selectList, setSelectList] = useState({});
    const [shopOption, setShopOption] = useState(false);
    const [optionText, setOptionText] = useState("");
    const [product, setProduct] = useState({ prd_additional4: "", prd_additional5: "" });
    console.log(selectList)

    const test = [
        {
            "opt_id": 2942,
            "opt_title": "사이즈",
            "opt_name": "XL",
            "opt_price": 0,
            "opt_inventory": 1,
            "opt_relate_id": 0,
            "prd_id": 524,
            "br_id": 1
        }
    ]

    useEffect(() => {
        if (product.prd_id && optionList.length == 0) {
            const data = {
                "1617853119962": {
                    "prd_id": product.prd_id,
                    "prd_img": product.prd_img,
                    "prd_price": product.prd_price,
                    "prd_sale_rate": product.prd_sale_rate,
                    "sale_price": product.sale_price,
                    "prd_title": product.prd_title,
                    "prd_list_price": product.prd_list_price,
                    "prd_earn_price": product.prd_earn_price,
                    "count": 1,
                    "options": {
                        "optionList": {},
                        "optId": [],
                        "product": {
                            "prd_id": product.prd_id,
                            "prd_sale_rate": product.prd_sale_rate,
                            "sale_price": product.sale_price,
                            "prd_price": product.prd_price,
                        },
                        "isOptional": false,
                        "isSetOptional": false
                    }
                }
            }
            setSelectList(data);
        }
        else
            setSelectList({});
    }, [optionList]);

    const getData = async () => {
        await serverController.connectFetchController(`purchase/${prd_id}`, "GET", null, (res) => {
            console.log(res, "옵션상세 res")
            if (res?.result == 1) {
                let purchaseData = res.purchase.purchase_prd;
                purchaseData.prd_additional4 = "";
                purchaseData.prd_additional5 = "";
                setProduct(purchaseData);
                // setOptionList(test);
                setOptionList(res.purchase.options);
            }
        })
    }

    useEffect(() => {
        getData()
    }, [])

    function getOptionTextList(option) {
        var optionText = "";
        var value = option;
        for (let i in value) {
            for (let key in value[i].options.optionList) {
                optionText += "[옵션: ";
                let optionlist = value[i].options.optionList[key];
                optionText += (value[i].options.isSetOptional == true) ? value[i].prd_title + ":" : "";
                for (let z = 0; z < optionlist.length; z++) {
                    var opt = optionlist[z];
                    optionText += opt.opt_name + (z + 1 == optionlist.length ? "" : "/");
                }
                optionText += "]";
            }
        }
        return optionText;
    }

    const checkSetProductValidation = (opt) => {
        setSelectList([]);
        setOptionText('');
        getOptionTextList('')

        /*  let options = opt.options;
            for(let key in selectList){
                if(JSON.stringify(selectList[key].options) == JSON.stringify(options)){
                    alert('이미 선택된 옵션입니다. 옵션을 다시 선택해주세요.')
                    // setCartModal(true);
                    return;
                }
            }*/

        var selectOption = JSON.parse(JSON.stringify(selectList));
        var date_id = new Date().getTime().toString();
        // selectOption[date_id] = opt;
        selectOption["1"] = opt;

        getOptionTextList(selectOption);
        setSelectList(selectOption);
        setOptionText(getOptionTextList(selectOption))
    }

    function getOptionName(optionlist) {
        var optionText = "";
        for (let key in optionlist) {
            optionText += "[옵션: ";
            let list = optionlist[key];
            for (let z = 0; z < list.length; z++) {
                var opt = list[z];
                optionText += opt.opt_name + (z + 1 == list.length ? "" : " / ") + (opt.opt_price > 0 ? " +" + numberWithCommas(opt.opt_price) + "원" : "");
            }
            optionText += "]";
        }
        return optionText;
    }

    function getOptionListLayout(optArray, prdKey, optionlist) {
        optArray.push(
            <OptionListLayout
                prdKey={prdKey}
                item={selectList[prdKey]}
                shopOption={shopOption}
                getOptionName={getOptionName}
                optionlist={optionlist}
                setSelectList={setSelectList}
            />
        );
    }

    function modalOptionList() {
        var optArray = [];
        for (let prdKey in selectList) {
            let optionlist = selectList[prdKey].options.optionList;
            getOptionListLayout(optArray, prdKey, optionlist);
            // if(prdKey > 0) return
        }
        return optArray;
    }

    const requestData = [
        {
            name: "company_name",
            head: "업체명",
            placeholder: "업체명을 입력해주세요.",
            value: stylistCompany,
            onChange: (e) => setStylistCompany(e.target.value)
        },
        {
            name: "stylist",
            head: "담당자(Stylist)",
            placeholder: "담당자를 입력해주세요.",
            value: stylistName,
            onChange: (e) => setStylistName(e.target.value)
        },
        {
            name: "celevrity_name",
            head: "셀럽(착장)",
            placeholder: "착장 예정인 셀럽명을 써주세요",
            value: celebName,
            onChange: (e) => setCelebName(e.target.value)
        },
        {
            name: "work_name",
            head: "작품",
            placeholder: "출연예정인 작품명을 써주세요",
            value: workName,
            onChange: (e) => setWorkName(e.target.value)
        },
    ]

    const receiveMethods = ["방문", "퀵", "택배"]

    function betweenDay(firstDate, secondDate) {
        var firstDateObj = new Date(firstDate);
        var secondDateObj = new Date(secondDate);
        var betweenTime = Math.abs(secondDateObj.getTime() - firstDateObj.getTime());
        return Math.floor(betweenTime / (1000 * 60 * 60 * 24));
    }

    const SponSubmitClick = () => {
        if (!optIdList.join()) {
            alert("옵션을 선택해주세요.")
            return;
        }
        if (!celebName) {
            alert("셀럽명을 입력해주세요")
            return;
        }
        if (!workName) {
            alert("작품명을 입력해주세요")
            return;
        }
        if (!startDate || !endDate) {
            alert("날짜를 등록해주세요")
            return;
        }
        const differDates = (betweenDay(startDate, endDate) ?? 1) + 1;
        const formData = new FormData();

        const payOptArr = optIdList.join()
        formData.append("opt_text", payOptArr);
        formData.append("purchase_prd_id", prd_id);
        formData.append("dress_cut", agree ? 1 : 0);
        formData.append("price", differDates * (current?.product?.prd_days_price ?? 1))

        formData.append("receive_type", deliveryType);
        formData.append("company_name", stylistCompany);
        formData.append("start_date", startDate);
        formData.append("return_date", endDate);
        formData.append("celevrity_name", celebName);
        formData.append("work_name", workName);

        formData.append("total_price", totalPrice)
        formData.append("day_count", totalDays)

        serverController.connectFetchController(`purchase`, "POST", formData, function (res) {
            console.log(res)
            if (res.result == 1) {
                alert('협찬 신청 성공하셨습니다 :D');
                getDetailData();
                setIsSponModal(false);
                modal.modalClosed();
            }
        });
    }

    return (
        <>
            <ModalContainer1 onClick={() => { setIsSponModal(false); modal.modalClosed(); }}></ModalContainer1>
            <QuestModalContents1>
                <QuestModalHeadings1>협찬 신청</QuestModalHeadings1>
                <QuestCloseButton1 onClick={() => { setIsSponModal(false); modal.modalClosed(); }} />

                <RequestFormWrapper>
                    <DetailSetOption
                        product={product}
                        productInfo={[product]}
                        checkSetProductValidation={checkSetProductValidation}
                        optionList={optionList}
                        setOptIdList={setOptIdList}
                    />

                    <OptionListWrap>
                        {modalOptionList()}
                    </OptionListWrap>

                    <RequestForm onSubmit={SponSubmitClick}>
                        {
                            requestData.map((v, i) => {
                                const obj = {
                                    ...(v?.value && { value: v.value }),
                                    ...(v?.onChange && { onChange: v.onChange }),
                                }
                                return (
                                    <RequstLabel name={v?.name} key={i}>
                                        <span>{v?.head}</span>
                                        <input name={v?.name} placeholder={v?.placeholder} required readOnly={v?.head === '담당자(Stylist)' || v?.head === '업체명'} {...obj} />
                                    </RequstLabel>
                                )
                            })
                        }

                        <RequestCalendar
                            current={current}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            totalPrice={totalPrice}
                            setTotalPrice={setTotalPrice}
                            totalDays={totalDays}
                            setTotalDays={setTotalDays}
                        />

                        <RequstLabel name="receive_method">
                            <RequestTitle>수령 방법</RequestTitle>
                            <RequestSelect name="receive_method" onChange={(e) => { setDeliveryType(e.target.value) }}>
                                {
                                    receiveMethods.map((v, index) => <option key={index} value={index}>{v}</option>)
                                }
                            </RequestSelect>
                            {/* <input name="receive_method" placeholder={v?.placeholder} required/> */}
                        </RequstLabel>

                        <RequestAgree>
                            <label name="spon_agree"  >
                                <input type="checkbox" name="spon_agree" checked={agree} onClick={() => setAgree(prev => !prev)} />
                                <div>
                                    <strong>착장컷 등록 동의</strong>
                                    <p>셀럽의 착장컷을 왈라와 공유해주시면 사용할 수 있는 포인트를 적립해 드립니다.</p>
                                </div>
                            </label>
                        </RequestAgree>

                        <RequestButtonBox>
                            <p>Brand에서 신청 내용을 확인 후 승인 절차가 진행 됩니다</p>
                            <RequestButton type="submit" onClick={() => { SponSubmitClick() }}>신청완료</RequestButton>
                        </RequestButtonBox>
                    </RequestForm>
                </RequestFormWrapper>
            </QuestModalContents1>
        </>
    )
}

export default SponRequestModal


const ModalContainer1 = styled(ModalContainer)`
    ${MQ(mquery)} {
        ${Padding2(0, 25, mobil)}   
    }
`
const QuestModalContents1 = styled(QuestModalContents)`
    position: fixed; left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    z-index: 500;
    ${MQ(mquery)} {
        width: calc(100vw*(400/428));
        margin: 0;
        
    }
`
const QuestModalHeadings1 = styled(QuestModalHeadings)`

`
const QuestCloseButton1 = styled(QuestCloseButton)`
    background-repeat: no-repeat;
    background-size: 100%;

    ${MQ(mquery)} {
        ${Width(21, mobil)}   
        ${Height(21, mobil)}   
    }
`
const OptionListWrap = styled.div`
    width: 100%;
    padding: calc(100vw*(16/1920)) 0;

    @media screen and (max-width: 1024px) {
    padding: calc(100vw*(16/414)) 0;
    }
`