import React from 'react';

import { useHistory } from 'react-router-dom';
import styled, {css} from 'styled-components';

export const DescTitleAndText = ({title, text})=>{
    let history = useHistory();
    return(
        <Wrapper>
            <DescContainer>
                <DescWrap type={'text'}>
                    <TitleWrap>
                        <BackBtn onClick={()=>{history.goBack();}}/>
                        <Title>{title}</Title>
                    </TitleWrap>
                    <Text>{text}</Text>
                </DescWrap>
            </DescContainer>
        </Wrapper>
    )
}

const BackBtn = styled.button`
    padding:5px; border-top:3px solid #D3D3D3; border-left:3px solid #D3D3D3; width:14px; height:14px; display:block; transform:rotate(-45deg); margin-left:2px; margin-right:24px; 
    @media screen and (max-width:1024px){
        border-top:3px solid #D3D3D3; border-left:3px solid #D3D3D3;
    }
`;

const Wrapper = styled.div`
    position:relative;
    margin:0 auto;
    width: calc(100vw * (1300 / 1920));
    margin-top:calc(100vw * (76 / 1920));

    @media screen and (max-width:768px){
        width:100%; padding-left:calc(100vw * (25 / 428)); padding-right:calc(100vw * (25 / 428));
        padding-top:36px;
    }
`;

const DescContainer = styled.div``;
const DescWrap = styled.div`
    ${props=> props.type === 'text' && css`
        ${Text}{
            margin-left:40px;
        }`
    };
`;
const TitleWrap = styled.div`
    display:flex;
`;
const Title = styled.p`
    font-size:20px; font-weight:900; line-height:22px; vertical-align:center;
    margin-bottom:13px;
    
`;
const Text = styled.p`
    font-size:14px; color:#A5A5A5;
    & p {
        font-size:14px; color:#A5A5A5; line-height:24px;
    }
    & p br { 
        @media screen and (max-width:768px){
            display:none;
        }
     }
`;
