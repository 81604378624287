import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../../component/common/Header";
import SignUpMain from "../../component/login/SignUpMain";

export default function SignUpPage() {
  const { isLogged } = useSelector((state) => state.userData);
  const history = useHistory();
  useEffect(() => {
    if (isLogged) {
      alert("잘못된 접근입니다.");
      history.push(`/store`);
    }
  }, []);

  return (
    <>
      <Header back />
      <SignUpMain />
    </>
  );
}
