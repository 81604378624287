import styled, { css } from "styled-components";
import {
  pc,
  mobil,
  Media,
  Px,
  Width,
  Height,
  Top,
  Bottom,
  Left,
  Right,
  FontSize,
  Margin,
  Margin2,
  Padding,
  Padding2,
  MT,
  MB,
  ML,
  MR,
  PT,
  PB,
  PL,
  PR,
  LineH,
  mquery,
} from "../common/mediaStlye";

export const MoreButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: calc(100vw * (188 / 1920));
  height: calc(100vw * (51 / 1920));
  font-size: calc(100vw * (18 / 1920));
  font-weight: 900;
  border: 2px solid #222;
  border-radius: calc(100vw * (37 / 1920));

  @media ${(props) => props.theme.container} {
    ${[Width(163, mobil), Height(44, mobil), FontSize(16, mobil)].join("")}
    line-height: 0;
    border-radius: ${Px(37, mobil)};
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 0 calc(100vw * (150 / 1920));

  @media ${(props) => props.theme.container} {
    padding-top: calc(100vw * (49 / 428));
  }
`;

export const LayOut = styled.div`
  width: calc(100vw * (1316 / 1920));

  @media ${(props) => props.theme.container} {
    width: 100%;
    ${Padding2(0, 0, mobil)}
  }
`;
export const ContentHeading = styled.h2`
  font-size: calc(100vw * (35 / 1920));
  font-weight: bold;
  color: #222;
  text-align: center;
  @media ${(props) => props.theme.container} {
    ${FontSize(19, mobil)}
  }
`;

// ContentDetailsMain
export const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const DetailMainBanner = styled.div`
  width: calc(100vw * (539 / 1920));
  height: calc(100vw * (772 / 1920));
  & div {
    width: 100%;
    height: 100%;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${(props) => props.theme.container} {
    width: 100%;
    ${Height(300, mobil)}
    ${MB(7, mobil)}
  }
`;

export const DetailMainContents = styled.div`
  flex: 1;
  padding-left: calc(100vw * (16 / 1920));

  @media ${(props) => props.theme.container} {
    padding-left: 0;
  }
`;

export const DetailMainDesc = styled.div`
  padding: 0 calc(100vw * (28 / 1920));
  margin-bottom: calc(100vw * (68 / 1920));

  @media ${(props) => props.theme.container} {
    padding: calc(100vw * (15 / 428)) calc(100vw * (25 / 428)) 0;
  }
`;

export const DescHead = styled.div`
  position: relative;
  padding-bottom: calc(100vw * (8.5 / 1920));
  margin-bottom: calc(100vw * (19.5 / 1920));
  border-bottom: 1px solid #d9d9d9;

  & p {
    font-size: calc(100vw * (14 / 1920));
    font-weight: 400;
    color: #a5a5a5;
  }

  & h2 {
    font-size: calc(100vw * (22 / 1920));
    color: #222;
    font-weight: 900;
  }

  & .like-button {
    ${Width(18, pc)}
  }

  & .like-button img {
    width: 100%;
  }

  @media ${(props) => props.theme.container} {
    ${PB(8.5, mobil)}

    & p {
      ${FontSize(14, mobil)}
    }
    & h2 {
      ${FontSize(20, mobil)}
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const LikeButton = styled.div`
  position: absolute;
  right: 0;
  bottom: calc(100vw * (13.5 / 1920));
  width: calc(100vw * (18 / 1920));
  cursor: pointer;

  @media ${(props) => props.theme.container} {
    ${Width(20, mobil)}
    bottom:0;
    position: relative;

    & > img {
      width: 100%;
    }
  }
`;

export const DescContent = styled.div`
  & p {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 14;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* padding-right: calc(100vw * (50 / 1920)); */
  }

  & ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  @media ${(props) => props.theme.container} {
    ${MB(150, mobil)}
    padding: 0 calc(100vw * (25 / 428));

    & p {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      text-align: left;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
    /* & p br + br {display: none;} */
  }
`;

export const MobileTagList = styled.ul`
  width: 100%;
  ${Margin2(17, 0, mobil)}

  @media ${(props) => props.theme.container} {
    & .swiper-slide {
      /* flex-shrink: 1; */
      width: auto;
    }
  }
`;

export const Tag = styled.li`
  margin-right: calc(100vw * (8 / 1920));
  margin-bottom: calc(100vw * (10 / 1920));
  padding: calc(100vw * (5 / 1920)) calc(100vw * (19 / 1920));
  font-size: calc(100vw * (14 / 1920));
  background-color: #f2f2f2;
  border-radius: calc(100vw * (41 / 1920));
  color: #000;

  @media ${(props) => props.theme.container} {
    margin: 0;
    ${[
      FontSize(13, mobil),
      //    MR(7, mobil),
      Padding2(5, 17, mobil),
    ].join("")}
    max-height: ${Px(28, mobil)};
    white-space: nowrap;
    display: inline-block;
    width: auto;
    line-height: 1;
  }
`;

export const DetailMainGallery = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: ${Px(15, pc)};
  align-items: center;
  width: calc(100vw * (596 / 1920));

  & > div {
    width: calc(100vw * (180 / 1920));
    height: calc(100vw * (220 / 1920));
    cursor: pointer;
  }

  & > div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${(props) => props.theme.container} {
    width: 100%;
    gap: ${Px(7, mobil)};

    & > div {
      flex: 1;
      ${Height(185, mobil)}
      ${MB(20, mobil)}
    }
  }
`;

export const FashionsContainer = styled.div`
  padding: calc(100vw * (70 / 1920)) 0 calc(100vw * (50 / 1920));
  display: flex;
  justify-content: flex-start;
  gap: ${Px(15, pc)};
  align-items: center;
  flex-wrap: wrap;
`;

export const FashionItem = styled.div``;
export const Item = styled.div`
  width: calc(100vw * (428 / 1920));
  height: calc(100vw * (413 / 1920));
  /* margin-bottom: calc(100vw * (20 / 1920)); */
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &:hover .item_head {
    opacity: 1;
  }

  @media ${(props) => props.theme.container} {
    width: 100%;
    height: auto;
    ${[MB(0, mobil)].join("")}
    & .item_head {
      opacity: 1;
    }
  }
`;

export const ItemImg = styled.div`
  width: 100%;
  height: 100%;
  line-height: 0;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media ${(props) => props.theme.container} {
    width: 100%;
    height: calc(100vw * (300 / 428));
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const ItemDesc = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: calc(100vw * (22 / 1920));
  padding-left: calc(100vw * (24 / 1920));
  padding: calc(100vw * (25 / 1920)) calc(100vw * (52 / 1920))
    calc(100vw * (25 / 1920)) calc(100vw * (30 / 1920));
  height: calc(100vw * (201 / 1920));
  background-color: rgba(34, 34, 34, 0.9);
  opacity: 0;
  transition: all 0.3s ease;

  & > em {
    display: block;
    font-size: calc(100vw * (13 / 1920));
    color: #fff200;
    padding-bottom: calc(100vw * (11 / 1920));
  }

  & > h4 {
    font-size: calc(100vw * (25 / 1920));
    font-weight: 900;
    padding-bottom: calc(100vw * (19 / 1920));
    color: #fff;
  }

  & > p {
    font-size: calc(100vw * (14 / 1920));
    padding-right: calc(100vw * (30 / 1920));
    /* line-height: 1.5; */
    color: #fff;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media ${(props) => props.theme.container} {
    position: static;
    ${[MB(0, mobil), Height(100, mobil), Padding(20, 30, 20, 30, mobil)].join(
      ""
    )}

    & > em {
      ${[FontSize(13, mobil), PB(4.5, mobil)].join("")}
    }
    & > h4 {
      ${[FontSize(15, mobil), PB(10, mobil)].join("")}
    }
    & > p {
      ${[FontSize(13, mobil), PR(0, mobil)].join("")}
      -webkit-line-clamp: 1;
    }
  }
`;

// ContentStylingProducts
export const StylingProductsLayout = styled(LayOut)`
  position: relative;
  width: calc(100vw * (1126 / 1920));

  @media ${(props) => props.theme.container} {
    width: 100%;
    ${Padding2(0, 0, mobil)}
  }
`;
export const StylingProductsHeadings = styled(ContentHeading)`
  margin-bottom: calc(100vw * (36 / 1920));
  font-size: calc(100vw * (22 / 1920));
  color: #222;
  text-align: left;
  font-weight: 900;
  text-transform: uppercase;

  @media ${(props) => props.theme.container} {
    ${FontSize(18, mobil)}
    text-align: center;
    padding: 0 calc(100vw * (25 / 428));
    margin-bottom: calc(100vw * (40 / 428));
  }
`;
export const ProductsSlider = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & .swiper-container {
    width: 100%;
  }
  @media ${(props) => props.theme.container} {
    display: block;
  }
`;

export const ProductsSlide = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  @media ${(props) => props.theme.container} {
    width: 100%;
    height: auto;
  }
`;

export const ProductItemDesc = styled(ItemDesc)`
  padding: calc(100vw * (32 / 1920)) calc(100vw * (43 / 1920))
    calc(100vw * (16 / 1920)) calc(100vw * (29 / 1920));
  width: 100%;
  height: calc(100vw * (123 / 1920));
  background-color: rgba(255, 242, 0, 0.53);

  & > em {
    display: block;
    font-size: calc(100vw * (10 / 1920));
    color: #222;
    padding-bottom: calc(100vw * (5.2 / 1920));
  }

  & > h4 {
    font-size: calc(100vw * (15 / 1920));
    font-weight: 400;
    padding-bottom: calc(100vw * (15 / 1920));
    color: #606060;
  }

  & > span {
    display: block;
    text-align: right;
  }

  @media ${(props) => props.theme.container} {
    position: static;
    height: calc(100vw * (135 / 428));
    overflow: hidden;
    ${Padding(20, 30, 20, 30, mobil)}

    & > em {
      ${FontSize(13, mobil)}
      ${PB(2, mobil)}
    }
    & > h4 {
      ${FontSize(16, mobil)}
      ${PB(10.2, mobil)}
    }
    & > span {
      ${FontSize(16, mobil)}
      position : absolute;
      right: calc(100vw * (30 / 428));
      bottom: calc(100vw * (20 / 428));
    }
  }
`;
export const ProductItem = styled(Item)`
  position: relative;
  width: 100%;
  height: calc(100vw * (366 / 1920));
  background-color: #ebebeb;

  @media ${(props) => props.theme.container} {
    width: 100%;
    height: 100%;
    /* ${[Width(342, mobil), Height(342, mobil)].join("")} */
  }
`;

export const ProductLikeButton = styled(LikeButton)`
  bottom: auto;
  top: calc(100vw * (20 / 1920));
  right: calc(100vw * (20 / 1920));
  ${Width(20, pc)}

  & > img {
    width: 100%;
  }

  @media ${(props) => props.theme.container} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100vw * (35 / 375));
    height: calc(100vw * (35 / 375));
    ${[Top(10, mobil), Right(10, mobil)].join("")}

    & img {
      ${Width(20, mobil)}
    }
  }
`;
// export const ProductItem = styled(Item)``
// export const ProductItem = styled(Item)``
// export const ProductItem = styled(Item)``
