//react
import React ,{ useState,useEffect,useRef } from 'react';
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery"

//css
import styled from "styled-components"

//img
import EventImg1 from "../../../images/category/category-prod-1.png"
import EventImg2 from "../../../images/category/category-prod-2.png"
import EventImg3 from "../../../images/category/category-prod-3.png"
import EventImg4 from "../../../images/category/category-prod-4.png"
import EventImg5 from "../../../images/category/category-prod-5.png"
import EventImg6 from "../../../images/category/category-prod-6.png"
import EventImg7 from "../../../images/content/content-detail-prod1.png"
import EventImg8 from "../../../images/content/content-detail-prod2.png"
import EventImg9 from "../../../images/content/content-detail-prod3.png"
import EventImg10 from "../../../images/category/category-idol-prod3.png"


export default function EventList() {
    const eventList = [
        {
            src : EventImg1,
            title: "FENDI에 관한 제품이 가득!",
            text: "WALA 스토어에서 만나보세요.",
            bg_color: "AC90DD",
            color: "fff",
            state: "진행중",
            date: "21/06/07 ~ 21/07/04"
        },
        {
            src : EventImg2,
            title: "FENDI에 관한 제품이 가득!",
            text: "WALA 스토어에서 만나보세요.",
            bg_color: "7BC27F",
            color: "fff",
            state: "진행중",
            date: "21/06/07 ~ 21/07/04"
        },
        {
            src : EventImg3,
            title: "FENDI에 관한 제품이 가득!",
            text: "WALA 스토어에서 만나보세요.",
            bg_color: "FDE1E8",
            color: "20232B",
            state: "진행중",
            date: "21/06/07 ~ 21/07/04"
        },
        {
            src : EventImg4,
            title: "FENDI에 관한 제품이 가득!",
            text: "WALA 스토어에서 만나보세요.",
            bg_color: "EFEFF1",
            color: "20232B",
            state: "진행중",
            date: "21/06/07 ~ 21/07/04"
        },
        {
            src : EventImg5,
            title: "FENDI에 관한 제품이 가득!",
            text: "WALA 스토어에서 만나보세요.",
            bg_color: "E38361",
            color: "fff",
            state: "진행중",
            date: "21/06/07 ~ 21/07/04"
        },
        {
            src : EventImg6,
            title: "FENDI에 관한 제품이 가득!",
            text: "WALA 스토어에서 만나보세요.",
            bg_color: "517A76",
            color: "fff",
            state: "진행중",
            date: "21/06/07 ~ 21/07/04"
        },
        {
            src : EventImg7,
            title: "FENDI에 관한 제품이 가득!",
            text: "WALA 스토어에서 만나보세요.",
            bg_color: "DEDBCB",
            color: "fff",
            state: "진행중",
            date: "21/06/07 ~ 21/07/04"
        },
        {
            src : EventImg8,
            title: "FENDI에 관한 제품이 가득!",
            text: "WALA 스토어에서 만나보세요.",
            bg_color: "FDC423",
            color: "fff",
            state: "진행중",
            date: "21/06/07 ~ 21/07/04"
        },
        {
            src : EventImg9,
            title: "FENDI에 관한 제품이 가득!",
            text: "WALA 스토어에서 만나보세요.",
            bg_color: "000",
            color: "fff",
            state: "진행중",
            date: "21/06/07 ~ 21/07/04"
        },
        {
            src : EventImg10,
            title: "FENDI에 관한 제품이 가득!",
            text: "WALA 스토어에서 만나보세요.",
            bg_color: "000",
            color: "fff",
            state: "진행중",
            date: "21/06/07 ~ 21/07/04"
        },
    ]

  return (
    <Container>
            <EventWrap>
                <EventListWrap>
                    {
                        eventList.map((value,index)=>{
                            if(index > 9){return}
                            return (
                                <EventListBox key={"event list" + index} >
                                    <EventListTop style={{background:`#${value.bg_color}`}}>
                                        <EventTextWrap>
                                            <EventTitle style={{color:`#${value.color}`}}>{value.title}</EventTitle>
                                            <EventText style={{color:`#${value.color}`}}>{value.text}</EventText>
                                        </EventTextWrap>
                                        <EventImgWrap>
                                            <EventImg src={value.src} alt="event image" />
                                        </EventImgWrap>
                                    </EventListTop>
                                    <EventListBt>
                                        <EventState>{value.state}</EventState>
                                        <EventDate>{value.date}</EventDate>
                                    </EventListBt>
                                </EventListBox>
                            )
                        })
                    }
                </EventListWrap>
                <MoreBtnWrap>
                    <MoreBtn>더보기 +</MoreBtn>
                </MoreBtnWrap>
            </EventWrap>
    </Container>
  );
}
const Container = styled.div`
    width: 100%;
    padding-top: 20px;
    margin-bottom: 180px;

    @media screen and (max-width: 1320px) {
        padding-top: calc(100vw*(20/1320));
        margin-bottom: calc(100vw*(180/1320));
    }
    
    @media screen and (max-width: 930px) {
        padding-top: calc(100vw*(40/428));
        margin-bottom: calc(100vw*(100/428));
    }
`
const EventWrap = styled.div`
    width: 1300px;
    margin: 0 auto;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(1250/1320));
    }

    @media screen and (max-width: 930px) {
        width: calc(100vw*(388/428));
    }
`
//list
const EventListWrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 60px;

    @media screen and (max-width: 1320px) {
        padding-top: calc(100vw*(60/1320));
    }

    @media screen and (max-width: 930px) {
        padding-top: 0;
    }
`
const EventListBox = styled.div`
    width: calc(100%/2 - 10px);
    margin-bottom: 35px;
    &:nth-child(2n){margin-left: 20px;}

    @media screen and (max-width: 1320px) {
        width: calc(100%/2 - calc(100vw*(10/1320)));
        margin-bottom: calc(100vw*(35/1320));
        &:nth-child(2n){margin-left: calc(100vw*(20/1320));}
    }

    @media screen and (max-width: 930px) {
        width: 100%;
        margin-bottom: calc(100vw*(45/428));
        &:nth-child(2n){margin-left: 0;}
    }
`
const EventListTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; height: 240px;
    
    overflow: hidden;
    cursor: pointer;

    @media screen and (max-width: 1320px) {
        height: calc(100vw*(240/1320));
        
    }

    @media screen and (max-width: 930px) {
        height: calc(100vw*(156/428));
        
    }
`
const EventTextWrap = styled.div`
    width: calc(100% - 200px);
    padding: 0 20px;

    @media screen and (max-width: 1320px) {
        width: calc(100% - calc(100vw*(200/1320)));
        padding: 0 calc(100vw*(20/1320));
    }

    @media screen and (max-width: 930px) {
        width: calc(100% - calc(100vw*(100/428)));
        padding: 0 calc(100vw*(22/428));
    }
`
const EventTitle = styled.p`
    font-size: 16px;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(16/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(14/428));
    }
`
const EventText = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-top: 6px;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(20/1320));
        margin-top: calc(100vw*(6/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(18/428));
        margin-top: calc(100vw*(8/428));
    }
`
const EventImgWrap = styled.div`
    width: 200px; height: 100%;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(200/1320));
    }
    @media screen and (max-width: 930px) {
        width: calc(100vw*(100/428));
    }
`
const EventImg = styled.img`
    width: 100%; height: 100%;
    object-fit: cover;
`
const EventListBt = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    @media screen and (max-width: 1320px) {
        padding: calc(100vw*(10/1320));
    }
    @media screen and (max-width: 930px) {
        padding: calc(100vw*(14/428)) 0;
    }
`
const EventState = styled.p`
    font-size: 18px;
    color: #000;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(18/1320));
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(18/428));
    }
`
const EventDate = styled.p`
    font-size: 14px;
    color: #646464;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(14/1320));
    }
    
    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(16/428));
    }
`
//more btn
const MoreBtnWrap = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 100px;

    @media screen and (max-width: 1320px) {
        margin-top: calc(100vw*(100/1320));
    }

    @media screen and (max-width: 930px) {
        margin-top: calc(100vw*(30/428));
    }
`
const MoreBtn = styled.p`
    font-size: 15px;
    padding: 12px 40px;
    border: 1px solid #646464;
    color: #646464;
    cursor: pointer;
    &:hover {background: #646464; color: #fff;}

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(15/1320));
        padding: calc(100vw*(12/1320)) calc(100vw*(40/1320));
        border: calc(100vw*(1/1320)) solid #646464;
    }

    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(16/428));
        padding: calc(100vw*(12/428)) calc(100vw*(40/428));
        border: calc(100vw*(1/428)) solid #646464;
    }
`