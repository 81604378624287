import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import styled, { css } from "styled-components";

// serverController
import serverController from "../../../server/serverController";

// common
import { numberWithCommas } from "../../../../components/common/commonUse";

// img
import IconChkOn from "../../../img/icon/icon_chk_on.svg";
import IconChkOff from "../../../img/icon/icon_chk_off.svg";
import { imgURL } from "../../../server/appInfoController";
import DefaultImg from "../../../img/icon/loading_default_img.svg";
import { PaymentAction } from "../../../../store/actionCreators";

export default function CartProduct({
  value,
  setList,
  brand,
  checks,
  setChecks,
  setSelectList,
  getCarts,
  allFinalPrice,
  allList,
  setAllList,
}) {
  // for productBox items
  const productBoxItems = {
    getCarts,
    setChecks,
    // setTotalPrice,
    setList,
    setSelectList,
    checks,
    allFinalPrice,
    allList,
    setAllList,
  };

  return (
    <ProductList>
      <ProductListInner>
        <Brand>{brand}</Brand>
        <ProductBoxWrap>
          {value?.length > 0 &&
            value?.map((item, idx) => {
              return <ProductBox item={item} {...productBoxItems} key={item.cart_id} />;
            })}
        </ProductBoxWrap>
      </ProductListInner>
      {/* <ProductTotal>
        상품 {numberWithCommas(totalPrice)}원 + 배송비 2,500원 ={" "}
        {numberWithCommas(totalPrice + 2500)}원
      </ProductTotal> */}
    </ProductList>
  );
}

function ProductBox(props) {
  const history = useHistory();
  const {
    item,
    getCarts,
    setChecks,
    // setTotalPrice,
    setList,
    setSelectList,
    checks,
    allFinalPrice,
    allList,
    setAllList,
  } = props;
  const cartInfo = item?.cart_item;

  const [count, setCount] = useState(1);
  const [price, setPrice] = useState(0);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (allList.length !== 0) {
      allList.forEach((el, idx) => {
        el.cart_id === item.cart_id && setIndex(idx);
      });
    }
  }, [allList]);

  const handleChecks = (e) => {
    e.preventDefault();
    setChecks((prev) => {
      const newArr = [...prev];
      newArr[index] = !prev[index];
      return newArr;
    });
  };

  const cartCountUpdate = (data, id) => {
    const formData = new FormData();
    formData.append("cart_item", JSON.stringify(data));
    formData.append("cart_id", id);
    serverController.connectFetchController(`cart/update`, "POST", formData, (res) => {
      if (res?.result === 1) {
      }
    });
  };

  const handleCount = (isPlus) => {
    const next = isPlus ? count + 1 : count - 1;
    setCount((prev) => {
      cartInfo.data[0].count = isPlus ? prev + 1 : prev - 1;
      if (next <= 0) {
        return 1;
      } else {
        setAllList((prev) => {
          const newArr = [...prev];
          newArr[index].cart_item.data[0].count = next;
          return newArr
        })

        setChecks((prev) => {
          const newArr = [...prev];
          newArr[index] = true;
          return newArr;
        });
        return next;
      }
    });
    cartCountUpdate(cartInfo, item.cart_id);
  };

  // delete single product
  const deleteSingleProduct = () => {
    serverController.connectFetchController(
      `cart/delete?cart_id=${item.cart_id}`,
      "POST",
      null,
      (res) => {
        if (res?.result === 1) {
          PaymentAction.resetData();
          getCarts();
        }
      },
      (err) => { }
    );
  };

  useEffect(() => {
    if (cartInfo) {
      const Productprice = cartInfo?.prd_list_price ?? 0;
      let optPrice = 0;

      if (cartInfo?.optionList) {
        for (let cart of Object.entries(cartInfo?.optionList)) {
          optPrice += cart[1][0]?.opt_price ?? 0;
        }
      }

      cartInfo.data.map((items) => {
        let opArr = [];
        for (let i in items.options.optionList) {
          opArr.push(items.options.optionList[i]);
        }
        return setCount(items.count === 0 ? 1 : items.count);
      });

      const totalP = (Productprice + optPrice) * count;
      setPrice(Productprice + optPrice ?? 0);
      if (checks[index]) {
        // setTotalPrice(totalP);
      }
      // setDeliveryPrice(cartInfo?.br_shipping_charge ?? 0);
    }
  }, [cartInfo, checks]);

  useEffect(() => {
    setList((prev) => {
      const newArr = [...prev];

      const changedData = newArr.map((v, i) => {
        if (i === index && v?.cart_item) {
          v.cart_item.data[0].count = count;
        }
        return v;
      });
      return changedData;
    });

    setSelectList((prev) => {
      if (prev.some((el) => el.cart_id === item?.cart_id)) {
        const index = prev.findIndex((obj) => obj.cart_id === item.cart_id);
        const newArr = [...prev];
        newArr[index].cart_item.data[0].count = count;
        return newArr;
      } else {
        return prev;
      }
    });
  }, [count, checks]);

  // control final price
  useEffect(() => {
    if (checks[index]) {
      allFinalPrice[index] = price * count;
    } else {
      allFinalPrice[index] = 0;
    }
  }, [count, checks]);

  return (
    <ProductBoxArea>
      <ProductBoxList key={item?.prd_id}>
        <Chk>
          <ChkInput type="checkbox" id={"chk-" + index} defaultChecked={checks[index]} />
          <ChkLabel htmlFor={"chk-" + index} onClick={handleChecks}>
            <ChkImg className="chk-img" chkState={checks[index]} />
          </ChkLabel>
        </Chk>
        <Info>
          <ImgBox
            onClick={() => {
              history.push(`/store/detail/${item?.prd_id}`);
            }}
          >
            <img
              src={item?.prd_thumbnail ? `${imgURL}${item?.prd_thumbnail}` : DefaultImg}
              alt="product-img"
            />
          </ImgBox>
          <TextBox>
            <ProductInfo>
              <ProductName
                onClick={() => {
                  history.push(`/store/detail/${item?.prd_id}`);
                }}
              >
                {cartInfo?.prd_title}
              </ProductName>
              {cartInfo?.data?.length > 0 &&
                cartInfo?.data?.map((option) => {
                  let optionArr = [];
                  for (let i in option?.options.optionList) {
                    optionArr.push(option.options.optionList[i]);
                  }
                  return (
                    <React.Fragment key={cartInfo?.prd_id}>
                      {optionArr?.length > 0 &&
                        optionArr[0]?.map((opt) => {
                          return (
                            <ProductColor key={opt?.opt_name}>{opt?.opt_title + ": " + opt?.opt_name}</ProductColor>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
              <PriceWrap>
                <PriceInner>
                  <Price>{numberWithCommas(cartInfo?.prd_list_price * 1)}원</Price>
                </PriceInner>
              </PriceWrap>
            </ProductInfo>

            <Bottom>
              <CountWrap>
                <CountMinus
                  type="button"
                  onClick={() => {
                    handleCount(false);
                  }}
                >
                  -
                </CountMinus>
                <CountText>{count}</CountText>
                <CountPlus
                  type="button"
                  onClick={() => {
                    handleCount(true);
                  }}
                >
                  +
                </CountPlus>
              </CountWrap>

              <PC>
                <PricePc>{numberWithCommas(cartInfo?.prd_list_price * 1)}</PricePc>
              </PC>

              <ProductDeleteButton type="button" onClick={deleteSingleProduct}>
                삭제
              </ProductDeleteButton>
            </Bottom>
          </TextBox>
        </Info>
      </ProductBoxList>
    </ProductBoxArea>
  );
}

const ProductList = styled.li`
  width: 1240px;
  margin: 0 auto;

  background-color: #fff;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 428));
    }
  }
`;
const ProductListInner = styled.div`
  width: 1190px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1190 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;
const Brand = styled.p`
  width: 1156px;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  padding: 17px 0 8px;
  margin-left: auto;
  border-bottom: 1px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1156 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (15 / 428)) 0 calc(100vw * (10 / 428));
    margin-left: 0;
  }
`;
const ProductBoxWrap = styled.div`
  padding: 15px 0 30px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (15 / 1320)) 0 calc(100vw * (30 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (15 / 428)) 0 calc(100vw * (25 / 428));
  }
`;
const ProductBoxArea = styled.ul`
  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 1156px;
    height: 1px;
    background-color: #eee;
    margin: 20px 0 20px auto;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      width: calc(100vw * (1156 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (35 / 428));
    }
    &:not(:last-child)::after {
      display: none;
    }
  }
`;
const ProductBoxList = styled.li`
  display: flex;
  justify-content: space-between;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 1156px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1156 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (344 / 428));
  }
`;
const ImgBox = styled.div`
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 1px solid #eee;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    height: calc(100vw * (100 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (100 / 428));
    height: calc(100vw * (100 / 428));
  }
`;
const TextBox = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 1044px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1044 / 1320));
  }

  @media screen and (max-width: 930px) {
    display: block;
    width: calc(100vw * (232 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProductColor = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 15px;
  margin-top: 4px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (4 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (4 / 428));
  }
`;
const ProductInfo = styled.div`
  width: 444px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (444 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
const PriceWrap = styled.div``;
const PriceOriginal = styled.p`
  color: #777;
  font-weight: 500;
  text-decoration: line-through;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;
const PriceInner = styled.p`
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (6 / 428));
  }
`;
const SaleRate = styled.span`
  color: #333;
  font-weight: 700;
  border-radius: 3px;
  background: #fdf251;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    padding: calc(100vw * (2 / 428)) calc(100vw * (4.5 / 428));
    margin-right: calc(100vw * (5 / 428));
  }
`;
const Price = styled.span`
  color: #333;
  font-weight: 600;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;

const PricePc = styled.span`
  width: 224px;
  color: #333;
  font-weight: 700;
  font-size: 18px;
  text-align: right;
  padding-right: 20px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (224 / 1320));
    font-size: calc(100vw * (18 / 1320));
    padding-right: calc(100vw * (20 / 1320));
  }
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 425px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (425 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    margin-top: calc(100vw * (30 / 428));
  }
`;
const CountWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (130 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (130 / 428));
  }
`;
const CountMinus = styled.button`
  display: block;
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 35px;
  height: 35px;
  font-size: 20px;
  text-align: center;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (35 / 1320));
    height: calc(100vw * (35 / 1320));
    font-size: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (35 / 428));
    height: calc(100vw * (35 / 428));
    font-size: calc(100vw * (24 / 428));
    padding: 0;
  }
`;
const CountPlus = styled(CountMinus)``;
const CountText = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 17px;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (17 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (17 / 428));
  }
`;
const ProductDeleteButton = styled.button`
  width: 86px;
  height: 35px;
  color: #333;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  ${({ isDeleteButton }) => isDeleteButton && `background-color : #eeeeee;`}

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (86 / 1320));
    height: calc(100vw * (35 / 1320));
    font-size: calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (60 / 428));
    height: calc(100vw * (35 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: 0;
  }
`;
const ProductTotal = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 15px;
  padding: 10px 0 12px 59px;
  border-top: 1px solid #eee;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    padding: calc(100vw * (10 / 1320)) 0 calc(100vw * (12 / 1320)) calc(100vw * (59 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    text-align: right;
    padding: calc(100vw * (10 / 428)) calc(100vw * (25 / 428));
  }
`;

const Chk = styled.div``;
const ChkInput = styled.input`
  display: none;

  /* &:checked + label .chk-img {
    background: url(${IconChkOn}) no-repeat center / contain;
  } */
`;
const ChkLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const ChkImg = styled.span`
  width: 24px;
  height: 24px;
  background: ${({ chkState }) => chkState ? css`url(${IconChkOn}) no-repeat center / contain;` : css`url(${IconChkOff}) no-repeat center / contain;`};

  ${({ isDeleteButton }) => isDeleteButton && `background-color : #eeeeee;`}
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const ChkText = styled.span`
  color: #333;
  font-weight: 500;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (10 / 428));
  }
`;

const CartTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;

  @media screen and (max-width: 930px) {
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;
