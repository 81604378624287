import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import Complete from "../../component/common/complete/Complete";
import Footer from "../../component/common/StoreFooter";
import FooterMenu from "../../component/common/FooterMenu";
import FixMenu from "../../component/common/FixMenu";

export default function ReturnCompletePage() {
  return (
    <>
      <Header />
      <Complete
        title="주문이 취소 되었습니다."
        btn="홈으로 이동"
        url="/store"
        rank={false}
      />
    </>
  );
}
