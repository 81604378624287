//react
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";

//css
import styled from "styled-components";

//img
import IconSearch from "../../../img/icon/icon_notice_search.png";
import IconArrow from "../../../img/icon/icon_select_arrow.png";

export default function Search() {
  return (
    <Container>
      <SearchWrap>
        <SelectWrap>
          <SelectBox name="" id="">
            <SelectOption value="">제목+내용</SelectOption>
            <SelectOption value="">제목</SelectOption>
            <SelectOption value="">내용</SelectOption>
            <SelectOption value="">작성자</SelectOption>
          </SelectBox>
        </SelectWrap>
        <SearchInputWrap>
          <SearchInput type="text" placeholder="검색어 입력" />
          <SearchBtn />
        </SearchInputWrap>
      </SearchWrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const SearchWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (40 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (30 / 428));
  }
`;
const SelectWrap = styled.div`
  width: 150px;
  margin-right: 20px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (150 / 1320));
    margin-right: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (145 / 428));
    margin-right: calc(100vw * (15 / 428));
  }
`;
const SelectBox = styled.select`
  width: 100%;
  height: 48px;
  color: #969696;
  border: 1px solid #cbcbcb;
  border-radius: 11px;
  text-align: center;
  font-size: 16px;
  padding: 0 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(${IconArrow}) no-repeat center right 15px/10px;
  border-radius: 0;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (48 / 1320));
    border: calc(100vw * (1 / 1320)) solid #cbcbcb;
    border-radius: calc(100vw * (11 / 1320));
    font-size: calc(100vw * (16 / 1320));
    padding: 0 calc(100vw * (20 / 1320));
    background: url(${IconArrow}) no-repeat center right
      calc(100vw * (15 / 1320)) / calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (45 / 428));
    border: calc(100vw * (1 / 428)) solid #cbcbcb;
    border-radius: calc(100vw * (11 / 428));
    font-size: calc(100vw * (15 / 428));
    padding: 0 calc(100vw * (20 / 428));
    background: url(${IconArrow}) no-repeat center right
      calc(100vw * (15 / 428)) / calc(100vw * (10 / 428));
  }
`;
const SelectOption = styled.option`
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #969696;
  text-align: center;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const SearchInputWrap = styled.div`
  position: relative;
  width: 320px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (320 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (160 / 428)));
  }
`;
const SearchInput = styled.input`
  width: 100%;
  height: 48px;
  font-size: 15px;
  border: 1px solid #cbcbcb;
  border-radius: 11px;
  padding: 0 50px 0 20px;
  &::placeholder {
    color: #969696;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (48 / 1320));
    font-size: calc(100vw * (15 / 1320));
    border: calc(100vw * (1 / 1320)) solid #cbcbcb;
    border-radius: calc(100vw * (11 / 1320));
    padding: 0 calc(100vw * (50 / 1320)) 0 calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vw * (45 / 428));
    font-size: calc(100vw * (15 / 428));
    border: calc(100vw * (1 / 428)) solid #cbcbcb;
    border-radius: calc(100vw * (11 / 428));
    padding: 0 calc(100vw * (50 / 428)) 0 calc(100vw * (20 / 428));
  }
`;
const SearchBtn = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  width: 22px;
  height: 21px;
  margin-top: -11px;
  background: url(${IconSearch}) no-repeat center/22px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (15 / 1320));
    width: calc(100vw * (22 / 1320));
    height: calc(100vw * (21 / 1320));
    margin-top: calc(100vw * (-11 / 1320));
  }

  @media screen and (max-width: 930px) {
    right: calc(100vw * (15 / 428));
    width: calc(100vw * (22 / 428));
    height: calc(100vw * (21 / 428));
    margin-top: calc(100vw * (-11 / 428));
  }
`;
