import { useEffect, useState, useRef } from "react";
import serverController from "../../../server/serverController";
import styled from "styled-components";
import { imgURL } from "../../server/appInfoController";
import IconCancel from "../../img/icon/icon_myhaeder_cancle.svg";
import IconWishOn from "../../img/icon/icon_wish_on.svg";
import IconWishOff from "../../img/icon/icon_wish_off.svg";
import NewPagination from "../../component/common/pagination/NewPagination";
import ContentsReviewWrap from "./ContentsReviewWrap";
import ContentsYouTubeWrap from "./ContentsYoutubeWrap";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import MyPageDefaultModal from "../common/modal/MyPageDefaultModal";
import shareIcon from "../../../images/stylist/share-icon.png";
import ContentsShare from "./ContentsShare";
import qs from "qs"
import HtmlParser from 'react-html-parser';

export default function ContentsDetailMain({ clickContentsId, setContentsDetail, modalToListWish, wishiconRef }) {
  const history = useHistory();
  const location = useLocation();
  const { isLogged } = useSelector((state) => state.userData);
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const [contentsDetailStylingList, setContentsDetailStylingList] = useState([]);
  const [contentsItem, setContentsItem] = useState([]);
  const [contentsSubImgArr, setContentsSubImgArr] = useState([]);

  const [rightSectionFix, setRightSectionFix] = useState(false);

  const [touch, setTouch] = useState(false);
  const [isWish, setIsWish] = useState(false);


  const [video, setVideo] = useState();
  const [reviewData, setReviewData] = useState();

  const [loginPop, setLoginPop] = useState(false);
  const [sharePop, setSharePop] = useState(false);

  const getReviewData = async () => {
    try {
      const response = await axios.get(`https://localhost:8071/api/content/comment/${clickContentsId}`)
    } catch (error) {
    }
  };


  // 댓글 내용 저장 스테이트
  const [inquiryText, setInquiryText] = useState("");
  const [page, setPage] = useState(1);

  // 클릭한 게시글 불러오는 코드 
  const getContentsDetailInfo = () => {
    serverController.connectFetchController(`contents/${clickContentsId}`, "GET", null, (res) => {
      if (res?.result === 1) {
        setContentsDetailStylingList(res?.content?.products);
        setContentsSubImgArr(res?.content.contents_sub?.split(","));
        setContentsItem(res.content);
        setVideo(res?.content?.contents_video);
        res?.content?.like_count !== 0 ? setIsWish(true) : setIsWish(false);
        // getReviewData();
        // getContentsReview();
      }
    });
  };

  // 댓글정보 기존코드 방식으로 불러오는 코드
  const getContentsReview = () => {
    serverController.connectFetchController(`api/content/comment/${clickContentsId}?page=${page}`, "GET", null, (res) => {
      if (res?.result === 1) {
        // const reverseData = res.data.reverse();
        setReviewData(res?.data);
      }
    });
  };

  useEffect(() => {
    getContentsReview();
  }, [page]);

  // 댓글 불러오는 코드 즉시실행함수
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const response = await axios.get(`https://localhost:8071/api/content/comment/${clickContentsId}`)
  //       console.log('@@@@: ', response);
  //     } catch (error) {
  //       console.log('error: ', error);
  //     }

  //   })();
  // }, [clickContentsId]);

  const postLikeCount = (contentsId) => {
    serverController.connectFetchController(
      `contentslike/${contentsId}`,
      "POST",
      null,
      (res) => {
        setIsWish(!isWish);
      },
      (err) => { console.log(err) }
    );
  };


  const onHandleLike = (contentsId) => {
    if (!isLogged) return setLoginPop(true);
    postLikeCount(contentsId);
    wishiconRef.current[contentsItem?.contents_id].classList.toggle('active');
  }




  useEffect(() => {
    if (!clickContentsId) return alert("잘못된 접근입니다.");
    getContentsDetailInfo();
    getContentsReview();
  }, [clickContentsId]);

  // s :: right section fix control
  const handleHeaderScroll = () => {
    if (window.innerWidth > 930) {
      const cateWrapY =
        document.querySelector("#cate_wrap").offsetTop +
        document.querySelector("#cate_wrap").offsetHeight;
      setRightSectionFix(window.scrollY > cateWrapY);
    }
  };

  // useEffect(() => {
  //   const watch = () => {
  //     window.addEventListener("scroll", handleHeaderScroll);
  //   };
  //   watch();
  //   return () => {
  //     window.removeEventListener("scroll", handleHeaderScroll);
  //   };
  // });
  // e :: right section fix control

  const body = document.querySelector("body");
  function modalOpen() {
    body.style.height = "100vh";
  }
  function modalClosed() {
    body.style.height = "auto";
  }

  useEffect(() => {
    modalOpen()
    return () => {
      modalClosed();
    };

  }, []);

  return (
    <>
      {loginPop && (
        <MyPageDefaultModal
          text="로그인 후 이용 가능합니다."
          trueText="로그인"
          trueFunc={() => {
            if (location) return history.push(`/store/login?returnTo=/store`);
            history.push(`/store/login`);
          }}
          falseFunc={() => setLoginPop(false)}
        />
      )}
      <Container>
        <Background onClick={() => setContentsDetail(false)} />
        <ContentWrap>
          <HeaderSection>
            <HeaderInner>
              <CloseButton onClick={() => setContentsDetail(false)}>
                <img src={IconCancel} alt="cancel-icon" />
              </CloseButton>
            </HeaderInner>
          </HeaderSection>
          <Content>
            {/* {contentsLoading && <FullLoading />} */}
            <ContentsDetailWrap>
              {/* 상품 정보 */}
              <ContentsProductSection>
                <RightSectionWrap rightSectionFix={rightSectionFix}>
                  {/* 배너 및 정보 */}
                  <ContentsDetailBannerBox>
                    <ContentsDetailBannerImg>
                      <img
                        src={`${imgURL}${contentsItem?.contents_main}`}
                        alt="contentsMain-detail-data"
                      />
                      <ContentLike>
                        <WishIcon
                          src={isWish ? IconWishOn : IconWishOff}
                          alt="wish"
                          onClick={() => {
                            onHandleLike(clickContentsId);
                          }}
                        />
                      </ContentLike>
                    </ContentsDetailBannerImg>
                    <ContentsDetailTextBox>
                      <ContentsDetailDate>{contentsItem?.contents_date}</ContentsDetailDate>
                      <ContentsDetailTitle>{contentsItem?.contents_title}</ContentsDetailTitle>
                    </ContentsDetailTextBox>
                  </ContentsDetailBannerBox>
                  {/* 공유하기 */}
                  <ShareSection>
                    <ShareIconWrap>
                      <ShareIcon
                        src={shareIcon}
                        alt="shareIcon"
                        onClick={() => {
                          setSharePop(!sharePop);
                        }}
                      />
                    </ShareIconWrap>
                    {sharePop && <ContentsShare contentsDetailData={contentsItem} setSharePop={setSharePop} />}
                  </ShareSection>
                  {/* 태그 */}
                  {/* <ContentsHashTagBox>
              <ContentsHashTag># 원피스</ContentsHashTag>
              <ContentsHashTag># 원피스</ContentsHashTag>
              <ContentsHashTag># 원피스</ContentsHashTag>
            </ContentsHashTagBox> */}

                  {/* 리스트 */}
                  <ContentsProductBox>
                    {contentsDetailStylingList?.map((contentsStylingEl, index) => {
                      return (
                        <ContentsDetailStylingTopBox key={`contents-id-${contentsStylingEl.contents_id}-${index}`}>
                          <ContentsDetailStylingTopImgBox
                            onClick={() => window.open(`${contentsStylingEl.prd_link}`, "_blank")}
                          >
                            <img
                              src={`${imgURL}${contentsStylingEl.prd_main_img}`}
                              alt="contents-styling-list"
                            />
                          </ContentsDetailStylingTopImgBox>
                          <ContentsDetailStylingTopText>
                            {contentsStylingEl.prd_name}
                          </ContentsDetailStylingTopText>
                        </ContentsDetailStylingTopBox>
                      );
                    })}
                  </ContentsProductBox>
                </RightSectionWrap>
              </ContentsProductSection>

              {/* 상품 상세 */}
              <ContentsDetailSection>
                <ContentsDetailImgBox>
                  {/* 유튜브 동영상 추가 */}
                  {/* {video && <ContentsYouTubeWrap video={video} />} */}
                  {contentsSubImgArr?.map((img, index) => {
                    return (
                      <ContentsDetailImg
                        key={index + "contentImg"}
                        src={`${imgURL}${img}`}
                        alt="contentsSubImg"
                      />
                    );
                  })}
                  {contentsItem?.contents_content && <div>{HtmlParser(contentsItem.contents_content)}</div>}
                </ContentsDetailImgBox>

                {/* {contentsDetailStylingList?.length > 0 && (
                <ContentsDetailStylingBox>
                  <ContentsDetailStylingTitle>
                    STYLING PRODUCTS
                  </ContentsDetailStylingTitle>
                  <ContentsDetailProductArea>
                    {contentsDetailStylingList?.map(
                      (contentsStylingEl, index) => {
                        return (
                          <ContentsDetailProductBox
                            key={index + "prdBox"}
                            contentsStylingPrd={contentsStylingEl}
                          />
                        );
                      }
                    )}
                  </ContentsDetailProductArea>
                </ContentsDetailStylingBox>
              )} */}

                {/* 댓글자리 */}
                <ContentsReviewWrap clickContentsId={clickContentsId} reviewData={reviewData} setReviewData={setReviewData} getContentsReview={getContentsReview} page={page} setPage={setPage} />
              </ContentsDetailSection>
            </ContentsDetailWrap>
          </Content>
        </ContentWrap>
      </Container>
    </>
  );
}


const Container = styled.div`
  width: 100%;
`;
const Content = styled.div`
  width: 100%;
  height: calc(100% - 60px);

  @media screen and (max-width: 1320px) {
    height: calc(100% - calc(100vw * (60 / 1320)));
  }
  @media screen and (max-width: 930px) {
    height: auto;
    padding-bottom: calc(100vw * (80 / 428));
  }
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;
// header
const HeaderSection = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  overflow: hidden;
  z-index: 99;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (60 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
  }
`;
const HeaderInner = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #fff;
  padding: 0 25px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (60 / 1320));
    padding: 0 calc(100vw * (25 / 1320));
  }
  @media screen and (max-width: 930px) {
    justify-content: center;
    height: calc(100vw * (55 / 428));
    padding: 0;
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    right: calc(100vw * (13.5 / 428));
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;

const ContentWrap = styled.div`
  margin: 0 auto;
  width: 1200px;
  height: 800px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  overflow: scroll;
  overflow-x: hidden;
  z-index: 101;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1200 / 1320));
    height: calc(100vw * (800 / 1320));
  }
`;
const ContentsDetailWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row nowrap;
  height: 100%;
  width: 1180px;
  margin: 0 auto;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1180 / 1320));
  }
`;
// 상품 정보
const ContentsProductSection = styled.div`
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  @media screen and (max-width: 1320px) {
    margin: 0 calc(100vw * (10 / 1320));
  }
`;
const RightSectionWrap = styled.div`
  width: 480px;

  ${({ rightSectionFix }) =>
    rightSectionFix &&
    `
  top : 0;
  position : fixed;
  padding-top : 50px;
  `}

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (480 / 1320));
    ${({ rightSectionFix }) =>
    rightSectionFix &&
    `
    padding-top : calc(100vw * (50 / 1320));
    `}
  }
`;
// 배너 및 정보
const ContentsDetailBannerBox = styled.div`
  position: relative;
  width: 100%;
  height: 429px;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (429 / 1320));
  }
`;
const ContentsDetailBannerImg = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 120px;
    background: linear-gradient(transparent, #000);
    opacity: 0.7;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    &::before {
      height: calc(100vw * (120 / 1320));
    }
  }
`;


const ContentLike = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`
const WishIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain !important;
`


// 좋아요
// const ContentLike = styled.div`
//   margin: 10px;
//   @media screen and (max-width: 1320px) {
//     margin: calc(100vw * (10 / 1320)) 0 0;
//   }
//   @media screen and (max-width: 930px) {
//     margin: 0;
//     padding: calc(100vw * (12 / 428)) calc(100vw * (15 / 428)) calc(100vw * (12 / 428)) calc(100vw * (20 / 428));
//     border-bottom : 1px solid #eee;
//   }
// `
// const WishIcon = styled.img`
//   width: 24px;
//   height: 24px;
//   @media screen and (max-width: 1320px) {
//     width: calc(100vw * (24 / 1320));
//     height: calc(100vw * (24 / 1320));
//   }
//   @media screen and (max-width: 930px) {
//     width: calc(100vw * (24 / 428));
//     height: calc(100vw * (24 / 428));
//     padding-top: 6px;
//   }
// `


const ContentsDetailTextBox = styled.div`
  position: absolute;
  left: 25px;
  bottom: 20px;

  @media screen and (max-width: 1320px) {
    left: calc(100vw * (25 / 1320));
    bottom: calc(100vw * (20 / 1320));
  }
`;
const ContentsDetailDate = styled.div`
  color: #dddddd;
  font-weight: 500;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
`;

const ContentsDetailTitle = styled.div`
  width: 490px;
  color: #ffffff;
  font-weight: 500;
  font-size: 22px;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (490 / 1320));
    font-size: calc(100vw * (22 / 1320));
    margin-top: calc(100vw * (5 / 1320));
  }
`;

// 공유
const ShareSection = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 10px;
  border-bottom: 1px solid #eee;

  @media screen and (max-width: 1320px){
    height: calc(100vw * (50 / 1320));
    padding: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px){
    width: calc(100vw * (22 / 428));
    height: calc(100vw * (22 / 428));
  }
`
const ShareIconWrap = styled.div`
  width: 22px;
  height: 22px;
  margin-right: 10px;
  cursor: pointer;

  @media screen and (max-width: 1320px){
    width: calc(100vw * (22 / 1320));
    height: calc(100vw * (22 / 1320));
    margin-right: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px){
    width: calc(100vw * (22 / 428));
    height: calc(100vw * (22 / 428));
  }
`
const ShareIcon = styled.img`
  width: 100%;
  height: 100%;
`

// 태그
const ContentsHashTagBox = styled.div`
  display: flex;
  margin-top: 20px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }
`;
const ContentsHashTag = styled.div`
  color: #333;
  font-size: 14px;
  padding: 7px 12px;
  border: 1px solid #ddd;

  &:not(:last-child) {
    margin-right: 6px;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    padding: calc(100vw * (7 / 1320)) calc(100vw * (12 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (6 / 1320));
    }
  }
`;
const ContentsProductBox = styled.div`
  display: flex;
  margin-top: 15px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (15 / 1320));
  }
`;
const ContentsDetailStylingTopBox = styled.div`
  width: 120px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (120 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }
`;
const ContentsDetailStylingTopImgBox = styled.div`
  width: 100%;
  height: 120px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (120 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }
`;
const ContentsDetailStylingTopText = styled.div`
  width: 100%;
  line-height: 1.5;
  color: #333333;
  font-size: 15px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 5px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-top: calc(100vw * (5 / 1320));
  }
`;

const ContentsDetailImgBox = styled.div`
  padding-bottom: 100px;
  @media screen and (max-width: 1320px){
    padding-bottom: calc(100vw * (100 / 1320));    
  }
`;
const ContentsDetailImg = styled.img`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child) {
      margin-bottom: calc(100vw * (10 / 1320));
    }
  }
`;

// 상품 상세
const ContentsDetailSection = styled.div`
  width: 690px;
  padding-bottom: 100px;
  overflow: auto;
  height: 100%;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (690 / 1320));
    padding-bottom: calc(100vw * (100 / 1320));
  }
`;

const ContentsDetailStylingBox = styled.div`
  margin-top: 50px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (50 / 1320));
  }
`;
const ContentsDetailStylingTitle = styled.span`
  position: relative;
  color: #222222;
  font-weight: 700;
  font-size: 20px;
  padding: 0 7px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 12px;
    background-color: #fdf251;
    z-index: -1;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
    padding: 0 calc(100vw * (7 / 1320));

    &::before {
      content: "";
      bottom: calc(100vw * (-2 / 1320));
      height: calc(100vw * (12 / 1320));
    }
  }
`;
const ContentsDetailProductArea = styled.div`
  margin-top: 16px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (16 / 1320));
  }
`;
