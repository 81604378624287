//react
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { Mobile, PC } from "../../../MediaQuery";

//css
import styled from "styled-components";
import { DateToText, numberWithCommas } from "../../../../components/common/commonUse";
import { imgURL } from "../../../server/appInfoController";
import serverController from "../../../server/serverController";

export default function OrderProduct({ value }) {
  const history = useHistory();
  const [confirmedButtonStatus, setConfirmedButtonStatus] = useState(true);
  const [reviewButtonStatus, setReviewButtonStatus] = useState(false);
  const [statusRequest, setStatusRequest] = useState(false);
  const [mogOption, setMogOption] = useState({});

  const getStatus = () => {
    switch (value?.mog_order_status) {
      case 0:
        return "미입금";
      case 5:
        return "옵션변경요청";
      case 6:
        return "배송지 변경요청";
      case 10:
        return "무통장 대기";
      case 11:
        return "결제취소";
      case 12:
        return "결제실패";
      case 13:
        return "신규주문";
      case 14:
        return "상품준비중";
      case 20:
        return "결제완료";
      case 30:
        return "배송준비";
      case 41:
        return "배송중";
      case 42:
        return "배송완료";
      case 43:
        return "부분 배송중";
      case 44:
        return "구매 확정";
      case 50:
        return "반품요청";
      case 51:
        return "반품중";
      case 52:
        return "반품완료";
      case 53:
        return "반품수거 접수완료";
      case 54:
        return "반품 수거중";
      case 55:
        return "반품 수거완료";
      case 57:
        return "교환 요청";
      case 58:
        return "교환 접수";
      case 59:
        return "교환 수거중";
      case 60:
        return "교환 수거완료";
      case 61:
        return "교환중";
      case 62:
        return "교환완료";
      case 70:
        return "주문취소";
      case 71:
        return "취소 요청";
      case 72:
        return "취소 대기";
      case 73:
        return "취소 거부";
      default:
        return;
    }
  };

  const postRequest = () => {
    serverController.connectFetchController(
      `payment/request-confirm?mogId=${value.mog_id}`,
      "POST",
      null,
      (res) => {
        console.log(res, "주문확정 보내짐? res");
        setConfirmedButtonStatus(!confirmedButtonStatus);
        setReviewButtonStatus(true);
        setStatusRequest(true);
      }
    );
  };

  useEffect(() => {
    setMogOption(JSON.parse(value.mog_option));
  }, [value]);

  return (
    <Container>
      <PC>
        <OrderProductWrap>
          <ProductImgWrap>
            <ProductImg src={`${imgURL}${value?.prd_thumbnail}`} />
          </ProductImgWrap>
          <Link to={`/store/mypage/order/detail/${value?.mog_id}`}>
            <div>
              <Brand>{value?.bc_title || "(BRAND)"}</Brand>
              <ProductName>{value?.prd_name}</ProductName>
              {/* <Option>옵션 (+{value.option}원)</Option> */}
            </div>
          </Link>
          {/* <OrderNumber>{value?.order_id?.slice(0, 7)}</OrderNumber> */}
          <Day>{value?.mog_payment_date || "확인요망"}</Day>
          <Amount></Amount>
          <Price>{numberWithCommas(mogOption?.prd_list_price ?? 0)}</Price>
          <Count>{value?.orderCount ?? 1}</Count>
          <StatusWrap>
            <Step>{statusRequest ? "구매 확정" : getStatus()}</Step>
            {getStatus() === "결제완료" && confirmedButtonStatus ? (
              <ConfirmedButton onClick={postRequest}>구매 확정</ConfirmedButton>
            ) : null}
            {(reviewButtonStatus || getStatus() === "구매 확정") && value?.reviewCompleted === 0 && (
              <ConfirmedButton
                onClick={() => {
                  history.push(`/store/mypage/write_review/${value.txn_id}`);
                }}
              >
                리뷰 작성
              </ConfirmedButton>
            )}
          </StatusWrap>
        </OrderProductWrap>
      </PC>

      <Mobile>
        <MbTitleWrap>
          <MbTitle>주문번호 : {value.order_number}</MbTitle>
          <MbTitle>주문일자 : {value?.mog_payment_date || "확인요망"}</MbTitle>
        </MbTitleWrap>
        <OrderProductWrap>
          <ProductImgWrap>
            <ProductImg src={`${imgURL}${value?.prd_thumbnail}`} />
          </ProductImgWrap>
          <ProductInfoWrap>
            <Link to={`/store/mypage/order/detail/${value?.mog_id}`}>
              <InfoTextWrap>
                <Brand>{value?.bc_title || "(BRAND)"}</Brand>
                <ProductName>{value?.prd_name}</ProductName>
              </InfoTextWrap>
            </Link>
            <InfoPriceWrap>
              <Price>{numberWithCommas(mogOption?.prd_list_price ?? 0)}</Price>
              <Count>{value?.orderCount ?? 1}개</Count>
            </InfoPriceWrap>
            <StatusWrap>
              <Step>{getStatus()}</Step>
              {getStatus() === "결제완료" && <ConfirmedButton>구매 확정</ConfirmedButton>}
              {(reviewButtonStatus || getStatus() === "구매 확정") && value?.reviewCompleted === 0 && (
                <ConfirmedButton
                  onClick={() => {
                    history.push(`/store/mypage/write_review/${value.txn_id}`);
                  }}
                >
                  리뷰 작성
                </ConfirmedButton>
              )}
            </StatusWrap>
          </ProductInfoWrap>
        </OrderProductWrap>
      </Mobile>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid #d9d9d9;

  @media screen and (max-width: 1320px) {
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 930px) {
    border-top: calc(100vw * (1 / 428)) solid #707070;
    border-bottom: none;
    &:last-child {
      border-bottom: 1px solid #000;
    }
  }
`;
const OrderProductWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px 20px 15px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) calc(100vw * (30 / 1320)) calc(100vw * (20 / 1320))
      calc(100vw * (15 / 1320));
  }

  @media screen and (max-width: 930px) {
    align-items: flex-start;
    padding: calc(100vw * (20 / 428)) 0 calc(100vw * (30 / 428));
    border-top: calc(100vw * (1 / 428)) solid #d9d9d9;
    border-bottom: none;
  }
`;
const ProductImgWrap = styled.div`
  width: 86px;
  height: 86px;
  overflow: hidden;
  margin-right: 20px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (86 / 1320));
    height: calc(100vw * (86 / 1320));
    margin-right: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (90 / 428));
    height: calc(100vw * (90 / 428));
    margin-right: 0;
  }
`;
const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Brand = styled.div`
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin-bottom: 9px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
    margin-bottom: calc(100vw * (9 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const ProductName = styled.div`
  display: block;
  cursor: pointer;
  width: 500px;
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin-bottom: 7px;
  padding-right: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (500 / 1320));
    font-size: calc(100vw * (18 / 1320));
    margin-bottom: calc(100vw * (7 / 1320));
    padding-right: calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (6 / 428));
    padding-right: 0;
  }
`;
const Option = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #969696;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
  }
`;
const OrderNumber = styled.p`
  width: 100px;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: 500;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
`;
const Day = styled.p`
  width: 140px;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: 500;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (140 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
`;
const Amount = styled.p`
  width: 80px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (80 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    position: relative;
    width: auto;
    font-size: calc(100vw * (14 / 428));
    margin-left: calc(100vw * (8 / 428));
    padding-left: calc(100vw * (8 / 428));
    color: #969696;
    &:after {
      content: "";
      position: absolute;
      top: calc(100vw * (3 / 428));
      left: 0;
      width: 1px;
      height: calc(100vw * (16 / 428));
      background: #d9d9d9;
    }
  }
`;
const Count = styled.p`
  width: 100px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    position: relative;
    width: auto;
    font-size: calc(100vw * (14 / 428));
    margin-left: calc(100vw * (8 / 428));
    padding-left: calc(100vw * (8 / 428));
    color: #969696;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw * (1 / 428));
      height: calc(100vw * (16 / 428));
      background: #d9d9d9;
    }
  }
`;
const Price = styled.div`
  width: 200px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (200 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: auto;
    font-size: calc(100vw * (16 / 428));
  }
`;
const Step = styled.p`
  width: 100px;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (15 / 428));
    text-align: left;
    margin-top: calc(100vw * (18 / 428));
  }
`;
//
const MbTitleWrap = styled.div`
  display: flex;
  align-items: center;
  padding: calc(100vw * (10 / 428)) 0;
`;
const MbTitle = styled.p`
  position: relative;
  font-size: calc(100vw * (14 / 428));
  font-weight: bold;
  margin-right: calc(100vw * (10 / 428));
  padding-right: calc(100vw * (10 / 428));
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: calc(100vw * (5 / 428));
    width: 1px;
    height: calc(100vw * (16 / 428));
    background: #999999;
  }
  &:last-child {
    margin: 0;
    padding: 0;
  }
  &:last-child:after {
    display: none;
  }
`;
const ProductInfoWrap = styled.div`
  width: calc(100% - calc(100vw * (90 / 428)));
  padding-left: calc(100vw * (15 / 428));
`;
const InfoTextWrap = styled.div`
  width: 100%;
`;
const InfoPriceWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  margin-top: calc(100vw * (5 / 428));
`;
const StatusWrap = styled.div``;
const ConfirmedButton = styled.div`
  margin-top: 3px;
  width: 100px;
  text-align: center;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    font-size: calc(100vw * (15 / 428));
    text-align: left;
  }
`;
