import React from 'react';
import Header from '../components/common/header/Header';
import FashionNews from '../components/news/FashionNews';
import Footer from '../components/common/footer/Footer';

const FashionNewsPage = () => {
    return (
        <div>
            <Header />
            <FashionNews />
            <Footer />
        </div>
    )
}

export default FashionNewsPage
