import { createAction, handleActions } from "redux-actions";
import produce from "immer";

const UPDATE_DEVICE = "userInfoAction/updateDeviceType";

export const deviceType = createAction(UPDATE_DEVICE);

const initialState = {
  device: {
    data: "",
  },
};

export default handleActions(
  {
    [UPDATE_DEVICE]: (state, action) => {
      return produce(state, (draft) => {
        draft.device.data = action.payload.device.data;
      });
    },
  },
  initialState
);
