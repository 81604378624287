import {
    styled,
    css,
    Mobile,PC,
    MQ,
    pc,
    mobil,
    mquery,
    Media,
    Px,
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
} from '../mediaStlye';
import IconCheckbox from "../../../store_src/img/icon/icon_checkbox.png";
import { MobileTabItemsList } from '../../auth/mypage/mypage.elements';

export const     RequestFormWrapper = styled.div`
    ${Padding2(30, 123, pc)} 
    
    ${MQ(mquery)} {
        ${Padding2(30, 25, mobil)}
    }
`

export const RequestForm = styled.form`
    height: auto;
    ${PR(30, pc)}
    overflow-y: scroll;
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #A5A5A5;
    }

    ${MQ(mquery)} {
        height: auto;
        ${PR(5, mobil)}
    }
`

export const RequstLabel = styled.label`
    ${MT(30, pc)}
    ${Padding2(10, 0, pc)}
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #404040;

    & span {
        ${PL(10, pc)}
        ${Width(180, pc)}
        ${FontSize(18, pc)}
        font-weight: 600;
    }

    & input {
        flex: 1;
        ${FontSize(18, pc)}
        font-weight: 500;
    }

    & input::placeholder {
        font-weight: 400;
    }

    ${MQ(mquery)} {
        ${MT(45, mobil)}
        ${Padding2(10, 0, mobil)}

        & span {
            ${PL(10, mobil)}
            ${Width(100, mobil)}
            ${FontSize(15, mobil)}
        }

        & input {
            flex: 1;
            ${FontSize(14, mobil)}
            font-weight: 500;
        }
    }
`

export const RequstPeriod = styled.div`
    & > em {
        ${PL(10, pc)}
        ${FontSize(18, pc)}
        ${MB(5, pc)}
        ${MT(30, pc)}
        display: block;
        font-weight: 700;
    }

    ${MQ(mquery)} {
        ${MT(45, mobil)}
        ${Padding2(10, 0, mobil)}

        & > em {
            ${PL(10, mobil)}
            ${FontSize(18, mobil)}
            ${MB(5, mobil)}
            ${MT(30, mobil)}
            display: block;
            font-weight: 700;
        }
    }
`
export const DateInputBox = styled.div` 
    display: flex;
    align-items: center;
    gap: ${Px(5, pc)};

    & > div {
        flex: 1;
    }

    & > div > h3 {
        ${PL(10, pc)}
        ${MB(5, pc)}
    }

    ${MQ(mquery)} {
        gap: ${Px(5, mobil)};

        & > div > h3 {
            ${PL(10, mobil)}
            ${MB(5, mobil)}
        }
    }
`

export const DateInput = styled.input`
    ${Height(53, pc)}
    width: 100%;
    border: 1px solid #222;
    text-align: center;

    ${MQ(mquery)} {
        ${Height(53, mobil)}
    }
`
export const RequestAgree = styled.div`
    ${MT(30, pc)}
    ${MB(80, pc)}
    

    & input {
        ${MT(7, pc)}
        ${Width(14, pc)}
        ${Height(14, pc)}   
        display: block;
        border: 1px solid #ccc;     
    }

    & input:checked {        
        background: #222 url(${IconCheckbox}) no-repeat center/14px;        
        border: none;
    }

    & label {
        display: flex;
        align-items: flex-start;
        gap: ${Px(8, pc)};
        cursor: pointer;
    }

    & label div strong {        
        color: #222;
    }

    & label div p {
        color: #A5A5A5;   
    }
    ${MQ(mquery)} {
        ${MT(30, mobil)}
        ${MB(80, mobil)}

        & label {
            gap: ${Px(2, mobil)};
        }

        & input {
            display: block;
            ${MT(7, mobil)}
            ${Width(35, mobil)}
            ${Height(25, mobil)}
            margin-right: calc(100vw*(8/428));
        }
    }
    
`

export const RequestButtonBox = styled.div`
    text-align: center; 

    & p {
        color: #A5A5A5;
        ${MB(17, pc)}
    }

    ${MQ(mquery)} {
        & p {
            color: #A5A5A5;
            ${MB(17, mobil)}
        }
    }
`

export const RequestButton = styled.div`
    ${Width(278, pc)}
    ${Height(59, pc)}
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;
    color: #fff;
    cursor: pointer;

    ${MQ(mquery)} {
        ${Width(278, mobil)}
        ${Height(59, mobil)}
    }
`

export const RequestSelect = styled.select`
    width: 100%;
    /* -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; */
    border: none;
    cursor: pointer;
`
export const RequestTitle = styled.span`
    width: calc(100vw*(145/1920));
`