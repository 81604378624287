//react
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import { useState, useEffect } from "react";
import styled from "styled-components";

import { ip } from "../../../server/serverController";

//img
import IconAnswerArrow from "../../../img/icon/icon_answer_arrow.svg";
import IconCancel from "../../../img/icon/icon_myhaeder_cancle.svg";
import { imgURL } from "../../../server/appInfoController";
import MyPageDefaultModal from "../../common/modal/MyPageDefaultModal";
import MyInquiryEditModal from "./MyInquiryEditModal";
import ProductInquiryEditModal from "./ProductInquiryEditModal";

export default function MyProductInquiryDetailModal({
  setDetailShow,
  inquiryDetail,
  setInquiryList,
  getInquiryList,
}) {
  //페이지 이동
  const history = useHistory();
  const [inquiryRemove, setInquiryRemove] = useState(false);
  const [inquiryEdit, setInquiryEdit] = useState(null);

  // delete inquiryItem
  const deleteInquiryItem = () => {
    const header = new Headers();
    header.append("Content-Type", "application/json");
    const rawBody = JSON.stringify({
      inq_id: inquiryDetail.inq_id.toString(),
    });

    const options = {
      method: "DELETE",
      body: rawBody,
      headers: header,
      redirect: "follow",
    };

    fetch(`${ip}inquiry/store`, options)
      .then((response) => response.json())
      .then((result) => {
        if (result.result === 1) {
          setInquiryList([]);
          setDetailShow(false);
          setInquiryRemove(false);
          getInquiryList();
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (inquiryEdit === false) {
      setInquiryList([]);
      setDetailShow(false);
      getInquiryList();
    }
  }, [inquiryEdit]);

  return (
    <>
      {inquiryRemove && (
        <MyPageDefaultModal
          text={"해당 문의를 삭제하시겠습니까?"}
          trueFunc={deleteInquiryItem}
          falseFunc={() => setInquiryRemove(false)}
        />
      )}
      {inquiryEdit && (
        <ProductInquiryEditModal
          setInquiryEditModalShow={setInquiryEdit}
          product={inquiryDetail}
          edit
        />
      )}
      <Container>
        <Mobile>
          <Content inquiryRemove={inquiryRemove}>
            {/* header */}
            <HeaderSection>
              <p>문의내역</p>
              {/* 삭제 버튼 */}
              <DeleteButton onClick={() => setDetailShow(false)}>
                <img src={IconCancel} alt="deleteButton" />
              </DeleteButton>
            </HeaderSection>

            <Wrap>
              <ContentsWrap>
                <ListInner>
                  <ProductInfoWrap>
                    <ImgBox>
                      <img src={imgURL + inquiryDetail.prd_thumbnail} alt="product-img" />
                    </ImgBox>
                    <TextBox>
                      <InquirySatisfactionBox>
                        <InquirySatisfactionList>
                          <InquiryDate>
                            {inquiryDetail.inq_date.split("T")[0].replaceAll("-", ".")}
                          </InquiryDate>
                        </InquirySatisfactionList>
                        <InquirySatisfactionList>
                          <InquirySatisfaction complete={inquiryDetail.inq_answer !== 1}>
                            {inquiryDetail.inq_answer === 1 ? "답변대기중" : "답변완료"}
                          </InquirySatisfaction>
                        </InquirySatisfactionList>
                      </InquirySatisfactionBox>

                      <Brand>{inquiryDetail.bc_title || "(BRAND)"}</Brand>
                      <ProductName>{inquiryDetail.prd_name || "(상품 이름 없음)"}</ProductName>
                    </TextBox>
                  </ProductInfoWrap>
                </ListInner>
                <BackgroundGray />
                <InquiryTextWrap>
                  <InquiryTextBox>
                    <InquiryText>
                      [
                      {inquiryDetail.inq_type === 1
                        ? "상품문의"
                        : inquiryDetail.inq_type === 2
                        ? "재입고 문의"
                        : inquiryDetail.inq_type === 3
                        ? "사이즈 문의"
                        : inquiryDetail.inq_type === 4
                        ? "배송문의"
                        : null}
                      ] {inquiryDetail.inq_title}
                    </InquiryText>
                    <InquiryText>
                      <p>{inquiryDetail.inq_content}</p>
                      {inquiryDetail?.inq_images && (
                        <InquiryImgList>
                          {inquiryDetail?.inq_images?.split(",")?.length > 0 &&
                            inquiryDetail?.inq_images?.split(",").map((images) => {
                              return (
                                <InquiryImgBox>
                                  <img alt="inquiry-img" src={imgURL + images}></img>
                                </InquiryImgBox>
                              );
                            })}
                        </InquiryImgList>
                      )}
                    </InquiryText>
                  </InquiryTextBox>

                  {inquiryDetail.inq_answer !== 1 && (
                    <AnswerTextBox>
                      <AnswerIconImg>
                        <img src={IconAnswerArrow} alt="" />
                      </AnswerIconImg>
                      <AnswerText>{inquiryDetail.inq_answer_content}</AnswerText>
                    </AnswerTextBox>
                  )}
                </InquiryTextWrap>
              </ContentsWrap>
              <ButtonListWrap>
                <InquiryDeleteButton
                  type="button"
                  onClick={() => {
                    setInquiryRemove(true);
                  }}
                >
                  삭제
                </InquiryDeleteButton>
                {/* {inquiryDetail.inq_answer === 1 && (
                  <InquiryModifyButton
                    type="button"
                    onClick={() => {
                      setInquiryEdit(true);
                    }}
                  >
                    수정
                  </InquiryModifyButton>
                )} */}
              </ButtonListWrap>
            </Wrap>
          </Content>
        </Mobile>
      </Container>
    </>
  );
}

const Container = styled.div`
  @media screen and (max-width: 930px) {
  }
`;

const Wrap = styled.div`
  width: 100%;
`;

// header
const HeaderSection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & p {
    color: #333;
    font-weight: 500;
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));

    & p {
      font-size: calc(100vw * (16 / 428));
    }
  }
`;
const DeleteButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 930px) {
    right: calc(100vw * (13.5 / 428));
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;

// 콘텐츠
const Content = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: #fff;
  overflow: hidden;

  @media screen and (max-width: 930px) {
    width: 100%;
    height: 100vh;
    overflow: auto;
  }
`;

// inquiry
const ContentsWrap = styled.div``;

const BackgroundGray = styled.div`
  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (8 / 428));
    background-color: #f9f9f9;
  }
`;

const ListInner = styled.div`
  position: relative;
  width: 100%;

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
  }
`;
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
  }
`;

const ImgBox = styled.div`
  background-color: #fff;
  border: 1px solid #eee;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (70 / 428));
    height: calc(100vw * (70 / 428));
  }
`;
const TextBox = styled.div`
  width: calc(100vw * (298 / 428));
`;
const Brand = styled.p`
  color: #333;
  font-weight: 400;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (6 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const InquirySatisfactionBox = styled.ul`
  display: flex;
  align-items: center;

  @media screen and (max-width: 930px) {
    margin-bottom: calc(100vw * (10 / 428));
  }
`;
const InquirySatisfactionList = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 1px;
    background-color: #ddd;
  }

  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      height: calc(100vw * (14 / 428));
      margin: 0 calc(100vw * (12 / 428));
    }
  }
`;
const InquirySatisfaction = styled.span`
  color: ${(props) => (props.complete ? "#333" : "#ACACAC")};
  font-weight: ${(props) => (props.complete ? "600" : "400")};

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const InquiryDate = styled.span`
  color: #333;
  font-weight: 400;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

const InquiryTextWrap = styled.div``;

const InquiryTextBox = styled.div`
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) calc(100vw * (25 / 428));
  }
`;
const InquiryText = styled.div`
  color: #333;
  font-weight: 400;

  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eee;
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));

    &:not(:last-child)::after {
      margin: calc(100vw * (20 / 428)) 0;
    }
    > p {
      margin-bottom: calc(100vw * (15 / 428));
    }
  }
`;

const InquiryImgList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (15 / 428));
  }
`;
const InquiryImgBox = styled.div`
  width: 100px;
  height: 100px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (100 / 1320));
    height: calc(100vw * (100 / 1320));

    &:not(:last-child) {
      margin-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (120 / 428));
    height: calc(100vw * (120 / 428));

    &:not(:last-child) {
      margin-right: calc(100vw * (9 / 428));
    }
  }
`;
const AnswerTextBox = styled(InquiryTextBox)`
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (25 / 428)) calc(100vw * (25 / 428)) calc(100vw * (50 / 428));
  }
`;
const AnswerText = styled(InquiryText)`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (344 / 428));
  }
`;
const AnswerIconImg = styled.div`
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;

const ButtonListWrap = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 930px) {
    padding: 0 calc(100vw * (25 / 428));
    margin: calc(100vw * (20 / 428)) 0;
  }
`;
const InquiryDeleteButton = styled.button`
  color: #fff;
  font-weight: 500;
  background-color: #000;
  border: 1px solid #000;

  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (45 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
const InquiryModifyButton = styled(InquiryDeleteButton)`
  background-color: #fff;
  color: #000;
`;
