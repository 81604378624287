import React, { useState } from 'react';
import styled from 'styled-components';

import Header from '../../components/common/header/Header';
import Footer from '../../components/common/footer/Footer';

import SearchProduct from '../../components/management/SearchProduct';

import ProductList from '../../components/management/ProductList';
import ContentsList from '../../components/management/ContentsList';
import Pagination from '../../components/management/Pagination';
// import {ArchiveBtnPopup, CompletePopup, UpdateOrderPopup} from '../../components/productManagement/ProductPopup';

export default function ManagementList({activeTab}){

    const ListView = function(activeTab){
        switch(activeTab){
            case 0:
                return(
                    <>
                        <ProductList activeTab={activeTab}/>
                    </>
                )
                break;
            case 1:
                return(
                    <>
                        <ContentsList activeTab={activeTab}/>
                    </>
                )
                break;
        }
    }
    return(
        <>
            {/* <ArchiveBtnPopup/> */}
            <Header />
            <SearchProduct backBtn={true}/>
            {ListView(activeTab)}
            <Pagination/>
            <Footer />
        </>
    )
}
