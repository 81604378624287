import React from 'react';
import BlogContentsBox from './BlogContentsBox';
import BlogContentsExtra from './BlogContentsExtra';

const FashionNewsContentsBlog = () => {
    return (
        <div>
            <BlogContentsBox />
            <BlogContentsExtra />
            {/* <Fashion */}
        </div>
    )
}

export default FashionNewsContentsBlog
