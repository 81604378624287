import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../../components/common/header/Header";
import ServiceMenu from "../../component/service/ServiceMenu";
import NoticeList from "../../component/service/NoticeList";
import Footer from "../../component/common/StoreFooter";
import FooterMenu from "../../component/common/FooterMenu";
import FixMenu from "../../component/common/FixMenu";

export default function NoticePage() {
  return (
    <>
      <Header search={true} />
      <ServiceMenu />
      <NoticeList />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
      <FixMenu />
    </>
  );
}
