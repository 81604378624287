import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";
import { PaymentAction } from "../../../../store/actionCreators";

import IconCheckbox from "../../../img/icon/icon_checkbox.png";
import IconArrow from "../../../img/icon/icon_selectPay_arrow.png";
import TermOfUseModal from "../../../../components/common/pop/TermOfUseModal";

export default function PayTerm({ setIsModal }) {
  const [isOpenTerm, setIsOpenTerm] = useState(false);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    PaymentAction.checkInfo({
      isTermCheck: check,
    });
  }, [check]);

  return (
    <React.Fragment>
      <Container>
        <TermWrap>
          <TermChkWrap>
            <Checks>
              <ChkBox
                id={1}
                type="checkbox"
                name="term"
                checked={check}
                onChange={(e) => {
                  setCheck((prev) => !prev);
                }}
              />
              <ChkBoxLabel htmlFor={1}></ChkBoxLabel>
            </Checks>
            <TermChkText>아래 내용에 모두 동의합니다. (필수)</TermChkText>
          </TermChkWrap>
          <TermTextWrap
            onClick={() => {
              setIsOpenTerm(true);
            }}
          >
            <TermText>
              개인정보 제 3자 제공 및 결제대행 서비스 표준 이용약관
            </TermText>
            <TermText>
              본인은 만 14세 이상이고, 위 내용을 확인하였습니다.
            </TermText>
          </TermTextWrap>
        </TermWrap>
      </Container>
      {isOpenTerm && <TermOfUseModal setIsOpenTerm={setIsOpenTerm} />}
    </React.Fragment>
  );
}

const Container = styled.div`
  width: 100%;
`;
const TermWrap = styled.div`
  width: 100%;
  padding: 22px;
  background: #f1f1f1;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (22 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428)) 0;
    border-top: calc(100vw * (12 / 428)) solid #e9ebee;
    background: #fff;
  }
`;
const TermChkWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;

  @media screen and (max-width: 1320px) {
    padding-bottom: calc(100vw * (10 / 1320));
    border-bottom: calc(100vw * (1 / 1320)) solid #d9d9d9;
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (388 / 428));
    padding-bottom: calc(100vw * (10 / 428));
    border-bottom: none;
    margin: 0 auto;
  }
`;
const TermChkText = styled.p`
  font-size: 15px;
  margin-left: 12px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
    margin-left: calc(100vw * (12 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (12 / 428));
  }
`;
const TermTextWrap = styled.div`
  width: 100%;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (10 / 428));
    padding: calc(100vw * (20 / 428));
    background: #f1f1f1;
  }
`;
const TermText = styled.p`
  font-size: 14px;
  color: #646464;
  line-height: 1.4;
  cursor: pointer;
  &:first-child {
    background: url(${IconArrow}) no-repeat center right 10px/10px;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    &:first-child {
      background: url(${IconArrow}) no-repeat center right
        calc(100vw * (10 / 1320)) / calc(100vw * (10 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    &:first-child {
      background: url(${IconArrow}) no-repeat center right
        calc(100vw * (10 / 428)) / calc(100vw * (10 / 428));
    }
  }
`;

//checkbox
const Checks = styled.div`
  width: 20px;
  height: 20px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (20 / 1320));
    height: calc(100vw * (20 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (20 / 428));
    height: calc(100vw * (20 / 428));
  }
`;
const ChkBox = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  & + label::after {
    display: block;
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    width: 16px;
    height: 16px;
    background: #fff;
    border: 2px solid #000;
    border-radius: 5px;
  }

  &:checked + label::after {
    display: none;
  }

  @media screen and (max-width: 1320px) {
    &:checked + label::after {
      top: calc(100vw * (-10 / 1320));
      width: calc(100vw * (16 / 1320));
      height: calc(100vw * (16 / 1320));
      border: calc(100vw * (2 / 1320)) solid #000;
      border-radius: calc(100vw * (5 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    &:checked + label::after {
      top: calc(100vw * (-10 / 428));
      width: calc(100vw * (16 / 428));
      height: calc(100vw * (16 / 428));
      border: calc(100vw * (2 / 428)) solid #000;
      border-radius: calc(100vw * (5 / 428));
    }
  }
`;
const ChkBoxLabel = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-indent: -9999px;
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    width: 16px;
    height: 16px;
    background: #000;
    border: 2px solid #000;
    border-radius: 5px;
    background: #000 url(${IconCheckbox}) no-repeat center/14px;
  }

  @media screen and (max-width: 1320px) {
    &&::before {
      top: calc(100vw * (-10 / 1320));
      width: calc(100vw * (16 / 1320));
      height: calc(100vw * (16 / 1320));
      border: calc(100vw * (2 / 1320)) solid #000;
      border-radius: calc(100vw * (5 / 1320));
      background: #000 url(${IconCheckbox}) no-repeat
        center/calc(100vw * (14/1320));
    }
  }

  @media screen and (max-width: 930px) {
    &&::before {
      top: calc(100vw * (-10 / 428));
      width: calc(100vw * (16 / 428));
      height: calc(100vw * (16 / 428));
      border: calc(100vw * (2 / 428)) solid #000;
      border-radius: calc(100vw * (5 / 428));
      background: #000 url(${IconCheckbox}) no-repeat
        center/calc(100vw * (14/428));
    }
  }
`;
