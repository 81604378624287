import { useInfiniteQuery } from "react-query";

export default function useAllPlanInfiniteScroll({ queryKey, requestAPI, options, requestQueryString }) {
  const fetchUrl = async ({ pageParam = requestQueryString.eventCategoryIdArr[0] }) => {
    const response = await requestAPI({ eventCategoryId: pageParam });
    return response;
  };

  const { data, fetchNextPage, hasNextPage, isLoading, isFetching, isSuccess, isError, refetch } = useInfiniteQuery(
    queryKey,
    (pageParam) => {
      return fetchUrl(pageParam);
    },
    {
      getNextPageParam: (lastPage) => {
        const currentId = lastPage?.eventCategory?.eventCategoryId;
        const eventCategoryIdArr = requestQueryString.eventCategoryIdArr;
        const lastId = eventCategoryIdArr[eventCategoryIdArr.length - 1];
        const findNextId = eventCategoryIdArr.indexOf(currentId);
        console.log(lastPage);
        if (!lastPage) {
          return undefined;
        }
        return currentId !== lastId ? eventCategoryIdArr[findNextId + 1] : undefined;
      },
      ...options,
      retry: 0,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isError,
    refetch,
    isSuccess,
  };
}
