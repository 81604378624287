import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import styled, { css } from "styled-components";

// controller
import serverController from "../../../server/serverController";
import cloneDeep from "lodash/cloneDeep";

// imgs
import IconChkOn from "../../../img/icon/icon_chk_on.svg";
import IconChkOff from "../../../img/icon/icon_chk_off.svg";

// component
import CartProduct from "./CartProduct";
import { PaymentAction } from "../../../../store/actionCreators";
import Commas from "../../../server/Commas";
import MyPageDefaultModal from "../../common/modal/MyPageDefaultModal";

export default function CartProductList({ setLoading }) {
  const history = useHistory();

  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);
  const [selectList, setSelectList] = useState([]);

  const [checks, setChecks] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const allFinalPrice = useRef([]);

  const [addressDataBool, setAddressDataBool] = useState(false);
  const [addressModalPop, setAddressModalPop] = useState(false);

  const [isPayButtonTouch, setIsPayButtonTouch] = useState(false);

  const sumFinalPrice = allFinalPrice.current.reduce(function add(sum, value) {
    return sum + value;
  }, 0);

  const handleCartId = () => {
    const indexArr = [];
    checks.forEach((check, index) => {
      if (check) {
        indexArr.push(index);
      }
    });
    const cartIds = indexArr.map((v) => allList[v].cart_id);
    return [indexArr, cartIds];
  };

  const handleChecks = () => {
    const [indexArr] = handleCartId();
    const newArr = [];
    indexArr.forEach((index) => {
      newArr.push(allList[index]);
    });
    setSelectList(newArr);
  };

  const onClickAllCheck = () => {
    setAllCheck((prev) => !prev);
  };

  const getIsInventory = async () => {
    if (selectList.length === 0) {
      return alert("구매하실 상품을 선택해주세요.");
    }
    for (const i in selectList) {
      const cartItem = selectList[i].cart_item;
      const cartItemCount = selectList[i].cart_item.data[0].count;
      const options = cartItem.data[0].options;
      const optId = options.optId[options.optId.length - 1];
      // await serverController.connectFetchController(
      //   `product/${optId}/inventory?count=${cartItemCount}`,
      //   "GET",
      //   null,
      //   (res) => {
      //     console.log(res)
      //     if (res.result !== 1) return alert("일시적인 오류입니다. 다시 시도해주세요.");
      //     if (res.isItPossible === "Y") {
      //       onClickOrder();
      //     } else {
      //       return alert("재고 보유 수량이 부족합니다. 다시 확인해주세요.");

      //     }
      //   }
      // );
      let result = await serverController.connectFetchController(
        `product/${optId}/inventory?count=${cartItemCount}`,
        "GET",
        null)

      if (result.result !== 1) return alert("일시적인 오류입니다. 다시 시도해주세요.");
      if (result.isItPossible === "Y") {
        onClickOrder();
      } else {
        alert("재고 보유 수량이 부족합니다. 다시 확인해주세요.");
        break;

      }
    }
  };

  const onClickOrder = () => {
    // if (selectList.length === 0) {
    //   return alert("구매하실 상품을 선택해주세요.");
    // }
    if (addressDataBool) {
      return setAddressModalPop(true);
    }
    let resultList = [];

    selectList.map((value) => {
      resultList.push({ cart_items: JSON.stringify(value.cart_item) });
    });
    localStorage.setItem("selectList", JSON.stringify(resultList));
    PaymentAction.saveProductsPaymentInfo(resultList);
    history.push(`/store/pay`);
  };

  const getCarts = () => {
    serverController.connectFetchController(`cart/list`, "GET", null, (res) => {
      if (res?.result !== 1) return;
      const list = res.list;
      const keys = Object.keys(list);
      const values = Object.values(list);

      const data = values.map((dataArr) => {
        dataArr.map((item) => {
          if (typeof item.cart_item === "string") {
            item.cart_item = JSON.parse(item.cart_item);
          }
          return item;
        });
        return dataArr;
      });

      // for check control
      let allArr = [];
      for (let d in data) {
        for (let f in data[d]) {
          allArr.push(data[d][f]);
        }
      }
      setAllList(allArr);
      setChecks(Array(allArr.length).fill(false));

      // for binding
      let arr = [];
      let d = cloneDeep(data);
      for (let i in d) {
        d[i].unshift(keys[i]);
        arr.push(d[i]);
      }
      setList(arr);

      setLoading(false);
    });
  };

  const getReceiversInfo = () => {
    serverController.connectFetchController(`receivers`, "GET", null, (res) => {
      if (res.result === 0) {
        return setAddressDataBool(true);
      }
    });
  };

  const deleteProduct = () => {
    const [indexArr, cartIds] = handleCartId();
    for (let i in cartIds) {
      serverController.connectFetchController(
        `cart/delete?cart_id=${cartIds[i]}`,
        "POST",
        null,
        (res) => {
          if (res?.result === 1) {
            PaymentAction.resetData();
            getCarts();
          }
        },
        (err) => { }
      );
    }
  };

  useEffect(() => {
    PaymentAction.resetData();
    getCarts();
    getReceiversInfo();
    handleCartId();
  }, []);

  useEffect(() => {
    handleChecks();
  }, [checks]);

  useEffect(() => {
    setChecks(Array(allList.length).fill(allCheck));
  }, [allCheck]);

  const cartProductItems = {
    setList,
    setChecks,
    checks,
    setSelectList,
    getCarts,
    allList,
    setAllList,
    allFinalPrice: allFinalPrice.current,
  };

  return (
    <Container>
      {addressModalPop && (
        <MyPageDefaultModal
          text="배송지 입력 후 정상 이용 가능합니다."
          trueText="입력하러 가기"
          trueFunc={() => {
            history.push(`/store/mypage/adress`);
          }}
          falseFunc={() => setAddressModalPop(false)}
        />
      )}
      <CartWrap>
        {/* 상품이 있을 때 */}
        {allList.length > 0 ? (
          <CartContent>
            <CartTop>
              <Chk>
                <ChkInput
                  type="checkbox"
                  id="chk-all"
                  defaultChecked={selectList.length === allList.length}
                />
                <ChkLabel htmlFor="chk-all" onClick={onClickAllCheck}>
                  <ChkImg className="chk-img" chkState={selectList.length === allList.length} />
                  <ChkText>
                    전체선택(
                    <span>{`${selectList.length} / ${allList.length}`}</span>)
                  </ChkText>
                </ChkLabel>
              </Chk>
              <DeleteButton onClick={deleteProduct} type="button">
                선택삭제
              </DeleteButton>
            </CartTop>
            <CartBottom>
              <ProductArea>
                {list.map((prd, idx) => {
                  let arr = [];
                  for (let i in prd) {
                    if (i !== "0") {
                      arr.push(prd[i]);
                    }
                  }
                  return (
                    <CartProduct
                      key={`${idx}-${prd.prd_id}-cartItem`}
                      value={arr}
                      brand={prd[0]}
                      {...cartProductItems}
                    />
                  );
                })}
              </ProductArea>

              <OrderPriceArea>
                <OrderPriceListBox>
                  <OrderPriceList>
                    <OrderPriceTitle>총 상품 금액</OrderPriceTitle>
                    <OrderPriceText>{Commas.numberWithCommas(sumFinalPrice)}원</OrderPriceText>
                  </OrderPriceList>
                  <OrderPriceList>
                    <OrderPriceTitle>총 배송비</OrderPriceTitle>
                    <OrderPriceText>
                      {/* {Commas.numberWithCommas(2500 * selectList.length)}원 */}
                      0원
                    </OrderPriceText>
                  </OrderPriceList>
                </OrderPriceListBox>
                <OrderPriceTotal>
                  <OrderPriceTotalTitle>총 결제 금액</OrderPriceTotalTitle>
                  <OrderPriceTotalText>
                    {Commas.numberWithCommas(sumFinalPrice)}원
                  </OrderPriceTotalText>
                </OrderPriceTotal>
              </OrderPriceArea>
            </CartBottom>
            <PC>
              <OrderButton type="button" onClick={getIsInventory}>
                주문하기
              </OrderButton>
            </PC>
            <Mobile>
              <OrderButton
                type="button"
                onClick={getIsInventory}
                onTouchStart={() => setIsPayButtonTouch(true)}
                onTouchEnd={() => setIsPayButtonTouch(false)}
                isPayButtonTouch={isPayButtonTouch}
              >
                {Commas.numberWithCommas(sumFinalPrice)}원 주문하기
              </OrderButton>
            </Mobile>
          </CartContent>
        ) : (
          <CartContentNone>
            <NoneText>장바구니에 담긴 상품이 없습니다.</NoneText>
            <NoneButton to={`/store`}>상품보러가기</NoneButton>
          </CartContentNone>
        )}
      </CartWrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const CartWrap = styled.div``;

const CartContent = styled.div`
  padding: 180px 0 270px;
  background-color: #f5f5f5;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (180 / 1320)) 0 calc(100vw * (270 / 1320));
  }

  @media screen and (max-width: 930px) {
    padding: 0 0 calc(100vw * (90 / 428));
  }
`;

const Chk = styled.div``;
const ChkInput = styled.input`
  display: none;
/* 
  &:checked + label .chk-img {
    background: url(${IconChkOn}) no-repeat center / contain;
  } */
`;
const ChkLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const ChkImg = styled.span`
  width: 24px;
  height: 24px;
  background: ${({ chkState }) => chkState ? css`url(${IconChkOn}) no-repeat center / contain;` : css`url(${IconChkOff}) no-repeat center / contain;`};

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (24 / 1320));
    height: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (24 / 428));
    height: calc(100vw * (24 / 428));
  }
`;
const ChkText = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-left: calc(100vw * (10 / 428));
  }
`;

const CartTop = styled.div`
  width: 1240px;
  margin: 0 auto;
  padding: 13px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    padding: calc(100vw * (13 / 1320)) calc(100vw * (25 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;
const DeleteButton = styled.button`
  color: #777;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const CartBottom = styled.div`
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (10 / 1320));
  }

  @media screen and (max-width: 930px) {
    margin-top: calc(100vw * (10 / 428));
  }
`;
const ProductArea = styled.ul``;

const OrderPriceArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1240px;
  padding: 24px 0;
  margin: 20px auto 0;
  background-color: #fff;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    padding: calc(100vw * (24 / 1320)) 0;
    margin: calc(100vw * (20 / 1320)) auto 0;
  }

  @media screen and (max-width: 930px) {
    display: block;
    width: 100%;
    padding: calc(100vw * (20 / 428)) calc(100vw * (25 / 428)) calc(100vw * (78 / 428));
    margin: calc(100vw * (10 / 428)) auto 0;
  }
`;
const OrderPriceListBox = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 66.6666%;

  @media screen and (max-width: 930px) {
    display: block;
    width: 100%;
    padding-bottom: calc(100vw * (20 / 428));
  }
`;
const OrderPriceList = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;

  @media screen and (max-width: 930px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: calc(100vw * (15 / 428));
    }
  }
`;
const OrderPriceTitle = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const OrderPriceText = styled.span`
  color: #333;
  font-weight: 700;
  font-size: 20px;
  margin-top: 7px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
    margin-top: calc(100vw * (7 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-weight: 500;
    font-size: calc(100vw * (15 / 428));
    margin-top: 0;
  }
`;

const OrderPriceTotal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 33.3333%;

  @media screen and (max-width: 930px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top: calc(100vw * (15 / 428));
    border-top: 1px solid #ddd;
  }
`;

const OrderPriceTotalTitle = styled.span`
  color: #333;
  font-size: 16px;
  font-weight: 500;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-weight: 700;
    font-size: calc(100vw * (18 / 428));
  }
`;
const OrderPriceTotalText = styled.span`
  color: #333;
  font-weight: 700;
  font-size: 20px;
  margin-top: 7px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (20 / 1320));
    margin-top: calc(100vw * (7 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    margin-top: 0;
  }
`;

const CartContentNone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 360px);
  background-color: #fff;

  @media screen and (max-width: 1320px) {
    height: calc(100vh - calc(100vw * (360 / 1320)));
  }

  @media screen and (max-width: 930px) {
    height: calc(100vh - calc(100vw * (58 / 428)));
  }
`;
const NoneText = styled.p`
  color: #777;
  font-weight: 500;
  font-size: 18px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const NoneButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  color: #fff;
  font-size: 18px;
  margin-top: 80px;
  background-color: #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (200 / 1320));
    height: calc(100vw * (50 / 1320));
    font-size: calc(100vw * (18 / 1320));
    margin-top: calc(100vw * (80 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (200 / 428));
    height: calc(100vw * (50 / 428));
    font-size: calc(100vw * (18 / 428));
    margin-top: calc(100vw * (50 / 428));
  }
`;

const OrderButton = styled.button`
  display: block;
  width: 323px;
  height: 60px;
  color: #fdf251;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (323 / 1320));
    height: calc(100vw * (60 / 1320));
    font-size: calc(100vw * (18 / 1320));
    margin-top: calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: calc(100vw * (90 / 428));
    font-size: calc(100vw * (20 / 428));
    padding: calc(100vw * (20 / 428)) 0 calc(100vw * (45 / 428));
    margin-top: 0;
    ${({ isPayButtonTouch }) => isPayButtonTouch && `background-color : #1b1b1b`}
  }
`;
