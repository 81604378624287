import { useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import { useState } from "react";
import styled from "styled-components";

// img
import Zigzag from "../../../img/borderBottom_zigzag_small.svg";
import ProductImg from "../../../img/product/pay_product_img1.png";

export default function MyOrderTab({ tabIndex, hide }) {
  const history = useHistory();
  const tabList = ["전체", "배송중", "배송완료", "취소/교환"];
  return (
    <TabArea hide={hide}>
      <TabBox>
        {tabList.map((tabValue, index) => {
          return (
            <Tab
              key={`${tabValue}-${index}`}
              onClick={() => {
                history.push(`/store/mypage/order/${index}?page=1`);
              }}
            >
              {tabValue}
            </Tab>
          );
        })}

        <TabBottom tabIndex={tabIndex}>
          <TabBottomImg src={Zigzag} alt="zigzag-bottom" />
        </TabBottom>
      </TabBox>
    </TabArea>
  );
}

const TabArea = styled.div`
  width: 100%;
  border-bottom: 1px solid #eee;
  transition: ease 0.3s all;

  @media screen and (max-width: 930px) {
    position: fixed;
    top: calc(100vw * (55 / 428));
    left: 0;
    background: #fff;
    ${({ hide }) => hide && `top : 0`}
  }
`;
const TabBox = styled.div`
  position: relative;
  display: flex;
  width: 400px;
  height: 50px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (400 / 1320));
    height: calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;
const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 4);
  height: 100%;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const TabBottom = styled.div`
  position: absolute;
  ${({ tabIndex }) => {
    return tabIndex === "0"
      ? `left: 0;`
      : tabIndex === "1"
        ? `left: 100px;`
        : tabIndex === "2"
          ? `left: 200px;`
          : tabIndex === "3"
            ? `left: 300px;`
            : ``;
  }}
  bottom: -7px;
  width: 100px;
  transition: ease 0.3s all;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    ${({ tabIndex }) => {
    return tabIndex === "0"
      ? `left: 0;`
      : tabIndex === "1"
        ? `left: calc(100vw * (100 / 1320));`
        : tabIndex === "2"
          ? `left: calc(100vw * (200 / 1320));`
          : tabIndex === "3"
            ? `left: calc(100vw * (300 / 1320));`
            : ``;
  }}
    bottom: calc(100vw * (-7 / 1320));
    width: calc(100vw * (100 / 1320));
  }

  @media screen and (max-width: 930px) {
    ${({ tabIndex }) => {
    return tabIndex === "0"
      ? `left: calc(100vw * (25 / 428));`
      : tabIndex === "1"
        ? `left: calc(100vw * (119 / 428));`
        : tabIndex === "2"
          ? `left: calc(100vw * (214 / 428));`
          : tabIndex === "3"
            ? `left: calc(100vw * (308 / 428));`
            : ``;
  }}
    bottom: calc(100vw * (-7 / 428));
    width: calc(100vw * (95 / 428));
  }
`;
const TabBottomImg = styled.img`
  width: 100%;
  height: 100%;
`;
