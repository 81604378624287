import React, { useState, useEffect } from 'react';
import faker from 'faker';
import { Mobile, PC } from '../../MediaQuery';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HtmlParser from 'react-html-parser';
import serverController from '../../server/serverController';

import { Wrapper, Section, SectionHeadings } from './Main.elements';
import {
    IdolSliderWrapper,
    IdolSlider,
    IdolSlide,
    IdolImg,
    IdolProducts,
    IdolProduct,
    IdolSliderBottom,
    IdolArrows,
    IdolDesc
} from './MainIdol.elements';
import IdolItem from './IdolItem';

import ArrowImg from '../../images/drama_arrow_right.png';

import { Swiper, SwiperSlide, } from 'swiper/react';
import SwiperCore, { Navigation, EffectCoverflow } from 'swiper';
import "swiper/swiper-bundle.css";
// import './Mainidol.css';
import './MainIdol2.css';
SwiperCore.use([Navigation, EffectCoverflow]);

const MainIdol = () => {
    const history = useHistory();

    const [textIndex, setTextIndex] = useState(0);

    const { isLogged } = useSelector(state => state.userData);
    const [list, setList] = useState([]);

    const getData = async () => {
        await serverController.connectFetchController(
            `main/contentslist/${4}`,
            "GET",
            null,
            (res) => {
                console.log(res, "메인 아이돌 인트로 response")
                if (res?.result === 1) {
                    setList(res?.list);
                }
            },
            (err) => console.error(err)
        );
    }

    useEffect(() => {
        getData();
    }, [isLogged])

    const onSlideChange = (swiper) => {
        const index = swiper.activeIndex;
        const currentDataId = parseInt(swiper.slides[index].dataset.value)
        const dataIndex = list.findIndex(v => v.contents_id === currentDataId)
        console.log(currentDataId, dataIndex)
        setTextIndex(dataIndex)
    }

    return (
        <>
            <PC>
                <Section>
                    <Wrapper>
                        <SectionHeadings>I-Dol Fashion For You</SectionHeadings>
                        <IdolSliderWrapper>
                            <IdolSlider className="idol-slider">
                                {
                                    list.length > 0 &&
                                    <Swiper
                                        navigation={{
                                            prevEl: '.swiper-button-prev-idol',
                                            nextEl: '.swiper-button-next-idol',
                                        }}
                                        modules={[EffectCoverflow]}
                                        effect="coverflow"
                                        loop
                                        centeredSlides
                                        initialSlide={3}
                                        slidesPerView="5"
                                        coverflowEffect={{
                                            rotate: 0,
                                            stretch: 50,
                                            depth: 150,
                                            modifier: 1,
                                        }}
                                        onSlideChange={onSlideChange}
                                    >
                                        {
                                            list.map((v, i) => {
                                                return (
                                                    <SwiperSlide key={v?.contents_id} data-value={v?.contents_id}>
                                                        <IdolItem value={v} />
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                    </Swiper>
                                }
                            </IdolSlider>
                            <IdolSliderBottom>
                                <IdolArrows>
                                    <div className="idol-arrows swiper-button-prev-idol">
                                        <img style={{ transform: "rotate(180deg)" }} src={ArrowImg} />
                                    </div>

                                    <IdolDesc>
                                        <h4>{list[textIndex]?.contents_title}</h4>
                                        <p>{HtmlParser(list[textIndex]?.contents_content)}</p>
                                    </IdolDesc>

                                    <div className="idol-arrows swiper-button-next-idol">
                                        <img src={ArrowImg} />
                                    </div>
                                </IdolArrows>
                            </IdolSliderBottom>

                        </IdolSliderWrapper>
                    </Wrapper>
                </Section>
            </PC>

            <Mobile>
                <Section>
                    <Wrapper>
                        <SectionHeadings>I-Dol Fashion For You</SectionHeadings>
                        <IdolSliderWrapper>
                            <IdolSlider className="idol-slider">
                                {
                                    list.length > 0 ? (
                                        <Swiper
                                            // navigation={{
                                            //     prevEl: '.swiper-button-prev-idol',
                                            //     nextEl: '.swiper-button-next-idol',
                                            // }}  
                                            // modules={[EffectCoverflow]} effect="coverflow"           
                                            // loop
                                            // centeredSlides={true}
                                            // slidesPerView={"auto"}
                                            // coverflowEffect={{
                                            //     rotate: 0,
                                            //     stretch: 10,
                                            //     depth: 250,
                                            //     modifier: 1,
                                            // }}
                                            // onSlideChange={onSlideChange}
                                            navigation={{
                                                prevEl: '.swiper-button-prev-idol',
                                                nextEl: '.swiper-button-next-idol',
                                            }}
                                            modules={[EffectCoverflow]}
                                            effect="coverflow"
                                            loop
                                            centeredSlides
                                            initialSlide={3}
                                            slidesPerView="3"
                                            coverflowEffect={{
                                                rotate: 0,
                                                stretch: 50,
                                                depth: 150,
                                                modifier: 1,
                                            }}
                                            onSlideChange={onSlideChange}
                                        >
                                            {
                                                list.map((v, i) => {
                                                    return (
                                                        <SwiperSlide key={v?.contents_id} data-value={v?.contents_id}>
                                                            <IdolItem value={v} />
                                                        </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                    ) : null
                                }
                            </IdolSlider>
                            <IdolSliderBottom>
                                <IdolArrows>
                                    <div className="idol-arrows swiper-button-prev-idol">
                                        <img style={{ transform: "rotate(180deg)" }} src={ArrowImg} />
                                    </div>

                                    <IdolDesc>
                                        <h4>{list[textIndex]?.contents_title}</h4>
                                        <p>{HtmlParser(list[textIndex]?.contents_content)}</p>
                                    </IdolDesc>

                                    <div className="idol-arrows swiper-button-next-idol">
                                        <img src={ArrowImg} />
                                    </div>
                                </IdolArrows>
                            </IdolSliderBottom>

                        </IdolSliderWrapper>
                    </Wrapper>
                </Section>
            </Mobile>
        </>
    )
}

export default MainIdol
