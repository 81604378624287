import React from "react";
import StoreCategoryContainer from "../../../containers/storeCategoryContainer";
import ScrollToTop from "components/common/ScrollToTop";
import { useLocation } from "react-router-dom";

export default function CategoryProductsPage() {
  const location = useLocation();

  React.useEffect(() => {
    const prevPath = sessionStorage.getItem("prevPath").split("/");
    console.log("prevPath: ", prevPath);

    if (!prevPath) {
      return;
    }

    if (prevPath.includes("category")) {
      return;
    }

    if (!prevPath.includes("detail")) {
      window.scrollTo({
        top: 0,
      });
    }
  }, [location]);

  return (
    <>
      <StoreCategoryContainer />
    </>
  );
}
