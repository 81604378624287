//react
import { useHistory } from "react-router-dom";
import { PC, Mobile } from "../../../MediaQuery";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import styled from "styled-components";

//img
import IconCancel from "../../../img/icon/icon_myhaeder_cancle.svg";

// controller
import serverController from "../../../server/serverController";

import DetailPhotoReview from "../../product/detail/DetailPhotoReview";
import Pagination from "../../common/pagination/Pagination";

export default function PhotoReviewModal({ setPhotoListModal }) {
  return (
    <Container>
      <Background
        onClick={() => {
          setPhotoListModal(false);
        }}
      />

      <Content>
        {/* header */}
        <HeaderSection>
          <HeaderInner>
            <p>포토리뷰</p>
            <CloseButton
              onClick={() => {
                setPhotoListModal(false);
              }}
            >
              <img src={IconCancel} alt="cancel-icon" />
            </CloseButton>
          </HeaderInner>
        </HeaderSection>

        <Wrap>
          <DetailPhotoReview />
        </Wrap>
      </Content>
    </Container>
  );
}

const Container = styled.div``;

const Wrap = styled.div`
  width: 100%;
  height: calc(100% - 199px);
  overflow: auto;

  @media screen and (max-width: 1320px) {
    height: calc(100% - calc(100vw * (199 / 1320)));
  }
  @media screen and (max-width: 930px) {
    height: auto;
  }
`;
const PaginationWrap = styled.div`
  height: 115px;
  display: flex;
  justify-content: center;
  align-items: center;
  .mg-top {
    margin-top: 0;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (115 / 1320));
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;

// header
const HeaderSection = styled.div`
  position: relative;
  width: 100%;
  height: 84px;
  overflow: hidden;
  z-index: 99;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
  }
`;
const HeaderInner = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
  background-color: #fff;
  padding: 0 25px;

  & p {
    color: #333;
    font-weight: 500;
    font-size: 20px;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
    padding: 0 calc(100vw * (25 / 1320));

    & p {
      font-size: calc(100vw * (20 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    justify-content: center;
    height: calc(100vw * (55 / 428));
    padding: 0;

    & p {
      font-size: calc(100vw * (16 / 428));
    }
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    right: calc(100vw * (13.5 / 428));
    width: calc(100vw * (55 / 428));
    height: calc(100vw * (55 / 428));
    padding: calc(100vw * (11.5 / 428));
  }
`;

// 콘텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  width: 1240px;
  height: 650px;
  background: #fff;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
    height: calc(100vw * (650 / 1320));
  }
  @media screen and (max-width: 930px) {
    top: 0;
    left: 0;
    transform: translate(0);
    width: 100%;
    height: 100vh;
    overflow: auto;
  }
`;
