//react
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery"

//css
import styled from "styled-components"

//page
import StylistPayProduct from "./StylistPayProduct"
import PayAdress from "./PayAdress"
import PayInfo from "./PayInfo"
import PayProduct from "./PayProduct"
import PayCoupon from "./PayCoupon"
import PayPoint from "./PayPoint"
import Payment from "./Payment"

export default function PayFrom({ isStylist, totalPrice }) {

  return (
    <Container>
      <PayFromWrap>
          {isStylist ? <StylistPayProduct /> :<PayProduct/>} 
          <PayAdress/>
          <PayInfo/>
          {isStylist ? null : <PayCoupon/>} 
          {isStylist ? null : <PayPoint totalPrice={totalPrice}/>}
          <Payment/>
      </PayFromWrap>
    </Container>
  );
}

const Container = styled.div`
    width: 100%;
`
const PayFromWrap = styled.div`
    width: 100%;
` 