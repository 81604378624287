import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";

// img
import IconSearch from "../../img/icon/icon_header_search_black.svg";
import IconCartBack from "../../img/icon/icon_header_back.svg";

export default function SearchHeader(props) {
  const {
    inputSearchValue,
    isDetail = false,
    setContentsDetail,
    recentList,
    setRecentList,
    searchWord,
    setSearchWord,
  } = props;
  const history = useHistory();

  const searchClick = () => {
    history.push(`/store/search?keyword=${searchWord}`);
  };

  useEffect(() => {
    setSearchWord(inputSearchValue || "");

    if (!inputSearchValue) return;
    if (inputSearchValue.length === 0) return;
    let recentListArr = recentList;
    recentListArr.unshift(inputSearchValue);
    if (recentListArr.length > 5) {
      recentListArr.pop();
    }
    setRecentList(recentListArr);
    localStorage.setItem("recentList", JSON.stringify([...new Set(recentListArr)]));
  }, [inputSearchValue]);

  return (
    <Mobile>
      <Container>
        <HeaderWrap>
          <HeaderInner>
            <BackWrap
              onClick={() => {
                if (isDetail) {
                  setContentsDetail(false);
                } else {
                  history.goBack();
                }
              }}
            >
              <BackIcon>
                <BackIconImg src={IconCartBack} alt="logo" />
              </BackIcon>
            </BackWrap>
          </HeaderInner>
          <HeaderInputWrap>
            <HeaderInput
              value={searchWord}
              onChange={(e) => {
                setSearchWord(e.target.value);
                setContentsDetail(false);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  searchClick();
                }
              }}
              placeholder="검색어를 입력해주세요."
            />
          </HeaderInputWrap>
          <HeaderRight onClick={searchClick}>
            <SearchIconWrap>
              <SearchImg src={IconSearch} alt="searchIcon" />
            </SearchIconWrap>
          </HeaderRight>
        </HeaderWrap>
      </Container>
    </Mobile>
  );
}
const Container = styled.div`
  width: 100%;
  overflow: hidden;
  z-index: 200;
  transition: ease 0.3s all;
  height: 136.85px;
  @media screen and (max-width: 1320px) {
    height: calc(100vw * (136.85 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));

    ${({ main }) =>
      main &&
      `
    height : 100%;
  `}
  }
`;
const HeaderWrap = styled.div`
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #eeeeee;
  transition: ease 0.3s all;

  @media screen and (max-width: 930px) {
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    border-bottom: #eeeeee calc(100vw * (1 / 428)) solid;
    position: fixed;
    z-index: 99;
  }
`;
const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1320px) {
  }

  @media screen and (max-width: 930px) {
    margin: 0;
  }
`;
const HeaderInputWrap = styled.div`
  color: #333333;
  font-weight: 400;
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    line-height: calc(100vw * (19 / 428));
    width: calc(100vw * (297 / 428));
  }
`;
const HeaderInput = styled.input`
  width: 100%;
  &::placeholder {
    color: #acacac;
  }
  @media screen and (max-width: 930px) {
    line-height: calc(100vw * (19 / 428));
    padding-bottom: calc(100vw * (17 / 428));
    padding-top: calc(100vw * (19 / 428));
  }
`;
const HeaderRight = styled.div`
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (12 / 1320)) calc(100vw * (25 / 1320));
  }
  @media screen and (max-width: 930px) {
    display: flex;
    padding: calc(100vw * (12 / 428)) calc(100vw * (25 / 428)) calc(100vw * (12 / 428))
      calc(100vw * (7 / 428));
  }
`;
const SearchIconWrap = styled.div`
  width: 32px;
  height: 32px;
  & > img {
    height: 100%;
  }
  @media screen and (max-width: 1320px) {
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
  }
`;
const SearchImg = styled.img``;
const BackWrap = styled.div`
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (12 / 428)) calc(100vw * (10 / 428)) calc(100vw * (12 / 428))
      calc(100vw * (25 / 428));
  }
`;
const BackIcon = styled.div`
  @media screen and (max-width: 930px) {
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
  }
`;
const BackIconImg = styled.img`
  width: 100%;
  height: 100%;
`;
