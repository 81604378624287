import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery";
import styled from "styled-components";

import qs from "qs";

// img
import Zigzag from "../../img/borderBottom_zigzag.svg";
import { imgURL } from "../../server/appInfoController";

// component
import ReviewFormModal from "../../component/common/modal/ReviewFormModal";
import ReviewForm from "../../component/mypage/order/ReviewForm";

export default function MyReview(props) {
  const {
    list,
    setReviewDetail,
    setReviewEdit,
    setReviewIndex,
    possibleList,
    isWriteModalMo,
    setIsWriteModalMo,
    hide,
  } = props;
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const history = useHistory();
  const [prdList, setPrdList] = useState([]);
  const [isEditModalPc, setIsEditModalPc] = useState(false);

  const [clickItem, setClickItem] = useState({});

  const [isEditButtonTouch, setIsEditButtonTouch] = useState(false);

  useEffect(() => {
    if (!isWriteModalMo) return;
    const unListen = history.block((loc, action) => {
      if (action === "POP") {
        setIsWriteModalMo(false);
      }
      return false;
    });

    return () => {
      unListen();
    };
  }, [history, isWriteModalMo]);

  return (
    <>
      {isEditModalPc && (
        <ReviewFormModal
          setIsEditModal={setIsEditModalPc}
          title={"리뷰작성"}
          clickItem={clickItem}
          pc
        />
      )}
      <Container>
        {isWriteModalMo && <ReviewForm prdList={prdList} setIsWriteModalMo={setIsWriteModalMo} />}
        <Wrap isWriteModalMo={isWriteModalMo}>
          <TabArea hide={hide}>
            <TabBox>
              {/* <Tab
                onClick={() => {
                  history.push(`/store/mypage/review?tab=1&page=1`);
                }}
              >
                작성가능한 리뷰
              </Tab> */}
              <Tab
                onClick={() => {
                  history.push(`/store/mypage/review?tab=1&page=1`);
                }}
              >
                작성한 리뷰
              </Tab>
              {/* <TabBottom tabIndex={query.tab}>
                <TabBottomImg src={Zigzag} alt="zigzag-bottom" />
              </TabBottom> */}
            </TabBox>
          </TabArea>

          <ContentsWrap>
            {/* 리뷰 */}
            {query.tab === "0" ? (
              <>
                <Contents>
                  <ListBox>
                    {possibleList.length > 0 ? (
                      possibleList.map((reviewV) => {
                        return (
                          <List>
                            <ListInnerFlex>
                              <ProductInfoWrap>
                                <ImgBox
                                  onClick={() => {
                                    history.push(`/store/detail/${reviewV.prd_id}`);
                                  }}
                                >
                                  <img
                                    src={`${imgURL}${reviewV.prd_thumbnail}`}
                                    alt="product-img"
                                  />
                                </ImgBox>
                                <TextBox>
                                  <Brand>{reviewV.bc_title || "(BRAND)"}</Brand>
                                  <ProductName
                                    onClick={() => {
                                      history.push(`/store/detail/${reviewV.prd_id}`);
                                    }}
                                  >
                                    {reviewV.prd_name}
                                  </ProductName>
                                  {reviewV.optionInfo.map((item) => {
                                    return (
                                      <ProductColor>
                                        {item.optName && <span>{item.optName}</span>}
                                        {/* <span>1개</span> */}
                                      </ProductColor>
                                    );
                                  })}
                                </TextBox>
                              </ProductInfoWrap>
                              <ReviewEditButtonWrap>
                                <PC>
                                  <ReviewEditButton
                                    type="button"
                                    onClick={() => {
                                      setClickItem(reviewV);
                                      setReviewEdit(true);
                                      setIsEditModalPc(true);
                                    }}

                                    onTouchStart={() => setIsEditButtonTouch(true)}
                                    onTouchEnd={() => setIsEditButtonTouch(false)}
                                    isEditButtonTouch={isEditButtonTouch}
                                  >
                                    리뷰작성
                                  </ReviewEditButton>
                                </PC>
                                <Mobile>
                                  <ReviewEditButton
                                    type="button"
                                    onClick={() => {
                                      setPrdList(reviewV);
                                      setIsWriteModalMo(true);
                                    }}
                                    onTouchStart={() => setIsEditButtonTouch(true)}
                                    onTouchEnd={() => setIsEditButtonTouch(false)}
                                    isEditButtonTouch={isEditButtonTouch}
                                  >
                                    리뷰작성
                                  </ReviewEditButton>
                                </Mobile>
                              </ReviewEditButtonWrap>
                            </ListInnerFlex>
                          </List>
                        );
                      })
                    ) : (
                      <ContentNone>
                        <NoneText>작성가능한 리뷰가 없습니다.</NoneText>
                      </ContentNone>
                    )}
                  </ListBox>
                </Contents>
              </>
            ) : (
              <>
                <Contents>
                  <ListBox>
                    {list.length > 0 ? (
                      list.map((reviewV, index) => {
                        return (
                          <List>
                            <ListInner>
                              <ProductInfoWrapLong>
                                <ImgBox
                                  onClick={() => {
                                    setReviewDetail(true);
                                    setReviewIndex(index);
                                  }}
                                >
                                  <img
                                    src={`${imgURL}${reviewV.prd_thumbnail}`}
                                    alt="product-img"
                                  />
                                </ImgBox>
                                <TextBox>
                                  <Brand>{reviewV.bc_title || "(BRAND)"}</Brand>
                                  <ProductName
                                    onClick={() => {
                                      setReviewDetail(true);
                                      setReviewIndex(index);
                                    }}
                                  >
                                    {reviewV.prd_name}
                                  </ProductName>
                                  <ProductColor>
                                    {reviewV?.option && <span>{reviewV?.option}</span>}
                                    {/* <span>1개</span> */}
                                  </ProductColor>
                                </TextBox>
                              </ProductInfoWrapLong>
                              <ReviewTextWrap
                                onClick={() => {
                                  // history.push(`/store/mypage/review/detail?reviewId=${}`);
                                  setReviewDetail(true);
                                  setReviewIndex(index);
                                }}
                              >
                                <ReviewSatisfactionBox>
                                  <ReviewSatisfactionList>
                                    <ReviewSatisfaction>
                                      {reviewV.satisfaction === 1 ? "별로예요" : "만족해요"}
                                    </ReviewSatisfaction>
                                  </ReviewSatisfactionList>
                                  <ReviewSatisfactionList>
                                    <ReviewDate>
                                      {reviewV.rev_date.split("T")[0].replaceAll("-", ".")}{" "}
                                      {reviewV.rev_fixed === 1 && "(수정됨)"}
                                    </ReviewDate>
                                  </ReviewSatisfactionList>
                                </ReviewSatisfactionBox>
                                {reviewV.rev_image && (
                                  <ReviewImgBox>
                                    <img
                                      src={`${imgURL}${reviewV.rev_image}`}
                                      alt="review-uploaded-img"
                                    />
                                  </ReviewImgBox>
                                )}
                                <ReviewTextBox>{reviewV.rev_content}</ReviewTextBox>
                              </ReviewTextWrap>
                            </ListInner>
                          </List>
                        );
                      })
                    ) : (
                      <ContentNone>
                        <NoneText>작성한 리뷰가 없습니다.</NoneText>
                      </ContentNone>
                    )}
                  </ListBox>
                </Contents>
              </>
            )}
          </ContentsWrap>
        </Wrap>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 20px 0 0;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0 0;
  }

  @media screen and (max-width: 930px) {
    padding: 0;
  }
`;
const Wrap = styled.div`
  width: 100%;
  ${({ isWriteModalMo }) => isWriteModalMo && `display : none; z-index : -9999;`}
`;
const TabArea = styled.div`
  width: 100%;
  border-bottom: 1px solid #eee;
  transition: ease 0.3s all;

  @media screen and (max-width: 930px) {
    position: fixed;
    top: calc(100vw * (55 / 428));
    left: 0;
    background: #fff;
    ${({ hide }) =>
    hide &&
    `
      top : 0;
  `}
  }
`;
const TabBox = styled.div`
  position: relative;
  display: flex;
  width: 284px;
  height: 50px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (284 / 1320));
    height: calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    height: calc(100vw * (50 / 428));
    padding: 0 calc(100vw * (25 / 428));
  }
`;
const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 2);
  height: 100%;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;

const TabBottom = styled.div`
  position: absolute;
  left: ${(props) => (props.tabIndex === "0" ? `0` : `142px`)};
  bottom: -7px;
  transition: ease 0.3s all;
  width: 142px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    left: ${(props) => (props.tabIndex === "0" ? `0` : `calc(100vw * (142 / 1320))`)};
    bottom: calc(100vw * (-7 / 1320));
    width: calc(100vw * (142 / 1320));
  }
  @media screen and (max-width: 930px) {
    left: ${(props) =>
    props.tabIndex === "0" ? `calc(100vw * (25 / 428))` : `calc(100vw * (214 / 428))`};
    bottom: calc(100vw * (-7 / 428));
    width: calc(100vw * (189 / 428));
  }
`;
const TabBottomImg = styled.img`
  width: 100%;
  height: 100%;
`;

const ContentsWrap = styled.div`
  @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (55 / 428));
  }
`;
const Contents = styled.div``;

const ListBox = styled.ul``;
const List = styled.li`
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 8px;
    background-color: #f9f9f9;
  }
`;

const ListInner = styled.div`
  width: 100%;
  padding: 30px 10px 30px 25px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (30 / 1320)) calc(100vw * (10 / 1320)) calc(100vw * (30 / 1320))
      calc(100vw * (25 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0;
  }
`;
const ListInnerFlex = styled(ListInner)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 930px) {
    display: block;
  }
`;
const ProductInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 210px);

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (210 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  }
`;
const ProductInfoWrapLong = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  }
`;

const ImgBox = styled.div`
  width: 70px;
  height: 70px;
  background-color: #fff;
  border: 1px solid #eee;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 1320px) {
    width: calc(100vw * (70 / 1320));
    height: calc(100vw * (70 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (70 / 428));
    height: calc(100vw * (70 / 428));
  }
`;
const TextBox = styled.div`
  width: calc(100% - 80px);
  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (80 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (298 / 428));
  }
`;
const Brand = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 6px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-bottom: calc(100vw * (6 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-bottom: calc(100vw * (6 / 428));
  }
`;
const ProductName = styled.p`
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ProductColor = styled.p`
  color: #777;
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;

  & span {
    color: #777;
    font-weight: 400;
  }
  & span:not(:last-child)::after {
    content: " / ";
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (14 / 1320));
    margin-top: calc(100vw * (4 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (14 / 428));
    margin-top: calc(100vw * (4 / 428));
  }
`;
const ReviewEditButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: 0 auto;
  }
`;
const ReviewEditButton = styled.button`
  width: 120px;
  height: 40px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  ${({ isEditButtonTouch }) => isEditButtonTouch && `background-color : #1b1b1b;`}

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (120 / 1320));
    height: calc(100vw * (40 / 1320));
    font-size: calc(100vw * (18 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (180 / 428));
    height: calc(100vw * (45 / 428));
    font-size: calc(100vw * (18 / 428));
    margin-top: calc(100vw * (30 / 428));
  }
`;

const ReviewTextWrap = styled.div`
  margin: 20px 0 0;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    margin: calc(100vw * (20 / 1320)) 0 0;
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (378 / 428));
    margin: calc(100vw * (20 / 428)) auto 0;
  }
`;
const ReviewSatisfactionBox = styled.ul`
  display: flex;
  align-items: center;
`;
const ReviewImgBox = styled.div`
  width: 180px;
  height: 180px;
  margin: 10px 0 15px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (180 / 1320));
    height: calc(100vw * (180 / 1320));
    margin: calc(100vw * (10 / 1320)) 0 calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (180 / 428));
    height: calc(100vw * (180 / 428));
    margin: calc(100vw * (10 / 428)) 0 calc(100vw * (15 / 428));
  }
`;
const ReviewSatisfactionList = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 1px;
    height: 15px;
    background-color: #ddd;
    margin: 0 15px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child)::after {
      height: calc(100vw * (15 / 1320));
      margin: 0 calc(100vw * (15 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    &:not(:last-child)::after {
      height: calc(100vw * (15 / 428));
      margin: 0 calc(100vw * (15 / 428));
    }
  }
`;
const ReviewSatisfaction = styled.span`
  color: #333;
  font-weight: 600;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const ReviewDate = styled.span`
  color: #777;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;
const ReviewTextBox = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 16px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 10px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    margin-top: calc(100vw * (10 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    margin-top: calc(100vw * (10 / 428));
  }
`;

const ContentNone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 930px) {
    height: calc(100vh - calc(100vw * (105 / 428)));
  }
`;
const NoneText = styled.p`
  color: #999;
  font-weight: 400;
  font-size: 16px;
  padding: 200px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (200 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
    margin-bottom: calc(100vw * (90 / 428));
    padding: 0;
  }
`;
