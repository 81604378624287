import React, { useState } from 'react';
import styled from 'styled-components';

import Header from '../common/header/Header';
import Footer from '../common/footer/Footer';

import SearchProduct from './SearchProduct';
import {DescTitleAndText} from './Description';
import {ProductOrderTab} from './ManagementTap';
// import ListTab from './ListTab';

import ProductListOrderEl from './product/ProductListOrderEl';

export default function ProductListOrder() {
    const [viewSet, setViewSet] = useState('list');

    return(
        <>
            <DescTitleAndText
            title={'제품 노출 순서 변경'}
            text={<p>Drag & Drop을 통해 제품 Display 순서를 변경하실 수 있습니다.<br/>변경 된 순서는 아래 “저장” 버튼을 클릭하여야 제품 Display에 반영 됩니다.</p>}/>
            <ProductOrderTab/>
            <Wrapper>
                <ProductStateListWrap>
                    <XScrollContainer>
                        <XScrollWrap>
                            { Array(8).fill().map((item, index)=> {
                                return(
                                    <ProductListOrderEl key={index} />
                                )
                                })
                            }
                        </XScrollWrap>
                    </XScrollContainer>
            </ProductStateListWrap>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    position:relative;
    margin:0 auto;
    width: calc(100vw * (1300 / 1920));
    padding-top:36px;

    @media screen and (max-width:768px){
        width:100%; padding-left:calc(100vw * (25 / 428)); padding-right:calc(100vw * (25 / 428));
    }
`;
const ProductListWrap = styled.div`
   
`;

const ContentsSimpleWrap = styled.div`
    width:100%; display:flex; flex-wrap:wrap;
    margin-left:-9px; margin-right:-9px; padding:0 calc(100vw * (64 / 1920));
    &:after { content:''; display:block; clear:both; }
    @media screen and (max-width:1300px){
        padding:0;
    }
    @media screen and (max-width:768px){
        margin-left:-6px; margin-right:-6px;
    }
`;

const ProductStateListWrap = styled.div`
    
`;

const XScrollContainer = styled.div`
    @media screen and (max-width:625px){
        overflow-x:scroll;
    }
`

const XScrollWrap = styled.div`
    min-width: 625px;
`