import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import serverController from '../../server/serverController';
import IconBack from '../../images/page-button.png';

import {
    Wrapper,
    ContentsListWrap,
    ContentsSimpleWrap,
    ManagementTabWrap,
    TabContainer,
    TabWrap,
    TabBtn,
    ListContainer,
} from './StylistContents.elements';
import StylistContentsListHead from './StylistContentsListHead';
import StylistContentsList from './StylistContentsList';
import StylistContentsListSimple from './StylistContentsListSimple';

const StylistContents = () => {
    const [list, setList] = useState([]);
    const [view, setView] = useState(true);
    const history = useHistory();

    const getData = async () => {
        await serverController.connectFetchController(`stylist/contentslist`, "GET", null, (res) => {
            console.log(res, "내가 등록한 컨텐츠 res");
            if (res?.result === 1) {
                setList(res.list ?? []);
            }
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Wrapper>
            <ManagementTabWrap>
                <TabContainer>
                    <TabWrap>
                        <img src={IconBack} alt="" onClick={() => { history.push('/mypage'); }} />
                        <TabBtn>콘텐츠 관리</TabBtn>
                    </TabWrap>
                </TabContainer>
            </ManagementTabWrap>

            <ListContainer>
                <StylistContentsListHead view={view} setView={setView} />
                {
                    list.length > 0 ?
                        !view &&
                        <StylistContentsListSimple list={list} setList={setList} />
                        ||
                        <StylistContentsList list={list} setList={setList} />
                        :
                        <NoText>콘텐츠가 없습니다.</NoText>
                }
            </ListContainer>
        </Wrapper>
    )
}

const NoText = styled.p`
    font-size: calc(100vw * (16 / 1920));
    font-weight: bold;
    color: #aaa;
    text-align: center;
    margin-top: calc(100vw * (20 / 1920));

    @media screen and (max-width:1024px){
        font-size: calc(100vw * (15 / 428));
        margin-top: calc(100vw * (20 / 428));
    }
`;

export default StylistContents

