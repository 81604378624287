//react
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

//css
import styled from "styled-components";

//img
import IconSearch from "../../img/icon/icon_header_search_black.svg";

export default function BrandSearchSection() {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  return (
    <Container>
      <BrandSearchWrap>
        <SearchIconWrap
          onClick={() =>
            history.push(`/store/search/result?page=1&searchKeyword=${searchText}&tabIndex=1`)
          }
        >
          <img src={IconSearch} alt="searchIcon" />
        </SearchIconWrap>
        <SearchInputWrap>
          <SearchInput
            placeholder="브랜드명을 입력해주세요."
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                history.push(`/store/search/result?page=1&searchKeyword=${searchText}&tabIndex=1`);
              }
            }}
          />
        </SearchInputWrap>
      </BrandSearchWrap>
    </Container>
  );
}

const Container = styled.div`
  padding: 280px 0 60px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (280 / 1320)) 0 calc(100vw * (60 / 1320));
  }
`;
const BrandSearchWrap = styled.div`
  display: flex;
  align-items: center;
  width: 600px;
  height: 60px;
  padding: 0 15px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #000;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (600 / 1320));
    height: calc(100vw * (60 / 1320));
    padding: 0 calc(100vw * (15 / 1320));
  }
`;

const SearchIconWrap = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;

  & img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
`;

const SearchInputWrap = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 20px;

  @media screen and (max-width: 1320px) {
    margin-left: calc(100vw * (20 / 1320));
  }
`;
const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  color: #333333;
  font-weight: 400;
  font-size: 18px;
  &::placeholder {
    color: #acacac;
  }

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (18 / 1320));
  }
`;
