import { Link, useHistory, useLocation, useParams } from "react-router-dom";
//css
import styled from "styled-components";

export const MyPageButtonComp = ({ state, value, setSatisfaction, active }) => {
  return (
    <Btn
      as="div"
      active={active}
      onClick={() => {
        setSatisfaction(state);
      }}
    >
      {value.text}
    </Btn>
  );
};

const Btn = styled(Link)`
  display: block;
  width: 184px;
  color: #333;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  border: 1px solid #ddd;
  padding: 13px 0;
  cursor: pointer;

  &:not(:last-child){
    margin-right: 10px;
  }

  ${({ active }) =>
    active &&
    `
      border: 1px solid #000;
    `};

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (184 / 1320));
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (13 / 1320)) 0;

    &:not(:last-child){
      margin-right: calc(100vw * (10 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    width: calc(100vw * (184 / 428));
    font-size: calc(100vw * (16 / 428));
    padding: calc(100vw * (13 / 428)) 0;
    
    &:not(:last-child){
      margin-right: 0;
    }
  }
`;
