//react
import { Link,useHistory } from "react-router-dom";
import { Mobile, PC } from "../../MediaQuery"

//css
import styled from "styled-components"

export default function ServiceMenu() {
    const pathName = window.location.pathname;
    console.log(pathName.split('/'))

  return (
    <Container>
        <ServiceMenuWrap>
            <SubTitleWrap>
                {/* <SubTitle>고객센터</SubTitle> */}
                <SubTitle>1:1문의</SubTitle>
            </SubTitleWrap>
            {/* <MenuListWrap>
                <MenuListBox>
                    <MenusList>
                        <Link to={`/faq`}>
                            <List MenuOn={pathName.split('/')[1] == "faq"}>자주묻는질문</List>
                        </Link>
                    </MenusList>
                    <MenusList>
                        <Link to={`/notice`}>
                            <List MenuOn={pathName.split('/')[1] == "notice"}>공지사항</List>
                        </Link>
                    </MenusList>
                    <MenusList>
                        <Link to={`/inquiry`}>
                            <List MenuOn={pathName.split('/')[1] == "inquiry"}>1:1문의</List>
                        </Link>
                    </MenusList>
                </MenuListBox>
            </MenuListWrap> */}
        </ServiceMenuWrap>
    </Container>
  );
}
const Container = styled.div`
    width: 100%;
    background: #fbfbfb;
    padding-bottom: 5px;
    margin-bottom: 30px;

    @media screen and (max-width: 1320px) {
        padding-bottom: calc(100vw*(5/1320));
        margin-bottom: calc(100vw*(30/1320));
    }
    
    @media screen and (max-width: 930px) {
        padding-bottom: calc(100vw*(5/428));
        margin-bottom: calc(100vw*(30/428));
    }
`
const ServiceMenuWrap = styled.div`
    width: 1300px;
    margin: 0 auto;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(1250/1320));
    }
    
    @media screen and (max-width: 930px) {
        width: calc(100vw*(388/428));
    }
`
const SubTitleWrap = styled.div`
    padding-top: 80px;
    margin-bottom: 40px;

    @media screen and (max-width: 1320px) {
        padding-top: calc(100vw*(80/1320));
        margin-bottom: calc(100vw*(40/1320));
    }
    
    @media screen and (max-width: 930px) {
        padding-top: calc(100vw*(80/428));
        margin-bottom: calc(100vw*(40/428));
    }
`
const SubTitle = styled.h2`
    font-size: 30px;
    font-weight: bold;
    text-align: center;

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(30/1320));
    }
    
    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(25/428));
    }
`
//menu
const MenuListWrap = styled.div`
    width: 100%;
    margin-bottom: 50px;

    @media screen and (max-width: 1320px) {
        margin-bottom: calc(100vw*(50/1320));
    }
    
    @media screen and (max-width: 930px) {
        margin-bottom: calc(100vw*(50/428));
    }
`
const MenuListBox = styled.div`
    width: 680px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @media screen and (max-width: 1320px) {
        width: calc(100vw*(680/1320));
    }
    
    @media screen and (max-width: 930px) {
        width: 100%;
    }
`
const MenusList = styled.div`
    width: calc(100%/3 - 40px);
    margin-right: 40px;
    &:last-child {margin-right: 0;}

    @media screen and (max-width: 1320px) {
        width: calc(100%/3 - calc(100vw*(40/1320)));
        margin-right: calc(100vw*(40/1320));
    }
    
    @media screen and (max-width: 930px) {
        width: calc(100%/3 - calc(100vw*(20/428)));
        margin-right: calc(100vw*(20/428));
    }
`
const List = styled.p`
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    padding: 12px 0;
    border: 1px solid #969696;
    border-radius: 5px;
    background: #fff;
    ${({MenuOn})=>{
        return MenuOn ?
        `color: #000; font-weight: bold; border: 2px solid #000;`
        :
        ``
    }}

    @media screen and (max-width: 1320px) {
        font-size: calc(100vw*(16/1320));
        padding: calc(100vw*(12/1320)) 0;
        border: calc(100vw*(1/1320)) solid #969696;
        border-radius: calc(100vw*(5/1320));
        ${({MenuOn})=>{
            return MenuOn ?
            `border: calc(100vw*(2/1320)) solid #000;`
            :
            ``
        }}
    }
    
    @media screen and (max-width: 930px) {
        font-size: calc(100vw*(16/428));
        padding: calc(100vw*(12/428)) 0;
        border: calc(100vw*(1/428)) solid #969696;
        border-radius: calc(100vw*(5/428));
        ${({MenuOn})=>{
            return MenuOn ?
            `border: calc(100vw*(2/428)) solid #000;`
            :
            ``
        }}
    }
`