import React, {useState} from 'react';

import styled, { css } from 'styled-components';

export default function ProductDetailTab({activeTab, setActiveTab}){
    const TabList = [
        {title:'신규 신청' }, {title:'승인 내역'}, {title:'반납 예정'}, {title:'반납 완료'}, {title:'문의 목록'}
    ];

    return(
        <TabContainer>
            <TabWrap>
                {
                    TabList.map((cur, index)=>{
                        return(
                            <TabBtn isTabActive={activeTab + 1} onClick={()=>{}}>{cur.title} <MoHid>(4)</MoHid></TabBtn>
                        )
                    })
                }
            </TabWrap>
        </TabContainer>
    )
}
const TabContainer = styled.div`
    width: 100%; border-bottom:1px solid #cccccc;
    padding-top:calc(100vw * (76 / 1920));

    @media screen and (max-width:768px){
        padding-top:calc(100vw * (80 / 768));
    }
`;
const MoHid = styled.span`
    @media screen and (max-width:1024px){
        display:none;
    }
`;
const TabWrap = styled.div`
    width: calc(100vw * (1300 / 1920)); margin:0 auto;

    @media screen and (max-width:1024px){
        width:100%;
        padding-left:calc(100vw * (25 / 428));
        padding-right:calc(100vw * (25 / 428));
    }
`;
const TabBtn = styled.button`
    width:20%; font-size:18px; line-height:25px; color:#A5A5A5; padding:18px 0;
    font-weight:400;
    ${MoHid} { color:#A5A5A5; }
    &:nth-child(${props => props.isTabActive}) { 
        box-shadow: inset 0 -5px 0 #222222; font-weight:700; color:#222222; ${MoHid} { color:#222222; } 
        @media screen and (max-width:1024px){
            box-shadow: inset 0 -3px 0 #222222;
        }
    }

    @media screen and (max-width:1024px){
        padding:7px 0; font-size:16px;
    }
`;