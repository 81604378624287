import React, { useState } from "react";
import { PC, Mobile } from "../../MediaQuery";
import styled from "styled-components";

import Header from "../../components/common/header/Header";
// import StylistSponserd from '../../components/stylist/StylistSponsored';
import StylistMyList from "../../components/stylist/StylistMyList";
import Footer from "../../components/common/footer/Footer";
import FooterMenu from "../../components/common/footer/FooterMenu";

const StylistMylistPage = () => {
  return (
    <Container>
      <Header />
      <StylistMyList />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
    </Container>
  );
};
const Container = styled.div`
  @media screen and (max-width: 1024px) {
    padding-bottom: calc(100vw * (68 / 428));
  }
`;

export default StylistMylistPage;
