import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Mobile } from "../../MediaQuery";
import serverController from "../../server/serverController";
import { ProductDetailsAction } from "../../../store/actionCreators";

import styled from "styled-components";

import Header from "../../component/common/Header";
import DetailInfo from "../../component/product/detail/DetailInfo";
import DetailContent from "../../component/product/detail/DetailContent";
import WithProduct from "../../component/product/detail/WithProduct";
import FixMenu from "../../component/common/FixMenu";

// component
import GrayZone from "../../component/common/GrayZone";
import DetailFixMenu from "../../component/product/detail/DetailFixMenu";
import FullLoading from "../../component/common/modal/FullLoading";
import { Helmet } from "react-helmet-async";
import ScrollToTop from "components/common/ScrollToTop";

export default function ProductDetailPage() {
  const body = document.querySelector("body");
  const { prd_id } = useParams();
  const [product, setProduct] = useState({
    prd_additional4: "",
    prd_additional5: "",
  });

  const [optionList, setOptionList] = useState([]);

  // inquiry
  const [inquiryLength, setInquiryLength] = useState(0);
  const [inquiry, setInquiry] = useState([]);
  const [inquiryPageData, setInquiryPageData] = useState([]);
  const [listWith, setListWith] = useState([]);

  const [purchaseModal, setPurchaseModal] = useState(false);
  const [clickCartPop, setClickCartPop] = useState(false);

  const [loading, setLoading] = useState(true);

  const getProduct = () => {
    serverController.connectFetchController(`product/${prd_id}`, "GET", null, (res) => {
      if (res?.result === 1) {
        console.log(res, "res");
        let productData = res.product;
        productData.prd_additional4 = "";
        productData.prd_additional5 = "";
        setProduct(productData);
        setOptionList(res.option);

        ProductDetailsAction.updateProductInfo({
          productInfo: res?.product ?? {},
          options: res?.option ?? [],
          brand: res?.brand,
        });
        getListData(productData.bc_id);
      }
      setLoading(false);
    });
  };

  const getInquiry = async () => {
    await serverController.connectFetchController(`inquiry/product/${prd_id}`, "GET", null, (res) => {
      console.log(res, "!!! res");
      if (res?.result === 1) {
        setInquiryLength(res?.inquiryList?.length ?? 0);
        setInquiry(res.inquiryList);
        setInquiryPageData(res.pagination);
      }
    });
  };

  const getListData = (bcId) => {
    serverController.connectFetchController(
      `products?prd_id=${prd_id}&bc_id=${bcId}`,
      "GET",
      null,
      (res) => {
        if (res?.result === 1) {
          setListWith(res?.list);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getProduct();
    getInquiry();
    ProductDetailsAction.resetData();
  }, [prd_id]);

  // overflow hidden
  useEffect(() => {
    if (purchaseModal || clickCartPop) {
      body.style.overflow = "hidden";
      body.style.height = "100vh";
    } else {
      body.style.overflow = "auto";
      body.style.height = "auto";
    }
    return () => {
      body.style.overflow = "auto";
      body.style.height = "auto";
    };
  }, [purchaseModal, clickCartPop]);

  // s :: component items
  const detailFixMenuItems = {
    product,
    purchaseModal,
    setPurchaseModal,
    optionList,
    clickCartPop,
    setClickCartPop,
    listWith,
  };

  const detailInfoItems = {
    product,
    setProduct,
    setOptionList,
    optionList,
  };

  const detailContentItems = {
    product,
    inquiryLength,
    inquiry,
    inquiryPageData,
    getInquiry,
  };
  // e :: component items

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=3, minimum-scale=1, user-scalable=1" />
      </Helmet>
      {loading && <FullLoading />}
      {(purchaseModal || clickCartPop) && (
        <BackGroundGray
          onClick={() => {
            setPurchaseModal(false);
            setClickCartPop(false);
          }}
        />
      )}
      <Header back={true} />
      <Mobile>
        <DetailFixMenu {...detailFixMenuItems} />
      </Mobile>
      <DetailInfo {...detailInfoItems} />
      <Mobile>
        <GrayZone />
      </Mobile>
      <DetailContent {...detailContentItems} />
      <GrayZoneWrap>
        <GrayZone />
      </GrayZoneWrap>
      <WithProduct listWith={listWith} />
      <FixMenu />
    </>
  );
}
const BackGroundGray = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 40%;
  position: fixed;
  z-index: 100;
`;
const GrayZoneWrap = styled.div`
  width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (1240 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
  }
`;
