import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { PC, Mobile } from '../common/mediaStlye';
import serverController from '../../server/serverController';

import {
    ResultLayout,
    ResultHeadings,
    BlogItemList,
    BlogItem,
    BlogItemImg,
    BlogItemText,
    BlogMark,
    MoreButton
} from './SearchResultPage.elements';

import NaverLogo from '../../images/naver-logo.png';

const ResultsBlog = () => {
    const { keyword } = useParams();
    const [list, setList] = useState([]);
    const [offset, setOffset] = useState(4);

    const getData = async () => {
        await serverController.connectFetchController(`search/naver?keyword=${keyword}&page=1`, "POST", null, (res) => {
            console.log(res, keyword + "네이버 검색 res");
            if (res?.result == 1) {
                setList(res.list);
            }
        }, (err) => { console.log(err); }
        );
    }

    useEffect(() => {
        getData()
    }, [keyword])

    const onHandleClick = () => {
        setOffset(prev => prev + 4);
    }


    return (
        <ResultLayout>
            <ResultHeadings>
                <h2>"{keyword}" Blog Contents</h2>
            </ResultHeadings>

            <BlogItemList>
                {
                    list?.slice(0, offset).map((v, i) => {
                        return (
                            <BlogItem as="a" key={i} href={v?.link} target="_blank">
                                {/* <BlogItemImg><img src={v?.link} /></BlogItemImg> */}
                                <BlogItemText>
                                    <h4>{ReactHtmlParser(v?.title)}</h4>
                                    <PC>
                                        <p>{ReactHtmlParser(v?.description)}</p>
                                    </PC>
                                    <BlogMark><img src={NaverLogo} alt="네이버"></img></BlogMark>
                                </BlogItemText>
                            </BlogItem>
                        )
                    })
                }
            </BlogItemList>

            {
                offset < list?.length && <MoreButton onClick={onHandleClick}>See More</MoreButton>
            }
        </ResultLayout>
    )
}

export default ResultsBlog
