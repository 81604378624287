import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PC, Mobile } from '../common/mediaStlye';

import {
    UserInfoBox,
    UserInfoTabs,
    UserInfoTab,
    TabContents,
    // UserThumb,
    // LogoutButton, 
    // UserAnalysisLink,
} from '../auth/mypage/mypage.elements';
import { UserDataAction } from '../../store/actionCreators';

import StylistInfoUser from './StylistInfoUser';
import StylistInfoHistory from './StylistInfoHistory';
import TabStylistFaq from './TabStylistFaq';
import ExitModal from '../common/pop/ExitModal';
import serverController from '../../server/serverController';
import Modal from '../../server/modal';

const StylistMyInfoContents = () => {
    const history = useHistory()
    const tabTitles = ["계정정보", "협찬 신청내역", /*"컨텐츠 관리",*/ "문의 목록", "회원탈퇴"];
    const [activeIndex, setActiveIndex] = useState("0");
    const [isExitModal, setIsExitModal] = useState(false);

    const tabRender = () => {
        switch (activeIndex) {
            case "0":
                return (
                    <TabContents>
                        <StylistInfoUser />
                    </TabContents>
                )
            case "1":
                return (
                    <TabContents>
                        <StylistInfoHistory />
                    </TabContents>
                )
            case "2":
                return (
                    <TabContents>
                        <TabStylistFaq />
                    </TabContents>
                )
            default:
                return;
        }
    }
    const tabClick = (i) => {
        if (i !== 3) {
            setActiveIndex("" + i)
        } else {
            Modal.modalOpen();
            setIsExitModal(true);
        }
    }
    const exitClick = () => {
        serverController.connectFetchController(`user/exit`, "PUT", null, function (res) {
            if (res.result == 1) {
                console.log(res + "회원탈퇴 완료")
                UserDataAction.logOutRequest();
                history.push(`/login`);
            }
        });
    }

    return (
        <>
            <UserInfoBox>
                <UserInfoTabs>
                    {
                        tabTitles.map((tab, i) => (
                            <UserInfoTab
                                key={i}
                                selected={i == activeIndex}
                                // onClick={i === 2 ? () => history.push('/stylist/contents') : () => setActiveIndex(""+ i)}
                                onClick={() => tabClick(i)}
                            >
                                {tab}
                            </UserInfoTab>
                        ))
                    }
                </UserInfoTabs>
                {tabRender()}
                {isExitModal && <ExitModal setIsExitModal={setIsExitModal} exitClick={exitClick} />}

            </UserInfoBox>
        </>
    )
}

export default StylistMyInfoContents
