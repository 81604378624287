import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    IntroContainer,
    Layout,
    BlogBackground,
    MediaBackground,
    IntroBox,
    IntroThumb,
    IntroText,
    MoreButton,
} from './FashionNews.element';
import Banner1 from '../../images/news-blog-banner.png';
import Banner2 from '../../images/news-media-banner.png';
import BannerLogo1 from '../../images/news-logo-fashion.png';
import BannerLogo2 from '../../images/news-logo-news.png';

const FashionNewsIntro = () => {
    const history = useHistory();
    return (
        <IntroContainer>
            <Layout>
                <IntroBox>
                    <IntroThumb><img src={Banner1} /></IntroThumb>
                    <IntroText>
                        <em>블로그 컨텐츠</em>
                        <h2>Blog Contents</h2>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <MoreButton onClick={() => history.push('/news/blog')}>See More</MoreButton>
                        </div>
                    </IntroText>
                    <BlogBackground><img src={BannerLogo1} /></BlogBackground>
                </IntroBox>

                <IntroBox reverse>
                    <IntroThumb><img src={Banner2} /></IntroThumb>
                    <IntroText reverse>
                        <em>미디어 컨텐츠</em>
                        <h2>Media Contents</h2>
                        <MoreButton onClick={() => history.push('/news/media')}>See More</MoreButton>
                    </IntroText>
                    <MediaBackground><img src={BannerLogo2} /></MediaBackground>
                </IntroBox>
            </Layout>
        </IntroContainer>
    )
}

export default FashionNewsIntro
