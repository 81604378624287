import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useSelector } from "react-redux";
import { PaymentAction } from "../../../../store/actionCreators";

import WishIconImgOff from "../../../img/icon/icon_wish_off.svg";
import WishIconImgOn from "../../../img/icon/icon_wish_on.svg";

// option test
import Commas from "../../../server/Commas";
import DetailSetOption from "../../../../components/option/DetailSetOption";
import OptionListLayout from "../../../../components/option/OptionListLayout";

import serverController from "../../../server/serverController";
import { useHistory, useLocation } from "react-router-dom";
import ProductToCartModal from "../../common/modal/ProductToCartModal";
import MyPageDefaultModal from "../../common/modal/MyPageDefaultModal";
import { postLikeProducts } from "../../../../hooks/likeHooks";

export default function DetailFixMenu(props) {
  const {
    purchaseModal,
    setPurchaseModal,
    product,
    optionList,
    clickCartPop,
    setClickCartPop,
    listWith,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const data = useSelector((state) => state?.prodDetailData?.productInfo);
  const { isLogged } = useSelector((state) => state.userData);

  const [wishIsState, setIsWish] = useState(false);

  // touch
  const [touch, setTouch] = useState(false);
  const [purchaseButtonTouch, setPurchaseButtonTouch] = useState(false);
  const [cartButtonTouch, setCartButtonTouch] = useState(false);
  const [twoPurchaseButtonTouch, setTwoPurchaseButtonTouch] = useState(false);

  const [addressDataBool, setAddressDataBool] = useState(false);
  const [addressModalPop, setAddressModalPop] = useState(false);

  // modal pop
  const [loginPop, setLoginPop] = useState(false);

  // s : option
  // option
  const [optIdList, setOptIdList] = useState([]);
  const [selectList, setSelectList] = useState({});
  const [shopOption, setShopOption] = useState(false);
  const [optionText, setOptionText] = useState("");

  const onHandleLike = async () => {
    const res = await postLikeProducts(product.prd_id);
    setIsWish(res?.result === 1);
  };

  useEffect(() => {
    if (product.prd_id && optionList.length === 0) {
      const data = {
        1617853119962: {
          prd_id: product.prd_id,
          prd_img: product.prd_main_img,
          prd_price: product.prd_price,
          prd_sale_rate: product.prd_sale_rate,
          sale_price: product.sale_price,
          prd_title: data?.prd_name,
          prd_list_price: product.prd_list_price,
          prd_sale_price: product.prd_sale_price,
          prd_earn_price: product.prd_earn_price,
          count: 1,
          options: {
            optionList: {},
            optId: [],
            product: {
              prd_id: product.prd_id,
              prd_sale_rate: product.prd_sale_rate,
              sale_price: product.sale_price,
              prd_price: product.prd_price,
            },
            isOptional: false,
            isSetOptional: false,
          },
        },
      };
      setSelectList(data);
    } else setSelectList({});
  }, [optionList]);

  const getOptionTextList = (option) => {
    var optionText = "";
    var value = option;
    for (let i in value) {
      for (let key in value[i].options.optionList) {
        let optionlist = value[i].options.optionList[key];
        optionText += value[i].options.isSetOptional == true ? value[i].prd_title + ":" : "";
        for (let z = 0; z < optionlist.length; z++) {
          var opt = optionlist[z];
          optionText += opt.opt_name + (z + 1 == optionlist.length ? "" : "/");
        }
      }
    }
    return optionText;
  };

  const checkSetProductValidation = (opt) => {
    let options = opt.options;

    for (let key in selectList) {
      if (JSON.stringify(selectList[key].options) == JSON.stringify(options)) {
        alert("이미 선택된 옵션입니다.");
        return;
      }
    }

    var selectOption = JSON.parse(JSON.stringify(selectList));
    var date_id = new Date().getTime().toString();
    selectOption[date_id] = opt;

    getOptionTextList(selectOption);
    setSelectList(selectOption);

    setOptionText(getOptionTextList(selectOption));
  };

  const getOptionListLayout = (optArray, prdKey, optionlist) => {
    optArray.push(
      <OptionListLayout
        store
        key={prdKey}
        prdKey={prdKey}
        item={selectList[prdKey]}
        selectList={selectList}
        shopOption={shopOption}
        optionlist={optionlist}
        setSelectList={setSelectList}
      />
    );
  };

  const modalOptionList = () => {
    let optArray = [];
    for (const prdKey in selectList) {
      const optionlist = selectList[prdKey].options.optionList;
      getOptionListLayout(optArray, prdKey, optionlist);
      // if(prdKey > 0) return
    }
    return optArray;
  };

  //단순 selectList 에 대한 돈계산.
  const calculatePrice = (list) => {
    var totalPrice = 0;
    var count = 0;

    for (var key in list) {
      var price = 0;
      var optionList = list[key].options.optionList;
      count++;
      for (var calKey in optionList) {
        for (var i = 0; i < optionList[calKey].length; i++) {
          price += optionList[calKey][i].opt_price;
        }
      }

      if (list[key].options.isOptional == false) {
        price += list[key].prd_list_price;
      }

      price *= list[key].count;
      totalPrice += price;
    }

    //배송비 추후 따로 받아와야함
    if (count > 0) totalPrice += 0; //shipPrice;

    return totalPrice;
  };
  // e : option

  // get address data
  const getReceiversInfo = () => {
    serverController.connectFetchController(`receivers`, "GET", null, (res) => {
      if (res.result === 0) {
        setAddressDataBool(true);
      }
    });
  };

  // cart
  const onClickCart = () => {
    if (!isLogged) {
      return setLoginPop(true);
    }
    if (Object.keys(selectList).length === 0) {
      return alert("옵션을 선택해주세요.");
    }

    let count = 0;
    for (let key in selectList) {
      if (selectList[key].options.isOptional === false && selectList[key].prd_id === product.prd_id)
        count++;
    }

    if (shopOption === true) {
      if (count === 0) {
        alert("필수 상품을 선택해주세요");
        return false;
      }
    }
    for (const key in selectList) {
      const formData = new FormData();
      formData.append(
        "cart_item",
        JSON.stringify({
          bc_title: product.bc_title,
          prd_id: product.prd_id,
          prd_type: product.prd_type,
          prd_price: product.prd_price,
          prd_sale_rate: product.prd_sale_rate,
          sale_price: product.sale_price,
          prd_title: data?.prd_name,
          prd_detail1: product.prd_detail1,
          prd_detail2: product.prd_detail2,
          prd_img: product.prd_main_img,
          prd_earn_price: product.prd_earn_price,
          prd_earn_rate: product.prd_earn_rate,
          prd_list_price: product.prd_list_price,
          prd_sale_price: product.prd_sale_price,
          count: 1,
          data: [selectList[key]],
        })
      );
      formData.append("prd_id", product.prd_id);
      formData.append(
        "opt_id",
        selectList[key].options.optId[selectList[key].options.optId.length - 1]
      );
      serverController.connectFetchController(`cart/products`, "POST", formData, (res) => {
        if (res?.result === 1) {
          setSelectList({});
          setPurchaseModal(false);
          setClickCartPop(true);
        }
      });
    }
  };

  const getIsInventory = async () => {
    if (Object.keys(selectList).length === 0) return alert("옵션을 선택해주세요.");
    for (const key in selectList) {
      const optId = selectList[key].options.optId[selectList[key].options.optId.length - 1];
      console.log(optId, "optId");
      console.log(selectList[key].count, "selectList[key].count");
      await serverController.connectFetchController(
        `product/${optId}/inventory?count=${selectList[key].count}`,
        "GET",
        null,
        (res) => {
          if (res.result !== 1) return alert("일시적인 오류입니다. 다시 시도해주세요.");
          if (res.isItPossible === "Y") {
            onClickPay();
          } else {
            return alert("재고 보유 수량이 부족합니다. 다시 확인해주세요.");
          }
        }
      );
    }
  };

  const onClickPay = () => {
    if (addressDataBool) {
      return setAddressModalPop(true);
    }
    var count = 0;
    for (let key in selectList) {
      if (selectList[key].options.isOptional == false && selectList[key].prd_id == product.prd_id)
        count++;
    }
    if (count == 0) {
      alert("상품 및 옵션을 선택해주세요");
      return;
    }

    var resultArray = [];
    for (var key in selectList) {
      resultArray.push(selectList[key]);
    }
    if (resultArray.length === 0) {
      alert("상품 및 옵션을 선택해주세요");
      return false;
    }

    localStorage.selectList = JSON.stringify([
      {
        cart_items: JSON.stringify({
          prd_id: product.prd_id,
          prd_type: product.prd_type,
          prd_price: product.prd_price,
          prd_sale_rate: product.prd_sale_rate,
          sale_price: product.sale_price,
          prd_title: data?.prd_name,
          prd_detail1: product.prd_detail1,
          prd_detail2: product.prd_detail2,
          prd_img: product.prd_main_img,
          prd_earn_price: product.prd_earn_price,
          prd_earn_rate: product.prd_earn_rate,
          prd_list_price: product.prd_list_price,
          prd_sale_price: product.prd_sale_price,
          count: 1,
          data: resultArray,
        }),
      },
    ]).toString();
    PaymentAction.saveProductsPaymentInfo(selectList);
    history.push("/store/pay");
  };

  useEffect(() => {
    getReceiversInfo();
  }, []);

  // option control
  useEffect(() => {
    setSelectList({});
  }, [purchaseModal]);

  // wish control
  useEffect(() => {
    setIsWish(product.like_id !== 0 && product.like_id !== undefined);
  }, [product]);

  // s :: items
  const productToCartModalItems = {
    listWith,
    setClickCartPop,
    clickCartPop,
  };
  const detailSetOptionItems = {
    product,
    productInfo: [product],
    checkSetProductValidation,
    optionList,
    setOptIdList,
  };
  // e :: items

  useEffect(() => {
    console.log(wishIsState)
  }, [wishIsState])
  return (
    <Container>
      {loginPop && (
        <MyPageDefaultModal
          text="로그인 후 이용 가능합니다."
          trueText="로그인"
          trueFunc={() => {
            if (location) return history.push(`/store/login?returnTo=${location.pathname}`);
            history.push(`/store/login`);
          }}
          falseFunc={() => setLoginPop(false)}
        />
      )}
      {addressModalPop && isLogged && (
        <MyPageDefaultModal
          text="배송지 입력 후 정상 이용 가능합니다."
          trueText="입력하러 가기"
          trueFunc={() => {
            history.push(`/store/mypage/adress`);
          }}
          falseFunc={() => setAddressModalPop(false)}
        />
      )}
      {clickCartPop ? (
        <ProductToCartModal {...productToCartModalItems} thumbs={product.prd_thumbnail} />
      ) : (
        <>
          {!purchaseModal ? (
            <FixMenuWrap>
              <WishButtonWrap
                onClick={() => {
                  if (isLogged) {
                    onHandleLike();
                  } else {
                    setLoginPop(true);
                  }
                }}
                onTouchStart={() => {
                  setTouch(true);
                }}
                onTouchEnd={() => {
                  setTouch(false);
                }}
                touch={touch}
              >
                <WishIcon
                  src={wishIsState ? WishIconImgOn : WishIconImgOff}
                  alt="wish-fix-button"
                />
              </WishButtonWrap>
              <PurchaseButtonWrap>

                <PurchaseButton
                  onClick={() => {
                    if (isLogged) {
                      if (product.prd_featured !== 1) return;
                      if (optionList?.length === 0) getIsInventory();
                      setPurchaseModal(!purchaseModal);
                    } else {
                      setLoginPop(true);
                    }
                  }}
                  onTouchStart={() => {
                    setPurchaseButtonTouch(true);
                  }}
                  onTouchEnd={() => {
                    setPurchaseButtonTouch(false);
                  }}
                  buttonTouch={purchaseButtonTouch}
                >
                  {product?.prd_featured === 1 && "구매하기"}
                  {product?.prd_featured !== 1 && product?.prd_featured !== undefined && "SOLD OUT"}
                </PurchaseButton>
              </PurchaseButtonWrap>
            </FixMenuWrap>
          ) : (
            <FixMenuStepTwoWrap>
              <OptionBoxWrap margin={modalOptionList().length === 0}>
                {product.prd_featured === 1 && <DetailSetOption {...detailSetOptionItems} />}
                {product.prd_featured !== 1 && <OptionPriceTitle>품절상품입니다.</OptionPriceTitle>}
                {modalOptionList().length > 0 && (
                  <OptionListWrap>{modalOptionList()}</OptionListWrap>
                )}
                {modalOptionList().length > 0 && (
                  <OptionWrap>
                    <OptionPriceBox>
                      <OptionPriceTitle>총 상품 금액</OptionPriceTitle>
                      <OptionPriceText>
                        {Commas.numberWithCommas(calculatePrice(selectList))} 원
                      </OptionPriceText>
                    </OptionPriceBox>
                  </OptionWrap>
                )}
              </OptionBoxWrap>
              <FixMenuStepTwoButtonsBox>
                <FixMenuStepTwoLeftButton
                  onClick={onClickCart}
                  onTouchStart={() => {
                    setCartButtonTouch(true);
                  }}
                  onTouchEnd={() => {
                    setCartButtonTouch(false);
                  }}
                  buttonTouch={cartButtonTouch}
                >
                  장바구니
                </FixMenuStepTwoLeftButton>
                <FixMenuStepTwoRightButton
                  onClick={getIsInventory}
                  onTouchStart={() => {
                    setTwoPurchaseButtonTouch(true);
                  }}
                  onTouchEnd={() => {
                    setTwoPurchaseButtonTouch(false);
                  }}
                  buttonTouch={twoPurchaseButtonTouch}
                >
                  구매하기
                </FixMenuStepTwoRightButton>
              </FixMenuStepTwoButtonsBox>
            </FixMenuStepTwoWrap>
          )}
        </>
      )}
    </Container>
  );
}
// s :: animation
const transitionY = keyframes`
0%{
    transform : translateY(300px);
		opacity: 0.5;
}
	100% {
		opacity: 1;
	}
`;
// e :: animation
const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  @media screen and (max-width: 930px) {
    width: 100%;
    z-index: 100;
  }
`;

const FixMenuWrap = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  @media screen and (max-width: 930px) {
    align-items: center;
    height: calc(100vw * (80 / 428));
    padding: 0 calc(100vw * (25 / 428));
    box-shadow: 0 4px 10px 5px rgba(0,0,0,0.1);
    /* padding: calc(100vw * (10 / 428)) calc(100vw * (25 / 428))
      calc(100vw * (20 / 428)); */
  }
`;
const WishButtonWrap = styled.div`
  transition: ease 0.3s all;

  @media screen and (max-width: 930px) {
    /* margin-top: calc(100vw * (20 / 428)); */
    margin-top: 0;
    margin-left: calc(100vw * (5 / 428));
    width: calc(100vw * (25 / 428));
    height: calc(100vw * (25 / 428));
    ${({ touch }) =>
    touch &&
    `
      scale : 0.85;
    `}
  }
`;
const WishIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* @media screen and (max-width: 930px) {
    padding-top: calc(100vw * (10 / 428));
    padding-left: calc(100vw * (20 / 428));
  } */
`;
const PurchaseButtonWrap = styled.div``;
const PurchaseButton = styled.button`
  color: #fff;
  font-weight: 500;
  text-align: center;
  border: 1px solid #000;
  background-color: #000;

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
    width: calc(100vw * (320 / 428));
    min-height: calc(100vw * (56 / 428));
    padding: calc(100vw * (13 / 428)) calc(100vw * (20 / 428)) calc(100vw * (14 / 428));
    /* margin-top: calc(100vw * (10 / 428)); */
    margin-top: 0;
    /* border-radius: calc(100vw * (5 / 428)); */
    ${({ buttonTouch }) =>
    buttonTouch &&
    `
      scale : 0.98;
    // background: #fdf251;
    // color : #000;
    
    `}
  }
`;
const FixMenuStepTwoWrap = styled.div`
  background-color: #fff;
  transition: ease 0.3s all;
  animation: ${transitionY} 0.3s ease-out;
`;
const OptionBoxWrap = styled.div`
  transition: none !important;
  width: 1240px;
  margin: 0 auto;
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (30 / 428)) 0 calc(100vw * (20 / 428));
    width: calc(100vw * (378 / 428));
    ${({ margin }) =>
    margin &&
    `
    margin-bottom : calc(100vw * (130 / 428));
    `}
  }
`;
const FixMenuStepTwoButtonsBox = styled.div`
  display: flex;
  font-weight: 600;
  @media screen and (max-width: 930px) {
    height: calc(100vw * (60 / 428));
    font-size: calc(100vw * (18 / 428));
  }
`;
const FixMenuStepTwoLeftButton = styled.button`
  width: 50%;
  height: 100%;
  background-color: #fff;
  color: #000000;
  border: 1px solid #000;
  ${({ buttonTouch }) =>
    buttonTouch &&
    `
      background-color : #eeeeee;
    
    `}
`;
const FixMenuStepTwoRightButton = styled.button`
  width: 50%;
  background-color: #000;
  color: #fff;
  ${({ buttonTouch }) =>
    buttonTouch &&
    `
      background-color : #1b1b1b
    
    `}
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (18 / 428)) 0 calc(100vw * (19 / 428));
  }
`;

// option test
const OptionListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  border-bottom: 2px solid #000;

  @media screen and (max-width: 930px) {
    max-height: calc(100vh * (400 / 926));
    overflow: auto;
  }
`;
const OptionWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 930px) {
    display: block;
    padding-top: calc(100vw * (20 / 428));
  }
`;
const OptionPriceBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const OptionPriceTitle = styled.p`
  color: #333;
  font-weight: bold;
  font-size: 24px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (18 / 428));
  }
`;
const OptionPriceText = styled.p`
  font-size: 24px;
  font-weight: bold;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (24 / 1320));
  }

  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (24 / 428));
  }
`;
