import React from "react";
import { imgURL } from "../../../server/appInfoContoller";
import openLink from "../../../server/openLink";
import faker from "faker";
import {
  TabItemsContent,
  TabItemsHeading,
  TabItemConts,
  TabItemImg,
  TabItemDesc,
  TabItemText,
  TabItemLiked,
  TabItemsList,
} from "./mypage.elements";
import LikedIcon from "../../../images/stylist/liked_icon.svg";
import { useHistory } from "react-router-dom";

const TabContentItemIntro = ({ data, itemData, setActiveIndex }) => {
  const headings = ["Celebrity", "Drama", "Professional", "Idol"];

  const history = useHistory();

  const pageLinkClick = (id, type, link) => {
    if (type == 3) {
      openLink.openLinkEvent(link);
      // window.open(`${link}`);
    } else {
      alert("런칭 준비중입니다 :D");
      // history.push(`/store/detail/${id}`);
    }
  };

  return (
    <>
      <TabItemsContent>
        <TabItemsHeading>
          <h3>Contents</h3>
          <div onClick={() => setActiveIndex(1)} style={{ cursor: "pointer" }}>
            <span>총 {data ? data.length : 0}개</span>
            <span>더보기</span>
          </div>
        </TabItemsHeading>

        <TabItemsList>
          {[...data].slice(0, 3).map((v, i) => {
            // console.log(v)
            const src = v?.contents_thumbnail?.split(",")[0];
            return (
              <TabItemConts
                key={i}
                onClick={() => history.push(`/content/${v?.contents_id}`)}
              >
                <TabItemImg>
                  <img src={imgURL + src} />
                </TabItemImg>

                <TabItemDesc className="items-desc">
                  <TabItemText>
                    <em>{headings[v?.c_cat_id - 1]}</em>
                    <h2>{v?.contents_title}</h2>
                    <p>{v?.contents_tag}</p>
                  </TabItemText>
                </TabItemDesc>

                <TabItemLiked className="item-liked">
                  <img src={LikedIcon} />
                </TabItemLiked>
              </TabItemConts>
            );
          })}
        </TabItemsList>
      </TabItemsContent>

      <TabItemsContent>
        <TabItemsHeading>
          <h3>Item</h3>
          <div onClick={() => setActiveIndex(2)} style={{ cursor: "pointer" }}>
            <span>총 {itemData ? itemData.length : 0}개</span>
            <span>더보기</span>
          </div>
        </TabItemsHeading>

        <TabItemsList>
          {[...itemData].slice(0, 3).map((v, i) => {
            const src = v?.prd_thumbnail?.split(",")[0];
            return (
              <TabItemConts
                key={i}
                onClick={() => {
                  pageLinkClick(v?.prd_id, v?.prd_type, v?.prd_link);
                }}
              >
                <TabItemImg>
                  <img src={imgURL + src} />
                </TabItemImg>

                <TabItemDesc className="items-desc" second>
                  <TabItemText second>
                    <em>{v?.br_name}</em>
                    <h2>{v?.prd_name}</h2>
                    <p>{v?.prd_detail1}</p>
                  </TabItemText>
                </TabItemDesc>

                <TabItemLiked className="item-liked">
                  <img src={LikedIcon} />
                </TabItemLiked>
              </TabItemConts>
            );
          })}
        </TabItemsList>
      </TabItemsContent>
    </>
  );
};

export default TabContentItemIntro;
