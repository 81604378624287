import React, {useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import serverController from '../../server/serverController';
import { imgURL } from '../../server/appInfoContoller';
import { PC, Mobile } from '../common/mediaStlye';

import {
    MultipleWrapper,
    LayOut,
    ContentHeading2,
    MultipleContentSlider,
    MultipleSlide,
    MultipleLeft,
    MultipleRight,    
    MultipleContentImg,
    MultipleProducts,
    MultipleThumbs,
    MultipleContentText,    
    MoreButton2
} from './CategoryContents.element';
import CategoryMultipleSlide from './CategoryMultipleSlide';

import { Swiper, SwiperSlide,  } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';  
import "swiper/swiper-bundle.css";
import './CategoryMultipleContents.css';
SwiperCore.use([Navigation]);

const CategoryMultipleContents = () => {
    const history = useHistory()
    const { category_id } = useParams(); 
    const headings = ["Celebrity", "Drama", "Professional", "Idol"];

    const { isLogged } = useSelector(state => state.userData);     
    const [list, setList] = useState([]);
    const [mlist, setMlist] = useState([]);

    const divideArr = (arr, n) => {
        const newArr = [...arr];
        const len = newArr.length;
        const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
        const tmp = [];

        for(let i = 0; i < cnt; i++) {
            tmp.push(newArr.splice(0, n));
        }

        return tmp;
    }

    const getContents = async () => {        
        await serverController.connectFetchController(
            `contentslist/${category_id}`,
            "GET",
            null,
            (res) => {
                console.log(res, "카테고리 멀티플 목록 res");
                if (res?.result === 1) {                    
                    console.log(divideArr(res?.list, 2), "sa")                                 
                    setList(divideArr(res.list ?? [], 2));       
                    setMlist(res.list ?? []);
                }
            },
            (err) => console.error(err)
        )
    };       

    
    useEffect(() => {
        getContents()
    }, [category_id, isLogged])

    return (
        <>
        <PC>
        <MultipleWrapper>
            <LayOut>
                <ContentHeading2>Popular {headings[parseInt(category_id) - 1]} Items</ContentHeading2>
                <MultipleContentSlider className="mutiple-content-slider">
                    <Swiper 
                        navigation
                        loop
                    >
                        {
                            list.map((v, i) => {                                
                                return (
                                    <SwiperSlide key={i}>
                                        <CategoryMultipleSlide data={v}/>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>                    
                </MultipleContentSlider>
            </LayOut>
        </MultipleWrapper>
        </PC>
        <Mobile>
        <MultipleWrapper>
            <LayOut>
                <ContentHeading2>Popular {headings[parseInt(category_id) - 1]} Items</ContentHeading2>
                <MultipleContentSlider className="mutiple-content-slider">
                    <Swiper
                        navigation
                        loop
                        spaceBetween={150}                        
                    >
                        {
                            mlist.map((v, i) => {
                                const src = v?.contents_thumbnail?.split(",")[0]           
                                return (
                                    <SwiperSlide key={v?.contents_id}>
                                    <MultipleSlide  onClick={() => history.push(`/content/${v?.contents_id}`)}>
                                            <MultipleContentImg>
                                                <MultipleProducts col={true}>
                                                    {
                                                        v?.products?.slice(0, 3).map((value, j) => {                                                            
                                                            return (
                                                                <div key={j}><img src={imgURL + value?.prd_thumbnail} alt={value?.prd_content} /></div>
                                                            )
                                                        })
                                                    }
                                                </MultipleProducts>
                                                
                                                <MultipleThumbs><img src={imgURL + src} alt={v?.contents_intro}/></MultipleThumbs>
                                            </MultipleContentImg>

                                            <MultipleContentText>
                                                <h3>{v?.contents_title}</h3>
                                                <div className="mutiple_contents">{ReactHtmlParser(v?.contents_content)}</div>                                
                                                <div><MoreButton2 onClick={() => history.push(`/content/${v?.contents_id}`)}>See More</MoreButton2></div>
                                            </MultipleContentText>
                                    </MultipleSlide>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>                    
                </MultipleContentSlider>
            </LayOut>
        </MultipleWrapper>
        </Mobile>
        </>
    )
}

export default CategoryMultipleContents
                                

                                
                                
