import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Mobile, PC } from "../../../MediaQuery";
import styled from "styled-components";
import GrayZone from "../GrayZone";
import axios from "axios";
import { ip } from "../../../server/serverController";

//img
import IconCancle from "../../../img/icon/icon_myhaeder_cancle.svg";

export default function MyDeliveryTrackingModal({ setIsDeliverycheckModal, deliveryName, deliveryNum, deleveryCode }) {
  const [trackingData, setTrackingData] = useState([]);

  const postTrackingInfo = () => {
    axios
      .post(`${ip}api/tracking`, {
        code: `${deleveryCode}`,
        invoice: `${deliveryNum}`,
      })
      .then((res) => {
        console.log(res);
        if (res.data.result === 1) {
          setTrackingData(res.data.data.trackingDetails.reverse());
          changeDate();
        };
        if (res.data.result === 0) {
          alert(res.data.data.msg);
          setIsDeliverycheckModal(false);
        };
      })
      .catch((e) => {

      });
  };

  const changeDate = (timeString) => {
    return timeString.split(' ')[0];
  };

  const changeTime = (timeString) => {
    return timeString.split(' ')[1];
  };
  useEffect(() => {
    postTrackingInfo();
  }, []);

  return (
    <Container>
      <Background onClick={() => { setIsDeliverycheckModal(false) }} />
      <Content>
        {/* header */}
        <HeaderSection>
          <HeaderInner>
            <p>배송조회</p>
            {/* 닫기 버튼 */}
            <CloseButton
              onClick={() => {
                setIsDeliverycheckModal(false);
              }}
            >
              <img src={IconCancle} alt="" />
            </CloseButton>
          </HeaderInner>
        </HeaderSection>

        <Wrap>
          <ListArea>
            <ListBox>
              <List>
                <Title>택배사</Title>
                <Text>{deliveryName}</Text>
              </List>
              <List>
                <Title>운송장번호</Title>
                <Text>{deliveryNum}</Text>
              </List>
            </ListBox>
          </ListArea>
          <GrayZone />
          <TableArea>
            <Table>
              <TableThead>
                <tr>
                  <th>처리일시</th>
                  <th>현재위치</th>
                  <th>상태</th>
                </tr>
              </TableThead>
              <TableTbody>
                {trackingData !== [] &&
                  trackingData.map((data, i) => {
                    return (
                      <tr>
                        <td>
                          <p>{changeDate(data.timeString)}</p>
                          <p>{changeTime(data.timeString)}</p>
                        </td>
                        <td>{data.where}</td>
                        <td>{data.kind}</td>
                      </tr>
                    )
                  })}

              </TableTbody>
            </Table>
            {/* 없을 때 */}
            {/* <NoneText>
              조회기간이 만료되어<br/>배송현황이 확인되지 않습니다.
            </NoneText> */}
          </TableArea>
        </Wrap>
      </Content>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 101;
`;

// header
const HeaderSection = styled.div`
  position: relative;
  width: 100%;
  height: 84px;
  overflow: hidden;
  z-index: 99;

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (70 / 428));
  }

`;
const HeaderInner = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 84px;
  background-color: #fff;
  border-bottom: 1px solid #EEE;

  & p {
    color: #333;
    font-weight: 500;
    font-size: 20px;
  }

  @media screen and (max-width: 1320px) {
    height: calc(100vw * (84 / 1320));
    
    & p {
      font-size: calc(100vw * (20 / 1320));
    }
  }
  @media screen and (max-width: 930px) {
    height: calc(100vw * (55 / 428));
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1320px) {
    right: calc(100vw * (25 / 1320));
    width: calc(100vw * (32 / 1320));
    height: calc(100vw * (32 / 1320));
  }
  @media screen and (max-width: 930px) {
    right: calc(100vw * (25 / 428));
    width: calc(100vw * (32 / 428));
    height: calc(100vw * (32 / 428));
  }
`;

// 콘텐츠
const Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  width: 520px;
  height: 700px;
  background: #fff;
  overflow: hidden;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (520 / 1320));
    height: calc(100vw * (700 / 1320));
  }
  @media screen and (max-width: 930px) {
    width: 100%;
    height: 100vh;
    & p , tr, td, th {
      font-size: calc(100vw * (15 / 428)) !important;
    }
    
  }
`;

const Wrap = styled.div`
  height: calc(100% - 114px);
  padding: 0 20px;
  overflow: auto;

  @media screen and (max-width: 1320px) {
    height: calc(100% - calc(100vw * (114 / 1320)));
    padding: 0 calc(100vw * (20 / 1320));
  }
`;

const ListArea = styled.div`
  padding: 10px 25px 5px;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (10 / 1320)) calc(100vw * (25 / 1320)) calc(100vw * (5 / 1320));
  }
`;
const ListBox = styled.ul`
  padding: 20px 0;
  &:not(:last-child){
    border-bottom: 1px solid #eee;
  }

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (20 / 428));
  }
`;
const List = styled.li`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  &:not(:last-child){
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1320px) {
    &:not(:last-child){
      margin-bottom: calc(100vw * (10 / 1320));
    }
  }
`;
const Title = styled.p`
  line-height: 1.6;
  color: #777;
  font-weight: 400;
  font-size: 15px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (15 / 1320));
  }
`;
const Text = styled(Title)`
  width: calc(100% - 96px);
  color: #333;

  @media screen and (max-width: 1320px) {
    width: calc(100% - calc(100vw * (96 / 1320)));
  }
  @media screen and (max-width: 930px) {
    width: calc(100% - calc(100vw * (110 / 428)));
  }
`;

const TableArea = styled.div`
  padding: 20px 0;
  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320)) 0;
  }
`;
const Table = styled.table`
  width: 100%;
`;
const TableThead = styled.thead`
  & th {
    width: calc(100% / 3);
    line-height: 1.6;
    color: #333;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    background-color: #F5F5F5;
    padding: 13px 0;
  }

  @media screen and (max-width: 1320px) {
    & th {
      font-size: calc(100vw * (15 / 1320));
      padding: calc(100vw * (13 / 1320)) 0;
    }
  }
`;
const TableTbody = styled.tbody`
  & tr{
    border-bottom: 1px solid #ddd;
  }
  & td {
    width: calc(100% / 3);
    line-height: 1.6;
    color: #333;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    padding: 12px 0;
  }

  @media screen and (max-width: 1320px) {
    & td {
      font-size: calc(100vw * (15 / 1320));
      padding: calc(100vw * (12 / 1320)) 0;
    }
  }
`;

// 없을 때
const NoneText = styled.p`
  line-height: 1.6;
  color: #999;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  padding: 140px 0;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (140 / 1320)) 0;
  }
`;