import React from 'react';
import { Mobile, PC } from '../common/mediaStlye';

import {
    Container,
    Layout,
    MyPageHead,
    UserNameBox,
    UserThumb,
    LogoutButton, 
    UserAnalysisLink,
} from '../auth/mypage/mypage.elements';
import StylistMyInfoHead from './StylistMyInfoHead';
import StylistMyInfoContents from './StylistMyInfoContents';

import anaylsisIcon from '../../images/analysis-arrow.png';
import userThumbImg from '../../images/stylist/style.svg';

const StylistMyInfo = () => {
    return (
        <Container>
            <Layout>
                <StylistMyInfoHead />
                <StylistMyInfoContents />
            </Layout>
        </Container>
    )
}

export default StylistMyInfo
