import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../../components/common/header/Header";
import ServiceMenu from "../../component/service/ServiceMenu";
import FaqList from "../../component/service/FaqList";
import Footer from "../../component/common/StoreFooter";
import FooterMenu from "../../component/common/FooterMenu";
import FixMenu from "../../component/common/FixMenu";

export default function FaqPage() {
  return (
    <>
      <Header search={true} />
      <ServiceMenu />
      <FaqList />
      <Footer />
      <Mobile>
        <FooterMenu />
      </Mobile>
      <FixMenu />
    </>
  );
}
