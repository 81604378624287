import { useEffect } from "react";
import { Mobile, PC } from "../../MediaQuery";

import Header from "../../component/common/Header";
import PayCancelComplete from "../../component/common/complete/PayCancelComplete";

export default function PayCancelCompletePage() {
  return (
    <>
      <PC>
      </PC>

      <Mobile>
        <Header />
        <PayCancelComplete
          title="주문이 취소되었습니다."
          text={<>취소내역 확인은 마이페이지<br/>‘취소/교환/반품 조회’에서 하실 수 있습니다.</>}
        />
      </Mobile>
    </>
  );
}
