import styled, { css, keyframes } from "styled-components";
import {            
    pc,
    mobil,
    mquery,
    Media,
    Px,
    MQ,    
    Width,
    Height,
    Top,
    Bottom,
    Left,
    Right,
    FontSize,
    Margin,
    Margin2,
    Padding,
    Padding2,
    MT,
    MB,
    ML,
    MR,
    PT,
    PB,
    PL,
    PR,
    LineH,
} from '../common/mediaStlye';

export const IntroList  = styled.div`
    width: 100%;
    height: calc(100vw * (737 / 1920));
 
    ${MQ(mquery)} {
        height: auto;
        ${MT(0, mobil)}
        width: 100%;
    }
`

export const IntroItem = styled.div`
    display:flex;    
    cursor: pointer;
    ${Media(mquery,[
        "flex-direction: column;"
    ])}
`

export const IntroImg = styled.div`
    width: calc(100vw * (717 / 1920));
    height: calc(100vw * (732 / 1920));    
    line-height: 0;
    & > img {
        width:100%;        
        height: 100%;
        object-fit: cover;
    }

    ${MQ(mquery)} {
        ${Height(360, mobil)}
        width: 100%;
    }
`

export const IntroTxtWrapper = styled.div`    
    flex: 1;
    padding-left: calc(100vw * (37 / 1920));    
    display: flex;
    justify-content:flex-start;
    align-items: flex-start;
    flex-direction: column;
    & h3 {
        width: 100%;
        /* transform: translateX(-124px); */
        ${MT(220, pc)}
        margin-bottom: calc(100vw * (13 / 1920));
        font-size: calc(100vw * (50 / 1920));
        font-weight: 900;
        line-height: 1;
    }

    & p {        
        margin-right: calc(100vw * (-150 / 1920));
        width: 100%;        
        font-size: calc(100vw * (20 / 1920));
        margin-bottom: calc(100vw * (25 / 1920));
        overflow: hidden;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }

    & div {

    }

    @media screen and (max-width: ${mquery}px) {
        width: 100%;

        &  h3 {
            ${FontSize(30, mobil)}
            ${MT(30, mobil)}
            // ${LineH(38, mobil)}
            line-height: 1.2;
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        & > div {
            ${PL(0, mobil)}
        }

        & p {
            ${FontSize(18, mobil)}
            ${MR(0, mobil)}
            width: 100%;
        }
    }
`

export const ControllBox = styled.div`
    position: absolute;
    /* right:calc(100vw * (45 / 1920)); */
    right: 0;
    bottom:calc(100vw * (205 / 1920));
    z-index: 100;
    display: flex;        
    align-items: center;
    width: calc(100vw * (542 / 1920));
    font-size: calc(100vw * (20 / 1920));
    font-weight: 900;
    
    & .intro-pagination {
        letter-spacing: -2px;
    }

    & .swiper-pagination-fraction {
        /* margin-right: calc(100vw * (15 / 1920)); */
        width: auto;
        color: #D9D9D9;
        font-weight: 900;
    }

    & .swiper-pagination-current {         
        color: #FFF200;
        font-weight: 900;
        /* letter-spacing: 1px; */
        font-size: calc(100vw * (23 / 1920));
        
    }
    & .swiper-pagination-total {
        color: #D9D9D9;
        font-weight: 900;
        /* letter-spacing: 1px; */
        font-size: calc(100vw * (23 / 1920));
        
    }
    
    & .next-button {        
        font-size: calc(100vw * (17 / 1920));
        color: #D9D9D9;        
        cursor: pointer;
        
    }
    @media screen and (max-width: ${mquery}px) {
        position: static;
        bottom: auto;
        width: 100%;
        justify-content: center;
        ${MT(43, mobil)}
        
        & > h3 {
            ${FontSize(30, mobil)}
            transform: translateX(124px);
        }

        & p {
            ${[
                FontSize(15, mobil)
            ].join("")}
        }

        & .swiper-pagination-fraction {
            ${FontSize(25, mobil)}      
        }
        & .swiper-pagination-current {        
            ${FontSize(25, mobil)}      
        
        }
        & .swiper-pagination-total {
            ${FontSize(25, mobil)}            
            
        }
        
        & .next-button {        
            ${FontSize(20, mobil)}    
            
        }
    }
`

export const ProgressBar = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100vw * (303 / 1920));
    align-self: stretch;    
    margin: 0 15px;
    
    & .progress-dot {
        position: absolute;
        right:0;
        top:50%;
        transform: translateY(-50%);
        width:15px;
        height:15px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #222;         
    }
    
    @media screen and (max-width: ${mquery}px) {
        ${Width(180, mobil)}
    }
`
