import { useEffect, useState } from "react";
import { Mobile, PC } from "../../MediaQuery";
import Header from "../../component/common/Header";
import Complete from "../../component/common/complete/Complete";
import Footer from "../../component/common/StoreFooter";
import FixMenu from "../../component/common/FixMenu";

import qs from "qs";

export default function PayCompletePage() {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  // product item list
  const [productItemList, setProductItemList] = useState([]);

  useEffect(() => {
    setProductItemList(JSON.parse(localStorage.selectList ?? []));
  }, []);

  const completeItem = {
    query: query,
    list: productItemList,
  };

  return (
    <>
      {/* <PC>
        <Header />
        <Complete
          title="주문이 완료 되었습니다."
          btn="주문현황 보기"
          url="/store/mypage/order"
          rank={true}
        />
        <Footer />
        <FixMenu />
      </PC> */}

      <Header />
      <Complete
        title="주문이 완료되었습니다."
        // btn="주문현황 보기"
        // url="/store/mypage/order"
        {...completeItem}
      />
      {/* <Footer />
      <FooterMenu />
      <FixMenu /> */}
    </>
  );
}
