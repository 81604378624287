//react
import { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import HeaderSearchModal from "../header/HeaderSearchModal";
import openLink from "../../../server/openLink";

//css
import styled from "styled-components";

//img
import IconHome from "../../../images/icon_home_footer.svg";
import IconVideo from "../../../images/icon_video.svg";
import IconSearch from "../../../images/icon_search.svg";
import IconStore from "../../../images/icon_store.svg";
import IconMypage from "../../../images/icon_mypage.svg";

import IconHomeBk from "../../../images/icon_home_footer_bk.svg";
import IconVideoBk from "../../../images/icon_video_bk.svg";
import IconSearchBk from "../../../images/icon_search_footer_bk.svg";
import IconStoreBk from "../../../images/icon_store_bk.svg";
import IconMypageBk from "../../../images/icon_mypage_bk.svg";

export default function FooterMenu({ black }) {
  const history = useHistory();
  const { isLogged, userInfo } = useSelector((state) => state.userData);
  const [isOpen, setIsOpen] = useState(false);
  const mainUrl = window.location.pathname.split("/")[1];

  const moveAdmin = () => {
    window.location.href = "https://wala.land:8073/dashboard";
  };
  const scrollTop = () => {
    if (mainUrl != "") return;
    window.location.reload();
  };

  const pageLinkClick = (link) => {
    openLink.openLinkEvent(link);
  };

  const ProductHandler = () => {
    if (!isLogged) {
      alert('로그인 후 이용 가능합니다.');
      sessionStorage.setItem('currentPath', '/stylist/sponserd')
      history.push(`/login`);
      return;
    }
    history.push(`/stylist/sponserd`);
  }

  return (
    <>
      <Container className="footer" black={black}>
        <FooterWrap>
          <Menu
            to={`/`}
            onClick={() => {
              scrollTop();
            }}
          >
            <MenuImg src={black ? IconHome : IconHomeBk} alt="" />
          </Menu>
          <Menu to={`/video`}>
            <MenuImg src={black ? IconVideo : IconVideoBk} alt="" />
          </Menu>
          <Menus onClick={ProductHandler}>
            <MenuImg src={black ? IconSearch : IconSearchBk} alt="" />
          </Menus>
          {/* <Menus onClick={() => alert('런칭 준비중입니다 :D')}><MenuImg src={black ? IconStore : IconStoreBk} alt="" /></Menus> */}
          {/* <Menus onClick={()=>{pageLinkClick('https://wala.land/store')}}><MenuImg src={black ? IconStore : IconStoreBk} alt="" /></Menus> */}
          {!isLogged ? (
            <Menu to={`/login`}>
              <MenuImg src={black ? IconMypage : IconMypageBk} alt="" />
            </Menu>
          ) : (
            <>
              {userInfo.mem_role === "USER" && (
                <Menu to={`/mypage`}>
                  <MenuImg src={black ? IconMypage : IconMypageBk} alt="" />
                </Menu>
              )}
              {userInfo.mem_role === "BRAND" && (
                <Menus onClick={moveAdmin}>
                  <MenuImg src={black ? IconMypage : IconMypageBk} alt="" />
                </Menus>
              )}
              {userInfo.mem_role === "STYLELIST" && (
                <Menu to={`/stylist/mypage`}>
                  <MenuImg src={black ? IconMypage : IconMypageBk} alt="" />
                </Menu>
              )}
            </>
          )}
        </FooterWrap>
      </Container>
      {isOpen && <HeaderSearchModal setIsOpen={setIsOpen} />}
    </>
  );
}

// Footer
const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  /* background-color: #000; */
  background-color: #fff;
  border-top: 1px solid #efefef;
  padding: 0 calc(100vw * (18 / 428));
  z-index: 100;
  ${({ black }) => {
    return black
      ? `background-color: #000; border-top: 1px solid #000000;`
      : ``;
  }}
`;
const FooterWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 calc(100vw * (20 / 428));
`;
const Menu = styled(Link)`
  width: calc(100vw * (40 / 428));
`;
const Menus = styled.div`
  width: calc(100vw * (40 / 428));
  cursor: pointer;
`;
const MenuImg = styled.img`
  width: 100%;
  opacity: 0.9;
`;
