import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { PC, Mobile } from "../../../MediaQuery";
import serverController from "../../../server/serverController";
import { useSelector } from "react-redux";
import { UserDataAction } from "../../../store/actionCreators";
import styled, { css } from "styled-components";

import {
  CategoryModal,
  CategoryModalHead,
  OpenMenuBar,
  CategoryModalSub,
  ModalSubItem,
} from "./Header.elements";

import open_menu_bar from "../../../images/open-menu-modal.png";
import walaStoreModal from "../../../images/wala-store-modal.svg";
import modalSubArrowIcon from "../../../images/modal-sub-arrow.png";
import heartIcon from "../../../images/heart-dark.svg";
import accountIcon from "../../../images/account-dark.svg";
import brandIcon from "../../../images/brand-dark.svg";
import stylistIcon from "../../../images/stylist-dark.svg";
import logoutIcon from "../../../images/logout-store.svg";

export default function MiniHeaderCategoryModal({ setIsMenu }) {
  const history = useHistory();
  const { isLogged, userInfo } = useSelector((state) => state.userData);

  const onHandleLogout = () => {
    serverController.connectFetchController(
      `logout`,
      "POST",
      null,
      (res) => { },
      (err) => console.error(err)
    )
    if (localStorage.getItem("login-info")) {
      localStorage.removeItem("login-info");
    }
    alert("로그아웃 되었습니다.");
    history.push("/");
    UserDataAction.logOutRequest();
    setIsMenu(false);
  };

  const moveAdmin = () => {
    window.location.href = "https://brand.wala.land";
  };

  return (
    <>
      <CategoryModals>
        {/* <CategoryModalHead>
          <OpenMenuBars onClick={() => setIsMenu(false)}>
            <img src={open_menu_bar} alt="메뉴바" />
          </OpenMenuBars>
        </CategoryModalHead> */}

        <CategoryModalSubs>
          {!isLogged && (
            <>
              <ModalSubItems>
                <Link to="/login">
                  <p>로그인</p>
                  <img src={modalSubArrowIcon} />
                </Link>
              </ModalSubItems>
              <ModalSubItems>
                <Link to="/signup">
                  <p>회원가입</p>
                  <img src={modalSubArrowIcon} />
                </Link>
              </ModalSubItems>
            </>
          )}
          {userInfo.mem_role === "USER" && (
            <>
              <ModalSubItems onClick={() => history.push("/mypage")}>
                <div>
                  <img src={accountIcon} />
                  <p>MY PAGE</p>
                </div>
                <img src={modalSubArrowIcon} />
              </ModalSubItems>
              <ModalSubItems onClick={onHandleLogout}>
                <div>
                  <IconLogout src={logoutIcon} alt="" style={{ filter: "brightness(10)" }} />
                  <p style={{ marginTop: 0 }}>로그아웃</p>
                </div>
              </ModalSubItems>
            </>
          )}
          {userInfo.mem_role === "BRAND" && (
            <>
              {/* <ModalSubItems onClick={() => history.push('/brandmain')}>
                            <div>
                                <img src={brandIcon} />
                                <p style={{marginTop:0}}>BRAND</p>
                            </div>
                            <img src={modalSubArrowIcon} />
                        </ModalSubItems> */}
              <ModalSubItems onClick={moveAdmin}>
                <div>
                  <img src={brandIcon} />
                  <p style={{ marginTop: 0 }}>BRAND 관리자페이지</p>
                </div>
              </ModalSubItems>
              <ModalSubItems onClick={onHandleLogout}>
                <div>
                  <IconLogout src={logoutIcon} alt="" style={{ filter: "brightness(10)" }} />
                  <p style={{ marginTop: 0 }}>로그아웃</p>
                </div>
              </ModalSubItems>

              {/* <ModalSubItems onClick={() => history.push('/brandarchive')}>
                            <div><img src={heartIcon} />MY LIST</div>
                            <img src={modalSubArrowIcon} />
                        </ModalSubItems>                    
                        <ModalSubItems onClick={() => history.push('/management')} >
                            <div><img src={accountIcon} />MY PAGE</div>
                            <img src={modalSubArrowIcon} />
                        </ModalSubItems>                     */}
            </>
          )}
          {userInfo.mem_role === "STYLELIST" && (
            <>
              <ModalSubItems onClick={() => history.push("/stylist/sponserd")}>
                <div>
                  <img src={stylistIcon} />
                  <p>STYLIST</p>
                </div>
                <img src={modalSubArrowIcon} />
              </ModalSubItems>
              <ModalSubItems onClick={() => history.push("/stylist/liked")}>
                <div>
                  <img src={heartIcon} />
                  <p>MY LIST</p>
                </div>
                <img src={modalSubArrowIcon} />
              </ModalSubItems>
              <ModalSubItems onClick={() => history.push("/stylist/mypage")}>
                <div>
                  <img src={accountIcon} />
                  <p>MY PAGE</p>
                </div>
                <img src={modalSubArrowIcon} />
              </ModalSubItems>
              <ModalSubItems onClick={onHandleLogout}>
                <div>
                  <IconLogout src={logoutIcon} alt="" style={{ filter: "brightness(10)" }} />
                  <p style={{ marginTop: 0 }}>로그아웃</p>
                </div>
              </ModalSubItems>
            </>
          )}
          {/* <ModalSubItems store style={{cursor: 'pointer'}} onClick={() => history.push('/store')}>
                        <StoreIcon src={walaStoreModal} alt="wala-store" />
                        <img src={modalSubArrowIcon} />
                    </ModalSubItems> */}
        </CategoryModalSubs>
      </CategoryModals>
      <Blank className="blank" onClick={() => setIsMenu(false)} />
    </>
  );
}

const Blank = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: none;
  z-index: 2;
`;
const OpenMenuBars = styled(OpenMenuBar)`
  @media screen and (max-width: 1024px) {
    position: absolute;
    left: calc(100vw * (20 / 375));
  }
`;

const CategoryModals = styled(CategoryModal)`
  width: calc(100vw * (700 / 1920));
  background-color: rgba(0, 0, 0, 0.87);
  left: 0;
  right: auto;
  padding: 0 calc(100vw * (60 / 1920));

  @media screen and (max-width: 1024px) {
    width: calc(100vw * (280 / 375));
    padding: 0 calc(100vw * (60 / 375));
  }
`;
const ModalSubItems = styled(ModalSubItem)`
  height: calc(100vw * (100 / 1920));
  padding: 0 calc(100vw * (27 / 1920));
  border-bottom: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & div > img {
    width: auto;
    margin-right: calc(100vw * (8 / 1920));
  }

  & div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  & p {
    color: #fff;
    font-size: calc(100vw * (18 / 1920));
    margin-top: calc(100vw * (5 / 1920));
  }

  & a {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: 1024px) {
    height: calc(100vw * (60 / 375));
    padding: 0;

    & div > img {
      margin-right: calc(100vw * (8 / 375));
    }

    & p {
      font-size: calc(100vw * (15 / 375));
      margin-top: calc(100vw * (5 / 375));
    }
  }
`;
const IconLogout = styled.img`
  width: calc(100vw * (12 / 1920)) !important;
  filter: brightness(10);
  @media screen and (max-width: 1024px) {
    width: calc(100vw * (12 / 375)) !important;
  }
`;
const StoreIcon = styled.img`
  width: calc(100vw * (100 / 1920));
  @media screen and (max-width: 1024px) {
    width: calc(100vw * (80 / 375)) !important;
  }
`;
const CategoryModalSubs = styled(CategoryModalSub)`
  position: absolute;
  width: calc(100vw * (580 / 1920));
  top: calc(100vw * (150 / 1920));
  left: calc(100vw * (60 / 1920));
  right: auto;

  @media screen and (max-width: 1024px) {
    width: calc(100vw * (238 / 375));
    top: calc(100vw * (50 / 375));
    left: calc(100vw * (20 / 375));
    right: auto;
  }
`;
