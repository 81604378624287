import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from 'react-router-dom'
// import Loading from "../common/modal/Loading";
import PlanShopSection from "../../component/plan/PlanShopSection";
import StorePlanContainer from "containers/storePlanContainer";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";



export default function PlanShopPage() {
  const params = useParams();
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const { isLogged } = useSelector((state) => state.userData);

  useEffect(() => {
    console.log(isLogged);
    if (isLogged) return;
    if (!location?.search) return;
    if (location?.search.includes("pass")) {
      const queryParams = new URLSearchParams(location.search);
      const passValue = queryParams.get('pass');
      setCookie('pass', passValue, { path: '/', maxAge: 14 * 24 * 60 * 60 });
    };
  }, [])

  // React.useEffect(() => {
  //   const prevPath = sessionStorage.getItem("prevPath").split("/");
  //   console.log("prevPath: ", prevPath);

  //   if (!prevPath) {
  //     return;
  //   }

  //   if (prevPath.includes("plan")) {
  //     return;
  //   }

  //   if (!prevPath.includes("detail")) {
  //     window.scrollTo({
  //       top: 0,
  //     });
  //   }
  // }, [location]);

  return (
    <>
      {/* <PlanShopSection /> */}
      <StorePlanContainer />
    </>
  )
}