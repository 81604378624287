//react
import React, { useState, useEffect } from "react";
import { Mobile, PC } from "../../../MediaQuery";
import { useHistory, useParams } from "react-router-dom";

//css
import styled from "styled-components";

//page
import Pagination from "../../common/pagination/Pagination";

import Loading from "../../common/modal/Loading";

import HtmlParser from "react-html-parser";

// img
import ZigzagPcSmall from "../../../img/borderBottom_zigzag_small.svg";

const tabList = ["전체", "주문/결제", "배송", "교환/취소", "회원", "기타"];

export default function MyFaq(props) {
  const { faqList, type, setType, loading, setLoading } = props;
  //페이지 이동

  return (
    <Container>
      <Wrap>
        <TabArea>
          <TabBox>
            {tabList.map((tabItem, index) => {
              return (
                <Tab
                  onClick={() => {
                    setType(index);
                    setLoading(true);
                  }}
                  BtnOn={type === index}
                >
                  <TabText>{tabItem}</TabText>
                </Tab>
              );
            })}
            <PC>
              <TabBottom tabIndex={type}>
                <TabBottomImg src={ZigzagPcSmall} alt="bottom" />
              </TabBottom>
            </PC>
          </TabBox>
        </TabArea>
        {loading ? (
          <Loading />
        ) : (
          <ContentsWrap>
            <Contents>
              {faqList.length > 0 && (
                <FaqUl>
                  {faqList.map((faqItem) => {
                    const faqItems = { type, faqItem };
                    return <FaqLi {...faqItems}></FaqLi>;
                  })}
                </FaqUl>
              )}
            </Contents>
          </ContentsWrap>
        )}
        {/* <Pagination /> */}
      </Wrap>
    </Container>
  );
}

const FaqLi = (faqItems) => {
  const { type, faqItem } = faqItems;
  const [active, setActive] = useState(false);
  return (
    <li>
      <TitleWrap onClick={() => setActive(!active)}>
        <Type>[{tabList[type]}]</Type>
        <Title>{faqItem.faq_title}</Title>
      </TitleWrap>
      {active && (
        <AnswerWrap className="answer-text">
          <AnswerText>{HtmlParser(faqItem.faq_content)}</AnswerText>
        </AnswerWrap>
      )}
    </li>
  );
};

const Container = styled.div`
  width: 100%;
`;
const Wrap = styled.div`
  width: 100%;

  @media screen and (max-width: 930px) {
    padding: 0 0 calc(100vw * (54 / 428));
  }
`;

const TabArea = styled.div`
  width: 100%;
  border-bottom: 1px solid #eee;
  background-color: #fff;

  @media screen and (max-width: 930px) {
    position: fixed;
    top: calc(100vw * (55 / 428));
    left: 0;
    z-index: 9;
    width: 100%;
    padding: calc(100vw * (8 / 428)) calc(100vw * (25 / 428));
    background-color: #f9f9f9;
  }
`;
const TabBox = styled.div`
  position: relative;
  display: flex;
  width: 600px;
  height: 50px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (600 / 1320));
    height: calc(100vw * (50 / 1320));
  }

  @media screen and (max-width: 930px) {
    width: 100%;
    height: auto;
  }
`;
const Tab = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 6);
  height: 100%;
  cursor: pointer;

  @media screen and (max-width: 930px) {
    width: auto;
    height: auto;
    padding: calc(100vw * (5 / 428)) calc(100vw * (12 / 428));
    transition: ease 0.3s all;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;

      display: none;
      width: 100%;
      height: 100%;
      background-color: #000;
      border-radius: 15px;
    }

    ${({ BtnOn }) =>
      BtnOn &&
      `
        &::after{
          display: block;
        }
        & span{
          position: relative;
          z-index: 1;
          color : #fff;
        }
      `}
  }
`;
const TabText = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 16px;
  width: max-content;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
  }
`;

const TabBottom = styled.div`
  position: absolute;
  ${({ tabIndex }) => {
    return tabIndex === 0
      ? `left: 0;`
      : tabIndex === 1
      ? `left: 100px;`
      : tabIndex === 2
      ? `left: 200px;`
      : tabIndex === 3
      ? `left: 300px;`
      : tabIndex === 4
      ? `left: 400px;`
      : tabIndex === 5
      ? `left: 500px;`
      : tabIndex === 6
      ? `left: 600px;`
      : ``;
  }}
  bottom: -7px;
  transition: ease 0.3s all;
  width: 100px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    ${({ tabIndex }) => {
      return tabIndex === 0
        ? `left: 0;`
        : tabIndex === 1
        ? `left: calc(100vw * (100 / 1320));`
        : tabIndex === 2
        ? `left: calc(100vw * (200 / 1320));`
        : tabIndex === 3
        ? `left: calc(100vw * (300 / 1320));`
        : tabIndex === 4
        ? `left: calc(100vw * (400 / 1320));`
        : tabIndex === 5
        ? `left: calc(100vw * (500 / 1320));`
        : tabIndex === 6
        ? `left: calc(100vw * (600 / 1320));`
        : ``;
    }}
    bottom: calc(100vw * (-7 / 1320));
    width: calc(100vw * (100 / 1320));
  }
`;
const TabBottomImg = styled.img`
  width: 100%;
  height: 100%;
`;

const ContentsWrap = styled.div`
  margin-top: 20px;

  @media screen and (max-width: 1320px) {
    margin-top: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-top: 0;
    padding-top: calc(100vw * (55 / 428));
  }
`;
const Contents = styled.div``;

const FaqUl = styled.ul``;
const TitleWrap = styled.div`
  border-bottom: 1px solid #eee;
  background-color: #fff;
  padding: 20px;
  cursor: pointer;

  @media screen and (max-width: 1320px) {
    padding: calc(100vw * (20 / 1320));
  }
  @media screen and (max-width: 930px) {
    padding: calc(100vw * (16 / 428)) calc(100vw * (25 / 428));
  }
`;
const Type = styled.span`
  color: #999;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (16 / 428));
  }
`;
const Title = styled(Type)`
  color: #333;
  margin-left: 5px;

  @media screen and (max-width: 1320px) {
    margin-left: calc(100vw * (5 / 1320));
  }
  @media screen and (max-width: 930px) {
    margin-left: calc(100vw * (5 / 428));
  }
`;
const AnswerWrap = styled.div``;
const AnswerText = styled.div`
  color: #333;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  padding: 30px 35px 50px;
  background-color: #f9f9f9;

  @media screen and (max-width: 1320px) {
    font-size: calc(100vw * (16 / 1320));
    padding: calc(100vw * (30 / 1320)) calc(100vw * (35 / 1320)) calc(100vw * (50 / 1320));
  }
  @media screen and (max-width: 930px) {
    font-size: calc(100vw * (15 / 428));
    padding: calc(100vw * (30 / 428)) calc(100vw * (25 / 428)) calc(100vw * (40 / 428));
  }
`;
