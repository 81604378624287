import { Link, useHistory, useLocation, useParams } from "react-router-dom";
//css
import styled from "styled-components";

export const ButtonComp = ({ state, value, setSatisfaction, active }) => {
  return (
    <Btn
      as="div"
      active={active}
      onClick={() => {
        setSatisfaction(state);
      }}
    >
      {value.text}
    </Btn>
  );
};

const Btn = styled(Link)`
  display: block;
  width: 120px;
  font-size: 16px;
  border: 1px solid #000;
  border-radius: 11px;
  color: #000;
  padding: 12px 0;
  text-align: center;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
            color:#fff;
            background-color: #000;
        `};

  &:not(:last-child) {
    margin-right: 7px;
  }

  @media screen and (max-width: 1320px) {
    width: calc(100vw * (120 / 1320));
    font-size: calc(100vw * (16 / 1320));
    border: calc(100vw * (1 / 1320)) solid #000;
    border-radius: calc(100vw * (11 / 1320));
    padding: calc(100vw * (12 / 1320)) 0;
    &:not(:last-child) {
      margin-right: calc(100vw * (7 / 1320));
    }
  }

  @media screen and (max-width: 930px) {
    width: calc(100vw * (130 / 428));
    font-size: calc(100vw * (16 / 428));
    border: calc(100vw * (1 / 428)) solid #000;
    border-radius: calc(100vw * (11 / 428));
    padding: calc(100vw * (10 / 428)) 0;
    &:not(:last-child) {
      margin-right: calc(100vw * (7 / 428));
    }
  }
`;
