import qs from "qs";

import Header from "../../component/common/Header";
import FindPW from "../../component/login/FindPW";

export default function StoreFindPWPage() {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  return (
    <>
      <Header back={true} url={`/store/login?returnTo=${query.returnTo}`} />
      <FindPW returnTo={query.returnTo} />
    </>
  );
}
