import React from 'react';
import faker from 'faker';
import { Mobile, PC } from '../common/mediaStlye';
import {
    ExtraContainer,
    Layout,
    ExtraHeadings,
    ExtraList,
    ExtraItem,
    ExtraItemImg,
    ExtraItemText,
    ExtraMark,
    PagingBox,
    NextButton,
    PrevButton
} from './FashionNewsContents.element';
import NaverLogo from '../../images/naver-logo.png';


const BlogContentsExtra = () => {
    return (
        <ExtraContainer>
            <Layout>
                <ExtraHeadings>
                    <h3>기타 추천 블로그</h3>
                </ExtraHeadings>

                <ExtraList>
                {
                    Array(4).fill().map((item,i) => {
                        return (
                            <ExtraItem key={i}>
                                <ExtraItemImg><img src={faker.image.image()} /></ExtraItemImg>
                                <ExtraItemText>
                                    <h4>{faker.commerce.productName()}</h4>
                                    <PC>
                                    <p>{faker.commerce.productDescription()}</p>
                                    </PC>
                                    <ExtraMark><img src={NaverLogo} alt="네이버"></img></ExtraMark>
                                </ExtraItemText>
                            </ExtraItem>
                        )
                    })
                }
                </ExtraList>
                <PagingBox>
                    <div>
                        <PrevButton />
                        <ul>
                            {
                                Array(5).fill().map((item, j) => item = j + 1).map((item, i) => {
                                    return <li>{item}</li>
                                })
                            }
                        </ul>
                        <NextButton />
                        
                    </div>
                </PagingBox>
            </Layout>
        </ExtraContainer>
    )
}

export default BlogContentsExtra
