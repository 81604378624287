import React, { useState, useEffect, useCallback } from 'react';
import serverController from '../../../server/serverController';
import { PC, Mobile } from '../../../MediaQuery';

import {  
    FieldBox,
    Label,    
    LabelName,
    PasswordInput,
    PasswordCheckInput,
    ErrorMessage    
} from '../auth.elements';


const SignupPasswordInput = ({ setPassword, setIsCheck }) => {
    const [value, setValue] = useState("");
    const [confirm, setConfirm] = useState("");
    const [isError, setIsError] = useState(false);
    const [isConfirmError, setIsConfirmError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    const onHandleChange = (e) => {       
        const data = e.target.value;
        setValue(data)   

        const check = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,10}$/;
        
        if(!check.test(data) || !data){
            setIsError(true);
            setIsSuccess(false); 
            setErrorMessage('*영문+숫자+특수문자 조합 8~10자로 생성해주세요.');
        } else if (check.test(data) && data){
            setIsError(false);            
        }
    }; 

    const onHandleConfirm = (e) => {       
        const data = e.target.value;
        setConfirm(data)                
        
        if(data !== value){
            setIsConfirmError(true);
            setIsSuccess(false);  
            setErrorMessage('*비밀번호가 일치하지 않습니다.');
        } else {
            setIsConfirmError(false); 
            setIsSuccess(true);                                 
        }
    }; 

    useEffect(() => {
        setPassword(value)
        setIsCheck(prev => ({...prev, isPassword: isSuccess })); 
    }, [isSuccess])


    return (
        <>
        <FieldBox>
            <Label>
                <LabelName>비밀번호</LabelName>
                <PasswordInput
                    required
                    value={value}
                    onChange={onHandleChange}
                />
            </Label> 
            { isError && <ErrorMessage primary>{errorMessage}</ErrorMessage> }
            
        </FieldBox>

        <FieldBox>
            <Label>
                <LabelName>비밀번호 확인</LabelName>
                <PasswordCheckInput
                    required
                    value={confirm}
                    onChange={onHandleConfirm}
                />
            </Label> 
            { isConfirmError && <ErrorMessage primary>{errorMessage}</ErrorMessage> }
            { isSuccess && <ErrorMessage success primary>*비밀번호가 일치합니다.</ErrorMessage> }
        </FieldBox>
        </>
    )
}

export default SignupPasswordInput
