import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Mobile, PC } from "../../MediaQuery";
import IconTop from "../../../images/arrow-store.svg";

export default function FixMenu({ infinite = false }) {
  const infiniteWrap = document.querySelector("#infinite-scroll-wrap");
  const [touch, setTouch] = useState(false);
  const [scrollV, setScrollV] = useState(true);

  const handleScroll = () => {
    if (window.pageYOffset < 1) {
      setScrollV(true);
    } else {
      setScrollV(false);
    }
  };

  const onClickScrollTop = () => {
    if (infinite) {
      infiniteWrap.scrollTo({
        top: infiniteWrap.getBoundingClientRect().top - 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (!infinite) {
      const watch = () => {
        window.addEventListener("scroll", handleScroll);
      };
      watch();
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      setScrollV(false);
    }
  }, [infinite]);

  return (
    <>
      <Container
        scrollV={scrollV}
        onClick={onClickScrollTop}
        onMouseOver={() => {
          setTouch(true);
        }}
        onMouseLeave={() => {
          setTouch(false);
        }}
        touch={touch}
      >
        <TopImgBox>
          <TopImg touch={touch}>
            <img src={IconTop} alt="move-top" />
          </TopImg>
        </TopImgBox>
      </Container>
    </>
  );
}
const Container = styled.div`
  position: fixed;
  right: 30px;
  bottom: 160px;
  width: 56px;
  height: 56px;
  background: rgba(255, 255, 255, 0.7);
  transition: ease-out 0.4s all;
  /* border: 0.5px solid #999; */
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 10%) 5px 4px 16px 0px;
  z-index: 5;
  cursor: pointer;
  ${({ touch }) =>
    touch &&
    `
    transform: scale(1.1);
    `}
  ${({ scrollV }) =>
    scrollV &&
    `
    right : calc(100vw * (-60 / 428));
    `}

    @media screen and (max-width: 930px){
      right: 18px;
      width: 40px;
      height: 40px;
    }
`;
const TopImgBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;

  @media screen and (max-width: 930px) {
    padding: 0 15px;
  }
`;
const TopImg = styled.div`
  width: 17px;
  height: 20px;
  transition: ease 0.3s all;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
