import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import IconBack from "../../img/common/prevBtn.svg";

const StylistSponserdDetailBack = () => {
  const history = useHistory();
  return (
    <Container>
      <BackText
        onClick={() => {
          history.goBack();
        }}
      >
        <BackBtn src={IconBack} alt="" />
        뒤로가기
      </BackText>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  @media screen and (max-width: 1024px) {
    padding : calc(100vw * (70 / 428)) 0 calc(100vw * (15 / 428));
    padding-left: calc(100vw * (26 / 428));
    z-index: 1;
  }
`;

const BackText = styled.p`
 display: flex;
  align-items: center;
  width: 100px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    width: calc(100vw * (100 / 428));
    font-size: calc(100vw * (13 / 428));
  }
`;

const BackBtn = styled.img`
display: block;
  width: 12px;
  padding-right: 10px;
  @media screen and (max-width: 1024px) {
    width: calc(100vw * (10 / 428));
    padding-right: calc(100vw * (10 / 428));
  }
`

export default StylistSponserdDetailBack;
