import { combineReducers } from "redux";
import management from "./management";
import userData from "./userData";
import signupData from "./signupData";
import prodDetailData from "./prodDetailData";
import paymentData from "./paymentData";
import permissionData from "./permissionData";
import deviceData from "./deviceData";
import settingVersionData from "./settingVersionData";
import recommenderData from "./recommenderData";

export default combineReducers({
  userData,
  management,
  signupData,
  prodDetailData,
  paymentData,
  permissionData,
  deviceData,
  settingVersionData,
  recommenderData,
});
